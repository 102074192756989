import * as React from 'react';
import {View} from 'react-native';

import Input from '../../../shared/forms/Input';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

import {formStyles} from '../../../../styles/variables';

interface Props {
  value: string;
  onChangeText: (coverIllustrator: string) => void;
  onPressQuestion: () => void;
}

const CoverIllustratorFormField: React.FunctionComponent<Props> = props => {
  const {value, onChangeText, onPressQuestion} = props;
  return (
    <View style={formStyles.formGroup}>
      <View style={{flexDirection: 'row'}}>
        <LabelWithOption
          title={'イラストレーター名'}
          onPressQuestion={onPressQuestion}
        />
      </View>
      <Input
        value={value}
        placeholder={'イラストレーター名を入力'}
        onChangeText={onChangeText}
      />
    </View>
  );
};

export default CoverIllustratorFormField;
