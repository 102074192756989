import * as React from 'react';
import {
  ImageStyle,
  LayoutChangeEvent,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../buttons/PrimaryButton';
import FastImageAdapter from '../fast_image/FastImageAdapter';
import LargeModal from './LargeModal';

import DimensionContext from '../../shared/dimension/DimensionContext';

import {imageUrl} from '../../../helpers/images';

import {Options} from '../../../../domain/helpers/ImageUrlHelper';

interface Entity {
  getImageUrl(options: Options): string;
}

interface Props<T extends Entity> {
  entity: T;
  onSelectEntity: (entity: T) => void;
  onCloseEntityModal: () => void;
  onLayoutModal: (event: LayoutChangeEvent) => void;
  onLayoutButton: (event: LayoutChangeEvent) => void;
  width?: number;
  height?: number;
  imageUrlExtractor?: (item: T, width: number, aspectRatio: number) => string;
  renderFavoriteButton?: (item: T) => React.ReactNode;
}

export default class EntityModal<T extends Entity> extends React.Component<
  Props<T>
> {
  public render(): React.ReactNode {
    const {
      entity,
      onLayoutModal,
      onLayoutButton,
      onCloseEntityModal,
      imageUrlExtractor,
      renderFavoriteButton,
    } = this.props;
    return (
      <DimensionContext.Consumer>
        {context => {
          const width = this.props.width || context.content.width * 0.85;
          const height = this.props.height || width / (220 / 180);
          const aspectRatio = width / height;
          return (
            <LargeModal
              style={{width}}
              visible={true}
              onLayout={onLayoutModal}
              onCloseModal={onCloseEntityModal}>
              <FastImageAdapter
                style={[styles.image, {aspectRatio, height}]}
                resizeMode={'contain'}
                source={{
                  uri: imageUrlExtractor
                    ? imageUrlExtractor(entity, width, aspectRatio)
                    : imageUrl(entity, width, aspectRatio),
                  headers: {Accept: 'image/webp,image/apng,*/*'},
                }}
              />
              {renderFavoriteButton && renderFavoriteButton(entity)}
              <View onLayout={onLayoutButton} style={styles.button}>
                <PrimaryButton
                  buttonSize={BUTTON_SIZE}
                  onPress={this.handlePress}>
                  選択
                </PrimaryButton>
              </View>
            </LargeModal>
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private handlePress = () => {
    const {entity, onSelectEntity} = this.props;
    onSelectEntity(entity);
  };
}

const BUTTON_SIZE = {width: 160, height: 46};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    marginVertical: 15,
  } as ViewStyle,
  image: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    width: '100%',
  } as ImageStyle,
});
