import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import Effect from '../../../domain/entities/Effect';

import NetEffectsRepository from '../../../data/repositories/writer/NetEffectsRepository';

const types = {
  failure: Types.ShowEffectFailure,
  request: Types.ShowEffectRequest,
  success: Types.ShowEffectSuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Effect> {
  return showGeneric(new NetEffectsRepository(), types, id);
}
