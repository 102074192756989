import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import dateformat from 'dateformat';

import ActionModal from '../../../shared/modals/ActionModal';
import PublicStateLabel from '../../../shared/PublicStateLabel';

import {colors} from '../../../../styles/variables';

import Episode from '../../../../../domain/entities/writers_gate/Episode';

interface Props {
  episode: Episode;
  onSelectEpisode: (episode: Episode) => void;
}

const EpisodeListItem: React.FC<Props> = props => {
  const {episode, onSelectEpisode} = props;
  const [visibleModal, setVisibleModal] = React.useState(false);
  const onPress = React.useCallback(() => {
    setVisibleModal(false);
    onSelectEpisode(episode);
  }, []);
  const onOpenModal = React.useCallback(() => {
    setVisibleModal(true);
  }, []);
  const onCloseModal = React.useCallback(() => {
    setVisibleModal(false);
  }, []);
  return (
    <Pressable style={styles.container} onPress={onOpenModal}>
      <View style={styles.container}>
        <View style={styles.top}>
          <View style={styles.titleWrapper}>
            <Text style={[styles.title]}>{episode.title}</Text>
          </View>
          <View style={styles.lastUpdatedAtWrapper}>
            <Text style={styles.lastUpdatedAtText}>
              {dateformat(episode.updatedAt, 'yyyy.mm.dd')}更新
            </Text>
            <PublicStateLabel
              published={episode.published}
              scheduled={
                !!episode.scheduledDeliveredAt &&
                new Date(episode.scheduledDeliveredAt) > new Date()
              }
            />
            {episode.scheduledDeliveredAt && (
              <Text style={styles.scheduledDeliveredAt}>
                {dateformat(episode.scheduledDeliveredAt, 'yyyy.mm.dd HH:MM')}{' '}
                公開
              </Text>
            )}
          </View>
        </View>
      </View>
      {visibleModal && (
        <ActionModal
          visible={true}
          title={'このエピソードを元に提出しますか？'}
          description={'エピソードは提出用にコピーされます'}
          onAccept={onPress}
          onRequestClose={onCloseModal}
        />
      )}
    </Pressable>
  );
};

export default React.memo(EpisodeListItem);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 8,
  } as ViewStyle,
  top: {
    flex: 1,
  } as ViewStyle,
  titleWrapper: {
    flexDirection: 'row',
  } as ViewStyle,
  title: {
    color: '#222222',
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
  lastUpdatedAtWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 10,
  } as ViewStyle,
  lastUpdatedAtText: {
    color: '#666666',
    fontSize: 11,
    paddingRight: 16,
  } as TextStyle,
  freeLabel: {
    width: 35,
    marginLeft: 16,
    alignItems: 'flex-end',
  } as ViewStyle,
  scheduledDeliveredAt: {
    color: '#666666',
    fontSize: 11,
    paddingLeft: 4,
  },
  copyLabel: {
    borderColor: colors.lightGray,
    borderWidth: 1,
    borderRadius: 100,
    height: 25,
    marginLeft: 8,
  } as ViewStyle,
  copyLabelText: {
    paddingVertical: 2,
    paddingHorizontal: 8,
    fontSize: 9,
    fontWeight: 'bold',
    lineHeight: 13,
    color: colors.gray,
  } as TextStyle,
  moreIcon: {
    marginTop: -12,
    marginRight: -4,
    marginBottom: 4,
    padding: 8,
  } as ViewStyle,
});
