import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import FullScreenIllustration from '../../entities/FullScreenIllustration';
import Sound from '../../entities/Sound';

const CLASS_NAME: SceneCommandFormClass =
  'FullScreenIllustrationShowSceneCommandForm';

export default class FullScreenIllustrationShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(
    public fullScreenIllustration: FullScreenIllustration,
    public sound: Sound | null,
    public startTime: number | null,
    public endTime: number | null,
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      full_screen_illustration_id: this.fullScreenIllustration.id,
      sound_id: this.sound ? this.sound.id : null,
      start_time: this.startTime,
      end_time: this.endTime,
      type: 'SceneCommands::FullScreenIllustrationShowSceneCommand',
    };
  }

  public copy(
    sceneCommandId?: number,
  ): FullScreenIllustrationShowSceneCommandForm {
    return new FullScreenIllustrationShowSceneCommandForm(
      this.fullScreenIllustration,
      this.sound,
      this.startTime,
      this.endTime,
      sceneCommandId,
    );
  }
}
