import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ScaleModal from './ScaleModal';

import PrimaryButton from '../buttons/PrimaryButton';
import AlertIcon from '../icons/AlertIcon';
import DimensionContext from '../dimension/DimensionContext';

interface Props {
  visible: boolean;
  children?: React.ReactNode;
  onCloseModal: () => void;
}

const AlertModal: React.FunctionComponent<Props> = props => {
  const {visible, children, onCloseModal} = props;
  return (
    <DimensionContext.Consumer>
      {context => {
        return (
          <ScaleModal
            style={{width: context.content.width * 0.82}}
            visible={visible}
            onCloseModal={onCloseModal}>
            <View style={styles.modal}>
              <View style={styles.icon}>
                <AlertIcon size={32} color={'#ff8f13'} />
              </View>
              <Text style={styles.content}>{children}</Text>
              <View style={styles.button}>
                <PrimaryButton buttonSize={BUTTON_SIZE} onPress={onCloseModal}>
                  OK
                </PrimaryButton>
              </View>
            </View>
          </ScaleModal>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(AlertModal);

const BUTTON_SIZE = {width: 160, height: 46};

const styles = StyleSheet.create({
  modal: {
    margin: 16,
  } as ViewStyle,
  icon: {
    marginTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  content: {
    color: '#222222',
    fontSize: 13,
    lineHeight: 20,
    marginVertical: 16,
  } as TextStyle,
  button: {
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
});
