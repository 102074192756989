import * as React from 'react';
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import Navigator from './Navigator';
import TabContent from './TabContent';

import {equalForKeys} from '../../../helpers/equalForKeys';

import {colors, size} from '../../../styles/variables';

import ScrollableTabView, {
  DefaultTabBar,
  ScrollableTabBar,
} from '../../../../lib/adapters/react-native-scrollable-tab-view/index';

interface Size {
  height: number;
  width: number;
}

interface Props {
  tabs: TabItem[];
  scrollableTabBar?: boolean;
  locked?: boolean;
  initialPage?: number;
  onChangeTab?: (changedTabInfo: ChangedTabInfo) => void;
  navigator?: boolean;
  onTouchStart?: (event: GestureResponderEvent) => void;
  onTouchMove?: (event: GestureResponderEvent) => void;
  onTouchEnd?: (event: GestureResponderEvent) => void;
  onTransitionStartForWeb?: () => void;
  onTransitionEndForWeb?: () => void;
}

interface State {
  currentPage: number;
  containerSize: Size | null;
}

export interface ChangedTabInfo {
  from: number;
  i: number;
  ref: any;
}

export interface TabItem {
  label: string;
  key?: string;
  element: React.ReactNode;
}

export default class ScrollableTabViewAdapter extends React.Component<
  Props,
  State
> {
  private scrollableTabView: any | null = null;

  private navigator: Navigator | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      containerSize: null,
      currentPage: 0,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    if (
      !equalForKeys(this.props, nextProps, [
        'tabs',
        'scrollableTabBar',
        'locked',
        'initialPage',
        'navigator',
      ])
    ) {
      return true;
    }
    if (!equalForKeys(this.state, nextState)) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {
      tabs,
      locked,
      initialPage,
      navigator,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      onTransitionStartForWeb,
      onTransitionEndForWeb,
    } = this.props;
    const {currentPage, containerSize} = this.state;
    return (
      <View style={styles.container}>
        <ScrollableTabView
          contentProps={{
            bounces: false,
            onTouchEnd,
            onTouchMove,
            onTouchStart,
          }}
          ref={this.refScrollableTabView}
          locked={locked}
          initialPage={initialPage}
          tabBarActiveTextColor={colors.textColor}
          tabBarInactiveTextColor={colors.gray}
          prerenderingSiblingsNumber={3}
          renderTabBar={this.renderTabBar}
          onChangeTab={this.onChangeTab}
          {...{
            onTransitionStartForWeb,
            onTransitionEndForWeb,
          }}>
          {tabs.map(tab => {
            return (
              <TabContent
                key={tab.key || tab.label}
                tabLabel={tab.label}
                onLayout={this.handleLayout}>
                {tab.element}
              </TabContent>
            );
          })}
        </ScrollableTabView>
        {navigator && currentPage > 0 && containerSize && (
          <ArrowLeft
            containerSize={containerSize}
            onPress={this.goToPrevPage}
          />
        )}
        {navigator && currentPage < tabs.length - 1 && containerSize && (
          <ArrowRight
            containerSize={containerSize}
            onPress={this.goToNextPage}
          />
        )}
        {navigator && (
          <Navigator
            ref={this.refNavigator}
            pages={tabs.length}
            animateToPage={this.animateToPage}
          />
        )}
      </View>
    );
  }

  private renderTabBar = (): JSX.Element => {
    const {scrollableTabBar, locked} = this.props;
    const customTabBar = scrollableTabBar ? (
      <ScrollableTabBar
        style={styles.scrollableTabBar as any}
        tabStyle={styles.scrollableTabBarTab as any}
        textStyle={styles.scrollableTabBarText as any}
        tabsContainerStyle={styles.scrollableTabBarTabsContainer as any}
        underlineStyle={styles.scrollableTabBarUnderline as any}
      />
    ) : (
      <DefaultTabBar
        tabStyle={styles.defaultTabBarTab as any}
        textStyle={styles.defaultTabBarText as any}
        underlineStyle={styles.defaultTabBarUnderline as any}
        {...({locked} as any)}
      />
    );
    return customTabBar;
  };

  private refScrollableTabView = (instance: any | null) => {
    this.scrollableTabView = instance;
  };

  private refNavigator = (instance: Navigator | null) => {
    this.navigator = instance;
  };

  private animateToPage = (page: number) => {
    if (!this.scrollableTabView) {
      return;
    }
    this.setState({currentPage: page});
    this.scrollableTabView.goToPage(page);
  };
  private goToNextPage = () => {
    if (!this.navigator) {
      return;
    }
    const currentPage = this.navigator.state.currentPage;
    if (currentPage < this.props.tabs.length - 1) {
      this.animateToPage(currentPage + 1);
    }
  };

  private goToPrevPage = () => {
    if (!this.navigator) {
      return;
    }
    const currentPage = this.navigator.state.currentPage;
    if (currentPage > 0) {
      this.animateToPage(currentPage - 1);
    }
  };

  private onChangeTab = (changedTabInfo: ChangedTabInfo) => {
    this.setState({currentPage: changedTabInfo.i});
    if (this.navigator) {
      this.navigator.goToPage(changedTabInfo.i);
    }
    if (this.props.onChangeTab) {
      this.props.onChangeTab(changedTabInfo);
    }
  };

  private handleLayout = (event: LayoutChangeEvent) => {
    if (!this.props.navigator) {
      return;
    }
    this.setState({
      containerSize: {
        height: event.nativeEvent.layout.height,
        width: event.nativeEvent.layout.height,
      },
    });
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  defaultTabBar: {
    backgroundColor: colors.whiteGray,
    borderColor: colors.paleGray,
  } as ViewStyle,
  defaultTabBarTab: {
    paddingBottom: 0,
  } as ViewStyle,
  defaultTabBarText: {
    color: colors.textColor,
    fontSize: 14,
  } as TextStyle,
  defaultTabBarUnderline: {
    backgroundColor: '#ff8f13',
    bottom: -1,
    height: 3,
  } as ViewStyle,
  scrollableTabBar: {
    backgroundColor: colors.whiteGray,
    borderWidth: 0,
    height: size.minTapHeight,
  } as ViewStyle,
  scrollableTabBarTab: {
    paddingBottom: 6,
    paddingLeft: 30,
    paddingRight: 30,
  } as ViewStyle,
  scrollableTabBarTabsContainer: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  scrollableTabBarText: {
    color: colors.textColor,
    fontSize: 13,
  } as TextStyle,
  scrollableTabBarUnderline: {
    backgroundColor: colors.lightBlack,
    bottom: -1,
    height: 3,
  } as ViewStyle,
});
