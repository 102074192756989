import * as React from 'react';
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';

import DefaultOutlineButton from '../../../../shared/buttons/DefaultOutlineButton';

import {colors} from '../../../../../styles/variables';

interface Props {
  title: string;
  children?: React.ReactNode;
  onPress: () => void;
}

const AddSceneCommandLink: React.FunctionComponent<Props> = props => {
  const {title, children, onPress} = props;
  const pageXOnPressIn = React.useRef<number | null>(null);
  const handlePress = React.useCallback(
    (e: GestureResponderEvent) => {
      const pageXOnPressInValue = pageXOnPressIn.current;
      pageXOnPressIn.current = null;
      if (
        pageXOnPressInValue &&
        Math.abs(e.nativeEvent.pageX - pageXOnPressInValue) >= 3
      ) {
        return;
      }
      onPress();
    },
    [onPress, pageXOnPressIn],
  );
  const handlePressIn = React.useCallback(
    (e: GestureResponderEvent) => {
      pageXOnPressIn.current = e.nativeEvent.pageX;
    },
    [pageXOnPressIn],
  );
  return (
    <DefaultOutlineButton
      style={styles.container}
      onPress={handlePress}
      onPressIn={handlePressIn}>
      <View style={styles.inner}>
        <View style={styles.iconWrapper}>
          <Text style={styles.icon}>{children}</Text>
        </View>
        <Text style={styles.title}>{title}</Text>
      </View>
    </DefaultOutlineButton>
  );
};

export default onlyUpdateForKeys(['title'])(AddSceneCommandLink);

const styles = StyleSheet.create({
  container: {
    height: 50,
    margin: 4,
    width: 80,
    borderRadius: 27,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
  inner: {
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  icon: {
    color: colors.textColor,
    textAlign: 'center',
  } as TextStyle,
  iconWrapper: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  title: {
    color: colors.textColor,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
});
