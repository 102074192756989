import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import OauthAccount from '../../../../domain/entities/OauthAccount';

export default function reducer(
  state: {[key: number]: OauthAccount} = {},
  action: AnyAction,
): {[key: number]: OauthAccount} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexWriterOauthAccountsSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: OauthAccount}, record: OauthAccount) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.CreateWriterOauthAccountSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case Types.DestroyWriterOauthAccountSuccess: {
      const deleteState = {...state};
      delete deleteState[action.payload.id];
      return deleteState;
    }
    default:
      return state;
  }
}
