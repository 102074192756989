import * as React from 'react';
import {camelizeKeys} from 'humps';

import Form, {Inputs, Errors} from './components/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {SettingsBankAccountRouteProp} from '../../../navigators/RouteProps';

import BankAccount from '../../../../domain/entities/BankAccount';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  from?: 'transfer';
}

export interface StateProps {
  navigation: NavigationProp;
  route: SettingsBankAccountRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {from} = route.params || {from: null};
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [bankAccount, setBankAccount] = React.useState<BankAccount | null>(
    null,
  );
  const [errors, setErrors] = React.useState<Errors | undefined>(undefined);
  React.useEffect(() => {
    TapNovelRestApi.get('/api/writer/bank_account')
      .then(response => {
        setBankAccount(camelizeKeys(response.body) as BankAccount);
        setLoaded(true);
      })
      .catch(() => {
        setLoaded(true);
      });
  }, []);
  const onSubmit = React.useCallback((params: Inputs) => {
    TapNovelRestApi.patch('/api/writer/bank_account', {bankAccount: params})
      .then(() => {
        if (from === 'transfer') {
          navigation.pop();
          navigation.navigate('TransferRequestNew', {});
        } else {
          navigation.pop();
        }
      })
      .catch(e => {
        setErrors(e.body);
      });
  }, []);
  const defaultValues = bankAccount ? bankAccount : undefined;
  return (
    <Layout
      title={'受取口座設定'}
      navigation={navigation}
      back={true}
      loading={loading}
      scrollable={false}>
      {loaded && (
        <Form
          defaultValues={defaultValues}
          errors={errors}
          onSubmit={onSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Index);
