import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EpisodeViewerErrorLogMapper, {
  EpisodeViewerErrorLogData,
} from '../../entities/mappers/EpisodeViewerErrorLogMapper';

import EpisodeViewerErrorLog from '../../../domain/entities/EpisodeViewerErrorLog';
import EpisodeViewerErrorLogsRepository from '../../../domain/repositories/writer/EpisodeViewerErrorLogsRepository';

export default class NetEpisodeViewerErrorLogsRepository
  extends NetResourcesRepository<
    EpisodeViewerErrorLog,
    EpisodeViewerErrorLogData
  >
  implements EpisodeViewerErrorLogsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/episode_viewer_error_logs',
        new EpisodeViewerErrorLogMapper(),
      ),
    );
  }
}
