import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Switch from '../Switch';

interface Props {
  value?: boolean;
  onValueChange: (value: boolean) => void;
}

const PaidToggleButton: React.FC<Props> = props => {
  const {onValueChange} = props;
  const [value, setValue] = React.useState(props.value || false);
  const toggle = React.useCallback(() => {
    const nextValue = !value;
    setValue(nextValue);
    onValueChange(nextValue);
  }, [value]);
  return (
    <View style={styles.container}>
      <Text style={styles.text}>無料キャラのみ表示</Text>
      <View>
        <Switch value={!value} onValueChange={toggle} />
      </View>
    </View>
  );
};

export default React.memo(PaidToggleButton);

const styles = StyleSheet.create({
  container: {
    height: 40,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 12,
    marginHorizontal: 5,
  } as TextStyle,
  switch: {
    width: 43.5,
    height: 26.5,
  } as ViewStyle,
});
