import * as React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import CharacterView from './CharacterView';

import SceneFrame from '../../../../../../view_models/SceneFrame';

import Position from '../../../../../../../domain/value_objects/Position';

interface Props {
  position: Position;
  sceneFrame: SceneFrame;
  selectedPositions: Position[];
  source: ImageSourcePropType;
  width: number;
  onSelectPositions: (positions: Position[]) => void;
  onToggleMultiplePositions: (positions: Position[]) => void;
}

export default class CharacterPosition extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {position, sceneFrame, selectedPositions, source, width} = this.props;

    return (
      <TouchableOpacity
        style={[styles.container, {width}]}
        onPress={this.handlePress}
        onLongPress={this.handleLongPress}>
        <View
          style={[
            styles.content,
            selectedPositions.includes(position)
              ? styles.contentActivated
              : null,
          ]}>
          <CharacterView
            characterSceneCommandForm={
              sceneFrame.isActive(position) ? sceneFrame.get(position) : null
            }
          />
          <Image style={styles.image} source={source} resizeMode={'cover'} />
        </View>
      </TouchableOpacity>
    );
  }

  private handlePress = () => {
    const {position, onSelectPositions} = this.props;
    onSelectPositions([position]);
  };

  private handleLongPress = () => {
    const {position, onToggleMultiplePositions} = this.props;
    onToggleMultiplePositions([position]);
  };
}

export const ITEM_MARGIN = 5;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    flexDirection: 'column',
    margin: ITEM_MARGIN,
  } as ViewStyle,
  content: {
    width: '100%',
    paddingVertical: 10,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  contentActivated: {
    backgroundColor: '#f0f0f0',
  } as ViewStyle,
  image: {
    marginTop: 12,
    height: 115,
    width: 109,
  } as ImageStyle,
});
