import {Dispatch} from 'redux';

import Types from '../Types';

import IllustrationUsageHistory from '../../../domain/entities/IllustrationUsageHistory';

import NetIllustrationUsageHistoriesRepository from '../../../data/repositories/writer/NetIllustrationUsageHistoriesRepository';

export interface Params {
  storyId: number;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<IllustrationUsageHistory> {
  return async (dispatch: Dispatch<any>): Promise<IllustrationUsageHistory> => {
    try {
      const payload =
        await new NetIllustrationUsageHistoriesRepository().update(id, params);
      dispatch({
        payload,
        type: Types.UpdateIllustrationUsageHistorySuccess,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateIllustrationUsageHistoryFailure});
      throw err;
    }
  };
}
