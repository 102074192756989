import * as React from 'react';
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import Cell from './Cell';

interface Props {
  labels: {0: string; 1: string; 2: string};
  label0Style?: StyleProp<TextStyle>;
  label1Style?: StyleProp<TextStyle>;
  label2Style?: StyleProp<TextStyle>;
}

const Row: React.FunctionComponent<Props> = props => {
  const {labels, label0Style, label1Style, label2Style} = props;
  return (
    <View style={styles.container}>
      <Cell
        style={styles.cell0}
        label={labels['0']}
        labelStyle={[styles.label0, label0Style]}
      />
      <Cell label={labels['1']} labelStyle={[styles.label1, label1Style]} />
      <Cell label={labels['2']} labelStyle={[styles.label2, label2Style]} />
    </View>
  );
};

export default React.memo(Row);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
  cell0: {
    backgroundColor: '#efefef',
  } as ViewStyle,
  label0: {
    fontWeight: 'bold',
  } as TextStyle,
  label1: {} as TextStyle,
  label2: {
    color: '#ff8f13',
  } as TextStyle,
});
