import Entity from './Entity';

export default class OauthAccount implements Entity {
  constructor(
    public id: number,
    public uid: string,
    public screenName: string,
    public oauthProviderCode: string,
  ) {}
}
