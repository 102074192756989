import React from 'react';

import RootStack from '../../RootStack';

import CoverImageFormCharacterFormActorSearchForm from '../../../containers/cover_image_form/character_form/actor_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="CoverImageFormCharacterFormActorSearchForm">
      <RootStack.Screen
        name="CoverImageFormCharacterFormActorSearchForm"
        component={CoverImageFormCharacterFormActorSearchForm}
        options={{title: '背景検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
