import * as React from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

import CharacterMakerActorGroup from '../../../../../../../domain/entities/CharacterMakerActorGroup';

interface Props {
  style?: StyleProp<ViewStyle>;
  characterMakerActorGroup: CharacterMakerActorGroup;
  onSelectCharacterMakerActorGroup: (
    characterMakerActorGroup: CharacterMakerActorGroup,
    skinColorPatternId: number,
  ) => void;
}

const ActorGroupListItem: React.FunctionComponent<Props> = props => {
  const {style, characterMakerActorGroup, onSelectCharacterMakerActorGroup} =
    props;
  const handleSelectCharacterMakerActorGroup = React.useCallback(() => {
    onSelectCharacterMakerActorGroup(characterMakerActorGroup, 1);
  }, []);
  return (
    <Pressable
      style={[styles.container, style]}
      onPress={handleSelectCharacterMakerActorGroup}>
      <Text style={styles.label}>{characterMakerActorGroup.name}</Text>
    </Pressable>
  );
};

export default React.memo(ActorGroupListItem);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderColor: '#d5d5d5',
    borderWidth: 1,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  label: {
    color: '#666',
    fontWeight: 'bold',
    fontSize: 12,
  } as TextStyle,
});
