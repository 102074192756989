import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics';

import OauthAccount from '../../../../domain/entities/OauthAccount';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetOauthAccountsRepository from '../../../../data/repositories/writer/NetOauthAccountsRepository';

const types = {
  failure: Types.IndexWriterOauthAccountsFailure,
  request: Types.IndexWriterOauthAccountsRequest,
  success: Types.IndexWriterOauthAccountsSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<OauthAccount>> {
  return indexGenerics(new NetOauthAccountsRepository(), types, params);
}
