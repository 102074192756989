import * as React from 'react';
import {GestureResponderEvent} from 'react-native';

interface Props<T> {
  onSelectItem: (item: T) => void;
}

interface State {}

export default class DirtyPressIgnoreView<
  T,
  P extends Props<T>,
  S extends State,
> extends React.Component<P, S> {
  private pressInLocation: {
    pageX: number;
    pageY: number;
  } | null = null;

  protected listViewDirtyPressEnabled = true;

  protected handleTouchStart = (event: GestureResponderEvent) => {
    const {pageX, pageY} = event.nativeEvent;
    this.pressInLocation = {pageX, pageY};
    this.listViewDirtyPressEnabled = true;
  };

  protected handleTouchMove = (event: GestureResponderEvent) => {
    const {pageX: aX, pageY: aY} = event.nativeEvent;
    if (this.pressInLocation) {
      const {pageX: bX, pageY: bY} = this.pressInLocation;
      if (this.getDistanceBetweenPoints(aX, aY, bX, bY) > 10) {
        this.listViewDirtyPressEnabled = false;
      }
    }
  };

  protected handleSelectItem = (item: T) => {
    const {onSelectItem} = this.props;
    if (!this.listViewDirtyPressEnabled) {
      return;
    }
    onSelectItem(item);
  };

  private getDistanceBetweenPoints(
    aX: number,
    aY: number,
    bX: number,
    bY: number,
  ): number {
    const deltaX = aX - bX;
    const deltaY = aY - bY;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  }
}
