import ForbiddenWordValidationResult from '../../../domain/value_objects/ForbiddenWordValidationResult';

export interface ForbiddenWordValidationResultData {
  word: string;
  error: string | null;
}

export default class ForbiddenWordValidationResultMapper {
  public map(
    obj: ForbiddenWordValidationResultData,
  ): ForbiddenWordValidationResult {
    return new ForbiddenWordValidationResult(obj.word, obj.error);
  }
}
