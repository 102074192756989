import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics/index';

import Story from '../../../../domain/entities/response/Story';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetStoriesRepository from '../../../../data/repositories/writer/response/NetStoriesRepository';

const types = {
  failure: Types.IndexResponseStoriesFailure,
  request: Types.IndexResponseStoriesRequest,
  success: Types.IndexResponseStoriesSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Story>> {
  return indexGenerics(new NetStoriesRepository(), types, params);
}
