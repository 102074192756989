import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import showGeneric from '../../generics/show';

import HelpCategory from '../../../../domain/entities/writer/HelpCategory';

import NetHelpCategoriesRepository from '../../../../data/repositories/writer/NetHelpCategoriesRepository';

const types = {
  failure: Types.ShowWriterHelpCategoryFailure,
  request: Types.ShowWriterHelpCategoryRequest,
  success: Types.ShowWriterHelpCategorySuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<HelpCategory> {
  return showGeneric(new NetHelpCategoriesRepository(), types, id);
}
