import * as React from 'react';
import {
  Platform,
  StyleSheet,
  ScrollView,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {FormValues, CONCACT_CATEGORY_IDS} from './FormData';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';
import CheckButton from '../../../shared/forms/CheckButton';
import AlertIcon from '../../../shared/icons/AlertIcon';

import {colors, formStyles} from '../../../../styles/variables';

interface Props {
  formValues: FormValues;
  onPressBack: () => void;
  onPressSubmit: () => void;
}

const Confirm: React.FC<Props> = props => {
  const {formValues, onPressSubmit, onPressBack} = props;
  const {title, content, selectedCategory} = formValues;
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={formStyles.formContainer}>
          <View style={[formStyles.formGroup, styles.headline]}>
            <Text style={styles.headlineText}>お問い合わせの内容確認</Text>
          </View>
          <View style={formStyles.formGroup}>
            <View>
              <Text style={[formStyles.formLabel, styles.formLabel]}>
                お問い合わせカテゴリ
              </Text>
            </View>
            <View style={styles.confirm}>
              <Text style={styles.confirmText}>
                {selectedCategory ? selectedCategory.name : ''}
              </Text>
            </View>
          </View>
          {CONCACT_CATEGORY_IDS.includes(selectedCategory.id) ? null : (
            <View style={formStyles.formGroup}>
              <View>
                <Text style={[formStyles.formLabel, styles.formLabel]}>
                  お問い合わせタイトル
                </Text>
              </View>
              <View style={styles.confirm}>
                <Text style={styles.confirmText}>{title}</Text>
              </View>
            </View>
          )}
          <View style={formStyles.formGroup}>
            <View>
              <Text style={[formStyles.formLabel, styles.formLabel]}>
                お問い合わせ内容
              </Text>
            </View>
            <View style={styles.confirm}>
              <Text style={styles.confirmText}>{content}</Text>
            </View>
          </View>
          <View style={styles.note}>
            <Text style={{color: '#f23406', fontSize: 11, fontWeight: 'bold'}}>
              <AlertIcon color={'#f23406'} size={11} />
              ご注意
            </Text>
            <Text
              style={{
                color: '#f23406',
                fontSize: 11,
                marginVertical: 8,
                textAlign: 'center',
              }}>
              {selectedCategory.id === 3
                ? ''
                : selectedCategory.id === 4
                ? '必ず最新版のブラウザに更新の上でお問い合わせください。\n不具合以外のお問い合わせについてはお返事いたしかねます。'
                : selectedCategory.id === 7
                ? 'サービスへのご要望はありがたく頂戴し、全社に共有の上で\n検討いたしますが、必ず実装できるとは限りません。\nまた、要望へのお返事はしておりません。'
                : 'お問い合わせ内容は全体に公開されます。\n個人情報等は記載しないようお願いいたします。\nまた、公開されたお問い合わせは削除できません。'}
            </Text>
            <CheckButton
              style={{paddingHorizontal: 24}}
              checked={checked}
              onPress={onPressCheck}>
              上記の通り理解いたしました
            </CheckButton>
          </View>
          <View style={styles.buttons}>
            {CONCACT_CATEGORY_IDS.includes(selectedCategory.id) ? (
              <>
                <PrimaryButton
                  style={styles.button}
                  buttonSize={{
                    width: 311,
                    height: 48,
                  }}
                  onPress={onPressSubmit}
                  disabled={!checked}>
                  お問い合わせを送信
                </PrimaryButton>
              </>
            ) : (
              <>
                <DefaultOutlineButton
                  buttonSize={{
                    width: 167,
                    height: 46,
                  }}
                  style={styles.button}
                  onPress={onPressBack}>
                  戻る
                </DefaultOutlineButton>
                <PrimaryButton
                  style={styles.button}
                  buttonSize={{
                    width: 167,
                    height: 46,
                  }}
                  onPress={onPressSubmit}
                  disabled={!checked}>
                  皆に質問する
                </PrimaryButton>
              </>
            )}
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default React.memo(Confirm);

const styles = StyleSheet.create({
  formLabel: {
    color: '#383838',
    fontWeight: 'bold',
  } as TextStyle,
  button: {
    marginHorizontal: 8,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
  } as ViewStyle,
  confirm: {
    marginTop: 3,
  } as TextStyle,
  confirmText: {
    color: '#383838',
    fontSize: 14,
    lineHeight: Platform.select({
      android: 22,
      ios: 18,
    }),
  } as TextStyle,
  container: {
    backgroundColor: 'white',
  } as ViewStyle,
  headline: {
    marginBottom: -5,
  } as ViewStyle,
  headlineText: {
    color: colors.textColor,
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  message: {
    flexDirection: 'row',
    marginBottom: 8,
  } as ViewStyle,
  messageText: {
    color: colors.textColor,
    fontSize: 13,
    lineHeight: Platform.select({
      android: 20,
      ios: 16,
    }),
  } as TextStyle,
  messageWrapper: {
    marginBottom: -8,
  } as ViewStyle,
  note: {
    backgroundColor: '#fafafa',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    margin: 16,
    marginTop: 32,
  } as ViewStyle,
});
