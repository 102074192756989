import * as React from 'react';

import EpisodeInfo from './EpisodeInfo';

import Episode from '../../../../domain/entities/Episode';

interface Props {
  episode: Episode;
  copyLabel?: string | null;
  onOpenActionSheet?: (episode: Episode) => void;
}

const EpisodeListItemContent: React.FunctionComponent<Props> = props => {
  const {episode, copyLabel, onOpenActionSheet} = props;
  return (
    <EpisodeInfo
      episode={episode}
      copyLabel={copyLabel}
      onOpenActionSheet={onOpenActionSheet}
    />
  );
};

export default React.memo(EpisodeListItemContent);
