import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions from '../../../../containers/scene_form/speech_text_show_scene_command/new/speech_text_positions/Index'; // tslint:disable-next-line:max-line-length
import SceneFormSpeechTextShowSceneCommandNewSpeechTexts from '../../../../containers/scene_form/speech_text_show_scene_command/new/speech_texts/Index'; // tslint:disable-next-line:max-line-length

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions">
      <RootStack.Screen
        name="SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions"
        component={SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions}
        options={{title: '吹き出しの向き'}}
      />
      <RootStack.Screen
        name="SceneFormSpeechTextShowSceneCommandNewSpeechTexts"
        component={SceneFormSpeechTextShowSceneCommandNewSpeechTexts}
        options={{title: 'セリフ内容'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
