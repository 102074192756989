import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Scene from '../../../domain/entities/Scene';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetScenesRepository from '../../../data/repositories/writer/NetScenesRepository';

const types = {
  failure: Types.IndexScenesFailure,
  request: Types.IndexScenesRequest,
  success: Types.IndexScenesSuccess,
};

export interface Params extends BaseParams {
  episodeId: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Scene>> {
  return indexGenerics(new NetScenesRepository(), types, params);
}
