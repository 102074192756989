import * as React from 'react';

import BaseIndex from '../../shared/generics/background_show_scene_command/base_backgrounds/Index';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormBaseBackgroundsRouteProp} from '../../../navigators/RouteProps';

import {Params as BaseBackgroundIndexParams} from '../../../actions/base_backgrounds/index';

import {QueryState} from '../../../reducers/queries/Response';

import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import BackgroundCategory from '../../../../domain/entities/BackgroundCategory';
import BaseBackground from '../../../../domain/entities/BaseBackground';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  query?: string;
  backgroundCategoryId?: number;
  selectedLargeBackgroundCategoryId?: number | null;
  selectedMiddleBackgroundCategoryId?: number | null;
  selectedSmallBackgroundCategoryId?: number | null;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormBaseBackgroundsRouteProp;
  coverImageForm: CoverImageForm | null;
  baseBackgroundsParams: BaseBackgroundIndexParams;
  baseBackgrounds: BaseBackground[] | null;
  backgroundCategory: BackgroundCategory | null;
  baseBackgroundQueries: QueryState;
  totalCount: number | null;
}

export interface DispatchProps {
  indexBaseBackgrounds: (
    params: BaseBackgroundIndexParams,
  ) => Promise<PaginatedResult<BaseBackground>>;
  showBackgroundCategory: (id: number) => Promise<BackgroundCategory>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {route} = this.props;
    const {sort, favorite} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        sort={sort}
        favorite={favorite}
        onPressSearchConditionChangeButton={
          this.handlePressSearchConditionChangeButton
        }
        onSelectBaseBackground={this.handleSelectBaseBackground}
      />
    );
  }

  private handlePressSearchConditionChangeButton = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      query,
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId,
      favorite,
    } = route.params;
    navigation.navigate('CoverImageBaseBackgroundSearchFormNavigation', {
      screen: 'CoverImageFormBaseBackgroundSearchForm',
      params: {
        storyId,
        query,
        selectedLargeBackgroundCategoryId,
        selectedMiddleBackgroundCategoryId,
        selectedSmallBackgroundCategoryId,
        hideBack: true,
        favorite,
      },
    } as any);
  };

  private handleSelectBaseBackground = (baseBackground: BaseBackground) => {
    const {navigation, route} = this.props;
    const baseBackgroundId = baseBackground.id;
    const {storyId, aspectRatio, imageKey} = route.params;
    navigation.navigate('CoverImageFormBackgrounds', {
      baseBackgroundId,
      storyId,
      aspectRatio,
      imageKey,
    });
  };
}
