import React from 'react';
import {
  Linking,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ScaleModal from '../../../../modals/ScaleModal';
import ShopIcon from '../../../../icons/ShopIcon';
import DimensionContext from '../../../../dimension/DimensionContext';

import LinkButton from '../../../../buttons/LinkButton';
import PrimaryButton from '../../../../buttons/PrimaryButton';

interface Props {
  actorId: number;
  onRequestClose: () => void;
}

const PurchaseModal: React.FC<Props> = props => {
  const {actorId, onRequestClose} = props;
  const onPress = React.useCallback(() => {
    onRequestClose();
    Linking.openURL(`/shop/actors/${actorId}?disableNavigation=true`);
  }, [actorId]);
  const onPressCancel = React.useCallback(() => {
    onRequestClose();
  }, [actorId]);
  return (
    <DimensionContext.Consumer>
      {context => (
        <ScaleModal
          style={{width: context.content.width * 0.82}}
          visible={true}>
          <View style={styles.container}>
            <Text style={styles.title}>
              こちらのキャラクターは{'\n'}
              ショップで購入できます。
            </Text>
            <ShopIcon size={72} color={'#ff8f13'} />
            <Text style={styles.message}>
              シーンを登録してショップ{'\n'}
              へ移動しますか？
            </Text>
            <View style={styles.buttons}>
              <LinkButton
                style={{width: 100, height: 46, marginRight: 8}}
                onPress={onPressCancel}>
                キャンセル
              </LinkButton>
              <PrimaryButton style={{width: 160, height: 46}} onPress={onPress}>
                ショップへ移動する
              </PrimaryButton>
            </View>
          </View>
        </ScaleModal>
      )}
    </DimensionContext.Consumer>
  );
};

export default React.memo(PurchaseModal);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 32,
  } as ViewStyle,
  title: {
    color: '#ff8f13',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 24,
  } as TextStyle,
  message: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 32,
  } as TextStyle,
  buttons: {
    flexDirection: 'row',
  } as ViewStyle,
});
