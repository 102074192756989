import EntityMapper from '../EntityMapper';

import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

export interface CurrentUserStatusData {
  id: number;
  fanned_consumer_users_count: number;
  published_stories_count: number;
  notifications_count: number;
  unconfirmed_notifications_count: number;
  response_story_aggregations_count: number;
  entry_contests_count: number;
  transfers_count: number;
  enabled_consumer_user: boolean;
  enabled_creator_user: boolean;
  last_notified_at: string | null;
  last_notification_confirmed_at: string | null;
  extension_codes: Array<string> | null;
  service_agreement_codes: Array<string> | null;
  first_published_at: string | null;
}

export default class CurrentUserMapper
  implements EntityMapper<CurrentUserStatusData, CurrentUserStatus>
{
  public map(obj: CurrentUserStatusData): CurrentUserStatus {
    return new CurrentUserStatus(
      obj.id,
      obj.fanned_consumer_users_count,
      obj.published_stories_count,
      obj.notifications_count,
      obj.unconfirmed_notifications_count,
      obj.response_story_aggregations_count,
      obj.entry_contests_count,
      obj.transfers_count,
      obj.enabled_consumer_user,
      obj.enabled_creator_user,
      obj.extension_codes || [],
      obj.service_agreement_codes || [],
      obj.last_notified_at ? new Date(obj.last_notified_at) : null,
      obj.last_notification_confirmed_at
        ? new Date(obj.last_notification_confirmed_at)
        : null,
      obj.first_published_at ? new Date(obj.first_published_at) : null,
    );
  }
}
