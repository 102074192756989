import EntityMapper from '../EntityMapper';

import ActorFacePart from '../../../../domain/entities/character_maker/ActorFacePart';

export interface ActorFacePartData {
  id: number;
  actor_id: number;
  face_pattern_id: number;
  face_pattern_name: string;
  color_pattern_id: number;
  color_pattern_name: string;
  actor_name: string;
  actor_original_image_url: string;
  original_image_url: string;
}

export default class ActorFaceMapper
  implements EntityMapper<ActorFacePartData, ActorFacePart>
{
  public map(obj: ActorFacePartData): ActorFacePart {
    return new ActorFacePart(
      obj.id,
      obj.actor_id,
      obj.face_pattern_id,
      obj.face_pattern_name,
      obj.color_pattern_id,
      obj.color_pattern_name,
      obj.actor_name,
      obj.actor_original_image_url,
      obj.original_image_url,
    );
  }
}
