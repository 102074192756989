import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../../../shared/Layout';
import AlertModal from '../../../../shared/modals/AlertModal';

import * as routers from '../../../../../routers';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustrationRouteProp,
  SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustrationRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as FullScreenIllustrationIndexParams} from '../../../../../actions/full_screen_illustrations/index';
import {Params as FullScreenIllustrationCreateParams} from '../../../../../actions/full_screen_illustrations/create';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';
import {formatErrorMessages} from '../../../../../helpers/errorMessages';

import Sound from '../../../../../../domain/entities/Sound';
import File from '../../../../../../domain/entities/File';
import FullScreenIllustration from '../../../../../../domain/entities/FullScreenIllustration';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustrationRouteProp
    | SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustrationRouteProp;
  sceneForm: SceneForm | null;
  fullScreenIllustrationsParams: FullScreenIllustrationIndexParams;
  fullScreenIllustrations: FullScreenIllustration[] | null;
}

export interface DispatchProps {
  indexFullScreenIllustrations: (
    params: FullScreenIllustrationIndexParams,
  ) => Promise<PaginatedResult<FullScreenIllustration>>;
  createFullScreenIllustration: (
    params: FullScreenIllustrationCreateParams,
  ) => Promise<FullScreenIllustration>;
  onSelectFullScreenIllustration: (
    fullScreenIllustration: FullScreenIllustration,
    sound: Sound | null,
  ) => void;
  onForwardFullScreenIllustrations?: () => void;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string | null;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      fullScreenIllustrationsParams,
      indexFullScreenIllustrations,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    indexFullScreenIllustrations(fullScreenIllustrationsParams);
  }

  public render(): React.ReactNode {
    const {
      navigation,
      fullScreenIllustrations,
      onForwardFullScreenIllustrations,
    } = this.props;
    const {loading, alertMessage} = this.state;
    const title = 'スチル';
    return (
      <Layout
        title={title}
        navigation={navigation}
        close={true}
        loading={loading}>
        <Form
          onSubmit={this.handleSubmit}
          onForwardFullScreenIllustrations={
            fullScreenIllustrations && fullScreenIllustrations.length > 0
              ? onForwardFullScreenIllustrations
              : undefined
          }
          addSound={this.handleAddSound}
        />
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => this.setState({alertMessage: null})}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleSubmit = (image: File, sound: Sound | null) => {
    const {
      route,
      createFullScreenIllustration,
      onSelectFullScreenIllustration,
    } = this.props;
    const {storyId} = route.params;
    this.setState({loading: true}, () => {
      createFullScreenIllustration({
        image,
        storyId,
        uploadedSelfMaterial: true,
      })
        .then(fullScreenIllustration => {
          onSelectFullScreenIllustration(fullScreenIllustration, sound);
          this.setState({loading: false});
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private handleAddSound = (callback: (sound: Sound) => void) => {
    const {navigation, route} = this.props;
    const params = route.params;
    routers.linkToSceneFormSoundEffectShowSceneCommandNewNavigations(
      navigation,
      {
        storyId: params.storyId,
        episodeId: params.episodeId,
        sceneId: params.sceneId,
        back: true,
        callback,
      },
    );
  };
}
