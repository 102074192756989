import {Dispatch} from 'redux';

import Types from '../../../Types';

import NetCurrentUserRepository from '../../../../../data/repositories/writer/NetCurrentUserRepository';

import {sendDefaultCustomEvent, setUser} from '../../../../helpers/analytics';

import File from '../../../../../domain/entities/File';
import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

import RegistrationRepository from '../../../../../data/repositories/writer/users/NetRegistrationRepository';

export interface Params {
  penName?: string | null;
  email?: string | null;
  userProfileAttributes?: {
    image?: File | null;
    description?: string | null;
    homePageUrl?: string | null;
    twitterAccountUrl?: string | null;
    youtubeChannelUrl?: string | null;
    birthday?: string | null;
  };
  currentPassword?: string | null;
  password?: string | null;
  passwordConfirmation?: string | null;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<CurrentUser> {
  return async (dispatch: Dispatch<any>): Promise<CurrentUser> => {
    dispatch({type: Types.ShowWriterCurrentUserRequest});
    try {
      const oldCurrentUser = await new NetCurrentUserRepository().find();
      const payload = await new RegistrationRepository().update(
        {apiWriterUser: params},
        {multipart: true},
      );
      dispatch({payload, type: Types.ShowWriterCurrentUserSuccess});
      setUser(payload);
      sendDefaultCustomEvent({
        resourceType: 'writer/user',
        resourceId: payload.id,
        resourceName: payload.penName,
        actionName:
          !oldCurrentUser.enabled && payload.enabled ? 'create' : 'update',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.ShowWriterCurrentUserFailure});
      throw err;
    }
  };
}
