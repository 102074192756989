import {QueryState, Response} from '../reducers/queries/Response';

export const selectEntities = <T>(
  idToEntity: {[key: number]: T},
  queryToResponse: QueryState,
  params: {[key: string]: any},
): T[] | null => {
  return selector(idToEntity, queryToResponse, params, true);
};

export default selectEntities;

export const selectEntitiesOnlyPage = <T>(
  idToEntity: {[key: number]: T},
  queryToResponse: QueryState,
  params: {[key: string]: any},
): T[] | null => {
  return selector(idToEntity, queryToResponse, params, false);
};

export const getNextPage = <T>(
  queryToResponse: QueryState,
  params: {[key: string]: any},
): number | null => {
  const response = getResponse(queryToResponse, params);
  if (!response) {
    return null;
  }
  return response.nextPage || null;
};

export const getTotalCount = <T>(
  queryToResponse: QueryState,
  params: {[key: string]: any},
): number | null => {
  const response = getResponse(queryToResponse, params);
  if (!response) {
    return null;
  }
  return response.total || null;
};

export const getResponse = <T>(
  queryToResponse: QueryState,
  params: {[key: string]: any},
): Response | null => {
  if (!queryToResponse) {
    return null;
  }
  const key = generateKey(params);
  const response = queryToResponse[key];
  if (!response) {
    return null;
  }
  return response;
};

const selector = <T>(
  idToEntity: {[key: number]: T},
  queryToResponse: QueryState,
  params: {[key: string]: any},
  total: boolean,
): T[] | null => {
  if (!queryToResponse) {
    return null;
  }
  const paramsPage = params.page || 1;
  const key = generateKey(params);
  const response = queryToResponse[key];
  if (!response || !response.pageToIds) {
    return null;
  }
  const entities: T[] = [];
  if (total) {
    const pages = Object.keys(response.pageToIds);
    pages
      .sort((a, b) => Number(a) - Number(b))
      .forEach(page => {
        response.pageToIds[Number(page)].forEach(id => {
          entities.push(idToEntity[id]);
        });
      });
    return entities;
  } else {
    if (!response.pageToIds[paramsPage]) {
      return null;
    }
    response.pageToIds[paramsPage].forEach(id => {
      entities.push(idToEntity[id]);
    });
    return entities;
  }
};

const generateKey = (params: {[key: string]: any}) => {
  const paramsWithoutPage = {} as {[key: string]: any};
  Object.keys(params)
    .sort()
    .forEach(paramsKey => {
      if (paramsKey !== 'page') {
        paramsWithoutPage[paramsKey] = params[paramsKey];
      }
    });
  return JSON.stringify(paramsWithoutPage);
};
