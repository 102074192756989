import {TextStyle, ViewStyle} from 'react-native';

import {camelizeKeys} from 'humps';

import EntityMapper from './EntityMapper';

import TextFramePartMapper, {TextFramePartData} from './TextFramePartMapper';

import TextFrame from '../../../domain/entities/TextFrame';

export interface TextFrameData {
  id: number;
  original_image_url: string;
  caption: boolean;
  text_frame_parts: TextFramePartData[];
  container_style: {[key: string]: any};
  text_style: {[key: string]: any};
  voice_icon_style?: {[key: string]: any};
}

export default class TextFrameMapper
  implements EntityMapper<TextFrameData, TextFrame>
{
  private textFramePartMapper: TextFramePartMapper;

  constructor() {
    this.textFramePartMapper = new TextFramePartMapper();
  }

  public map(obj: TextFrameData): TextFrame {
    const textFrameParts = obj.text_frame_parts.map(textFramePartObj => {
      return this.textFramePartMapper.map(textFramePartObj);
    });
    const containerStyle = camelizeKeys(obj.container_style) as ViewStyle;
    const textStyle = camelizeKeys(obj.text_style) as TextStyle;
    const voiceIconStyle = obj.voice_icon_style
      ? (camelizeKeys(obj.voice_icon_style) as ViewStyle)
      : undefined;
    return new TextFrame(
      obj.id,
      obj.original_image_url,
      obj.caption,
      textFrameParts,
      containerStyle,
      textStyle,
      voiceIconStyle,
    );
  }
}
