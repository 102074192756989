import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Character from '../../../domain/entities/Character';
import NameLabelColor from '../../../domain/value_objects/NameLabelColor';

import NetCharactersRepository from '../../../data/repositories/writer/NetCharactersRepository';

export interface Params {
  storyId: number;
  actorCharacterId: number;
  actorCharacterFaceId?: number;
  name: string;
  description?: string;
  nameLabelColor?: NameLabelColor | null;
  voiceActorName?: string | null;
  inverted?: boolean;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Character> {
  return async (dispatch: Dispatch<any>): Promise<Character> => {
    try {
      const payload = await new NetCharactersRepository().create({
        character: params,
      });
      dispatch({payload, type: Types.CreateCharacterSuccess});
      sendDefaultCustomEvent({
        resourceType: 'character',
        resourceId: payload.id,
        resourceName: payload.name,
        actionName: 'create',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateCharacterFailure});
      throw err;
    }
  };
}
