import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import EpisodeList from '../episodes/partials/EpisodeList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {FullVideoStoryRouteProp} from '../../../navigators/RouteProps';

import Episode from '../../../../domain/entities/writer/full_video/Episode';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  storyId: number;
  orientation?: 'vertical' | 'horizontal';
}

export interface StateProps {
  navigation: NavigationProp;
  route: FullVideoStoryRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {storyId, orientation} = route.params;
  const [episodes, setEpisodes] = React.useState<Array<Episode> | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelRestApi.get('/api/writer/full_video/episodes', {storyId}).then(
        response => {
          setEpisodes(camelizeKeys(response.body as any) as Array<Episode>);
        },
      );
    }, []),
  );
  const onSelectEpisode = React.useCallback((episode: Episode) => {
    navigation.navigate('NewFullVideoExportJobExecution', {
      episodeId: episode.id,
      orientation,
    });
  }, []);

  return (
    <Layout
      title={'動画化するエピソードを選択'}
      navigation={navigation}
      back={true}>
      <EpisodeList episodes={episodes} onSelectEpisode={onSelectEpisode} />
    </Layout>
  );
};

export default React.memo(Show);
