import * as React from 'react';
import {View} from 'react-native';
import CharacterView from './partials/CharacterView';
import Tabs, {Value} from './partials/Tabs';
import EntityList from './partials/EntityList';

import Layout from '../../../Layout';
import HeaderRightButton from '../../../buttons/HeaderRightButton';
import InversionButton from '../../../InversionButton';

import ResourceFavoriteButton from '../../../ResourceFavoriteButton';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import NavigationProp from '../../../../../navigators/NavigationProp';

import {Params as ActorCostumeIndexParams} from '../../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessoryIndexParams} from '../../../../../actions/actor_accessories/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../../actions/actor_accessory_sets/index';

import scrollableLayout from '../../../../../helpers/scrollableLayout';

import Actor from '../../../../../../domain/entities/Actor';
import ActorCostume from '../../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../../domain/entities/ActorHairStyle';
import ActorAccessory from '../../../../../../domain/entities/ActorAccessory';
import ActorAccessorySet from '../../../../../../domain/entities/ActorAccessorySet';
import ActorCharacter from '../../../../../../domain/entities/ActorCharacter';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

interface Props {
  navigation: NavigationProp;
  actorId: number;
  actor: Actor | null;
  enabledFavorite?: boolean;
  onSelectActorCharacter: (
    actorCharacer: ActorCharacter,
    inverted: boolean,
  ) => void;
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessories: (
    params: ActorAccessoryIndexParams,
  ) => Promise<PaginatedResult<ActorAccessory>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
}

interface State {
  selectedActorCharacter: ActorCharacter | null;
  activeTab: Value;
  actorCostumes: ActorCostume[] | null;
  actorHairStyles: ActorHairStyle[] | null;
  actorAccessorySets: ActorAccessorySet[] | null;
  inverted: boolean;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedActorCharacter: props.actor ? props.actor.actorCharacter : null,
      activeTab: 'costume',
      actorCostumes: null,
      actorHairStyles: null,
      actorAccessorySets: null,
      inverted: false,
    };
  }

  public componentDidMount() {
    const {
      actorId,
      indexActorCostumes,
      indexActorHairStyles,
      indexActorAccessorySets,
    } = this.props;

    indexActorCostumes({actorId}).then(actorCostumesResult => {
      this.setState({actorCostumes: actorCostumesResult.records});
      const actorCostume = actorCostumesResult.records[0];
      if (actorCostume) {
        indexActorHairStyles({
          actorId,
          actorCostumeId: actorCostume.actorCharacter.actorCostumeId,
          actorAccessorySetId: actorCostume.actorCharacter.actorAccessorySetId,
        }).then(actorHairStylesResult => {
          this.setState({
            actorHairStyles: actorHairStylesResult.records,
            activeTab:
              actorCostumesResult.records.length < 2 &&
              actorHairStylesResult.records.length > 1
                ? 'hair_style'
                : this.state.activeTab,
          });
          indexActorAccessorySets({
            actorId,
            actorCostumeId: actorCostume.actorCharacter.actorCostumeId,
            actorHairStyleId: actorCostume.actorCharacter.actorHairStyleId,
          }).then(actorAccessorySetsResult => {
            this.setState({
              actorAccessorySets: actorAccessorySetsResult.records,
              activeTab:
                actorCostumesResult.records.length < 2 &&
                actorHairStylesResult.records.length < 2 &&
                actorAccessorySetsResult.records.length > 1
                  ? 'actor_accessory'
                  : this.state.activeTab,
            });
          });
        });
      }
    });
  }

  public render(): React.ReactNode {
    const {navigation, actor, actorId, enabledFavorite} = this.props;
    const {
      activeTab,
      actorCostumes,
      actorHairStyles,
      actorAccessorySets,
      selectedActorCharacter,
    } = this.state;
    return (
      <DimensionContext.Consumer>
        {context => {
          const scrollable = scrollableLayout(context);
          return (
            <Layout
              title={
                activeTab === 'costume'
                  ? '衣装'
                  : activeTab === 'hair_style'
                  ? '髪色'
                  : activeTab === 'actor_accessory'
                  ? 'アクセサリー'
                  : ''
              }
              scrollable={scrollable}
              navigation={navigation}
              back={true}
              rightButton={{
                tintColor: 'white',
                title: (
                  <HeaderRightButton onPress={this.handleSubmit}>
                    次へ
                  </HeaderRightButton>
                ),
              }}>
              <>
                <View>
                  <CharacterView
                    actorCharacter={selectedActorCharacter}
                    width={context.content.width}
                    inverted={this.state.inverted}
                  />
                  {enabledFavorite && (
                    <ResourceFavoriteButton
                      resourceType={'Actor'}
                      resourceId={actorId}
                    />
                  )}
                  {actor?.options.allowedInversion ? (
                    <InversionButton
                      onPress={() => {
                        this.setState({inverted: !this.state.inverted});
                      }}
                    />
                  ) : null}
                </View>
                {actorCostumes && actorHairStyles && actorAccessorySets && (
                  <>
                    <Tabs
                      activeTab={activeTab}
                      visibleActorCostume={actorCostumes.length > 1}
                      visibleActorHairStyle={actorHairStyles.length > 1}
                      visibleActorAccessory={actorAccessorySets.length > 1}
                      onChangeTab={this.switchTab}
                    />
                    <EntityList
                      entities={actorCostumes}
                      visible={activeTab === 'costume'}
                      selectedEntityId={
                        selectedActorCharacter?.actorCostumeId || null
                      }
                      clipped={false}
                      width={context.content.width}
                      inverted={this.state.inverted}
                      scrollEnabled={!scrollable}
                      onSelectActorCharacter={this.handleSelectActorCharacter}
                    />
                    <EntityList
                      entities={actorHairStyles}
                      visible={activeTab === 'hair_style'}
                      selectedEntityId={
                        selectedActorCharacter?.actorHairStyleId || null
                      }
                      clipped={true}
                      width={context.content.width}
                      inverted={this.state.inverted}
                      scrollEnabled={!scrollable}
                      onSelectActorCharacter={this.handleSelectActorCharacter}
                    />
                    <EntityList
                      entities={actorAccessorySets}
                      visible={activeTab === 'actor_accessory'}
                      selectedEntityId={
                        selectedActorCharacter?.actorAccessorySetId || null
                      }
                      clipped={false}
                      width={context.content.width}
                      inverted={this.state.inverted}
                      scrollEnabled={!scrollable}
                      onSelectActorCharacter={this.handleSelectActorCharacter}
                    />
                  </>
                )}
              </>
            </Layout>
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private switchTab = (activeTab: Value) => {
    const {indexActorCostumes, indexActorHairStyles, indexActorAccessorySets} =
      this.props;
    const {selectedActorCharacter} = this.state;
    if (this.state.activeTab === activeTab || !selectedActorCharacter) {
      return;
    }
    this.setState({activeTab});
    switch (activeTab) {
      case 'costume':
        indexActorCostumes({
          actorId: selectedActorCharacter.actorId,
          actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
          actorHairStyleId: selectedActorCharacter.actorHairStyleId,
        }).then(result => {
          this.setState({actorCostumes: result.records});
        });
        break;
      case 'hair_style':
        indexActorHairStyles({
          actorId: selectedActorCharacter.actorId,
          actorCostumeId: selectedActorCharacter.actorCostumeId,
          actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
        }).then(result => {
          this.setState({actorHairStyles: result.records});
        });
        break;
      case 'actor_accessory':
        indexActorAccessorySets({
          actorId: selectedActorCharacter.actorId,
          actorCostumeId: selectedActorCharacter.actorCostumeId,
          actorHairStyleId: selectedActorCharacter.actorHairStyleId,
        }).then(result => {
          this.setState({actorAccessorySets: result.records});
        });
        break;
    }
  };

  private handleSelectActorCharacter = (
    selectedActorCharacter: ActorCharacter,
  ) => {
    this.setState({
      selectedActorCharacter,
    });
  };

  private handleSubmit = () => {
    const {onSelectActorCharacter} = this.props;
    const {selectedActorCharacter, inverted} = this.state;
    if (selectedActorCharacter) {
      onSelectActorCharacter(selectedActorCharacter, inverted);
    }
  };
}
