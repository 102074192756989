import * as React from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import EditableLaterMessage from '../../shared/EditableLaterMessage';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import Input from '../../shared/forms/Input';
import LabelWithOption from '../../shared/forms/LabelWithOption';

import {formStyles} from '../../../styles/variables';

interface Props {
  title: string;
  onSubmit?: (params: {title: string}) => void;
}

interface State {
  title: string;
}

export default class InfoForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const {title} = this.props;
    this.state = {title};
  }

  public render(): React.ReactNode {
    const {title} = this.state;
    return (
      <View style={styles.container}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={`シーンタイトル（最大${MAX_TITLE_SIZE}文字）`}
            length={title.length}
            maxLength={MAX_TITLE_SIZE}
          />
          <Input
            style={this.validTitle() ? null : styles.inputError}
            value={title}
            onChangeText={this.handleChangeText}
          />
        </View>
        <View style={styles.button}>
          <EditableLaterMessage />
          <PrimaryButton
            disabled={this.disabledButton()}
            onPress={this.handlePress}>
            決定
          </PrimaryButton>
        </View>
      </View>
    );
  }

  private handlePress = () => {
    const {onSubmit} = this.props;
    const {title} = this.state;
    if (!onSubmit) {
      return;
    }
    onSubmit({title});
  };

  private handleChangeText = (title: string) => {
    this.setState({title});
  };

  private disabledButton = (): boolean => {
    if (!this.validTitle()) {
      return true;
    }
    return false;
  };

  private validTitle = () => {
    const {title} = this.state;
    return title.length <= MAX_TITLE_SIZE;
  };
}

const MAX_TITLE_SIZE = 20;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginBottom: 12,
    marginTop: 20,
  } as ViewStyle,
  inputError: {
    color: '#f23406',
  } as TextStyle,
});
