import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import CharacterIcon from './icons/CharacterIcon';

interface Props {
  defaultLayerOrder: 'background' | 'foreground';
  onChangeLayerOrder: (layerOrder: 'background' | 'foreground') => void;
}

const BackgroundEffectLayerOrderSwitchButton: React.FunctionComponent<
  Props
> = props => {
  const {defaultLayerOrder, onChangeLayerOrder} = props;
  const [layerOrder, setLayerOrder] = React.useState<
    'background' | 'foreground'
  >(defaultLayerOrder);
  const onPressBackground = React.useCallback(() => {
    setLayerOrder('background');
    onChangeLayerOrder('background');
  }, []);
  const onPressForeground = React.useCallback(() => {
    setLayerOrder('foreground');
    onChangeLayerOrder('foreground');
  }, []);
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={[
          styles.button,
          layerOrder === 'background'
            ? styles.buttonActive
            : styles.buttonInactive,
        ]}
        onPress={onPressBackground}>
        <Text
          style={[
            styles.buttonLabel,
            layerOrder === 'background'
              ? styles.buttonActiveLabel
              : styles.buttonInactiveLabel,
          ]}>
          後
        </Text>
      </TouchableOpacity>
      <View
        style={{height: 30, justifyContent: 'center', alignItems: 'center'}}>
        <CharacterIcon size={18} color={'#666666'} />
      </View>
      <TouchableOpacity
        style={[
          styles.button,
          layerOrder === 'background'
            ? styles.buttonInactive
            : styles.buttonActive,
        ]}
        onPress={onPressForeground}>
        <Text
          style={[
            styles.buttonLabel,
            layerOrder === 'background'
              ? styles.buttonInactiveLabel
              : styles.buttonActiveLabel,
          ]}>
          前
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(BackgroundEffectLayerOrderSwitchButton);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    right: 8,
    bottom: 8,
    backgroundColor: 'rgba(250, 250, 250, 0.9)',
    borderRadius: 90,
  } as ViewStyle,
  button: {
    margin: 3,
    width: 30,
    height: 30,
    borderRadius: 15,
    borderWidth: 1,
  } as ViewStyle,
  buttonActive: {
    borderColor: '#ff8f13',
    backgroundColor: '#fff',
  } as ViewStyle,
  buttonInactive: {
    borderColor: 'rgba(250, 250, 250, 0)',
    borderWidth: 1,
  } as ViewStyle,
  buttonLabel: {
    width: 30,
    height: 30,
    fontSize: 12,
    lineHeight: 30,
    fontWeight: 'bold',
    textAlign: 'center',
    textAlignVertical: 'center',
  } as TextStyle,
  buttonActiveLabel: {
    color: '#ff8f13',
  } as TextStyle,
  buttonInactiveLabel: {
    color: '#999',
  } as TextStyle,
});
