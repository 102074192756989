import * as React from 'react';
import {
  ImageStyle,
  Text,
  TextStyle,
  TouchableOpacity,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import CommentDetail from '../../topic_comments/partials/CommentDetail';
import CommentLikeButton from './CommentLikeButton';
import CommentReplyLikeButton from './CommentReplyLikeButton';

import MemberImage from '../../members/partials/MemberImage';

import {formatElapsedTime} from '../.../../../../../helpers/times';

import ProjectTopic from '../../../../../domain/entities/ProjectTopic';
import ProjectTopicComment from '../../../../../domain/entities/ProjectTopicComment';
import ProjectTopicCommentLike from '../../../../../domain/entities/ProjectTopicCommentLike';
import ProjectTopicCommentReplyLike from '../../../../../domain/entities/ProjectTopicCommentReplyLike';

interface Props {
  topic: ProjectTopic;
  comment: ProjectTopicComment;
  commentLike: ProjectTopicCommentLike | null;
  commentReplyLike: ProjectTopicCommentReplyLike | null;
  topicOwner?: boolean;
}

const CommentListItem: React.FC<Props> = props => {
  const {topic, comment, commentLike, commentReplyLike, topicOwner} = props;
  const linkTo = useLinkTo();
  const member = comment.projectMember;
  const onPressReply = React.useCallback(() => {
    linkTo(
      `/projects/${topic.projectId}/topics/${topic.id}/comments/${comment.id}/reply/new`,
    );
  }, []);
  return (
    <View style={styles.container}>
      <CommentDetail comment={comment}>
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: 8,
          }}>
          {!comment.projectTopicCommentReply && topicOwner ? (
            <TouchableOpacity style={{marginRight: 16}} onPress={onPressReply}>
              <Text style={{color: '#999', fontSize: 11}}>返信する</Text>
            </TouchableOpacity>
          ) : null}
          <CommentLikeButton comment={comment} commentLike={commentLike} />
        </View>
        {comment.projectTopicCommentReply ? (
          <View style={{flexDirection: 'row'}}>
            <View style={styles.imageWrapper}>
              <MemberImage member={member} imageSize={40} />
            </View>
            <View style={styles.right}>
              <View style={styles.rightTop}>
                <Text style={styles.penName}>
                  {
                    comment.projectTopicCommentReply.projectMember.writerUser
                      .penName
                  }
                </Text>
                {comment.projectTopicCommentReply.projectMember.leader ? (
                  <View style={styles.leaderLabel}>
                    <Text style={styles.leaderText}>リーダー</Text>
                  </View>
                ) : null}
              </View>
              <Text style={styles.date}>
                {formatElapsedTime(
                  new Date(comment.projectTopicCommentReply.createdAt),
                )}
              </Text>
              <View style={styles.triangle} />
              <View style={styles.commentFrame}>
                <Text style={styles.comment}>
                  {comment.projectTopicCommentReply.comment}
                </Text>
              </View>
              <CommentReplyLikeButton
                commentReply={comment.projectTopicCommentReply}
                commentReplyLike={commentReplyLike}
              />
            </View>
          </View>
        ) : null}
      </CommentDetail>
    </View>
  );
};

export default React.memo(CommentListItem);

const IMAGE_SIZE = 50;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 9,
    marginHorizontal: 16,
  } as ViewStyle,
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMAGE_SIZE / 2,
    backgroundColor: '#ffffff',
    height: IMAGE_SIZE,
    overflow: 'hidden',
    width: IMAGE_SIZE,
  } as ViewStyle,
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  } as ImageStyle,
  right: {
    flex: 1,
    paddingTop: 3,
    paddingHorizontal: 10,
  } as ViewStyle,
  rightTop: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  penName: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  leaderLabel: {
    marginLeft: 6,
  } as ViewStyle,
  leaderText: {
    color: '#fff',
    fontSize: 8,
    fontWeight: 'bold',
    backgroundColor: '#ff8f13',
    padding: 2,
  } as TextStyle,
  date: {
    color: '#999',
    fontSize: 10,
    marginVertical: 6,
  } as TextStyle,
  triangle: {
    width: 7,
    borderLeftWidth: 7,
    borderLeftColor: 'transparent',
    borderRightWidth: 7,
    borderRightColor: 'transparent',
    borderBottomWidth: 7,
    borderBottomColor: '#efefef',
    marginLeft: 12,
  } as ViewStyle,
  commentFrame: {
    backgroundColor: '#efefef',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 12,
    paddingHorizontal: 10,
  } as ViewStyle,
  comment: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
});
