import * as React from 'react';
import {
  ImageResizeMode,
  ImageStyle,
  GestureResponderEvent,
  LayoutChangeEvent,
  LayoutRectangle,
  StyleProp,
  ViewStyle,
} from 'react-native';

import EntityModal from './modals/EntityModal';

import {Options} from '../../../domain/helpers/ImageUrlHelper';

const EXTEND_BUTTON_AREA = 10;

export interface Entity {
  id: number;
  getImageUrl(options: Options): string;
}

export interface Props<T extends Entity> {
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  imageMaskStyle?: StyleProp<ViewStyle>;
  modalWidth?: number;
  modalHeight?: number;
  aspectRatio?: number;
  imageAspectRatio?: number;
  resizeMode?: ImageResizeMode;
  onEndReachedThreshold?: number | null;
  frame?: boolean;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  extraData?: any;
  onSelectEntity: (entity: T) => void;
  imageUrlExtractor?: (item: T, width: number) => string;
  labelExtractor?: (item: T) => string | null;
  imageFrameStyleExtractor?: (item: T) => StyleProp<ViewStyle>;
  imageStyleExtractor?: (item: T) => StyleProp<ImageStyle>;
  renderCustomLabel?: (item: T, width: number) => React.ReactNode;
  renderCustom?: (item: T, width: number) => React.ReactNode;
  modalImageUrlExtractor?: (
    item: T,
    width: number,
    aspectRatio: number,
  ) => string;
  modalRenderFavoriteButton?: (item: T) => React.ReactNode;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  disabledItem?: (item: T) => boolean;
}

export interface State<T extends Entity> {
  selectedEntity: T | null;
  longPressed: boolean;
}

export default class SelectableEntityListWithModal<
  P,
  T extends Entity,
> extends React.Component<Props<T> & P, State<T>> {
  private modalLayout: LayoutRectangle | null = null;
  private modalButtonLayout: LayoutRectangle | null = null;

  constructor(props: Props<T> & P) {
    super(props);
    this.state = {
      longPressed: false,
      selectedEntity: null,
    };
  }

  public render(): React.ReactNode {
    const {
      modalWidth,
      modalHeight,
      modalImageUrlExtractor,
      modalRenderFavoriteButton,
    } = this.props;
    const {selectedEntity} = this.state;
    return [
      this.renderEntityList(),
      selectedEntity ? (
        <EntityModal
          key={'EntityModal'}
          entity={selectedEntity}
          onCloseEntityModal={this.handleCloseEntityModal}
          onSelectEntity={this.handleSelectEntity}
          onLayoutModal={this.handleLayoutModal}
          onLayoutButton={this.handleLayoutButton}
          width={modalWidth}
          height={modalHeight}
          imageUrlExtractor={modalImageUrlExtractor}
          renderFavoriteButton={modalRenderFavoriteButton}
        />
      ) : null,
    ];
  }

  protected renderEntityList(): React.ReactNode {
    return null;
  }

  protected handleSelectEntity = (entity: T) => {
    const {onSelectEntity} = this.props;
    this.setState({selectedEntity: null}, () => {
      onSelectEntity(entity);
    });
  };

  protected handleLongPressEntity = (entity: T) => {
    this.setState({longPressed: true, selectedEntity: entity});
  };

  protected handleTouchEnd = (event: GestureResponderEvent) => {
    const {onSelectEntity} = this.props;
    const {selectedEntity} = this.state;
    const {pageX, pageY} = this.getTouchLocation(event);
    this.setState({longPressed: false, selectedEntity: null}, () => {
      if (
        this.modalLayout &&
        this.modalButtonLayout &&
        selectedEntity &&
        pageX &&
        pageY
      ) {
        const {x: baseX, y: baseY} = this.modalLayout;
        const {height, width, x: offsetX, y: offsetY} = this.modalButtonLayout;
        const x = baseX + offsetX;
        const y = baseY + offsetY;
        if (
          x - EXTEND_BUTTON_AREA <= pageX &&
          pageX <= x + width + EXTEND_BUTTON_AREA &&
          y - EXTEND_BUTTON_AREA <= pageY &&
          pageY <= y + height + EXTEND_BUTTON_AREA
        ) {
          onSelectEntity(selectedEntity);
        }
      }
    });
  };

  private handleCloseEntityModal = () => {
    this.setState({selectedEntity: null});
  };

  private handleLayoutModal = (event: LayoutChangeEvent) => {
    this.modalLayout = event.nativeEvent.layout;
  };

  private handleLayoutButton = (event: LayoutChangeEvent) => {
    this.modalButtonLayout = event.nativeEvent.layout;
  };

  private getTouchLocation = (
    event: GestureResponderEvent,
  ): {pageX: number | undefined; pageY: number | undefined} => {
    const {pageX, pageY, touches} = event.nativeEvent;
    if (pageX && pageY) {
      return {pageX, pageY};
    } else if (touches) {
      const foundKey = Object.keys(touches).find(key => {
        const touch = touches[Number(key)];
        const {pageX, pageY} = touch;
        if (pageX && pageY) {
          return {pageX, pageY};
        } else {
          return null;
        }
      });
      if (foundKey) {
        const {pageX, pageY} = touches[Number(foundKey)];
        return {pageX, pageY};
      }
    }
    return {pageX: undefined, pageY: undefined};
  };
}
