import SceneCommand from './SceneCommand';
import CharacterHideSceneCommand from './CharacterHideSceneCommand';
import CharacterShowSceneCommand from './CharacterShowSceneCommand';
import CharacterUpdateSceneCommand from './CharacterUpdateSceneCommand';

import CompositeParallelSceneCommandForm from '../../forms/scene_commands/CompositeParallelSceneCommandForm';
import CharacterHideSceneCommandForm from '../../forms/scene_commands/CharacterHideSceneCommandForm';
import CharacterShowSceneCommandForm from '../../forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../forms/scene_commands/CharacterUpdateSceneCommandForm';

import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class CompositeParallelSceneCommand implements SceneCommand {
  constructor(
    public commands: Array<
      | CharacterHideSceneCommand
      | CharacterShowSceneCommand
      | CharacterUpdateSceneCommand
    >,
  ) {}

  public getValue() {
    return {};
  }

  public toForm(): SceneCommandForm {
    const sceneCommandForms = this.commands.map(command => {
      return command.toForm();
    });
    const filteredSceneCommandForms: Array<
      | CharacterHideSceneCommandForm
      | CharacterShowSceneCommandForm
      | CharacterUpdateSceneCommandForm
    > = [];
    sceneCommandForms.forEach(sceneCommandForm => {
      if (
        sceneCommandForm instanceof CharacterHideSceneCommandForm ||
        sceneCommandForm instanceof CharacterShowSceneCommandForm ||
        sceneCommandForm instanceof CharacterUpdateSceneCommandForm
      ) {
        filteredSceneCommandForms.push(sceneCommandForm);
      }
    });
    return new CompositeParallelSceneCommandForm(filteredSceneCommandForms);
  }
}
