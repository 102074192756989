import * as React from 'react';

import CharacterPatternList from '../../../shared/generics/character_patterns/partials/CharacterPatternList';

import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CharacterFormEditCharacterPatternsRouteProp} from '../../../../navigators/RouteProps';

import {Params as CharacterPatternIndexParams} from '../../../../actions/character_patterns/index';
import {Params as ActorCharacterFaceIndexParams} from '../../../../actions/actor_character_faces/index';
import {Params as CharacterFormUpdateParams} from '../../../../actions/character_forms/update';

import CharacterPattern from '../../../../../domain/entities/CharacterPattern';
import ActorCharacterFace from '../../../../../domain/entities/ActorCharacterFace';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';

export interface Params {
  characterId: number;
  faceName?: string | null;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CharacterFormEditCharacterPatternsRouteProp;
  characterPatterns: CharacterPattern[] | null;
  characterPatternsParams: CharacterPatternIndexParams;
}

export interface DispatchProps {
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  updateCharacterForm: (params: CharacterFormUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {characterPatterns, characterPatternsParams, indexCharacterPatterns} =
      this.props;
    if (!characterPatterns) {
      indexCharacterPatterns(characterPatternsParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, characterPatterns} = this.props;
    return (
      <Layout
        close={true}
        title={'使用したキャラクター差分'}
        navigation={navigation}>
        {characterPatterns && (
          <CharacterPatternList
            characterPatterns={characterPatterns}
            onSelectCharacterPattern={this.handleSelectCharacterPattern}
          />
        )}
      </Layout>
    );
  }

  private handleSelectCharacterPattern = (
    characterPattern: CharacterPattern,
  ) => {
    const {navigation, route, indexActorCharacterFaces, updateCharacterForm} =
      this.props;
    const {faceName} = route.params;
    indexActorCharacterFaces({
      characterPatternId: characterPattern.id,
    }).then(result => {
      const actorCharacterFace =
        result.records.find(record => record.faceName === faceName) ||
        result.records[0];
      updateCharacterForm({
        actorId: characterPattern.actorCharacter.actorId,
        actorCharacterId: characterPattern.actorCharacter.id,
        actorCharacterFaceId: actorCharacterFace?.id,
      });
      navigation.goBack();
    });
  };
}
