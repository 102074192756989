import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import RequestTabs from './partials/RequestTabs';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectRequestsRouteProp} from '../../../navigators/RouteProps';

import ProjectMemberJoinRequest, {
  Status as ProjectMemberJoinRequestStatus,
} from '../../../../domain/entities/ProjectMemberJoinRequest';
import ProjectEpisodeReleaseRequest, {
  Status as ProjectEpisodeReleaseRequestStatus,
} from '../../../../domain/entities/ProjectEpisodeReleaseRequest';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectRequestsRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {projectId} = route.params;
  const [memberJoinRequests, setMemberJoinRequests] = React.useState<
    ProjectMemberJoinRequest[] | null
  >(null);
  const [episodeReleaseRequests, setEpisodeReleaseRequests] = React.useState<
    ProjectEpisodeReleaseRequest[] | null
  >(null);
  const [memberJoinRequestStatus, setMemberJoinRequestStatus] =
    React.useState<ProjectMemberJoinRequestStatus>('pending');
  const [episodeReleaseRequestStatus, setEpisodeReleaseRequestStatus] =
    React.useState<ProjectEpisodeReleaseRequestStatus>('pending');
  const fetchMemberJoinRequests = React.useCallback(
    (status: ProjectMemberJoinRequestStatus) => {
      TapNovelTypedRestApi.get<ProjectMemberJoinRequest[]>(
        '/api/writer/leader/project_member_join_requests',
        {projectId, status},
      ).then(result => {
        setMemberJoinRequests(result.body);
      });
    },
    [],
  );
  const fetchEpisodeReleaseRequests = React.useCallback(
    (status: ProjectEpisodeReleaseRequestStatus) => {
      TapNovelTypedRestApi.get<ProjectEpisodeReleaseRequest[]>(
        '/api/writer/leader/project_episode_release_requests',
        {projectId, status},
      ).then(result => {
        setEpisodeReleaseRequests(result.body);
      });
    },
    [],
  );
  const fetchEntities = React.useCallback(() => {
    fetchMemberJoinRequests(memberJoinRequestStatus);
    fetchEpisodeReleaseRequests(episodeReleaseRequestStatus);
  }, [memberJoinRequestStatus, episodeReleaseRequestStatus]);
  useFocusEffect(fetchEntities);
  React.useEffect(() => {
    fetchMemberJoinRequests(memberJoinRequestStatus);
  }, [memberJoinRequestStatus]);
  React.useEffect(() => {
    fetchEpisodeReleaseRequests(episodeReleaseRequestStatus);
  }, [episodeReleaseRequestStatus]);
  const onForceUpdateMemberJoinRequests = React.useCallback(() => {
    fetchMemberJoinRequests(memberJoinRequestStatus);
  }, [memberJoinRequestStatus]);
  return (
    <Layout
      title={'申請管理'}
      navigation={navigation}
      back={true}
      scrollable={false}>
      {memberJoinRequests && episodeReleaseRequests && (
        <RequestTabs
          memberJoinRequests={memberJoinRequests}
          episodeReleaseRequests={episodeReleaseRequests}
          memberJoinRequestStatus={memberJoinRequestStatus}
          episodeReleaseRequestStatus={episodeReleaseRequestStatus}
          onForceUpdateMemberJoinRequests={onForceUpdateMemberJoinRequests}
          onMemberJoinRequestStatusChange={setMemberJoinRequestStatus}
          onEpisodeReleaseRequestStatusChange={setEpisodeReleaseRequestStatus}
        />
      )}
    </Layout>
  );
};

export default React.memo(Index);
