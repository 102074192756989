import * as React from 'react';
import {View} from 'react-native';

import Layout from '../../../shared/Layout';
import Form from '../../../shared/stripe/Form';

import NavigationProp from '../../../../navigators/NavigationProp';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';
import PaymentMethod from '../../../../../domain/entities/writer/my_stripe/PaymentMethod';
import PaymentIntent from '../../../../../domain/entities/writer/my_stripe/PaymentIntent';

import NetPaymentIntentRepository from '../../../../../data/repositories/writer/my_stripe/NetPaymentIntentRepository';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation} = props;
  const [loading, setLoading] = React.useState(true);
  const [ready, setReady] = React.useState(false);
  const createPaymentIntent = React.useCallback(() => {
    return new NetPaymentIntentRepository().create({});
  }, []);
  const [paymentMethod, setPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const [paymentIntent, setPaymentIntent] =
    React.useState<PaymentIntent | null>(null);
  const onReady = React.useCallback(() => {
    setReady(true);
    setLoading(false);
  }, []);
  const success = React.useCallback(() => {
    setTimeout(() => {
      setLoading(false);
      navigation.goBack();
    }, 1000);
  }, []);
  const fail = React.useCallback(() => {}, []);
  return (
    <Layout
      title={'お支払い方法の追加'}
      navigation={navigation}
      back={true}
      loading={loading}>
      <View style={{marginVertical: 32, display: ready ? 'flex' : 'none'}}>
        <Form
          onlySetup={true}
          paymentMethod={paymentMethod}
          paymentIntent={paymentIntent}
          createPaymentIntent={createPaymentIntent}
          onReady={onReady}
          setPaymentMethod={setPaymentMethod}
          setPaymentIntent={setPaymentIntent}
          setLoading={setLoading}
          success={success}
          fail={fail}
        />
      </View>
    </Layout>
  );
};

export default React.memo(Index);
