import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import EpisodeList from './EpisodeList';

import ScrollableTabViewAdapter, {
  ChangedTabInfo,
} from '../../../../shared/scrollable_tab_view/ScrollableTabViewAdapter';
import NoticeIcon from '../../../../shared/NoticeIcon';

import ProjectEpisode from '../../../../../../domain/entities/ProjectEpisode';

interface Props {
  projectId: number;
  activeEpisodes: ProjectEpisode[];
  deactiveEpisodes: ProjectEpisode[];
  setCurrentTabPage: React.Dispatch<React.SetStateAction<number>>;
  onForceUpdate: () => void;
}

const ManuscriptTabs: React.FC<Props> = props => {
  const {activeEpisodes, deactiveEpisodes, setCurrentTabPage, onForceUpdate} =
    props;
  const onChangeTab = React.useCallback((changedTabInfo: ChangedTabInfo) => {
    setCurrentTabPage(changedTabInfo.i);
  }, []);
  return (
    <>
      <ScrollableTabViewAdapter
        tabs={[
          {
            label: '稼働中のエピソード',
            element: (
              <EpisodeList
                episodes={activeEpisodes}
                label={'稼働中のエピソード'}
                onForceUpdate={onForceUpdate}
              />
            ),
          },
          {
            label: '終了したエピソード',
            element: (
              <EpisodeList
                episodes={deactiveEpisodes}
                label={'終了したエピソード'}
                onForceUpdate={onForceUpdate}
              />
            ),
          },
        ]}
        locked={false}
        onChangeTab={onChangeTab}
      />
      <View style={styles.notices}>
        <View style={styles.notice}>
          {/**
          <NoticeIcon />
          */}
        </View>
        <View style={styles.notice}>
          {/**
          <NoticeIcon />
          */}
        </View>
      </View>
    </>
  );
};

export default React.memo(ManuscriptTabs);

const styles = StyleSheet.create({
  notices: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 0,
    flexDirection: 'row',
  } as ViewStyle,
  notice: {
    flex: 1,
    alignItems: 'flex-end',
    margin: 8,
  } as ViewStyle,
});
