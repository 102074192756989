import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import IllustrationCategory from '../../../domain/entities/IllustrationCategory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetIllustrationCategoriesRepository from '../../../data/repositories/NetIllustrationCategoriesRepository';

const types = {
  failure: Types.IndexIllustrationCategoriesFailure,
  request: Types.IndexIllustrationCategoriesRequest,
  success: Types.IndexIllustrationCategoriesSuccess,
};

export interface Params extends BaseParams {
  id?: number | null;
  parentId?: number | null;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<IllustrationCategory>> {
  return indexGenerics(
    new NetIllustrationCategoriesRepository(),
    types,
    params,
  );
}
