import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import CommentListItem from './CommentListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectManuscriptComment from '../../../../../domain/entities/ProjectManuscriptComment';
import ProjectManuscriptCommentLike from '../../../../../domain/entities/ProjectManuscriptCommentLike';

interface Props {
  comments: ProjectManuscriptComment[];
  commentLikes: ProjectManuscriptCommentLike[];
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const CommentList: React.FC<Props> = props => {
  const {comments, commentLikes, ListHeaderComponent} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ProjectManuscriptComment>) => {
      const commentLike =
        commentLikes.find(
          commentLike =>
            commentLike.projectManuscriptCommentId === info.item.id,
        ) || null;
      return <CommentListItem comment={info.item} commentLike={commentLike} />;
    },
    [],
  );
  return (
    <FlatList
      data={comments}
      renderItem={renderItem}
      ListHeaderComponent={ListHeaderComponent}
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(CommentList);
