import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import CommunityHome from '../../../containers/community/home/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="CommunityHome">
      <RootStack.Screen
        name="CommunityHome"
        component={CommunityHome}
        options={{
          title: 'TapNovelMaker開発室',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
