import dateformat from 'dateformat';

import EpisodeBrowsingRetentionRateMapper, {
  EpisodeBrowsingRetentionRateData,
} from '../../../value_objects/mappers/summary/EpisodeBrowsingRetentionRateMapper';

import TapNovelRestApi from '../../../data_stores/net/TapNovelRestApi';

import EpisodeBrowsingRetentionRatesRepository from '../../../../domain/repositories/writer/summary/EpisodeBrowsingRetentionRatesRepository';
import EpisodeBrowsingRetentionRate from '../../../../domain/value_objects/summary/EpisodeBrowsingRetentionRate';

export default class NetEpisodeBrowsingRetentionRatesRepository
  implements EpisodeBrowsingRetentionRatesRepository
{
  private mapper: EpisodeBrowsingRetentionRateMapper;
  constructor() {
    this.mapper = new EpisodeBrowsingRetentionRateMapper();
  }

  public async findBy(params: {
    episodeId: number;
    from?: Date;
    to?: Date;
  }): Promise<Array<EpisodeBrowsingRetentionRate>> {
    const path = `/api/writer/summary/episode_browsing_retention_rates${this.generateQuery(
      params,
    )}`;
    const obj = await TapNovelRestApi.get<
      Array<EpisodeBrowsingRetentionRateData>
    >(path);
    return obj.body.map(data => this.mapper.map(data));
  }

  private generateQuery(options: {
    episodeId: number;
    from?: Date | undefined;
    to?: Date | undefined;
  }) {
    const ary = [];
    ary.push(`episode_id=${options.episodeId}`);
    if (options.from) {
      ary.push(`from=${dateformat(options.from, 'yyyy.mm.dd')}`);
    }
    if (options.to) {
      ary.push(`to=${dateformat(options.to, 'yyyy.mm.dd')}`);
    }
    if (ary.length > 0) {
      return `?${ary.join('&')}`;
    } else {
      return '';
    }
  }
}
