import * as React from 'react';
import {Image, Pressable, StyleSheet, View, ViewStyle} from 'react-native';

import {textFrameImageUrl} from '../../../../../../helpers/images';

import TextFrame from '../../../../../../../domain/entities/TextFrame';

interface Props {
  textFrame: TextFrame;
  selectedTextFrameId: number;
  width: number;
  onSelectTextFrame: (textFrame: TextFrame) => void;
}

const TextFrameGridListItem: React.FunctionComponent<Props> = props => {
  const {textFrame, selectedTextFrameId, width, onSelectTextFrame} = props;
  const isActive = selectedTextFrameId === textFrame.id;
  const height = width * aspectRatio;
  return (
    <Pressable
      onPress={() => {
        onSelectTextFrame(textFrame);
      }}>
      <View
        style={[
          styles.imageWrapper,
          isActive ? styles.imageWrapperActive : null,
          {width, height},
        ]}>
        <Image
          style={{
            width: width - IMAGE_MARGIN_HORIZONTAL,
            height: height - IMAGE_MARGIN_VERTICAL,
          }}
          resizeMode={'contain'}
          source={{
            uri: textFrameImageUrl(textFrame, 'large'),
          }}
        />
      </View>
    </Pressable>
  );
};

export default TextFrameGridListItem;

const aspectRatio = 88 / 167;

const IMAGE_MARGIN_VERTICAL = 42;
const IMAGE_MARGIN_HORIZONTAL = 22;

const styles = StyleSheet.create({
  imageWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'transparent',
    borderWidth: 2,
    borderRadius: 4,
  } as ViewStyle,
  imageWrapperActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
});
