import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import BaseSceneCommandModal from './BaseSceneCommandModal';

import EffectView from './visualizations/EffectView';
import AttachmentSoundView from '../../../../shared/sound/AttachmentSoundView';

import DeleteButton from './buttons/DeleteButton';
import ChangeButton from './buttons/ChangeButton';
import ConfirmButton from './buttons/ConfirmButton';

import {ModalParams} from '../../Modal';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';
import SceneFrame from '../../../../../view_models/SceneFrame';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import Sound from '../../../../../../domain/entities/Sound';

interface Props {
  sceneCommandForm: EffectShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  enableSoundEffect?: boolean;
  sceneFrame: SceneFrame;
  onRequestCloseModal: () => void;
  onForwardToChangeEffects: (
    sceneCommandForm: EffectShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => void;
  onRemoveEffectShowCommand: (
    sceneCommandForm: EffectShowSceneCommandForm,
  ) => void;
  onForwardToAddSoundEffects: (
    sceneCommandForm: EffectShowSceneCommandForm,
    parentSceneCommandId?: number,
    callback?: (sound: Sound) => void,
  ) => void;
  onChangeEffectShowSceneCommandForm: (
    sceneCommandForm: EffectShowSceneCommandForm,
  ) => void;
  onChangeCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRequestUpdateModal: (modalParams: ModalParams) => void;
}

interface State {
  selectedStartIndex: number | null;
  selectedEndIndex: number | null;
  selectedSound: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
}

export default class EffectShowSceneCommandModal extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedStartIndex: props.sceneCommandForm.startIndex || null,
      selectedEndIndex: props.sceneCommandForm.endIndex || null,
      selectedSound: props.sceneCommandForm.sound || null,
      selectedSoundStartTime: props.sceneCommandForm.startTime || null,
      selectedSoundEndTime: props.sceneCommandForm.endTime || null,
    };
  }

  public render(): React.ReactNode {
    const {sceneCommandForm, enableSoundEffect, onRequestCloseModal} =
      this.props;
    const {selectedSound, selectedSoundStartTime, selectedSoundEndTime} =
      this.state;
    return (
      <BaseSceneCommandModal
        title={'エフェクトの編集'}
        onRequestCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.footer}>
            <View style={styles.deleteButtons}>
              <DeleteButton onPress={this.destroySceneCommandForm} />
            </View>
          </View>
        }>
        <View style={styles.body}>
          <View
            style={{
              flex: 1,
              width: '100%',
              flexDirection: 'column',
              alignItems: 'flex-end',
              paddingHorizontal: 32,
              marginVertical: 4,
            }}>
            <ChangeButton
              title={'エフェクト変更'}
              onPress={this.changeEffect}
            />
          </View>
          <EffectView
            effect={sceneCommandForm.effect}
            startIndex={sceneCommandForm.startIndex || undefined}
            endIndex={sceneCommandForm.endIndex || undefined}
            onSliderTouchEnd={this.handleSliderTouchEnd}
          />
          {enableSoundEffect ? (
            <AttachmentSoundView
              style={{width: '95%', marginVertical: 10}}
              sound={selectedSound}
              startTime={selectedSoundStartTime}
              endTime={selectedSoundEndTime}
              addSound={this.addSound}
              deleteSound={this.deleteSound}
              onChagenRegion={this.handleChagenRegion}
            />
          ) : null}
          <View style={{alignItems: 'center', marginBottom: 16}}>
            <ConfirmButton onPress={this.handlePress} />
          </View>
        </View>
      </BaseSceneCommandModal>
    );
  }

  private handlePress = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onChangeCommand,
      onChangeEffectShowSceneCommandForm,
      onRequestCloseModal,
    } = this.props;
    const {
      selectedStartIndex,
      selectedEndIndex,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
    } = this.state;
    const newSceneCommandForm = new EffectShowSceneCommandForm(
      sceneCommandForm.effect,
      sceneCommandForm.position,
      selectedStartIndex,
      selectedEndIndex,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      sceneCommandForm.sceneCommandId,
    );
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          newSceneCommandForm,
        );
      onChangeCommand(newParentSceneCommandForm);
    } else {
      onChangeEffectShowSceneCommandForm(newSceneCommandForm);
    }
    onRequestCloseModal();
  };

  private changeEffect = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onForwardToChangeEffects,
    } = this.props;
    onForwardToChangeEffects(
      sceneCommandForm,
      parentSceneCommandForm?.sceneCommandId,
    );
    onRequestCloseModal();
  };

  private addSound = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onForwardToAddSoundEffects,
    } = this.props;
    onForwardToAddSoundEffects(
      sceneCommandForm,
      parentSceneCommandForm?.sceneCommandId,
      sound => {
        this.setState({selectedSound: sound});
      },
    );
  };

  private deleteSound = () => {
    this.setState({
      selectedSound: null,
      selectedSoundStartTime: null,
      selectedSoundEndTime: null,
    });
  };

  private handleSliderTouchEnd = (startIndex: number, endIndex: number) => {
    this.setState({selectedStartIndex: startIndex, selectedEndIndex: endIndex});
  };

  private handleChagenRegion = (start?: number, end?: number) => {
    this.setState({
      selectedSoundStartTime: start || null,
      selectedSoundEndTime: end || null,
    });
  };

  private destroySceneCommandForm = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onRemoveEffectShowCommand,
      onChangeCommand,
      onRemoveCommand,
    } = this.props;
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.remove(
          parentSceneCommandForm,
          sceneCommandForm,
        );
      if (newParentSceneCommandForm) {
        onChangeCommand(newParentSceneCommandForm);
      } else {
        onRemoveCommand(parentSceneCommandForm);
      }
    } else {
      onRemoveEffectShowCommand(sceneCommandForm);
    }
    onRequestCloseModal();
  };
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: '#fafafa',
    alignItems: 'center',
    width: '100%',
  } as ViewStyle,
  button: {
    marginVertical: 5,
  } as ViewStyle,
  deleteButtons: {
    marginTop: 5,
  } as ViewStyle,
  footer: {
    padding: 10,
  } as ViewStyle,
});
