import * as React from 'react';
import BaseIndex from '../../shared/generics/character_form/actors/Index';

import NavigationProp from '../../../navigators/NavigationProp';
import {ShopActorsRouteProp} from '../../../navigators/RouteProps';

import {Params as ActorIndexParams} from '../../../actions/actors/index';
import {Params as ActorCategoryIndexParams} from '../../../actions/actor_categories/index';

import {QueryState} from '../../../reducers/queries/Response';

import Actor from '../../../../domain/entities/Actor';
import ActorCategory from '../../../../domain/entities/ActorCategory';
import PaginatedResult from '../../../../domain/results/PaginatedResult';
import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

export interface Params {
  formValues: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    allowedInversion?: Array<'true' | 'false'>;
  };
  sort?: 'popularity' | 'new_arrival';
  page?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ShopActorsRouteProp;
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
  actors: Actor[] | null;
  actorsParams: ActorIndexParams;
  actorCategories: ActorCategory[] | null;
  actorCategoriesParams: ActorCategoryIndexParams;
  actorQueries: QueryState;
  totalCount: number | null;
}

export interface DispatchProps {
  indexActors: (params: ActorIndexParams) => Promise<PaginatedResult<Actor>>;
  indexActorCategories: (
    params: ActorCategoryIndexParams,
  ) => Promise<PaginatedResult<ActorCategory>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public render(): React.ReactNode {
    const {route} = this.props;
    const {formValues, sort} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        title={'ショップ'}
        formValues={formValues || defaultFormValues}
        sort={sort || 'new_arrival'}
        onPressForActor={this.handlePressForActor}
        onPressForSearch={this.handlePressForSearch}
        shopMode={true}
      />
    );
  }

  private handlePressForSearch = () => {
    const {navigation, route} = this.props;
    const {formValues} = route.params || {};
    navigation.navigate('ShopActorSearchFormNavigation', {
      screen: 'ShopActorSearchForm',
      params: {
        formValues: formValues || defaultFormValues,
        hideBack: true,
      },
    } as any);
  };

  private handlePressForActor = (actor: Actor) => {
    const {navigation} = this.props;
    navigation.navigate('ShopActor', {id: actor.id});
  };
}

const defaultFormValues = {
  query: '',
  ageMin: 0,
  ageMax: 6,
  rootCategoryIdToActorCategoryIds: [],
  favorite: false,
  paid: true,
};
