import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import ReactionListItem from './ReactionListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import EpisodeReaction from '../../../../../domain/entities/EpisodeReaction';

interface Props {
  episodeReactions: EpisodeReaction[] | null;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
}

const ReactionList: React.FunctionComponent<Props> = props => {
  const {episodeReactions, ListHeaderComponent} = props;
  const keyExtractor = React.useCallback(
    (episode: EpisodeReaction, index: number): string => `${index}`,
    [],
  );
  const renderItem = React.useCallback(
    (
      info: ListRenderItemInfo<EpisodeReaction>,
    ): React.ReactElement<any> | null => {
      return <ReactionListItem episodeReaction={info.item} />;
    },
    [],
  );
  return (
    <FlatList
      data={episodeReactions}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
      onEndReachedThreshold={0.1}
    />
  );
};

export default React.memo(ReactionList);
