import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../../../shared/svg/SvgUriAdapter';

import CheckCircleIcon from '../../../../shared/icons/CheckCircleIcon';

import Tutorial1Uri from '../../../../../../assets/svgs/writers_gate/tutorial-1.svg';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep1: React.FC<Props> = props => {
  const {onPress} = props;
  const linkTo = useLinkTo();
  const [checked, setChecked] = React.useState(false);
  const onPressTerms = React.useCallback(() => {
    if (!checked) {
      setChecked(true);
    }
    linkTo('/writers_gate/policies/terms?modal=true');
  }, [checked]);
  const onPressPartnerProgram = React.useCallback(() => {
    linkTo('/partner_program');
  }, []);
  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.message}>
          「ライターズゲート」は、タップライターの皆様に{'\n'}
          お仕事を紹介するための窓口です。
        </Text>
        <View style={styles.image}>
          <SvgUriAdapter uri={Tutorial1Uri} width={305} height={200} />
        </View>
        <Text style={styles.note}>
          小説、漫画、Webtoon、ドラマ、映画、アニメ、イベント、グッズ販売まで幅広く手掛けるイマジカインフォス
          には、 エンタメ業界から様々なオファーが集まります。
        </Text>
        <Text style={styles.note}>
          既にプロとして活躍している方から、これから商業デビューを目指す方まで、タップライターの皆様にピッタリだと思うお仕事をご紹介します。
        </Text>
        <TouchableOpacity style={styles.agreement} onPress={onPressTerms}>
          <CheckCircleIcon size={11} color={checked ? '#FF8F13' : undefined} />
          <Text style={styles.agreementLink}>ライターズゲート約款</Text>
          <Text style={styles.agreementText}>を確認の上、ご同意ください</Text>
        </TouchableOpacity>
        <PrimaryButton disabled={!checked} onPress={onPress}>
          説明を読んでオファーを探す
        </PrimaryButton>
        <Text style={styles.requiredNote}>
          ※ライターズゲートをご利用するには、
          <TouchableOpacity onPress={onPressPartnerProgram}>
            <Text style={{textDecorationLine: 'underline'}}>
              パートナープログラム
            </Text>
          </TouchableOpacity>
          にご参加いただく必要がございます。
        </Text>
      </View>
    </ScrollView>
  );
};

export default React.memo(ServiceExplanationStep1);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
  image: {
    marginVertical: 16,
  } as ViewStyle,
  title: {
    color: '#ff8f13',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
  note: {
    fontSize: 14,
    color: '#383838',
    textAlign: 'center',
    marginHorizontal: 24,
    marginBottom: 8,
  } as TextStyle,
  agreement: {
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 16,
  } as ViewStyle,
  agreementLink: {
    color: '#ff8f13',
    fontSize: 10,
    textDecorationLine: 'underline',
    marginLeft: 8,
  } as TextStyle,
  agreementText: {
    color: '#222',
    fontSize: 10,
  } as TextStyle,
  requiredNote: {
    marginTop: 21,
    marginHorizontal: 24,
    color: '#666666',
    fontSize: 11,
  } as TextStyle,
});
