import * as React from 'react';
import {Image, Text, View} from 'react-native';

import Layout from '../../shared/Layout';

import ShareButtonsWithCopy from '../../shared/share/ShareButtonsWithCopy';

import NavigationProp from '../../../navigators/NavigationProp';
import {UserUpgradeAchievementRouteProp} from '../../../navigators/RouteProps';

import convertImageSource from '../../../helpers/convertImageSource';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

import CongratulationsUri from '../../../../assets/images/user_upgrade_achievements/rank_up.png';

interface UserUpgradeAchievement {
  id: number;
  ogp_image_url: string;
  writer_user: {
    id: number;
    pen_name: string;
  };
  writer_user_url: string;
  writer_grade: {
    id: string;
    code: string;
    name: string;
  };
  updated_at: string;
  created_at: string;
}

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: UserUpgradeAchievementRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

interface State {
  userUpgradeAchievement: UserUpgradeAchievement | null;
}

export default class Show extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userUpgradeAchievement: null,
    };
  }

  public componentDidMount() {
    const {navigation, route} = this.props;
    const {id} = route.params;
    TapNovelRestApi.get<UserUpgradeAchievement>(
      `/api/writer/user_upgrade_achievements/${id}`,
    )
      .then(response => {
        this.setState({userUpgradeAchievement: response.body});
      })
      .catch(e => {
        if (e.status === 404) {
          navigation.goBack();
        }
      });
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {userUpgradeAchievement} = this.state;
    return (
      <Layout
        title={'通知'}
        navigation={navigation}
        scrollable={false}
        back={true}>
        {userUpgradeAchievement && (
          <View style={{flex: 1, alignItems: 'center', paddingVertical: 25}}>
            <Image
              source={convertImageSource(CongratulationsUri)}
              style={{width: 343, height: 45}}
            />
            <Text
              style={{
                color: '#222',
                fontSize: 15,
                fontWeight: 'bold',
                textAlign: 'center',
                marginVertical: 24,
              }}>
              おめでとうございます！{'\n'}
              ライターランクが上がりました。
            </Text>
            <Image
              source={{uri: userUpgradeAchievement.ogp_image_url}}
              style={{
                width: 343,
                height: 180,
                marginBottom: 32,
              }}
            />
            <Text style={{color: '#222', fontSize: 15, marginBottom: 25}}>
              今後益々のご活躍をお祈り申し上げます！
            </Text>
            <Text
              style={{
                color: '#222',
                fontSize: 15,
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              この画像をシェアして、{'\n'}
              もっとたくさんの人に読んでもらおう！
            </Text>
            <View style={{marginTop: 20}}>
              <ShareButtonsWithCopy
                title={userUpgradeAchievement.writer_user.pen_name}
                message={`ライターランクが【${userUpgradeAchievement.writer_grade.name}】に上がりました。`}
                url={`${userUpgradeAchievement.writer_user_url}?ogp_type=user_upgrade_achievement&writer_grade_id=${userUpgradeAchievement.writer_grade.id}`}
              />
            </View>
          </View>
        )}
      </Layout>
    );
  }
}
