import * as React from 'react';
import {StyleSheet, Text, View, ViewStyle} from 'react-native';

import IconsTips from './IconsTips';

interface Props {
  totalCount: number | null;
  visibleIconsTips: boolean;
}

const CommentListHeader: React.FunctionComponent<Props> = props => {
  const {totalCount, visibleIconsTips} = props;
  if (!totalCount) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text>全{totalCount}件</Text>
      <IconsTips visible={visibleIconsTips} />
    </View>
  );
};

export default React.memo(CommentListHeader);

const styles = StyleSheet.create({
  container: {
    height: 44,
    width: '100%',
    backgroundColor: '#fafafa',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  } as ViewStyle,
});
