import Episode from '../../../../domain/entities/Episode';

interface Props {
  episodes: Episode[] | null;
}

export default (current: Props, next: Props) => {
  if (current.episodes === next.episodes) {
    return false;
  }
  if (!current.episodes || !next.episodes) {
    return true;
  }
  if (current.episodes.length !== next.episodes.length) {
    return true;
  }
  return !current.episodes.every((currentEpisode, i) => {
    if (!next.episodes) {
      return false;
    }
    const nextEpisode = next.episodes[i];
    if (currentEpisode.id !== nextEpisode.id) {
      return false;
    }
    if (
      currentEpisode.updatedAt.getTime() !== nextEpisode.updatedAt.getTime()
    ) {
      return false;
    }
    return true;
  });
};
