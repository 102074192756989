import * as React from 'react';
import {
  ActivityIndicator,
  GestureResponderEvent,
  Keyboard,
  LayoutChangeEvent,
  Platform,
  ScrollView,
  StyleProp,
  StatusBar,
  View,
  ViewStyle,
} from 'react-native';

import ModalContainer from './ModalContainer';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

import DimensionContext from '../dimension/DimensionContext';

import {baseStyleWidth} from '../../../styles/variables';

interface Props {
  style?: StyleProp<ViewStyle>;
  visible: boolean;
  animationType?: 'none' | 'slide' | 'fade';
  title?: string;
  disableCloseByOutsideTouch?: boolean;
  footer?: React.ReactNode;
  loading?: boolean;
  children?: React.ReactNode;
  onCloseModal?: (event: GestureResponderEvent) => void;
  onLayout?: (event: LayoutChangeEvent) => void;
}

const LoadingComponent = (
  <View
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, .2)',
      borderRadius: 5,
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <ActivityIndicator size="large" color="#999999" />
  </View>
);

const DefaultModal: React.FunctionComponent<Props> = props => {
  const {
    style,
    visible,
    animationType,
    title,
    footer,
    disableCloseByOutsideTouch,
    loading,
    onCloseModal,
    onLayout,
    children,
  } = props;
  const [modalHeight, setModalHeight] = React.useState<number | undefined>(
    undefined,
  );
  const [keyboardHeight, setKeyboardHeight] = React.useState(0);

  React.useEffect(() => {
    const keyboardWillShowSubscription = Keyboard.addListener(
      'keyboardDidShow',
      event => {
        setKeyboardHeight(event.endCoordinates.height);
      },
    );
    const keyboardWillHideSubscription = Keyboard.addListener(
      'keyboardDidHide',
      event => {
        setKeyboardHeight(0);
      },
    );
    return () => {
      keyboardWillShowSubscription.remove();
      keyboardWillHideSubscription.remove();
    };
  }, []);

  return (
    <DimensionContext.Consumer>
      {context => (
        <ModalContainer
          style={[
            {width: (context.content.width * 343) / baseStyleWidth},
            style,
          ]}
          animationType={animationType || 'fade'}
          visible={visible}
          disableCloseByOutsideTouch={disableCloseByOutsideTouch}
          onLayout={onLayout}
          onCloseModal={onCloseModal}>
          <ScrollView
            style={{
              width: '100%',
              height:
                modalHeight && modalHeight > context.content.height
                  ? context.content.height
                  : keyboardHeight && Platform.OS === 'android'
                  ? context.content.height -
                    keyboardHeight -
                    (StatusBar.currentHeight || 0)
                  : modalHeight
                  ? modalHeight
                  : undefined,
            }}
            showsVerticalScrollIndicator={false}>
            <View
              style={{
                flex: 1,
                marginBottom: Platform.select({
                  ios: keyboardHeight,
                  default: undefined,
                }),
              }}
              onLayout={e => {
                const newModalHeight = e.nativeEvent.layout.height;
                if (!modalHeight || newModalHeight > modalHeight) {
                  setModalHeight(newModalHeight);
                }
              }}>
              <ModalHeader title={title} onCloseModal={onCloseModal} />
              {children}
              <ModalFooter>{footer}</ModalFooter>
              {loading && LoadingComponent}
            </View>
          </ScrollView>
        </ModalContainer>
      )}
    </DimensionContext.Consumer>
  );
};

export default React.memo(DefaultModal);
