import EntityMapper from '../EntityMapper';

import ManualCategory from '../../../../domain/entities/writer/ManualCategory';

export interface ManualCategoryData {
  id: number;
  name: string;
}

export default class ManualCategoryMapper
  implements EntityMapper<ManualCategoryData, ManualCategory>
{
  public map(obj: ManualCategoryData): ManualCategory {
    return new ManualCategory(obj.id, obj.name);
  }
}
