import {Dispatch} from 'redux';

import Types from '../../../Types';

import {sendDefaultCustomEvent, setUser} from '../../../../helpers/analytics';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

import UserAuthentication from '../../../../../domain/services/writer/UserAuthentication';

import AccessTokenRepository from '../../../../../data/repositories/StorageAccessTokenRepository';
import UserRepository from '../../../../../data/repositories/writer/NetCurrentUserRepository';
import RegistrationRepository from '../../../../../data/repositories/writer/temporary_users/NetRegistrationRepository';

export interface Params {
  email?: string | null;
  password?: string | null;
  password_confirmation?: string | null;
  facebookAccount?: {
    accessToken: string;
    userId: string;
    screenName: string;
  };
  twitterAccount?: {
    accessToken: string;
    accessTokenSecret: string;
    userId: string;
    screenName: string;
  };
  lineAccount?: {
    accessToken: string;
    userId: string;
    screenName: string;
  };
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<CurrentUser> {
  return async (dispatch: Dispatch<any>): Promise<CurrentUser> => {
    const accessTokenRepository = new AccessTokenRepository();
    const registrationRepository = new RegistrationRepository();
    const userRepository = new UserRepository();
    try {
      const payload = await new UserAuthentication(
        accessTokenRepository,
        registrationRepository,
        userRepository,
      ).authenticate({apiWriterUser: params});
      setUser(payload);
      sendDefaultCustomEvent({
        resourceType: 'writer/temporary_user',
        resourceId: payload.id,
        actionName: 'create',
      });
      dispatch({payload, type: Types.CreateWriterCurrentUserSuccess});
      return payload;
    } catch (err: any) {
      dispatch({type: Types.CreateWriterCurrentUserFailure});
      if (err.status === 401) {
        accessTokenRepository.destroy();
      }
      throw err;
    }
  };
}
