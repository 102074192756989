import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import LinkButton from '../../shared/buttons/LinkButton';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import DefaultModal from '../../shared/modals/DefaultModal';

import Story from '../../../../domain/entities/Story';

interface Props {
  story: Story;
  onRequestDeleteStory: (story: Story) => void;
  onRequestCloseModal: () => void;
}

export default class StoryDeleteConfirmModal extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {story, onRequestCloseModal} = this.props;
    return (
      <DefaultModal
        visible={true}
        onCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.buttons}>
            <LinkButton style={styles.button} onPress={onRequestCloseModal}>
              キャンセル
            </LinkButton>
            <PrimaryButton style={styles.button} onPress={this.handlePress}>
              削除
            </PrimaryButton>
          </View>
        }>
        <View style={styles.body}>
          <Text
            style={[
              styles.text,
              {fontWeight: 'bold', marginTop: -16, marginBottom: 16},
            ]}>
            {story.title}を削除する
          </Text>
          <Text style={styles.text}>
            一度削除したストーリーは復元できません。{'\n'}
            作成したストーリーは削除されますが{'\n'}
            よろしいですか？
          </Text>
        </View>
      </DefaultModal>
    );
  }

  private handlePress = () => {
    const {story, onRequestDeleteStory} = this.props;
    onRequestDeleteStory(story);
  };
}

const styles = StyleSheet.create({
  body: {
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    marginVertical: 15,
  } as ViewStyle,
  button: {
    marginHorizontal: 3,
    width: 145,
    height: 46,
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
  } as TextStyle,
});
