import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import StoryMapper, {StoryData} from '../../entities/mappers/StoryMapper';

import Story from '../../../domain/entities/Story';
import StoriesRepository from '../../../domain/repositories/writer/StoriesRepository';

export default class NetStoriesRepository
  extends NetResourcesRepository<Story, StoryData>
  implements StoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/stories',
        new StoryMapper(),
      ),
    );
  }
}
