import {generateGetUrl, generateHeaders} from './ApiUtils';

export default class RawApi {
  public static async get(
    url: string,
    params?: {[key: string]: any},
  ): Promise<Response> {
    const urlWithQuery = generateGetUrl(url, params);
    const headers = await generateHeaders({onlyAccessToken: true});
    const response = await fetch(urlWithQuery, {
      headers,
    });
    return response;
  }
}
