import TwitterRequestToken from '../../../domain/value_objects/TwitterRequestToken';

export interface TwitterRequestTokenData {
  token: string;
  secret: string;
}

export default class TwitterRequestTokenMapper {
  public map(obj: TwitterRequestTokenData): TwitterRequestToken {
    return new TwitterRequestToken(obj.token, obj.secret);
  }
}
