export default class UserRegistrationEmailEditForm {
  constructor(public email: string) {}

  public validate(): boolean {
    return this.email.length > 0;
  }

  public getParams() {
    return {
      email: this.email,
    };
  }
}
