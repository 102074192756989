import * as React from 'react';
import {TouchableOpacity} from 'react-native';

import BackgroundView from './visualizations/BackgroundView';

import Background from '../../../../../../domain/entities/Background';
import SceneForm from '../../../../../../domain/forms/SceneForm';

interface Props {
  sceneForm: SceneForm;
  background: Background | null;
  onForwardToBackgrounds: () => void;
  onForwardToBackgroundFilters: () => void;
  onForwardToBackgroundEffects: () => void;
}

const BackgroundShowSceneCommandBox: React.FunctionComponent<Props> = props => {
  const {
    sceneForm,
    background,
    onForwardToBackgrounds,
    onForwardToBackgroundFilters,
    onForwardToBackgroundEffects,
  } = props;
  return (
    <TouchableOpacity activeOpacity={0.9} onPress={onForwardToBackgrounds}>
      <BackgroundView
        sceneForm={sceneForm}
        background={background}
        onForwardToBackgrounds={onForwardToBackgrounds}
        onForwardToBackgroundFilters={onForwardToBackgroundFilters}
        onForwardToBackgroundEffects={onForwardToBackgroundEffects}
      />
    </TouchableOpacity>
  );
};

export default React.memo(BackgroundShowSceneCommandBox);
