import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/cover_image_form/text/Index';

import AppState from '../../../reducers/AppState';

import {CoverImageFormTextRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CoverImageFormTextRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {forms} = state;
  const {coverImageForm} = forms;
  return {
    navigation,
    coverImageForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {};
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
