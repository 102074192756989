import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import UserNotification from '../../../../domain/entities/writer/UserNotification';

import NetUserNotificationsRepository from '../../../../data/repositories/writer/NetUserNotificationsRepository';

const types = {
  failure: Types.UpdateWriterUserNotificationFailure,
  request: Types.UpdateWriterUserNotificationRequest,
  success: Types.UpdateWriterUserNotificationSuccess,
};

export interface Params {
  confirmed: boolean;
}

export default function action(
  id: number,
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<UserNotification> {
  return (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<UserNotification> => {
    return new NetUserNotificationsRepository()
      .update(id, {writerUserNotification: params})
      .then(payload => {
        dispatch({payload, type: types.success});
        return payload;
      })
      .catch(err => {
        dispatch({type: types.failure});
        throw err;
      });
  };
}
