import * as React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import CurrentUser from '../../../../../../domain/entities/writer/CurrentUser';
import UserRegistrationPasswordEditForm from '../../../../../../domain/forms/writer/UserRegistrationPasswordEditForm';

interface Props {
  currentUser: CurrentUser;
  userRegistrationPasswordEditForm: UserRegistrationPasswordEditForm;
  onChangeCurrentPassword: (currentPassword: string) => void;
  onChangePassword: (password: string) => void;
  onChangePasswordConfirmation: (passwordConfirmation: string) => void;
}

export default class Form extends React.PureComponent<Props> {
  private passwordInput: TextInput | null = null;
  private passwordConfirmationInput: TextInput | null = null;

  public render(): React.ReactNode {
    const {
      userRegistrationPasswordEditForm,
      onChangeCurrentPassword,
      onChangePassword,
      onChangePasswordConfirmation,
    } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.section}>
          <View style={styles.section}>
            <Text style={styles.formLabel}>現在のパスワード</Text>
          </View>
          <View style={styles.textInputWrapper}>
            <TextInput
              style={styles.textInput}
              keyboardType={Platform.select({
                ios: 'ascii-capable',
                default: 'default',
              })}
              secureTextEntry={true}
              placeholder={'現在のパスワード'}
              value={userRegistrationPasswordEditForm.currentPassword}
              onChangeText={onChangeCurrentPassword}
              returnKeyType={'next'}
              onSubmitEditing={this.handleSubmitEditingCurrentPassword}
            />
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.section}>
            <Text style={styles.formLabel}>
              新しいパスワード(8文字以上)
              {userRegistrationPasswordEditForm.password.length > 0 &&
                userRegistrationPasswordEditForm.passwordConfirmation.length >
                  0 &&
                userRegistrationPasswordEditForm.password !==
                  userRegistrationPasswordEditForm.passwordConfirmation && (
                  <Text style={styles.errorMessage}>
                    {' '}
                    パスワードが一致していません
                  </Text>
                )}
            </Text>
          </View>
          <View style={styles.textInputWrapper}>
            <TextInput
              style={styles.textInput}
              keyboardType={Platform.select({
                ios: 'ascii-capable',
                default: 'default',
              })}
              secureTextEntry={true}
              placeholder={'新しいパスワード'}
              value={userRegistrationPasswordEditForm.password}
              onChangeText={onChangePassword}
              returnKeyType={'next'}
              ref={this.refPasswordInput}
              onSubmitEditing={this.handleSubmitEditingPassword}
            />
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.section}>
            <Text style={styles.formLabel}>確認用パスワード</Text>
          </View>
          <View style={styles.textInputWrapper}>
            <TextInput
              style={styles.textInput}
              keyboardType={Platform.select({
                ios: 'ascii-capable',
                default: 'default',
              })}
              secureTextEntry={true}
              placeholder={'新しいパスワードの確認'}
              value={userRegistrationPasswordEditForm.passwordConfirmation}
              onChangeText={onChangePasswordConfirmation}
              returnKeyType={'done'}
              ref={this.refPasswordConfirmationInput}
            />
          </View>
        </View>
      </View>
    );
  }

  private refPasswordInput = (instance: TextInput | null) => {
    this.passwordInput = instance;
  };

  private handleSubmitEditingCurrentPassword = () => {
    if (this.passwordInput) {
      this.passwordInput.focus();
    }
  };

  private refPasswordConfirmationInput = (instance: TextInput | null) => {
    this.passwordConfirmationInput = instance;
  };

  private handleSubmitEditingPassword = () => {
    if (this.passwordConfirmationInput) {
      this.passwordConfirmationInput.focus();
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 25,
  } as ViewStyle,
  errorMessage: {
    color: 'red',
    fontSize: 11,
  } as TextStyle,
  section: {
    marginVertical: 8,
  } as ViewStyle,
  formLabel: {
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  textInputWrapper: {
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  textInput: {
    fontSize: 15,
    marginVertical: 8,
  } as TextStyle,
});
