import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics';

import Help from '../../../../domain/entities/writer/Help';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetHelpsRepository from '../../../../data/repositories/writer/NetHelpsRepository';

const types = {
  failure: Types.IndexWriterHelpsFailure,
  request: Types.IndexWriterHelpsRequest,
  success: Types.IndexWriterHelpsSuccess,
};

export interface Params extends BaseParams {
  writerHelpCategoryId?: number;
  primary?: boolean;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Help>> {
  return indexGenerics(new NetHelpsRepository(), types, params);
}
