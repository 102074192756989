import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Scene, {Options} from '../../../domain/entities/Scene';

import NetScenesRepository from '../../../data/repositories/writer/NetScenesRepository';

export interface Params {
  title?: string | null;
  backgroundId?: number | null;
  rowOrderPosition?: number | null;
  options?: Options | null;
  commandsAttributes?: {[key: string]: any};
  ignoreUpdate?: boolean;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Scene> {
  return async (dispatch: Dispatch<any>): Promise<Scene> => {
    try {
      const payload = await new NetScenesRepository().update(id, {
        scene: params,
      });
      if (!params.ignoreUpdate) {
        dispatch({payload, type: Types.UpdateSceneSuccess});
      }
      sendDefaultCustomEvent({
        resourceType: 'scene',
        resourceId: payload.id,
        resourceName: `シーン${payload.numberOfEpisode}`,
        actionName: 'update',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateSceneFailure});
      throw err;
    }
  };
}
