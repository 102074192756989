import {Platform} from 'react-native';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RawApi, {Result} from './RawApi';

// tslint:disable-next-line:no-implicit-dependencies
import Config from 'react-native-config';

interface Params {
  [key: string]: any;
}

export type Result<T> = Result<T>;

export default class TapNovelRawApi extends RawApi {
  public static get<T>(path: string, params?: Params): Promise<Result<T>> {
    return super.get(this.getUrl(path), params);
  }

  public static getUrl(path: string) {
    if (Platform.OS === 'web') {
      return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
    } else {
      return Config.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
    }
  }
}
