import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import BaseSceneCommandModal from './BaseSceneCommandModal';

import SoundEffectView from './visualizations/SoundEffectView';

import MusicMuteButton from './buttons/MusicMuteButton';
import ChangeButton from './buttons/ChangeButton';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../../../../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';

interface Props {
  sceneCommandForm: BackgroundMusicShowSceneCommandForm;
  sceneCommandIndex: number;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestCloseModal: () => void;
  onForwardToBackgroundMusic: (params: {
    sceneCommandIndex: number;
    parentSceneCommandId?: number;
  }) => void;
  onRemoveBackgroundMusicShowCommand: (
    sceneCommandForm: BackgroundMusicShowSceneCommandForm,
  ) => void;
  onInsertBackgroundMusicHideCommandAt: (
    sceneCommandForm: BackgroundMusicHideSceneCommandForm,
    sceneCommandIndex: number,
  ) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
}

export default class CurrentBackgroundMusicShowSceneCommandModal extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {sceneCommandForm, onRequestCloseModal} = this.props;
    return (
      <BaseSceneCommandModal
        title={'BGMの編集'}
        onRequestCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.footer}>
            <View style={styles.deleteButtons}>
              <MusicMuteButton
                onPress={this.addBackgroundMusicHideSceneCommand}
              />
            </View>
          </View>
        }>
        <View style={styles.body}>
          <View
            style={{
              flex: 1,
              width: '100%',
              flexDirection: 'column',
              alignItems: 'flex-end',
              paddingHorizontal: 32,
              marginVertical: 4,
            }}>
            <ChangeButton title={'BGM変更'} onPress={this.changeSound} />
          </View>

          <SoundEffectView sound={sceneCommandForm.sound} />
        </View>
      </BaseSceneCommandModal>
    );
  }

  private changeSound = () => {
    const {sceneCommandIndex, onRequestCloseModal, onForwardToBackgroundMusic} =
      this.props;
    onForwardToBackgroundMusic({sceneCommandIndex});
    onRequestCloseModal();
  };

  private addBackgroundMusicHideSceneCommand = () => {
    const {
      sceneCommandForm,
      sceneCommandIndex,
      onRequestCloseModal,
      onInsertBackgroundMusicHideCommandAt,
    } = this.props;
    onInsertBackgroundMusicHideCommandAt(sceneCommandForm, sceneCommandIndex);
    onRequestCloseModal();
  };
}

const styles = StyleSheet.create({
  body: {
    backgroundColor: '#fafafa',
    alignItems: 'center',
    width: '100%',
  } as ViewStyle,
  button: {
    marginVertical: 5,
  } as ViewStyle,
  deleteButtons: {
    marginTop: 5,
  } as ViewStyle,
  footer: {
    padding: 10,
  } as ViewStyle,
});
