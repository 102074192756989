import * as React from 'react';
import {
  Text,
  TextStyle,
  TouchableOpacity,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import ThumbsUpActiveIcon from '../../../shared/icons/project/ThumbsUpActiveIcon';
import ThumbsUpIcon from '../../../shared/icons/project/ThumbsUpIcon';

import ProjectTopicCommentReply from '../../../../../domain/entities/ProjectTopicCommentReply';
import ProjectTopicCommentReplyLike from '../../../../../domain/entities/ProjectTopicCommentReplyLike';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  commentReply: ProjectTopicCommentReply;
  commentReplyLike: ProjectTopicCommentReplyLike | null;
}

const CommentReplyLikeButton: React.FC<Props> = props => {
  const {commentReply} = props;
  const [commentReplyLike, setCommentReplyLike] = React.useState<
    ProjectTopicCommentReplyLike | null | undefined
  >(props.commentReplyLike);
  const [likesCount, setLikesCount] = React.useState(commentReply.likesCount);
  const onPress = React.useCallback(() => {
    if (commentReplyLike) {
      TapNovelTypedRestApi.delete(
        `/api/writer/project_topic_comment_reply_likes/${commentReplyLike.id}`,
      ).then(result => {
        setCommentReplyLike(null);
        setLikesCount(likesCount - 1);
      });
    } else {
      TapNovelTypedRestApi.post<ProjectTopicCommentReplyLike>(
        '/api/writer/project_topic_comment_reply_likes',
        {
          projectTopicCommentReplyId: commentReply.id,
        },
      ).then(result => {
        setCommentReplyLike(result.body);
        setLikesCount(likesCount + 1);
      });
    }
  }, [commentReplyLike, likesCount]);
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPress}>
        {commentReplyLike ? (
          <ThumbsUpActiveIcon size={13} />
        ) : (
          <ThumbsUpIcon size={13} />
        )}
      </TouchableOpacity>
      <Text
        style={commentReplyLike ? styles.likesActiveCount : styles.likesCount}>
        {likesCount}
      </Text>
    </View>
  );
};

export default React.memo(CommentReplyLikeButton);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 8,
  } as ViewStyle,
  likesCount: {
    color: '#999',
    fontSize: 10,
    marginLeft: 2,
  } as TextStyle,
  likesActiveCount: {
    color: '#f23406',
    fontSize: 10,
    marginLeft: 2,
  } as TextStyle,
});
