import * as React from 'react';
import {camelizeKeys} from 'humps';

import CompleteMessage from './components/CompleteMessage';
import RecommendActors from './components/RecommendActors';

import CloseWindow from '../components/CloseWindow';

import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';
import {ShopActorCheckoutCompleteRouteProp} from '../../../../navigators/RouteProps';

import Actor from '../../../../../domain/entities/shop/Actor';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  actorId: number;
  disableNavigation?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ShopActorCheckoutCompleteRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Complete: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {actorId, disableNavigation} = route.params;
  const [actor, setActor] = React.useState<Actor | null>(null);
  const [sameNameActors, setSameNameActors] = React.useState<Actor[] | null>(
    null,
  );
  const [sameCreatorActors, setSameCreatorActors] = React.useState<
    Actor[] | null
  >(null);
  const [otherWriterPurchasedActors, setOtherWriterPurchasedActors] =
    React.useState<Actor[] | null>(null);
  const [closeConditionsActors, setCloseConditionsActors] = React.useState<
    Actor[] | null
  >(null);
  React.useEffect(() => {
    TapNovelRestApi.get(`/api/writer/shop/actors/${actorId}`).then(res => {
      setActor(camelizeKeys(res.body) as Actor);
    });
    TapNovelRestApi.get(`/api/writer/shop/recommend_actors`, {
      actorId,
      type: 'same_actor_name',
    }).then(res => {
      setSameNameActors(camelizeKeys(res.body) as Actor[]);
    });
    TapNovelRestApi.get(`/api/writer/shop/recommend_actors`, {
      actorId,
      type: 'same_creator',
    }).then(res => {
      setSameCreatorActors(camelizeKeys(res.body) as Actor[]);
    });
    TapNovelRestApi.get(`/api/writer/shop/recommend_actors`, {
      actorId,
      type: 'other_writer_purchased',
    }).then(res => {
      setOtherWriterPurchasedActors(camelizeKeys(res.body) as Actor[]);
    });
    TapNovelRestApi.get(`/api/writer/shop/recommend_actors`, {
      actorId,
      type: 'close_conditions',
    }).then(res => {
      setCloseConditionsActors(camelizeKeys(res.body) as Actor[]);
    });
  }, []);
  const onPressClose = React.useCallback(() => {
    navigation.pop(2);
  }, []);
  return (
    <Layout
      title={'購入完了'}
      navigation={navigation}
      back={disableNavigation ? false : true}
      close={disableNavigation ? false : true}
      onPressClose={onPressClose}
      footer={disableNavigation ? <CloseWindow /> : null}>
      {actor && <CompleteMessage actor={actor} />}
      {!disableNavigation && sameNameActors && (
        <RecommendActors
          message={'このイラストの衣装差分'}
          actors={sameNameActors}
          disableNavigation={disableNavigation}
        />
      )}
      {!disableNavigation && sameCreatorActors && (
        <RecommendActors
          message={'同じイラストレーターによるイラスト'}
          actors={sameCreatorActors}
          disableNavigation={disableNavigation}
        />
      )}
      {!disableNavigation && otherWriterPurchasedActors && (
        <RecommendActors
          message={'このイラストを購入した人におすすめ'}
          actors={otherWriterPurchasedActors}
          disableNavigation={disableNavigation}
        />
      )}
      {!disableNavigation && closeConditionsActors && (
        <RecommendActors
          message={'条件の近いイラスト'}
          actors={closeConditionsActors}
          disableNavigation={disableNavigation}
        />
      )}
    </Layout>
  );
};

export default React.memo(Complete);
