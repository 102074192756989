import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';

import fullVideoKeyvisuallUri from '../../../../../assets/svgs/full-video-keyvisual.svg';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep1: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        TapNovelMakerでつくった作品を{'\n'}
        動画化することができます。
      </Text>
      <View style={styles.keyVisual}>
        <SvgUriAdapter uri={fullVideoKeyvisuallUri} width={315} height={156} />
      </View>
      <PrimaryButton onPress={onPress}>
        説明を読んで動画化を始める
      </PrimaryButton>
    </View>
  );
};

export default React.memo(ServiceExplanationStep1);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
  keyVisual: {
    marginVertical: 24,
  } as ViewStyle,
});
