import {Dispatch} from 'redux';

import Types from '../../Types';

import Episode from '../../../../domain/entities/response/Episode';

import NetEpisodesRepository from '../../../../data/repositories/writer/response/NetEpisodesRepository';

export interface Params {
  confirmed?: boolean;
  confirmedTips?: boolean;
  confirmedReactions?: boolean;
  confirmedComments?: boolean;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Episode> {
  return async (dispatch: Dispatch<any>): Promise<Episode> => {
    try {
      const payload = await new NetEpisodesRepository().update(id, {
        responseEpisode: params,
      });
      dispatch({payload, type: Types.UpdateResponseEpisodeSuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateResponseEpisodeFailure});
      throw err;
    }
  };
}
