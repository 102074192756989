import * as React from 'react';
import {
  Image as ReactNativeImage,
  ImageStyle,
  ImageURISource,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {unstable_createElement} from 'react-native-web';

import Alert from '../alert/Alert';
import ImageIcon from '../icons/ImageIcon';

import {isAndroid} from '../../../../data/data_stores/net/UserAgent';

interface CompleteData {
  cancelled: boolean;
  uri: string;
  width: number;
  height: number;
  file: File;
}

interface FailData {
  cancelled: boolean;
  error: any;
}

interface Props {
  imageSource: ImageURISource | ImageURISource[] | null;
  imageStyle: StyleProp<ImageStyle>;
  imageContainerStyle: StyleProp<ViewStyle>;
  noImage?: React.ReactNode;
  visibleImageIcon?: boolean;
  imageIconStyle?: StyleProp<ViewStyle>;
  imageIconSize?: number;
  onChangeImage?: (file: File) => void;
  onFailImage?: (message: string) => void;
}

interface State {
  imageSource: ImageURISource | ImageURISource[] | null;
}

export default class ImagePickerAdapter extends React.PureComponent<
  Props,
  State
> {
  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    const {imageSource} = nextProps;
    if (
      imageSource &&
      ImagePickerAdapter.instanceOfImageURISource(imageSource) &&
      imageSource.uri
    ) {
      return {imageSource};
    }
    if (imageSource !== prevState.imageSource) {
      return {imageSource};
    }
    return null;
  }

  private static instanceOfImageURISource = (
    object: any,
  ): object is ImageURISource => {
    return 'uri' in object;
  };

  constructor(props: Props) {
    super(props);
    const imageSource = props.imageSource;
    this.state = {imageSource};
  }

  public render(): React.ReactNode {
    const {
      imageStyle,
      imageContainerStyle,
      noImage,
      visibleImageIcon,
      imageIconStyle,
      imageIconSize,
    } = this.props;
    const {imageSource} = this.state;
    return (
      <View style={styles.container}>
        {unstable_createElement('label', {
          onChange: this.handleUpload(this.handleComplete, this.handleFail),
          style: styles.label,
          children: (
            <>
              {unstable_createElement('input', {
                style: styles.input,
                type: 'file',
                value: '',
                accept: isAndroid
                  ? 'image/png,image/jpg,image/jpeg,video/*'
                  : 'image/png,image/jpg,image/jpeg',
              })}
              <View style={[imageStyle, imageContainerStyle]}>
                {imageSource === null ? (
                  noImage
                ) : (
                  <ReactNativeImage style={imageStyle} source={imageSource} />
                )}
                {visibleImageIcon && imageSource ? (
                  <View style={[styles.imageIcon, imageIconStyle]}>
                    <ImageIcon size={imageIconSize || 20} />
                  </View>
                ) : null}
              </View>
            </>
          ),
        })}
      </View>
    );
  }

  private handleComplete = (data: CompleteData) => {
    const {onChangeImage} = this.props;

    const uri = data.uri;
    const file = data.file;
    (file as any).uri = uri;
    if (onChangeImage) {
      onChangeImage(file);
    }

    this.setState({
      imageSource: {
        uri,
      },
    });
  };

  private handleFail = (data: FailData) => {
    const {onFailImage} = this.props;
    if (onFailImage) {
      onFailImage(`${data.error}`);
    } else {
      Alert.alert(`${data.error}`);
    }
  };

  private handleUpload = (
    onComplete: (data: CompleteData) => void,
    onFail: (data: FailData) => void,
  ) => {
    return (event: React.SyntheticEvent<HTMLLabelElement>) => {
      if (!event.target) {
        return;
      }
      const target = event.target as HTMLInputElement;
      if (!target.files) {
        return;
      }
      const file = target.files[0];
      if (!file) {
        return;
      }

      if (!/^image\/(png|jpg|jpeg)/.test(file.type)) {
        const data = {
          cancelled: false,
          error: '画像はPNGかJPEGでアップロードしてください',
        };
        onFail(data);
        return;
      }
      if (file.size > Math.pow(1024, 2) * 10) {
        const data = {
          cancelled: false,
          error: '画像は10MB以下でアップロードしてください',
        };
        onFail(data);
        return;
      }

      const reader = new FileReader();

      reader.onload = (event: any) => {
        const image = new Image();

        if (!event.target || !event.target.result) {
          return;
        }
        if (typeof event.target.result !== 'string') {
          return;
        }
        image.src = event.target.result;
        image.onload = (event: Event) => {
          if (!event.target) {
            return;
          }
          const target = event.target as any;
          const {width, height} = target;
          const uri = target.src;
          const data = {cancelled: false, uri, width, height, file};
          onComplete(data);
        };
      };

      reader.onerror = err => {
        const data = {
          cancelled: false,
          error: this.convertCodeToMessage(reader.error?.code),
        };
        onFail(data);
      };

      reader.onabort = err => {
        const data = {
          cancelled: false,
          error: this.convertCodeToMessage(reader.error?.code),
        };
        onFail(data);
      };

      reader.readAsDataURL(file);
    };
  };

  private convertCodeToMessage = (code?: number | null) => {
    switch (code) {
      case DOMException.NOT_FOUND_ERR:
        return 'ファイルが見つかりません';
      case DOMException.SECURITY_ERR:
        return 'セキュリティ・エラーです';
      case DOMException.ABORT_ERR:
        return '読み込みが中止されました';
      default:
        return `エラーが発生しました(エラーコード: ${code})`;
    }
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  label: {
    display: 'flex',
    cursor: 'pointer',
  } as any,
  input: {
    border: 0,
    width: 0,
    height: 0,
    visibility: 'hidden' as any,
    display: 'none',
  } as any,
  imageIcon: {
    position: 'absolute',
    backgroundColor: '#efefef',
    right: 0,
    bottom: 0,
    width: 36,
    height: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
