import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import EpisodeCommentBlockingUser from '../../../domain/entities/EpisodeCommentBlockingUser';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetEpisodeCommentBlockingUsersRepository from '../../../data/repositories/writer/NetEpisodeCommentBlockingUsersRepository';

const types = {
  failure: Types.IndexEpisodeCommentBlockingUsersFailure,
  request: Types.IndexEpisodeCommentBlockingUsersRequest,
  success: Types.IndexEpisodeCommentBlockingUsersSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<EpisodeCommentBlockingUser>> {
  return indexGenerics(
    new NetEpisodeCommentBlockingUsersRepository(),
    types,
    params,
  );
}
