import * as React from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputSelectionChangeEventData,
  View,
  ViewStyle,
} from 'react-native';

import SpeechBalloonGridList from './SpeechBalloonGridList';
import Tabs from './Tabs';
import PositionList from './PositionList';

import AttachmentSoundView from '../../../../../shared/sound/AttachmentSoundView';
import AddTextCommandLinksGroup from '../../../../../shared/AddFrequentlySymbolButtonsGroup';
import ElasticBoxInputTextCounter from '../../../../../shared/ElasticBoxInputTextCounter';
import ElasticSpeechBalloonInput from '../../../../../shared/ElasticSpeechBalloonInput';
import VoiceForm from '../../../../../shared/VoiceForm';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';
import UserStatusContext from '../../../../../shared/user_status/UserStatusContext';

import SceneFrame from '../../../../../../view_models/SceneFrame';
import NameLabelColor from '../../../../../../view_models/NameLabelColor';

import generateSceneFrame from '../../../../../../helpers/generateSceneFrame';

import SpeechBalloon from '../../../../../../../domain/entities/SpeechBalloon';
import OrientedSpeechBalloon from '../../../../../../../domain/entities/OrientedSpeechBalloon';
import Voice from '../../../../../../../domain/entities/Voice';
import Sound from '../../../../../../../domain/entities/Sound';
import SceneCommandForm from '../../../../../../../domain/forms/scene_commands/SceneCommandForm';
import Position from '../../../../../../../domain/value_objects/Position';
import Gender from '../../../../../../../domain/value_objects/Gender';

export type TabValue = 'text' | 'speech_balloon' | 'position' | 'voice';

const TAB_ITEMS = [
  {value: 'text', label: '内容'},
  {value: 'speech_balloon', label: '吹き出し'},
  {value: 'position', label: '向き'},
  {value: 'voice', label: 'ボイス'},
] as Array<{value: TabValue; label: string}>;

interface Props {
  storyId: number;
  textInputRef?: React.RefObject<TextInput>;
  currentTab: TabValue;
  orientedSpeechBalloon: OrientedSpeechBalloon;
  text: string;
  overrideCharacterName?: boolean;
  characterName?: string;
  speechBalloons: SpeechBalloon[];
  positions: Position[];
  selectedSpeechBalloonId: number;
  sceneCommandForms: SceneCommandForm[] | null;
  sceneCommandIndex?: number;
  voice: Voice | null;
  sound?: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
  enableVoice?: boolean;
  selection?: {start: number; end?: number};
  onChangeTab: (value: TabValue) => void;
  onChangeText: (text: string) => void;
  onPressSymbol: (symbol: string) => void;
  onChangeName?: (name: string) => void;
  onSelectSpeechBalloon: (speechBalloon: SpeechBalloon) => void;
  onSelectPosition: (
    selectedPosition: Position,
    toggleMultiple?: boolean,
  ) => void;
  onUpdateVoice: (voice: Voice | null) => void;
  onSelectionChange?: (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => void;
  addSound: () => void;
  deleteSound: () => void;
  onChagenRegion?: (start?: number, end?: number) => void;
}

interface State {
  sceneFrame: SceneFrame;
}

export default class Form extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sceneFrame: generateSceneFrame(
        props.sceneCommandForms,
        props.sceneCommandIndex,
      ),
    };
  }

  public render(): React.ReactNode {
    const {
      storyId,
      textInputRef,
      currentTab,
      orientedSpeechBalloon,
      text,
      overrideCharacterName,
      speechBalloons,
      selectedSpeechBalloonId,
      voice,
      sound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      selection,
      positions,
      enableVoice,
      onChangeTab,
      onChangeText,
      onPressSymbol,
      onChangeName,
      onSelectSpeechBalloon,
      onSelectPosition,
      onUpdateVoice,
      onSelectionChange,
      addSound,
      deleteSound,
      onChagenRegion,
    } = this.props;
    return (
      <View style={styles.container}>
        <DimensionContext.Consumer>
          {context => {
            const {width, height} = context.content;
            const speechBalloonHeight =
              height > 300 ? width * aspectRatio : 100;
            return (
              <UserStatusContext.Consumer>
                {userStatusContext => (
                  <View
                    style={{
                      width,
                      height: speechBalloonHeight,
                      marginTop: 52,
                      marginBottom: 20,
                      zIndex: 10,
                    }}>
                    <ElasticSpeechBalloonInput
                      textInputRef={textInputRef}
                      text={text}
                      orientedSpeechBalloon={orientedSpeechBalloon}
                      width={width}
                      height={speechBalloonHeight}
                      hasVoice={!!voice}
                      onChangeText={onChangeText}
                      name={this.getCharacterName()}
                      nameLabelColor={this.getNameLabelColor()}
                      selection={selection}
                      onChangeName={
                        overrideCharacterName ? onChangeName : undefined
                      }
                      onSelectionChange={onSelectionChange}
                    />
                    {(userStatusContext.currentUser?.enabledPaidSubscriber ||
                      userStatusContext.currentUser?.isGradeBlack()) && (
                      <View
                        style={[
                          {
                            position: 'absolute',
                            bottom: -10,
                            left: 20,
                            right: 20,
                          },
                          Platform.select({
                            default: {},
                            web: {
                              touchAction: 'none',
                            } as any,
                          }),
                        ]}>
                        <AttachmentSoundView
                          sound={sound}
                          startTime={selectedSoundStartTime}
                          endTime={selectedSoundEndTime}
                          addSound={addSound}
                          deleteSound={deleteSound}
                          onChagenRegion={onChagenRegion}
                        />
                      </View>
                    )}
                  </View>
                )}
              </UserStatusContext.Consumer>
            );
          }}
        </DimensionContext.Consumer>
        <ElasticBoxInputTextCounter
          style={{marginTop: 16}}
          length={text.length}
        />
        <Tabs<TabValue>
          items={TAB_ITEMS.filter(
            item => enableVoice || item.value !== 'voice',
          )}
          currentValue={currentTab}
          onChangeTab={onChangeTab}
        />
        <AddTextCommandLinksGroup
          style={currentTab === 'text' ? {marginTop: 16} : styles.hide}
          onPressSymbol={onPressSymbol}
        />
        <SpeechBalloonGridList
          style={
            currentTab === 'speech_balloon' ? {marginTop: 16} : styles.hide
          }
          speechBalloons={speechBalloons}
          selectedSpeechBalloonId={selectedSpeechBalloonId}
          onSelectSpeechBalloon={onSelectSpeechBalloon}
        />
        <PositionList
          visible={currentTab === 'position'}
          selectedPositions={positions}
          onSelectPosition={onSelectPosition}
        />
        <VoiceForm
          style={{margin: 16}}
          resetButtonWrapper={{marginTop: 8, marginRight: 8}}
          visible={currentTab === 'voice'}
          voice={voice}
          storyId={storyId}
          onUpdateVoice={onUpdateVoice}
        />
      </View>
    );
  }

  private getNameLabelColor = (): NameLabelColor => {
    const {positions, overrideCharacterName} = this.props;
    const {sceneFrame} = this.state;
    if (overrideCharacterName) {
      return NameLabelColor.Black;
    }
    if (positions.length !== 1) {
      return NameLabelColor.Green;
    }
    const position = positions[0];
    if (sceneFrame.isActive(position)) {
      const character = sceneFrame.get(position);
      if (character) {
        if (character.characterPattern.character.nameLabelColor) {
          return character.characterPattern.character.nameLabelColor;
        }
        const gender = character.characterPattern.character.option.gender;
        if (gender === Gender.Male) {
          return NameLabelColor.Blue;
        } else if (gender === Gender.Female) {
          return NameLabelColor.Pink;
        } else if (gender === Gender.Other) {
          return NameLabelColor.Green;
        }
      }
    }
    return NameLabelColor.Green;
  };

  private getCharacterName = () => {
    const {overrideCharacterName, characterName, positions} = this.props;
    if (overrideCharacterName) {
      return characterName || '';
    }
    const characters = this.getCharacters();
    if (positions.length > 1) {
      return undefined;
    }
    if (characters.length > 1) {
      return undefined;
    }
    return characters.map(character => character.name)[0];
  };

  private getCharacters = () => {
    const {positions} = this.props;
    const {sceneFrame} = this.state;
    const left = sceneFrame.leftCharacter;
    const center = sceneFrame.centerCharacter;
    const right = sceneFrame.rightCharacter;
    const ret = [];
    if (
      left &&
      sceneFrame.isActive(Position.Left) &&
      positions.includes(Position.Left)
    ) {
      ret.push(left.characterPattern.character);
    }
    if (
      center &&
      sceneFrame.isActive(Position.Center) &&
      positions.includes(Position.Center)
    ) {
      ret.push(center.characterPattern.character);
    }
    if (
      right &&
      sceneFrame.isActive(Position.Right) &&
      positions.includes(Position.Right)
    ) {
      ret.push(right.characterPattern.character);
    }
    return ret;
  };
}

const aspectRatio = 160 / 343;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});
