import dateformat from 'dateformat';

import EpisodeAggregationMapper, {
  EpisodeAggregationData,
} from '../../../value_objects/mappers/summary/EpisodeAggregationMapper';

import TapNovelRestApi from '../../../data_stores/net/TapNovelRestApi';

import EpisodeAggregationsRepository from '../../../../domain/repositories/writer/summary/EpisodeAggregationsRepository';
import EpisodeAggregation from '../../../../domain/value_objects/summary/EpisodeAggregation';

export default class NetEpisodeAggregationsRepository
  implements EpisodeAggregationsRepository
{
  private mapper: EpisodeAggregationMapper;
  constructor() {
    this.mapper = new EpisodeAggregationMapper();
  }

  public async findBy(params: {
    storyId: number;
    from?: Date;
    to?: Date;
  }): Promise<Array<EpisodeAggregation>> {
    const path = `/api/writer/summary/episode_aggregations${this.generateQuery(
      params,
    )}`;
    const obj = await TapNovelRestApi.get<Array<EpisodeAggregationData>>(path);
    return obj.body.map(data => this.mapper.map(data));
  }

  public async find(
    id: number,
    options: {from?: Date | undefined; to?: Date | undefined} = {},
  ): Promise<EpisodeAggregation> {
    const path = `/api/writer/summary/episode_aggregations/${id}${this.generateQuery(
      options,
    )}`;
    const obj = await TapNovelRestApi.get<EpisodeAggregationData>(path);
    return this.mapper.map(obj.body);
  }

  private generateQuery(options: {
    storyId?: number;
    from?: Date | undefined;
    to?: Date | undefined;
  }) {
    const ary = [];
    if (options.storyId) {
      ary.push(`story_id=${options.storyId}`);
    }
    if (options.from) {
      ary.push(`from=${dateformat(options.from, 'yyyy.mm.dd')}`);
    }
    if (options.to) {
      ary.push(`to=${dateformat(options.to, 'yyyy.mm.dd')}`);
    }
    if (ary.length > 0) {
      return `?${ary.join('&')}`;
    } else {
      return '';
    }
  }
}
