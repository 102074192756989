export type Status =
  | 'incomplete'
  | 'incomplete_expired'
  | 'trialing'
  | 'active'
  | 'past_due'
  | 'canceled'
  | 'unpaid';

export default class Subscription {
  constructor(
    public id: number,
    public myStripeCustomerId: number,
    public cancelAt: Date | null,
    public cancelAtPeriodEnd: boolean | null,
    public canceledAt: Date | null,
    public currentPeriodStart: Date | null,
    public currentPeriodEnd: Date | null,
    public status: Status,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
