import * as React from 'react';

import BaseIndex from '../../common/sounds/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundMusicShowSceneCommandNewSoundsRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as SoundUsageHistoryUpdateParams} from '../../../../../actions/sound_usage_histories/update';
import {Params as SoundIndexParams} from '../../../../../actions/sounds/index';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import {QueryState} from '../../../../../reducers/queries/Response';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import Sound from '../../../../../../domain/entities/Sound';
import SoundCategory from '../../../../../../domain/entities/SoundCategory';
import SoundUsageHistory from '../../../../../../domain/entities/SoundUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  soundCategoryId?: number;
  selectedLargeSoundCategoryId?: number | null;
  selectedMiddleSoundCategoryId?: number | null;
  selectedSmallSoundCategoryId?: number | null;
  favorite?: boolean;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  sort?: 'popularity' | 'new_arrival';
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundMusicShowSceneCommandNewSoundsRouteProp;
  sceneForm: SceneForm | null;
  soundsParams: SoundIndexParams;
  sounds: Sound[] | null;
  soundCategory: SoundCategory | null;
  parentSceneCommandForm: SceneCommandForm | null;
  soundQueries: QueryState;
  totalCount: number | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexSounds: (params: SoundIndexParams) => Promise<PaginatedResult<Sound>>;
  showSoundCategory: (id: number) => Promise<SoundCategory>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateSoundUsageHistory: (
    id: number,
    params: SoundUsageHistoryUpdateParams,
  ) => Promise<SoundUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedSound: Sound | null;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    const {route} = this.props;
    const {sort} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        sort={sort}
        onSelectSound={this.handleSelectSound}
        onPressSearchConditionChangeButton={
          this.handlePressSearchConditionChangeButton
        }
      />
    );
  }

  private handlePressSearchConditionChangeButton = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      query,
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      favorite,
    } = route.params;
    navigation.navigate(
      'SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormNavigations',
      {
        screen: 'SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm',
        params: {
          storyId,
          episodeId,
          sceneId,
          query,
          selectedLargeSoundCategoryId,
          selectedMiddleSoundCategoryId,
          selectedSmallSoundCategoryId,
          sceneCommandIndex,
          subSceneCommandIndex,
          parentSceneCommandId,
          favorite,
          hideBack: true,
        },
      } as any,
    );
  };

  private handleSelectSound = (sound: Sound): void => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      createSceneCommandForm,
      updateSceneCommandForm,
      updateSoundUsageHistory,
    } = this.props;
    const {storyId, sceneCommandIndex, subSceneCommandIndex} = route.params;
    const sceneCommandForm = new BackgroundMusicShowSceneCommandForm(sound);
    if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.add(
          parentSceneCommandForm,
          sceneCommandForm,
          subSceneCommandIndex !== undefined
            ? {insertIndex: subSceneCommandIndex}
            : undefined,
        ),
      });
    } else {
      createSceneCommandForm({
        index: sceneCommandIndex,
        sceneCommandForm,
      });
    }
    updateSoundUsageHistory(sound.id, {
      storyId,
      soundType: 'background_music',
    }).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };
}
