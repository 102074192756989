import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from '../Response';

import Types from '../../../actions/Types';

import Episode from '../../../../domain/entities/response/Episode';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexResponseEpisodesSuccess: {
      const result = action.payload as PaginatedResult<Episode>;
      return mergeResponse(state, result);
    }
    default:
      return state;
  }
}
