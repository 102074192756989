import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import Bullet from './Bullet';

interface Props {
  pages: number;
  initialPage?: number;
  animateToPage?: (page: number) => void;
}

interface State {
  currentPage: number;
}

export default class Navigator extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const currentPage = props.initialPage ? props.initialPage : 0;
    this.state = {currentPage};
  }

  public render(): React.ReactNode {
    const {pages} = this.props;
    const {currentPage} = this.state;
    const bullets = [];
    for (let i = 0; i < pages; i += 1) {
      bullets.push(
        <Bullet
          key={`${i}`}
          page={i}
          currentPage={currentPage}
          onSelectPage={this.handleSelectPage}
        />,
      );
    }
    return (
      <View style={styles.container}>
        <View style={styles.bulletsContainer}>{bullets}</View>
      </View>
    );
  }

  public goToPage(page: number) {
    this.setState({currentPage: page});
  }

  private handleSelectPage = (page: number) => {
    const {animateToPage} = this.props;
    if (animateToPage) {
      animateToPage(page);
    }
    this.goToPage(page);
  };
}

const styles = StyleSheet.create({
  bulletsContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  container: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    bottom: 0,
    flexDirection: 'row',
    height: 30,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
  } as ViewStyle,
});
