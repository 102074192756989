import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/background_music_show_scene_command/edit/sounds/Index';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import showSoundCategory from '../../../../../actions/sound_categories/show';
import updateSoundUsageHistory, {
  Params as SoundUsageHistoryUpdateParams,
} from '../../../../../actions/sound_usage_histories/update';
import indexSounds, {
  Params as SoundIndexParams,
} from '../../../../../actions/sounds/index';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import selectEntities, {
  getTotalCount,
} from '../../../../../helpers/selectEntities';
import selectEntity from '../../../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../../../helpers/ResourceAuthorizattion';

import {SceneFormBackgroundMusicShowSceneCommandEditSoundsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormBackgroundMusicShowSceneCommandEditSoundsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {
    query,
    soundCategoryId,
    sceneCommandId,
    parentSceneCommandId,
    sort,
    favorite,
  } = route.params;
  const sceneForm = forms.sceneForm;
  const soundsParams: SoundIndexParams = {
    query,
    allSoundCategoryId: soundCategoryId,
    onlyBackgroundMusic: true,
    sort: sort || 'popularity',
    favorite,
  };
  const sceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    sceneCommandId,
  );
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  const soundCategory = soundCategoryId
    ? selectEntity(entities.soundCategories, soundCategoryId)
    : null;
  const sounds = selectEntities(entities.sounds, queries.sounds, soundsParams);
  const totalCount = getTotalCount(queries.sounds, soundsParams);
  const soundQueries = queries.sounds;
  return {
    sceneForm,
    soundCategory,
    sounds,
    soundsParams,
    navigation,
    sceneCommandForm,
    parentSceneCommandForm,
    soundQueries,
    totalCount,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexSounds: (params: SoundIndexParams) => dispatch(indexSounds(params)),
    showSoundCategory: (id: number) => dispatch(showSoundCategory(id)),
    updateSoundUsageHistory: (
      id: number,
      params: SoundUsageHistoryUpdateParams,
    ) => dispatch(updateSoundUsageHistory(id, params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
