import * as React from 'react';

import BaseIndex from '../../common/effect_positions/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormEffectShowSceneCommandEditEffectPositionsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as EffectUsageHistoryUpdateParams} from '../../../../../actions/effect_usage_histories/update';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import Effect from '../../../../../../domain/entities/Effect';
import EffectUsageHistory from '../../../../../../domain/entities/EffectUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import Position from '../../../../../../domain/value_objects/Position';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  effectId: number;
  sceneCommandId: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandEditEffectPositionsRouteProp;
  sceneForm: SceneForm | null;
  effect: Effect | null;
  parentSceneCommandForm: SceneCommandForm | null;
}

export interface DispatchProps {
  showEffect: (id: number) => Promise<Effect>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateEffectUsageHistory: (
    id: number,
    params: EffectUsageHistoryUpdateParams,
  ) => Promise<EffectUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedEffect: Effect | null;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }
  private handleSubmit = (position: Position): void => {
    const {effect} = this.props;
    if (!effect) {
      return;
    }
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      updateSceneCommandForm,
      updateEffectUsageHistory,
    } = this.props;
    const {storyId, sceneCommandId} = route.params;
    const sceneCommandForm = new EffectShowSceneCommandForm(
      effect,
      position,
      null,
      null,
      null,
      null,
      null,
      sceneCommandId,
    );
    if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          sceneCommandForm,
        ),
      });
    } else {
      updateSceneCommandForm({
        sceneCommandForm,
      });
    }
    updateEffectUsageHistory(effect.id, {storyId}).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };
}
