import {Dispatch} from 'redux';

import Types from '../Types';

import SceneCommandForm from '../../../domain/forms/scene_commands/SceneCommandForm';

export interface Params {
  sceneCommandForm: SceneCommandForm;
}

export default function action(params: Params) {
  return (dispatch: Dispatch<any>) => {
    const payload = params;
    dispatch({
      payload,
      type: Types.DestroySceneCommandForm,
    });
    return new Promise(resolve => resolve(payload));
  };
}
