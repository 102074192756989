import * as React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

interface Props {
  regulatoryViolationStatus: 'requesting' | 'applying' | 'rejected';
}

const ViolationStateLabel: React.FunctionComponent<Props> = props => {
  const {regulatoryViolationStatus} = props;
  return (
    <Text
      style={[
        styles.label,
        regulatoryViolationStatus === 'requesting'
          ? styles.notPublished
          : regulatoryViolationStatus === 'applying'
          ? styles.notPublished
          : regulatoryViolationStatus === 'rejected'
          ? styles.notPublished
          : styles.notPublished,
      ]}>
      {regulatoryViolationStatus === 'requesting'
        ? '規約違反'
        : regulatoryViolationStatus === 'applying'
        ? '申請中'
        : regulatoryViolationStatus === 'rejected'
        ? '非承認'
        : '規約違反'}
    </Text>
  );
};

export default React.memo(ViolationStateLabel);

const styles = StyleSheet.create({
  label: {
    lineHeight: 12,
    fontSize: 8,
    fontWeight: 'bold',
    height: 15,
    minWidth: 35,
    maxWidth: 40,
    paddingHorizontal: 3,
    paddingTop: 1,
    textAlign: 'center',
  } as TextStyle,
  notPublished: {
    backgroundColor: '#383838',
    color: 'white',
  } as TextStyle,
  published: {
    backgroundColor: '#ff8f13',
    color: 'white',
  } as TextStyle,
  scheduledDeliveredAt: {
    borderColor: '#ff8f13',
    borderWidth: 1,
    color: '#ff8f13',
  } as TextStyle,
});
