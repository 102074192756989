import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import UserResourcePurchaseMapper, {
  UserResourcePurchaseData,
} from '../../entities/mappers/writer/UserResourcePurchaseMapper';

import UserResourcePurchase from '../../../domain/entities/writer/UserResourcePurchase';
import UserResourcePurchasesRepository from '../../../domain/repositories/writer/UserResourcePurchasesRepository';

export default class NetUserResourcePurchasesRepository
  extends NetResourcesRepository<UserResourcePurchase, UserResourcePurchaseData>
  implements UserResourcePurchasesRepository
{
  constructor(type: 'actor') {
    super(
      new NetResourcesRepositoryAdapter(
        `/api/writer/user_${type}_purchases`,
        new UserResourcePurchaseMapper(),
      ),
    );
  }
}
