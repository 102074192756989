import EntityMapper from './EntityMapper';

import FramePartPositionMapper, {
  FramePartPositionData,
} from '../../value_objects/mappers/FramePartPositionMapper';

import TextFramePart from '../../../domain/entities/TextFramePart';

export interface TextFramePartData {
  id: number;
  text_frame_id: number;
  frame_part_position: FramePartPositionData;
  original_image_url: string;
}

export default class TextFramePartMapper
  implements EntityMapper<TextFramePartData, TextFramePart>
{
  private framePartPositionMapper: FramePartPositionMapper;

  constructor() {
    this.framePartPositionMapper = new FramePartPositionMapper();
  }

  public map(obj: TextFramePartData): TextFramePart {
    const framePartPosition = this.framePartPositionMapper.map(
      obj.frame_part_position,
    );
    return new TextFramePart(
      obj.id,
      obj.text_frame_id,
      framePartPosition,
      obj.original_image_url,
    );
  }
}
