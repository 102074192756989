import * as React from 'react';
import {InteractionManager} from 'react-native';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import PreviewBox from '../../shared/cover_image_form/PreviewBox';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormImageCropRouteProp} from '../../../navigators/RouteProps';

import {Params as CoverImageFormUpdateParams} from '../../../actions/cover_image_forms/update';

import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import File from '../../../../domain/entities/File';

import fetchBlob from '../../../../data/data_stores/net/FetchBlob';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  uri: string;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormImageCropRouteProp;
  coverImageForm: CoverImageForm | null;
}

export interface DispatchProps {
  updateCoverImageForm: (params: CoverImageFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  image: File | null;
  loading: boolean;
  base64Uri: string | null;
  fetchedBackgroundImageUri: boolean;
  backgroundImageUri: string | null;
}

export default class Index extends React.PureComponent<Props, State> {
  private ref = React.createRef<PreviewBox>();

  constructor(props: Props) {
    super(props);
    this.state = {
      image: null,
      loading: false,
      base64Uri: null,
      fetchedBackgroundImageUri: false,
      backgroundImageUri: null,
    };
  }

  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    const {uri} = route.params;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
    InteractionManager.runAfterInteractions(() => {
      this.fetchBlob(uri).then(base64Uri => {
        this.setState({base64Uri});
      });
    });
  }

  public render(): React.ReactNode {
    const {navigation, route, coverImageForm} = this.props;
    const {loading, base64Uri, fetchedBackgroundImageUri, backgroundImageUri} =
      this.state;
    const {aspectRatio} = route.params;
    return (
      <Layout
        title={'背景'}
        scrollable={true}
        navigation={navigation}
        back={true}
        close={true}
        loading={loading}>
        <Form
          uri={base64Uri}
          aspectRatio={aspectRatio}
          onSubmit={this.handleSubmit}
        />
        {coverImageForm && fetchedBackgroundImageUri && (
          <PreviewBox
            ref={this.ref}
            backgroundImageUri={backgroundImageUri}
            characterImageUri={coverImageForm.getCharacterImageUri()}
            aspectRatio={aspectRatio}
            imageTextInfoList={coverImageForm.getImageTextInfoList()}
            onDrawCanvas={this.handleDrawCanvas}
          />
        )}
      </Layout>
    );
  }

  private handleSubmit = (image: File) => {
    this.setState({
      fetchedBackgroundImageUri: true,
      backgroundImageUri: image.uri,
      loading: true,
    });
  };

  private handleDrawCanvas = (image: File | null) => {
    const {navigation, updateCoverImageForm} = this.props;
    const {backgroundImageUri} = this.state;
    if (!this.ref.current) {
      return;
    }
    if (image) {
      updateCoverImageForm({
        image,
        backgroundImageUri,
        uploadedSelfImage: false,
      });
      this.setState({loading: false}, () => {
        navigation.getParent()?.goBack();
      });
    } else {
      this.setState({loading: false});
    }
  };

  private async fetchBlob(uri: string): Promise<string> {
    return fetchBlob(uri);
  }
}
