import React from 'react';

import RootStack from '../../../../RootStack';

import SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm from '../../../../../containers/scene_form/illustration_show_scene_command/new/illustration_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm">
      <RootStack.Screen
        name="SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm"
        component={
          SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm
        }
        options={{title: 'アイテム検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
