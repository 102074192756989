import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import Tag from '../../../shared/Tag';

interface Props {
  names: string[];
  onRemove?: (name: string) => void;
  children?: React.ReactNode;
}

const KeywordTags: React.FunctionComponent<Props> = props => {
  const {names, onRemove} = props;
  return (
    <View style={styles.container}>
      {names.map(name => (
        <Tag key={name} name={name} onRemove={onRemove} />
      ))}
    </View>
  );
};

export default React.memo(KeywordTags);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: 10,
  } as ViewStyle,
});
