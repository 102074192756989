import React from 'react';

import RootStack from '../../../../RootStack';

import SceneFormEffectShowSceneCommandEditBaseEffectSearchForm from '../../../../../containers/scene_form/effect_show_scene_command/edit/base_effect_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormEffectShowSceneCommandEditBaseEffectSearchForm">
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandEditBaseEffectSearchForm"
        component={SceneFormEffectShowSceneCommandEditBaseEffectSearchForm}
        options={{title: 'エフェクト検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
