import * as React from 'react';

import BaseIndex from '../../common/effects/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormEffectShowSceneCommandNewEffectsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as EffectUsageHistoryUpdateParams} from '../../../../../actions/effect_usage_histories/update';
import {Params as EffectIndexParams} from '../../../../../actions/effects/index';
import {Params as PositionedEffectIndexParams} from '../../../../../actions/positioned_effects/index';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import * as routers from '../../../../../routers';

import Effect from '../../../../../../domain/entities/Effect';
import EffectUsageHistory from '../../../../../../domain/entities/EffectUsageHistory';
import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import PositionedEffect from '../../../../../../domain/entities/PositionedEffect';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import Position from '../../../../../../domain/value_objects/Position';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  baseEffectId: number;
  parentSceneCommandId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandNewEffectsRouteProp;
  sceneForm: SceneForm | null;
  baseEffect: BaseEffect | null;
  effectsParams: EffectIndexParams;
  effects: Effect[] | null;
  parentSceneCommandForm: SceneCommandForm | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexEffects: (params: EffectIndexParams) => Promise<PaginatedResult<Effect>>;
  showBaseEffect: (id: number) => Promise<BaseEffect>;
  indexPositionedEffects: (
    params: PositionedEffectIndexParams,
  ) => Promise<PaginatedResult<PositionedEffect>>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateEffectUsageHistory: (
    id: number,
    params: EffectUsageHistoryUpdateParams,
  ) => Promise<EffectUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  private disabledSubmit = false;

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (effect: Effect): void => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      createSceneCommandForm,
      updateSceneCommandForm,
      updateEffectUsageHistory,
      indexPositionedEffects,
    } = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    indexPositionedEffects({effectId: effect.id}).then(result => {
      if (result.records.length < 5) {
        const sceneCommandForm = new EffectShowSceneCommandForm(
          effect,
          Position.Center,
        );
        if (parentSceneCommandForm) {
          updateSceneCommandForm({
            sceneCommandForm: CompositeSequenceSceneCommandFormFactory.add(
              parentSceneCommandForm,
              sceneCommandForm,
              subSceneCommandIndex !== undefined
                ? {insertIndex: subSceneCommandIndex}
                : undefined,
            ),
          });
        } else {
          createSceneCommandForm({
            index: sceneCommandIndex,
            sceneCommandForm,
          });
        }
        updateEffectUsageHistory(effect.id, {storyId}).then(() => {
          (navigation.getParent() || navigation).goBack();
        });
      } else {
        routers.linkToSceneFormEffectShowSceneCommandNewEffectPositions(
          navigation,
          {
            effectId: effect.id,
            storyId,
            episodeId,
            sceneId,
            sceneCommandIndex,
            subSceneCommandIndex,
            parentSceneCommandId,
          },
        );
      }
    });
  };
}
