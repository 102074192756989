import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import CheckButton from './forms/CheckButton';

interface Props {
  style?: StyleProp<ViewStyle>;
  checked: boolean;
  messageText: string;
  labelText: string;
  renderHeader?: React.ReactNode;
  onPress: () => void;
}

const LabelAndCheck: React.FunctionComponent<Props> = props => {
  const {style, checked, messageText, labelText, renderHeader, onPress} = props;
  return (
    <View style={[styles.container, style]}>
      {renderHeader}
      <Text style={styles.message}>{messageText}</Text>
      <View style={styles.checkButtons}>
        <CheckButton checked={checked} onPress={onPress}>
          {labelText}
        </CheckButton>
      </View>
    </View>
  );
};

export default React.memo(LabelAndCheck);

const styles = StyleSheet.create({
  container: {
    margin: 16,
    padding: 16,
    backgroundColor: '#fafafa',
    borderRadius: 4,
  } as ViewStyle,
  message: {
    alignSelf: 'center',
    color: '#666',
    fontSize: 11,
  } as TextStyle,
  checkButtons: {
    marginTop: 11,
  } as ViewStyle,
});
