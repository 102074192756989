import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import StoryMapper, {
  StoryData,
} from '../../../entities/mappers/response/StoryMapper';

import Story from '../../../../domain/entities/response/Story';
import StoriesRepository from '../../../../domain/repositories/writer/response/StoriesRepository';

export default class NetStoriesRepository
  extends NetResourcesRepository<Story, StoryData>
  implements StoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/response/stories',
        new StoryMapper(),
      ),
    );
  }
}
