import * as React from 'react';
import {camelizeKeys} from 'humps';

import {FormValues} from './components/FormData';
import Form from './components/Form';
import Confirm from './components/Confirm';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import NavigationProp from '../../../navigators/NavigationProp';

import {NewFaqReplyRouteProp} from '../../../navigators/RouteProps';

import {Params as ApplicationModalUpdateParams} from '../../../actions/application_modal/update';

import Question from '../../../../domain/entities/writer/faq/Question';
import Answer from '../../../../domain/entities/writer/faq/Answer';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  answerId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewFaqReplyRouteProp;
}

export interface DispatchProps {
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FunctionComponent<Props> = props => {
  const {navigation, route, updateApplicationModal} = props;
  const {answerId} = route.params;
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [formValues, setFormValues] = React.useState<FormValues | null>(null);
  const onPressBack = React.useCallback(() => {
    setFormValues(null);
  }, []);
  const onPressSubmit = React.useCallback(() => {
    if (!formValues) {
      return null;
    }
    setLoading(true);
    const {content} = formValues;
    TapNovelRestApi.post('/api/writer/faq/replies', {
      reply: {
        writerFaqAnswerId: answerId,
        content,
      },
    })
      .then(response => {
        setLoading(false);
        navigation.goBack();
        TapNovelRestApi.get(`/api/writer/faq/answers/${answerId}`).then(
          response => {
            const answer = camelizeKeys(response.body) as Answer;
            TapNovelRestApi.get(
              `/api/writer/faq/questions/${answer.writerFaqQuestionId}`,
            ).then(response => {
              const question = camelizeKeys(response.body) as Question;
              updateApplicationModal({
                options: {
                  message: question.resolvedAt
                    ? ''
                    : 'お問い合わせは解決しましたか？',
                  title: '返信を公開いたしました！',
                  action: question.resolvedAt
                    ? undefined
                    : {
                        label: '解決済みにする',
                        callback: () => {
                          TapNovelRestApi.patch(
                            `/api/writer/faq/questions/${question.id}`,
                            {
                              question: {
                                resolved: true,
                              },
                            },
                          ).then(() => {
                            navigation.replace('FaqQuestion', {
                              id: question.id,
                            });
                          });
                        },
                      },
                },
              });
            });
          },
        );
      })
      .catch(() => {
        setLoading(false);
      });
  }, [formValues]);
  const onCloseAlertModal = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  return (
    <Layout
      title={'返信フォーム'}
      navigation={navigation}
      scrollable={false}
      loading={loading}
      back={true}>
      <Form hidden={!!formValues} onPressConfirm={setFormValues} />
      {formValues ? (
        <Confirm
          formValues={formValues}
          onPressBack={onPressBack}
          onPressSubmit={onPressSubmit}
        />
      ) : null}
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseAlertModal}>
          {alertMessage}
        </AlertModal>
      )}
    </Layout>
  );
};

export default React.memo(New);
