import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/episode_form/edit/episode_publication/Index';

import AppState from '../../../../reducers/AppState';

import showEpisode from '../../../../actions/episodes/show';
import showStory from '../../../../actions/stories/show';
import updateStory, {
  Params as StoryUpdateParams,
} from '../../../../actions/stories/update';
import updateEpisodePublication, {
  Params as EpisodePublicationUpdateParams,
} from '../../../../actions/episode_publications/update';

import selectEntity from '../../../../helpers/selectEntity';

import {EpisodeFormEditEpisodePublicationRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EpisodeFormEditEpisodePublicationRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities} = state;
  const {episodeId} = route.params;
  const episode = selectEntity(entities.episodes, episodeId);
  return {
    episode,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showEpisode: (id: number) => dispatch(showEpisode(id)),
    showStory: (id: number) => dispatch(showStory(id)),
    updateStory: (id: number, params: StoryUpdateParams) =>
      dispatch(updateStory(id, params)),
    updateEpisodePublication: (
      id: number,
      params: EpisodePublicationUpdateParams,
    ) => dispatch(updateEpisodePublication(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
