import {Dispatch} from 'redux';

import Types from '../../Types';

import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

import NetCurrentUserStatusRepository from '../../../../data/repositories/writer/NetCurrentUserStatusRepository';

export default function action(): (
  dispatch: Dispatch<any>,
) => Promise<CurrentUserStatus> {
  return async (dispatch: Dispatch<any>): Promise<CurrentUserStatus> => {
    try {
      const payload = await new NetCurrentUserStatusRepository().find();
      dispatch({payload, type: Types.ShowWriterCurrentUserStatusSuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.ShowWriterCurrentUserStatusFailure});
      throw err;
    }
  };
}
