import * as React from 'react';
import {ImageStyle, StyleSheet, View} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';

import {
  actorCharacterFaceImageUrl,
  markImageUrl,
} from '../../../../../../helpers/images';

import ActorCharacterFace from '../../../../../../../domain/entities/ActorCharacterFace';
import Mark from '../../../../../../../domain/entities/Mark';

const MARK_IMAGE_SIZE = 'large';
const CHARACTER_IMAGE_SIZE = 'middle';

const resizeMode = 'contain';
const headers = {Accept: 'image/webp,image/apng,*/*'};

interface Props {
  actorCharacterFace: ActorCharacterFace;
  mark?: Mark | null;
  inverted?: boolean;
  children?: React.ReactNode;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {actorCharacterFace, mark, inverted} = props;
  return (
    <View>
      {mark && (
        <FastImageAdapter
          style={styles.mark}
          resizeMode={resizeMode}
          source={{
            uri: markImageUrl(mark, MARK_IMAGE_SIZE),
            headers,
          }}
        />
      )}
      <View style={styles.imageWrapper}>
        <FastImageAdapter
          resizeMode={resizeMode}
          style={[styles.image, inverted ? styles.inverted : null]}
          source={{
            uri: actorCharacterFaceImageUrl(
              actorCharacterFace,
              CHARACTER_IMAGE_SIZE,
            ),
            headers,
          }}
        />
      </View>
    </View>
  );
};

export default React.memo(CharacterView);

const markWidth = 25;
const markHeight = 25;
const markAspectRatio = markWidth / markHeight;

const characterWidth = 200;
const characterHeight = 200;
const characterAspectRatio = characterWidth / characterHeight;

const styles = StyleSheet.create({
  image: {
    aspectRatio: characterAspectRatio,
    height: characterHeight,
    width: characterWidth,
  } as ImageStyle,
  imageWrapper: {
    alignItems: 'center',
    height: 200,
    overflow: 'hidden',
    width: 200,
  } as ImageStyle,
  mark: {
    aspectRatio: markAspectRatio,
    height: markHeight,
    left: 50,
    position: 'absolute',
    width: markWidth,
    zIndex: 10,
  } as ImageStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ImageStyle,
});
