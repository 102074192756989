import {Dispatch} from 'redux';

import EpisodeViewerErrorLog from '../../../domain/entities/EpisodeViewerErrorLog';

import NetEpisodeViewerErrorLogsRepository from '../../../data/repositories/writer/NetEpisodeViewerErrorLogsRepository';

export interface Params {
  episodeId: number;
  sceneId?: number;
  errorUrl: string;
  errorInfo: {
    name: string;
    message: string;
    stack: string;
  };
  platform: 'ios' | 'android' | 'web';
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<EpisodeViewerErrorLog> {
  return async (dispatch: Dispatch<any>): Promise<EpisodeViewerErrorLog> => {
    try {
      const payload = await new NetEpisodeViewerErrorLogsRepository().create({
        episodeViewerErrorLog: params,
      });
      return payload;
    } catch (err) {
      console.log(err);
      throw err;
    }
  };
}
