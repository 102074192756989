import Character from '../entities/Character';
import NameLabelColor from '../value_objects/NameLabelColor';

export default class CharacterForm {
  public id: number | null = null;
  public name: string | null = null;
  public description: string | null = null;
  public actorId: number | null = null;
  public actorCharacterId: number | null = null;
  public defaultCharacterPatternId: number | null = null;
  public actorCharacterFaceId: number | null = null;
  public actorCharacterFaceName: string | null = null;
  public published = false;
  public nameLabelColor?: NameLabelColor | null = null;
  public voiceActorName?: string | null = null;

  public bind(character: Character) {
    this.id = character.id;
    this.name = character.name;
    this.description = character.description;
    this.actorId = character.actorId;
    this.actorCharacterId = character.actorCharacterId;
    this.defaultCharacterPatternId = character.defaultCharacterPatternId;
    this.actorCharacterFaceId = character.actorCharacterFace.id;
    this.actorCharacterFaceName = character.actorCharacterFace.faceName;
    this.published = character.published;
    this.nameLabelColor = character.nameLabelColor;
    this.voiceActorName = character.voiceActorName;
  }

  public toParams(): {[key: string]: any} {
    return {
      actorCharacterId: this.actorCharacterId,
      actorCharacterFaceId: this.actorCharacterFaceId,
      description: this.description,
      name: this.name,
      published: this.published ? 1 : 0,
      nameLabelColor: this.nameLabelColor,
      voiceActorName: this.voiceActorName,
    };
  }
}
