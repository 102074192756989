import {
  Options,
  generateBody,
  generateGetUrl,
  generateHeaders,
} from './ApiUtils';

export interface Result<T> {
  headers: Headers;
  body: T;
  status: number;
}

export default class RestApi {
  public static async get<T>(
    url: string,
    params?: {[key: string]: any} | null,
  ): Promise<Result<T>> {
    const urlWithQuery = generateGetUrl(url, params);
    const headers = await generateHeaders();
    const response = await fetch(urlWithQuery, {
      headers,
    });
    return this.convertResponse(response);
  }

  public static async post<T>(
    url: string,
    params?: {[key: string]: any} | null,
    options?: Options,
  ): Promise<Result<T>> {
    const headers = await generateHeaders(options);
    const response = await fetch(url, {
      body: generateBody(params, options),
      headers,
      method: 'POST',
    });
    return this.convertResponse(response);
  }

  public static async patch<T>(
    url: string,
    params?: {[key: string]: any} | null,
    options?: Options,
  ): Promise<Result<T>> {
    const headers = await generateHeaders(options);
    const response = await fetch(url, {
      body: generateBody(params, options),
      headers,
      method: 'PATCH',
    });
    return this.convertResponse(response);
  }

  public static async delete(
    url: string,
    params?: {[key: string]: any} | null,
    options?: Options,
  ): Promise<void> {
    const headers = await generateHeaders(options);
    const response = await fetch(url, {
      body: generateBody(params, options),
      headers,
      method: 'DELETE',
    });
    return new Promise((resolve, reject) => {
      if (response.status >= 200 && response.status < 300) {
        return resolve();
      } else {
        response.json().then(body => {
          return reject({
            body,
            headers: response.headers,
            status: response.status,
          });
        });
      }
    });
  }
  private static async convertResponse<T>(
    response: Response,
  ): Promise<Result<T>> {
    const body = response.status === 204 ? {} : await response.json();
    const ret = {
      body,
      headers: response.headers,
      status: response.status,
    };
    if (response.status >= 200 && response.status < 300) {
      return ret;
    } else {
      throw ret;
    }
  }
}
