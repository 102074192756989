import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {
  children?: React.ReactNode;
}

const NoEntities: React.FunctionComponent<Props> = props => {
  const {children} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.message}>{children}</Text>
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(NoEntities, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 16,
    paddingTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  message: {
    color: '#666666',
    fontSize: 15,
  } as TextStyle,
});
