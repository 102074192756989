import SceneCommandMapper, {SceneCommandData} from './SceneCommandMapper';

import CompositeParallelSceneCommand from '../../../../domain/value_objects/scene_commands/CompositeParallelSceneCommand';

export interface CompositeParallelSceneCommandData {
  type: string;
  commands: SceneCommandData[];
}

export default class CompositeParallelSceneCommandMapper {
  public map(
    obj: CompositeParallelSceneCommandData,
  ): CompositeParallelSceneCommand {
    const sceneCommandMapper = new SceneCommandMapper();
    const commands = obj.commands.map(command =>
      sceneCommandMapper.map(command),
    );
    // TODO
    return new CompositeParallelSceneCommand(commands as any);
  }
}
