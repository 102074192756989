import * as React from 'react';

import PrimaryButton from '../../../../../shared/buttons/PrimaryButton';

interface Props {
  onPress: () => void;
}

const NewIllustrationUsageHistoryButton: React.FunctionComponent<
  Props
> = props => {
  const {onPress} = props;
  return <PrimaryButton onPress={onPress}>アイテムを選ぶ</PrimaryButton>;
};

export default React.memo(NewIllustrationUsageHistoryButton);
