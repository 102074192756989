import dateformat from 'dateformat';

import StoryAggregationMapper, {
  StoryAggregationData,
} from '../../../value_objects/mappers/summary/StoryAggregationMapper';

import TapNovelRestApi from '../../../data_stores/net/TapNovelRestApi';

import StoryAggregationsRepository from '../../../../domain/repositories/writer/summary/StoryAggregationsRepository';
import StoryAggregation from '../../../../domain/value_objects/summary/StoryAggregation';

export default class NetStoryAggregationsRepository
  implements StoryAggregationsRepository
{
  private mapper: StoryAggregationMapper;
  constructor() {
    this.mapper = new StoryAggregationMapper();
  }

  public async findBy(params: {
    from?: Date;
    to?: Date;
  }): Promise<Array<StoryAggregation>> {
    const path = `/api/writer/summary/story_aggregations${this.generateQuery(
      params,
    )}`;
    const obj = await TapNovelRestApi.get<Array<StoryAggregationData>>(path);
    return obj.body.map(data => this.mapper.map(data));
  }

  public async find(
    id: number,
    options: {from?: Date | undefined; to?: Date | undefined} = {},
  ): Promise<StoryAggregation> {
    const path = `/api/writer/summary/story_aggregations/${id}${this.generateQuery(
      options,
    )}`;
    const obj = await TapNovelRestApi.get<StoryAggregationData>(path);
    return this.mapper.map(obj.body);
  }

  private generateQuery(options: {
    from?: Date | undefined;
    to?: Date | undefined;
  }) {
    const ary = [];
    if (options.from) {
      ary.push(`from=${dateformat(options.from, 'yyyy.mm.dd')}`);
    }
    if (options.to) {
      ary.push(`to=${dateformat(options.to, 'yyyy.mm.dd')}`);
    }
    if (ary.length > 0) {
      return `?${ary.join('&')}`;
    } else {
      return '';
    }
  }
}
