import EntityMapper from './EntityMapper';

import IllustrationCategory from '../../../domain/entities/IllustrationCategory';

export interface IllustrationCategoryData {
  id: number;
  parent_id: number;
  name: string;
  leaf: boolean;
  illustration_count: number;
}

export default class IllustrationCategoryMapper
  implements EntityMapper<IllustrationCategoryData, IllustrationCategory>
{
  public map(obj: IllustrationCategoryData): IllustrationCategory {
    return new IllustrationCategory(
      obj.id,
      obj.parent_id,
      obj.name,
      obj.leaf,
      obj.illustration_count,
    );
  }
}
