import * as React from 'react';
import {useLinkTo, useFocusEffect} from '@react-navigation/native';

import StoryInfo from './partials/StoryInfo';
import MemberListSection from './partials/MemberListSection';
import Separator from './partials/Separator';
import ProjectInfo from './partials/ProjectInfo';
import ActionButton from './partials/ActionButton';
import ProjectTabs from './partials/ProjectTabs';
import ApplyButton from './partials/ApplyButton';
import BottomPrimaryButton from '../shared/buttons/BottomPrimaryButton';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';
import {ProjectRouteProp} from '../../navigators/RouteProps';

import Project from '../../../domain/entities/Project';
import ProjectUserStatus from '../../../domain/entities/ProjectUserStatus';
import ProjectIteration from '../../../domain/entities/ProjectIteration';
import ProjectMember from '../../../domain/entities/ProjectMember';
import ProjectManuscript from '../../../domain/entities/ProjectManuscript';
import ProjectTopic from '../../../domain/entities/ProjectTopic';
import ProjectStoryAggregation from '../../../domain/entities/ProjectStoryAggregation';

import TapNovelTypedRestApi from '../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route} = props;
  const linkTo = useLinkTo();
  const {id} = route.params;
  const [currentTabPage, setCurrentTabPage] = React.useState(0);
  const [project, setProject] = React.useState<Project | null>(null);
  const [userStatus, setUserStatus] = React.useState<ProjectUserStatus | null>(
    null,
  );
  const [iteration, setIteration] = React.useState<ProjectIteration | null>(
    null,
  );
  const [members, setMembers] = React.useState<ProjectMember[] | null>(null);
  const [manuscripts, setManuscripts] = React.useState<
    ProjectManuscript[] | null
  >(null);
  const [topics, setTopics] = React.useState<ProjectTopic[] | null>(null);
  const [storyAggregation, setStoryAggregation] =
    React.useState<ProjectStoryAggregation | null>(null);
  const rightButton = React.useMemo(() => {
    if (userStatus?.role !== 'leader') {
      return null;
    }
    return {
      tintColor: 'white',
      title: <ApplyButton projectId={id} />,
    };
  }, [userStatus]);
  const onPressMyEpisodes = React.useCallback(() => {
    linkTo(`/projects/${id}/user/episodes`);
  }, []);
  const onPressNewTopic = React.useCallback(() => {
    linkTo(`/projects/${id}/topics/new`);
  }, []);
  const fetchEntities = React.useCallback(() => {
    TapNovelTypedRestApi.get<Project>(`/api/writer/projects/${id}`).then(
      result => {
        setProject(result.body);
      },
    );
    TapNovelTypedRestApi.get<ProjectUserStatus>(
      '/api/writer/project_user_status',
      {projectId: id},
    ).then(result => {
      setUserStatus(result.body);
    });
    TapNovelTypedRestApi.get<ProjectIteration>(
      '/api/writer/current_project_iteration',
      {projectId: id},
    )
      .then(result => {
        setIteration(result.body);
      })
      .catch(() => {});
    TapNovelTypedRestApi.get<ProjectMember[]>(`/api/writer/project_members`, {
      projectId: id,
    }).then(result => {
      setMembers(result.body);
    });
    TapNovelTypedRestApi.get<ProjectStoryAggregation>(
      `/api/writer/project_story_aggregations/${id}`,
    ).then(result => {
      setStoryAggregation(result.body);
    });
    TapNovelTypedRestApi.get<ProjectManuscript[]>(
      `/api/writer/project_manuscripts`,
      {projectId: id},
    )
      .then(result => {
        setManuscripts(result.body);
      })
      .catch(() => {});
    TapNovelTypedRestApi.get<ProjectTopic[]>(`/api/writer/project_topics`, {
      projectId: id,
    })
      .then(result => {
        setTopics(result.body);
      })
      .catch(() => {});
  }, []);
  useFocusEffect(fetchEntities);
  const onForceUpdate = React.useCallback(() => {
    fetchEntities();
  }, []);
  return (
    <Layout
      title={'プロジェクト'}
      navigation={navigation}
      back={true}
      rightButton={rightButton}
      footer={
        currentTabPage === 0 ? (
          manuscripts && iteration ? (
            <BottomPrimaryButton
              bottomButtonStyle={{backgroundColor: 'rgba(255, 255, 255, 0.9)'}}
              onPress={onPressMyEpisodes}>
              エピソード管理
            </BottomPrimaryButton>
          ) : null
        ) : topics ? (
          <BottomPrimaryButton
            bottomButtonStyle={{backgroundColor: 'rgba(255, 255, 255, 0.9)'}}
            onPress={onPressNewTopic}>
            トピックを作る
          </BottomPrimaryButton>
        ) : null
      }>
      {project && userStatus && (
        <>
          {storyAggregation && (
            <StoryInfo
              project={project}
              storyAggregation={storyAggregation}
              isLeader={userStatus.role === 'leader'}
              isMember={userStatus.role !== 'unmember'}
            />
          )}
          {members && (
            <MemberListSection
              projectId={project.id}
              members={members.slice(0, 6)}
              totalMembersCount={members.length}
            />
          )}
          {userStatus.role !== 'unmember' ? (
            manuscripts &&
            topics && (
              <ProjectTabs
                projectId={id}
                currentTabPage={currentTabPage}
                iteration={iteration}
                manuscripts={manuscripts}
                topics={topics}
                isLeader={userStatus.role === 'leader'}
                setCurrentTabPage={setCurrentTabPage}
                onForceUpdate={fetchEntities}
              />
            )
          ) : (
            <>
              <Separator />
              <ProjectInfo project={project} />
              <ActionButton
                project={project}
                userStatus={userStatus}
                onForceUpdate={onForceUpdate}
              />
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default React.memo(Show);
