import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import Layout from '../../shared/Layout';

import Detail from './partials/Detail';

import NavigationProp from '../../../navigators/NavigationProp';
import {WritersGateOfferRouteProp} from '../../../navigators/RouteProps';

import Offer from '../../../../domain/entities/writers_gate/Offer';
import OfferManuscript from '../../../../domain/entities/writers_gate/OfferManuscript';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: WritersGateOfferRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {
  children?: React.ReactNode;
}

const Show: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {id} = route.params;
  const [offer, setOffer] = React.useState<Offer | null>(null);
  const [submittedCount, setSubmittedCount] = React.useState<number | null>(
    null,
  );
  React.useEffect(() => {
    TapNovelTypedRestApi.get<Offer>(`/api/writer/writers_gate/offers/${id}`)
      .then(result => {
        setOffer(result.body);
      })
      .catch(error => {
        if (error.status === 403) {
          navigation.navigate('Home', {});
        }
      });
  }, []);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelTypedRestApi.get<OfferManuscript[]>(
        `/api/writer/writers_gate/offer_manuscripts`,
        {
          writersGateOfferId: id,
          submitted: true,
        },
      ).then(result => {
        setSubmittedCount(result.body.length);
      });
    }, []),
  );
  return (
    <Layout title={'オファー詳細'} navigation={navigation} back={true}>
      {offer && submittedCount != null ? (
        <Detail offer={offer} submittedCount={submittedCount} />
      ) : null}
    </Layout>
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(Show, areEqual);
