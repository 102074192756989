import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import CharacterPattern from '../../../domain/entities/CharacterPattern';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetCharacterPatternsRepository from '../../../data/repositories/writer/NetCharacterPatternsRepository';

const types = {
  failure: Types.IndexCharacterPatternsFailure,
  request: Types.IndexCharacterPatternsRequest,
  success: Types.IndexCharacterPatternsSuccess,
};

export interface Params extends BaseParams {
  storyId?: number;
  storyIdOfEpisodeId?: number;
  storyIdOfSceneId?: number;
  characterId?: number;
  actorCharacterId?: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<CharacterPattern>> {
  return indexGenerics(new NetCharacterPatternsRepository(), types, params);
}
