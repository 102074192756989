import * as React from 'react';

import MemberListWithLeader from './partials/MemberListWithLeader';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectMembersRouteProp} from '../../../navigators/RouteProps';

import ProjectMember from '../../../../domain/entities/ProjectMember';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectMembersRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {projectId} = route.params;
  const [leader, setLeader] = React.useState<ProjectMember | null>(null);
  const [members, setMembers] = React.useState<ProjectMember[] | null>(null);
  const fetchMembers = React.useCallback(() => {
    TapNovelTypedRestApi.get<ProjectMember[]>('/api/writer/project_members', {
      projectId,
    }).then(result => {
      const allMembers = result.body;
      setLeader(allMembers[0]);
      setMembers(allMembers.slice(1));
    });
  }, []);
  React.useEffect(fetchMembers, []);
  return (
    <Layout
      title={'プロジェクトメンバー一覧'}
      navigation={navigation}
      back={true}>
      {leader && members ? (
        <MemberListWithLeader
          leader={leader}
          members={members}
          onForceUpdate={fetchMembers}
        />
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
