import React from 'react';

import RootStack from '../RootStack';

import FullVideoExportJobExecutions from '../../containers/full_video/export_job_executions/Index';
import FullVideoExportJobExecution from '../../containers/full_video/export_job_executions/Show';
import NewFullVideoExportJobExecution from '../../containers/full_video/export_job_executions/New';
import NewFullVideoExportJobExecutionFormImage from '../../containers/full_video/export_job_execution_form/image/New';
import FullVideoStories from '../../containers/full_video/stories/Index';
import FullVideoStory from '../../containers/full_video/stories/Show';

import ExportStories from '../../containers/export/stories/Index';
import ExportStory from '../../containers/export/stories/Show';
import ExportEpisode from '../../containers/export/episodes/Show';

const MemberOnlyScreens = (
  <>
    <RootStack.Screen
      name="FullVideoExportJobExecutions"
      component={FullVideoExportJobExecutions}
      options={{title: '動画化'}}
    />
    <RootStack.Screen
      name="NewFullVideoExportJobExecution"
      component={NewFullVideoExportJobExecution}
      options={{title: '動画情報'}}
    />
    <RootStack.Screen
      name="NewFullVideoExportJobExecutionFormImage"
      component={NewFullVideoExportJobExecutionFormImage}
      options={{title: 'サムネイル'}}
    />
    <RootStack.Screen
      name="FullVideoExportJobExecution"
      component={FullVideoExportJobExecution}
      options={{title: '動画一覧'}}
    />
    <RootStack.Screen
      name="FullVideoStories"
      component={FullVideoStories}
      options={{title: '動画化するストーリーを選択'}}
    />
    <RootStack.Screen
      name="FullVideoStory"
      component={FullVideoStory}
      options={{title: '動画化するエピソードを選択'}}
    />
    <RootStack.Screen
      name="ExportStories"
      component={ExportStories}
      options={{title: 'エクスポートするストーリーを選択'}}
    />
    <RootStack.Screen
      name="ExportStory"
      component={ExportStory}
      options={{title: 'エクスポートするエピソードを選択'}}
    />
    <RootStack.Screen
      name="ExportEpisode"
      component={ExportEpisode}
      options={{title: 'エクスポート形式'}}
    />
  </>
);

export default MemberOnlyScreens;
