import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import BottomPrimaryButton from '../../../shared/buttons/BottomPrimaryButton';

interface Props {
  style?: StyleProp<ViewStyle>;
  onForwardToNewEpisodeVideoExportJobExecution: () => void;
}

const NewEpisodeVideoExportJobExecutionButton: React.FunctionComponent<
  Props
> = props => {
  const {style, onForwardToNewEpisodeVideoExportJobExecution} = props;
  return (
    <BottomPrimaryButton
      bottomButtonStyle={style}
      onPress={onForwardToNewEpisodeVideoExportJobExecution}>
      新しい動画を作成
    </BottomPrimaryButton>
  );
};

export default React.memo(NewEpisodeVideoExportJobExecutionButton);
