import EntityMapper from '../EntityMapper';

import AnnouncementCategory from '../../../../domain/entities/writer/AnnouncementCategory';

export interface AnnouncementCategoryData {
  id: number;
  name: string;
}

export default class AnnouncementCategoryMapper
  implements EntityMapper<AnnouncementCategoryData, AnnouncementCategory>
{
  public map(obj: AnnouncementCategoryData): AnnouncementCategory {
    return new AnnouncementCategory(obj.id, obj.name);
  }
}
