import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ManualMapper, {
  ManualData,
} from '../../entities/mappers/writer/ManualMapper';

import Manual from '../../../domain/entities/writer/Manual';
import ManualsRepository from '../../../domain/repositories/writer/ManualsRepository';

export default class NetManualsRepository
  extends NetResourcesRepository<Manual, ManualData>
  implements ManualsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/manuals',
        new ManualMapper(),
      ),
    );
  }
}
