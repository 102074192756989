import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';
import CharacterHideSceneCommandForm from './CharacterHideSceneCommandForm';
import CharacterShowSceneCommandForm from './CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from './CharacterUpdateSceneCommandForm';

const CLASS_NAME: SceneCommandFormClass = 'CompositeParallelSceneCommandForm';

export default class CompositeParallelSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(
    public commandForms: Array<
      | CharacterHideSceneCommandForm
      | CharacterShowSceneCommandForm
      | CharacterUpdateSceneCommandForm
    >,
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      type: 'SceneCommands::CompositeParallelSceneCommand',
      commands: this.commandForms.map(commandForm => commandForm.toParams()),
    };
  }

  public copy(sceneCommandId?: number): CompositeParallelSceneCommandForm {
    return new CompositeParallelSceneCommandForm(
      this.commandForms.map(commandForm => commandForm.copy()),
      sceneCommandId,
    );
  }
}
