import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../components/response/episodes/Show';

import AppState from '../../../reducers/AppState';

import showCurrentUser from '../../../actions/writer/current_user/show';

import showResponseEpisode from '../../../actions/response/episodes/show';
import updateResponseEpisode, {
  Params as ResponseEpisodeUpdateParams,
} from '../../../actions/response/episodes/update';
import indexEpisodeReactions, {
  Params as EpisodeReactionIndexParams,
} from '../../../actions/episode_reactions/index';
import indexEpisodeComments, {
  Params as EpisodeCommentIndexParams,
} from '../../../actions/episode_comments/index';
import showEpisodeComment from '../../../actions/episode_comments/show';
import destroyEpisodeComment from '../../../actions/episode_comments/destroy';
import destroyEpisodeCommentReply from '../../../actions/episode_comment_replies/destroy';
import indexEpisodeCommentBlockingUsers, {
  Params as EpisodeCommentBlockingUserIndexParams,
} from '../../../actions/episode_comment_blocking_users/index';
import createEpisodeCommentBlockingUser, {
  Params as EpisodeCommentBlockingUserCreateParams,
} from '../../../actions/episode_comment_blocking_users/create';
import destroyEpisodeCommentBlockingUser from '../../../actions/episode_comment_blocking_users/destroy';

import selectEntity from '../../../helpers/selectEntity';
import selectEntities from '../../../helpers/selectEntities';

import {ResponseEpisodeRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: ResponseEpisodeRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {episodeId} = route.params;
  const {entities, queries, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const episodeReactionsParams: EpisodeReactionIndexParams = {episodeId};
  const episodeCommentsParams: EpisodeCommentIndexParams = {episodeId};
  const episodeCommentBlockingUsersParams: EpisodeCommentBlockingUserIndexParams =
    {};
  const episode = selectEntity(entities.response.episodes, episodeId);
  const episodeReactions = selectEntities(
    entities.episodeReactions,
    queries.episodeReactions,
    episodeReactionsParams,
  );
  const episodeComments = selectEntities(
    entities.episodeComments,
    queries.episodeComments,
    episodeCommentsParams,
  );
  const episodeCommentBlockingUsers = selectEntities(
    entities.episodeCommentBlockingUsers,
    queries.episodeCommentBlockingUsers,
    episodeCommentBlockingUsersParams,
  );
  return {
    navigation,
    currentUser,
    episode,
    episodeReactions,
    episodeReactionsParams,
    episodeComments,
    episodeCommentsParams,
    episodeCommentBlockingUsers,
    episodeCommentBlockingUsersParams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUser: () => dispatch(showCurrentUser()),
    showResponseEpisode: (id: number) => dispatch(showResponseEpisode(id)),
    updateResponseEpisode: (id: number, params: ResponseEpisodeUpdateParams) =>
      dispatch(updateResponseEpisode(id, params)),
    indexEpisodeReactions: (params: EpisodeReactionIndexParams) =>
      dispatch(indexEpisodeReactions(params)),
    indexEpisodeComments: (params: EpisodeCommentIndexParams) =>
      dispatch(indexEpisodeComments(params)),
    showEpisodeComment: (id: number) => dispatch(showEpisodeComment(id)),
    destroyEpisodeComment: (id: number) => dispatch(destroyEpisodeComment(id)),
    destroyEpisodeCommentReply: (id: number) =>
      dispatch(destroyEpisodeCommentReply(id)),
    indexEpisodeCommentBlockingUsers: (
      params: EpisodeCommentBlockingUserIndexParams,
    ) => dispatch(indexEpisodeCommentBlockingUsers(params)),
    createEpisodeCommentBlockingUser: (
      params: EpisodeCommentBlockingUserCreateParams,
    ) => dispatch(createEpisodeCommentBlockingUser(params)),
    destroyEpisodeCommentBlockingUser: (id: number) =>
      dispatch(destroyEpisodeCommentBlockingUser(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
