import React from 'react';

import RootStack from '../../../../RootStack';
import {DefaultTransition} from '../../../../BaseNavigator';

import SceneFormBackgroundShowSceneCommandNewBackgroundFilters from '../../../../../containers/scene_form/background_show_scene_command/new/background_filters/Index'; // tslint:disable-next-line:max-line-length

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormBackgroundShowSceneCommandNewBackgroundFilters">
      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBackgroundFilters"
        component={SceneFormBackgroundShowSceneCommandNewBackgroundFilters}
        options={{title: '背景フィルター'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
