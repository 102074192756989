import * as React from 'react';
import {InteractionManager} from 'react-native';

import Form from './partials/Form';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';
import {EditCharacterRouteProp} from '../../navigators/RouteProps';

import * as routers from '../../routers';

import {Params as CharacterFormCreateParams} from '../../actions/character_forms/create';
import {Params as CharacterFormUpdateParams} from '../../actions/character_forms/update';
import {Params as ActorCharacterFaceIndexParams} from '../../actions/actor_character_faces/index';

import Character from '../../../domain/entities/Character';
import ActorCharacterFace from '../../../domain/entities/ActorCharacterFace';
import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';
import PaginatedResult from '../../../domain/results/PaginatedResult';
import CharacterForm from '../../../domain/forms/CharacterForm';

export interface Params {
  projectId?: number;
  characterId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditCharacterRouteProp;
  character: Character | null;
  characterForm: CharacterForm | null;
  actorCharacterFaces: ActorCharacterFace[] | null;
  actorCharacterFacesParams: ActorCharacterFaceIndexParams | null;
  currentUserStatus: CurrentUserStatus | null;
}

export interface DispatchProps {
  showCharacter: (id: number) => Promise<Character>;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  createCharacterForm: (params: CharacterFormCreateParams) => void;
  updateCharacterForm: (params: CharacterFormUpdateParams) => void;
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  ready: boolean;
  loading: boolean;
}

export default class Edit extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ready: false,
      loading: false,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      character,
      actorCharacterFacesParams,
      showCharacter,
      indexActorCharacterFaces,
      createCharacterForm,
      showCurrentUserStatus,
    } = this.props;
    const {characterId} = route.params;
    showCurrentUserStatus();
    const createCharacterFormCallback = (c: Character) => {
      const {
        id,
        name,
        description,
        actorId,
        actorCharacterId,
        defaultCharacterPatternId,
        actorCharacterFace,
        published,
        nameLabelColor,
        voiceActorName,
      } = c;
      const actorCharacterFaceId = actorCharacterFace.id;
      const actorCharacterFaceName = actorCharacterFace.faceName;
      const params = {
        actorId,
        actorCharacterId,
        actorCharacterFaceId,
        actorCharacterFaceName,
        defaultCharacterPatternId,
        description,
        id,
        name,
        published,
        nameLabelColor,
        voiceActorName,
      };
      createCharacterForm(params);
      InteractionManager.runAfterInteractions(() => {
        this.setState({ready: true});
      });
    };
    if (character) {
      createCharacterFormCallback(character);
    } else {
      showCharacter(characterId)
        .then(createCharacterFormCallback)
        .catch(e => {
          if (e.status === 401) {
            navigation.goBack();
          }
        });
    }
    if (actorCharacterFacesParams) {
      indexActorCharacterFaces(actorCharacterFacesParams);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {actorCharacterFacesParams, indexActorCharacterFaces} = this.props;
    if (!actorCharacterFacesParams) {
      return;
    }
    if (
      JSON.stringify(actorCharacterFacesParams) !==
      JSON.stringify(prevProps.actorCharacterFacesParams)
    ) {
      indexActorCharacterFaces(actorCharacterFacesParams);
    }
  }

  public render(): React.ReactNode {
    const {characterForm, actorCharacterFaces, navigation, currentUserStatus} =
      this.props;
    const {ready, loading} = this.state;
    return (
      <Layout
        title={'キャラクター情報'}
        navigation={navigation}
        back={true}
        loading={loading}>
        {characterForm && ready && currentUserStatus && (
          <Form
            characterForm={characterForm}
            actorCharacterFaces={actorCharacterFaces}
            enabledVoiceActorName={currentUserStatus.extensionsCodes.includes(
              'voice',
            )}
            onForwardCharacterPatterns={this.handleForwardCharacterPatterns}
            onChangeName={this.handleChangeName}
            onChangeDescription={this.handleChangeDescription}
            onChangeVoiceActorName={this.handleChangeVoiceActorName}
            onChangeActorCharacterFaceId={this.handleChangeActorCharacterFaceId}
            onSubmit={this.handleSubmit}
          />
        )}
      </Layout>
    );
  }

  private handleForwardCharacterPatterns = () => {
    const {navigation, route, characterForm} = this.props;
    const {characterId} = route.params;
    const faceName = characterForm?.actorCharacterFaceName;
    routers.linkToCharacterFormEditCharacterPatterns(navigation, {
      characterId,
      faceName,
    });
  };

  private handleChangeName = (name: string) => {
    const {updateCharacterForm} = this.props;
    updateCharacterForm({name});
  };

  private handleChangeDescription = (description: string) => {
    const {updateCharacterForm} = this.props;
    updateCharacterForm({description});
  };

  private handleChangeVoiceActorName = (voiceActorName: string) => {
    const {updateCharacterForm} = this.props;
    updateCharacterForm({voiceActorName});
  };

  private handleChangeActorCharacterFaceId = (actorCharacterFaceId: number) => {
    const {updateCharacterForm, actorCharacterFaces} = this.props;
    const actorCharacterFace = actorCharacterFaces?.find(
      o => o.id === actorCharacterFaceId,
    );
    updateCharacterForm({
      actorCharacterFaceId,
      actorCharacterFaceName: actorCharacterFace?.faceName,
    });
  };

  private handleSubmit = () => {
    const {navigation, route, character, characterForm} = this.props;
    const {projectId} = route.params;
    if (!character || !characterForm) {
      return;
    }
    navigation.navigate('CharacterFormEditNavigations', {
      screen: 'CharacterFormEditNameLabelColors',
      params: {characterId: character.id, projectId},
    } as any);
  };
}
