import {Dispatch} from 'redux';

import Types from '../Types';

import SceneForm from '../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId: number;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<SceneForm> {
  return (dispatch: Dispatch<any>): Promise<SceneForm> => {
    const payload = new SceneForm(params.storyId, params.episodeId);
    dispatch({
      payload,
      type: Types.CreateSceneForm,
    });
    return new Promise(resolve => resolve(payload));
  };
}
