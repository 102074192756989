import Entity from './Entity';

export default class Sound implements Entity {
  constructor(
    public id: number,
    public name: string,
    public audioUrl: string,
    public trimmedAudioUrl?: string,
  ) {}
}
