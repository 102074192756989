import * as React from 'react';

import {Image} from 'react-native';

import DimensionContext from '../../../../dimension/DimensionContext';

import Background from '../../../../../../../domain/entities/Background';

import {backgroundImageUrl} from '../../../../../../helpers/images';

interface Props {
  background: Background;
}

const SelectedBackgroundImage: React.FunctionComponent<Props> = props => {
  const {background} = props;
  return (
    <DimensionContext.Consumer>
      {context => {
        const {width} = context.content;
        return (
          <Image
            style={{width, height: width / aspectRatio}}
            source={{uri: backgroundImageUrl(background, 'large')}}
          />
        );
      }}
    </DimensionContext.Consumer>
  );
};

const aspectRatio = 1.25;

export default React.memo(SelectedBackgroundImage);
