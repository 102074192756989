import Entity from '../Entity';

import HelpCategory from './HelpCategory';

export default class Help implements Entity {
  constructor(
    public id: number,
    public title: string,
    public content: string,
    public writerHelpCategory: HelpCategory,
  ) {}
}
