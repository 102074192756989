import EntityMapper from '../EntityMapper';

import UserEpisodeComment from '../../../../domain/entities/writer/UserEpisodeComment';

export interface UserEpisodeCommentData {
  id: number;
  episode_id: number;
  comment: string;
  comment_for_supporter: string;
  created_at: string;
  updated_at: string;
}

export default class UserEpisodeCommentMapper
  implements EntityMapper<UserEpisodeCommentData, UserEpisodeComment>
{
  public map(obj: UserEpisodeCommentData): UserEpisodeComment {
    return new UserEpisodeComment(
      obj.id,
      obj.episode_id,
      obj.comment,
      obj.comment_for_supporter,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
