import EntityMapper from '../EntityMapper';

import UserProfileMapper, {UserProfileData} from './UserProfileMapper';

import User from '../../../../domain/entities/writer/User';
import UserProfile from '../../../../domain/entities/writer/UserProfile';

export interface UserData {
  id: number;
  pen_name: string;
  writer_grade_id: number;
  writer_grade_code: string | null;
  writer_rank: number;
  writer_score: number;
  fans_count?: number;
  profile_comment: string | null;
  user_profile: UserProfileData | null;
}

export default class UserMapper implements EntityMapper<UserData, User> {
  private userProfileMapper = new UserProfileMapper();
  public map(obj: UserData): User {
    let userProfile: UserProfile | null = null;
    if (obj.user_profile) {
      userProfile = this.userProfileMapper.map(obj.user_profile);
    }
    return new User(
      obj.id,
      obj.pen_name,
      obj.writer_grade_id,
      obj.writer_grade_code,
      obj.writer_rank,
      obj.writer_score,
      obj.fans_count,
      obj.profile_comment,
      userProfile,
    );
  }
}
