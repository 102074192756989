import * as React from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputSelectionChangeEventData,
  View,
  ViewStyle,
} from 'react-native';

import TextFrameGridList from './TextFrameGridList';
import Tabs from './Tabs';

import AttachmentSoundView from '../../../../../shared/sound/AttachmentSoundView';
import AddTextCommandLinksGroup from '../../../../../shared/AddFrequentlySymbolButtonsGroup';
import ElasticBoxInputTextCounter from '../../../../../shared/ElasticBoxInputTextCounter';
import ElasticTextFrameInput from '../../../../../shared/ElasticTextFrameInput';
import VoiceForm from '../../../../../shared/VoiceForm';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';
import UserStatusContext from '../../../../../shared/user_status/UserStatusContext';

import TextFrame from '../../../../../../../domain/entities/TextFrame';
import Voice from '../../../../../../../domain/entities/Voice';
import Sound from '../../../../../../../domain/entities/Sound';

export type TabValue = 'text' | 'text_frame' | 'voice';

const TAB_ITEMS = [
  {value: 'text', label: '内容'},
  {value: 'text_frame', label: '枠組み'},
  {value: 'voice', label: 'ボイス'},
] as Array<{value: TabValue; label: string}>;

interface Props {
  storyId: number;
  textInputRef?: React.RefObject<TextInput>;
  currentTab: TabValue;
  text: string;
  textFrame: TextFrame;
  selectedTextFrameId: number;
  textFrames: TextFrame[];
  voice: Voice | null;
  sound?: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
  enableVoice?: boolean;
  selection?: {start: number; end?: number};
  onChangeTab: (value: TabValue) => void;
  onChangeText: (text: string) => void;
  onPressSymbol: (symbol: string) => void;
  onSelectTextFrame: (textFrame: TextFrame) => void;
  onUpdateVoice: (voice: Voice | null) => void;
  onSelectionChange?: (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => void;
  addSound: () => void;
  deleteSound: () => void;
  onChagenRegion?: (start?: number, end?: number) => void;
}

export default class Index extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      storyId,
      textInputRef,
      currentTab,
      textFrame,
      textFrames,
      text,
      selectedTextFrameId,
      voice,
      sound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      selection,
      enableVoice,
      onChangeTab,
      onChangeText,
      onPressSymbol,
      onSelectTextFrame,
      onUpdateVoice,
      onSelectionChange,
      addSound,
      deleteSound,
      onChagenRegion,
    } = this.props;
    return (
      <View style={styles.container}>
        <DimensionContext.Consumer>
          {context => {
            const {width, height} = context.content;
            const textFrameHeight = height > 300 ? width * aspectRatio : 100;
            return (
              <UserStatusContext.Consumer>
                {userStatusContext => (
                  <View
                    style={{
                      width,
                      height: textFrameHeight,
                      marginTop: 62,
                      marginBottom: 18,
                      zIndex: 10,
                    }}>
                    <ElasticTextFrameInput
                      textInputRef={textInputRef}
                      text={text}
                      textFrame={textFrame}
                      width={context.content.width}
                      height={textFrameHeight}
                      selection={selection}
                      hasVoice={!!voice}
                      onChangeText={onChangeText}
                      onSelectionChange={onSelectionChange}
                    />
                    {(userStatusContext.currentUser?.enabledPaidSubscriber ||
                      userStatusContext.currentUser?.isGradeBlack()) && (
                      <View
                        style={[
                          {
                            position: 'absolute',
                            bottom: 0,
                            left: 20,
                            right: 20,
                          },
                          Platform.select({
                            default: {},
                            web: {
                              touchAction: 'none',
                            } as any,
                          }),
                        ]}>
                        <AttachmentSoundView
                          sound={sound}
                          startTime={selectedSoundStartTime}
                          endTime={selectedSoundEndTime}
                          addSound={addSound}
                          deleteSound={deleteSound}
                          onChagenRegion={onChagenRegion}
                        />
                      </View>
                    )}
                  </View>
                )}
              </UserStatusContext.Consumer>
            );
          }}
        </DimensionContext.Consumer>
        <ElasticBoxInputTextCounter
          style={{marginTop: 16}}
          length={text.length}
        />
        <Tabs<TabValue>
          items={TAB_ITEMS.filter(
            item => enableVoice || item.value !== 'voice',
          )}
          currentValue={currentTab}
          onChangeTab={onChangeTab}
        />
        <AddTextCommandLinksGroup
          style={currentTab === 'text' ? {marginTop: 16} : styles.hide}
          onPressSymbol={onPressSymbol}
        />
        <TextFrameGridList
          style={currentTab === 'text_frame' ? {marginTop: 16} : styles.hide}
          textFrames={textFrames}
          selectedTextFrameId={selectedTextFrameId}
          onSelectTextFrame={onSelectTextFrame}
        />
        <VoiceForm
          style={{margin: 16}}
          resetButtonWrapper={{marginTop: 8, marginRight: 8}}
          visible={currentTab === 'voice'}
          voice={voice}
          storyId={storyId}
          onUpdateVoice={onUpdateVoice}
        />
      </View>
    );
  }
}

const aspectRatio = 160 / 343;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});
