import SceneCommand from './SceneCommand';

import Background from '../../entities/Background';

import BackgroundShowSceneCommandForm from '../../forms/scene_commands/BackgroundShowSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class BackgroundShowSceneCommand implements SceneCommand {
  constructor(public background: Background) {}

  public getValue(): {[key: string]: any} {
    return {
      background_id: this.background.id,
    };
  }

  public toForm(): SceneCommandForm {
    return new BackgroundShowSceneCommandForm(this.background);
  }
}
