import * as React from 'react';
import {StyleSheet, TouchableOpacity, ViewStyle} from 'react-native';

import InversionIcon from './icons/InversionIcon';

interface Props {
  onPress?: () => void;
}

const InversionButton: React.FC<Props> = props => {
  const {onPress} = props;
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <InversionIcon />
    </TouchableOpacity>
  );
};

export default React.memo(InversionButton);

const styles = StyleSheet.create({
  container: {
    width: 38,
    height: 38,
    borderRadius: 19,
    borderColor: '#383838',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 10,
    right: 10,
  } as ViewStyle,
});
