import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

interface Props {
  onSubmit: (email: string) => void;
}

interface State {
  email: string;
}

export default class NewForm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  public render(): React.ReactNode {
    const {email} = this.state;
    return (
      <View style={styles.container}>
        <View style={styles.section}>
          <Text style={styles.message}>
            パスワード再設定用URLを記載したメールを送信します。{'\n'}
            TapNovelMakerにご登録のメールアドレスを入力してください。
          </Text>
          <View style={styles.section}>
            <Text style={styles.formLabel}>メールアドレス</Text>
          </View>
          <View style={styles.textInputWrapper}>
            <TextInput
              style={styles.textInput}
              keyboardType={'email-address'}
              autoCapitalize={'none'}
              placeholder={'メールアドレスを入力'}
              value={email}
              onChangeText={this.handleChangeEmail}
              returnKeyType={'done'}
              onSubmitEditing={this.handlePressButton}
            />
          </View>
        </View>
        <View style={styles.submitButton}>
          <PrimaryButton
            disabled={!this.validEmail(email)}
            onPress={this.handlePressButton}>
            送信する
          </PrimaryButton>
        </View>
      </View>
    );
  }

  private handleChangeEmail = (email: string) => {
    this.setState({email});
  };

  private handlePressButton = () => {
    const {onSubmit} = this.props;
    const {email} = this.state;
    if (this.validEmail(email)) {
      onSubmit(email);
    }
  };

  private validEmail = (email: string) => {
    return email.length > 0 && !!email.match(/.+@.+\.[A-Za-z0-9]{1,}/);
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 25,
  } as ViewStyle,
  submitButton: {
    alignItems: 'center',
    marginTop: 24,
  } as ViewStyle,
  section: {
    marginVertical: 8,
  } as ViewStyle,
  formLabel: {
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  textInputWrapper: {
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  textInput: {
    fontSize: 15,
    marginVertical: 8,
  } as TextStyle,
  message: {
    color: '#383838',
    fontSize: 11,
    marginBottom: 16,
  } as TextStyle,
});
