import NetResourceRepository from '../../../NetResourceRepository';
import NetResourceRepositoryAdapter from '../../../NetResourceRepositoryAdapter';

import CurrentUserMapper, {
  CurrentUserData,
} from '../../../../entities/mappers/writer/CurrentUserMapper';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';
import SessionRepository from '../../../../../domain/repositories/writer/social_accounts/twitter_accounts/SessionRepository';

export default class NetSessionRepository
  extends NetResourceRepository<CurrentUser, CurrentUserData>
  implements SessionRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/writer/social_accounts/twitter_accounts/session',
        new CurrentUserMapper(),
      ),
    );
  }
}
