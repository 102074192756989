import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ContestMapper, {ContestData} from '../../entities/mappers/ContestMapper';

import Contest from '../../../domain/entities/Contest';
import ContestsRepository from '../../../domain/repositories/writer/ContestsRepository';

export default class NetContestsRepository
  extends NetResourcesRepository<Contest, ContestData>
  implements ContestsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/contests',
        new ContestMapper(),
      ),
    );
  }
}
