import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormCharacterShowSceneCommandNewCharacterPatterns from '../../../../containers/scene_form/character_show_scene_command/new/character_patterns/Index';
import SceneFormCharacterShowSceneCommandNewActorCharacterFaces from '../../../../containers/scene_form/character_show_scene_command/new/actor_character_faces/Index';
import SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm from '../../../../containers/scene_form/character_show_scene_command/new/character_form/actor_search_form/Index';
import SceneFormCharacterShowSceneCommandNewCharacterFormActors from '../../../../containers/scene_form/character_show_scene_command/new/character_form/actors/Index';
import SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames from '../../../../containers/scene_form/character_show_scene_command/new/character_form/character_names/Index';
import SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns from '../../../../containers/scene_form/character_show_scene_command/new/character_form/default_character_patterns/Index';
import SceneFormCharacterShowSceneCommandNewCharacterPositions from '../../../../containers/scene_form/character_show_scene_command/new/character_positions/Index';

import SceneFormCharacterShowSceneCommandNewCharacterMakerHome from '../../../../containers/scene_form/character_show_scene_command/new/character_maker/home/Index';
import SceneFormCharacterShowSceneCommandNewCharacterMakerActors from '../../../../containers/scene_form/character_show_scene_command/new/character_maker/actors/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormCharacterShowSceneCommandNewCharacterPatterns">
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterPatterns"
        component={SceneFormCharacterShowSceneCommandNewCharacterPatterns}
        options={{title: 'キャラクター'}}
      />
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm"
        component={
          SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm
        }
        options={{title: 'キャラクター検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterFormActors"
        component={SceneFormCharacterShowSceneCommandNewCharacterFormActors}
        options={{title: 'キャラクター'}}
      />
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns"
        component={
          SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns
        }
        options={{title: '衣装'}}
      />
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames"
        component={
          SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames
        }
        options={{title: 'キャラクター名'}}
      />
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewActorCharacterFaces"
        component={SceneFormCharacterShowSceneCommandNewActorCharacterFaces}
        options={{title: '表情選択'}}
      />
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterPositions"
        component={SceneFormCharacterShowSceneCommandNewCharacterPositions}
        options={{title: '立ち位置'}}
      />
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterMakerHome"
        component={SceneFormCharacterShowSceneCommandNewCharacterMakerHome}
        options={{title: '素体'}}
      />
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterMakerActors"
        component={SceneFormCharacterShowSceneCommandNewCharacterMakerActors}
        options={{title: 'キャラクター'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
