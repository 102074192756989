import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {getDoubleResizeImageUrl} from '../../../../../helpers/images';

import Actor from '../../../../../../domain/entities/shop/Actor';

interface Props {
  actor: Actor;
}

const CompleteMessage: React.FC<Props> = props => {
  const {actor} = props;
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(
        actor,
        {
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        },
        {nocanvas: true},
      ),
    }),
    [actor],
  );
  return (
    <View style={styles.container}>
      <Text style={styles.message}>ご購入ありがとうございました</Text>
      <Image source={source} style={styles.image} />
    </View>
  );
};

export default React.memo(CompleteMessage);

const IMAGE_WIDTH = 169;
const IMAGE_HEIGHT = 240;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 334,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#fafafa',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    marginBottom: 16,
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  } as ImageStyle,
});
