import * as React from 'react';

interface Props extends React.PropsWithChildren {
  enabled?: boolean;
}

const UserSelectContent: React.FC<Props> = props => {
  const {enabled, children} = props;
  if (enabled) {
    return (
      <div className="enabled-user-select" style={{overflow: 'scroll'}}>
        {children}
      </div>
    );
  }
  return <>{children}</>;
};

export default React.memo(UserSelectContent);
