import * as React from 'react';

type NumberProp = string | number;

interface Props {
  uri: string | any;
  width?: NumberProp;
  height?: NumberProp;
  color?: string;
  title?: string;
}

const SvgUriAdapter: React.FunctionComponent<Props> = props => {
  const {uri, width, height, color, title} = props;
  return (
    <img src={uri} width={width} height={height} color={color} title={title} />
  );
};
export default React.memo(SvgUriAdapter);
