import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import InvoiceListItem from './InvoiceListItem';
import NoInvoices from './NoInvoices';

import Invoice from '../../../../../../domain/entities/writer/my_stripe/Invoice';

interface Props {
  invoices: Array<Invoice> | null;
}

const InvoiceList: React.FunctionComponent<Props> = props => {
  const {invoices} = props;
  const renderItem = React.useCallback((info: ListRenderItemInfo<Invoice>) => {
    return <InvoiceListItem invoice={info.item} />;
  }, []);
  return (
    <FlatList
      contentContainerStyle={contentContainerStyle}
      data={invoices}
      renderItem={renderItem}
      ListEmptyComponent={NoInvoices}
    />
  );
};

export default InvoiceList;

const contentContainerStyle = {flex: 1};
