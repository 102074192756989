import EpisodeAggregation from '../../../../domain/value_objects/summary/EpisodeAggregation';

export interface EpisodeAggregationData {
  id: number;
  from?: string | null;
  to?: string | null;
  title: string;
  version: number;
  tap_count: number;
  episode_page_view_count: number;
  episode_page_view_unique_consumer_user_count: number;
  finished_reading_episode_page_view_count: number;
  finished_reading_episode_page_view_unique_consumer_user_count: number;
  finished_reading_rate: number;
  genre_average_finished_reading_rate: number;
  total_speech_text_length: number;
  total_descriptive_text_length: number;
}

export default class EpisodeAggregationMapper {
  public map(obj: EpisodeAggregationData): EpisodeAggregation {
    return new EpisodeAggregation(
      obj.id,
      obj.from ? new Date(obj.from) : null,
      obj.to ? new Date(obj.to) : null,
      obj.title,
      obj.version,
      obj.tap_count,
      obj.episode_page_view_count,
      obj.episode_page_view_unique_consumer_user_count,
      obj.finished_reading_episode_page_view_count,
      obj.finished_reading_episode_page_view_unique_consumer_user_count,
      obj.finished_reading_rate,
      obj.genre_average_finished_reading_rate,
      obj.total_speech_text_length,
      obj.total_descriptive_text_length,
    );
  }
}
