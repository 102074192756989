import LineAccessToken, {
  DecodedIdToken,
} from '../../../domain/value_objects/LineAccessToken';

export interface LineAccessTokenData {
  access_token: string;
  token_type: string;
  refresh_token: string;
  expires_in: string;
  scope: string;
  id_token: string;
  decoded_id_token: DecodedIdToken;
}

export default class LineAccessTokenMapper {
  public map(obj: LineAccessTokenData): LineAccessToken {
    return new LineAccessToken(
      obj.access_token,
      obj.token_type,
      obj.refresh_token,
      obj.expires_in,
      obj.scope,
      obj.id_token,
      obj.decoded_id_token,
    );
  }
}
