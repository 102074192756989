import React from 'react';
import {Platform} from 'react-native';
import {TransitionPresets} from '@react-navigation/stack';

import RootStack from './RootStack';

import MemberNavigator from './MemberNavigator';
import PublicNavigator from './PublicNavigator';
import ProNavigator from './ProNavigator';

import EpisodeViewer from '../containers/episode_viewers/Show';
import EpisodeRelease from '../containers/episode_releases/Show';
import PreviewSceneViewer from '../containers/preview_scene_viewers/Show';
import SceneViewer from '../containers/scene_viewers/Show';
import PoliciesPrivacy from '../containers/policies/privacy/Index';
import PoliciesTerms from '../containers/policies/terms/Index';
import PoliciesSettlementLaw from '../containers/policies/settlement_law/Index';
import PoliciesSpecifiedCommercialTransactionLaw from '../containers/policies/specified_commercial_transaction_law/Index';
import PartnerProgramPoliciesTerms from '../containers/partner_program/policies/terms/Index';
import WritersGatePoliciesTerms from '../containers/writers_gate/policies/terms/Index';
import EditWriterUser from '../containers/writer/users/Edit';
import NewWriterUserCancellation from '../containers/writer/user_cancellations/New';
import NewWriterUserConfirmation from '../containers/writer/user_confirmations/New';

import EpisodeFormEditNavigations from './episode_form/edit/navigations/Index';

import CharacterFormNewNavigations from './character_form/new/navigations/Index';
import CharacterFormNewActorSearchFormNavigations from './character_form/new/navigations/actor_search_form/Index';
import CharacterFormEditNavigations from './character_form/edit/navigations/Index';
import Character from '../containers/characters/Show';

import SceneFormBackgroundShowSceneCommandNewNavigations from './scene_form/background_show_scene_command/new/Index';
import SceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations from './scene_form/background_show_scene_command/new/background_filters/Index';
import SceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations from './scene_form/background_show_scene_command/new/background_effects/Index';
import SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormNavigations from './scene_form/background_show_scene_command/new/base_background_search_form/Index';

import SceneFormCharacterShowSceneCommandNewNavigations from './scene_form/character_show_scene_command/new/Index';
import SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchFormNavigations from './scene_form/character_show_scene_command/new/character_form/actor_search_form/Index';

import SceneFormDescriptiveTextShowSceneCommandNewNavigations from './scene_form/descriptive_text_show_scene_command/new/Index';

import SceneFormIllustrationShowSceneCommandEditNavigations from './scene_form/illustration_show_scene_command/edit/Index';
import SceneFormIllustrationShowSceneCommandEditIllustrationSearchFormNavigations from './scene_form/illustration_show_scene_command/edit/illustration_search_form/Index';

import SceneFormIllustrationShowSceneCommandNewNavigations from './scene_form/illustration_show_scene_command/new/Index';
import SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormNavigations from './scene_form/illustration_show_scene_command/new/illustration_search_form/Index';

import SceneFormFullScreenIllustrationShowSceneCommandEditNavigations from './scene_form/full_screen_illustration_show_scene_command/edit/Index';

import SceneFormFullScreenIllustrationShowSceneCommandNewNavigations from './scene_form/full_screen_illustration_show_scene_command/new/Index';

import SceneFormEffectShowSceneCommandEditNavigations from './scene_form/effect_show_scene_command/edit/Index';
import SceneFormEffectShowSceneCommandEditBaseEffectSearchFormNavigations from './scene_form/effect_show_scene_command/edit/base_effect_search_form/Index';

import SceneFormEffectShowSceneCommandNewNavigations from './scene_form/effect_show_scene_command/new/Index';
import SceneFormEffectShowSceneCommandNewBaseEffectSearchFormNavigations from './scene_form/effect_show_scene_command/new/base_effect_search_form/Index';

import SceneFormSoundEffectShowSceneCommandEditNavigations from './scene_form/sound_effect_show_scene_command/edit/Index';
import SceneFormSoundEffectShowSceneCommandEditSoundSearchFormNavigations from './scene_form/sound_effect_show_scene_command/edit/sound_search_form/Index';

import SceneFormSoundEffectShowSceneCommandNewNavigations from './scene_form/sound_effect_show_scene_command/new/Index';
import SceneFormSoundEffectShowSceneCommandNewSoundSearchFormNavigations from './scene_form/sound_effect_show_scene_command/new/sound_search_form/Index';

import SceneFormBackgroundMusicShowSceneCommandEditNavigations from './scene_form/background_music_show_scene_command/edit/Index';
import SceneFormBackgroundMusicShowSceneCommandEditSoundSearchFormNavigations from './scene_form/background_music_show_scene_command/edit/sound_search_form/Index';

import SceneFormBackgroundMusicShowSceneCommandNewNavigations from './scene_form/background_music_show_scene_command/new/Index';
import SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormNavigations from './scene_form/background_music_show_scene_command/new/sound_search_form/Index';

import SceneFormSpeechTextShowSceneCommandNewNavigations from './scene_form/speech_text_show_scene_command/new/Index';

import EditStoryNavigation from './story_form/edit/navigations/Index';
import NewStoryNavigation from './story_form/new/navigations/Index';

import CoverImageNavigation from './cover_image_form/Index';
import CoverImageBaseBackgroundSearchFormNavigation from './cover_image_form/base_background_search_form/Index';
import CoverImageFormCharacterFormActorSearchFormNavigation from './cover_image_form/actor_search_form/Index';

import SettingsNavigation from './settings/navigations/Index';
import SettingsBankAccount from '../containers/settings/bank_account/Index';
import SettingsTransferHistory from '../containers/settings/transfer_history/Index';
import SettingsUserIdentification from '../containers/settings/user_identification/Index';

import ProNavigation from './pro/navigations/Index';
import PartnerProgramNavigation from './partner_program/navigations/Index';
import TransferNavigation from './transfer/navigations/Index';

import WriterUserNavigation from './writer/navigations/Index';
import WriterUserRegistrationNavigation from './writer/user_registrations/navigations/Index';
import WriterUserSessionNavigation from './writer/user_sessions/navigations/Index';
import WriterUserPasswordNavigation from './writer/user_passwords/navigations/Index';

import MonitorNavigation from './monitor/navigations/Index';
import ReservationNavigation from './reservation/navigations/Index';
import CommunityNavigation from './community/navigations/Index';
import FaqNavigation, {
  PublishedStack as PublishedFaqNavigation,
} from './faq/navigations/Index';

import ShowScene from '../containers/scenes/Show';

import NewEpisodeCommentReply from '../containers/episode_comment_replies/New';
import EditEpisodeCommentReply from '../containers/episode_comment_replies/Edit';

import Notifications from '../containers/notifications/Index';

import StoryTapCountAchievement from '../containers/story_tap_count_achievements/Show';
import StoryGenreRankedAchievement from '../containers/story_genre_ranked_achievements/Show';
import UserUpgradeAchievement from '../containers/writer/user_upgrade_achievements/Show';
import UserExtension from '../containers/writer/user_extensions/Show';
import CommercialVideoExportJobExecution from '../containers/commercial_video/export_job_executions/Show';

import AccountNavigation from './account/navigations/Index';

import Contest from '../containers/contests/Show';
import ContestGroup from '../containers/contest_groups/Show';

import ProjectsNavigation from './projects/navigations/Index';
import WritersGateNavigation from './writers_gate/navigations/Index';

if (Platform.OS !== 'ios') {
  TransitionPresets.ModalTransition.cardStyleInterpolator =
    TransitionPresets.FadeFromBottomAndroid.cardStyleInterpolator;
  TransitionPresets.ModalTransition.gestureDirection =
    TransitionPresets.FadeFromBottomAndroid.gestureDirection;
  TransitionPresets.ModalTransition.headerStyleInterpolator =
    TransitionPresets.FadeFromBottomAndroid.headerStyleInterpolator;
  TransitionPresets.ModalTransition.transitionSpec =
    TransitionPresets.FadeFromBottomAndroid.transitionSpec;
}

export const ModalTransition = Platform.select({
  ios: TransitionPresets.ModalSlideFromBottomIOS,
  default: TransitionPresets.FadeFromBottomAndroid,
});

interface Props {
  signedIn: boolean;
  isPro: boolean;
}

const Index = (props: Props) => {
  const {signedIn, isPro} = props;
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        gestureEnabled: false,
        headerShown: false,
        cardStyle: {backgroundColor: 'transparent'},
        ...ModalTransition,
      }}
      initialRouteName="MainStackNavigator">
      <RootStack.Screen
        name="PoliciesPrivacyModal"
        component={PoliciesPrivacy}
        options={{title: 'プライバシーポリシー'}}
      />
      <RootStack.Screen
        name="PoliciesTermsModal"
        component={PoliciesTerms}
        options={{title: '利用規約'}}
      />
      <RootStack.Screen
        name="PoliciesSettlementLaw"
        component={PoliciesSettlementLaw}
        options={{title: '資金決済法に基づく表示'}}
      />
      <RootStack.Screen
        name="PoliciesSpecifiedCommercialTransactionLaw"
        component={PoliciesSpecifiedCommercialTransactionLaw}
        options={{title: '特定商取引法に基づく表示'}}
      />
      <RootStack.Screen
        name="PartnerProgramPoliciesTerms"
        component={PartnerProgramPoliciesTerms}
        options={{title: '利用規約'}}
      />
      <RootStack.Screen
        name="WritersGatePoliciesTerms"
        component={WritersGatePoliciesTerms}
        options={{title: '利用規約'}}
      />
      <RootStack.Screen
        name="MonitorNavigation"
        component={MonitorNavigation}
      />
      <RootStack.Screen
        name="ReservationNavigation"
        component={ReservationNavigation}
      />
      <RootStack.Screen
        name="CommunityNavigation"
        component={CommunityNavigation}
        options={{
          animationEnabled: Platform.OS !== 'web',
        }}
      />
      <RootStack.Screen
        name="Contest"
        component={Contest}
        options={{
          animationEnabled: Platform.OS !== 'web',
        }}
      />
      <RootStack.Screen
        name="ContestGroup"
        component={ContestGroup}
        options={{
          animationEnabled: Platform.OS !== 'web',
        }}
      />
      {signedIn ? (
        <>
          <RootStack.Screen
            name="MainStackNavigator"
            component={isPro ? ProNavigator : MemberNavigator}
          />
          <RootStack.Screen
            name="NewStoryNavigation"
            component={NewStoryNavigation}
          />
          <RootStack.Screen
            name="EditStoryNavigation"
            component={EditStoryNavigation}
          />
          <RootStack.Screen
            name="CoverImageNavigation"
            component={CoverImageNavigation}
          />
          <RootStack.Screen
            name="CoverImageBaseBackgroundSearchFormNavigation"
            component={CoverImageBaseBackgroundSearchFormNavigation}
          />
          <RootStack.Screen
            name="CoverImageFormCharacterFormActorSearchFormNavigation"
            component={CoverImageFormCharacterFormActorSearchFormNavigation}
          />
          <RootStack.Screen
            name="ShowScene"
            component={ShowScene}
            options={{title: 'シーンの情報', gestureEnabled: false}}
          />
          <RootStack.Screen
            name="SettingsNavigation"
            component={SettingsNavigation}
          />
          <RootStack.Screen
            name="SettingsBankAccount"
            component={SettingsBankAccount}
          />
          <RootStack.Screen
            name="SettingsTransferHistory"
            component={SettingsTransferHistory}
          />
          <RootStack.Screen
            name="SettingsUserIdentification"
            component={SettingsUserIdentification}
          />
          <RootStack.Screen
            name="EpisodeViewer"
            component={EpisodeViewer}
            options={{
              title: 'エピソードプレビュー',
              animationEnabled: Platform.OS !== 'web',
              ...(Platform.OS === 'web'
                ? {
                    gestureDirection: undefined,
                    transitionSpec: undefined,
                    cardStyleInterpolator: undefined,
                    headerStyleInterpolator: undefined,
                  }
                : {}),
            }}
          />
          <RootStack.Screen
            name="EpisodeRelease"
            component={EpisodeRelease}
            options={{
              title: 'エピソードが公開されました',
              animationEnabled: Platform.OS !== 'web',
              ...(Platform.OS === 'web'
                ? {
                    gestureDirection: undefined,
                    transitionSpec: undefined,
                    cardStyleInterpolator: undefined,
                    headerStyleInterpolator: undefined,
                  }
                : {}),
            }}
          />
          <RootStack.Screen
            name="PreviewSceneViewer"
            component={PreviewSceneViewer}
            options={{
              title: 'シーンプレビュー',
              animationEnabled: Platform.OS !== 'web',
              ...(Platform.OS === 'web'
                ? {
                    gestureDirection: undefined,
                    transitionSpec: undefined,
                    cardStyleInterpolator: undefined,
                    headerStyleInterpolator: undefined,
                  }
                : {}),
            }}
          />
          <RootStack.Screen
            name="WriterUserRegistrationNavigation"
            component={WriterUserRegistrationNavigation}
          />
          <RootStack.Screen
            name="WriterUserSessionNavigation"
            component={WriterUserSessionNavigation}
          />
          <RootStack.Screen
            name="EditWriterUser"
            component={EditWriterUser}
            options={{title: 'プロフィール'}}
          />
          <RootStack.Screen
            name="NewWriterUserCancellation"
            component={NewWriterUserCancellation}
            options={{title: '退会'}}
          />
          <RootStack.Screen
            name="NewWriterUserConfirmation"
            component={NewWriterUserConfirmation}
            options={{title: 'アカウント認証メール再送'}}
          />

          <RootStack.Screen
            name="EpisodeFormEditNavigations"
            component={EpisodeFormEditNavigations}
          />

          <RootStack.Screen name="Character" component={Character} />
          <RootStack.Screen
            name="CharacterFormNewNavigations"
            component={CharacterFormNewNavigations}
          />
          <RootStack.Screen
            name="CharacterFormNewActorSearchFormNavigations"
            component={CharacterFormNewActorSearchFormNavigations}
          />
          <RootStack.Screen
            name="CharacterFormEditNavigations"
            component={CharacterFormEditNavigations}
          />
          <RootStack.Screen
            name="SceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations"
            component={
              SceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations"
            component={
              SceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormNavigations"
            component={
              SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormBackgroundShowSceneCommandNewNavigations"
            component={SceneFormBackgroundShowSceneCommandNewNavigations}
          />
          <RootStack.Screen
            name="SceneFormCharacterShowSceneCommandNewNavigations"
            component={SceneFormCharacterShowSceneCommandNewNavigations}
          />
          <RootStack.Screen
            name="SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchFormNavigations"
            component={
              SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormDescriptiveTextShowSceneCommandNewNavigations"
            component={SceneFormDescriptiveTextShowSceneCommandNewNavigations}
          />
          <RootStack.Screen
            name="SceneFormIllustrationShowSceneCommandEditNavigations"
            component={SceneFormIllustrationShowSceneCommandEditNavigations}
          />
          <RootStack.Screen
            name="SceneFormIllustrationShowSceneCommandEditIllustrationSearchFormNavigations"
            component={
              SceneFormIllustrationShowSceneCommandEditIllustrationSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormIllustrationShowSceneCommandNewNavigations"
            component={SceneFormIllustrationShowSceneCommandNewNavigations}
          />
          <RootStack.Screen
            name="SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormNavigations"
            component={
              SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormFullScreenIllustrationShowSceneCommandEditNavigations"
            component={
              SceneFormFullScreenIllustrationShowSceneCommandEditNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormFullScreenIllustrationShowSceneCommandNewNavigations"
            component={
              SceneFormFullScreenIllustrationShowSceneCommandNewNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormEffectShowSceneCommandEditNavigations"
            component={SceneFormEffectShowSceneCommandEditNavigations}
          />
          <RootStack.Screen
            name="SceneFormEffectShowSceneCommandEditBaseEffectSearchFormNavigations"
            component={
              SceneFormEffectShowSceneCommandEditBaseEffectSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormEffectShowSceneCommandNewNavigations"
            component={SceneFormEffectShowSceneCommandNewNavigations}
          />
          <RootStack.Screen
            name="SceneFormEffectShowSceneCommandNewBaseEffectSearchFormNavigations"
            component={
              SceneFormEffectShowSceneCommandNewBaseEffectSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormSoundEffectShowSceneCommandEditNavigations"
            component={SceneFormSoundEffectShowSceneCommandEditNavigations}
          />
          <RootStack.Screen
            name="SceneFormSoundEffectShowSceneCommandEditSoundSearchFormNavigations"
            component={
              SceneFormSoundEffectShowSceneCommandEditSoundSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormSoundEffectShowSceneCommandNewNavigations"
            component={SceneFormSoundEffectShowSceneCommandNewNavigations}
          />
          <RootStack.Screen
            name="SceneFormSoundEffectShowSceneCommandNewSoundSearchFormNavigations"
            component={
              SceneFormSoundEffectShowSceneCommandNewSoundSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormBackgroundMusicShowSceneCommandEditNavigations"
            component={SceneFormBackgroundMusicShowSceneCommandEditNavigations}
          />
          <RootStack.Screen
            name="SceneFormBackgroundMusicShowSceneCommandEditSoundSearchFormNavigations"
            component={
              SceneFormBackgroundMusicShowSceneCommandEditSoundSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormBackgroundMusicShowSceneCommandNewNavigations"
            component={SceneFormBackgroundMusicShowSceneCommandNewNavigations}
          />
          <RootStack.Screen
            name="SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormNavigations"
            component={
              SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormNavigations
            }
          />
          <RootStack.Screen
            name="SceneFormSpeechTextShowSceneCommandNewNavigations"
            component={SceneFormSpeechTextShowSceneCommandNewNavigations}
          />
          <RootStack.Screen
            name="SceneViewer"
            component={SceneViewer}
            options={{
              animationEnabled: Platform.OS !== 'web',
            }}
          />
          <RootStack.Screen
            name="NewEpisodeCommentReply"
            component={NewEpisodeCommentReply}
            options={{title: '返信'}}
          />
          <RootStack.Screen
            name="EditEpisodeCommentReply"
            component={EditEpisodeCommentReply}
            options={{title: '返信'}}
          />
          <RootStack.Screen
            name="Notifications"
            component={Notifications}
            options={{title: '通知'}}
          />
          <RootStack.Screen
            name="StoryTapCountAchievement"
            component={StoryTapCountAchievement}
            options={{title: '通知'}}
          />
          <RootStack.Screen
            name="StoryGenreRankedAchievement"
            component={StoryGenreRankedAchievement}
            options={{title: '通知'}}
          />
          <RootStack.Screen
            name="UserUpgradeAchievement"
            component={UserUpgradeAchievement}
            options={{title: '通知'}}
          />
          <RootStack.Screen
            name="UserExtension"
            component={UserExtension}
            options={{title: '通知'}}
          />
          <RootStack.Screen
            name="CommercialVideoExportJobExecution"
            component={CommercialVideoExportJobExecution}
            options={{title: '通知'}}
            initialParams={{notification: true}}
          />

          <RootStack.Screen
            name="AccountNavigation"
            component={AccountNavigation}
            options={{title: 'アカウント'}}
          />
          <RootStack.Screen
            name="FaqNavigation"
            component={FaqNavigation}
            options={{title: 'お問い合わせ'}}
          />
          <RootStack.Screen
            name="ProNavigation"
            component={ProNavigation}
            options={{title: 'MakerPro'}}
          />
          <RootStack.Screen
            name="PartnerProgramNavigation"
            component={PartnerProgramNavigation}
            options={{title: 'パートナープログラム'}}
          />
          <RootStack.Screen
            name="TransferNavigation"
            component={TransferNavigation}
            options={{title: '振込申請'}}
          />
          <RootStack.Screen
            name="ProjectsNavigation"
            component={ProjectsNavigation}
            options={{title: 'プロジェクト'}}
          />
          <RootStack.Screen
            name="WritersGateNavigation"
            component={WritersGateNavigation}
            options={{title: 'ライターズゲート'}}
          />
        </>
      ) : (
        <>
          <RootStack.Screen
            name="MainStackNavigator"
            component={PublicNavigator}
          />
          <RootStack.Screen
            name="WriterUserRegistrationNavigation"
            component={WriterUserRegistrationNavigation}
          />
          <RootStack.Screen
            name="WriterUserSessionNavigation"
            component={WriterUserSessionNavigation}
          />
          <RootStack.Screen
            name="WriterUserPasswordNavigation"
            component={WriterUserPasswordNavigation}
          />
          <RootStack.Screen
            name="FaqNavigation"
            component={PublishedFaqNavigation}
            options={{title: 'お問い合わせ'}}
          />
        </>
      )}
    </RootStack.Navigator>
  );
};

export default React.memo(Index);
