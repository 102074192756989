import SoundMapper, {SoundData} from '../../../entities/mappers/SoundMapper';

import SoundEffectShowSceneCommand from '../../../../domain/value_objects/scene_commands/SoundEffectShowSceneCommand';

export interface SoundEffectShowSceneCommandData {
  type: string;
  sound: SoundData;
  start_time?: number;
  end_time?: number;
}

export default class SoundEffectShowSceneCommandMapper {
  private soundMapper: SoundMapper;

  constructor() {
    this.soundMapper = new SoundMapper();
  }

  public map(
    obj: SoundEffectShowSceneCommandData,
  ): SoundEffectShowSceneCommand {
    const sound = this.soundMapper.map(obj.sound);
    return new SoundEffectShowSceneCommand(sound, obj.start_time, obj.end_time);
  }
}
