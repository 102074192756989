import EntityMapper from './EntityMapper';

import Mark from '../../../domain/entities/Mark';

export interface MarkData {
  id: number;
  original_image_url: string;
}

export default class MarkMapper implements EntityMapper<MarkData, Mark> {
  public map(obj: MarkData): Mark {
    return new Mark(obj.id, obj.original_image_url);
  }
}
