import * as React from 'react';
import {ImageStyle, StyleSheet} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';

import {illustrationImageUrl} from '../../../../../../helpers/images';

import Illustration from '../../../../../../../domain/entities/Illustration';

interface Props {
  illustration: Illustration;
  children?: React.ReactNode;
}

const IllustrationView: React.FunctionComponent<Props> = props => {
  const {illustration} = props;
  return (
    <FastImageAdapter
      resizeMode={'contain'}
      style={styles.image}
      source={{
        uri: illustrationImageUrl(illustration, 'large'),
        headers: {Accept: 'image/webp,image/apng,*/*'},
      }}
    />
  );
};

export default React.memo(IllustrationView);

const width = 250;
const height = 250;
const aspectRatio = width / height;

const styles = StyleSheet.create({
  image: {
    aspectRatio,
    height,
    width,
  } as ImageStyle,
});
