import * as React from 'react';

import Detail from './partials/Detail';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';
import {AnnouncementRouteProp} from '../../navigators/RouteProps';

import Announcement from '../../../domain/entities/writer/Announcement';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: AnnouncementRouteProp;
  announcement: Announcement | null;
}

export interface DispatchProps {
  showAnnouncement: (id: number) => Promise<Announcement>;
}

interface Props extends StateProps, DispatchProps {}

export default class Show extends React.PureComponent<Props> {
  public componentDidMount() {
    const {route, showAnnouncement} = this.props;
    if (!this.props.announcement) {
      showAnnouncement(route.params.id);
    }
  }

  public render(): React.ReactNode {
    const {navigation, announcement} = this.props;
    return (
      <Layout
        title={'お知らせ'}
        scrollable={false}
        navigation={navigation}
        back={true}>
        {announcement && <Detail announcement={announcement} />}
      </Layout>
    );
  }
}
