import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import CheckCircleIcon from '../../../shared/icons/CheckCircleIcon';

interface Props {
  onToggleAgreed: (agreed: boolean) => void;
}

const Checkbox: React.FunctionComponent<Props> = props => {
  const {onToggleAgreed} = props;
  const [agreed, setAgreed] = React.useState(false);
  const onPress = React.useCallback(() => {
    setAgreed(!agreed);
    onToggleAgreed(!agreed);
  }, [agreed]);
  return (
    <TouchableOpacity
      style={styles.container}
      activeOpacity={0.7}
      onPress={onPress}>
      <View
        style={[
          styles.button,
          agreed ? styles.buttonActive : styles.buttonDeactive,
        ]}>
        <CheckCircleIcon size={13} color={agreed ? '#ff8f13' : '#d5d5d5'} />
        <Text
          style={[
            styles.text,
            agreed ? styles.textActive : styles.textDeactive,
          ]}>
          上記に同意します
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(Checkbox);

const styles = StyleSheet.create({
  container: {
    marginVertical: 16,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    width: 160,
    height: 30,
    borderWidth: 1,
    borderRadius: 4,
  } as ViewStyle,
  buttonActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
  buttonDeactive: {
    borderColor: '#d5d5d5',
  } as ViewStyle,
  text: {
    fontSize: 12,
    marginLeft: 5,
  } as TextStyle,
  textActive: {
    color: '#ff8f13',
  } as TextStyle,
  textDeactive: {
    color: '#666',
  } as TextStyle,
});
