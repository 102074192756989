import PriceMapper, {
  PriceData,
} from '../../../../entities/mappers/writer/my_stripe/maker_pro/PriceMapper';

import Price from '../../../../../domain/entities/writer/my_stripe/maker_pro/Price';
import PriceRepository from '../../../../../domain/repositories/writer/my_stripe/maker_pro/PriceRepository';

import TapNovelRestApi from '../../../../data_stores/net/TapNovelRestApi';

export default class NetPriceRepository implements PriceRepository {
  private endPoint = '/api/writer/my_stripe/maker_pro/price';
  public mapper = new PriceMapper();

  constructor() {}

  public async find(): Promise<Price> {
    const path = `${this.endPoint}`;
    const obj = await TapNovelRestApi.get<PriceData>(path);
    return this.mapper.map(obj.body);
  }
}
