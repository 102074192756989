import * as React from 'react';
import {TouchableOpacity} from 'react-native';

import Tag from '../../../shared/Tag';

import Contest from '../../../../../domain/entities/Contest';

interface Props {
  contest: Contest;
  onPress: (contest: Contest) => void;
}

const ContestKeywordTag: React.FunctionComponent<Props> = props => {
  const {contest, onPress} = props;
  const handlePress = React.useCallback(() => {
    onPress(contest);
  }, []);
  return (
    <TouchableOpacity
      key={contest.id}
      activeOpacity={0.6}
      onPress={handlePress}>
      <Tag name={contest.keywordName} />
    </TouchableOpacity>
  );
};

export default React.memo(ContestKeywordTag);
