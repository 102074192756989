import * as React from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {buildPathWithQuery} from '../../../../helpers/buildPathWithQuery';
import {SortValue} from './types';

import SearchIcon from '../../../shared/icons/SearchIcon';
import ClearIcon from '../../../shared/icons/ClearIcon';

interface Props {
  value?: string;
  sort?: SortValue;
  textInputStyle?: StyleProp<TextStyle>;
  placeholder?: string;
}

const SearchForm: React.FC<Props> = props => {
  const {textInputStyle, placeholder, sort} = props;
  const linkTo = useLinkTo();
  const [value, setValue] = React.useState(props.value);
  const onChangeText = React.useCallback((text: string) => {
    setValue(text);
  }, []);
  const onSubmitEditing = React.useCallback(() => {
    const obj = {sort, query: value};
    linkTo(buildPathWithQuery('/faq/questions', obj));
  }, [value, sort]);
  const onPressClear = React.useCallback(() => {
    setValue('');
  }, []);
  return (
    <View style={styles.textInputWrapper}>
      <TextInput
        style={[styles.textInput, textInputStyle]}
        value={value}
        clearButtonMode={'while-editing'}
        placeholder={placeholder || 'お問い合わせ内容を入力'}
        placeholderTextColor={'#999'}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmitEditing}
      />
      <View style={styles.searchIcon}>
        <View style={styles.icon}>
          <SearchIcon size={16} color={'#383838'} />
        </View>
      </View>
      <View style={styles.clearIcon}>
        <View style={styles.icon}>
          {value ? (
            <Pressable onPress={onPressClear}>
              <ClearIcon size={16} color={'#999999'} />
            </Pressable>
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default React.memo(SearchForm);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    padding: 16,
    backgroundColor: '#fafafa',
    borderRadius: 4,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 4,
  } as TextStyle,
  textInputWrapper: {
    flexDirection: 'row',
    height: 40,
    marginVertical: 16,
    marginBottom: 8,
  } as ViewStyle,
  textInput: {
    flex: 1,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    height: 40,
    paddingLeft: 32,
  } as TextStyle,
  searchIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 8,
  } as ViewStyle,
  clearIcon: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 8,
  },
  icon: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
