import AutoSaveCreatingSceneMapper, {
  AutoSaveCreatingSceneData,
} from '../entities/mappers/AutoSaveCreatingSceneMapper';

import JsonAsyncStorage from '../data_stores/storage/JsonAsyncStorage';

import AutoSaveCreatingScene from '../../domain/entities/AutoSaveCreatingScene';

import SceneForm from '../../domain/forms/SceneForm';
import SceneCommandForm from '../../domain/forms/scene_commands/SceneCommandForm';

import AutoSaveCreatingScenesRepository from '../../domain/repositories/AutoSaveCreatingScenesRepository';

export default class StorageAutoSaveCreatingScenesRepository
  implements AutoSaveCreatingScenesRepository
{
  private static KEY = 'auto_save_creating_scenes';

  private mapper: AutoSaveCreatingSceneMapper;

  constructor() {
    this.mapper = new AutoSaveCreatingSceneMapper();
  }

  public async find(id: number): Promise<AutoSaveCreatingScene> {
    const row: AutoSaveCreatingSceneData | null = await JsonAsyncStorage.get(
      this.generateKey(id),
    );
    if (!row) {
      throw new Error(`Not Found AutoSaveCreatingScene #${id}`);
    }
    return this.mapper.map(row);
  }

  public async update(
    id: number,
    params: {
      sceneForm: SceneForm;
      sceneCommandForms: SceneCommandForm[];
      updatedAt: Date;
    },
  ): Promise<AutoSaveCreatingScene> {
    const row: AutoSaveCreatingSceneData = {
      id,
      ...params,
      updatedAt: params.updatedAt.toString(),
    };
    await JsonAsyncStorage.set(this.generateKey(id), row);
    return this.mapper.map(row);
  }

  public async destroy(id: number): Promise<void> {
    const row: AutoSaveCreatingSceneData = await JsonAsyncStorage.get(
      this.generateKey(id),
    );
    if (!row) {
      return;
    }
    await JsonAsyncStorage.remove(this.generateKey(id));
    return;
  }

  private generateKey(id: number) {
    return `${StorageAutoSaveCreatingScenesRepository.KEY}/${id}`;
  }
}
