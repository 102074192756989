import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import BackgroundMapper, {
  BackgroundData,
} from '../../entities/mappers/BackgroundMapper';

import Background from '../../../domain/entities/Background';
import BackgroundsRepository from '../../../domain/repositories/writer/BackgroundsRepository';

export default class NetBackgroundsRepository
  extends NetResourcesRepository<Background, BackgroundData>
  implements BackgroundsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/backgrounds',
        new BackgroundMapper(),
      ),
    );
  }
}
