import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import UserResourceFavoriteMapper, {
  UserResourceFavoriteData,
} from '../../entities/mappers/writer/UserResourceFavoriteMapper';

import UserResourceFavorite from '../../../domain/entities/writer/UserResourceFavorite';
import UserResourceFavoritesRepository from '../../../domain/repositories/writer/UserResourceFavoritesRepository';

export default class NetUserResourceFavoritesRepository
  extends NetResourcesRepository<UserResourceFavorite, UserResourceFavoriteData>
  implements UserResourceFavoritesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/user_resource_favorites',
        new UserResourceFavoriteMapper(),
      ),
    );
  }
}
