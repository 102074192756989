import SceneFrame from '../view_models/SceneFrame';

import CharacterHideSceneCommandForm from '../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import CharacterShowSceneCommandForm from '../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import SceneCommandForm from '../../domain/forms/scene_commands/SceneCommandForm';

const generateSceneFrame = (
  sceneCommandForms: SceneCommandForm[] | null,
  sceneCommandIndex?: number,
): SceneFrame => {
  const sceneFrame = new SceneFrame();
  if (!sceneCommandForms) {
    return sceneFrame;
  }
  sceneCommandForms.forEach((sceneCommandForm, index) => {
    if (
      sceneCommandIndex !== undefined &&
      sceneCommandIndex !== null &&
      sceneCommandIndex <= index
    ) {
      return;
    }
    if (
      sceneCommandForm instanceof CharacterShowSceneCommandForm ||
      sceneCommandForm instanceof CharacterUpdateSceneCommandForm ||
      sceneCommandForm instanceof CharacterHideSceneCommandForm
    ) {
      sceneFrame.update(sceneCommandForm.position, sceneCommandForm);
    } else if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      sceneCommandForm.commandForms.forEach(commandForm => {
        if (commandForm instanceof CompositeParallelSceneCommandForm) {
          commandForm.commandForms.forEach(subCommandForm => {
            sceneFrame.update(subCommandForm.position, subCommandForm);
          });
        } else if (
          commandForm instanceof CharacterShowSceneCommandForm ||
          commandForm instanceof CharacterUpdateSceneCommandForm ||
          commandForm instanceof CharacterHideSceneCommandForm
        ) {
          sceneFrame.update(commandForm.position, commandForm);
        }
      });
    }
  });
  return sceneFrame;
};

export default generateSceneFrame;
