import * as React from 'react';

import BaseIndex from '../../shared/generics/character_patterns/Index';
import shouldUpdateCharacterPatternList from '../../shared/enhanced/shouldUpdateCharacterPatternList';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormCharacterPatternsRouteProp} from '../../../navigators/RouteProps';

import {Params as CharacterPatternIndexParams} from '../../../actions/character_patterns/index';

import {equalForKeys} from '../../../helpers/equalForKeys';
import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import CharacterPattern from '../../../../domain/entities/CharacterPattern';
import PaginatedResult from '../../../../domain/results/PaginatedResult';
import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';
import ActorSearchSetting from '../../../../domain/value_objects/ActorSearchSetting';

import StorageActorSearchSettingRepository from '../../../../data/repositories/StorageActorSearchSettingRepository';

const actorSearchSettingRepository = new StorageActorSearchSettingRepository();

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormCharacterPatternsRouteProp;
  currentUserStatus?: CurrentUserStatus;
  coverImageForm: CoverImageForm | null;
  characterPatternsParams: CharacterPatternIndexParams;
  characterPatterns: CharacterPattern[] | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  private actorSearchSetting: ActorSearchSetting | null = null;

  constructor(props: Props) {
    super(props);
    actorSearchSettingRepository
      .find()
      .then(actorSearchSetting => {
        this.actorSearchSetting = actorSearchSetting;
      })
      .catch(() => {});
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!equalForKeys(this.props, nextProps, ['characterPatternsParams'])) {
      return true;
    }
    if (shouldUpdateCharacterPatternList(this.props, nextProps)) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        onSelectCharacterPattern={this.handleSelectCharacterPattern}
        onForwardToNewCharacterPattern={this.handleForwardToNewCharacterPattern}
        onForwardToNewCharacter={this.handleForwardToNewCharacter}
        onForwardToFavoriteList={this.handleForwardToFavoriteList}
        labelExtractor={this.labelExtractor}
      />
    );
  }

  private handleSelectCharacterPattern = (
    characterPattern: CharacterPattern,
  ) => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;
    navigation.navigate('CoverImageFormActorCharacterFaces', {
      storyId,
      aspectRatio,
      imageKey,
      characterId: characterPattern.character.id,
      characterPatternId: characterPattern.id,
    });
  };

  private handleForwardToNewCharacterPattern = () => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;
    const withPaid =
      this.actorSearchSetting &&
      (this.actorSearchSetting.withPaid === true ||
        this.actorSearchSetting.withPaid === false)
        ? this.actorSearchSetting.withPaid
        : false;
    navigation.navigate('CoverImageFormCharacterFormActorSearchForm', {
      storyId,
      aspectRatio,
      imageKey,
      withPaid,
    });
  };

  private handleForwardToNewCharacter = () => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;
    navigation.navigate('CoverImageFormCharacterMakerHome', {
      storyId,
      aspectRatio,
      imageKey,
    });
  };

  private handleForwardToFavoriteList = () => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;
    navigation.navigate('CoverImageFormCharacterFormActors', {
      storyId,
      aspectRatio,
      imageKey,
      formValues: {
        query: '',
        ageMin: 0,
        ageMax: 6,
        rootCategoryIdToActorCategoryIds: {},
        favorite: true,
      },
    });
  };

  private labelExtractor = (characterPattern: CharacterPattern) => {
    return characterPattern.character.name;
  };
}
