import equal from 'react-fast-compare';

const equalForKeys = (
  a: {[key: string]: any},
  b: {[key: string]: any},
  keys?: string[],
) => {
  return equal(pick(a, keys), pick(b, keys));
};

const pick = function pick(obj: {[key: string]: any}, keys?: string[]) {
  if (!keys) {
    return obj;
  }
  const result: {[key: string]: any} = {};
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (Object.hasOwnProperty.call(obj, key)) {
      result[key] = obj[key];
    }
  }
  return result;
};

export {equalForKeys};
