import EntityMapper from './EntityMapper';

import ActorCharacterMapper, {ActorCharacterData} from './ActorCharacterMapper';
import CharacterMapper, {CharacterData} from './CharacterMapper';

import CharacterPattern from '../../../domain/entities/CharacterPattern';

export interface CharacterPatternData {
  id: number;
  character: CharacterData;
  actor_character: ActorCharacterData;
  actor_character_id: number;
  original_image_url: string;
}

export default class CharacterPatternMapper
  implements EntityMapper<CharacterPatternData, CharacterPattern>
{
  private characterMapper: CharacterMapper;
  private actorCharacterMapper: ActorCharacterMapper;

  constructor() {
    this.characterMapper = new CharacterMapper();
    this.actorCharacterMapper = new ActorCharacterMapper();
  }

  public map(obj: CharacterPatternData): CharacterPattern {
    const character = new CharacterMapper().map(obj.character);
    const actorCharacter = new ActorCharacterMapper().map(obj.actor_character);
    return new CharacterPattern(
      obj.id,
      character,
      actorCharacter,
      obj.actor_character_id,
      obj.original_image_url,
    );
  }
}
