import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import SoundUsageHistoryMapper, {
  SoundUsageHistoryData,
} from '../../entities/mappers/SoundUsageHistoryMapper';

import SoundUsageHistory from '../../../domain/entities/SoundUsageHistory';
import SoundUsageHistoriesRepository from '../../../domain/repositories/writer/SoundUsageHistoriesRepository';

export default class NetSoundUsageHistoriesRepository
  extends NetResourcesRepository<SoundUsageHistory, SoundUsageHistoryData>
  implements SoundUsageHistoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/sound_usage_histories',
        new SoundUsageHistoryMapper(),
      ),
    );
  }
}
