import EntityMapper from '../EntityMapper';

import EpisodeVideoExportJobExecution from '../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

export interface EpisodeVideoExportJobExecutionData {
  id: number;
  episode: {
    id: number;
    title: string;
    version: number;
    story: {
      id: number;
      title: string;
      has_image: boolean;
      original_image_url: string;
      has_cover_image: boolean;
      original_cover_image_url: string;
    };
  };
  episode_video: {
    id: number;
    episode_version: number;
    created_at: string;
    updated_at: string;
    video_inline_url: string;
    video_download_url: string;
  } | null;
  video_type: 'vertical_full' | 'horizontal_full';
  video_auto_play_speed: '1.0' | '1.5' | '2.0';
  title: string | null;
  has_image: boolean;
  original_image_url: string;
  video_download_url: string | null;
  image_download_url: string | null;
  status: 'pending' | 'running' | 'succeeded' | 'failed' | 'cancelled';
  started_at: string | null;
  finished_at: string | null;
  created_at: string;
  updated_at: string;
}

export default class CurrentUserMapper
  implements
    EntityMapper<
      EpisodeVideoExportJobExecutionData,
      EpisodeVideoExportJobExecution
    >
{
  public map(
    obj: EpisodeVideoExportJobExecutionData,
  ): EpisodeVideoExportJobExecution {
    return new EpisodeVideoExportJobExecution(
      obj.id,
      {
        id: obj.episode.id,
        title: obj.episode.title,
        version: obj.episode.version,
        story: {
          id: obj.episode.story.id,
          title: obj.episode.story.title,
          hasImage: obj.episode.story.has_image,
          originalImageUrl: obj.episode.story.original_image_url,
          hasCoverImage: obj.episode.story.has_cover_image,
          originalCoverImageUrl: obj.episode.story.original_cover_image_url,
        },
      },
      obj.episode_video
        ? {
            id: obj.episode_video.id,
            episodeVersion: obj.episode_video.episode_version,
            createdAt: new Date(obj.episode_video.created_at),
            updatedAt: new Date(obj.episode_video.updated_at),
            videoInlineUrl: obj.episode_video.video_inline_url,
            videoDownloadUrl: obj.episode_video.video_download_url,
          }
        : null,
      obj.video_type,
      obj.video_auto_play_speed,
      obj.title,
      obj.has_image,
      obj.original_image_url,
      obj.video_download_url,
      obj.image_download_url,
      obj.status,
      obj.started_at ? new Date(obj.started_at) : null,
      obj.finished_at ? new Date(obj.finished_at) : null,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
