import EntityMapper from '../EntityMapper';

import ActorFace from '../../../../domain/entities/character_maker/ActorFace';

export interface ActorFaceData {
  id: number;
  actor_id: number;
  face_pattern_id: number;
  face_pattern_name: string;
  color_pattern_id: number;
  color_pattern_name: string;
  original_image_url: string;
}

export default class ActorFaceMapper
  implements EntityMapper<ActorFaceData, ActorFace>
{
  public map(obj: ActorFaceData): ActorFace {
    return new ActorFace(
      obj.id,
      obj.actor_id,
      obj.face_pattern_id,
      obj.face_pattern_name,
      obj.color_pattern_id,
      obj.color_pattern_name,
      obj.original_image_url,
    );
  }
}
