import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/character_form/new/home/Index';

import AppState from '../../../../reducers/AppState';

import indexCharacterPatterns, {
  Params as CharacterPatternIndexParams,
} from '../../../../actions/character_patterns/index';

import selectEntities from '../../../../helpers/selectEntities';
import selectEntity from '../../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../../helpers/ResourceAuthorizattion';
import {CharacterFormNewRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CharacterFormNewRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const {storyId} = route.params || {};
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const sceneForm = forms.sceneForm;
  const characterPatternsParams: CharacterPatternIndexParams = {storyId};
  const characterPatterns = selectEntities(
    entities.characterPatterns,
    queries.characterPatterns,
    characterPatternsParams,
  );
  return {
    sceneForm,
    currentUserStatus,
    characterPatterns,
    characterPatternsParams,
    navigation,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexCharacterPatterns: (params: CharacterPatternIndexParams) =>
      dispatch(indexCharacterPatterns(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
