import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import dateformat from 'dateformat';

import TopicLikeButton from './TopicLikeButton';

import LinkedText from '../../../shared/LinkedText';

import WriterUserImage from '../../members/partials/WriterUserImage';

import PenIcon from '../../../shared/icons/PenIcon';

import ProjectTopic from '../../../../../domain/entities/ProjectTopic';

interface Props {
  topic: ProjectTopic;
  topicOwner: boolean;
}

const Detail: React.FC<Props> = props => {
  const {topic, topicOwner} = props;
  const linkTo = useLinkTo();
  const onPressEdit = React.useCallback(() => {
    linkTo(`/projects/${topic.projectId}/topics/${topic.id}/edit`);
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <WriterUserImage user={topic.writerUser} imageSize={60} />
        <View style={styles.topMiddle}>
          <Text style={styles.penName}>{topic.writerUser.penName}</Text>
          <Text style={styles.date}>
            {dateformat(topic.createdAt, 'yyyy.mm.dd HH:MM')}
          </Text>
        </View>
        {topicOwner ? (
          <TouchableOpacity
            style={styles.editButton}
            activeOpacity={0.6}
            onPress={onPressEdit}>
            <>
              <PenIcon size={13} color={'#383838'} />
              <Text style={styles.editButtonLabel}>編集</Text>
            </>
          </TouchableOpacity>
        ) : null}
      </View>
      <Text style={styles.title}>{topic.title}</Text>
      <Text style={styles.content}>
        <LinkedText>{topic.content}</LinkedText>
      </Text>
      <TopicLikeButton topic={topic} />
    </View>
  );
};

export default React.memo(Detail);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 16,
  } as ViewStyle,
  top: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  topMiddle: {
    flex: 1,
    justifyContent: 'center',
    marginLeft: 10,
  } as ViewStyle,
  penName: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 4,
  } as TextStyle,
  date: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  editButton: {
    width: 82,
    height: 28,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#383838',
    borderWidth: 1,
    borderRadius: 16,
  } as ViewStyle,
  editButtonLabel: {
    marginLeft: 4,
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 16,
  } as TextStyle,
  content: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
  likes: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  } as ViewStyle,
  likesCount: {
    color: '#f24006',
    fontSize: 14,
    marginLeft: 4,
  } as TextStyle,
  likesActiveCount: {
    color: '#999',
    fontSize: 14,
    marginLeft: 4,
  } as TextStyle,
});
