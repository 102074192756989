import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import CoachmarkState from '../../view_models/CoachmarkState';

export default function reducer(
  state: CoachmarkState | null = null,
  action: AnyAction,
): CoachmarkState | null {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return null;
    case Types.UpdateCoachmarkModal:
      return action.payload;
    default:
      return state;
  }
}
