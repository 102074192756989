import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import CharacterPatternMapper, {
  CharacterPatternData,
} from '../../entities/mappers/CharacterPatternMapper';

import CharacterPattern from '../../../domain/entities/CharacterPattern';
import CharacterPatternsRepository from '../../../domain/repositories/writer/CharacterPatternsRepository';

export default class NetCharacterPatternsRepository
  extends NetResourcesRepository<CharacterPattern, CharacterPatternData>
  implements CharacterPatternsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_patterns',
        new CharacterPatternMapper(),
      ),
    );
  }
}
