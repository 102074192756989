import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import StoryAggregationListItem from './StoryAggregationListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import StoryAggregation from '../../../../../domain/value_objects/partner_program/StoryAggregation';

interface Props {
  storyAggregations: Array<StoryAggregation> | null;
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const StoryAggregationList: React.FunctionComponent<Props> = props => {
  const {storyAggregations, ListHeaderComponent} = props;
  const keyExtractor = React.useCallback(
    (storyAggregation: StoryAggregation) => `${storyAggregation.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<StoryAggregation>) => (
      <StoryAggregationListItem storyAggregation={info.item} />
    ),
    [],
  );
  return (
    <FlatList
      data={storyAggregations}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(StoryAggregationList);
