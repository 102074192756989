import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';

import {storyCoverImageUrl} from '../../../../helpers/images';

import {colors} from '../../../../styles/variables';

import Story from '../../../../../domain/entities/response/Story';

interface Props {
  story: Story;
}

const StoryInfo: React.FunctionComponent<Props> = props => {
  const {story} = props;
  const source = {
    uri: storyCoverImageUrl(story, 'small'),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  return (
    <View style={styles.container}>
      <View style={styles.body}>
        {story.hasCoverImage ? (
          <FastImageAdapter style={styles.image} source={source} />
        ) : (
          <View style={styles.image}>
            <Text style={styles.noImageText}>表紙なし</Text>
          </View>
        )}
        <View style={styles.info}>
          <View style={[styles.infoBox, styles.infoBoxLeft]}>
            <Text style={styles.infoBoxLabel}>リアクション</Text>
            <Text style={styles.infoBoxValue}>
              {numeral(story.reactionsCount).format('0,0')}
            </Text>
          </View>
          <View style={[styles.infoBox, styles.infoBoxRight]}>
            <Text style={styles.infoBoxLabel}>コメント</Text>
            <Text style={styles.infoBoxValue}>
              {numeral(story.commentsCount).format('0,0')}
            </Text>
          </View>
        </View>
      </View>
      <Text style={styles.titleForEpisodes}>エピソード別</Text>
    </View>
  );
};

export default React.memo(StoryInfo);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  container: {
    padding: 16,
    paddingBottom: 10,
    backgroundColor: '#fafafa',
    width: '100%',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  body: {
    flexDirection: 'row',
    marginBottom: 24,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
    marginRight: 16,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  info: {
    alignItems: 'center',
    flexDirection: 'row',
    flex: 1,
  } as ViewStyle,
  infoBox: {
    backgroundColor: '#f2f2f2',
    flex: 1,
    width: '100%',
    aspectRatio: 125 / 54,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  infoBoxLeft: {
    marginRight: 4,
  } as ViewStyle,
  infoBoxRight: {
    marginLeft: 4,
  } as ViewStyle,
  infoBoxLabel: {
    color: '#383838',
    fontSize: 11,
    marginBottom: 4,
  } as TextStyle,
  infoBoxValue: {
    color: '#ff8f13',
    fontSize: 17,
    fontWeight: 'bold',
  } as TextStyle,
  titleForEpisodes: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#222',
  } as TextStyle,
});
