import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import BackgroundWeather from '../../../domain/entities/BackgroundWeather';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetBackgroundWeathersRepository from '../../../data/repositories/NetBackgroundWeathersRepository';

const types = {
  failure: Types.IndexBackgroundWeathersFailure,
  request: Types.IndexBackgroundWeathersRequest,
  success: Types.IndexBackgroundWeathersSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<BackgroundWeather>> {
  return indexGenerics(new NetBackgroundWeathersRepository(), types, params);
}
