import {combineReducers} from 'redux';

import actorAccessories from './actorAccessories';
import actorAccessorySets from './actorAccessorySets';
import actorCategories from './actorCategories';
import actorCharacterFaces from './actorCharacterFaces';
import actorCharacters from './actorCharacters';
import actorCostumes from './actorCostumes';
import actorHairStyles from './actorHairStyles';
import actors from './actors';
import backgroundCategories from './backgroundCategories';
import backgrounds from './backgrounds';
import backgroundTimeZones from './backgroundTimeZones';
import backgroundUsageHistories from './backgroundUsageHistories';
import backgroundWeathers from './backgroundWeathers';
import baseBackgrounds from './baseBackgrounds';
import characterMakerActorGroups from './characterMakerActorGroups';
import characterPatterns from './characterPatterns';
import characters from './characters';
import effectCategories from './effectCategories';
import effectUsageHistories from './effectUsageHistories';
import episodeComments from './episodeComments';
import episodeCommentBlockingUsers from './episodeCommentBlockingUsers';
import episodeReactions from './episodeReactions';
import episodes from './episodes';
import fullScreenIllustrations from './fullScreenIllustrations';
import fullScreenIllustrationUsageHistories from './fullScreenIllustrationUsageHistories';
import baseEffects from './baseEffects';
import effects from './effects';
import positionedEffects from './positionedEffects';
import genres from './genres';
import illustrationCategories from './illustrationCategories';
import illustrations from './illustrations';
import illustrationUsageHistories from './illustrationUsageHistories';
import marks from './marks';
import orientedSpeechBalloons from './orientedSpeechBalloons';
import scenes from './scenes';
import sceneWithCommandsWrappers from './sceneWithCommandsWrappers';
import soundCategories from './soundCategories';
import sounds from './sounds';
import soundUsageHistories from './soundUsageHistories';
import speechBalloons from './speechBalloons';
import stories from './stories';
import textFrames from './textFrames';
import wallpapers from './wallpapers';
import wallpaperUsageHistories from './wallpaperUsageHistories';
import writer from './writer';
import response from './response/index';

export default combineReducers({
  actorAccessories,
  actorAccessorySets,
  actorCategories,
  actorCharacterFaces,
  actorCharacters,
  actorCostumes,
  actorHairStyles,
  actors,
  backgroundCategories,
  backgroundTimeZones,
  backgroundUsageHistories,
  backgroundWeathers,
  backgrounds,
  baseBackgrounds,
  characterMakerActorGroups,
  characterPatterns,
  characters,
  effectCategories,
  effectUsageHistories,
  episodeComments,
  episodeCommentBlockingUsers,
  episodeReactions,
  episodes,
  fullScreenIllustrationUsageHistories,
  fullScreenIllustrations,
  baseEffects,
  effects,
  positionedEffects,
  genres,
  illustrationCategories,
  illustrationUsageHistories,
  illustrations,
  marks,
  orientedSpeechBalloons,
  sceneWithCommandsWrappers,
  scenes,
  soundCategories,
  sounds,
  soundUsageHistories,
  speechBalloons,
  stories,
  textFrames,
  wallpapers,
  wallpaperUsageHistories,
  writer,
  response,
});
