import * as React from 'react';

import Form, {FormValues} from './partials/form/Form';

import AlertModal from '../shared/modals/AlertModal';

import NavigationProp from '../../navigators/NavigationProp';

import {formatErrorMessages} from '../../helpers/errorMessages';

import CurrentUser from '../../../domain/entities/writer/CurrentUser';

import Project from '../../../domain/entities/Project';

import TapNovelTypedRestApi from '../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, showCurrentUser} = props;
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | null>(
    null,
  );
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalTooltip = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const onSubmit = React.useCallback((formValues: FormValues) => {
    const {
      title,
      introduction,
      catchPhrase,
      rating,
      format,
      allowedVideoSharing,
      mainGenreId,
      subGenreIds,
      keywordNames,
      ...projectValues
    } = formValues;
    TapNovelTypedRestApi.post<Project>('/api/writer/user/projects', {
      project: {
        ...projectValues,
        storyAttributes: {
          title,
          introduction,
          catchPhrase,
          rating,
          format,
          allowedVideoSharing,
          mainGenreId,
          subGenreIds,
          keywordNames,
        },
      },
    })
      .then(response => {
        navigation.navigate('Projects', {});
        navigation.navigate('Project', {id: response.body.id});
        navigation.navigate('EditProjectStoryCoverImage', {
          storyId: response.body.story.id,
        });
      })
      .catch(e => {
        setAlertMessage(formatErrorMessages({}, e));
      });
  }, []);
  React.useEffect(() => {
    showCurrentUser().then(currentUser => {
      setCurrentUser(currentUser);
    });
  }, []);
  if (!currentUser) {
    return null;
  }
  return (
    <>
      <Form
        navigation={navigation}
        isNew={true}
        onSubmit={onSubmit}
        editableEpisodeCopyright={currentUser.isGradeBlack()}
      />
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
    </>
  );
};

export default React.memo(New);
