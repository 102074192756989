import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import LinkButton from '../../shared/buttons/LinkButton';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import DefaultModal from '../../shared/modals/DefaultModal';

import Scene from '../../../../domain/entities/Scene';

interface Props {
  scene: Scene;
  onRequestDeleteScene: (scene: Scene) => void;
  onRequestCloseModal: () => void;
  children?: React.ReactNode;
}

export default class SceneDeleteConfirmModal extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {scene, onRequestCloseModal} = this.props;
    return (
      <DefaultModal
        visible={true}
        onCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.buttons}>
            <LinkButton style={styles.button} onPress={onRequestCloseModal}>
              キャンセル
            </LinkButton>
            <PrimaryButton style={styles.button} onPress={this.handlePress}>
              削除
            </PrimaryButton>
          </View>
        }>
        <Text
          style={
            styles.title
          }>{`シーン${scene.numberOfEpisode}を削除する`}</Text>
        <View style={styles.body}>
          <Text style={styles.text}>
            一度削除したシーンは復元できません。{'\n'}
            作成したシーンは削除されますが{'\n'}
            よろしいですか？
          </Text>
        </View>
      </DefaultModal>
    );
  }

  private handlePress = () => {
    const {scene, onRequestDeleteScene} = this.props;
    onRequestDeleteScene(scene);
  };
}

const styles = StyleSheet.create({
  body: {
    marginTop: 24,
    marginBottom: 32,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    marginBottom: 32,
  } as ViewStyle,
  button: {
    marginHorizontal: 3,
    width: 145,
    height: 46,
  } as ViewStyle,
  title: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    marginTop: 32,
    color: '#222',
    fontWeight: 'bold',
  } as TextStyle,
  text: {
    color: '#222',
    fontSize: 14,
    lineHeight: 22,
    textAlign: 'center',
  } as TextStyle,
});
