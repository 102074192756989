import NavigationProp from '../navigators/NavigationProp';
import * as routers from '../routers';

import Story from '../../domain/entities/Story';

import TapNovelRestApi from '../../data/data_stores/net/TapNovelRestApi';

const navigateStoryPublication = async (
  navigation: NavigationProp,
  story: Story,
  options?: {
    episodeIdToBePublished?: number;
  },
) => {
  const storyId = story.id;
  const episodeIdToBePublished = options?.episodeIdToBePublished;
  if (isFulfilledAll(story) && episodeIdToBePublished) {
    if (await isFulfilledEpisode(episodeIdToBePublished)) {
      routers.linkToEpisodeFormEditEpisodePublication(navigation, {
        episodeId: episodeIdToBePublished,
      });
    } else {
      routers.linkToEditEpisode(navigation, {
        episodeId: episodeIdToBePublished,
        toBePublished: true,
      });
    }
  } else if (isFulfilledInfo(story)) {
    routers.linkToEditStoryNavigation(
      navigation,
      {
        storyId,
        episodeIdToBePublished,
      },
      'EditStoryFormCoverImage',
    );
  } else {
    routers.linkToEditStoryNavigation(navigation, {
      storyId,
      episodeIdToBePublished,
    });
  }
};

export default navigateStoryPublication;

export const isFulfilledAll = (story: Story) => {
  if (!isFulfilledInfo(story)) {
    return false;
  }
  if (!isFulfilledImages(story)) {
    return false;
  }
  return true;
};

export const isFulfilledInfo = (story: Story) => {
  const title = getTitle(story);
  if (!title) {
    return false;
  }
  if (!story.getMainGenre()) {
    return false;
  }
  if (story.getSubGenres().length === 0) {
    return false;
  }
  if (!story.introduction) {
    return false;
  }
  if (!story.catchPhrase) {
    return false;
  }
  if (story.keywords.length === 0) {
    return false;
  }
  return true;
};

export const isFulfilledImages = (story: Story) => {
  if (!story.hasCoverImage) {
    return false;
  }
  return true;
};

export const isFulfilledEpisode = async (episodeId: number) => {
  const res = await TapNovelRestApi.post(
    `/api/writer/episodes/${episodeId}/satisfaction_status`,
  );
  if (res.body) {
    return (res.body as any).filled;
  } else {
    return false;
  }
};

const getTitle = (story: Story) =>
  story.title.match(/^無題(\d+)?$/) ? '' : story.title;
