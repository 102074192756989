import * as React from 'react';
import {StyleProp, StyleSheet, ViewStyle} from 'react-native';
import {LineChart} from 'react-native-chart-kit';
import EpisodeBrowsingRetentionRate from '../../../../../domain/value_objects/summary/EpisodeBrowsingRetentionRate';

const dataset = {
  key: 'min-max',
  data: [100, 0],
  color: (opacity: number) => 'rgba(0,0,0,0)',
};

function generateData(
  episodeBrowsingRetentionRates: Array<EpisodeBrowsingRetentionRate>,
) {
  return {
    labels: episodeBrowsingRetentionRates.map(
      (episodeBrowsingRetentionRate, i) => {
        if (i % Math.ceil(episodeBrowsingRetentionRates.length / 5) === 0) {
          return `${episodeBrowsingRetentionRate.commandIndex}`;
        }
        return '';
      },
    ),
    datasets: [
      dataset,
      {
        data: episodeBrowsingRetentionRates.map(
          episodeBrowsingRetentionRate =>
            episodeBrowsingRetentionRate.retentionRate * 100,
        ),
      },
    ],
  };
}

interface Props {
  episodeBrowsingRetentionRates: Array<EpisodeBrowsingRetentionRate>;
  width: number;
  onTouchPoint: (point: {
    top: number;
    left: number;
    commandIndex: number;
  }) => void;
}

const EpisodeBrowsingRetentionRateGraph: React.FunctionComponent<
  Props
> = props => {
  const {episodeBrowsingRetentionRates, width, onTouchPoint} = props;
  return (
    <LineChart
      style={styles.container}
      data={generateData(episodeBrowsingRetentionRates) as any}
      width={width}
      height={(width / 375) * 250}
      xAxisLabel={''}
      yAxisLabel={''}
      yAxisSuffix={'%'}
      fromZero={true}
      withVerticalLines={false}
      withShadow={false}
      onDataPointClick={data => {
        if (data.dataset.key === 'min-max') {
          return;
        }
        onTouchPoint({
          top: data.y - 4,
          left: data.x - 17,
          commandIndex: data.index,
        });
      }}
      chartConfig={{
        strokeWidth: 2,
        barPercentage: 0.4,
        color: () => '#ff8f13',
        backgroundGradientFrom: 'white',
        backgroundGradientTo: 'white',
        propsForBackgroundLines: {
          strokeDasharray: '0',
          strokeWidth: 0.5,
          stroke: '#efefef',
        },
        propsForHorizontalLabels: {
          fill: '#383838',
        },
        propsForVerticalLabels: {
          fill: '#383838',
        },
        decimalPlaces: 0,
        propsForDots: {
          fill: '#fff',
          fillOpacity: 0,
        },
      }}
    />
  );
};

export default React.memo(EpisodeBrowsingRetentionRateGraph);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    alignItems: 'center',
    paddingRight: 60,
    paddingLeft: 32,
  } as ViewStyle,
});
