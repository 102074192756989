import Entity from './Entity';

import Scene from './Scene';

import SceneCommand from '../value_objects/scene_commands/SceneCommand';

export default class SceneWithCommandsWrapper implements Entity {
  constructor(
    public id: number,
    public scene: Scene,
    public sceneCommands: SceneCommand[],
    public updatedAt: Date,
  ) {}
}
