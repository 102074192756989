import * as React from 'react';
import {
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import dateformat from 'dateformat';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import DimensionContext from '../../../shared/dimension/DimensionContext';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../../styles/variables';
import {storyCoverImageUrl} from '../../../../helpers/images';

import Story from '../../../../../domain/entities/writer/vertical_short_video/Story';

interface Props {
  story: Story;
  onEpisodeVideoExport: () => void;
}

const Detail: React.FunctionComponent<Props> = props => {
  const {story, onEpisodeVideoExport} = props;
  const source = React.useMemo(() => {
    return {
      uri: storyCoverImageUrl(new StoryCoverImageDecorator(story), 'large'),
      headers: {Accept: 'image/webp,image/apng,*/*'},
    };
  }, []);
  return (
    <DimensionContext.Consumer>
      {context => {
        const width = context.content.width * 0.48;
        const size = {width, height: width / ASPECT_RATIO};
        return (
          <View style={styles.container}>
            <View style={styles.imageWrapper}>
              {story.hasCoverImage ? (
                <FastImageAdapter
                  style={[styles.image, size]}
                  source={source}
                />
              ) : (
                <View style={[styles.image, size]}>
                  <Text style={styles.noImageText}>表紙なし</Text>
                </View>
              )}
            </View>
            <PrimaryButton
              disabled={['job_running', 'up-to-date'].includes(story.status)}
              onPress={onEpisodeVideoExport}>
              このストーリーのCM動画を作成する
            </PrimaryButton>
          </View>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(Detail);

const ASPECT_RATIO = 0.7;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  } as ViewStyle,
  imageWrapper: {
    marginTop: 32,
    alignItems: 'center',
    marginBottom: 32,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
});
