import * as React from 'react';
import {StyleProp, StyleSheet, Text, TextStyle, ViewStyle} from 'react-native';

import DefaultOutlineButton from './buttons/DefaultOutlineButton';

import {colors} from '../../styles/variables';

import {isWindows} from '../../../data/data_stores/net/UserAgent';

interface Props {
  style?: StyleProp<ViewStyle>;
  symbol: string;
  onPress: (symbol: string) => void;
}

export default class AddFrequentlySymbolButton extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {style, symbol} = this.props;
    return (
      <DefaultOutlineButton
        style={[styles.container, style]}
        onPress={this.handlePress}>
        <Text style={styles.title}>
          {symbol === '──' && isWindows ? '―' : symbol}
        </Text>
      </DefaultOutlineButton>
    );
  }

  private handlePress = () => {
    const {symbol, onPress} = this.props;
    onPress(symbol);
  };
}

const styles = StyleSheet.create({
  container: {
    height: 40,
    width: 42,
    margin: 3,
    borderRadius: 3,
    borderColor: '#383838',
    borderWidth: 1.5,
  } as ViewStyle,
  title: {
    color: colors.textColor,
    fontSize: 10,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
});
