import * as React from 'react';

import BaseIndex from '../../common/effect_usage_histories/Index';

import shouldUpdateEntityList from '../../../../shared/enhanced/shouldUpdateEntityList';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormEffectShowSceneCommandNewEffectUsageHistoriesRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as EffectUsageHistoryIndexParams} from '../../../../../actions/effect_usage_histories/index';

import {equalForKeys} from '../../../../../helpers/equalForKeys';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import EffectUsageHistory from '../../../../../../domain/entities/EffectUsageHistory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  back?: boolean;
  page?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandNewEffectUsageHistoriesRouteProp;
  sceneForm: SceneForm | null;
  effectUsageHistories: EffectUsageHistory[] | null;
  effectUsageHistoriesParams: EffectUsageHistoryIndexParams;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexEffectUsageHistories: (
    params: EffectUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<EffectUsageHistory>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!equalForKeys(this.props, nextProps, ['effectUsageHistoryParams'])) {
      return true;
    }
    if (
      shouldUpdateEntityList(
        {
          entities: this.props.effectUsageHistories,
        },
        {entities: nextProps.effectUsageHistories},
      )
    ) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      effectUsageHistories,
      effectUsageHistoriesParams,
      indexEffectUsageHistories,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!effectUsageHistories) {
      indexEffectUsageHistories(effectUsageHistoriesParams);
    }
  }

  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        onForwardToNewEffectUsageHistory={
          this.handleForwardToNewEffectUsageHistory
        }
        onSelectEffectUsageHistory={this.handleSelectEffectUsageHistory}
        onForwardToFavoriteList={this.handleForwardToFavoriteList}
      />
    );
  }

  private handleSelectEffectUsageHistory = (
    effectUsageHistory: EffectUsageHistory,
  ) => {
    const {navigation, route} = this.props;
    const baseEffectId = effectUsageHistory.baseEffect.id;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    routers.linkToSceneFormEffectShowSceneCommandNewEffects(navigation, {
      baseEffectId,
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    });
  };

  private handleForwardToNewEffectUsageHistory = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    routers.linkToSceneFormEffectShowSceneCommandNewBaseEffectSearchForm(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
      },
    );
  };

  private handleForwardToFavoriteList = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    routers.linkToSceneFormEffectShowSceneCommandNewBaseEffects(navigation, {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      favorite: true,
    });
  };
}
