import * as React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import MusicNoteIcon from '../../../../../shared/icons/MusicNoteIcon';
import MusicNoteMuteIcon from '../../../../../shared/icons/MusicNoteMuteIcon';

import Sound from '../../../../../../../domain/entities/Sound';

interface Props {
  sound: Sound;
  start: boolean;
  children?: React.ReactNode;
}

const musicNoteIcon = <MusicNoteIcon color={'#222'} size={18} />;
const musicNoteMuteIcon = <MusicNoteMuteIcon color={'#222'} size={18} />;

const BackgroundMusicView: React.FunctionComponent<Props> = props => {
  const {sound, start} = props;
  return (
    <View style={styles.container}>
      <View style={styles.box}>
        <View style={styles.left}>
          <View style={styles.icon}>
            {start ? musicNoteIcon : musicNoteMuteIcon}
          </View>
        </View>
        <View style={styles.right}>
          <Text style={styles.label}>BGM{start ? '開始' : '停止'}</Text>
          <Text style={styles.soundName}>{sound.name}</Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(BackgroundMusicView);

const width = 343;
const height = 61;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: '100%',
    paddingTop: 24,
    paddingBottom: 16,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
  box: {
    width,
    height,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  left: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#d5d5d5',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  right: {
    marginLeft: 8,
  } as ViewStyle,
  icon: {
    marginRight: 2,
  } as ViewStyle,
  label: {
    color: '#666',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  soundName: {
    color: '#222',
    fontSize: 18,
    fontWeight: 'bold',
  } as TextStyle,
});
