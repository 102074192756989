import * as React from 'react';
import {Platform, Text} from 'react-native';

import Form from './partials/Form';
import RegistrationMessage from './partials/RegistrationMessage';

import Layout from '../../shared/Layout';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {EditWriterUserRouteProp} from '../../../navigators/RouteProps';

import {Params as RegistrationUpdateParams} from '../../../actions/writer/users/registration/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import File from '../../../../domain/entities/File';
import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import UserForm from '../../../../domain/forms/writer/UserForm';

import dic from '../../../../config/locales/writer/user/ja';

export interface Params {
  hideClose?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditWriterUserRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<CurrentUser>;
  updateUser: (params: RegistrationUpdateParams) => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  form: UserForm | null;
  loading: boolean;
  alertMessage: string | null;
  birthdayYear: number | null;
  birthdayMonth: number | null;
  birthdayDate: number | null;
}

export default class Edit extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      form: null,
      loading: false,
      alertMessage: null,
      birthdayYear: null,
      birthdayMonth: null,
      birthdayDate: null,
    };
  }

  public componentDidMount() {
    const {showCurrentUser} = this.props;
    showCurrentUser().then(currentUser => {
      const form = new UserForm();
      const birthday = currentUser.userProfile?.birthday;
      form.bind(currentUser);
      this.setState({
        form,
        birthdayYear: birthday?.getFullYear() || null,
        birthdayMonth: birthday ? birthday.getMonth() + 1 : null,
        birthdayDate: birthday?.getDate() || null,
      });
    });
  }

  public render(): React.ReactNode {
    const {navigation, currentUser} = this.props;
    const {form, loading, alertMessage} = this.state;
    return (
      <Layout
        title={'プロフィール'}
        navigation={navigation}
        leftButton={
          currentUser?.enabled
            ? {
                title: (
                  <Text style={{color: '#383838', fontSize: 15}}>
                    キャンセル
                  </Text>
                ),
                tintColor: 'white',
                handler: () => navigation.goBack(),
              }
            : null
        }
        rightButton={{
          title: (
            <PrimaryButton
              buttonSize={{width: 64, height: 32}}
              disabled={
                (form?.penName.length || 0) > 20 ||
                (form?.penName.length || 0) === 0 ||
                (form?.description.length || 0) > 150
              }
              onPress={this.handleSubmit}>
              保存
            </PrimaryButton>
          ),
          tintColor: 'white',
          handler: () => {},
        }}
        loading={loading}>
        {currentUser ? (
          !currentUser.enabled && currentUser.enabledConsumerUser ? (
            <RegistrationMessage />
          ) : null
        ) : null}
        {form && (
          <Form
            form={form}
            birthday={this.getBirthday()}
            onChangeImage={this.handleChangeImage}
            onChangePenName={this.handleChangePenName}
            onChangeDescription={this.handleChangeDescription}
            onChangeEmail={this.handleChangeEmail}
            onValueChangeYear={this.handleValueChangeYear}
            onValueChangeMonth={this.handleValueChangeMonth}
            onValueChangeDate={this.handleValueChangeDate}
            onChangeHomePageUrl={this.handleChangeHomePageUrl}
            onChangeTwitterAccountUrl={this.handleChangeTwitterAccountUrl}
            onChangeYoutubeChannelUrl={this.handleChangeYoutubeChannelUrl}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleChangeImage = (image: File) => {
    if (!this.state.form) {
      return;
    }
    const form = new UserForm();
    form.copyForm(this.state.form);
    form.image = image;
    this.setState({form});
  };

  private handleChangePenName = (penName: string) => {
    if (!this.state.form) {
      return;
    }
    const form = new UserForm();
    form.copyForm(this.state.form);
    form.penName = penName;
    this.setState({form});
  };

  private handleChangeDescription = (description: string) => {
    if (!this.state.form) {
      return;
    }
    const form = new UserForm();
    form.copyForm(this.state.form);
    form.description =
      Platform.OS !== 'web' ? description.substr(0, 150) : description;
    this.setState({form});
  };

  private handleChangeEmail = (email: string) => {
    if (!this.state.form) {
      return;
    }
    const form = new UserForm();
    form.copyForm(this.state.form);
    form.email = email;
    this.setState({form});
  };

  private handleValueChangeYear = (value: number) => {
    this.setState({birthdayYear: value ? value : null});
  };

  private handleValueChangeMonth = (value: number) => {
    this.setState({birthdayMonth: value ? value : null});
  };

  private handleValueChangeDate = (value: number) => {
    this.setState({birthdayDate: value ? value : null});
  };

  private handleChangeHomePageUrl = (homePageUrl: string) => {
    if (!this.state.form) {
      return;
    }
    const form = new UserForm();
    form.copyForm(this.state.form);
    form.homePageUrl = homePageUrl;
    this.setState({form});
  };

  private handleChangeTwitterAccountUrl = (twitterAccountUrl: string) => {
    if (!this.state.form) {
      return;
    }
    const form = new UserForm();
    form.copyForm(this.state.form);
    form.twitterAccountUrl = twitterAccountUrl;
    this.setState({form});
  };

  private handleChangeYoutubeChannelUrl = (youtubeChannelUrl: string) => {
    if (!this.state.form) {
      return;
    }
    const form = new UserForm();
    form.copyForm(this.state.form);
    form.youtubeChannelUrl = youtubeChannelUrl;
    this.setState({form});
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: null});
  };

  private handleSubmit = () => {
    if (!this.state.form) {
      return;
    }
    const {navigation, updateUser} = this.props;
    const {form} = this.state;
    const params = form.toParams();
    params.userProfileAttributes.birthday = this.getBirthday();
    this.setState({loading: true}, () => {
      updateUser(params)
        .then(() => {
          this.setState({loading: false}, () => {
            (navigation.getParent() || navigation).goBack();
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages(dic, error),
          });
        });
    });
  };

  private getBirthday = () => {
    const {birthdayYear, birthdayMonth, birthdayDate} = this.state;
    if (birthdayYear && birthdayMonth && birthdayDate) {
      return `${birthdayYear}-${birthdayMonth}-${birthdayDate}`;
    } else {
      return null;
    }
  };
}
