import * as React from 'react';
import {FlatList, StyleSheet, ViewStyle} from 'react-native';

import ActorCharacterFaceListItem from './ActorCharacterFaceListItem';

import ActorCharacterFace from '../../../../../../../domain/entities/ActorCharacterFace';

interface Props {
  actorCharacterFaces: ActorCharacterFace[] | null;
  selectedActorCharacterFace: ActorCharacterFace | null;
  width: number;
  visible: boolean;
  inverted?: boolean;
  scrollEnabled?: boolean;
  onSelectActorCharacterFace: (actorCharacterFace: ActorCharacterFace) => void;
}

const ActorCharacterFaceList: React.FunctionComponent<Props> = props => {
  const {
    actorCharacterFaces,
    selectedActorCharacterFace,
    width,
    visible,
    inverted,
    scrollEnabled,
    onSelectActorCharacterFace,
  } = props;
  return (
    <FlatList
      data={actorCharacterFaces}
      style={[styles.container, visible ? null : {display: 'none'}]}
      keyExtractor={item => `${item.id}`}
      scrollEnabled={scrollEnabled}
      renderItem={info => {
        return (
          <ActorCharacterFaceListItem
            style={styles.content}
            actorCharacterFace={info.item}
            width={
              (width - (MARGIN_HORIZONTAL + CONTENT_MARGIN * NUM_COLUMNS) * 2) /
              NUM_COLUMNS
            }
            inverted={inverted}
            selected={selectedActorCharacterFace?.id === info.item.id}
            onSelectActorCharacterFace={onSelectActorCharacterFace}
          />
        );
      }}
      numColumns={NUM_COLUMNS}
      extraData={selectedActorCharacterFace}
    />
  );
};

export default ActorCharacterFaceList;

const MARGIN_HORIZONTAL = 14;
const CONTENT_MARGIN = 2;

const NUM_COLUMNS = 3;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: MARGIN_HORIZONTAL,
  } as ViewStyle,
  content: {
    margin: CONTENT_MARGIN,
  } as ViewStyle,
});
