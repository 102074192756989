import * as React from 'react';
import {Text, ViewStyle, View} from 'react-native';
import {useFocusEffect} from '@react-navigation/native';

import PaymentMethodList from './partials/PaymentMethodList';

import Layout from '../../../shared/Layout';
import BottomPrimaryButton from '../../../shared/buttons/BottomPrimaryButton';
import WarningModal from '../../../shared/modals/WarningModal';

import NavigationProp from '../../../../navigators/NavigationProp';

import PaymentMethod from '../../../../../domain/entities/writer/my_stripe/PaymentMethod';

import NetPaymentMethodsRepository from '../../../../../data/repositories/writer/my_stripe/NetPaymentMethodsRepository';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation} = props;
  const [loading, setLoading] = React.useState(false);
  const [paymentMethods, setPaymentMethods] =
    React.useState<Array<PaymentMethod> | null>(null);
  const [selectingPaymentMethod, setSelectingPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const [deletingPaymentMethod, setDeletingPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const fetchtPaymentMethods = React.useCallback(() => {
    return new NetPaymentMethodsRepository().findAll().then(result => {
      setPaymentMethods(result.records);
    });
  }, []);
  useFocusEffect(
    React.useCallback(() => {
      fetchtPaymentMethods();
    }, []),
  );
  const onSelectPaymentMethod = React.useCallback(() => {
    if (!selectingPaymentMethod) {
      return;
    }
    setLoading(true);
    onRequestCloseSelecting();
    TapNovelRestApi.patch(
      '/api/writer/my_stripe/customer_default_payment_method',
      {
        myStripePaymentMethodId: selectingPaymentMethod.id,
      },
    )
      .then(() => {
        setTimeout(() => {
          fetchtPaymentMethods()
            .then(() => {
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [selectingPaymentMethod]);
  const onDeletePaymentMethod = React.useCallback(() => {
    if (!deletingPaymentMethod) {
      return;
    }
    setLoading(true);
    onRequestCloseDeleting();
    new NetPaymentMethodsRepository()
      .destroy(deletingPaymentMethod.id)
      .then(() => {
        fetchtPaymentMethods();
        setLoading(false);
      });
  }, [deletingPaymentMethod]);
  const onRequestCloseSelecting = React.useCallback(() => {
    setSelectingPaymentMethod(null);
  }, []);
  const onRequestCloseDeleting = React.useCallback(() => {
    setDeletingPaymentMethod(null);
  }, []);
  const onPressNew = React.useCallback(() => {
    navigation.navigate('NewSettingsBillingPaymentMethod', {});
  }, []);
  return (
    <Layout
      title={'お支払い方法'}
      navigation={navigation}
      back={true}
      loading={loading}
      scrollable={false}>
      {paymentMethods && (
        <PaymentMethodList
          paymentMethods={paymentMethods}
          onSelectPaymentMethod={setSelectingPaymentMethod}
          onDeletePaymentMethod={setDeletingPaymentMethod}
        />
      )}
      <View style={buttonWrapperStyle}>
        <BottomPrimaryButton onPress={onPressNew}>
          お支払い方法の追加
        </BottomPrimaryButton>
      </View>
      {selectingPaymentMethod && (
        <WarningModal
          visible={true}
          onAccept={onSelectPaymentMethod}
          onRequestClose={onRequestCloseSelecting}>
          <Text>使用するカードを変更してもよろしいですか？</Text>
        </WarningModal>
      )}
      {deletingPaymentMethod && (
        <WarningModal
          visible={true}
          onAccept={onDeletePaymentMethod}
          onRequestClose={onRequestCloseDeleting}>
          <Text>削除してもよろしいですか？</Text>
        </WarningModal>
      )}
    </Layout>
  );
};

export default React.memo(Index);

const buttonWrapperStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
} as ViewStyle;
