import * as React from 'react';

import PreviewBase, {SCALE} from './PreviewBase';
import DomainFile from '../../../../domain/entities/File';

export default class Preview extends PreviewBase {
  protected ref = React.createRef<HTMLCanvasElement>();

  public componentDidMount() {
    this.drawContext();
  }

  public componentDidUpdate() {
    this.drawContext();
  }

  public async drawContext() {
    if (this.ref.current) {
      const context = this.ref.current.getContext('2d');
      if (context) {
        const {backgroundImageUri, characterImageUri} = this.props;
        const backgroundImage = await this.loadImage(backgroundImageUri);
        const characterImage = await this.loadImage(characterImageUri);
        await this.setupContext(context, {
          ...(backgroundImage
            ? {
                background: {
                  image: backgroundImage,
                  naturalHeight: backgroundImage.naturalHeight,
                  naturalWidth: backgroundImage.naturalWidth,
                },
              }
            : {}),
          ...(characterImage
            ? {
                character: {
                  image: characterImage,
                  naturalHeight: characterImage.naturalHeight,
                  naturalWidth: characterImage.naturalWidth,
                },
              }
            : {}),
        });
      }
    }
  }

  public getImageFile = (): Promise<DomainFile | null> => {
    if (!this.ref.current) {
      return new Promise(resolve => {
        return resolve(null);
      });
    }
    const base64 = this.ref.current.toDataURL('image/jpeg', 1);
    const bin = atob(base64.replace(/^.*,/, ''));
    const buffer = new Uint8Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
    }
    const filename = Math.random().toString(32).substring(2);
    const type = 'jpg';
    const file = new File([buffer.buffer], `${filename}.${type}`, {
      type: `image/${type}`,
    });
    (file as any).uri = base64;
    return new Promise((resolve: any) => {
      return resolve(file);
    });
  };

  protected renderCanvas = (): React.ReactNode => {
    const {width, height, visible} = this.props;
    return (
      <div style={visible ? undefined : {display: 'none', width: 0, height: 0}}>
        <canvas
          ref={this.ref}
          style={{width, height}}
          width={width * SCALE}
          height={height * SCALE}
        />
      </div>
    );
  };

  protected measureText = async (
    context: CanvasRenderingContext2D,
    text: string,
  ) => {
    return new Promise((resolve: (textMetrics: TextMetrics) => void) => {
      resolve(context.measureText(text));
    });
  };

  private loadImage = (
    src: string | null | undefined,
  ): Promise<HTMLImageElement | null> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = e => resolve(null);
      img.src = src || '';
    });
  };
}
