import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class SceneCommandParamsBuilder {
  public build(forms: SceneCommandForm[]): Array<{[key: string]: any}> {
    const params: Array<{[key: string]: any}> = [];
    forms.forEach(form => {
      params.push(form.toParams());
    });
    return params;
  }
}
