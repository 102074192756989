import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';

import UserRegistrationEmailEditForm from '../../../../../../domain/forms/writer/UserRegistrationEmailEditForm';
import CurrentUser from '../../../../../../domain/entities/writer/CurrentUser';

interface Props {
  currentUser: CurrentUser;
  onSubmit: (
    consumerUserRegistrationForm: UserRegistrationEmailEditForm,
  ) => void;
}

interface State {
  userRegistrationEmailEditForm: UserRegistrationEmailEditForm;
}

export default class Form extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userRegistrationEmailEditForm: new UserRegistrationEmailEditForm(''),
    };
  }

  public render(): React.ReactNode {
    const {currentUser} = this.props;
    const {userRegistrationEmailEditForm} = this.state;
    return (
      <View style={styles.container}>
        <View style={styles.section}>
          <View style={styles.section}>
            <Text style={styles.formLabel}>
              現在のメールアドレス(
              {currentUser.verifiedEmailAddress() ? (
                <Text style={styles.verifiedEmail}>認証済</Text>
              ) : (
                <Text style={styles.notVerifiedEmail}>未認証</Text>
              )}
              )
            </Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.displayableEmail}>
              {currentUser.getDisplayableEmail()}
            </Text>
          </View>
        </View>

        <View style={styles.section}>
          <View style={styles.section}>
            <Text style={styles.formLabel}>新しいメールアドレス</Text>
          </View>
          <View style={styles.textInputWrapper}>
            <TextInput
              style={styles.textInput}
              keyboardType={'email-address'}
              autoCapitalize={'none'}
              placeholder={'新しいメールアドレスを入力'}
              value={userRegistrationEmailEditForm.email}
              onChangeText={this.handleChangeEmail}
              returnKeyType={'done'}
            />
          </View>
        </View>
        <View style={styles.submitButton}>
          <PrimaryButton
            disabled={userRegistrationEmailEditForm.email.length === 0}
            onPress={this.handlePressButton}>
            認証メールを送信
          </PrimaryButton>
        </View>
      </View>
    );
  }

  private handleChangeEmail = (email: string) => {
    this.setState({
      userRegistrationEmailEditForm: new UserRegistrationEmailEditForm(email),
    });
  };

  private handlePressButton = () => {
    const {onSubmit} = this.props;
    const {userRegistrationEmailEditForm} = this.state;
    if (userRegistrationEmailEditForm.validate()) {
      onSubmit(userRegistrationEmailEditForm);
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 25,
  } as ViewStyle,
  notVerifiedEmail: {
    color: 'red',
  } as TextStyle,
  submitButton: {
    alignItems: 'center',
    marginTop: 24,
  } as ViewStyle,
  verifiedEmail: {
    color: 'green',
  } as TextStyle,
  section: {
    marginVertical: 8,
  } as ViewStyle,
  formLabel: {
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  displayableEmail: {
    color: '#383838',
    fontSize: 15,
  } as TextStyle,
  textInputWrapper: {
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  textInput: {
    fontSize: 15,
    marginVertical: 8,
  } as TextStyle,
});
