import * as React from 'react';
import {ScrollView, StyleSheet, View, ViewStyle} from 'react-native';

import Tab from './Tab';

interface Props<T> {
  data: Array<{value: T; label: string}>;
  activeTab: T;
  hide?: boolean;
  disableValues?: Array<T>;
  onChangeTab: (value: T) => void;
}

type TabsComponent = <T>(props: Props<T>) => React.ReactElement<Props<T>>;

const Tabs: TabsComponent = props => {
  const {data, activeTab, hide, disableValues, onChangeTab} = props;
  if (hide) {
    return <View style={styles.hide} />;
  }
  return (
    <View style={styles.tabs}>
      <ScrollView horizontal={true} showsHorizontalScrollIndicator={false}>
        {data.map(item => (
          <Tab
            key={`${item.value}`}
            value={item.value}
            label={item.label}
            isActive={activeTab === item.value}
            disabled={disableValues?.includes(item.value)}
            onChangeTab={onChangeTab}
          />
        ))}
      </ScrollView>
    </View>
  );
};

export default React.memo(Tabs) as typeof Tabs;

const styles = StyleSheet.create({
  tabs: {
    height: 41,
    flexDirection: 'row',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    backgroundColor: 'white',
  } as ViewStyle,
  hide: {
    borderTopColor: '#efefef',
    borderTopWidth: 1,
  } as ViewStyle,
});
