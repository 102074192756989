import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
}

const InversionIcon: React.FC<Props> = props => {
  return (
    <Icon
      name={'inversion'}
      size={props.size || 22}
      color={props.color || '#383838'}
    />
  );
};

export default React.memo(InversionIcon);
