import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Project from '../../../../../domain/entities/Project';

interface Props {
  project: Project;
}

const StoryInfo: React.FC<Props> = props => {
  const {project} = props;
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>プロジェクトタイトル</Text>
        </View>
        <Text style={styles.content}>{project.story.title}</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>キャッチコピー</Text>
        </View>
        <Text style={styles.content}>{project.story.catchPhrase}</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>イントロダクション</Text>
        </View>
        <Text style={styles.content}>{project.story.introduction}</Text>
      </View>
    </View>
  );
};

export default React.memo(StoryInfo);

const styles = StyleSheet.create({
  container: {
    marginVertical: 24,
    marginHorizontal: 16,
  } as ViewStyle,
  section: {
    marginBottom: 32,
  } as ViewStyle,
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 4,
  } as TextStyle,
  content: {
    color: '#222',
    fontSize: 14,
    marginTop: 16,
  } as TextStyle,
});
