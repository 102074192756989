import * as React from 'react';

import BottomPrimaryButton from '../../shared/buttons/BottomPrimaryButton';

interface Props {
  label?: string | null;
  onForwardToNewCharacter: () => void;
}

const NewCharacterButton: React.FunctionComponent<Props> = props => {
  const {label, onForwardToNewCharacter} = props;
  return (
    <BottomPrimaryButton
      bottomButtonStyle={{
        backgroundColor: 'rgba(255,255,255,0.9)',
        paddingTop: 16,
      }}
      onPress={onForwardToNewCharacter}>
      {label || 'キャラクターを追加'}
    </BottomPrimaryButton>
  );
};

export default React.memo(NewCharacterButton);
