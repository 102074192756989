import EntityMapper from '../EntityMapper';

import Episode from '../../../../domain/entities/response/Episode';

export interface EpisodeData {
  id: number;
  story_id: number;
  title: string;
  tips_count: number;
  reactions_count: number;
  comments_count: number;
  confirmed: boolean;
  confirmed_tips: boolean;
  confirmed_reactions: boolean;
  confirmed_comments: boolean;
  created_at: string;
  updated_at: string;
}

export default class EpisodeMapper
  implements EntityMapper<EpisodeData, Episode>
{
  public map(obj: EpisodeData): Episode {
    return new Episode(
      obj.id,
      obj.story_id,
      obj.title,
      obj.tips_count,
      obj.reactions_count,
      obj.comments_count,
      obj.confirmed,
      obj.confirmed_tips,
      obj.confirmed_reactions,
      obj.confirmed_comments,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
