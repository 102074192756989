import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';

import VideoShareLabel from './VideoShareLabel';
import ViolationStateLabel from './ViolationStateLabel';

import PublicStateLabel from '../../shared/PublicStateLabel';
import FastImageAdapter from '../../shared/fast_image/FastImageAdapter';
import DimensionContext from '../../shared/dimension/DimensionContext';
import PenIcon from '../../shared/icons/PenIcon';

import {storyCoverImageUrl} from '../../../helpers/images';

import StoryCoverImageDecorator from '../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../styles/variables';

import Story from '../../../../domain/entities/Story';
import Episode from '../../../../domain/entities/Episode';

interface Props {
  story: Story;
  episodes: Episode[];
  onForwardToEditStory: () => void;
  onForwardToEditStoryImage: () => void;
  children?: React.ReactNode;
}

const StoryDetail: React.FunctionComponent<Props> = props => {
  const {story, episodes, onForwardToEditStory, onForwardToEditStoryImage} =
    props;
  const mainGenre = story.getMainGenre();
  return (
    <>
      <DimensionContext.Consumer>
        {context => {
          const width = (context.content.width / 375) * 140;
          const imageStyle = {
            height: width / ASPECT_RATIO,
            width,
          };
          return (
            <View style={styles.header}>
              <TouchableOpacity
                activeOpacity={0.9}
                onPress={onForwardToEditStoryImage}>
                {story.hasCoverImage ? (
                  <FastImageAdapter
                    style={[styles.image, imageStyle]}
                    source={{
                      uri: storyCoverImageUrl(
                        new StoryCoverImageDecorator(story),
                        'large',
                      ),
                      headers: {Accept: 'image/webp,image/apng,*/*'},
                    }}
                  />
                ) : (
                  <View style={[styles.image, imageStyle]}>
                    <Text style={styles.noImageText}>表紙なし</Text>
                  </View>
                )}
              </TouchableOpacity>
              <View style={styles.headerInfo}>
                <Text style={styles.title}>{story.title}</Text>
                {mainGenre ? (
                  <View style={styles.genres}>
                    <View style={styles.genre}>
                      <Text style={[styles.genreLabel, styles.genreMainLabel]}>
                        {mainGenre.name}
                      </Text>
                    </View>
                    {story.getSubGenres().map(genre => (
                      <View key={`${genre.id}`} style={styles.genre}>
                        <Text style={[styles.genreLabel, styles.genreSubLabel]}>
                          {genre.name}
                        </Text>
                      </View>
                    ))}
                  </View>
                ) : null}
                {story.keywords.length > 0 ? (
                  <View style={styles.keywords}>
                    {story.keywords.map(keyword => (
                      <View key={`${keyword.id}`} style={styles.keyword}>
                        <Text style={styles.keywordLabel}>{keyword.name}</Text>
                      </View>
                    ))}
                  </View>
                ) : null}
                <View style={{marginVertical: 8, flexDirection: 'row'}}>
                  {story.regulatoryViolationStatus ? (
                    <ViolationStateLabel
                      regulatoryViolationStatus={
                        story.regulatoryViolationStatus
                      }
                    />
                  ) : (
                    <PublicStateLabel published={story.published} />
                  )}
                  {story.allowedVideoSharing && (
                    <VideoShareLabel type={videoSharableType(episodes)} />
                  )}
                </View>
                <TouchableOpacity
                  style={styles.editButton}
                  activeOpacity={0.6}
                  onPress={onForwardToEditStory}>
                  <>
                    <PenIcon size={13} color={'#383838'} />
                    <Text style={styles.editButtonLabel}>編集</Text>
                  </>
                </TouchableOpacity>
              </View>
            </View>
          );
        }}
      </DimensionContext.Consumer>
      <View style={styles.container}>
        <Text style={styles.introduction}>{story.introduction}</Text>
      </View>
    </>
  );
};

const videoSharableType = (episodes: Episode[]): 'none' | 'partial' | 'all' => {
  if (episodes.length === 0) {
    return 'none';
  }
  if (episodes.every(episode => episode.free)) {
    return 'all';
  } else if (episodes.some(episode => episode.free)) {
    return 'partial';
  } else {
    return 'none';
  }
};

export default onlyUpdateForKeys(['story', 'episodes'])(StoryDetail);

const ASPECT_RATIO = 0.7;
const width = 140;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderBottomColor: colors.paleGray,
    borderBottomWidth: 1,
    paddingHorizontal: 16,
  } as ViewStyle,
  header: {
    padding: 16,
    backgroundColor: 'white',
    borderBottomColor: 'white',
    flexDirection: 'row',
  } as ViewStyle,
  headerInfo: {
    flex: 1,
    marginLeft: 8,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'center',
    backgroundColor: colors.powderGray,
  } as ImageStyle,
  body: {
    paddingBottom: 10,
  } as ViewStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 17,
  } as TextStyle,
  title: {
    color: '#383838',
    fontSize: 19,
    fontWeight: 'bold',
    marginBottom: 11,
  } as TextStyle,
  genres: {
    flexDirection: 'row',
    marginVertical: 8,
    flexWrap: 'wrap',
  } as ViewStyle,
  genre: {
    marginRight: 8,
    marginBottom: 4,
  } as ViewStyle,
  genreLabel: {
    fontSize: 11,
    lineHeight: 11,
  } as TextStyle,
  genreMainLabel: {
    color: '#222222',
  } as TextStyle,
  genreSubLabel: {
    color: '#666666',
  } as TextStyle,
  keywords: {
    flexDirection: 'row',
    marginVertical: 8,
    flexWrap: 'wrap',
  } as ViewStyle,
  keyword: {
    borderColor: '#ff8f13',
    borderWidth: 1,
    borderRadius: 100,
    marginRight: 8,
    marginBottom: 4,
  } as ViewStyle,
  keywordLabel: {
    color: '#ff8f13',
    fontSize: 11,
    paddingVertical: 2,
    paddingHorizontal: 8,
  } as TextStyle,
  editButton: {
    width: 82,
    height: 28,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#383838',
    borderWidth: 1,
    borderRadius: 16,
    position: 'absolute',
    bottom: 0,
    right: 0,
  } as ViewStyle,
  editButtonLabel: {
    marginLeft: 4,
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  introduction: {
    color: '#383838',
    fontSize: 14,
    lineHeight: 16,
    marginBottom: 16,
  } as TextStyle,
});
