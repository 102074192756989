import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import VoiceMapper, {VoiceData} from '../../entities/mappers/VoiceMapper';

import Voice from '../../../domain/entities/Voice';
import VoicesRepository from '../../../domain/repositories/writer/VoicesRepository';

export default class NetVoicesRepository
  extends NetResourcesRepository<Voice, VoiceData>
  implements VoicesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/voices',
        new VoiceMapper(),
      ),
    );
  }
}
