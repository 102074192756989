import * as React from 'react';
import {StyleProp, StyleSheet, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import BottomPrimaryButton from '../../../shared/buttons/BottomPrimaryButton';

interface Props {
  style?: StyleProp<ViewStyle>;
}

const NewProjectButton: React.FunctionComponent<Props> = props => {
  const {style} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo('/projects/new');
  }, []);
  return (
    <BottomPrimaryButton
      style={style}
      bottomButtonStyle={styles.bottomButton}
      onPress={onPress}>
      プロジェクトを立ち上げる
    </BottomPrimaryButton>
  );
};

export default React.memo(NewProjectButton);

const styles = StyleSheet.create({
  bottomButton: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  } as ViewStyle,
});
