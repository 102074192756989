import {TextStyle, ViewStyle} from 'react-native';

import Entity from './Entity';

import OrientedSpeechBalloonPart from './OrientedSpeechBalloonPart';

import FramePartPosition from '../value_objects/FramePartPosition';
import Position from '../value_objects/Position';
import SpeechBalloonDirection from '../value_objects/SpeechBalloonDirection';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class OrientedSpeechBalloon implements Entity {
  constructor(
    public id: number,
    public speechBalloonId: number,
    public speechBalloonDirection: SpeechBalloonDirection,
    public originalImageUrl: string,
    public orientedSpeechBalloonParts: OrientedSpeechBalloonPart[],
    public containerStyle: ViewStyle,
    public textStyle: TextStyle,
    public voiceIconStyle?: ViewStyle,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getPartImageUrlOf(
    framePartPosition: FramePartPosition,
  ): OrientedSpeechBalloonPart {
    const found = this.orientedSpeechBalloonParts.find(
      orientedSpeechBalloonPart => {
        return (
          orientedSpeechBalloonPart.framePartPosition === framePartPosition
        );
      },
    );
    if (found) {
      return found;
    } else {
      throw Error(`Not found part of ${framePartPosition} for ${this.id}`);
    }
  }

  public matchesPositions(positions: Position[]): boolean {
    const thisPositions = this.getPositions().sort();
    const thatPositions = positions.sort();
    if (thisPositions.length !== thatPositions.length) {
      return false;
    }
    for (let i = 0; i < thisPositions.length; ++i) {
      if (thisPositions[i] !== thatPositions[i]) {
        return false;
      }
    }
    return true;
  }

  public getPositions(): Position[] {
    switch (this.speechBalloonDirection) {
      case SpeechBalloonDirection.Left:
        return [Position.Left];
      case SpeechBalloonDirection.Center:
        return [Position.Center];
      case SpeechBalloonDirection.Right:
        return [Position.Right];
      case SpeechBalloonDirection.Left_Center:
        return [Position.Left, Position.Center];
      case SpeechBalloonDirection.Center_Right:
        return [Position.Center, Position.Right];
      case SpeechBalloonDirection.Left_Right:
        return [Position.Left, Position.Right];
      case SpeechBalloonDirection.Left_Center_Right:
        return [Position.Left, Position.Center, Position.Right];
    }
  }
}
