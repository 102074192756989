import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';
import CheckButton from '../../../shared/forms/CheckButton';

import Tutorial2Uri from '../../../../../assets/svgs/projects/tutorial-2.svg';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep2: React.FC<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.message}>
          プロジェクトを立ち上げた人がリーダー、{'\n'}
          参加した人はメンバーになります。
        </Text>
        <Text style={styles.message}>
          リーダーの立ち上げた企画内容に沿って、{'\n'}
          メンバーそれぞれがエピソードを{'\n'}
          執筆することができます。
        </Text>
        <View style={styles.image}>
          <SvgUriAdapter uri={Tutorial2Uri} width={317} height={195} />
        </View>
        <CheckButton
          style={styles.checkButton}
          checked={checked}
          onPress={onPressCheck}>
          理解した
        </CheckButton>
        <PrimaryButton disabled={!checked} onPress={onPress}>
          次へ
        </PrimaryButton>
      </View>
    </ScrollView>
  );
};

export default React.memo(ServiceExplanationStep2);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 24,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 8,
  } as TextStyle,
  image: {
    marginTop: 8,
    marginBottom: 24,
  } as ViewStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginTop: 8,
    marginBottom: 16,
  } as ViewStyle,
});
