import * as React from 'react';
import {Image, ImageStyle, StyleProp} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';

interface Props {
  source: any;
  style?: StyleProp<ImageStyle>;
  resizeMode?: any;
  onLoad?: () => void;
  onError?: () => void;
  children?: React.ReactNode;
}

const FastImageAdapter: React.FunctionComponent<Props> = props => {
  return <Image {...props} />;
};

export default onlyUpdateForKeys(['source', 'style', 'resizeMode'])(
  FastImageAdapter,
);
