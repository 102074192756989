import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import EffectUsageHistory from '../../../domain/entities/EffectUsageHistory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexEffectUsageHistoriesSuccess: {
      const result = action.payload as PaginatedResult<EffectUsageHistory>;
      return mergeResponse(state, result);
    }
    case Types.UpdateEffectUsageHistorySuccess: {
      const effectUsageHistory = action.payload as EffectUsageHistory;
      const newState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        const filteredIds = response.pageToIds[1].filter(
          id => id !== effectUsageHistory.id,
        );
        if (stateKey.includes(`"storyId":${effectUsageHistory.storyId}`)) {
          response.pageToIds[1] = [effectUsageHistory.id, ...filteredIds];
        }
        newState[stateKey] = response;
      });
      return newState;
    }
    default:
      return state;
  }
}
