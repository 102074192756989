import * as React from 'react';

import {
  LayoutChangeEvent,
  NativeSyntheticEvent,
  NativeScrollEvent,
  ScrollView,
  ScrollViewProps,
  View,
} from 'react-native';

interface Props extends ScrollViewProps {
  children?: React.ReactNode;
}

export default class DragScrollView extends React.Component<Props> {
  private scrolling = false;
  private scrollLeft = 0;
  private lastClientX = 0;
  private contentWidth = 0;

  private ref = React.createRef<ScrollView>();

  public render(): React.ReactNode {
    const {children, ...restProps} = this.props;
    return (
      <ScrollView
        ref={this.ref}
        {...restProps}
        onScroll={this.handleScroll}
        scrollEventThrottle={16}>
        <div onMouseDown={this.handleMouseDown}>
          <View
            style={
              {
                userSelect: 'none',
                cursor: 'pointer',
              } as any
            }
            onLayout={this.handleLayout}>
            {children}
          </View>
        </div>
      </ScrollView>
    );
  }

  private handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (!this.scrolling) {
      this.scrollLeft = event.nativeEvent.contentOffset.x;
    }
  };

  private handleLayout = (event: LayoutChangeEvent) => {
    this.contentWidth = event.nativeEvent.layout.width;
  };

  private handleMouseDown = (e: React.MouseEvent) => {
    this.scrolling = true;
    this.lastClientX = e.clientX;
    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('mouseup', this.handleMouseUp);
  };

  private handleMouseMove = (e: MouseEvent) => {
    const current = this.ref.current;
    if (current && this.scrolling) {
      const x = this.scrollLeft + this.lastClientX - e.clientX;
      if (x <= 0 || this.contentWidth < x) {
        return;
      }
      this.scrollLeft = x;
      this.lastClientX = e.clientX;
      current.scrollTo({x, animated: false});
    }
  };

  private handleMouseUp = (e: MouseEvent) => {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('mouseup', this.handleMouseUp);
    this.scrolling = false;
  };
}
