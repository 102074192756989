import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import QuestionIcon from '../../../shared/icons/QuestionIcon';

import TooltipModal from '../../../shared/modals/TooltipModal';

interface Props {}

const Header: React.FC<Props> = props => {
  const [visibleModal, setVisibleModal] = React.useState(false);
  const onPress = React.useCallback(() => {
    setVisibleModal(!visibleModal);
  }, [visibleModal]);
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        TapNovelMakerでは、お問い合わせ内容を公開し、他の利用者様にも回答いただける形式をとっております。
        <Pressable onPress={onPress}>
          <QuestionIcon />
        </Pressable>
      </Text>
      <Text style={styles.text}>
        まずご自身で同じお問い合わせがないかご確認いただきますようお願いいたします。
      </Text>
      <TooltipModal
        visible={visibleModal}
        title={''}
        onCloseModal={onPress}
        description={
          'TapNovelには大変ありがたいことに、お問い合わせフォームやSNSのDMに毎日沢山のお問い合わせが寄せられます。\n\nしかしながら、当社がお返事できるキャパシティーを超えることがあり、回答をお待たせしてしまうことがございます。\n\nお問い合わせには同じ内容のものが多く、お待ちいただく時間を少しででも短くできればと思い、お問い合わせ内容と回答を公開させていただくことにいたしました。\n\nまた、より迅速でバラエティに富んだ解決につながると考え、お問い合わせには当社だけでなく、他の利用者様にも回答いただけるようにいたしました。\n\nTapNovelのサービスを拡大していく上で、皆様のお力をお貸しいただければ幸いです。'
        }
      />
    </View>
  );
};

export default React.memo(Header);

const styles = StyleSheet.create({
  container: {
    marginVertical: 24,
    marginHorizontal: 16,
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 14,
    marginVertical: 8,
  } as TextStyle,
  modalText: {
    color: '#222',
    fontSize: 12,
    marginVertical: 40,
  } as TextStyle,
});
