import * as React from 'react';

// tslint:disable-next-line:no-submodule-imports
import Ionicons from 'react-native-vector-icons/Ionicons';

interface Props {
  name: string;
  size: number;
  color: string;
  children?: React.ReactNode;
}

const Icon: React.FunctionComponent<Props> = props => {
  const {name, size, color} = props;
  return <Ionicons name={name} size={size} color={color} />;
};

export default React.memo(Icon);
