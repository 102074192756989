import * as React from 'react';
import {InteractionManager} from 'react-native';

import Form from './partials/Form';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';
import {NewCharacterRouteProp} from '../../navigators/RouteProps';

import * as routers from '../../routers';

import {Params as CharacterFormCreateParams} from '../../actions/character_forms/create';
import {Params as CharacterFormUpdateParams} from '../../actions/character_forms/update';
import {Params as ActorCharacterFaceIndexParams} from '../../actions/actor_character_faces/index';

import ActorCharacterFace from '../../../domain/entities/ActorCharacterFace';
import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';
import PaginatedResult from '../../../domain/results/PaginatedResult';
import CharacterForm from '../../../domain/forms/CharacterForm';

export interface Params {
  projectId?: number;
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewCharacterRouteProp;
  characterForm: CharacterForm | null;
  actorCharacterFaces: ActorCharacterFace[] | null;
  actorCharacterFacesParams: ActorCharacterFaceIndexParams | null;
  currentUserStatus: CurrentUserStatus | null;
}

export interface DispatchProps {
  createCharacterForm: (params: CharacterFormCreateParams) => void;
  updateCharacterForm: (params: CharacterFormUpdateParams) => void;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  ready: boolean;
  loading: boolean;
}

export default class New extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      ready: false,
      loading: false,
    };
  }

  public componentDidMount() {
    const {
      actorCharacterFacesParams,
      indexActorCharacterFaces,
      createCharacterForm,
      showCurrentUserStatus,
    } = this.props;
    createCharacterForm({});
    showCurrentUserStatus();
    if (actorCharacterFacesParams) {
      indexActorCharacterFaces(actorCharacterFacesParams);
    }
    setTimeout(() => {
      this.handleForwardCharacterPatterns();
    }, 0);
    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        this.setState({ready: true});
      }, 200);
    });
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {actorCharacterFacesParams, indexActorCharacterFaces} = this.props;
    if (!actorCharacterFacesParams) {
      return;
    }
    if (
      JSON.stringify(actorCharacterFacesParams) !==
      JSON.stringify(prevProps.actorCharacterFacesParams)
    ) {
      indexActorCharacterFaces(actorCharacterFacesParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, characterForm, actorCharacterFaces, currentUserStatus} =
      this.props;
    const {ready, loading} = this.state;
    return (
      <Layout
        title={'キャラ作成'}
        navigation={navigation}
        back={true}
        loading={loading}>
        {characterForm && ready && currentUserStatus && (
          <Form
            characterForm={characterForm}
            actorCharacterFaces={actorCharacterFaces}
            enabledVoiceActorName={currentUserStatus.extensionsCodes.includes(
              'voice',
            )}
            onForwardCharacterPatterns={this.handleForwardCharacterPatterns}
            onChangeName={this.handleChangeName}
            onChangeDescription={this.handleChangeDescription}
            onChangeVoiceActorName={this.handleChangeVoiceActorName}
            onChangeActorCharacterFaceId={this.handleChangeActorCharacterFaceId}
            onSubmit={this.handleSubmit}
          />
        )}
      </Layout>
    );
  }
  private handleForwardCharacterPatterns = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToCharacterFormNew(navigation, {
      storyId,
    });
  };

  private handleChangeName = (name: string) => {
    const {updateCharacterForm} = this.props;
    updateCharacterForm({name});
  };

  private handleChangeDescription = (description: string) => {
    const {updateCharacterForm} = this.props;
    updateCharacterForm({description});
  };

  private handleChangeVoiceActorName = (voiceActorName: string) => {
    const {updateCharacterForm} = this.props;
    updateCharacterForm({voiceActorName});
  };

  private handleChangeActorCharacterFaceId = (actorCharacterFaceId: number) => {
    const {updateCharacterForm, actorCharacterFaces} = this.props;
    const actorCharacterFace = actorCharacterFaces?.find(
      o => o.id === actorCharacterFaceId,
    );
    updateCharacterForm({
      actorCharacterFaceId,
      actorCharacterFaceName: actorCharacterFace?.faceName,
    });
  };

  private handleSubmit = () => {
    const {navigation, route, characterForm} = this.props;
    const {storyId, projectId} = route.params;
    if (!characterForm) {
      return;
    }
    const {actorCharacterId, name, actorCharacterFaceId} = characterForm;
    if (!actorCharacterId || !name || !actorCharacterFaceId) {
      return;
    }

    navigation.navigate('CharacterFormNewNavigations', {
      screen: 'CharacterFormNewNameLabelColors',
      params: {storyId, projectId},
    } as any);
  };
}
