import * as React from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import HorizontalAlignmentSelect from './HorizontalAlignmentSelect';
import VerticalAlignmentSelect from './VerticalAlignmentSelect';
import WritingModeSwitch from './WritingModeSwitch';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import DimensionContext from '../../../shared/dimension/DimensionContext';
import KeyboardSpacer from '../../../shared/KeyboardSpacer';

import FontColor from '../../../../../domain/value_objects/FontColor';
import FontSize from '../../../../../domain/value_objects/FontSize';
import TextPosition from '../../../../../domain/value_objects/TextPosition';
import WritingMode from '../../../../../domain/value_objects/WritingMode';

import {
  convertFontColor,
  convertFontSize,
} from '../../../../../vendor/react-native-tapnovel-viewer/presentation/styles/variables';

interface Props {
  onSubmit: (params: {
    text: string;
    fontColor: FontColor;
    fontSize: FontSize;
    writingMode: WritingMode;
    textPosition: TextPosition;
    fontFamily?: string;
  }) => void;
}

interface State {
  text: string;
  fontColor: FontColor;
  fontSize: FontSize;
  horizontalAlignment: 'left' | 'center' | 'right';
  verticalAlignment: 'upper' | 'middle' | 'bottom';
  writingMode: WritingMode;
  fontFamily?: string;
}

export default class Form extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      text: '',
      fontColor: FontColor.Black,
      fontSize: FontSize.Middle,
      horizontalAlignment: 'center',
      verticalAlignment: 'middle',
      writingMode: 'horizontal',
      fontFamily: Platform.select({
        web: 'Noto Sans JP',
        default: 'NotoSansJP-Regular',
      }),
    };
  }

  public render(): React.ReactNode {
    const {
      text,
      fontColor,
      fontSize,
      fontFamily,
      horizontalAlignment,
      verticalAlignment,
      writingMode,
    } = this.state;
    return (
      <View style={styles.container}>
        <ScrollView>
          <DimensionContext.Consumer>
            {context => {
              const width = context.content.width - textInputMargin * 2;
              return (
                <TextInput
                  key={`${fontColor}_${fontSize}_${fontFamily}`}
                  style={[
                    styles.textInput,
                    {width, height: width / aspectRatio},
                    {
                      color: convertFontColor(fontColor),
                      fontSize: convertFontSize(fontSize),
                      fontFamily,
                      backgroundColor:
                        fontColor === FontColor.White ? '#dddddd' : undefined,
                    },
                  ]}
                  multiline={true}
                  value={text}
                  onChangeText={this.handleChangeText}
                />
              );
            }}
          </DimensionContext.Consumer>
          <HorizontalAlignmentSelect
            value={horizontalAlignment}
            onValueChange={this.handleHorizontalAlignmentValueChange}
          />
          <VerticalAlignmentSelect
            value={verticalAlignment}
            onValueChange={this.handleVerticalAlignmentValueChange}
          />
          <WritingModeSwitch
            value={writingMode}
            onSelectItem={this.handleSelectItem}
          />
          <View style={styles.button}>
            <PrimaryButton
              disabled={text.length === 0}
              onPress={this.handlePress}>
              確定
            </PrimaryButton>
          </View>
          <KeyboardSpacer />
        </ScrollView>
      </View>
    );
  }

  private handleChangeText = (text: string) => {
    this.setState({text});
  };

  private handleHorizontalAlignmentValueChange = (
    horizontalAlignment: string | number,
  ) => {
    if (
      horizontalAlignment === 'left' ||
      horizontalAlignment === 'center' ||
      horizontalAlignment === 'right'
    ) {
      this.setState({horizontalAlignment});
    }
  };

  private handleVerticalAlignmentValueChange = (
    verticalAlignment: string | number,
  ) => {
    if (
      verticalAlignment === 'upper' ||
      verticalAlignment === 'middle' ||
      verticalAlignment === 'bottom'
    ) {
      this.setState({verticalAlignment});
    }
  };

  private handleSelectItem = (item: {value: string}) => {
    if (item.value === 'horizontal' || item.value === 'vertical') {
      this.setState({writingMode: item.value});
    }
  };

  private handlePress = () => {
    const {onSubmit} = this.props;
    const {text, fontColor, fontSize, writingMode, fontFamily} = this.state;
    const textPosition = this.getTextPosition();
    onSubmit({
      text,
      fontColor,
      fontSize,
      writingMode,
      fontFamily,
      textPosition,
    });
  };

  private getTextPosition = (): TextPosition => {
    const {horizontalAlignment, verticalAlignment} = this.state;
    if (verticalAlignment === 'upper' && horizontalAlignment === 'left') {
      return 'upper_left';
    } else if (
      verticalAlignment === 'upper' &&
      horizontalAlignment === 'center'
    ) {
      return 'upper_center';
    } else if (
      verticalAlignment === 'upper' &&
      horizontalAlignment === 'right'
    ) {
      return 'upper_right';
    } else if (
      verticalAlignment === 'middle' &&
      horizontalAlignment === 'left'
    ) {
      return 'middle_left';
    } else if (
      verticalAlignment === 'middle' &&
      horizontalAlignment === 'center'
    ) {
      return 'middle_center';
    } else if (
      verticalAlignment === 'middle' &&
      horizontalAlignment === 'right'
    ) {
      return 'middle_right';
    } else if (
      verticalAlignment === 'bottom' &&
      horizontalAlignment === 'left'
    ) {
      return 'bottom_left';
    } else if (
      verticalAlignment === 'bottom' &&
      horizontalAlignment === 'center'
    ) {
      return 'bottom_center';
    } else if (
      verticalAlignment === 'bottom' &&
      horizontalAlignment === 'right'
    ) {
      return 'bottom_right';
    }
    return 'middle_center';
  };
}

const aspectRatio = 343 / 203;
const textInputMargin = 16;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 16,
  } as ViewStyle,
  textInput: {
    margin: textInputMargin,
    padding: 16,
    borderRadius: 10,
    borderColor: '#383838',
    borderWidth: 2,
    textAlignVertical: 'top',
  } as TextStyle,
  button: {
    alignItems: 'center',
    marginTop: 10,
  } as ViewStyle,
});
