import * as React from 'react';
import {View} from 'react-native';

import DimensionContext from '../../../../../shared/dimension/DimensionContext';
import BackgroundEffectImageSequence from '../../../../../shared/BackgroundEffectImageSequence';
import BackgroundEffectLayerOrderSwitchButton from '../../../../../shared/BackgroundEffectLayerOrderSwitchButton';

import {GLImageFilter} from '../../../../../../../vendor/react-native-tapnovel-viewer';

import Effect from '../../../../../../../domain/entities/Effect';

import {Filter} from '../../../../../../../domain/forms/SceneForm';

interface Props {
  uri: string;
  filter: Filter;
  selectedBaseEffect: Effect | null;
  defaultLayerOrder: 'background' | 'foreground';
  onChangeLayerOrder: (layerOrder: 'background' | 'foreground') => void;
}

const SelectedBackgroundEffectImage: React.FunctionComponent<Props> = props => {
  const {
    uri,
    filter,
    selectedBaseEffect,
    defaultLayerOrder,
    onChangeLayerOrder,
  } = props;
  const urls =
    selectedBaseEffect?.centerPositionedEffect.getMaxSizeFrameUrls() || [];
  return (
    <DimensionContext.Consumer>
      {context => {
        const {width} = context.content;
        const size = {width, height: width / aspectRatio};
        return (
          <View style={size}>
            <GLImageFilter size={size} filter={filter} uri={uri} />
            <BackgroundEffectImageSequence
              key={`${selectedBaseEffect?.id}`}
              id={selectedBaseEffect?.id}
              urls={urls}
              size={size}
            />
            <BackgroundEffectLayerOrderSwitchButton
              defaultLayerOrder={defaultLayerOrder}
              onChangeLayerOrder={onChangeLayerOrder}
            />
          </View>
        );
      }}
    </DimensionContext.Consumer>
  );
};

const aspectRatio = 1.25;

export default React.memo(SelectedBackgroundEffectImage);
