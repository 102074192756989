import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import numeral from 'numeral';

interface Item {
  label: string;
  value: number;
}

interface Props {
  items: Array<Item>;
}

const GridList: React.FunctionComponent<Props> = props => {
  const {items} = props;
  return (
    <View style={styles.summary}>
      {items.map((item, i) => {
        const style =
          i === 0
            ? styles.rowFirst
            : i === items.length - 1
            ? styles.rowLast
            : null;
        return (
          <View style={[styles.row, style]}>
            <Text style={styles.label}>{item.label}</Text>
            <Text style={styles.value}>
              {numeral(item.value).format('0,0')}
            </Text>
          </View>
        );
      })}
    </View>
  );
};

export default React.memo(GridList);

const styles = StyleSheet.create({
  summary: {
    width: '100%',
    flexDirection: 'row',
    paddingHorizontal: 12,
  } as ViewStyle,
  row: {
    backgroundColor: '#f2f2f2',
    height: 54,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4,
  } as ViewStyle,
  rowFirst: {
    marginRight: 4,
    marginLeft: 0,
  } as ViewStyle,
  rowLast: {
    marginRight: 0,
    marginLeft: 4,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2,
  } as TextStyle,
  value: {
    color: '#ff8f13',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 2,
  } as TextStyle,
});
