import * as React from 'react';
import {View, ViewStyle} from 'react-native';
import StoryListWithActionSheet from './StoryListWithActionSheet';
import NewStoryButton from './NewStoryButton';

import shouldUpdateStoryList from '../../shared/enhanced/shouldUpdateStoryList';
import ScrollableTabViewAdapter, {
  ChangedTabInfo,
} from '../../shared/scrollable_tab_view/ScrollableTabViewAdapter';

import Story from '../../../../domain/entities/Story';

const buttonWrapperStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
} as ViewStyle;

const bottomButtonStyle = {
  backgroundColor: 'rgba(255,255,255,0.9)',
  paddingTop: 16,
};

interface Props {
  serializedStories: Story[];
  completedStories: Story[];
  onSelectStory: (story: Story) => void;
  onDeleteStory: (story: Story) => void;
  onForwardToNewStory: () => void;
  onEndReachedForSerialized?:
    | ((info: {distanceFromEnd: number}) => void)
    | null;
  onEndReachedForCompleted?: ((info: {distanceFromEnd: number}) => void) | null;
}

interface State {
  currentPage: number;
}

export default class StoryDetailTabs extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      currentPage: 0,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    if (
      shouldUpdateStoryList(
        {stories: this.props.serializedStories},
        {stories: nextProps.serializedStories},
      )
    ) {
      return true;
    }
    if (
      shouldUpdateStoryList(
        {stories: this.props.completedStories},
        {stories: nextProps.completedStories},
      )
    ) {
      return true;
    }
    return this.state.currentPage !== nextState.currentPage;
  }

  public render(): React.ReactNode {
    const {completedStories, onForwardToNewStory} = this.props;
    const {currentPage} = this.state;
    return (
      <>
        <ScrollableTabViewAdapter
          tabs={[
            {label: '執筆中', element: this.generateSerialized()},
            {label: '完結済み', element: this.generateCompleted()},
          ]}
          locked={currentPage === 0 && completedStories.length === 0}
          onChangeTab={this.handleChangeTab}
        />
        <View style={buttonWrapperStyle}>
          <NewStoryButton
            bottomButtonStyle={bottomButtonStyle}
            onForwardToNewStory={onForwardToNewStory}
          />
        </View>
      </>
    );
  }

  private generateSerialized = (): React.ReactNode => {
    const {
      serializedStories,
      onSelectStory,
      onDeleteStory,
      onEndReachedForSerialized,
    } = this.props;
    return (
      <StoryListWithActionSheet
        stories={serializedStories}
        type={'執筆中'}
        onSelectStory={onSelectStory}
        onDeleteStory={onDeleteStory}
        onEndReached={onEndReachedForSerialized}
      />
    );
  };

  private generateCompleted = (): React.ReactNode => {
    const {
      completedStories,
      onSelectStory,
      onDeleteStory,
      onEndReachedForCompleted,
    } = this.props;
    return (
      <StoryListWithActionSheet
        stories={completedStories}
        type={'完結済み'}
        onSelectStory={onSelectStory}
        onDeleteStory={onDeleteStory}
        onEndReached={onEndReachedForCompleted}
      />
    );
  };

  private handleChangeTab = (changedTabInfo: ChangedTabInfo) => {
    this.setState({
      currentPage: changedTabInfo.i,
    });
  };
}
