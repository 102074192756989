import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewWriterUserConfirmationRouteProp} from '../../../navigators/RouteProps';

import * as routers from '../../../routers';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: NewWriterUserConfirmationRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  destroyWriterCurrentUserSession: () => Promise<void>;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FunctionComponent<Props> = props => {
  const {navigation, currentUser, destroyWriterCurrentUserSession} = props;
  const [email, setEmail] = React.useState(
    currentUser?.unconfirmedEmail || currentUser?.email || '',
  );
  const [loading, setLoading] = React.useState(false);
  const [visibleAlertModal, setVisibleAlertModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const handleSubmit = React.useCallback(() => {
    setLoading(true);
    TapNovelRestApi.post('/api/writer/users/confirmation', {
      api_writer_user: {email},
    })
      .then(a => {
        if (
          currentUser &&
          (currentUser.unconfirmedEmail || currentUser.email) !== email
        ) {
          setVisibleAlertModal(true);
        } else {
          routers.linkToWriterTemporaryUserRegistrationCompletion(
            navigation,
            {},
          );
        }
        setTimeout(() => {
          setLoading(false);
        }, 200);
      })
      .catch(e => {
        if (e.body.email && e.body.email.length > 0) {
          setErrorMessage(
            e.body.email.map((m: string) => `メールアドレス${m}`),
          );
        }
        setTimeout(() => {
          setLoading(false);
        }, 200);
      });
  }, [email]);
  return (
    <Layout
      title={'アカウント認証メール再送'}
      navigation={navigation}
      back={true}
      loading={loading}>
      <Form
        email={email}
        errorMessages={errorMessage}
        disabled={loading}
        onChangeEmail={setEmail}
        onSubmit={handleSubmit}
      />
      <AlertModal
        visible={visibleAlertModal}
        onCloseModal={() => {
          destroyWriterCurrentUserSession();
          navigation.navigate('NewWriterUserSession', {});
        }}>
        メールをご確認いただき、認証を行ってください。{'\n'}
        認証後、再度ログインください。
      </AlertModal>
    </Layout>
  );
};

export default React.memo(New);
