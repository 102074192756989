import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import ProjectTopic from '../../../../../domain/entities/ProjectTopic';

interface Props extends React.PropsWithChildren {
  topic: ProjectTopic;
}

const CommentListHeader: React.FC<Props> = props => {
  const {topic, children} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(`/projects/${topic.projectId}/topics/${topic.id}/comments/new`);
  }, []);
  return (
    <>
      {children}
      <View style={styles.button}>
        <PrimaryButton style={{width: 240}} onPress={onPress}>
          コメント
        </PrimaryButton>
      </View>
      {topic.commentsCount ? (
        <View style={styles.container}>
          <Text style={styles.title}>
            みんなのコメント（全{topic.commentsCount}件）
          </Text>
        </View>
      ) : null}
    </>
  );
};

export default React.memo(CommentListHeader);

const styles = StyleSheet.create({
  button: {
    margin: 16,
    alignItems: 'center',
  } as ViewStyle,
  container: {
    margin: 16,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
});
