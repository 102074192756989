import SceneCommandForm from '../../domain/forms/scene_commands/SceneCommandForm';

export default function getSceneCommandForm(
  sceneCommandForms: SceneCommandForm[] | null,
  sceneCommandId: number | undefined,
): SceneCommandForm | null {
  let sceneCommandForm: SceneCommandForm | null = null;
  if (sceneCommandId && sceneCommandForms) {
    const foundSceneCommandForm = sceneCommandForms.find(f => {
      return f.sceneCommandId === sceneCommandId;
    });
    if (foundSceneCommandForm) {
      sceneCommandForm = foundSceneCommandForm;
    }
  }
  return sceneCommandForm;
}
