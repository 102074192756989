import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import ProjectListItem from './ProjectListItem';

import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import Project from '../../../../domain/entities/Project';

interface Props {
  projects: Project[];
  wrriterUserId: number;
  ListFooterComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
  onForceUpdate: () => void;
}

const ProjectList: React.FC<Props> = props => {
  const {projects, wrriterUserId, ListFooterComponent, onForceUpdate} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Project>) => (
      <ProjectListItem
        project={info.item}
        wrriterUserId={wrriterUserId}
        onForceUpdate={onForceUpdate}
      />
    ),
    [],
  );
  return (
    <FlatList
      data={projects}
      style={{backgroundColor: '#fafafa'}}
      renderItem={renderItem}
      ListHeaderComponent={ListItemNormalSeparator}
      ListFooterComponent={
        <>
          <ListItemNormalSeparator />
          {ListFooterComponent}
        </>
      }
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(ProjectList);
