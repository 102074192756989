import * as React from 'react';
import {DateObject} from 'react-native-calendars';

import Form, {PublicationState} from './partials/Form';

import Layout from '../../../shared/Layout';
import AlertModal from '../../../shared/modals/AlertModal';

import NavigationProp from '../../../../navigators/NavigationProp';
import {EpisodeFormEditEpisodePublicationRouteProp} from '../../../../navigators/RouteProps';

import {Params as StoryUpdateParams} from '../../../../actions/stories/update';
import {Params as EpisodePublicationUpdateParams} from '../../../../actions/episode_publications/update';

import {formatErrorMessages} from '../../../../helpers/errorMessages';

import Episode from '../../../../../domain/entities/Episode';
import Story from '../../../../../domain/entities/Story';

import NetAnalyticsParticularEventsRepository from '../../../../../data/repositories/writer/NetAnalyticsParticularEventsRepository';
import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

interface StoryMaterialRight {
  id: number;
  story_id: number;
  uploaded_full_screen_illustration: boolean;
  uploaded_story_image: boolean;
  uploaded_voice: boolean;
  last_updated_at: string;
  confirmed_at: string | null;
  created_at: string;
  updated_at: string;
}

export interface Params {
  episodeId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EpisodeFormEditEpisodePublicationRouteProp;
  episode: Episode | null;
}

export interface DispatchProps {
  showEpisode: (id: number) => Promise<Episode>;
  updateStory: (id: number, params: StoryUpdateParams) => Promise<Story>;
  showStory: (id: number) => Promise<Story>;
  updateEpisodePublication: (
    id: number,
    params: EpisodePublicationUpdateParams,
  ) => Promise<Episode>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  publicationState: PublicationState;
  selectedDate: DateObject | null;
  selectedTime: Date;
  entryContestInfoLoaded: boolean;
  entryContestInfo?: {
    contest_id: number;
    errors: string[];
  };
  alertMessage?: string;
  uploadedSelfImage?: boolean;
  uploadedSelfVoice?: boolean;
  firstPost?: boolean;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {episode} = props;
    const defaultReserveDate = this.getDefaultReserveDate();
    this.state = {
      publicationState: this.getPublicationState(episode),
      selectedDate: this.generateDateObject(
        episode?.scheduledDeliveredAt || defaultReserveDate,
      ),
      selectedTime: episode?.scheduledDeliveredAt || defaultReserveDate,
      entryContestInfoLoaded: false,
      firstPost: false,
    };
  }

  public componentDidMount() {
    const {showEpisode, route} = this.props;
    const {episodeId} = route.params;
    showEpisode(episodeId).then(episode => {
      TapNovelRestApi.get<StoryMaterialRight>(
        `/api/writer/stories/${episode.storyId}/material_right`,
      )
        .then(res => {
          this.setState({
            uploadedSelfImage:
              res.body.uploaded_story_image ||
              res.body.uploaded_full_screen_illustration,
            uploadedSelfVoice: res.body.uploaded_voice && episode.withVoice,
          });
        })
        .catch(() => {
          this.setState({uploadedSelfImage: false, uploadedSelfVoice: false});
        });
      const defaultReserveDate = this.getDefaultReserveDate();
      this.setState({
        publicationState: this.getPublicationState(episode),
        selectedDate: this.generateDateObject(
          episode?.scheduledDeliveredAt || defaultReserveDate,
        ),
        selectedTime: episode?.scheduledDeliveredAt || defaultReserveDate,
      });
    });
    TapNovelRestApi.post('/api/writer/contest_entry_episode_validations', {
      episodeId,
    })
      .then(res => {
        this.setState({
          entryContestInfoLoaded: true,
          entryContestInfo: res.body ? (res.body as any) : null,
        });
      })
      .catch(() => {
        this.setState({
          entryContestInfoLoaded: true,
        });
      });

    new NetAnalyticsParticularEventsRepository()
      .findBy({
        resourceType: 'episode_publication',
        actionName: 'create',
        per: 1,
      })
      .then(res => {
        this.setState({firstPost: res.total === 0});
      });
  }

  public render(): React.ReactNode {
    const {navigation, episode} = this.props;
    const {
      selectedDate,
      selectedTime,
      publicationState,
      entryContestInfoLoaded,
      entryContestInfo,
      alertMessage,
      uploadedSelfImage,
      uploadedSelfVoice,
      firstPost,
    } = this.state;
    return (
      <Layout
        title={'エピソードの公開設定'}
        close={true}
        navigation={navigation}>
        {entryContestInfoLoaded && episode && (
          <Form
            episode={episode}
            publicationState={publicationState}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            entryContestInfo={entryContestInfo}
            uploadedSelfImage={uploadedSelfImage}
            uploadedSelfVoice={uploadedSelfVoice}
            firstPost={firstPost}
            onChangePublicationState={this.handleChangePublicationState}
            onDayPress={this.handleDayPress}
            onTimePress={this.handleTimePress}
            onSubmit={this.handleSubmit}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private getPublicationState = (episode: Episode | null) => {
    if (episode) {
      if (episode.published) {
        if (episode.scheduledDelivery()) {
          return 'reserved';
        } else {
          return 'public';
        }
      } else {
        return 'private';
      }
    }
    return 'private';
  };

  private generateDateObject(date: Date | null): DateObject | null {
    if (!date) {
      return null;
    }
    const ary = [
      date.getFullYear(),
      this.zeroPadding(date.getMonth() + 1, 2),
      this.zeroPadding(date.getDate(), 2),
    ];
    return {
      dateString: ary.join('-'),
      day: date.getDate(),
      month: date.getMonth() + 1,
      timestamp: date.getTime(),
      year: date.getFullYear(),
    };
  }

  private zeroPadding(n: number, length: number): string {
    return `0000000000${n}`.slice(-length);
  }

  private handleChangePublicationState = (
    publicationState: PublicationState,
  ) => {
    this.setState({publicationState});
  };

  private handleDayPress = (selectedDate: DateObject) => {
    this.setState({selectedDate});
  };

  private handleTimePress = (selectedTime: Date) => {
    this.setState({selectedTime});
  };

  private handleSubmit = () => {
    const {
      navigation,
      route,
      episode,
      updateEpisodePublication,
      showStory,
      updateStory,
    } = this.props;
    const {episodeId} = route.params;
    const {
      publicationState,
      selectedDate,
      selectedTime,
      uploadedSelfImage: uploadedSelfMaterial,
    } = this.state;
    if (!episode) {
      return;
    }
    const updateCallback = () => {
      showStory(episode.storyId).then(story => {
        if (story.violated) {
          updateStory(story.id, {requestViolationModification: true});
        }
      });
    };
    switch (publicationState) {
      case 'public':
        if (!episode.publishedDelivery()) {
          updateEpisodePublication(episodeId, {
            published: true,
            scheduledDeliveredAt: new Date(),
            confirmedMaterialRight: true,
          })
            .then(episode => {
              navigation.replace(
                'EpisodeFormEditEpisodePublicationCompletion',
                {
                  episodeId: episode.id,
                },
              );
              updateCallback();
            })
            .catch(error => {
              this.setState({alertMessage: formatErrorMessages({}, error)});
            });
        } else {
          if (uploadedSelfMaterial) {
            updateEpisodePublication(episodeId, {
              published: true,
              confirmedMaterialRight: true,
            })
              .then(episode => {
                navigation.replace(
                  'EpisodeFormEditEpisodePublicationCompletion',
                  {
                    episodeId: episode.id,
                  },
                );
                updateCallback();
              })
              .catch(error => {
                this.setState({alertMessage: formatErrorMessages({}, error)});
              });
          } else {
            navigation.replace('EpisodeFormEditEpisodePublicationCompletion', {
              episodeId: episode.id,
            });
          }
          updateCallback();
        }
        break;
      case 'reserved': {
        if (!selectedDate || !selectedTime) {
          return;
        }
        updateEpisodePublication(episodeId, {
          published: true,
          confirmedMaterialRight: true,
          scheduledDeliveredAt: new Date(
            selectedDate.year,
            selectedDate.month - 1,
            selectedDate.day,
            selectedTime.getHours(),
            selectedTime.getMinutes(),
            0,
            0,
          ),
        })
          .then(episode => {
            navigation.replace('EpisodeFormEditEpisodePublicationCompletion', {
              episodeId: episode.id,
            });
            updateCallback();
          })
          .catch(error => {
            this.setState({alertMessage: formatErrorMessages({}, error)});
          });
        break;
      }
      case 'private':
        updateEpisodePublication(episodeId, {published: false})
          .then(episode => {
            navigation.goBack();
            showStory(episode.storyId);
          })
          .catch(error => {
            this.setState({alertMessage: formatErrorMessages({}, error)});
          });
        break;
    }
  };

  private getDefaultReserveDate = () => {
    const now = new Date();
    return new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes() + 5,
      0,
      0,
    );
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };
}
