import EntityMapper from './EntityMapper';

import Voice from '../../../domain/entities/Voice';

export interface VoiceData {
  id: number;
  story_id: number;
  name: string;
  audio_url: string;
  audio_file_name: string;
  audio_content_type: string;
  audio_file_size: number;
  audio_updated_at: string;
  created_at: string;
  updated_at: string;
}

export default class VoiceMapper implements EntityMapper<VoiceData, Voice> {
  public map(obj: VoiceData): Voice {
    return new Voice(
      obj.id,
      obj.story_id,
      obj.name,
      obj.audio_url,
      obj.audio_file_name,
      obj.audio_content_type,
      obj.audio_file_size,
      new Date(obj.audio_updated_at),
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
