import * as React from 'react';
import {Image, Text, View} from 'react-native';

import Layout from '../shared/Layout';

import ShareButtonsWithCopy from '../shared/share/ShareButtonsWithCopy';

import NavigationProp from '../../navigators/NavigationProp';
import {StoryTapCountAchievementRouteProp} from '../../navigators/RouteProps';

import TapNovelTypedRestApi from '../../../data/data_stores/net/TapNovelTypedRestApi';

interface EpisodeRelease {
  id: number;
  ogpImageUrl: string;
  title: string;
  storyId: number;
  storyTitle: string;
  storyUrl: string;
  updatedAt: string;
  createdAt: string;
}

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: StoryTapCountAchievementRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {id} = route.params;
  const [episodeRelease, setEpisodeRelease] =
    React.useState<EpisodeRelease | null>(null);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<EpisodeRelease>(
      `/api/writer/episodes/${id}/release`,
    )
      .then(response => {
        setEpisodeRelease(response.body);
      })
      .catch(e => {
        if (e.status === 404) {
          navigation.goBack();
        }
      });
  }, []);

  return (
    <Layout
      title={'通知'}
      navigation={navigation}
      scrollable={false}
      back={true}>
      {episodeRelease && (
        <View style={{flex: 1, alignItems: 'center', paddingVertical: 25}}>
          <Text
            style={{
              color: '#222',
              fontSize: 15,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 25,
            }}>
            予約したエピソードが公開されました。
          </Text>
          <Image
            source={{uri: episodeRelease.ogpImageUrl}}
            style={{
              width: 343,
              height: 180,
              marginBottom: 32,
            }}
          />
          <Text
            style={{
              color: '#222',
              fontSize: 15,
              fontWeight: 'bold',
              textAlign: 'center',
            }}>
            沢山の人に読んでもらえるようにシェアしましょう！
          </Text>
          <View style={{marginTop: 20}}>
            <ShareButtonsWithCopy
              title={episodeRelease.storyTitle}
              message={`『${episodeRelease.storyTitle}』の「${episodeRelease.title}」を公開されました。`}
              url={`${episodeRelease.storyUrl}?ogp_type=episode_publication`}
            />
          </View>
        </View>
      )}
    </Layout>
  );
};

export default React.memo(Show);
