import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import CharacterPosition, {ITEM_MARGIN} from './CharacterPosition';

import DimensionContext from '../../../../../shared/dimension/DimensionContext';

import SceneFrame from '../../../../../../view_models/SceneFrame';

import generateSceneFrame from '../../../../../../helpers/generateSceneFrame';
import convertImageSource from '../../../../../../helpers/convertImageSource';

import SceneCommandForm from '../../../../../../../domain/forms/scene_commands/SceneCommandForm';
import Position from '../../../../../../../domain/value_objects/Position';

import leftUri from '../../../../../../../assets/images/speech_text_positions/left.png';
import centerUri from '../../../../../../../assets/images/speech_text_positions/center.png';
import rightUri from '../../../../../../../assets/images/speech_text_positions/right.png';

interface Props {
  selectedPositions: Position[];
  sceneCommandForms: SceneCommandForm[] | null;
  overrideCharacterName: boolean;
  sceneCommandIndex?: number;
  onSelectPositions: (positions: Position[], sceneFrame: SceneFrame) => void;
  onToggleMultiplePositions: (positions: Position[]) => void;
}

interface State {
  sceneFrame: SceneFrame;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {sceneCommandForms, sceneCommandIndex} = props;
    this.state = {
      sceneFrame: this.generateSceneFrame(sceneCommandForms, sceneCommandIndex),
    };
  }

  public render(): React.ReactNode {
    const {
      selectedPositions,
      overrideCharacterName,
      onToggleMultiplePositions,
    } = this.props;
    const sceneFrame = overrideCharacterName
      ? new SceneFrame()
      : this.state.sceneFrame;
    return (
      <DimensionContext.Consumer>
        {context => {
          const imageWidth = Math.ceil(
            (context.content.width -
              (CONTAINER_PADDING + ITEM_MARGIN * NUM_COLUMNS) * 2) /
              NUM_COLUMNS,
          );
          return (
            <View style={styles.container}>
              <View style={styles.positions}>
                <CharacterPosition
                  position={Position.Left}
                  sceneFrame={sceneFrame}
                  selectedPositions={selectedPositions}
                  source={convertImageSource(leftUri)}
                  width={imageWidth}
                  onSelectPositions={this.handleSelectPositions}
                  onToggleMultiplePositions={onToggleMultiplePositions}
                />
                <CharacterPosition
                  position={Position.Center}
                  sceneFrame={sceneFrame}
                  selectedPositions={selectedPositions}
                  source={convertImageSource(centerUri)}
                  width={imageWidth}
                  onSelectPositions={this.handleSelectPositions}
                  onToggleMultiplePositions={onToggleMultiplePositions}
                />
                <CharacterPosition
                  position={Position.Right}
                  sceneFrame={sceneFrame}
                  selectedPositions={selectedPositions}
                  source={convertImageSource(rightUri)}
                  width={imageWidth}
                  onSelectPositions={this.handleSelectPositions}
                  onToggleMultiplePositions={onToggleMultiplePositions}
                />
              </View>
              <Text style={styles.message}>長押しで複数選択できます</Text>
            </View>
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private handleSelectPositions = (positions: Position[]) => {
    const {onSelectPositions} = this.props;
    const {sceneFrame} = this.state;
    onSelectPositions(positions, sceneFrame);
  };

  private generateSceneFrame(
    sceneCommandForms: SceneCommandForm[] | null,
    sceneCommandIndex?: number,
  ): SceneFrame {
    return generateSceneFrame(sceneCommandForms, sceneCommandIndex);
  }
}

const CONTAINER_PADDING = 10;

const NUM_COLUMNS = 3;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: CONTAINER_PADDING,
  } as ViewStyle,
  positions: {
    alignSelf: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    marginTop: 10,
    color: '#383838',
    fontSize: 10,
    textAlign: 'center',
  } as TextStyle,
});
