import AccountAggregation from '../../../../domain/value_objects/summary/AccountAggregation';

export interface AccountAggregationData {
  id: number;
  from?: string | null;
  to?: string | null;
  pen_name: string;
  published_stories_count: number;
  fanned_consumer_user_count: number;
  episode_page_view_count: number;
  reactions_count: number;
  comments_count: number;
}

export default class AccountAggregationMapper {
  public map(obj: AccountAggregationData): AccountAggregation {
    return new AccountAggregation(
      obj.id,
      obj.from ? new Date(obj.from) : null,
      obj.to ? new Date(obj.to) : null,
      obj.pen_name,
      obj.published_stories_count,
      obj.fanned_consumer_user_count,
      obj.episode_page_view_count,
      obj.reactions_count,
      obj.comments_count,
    );
  }
}
