import {AnyAction} from 'redux';

import {QueryState} from '../Response';

import Types from '../../../actions/Types';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
  }
  return state;
}
