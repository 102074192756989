import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import Sound from '../../entities/Sound';

const CLASS_NAME: SceneCommandFormClass = 'BackgroundMusicHideSceneCommandForm';

export default class BackgroundMusicHideSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(public sound: Sound, sceneCommandId?: number) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      sound_id: this.sound.id,
      type: 'SceneCommands::BackgroundMusicHideSceneCommand',
    };
  }

  public copy(sceneCommandId?: number): BackgroundMusicHideSceneCommandForm {
    return new BackgroundMusicHideSceneCommandForm(this.sound, sceneCommandId);
  }
}
