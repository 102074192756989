import UserProperties from '../../../domain/value_objects/UserProperties';

export interface UserPropertiesData {
  user_id: number;
  writer_grade_id: number;
}

export default class UserPropertiesMapper {
  public map(obj: UserPropertiesData): UserProperties {
    return new UserProperties(obj.user_id, obj.writer_grade_id);
  }
}
