import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import DefaultOutlineButton from './DefaultOutlineButton';

import SearchFilterIcon from '../icons/SearchFilterIcon';

interface Props {
  onPress: () => void;
}

const SearchConditionChangeButton: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <DefaultOutlineButton
      style={styles.container}
      fontSizeClass={'small'}
      unwrappedText={true}
      onPress={onPress}>
      <View style={styles.inner}>
        <SearchFilterIcon size={13} color={'#383838'} />
        <Text style={styles.label}>条件変更</Text>
      </View>
    </DefaultOutlineButton>
  );
};

export default React.memo(SearchConditionChangeButton);

const styles = StyleSheet.create({
  container: {
    width: 97,
    height: 32,
  } as ViewStyle,
  inner: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    color: '#222222',
    marginLeft: 4,
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
