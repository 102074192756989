import * as React from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputSelectionChangeEventData,
  View,
  ViewStyle,
} from 'react-native';

import AttachmentSoundView from '../../../../../shared/sound/AttachmentSoundView';

import Tabs from '../tabs/Tabs';
import ConfirmButton from '../buttons/ConfirmButton';
import ImageGridList from '../lists/ImageGridList';

import AddTextCommandLinksGroup from '../../../../../shared/AddFrequentlySymbolButtonsGroup';
import ElasticTextFrameInput from '../../../../../shared/ElasticTextFrameInput';
import ElasticBoxInputTextCounter from '../../../../../shared/ElasticBoxInputTextCounter';
import VoiceForm from '../../../../../shared/VoiceForm';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';
import UserStatusContext from '../../../../../shared/user_status/UserStatusContext';

import {
  addSymbolToTextAndCalcSelection,
  validText,
} from '../../../../../../helpers/textInputHelper';

import DescriptiveTextShowSceneCommandForm from '../../../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import TextFrame from '../../../../../../../domain/entities/TextFrame';
import Voice from '../../../../../../../domain/entities/Voice';
import Sound from '../../../../../../../domain/entities/Sound';

import TextNormalizer from '../../../../../../../domain/helpers/TextNormalizer';

export type TabValue = 'text' | 'text_frame' | 'voice';

const TAB_ITEMS = [
  {value: 'text', label: '内容'},
  {value: 'text_frame', label: '枠組み'},
  {value: 'voice', label: 'ボイス'},
] as Array<{value: TabValue; label: string}>;

interface Props {
  storyId: number;
  sceneCommandForm: DescriptiveTextShowSceneCommandForm;
  textFrames: TextFrame[];
  enableVoice?: boolean;
  textInputRef: React.RefObject<TextInput>;
  currentTab: TabValue;
  selectedText: string;
  selectedTextFrame: TextFrame;
  selectedVoice: Voice | null;
  selectedSound: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
  selection?: {
    start: number;
    end: number;
  };
  onChangeTab: (currentTab: TabValue) => void;
  onSelectText: (selectedText: string) => void;
  onPressSymbol: (symbol: string) => void;
  onUpdateVoice: (voice: Voice | null) => void;
  onSelectionChange: (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => void;
  onSelectTextFrame: (selectedTextFrame: TextFrame) => void;
  onSubmit: () => void;
  addSound: () => void;
  deleteSound: () => void;
  onChagenSoundRegion: (start?: number, end?: number) => void;
}

export default class DescriptiveTextViewWithEditor extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      storyId,
      textFrames,
      enableVoice,
      textInputRef,
      currentTab,
      selectedText,
      selectedTextFrame,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      selection,
      onChangeTab,
      onSelectText,
      onPressSymbol,
      onUpdateVoice,
      onSelectionChange,
      onSelectTextFrame,
      onSubmit,
      addSound,
      deleteSound,
      onChagenSoundRegion,
    } = this.props;
    return (
      <UserStatusContext.Consumer>
        {userStatusContext => (
          <View style={styles.container}>
            <DimensionContext.Consumer>
              {context => (
                <View style={styles.frameText}>
                  <ElasticTextFrameInput
                    textInputRef={textInputRef}
                    text={selectedText}
                    textFrame={selectedTextFrame}
                    width={Math.floor(context.content.width * ASPECT_RATIO)}
                    height={TEXT_FRAME_HEIGHT}
                    selection={selection}
                    hasVoice={!!selectedVoice}
                    onChangeText={onSelectText}
                    onSelectionChange={onSelectionChange}
                  />
                  {(userStatusContext.currentUser?.enabledPaidSubscriber ||
                    userStatusContext.currentUser?.isGradeBlack()) && (
                    <View
                      style={{
                        position: 'absolute',
                        bottom: 26,
                        left: 50,
                        right: 50,
                      }}>
                      <AttachmentSoundView
                        style={{height: '100%'}}
                        sound={selectedSound}
                        startTime={selectedSoundStartTime}
                        endTime={selectedSoundEndTime}
                        addSound={addSound}
                        deleteSound={deleteSound}
                        onChagenRegion={onChagenSoundRegion}
                      />
                    </View>
                  )}
                </View>
              )}
            </DimensionContext.Consumer>
            <ElasticBoxInputTextCounter
              style={styles.textCounter}
              length={selectedText.length}
            />
            <Tabs<TabValue>
              items={TAB_ITEMS.filter(
                item => enableVoice || item.value !== 'voice',
              )}
              currentValue={currentTab}
              onChangeTab={onChangeTab}
            />
            <View style={styles.footer}>
              <View style={styles.commands}>
                <View
                  style={[
                    styles.addTextCommands,
                    currentTab === 'text' ? null : {display: 'none'},
                  ]}>
                  <AddTextCommandLinksGroup
                    buttonStyle={{width: 38, height: 36}}
                    onPressSymbol={onPressSymbol}
                  />
                </View>
                <ImageGridList
                  visible={currentTab === 'text_frame'}
                  entities={textFrames}
                  selectedEntity={selectedTextFrame}
                  onSelectEntity={onSelectTextFrame}
                />
                <VoiceForm
                  style={{margin: 16}}
                  visible={currentTab === 'voice'}
                  storyId={storyId}
                  voice={selectedVoice}
                  onUpdateVoice={onUpdateVoice}
                />
              </View>
              <View style={styles.button}>
                <ConfirmButton
                  disabled={
                    !userStatusContext.currentUser?.isGradeBlack() &&
                    !validText(selectedText)
                  }
                  onPress={onSubmit}
                />
              </View>
            </View>
          </View>
        )}
      </UserStatusContext.Consumer>
    );
  }
}

const ASPECT_RATIO = 290 / 375;

const CONTENT_HEIGHT = 225;

const TEXT_FRAME_HEIGHT = 180;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  } as ViewStyle,
  frameText: {
    height: CONTENT_HEIGHT,
    marginVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      web: {width: '100%'},
      default: {},
    }),
  } as ViewStyle,
  footer: {
    backgroundColor: 'white',
    width: '100%',
  } as ViewStyle,
  addTextCommands: {
    height: 70,
    paddingHorizontal: 6,
    width: '100%',
  } as ViewStyle,
  commands: {
    height: 183,
    width: '100%',
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  textCounter: {
    width: '100%',
    height: 20,
    marginTop: -8,
    marginBottom: 8,
    marginHorizontal: 0,
    paddingHorizontal: 48,
  } as ViewStyle,
});
