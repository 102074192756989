import {Dispatch} from 'redux';

import AppState from '../../../reducers/AppState';

import EpisodePageViewAggregation from '../../../../domain/value_objects/summary/EpisodePageViewAggregation';

import NetEpisodePageViewAggregationsRepository from '../../../../data/repositories/writer/summary/NetEpisodePageViewAggregationsRepository';

export interface Params {
  storyId: number;
  from?: Date | undefined;
  to?: Date | undefined;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<Array<EpisodePageViewAggregation>> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<Array<EpisodePageViewAggregation>> => {
    const repository = new NetEpisodePageViewAggregationsRepository();
    const payload = await repository.findBy(params);
    return payload;
  };
}
