import * as React from 'react';
import {
  ImageStyle,
  Linking,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import DimensionContext from '../../../shared/dimension/DimensionContext';
import PlayIcon from '../../../shared/icons/PlayIcon';
import VideoPlayer from '../../../shared/VideoPlayer';

import {colors} from '../../../../styles/variables';

import EpisodeVideoExportJobExecution from '../../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

interface Props {
  episodeVideoExportJobExecution: EpisodeVideoExportJobExecution;
  notification?: boolean;
}

const EpisodeVideoExportJobExecutionList: React.FunctionComponent<
  Props
> = props => {
  const {episodeVideoExportJobExecution, notification} = props;
  const source = React.useMemo(() => {
    return {
      uri: episodeVideoExportJobExecution.getLargeImageUrl(),
      headers: {Accept: 'image/webp,image/apng,*/*'},
    };
  }, []);
  const [playing, setPlaying] = React.useState(false);
  const onPress = React.useCallback(() => {
    setPlaying(!playing);
  }, [playing]);
  const onEnded = React.useCallback(() => {
    setPlaying(false);
  }, []);
  const onPressVideoDownload = React.useCallback(() => {
    if (episodeVideoExportJobExecution.episodeVideo) {
      Linking.openURL(
        episodeVideoExportJobExecution.videoDownloadUrl ||
          episodeVideoExportJobExecution.episodeVideo.videoDownloadUrl,
      );
    }
  }, []);
  const onPressImageDownload = React.useCallback(() => {
    if (episodeVideoExportJobExecution.imageDownloadUrl) {
      Linking.openURL(episodeVideoExportJobExecution.imageDownloadUrl);
    }
  }, []);
  return (
    <DimensionContext.Consumer>
      {context => {
        const width = context.content.width * 0.62;
        const size = episodeVideoExportJobExecution.getLargeImageSize();
        return (
          <View style={styles.container}>
            {notification ? (
              <Text style={styles.title}>動画が完成しました！</Text>
            ) : null}
            <Pressable style={styles.imageWrapper} onPress={onPress}>
              {episodeVideoExportJobExecution.episodeVideo && playing ? (
                <VideoPlayer
                  style={size}
                  src={
                    episodeVideoExportJobExecution.episodeVideo.videoInlineUrl
                  }
                  onEnded={onEnded}
                />
              ) : (
                <>
                  {episodeVideoExportJobExecution.hasImage ? (
                    <FastImageAdapter
                      style={[styles.image, size]}
                      source={source}
                    />
                  ) : (
                    <View style={[styles.image, size]}>
                      <Text style={styles.noImageText}>表紙なし</Text>
                    </View>
                  )}
                  <View style={styles.playIcon}>
                    <PlayIcon size={50} color={'white'} />
                  </View>
                </>
              )}
            </Pressable>
            <View style={styles.buttons}>
              <View style={styles.buttonWrapper}>
                <Pressable style={styles.button} onPress={onPressVideoDownload}>
                  <Text style={styles.buttonText}>動画ダウンロード</Text>
                </Pressable>
              </View>
              {episodeVideoExportJobExecution.imageDownloadUrl ? (
                <View style={styles.buttonWrapper}>
                  <Pressable
                    style={styles.button}
                    onPress={onPressImageDownload}>
                    <Text style={styles.buttonText}>
                      サムネイルダウンロード
                    </Text>
                  </Pressable>
                </View>
              ) : null}
            </View>
          </View>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(EpisodeVideoExportJobExecutionList);

const button = {
  borderRadius: 24,
  width: 311,
  height: 46,
  justifyContent: 'center',
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 8,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 8,
  } as TextStyle,
  imageWrapper: {
    marginTop: 24,
    alignItems: 'center',
    marginBottom: 32,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
  } as ImageStyle,
  playIcon: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  message: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  buttons: {
    marginTop: 8,
  } as ViewStyle,
  buttonWrapper: {
    marginVertical: 8,
  } as ViewStyle,
  buttonMessage: {
    textAlign: 'center',
    color: '#222',
    fontSize: 11,
    marginBottom: 8,
  } as TextStyle,
  button: {
    ...button,
    alignItems: 'center',
    backgroundColor: '#ff8f13',
  } as ViewStyle,
  buttonInner: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  buttonText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  icon: {
    marginRight: 8,
    width: 30,
    height: 30,
  } as ImageStyle,
});
