import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ProLabel from '../../shared/ProLabel';

import MenuItem from '../../../view_models/MenuItem';

interface Props {
  menuItem: MenuItem;
  width: number;
  disabled?: boolean;
  onSelectMenuItem: (menuItem: MenuItem) => void;
}

const MenuListItem: React.FunctionComponent<Props> = (props: Props) => {
  const {menuItem, width, disabled, onSelectMenuItem} = props;
  const onPress = React.useCallback(
    () => onSelectMenuItem(menuItem),
    [onSelectMenuItem, menuItem],
  );
  const Icon = menuItem.Icon;
  return (
    <TouchableOpacity activeOpacity={0.6} disabled={disabled} onPress={onPress}>
      <View style={[styles.container, {width}]}>
        <View style={styles.iconWrapper}>
          {<Icon size={33} color={disabled ? '#d5d5d5' : '#383838'} />}
          {menuItem.pro ? (
            <View style={{position: 'absolute', top: -5, right: 3}}>
              <ProLabel
                style={{width: 21, height: 12}}
                textStyle={{fontSize: 8}}
              />
            </View>
          ) : null}
        </View>
        <Text style={[styles.label, disabled ? {color: '#d5d5d5'} : null]}>
          {menuItem.label}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(MenuListItem);

const styles = StyleSheet.create({
  container: {
    marginVertical: 20,
    alignItems: 'center',
  } as ViewStyle,
  iconWrapper: {
    height: 34,
    width: 54,
    alignContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
    marginTop: 9,
  } as TextStyle,
});
