import * as React from 'react';
import {Text} from 'react-native';
import DefaultModal from '../../../shared/modals/DefaultModal';
import Form from '../../../shared/stripe/Form';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';
import PaymentMethod from '../../../../../domain/entities/writer/my_stripe/PaymentMethod';
import PaymentIntent from '../../../../../domain/entities/writer/my_stripe/PaymentIntent';

import NetSubscriptionPaymentIntentRepository from '../../../../../data/repositories/writer/my_stripe/maker_pro/NetSubscriptionPaymentIntentRepository';

interface Props {
  paymentMethod: PaymentMethod | null;
  paymentIntent: PaymentIntent | null;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod | null>>;
  setPaymentIntent: React.Dispatch<React.SetStateAction<PaymentIntent | null>>;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  success: () => void;
  fail: () => void;
  onCloseModal: () => void;
  onComplete: () => void;
}

const FormModal: React.FunctionComponent<Props> = props => {
  const [visible, setVisible] = React.useState(false);
  const {
    paymentMethod,
    paymentIntent,
    setPaymentMethod,
    setPaymentIntent,
    success,
    fail,
    onCloseModal,
    setLoading,
  } = props;
  const onReady = React.useCallback(() => {
    setVisible(true);
  }, []);
  const createPaymentIntent = React.useCallback(() => {
    return new NetSubscriptionPaymentIntentRepository().create({});
  }, []);
  return (
    <DefaultModal
      visible={true}
      style={{display: visible ? 'flex' : 'none'}}
      disableCloseByOutsideTouch={true}
      onCloseModal={onCloseModal}>
      <Text
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          textAlign: 'center',
          marginBottom: 16,
        }}>
        MakerPro会員のお支払い
      </Text>
      <Form
        onlySetup={false}
        paymentMethod={paymentMethod}
        paymentIntent={paymentIntent}
        recurringMonthly={true}
        note={'※MakerProは退会しない限り、自動更新されます。'}
        createPaymentIntent={createPaymentIntent}
        onReady={onReady}
        setPaymentMethod={setPaymentMethod}
        setPaymentIntent={setPaymentIntent}
        setLoading={setLoading}
        success={success}
        fail={fail}
      />
    </DefaultModal>
  );
};

export default React.memo(FormModal);
