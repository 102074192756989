import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ServiceExplanationStep1 from './ServiceExplanationStep1';
import ServiceExplanationStep2 from './ServiceExplanationStep2';
import ServiceExplanationStep3 from './ServiceExplanationStep3';
import ServiceExplanationStep4 from './ServiceExplanationStep4';

import DimensionContext from '../../../../shared/dimension/DimensionContext';

import AlertModal from '../../../../shared/modals/AlertModal';

interface InnerProps {
  width: number;
  joinedPartnerProgram: boolean;
  onAgreeServicePolicy: () => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const ServiceExplanationInnter: React.FC<InnerProps> = props => {
  const {width, joinedPartnerProgram, onAgreeServicePolicy, setTitle} = props;
  const scrollViewRef = React.useRef<ScrollView | null>(null);
  const [step, setStep] = React.useState<1 | 2 | 3 | 4>(1);
  const [error, setError] = React.useState<string | null>(null);
  const onPressStep1 = React.useCallback(() => {
    if (!joinedPartnerProgram) {
      setError(
        'パートナープログラムに参加していない場合、ライターズゲートに参加できません',
      );
      return;
    }
    setStep(2);
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: width});
    }
    setTitle('条件について');
  }, [scrollViewRef, joinedPartnerProgram]);
  const onPressStep2 = React.useCallback(() => {
    setStep(3);
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: width * 2});
    }
    setTitle('窓口業務について');
  }, [scrollViewRef]);
  const onPressStep3 = React.useCallback(() => {
    setStep(4);
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: width * 3});
    }
    setTitle('注意事項');
  }, [scrollViewRef]);
  const onPressStep4 = React.useCallback(() => {
    onAgreeServicePolicy();
    setTitle('オファー一覧');
  }, [scrollViewRef]);
  const onCloseModal = React.useCallback(() => {
    setError(null);
  }, []);
  const style = React.useMemo(() => {
    return {width, flex: 1};
  }, [width]);
  return (
    <ScrollView
      ref={scrollViewRef}
      pagingEnabled={true}
      horizontal={true}
      scrollEnabled={false}>
      <View style={style}>
        <ServiceExplanationStep1 onPress={onPressStep1} />
      </View>
      <View style={style}>
        <ServiceExplanationStep2 onPress={onPressStep2} />
      </View>
      <View style={style}>
        <ServiceExplanationStep3 onPress={onPressStep3} />
      </View>
      <View style={style}>
        <ServiceExplanationStep4 onPress={onPressStep4} />
      </View>
      <AlertModal visible={!!error} onCloseModal={onCloseModal}>
        <Text>{error}</Text>
      </AlertModal>
    </ScrollView>
  );
};

interface Props {
  joinedPartnerProgram: boolean;
  onAgreeServicePolicy: () => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const ServiceExplanation: React.FunctionComponent<Props> = props => {
  return (
    <DimensionContext.Consumer>
      {context => {
        const width = context.content.width;
        return <ServiceExplanationInnter {...props} width={width} />;
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(ServiceExplanation);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
});
