import * as React from 'react';

import PrimaryButton from '../../../../../shared/buttons/PrimaryButton';

interface Props {
  disabled?: boolean;
  onPress: () => void;
}

const ConfirmButton: React.FunctionComponent<Props> = props => {
  const {disabled, onPress} = props;
  return (
    <PrimaryButton
      disabled={disabled}
      buttonSize={BUTTON_SIZE}
      onPress={onPress}>
      確定
    </PrimaryButton>
  );
};

const BUTTON_SIZE = {
  width: 160,
  height: 32,
};

export default React.memo(ConfirmButton);
