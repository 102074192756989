import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import UserNotificationSettingsBatch from '../../../../domain/entities/writer/UserNotificationSettingsBatch';

import NetUserNotificationSettingsBatchRepository from '../../../../data/repositories/writer/NetUserNotificationSettingsBatchRepository';

const types = {
  failure: Types.ShowWriterUserNotificationSettingsBatchFailure,
  request: Types.ShowWriterUserNotificationSettingsBatchRequest,
  success: Types.ShowWriterUserNotificationSettingsBatchSuccess,
};

export default function action(): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<UserNotificationSettingsBatch> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<UserNotificationSettingsBatch> => {
    try {
      const payload =
        await new NetUserNotificationSettingsBatchRepository().find();
      dispatch({payload, type: types.success});
      return payload;
    } catch (err) {
      dispatch({type: types.failure});
      throw err;
    }
  };
}
