import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/background_show_scene_command/new/background_effects/Index';

import AppState from '../../../../../reducers/AppState';

import showBaseEffect from '../../../../../actions/base_effects/show';
import indexEffects, {
  Params as EffectIndexParams,
} from '../../../../../actions/effects/index';
import updateSceneForm, {
  Params as SceneFormUpdateParams,
} from '../../../../../actions/scene_forms/update';

import selectEntities from '../../../../../helpers/selectEntities';
import selectEntity from '../../../../../helpers/selectEntity';

import {SceneFormBackgroundShowSceneCommandNewBackgroundEffectsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBackgroundEffectsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {baseEffectId} = route.params;
  const sceneForm = forms.sceneForm;
  const effectsParams: EffectIndexParams = {
    baseEffectId,
  };
  const effects = selectEntities(
    entities.effects,
    queries.effects,
    effectsParams,
  );
  const baseEffect = selectEntity(entities.baseEffects, baseEffectId);
  return {
    navigation,
    sceneForm,
    baseEffect,
    effects,
    effectsParams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showBaseEffect: (id: number) => dispatch(showBaseEffect(id)),
    indexEffects: (params: EffectIndexParams) => dispatch(indexEffects(params)),
    updateSceneForm: (params: SceneFormUpdateParams) =>
      dispatch(updateSceneForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
