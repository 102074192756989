import TapNovelRestApi from '../data_stores/net/TapNovelRestApi';

import EntityMapper from '../entities/mappers/EntityMapper';

import Entity from '../../domain/entities/Entity';
import ResourceRepository from '../../domain/repositories/ResourceRepository';

export default class NetResourceRepositoryAdapter<E extends Entity, D>
  implements ResourceRepository<E>
{
  constructor(private endPoint: string, public mapper: EntityMapper<D, E>) {}

  public async find(): Promise<E> {
    const path = `${this.endPoint}`;
    const obj = await TapNovelRestApi.get<D>(path);
    return this.mapper.map(obj.body, obj.headers);
  }

  public async create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    const path = this.endPoint;
    const obj = await TapNovelRestApi.post<D>(path, params, options);
    return this.mapper.map(obj.body, obj.headers);
  }

  public async update(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    const path = `${this.endPoint}`;
    const obj = await TapNovelRestApi.patch<D>(path, params, options);
    return this.mapper.map(obj.body, obj.headers);
  }

  public async destroy(params?: {[key: string]: any}): Promise<void> {
    const path = `${this.endPoint}`;
    await TapNovelRestApi.delete(path, params);
    return;
  }
}
