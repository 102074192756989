import * as React from 'react';
import {View} from 'react-native';
import Headline from '../../../shared/Headline';
import EntityImageGridListWithModal from '../../../shared/EntityImageGridListWithModal';

import {
  backgroundUsageHistoryImageUrl,
  wallpaperUsageHistoryImageUrl,
} from '../../../../helpers/images';

import BackgroundUsageHistory from '../../../../../domain/entities/BackgroundUsageHistory';

type EntityUsageHistory = BackgroundUsageHistory;

interface Props {
  entities: EntityUsageHistory[];
  onSelectBackgroundUsageHistory: (entity: EntityUsageHistory) => void;
  onPressFavoriteList?: () => void;
}

export default class EntityUsageHistoryList extends React.Component<Props> {
  public render(): React.ReactNode {
    const {entities, onSelectBackgroundUsageHistory, onPressFavoriteList} =
      this.props;
    if (entities.length === 0) {
      return null;
    }
    return (
      <View>
        <Headline
          title={'使用した背景'}
          onPressFavoriteList={onPressFavoriteList}
        />
        <EntityImageGridListWithModal
          entities={entities}
          aspectRatio={aspectRatio}
          resizeMode={'cover'}
          onSelectEntity={onSelectBackgroundUsageHistory}
          imageUrlExtractor={this.imageUrlExtractor}
        />
      </View>
    );
  }

  private imageUrlExtractor = (entity: EntityUsageHistory) => {
    if (entity instanceof BackgroundUsageHistory) {
      return backgroundUsageHistoryImageUrl(entity, 'middle');
    } else {
      return wallpaperUsageHistoryImageUrl(entity, 'middle');
    }
  };
}

const aspectRatio = 1.25;
