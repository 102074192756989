import TapNovelRawApi from '../../data/data_stores/net/TapNovelRawApi';

interface Params {
  [key: string]: any;
}

export default function downloadFile(
  path: string,
  params?: Params,
  callback?: () => void,
) {
  TapNovelRawApi.get(path, params).then(async response => {
    const disposition = response.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const encodedFilenameRegex =
        /filename[^;=\n]*\*=UTF-8''((['"]).*?\2|[^;\n]*)/;
      const matches = encodedFilenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        const fileName = decodeURIComponent(matches[1].replace(/['"]/g, ''));
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(await response.blob());
        a.download = fileName;
        a.click();
      } else {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          const fileName = decodeURIComponent(matches[1].replace(/['"]/g, ''));
          const a = document.createElement('a');
          a.href = window.URL.createObjectURL(await response.blob());
          a.download = fileName;
          a.click();
          callback && callback();
        }
      }
    }
  });
}
