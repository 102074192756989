import EntityMapper from './EntityMapper';

import FullScreenIllustration from '../../../domain/entities/FullScreenIllustration';

export interface FullScreenIllustrationData {
  id: number;
  story_id: number;
  name: string;
  original_image_url: string;
}

export default class FullScreenIllustrationMapper
  implements EntityMapper<FullScreenIllustrationData, FullScreenIllustration>
{
  public map(obj: FullScreenIllustrationData): FullScreenIllustration {
    return new FullScreenIllustration(
      obj.id,
      obj.story_id,
      obj.name,
      obj.original_image_url,
    );
  }
}
