import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import ManuscriptClapButton from './ManuscriptClapButton';

import DimensionContext from '../../../shared/dimension/DimensionContext';
import PlayIcon from '../../../shared/icons/PlayIcon';
import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';

import ProjectManuscript from '../../../../../domain/entities/ProjectManuscript';

import ImageUrlHelper from '../../../../../domain/helpers/ImageUrlHelper';
import Episode from '../../../../../domain/entities/Episode';

import NetEpisodesRepository from '../../../../../data/repositories/writer/NetEpisodesRepository';

interface Props {
  manuscript: ProjectManuscript;
  disabledLike?: boolean;
}

const DetailEpisode: React.FC<Props> = props => {
  const {manuscript, disabledLike} = props;
  const linkTo = useLinkTo();
  const [episode, setEpisode] = React.useState<Episode | null>(null);
  const onPress = React.useCallback(() => {
    if (manuscript.episodeId) {
      linkTo(`/episodes/${manuscript.episodeId}/viewer`);
    }
  }, []);
  React.useEffect(() => {
    if (!manuscript.episodeId) {
      return;
    }
    new NetEpisodesRepository().find(manuscript.episodeId).then(episode => {
      setEpisode(episode);
    });
  }, []);
  const source = episode
    ? {
        uri: ImageUrlHelper.getImageUrl(episode.originalImageUrl, {
          width: 992,
          height: 622,
        }),
        headers: {Accept: 'image/webp,image/apng,*/*'},
      }
    : null;
  return (
    <DimensionContext.Consumer>
      {context => {
        const width = context.content.width - 16 * 2;
        const height = Math.round((width / 343) * 215);
        return (
          <View style={styles.container}>
            <View style={[styles.image, {width, height}]}>
              {episode?.hasImage && source ? (
                <FastImageAdapter style={{width, height}} source={source} />
              ) : (
                <Text style={styles.imageText}>サムネイル</Text>
              )}
            </View>
            <Text style={styles.title}>{manuscript.title}</Text>
            <Text style={styles.penName}>{manuscript.writerUser.penName}</Text>
            <View style={{alignItems: 'center', marginVertical: 24}}>
              <TouchableOpacity activeOpacity={0.6} onPress={onPress}>
                <View style={styles.previewButton}>
                  <PlayIcon size={26} color={'white'} />
                  <Text style={styles.previewButtonLabel}>プレビュー</Text>
                </View>
              </TouchableOpacity>
            </View>
            <ManuscriptClapButton
              manuscript={manuscript}
              disabledLike={disabledLike}
            />
          </View>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(DetailEpisode);

const styles = StyleSheet.create({
  container: {
    marginVertical: 24,
    marginHorizontal: 16,
  } as ViewStyle,
  image: {
    backgroundColor: '#d8d8d8',
    width: 343,
    height: 215,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  imageText: {
    color: '#999',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 16,
  } as TextStyle,
  penName: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  previewButton: {
    width: 311,
    height: 46,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#383838',
    borderRadius: 26,
  } as ViewStyle,
  previewButtonLabel: {
    marginLeft: 6,
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
});
