import * as React from 'react';
import {Platform, StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import AddFrequentlySymbolButton from './AddFrequentlySymbolButton';

const symbols = ['・・・', '──', '!!', '!?', '（）', '『』', '””'];

interface Props {
  style?: StyleProp<ViewStyle>;
  buttonStyle?: StyleProp<ViewStyle>;
  onPressSymbol: (symbol: string) => void;
}

export default class AddTextCommandLinksGroup extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {style, buttonStyle, onPressSymbol} = this.props;
    return (
      <View style={[styles.container, style]}>
        <View style={styles.inner}>
          <View style={styles.buttons}>
            {symbols.map(symbol => (
              <AddFrequentlySymbolButton
                key={`${symbol}`}
                style={buttonStyle}
                symbol={symbol}
                onPress={onPressSymbol}
              />
            ))}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  buttons: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  } as ViewStyle,
  container: {
    flex: Platform.select({
      web: undefined,
      default: 1,
    }),
    marginTop: 5,
    paddingHorizontal: 16,
    width: '100%',
  } as ViewStyle,
  inner: {
    height: 44,
    padding: 5,
  } as ViewStyle,
});
