import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import HelpMapper, {HelpData} from '../../entities/mappers/writer/HelpMapper';

import Help from '../../../domain/entities/writer/Help';
import HelpsRepository from '../../../domain/repositories/writer/HelpsRepository';

export default class NetHelpsRepository
  extends NetResourcesRepository<Help, HelpData>
  implements HelpsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter('/api/writer/helps', new HelpMapper()),
    );
  }
}
