import * as React from 'react';
import {
  Platform,
  StyleProp,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {colors, formStyles} from '../../../styles/variables';

interface Props {
  containerStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<TextStyle>;
  placeholder?: string;
  value: string;
  onChangeText: (text: string) => void;
  onBlur?: () => void;
}

export default class TextArea extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {containerStyle, style, placeholder, value, onChangeText, onBlur} =
      this.props;
    return (
      <View style={[formStyles.formFieldTextAreaWrapper, containerStyle]}>
        <TextInput
          style={[formStyles.textArea, style]}
          multiline={true}
          placeholder={placeholder}
          value={value}
          placeholderTextColor={colors.lightGray}
          autoFocus={Platform.select({web: undefined, default: false})}
          onChangeText={onChangeText}
          onBlur={onBlur}
        />
      </View>
    );
  }
}
