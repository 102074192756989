import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import ActorCategory from '../../../domain/entities/ActorCategory';

export default function reducer(
  state: {[key: number]: ActorCategory} = {},
  action: AnyAction,
): {[key: number]: ActorCategory} {
  switch (action.type) {
    case Types.IndexActorCategoriesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: ActorCategory}, record: ActorCategory) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.ShowActorCategorySuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
