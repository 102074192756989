import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import CharacterPattern from '../../../domain/entities/CharacterPattern';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexCharacterPatternsSuccess: {
      const result = action.payload as PaginatedResult<CharacterPattern>;
      return mergeResponse(state, result);
    }
    case Types.CreateCharacterPatternSuccess:
    case Types.UpdateCharacterPatternUsageHistorySuccess: {
      const characterPattern = action.payload as CharacterPattern;
      const newState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        const filteredIds = response.pageToIds[1].filter(
          id => id !== characterPattern.id,
        );
        if (
          stateKey.includes(`"characterId":${characterPattern.character.id}`)
        ) {
          response.pageToIds[1] = [characterPattern.id, ...filteredIds];
        } else if (
          stateKey.includes(`"storyId":${characterPattern.character.storyId}`)
        ) {
          response.pageToIds[1] = [characterPattern.id, ...filteredIds];
        }
        newState[stateKey] = response;
      });
      return newState;
    }
    default:
      return state;
  }
}
