import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics';

import HelpCategory from '../../../../domain/entities/writer/HelpCategory';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetHelpCategoriesRepository from '../../../../data/repositories/writer/NetHelpCategoriesRepository';

const types = {
  failure: Types.IndexWriterHelpCategoriesFailure,
  request: Types.IndexWriterHelpCategoriesRequest,
  success: Types.IndexWriterHelpCategoriesSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<HelpCategory>> {
  return indexGenerics(new NetHelpCategoriesRepository(), types, params);
}
