import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import shouldUpdateEntity from '../../shared/enhanced/shouldUpdateEntity';
import ArrowForwardIcon from '../../shared/icons/ArrowForwardIcon';

import {size} from '../../../styles/variables';

import HelpCategory from '../../../../domain/entities/writer/HelpCategory';

interface Props {
  helpCategory: HelpCategory;
  onSelectHelpCategory: (helpCategory: HelpCategory) => void;
}

export default class HelpListItem extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return shouldUpdateEntity(
      {entity: this.props.helpCategory},
      {entity: nextProps.helpCategory},
    );
  }

  public render(): React.ReactNode {
    const {helpCategory} = this.props;
    return (
      <Pressable onPress={this.handlePress}>
        <View style={styles.container}>
          <View style={styles.containerInner}>
            <View style={styles.title}>
              <Text style={styles.titleText}>{helpCategory.name}</Text>
            </View>
            <View>
              <ArrowForwardIcon />
            </View>
          </View>
        </View>
      </Pressable>
    );
  }

  private handlePress = () => {
    const {helpCategory, onSelectHelpCategory} = this.props;
    onSelectHelpCategory(helpCategory);
  };
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    justifyContent: 'center',
    minHeight: size.minTapHeight,
  } as ViewStyle,
  containerInner: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  } as ViewStyle,
  title: {
    flex: 1,
    marginHorizontal: 5,
  } as ViewStyle,
  titleText: {
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
