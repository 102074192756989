import * as React from 'react';

import NavigationProp from '../../../../../navigators/NavigationProp';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Form, {FormParams} from './partials/Form';
import Preview from './partials/Preview';

import Layout from '../../../Layout';

import ActorCharacter from '../../../../../../domain/entities/ActorCharacter';

export type FormParams = FormParams;

export interface Params {
  actorCharacterId: number;
  inverted?: boolean;
}

interface Props {
  navigation: NavigationProp;
  route: any;
  actorCharacter: ActorCharacter | null;
  loading?: boolean;
  onSubmit: (params: FormParams) => void;
  showActorCharacter: (id: number) => Promise<ActorCharacter>;
}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {route, showActorCharacter} = this.props;
    const {actorCharacterId} = route.params;
    showActorCharacter(actorCharacterId);
  }

  public render(): React.ReactNode {
    const {navigation, route, actorCharacter, loading, onSubmit} = this.props;
    const {inverted} = route.params;
    return (
      <Layout
        title={'キャラクター名'}
        navigation={navigation}
        back={true}
        close={true}
        loading={loading}>
        <Preview actorCharacter={actorCharacter} inverted={inverted} />
        <Form onSubmit={onSubmit} />
      </Layout>
    );
  }
}
