import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/effect_show_scene_command/new/base_effects/Index';

import AppState from '../../../../../reducers/AppState';

import showEffectCategory from '../../../../../actions/effect_categories/show';
import updateEffectUsageHistory, {
  Params as EffectUsageHistoryUpdateParams,
} from '../../../../../actions/effect_usage_histories/update';
import indexBaseEffects, {
  Params as BaseEffectIndexParams,
} from '../../../../../actions/base_effects/index';
import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../../../../actions/scene_command_forms/create';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';
import selectEntities, {
  getTotalCount,
} from '../../../../../helpers/selectEntities';
import selectEntity from '../../../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../../../helpers/ResourceAuthorizattion';

import {SceneFormEffectShowSceneCommandNewBaseEffectsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandNewBaseEffectsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {query, effectCategoryId, parentSceneCommandId, sort, favorite} =
    route.params;
  const sceneForm = forms.sceneForm;
  const baseEffectsParams: BaseEffectIndexParams = {
    query,
    allEffectCategoryId: effectCategoryId,
    sort: sort || 'popularity',
    exceptBackground: true,
    favorite,
  };
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  const effectCategory = effectCategoryId
    ? selectEntity(entities.effectCategories, effectCategoryId)
    : null;
  const baseEffects = selectEntities(
    entities.baseEffects,
    queries.baseEffects,
    baseEffectsParams,
  );
  const totalCount = getTotalCount(queries.baseEffects, baseEffectsParams);
  const baseEffectQueries = queries.baseEffects;
  return {
    sceneForm,
    effectCategory,
    baseEffects,
    baseEffectsParams,
    navigation,
    parentSceneCommandForm,
    baseEffectQueries,
    totalCount,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
    indexBaseEffects: (params: BaseEffectIndexParams) =>
      dispatch(indexBaseEffects(params)),
    showEffectCategory: (id: number) => dispatch(showEffectCategory(id)),
    updateEffectUsageHistory: (
      id: number,
      params: EffectUsageHistoryUpdateParams,
    ) => dispatch(updateEffectUsageHistory(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
