import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Edit, {
  DispatchProps,
  StateProps,
} from '../../../components/writer/users/Edit';

import AppState from '../../../reducers/AppState';

import showCurrentUser from '../../../actions/writer/current_user/show';
import updateUser, {
  Params as UserUpdateParams,
} from '../../../actions/writer/users/registration/update';

import selectEntity from '../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  return {
    currentUser,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUser: () => dispatch(showCurrentUser()),
    updateUser: (params: UserUpdateParams) => dispatch(updateUser(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
