import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import NetScenesRepository from '../../../data/repositories/writer/NetScenesRepository';

export default function action(
  id: number,
): (dispatch: Dispatch<any>) => Promise<number> {
  return async (dispatch: Dispatch<any>): Promise<number> => {
    try {
      await new NetScenesRepository().destroy(id);
      const payload = id;
      dispatch({payload, type: Types.DestroySceneSuccess});
      sendDefaultCustomEvent({
        resourceType: 'scene',
        resourceId: id,
        actionName: 'destroy',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.DestroySceneFailure});
      throw err;
    }
  };
}
