import EntityMapper from '../EntityMapper';

import ActorHairColor from '../../../../domain/entities/character_maker/ActorHairColor';

export interface ActorHairColorData {
  id: number;
  actor_id: number;
  name: string;
  color_pattern_id: number;
  color_pattern_name: string;
  original_image_url: string;
  no_hair_color: boolean;
}

export default class ActorHairColorMapper
  implements EntityMapper<ActorHairColorData, ActorHairColor>
{
  public map(obj: ActorHairColorData): ActorHairColor {
    return new ActorHairColor(
      obj.id,
      obj.actor_id,
      obj.name,
      obj.color_pattern_id,
      obj.color_pattern_name,
      obj.original_image_url,
      obj.no_hair_color,
    );
  }
}
