import * as React from 'react';

import BaseIndex from '../../../../shared/generics/character_show_or_update_scene_command/character_positions/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterPositionsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import ActorCharacterFace from '../../../../../../domain/entities/ActorCharacterFace';
import CharacterPattern from '../../../../../../domain/entities/CharacterPattern';
import Mark from '../../../../../../domain/entities/Mark';
import CharacterShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import Position from '../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  episodeId?: number;
  sceneId?: number;
  characterPatternId: number;
  actorCharacterFaceId: number;
  markId: number | null;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterPositionsRouteProp;
  sceneForm: SceneForm | null;
  characterPattern: CharacterPattern | null;
  actorCharacterFace: ActorCharacterFace | null;
  mark: Mark | null;
  parentSceneCommandForm: SceneCommandForm | null;
  sceneCommandForms: SceneCommandForm[] | null;
}

export interface DispatchProps {
  showCharacterPattern: (id: number) => Promise<CharacterPattern>;
  showActorCharacterFace: (id: number) => Promise<ActorCharacterFace>;
  showMark: (id: number) => Promise<Mark>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateCharacterPatternUsageHistory: (id: number) => Promise<CharacterPattern>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  private disabledSubmit = false;

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return (
      <BaseIndex {...this.props} onSelectPosition={this.handleSelectPosition} />
    );
  }

  private handleSelectPosition = (position: Position) => {
    const {
      route,
      navigation,
      actorCharacterFace,
      characterPattern,
      mark,
      sceneCommandForms,
      parentSceneCommandForm,
      updateCharacterPatternUsageHistory,
      createSceneCommandForm,
      updateSceneCommandForm,
    } = this.props;
    const {markId, sceneCommandIndex, subSceneCommandIndex} = route.params;
    if (
      !actorCharacterFace ||
      !characterPattern ||
      (markId && (!mark || mark.id !== markId))
    ) {
      return null;
    }
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const existsSceneCommandFormAtIndex =
      sceneCommandForms &&
      sceneCommandIndex !== undefined &&
      !!sceneCommandForms[sceneCommandIndex];
    const sceneCommandForm = new CharacterShowSceneCommandForm(
      characterPattern,
      actorCharacterFace,
      markId ? mark : null,
      position,
      sceneCommandIndex === 0
        ? true
        : sceneCommandIndex !== 1 || existsSceneCommandFormAtIndex
        ? undefined
        : true,
    );
    if (parentSceneCommandForm) {
      const compositeSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.add(
          parentSceneCommandForm,
          sceneCommandForm,
          subSceneCommandIndex !== undefined
            ? {insertIndex: subSceneCommandIndex}
            : undefined,
        );
      updateSceneCommandForm({
        sceneCommandForm: compositeSceneCommandForm,
      });
    } else {
      const index = sceneCommandIndex;
      updateCharacterPatternUsageHistory(characterPattern.id);
      createSceneCommandForm({sceneCommandForm, index});
    }
    (navigation.getParent() || navigation).goBack();
  };
}
