import * as React from 'react';
import {
  Linking,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';
import ArrowDown from '../../../shared/ArrowDown';
import ArrowUp from '../../../shared/ArrowUp';

import OfferManuscript from '../../../../../domain/entities/writers_gate/OfferManuscript';

interface Props {
  manuscript: OfferManuscript;
  disabledLike?: boolean;
}

const DetailPlot: React.FC<Props> = props => {
  const {manuscript, disabledLike} = props;
  const [folded, setFolded] = React.useState(true);
  const onPress = React.useCallback(() => {
    if (manuscript.documentUrl) {
      Linking.openURL(manuscript.documentUrl);
    }
  }, []);
  const togglePlot = React.useCallback(() => {
    setFolded(!folded);
  }, [folded]);
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.title}>エピソードタイトル</Text>
        <Text style={styles.content}>{manuscript.title}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>プロット</Text>
        <Text style={styles.content}>
          {manuscript.plot && manuscript.plot.length > 200 && folded
            ? manuscript.plot.substring(0, 200)
            : manuscript.plot}
        </Text>
        {manuscript.plot && manuscript.plot.length > 200 ? (
          <View style={{justifyContent: 'center', alignItems: 'center'}}>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onPress={togglePlot}>
              {folded ? <ArrowDown /> : <ArrowUp />}
              <Text style={{marginLeft: 10, color: '#999', fontSize: 12}}>
                {folded ? 'すべて表示' : '戻す'}
              </Text>
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
      {manuscript.notes ? (
        <View style={styles.section}>
          <Text style={styles.title}>備考</Text>
          <Text style={styles.content}>{manuscript.notes}</Text>
        </View>
      ) : null}
      {manuscript.documentUrl ? (
        <View style={styles.section}>
          <Text style={styles.title}>ファイル</Text>
          <Text style={styles.content}>
            <View style={styles.pickerWrapper}>
              <DefaultOutlineButton style={{width: 240}} onPress={onPress}>
                ファイルを開く
              </DefaultOutlineButton>
            </View>
          </Text>
        </View>
      ) : null}
      {manuscript.modification ? (
        <View style={styles.section}>
          <Text style={styles.title}>前回からの修正点</Text>
          <Text style={styles.content}>{manuscript.modification}</Text>
        </View>
      ) : null}
    </View>
  );
};

export default React.memo(DetailPlot);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 16,
  } as ViewStyle,
  section: {
    marginVertical: 16,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
  content: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
  pickerWrapper: {
    width: '100%',
    height: 78,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
});
