import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import HelpCategoryListItem from './HelpCategoryListItem';

import shouldUpdateEntityList from '../../shared/enhanced/shouldUpdateEntityList';
import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import HelpCategory from '../../../../domain/entities/writer/HelpCategory';

interface Props {
  helpCategories: HelpCategory[];
  onSelectHelpCategory: (helpCategory: HelpCategory) => void;
}

export default class HelpList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return shouldUpdateEntityList(
      {entities: this.props.helpCategories},
      {entities: nextProps.helpCategories},
    );
  }

  public render(): React.ReactNode {
    const {helpCategories} = this.props;
    return (
      <FlatList
        data={helpCategories}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
        ListFooterComponent={ListItemNormalSeparator}
      />
    );
  }

  private keyExtractor = (item: HelpCategory, index: number): string => {
    return `${item.id}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<HelpCategory>,
  ): React.ReactElement<any> | null => {
    const {onSelectHelpCategory} = this.props;
    return (
      <HelpCategoryListItem
        helpCategory={info.item}
        onSelectHelpCategory={onSelectHelpCategory}
      />
    );
  };
}
