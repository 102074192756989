import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/illustration_show_scene_command/new/illustration_search_form/Index';

import AppState from '../../../../../reducers/AppState';

import indexIllustrationCategories, {
  Params as IllustrationCategoryIndexParams,
} from '../../../../../actions/illustration_categories/index';
import indexIllustrations, {
  Params as IllustrationIndexParams,
} from '../../../../../actions/illustrations/index';

import selectEntities from '../../../../../helpers/selectEntities';
import {SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {
    selectedLargeIllustrationCategoryId,
    selectedMiddleIllustrationCategoryId,
  } = route.params;
  const sceneForm = forms.sceneForm;
  const largeIllustrationCategoriesParams: IllustrationCategoryIndexParams = {
    parentId: null,
  };
  const largeIllustrationCategories = selectEntities(
    entities.illustrationCategories,
    queries.illustrationCategories,
    largeIllustrationCategoriesParams,
  );
  const middleIllustrationCategoriesParams: IllustrationCategoryIndexParams = {
    parentId: selectedLargeIllustrationCategoryId || 0,
  };
  const middleIllustrationCategories = selectEntities(
    entities.illustrationCategories,
    queries.illustrationCategories,
    middleIllustrationCategoriesParams,
  );
  const smallIllustrationCategoriesParams: IllustrationCategoryIndexParams = {
    parentId: selectedMiddleIllustrationCategoryId || 0,
  };
  const smallIllustrationCategories = selectEntities(
    entities.illustrationCategories,
    queries.illustrationCategories,
    smallIllustrationCategoriesParams,
  );
  return {
    sceneForm,
    largeIllustrationCategories,
    largeIllustrationCategoriesParams,
    middleIllustrationCategories,
    middleIllustrationCategoriesParams,
    smallIllustrationCategories,
    smallIllustrationCategoriesParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexIllustrationCategories: (params: IllustrationCategoryIndexParams) =>
      dispatch(indexIllustrationCategories(params)),
    indexIllustrations: (params: IllustrationIndexParams) =>
      dispatch(indexIllustrations(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
