import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Edit, {DispatchProps, StateProps} from '../../components/stories/Edit';

import AppState from '../../reducers/AppState';

import showStory from '../../actions/stories/show';
import indexGenres, {
  Params as GenreIndexParams,
} from '../../actions/genres/index';
import indexEpisodes, {
  Params as EpisodeIndexParams,
} from '../../actions/episodes/index';
import createStoryForm, {
  Params as StoryFormCreateParams,
} from '../../actions/story_forms/create';
import updateStoryForm, {
  Params as StoryFormUpdateParams,
} from '../../actions/story_forms/update';
import updateStory, {
  Params as StoryUpdateParams,
} from '../../actions/stories/update';

import selectEntities from '../../helpers/selectEntities';
import selectEntity from '../../helpers/selectEntity';

import {EditStoryRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EditStoryRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {storyId} = route.params || {storyId: undefined};
  const storyForm =
    forms.storyForm && forms.storyForm.id === Number(storyId)
      ? forms.storyForm
      : null;
  const genresParams = {};
  const genres = selectEntities(entities.genres, queries.genres, genresParams);
  const episodesParams: EpisodeIndexParams = {storyId};
  const episodes = selectEntities(
    entities.episodes,
    queries.episodes,
    episodesParams,
  );
  const story = selectEntity(entities.stories, Number(storyId));
  return {
    genres,
    genresParams,
    episodes,
    episodesParams,
    navigation,
    story,
    storyForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showStory: (id: number) => dispatch(showStory(id)),
    indexGenres: (params: GenreIndexParams) => dispatch(indexGenres(params)),
    indexEpisodes: (params: EpisodeIndexParams) =>
      dispatch(indexEpisodes(params)),
    createStoryForm: (params: StoryFormCreateParams) =>
      dispatch(createStoryForm(params)),
    updateStoryForm: (params: StoryFormUpdateParams) =>
      dispatch(updateStoryForm(params)),
    updateStory: (id: number, params: StoryUpdateParams) =>
      dispatch(updateStory(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
