import * as React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {markImageUrl} from '../../../../../../helpers/images';

import Mark from '../../../../../../../domain/entities/Mark';

interface Props {
  style?: StyleProp<ViewStyle>;
  mark: Mark;
  width: number;
  selected: boolean;
  onSelectMark: (mark: Mark) => void;
}

const MarkListItem: React.FunctionComponent<Props> = props => {
  const {style, mark, width, selected, onSelectMark} = props;
  const height = (width * 80) / 112;
  const uri = mark.id !== 0 ? markImageUrl(mark, 'large') : null;
  return (
    <Pressable onPress={() => onSelectMark(mark)}>
      <View style={[style, {width, height}]}>
        <View
          style={[
            styles.imageWrapper,
            selected ? styles.imageWrapperActive : null,
            {width, height},
          ]}>
          {uri ? (
            <Image style={styles.image} resizeMode={'contain'} source={{uri}} />
          ) : (
            <Text style={styles.noImage}>なし</Text>
          )}
        </View>
      </View>
    </Pressable>
  );
};

const ASPECT_RATIO = 1;
const IMAGE_BASE_WIDTH = 40;
const IMAGE_BASE_HEIGHT = IMAGE_BASE_WIDTH / ASPECT_RATIO;

export default React.memo(MarkListItem);

const styles = StyleSheet.create({
  imageWrapper: {
    borderWidth: 2,
    borderRadius: 4,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'transparent',
  } as ViewStyle,
  imageWrapperActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
  image: {
    width: IMAGE_BASE_WIDTH,
    height: IMAGE_BASE_HEIGHT,
  } as ImageStyle,
  noImage: {
    color: '#666666',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
});
