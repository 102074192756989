import * as React from 'react';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

const UserStatusContext = React.createContext<{
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
}>({currentUser: null, currentUserStatus: null});

export default UserStatusContext;
