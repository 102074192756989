import {Dispatch} from 'redux';

import Types from '../../../../Types';

import CurrentUser from '../../../../../../domain/entities/writer/CurrentUser';

import SessionRepository from '../../../../../../data/repositories/writer/social_accounts/facebook_accounts/NetSessionRepository';

import AccessTokenRepository from '../../../../../../data/repositories/StorageAccessTokenRepository';

export interface Params {
  accessToken: string;
  userId: string;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<CurrentUser> {
  return async (dispatch: Dispatch<any>): Promise<CurrentUser> => {
    try {
      const payload = await new SessionRepository().create({
        facebookAccount: params,
      });
      if (payload.accessToken) {
        new AccessTokenRepository().update(payload.accessToken);
      }
      dispatch({payload, type: Types.CreateWriterCurrentUserSuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateWriterCurrentUserFailure});
      throw err;
    }
  };
}
