import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import CharacterList from './partials/CharacterList';

import Layout from '../../shared/Layout';
import CharacterDeleteAlertModal from '../../characters/partials/CharacterDeleteAlertModal';
import CharacterDeleteConfirmModal from '../../characters/partials/CharacterDeleteConfirmModal';
import NewCharacterButton from '../../characters/partials/NewCharacterButton';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectCharactersRouteProp} from '../../../navigators/RouteProps';

import AlertModal from '../../shared/modals/AlertModal';

import {Params as CharacterUpdateParams} from '../../../actions/characters/update';
import {formatErrorMessages} from '../../../helpers/errorMessages';

import Character from '../../../../domain/entities/Character';
import Project from '../../../../domain/entities/Project';
import ProjectUserStatus from '../../../../domain/entities/ProjectUserStatus';

import NetProjectCharactersRepository from '../../../../data/repositories/writer/NetProjectCharactersRepository';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectCharactersRouteProp;
}

export interface DispatchProps {
  updateCharacter: (
    id: number,
    params: CharacterUpdateParams,
  ) => Promise<Character>;
  destroyCharacter: (id: number) => Promise<number>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {projectId} = route.params;
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [
    visibleCharacterDeleteAlertModal,
    setVisibleCharacterDeleteAlertModal,
  ] = React.useState(false);
  const [deleteCharacter, setDeleteCharacter] =
    React.useState<Character | null>(null);
  const [userStatus, setUserStatus] = React.useState<ProjectUserStatus | null>(
    null,
  );
  const [project, setProject] = React.useState<Project | null>(null);
  const [characters, setCharacters] = React.useState<Character[] | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelTypedRestApi.get<Project>(
        `/api/writer/projects/${projectId}`,
      ).then(result => {
        setProject(result.body);
        new NetProjectCharactersRepository()
          .findBy({projectId})
          .then(result => {
            setCharacters(result.records);
          });
      });
    }, []),
  );
  React.useEffect(() => {
    TapNovelTypedRestApi.get<ProjectUserStatus>(
      '/api/writer/project_user_status',
      {projectId},
    ).then(result => {
      setUserStatus(result.body);
    });
  }, []);
  const onSelectCharacter = (character: Character) => {
    const characterId = character.id;
    navigation.navigate('ProjectCharacter', {characterId, projectId});
  };
  const onForwardToNewCharacter = React.useCallback(() => {
    if (!project) {
      return;
    }
    navigation.navigate('NewProjectCharacter', {
      projectId,
      storyId: project.story.id,
    });
  }, [project]);
  const onDeleteCharacter = (character: Character) => {
    const characterId = character.id;
    TapNovelTypedRestApi.get(
      `/api/writer/scene_character_patterns?character_id=${characterId}`,
    ).then((res: any) => {
      if (res.body.length > 0) {
        setVisibleCharacterDeleteAlertModal(true);
      } else {
        setDeleteCharacter(character);
      }
    });
  };
  const onChangeOrder = React.useCallback(
    (character: Character, rowOrderPosition: number) => {
      const {updateCharacter} = props;
      setLoading(true);
      updateCharacter(character.id, {rowOrderPosition, ignoreUpdate: true})
        .then(() => {
          if (!project) {
            return;
          }
          new NetProjectCharactersRepository()
            .findBy({projectId})
            .then(result => {
              setCharacters(result.records);
              setLoading(false);
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch(error => {
          setLoading(false);
          setAlertMessage(formatErrorMessages({}, error));
        });
    },
    [project],
  );
  const onStartSort = React.useCallback(() => {}, []);
  const onEndSort = React.useCallback(() => {}, []);
  const onRequestDeleteCharacter = React.useCallback((character: Character) => {
    const {destroyCharacter} = props;
    destroyCharacter(character.id)
      .then(() => {
        new NetProjectCharactersRepository()
          .findBy({projectId})
          .then(result => {
            setCharacters(result.records);
            setLoading(false);
            onRequestCloseModal();
          })
          .catch(() => {
            setLoading(false);
            onRequestCloseModal();
          });
      })
      .catch(() => {
        setLoading(false);
        onRequestCloseModal();
      });
  }, []);
  const onRequestCloseModal = React.useCallback(() => {
    setAlertMessage(null);
    setVisibleCharacterDeleteAlertModal(false);
    setDeleteCharacter(null);
  }, []);
  return (
    <Layout
      title={'キャラクター一覧'}
      navigation={navigation}
      back={true}
      loading={loading}
      footer={
        userStatus && userStatus.role === 'leader' ? (
          <NewCharacterButton
            label={
              characters && characters.length === 0
                ? '新しいキャラクターをつくる'
                : null
            }
            onForwardToNewCharacter={onForwardToNewCharacter}
          />
        ) : null
      }>
      {characters && (
        <CharacterList
          characters={characters}
          leader={userStatus ? userStatus.role === 'leader' : false}
          currentWriterUserId={userStatus ? userStatus.writerUserId : null}
          onSelectCharacter={onSelectCharacter}
          onDeleteCharacter={onDeleteCharacter}
          onChangeOrder={onChangeOrder}
          onStartSort={onStartSort}
          onEndSort={onEndSort}
        />
      )}
      {deleteCharacter && (
        <CharacterDeleteConfirmModal
          character={deleteCharacter}
          onRequestCloseModal={onRequestCloseModal}
          onRequestDeleteCharacter={onRequestDeleteCharacter}
        />
      )}
      {visibleCharacterDeleteAlertModal && (
        <CharacterDeleteAlertModal onRequestCloseModal={onRequestCloseModal} />
      )}
      <AlertModal visible={!!alertMessage} onCloseModal={onRequestCloseModal}>
        {alertMessage}
      </AlertModal>
    </Layout>
  );
};

export default React.memo(Index);

const characters: Character[] = [];
