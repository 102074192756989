import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import ElasticSpeechBalloon from '../../../../shared/ElasticSpeechBalloon';
import NameLabelColorButtons from '../../../../shared/NameLabelColorButtons';
import DimensionContext from '../../../../shared/dimension/DimensionContext';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';
import TooltipModal from '../../../../shared/modals/TooltipModal';
import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import EditableLaterMessage from '../../../../shared/EditableLaterMessage';

import {formStyles} from '../../../../../styles/variables';

import CharacterForm from '../../../../../../domain/forms/CharacterForm';
import SpeechBalloon from '../../../../../../domain/entities/SpeechBalloon';
import NameLabelColor from '../../../../../../domain/value_objects/NameLabelColor';
import Gender from '../../../../../../domain/value_objects/Gender';

const PLACEHOLDER_TEXT = 'ここにセリフが表示されます';

const TEXT_STYLE = {
  color: '#999',
};

type TooltipModalType = 'name_label_color';

const TypeToTooltipInfo = {
  name_label_color: {
    title: 'ネームラベルの色',
    description: 'キャラクター固有のネームラベルの色を設定できます。',
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
  };
};

interface Props {
  characterForm: CharacterForm;
  speechBalloon: SpeechBalloon;
  gender?: Gender | null;
  onSelectNameLabelColor: (nameLabelColor: NameLabelColor) => void;
  onSubmit: () => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {
    characterForm,
    speechBalloon,
    gender,
    onSelectNameLabelColor,
    onSubmit,
  } = props;
  const [modalInfoType, setModalInfoType] =
    React.useState<TooltipModalType | null>(null);
  const onPressQuestion = React.useCallback(() => {
    setModalInfoType('name_label_color');
  }, []);
  const onCloseModal = React.useCallback(() => {
    setModalInfoType(null);
  }, []);
  const modalInfo = modalInfoType ? TypeToTooltipInfo[modalInfoType] : null;
  const nameLabelColor =
    characterForm.nameLabelColor || getDefaultNameLabelColor(gender);
  return (
    <>
      <View style={styles.container}>
        <DimensionContext.Consumer>
          {context => {
            return (
              <ElasticSpeechBalloon
                text={PLACEHOLDER_TEXT}
                name={characterForm.name || ''}
                orientedSpeechBalloon={speechBalloon}
                width={context.content.width}
                textStyle={TEXT_STYLE}
                nameLabelColor={nameLabelColor}
              />
            );
          }}
        </DimensionContext.Consumer>
      </View>
      <View style={styles.border} />
      <View style={styles.formGroup}>
        <LabelWithOption
          title={'ネームラベルの色'}
          onPressQuestion={onPressQuestion}
        />
        <NameLabelColorButtons
          nameLabelColor={nameLabelColor}
          onSelectNameLabelColor={onSelectNameLabelColor}
        />
      </View>
      <View style={styles.button}>
        <EditableLaterMessage />
        <PrimaryButton onPress={onSubmit}>決定</PrimaryButton>
      </View>
      {modalInfo ? (
        <TooltipModal
          visible={true}
          title={modalInfo.title}
          description={modalInfo.description}
          onCloseModal={onCloseModal}></TooltipModal>
      ) : null}
    </>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    paddingVertical: 8,
  } as ViewStyle,
  border: {
    backgroundColor: '#efefef',
    height: 1,
    width: '100%',
  } as ViewStyle,
  formGroup: StyleSheet.flatten([
    formStyles.formGroup,
    {
      marginTop: 16,
      marginBottom: 22,
    },
  ]) as ViewStyle,
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
});

const getDefaultNameLabelColor = (
  gender: Gender | undefined | null,
): NameLabelColor | undefined => {
  switch (gender) {
    case Gender.Male:
      return NameLabelColor.Blue;
    case Gender.Female:
      return NameLabelColor.Pink;
    case Gender.Other:
      return NameLabelColor.Green;
    default:
    // noop
  }
};
