import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import Tab from './Tab';

export type Value = 'costume' | 'hair_style' | 'actor_accessory';

interface Props {
  activeTab: Value;
  visibleActorCostume: boolean;
  visibleActorHairStyle: boolean;
  visibleActorAccessory: boolean;
  onChangeTab: (value: Value) => void;
}

const Tabs: React.FunctionComponent<Props> = props => {
  const {
    activeTab,
    visibleActorCostume,
    visibleActorHairStyle,
    visibleActorAccessory,
    onChangeTab,
  } = props;
  return (
    <View style={styles.tabs}>
      {(visibleActorCostume ||
        (!visibleActorCostume &&
          !visibleActorHairStyle &&
          !visibleActorAccessory)) && (
        <Tab
          value={'costume'}
          label={'衣装'}
          isActive={activeTab === 'costume'}
          onChangeTab={onChangeTab}
        />
      )}
      {visibleActorHairStyle && (
        <Tab
          value={'hair_style'}
          label={'髪色'}
          isActive={activeTab === 'hair_style'}
          onChangeTab={onChangeTab}
        />
      )}
      {visibleActorAccessory && (
        <Tab
          value={'actor_accessory'}
          label={'アクセサリー'}
          isActive={activeTab === 'actor_accessory'}
          onChangeTab={onChangeTab}
        />
      )}
    </View>
  );
};

export default React.memo(Tabs);

const styles = StyleSheet.create({
  tabs: {
    height: 41,
    flexDirection: 'row',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    alignItems: 'center',
    padding: 16,
    backgroundColor: 'white',
  } as ViewStyle,
});
