import Recorder from 'recorder-js';
import dateformat from 'dateformat';

import {audioContext} from '../../../vendor/react-native-tapnovel-viewer/presentation/view_models/AudioContextInitializer.web';

export default class SoundRecorder {
  private recorder: Recorder | null = null;

  private mediaStream: MediaStream | null = null;

  private static permittedMicrophone = false;

  public static async permitted() {
    if (this.permittedMicrophone) {
      return true;
    }
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      mediaStream.getTracks().forEach(track => track.stop());
      this.permittedMicrophone = true;
      return true;
    } catch (e) {
      return false;
    }
  }

  public async start(options?: any): Promise<void> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      throw new Error('HTTPでは録音を使用できません。');
    }
    if (!audioContext) {
      throw new Error('オーディオコンテキストが初期化されていません。');
    }
    this.recorder = new Recorder(audioContext);
    this.mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    this.recorder.init(this.mediaStream);
    return await this.recorder.start().then(() => {});
  }

  public async stop(): Promise<File> {
    return new Promise((resolve, reject) => {
      if (!this.recorder) {
        return;
      }
      this.recorder
        .stop()
        .then(({blob}) => {
          if (this.mediaStream) {
            this.mediaStream.getAudioTracks().forEach(track => {
              track.stop();
            });
            resolve(
              new File(
                [blob],
                `voice-${dateformat(new Date(), 'yyyymmdd-HHMM')}.wav`,
                {type: 'audio/wav'},
              ),
            );
          }
        })
        .catch(() => {});
    });
  }

  public pause(): Promise<void> {
    return new Promise(() => {});
  }

  public resume(): Promise<void> {
    return new Promise(() => {});
  }
}
