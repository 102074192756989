import * as React from 'react';

import Header from './partials/Header';
import EpisodeList from './partials/EpisodeList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectEpisodesRouteProp} from '../../../navigators/RouteProps';

import {Params as EpisodePublicationUpdateParams} from '../../../actions/episode_publications/update';

import Episode from '../../../../domain/entities/Episode';
import ProjectUserStatus from '../../../../domain/entities/ProjectUserStatus';

import NetEpisodesRepository from '../../../../data/repositories/writer/NetEpisodesRepository';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectEpisodesRouteProp;
}

export interface DispatchProps {
  updateEpisodePublication: (
    id: number,
    params: EpisodePublicationUpdateParams,
  ) => Promise<Episode>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route, updateEpisodePublication} = props;
  const {projectId} = route.params;
  const [episodes, setEpisodes] = React.useState<Episode[] | null>(null);
  const [totalCount, setTotalCount] = React.useState<number | null>(null);
  const [bestEpisodeId, setBestEpisodeId] = React.useState<
    number | null | undefined
  >(undefined);
  const [sort, setSort] = React.useState<'first' | 'last'>('first');
  const [userStatus, setUserStatus] = React.useState<ProjectUserStatus | null>(
    null,
  );
  const fetchEpisodes = React.useCallback(() => {
    new NetEpisodesRepository().findBy({projectId, sort}).then(result => {
      setEpisodes(result.records);
      setTotalCount(result.total);
    });
  }, [sort]);
  const fetchStoryBestEpisode = React.useCallback(() => {
    TapNovelTypedRestApi.get<any>('/api/writer/story_best_episode', {
      projectId,
    })
      .then(result => {
        setBestEpisodeId(result.body.episodeId);
      })
      .catch(() => {
        setBestEpisodeId(null);
      });
    TapNovelTypedRestApi.get<ProjectUserStatus>(
      '/api/writer/project_user_status',
      {projectId},
    ).then(result => {
      setUserStatus(result.body);
    });
  }, []);
  React.useEffect(fetchEpisodes, [sort]);
  React.useEffect(fetchStoryBestEpisode, []);
  const onUpdateEpisode = React.useCallback(
    (episode: Episode) => {
      updateEpisodePublication(episode.id, {published: false}).then(() => {
        fetchEpisodes();
      });
    },
    [sort],
  );
  const onCreateStoryBestEpisode = React.useCallback((episode: Episode) => {
    TapNovelTypedRestApi.post<any>('/api/writer/story_best_episode', {
      storyId: episode.storyId,
      episodeId: episode.id,
    })
      .then(result => {
        setBestEpisodeId(result.body.episodeId);
      })
      .catch(() => {
        setBestEpisodeId(null);
      });
  }, []);
  const onDestroyStoryBestEpisode = React.useCallback((episode: Episode) => {
    TapNovelTypedRestApi.delete('/api/writer/story_best_episode', {
      storyId: episode.storyId,
    })
      .then(result => {
        setBestEpisodeId(null);
      })
      .catch(() => {});
  }, []);
  return (
    <Layout
      title={'エピソード一覧'}
      navigation={navigation}
      scrollable={false}
      back={true}>
      {episodes &&
      userStatus &&
      totalCount != null &&
      bestEpisodeId !== undefined ? (
        <EpisodeList
          episodes={episodes}
          userStatus={userStatus}
          ListHeaderComponent={
            <Header
              totalCount={totalCount}
              sort={sort}
              onChangeSort={setSort}
            />
          }
          bestEpisodeId={bestEpisodeId}
          onUpdateEpisode={onUpdateEpisode}
          onCreateStoryBestEpisode={onCreateStoryBestEpisode}
          onDestroyStoryBestEpisode={onDestroyStoryBestEpisode}
        />
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
