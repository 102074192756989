import * as React from 'react';

import SearchResultHeader from '../../../shared/scene_form/SearchResultHeader';
import TotalCountWithSort from './TotalCountWithSort';
import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

interface Props {
  totalCount: number;
  sort?: 'first' | 'last';
  onChangeSort: (sort: 'first' | 'last') => void;
}

const Header: React.FC<Props> = props => {
  const {totalCount, sort, onChangeSort} = props;
  return (
    <>
      <SearchResultHeader title={'エピソード一覧'} />
      <TotalCountWithSort
        style={{paddingHorizontal: 16}}
        totalCount={totalCount}
        sort={sort}
        onPress={onChangeSort}
      />
      <ListItemNormalSeparator />
    </>
  );
};

export default React.memo(Header);
