import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ActorAccessoryMapper, {
  ActorAccessoryData,
} from '../../entities/mappers/ActorAccessoryMapper';

import ActorAccessory from '../../../domain/entities/ActorAccessory';
import ActorAccessoriesRepository from '../../../domain/repositories/writer/ActorAccessoriesRepository';

export default class NetActorAccessoriesRepository
  extends NetResourcesRepository<ActorAccessory, ActorAccessoryData>
  implements ActorAccessoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/actor_accessories',
        new ActorAccessoryMapper(),
      ),
    );
  }
}
