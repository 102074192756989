import dateformat from 'dateformat';

export type TermRange =
  | 'all'
  | 'today'
  | 'yesterday'
  | 'last_week'
  | 'last_month'
  | 'last_7days'
  | 'last_30days';

export function convertTermRangeToQueryParams(termRange: TermRange): {
  from?: Date;
  to?: Date;
} {
  const now = new Date();
  switch (termRange) {
    case 'all':
      return {};
    case 'today': {
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      return {from: today};
    }
    case 'yesterday': {
      const yesterday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 1,
      );
      return {from: yesterday, to: yesterday};
    }
    case 'last_week': {
      const oneWeekAgo = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() - 7,
      );
      const from = new Date(
        oneWeekAgo.getFullYear(),
        oneWeekAgo.getMonth(),
        oneWeekAgo.getDate() - oneWeekAgo.getDay(),
      );
      const to = new Date(
        from.getFullYear(),
        from.getMonth(),
        from.getDate() + 6,
      );
      return {from, to};
    }
    case 'last_month': {
      const from = new Date(now.getFullYear(), now.getMonth() - 1, 1);
      const to = new Date(now.getFullYear(), now.getMonth(), -1);
      return {from, to};
    }
    case 'last_7days': {
      const to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      const from = new Date(to.getFullYear(), to.getMonth(), to.getDate() - 6);
      return {from, to};
    }
    case 'last_30days': {
      const to = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
      const from = new Date(to.getFullYear(), to.getMonth(), to.getDate() - 29);
      return {from, to};
    }
  }
}

export function inTermRange(termRange: TermRange, version: number) {
  const {from, to} = convertTermRangeToQueryParams(termRange);
  const fromVersion = convertDateToVersionStyleNumber(from);
  const toVersion = convertDateToVersionStyleNumber(to);
  if (toVersion) {
    if (toVersion < version) {
      return false;
    }
  }
  return true;
}

export function convertDateToVersionStyleNumber(date: Date | undefined) {
  if (!date) {
    return null;
  }
  return Number(`${dateformat(date, 'yyyymmddHHMMss')}`);
}
