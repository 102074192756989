import * as React from 'react';
import {Dimensions, ScaledSize} from 'react-native';

import getContentDimension from '../../../helpers/getContentDimension';

const window = Dimensions.get('window');
const content = getContentDimension(window);

const DimensionContext = React.createContext<{
  window: ScaledSize;
  content: {width: number; height: number};
}>({window, content});

export default DimensionContext;
