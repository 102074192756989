import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import NewSceneButton from '../../scenes/partials/NewSceneButton';

interface Props {
  disabled?: boolean;
  onForwardToNewScene: () => void;
}

const Footer: React.FunctionComponent<Props> = props => {
  const {disabled, onForwardToNewScene} = props;
  return (
    <View style={styles.container}>
      <NewSceneButton
        disabled={disabled}
        onForwardToNewScene={onForwardToNewScene}
      />
    </View>
  );
};

export default React.memo(Footer);

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    alignItems: 'center',
  } as ViewStyle,
});
