import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import CheckBoxList from '../../../forms/CheckBoxList';

interface Item<T> {
  label: string;
  value: T;
}

interface Props<T> {
  title: string;
  items: Array<Item<T>>;
  values?: Array<T>;
  onChangeValues: (values: Array<T>) => void;
}

const CheckBoxListSection = <T,>(props: Props<T>) => {
  const {title, items, values, onChangeValues} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <CheckBoxList
        items={items}
        values={values}
        onChangeValues={onChangeValues}
      />
    </View>
  );
};

export default React.memo(CheckBoxListSection) as typeof CheckBoxListSection;

const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
  } as ViewStyle,
  title: {
    marginHorizontal: 16,
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
});
