import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import ProHome from '../../../containers/pro/home/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="ProHome">
      <RootStack.Screen
        name="ProHome"
        component={ProHome}
        options={{
          title: 'MakerPro',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
