import {Dispatch} from 'redux';

import Types from '../Types';

import Voice from '../../../domain/entities/Voice';
import File from '../../../domain/entities/File';

import NetVoicesRepository from '../../../data/repositories/writer/NetVoicesRepository';

export interface Params {
  storyId: number;
  audio: File | null;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Voice> {
  return async (dispatch: Dispatch<any>): Promise<Voice> => {
    try {
      const payload = await new NetVoicesRepository().create({voice: params});
      dispatch({payload, type: Types.CreateVoiceSuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateVoiceFailure});
      throw err;
    }
  };
}
