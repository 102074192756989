export default class EpisodeAggregation {
  constructor(
    public id: number,
    public from: Date | null,
    public to: Date | null,
    public title: string,
    public version: number,
    public tapCount: number,
    public episodePageViewCount: number,
    public episodePageViewUniqueConsumerUserCount: number,
    public finishedReadingEpisodePageViewCount: number,
    public finishedReadingEpisodePageViewUniqueConsumerUserCount: number,
    public finishedReadingRate: number,
    public genreAverageFinishedReadingRate: number,
    public totalSpeechTextLength: number,
    public totalDescriptiveTextLength: number,
  ) {}

  public getTotalTextLength(): number {
    return this.totalSpeechTextLength + this.totalDescriptiveTextLength;
  }

  public getSpeechTextLengthRate(): number {
    return this.totalSpeechTextLength / this.getTotalTextLength();
  }
}
