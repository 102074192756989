import IllustrationMapper, {
  IllustrationData,
} from '../../../entities/mappers/IllustrationMapper';

import SoundMapper, {SoundData} from '../../../entities/mappers/SoundMapper';

import IllustrationShowSceneCommand from '../../../../domain/value_objects/scene_commands/IllustrationShowSceneCommand';

export interface IllustrationShowSceneCommandData {
  type: string;
  illustration: IllustrationData;
  sound?: SoundData | null;
  start_time?: number;
  end_time?: number;
}

export default class IllustrationShowSceneCommandMapper {
  private illustrationMapper: IllustrationMapper;
  private soundMapper: SoundMapper;

  constructor() {
    this.illustrationMapper = new IllustrationMapper();
    this.soundMapper = new SoundMapper();
  }

  public map(
    obj: IllustrationShowSceneCommandData,
  ): IllustrationShowSceneCommand {
    const illustration = this.illustrationMapper.map(obj.illustration);
    const sound = obj.sound ? this.soundMapper.map(obj.sound) : null;
    return new IllustrationShowSceneCommand(
      illustration,
      sound,
      obj.start_time,
      obj.end_time,
    );
  }
}
