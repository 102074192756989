import * as React from 'react';

import Layout from '../../../../shared/Layout';
import SoundSearchResult from '../../../../shared/scene_form/SoundSearchResult';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormBackgroundMusicShowSceneCommandNewSoundsRouteProp,
  SceneFormBackgroundMusicShowSceneCommandEditSoundsRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as SoundIndexParams} from '../../../../../actions/sounds/index';

import {getNextPage} from '../../../../../helpers/selectEntities';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import {QueryState} from '../../../../../reducers/queries/Response';

import Sound from '../../../../../../domain/entities/Sound';
import SoundCategory from '../../../../../../domain/entities/SoundCategory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import UserResourceFavorite from '../../../../../../domain/entities/writer/UserResourceFavorite';
import NetUserResourceFavoritesRepository from '../../../../../../data/repositories/writer/NetUserResourceFavoritesRepository';

export interface Params {
  episodeId?: number;
  sceneId?: number;
  soundCategoryId: number;
  selectedLargeSoundCategoryId?: number | null;
  selectedMiddleSoundCategoryId?: number | null;
  selectedSmallSoundCategoryId?: number | null;
  favorite?: boolean;
  sort?: 'popularity' | 'new_arrival';
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormBackgroundMusicShowSceneCommandNewSoundsRouteProp
    | SceneFormBackgroundMusicShowSceneCommandEditSoundsRouteProp;
  sceneForm: SceneForm | null;
  soundsParams: SoundIndexParams;
  sounds: Sound[] | null;
  soundCategory: SoundCategory | null;
  soundQueries: QueryState;
  totalCount: number | null;
  sort?: 'popularity' | 'new_arrival';
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexSounds: (params: SoundIndexParams) => Promise<PaginatedResult<Sound>>;
  showSoundCategory: (id: number) => Promise<SoundCategory>;
  onSelectSound: (sound: Sound) => void;
  onPressSearchConditionChangeButton: () => void;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  userResourceFavorites: UserResourceFavorite[] | null;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userResourceFavorites: null,
    };
  }

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    this.fetchEntities();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {route} = this.props;
    const {route: prevRoute} = prevProps;
    const {query, soundCategoryId, sort} = route.params;
    const {
      query: prevQuery,
      soundCategoryId: prevSoundCategoryId,
      sort: prevSort,
    } = prevRoute.params;
    if (
      query === prevQuery &&
      soundCategoryId === prevSoundCategoryId &&
      sort === prevSort
    ) {
      return;
    }
    this.fetchEntities();
  }

  public render(): React.ReactNode {
    const {
      navigation,
      sounds,
      sort,
      totalCount,
      route,
      enabledFavorite,
      onSelectSound,
      onPressSearchConditionChangeButton,
    } = this.props;
    const {favorite} = route.params;
    const {userResourceFavorites} = this.state;
    return (
      <Layout
        title={`${favorite ? 'お気に入り' : ''}BGM`}
        navigation={navigation}
        back={true}
        close={true}
        scrollable={false}>
        {(!enabledFavorite || (enabledFavorite && userResourceFavorites)) && (
          <SoundSearchResult
            entities={sounds}
            totalCount={totalCount}
            sort={sort}
            title={this.getTitle()}
            favorite={favorite}
            resourceName={'BGM'}
            enabledFavorite={enabledFavorite}
            userResourceFavorites={userResourceFavorites}
            onPressSearchConditionChangeButton={
              onPressSearchConditionChangeButton
            }
            onSelectEntity={onSelectSound}
            onPressSort={this.handlePressSort}
            onEndReached={this.handleEndReached}
          />
        )}
      </Layout>
    );
  }

  private fetchEntities = () => {
    const {
      route,
      soundsParams,
      soundCategory,
      sounds,
      enabledFavorite,
      showSoundCategory,
      indexSounds,
    } = this.props;
    const {soundCategoryId, favorite} = route.params;
    if (enabledFavorite) {
      new NetUserResourceFavoritesRepository()
        .findBy({
          resource_type: 'Sound',
          per_page: 1000,
        })
        .then(res => {
          this.setState({userResourceFavorites: res.records});
        });
    }
    if (!soundCategory && soundCategoryId) {
      showSoundCategory(soundCategoryId);
    }
    if (!sounds || favorite) {
      indexSounds(soundsParams);
    }
  };

  private getTitle = () => {
    const ary: Array<string> = [];
    const {route, soundCategory} = this.props;
    const {query, favorite} = route.params;
    if (favorite) {
      ary.push('お気に入り');
    }
    if (query) {
      ary.push(query);
    }
    if (soundCategory) {
      ary.push(soundCategory.name);
    }
    return ary.join(' / ');
  };

  private handleEndReached = (info: {distanceFromEnd: number}): void => {
    const {soundQueries, soundsParams, indexSounds} = this.props;
    const nextPage = getNextPage(soundQueries, soundsParams);
    if (nextPage) {
      indexSounds({...soundsParams, page: nextPage});
    }
  };

  private handlePressSort = (sort: 'popularity' | 'new_arrival') => {
    const {navigation} = this.props;
    navigation.setParams({sort});
  };
}

const aspectRatio = 112 / 90;
