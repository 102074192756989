import EntityMapper from './EntityMapper';

import ActorCharacter from '../../../domain/entities/ActorCharacter';

export interface ActorCharacterData {
  id: number;
  actor_id: number;
  actor_hair_style_id: number;
  actor_costume_id: number;
  actor_accessory_set_id: number;
  original_image_url: string;
  face_center_position?: {
    top: number;
    left: number;
  };
}

export default class ActorCharacterMapper
  implements EntityMapper<ActorCharacterData, ActorCharacter>
{
  public map(obj: ActorCharacterData): ActorCharacter {
    return new ActorCharacter(
      obj.id,
      obj.actor_id,
      obj.actor_hair_style_id,
      obj.actor_costume_id,
      obj.actor_accessory_set_id,
      obj.original_image_url,
      obj.face_center_position,
    );
  }
}
