import * as React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import CharacterImage from './CharacterImage';

import ActorCharacterFace from '../../../../domain/entities/ActorCharacterFace';

interface Props {
  actorCharacterFace?: ActorCharacterFace | null;
  onForwardCharacterPatterns: () => void;
}

const CharacterImageFormField: React.FunctionComponent<Props> = props => {
  const {actorCharacterFace, onForwardCharacterPatterns} = props;
  return (
    <Pressable onPress={onForwardCharacterPatterns}>
      <View
        style={[
          styles.container,
          actorCharacterFace ? styles.containerActive : null,
        ]}>
        <CharacterImage
          actorCharacterFace={actorCharacterFace}
          withIcon={true}
        />
      </View>
    </Pressable>
  );
};

export default React.memo(CharacterImageFormField);

const IMAGE_SIZE = 112;
const IMAGE_ZOOM = 2;

const styles = StyleSheet.create({
  container: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: IMAGE_SIZE / IMAGE_ZOOM,
    alignItems: 'center',
    backgroundColor: '#efefef',
  } as ViewStyle,
  containerActive: {
    backgroundColor: '#ffffff',
  } as ViewStyle,
  imageWrapper: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
    borderRadius: IMAGE_SIZE / IMAGE_ZOOM,
    borderWidth: 1,
    borderColor: '#efefef',
    overflow: 'hidden',
    position: 'absolute',
  } as ViewStyle,
  image: {
    width: IMAGE_SIZE * IMAGE_ZOOM,
    height: IMAGE_SIZE * IMAGE_ZOOM,
  } as ImageStyle,
  imageIcon: {
    position: 'absolute',
    backgroundColor: '#efefef',
    right: 0,
    bottom: 0,
    width: 36,
    height: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  noImage: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  } as ViewStyle,
});
