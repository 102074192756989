import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import PrimaryButton from '../../shared/buttons/PrimaryButton';

import NoticeIcon from '../../shared/NoticeIcon';

interface Props {
  projectId: number;
}

const ApplyButton: React.FC<Props> = props => {
  const {projectId} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(`/projects/${projectId}/requests`);
  }, []);
  return (
    <>
      <PrimaryButton style={styles.button} onPress={onPress}>
        申請
      </PrimaryButton>
      <View style={styles.notice}>
        {/**
        <NoticeIcon />
        */}
      </View>
    </>
  );
};

export default React.memo(ApplyButton);

const styles = StyleSheet.create({
  button: {
    width: 64,
    height: 32,
  } as ViewStyle,
  notice: {
    position: 'absolute',
    top: 4,
    right: 8,
  } as ViewStyle,
});
