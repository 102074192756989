import React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native';

interface Props {}

const CloseWindow: React.FC<Props> = props => {
  const onPress = React.useCallback(() => {
    if (Platform.OS === 'web') {
      window.close();
    }
  }, []);
  return (
    <>
      <View style={styles.block} />
      <TouchableWithoutFeedback style={styles.container} onPress={onPress}>
        <View style={styles.container}>
          <Text style={styles.text}>画面を閉じる</Text>
        </View>
      </TouchableWithoutFeedback>
    </>
  );
};

export default React.memo(CloseWindow);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#000',
    opacity: 0.8,
    height: 44,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  } as ViewStyle,
  text: {
    color: '#fff',
    fontSize: 14,
  } as TextStyle,
  block: {
    height: 44,
    width: '100%',
  } as ViewStyle,
});
