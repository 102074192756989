import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ContactMapper, {
  ContactData,
} from '../../entities/mappers/writer/ContactMapper';

import Contact from '../../../domain/entities/writer/Contact';
import ContactsRepository from '../../../domain/repositories/writer/ContactsRepository';

export default class NetContactsRepository
  extends NetResourcesRepository<Contact, ContactData>
  implements ContactsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/contacts',
        new ContactMapper(),
      ),
    );
  }
}
