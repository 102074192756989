import Entity from './Entity';

import ImageUrlHelper from '../helpers/ImageUrlHelper';
export default class PositionedEffect implements Entity {
  constructor(
    public id: number,
    public baseEffectId: number,
    public effectId: number,
    public position: 'center' | 'left' | 'right',
    public originalImageUrl: string,
    public frameUrls: string[],
  ) {}

  public getMaxSizeFrameUrls(): string[] {
    return this.frameUrls.map(url =>
      ImageUrlHelper.getImageUrl(url, {
        width: 640,
        height: 520,
      }),
    );
  }
}
