import * as React from 'react';
import {
  ActivityIndicator,
  Image,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ShareButtons from './ShareButtons';
import DimensionContext from '../dimension/DimensionContext';

interface Props {
  message: string;
  url: string;
  title: string;
  text?: string;
  imageUrl?: string;
  disableShare?: boolean;
}

const Content: React.FunctionComponent<Props> = props => {
  const {message, url, title, text, imageUrl, disableShare} = props;
  const [loaded, setLoaded] = React.useState(false);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{message}</Text>
      {imageUrl && (
        <DimensionContext.Consumer>
          {context => {
            const width = context.content.width - 16 * 2;
            return (
              <View
                style={{
                  width,
                  height: (width * 630) / 1200,
                }}>
                <Image
                  source={{uri: imageUrl}}
                  style={{
                    width,
                    height: (width * 630) / 1200,
                  }}
                  onLoad={() => setLoaded(true)}
                />
                {loaded ? null : (
                  <ActivityIndicator
                    style={{
                      position: 'absolute',
                      left: width / 2,
                      top: (width * 630) / 1200 / 2,
                    }}
                  />
                )}
              </View>
            );
          }}
        </DimensionContext.Consumer>
      )}
      {!disableShare ? (
        <>
          <Text style={styles.message}>
            沢山の人に読んでもらえるようシェアしましょう！
          </Text>
          <ShareButtons url={url} title={title} message={text || message} />
        </>
      ) : null}
    </View>
  );
};

export default React.memo(Content);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    marginHorizontal: 16,
    marginTop: 32,
    marginBottom: 100,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 17,
    fontWeight: 'bold',
    marginBottom: 24,
  } as TextStyle,
  message: {
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
    marginVertical: 16.5,
  } as TextStyle,
});
