interface Options {
  facebookAccount?: {
    accessToken: string;
    userId: string;
    screenName: string;
  };
  twitterAccount?: {
    accessToken: string;
    accessTokenSecret: string;
    userId: string;
    screenName: string;
  };
  lineAccount?: {
    accessToken: string;
    userId: string;
    screenName: string;
  };
}

export default class UserRegistrationForm {
  constructor(
    public email: string = '',
    public password: string = '',
    public passwordConfirmation: string = '',
    public options: Options = {},
  ) {}

  public toParams() {
    const email = this.email;
    const password = this.password;
    const password_confirmation = this.passwordConfirmation;
    return {
      email,
      password,
      password_confirmation,
      ...this.options,
    };
  }
}
