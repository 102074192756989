import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import New, {
  DispatchProps,
  StateProps,
} from '../../../components/writer/user_registrations/New';

import AppState from '../../../reducers/AppState';

import createTemporaryUserRegistration, {
  Params as TemporaryUserRegistrationCreateParams,
} from '../../../actions/writer/temporary_users/registration/create';
import showCurrentUser from '../../../actions/writer/current_user/show';
import updateCurrentUser from '../../../actions/writer/current_user/update';
import destroyWriterCurrentUserSession from '../../../actions/writer/users/session/destroy';

import selectEntity from '../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  return {
    navigation,
    currentUser,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createTemporaryUserRegistration: (
      params: TemporaryUserRegistrationCreateParams,
    ) => dispatch(createTemporaryUserRegistration(params)),
    showCurrentUser: () => dispatch(showCurrentUser()),
    updateCurrentUser: () => dispatch(updateCurrentUser()),
    destroyWriterCurrentUserSession: () =>
      dispatch(destroyWriterCurrentUserSession()),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
