import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props<T> {
  value: T;
  label: string;
  isActive: boolean;
  disabled?: boolean;
  onChangeTab: (value: T) => void;
}

type TabComponent = <T>(props: Props<T>) => React.ReactElement<Props<T>>;

const Tab: TabComponent = props => {
  const {value, label, isActive, disabled, onChangeTab} = props;
  return (
    <Pressable disabled={disabled} onPress={() => onChangeTab(value)}>
      <View style={[styles.tab, isActive ? styles.tabActive : null]}>
        <Text
          style={[
            styles.label,
            isActive ? styles.labelActive : null,
            disabled ? styles.labelDisabled : null,
          ]}>
          {label}
        </Text>
      </View>
    </Pressable>
  );
};

export default React.memo(Tab) as typeof Tab;

const styles = StyleSheet.create({
  tab: {
    width: 75,
    height: 25,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 3,
  } as ViewStyle,
  tabActive: {
    backgroundColor: '#efefef',
  } as ViewStyle,
  label: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#666666',
  } as TextStyle,
  labelActive: {
    color: '#383838',
  },
  labelDisabled: {
    color: '#ccc',
  },
});
