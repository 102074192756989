import EntityMapper from './EntityMapper';

import ActorCharacterMapper, {ActorCharacterData} from './ActorCharacterMapper';

import ActorCostume from '../../../domain/entities/ActorCostume';

export interface ActorCostumeData {
  id: number;
  name: string;
  original_image_url: string;
  actor_character: ActorCharacterData;
}

export default class ActorCostumeMapper
  implements EntityMapper<ActorCostumeData, ActorCostume>
{
  public map(obj: ActorCostumeData): ActorCostume {
    const actorCharacter = new ActorCharacterMapper().map(obj.actor_character);
    return new ActorCostume(
      obj.id,
      obj.name,
      obj.original_image_url,
      actorCharacter,
    );
  }
}
