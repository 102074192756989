import * as React from 'react';
import {Image, StyleSheet, View, ViewStyle} from 'react-native';

import {actorCharacterImageUrl} from '../../.././../../../helpers/images';

import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';

interface Props {
  actorCharacter: ActorCharacter | null;
  width: number;
  inverted?: boolean;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {actorCharacter, width, inverted} = props;
  const characterSize = width * ASPECT_RATIO - MARGIN;
  return (
    <View
      style={[
        styles.container,
        {width: width, height: width * ASPECT_RATIO},
        inverted ? styles.inverted : null,
      ]}>
      {actorCharacter && (
        <Image
          style={{width: characterSize, height: characterSize}}
          resizeMode={'contain'}
          source={{
            uri: actorCharacterImageUrl(actorCharacter, 'middle'),
          }}
        />
      )}
    </View>
  );
};

export default React.memo(CharacterView);

const ASPECT_RATIO = 256 / 375;

const MARGIN = 16;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    padding: 8,
  } as ViewStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ViewStyle,
});
