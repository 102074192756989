import {Dispatch} from 'redux';

import Types from '../Types';

import SceneCommandForm from '../../../domain/forms/scene_commands/SceneCommandForm';

export interface Params {
  sceneCommandForms: SceneCommandForm[];
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<SceneCommandForm[]> {
  return (dispatch: Dispatch<any>): Promise<SceneCommandForm[]> => {
    const payload = params.sceneCommandForms;
    dispatch({
      payload,
      type: Types.CreateSceneCommandFormList,
    });
    return new Promise(resolve => resolve(payload));
  };
}
