import * as React from 'react';
import {
  Platform,
  StyleSheet,
  ScrollView,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../shared/buttons/DefaultOutlineButton';
import CheckButton from '../../shared/forms/CheckButton';
import AlertIcon from '../../shared/icons/AlertIcon';

import {colors, formStyles} from '../../../styles/variables';

import ContactCategory from '../../../../domain/entities/writer/ContactCategory';
import WriterContactForm from '../../../../domain/forms/writer/ContactForm';

interface Props {
  contactCategories: ContactCategory[];
  writerContactForm: WriterContactForm;
  onSubmit: (writerContactForm: WriterContactForm) => void;
  onBack: () => void;
}

interface State {
  checked: boolean;
}

export default class Confirm extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      checked: false,
    };
  }

  public render(): React.ReactNode {
    const {writerContactForm, onBack} = this.props;
    const contactCategory = this.getContactCategory(
      writerContactForm.writerContactCategoryId,
    );
    const {checked} = this.state;
    return (
      <ScrollView>
        <View style={styles.container}>
          <View style={formStyles.formContainer}>
            <View style={[formStyles.formGroup, styles.headline]}>
              <Text style={styles.headlineText}>お問い合わせの内容確認</Text>
            </View>

            <View style={formStyles.formGroup}>
              <View>
                <Text style={[formStyles.formLabel, styles.formLabel]}>
                  お問い合わせカテゴリ
                </Text>
              </View>
              <View style={styles.confirm}>
                <Text style={styles.confirmText}>
                  {contactCategory ? contactCategory.name : ''}
                </Text>
              </View>
            </View>
            <View style={formStyles.formGroup}>
              <View>
                <Text style={[formStyles.formLabel, styles.formLabel]}>
                  返信用メールアドレス
                </Text>
              </View>
              <View style={styles.confirm}>
                <Text style={styles.confirmText}>
                  {writerContactForm.email}
                </Text>
              </View>
            </View>
            <View style={formStyles.formGroup}>
              <View>
                <Text style={[formStyles.formLabel, styles.formLabel]}>
                  お問い合わせ内容
                </Text>
              </View>
              <View style={styles.confirm}>
                <Text style={styles.confirmText}>
                  {writerContactForm.content}
                </Text>
              </View>
            </View>
            <View style={styles.note}>
              <Text
                style={{color: '#f23406', fontSize: 11, fontWeight: 'bold'}}>
                <AlertIcon color={'#f23406'} size={11} />
                ご注意
              </Text>
              <Text style={{color: '#f23406', fontSize: 11, marginVertical: 8}}>
                お問い合わせ内容がマニュアルやヘルプ、利用規約に記載されている場合、お返事はいたしかねます。
              </Text>
              <CheckButton
                style={{paddingHorizontal: 24}}
                checked={checked}
                onPress={() => {
                  this.setState({checked: !checked});
                }}>
                上記の通り理解いたしました
              </CheckButton>
            </View>
            <View style={styles.buttons}>
              <DefaultOutlineButton
                buttonSize={{
                  width: 150,
                  height: 46,
                }}
                style={styles.button}
                onPress={onBack}>
                戻る
              </DefaultOutlineButton>
              <PrimaryButton
                style={styles.button}
                buttonSize={{
                  width: 150,
                  height: 46,
                }}
                onPress={this.handlePressButton}
                disabled={!checked}>
                送信する
              </PrimaryButton>
            </View>
          </View>
        </View>
      </ScrollView>
    );
  }

  private handlePressButton = () => {
    const {writerContactForm, onSubmit} = this.props;
    if (writerContactForm.validate()) {
      onSubmit(writerContactForm);
    }
  };

  private getContactCategory = (id: number | null) => {
    const {contactCategories} = this.props;
    return contactCategories.find(contactCategory => contactCategory.id === id);
  };
}

const styles = StyleSheet.create({
  formLabel: {
    color: '#383838',
    fontWeight: 'bold',
  } as TextStyle,
  button: {
    marginHorizontal: 8,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
  } as ViewStyle,
  confirm: {
    marginTop: 3,
  } as TextStyle,
  confirmText: {
    color: '#383838',
    fontSize: 14,
    lineHeight: Platform.select({
      android: 22,
      ios: 18,
    }),
  } as TextStyle,
  container: {
    backgroundColor: 'white',
  } as ViewStyle,
  headline: {
    marginBottom: -5,
  } as ViewStyle,
  headlineText: {
    color: colors.textColor,
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  message: {
    flexDirection: 'row',
    marginBottom: 8,
  } as ViewStyle,
  messageText: {
    color: colors.textColor,
    fontSize: 13,
    lineHeight: Platform.select({
      android: 20,
      ios: 16,
    }),
  } as TextStyle,
  messageWrapper: {
    marginBottom: -8,
  } as ViewStyle,
  note: {
    backgroundColor: '#fafafa',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
    margin: 16,
    marginTop: 32,
  } as ViewStyle,
});
