import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import CheckButton from '../../../shared/forms/CheckButton';
import AlertIcon from '../../../shared/icons/AlertIcon';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep4: React.FC<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.alert}>
          <AlertIcon size={32} color={'#ff8f13'} />
          <Text style={styles.alertText}>注意</Text>
        </View>
        <Text style={styles.message}>
          プロジェクト内で発生したトラブルに対して、{'\n'}
          運営では一切責任を負いかねます。
        </Text>
        <Text style={styles.message}>
          ただし、トラブル防止のため、プロジェクトは{'\n'}
          監視させていただくことがございます。
        </Text>
        <CheckButton
          style={styles.checkButton}
          checked={checked}
          onPress={onPressCheck}>
          注意事項を理解した
        </CheckButton>
        <PrimaryButton disabled={!checked} onPress={onPress}>
          プロジェクトを始める
        </PrimaryButton>
      </View>
    </ScrollView>
  );
};

export default React.memo(ServiceExplanationStep4);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  alert: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
  } as ViewStyle,
  alertText: {
    color: '#ff8f13',
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 4,
  } as TextStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginHorizontal: 24,
    marginBottom: 8,
    textAlign: 'center',
  } as TextStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 16,
  } as ViewStyle,
});
