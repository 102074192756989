import * as React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

interface Props {
  children: string;
}

function autoLink(str: string) {
  const regexpUrl =
    /(https?|ftp):\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3001-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g;
  const regexpMakeLink = function (url: string) {
    return (
      '<a href="' +
      url +
      '" target="_blank" rel="noopener" style="text-decoration: underline;">' +
      url +
      '</a>'
    );
  };
  if (str.match(regexpUrl) != null) {
    const urlAllMatches = str.match(regexpUrl);
    if (urlAllMatches) {
      const urlMatches = new Set(urlAllMatches);
      urlMatches.forEach(url => {
        str = str.replaceAll(url, regexpMakeLink(url));
      });
    }
  }
  return str;
}

const LinkedText: React.FunctionComponent<Props> = props => {
  const {children} = props;
  return <div dangerouslySetInnerHTML={{__html: autoLink(children)}} />;
};

export default React.memo(LinkedText);

const styles = StyleSheet.create({});
