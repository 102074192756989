import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import BackgroundUsageHistory from '../../../domain/entities/BackgroundUsageHistory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetBackgroundUsageHistoriesRepository from '../../../data/repositories/writer/NetBackgroundUsageHistoriesRepository';

const types = {
  failure: Types.IndexBackgroundUsageHistoriesFailure,
  request: Types.IndexBackgroundUsageHistoriesRequest,
  success: Types.IndexBackgroundUsageHistoriesSuccess,
};

export interface Params extends BaseParams {
  storyId: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<BackgroundUsageHistory>> {
  return indexGenerics(
    new NetBackgroundUsageHistoriesRepository(),
    types,
    params,
  );
}
