import * as React from 'react';
import {FlatList, StyleSheet, ViewStyle} from 'react-native';

import MenuListItem from './MenuListItem';

import NewStoryIcon from '../../shared/icons/NewStoryIcon';
import ListIcon from '../../shared/icons/ListIcon';
import AnalyzeIcon from '../../shared/icons/AnalyzeIcon';
// import BellIcon from "../../shared/icons/BellIcon";
import NewsIcon from '../../shared/icons/NewsIcon';
import SettingIcon from '../../shared/icons/SettingIcon';
import ManualIcon from '../../shared/icons/ManualIcon';
import HelpIcon from '../../shared/icons/HelpIcon';
import ResponseIcon from '../../shared/icons/ResponseIcon';
import ExtensionsIcon from '../../shared/icons/ExtensionsIcon';
import CmIcon from '../../shared/icons/CmIcon';
import VideoIcon from '../../shared/icons/VideoIcon';
import ExportIcon from '../../shared/icons/ExportIcon';
import ProIcon from '../../shared/icons/ProIcon';
import ShopIcon from '../../shared/icons/ShopIcon';
import FaqIcon from '../../shared/icons/FaqIcon';
import PartnerProgramIcon from '../../shared/icons/PartnerProgramIcon';
import TransferIcon from '../../shared/icons/TransferIcon';
import ProjectIcon from '../../shared/icons/ProjectIcon';
import WritersGateIcon from '../../shared/icons/WritersGateIcon';

import {
  enabledMakerPro,
  enabledCommercialVideo,
  enabledFullVideo,
  enabledExport,
  enabledShop,
  enabledPartnerProgram,
  enabledProject,
  enabledWritersGate,
} from '../../../helpers/features';

import MenuItem from '../../../view_models/MenuItem';

import DimensionContext from '../../shared/dimension/DimensionContext';

import {RootStackParamList} from '../../../navigators/RootStackParamList';

type RouteName = keyof RootStackParamList;

interface Props {
  scrollEnabled?: boolean;
  disabledRouteNames?: Array<RouteName>;
  isGradeBlack?: boolean;
  isPro?: boolean;
  onSelectMenuItem: (menuItem: MenuItem) => void;
}
const MenuList: React.FunctionComponent<Props> = (props: Props) => {
  const {
    scrollEnabled,
    disabledRouteNames,
    isGradeBlack,
    isPro,
    onSelectMenuItem,
  } = props;
  const keyExtractor = React.useCallback((item: MenuItem) => item.label, []);
  return (
    <DimensionContext.Consumer>
      {context => (
        <FlatList
          data={[
            ...MENU_ITEMS,
            ...(isPro || isGradeBlack ? MENU_ITEMS_ONLY_BLACK_OR_PRO : []),
            ...(isGradeBlack ? MENU_ITEMS_ONLY_BLACK : []),
            ...(isPro ? MENU_ITEMS_ONLY_PRO : []),
          ]}
          bounces={false}
          style={styles.container}
          numColumns={NUM_COLUMNS}
          keyExtractor={keyExtractor}
          scrollEnabled={scrollEnabled}
          renderItem={info => (
            <MenuListItem
              menuItem={info.item}
              width={
                (context.content.width - MARGIN_HORIZONTAL * 2) / NUM_COLUMNS
              }
              disabled={(disabledRouteNames || []).includes(
                info.item.routeName,
              )}
              onSelectMenuItem={onSelectMenuItem}
            />
          )}
        />
      )}
    </DimensionContext.Consumer>
  );
};

export default React.memo(MenuList);

const NUM_COLUMNS = 3;

const MARGIN_HORIZONTAL = 20;

const MENU_ITEMS: Array<MenuItem> = [
  {
    Icon: NewStoryIcon,
    label: 'ストーリー作成',
    routeName: 'NewStoryNavigation',
  },
  {
    Icon: ListIcon,
    label: 'ストーリー一覧',
    routeName: 'Home',
  },
  {
    Icon: AnalyzeIcon,
    label: '分析',
    routeName: 'AnalysisStories',
  },
  {
    Icon: ResponseIcon,
    label: 'レスポンス',
    routeName: 'ResponseStories',
  },
  {
    Icon: ExtensionsIcon,
    label: '機能拡張',
    routeName: 'Extensions',
  },
  /*
  {
    Icon: BellIcon,
    label: "通知",
  },
  */
  {
    Icon: NewsIcon,
    label: 'お知らせ',
    routeName: 'Announcements',
  },
  {
    Icon: SettingIcon,
    label: '設定',
    routeName: 'SettingsNavigation',
  },
  {
    Icon: ManualIcon,
    label: 'マニュアル',
    routeName: 'Manuals',
  },
  {
    Icon: HelpIcon,
    label: 'ヘルプ',
    routeName: 'Helps',
  },
  {
    Icon: CmIcon,
    label: 'CM動画',
    routeName: 'CommercialVideoExportJobExecutions',
  },
  {
    Icon: FaqIcon,
    label: 'お問い合わせ',
    routeName: 'FaqNavigation',
  },
  ...(enabledMakerPro
    ? ([
        {
          Icon: ProIcon,
          label: 'MakerPro',
          routeName: 'ProNavigation',
        },
      ] as Array<MenuItem>)
    : []),
];

const MENU_ITEMS_ONLY_BLACK: Array<MenuItem> = [];

const MENU_ITEMS_ONLY_PRO: Array<MenuItem> = [];

const MENU_ITEMS_ONLY_BLACK_OR_PRO: Array<MenuItem> = [];

if (enabledFullVideo) {
  MENU_ITEMS_ONLY_BLACK_OR_PRO.push({
    Icon: VideoIcon,
    label: '動画化',
    routeName: 'FullVideoExportJobExecutions',
    pro: true,
  });
}

if (enabledExport) {
  MENU_ITEMS_ONLY_BLACK_OR_PRO.push({
    Icon: ExportIcon,
    label: 'エクスポート',
    routeName: 'ExportStories',
    pro: true,
  });
}
if (enabledProject) {
  MENU_ITEMS_ONLY_BLACK_OR_PRO.push({
    Icon: ProjectIcon,
    label: 'プロジェクト',
    routeName: 'ProjectsNavigation',
    pro: true,
  });
}
if (enabledShop) {
  MENU_ITEMS.push({
    Icon: ShopIcon,
    label: 'ショップ',
    routeName: 'ShopNavigation',
  });
}

if (enabledPartnerProgram) {
  MENU_ITEMS.push({
    Icon: PartnerProgramIcon,
    label: 'パートナー',
    routeName: 'PartnerProgramNavigation',
  });
  MENU_ITEMS.push({
    Icon: TransferIcon,
    label: '振込申請',
    routeName: 'TransferNavigation',
  });
}

if (enabledWritersGate) {
  MENU_ITEMS.push({
    Icon: WritersGateIcon,
    label: 'ライターズゲート',
    routeName: 'WritersGateNavigation',
  });
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 12.5,
    marginHorizontal: MARGIN_HORIZONTAL,
  } as ViewStyle,
});
