import Entity from '../Entity';

import UserProfile from './UserProfile';
import WriterUser from '../writer/User';

export default class User implements Entity {
  constructor(
    public id: number,
    public userName: string,
    public inhouseStaff: boolean,
    public userProfile: UserProfile | null,
    public linkedWriterUser: WriterUser | null,
  ) {}
}
