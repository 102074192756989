export default class OtherHostReferrer {
  public static get(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      const val = localStorage.getItem(OtherHostReferrer.KEY);
      resolve(val);
    });
  }

  public static set(value: string): Promise<void> {
    return new Promise(resolve => {
      localStorage.setItem(OtherHostReferrer.KEY, value);
      resolve();
    });
  }

  public static remove(): Promise<void> {
    return new Promise(resolve => {
      localStorage.removeItem(OtherHostReferrer.KEY);
      resolve();
    });
  }

  private static KEY = 'other_host_referrer';
}
