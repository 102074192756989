import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import MonitorHome from '../../../containers/monitor/home/Index';
import NewMonitorApplication from '../../../containers/monitor/application/New';

import MonitorPoliciesTerms from '../../../containers/monitor/policies/terms/Index';
import MonitorPoliciesPrivacy from '../../../containers/monitor/policies/privacy/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="MonitorHome">
      <RootStack.Screen
        name="MonitorHome"
        component={MonitorHome}
        options={{
          title: 'モニター100名限定募集',
        }}
      />
      <RootStack.Screen
        name="NewMonitorApplication"
        component={NewMonitorApplication}
        options={{title: 'モニター100名限定募集フォーム'}}
      />
      <RootStack.Screen
        name="MonitorPoliciesTerms"
        component={MonitorPoliciesTerms}
        options={{title: '利用規約'}}
      />
      <RootStack.Screen
        name="MonitorPoliciesPrivacy"
        component={MonitorPoliciesPrivacy}
        options={{title: 'プライバシー'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
