import * as React from 'react';
import {ScrollView, StyleSheet, TextStyle, ViewStyle} from 'react-native';

import NavigationProp from '../../../../navigators/NavigationProp';

import Layout from '../../../shared/Layout';

import FormStep0 from './FormStep0';
import FormStep1, {Form1Values} from './FormStep1';
import FormStep2, {Form2Values} from './FormStep2';
import FormStep3, {Form3Values} from './FormStep3';

export type FormValues = Form1Values & Form2Values & Form3Values;

import DimensionContext from '../../../shared/dimension/DimensionContext';

interface InnerProps {
  navigation: NavigationProp;
  isNew: boolean;
  width: number;
  formValues?: FormValues;
  editableEpisodeCopyright: boolean;
  onSubmit: (formValues: FormValues) => void;
  // setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const FormInnter: React.FC<InnerProps> = props => {
  const {
    navigation,
    isNew,
    width,
    formValues,
    editableEpisodeCopyright,
    onSubmit,
  } = props;
  const scrollViewRef = React.useRef<ScrollView | null>(null);
  const [form1Values, setForm1Values] = React.useState<Form1Values | null>(
    null,
  );
  const [form2Values, setForm2Values] = React.useState<Form2Values | null>(
    null,
  );
  const [form3Values, setForm3Values] = React.useState<Form3Values | null>(
    null,
  );
  const [step, setStep] = React.useState<0 | 1 | 2 | 3>(0);
  const preventDowbleClick = React.useRef(false);
  const goNext = React.useCallback(() => {
    if (preventDowbleClick.current) {
      return;
    }
    preventDowbleClick.current = true;
    setTimeout(() => {
      preventDowbleClick.current = false;
    }, 1000);
    const nextStep = step + 1;
    if (nextStep === 0 || nextStep === 1 || nextStep === 2 || nextStep === 3) {
      setStep(nextStep);
    }
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: width * nextStep});
    }
  }, [step]);
  const goPrev = React.useCallback(() => {
    if (preventDowbleClick.current) {
      return;
    }
    preventDowbleClick.current = true;
    setTimeout(() => {
      preventDowbleClick.current = false;
    }, 1000);
    const nextStep = step - 1;
    if (nextStep === 0 || nextStep === 1 || nextStep === 2 || nextStep === 3) {
      setStep(nextStep);
    }
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: width * nextStep});
    }
  }, [step]);

  const onSubmitForm1 = React.useCallback(
    (form1Values: Form1Values) => {
      setForm1Values(form1Values);
      goNext();
    },
    [step],
  );
  const onSubmitForm2 = React.useCallback(
    (form2Values: Form2Values) => {
      setForm2Values(form2Values);
      goNext();
    },
    [step],
  );
  const onSubmitForm3 = React.useCallback(
    (form3Values: Form3Values) => {
      if (form1Values && form2Values) {
        onSubmit({
          ...form1Values,
          ...form2Values,
          ...form3Values,
        });
      } else if (formValues && form1Values) {
        onSubmit({
          ...formValues,
          ...form1Values,
          ...form3Values,
        });
      }
    },
    [form1Values, form2Values],
  );
  const onPressClose = () => navigation.goBack();
  const style = React.useMemo(() => {
    return {width, flex: 1};
  }, [width]);
  return (
    <ScrollView
      ref={scrollViewRef}
      pagingEnabled={true}
      horizontal={true}
      scrollEnabled={false}>
      {isNew ? (
        <Layout
          navigation={navigation}
          title={'プロジェクト作成'}
          containerStyle={style}
          back={true}>
          <FormStep0 onPress={goNext} />
        </Layout>
      ) : null}
      <Layout
        navigation={navigation}
        title={'プロジェクト情報'}
        containerStyle={style}
        close={true}
        scrollable={false}
        onPressClose={onPressClose}>
        <FormStep1 values={formValues} onSubmit={onSubmitForm1} />
      </Layout>
      {isNew ? (
        <Layout
          navigation={navigation}
          title={'プロジェクト権利情報'}
          containerStyle={style}
          back={true}
          onPressBack={goPrev}
          close={true}
          scrollable={false}
          onPressClose={onPressClose}>
          <FormStep2
            values={formValues}
            onSubmit={onSubmitForm2}
            editableEpisodeCopyright={editableEpisodeCopyright}
          />
        </Layout>
      ) : null}
      <Layout
        navigation={navigation}
        title={'プロジェクト参加条件設定'}
        containerStyle={style}
        back={true}
        onPressBack={goPrev}
        close={true}
        scrollable={false}
        onPressClose={onPressClose}>
        <FormStep3
          values={formValues}
          episodeCopyright={form2Values?.episodeCopyright}
          isNew={isNew}
          onSubmit={onSubmitForm3}
        />
      </Layout>
    </ScrollView>
  );
};

interface Props {
  navigation: NavigationProp;
  isNew: boolean;
  formValues?: FormValues;
  editableEpisodeCopyright: boolean;
  onSubmit: (formValues: FormValues) => void;
  // setTitle: React.Dispatch<React.SetStateAction<string>>;
}

const Form: React.FC<Props> = props => {
  return (
    <DimensionContext.Consumer>
      {context => {
        const width = context.content.width;
        return <FormInnter {...props} width={width} />;
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
});
