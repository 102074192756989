import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Background from '../../../domain/entities/Background';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetBackgroundsRepository from '../../../data/repositories/writer/NetBackgroundsRepository';

const types = {
  failure: Types.IndexBackgroundsFailure,
  request: Types.IndexBackgroundsRequest,
  success: Types.IndexBackgroundsSuccess,
};

export interface Params extends BaseParams {
  baseBackgroundId?: number | null;
  backgroundCategoryId?: number | null;
  backgroundWeatherId?: number | null;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Background>> {
  return indexGenerics(new NetBackgroundsRepository(), types, params);
}
