import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import NetEpisodesRepository from '../../../data/repositories/writer/NetEpisodesRepository';

export default function action(
  id: number,
): (dispatch: Dispatch<any>) => Promise<number> {
  return async (dispatch: Dispatch<any>): Promise<number> => {
    try {
      await new NetEpisodesRepository().destroy(id);
      const payload = id;
      dispatch({payload, type: Types.DestroyEpisodeSuccess});
      sendDefaultCustomEvent({
        resourceType: 'episode',
        resourceId: id,
        actionName: 'destroy',
      });
      return id;
    } catch (err) {
      dispatch({type: Types.DestroyEpisodeFailure});
      throw err;
    }
  };
}
