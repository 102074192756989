import * as React from 'react';
import {
  ActivityIndicator,
  Image,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import FilterSelect from './FilterSelect';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import ImageCrop from '../../../shared/image_crop/ImageCrop';
import DimensionContext from '../../../shared/dimension/DimensionContext';

import File from '../../../../../domain/entities/File';

import {Filter} from '../../../../../vendor/react-native-tapnovel-viewer/domain/value_objects/command_options/BackgroundCommandOptions';

interface Props {
  uri: string | null;
  aspectRatio: number;
  onSubmit: (file: File) => void;
}

interface State {
  filter?: Filter;
  imageWidth?: number;
  imageHeight?: number;
}
const DEFAULT_IMAGE_WIDTH = 2480;
const DEFAULT_IMAGE_HEIGHT = 2015;

export default class Form extends React.PureComponent<Props, State> {
  private ref = React.createRef<ImageCrop>();

  constructor(props: Props) {
    super(props);
    this.state = {
      filter: 'normal',
    };
  }

  public componentDidUpdate?(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    if (this.props.uri && this.props.uri !== prevProps.uri) {
      Image.getSize(this.props.uri, (imageWidth, imageHeight) => {
        this.setState({
          imageWidth,
          imageHeight,
        });
      });
    }
  }

  public render(): React.ReactNode {
    const {uri, aspectRatio} = this.props;
    const {filter, imageWidth, imageHeight} = this.state;
    return (
      <View style={styles.container}>
        <DimensionContext.Consumer>
          {context => {
            const width = context.content.width * 0.9;

            return uri && imageWidth && imageHeight ? (
              <ImageCrop
                ref={this.ref}
                uri={uri}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                cropHeight={width / aspectRatio}
                cropWidth={width}
                pixelRatio={3}
                filter={filter}
                zoom={1}
              />
            ) : (
              <View
                style={{
                  width,
                  height: width / (DEFAULT_IMAGE_WIDTH / DEFAULT_IMAGE_HEIGHT),
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <ActivityIndicator />
              </View>
            );
          }}
        </DimensionContext.Consumer>
        <FilterSelect value={filter} onValueChange={this.handleValueChange} />
        <View style={styles.button}>
          <PrimaryButton disabled={!uri} onPress={this.handlePress}>
            切り取る
          </PrimaryButton>
        </View>
      </View>
    );
  }

  private handleValueChange = (filter: Filter) => {
    this.setState({filter});
  };

  private handlePress = () => {
    const {onSubmit} = this.props;
    if (!this.ref.current) {
      return;
    }
    this.ref.current.crop().then(onSubmit);
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 33,
  } as ViewStyle,
  button: {
    marginVertical: 32,
  },
});
