import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../components/announcements/Show';

import AppState from '../../reducers/AppState';

import showAnnouncement from '../../actions/writer/announcements/show';

import selectEntity from '../../helpers/selectEntity';
import {AnnouncementRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: AnnouncementRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities} = state;
  return {
    announcement: selectEntity(entities.writer.announcements, route.params.id),
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showAnnouncement: (id: number) => dispatch(showAnnouncement(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
