import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import UserNotificationSettingsBatch from '../../../../domain/entities/writer/UserNotificationSettingsBatch';

import NetUserNotificationSettingsBatchRepository from '../../../../data/repositories/writer/NetUserNotificationSettingsBatchRepository';

const types = {
  failure: Types.UpdateWriterUserNotificationSettingsBatchFailure,
  request: Types.UpdateWriterUserNotificationSettingsBatchRequest,
  success: Types.UpdateWriterUserNotificationSettingsBatchSuccess,
};

interface WriterUserNotificationSetting {
  writerNotificationSettingId: number;
  enabled: boolean;
}

export interface Params {
  writerUserNotificationSettings: WriterUserNotificationSetting[];
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<UserNotificationSettingsBatch> {
  return (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<UserNotificationSettingsBatch> => {
    return new NetUserNotificationSettingsBatchRepository()
      .update({writerNotificationSettingsBatch: params})
      .then(payload => {
        dispatch({payload, type: types.success});
        return payload;
      })
      .catch(err => {
        dispatch({type: types.failure});
        throw err;
      });
  };
}
