import NetResourceRepository from '../../../NetResourceRepository';
import NetResourceRepositoryAdapter from '../../../NetResourceRepositoryAdapter';

import SubscriptionMapper, {
  SubscriptionData,
} from '../../../../entities/mappers/writer/my_stripe/maker_pro/SubscriptionMapper';

import Subscription from '../../../../../domain/entities/writer/my_stripe/maker_pro/Subscription';
import SubscriptionRepository from '../../../../../domain/repositories/writer/my_stripe/maker_pro/SubscriptionRepository';

export default class NetSubscriptionRepository
  extends NetResourceRepository<Subscription, SubscriptionData>
  implements SubscriptionRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/writer/my_stripe/maker_pro/subscription',
        new SubscriptionMapper(),
      ),
    );
  }
}
