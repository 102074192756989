import EntityMapper from '../EntityMapper';

import ExtensionMapper, {ExtensionData} from './ExtensionMapper';
import ExtensionMission from '../../../../domain/entities/writer/ExtensionMission';

export interface ExtensionMissionData {
  id: number;
  content: string;
  created_at: string;
  updated_at: string;
  writer_extension: ExtensionData;
}

export default class ExtensionMissionMapper
  implements EntityMapper<ExtensionMissionData, ExtensionMission>
{
  private extensionMapper = new ExtensionMapper();

  public map(obj: ExtensionMissionData): ExtensionMission {
    return new ExtensionMission(
      obj.id,
      obj.content,
      new Date(obj.created_at),
      new Date(obj.updated_at),
      this.extensionMapper.map(obj.writer_extension),
    );
  }
}
