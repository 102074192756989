import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import ActorAccessorySet from '../../../domain/entities/ActorAccessorySet';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetActorAccessorySetsRepository from '../../../data/repositories/writer/NetActorAccessorySetsRepository';

const types = {
  failure: Types.IndexActorAccessorySetsFailure,
  request: Types.IndexActorAccessorySetsRequest,
  success: Types.IndexActorAccessorySetsSuccess,
};

export interface Params extends BaseParams {
  actorId: number;
  code?: string;
  actorCostumeId?: number;
  actorHairStyleId?: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<ActorAccessorySet>> {
  return indexGenerics(new NetActorAccessorySetsRepository(), types, params);
}
