import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {FormValues} from './FormData';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import TextArea from '../../../shared/forms/TextArea';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

import {formStyles} from '../../../../styles/variables';

interface Props {
  hidden?: boolean;
  onPressConfirm: (formValues: FormValues) => void;
}

const Form: React.FC<Props> = props => {
  const {hidden, onPressConfirm} = props;
  const [content, setContent] = React.useState('');
  const valid = content.length > 0 && content.length <= MAX_CONTENT_LENGTH;
  const onPressSubmit = () => {
    onPressConfirm({content});
  };
  return (
    <View style={[styles.container, hidden ? {display: 'none'} : null]}>
      <View style={[styles.formGroup, styles.formGroupTextArea]}>
        <LabelWithOption
          title={`回答内容（${MAX_CONTENT_LENGTH}文字まで）`}
          length={content.length}
          maxLength={MAX_CONTENT_LENGTH}
        />
        <TextArea
          style={[formStyles.formTextArea, styles.formTextArea]}
          value={content}
          placeholder={'回答内容を入力'}
          onChangeText={setContent}
        />
      </View>
      <Text
        style={{
          color: '#666',
          fontSize: 13,
          textAlign: 'center',
          marginVertical: 8,
        }}>
        TapNovelMakerでは、{'\n'}
        優しくて温かいコミュニティを目指しております。{'\n'}
        同じ創作活動を行う仲間に向けて、{'\n'}
        愛のこもった回答をよろしくお願いします。
      </Text>
      <View style={styles.submitButton}>
        <PrimaryButton disabled={!valid} onPress={onPressSubmit}>
          内容を確認
        </PrimaryButton>
      </View>
    </View>
  );
};

const MAX_CONTENT_LENGTH = 400;

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  } as ViewStyle,
  headlineText: {
    color: '#383838',
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
  formGroup: {
    margin: 16,
  } as ViewStyle,
  formGroupHelp: {
    marginTop: 41,
  } as ViewStyle,
  formGroupTextArea: {
    marginBottom: 0,
  } as ViewStyle,
  submitButton: {
    alignItems: 'center',
    marginVertical: 16,
  } as ViewStyle,
  helpText: {
    color: '#222222',
    fontSize: 15,
    lineHeight: 20,
  } as TextStyle,
  helpLink: {
    borderBottomWidth: 1,
    borderBottomColor: '#383838',
  } as ViewStyle,
  helpLinkText: {
    color: '#222222',
    fontWeight: 'bold',
  } as TextStyle,
  helpMessage: {
    color: '#383838',
    fontSize: 15,
    lineHeight: 17,
  } as TextStyle,
  contactMessageWrapper: {
    marginTop: 16,
  } as ViewStyle,
  contactMessage: {
    color: '#222222',
    fontSize: 15,
    lineHeight: 20,
  } as TextStyle,
  formTextArea: {
    height: 174,
  } as TextStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
    right: 3,
  } as ViewStyle,
});

const generateNoteHtml = (note: string) => {
  return `
  <html>
    <head>
      <style>
        html, body {
          margin: 0;
          padding: 0;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overscroll-behavior-y: contain;
        }

        a, p {
          color: #222;
          margin: 0;
          padding: 0;
          border: 0;
        }
        a {
          font-weight: bold;

        }
        p {
          font-size: 14px;
          font-weight: 400;
        }
      </style>
    </head>
    <body>
      <div id="main">
        <p>
          ${note}
        </p>
      </div>
    </body>
  </html>
`;
};
