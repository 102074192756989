import * as React from 'react';
import {Text, View} from 'react-native';

import DefaultModal from '../../../shared/modals/DefaultModal';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import {sendDefaultCustomEvent} from '../../../../helpers/analytics';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

import NetCurrentUserRepository from '../../../../../data/repositories/writer/NetCurrentUserRepository';
import NetSubscriptionRepository from '../../../../../data/repositories/writer/my_stripe/maker_pro/NetSubscriptionRepository';

interface Props {
  currentUser: CurrentUser;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser | null>>;
  onCloseModal: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReactivateModal: React.FunctionComponent<Props> = props => {
  const {setCurrentUser, onCloseModal, setLoading} = props;
  const onPress = React.useCallback(() => {
    setLoading(true);
    onCloseModal();
    new NetSubscriptionRepository().update({method: 'reactivate'}).then(() => {
      const intervalId = setInterval(() => {
        new NetCurrentUserRepository().find().then(currentUser => {
          if (!currentUser.paidSubscriberCancelAt) {
            clearInterval(intervalId);
            setLoading(false);
            setCurrentUser(currentUser);
            sendDefaultCustomEvent({
              resourceType: 'writer/paid_subscriber_user',
              resourceId: currentUser.id,
              actionName: 'update',
            });
          }
        });
      }, 1000);
    });
  }, []);
  return (
    <DefaultModal
      visible={true}
      disableCloseByOutsideTouch={true}
      onCloseModal={onCloseModal}>
      <Text
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          textAlign: 'center',
        }}>
        再度入会してもよろしいですか？
      </Text>
      <View
        style={{justifyContent: 'center', alignItems: 'center', margin: 32}}>
        <PrimaryButton style={{width: 250}} onPress={onPress}>
          再度会員になる
        </PrimaryButton>
      </View>
    </DefaultModal>
  );
};

export default React.memo(ReactivateModal);
