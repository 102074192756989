import * as React from 'react';
import {RefreshControl, ScrollView} from 'react-native';

import Form from './partials/Form';

import Layout from '../../../shared/Layout';
import Alert from '../../../shared/alert/Alert';

import NavigationProp from '../../../../navigators/NavigationProp';

import * as routers from '../../../../routers';

import {Params as UserRegistrationUpdateParams} from '../../../../actions/writer/users/registration/update';

import {formatErrorMessages} from '../../../../helpers/errorMessages';

import UserRegistrationEmailEditForm from '../../../../../domain/forms/writer/UserRegistrationEmailEditForm';
import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<CurrentUser>;
  updateUserRegistration: (
    params: UserRegistrationUpdateParams,
  ) => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  refreshing: boolean;
}

export default class Edit extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      refreshing: false,
    };
  }

  public componentDidMount() {
    this.mounted = true;
    this.fetchData();
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {navigation, currentUser} = this.props;
    const {loading, refreshing} = this.state;
    return (
      <Layout
        title={'メールアドレス変更'}
        navigation={navigation}
        back={true}
        loading={loading}
        scrollable={false}>
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={this.handleRefresh}
            />
          }>
          {currentUser && (
            <Form currentUser={currentUser} onSubmit={this.handleSubmit} />
          )}
        </ScrollView>
      </Layout>
    );
  }

  private handleSubmit = (
    userRegistrationEmailEditForm: UserRegistrationEmailEditForm,
  ) => {
    const {navigation, updateUserRegistration} = this.props;
    this.setStateIfMounted({loading: true}, () => {
      updateUserRegistration(userRegistrationEmailEditForm.getParams())
        .then(() => {
          this.setStateIfMounted({loading: false});
          routers.linkToWriterUserAccount(navigation, {
            showSentVerifyEmailModal: true,
          });
        })
        .catch(err => {
          this.setStateIfMounted({loading: false}, () => {
            setTimeout(() => {
              Alert.alert(formatErrorMessages({}, err));
            }, 300);
          });
        });
    });
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ) {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }

  private handleRefresh = () => {
    this.fetchData();
  };

  private fetchData = () => {
    const {showCurrentUser} = this.props;
    showCurrentUser();
  };
}
