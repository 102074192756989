import React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';

interface Props {
  onPress: () => void;
}

const CloseButton: React.FC<Props> = props => {
  const {onPress} = props;
  return (
    <View style={styles.container}>
      <DefaultOutlineButton onPress={onPress}>閉じる</DefaultOutlineButton>
    </View>
  );
};

export default React.memo(CloseButton);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    position: 'absolute',
    bottom: 16,
    left: 0,
    right: 0,
  } as ViewStyle,
});
