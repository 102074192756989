import EntityMapper from '../EntityMapper';

import NotificationSettingCategory from '../../../../domain/entities/writer/NotificationSettingCategory';

export interface NotificationSettingCategoryData {
  id: number;
  name: string;
}

export default class NotificationSettingCategoryMapper
  implements
    EntityMapper<NotificationSettingCategoryData, NotificationSettingCategory>
{
  public map(
    obj: NotificationSettingCategoryData,
  ): NotificationSettingCategory {
    return new NotificationSettingCategory(obj.id, obj.name);
  }
}
