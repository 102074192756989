import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics';

import ManualCategory from '../../../../domain/entities/writer/ManualCategory';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetManualCategoriesRepository from '../../../../data/repositories/writer/NetManualCategoriesRepository';

const types = {
  failure: Types.IndexWriterManualCategoriesFailure,
  request: Types.IndexWriterManualCategoriesRequest,
  success: Types.IndexWriterManualCategoriesSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<ManualCategory>> {
  return indexGenerics(new NetManualCategoriesRepository(), types, params);
}
