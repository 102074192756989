import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import Alert from '../../shared/alert/Alert';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewWriterUserSessionRouteProp} from '../../../navigators/RouteProps';

import * as routers from '../../../routers';

import {Params as SessionCreateParams} from '../../../actions/writer/users/session/create';
import {Params as FacebookAccountSessionCreateParams} from '../../../actions/writer/social_accounts/facebook_accounts/session/create';
import {Params as LineAccountSessionCreateParams} from '../../../actions/writer/social_accounts/line_accounts/session/create';
import {Params as TwitterAccountSessionCreateParams} from '../../../actions/writer/social_accounts/twitter_accounts/session/create';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import UserSessionForm from '../../../../domain/forms/writer/UserSessionForm';
import {
  ErrorData,
  SuccessData,
} from './../../../../domain/services/social_logins/SocialLogin';

export interface Params {
  hideClose?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewWriterUserSessionRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  createUserSession: (params: SessionCreateParams) => Promise<CurrentUser>;
  createFacebookAccountSession: (
    params: FacebookAccountSessionCreateParams,
  ) => Promise<CurrentUser>;
  createLineAccountSession: (
    params: LineAccountSessionCreateParams,
  ) => Promise<CurrentUser>;
  createTwitterAccountSession: (
    params: TwitterAccountSessionCreateParams,
  ) => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  form: UserSessionForm;
  errorMessages: {
    email: Array<string> | undefined;
    password: Array<string> | undefined;
    error: string | undefined;
  } | null;
}

export default class New extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    const form = new UserSessionForm();
    this.state = {loading: false, form, errorMessages: null};
  }

  public componentDidMount() {
    const {navigation} = this.props;
    this.mounted = true;
    navigation.addListener('focus', this.redirectIfUserStatus);
  }

  public componentWillUnmount() {
    const {navigation} = this.props;
    this.mounted = false;
    navigation.removeListener('focus', this.redirectIfUserStatus);
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {loading, form, errorMessages} = this.state;

    return (
      <Layout
        title={'ログイン'}
        navigation={navigation}
        close={true}
        loading={loading}
        hideHeaderBottomBorder={true}
        scrollViewStyle={{backgroundColor: '#fafafa'}}>
        <Form
          form={form}
          errorMessages={errorMessages}
          onChangeEmail={this.handleChangeEmail}
          onChangePassword={this.handleChangePassword}
          onSubmit={this.handleSubmit}
          onForwardToSignup={this.handleForwardToSignup}
          onForwardToNewPassword={this.handleForwardToNewPassword}
          onSuccessFacebookLogin={this.handleSuccessFacebookLogin}
          onErrorFacebookLogin={this.handleErrorFacebookLogin}
          onSuccessTwitterLogin={this.handleSuccessTwitterLogin}
          onErrorTwitterLogin={this.handleErrorTwitterLogin}
          onSuccessLineLogin={this.handleSuccessLineLogin}
          onErrorLineLogin={this.handleErrorLineLogin}
        />
      </Layout>
    );
  }

  private handleChangeEmail = (email: string) => {
    const form = new UserSessionForm();
    form.email = email;
    form.password = this.state.form.password;

    this.setStateIfMounted({form});
  };

  private handleChangePassword = (password: string) => {
    const form = new UserSessionForm();
    form.email = this.state.form.email;
    form.password = password;
    this.setStateIfMounted({form});
  };

  private handleSubmit = () => {
    const {navigation, createUserSession} = this.props;
    const {form} = this.state;
    this.setStateIfMounted({loading: true}, () => {
      createUserSession(form.toParams())
        .then(currentUser => {
          this.setStateIfMounted({loading: false});
          if (!currentUser.confirmed) {
            (navigation as any).replace('WriterUserRegistrationNavigation', {
              screen: 'WriterTemporaryUserRegistrationCompletion',
              params: {},
            });
          } else if (!currentUser.acceptedTerms) {
            (navigation as any).replace('WriterUserRegistrationNavigation', {
              screen: 'NewWriterTemporaryUsersTermsAcceptance',
              params: {},
            });
          } else if (currentUser.enabled) {
            routers.linkToHome(navigation, {});
          } else {
            routers.replaceToEditWriterUser(navigation, {});
          }
        })
        .catch(error => {
          this.setStateIfMounted({loading: false, errorMessages: error.body});
        });
    });
  };

  private handleForwardToSignup = () => {
    const {navigation, route} = this.props;
    const params = route.params;
    routers.replaceToNewWriterUserRegistration(navigation, params);
  };

  private handleForwardToNewPassword = () => {
    const {navigation, route} = this.props;
    navigation.navigate('WriterUserPasswordNavigation', {});
  };

  private handleSuccessFacebookLogin = (data: SuccessData) => {
    const {navigation, createFacebookAccountSession} = this.props;

    const accessToken = data.accessToken;
    if (accessToken) {
      createFacebookAccountSession({
        accessToken,
        userId: data.id,
      })
        .then(() => {
          navigation.pop();
        })
        .catch(e => {
          switch (e.status) {
            case 401:
              routers.replaceToNewWriterUserRegistration(navigation, {
                email: data.email,
                options: {
                  facebookAccount: {
                    accessToken,
                    screenName: data.screenName,
                    userId: data.id,
                  },
                },
                penName: data.screenName,
              });
              break;
            case 422:
              Alert.alert('トークンが不正です');
              break;
          }
        });
    }
  };

  private handleErrorFacebookLogin = (data: ErrorData) => {
    /* TODO */
  };

  private handleSuccessLineLogin = (data: SuccessData) => {
    const {navigation, createLineAccountSession} = this.props;
    if (!data.accessToken) {
      return;
    }
    const accessToken = data.accessToken;
    createLineAccountSession({
      accessToken: data.accessToken,
      userId: data.id,
    })
      .then(() => {
        navigation.pop();
      })
      .catch(e => {
        switch (e.status) {
          case 401:
            routers.replaceToNewWriterUserRegistration(navigation, {
              email: data.email,
              options: {
                lineAccount: {
                  accessToken,
                  screenName: data.screenName,
                  userId: data.id,
                },
              },
              penName: data.screenName,
            });

            break;
          case 422:
            Alert.alert('トークンが不正です');
            break;
        }
      });
  };

  private handleErrorLineLogin = (data: ErrorData) => {
    /* TODO */
  };

  private handleSuccessTwitterLogin = (data: SuccessData) => {
    const {navigation, createTwitterAccountSession} = this.props;
    if (!data.accessToken || !data.accessTokenSecret) {
      return;
    }
    const accessToken = data.accessToken;
    const accessTokenSecret = data.accessTokenSecret;
    createTwitterAccountSession({
      accessToken: data.accessToken,
      accessTokenSecret: data.accessTokenSecret,
      userId: data.id,
    })
      .then(() => {
        navigation.pop();
      })
      .catch(e => {
        switch (e.status) {
          case 401:
            routers.replaceToNewWriterUserRegistration(navigation, {
              email: data.email,
              options: {
                twitterAccount: {
                  accessToken,
                  accessTokenSecret,
                  screenName: data.screenName,
                  userId: data.id,
                },
              },
              penName: data.screenName,
            });

            break;
          case 422:
            Alert.alert('トークンが不正です');
            break;
        }
      });
  };

  private handleErrorTwitterLogin = (data: ErrorData) => {
    /* TODO */
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ) {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }

  private redirectIfUserStatus = () => {
    const {navigation, currentUser} = this.props;
    if (currentUser) {
      if (!currentUser.confirmed) {
        // nop
      } else if (!currentUser.acceptedTerms) {
        routers.linkToWriterUserRegistrationNavigation(
          navigation,
          {},
          'NewWriterTemporaryUsersTermsAcceptance',
        );
      } else if (!currentUser.enabled) {
        routers.linkToWriterUserRegistrationNavigation(
          navigation,
          {},
          'EditWriterUser',
        );
      } else {
        navigation.goBack();
      }
    }
  };
}
