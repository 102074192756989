import Entity from '../Entity';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class ActorFacePart implements Entity {
  constructor(
    public id: number,
    public actorId: number,
    public facePatternId: number,
    public facePatternName: string,
    public colorPatternId: number,
    public colorPatternName: string,
    public actorName: string,
    public actorOriginalImageUrl: string,
    public originalImageUrl: string,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getHalfImageUrl(): string {
    return ImageUrlHelper.getHalfImageUrl(this.originalImageUrl);
  }

  public getOneThirdImageUrl(): string {
    return ImageUrlHelper.getOneThirdImageUrl(this.originalImageUrl);
  }

  public getActorImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.actorOriginalImageUrl, options);
  }

  public getActorHalfImageUrl(): string {
    return ImageUrlHelper.getHalfImageUrl(this.actorOriginalImageUrl);
  }

  public getActorOneThirdImageUrl(): string {
    return ImageUrlHelper.getOneThirdImageUrl(this.actorOriginalImageUrl);
  }

  public getLabel(): string {
    return this.actorName;
  }
}
