import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import EpisodeListItem from './EpisodeListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Episode from '../../../../../domain/entities/writers_gate/Episode';

interface Props {
  episodes: Array<Episode> | null;
  onSelectEpisode: (episode: Episode) => void;
}

const EpisodeList: React.FC<Props> = props => {
  const {episodes, onSelectEpisode} = props;
  const keyExtractor = React.useCallback(
    (episode: Episode) => `${episode.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Episode>) => (
      <EpisodeListItem episode={info.item} onSelectEpisode={onSelectEpisode} />
    ),
    [],
  );
  return (
    <FlatList
      data={episodes}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(EpisodeList);
