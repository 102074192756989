import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../shared/buttons/PrimaryButton';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  projectId: number;
  isLeader?: boolean;
  onForceUpdate: () => void;
}

const NoActiveIteration: React.FC<Props> = props => {
  const {projectId, isLeader, onForceUpdate} = props;
  const onPress = React.useCallback(() => {
    TapNovelTypedRestApi.post('/api/writer/leader/project_iterations', {
      projectId,
    }).then(onForceUpdate);
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        まだタームが開始されていません。{'\n'}
        タームが開始されるまでしばらくお待ちください。
      </Text>
      {isLeader && (
        <PrimaryButton onPress={onPress}>タームを開始する</PrimaryButton>
      )}
    </View>
  );
};

export default React.memo(NoActiveIteration);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 56,
  } as TextStyle,
});
