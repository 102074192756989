import * as React from 'react';
import {useActionSheet} from '@expo/react-native-action-sheet';

import SceneList from './SceneList';

import DimensionContext from '../../shared/dimension/DimensionContext';

import Scene from '../../../../domain/entities/Scene';

interface Props {
  scenes: Scene[];
  idToCopyLabel?: {[key: number]: string};
  renderHeader?: () => React.ReactElement;
  renderFooter?: () => React.ReactElement;
  onSelectScene: (scene: Scene) => void;
  onEditScene: (scene: Scene) => void;
  onDeleteScene: (scene: Scene) => void;
  onCopyScene: (scene: Scene) => void;
  onChangeOrder: (scene: Scene, rowOrderPosition: number) => void;
  onRenderError: (message: string) => void;
}

const SceneListSection: React.FunctionComponent<Props> = props => {
  const {
    scenes,
    idToCopyLabel,
    renderHeader,
    renderFooter,
    onSelectScene,
    onChangeOrder,
  } = props;
  const {showActionSheetWithOptions} = useActionSheet();
  const onOpenActionSheet = React.useCallback((scene: Scene) => {
    const options = ['シーン情報編集', 'コピー', '削除', 'キャンセル'];
    const editSceneIndex = 0;
    const copyButtonIndex = 1;
    const destructiveButtonIndex = 2;
    const cancelButtonIndex = 3;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        destructiveButtonIndex,
        options,
      },
      async (buttonIndex?: number) => {
        switch (buttonIndex) {
          case editSceneIndex:
            props.onEditScene(scene);
            break;
          case destructiveButtonIndex:
            props.onDeleteScene(scene);
            break;
          case copyButtonIndex:
            props.onCopyScene(scene);
            break;
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, []);
  return (
    <DimensionContext.Consumer>
      {context => {
        return (
          <SceneList
            scenes={scenes}
            contentWidth={context.content.width}
            idToCopyLabel={idToCopyLabel}
            renderHeader={renderHeader}
            renderFooter={renderFooter}
            onSelectScene={onSelectScene}
            onOpenActionSheet={onOpenActionSheet}
            onChangeOrder={onChangeOrder}
          />
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default SceneListSection;
