import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import AccountBillingHistory from '../../../containers/account/billing/history/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="AccountBillingHistory">
      <RootStack.Screen
        name="AccountBillingHistory"
        component={AccountBillingHistory}
        options={{
          title: 'お支払い履歴',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
