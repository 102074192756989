import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EpisodeVideoExportJobExecutionMapper, {
  EpisodeVideoExportJobExecutionData,
} from '../../entities/mappers/writer/EpisodeVideoExportJobExecutionMapper';

import EpisodeVideoExportJobExecution from '../../../domain/entities/writer/EpisodeVideoExportJobExecution';
import EpisodeVideoExportJobExecutionsRepository from '../../../domain/repositories/writer/EpisodeVideoExportJobExecutionsRepository';

export default class NetEpisodeVideoExportJobExecutionsRepository
  extends NetResourcesRepository<
    EpisodeVideoExportJobExecution,
    EpisodeVideoExportJobExecutionData
  >
  implements EpisodeVideoExportJobExecutionsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/episode_video_export_job_executions',
        new EpisodeVideoExportJobExecutionMapper(),
      ),
    );
  }
}
