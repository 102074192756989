import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import EpisodeComment from '../../../domain/entities/EpisodeComment';

import NetEpisodeCommentsRepository from '../../../data/repositories/writer/NetEpisodeCommentsRepository';

const types = {
  failure: Types.ShowEpisodeCommentFailure,
  request: Types.ShowEpisodeCommentRequest,
  success: Types.ShowEpisodeCommentSuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<EpisodeComment> {
  return showGeneric(new NetEpisodeCommentsRepository(), types, id);
}
