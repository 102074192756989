import ActorCharacterFace from '../../domain/entities/ActorCharacterFace';
import CharacterPattern from '../../domain/entities/CharacterPattern';

import Position from '../../domain/value_objects/Position';

import NetActorCharacterFacesRepository from '../../data/repositories/writer/NetActorCharacterFacesRepository';

export default class CharacterPatternMapping {
  private characterPatternMapping: {
    [key: number]: {
      [position: number]: CharacterPattern;
    };
  };
  private actorCharacterFaceMapping: {
    [key: number]: {
      [position: number]: ActorCharacterFace;
    };
  };

  private repository: NetActorCharacterFacesRepository;

  constructor() {
    this.characterPatternMapping = {};
    this.actorCharacterFaceMapping = {};
    this.repository = new NetActorCharacterFacesRepository();
  }

  public getCharacterPatternAndActorCharacterFace = (
    oldCharacterPattern: CharacterPattern,
    oldActorCharacterFace: ActorCharacterFace,
    position: Position,
  ) => {
    const newCharacterPattern = this.characterPatternMapping[
      oldCharacterPattern.id
    ]
      ? this.characterPatternMapping[oldCharacterPattern.id][position]
      : null;
    const newActorCharacterFace = this.actorCharacterFaceMapping[
      oldActorCharacterFace.id
    ]
      ? this.actorCharacterFaceMapping[oldActorCharacterFace.id][position]
      : null;
    if (newCharacterPattern && newActorCharacterFace) {
      return {
        characterPattern: newCharacterPattern,
        actorCharacterFace: newActorCharacterFace,
      };
    }
    return null;
  };

  public register = async (
    oldCharacterPattern: CharacterPattern,
    newCharacterPattern: CharacterPattern,
    position: Position,
  ) => {
    const oldActorCharacterFaces = await this.repository.findBy({
      actorCharacterId: oldCharacterPattern.actorCharacter.id,
    });
    const newActorCharacterFaces = await this.repository.findBy({
      actorCharacterId: newCharacterPattern.actorCharacter.id,
    });
    if (!this.characterPatternMapping[oldCharacterPattern.id]) {
      this.characterPatternMapping[oldCharacterPattern.id] = {};
    }
    this.characterPatternMapping[oldCharacterPattern.id][position] =
      newCharacterPattern;
    oldActorCharacterFaces.records.forEach(oldActorCharacterFace => {
      const foundNewActorCharacterFace = newActorCharacterFaces.records.find(
        newActorCharacterFace =>
          oldActorCharacterFace.faceName === newActorCharacterFace.faceName,
      );
      if (!this.actorCharacterFaceMapping[oldActorCharacterFace.id]) {
        this.actorCharacterFaceMapping[oldActorCharacterFace.id] = {};
      }
      if (foundNewActorCharacterFace) {
        this.actorCharacterFaceMapping[oldActorCharacterFace.id][position] =
          foundNewActorCharacterFace;
      } else {
        this.actorCharacterFaceMapping[oldActorCharacterFace.id][position] =
          newActorCharacterFaces.records[0];
      }
    });
  };
}
