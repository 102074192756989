import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import QuestionIcon from '../../../shared/icons/QuestionIcon';

interface Props {
  onPressRetentionRate: () => void;
}

const TitleWithQuestion: React.FunctionComponent<Props> = props => {
  const {onPressRetentionRate} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>閲覧維持率</Text>
      <Pressable onPress={onPressRetentionRate}>
        <View style={styles.question}>
          <QuestionIcon />
        </View>
      </Pressable>
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(TitleWithQuestion, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    //    margin: 16,
    marginBottom: 0,
  } as TextStyle,
  question: {
    marginLeft: 5,
  } as ViewStyle,
});
