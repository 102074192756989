import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import PlayIcon from '../icons/PlayIcon';
import StopIcon from '../icons/StopIcon';
import SoundPlayable from '../SoundPlayable';

import Sound from '../../../../domain/entities/Sound';

interface Props {
  sound: Sound;
  iconSize: number;
  labelStyle?: StyleProp<TextStyle>;
  muted?: boolean;
  playing?: boolean;
  onPlay?: () => void;
}

const SoundView: React.FunctionComponent<Props> = props => {
  const {sound, iconSize, labelStyle, muted, onPlay} = props;
  const [playing, setPlaying] = React.useState(props.playing || false);
  React.useEffect(() => {
    if (props.playing !== undefined) {
      setPlaying(props.playing);
    }
  }, [props.playing]);
  const handlePlay = React.useCallback(() => {
    setPlaying(!playing);
    onPlay && onPlay();
  }, [playing]);
  const handleFinishPlay = React.useCallback(() => {
    setPlaying(false);
  }, []);
  return (
    <TouchableOpacity style={styles.container} onPress={handlePlay}>
      {playing ? <StopIcon size={iconSize} /> : <PlayIcon size={iconSize} />}
      <Text style={[styles.label, labelStyle]}>{sound.name}</Text>
      {playing && !muted ? (
        <SoundPlayable uri={sound.audioUrl} onFinishPlay={handleFinishPlay} />
      ) : null}
    </TouchableOpacity>
  );
};

export default React.memo(SoundView);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  label: {
    color: '#222',
    fontWeight: 'bold',
  } as TextStyle,
});
