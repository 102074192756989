import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Show, {DispatchProps, StateProps} from '../../components/manuals/Show';

import AppState from '../../reducers/AppState';

import showManualCategory from '../../actions/writer/manual_categories/show';
import indexManuals, {
  Params as ManualIndexParams,
} from '../../actions/writer/manuals';

import selectEntities from '../../helpers/selectEntities';
import selectEntity from '../../helpers/selectEntity';
import {ManualRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: ManualRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries} = state;
  const {writerManualCategoryId} = route.params;
  const manualCategory = selectEntity(
    entities.writer.manualCategories,
    writerManualCategoryId,
  );
  const manualsParams = {writerManualCategoryId} as ManualIndexParams;
  const manuals = selectEntities(
    entities.writer.manuals,
    queries.writer.manuals,
    manualsParams,
  );
  return {
    manualCategory,
    manuals,
    manualsParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  props: Props,
) => {
  return {
    indexManuals: (params: ManualIndexParams) => dispatch(indexManuals(params)),
    showManualCategory: (id: number) => dispatch(showManualCategory(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
