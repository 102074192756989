import * as React from 'react';
import {
  FlatList,
  GestureResponderEvent,
  InteractionManager,
  ListRenderItemInfo,
  Platform,
  StyleProp,
  ViewStyle,
} from 'react-native';

interface Props<T> {
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: StyleProp<ViewStyle>;
  scrollEnabled?: boolean;
  data: T[];
  showsVerticalScrollIndicator?: boolean;
  horizontal?: boolean;
  renderRow: (rowData: T) => React.ReactElement<any>;
  ItemSeparatorComponent?: React.ComponentType<any>;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  numColumns?: number;
  blockAnimation?: boolean;
  onEndReachedThreshold?: number | null;
  extraData?: any;
  onTouchStart?: (event: GestureResponderEvent) => void;
  onTouchMove?: (event: GestureResponderEvent) => void;
  onTouchEnd?: (event: GestureResponderEvent) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
}

interface State {
  loaded: boolean;
}

export default class CommonListView<T> extends React.Component<
  Props<T>,
  State
> {
  private isUnmounted = false;

  constructor(props: Props<T>) {
    super(props);
    this.state = {loaded: false};
  }

  public componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      if (!this.isUnmounted) {
        this.setState({loaded: true});
      }
    });
  }

  public componentWillUnmount() {
    this.isUnmounted = true;
  }

  public render(): React.ReactNode {
    const {
      style,
      contentContainerStyle,
      scrollEnabled,
      data,
      showsVerticalScrollIndicator,
      horizontal,
      ItemSeparatorComponent,
      ListHeaderComponent,
      ListFooterComponent,
      ListEmptyComponent,
      numColumns,
      blockAnimation,
      extraData,
      onTouchStart,
      onTouchMove,
      onTouchEnd,
      onEndReached,
      onEndReachedThreshold,
    } = this.props;
    const {loaded} = this.state;
    if (blockAnimation && !loaded) {
      return null;
    }
    const webProps = Platform.select({
      web: {
        onMouseDown: onTouchStart,
        onMouseMove: onTouchMove,
        onMouseUp: onTouchEnd,
      },
      default: {},
    });
    return (
      <FlatList
        style={style}
        contentContainerStyle={contentContainerStyle}
        scrollEnabled={Platform.select({web: true, default: scrollEnabled})}
        showsVerticalScrollIndicator={
          showsVerticalScrollIndicator === undefined
            ? false
            : showsVerticalScrollIndicator
        }
        horizontal={horizontal}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onEndReached={onEndReached}
        onEndReachedThreshold={onEndReachedThreshold}
        data={data}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        ItemSeparatorComponent={ItemSeparatorComponent}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={ListFooterComponent || ItemSeparatorComponent}
        ListEmptyComponent={ListEmptyComponent}
        numColumns={numColumns}
        extraData={extraData}
        {...webProps}
      />
    );
  }

  private generateKey(row: any): string {
    if (row === null) {
      return 'null';
    } else if (row === undefined) {
      return 'undefined';
    } else if (
      typeof row === 'string' ||
      typeof row === 'number' ||
      typeof row === 'boolean'
    ) {
      return `${row}`;
    }
    return Object.keys(row)
      .map(key => `${key}_${this.generateKey(row[key])}`)
      .join('/');
  }

  private keyExtractor = (item: T, index: number): string => {
    return this.generateKey(item);
  };

  private renderItem = (
    info: ListRenderItemInfo<T>,
  ): React.ReactElement<any> | null => {
    const {renderRow} = this.props;
    return renderRow(info.item);
  };
}
