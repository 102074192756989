import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import TagRemoveButton from './TagRemoveButton';

import {colors} from '../../styles/variables';

interface Props {
  name: string;
  onRemove?: (name: string) => void;
}

export default class Tag extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {name, onRemove} = this.props;
    return (
      <View style={styles.wrapper}>
        <View style={styles.container}>
          <Text style={[styles.name, onRemove ? null : {marginRight: 20}]}>
            {name}
          </Text>
          {onRemove && <TagRemoveButton onPress={this.handlePress} />}
        </View>
      </View>
    );
  }

  private handlePress = () => {
    const {name, onRemove} = this.props;
    if (!onRemove) {
      return;
    }
    onRemove(name);
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
  } as ViewStyle,
  name: {
    color: colors.textColor,
    fontSize: 13,
    marginLeft: 20,
    marginRight: 2,
  } as TextStyle,
  wrapper: {
    backgroundColor: 'white',
    borderColor: colors.powderGray,
    borderRadius: 100,
    borderWidth: 1,
    height: 28,
    margin: 5,
  } as ViewStyle,
});
