import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';

import * as routers from '../../../routers';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import {Params as StoryCreateParams} from '../../../actions/stories/create';
import {Params as StoryFormUpdateParams} from '../../../actions/story_forms/update';

import Story, {Rating, Format} from '../../../../domain/entities/Story';
import StoryForm from '../../../../domain/forms/StoryForm';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  storyForm: StoryForm | null;
}

export interface DispatchProps {
  createStory: (params: StoryCreateParams) => Promise<Story>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class New extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public componentDidMount() {
    const {navigation, storyForm} = this.props;
    if (!storyForm) {
      navigation.replace('NewStory', {});
    }
  }

  public render(): React.ReactNode {
    const {navigation, storyForm} = this.props;
    const {loading, alertMessage} = this.state;
    return (
      <Layout
        title={'ストーリー情報'}
        navigation={navigation}
        back={true}
        close={true}
        hideHeaderBottomBorder={true}
        loading={loading}>
        {storyForm && (
          <Form
            storyForm={storyForm}
            submitButtonText={'決定'}
            onChangeCatchPhrase={this.handleChangeCatchPhrase}
            onToggleSerialized={this.handleToggleSerialized}
            onForwardToKeywords={this.handleForwardToKeywords}
            onChangeFormat={this.handleChangeFormat}
            onChangeRating={this.handleChangeRating}
            onSubmit={this.handleSubmit}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleChangeCatchPhrase = (catchPhrase: string) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({catchPhrase});
  };

  private handleToggleSerialized = (serialized: boolean) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({serialized});
  };

  private handleForwardToKeywords = (keywordNames: string[] | null) => {
    const {navigation} = this.props;
    routers.linkToStoryFormKeywords(navigation, {
      keywordNames,
      enableContests: true,
    });
  };

  private handleChangeRating = (rating: Rating) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({rating});
  };

  private handleChangeFormat = (format: Format) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({format});
  };

  private handleSubmit = () => {
    const {navigation, storyForm, createStory} = this.props;
    if (storyForm) {
      this.setState({loading: true}, () => {
        createStory(storyForm.toParams())
          .then(story => {
            this.setState({loading: false}, () => {
              (navigation.getParent() || navigation).goBack();
              navigation.navigate('Story', {storyId: story.id});
            });
          })
          .catch(error => {
            this.setState({
              loading: false,
              alertMessage: formatErrorMessages({}, error),
            });
          });
      });
    }
  };
}
