import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Illustration from '../../../domain/entities/Illustration';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetIllustrationsRepository from '../../../data/repositories/writer/NetIllustrationsRepository';

const types = {
  failure: Types.IndexIllustrationsFailure,
  request: Types.IndexIllustrationsRequest,
  success: Types.IndexIllustrationsSuccess,
};

export interface Params extends BaseParams {
  allIllustrationCategoryId?: number;
  illustrationCategoryId?: number;
  query?: string;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
  storyId?: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Illustration>> {
  return indexGenerics(new NetIllustrationsRepository(), types, params);
}
