// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Options, Result} from './RestApi';

import {camelizeKeys} from 'humps';

import TapNovelRestApi from './TapNovelRestApi';

interface Params {
  [key: string]: any;
}

export type Result<T> = Result<T>;

export default class TapNovelTypedRestApi {
  public static get<T>(path: string, params?: Params): Promise<Result<T>> {
    return TapNovelRestApi.get<any>(path, params).then(res => {
      return {
        body: camelizeKeys(res.body as any) as T,
        headers: res.headers,
        status: res.status,
      };
    });
  }

  public static post<T>(
    path: string,
    params?: Params,
    options?: Options,
  ): Promise<Result<T>> {
    return TapNovelRestApi.post<any>(path, params, options).then(res => {
      return {
        body: camelizeKeys(res.body as any) as T,
        headers: res.headers,
        status: res.status,
      };
    });
  }

  public static patch<T>(
    path: string,
    params?: Params,
    options?: Options,
  ): Promise<Result<T>> {
    return TapNovelRestApi.patch<any>(path, params, options).then(res => {
      return {
        body: camelizeKeys(res.body as any) as T,
        headers: res.headers,
        status: res.status,
      };
    });
  }

  public static delete(
    path: string,
    params?: Params,
    options?: Options,
  ): Promise<void> {
    return TapNovelRestApi.delete(path, params, options);
  }
}
