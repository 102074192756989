import NetResourceRepository from '../../../NetResourceRepository';
import NetResourceRepositoryAdapter from '../../../NetResourceRepositoryAdapter';

import OauthAccountMapper, {
  OauthAccountData,
} from '../../../../entities/mappers/OauthAccountMapper';

import OauthAccount from '../../../../../domain/entities/OauthAccount';
import RegistrationRepository from '../../../../../domain/repositories/writer/social_accounts/facebook_accounts/RegistrationRepository';

export default class NetRegistrationRepository
  extends NetResourceRepository<OauthAccount, OauthAccountData>
  implements RegistrationRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/writer/social_accounts/facebook_accounts/registration',
        new OauthAccountMapper(),
      ),
    );
  }
}
