import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import StoryList from './partials/StoryList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {CommercialVideoStoriesRouteProp} from '../../../navigators/RouteProps';

import Story from '../../../../domain/entities/writer/vertical_short_video/Story';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: CommercialVideoStoriesRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation} = props;
  const [stories, setStories] = React.useState<Array<Story> | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelRestApi.get('/api/writer/vertical_short_video/stories').then(
        response => {
          setStories(camelizeKeys(response.body as any) as Array<Story>);
        },
      );
    }, []),
  );
  const onSelectStory = React.useCallback((story: Story) => {
    navigation.navigate('CommercialVideoStory', {storyId: story.id});
  }, []);
  return (
    <Layout
      title={'CM動画を作成するストーリーを選択'}
      navigation={navigation}
      back={true}>
      <StoryList stories={stories} onSelectStory={onSelectStory} />
    </Layout>
  );
};

export default React.memo(Index);
