import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Show, {DispatchProps, StateProps} from '../../components/episodes/Show';

import AppState from '../../reducers/AppState';

import showEpisode from '../../actions/episodes/show';
import showStory from '../../actions/stories/show';
import indexScenes, {
  Params as SceneIndexParams,
} from '../../actions/scenes/index';
import createScene, {
  Params as SceneCreateParams,
} from '../../actions/scenes/create';
import updateScene, {
  Params as SceneUpdateParams,
} from '../../actions/scenes/update';
import destroyScene from '../../actions/scenes/destroy';
import updateCoachmarkModal, {
  Params as CoachmarkModalUpdateParams,
} from '../../actions/coachmark_modal/update';
import updateApplicationModal, {
  Params as ApplicationModalUpdateParams,
} from '../../actions/application_modal/update';

import selectEntities from '../../helpers/selectEntities';
import selectEntity from '../../helpers/selectEntity';

import {EpisodeRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EpisodeRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, modals} = state;
  const {episodeId, page} = route.params;
  const scenesParams = {episodeId, page};
  const episode = selectEntity(entities.episodes, episodeId);
  const story = episode
    ? selectEntity(entities.stories, episode.storyId)
    : null;
  const scenes = selectEntities(entities.scenes, queries.scenes, scenesParams);
  return {
    navigation,
    episode,
    story,
    scenes,
    scenesParams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showEpisode: (id: number) => dispatch(showEpisode(id)),
    showStory: (id: number) => dispatch(showStory(id)),
    indexScenes: (params: SceneIndexParams) => dispatch(indexScenes(params)),
    createScene: (params: SceneCreateParams) => dispatch(createScene(params)),
    updateScene: (id: number, params: SceneUpdateParams) =>
      dispatch(updateScene(id, params)),
    destroyScene: (id: number) => dispatch(destroyScene(id)),
    updateCoachmarkModal: (params: CoachmarkModalUpdateParams) =>
      dispatch(updateCoachmarkModal(params)),
    updateApplicationModal: (params: ApplicationModalUpdateParams) =>
      dispatch(updateApplicationModal(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
