import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ActorCostumeMapper, {
  ActorCostumeData,
} from '../../entities/mappers/ActorCostumeMapper';

import ActorCostume from '../../../domain/entities/ActorCostume';
import ActorCostumesRepository from '../../../domain/repositories/writer/ActorCostumesRepository';

export default class NetActorCostumesRepository
  extends NetResourcesRepository<ActorCostume, ActorCostumeData>
  implements ActorCostumesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/actor_costumes',
        new ActorCostumeMapper(),
      ),
    );
  }
}
