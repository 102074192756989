import * as React from 'react';
import {FlatList, StyleProp, StyleSheet, ViewStyle} from 'react-native';

import SpeechBalloonGridListItem from './SpeechBalloonGridListItem';

import shouldUpdateEntityList from '../../../../../shared/enhanced/shouldUpdateEntityList';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';

import SpeechBalloon from '../../../../../../../domain/entities/SpeechBalloon';

interface Props {
  style?: StyleProp<ViewStyle>;
  speechBalloons: SpeechBalloon[];
  selectedSpeechBalloonId: number;
  onSelectSpeechBalloon: (speechBalloon: SpeechBalloon) => void;
}

export default class SpeechBalloonGridList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return (
      shouldUpdateEntityList(
        {entities: this.props.speechBalloons},
        {entities: nextProps.speechBalloons},
      ) ||
      this.props.selectedSpeechBalloonId !==
        nextProps.selectedSpeechBalloonId ||
      this.props.style !== nextProps.style
    );
  }

  public render(): React.ReactNode {
    const {
      style,
      speechBalloons,
      selectedSpeechBalloonId,
      onSelectSpeechBalloon,
    } = this.props;
    return (
      <DimensionContext.Consumer>
        {context => {
          const width =
            (context.content.width - CONTAINER_MARGIN_HORIZONTAL * 2) /
            NUM_COLUMNS;
          return (
            <FlatList
              style={[styles.container, style]}
              data={speechBalloons}
              keyExtractor={this.keyExtractor}
              numColumns={NUM_COLUMNS}
              renderItem={info => (
                <SpeechBalloonGridListItem
                  speechBalloon={info.item}
                  selectedSpeechBalloonId={selectedSpeechBalloonId}
                  width={width}
                  onSelectSpeechBalloon={onSelectSpeechBalloon}
                />
              )}
              extraData={selectedSpeechBalloonId}
            />
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private keyExtractor = (item: SpeechBalloon) => `${item.id}`;
}

const CONTAINER_MARGIN_HORIZONTAL = 16;

const NUM_COLUMNS = 2;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: CONTAINER_MARGIN_HORIZONTAL,
  } as ViewStyle,
  imageWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'transparent',
    borderWidth: 2,
    borderRadius: 4,
  } as ViewStyle,
  imageWrapperActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
});
