import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import ActorHairStyleMapper, {
  ActorHairStyleData,
} from '../../../entities/mappers/character_maker/ActorHairStyleMapper';

import PaginatedResult from '../../../../domain/results/PaginatedResult';
import ActorHairStyle from '../../../../domain/entities/character_maker/ActorHairStyle';
import ActorHairStylesRepository from '../../../../domain/repositories/writer/character_maker/ActorHairStylesRepository';

export default class NetActorHairStylesRepository
  extends NetResourcesRepository<ActorHairStyle, ActorHairStyleData>
  implements ActorHairStylesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_maker/actor_hair_styles',
        new ActorHairStyleMapper(),
      ),
    );
  }

  public async findBy(params: {
    actorId: number;
    name?: string;
    colorPatternId?: number;
  }): Promise<PaginatedResult<ActorHairStyle>> {
    return super.findBy(params);
  }
}
