import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import EpisodeVideoExportJobExecutionListWithNewButton from './partials/EpisodeVideoExportJobExecutionListWithNewButton';
import ServiceExplanation from './partials/ServiceExplanation';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {CommercialVideoExportJobExecutionsRouteProp} from '../../../navigators/RouteProps';

import EpisodeVideoExportJobExecution from '../../../../domain/entities/writer/EpisodeVideoExportJobExecution';
import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';
import NetEpisodeVideoExportJobExecutionsRepository from '../../../../data/repositories/writer/NetEpisodeVideoExportJobExecutionsRepository';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: CommercialVideoExportJobExecutionsRouteProp;
}

export interface DispatchProps {
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, showCurrentUserStatus} = props;
  const [episodeVideoExportJobExecutions, setEpisodeVideoExportJobExecutions] =
    React.useState<Array<EpisodeVideoExportJobExecution> | null>(null);
  const [currentUserStatus, setCurrentUserStatus] =
    React.useState<CurrentUserStatus | null>(null);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    showCurrentUserStatus().then(setCurrentUserStatus);
  }, []);
  useFocusEffect(
    React.useCallback(() => {
      new NetEpisodeVideoExportJobExecutionsRepository()
        .findBy({videoType: 'vertical_short'})
        .then(result => {
          setEpisodeVideoExportJobExecutions(result.records);
        });
    }, []),
  );
  const onSelectEpisodeVideoExportJobExecution = React.useCallback(
    (episodeVideoExportJobExecution: EpisodeVideoExportJobExecution) => {
      navigation.navigate('CommercialVideoExportJobExecution', {
        exportJobExecutionId: episodeVideoExportJobExecution.id,
      });
    },
    [],
  );
  const onDeleteEpisodeVideoExportJobExecution = React.useCallback(
    (episodeVideoExportJobExecution: EpisodeVideoExportJobExecution) => {
      new NetEpisodeVideoExportJobExecutionsRepository()
        .destroy(episodeVideoExportJobExecution.id)
        .then(() => {
          new NetEpisodeVideoExportJobExecutionsRepository()
            .findBy({videoType: 'vertical_short'})
            .then(result => {
              setEpisodeVideoExportJobExecutions(result.records);
            });
        });
    },
    [],
  );
  const onForwardToNewEpisodeVideoExportJobExecution = React.useCallback(() => {
    navigation.navigate('CommercialVideoStories', {});
  }, []);
  const onAgreeServicePolicy = React.useCallback(() => {
    setLoading(true);
    TapNovelRestApi.post('/api/writer/user_service_agreements', {
      writer_user_service_agreement: {
        writer_service_agreement_code: 'commercial_video',
      },
    })
      .then(response => {
        setTimeout(() => {
          showCurrentUserStatus().then(setCurrentUserStatus);
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Layout
      title={'CM動画一覧'}
      navigation={navigation}
      scrollable={false}
      back={true}
      loading={loading}>
      {currentUserStatus ? (
        currentUserStatus.serviceAgreementCodes.includes('commercial_video') ? (
          episodeVideoExportJobExecutions ? (
            <EpisodeVideoExportJobExecutionListWithNewButton
              episodeVideoExportJobExecutions={episodeVideoExportJobExecutions}
              onSelectEpisodeVideoExportJobExecution={
                onSelectEpisodeVideoExportJobExecution
              }
              onDeleteEpisodeVideoExportJobExecution={
                onDeleteEpisodeVideoExportJobExecution
              }
              onForwardToNewEpisodeVideoExportJobExecution={
                onForwardToNewEpisodeVideoExportJobExecution
              }
            />
          ) : null
        ) : (
          <ServiceExplanation onAgreeServicePolicy={onAgreeServicePolicy} />
        )
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
