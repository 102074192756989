import React from 'react';

import RootStack from '../../../../RootStack';

import SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm from '../../../../../containers/scene_form/background_show_scene_command/new/base_background_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm">
      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm"
        component={
          SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm
        }
        options={{title: '背景検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
