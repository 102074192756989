import * as React from 'react';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import DescriptiveTextView from './visualizations/DescriptiveTextView';

import {ModalParams} from '../../Modal';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';

interface Props {
  sceneCommandForm: DescriptiveTextShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestOpenModal: (modalParams: ModalParams) => void;
}

export default class DescriptiveTextShowSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, ['sceneCommandForm']);
  }

  public render(): React.ReactNode {
    const {sceneCommandForm} = this.props;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <DescriptiveTextView
            textFrame={sceneCommandForm.textFrame}
            text={sceneCommandForm.text}
            hasVoice={!!sceneCommandForm.voice}
            hasSound={!!sceneCommandForm.sound}
          />
        }
        onRequestOpenModal={this.handleRequestOpen}
      />
    );
  }

  private handleRequestOpen = () => {
    const {sceneCommandForm, parentSceneCommandForm, onRequestOpenModal} =
      this.props;
    onRequestOpenModal({
      type: 'DescriptiveTextShowSceneCommandModal',
      sceneCommandForm,
      parentSceneCommandForm,
    });
  };
}
