import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';

import Tutorial1Uri from '../../../../../assets/svgs/projects/tutorial-1.svg';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep1: React.FC<Props> = props => {
  const {onPress} = props;
  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.message}>
          タップライター同士でチームを組成し、{'\n'}
          作品を共同執筆することができます。
        </Text>
        <View style={styles.image}>
          <SvgUriAdapter uri={Tutorial1Uri} width={305} height={200} />
        </View>
        <PrimaryButton onPress={onPress}>
          説明を読んでプロジェクトに参加する
        </PrimaryButton>
      </View>
    </ScrollView>
  );
};

export default React.memo(ServiceExplanationStep1);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
  image: {
    marginTop: 16,
    marginBottom: 24,
  } as ViewStyle,
  title: {
    color: '#ff8f13',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
  note: {
    fontSize: 14,
    color: '#383838',
    textAlign: 'center',
    marginBottom: 32,
  } as TextStyle,
  agreement: {
    flexDirection: 'row',
  } as ViewStyle,
  agreementLink: {
    color: '#ff8f13',
    fontSize: 10,
    marginBottom: 18,
    textDecorationLine: 'underline',
  } as TextStyle,
  agreementText: {
    color: '#222',
    fontSize: 10,
    marginBottom: 18,
  } as TextStyle,
});
