import * as React from 'react';
import {Text, View} from 'react-native';

import DefaultModal from '../../../shared/modals/DefaultModal';

interface Props {
  onCloseModal: () => void;
}

const CancelCompleteModal: React.FunctionComponent<Props> = props => {
  const {onCloseModal} = props;
  return (
    <DefaultModal
      visible={true}
      disableCloseByOutsideTouch={true}
      onCloseModal={onCloseModal}>
      <Text style={{fontSize: 16, fontWeight: 'bold', textAlign: 'center'}}>
        MakerProを退会しました。
      </Text>

      <View
        style={{justifyContent: 'center', alignItems: 'center', margin: 24}}>
        <Text style={{marginBottom: 16, textAlign: 'center'}}>
          またの入会をお待ちしております。
        </Text>
      </View>
    </DefaultModal>
  );
};

export default React.memo(CancelCompleteModal);
