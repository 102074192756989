import EntityMapper from './EntityMapper';

import Sound from '../../../domain/entities/Sound';

export interface SoundData {
  id: number;
  name: string;
  audio_url: string;
  trimmed_audio_url?: string;
}

export default class SoundMapper implements EntityMapper<SoundData, Sound> {
  public map(obj: SoundData): Sound {
    return new Sound(obj.id, obj.name, obj.audio_url, obj.trimmed_audio_url);
  }
}
