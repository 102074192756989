import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  label: string;
  color: string;
  value: number;
  checked: boolean;
  onPress: (skinColorPatternId: number) => void;
}

const SikinColorButton: React.FC<Props> = props => {
  const {label, color, value, checked, onPress} = props;
  const handlePress = React.useCallback(() => onPress(value), []);
  return (
    <Pressable style={styles.container} onPress={handlePress}>
      <View
        style={[
          styles.circle,
          {backgroundColor: color},
          checked ? styles.circleActive : {},
        ]}
      />
      <Text style={[styles.label, checked ? styles.labelActive : {}]}>
        {label}
      </Text>
    </Pressable>
  );
};

export default React.memo(SikinColorButton);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  } as ViewStyle,
  circle: {
    width: 60,
    height: 60,
    borderRadius: 30,
  } as ViewStyle,
  circleActive: {
    width: 60,
    height: 60,
    borderWidth: 2,
    borderColor: '#ff8f13',
  } as ViewStyle,
  label: {
    color: '#999',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 4,
  } as TextStyle,
  labelActive: {
    color: '#ff8f13',
  } as TextStyle,
});
