import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../components/notifications/Index';

import AppState from '../../reducers/AppState';

import indexWriterUserNotifications, {
  Params as WriterUserNotificationIndexParams,
} from '../../actions/writer/user_notifications/index';
import updateWriterUserNotification, {
  Params as WriterUserNotificationUpdateParams,
} from '../../actions/writer/user_notifications/update';

import selectEntities from '../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {queries, entities} = state;
  const writerUserNotificationsParams: WriterUserNotificationIndexParams = {};
  const writerUserNotifications = selectEntities(
    entities.writer.userNotifications,
    queries.writer.userNotifications,
    writerUserNotificationsParams,
  );
  return {
    navigation,
    writerUserNotifications,
    writerUserNotificationsParams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexWriterUserNotifications: (params: WriterUserNotificationIndexParams) =>
      dispatch(indexWriterUserNotifications(params)),
    updateWriterUserNotification: (
      id: number,
      params: WriterUserNotificationUpdateParams,
    ) => dispatch(updateWriterUserNotification(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
