/*
export type DecodedIdToken = [
  {
    amr: [string];
    aud: string;
    exp: number;
    iat: number;
    iss: string;
    name: string;
    sub: string;
  },
  {
    typ: string;
    alg: string;
  }
];
*/

export type DecodedIdToken = Array<{[key: string]: any}>;

export default class LineAccessToken {
  constructor(
    public accessToken: string,
    public tokenType: string,
    public refreshToken: string,
    public expiresIn: string,
    public scope: string,
    public idToken: string,
    public decodedIdToken: DecodedIdToken,
  ) {}
}
