import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import TextArea from '../../../shared/forms/TextArea';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import ScaleModal from '../../../shared/modals/ScaleModal';

import {formStyles} from '../../../../styles/variables';

export type RejectFormValues = {
  reason: string;
};

interface Props {
  visible: boolean;
  onSubmit: (values: RejectFormValues) => void;
  onRequestClose: () => void;
}

const RejectFormModal: React.FC<Props> = props => {
  const {visible, onSubmit, onRequestClose} = props;
  const [reason, setReason] = React.useState('');
  const onPress = React.useCallback(() => {
    onSubmit({reason});
  }, [reason]);
  const disabled = reason.length <= 0 || reason.length > 400;
  return (
    <ScaleModal
      style={{width: 345}}
      visible={visible}
      onCloseModal={onRequestClose}>
      <Text
        style={{
          color: '#222',
          fontSize: 14,
          fontWeight: 'bold',
          marginTop: 32,
        }}>
        非承認理由
      </Text>
      <View style={[formStyles.formGroup, {width: '85%', marginTop: 16}]}>
        <LabelWithOption length={reason.length} maxLength={400} />
        <TextArea
          style={[
            styles.textArea,
            reason.length <= 400 ? undefined : styles.inputError,
          ]}
          value={reason}
          onChangeText={setReason}
        />
      </View>

      <PrimaryButton
        style={{marginBottom: 32}}
        buttonSize={{width: 160, height: 46}}
        disabled={disabled}
        onPress={onPress}>
        非承認にする
      </PrimaryButton>
    </ScaleModal>
  );
};

export default React.memo(RejectFormModal);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
  alert: {
    backgroundColor: '#fafafa',
    margin: 16,
    marginTop: 0,
    padding: 16,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  alertTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  } as ViewStyle,
  alertTitleText: {
    color: '#f23406',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  alertMessage: {
    color: '#f23406',
    fontSize: 11,
    textAlign: 'center',
  } as TextStyle,
});
