import EntityMapper from '../EntityMapper';

import Grade from '../../../../domain/entities/writer/Grade';

export interface GradeData {
  id: number;
  code: string;
  name: string;
  color: string;
}

export default class GradeMapper implements EntityMapper<GradeData, Grade> {
  public map(obj: GradeData): Grade {
    return new Grade(obj.id, obj.code, obj.name, obj.color);
  }
}
