import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/character_form/new/default_character_patterns/Index';

import AppState from '../../../../reducers/AppState';

import indexActorCostumes, {
  Params as ActorCostumeIndexParams,
} from '../../../../actions/actor_costumes/index';
import indexActorHairStyles, {
  Params as ActorHairStyleIndexParams,
} from '../../../../actions/actor_hair_styles/index';
import indexActorAccessories, {
  Params as ActorAccessoryIndexParams,
} from '../../../../actions/actor_accessories/index';
import indexActorAccessorySets, {
  Params as ActorAccessorySetIndexParams,
} from '../../../../actions/actor_accessory_sets/index';
import indexActorCharacterFaces, {
  Params as ActorCharacterFaceIndexParams,
} from '../../../../actions/actor_character_faces/index';
import updateCharacterForm, {
  Params as CharacterFormUpdateParams,
} from '../../../../actions/character_forms/update';

import selectEntity from '../../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../../helpers/ResourceAuthorizattion';

import {CharacterFormNewDefaultCharacterPatternsRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CharacterFormNewDefaultCharacterPatternsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities} = state;
  const {actorId}: ActorCostumeIndexParams = route.params;
  const actor = selectEntity(entities.actors, actorId);
  return {
    actor,
    navigation,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexActorCostumes: (params: ActorCostumeIndexParams) =>
      dispatch(indexActorCostumes(params)),
    indexActorHairStyles: (params: ActorHairStyleIndexParams) =>
      dispatch(indexActorHairStyles(params)),
    indexActorAccessories: (params: ActorAccessoryIndexParams) =>
      dispatch(indexActorAccessories(params)),
    indexActorAccessorySets: (params: ActorAccessorySetIndexParams) =>
      dispatch(indexActorAccessorySets(params)),
    indexActorCharacterFaces: (params: ActorCharacterFaceIndexParams) =>
      dispatch(indexActorCharacterFaces(params)),
    updateCharacterForm: (params: CharacterFormUpdateParams) =>
      dispatch(updateCharacterForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
