import * as React from 'react';
import {
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {useActionSheet} from '@expo/react-native-action-sheet';

import EpisodeList from './EpisodeList';
import NewEpisodeButton from './NewEpisodeButton';

import Episode from '../../../../domain/entities/Episode';

const options = ['コピー', '削除', 'キャンセル'];

interface Props {
  episodes: Episode[] | null;
  scrollEnabled?: boolean;
  sortingEnabled?: boolean;
  disableNewEpisode?: boolean;
  idToCopyLabel?: {[key: number]: string};
  onSelectEpisode: (episode: Episode) => void;
  onDeleteEpisode: (episode: Episode) => void;
  onCopyEpisode: (episode: Episode) => void;
  onLayout?: (event: LayoutChangeEvent) => void;
  onChangeOrder: (
    episode: Episode,
    rowOrderPosition: number,
    options?: {error?: () => void},
  ) => void;
  onStartSort?: () => void;
  onEndSort?: () => void;
  onForwardToNewEpisode: () => void;
}

const EpisodeListWithNewButton: React.FunctionComponent<Props> = props => {
  const {
    episodes,
    scrollEnabled,
    sortingEnabled,
    disableNewEpisode,
    idToCopyLabel,
    onSelectEpisode,
    onLayout,
    onChangeOrder,
    onStartSort,
    onEndSort,
    onForwardToNewEpisode,
  } = props;
  const {showActionSheetWithOptions} = useActionSheet();
  if (!episodes) {
    return null;
  }
  const onOpenActionSheet = React.useCallback((episode: Episode) => {
    const copyButtonIndex = 0;
    const destructiveButtonIndex = 1;
    const cancelButtonIndex = 2;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        destructiveButtonIndex,
        options,
      },
      (buttonIndex?: number) => {
        switch (buttonIndex) {
          case destructiveButtonIndex:
            props.onDeleteEpisode(episode);
            break;
          case copyButtonIndex:
            props.onCopyEpisode(episode);
            break;
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, []);
  return (
    <ScrollView bounces={false} scrollEnabled={scrollEnabled}>
      <View style={styles.container} onLayout={onLayout}>
        <EpisodeList
          episodes={episodes}
          sortingEnabled={sortingEnabled}
          idToCopyLabel={idToCopyLabel}
          onSelectEpisode={onSelectEpisode}
          onOpenActionSheet={onOpenActionSheet}
          onChangeOrder={onChangeOrder}
          onStartSort={onStartSort}
          onEndSort={onEndSort}
        />
        {disableNewEpisode ? null : (
          <NewEpisodeButton
            label={episodes.length === 0 ? '新しいエピソードをつくる' : null}
            onForwardToNewEpisode={onForwardToNewEpisode}
          />
        )}
      </View>
    </ScrollView>
  );
};

export default EpisodeListWithNewButton;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
  } as ViewStyle,
});
