import {Platform} from 'react-native';
import AccessToken from '../storage/AccessToken';

import {getUserAgent} from './UserAgent';

export interface Options {
  multipart?: boolean;
  onlyAccessToken?: boolean;
}

export function generateGetUrl(
  url: string,
  params?: {[key: string]: any} | null,
): string {
  const getParams = params ? params : {};
  const queryString = generateQueryString(getParams);
  if (queryString.length === 0) {
    return url;
  }
  return url + (url.indexOf('?') === -1 ? '?' : '&') + queryString;
}

function generateQueryString(params: {[key: string]: any}): string {
  return Object.keys(params)
    .filter(k => params[k] !== undefined)
    .map(k => {
      const v = params[k];
      if (Array.isArray(v)) {
        return Object.keys(v)
          .map(
            k2 =>
              encodeURIComponent(`${k}[]`) +
              '=' +
              (v !== null ? encodeURIComponent(v[Number(k2)]) : ''),
          )
          .join('&');
      } else {
        return (
          encodeURIComponent(k) +
          '=' +
          (v !== null ? encodeURIComponent(v) : '')
        );
      }
    })
    .join('&');
}

export async function generateHeaders(options?: Options): Promise<any> {
  const accessToken = await AccessToken.get();
  const userAgent = await getUserAgent();
  if (options && options.onlyAccessToken) {
    return {
      Authorization: accessToken,
    };
  }
  if (options && options.multipart) {
    return {
      Accept: 'application/json',
      Authorization: accessToken,
      'User-Agent': userAgent,
      ...Platform.select({
        web: {},
        default: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    };
  } else {
    return {
      Accept: 'application/json',
      Authorization: accessToken,
      'Content-Type': 'application/json',
      'User-Agent': userAgent,
    };
  }
}

export function generateBody(
  params?: {[key: string]: any} | null,
  options?: Options,
): FormData | string {
  const postParams = params ? params : {};
  if (options && options.multipart) {
    const formData = new FormData();
    buildFormData(postParams, formData);
    return formData;
  } else {
    return JSON.stringify(postParams);
  }
}

export function buildFormData(
  postParams: {[key: string]: any},
  formData: FormData,
  namespace?: string,
) {
  Object.keys(postParams).forEach(key => {
    const formKey = Array.isArray(postParams)
      ? `${namespace}[]`
      : namespace
      ? `${namespace}[${key}]`
      : key;
    const value = postParams[key];
    if (value && typeof value === 'object' && !isFileObject(value)) {
      buildFormData(value, formData, formKey);
    } else {
      if (value === undefined) {
        return;
      } else if (value === null) {
        formData.append(formKey, '');
      } else if (isFileObject(value)) {
        formData.append(formKey, value);
      } else {
        formData.append(formKey, value);
      }
    }
  });
}

export function isFileObject(obj: any): boolean {
  if (toString.call(obj) === '[object File]') {
    return true;
  }
  if (!obj) {
    return false;
  }
  if (!(typeof obj.uri === 'string')) {
    return false;
  }
  if (!(typeof obj.type === 'string')) {
    return false;
  }
  if (!(typeof obj.name === 'string')) {
    return false;
  }
  return true;
}
