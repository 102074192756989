import * as React from 'react';
import {
  ImageStyle,
  ImageURISource,
  ImageSourcePropType,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import EditableLaterMessage from '../../../../shared/EditableLaterMessage';
import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../../../shared/buttons/DefaultOutlineButton';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';
import CoverPicker from '../../../../shared/image_picker/CoverPicker';
import DimensionContext from '../../../../shared/dimension/DimensionContext';
import AlertModal from '../../../../shared/modals/AlertModal';
import TooltipModal from '../../../../shared/modals/TooltipModal';
import MaterialRightCheck from '../../../../shared/MaterialRightCheck';

import CoverImageForm from '../../../../../../domain/forms/CoverImageForm';

import convertImageSource from '../../../../../helpers/convertImageSource';

import File from '../../../../../../domain/entities/File';

type TooltipModalType = 'image';

const TypeToTooltipInfo = {
  image: {
    title: 'サムネイル',
    description: '',
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source: ImageSourcePropType;
    imageStyle?: ImageStyle;
  };
};

interface Props {
  aspectRatio: number;
  widthAspectRatio: number;
  coverImageForm: CoverImageForm | null;
  recommendedSize?: string;
  onChangeImage: (file: File) => void;
  onSubmit: () => void;
  onRequestReset: () => void;
  onForwardToImageSelection: () => void;
  onForwardToCharacterImageSelection: () => void;
  onForwardToTextInput: () => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {
    aspectRatio,
    widthAspectRatio,
    coverImageForm,
    recommendedSize,
    onChangeImage,
    onSubmit,
    onRequestReset,
    onForwardToImageSelection,
    onForwardToCharacterImageSelection,
    onForwardToTextInput,
  } = props;
  let coverSource: ImageURISource | ImageURISource[] | null = null;
  let disabled = true;
  const [confirmedMaterialRight, setConfirmedMaterialRight] =
    React.useState(false);
  if (coverImageForm?.image) {
    coverSource = {uri: coverImageForm.image.uri};
    disabled = false;
  } else if (coverImageForm?.hasImage && coverImageForm?.originalImageUrl) {
    coverSource = {uri: coverImageForm.originalImageUrl};
    disabled = false;
  }
  if (coverImageForm?.uploadedSelfImage && !confirmedMaterialRight) {
    disabled = true;
  }
  const handlePressConfirmedMaterialRight = React.useCallback(() => {
    setConfirmedMaterialRight(!confirmedMaterialRight);
  }, [confirmedMaterialRight]);
  const [tooltipModalType, setTooltipModal] =
    React.useState<TooltipModalType | null>(null);
  const [imagePickerError, setImagePickerError] = React.useState<string | null>(
    null,
  );
  const tooltipModal = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  return (
    <View style={styles.container}>
      <View style={styles.thumbnailWrapper}>
        <>
          <View style={styles.labelWrapper}>
            <LabelWithOption
              title={'サムネイル'}
              onPressQuestion={() => {
                setTooltipModal('image');
              }}
            />
            <DefaultOutlineButton
              style={styles.resetButton}
              fontSizeClass={'small'}
              onPress={onRequestReset}>
              リセット
            </DefaultOutlineButton>
          </View>
          <DimensionContext.Consumer>
            {context => {
              const width = context.content.width * widthAspectRatio;
              const height = width / aspectRatio;
              return (
                <View style={{width, height}}>
                  <CoverPicker
                    style={{height, width}}
                    source={coverSource}
                    noImageText={'サムネイルなし'}
                    recommendedSize={recommendedSize}
                    onChangeImage={onChangeImage}
                    onFailImage={setImagePickerError}
                  />
                </View>
              );
            }}
          </DimensionContext.Consumer>
          <View style={styles.editButtons}>
            <DefaultOutlineButton
              style={styles.editButton}
              fontSizeClass={'small'}
              onPress={onForwardToImageSelection}>
              壁紙から選ぶ
            </DefaultOutlineButton>
            <DefaultOutlineButton
              style={styles.editButton}
              fontSizeClass={'small'}
              disabled={!coverSource}
              onPress={onForwardToCharacterImageSelection}>
              キャラ配置
            </DefaultOutlineButton>
            <DefaultOutlineButton
              style={styles.editButton}
              fontSizeClass={'small'}
              disabled={!coverSource}
              onPress={onForwardToTextInput}>
              テキストを入力
            </DefaultOutlineButton>
          </View>
        </>
      </View>
      {coverImageForm?.uploadedSelfImage ? (
        <View style={{marginTop: 16}}>
          <MaterialRightCheck
            checked={confirmedMaterialRight}
            onPress={handlePressConfirmedMaterialRight}
          />
        </View>
      ) : null}
      <View style={styles.button}>
        <EditableLaterMessage />
        <PrimaryButton disabled={disabled} onPress={onSubmit}>
          動画を作成する
        </PrimaryButton>
      </View>
      <AlertModal
        visible={!!imagePickerError}
        onCloseModal={() => setImagePickerError(null)}>
        {imagePickerError}
      </AlertModal>
      <TooltipModal
        visible={tooltipModal ? true : false}
        title={tooltipModal?.title || ''}
        description={tooltipModal?.description || ''}
        source={tooltipModal?.source}
        imageStyle={tooltipModal?.imageStyle}
        onCloseModal={() => {
          setTooltipModal(null);
        }}></TooltipModal>
    </View>
  );
};

export default React.memo(Form);

const MARGIN_HORIZONTAL = 16;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 60,
  } as ViewStyle,
  thumbnailWrapper: {
    flex: 1,
    alignItems: 'center',
  } as ViewStyle,
  labelWrapper: {
    flexDirection: 'row',
    height: 30,
    alignSelf: 'stretch',
    marginBottom: 14,
    marginHorizontal: MARGIN_HORIZONTAL,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    paddingVertical: 20,
  } as ViewStyle,
  editButtons: {
    flexDirection: 'row',
    marginVertical: 16,
  } as ViewStyle,
  editButton: {
    borderRadius: 20,
    width: 120,
    height: 40,
    marginHorizontal: 4,
  } as ViewStyle,
  resetButton: {
    borderRadius: 24,
    width: 84,
    height: 32,
    marginHorizontal: 4,
  } as ViewStyle,
});
