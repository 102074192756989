import * as React from 'react';
import {ScrollView} from 'react-native';

import BackgroundUsageHistoryList from './partials/EntityUsageHistoryList';
import WallpaperList from './partials/WallpaperList';
import BottomNewBackgroundUsageHistoryButton from './partials/BottomNewBackgroundUsageHistoryButton';
import NoBackgroundUsageHistories from './partials/NoBackgroundUsageHistories';

import Layout from '../../shared/Layout';
import shouldUpdateEntityList from '../../shared/enhanced/shouldUpdateEntityList';
import ResourceFavoriteListLink from '../../shared/ResourceFavoriteListLink';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormBackgroundUsageHistoriesRouteProp} from '../../../navigators/RouteProps';

import {Params as BackgroundUsageHistoryIndexParams} from '../../../actions/background_usage_histories/index';
import {Params as BaseBackgroundIndexParams} from '../../../actions/base_backgrounds/index';

import {equalForKeys} from '../../../helpers/equalForKeys';
import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import Story from '../../../../domain/entities/Story';
import BackgroundUsageHistory from '../../../../domain/entities/BackgroundUsageHistory';
import BaseBackground from '../../../../domain/entities/BaseBackground';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  back?: boolean;
  page?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormBackgroundUsageHistoriesRouteProp;
  coverImageForm: CoverImageForm | null;
  story: Story | null;
  backgroundUsageHistories: BackgroundUsageHistory[] | null;
  backgroundUsageHistoryParams: BackgroundUsageHistoryIndexParams;
  mainGenreBaseBackgrounds: BaseBackground[] | null;
  mainGenreBaseBackgroundsParams: BaseBackgroundIndexParams;
  subGenreBaseBackgrounds: BaseBackground[] | null;
  subGenreBaseBackgroundsParams: BaseBackgroundIndexParams;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  showStory: (id: number) => Promise<Story>;
  indexBackgroundUsageHistories: (
    params: BackgroundUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<BackgroundUsageHistory>>;
  indexBaseBackgrounds: (
    params: BaseBackgroundIndexParams,
  ) => Promise<PaginatedResult<BaseBackground>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (
      !equalForKeys(this.props, nextProps, [
        'backgroundUsageHistoryParams',
        'wallpapersParams',
      ])
    ) {
      return true;
    }
    if (
      shouldUpdateEntityList(
        {
          entities: this.props.backgroundUsageHistories,
        },
        {entities: nextProps.backgroundUsageHistories},
      )
    ) {
      return true;
    }
    if (
      shouldUpdateEntityList(
        {entities: this.props.mainGenreBaseBackgrounds},
        {entities: nextProps.mainGenreBaseBackgrounds},
      )
    ) {
      return true;
    }
    if (
      shouldUpdateEntityList(
        {entities: this.props.subGenreBaseBackgrounds},
        {entities: nextProps.subGenreBaseBackgrounds},
      )
    ) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      coverImageForm,
      story,
      backgroundUsageHistoryParams,
      mainGenreBaseBackgroundsParams,
      subGenreBaseBackgroundsParams,
      showStory,
      indexBackgroundUsageHistories,
      indexBaseBackgrounds,
    } = this.props;
    const {storyId} = route.params;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
    indexBackgroundUsageHistories(backgroundUsageHistoryParams);
    const callback = (story: Story) => {
      indexBaseBackgrounds(mainGenreBaseBackgroundsParams).then(result => {
        if (result.total < 9 && story.getSubGenreIds().length > 0) {
          indexBaseBackgrounds(subGenreBaseBackgroundsParams);
        }
      });
    };
    if (story) {
      callback(story);
    } else {
      showStory(storyId).then(callback);
    }
  }

  public render(): React.ReactNode {
    const {route, navigation, backgroundUsageHistories, enabledFavorite} =
      this.props;
    const baseBackgrounds = this.getBaseBackgrounds();
    const {back} = route.params;
    return (
      <Layout
        title={'背景'}
        scrollable={false}
        back={back}
        navigation={navigation}
        close={true}>
        {backgroundUsageHistories && baseBackgrounds ? (
          backgroundUsageHistories.length > 0 || baseBackgrounds?.length > 0 ? (
            <>
              <ScrollView contentContainerStyle={{paddingBottom: 78}}>
                <BackgroundUsageHistoryList
                  entities={backgroundUsageHistories}
                  onSelectBackgroundUsageHistory={
                    this.handleSelectBackgroundUsageHistory
                  }
                  onPressFavoriteList={
                    enabledFavorite ? this.handlePressFavoriteList : undefined
                  }
                />
                <WallpaperList
                  baseBackgrounds={baseBackgrounds}
                  onSelectBaseBackground={this.handleSelectBaseBackground}
                />
              </ScrollView>
              <BottomNewBackgroundUsageHistoryButton
                onPress={this.handleForwardToNewBackgroundUsageHistory}
              />
            </>
          ) : (
            <>
              <NoBackgroundUsageHistories
                onForwardToNewBackgroundUsageHistory={
                  this.handleForwardToNewBackgroundUsageHistory
                }
              />
              {enabledFavorite && (
                <ResourceFavoriteListLink
                  style={{position: 'absolute', top: 16, right: 16}}
                  onPress={this.handlePressFavoriteList}
                />
              )}
            </>
          )
        ) : null}
      </Layout>
    );
  }

  private getBaseBackgrounds = () => {
    const {mainGenreBaseBackgrounds, subGenreBaseBackgrounds} = this.props;
    if (!mainGenreBaseBackgrounds) {
      return null;
    }
    if (mainGenreBaseBackgrounds.length >= 9) {
      return mainGenreBaseBackgrounds;
    } else {
      return mainGenreBaseBackgrounds.concat(subGenreBaseBackgrounds || []);
    }
  };

  private handleSelectBackgroundUsageHistory = (
    entityUsageHistory: BackgroundUsageHistory,
  ) => {
    const {navigation, route} = this.props;
    const baseBackgroundId = entityUsageHistory.baseBackground.id;
    const {storyId, aspectRatio, imageKey} = route.params;
    navigation.navigate('CoverImageFormBackgrounds', {
      storyId,
      aspectRatio,
      imageKey,
      baseBackgroundId,
    });
  };

  private handleSelectBaseBackground = (baseBackground: BaseBackground) => {
    const {navigation, route} = this.props;
    const baseBackgroundId = baseBackground.id;
    const {storyId, aspectRatio, imageKey} = route.params;
    navigation.navigate('CoverImageFormBackgrounds', {
      storyId,
      aspectRatio,
      imageKey,
      baseBackgroundId,
    });
  };

  private handleForwardToNewBackgroundUsageHistory = () => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;
    navigation.navigate('CoverImageFormBaseBackgroundSearchForm', {
      storyId,
      imageKey,
      aspectRatio,
    });
  };

  private handlePressFavoriteList = () => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;

    navigation.navigate('CoverImageFormBaseBackgrounds', {
      storyId,
      aspectRatio,
      imageKey,
      favorite: true,
    });
  };
}
