import * as React from 'react';
import {ScaledSize} from 'react-native';

import DimensionContext from './DimensionContext';

import getContentDimension from '../../../helpers/getContentDimension';

interface Props extends React.PropsWithChildren {}

interface State {
  value: {
    window: ScaledSize;
    content: {width: number; height: number};
  };
}

export default class DimensionProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: this.generateValue(),
    };
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleResize, false);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  public render(): React.ReactNode {
    const {children} = this.props;
    const {value} = this.state;
    return (
      <DimensionContext.Provider value={value}>
        {children}
      </DimensionContext.Provider>
    );
  }

  private handleResize = () => {
    this.setState({value: this.generateValue()});
  };

  private generateValue = () => {
    const docEl = window.document.documentElement;
    const height = docEl.clientHeight;
    const width = docEl.clientWidth;
    return {
      window: {
        fontScale: 1,
        height,
        scale: window.devicePixelRatio || 1,
        width,
      },
      content: getContentDimension({
        fontScale: 1,
        height,
        scale: window.devicePixelRatio || 1,
        width,
      }),
    };
  };
}
