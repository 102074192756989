import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import EffectCategory from '../../../domain/entities/EffectCategory';

import NetEffectCategoriesRepository from '../../../data/repositories/NetEffectCategoriesRepository';

const types = {
  failure: Types.ShowEffectCategoryFailure,
  request: Types.ShowEffectCategoryRequest,
  success: Types.ShowEffectCategorySuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<EffectCategory> {
  return showGeneric(new NetEffectCategoriesRepository(), types, id);
}
