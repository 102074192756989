import * as React from 'react';
import {
  ImageStyle,
  Text,
  TextStyle,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import MemberImage from '../../members/partials/MemberImage';

import {formatElapsedTime} from '../.../../../../../helpers/times';

import ProjectTopicComment from '../../../../../domain/entities/ProjectTopicComment';

interface Props extends React.PropsWithChildren {
  comment: ProjectTopicComment;
}

const CommentDetail: React.FC<Props> = props => {
  const {comment, children} = props;
  const member = comment.projectMember;
  return (
    <>
      <View style={styles.imageWrapper}>
        <MemberImage member={member} imageSize={IMAGE_SIZE} />
      </View>
      <View style={styles.right}>
        <View style={styles.rightTop}>
          <Text style={styles.penName}>{member.writerUser.penName}</Text>
          {member.leader ? (
            <View style={styles.leaderLabel}>
              <Text style={styles.leaderText}>リーダー</Text>
            </View>
          ) : null}
        </View>
        <Text style={styles.date}>
          {formatElapsedTime(new Date(comment.createdAt))}
        </Text>
        <View style={styles.triangle} />
        <View style={styles.commentFrame}>
          <Text style={styles.comment}>{comment.comment}</Text>
        </View>
        {children}
      </View>
    </>
  );
};

export default React.memo(CommentDetail);

const IMAGE_SIZE = 50;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 9,
    marginHorizontal: 16,
  } as ViewStyle,
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMAGE_SIZE / 2,
    backgroundColor: '#ffffff',
    height: IMAGE_SIZE,
    overflow: 'hidden',
    width: IMAGE_SIZE,
  } as ViewStyle,
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  } as ImageStyle,
  right: {
    flex: 1,
    paddingTop: 3,
    paddingHorizontal: 10,
  } as ViewStyle,
  rightTop: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  penName: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  leaderLabel: {
    marginLeft: 6,
  } as ViewStyle,
  leaderText: {
    color: '#fff',
    fontSize: 8,
    fontWeight: 'bold',
    backgroundColor: '#ff8f13',
    padding: 2,
  } as TextStyle,
  date: {
    color: '#999',
    fontSize: 10,
    marginVertical: 6,
  } as TextStyle,
  triangle: {
    width: 7,
    borderLeftWidth: 7,
    borderLeftColor: 'transparent',
    borderRightWidth: 7,
    borderRightColor: 'transparent',
    borderBottomWidth: 7,
    borderBottomColor: '#efefef',
    marginLeft: 12,
  } as ViewStyle,
  commentFrame: {
    backgroundColor: '#efefef',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 12,
    paddingHorizontal: 10,
  } as ViewStyle,
  comment: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
});
