import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import PaymentMethodMapper, {
  PaymentMethodData,
} from '../../../entities/mappers/writer/my_stripe/PaymentMethodMapper';

import PaymentMethod from '../../../../domain/entities/writer/my_stripe/PaymentMethod';
import PaymentMethodsRepository from '../../../../domain/repositories/writer/my_stripe/PaymentMethodsRepository';

export default class NetPaymentMethodsRepository
  extends NetResourcesRepository<PaymentMethod, PaymentMethodData>
  implements PaymentMethodsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/my_stripe/payment_methods',
        new PaymentMethodMapper(),
      ),
    );
  }
}
