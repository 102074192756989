import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import numeral from 'numeral';

import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import EpisodeAggregation from '../../../../../domain/value_objects/summary/EpisodeAggregation';

interface Props {
  episodeAggregation: EpisodeAggregation;
}

const EpisodeListItem: React.FunctionComponent<Props> = props => {
  const {episodeAggregation} = props;
  return (
    <View style={styles.container}>
      <View style={styles.main}>
        <Text style={[styles.title]}>{episodeAggregation.title}</Text>
        <View style={styles.rows}>
          <View style={styles.row}>
            <Text style={styles.label}>閲覧数(人)</Text>
            <Text style={styles.value}>
              {numeral(episodeAggregation.episodePageViewCount).format('0,0')}(
              {numeral(
                episodeAggregation.episodePageViewUniqueConsumerUserCount,
              ).format('0,0')}
              )
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>読了数(人)</Text>
            <Text style={styles.value}>
              {numeral(
                episodeAggregation.finishedReadingEpisodePageViewCount,
              ).format('0,0')}
              (
              {numeral(
                episodeAggregation.finishedReadingEpisodePageViewUniqueConsumerUserCount,
              ).format('0,0')}
              )
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.label}>読了率</Text>
            <Text style={styles.value}>
              {numeral(episodeAggregation.finishedReadingRate * 100).format(
                '0,0.0',
              )}
              ％
            </Text>
          </View>
        </View>
      </View>
      <View style={styles.icon}>
        <ArrowForwardIcon />
      </View>
    </View>
  );
};

export default React.memo(EpisodeListItem);

const styles = StyleSheet.create({
  container: {
    margin: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  main: {
    flex: 1,
  } as ViewStyle,
  title: {
    color: '#222222',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 4,
  } as TextStyle,
  rows: {
    paddingRight: 40,
  } as ViewStyle,
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  icon: {
    justifyContent: 'center',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  value: {
    color: '#ff8f13',
    fontSize: 11,
  } as TextStyle,
});
