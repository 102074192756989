import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EpisodeMapper, {EpisodeData} from '../../entities/mappers/EpisodeMapper';

import Episode from '../../../domain/entities/Episode';
import EpisodesRepository from '../../../domain/repositories/writer/EpisodesRepository';

export default class NetAutoGenerationEpisodesRepository
  extends NetResourcesRepository<Episode, EpisodeData>
  implements EpisodesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/auto_generation_episodes',
        new EpisodeMapper(),
      ),
    );
  }
}
