import * as React from 'react';

import BaseIndex from '../../../shared/generics/character_form/default_character_patterns/Index';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CharacterFormNewDefaultCharacterPatternsRouteProp} from '../../../../navigators/RouteProps';

import {Params as ActorCostumeIndexParams} from '../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessoryIndexParams} from '../../../../actions/actor_accessories/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../actions/actor_accessory_sets/index';
import {Params as ActorCharacterFaceIndexParams} from '../../../../actions/actor_character_faces/index';
import {Params as CharacterFormUpdateParams} from '../../../../actions/character_forms/update';

import Actor from '../../../../../domain/entities/Actor';
import ActorCostume from '../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../domain/entities/ActorHairStyle';
import ActorAccessory from '../../../../../domain/entities/ActorAccessory';
import ActorAccessorySet from '../../../../../domain/entities/ActorAccessorySet';
import ActorCharacter from '../../../../../domain/entities/ActorCharacter';
import ActorCharacterFace from '../../../../../domain/entities/ActorCharacterFace';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  actorId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CharacterFormNewDefaultCharacterPatternsRouteProp;
  actor: Actor | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessories: (
    params: ActorAccessoryIndexParams,
  ) => Promise<PaginatedResult<ActorAccessory>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  updateCharacterForm: (params: CharacterFormUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        actorId={this.props.route.params.actorId}
        onSelectActorCharacter={this.handleSelectActorCharacter}
      />
    );
  }

  private handleSelectActorCharacter = (actorCharacter: ActorCharacter) => {
    const {navigation, indexActorCharacterFaces, updateCharacterForm} =
      this.props;
    const actorCharacterId = actorCharacter.id;

    indexActorCharacterFaces({actorCharacterId}).then(result => {
      const actorCharacterFace = result.records[0];
      const actorCharacterFaceId = actorCharacterFace?.id;
      const actorCharacterFaceName = actorCharacterFace?.faceName;
      updateCharacterForm({
        actorId: actorCharacter.actorId,
        actorCharacterId,
        actorCharacterFaceId,
        actorCharacterFaceName,
      });
      navigation.getParent()?.goBack();
    });
  };
}
