import * as React from 'react';
import {ImageStyle, StyleSheet, View, ViewStyle} from 'react-native';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';

import ProjectWriterUser from '../../../../../domain/entities/ProjectWriterUser';
import ImageUrlHelper from '../../../../../domain/helpers/ImageUrlHelper';

import uri from '../../../../../assets/svgs/noimage-writer.svg';

interface Props {
  user: ProjectWriterUser;
  imageSize?: number;
  withRankColor?: boolean;
}

const WriterUserImage: React.FC<Props> = props => {
  const {user, imageSize = IMAGE_SIZE, withRankColor} = props;
  const source =
    user.userProfile && user.userProfile.hasImage
      ? {
          uri: ImageUrlHelper.getImageUrl(user.userProfile.originalImageUrl, {
            width: 200,
            height: 200,
            canvas: false,
          }),
        }
      : null;
  const borderColor = user.writerGradeCode
    ? RANK_CODE_TO_COLOR[user.writerGradeCode]
    : undefined;
  return (
    <View
      style={[
        styles.container,
        {width: imageSize, height: imageSize, borderRadius: imageSize / 2},
        withRankColor ? {borderColor, borderWidth: 1} : null,
      ]}>
      {source ? (
        <FastImageAdapter
          resizeMode={'contain'}
          style={[styles.image, {width: imageSize, height: imageSize}]}
          source={source}
        />
      ) : (
        <SvgUriAdapter uri={uri} width={imageSize} height={imageSize} />
      )}
    </View>
  );
};

export default React.memo(WriterUserImage);

const IMAGE_SIZE = 42;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMAGE_SIZE / 2,
    backgroundColor: '#e5e5e5',
    borderColor: '#e5e5e5',
    borderWidth: 1,
    height: IMAGE_SIZE,
    overflow: 'hidden',
    width: IMAGE_SIZE,
  } as ViewStyle,
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  } as ImageStyle,
});

const RANK_CODE_TO_COLOR: {[key: string]: string} = {
  white: '#fff',
  blue: '#5d88bd',
  green: '#5fb18b',
  yellow: '#f0b63c',
  orange: '#f19b3b',
  red: '#ec4c34',
  purple: '#ac77cb',
  bronze: '#c2764b',
  sliver: '#77859a',
  gold: '#c99f0e',
  platinum: '#5d646b',
  diamond: '#207d97',
  black: '#222',
};
