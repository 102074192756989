import * as React from 'react';

import SoundUsageHistoryList from '../../common/sound_usage_histories/partials/SoundUsageHistoryList';
import BottomNewSoundUsageHistoryButton from '../../common/sound_usage_histories/partials/BottomNewSoundUsageHistoryButton';
import NoSoundUsageHistories from '../../common/sound_usage_histories/partials/NoSoundUsageHistories';

import Layout from '../../../../shared/Layout';
import ResourceFavoriteListLink from '../../../../shared/ResourceFavoriteListLink';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormSoundEffectShowSceneCommandNewSoundUsageHistoriesRouteProp,
  SceneFormSoundEffectShowSceneCommandEditSoundUsageHistoriesRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as SoundUsageHistoryIndexParams} from '../../../../../actions/sound_usage_histories/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import SoundUsageHistory from '../../../../../../domain/entities/SoundUsageHistory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  back?: boolean;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormSoundEffectShowSceneCommandNewSoundUsageHistoriesRouteProp
    | SceneFormSoundEffectShowSceneCommandEditSoundUsageHistoriesRouteProp;
  sceneForm: SceneForm | null;
  soundUsageHistoriesParams: SoundUsageHistoryIndexParams;
  soundUsageHistories: SoundUsageHistory[] | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexSoundUsageHistories: (
    params: SoundUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<SoundUsageHistory>>;
}

interface Props extends StateProps, DispatchProps {
  onSelectSoundUsageHistory: (soundUsageHistory: SoundUsageHistory) => void;
  onForwardToNewSoundUsageHistory: () => void;
  onForwardToFavoriteList?: () => void;
}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      soundUsageHistoriesParams,
      soundUsageHistories,
      indexSoundUsageHistories,
      onForwardToFavoriteList,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!soundUsageHistories) {
      indexSoundUsageHistories(soundUsageHistoriesParams);
    }
  }

  public render(): React.ReactNode {
    const {
      route,
      navigation,
      soundUsageHistories,
      enabledFavorite,
      onSelectSoundUsageHistory,
      onForwardToNewSoundUsageHistory,
      onForwardToFavoriteList,
    } = this.props;

    const {back} = route.params;
    return (
      <Layout
        title={'効果音'}
        scrollable={false}
        back={back}
        navigation={navigation}
        close={true}>
        {soundUsageHistories ? (
          soundUsageHistories.length > 0 ? (
            <>
              <SoundUsageHistoryList
                soundUsageHistories={soundUsageHistories}
                onSelectSoundUsageHistory={onSelectSoundUsageHistory}
                onForwardToFavoriteList={
                  enabledFavorite ? onForwardToFavoriteList : undefined
                }
              />

              <BottomNewSoundUsageHistoryButton
                onPress={onForwardToNewSoundUsageHistory}
              />
            </>
          ) : (
            <>
              <NoSoundUsageHistories
                onForwardToNewSoundUsageHistory={
                  onForwardToNewSoundUsageHistory
                }
              />
              {enabledFavorite && (
                <ResourceFavoriteListLink
                  style={{position: 'absolute', top: 16, right: 16}}
                  onPress={onForwardToFavoriteList}
                />
              )}
            </>
          )
        ) : null}
      </Layout>
    );
  }
}
