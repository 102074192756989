import * as React from 'react';

import EpisodeDetailTabs from './partials/EpisodeDetailTabs';

import DeleteEpisodeCommentConfirmModal from './partials/modals/DeleteEpisodeCommentConfirmModal';
import BlockUserConfirmModal from './partials/modals/BlockUserConfirmModal';
import UnblockUserConfirmModal from './partials/modals/UnblockUserConfirmModal';
import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {ResponseEpisodeRouteProp} from '../../../navigators/RouteProps';

import {Params as EpisodeReactionIndexParams} from '../../../actions/episode_reactions/index';
import {Params as EpisodeCommentIndexParams} from '../../../actions/episode_comments/index';
import {Params as ResponseEpisodeUpdateParams} from '../../../actions/response/episodes/update';
import {Params as EpisodeCommentBlockingUserIndexParams} from '../../../actions/episode_comment_blocking_users/index';
import {Params as EpisodeCommentBlockingUserCreateParams} from '../../../actions/episode_comment_blocking_users/create';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import Episode from '../../../../domain/entities/response/Episode';
import EpisodeReaction from '../../../../domain/entities/EpisodeReaction';
import EpisodeComment from '../../../../domain/entities/EpisodeComment';
import EpisodeCommentReply from '../../../../domain/entities/EpisodeCommentReply';
import EpisodeCommentBlockingUser from '../../../../domain/entities/EpisodeCommentBlockingUser';
import PaginatedResult from '../../../../domain/results/PaginatedResult';
import User from '../../../../domain/entities/consumer/User';
import WriterCurrentUser from '../../../../domain/entities/writer/CurrentUser';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  episodeId: number;
  tab?: string;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ResponseEpisodeRouteProp;
  currentUser: WriterCurrentUser | null;
  episode: Episode | null;
  episodeReactions: EpisodeReaction[] | null;
  episodeComments: EpisodeComment[] | null;
  episodeReactionsParams: EpisodeReactionIndexParams;
  episodeCommentsParams: EpisodeCommentIndexParams;
  episodeCommentBlockingUsers: EpisodeCommentBlockingUser[] | null;
  episodeCommentBlockingUsersParams: EpisodeCommentBlockingUserIndexParams;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<WriterCurrentUser>;
  showResponseEpisode: (id: number) => Promise<Episode>;
  updateResponseEpisode: (
    id: number,
    params: ResponseEpisodeUpdateParams,
  ) => Promise<Episode>;
  indexEpisodeReactions: (
    params: EpisodeReactionIndexParams,
  ) => Promise<PaginatedResult<EpisodeReaction>>;
  indexEpisodeComments: (
    params: EpisodeCommentIndexParams,
  ) => Promise<PaginatedResult<EpisodeComment>>;
  showEpisodeComment: (id: number) => Promise<EpisodeComment>;
  destroyEpisodeComment: (id: number) => Promise<number>;
  destroyEpisodeCommentReply: (id: number) => Promise<number>;
  indexEpisodeCommentBlockingUsers: (
    params: EpisodeCommentBlockingUserIndexParams,
  ) => Promise<PaginatedResult<EpisodeCommentBlockingUser>>;
  createEpisodeCommentBlockingUser: (
    params: EpisodeCommentBlockingUserCreateParams,
  ) => Promise<EpisodeCommentBlockingUser>;
  destroyEpisodeCommentBlockingUser: (id: number) => Promise<number>;
}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    route,
    currentUser,
    episode,
    episodeReactions: propsEpisodeReactions,
    episodeReactionsParams,
    episodeComments,
    episodeCommentsParams,
    episodeCommentBlockingUsers,
    episodeCommentBlockingUsersParams,
    showCurrentUser,
    showResponseEpisode,
    updateResponseEpisode,
    indexEpisodeReactions,
    indexEpisodeComments,
    showEpisodeComment,
    destroyEpisodeComment,
    destroyEpisodeCommentReply,
    indexEpisodeCommentBlockingUsers,
    createEpisodeCommentBlockingUser,
    destroyEpisodeCommentBlockingUser,
  } = props;
  const {episodeId, tab} = route.params;
  const [loading, setLoading] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [episodeReactions, setEpisodeReactions] = React.useState<
    EpisodeReaction[] | null
  >(propsEpisodeReactions);
  const [deletingEpisodeComment, setDeletingEpisodeComment] =
    React.useState<EpisodeComment | null>(null);
  const [
    creatingEpisodeCommentBlockingUser,
    setCreatingEpisodeCommentBlockingUser,
  ] = React.useState<User | null>(null);
  const [
    deletingEpisodeCommentBlockingUser,
    setDeletingEpisodeCommentBlockingUser,
  ] = React.useState<EpisodeCommentBlockingUser | null>(null);
  const [reactionIds, setReactionIds] = React.useState<number[]>([
    0, 1, 2, 3, 4, 5,
  ]);
  const requestEpisodeReactions = React.useCallback(
    (reactionIds?: number[]) => {
      indexEpisodeReactions({...episodeReactionsParams, reactionIds}).then(
        result => {
          setEpisodeReactions(result.records);
        },
      );
    },
    [episodeReactionsParams],
  );
  const requestEpisodeComments = React.useCallback(() => {
    indexEpisodeComments(episodeCommentsParams);
  }, [episodeReactionsParams]);
  React.useEffect(() => {
    if (!currentUser) {
      showCurrentUser();
    }
    showResponseEpisode(episodeId)
      .then(episode => {
        setTimeout(() => {
          if (tab === 'comment') {
            if (!episode.confirmed || !episode.confirmedComments) {
              updateResponseEpisode(episodeId, {
                confirmed: true,
                confirmedComments: true,
              });
            }
          } else {
            if (!episode.confirmed || !episode.confirmedReactions) {
              updateResponseEpisode(episodeId, {
                confirmed: true,
                confirmedReactions: true,
              });
            }
          }
        }, 200);
      })
      .catch(e => {
        if (e.status === 401 || e.status === 404) {
          navigation.goBack();
        }
      });
    indexEpisodeCommentBlockingUsers(episodeCommentBlockingUsersParams);
    requestEpisodeReactions();
    requestEpisodeComments();
  }, []);
  const onRequestCreateEpisodeCommentBlockingUser = React.useCallback(
    (user: User) => {
      setCreatingEpisodeCommentBlockingUser(user);
    },
    [],
  );
  const onRequestDestroyEpisodeCommentBlockingUser = React.useCallback(
    (episodeCommentBlockingUser: EpisodeCommentBlockingUser) => {
      setDeletingEpisodeCommentBlockingUser(episodeCommentBlockingUser);
    },
    [],
  );
  const onRequestDestroyEpisodeComment = React.useCallback(
    (episodeComment: EpisodeComment) => {
      setDeletingEpisodeComment(episodeComment);
    },
    [],
  );
  const onOpenEditEpisodeCommentReply = React.useCallback(
    (episodeCommentReply: EpisodeCommentReply) => {
      navigation.navigate('EditEpisodeCommentReply', {
        id: episodeCommentReply.id,
      });
    },
    [],
  );
  const onOpenNewEpisodeCommentReply = React.useCallback(
    (episodeComment: EpisodeComment) => {
      navigation.navigate('NewEpisodeCommentReply', {
        episodeCommentId: episodeComment.id,
      });
    },
    [],
  );
  const onRequestDestroyEpisodeCommentReply = React.useCallback(
    (episodeCommentReply: EpisodeCommentReply) => {
      destroyEpisodeCommentReply(episodeCommentReply.id).then(() => {
        showEpisodeComment(episodeCommentReply.episodeCommentId);
      });
    },
    [],
  );
  const onRequestCreateEpisodeCommentStoryPage = React.useCallback(
    (episodeComment: EpisodeComment) => {
      setLoading(true);
      TapNovelRestApi.post<any>(
        `/api/writer/episode_comments/${episodeComment.id}/story_page`,
      )
        .then(response => {
          showEpisodeComment(episodeComment.id).then(() => {
            setLoading(false);
          });
        })
        .catch(error => {
          setAlertMessage(formatErrorMessages({}, error));
          setLoading(false);
        });
    },
    [],
  );
  const onRequestDestroyEpisodeCommentStoryPage = React.useCallback(
    (episodeComment: EpisodeComment) => {
      setLoading(true);
      TapNovelRestApi.delete(
        `/api/writer/episode_comments/${episodeComment.id}/story_page`,
      )
        .then(response => {
          showEpisodeComment(episodeComment.id).then(() => {
            setLoading(false);
          });
        })
        .catch(error => {
          setAlertMessage(formatErrorMessages({}, error));
          setLoading(false);
        });
    },
    [],
  );
  const onCloseModal = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  return (
    <Layout
      title={episode ? episode.title : 'エピソード'}
      navigation={navigation}
      scrollable={false}
      back={true}
      loading={loading}>
      {episode && currentUser && episodeCommentBlockingUsers && (
        <EpisodeDetailTabs
          currentUser={currentUser}
          episode={episode}
          episodeReactions={episodeReactions}
          episodeComments={episodeComments}
          reactionIds={reactionIds}
          episodeCommentBlockingUsers={episodeCommentBlockingUsers}
          initialPage={tab === 'comment' ? 1 : undefined}
          onChangeTab={({i}) => {
            if (i === 1 && !episode.confirmedComments) {
              updateResponseEpisode(episodeId, {
                confirmed: true,
                confirmedComments: true,
              });
            } else if (i === 0 && !episode.confirmedReactions) {
              updateResponseEpisode(episodeId, {
                confirmed: true,
                confirmedReactions: true,
              });
            }
          }}
          onRequestToggleReaction={(reactionId: number) => {
            const newReactionIds = reactionIds.includes(reactionId)
              ? reactionIds.filter(v => v !== reactionId)
              : [...reactionIds, reactionId];
            setReactionIds(newReactionIds);
            requestEpisodeReactions(newReactionIds);
          }}
          onRequestCreateEpisodeCommentBlockingUser={
            onRequestCreateEpisodeCommentBlockingUser
          }
          onRequestDestroyEpisodeCommentBlockingUser={
            onRequestDestroyEpisodeCommentBlockingUser
          }
          onRequestDestroyEpisodeComment={onRequestDestroyEpisodeComment}
          onRequestCreateEpisodeCommentStoryPage={
            onRequestCreateEpisodeCommentStoryPage
          }
          onRequestDestroyEpisodeCommentStoryPage={
            onRequestDestroyEpisodeCommentStoryPage
          }
          onOpenEditEpisodeCommentReply={onOpenEditEpisodeCommentReply}
          onOpenNewEpisodeCommentReply={onOpenNewEpisodeCommentReply}
          onRequestDestroyEpisodeCommentReply={
            onRequestDestroyEpisodeCommentReply
          }
        />
      )}
      <DeleteEpisodeCommentConfirmModal
        visible={!!deletingEpisodeComment}
        onCancel={() => {
          setDeletingEpisodeComment(null);
        }}
        onDelete={() => {
          if (deletingEpisodeComment) {
            destroyEpisodeComment(deletingEpisodeComment.id).then(() => {
              setDeletingEpisodeComment(null);
            });
          }
        }}
      />
      <BlockUserConfirmModal
        visible={!!creatingEpisodeCommentBlockingUser}
        onCancel={() => {
          setCreatingEpisodeCommentBlockingUser(null);
        }}
        onBlock={() => {
          if (creatingEpisodeCommentBlockingUser && episode) {
            createEpisodeCommentBlockingUser({
              storyId: episode.storyId,
              consumerUserId: creatingEpisodeCommentBlockingUser.id,
            })
              .then(() => {
                setCreatingEpisodeCommentBlockingUser(null);
              })
              .catch(() => {
                setCreatingEpisodeCommentBlockingUser(null);
              });
          }
        }}
      />
      <UnblockUserConfirmModal
        visible={!!deletingEpisodeCommentBlockingUser}
        onCancel={() => {
          setDeletingEpisodeCommentBlockingUser(null);
        }}
        onUnblock={() => {
          if (deletingEpisodeCommentBlockingUser) {
            destroyEpisodeCommentBlockingUser(
              deletingEpisodeCommentBlockingUser.id,
            )
              .then(() => {
                setDeletingEpisodeCommentBlockingUser(null);
              })
              .catch(() => {
                setCreatingEpisodeCommentBlockingUser(null);
              });
          }
        }}
      />
      <AlertModal visible={!!alertMessage} onCloseModal={onCloseModal}>
        {alertMessage}
      </AlertModal>
    </Layout>
  );
};

export default React.memo(Show);
