import * as React from 'react';
import {StyleSheet, Text, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../shared/buttons/PrimaryButton';
import DefaultModal from '../../shared/modals/DefaultModal';

import Character from '../../../../domain/entities/Character';

interface Props {
  onRequestCloseModal: () => void;
  children?: React.ReactNode;
}

export default class CharacterDeleteAlertModal extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {onRequestCloseModal} = this.props;
    return (
      <DefaultModal
        visible={true}
        onCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.buttons}>
            <PrimaryButton style={styles.button} onPress={onRequestCloseModal}>
              OK
            </PrimaryButton>
          </View>
        }>
        <View style={styles.body}>
          <Text style={styles.text}>
            このキャラクターはシーンで{'\n'}
            使用されております。{'\n'}
            削除する場合はシーンから削除した後に{'\n'}
            実行ください。
          </Text>
        </View>
      </DefaultModal>
    );
  }
}

const styles = StyleSheet.create({
  body: {
    marginTop: 0,
    marginBottom: 32,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    marginBottom: 32,
  } as ViewStyle,
  button: {
    marginHorizontal: 3,
    width: 145,
    height: 46,
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 14,
    lineHeight: 22,
    textAlign: 'center',
  },
});
