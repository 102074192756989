import * as React from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputSelectionChangeEventData,
  View,
  ViewStyle,
} from 'react-native';

import BaseSceneCommandModal from '../BaseSceneCommandModal';

import SpeechTextViewWithEditor, {TabValue} from './SpeechTextViewWithEditor';

import DeleteButton from '../buttons/DeleteButton';

import {ModalParams} from '../../../Modal';

import SceneFrame from '../../../../../../view_models/SceneFrame';
import CompositeSequenceSceneCommandFormFactory from '../../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import {addSymbolToTextAndCalcSelection} from '../../../../../../helpers/textInputHelper';

import SpeechBalloon from '../../../../../../../domain/entities/SpeechBalloon';
import OrientedSpeechBalloon from '../../../../../../../domain/entities/OrientedSpeechBalloon';
import Voice from '../../../../../../../domain/entities/Voice';
import Sound from '../../../../../../../domain/entities/Sound';
import SceneCommandForm from '../../../../../../../domain/forms/scene_commands/SceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import Position from '../../../../../../../domain/value_objects/Position';
import TextNormalizer from '../../../../../../../domain/helpers/TextNormalizer';

interface Props {
  storyId: number;
  sceneCommandForm: SpeechTextShowSceneCommandForm;
  speechBalloons: SpeechBalloon[];
  orientedSpeechBalloons: OrientedSpeechBalloon[];
  sceneFrame: SceneFrame;
  enableVoice?: boolean;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestCloseModal: () => void;
  onRemoveSpeechTextShowCommand: (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
  ) => void;
  onChangeSpeechTextShowSceneCommandForm: (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
  ) => void;
  onRequestIndexSpeechBalloons: () => void;
  onRequestIndexOrientedSpeechBalloons: () => void;
  onChangeCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
  onForwardToAddSoundEffects: (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
    parentSceneCommandId?: number,
    callback?: (sound: Sound) => void,
  ) => void;
  onRequestUpdateModal: (modalParams: ModalParams) => void;
}

interface State {
  currentTab: TabValue;
  selectedText: string;
  selectedCharacterName: string | null;
  selectedOrientedSpeechBalloon: OrientedSpeechBalloon;
  selectedSpeechBalloon: SpeechBalloon | null;
  selectedPositions: Position[];
  selectedVoice: Voice | null;
  selectedSound: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
  multiplePositions: boolean;
  selection?: {
    start: number;
    end: number;
  };
}

export default class SpeechTextViewWithEditorModal extends React.PureComponent<
  Props,
  State
> {
  private currentSelection:
    | {
        start: number;
        end: number;
      }
    | undefined = undefined;

  private textInputRef: React.RefObject<TextInput>;

  constructor(props: Props) {
    super(props);
    const {sceneCommandForm} = props;
    const textLength = sceneCommandForm.text.length;
    this.state = {
      currentTab: 'text',
      selectedText: sceneCommandForm.text,
      selectedCharacterName: sceneCommandForm.characterName,
      selectedOrientedSpeechBalloon: sceneCommandForm.orientedSpeechBalloon,
      selectedSpeechBalloon: this.findSpeechBalloonById({
        id: sceneCommandForm.orientedSpeechBalloon.speechBalloonId,
      }),
      selectedPositions: sceneCommandForm.orientedSpeechBalloon.getPositions(),
      selectedVoice: sceneCommandForm.voice,
      selectedSound: sceneCommandForm.sound,
      selectedSoundStartTime: sceneCommandForm.startTime,
      selectedSoundEndTime: sceneCommandForm.endTime,
      multiplePositions: false,
      selection: {start: textLength, end: textLength},
    };
    this.textInputRef = React.createRef<TextInput>();
  }

  public render(): React.ReactNode {
    const {
      storyId,
      sceneCommandForm,
      speechBalloons,
      orientedSpeechBalloons,
      sceneFrame,
      enableVoice,
      onRequestCloseModal,
    } = this.props;
    const {
      currentTab,
      selectedText,
      selectedCharacterName,
      selectedOrientedSpeechBalloon,
      selectedSpeechBalloon,
      selectedPositions,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      multiplePositions,
      selection,
    } = this.state;
    if (!speechBalloons || !orientedSpeechBalloons || !selectedSpeechBalloon) {
      return null;
    }
    return (
      <BaseSceneCommandModal
        title={'セリフの編集'}
        onRequestCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.footer}>
            <DeleteButton onPress={this.destroySceneCommandForm} />
          </View>
        }>
        <SpeechTextViewWithEditor
          storyId={storyId}
          sceneCommandForm={sceneCommandForm}
          speechBalloons={speechBalloons}
          orientedSpeechBalloons={orientedSpeechBalloons}
          sceneFrame={sceneFrame}
          enableVoice={enableVoice}
          textInputRef={this.textInputRef}
          currentTab={currentTab}
          selectedText={selectedText}
          selectedCharacterName={selectedCharacterName}
          selectedOrientedSpeechBalloon={selectedOrientedSpeechBalloon}
          selectedSpeechBalloon={selectedSpeechBalloon}
          selectedPositions={selectedPositions}
          selectedVoice={selectedVoice}
          selectedSound={selectedSound}
          selectedSoundStartTime={selectedSoundStartTime}
          selectedSoundEndTime={selectedSoundEndTime}
          multiplePositions={multiplePositions}
          selection={selection}
          onSubmit={this.handleSubmit}
          onChangeTab={this.handleChangeTab}
          onSelectText={this.handleSelectText}
          onChangeName={
            sceneCommandForm.overrideCharacterName
              ? this.handleChangeName
              : undefined
          }
          onPressSymbol={this.handlePressSymbol}
          onSelectionChange={this.handleSelectionChange}
          onSelectSpeechBalloon={this.handleSelectSpeechBalloon}
          onSelectPosition={this.handleSelectPosition}
          onUpdateVoice={this.handleUpdateVoice}
          addSound={this.addSound}
          deleteSound={this.deleteSound}
          onChagenSoundRegion={this.handleChangeSoundRegion}
        />
      </BaseSceneCommandModal>
    );
  }

  private destroySceneCommandForm = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onRemoveSpeechTextShowCommand,
      onChangeCommand,
      onRemoveCommand,
    } = this.props;
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.remove(
          parentSceneCommandForm,
          sceneCommandForm,
        );
      if (newParentSceneCommandForm) {
        onChangeCommand(newParentSceneCommandForm);
      } else {
        onRemoveCommand(parentSceneCommandForm);
      }
    } else {
      onRemoveSpeechTextShowCommand(sceneCommandForm);
    }
    onRequestCloseModal();
  };

  private handleSubmit = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onChangeSpeechTextShowSceneCommandForm,
      onRequestCloseModal,
      onChangeCommand,
    } = this.props;
    const {
      selectedText,
      selectedOrientedSpeechBalloon,
      selectedCharacterName,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
    } = this.state;
    const nextText = TextNormalizer.normalize(selectedText, 'speech_text');
    const newSceneCommandForm = new SpeechTextShowSceneCommandForm(
      selectedOrientedSpeechBalloon,
      nextText,
      sceneCommandForm.overrideCharacterName,
      selectedCharacterName || null,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime || null,
      selectedSoundEndTime || null,
      sceneCommandForm.sceneCommandId,
    );
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          newSceneCommandForm,
        );
      onChangeCommand(newParentSceneCommandForm);
    } else {
      onChangeSpeechTextShowSceneCommandForm(newSceneCommandForm);
    }
    onRequestCloseModal();
  };

  private addSound = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onForwardToAddSoundEffects,
    } = this.props;
    onForwardToAddSoundEffects(
      sceneCommandForm,
      parentSceneCommandForm?.sceneCommandId,
      sound => {
        this.setState({selectedSound: sound});
      },
    );
  };

  private deleteSound = () => {
    this.setState({
      selectedSound: null,
      selectedSoundStartTime: null,
      selectedSoundEndTime: null,
    });
  };

  private handleChangeSoundRegion = (startTime?: number, endTime?: number) => {
    this.setState({
      selectedSoundStartTime: startTime || null,
      selectedSoundEndTime: endTime || null,
    });
  };

  private handleChangeTab = (currentTab: TabValue) => {
    this.setState({currentTab});
  };

  private handleSelectText = (selectedText: string) => {
    this.setState({selectedText});
  };

  private handleChangeName = (selectedCharacterName: string) => {
    const {sceneCommandForm} = this.props;
    if (sceneCommandForm.overrideCharacterName) {
      this.setState({selectedCharacterName});
    }
  };

  private handlePressSymbol = (symbol: string) => {
    const {selectedText} = this.state;
    const {text, selection} = addSymbolToTextAndCalcSelection(
      selectedText,
      symbol,
      this.currentSelection,
    );
    this.setState({selectedText: text, selection});
    this.textInputRef.current?.focus();
  };

  private handleSelectionChange = (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => {
    if (this.state.selection) {
      this.setState({selection: undefined});
    }
    this.currentSelection = e.nativeEvent.selection;
  };

  private handleSelectSpeechBalloon = (
    selectedSpeechBalloon: SpeechBalloon,
  ) => {
    this.setState({
      selectedSpeechBalloon,
      selectedOrientedSpeechBalloon: this.findOrientedSpeechBalloonBy({
        speechBalloonId: selectedSpeechBalloon.id,
        positions: this.state.selectedPositions,
      }),
    });
  };

  private handleSelectPosition = (
    selectedPosition: Position,
    toggleMultiple?: boolean,
  ) => {
    if (!this.state.selectedSpeechBalloon) {
      return;
    }
    const multiplePositions = toggleMultiple
      ? !this.state.multiplePositions
      : this.state.multiplePositions;

    const selectedPositions = multiplePositions
      ? this.state.selectedPositions.includes(selectedPosition)
        ? this.state.selectedPositions.length === 1
          ? this.state.selectedPositions
          : this.state.selectedPositions.filter(p => p !== selectedPosition)
        : [...this.state.selectedPositions, selectedPosition]
      : [selectedPosition];
    this.setState({
      selectedOrientedSpeechBalloon: this.findOrientedSpeechBalloonBy({
        speechBalloonId: this.state.selectedSpeechBalloon.id,
        positions: selectedPositions,
      }),
      selectedPositions,
      multiplePositions,
    });
  };

  private handleUpdateVoice = (voice: Voice | null) => {
    this.setState({selectedVoice: voice});
  };

  private findSpeechBalloonById = (params: {id: number}): SpeechBalloon => {
    const found = this.props.speechBalloons?.find(
      speechBalloon => speechBalloon.id === params.id,
    );
    if (found) {
      return found;
    } else {
      throw new Error(`Not found SpeechBalloon by ${JSON.stringify(params)}`);
    }
  };

  private findOrientedSpeechBalloonBy = (params: {
    speechBalloonId: number;
    positions: Position[];
  }): OrientedSpeechBalloon => {
    const found = this.props.orientedSpeechBalloons?.find(
      orientedSpeechBalloon => {
        return (
          orientedSpeechBalloon.speechBalloonId === params.speechBalloonId &&
          orientedSpeechBalloon.matchesPositions(params.positions)
        );
      },
    );
    if (found) {
      return found;
    } else {
      throw new Error(
        `Not found OrientedSpeechBalloon by ${JSON.stringify(params)}`,
      );
    }
  };
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  } as ViewStyle,
  footer: {
    marginVertical: 5,
    padding: 10,
  } as ViewStyle,
});
