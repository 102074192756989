import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import ProjectListItem from './ProjectListItem';

import DragScrollView from '../../../../shared/drag_scroll_view';

import Project from '../../../../../../domain/entities/Project';

interface Props {
  projects: Project[];
}

const ProjectList: React.FC<Props> = props => {
  const {projects} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Project>) => (
      <ProjectListItem project={info.item} />
    ),
    [],
  );
  return (
    <DragScrollView
      horizontal={true}
      bounces={false}
      showsHorizontalScrollIndicator={false}
      scrollEventThrottle={16}>
      <FlatList data={projects} renderItem={renderItem} horizontal={true} />
    </DragScrollView>
  );
};

export default React.memo(ProjectList);
