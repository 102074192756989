export default async function convertBase64ToFile(
  base64: string,
  type: string,
) {
  const bin = atob(base64.replace(/^.*,/, ''));
  const buffer = new Uint8Array(bin.length);
  for (let i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }
  const file = new File([buffer.buffer], `cropped_image.${type}`, {
    type: `image/${type}`,
  });
  (file as any).uri = base64;
  return file;
}
