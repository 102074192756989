import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

export default function reducer(
  state: {[key: string]: {[key: number]: number[]}} = {},
  action: AnyAction,
): {[key: string]: {[key: number]: number[]}} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    default:
      return state;
  }
}
