import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import EpisodeComment from '../../../domain/entities/EpisodeComment';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexEpisodeCommentsSuccess: {
      const result = action.payload as PaginatedResult<EpisodeComment>;
      return mergeResponse(state, result);
    }

    case Types.DestroyEpisodeCommentSuccess: {
      const id = action.payload as number;
      const destroyState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        Object.keys(response.pageToIds).forEach((pageKey: string) => {
          const nPageKey = Number(pageKey);
          response.pageToIds[nPageKey] = response.pageToIds[nPageKey].filter(
            episodeCommentId => {
              return episodeCommentId !== id;
            },
          );
        });
        destroyState[stateKey] = response;
      });
      return destroyState;
    }
    default:
      return state;
  }
}
