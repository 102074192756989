import EntityMapper from '../../EntityMapper';

import Invoice, {
  Status,
} from '../../../../../domain/entities/writer/my_stripe/Invoice';

export interface InvoiceData {
  id: number;
  my_stripe_subscription_id: number;
  issued_at: Date | null;
  data_number: string;
  data_total: number;
  api_writer_receipt_url: string;
  status: Status;
  created_at: string;
  updated_at: string;
}

export default class InvoiceMapper
  implements EntityMapper<InvoiceData, Invoice>
{
  public map(obj: InvoiceData): Invoice {
    return new Invoice(
      obj.id,
      obj.my_stripe_subscription_id,
      obj.issued_at ? new Date(obj.issued_at) : null,
      obj.data_number,
      obj.data_total,
      obj.api_writer_receipt_url,
      obj.status,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
