import * as React from 'react';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const GL = require('gl-react');

const shaders = GL.Shaders.create({
  image: {
    frag: `
precision highp float;
varying vec2 uv;
uniform sampler2D t;
uniform vec4 crop;
vec2 invert (vec2 p) {
  return vec2(p.x, 1.0-p.y);
}
void main () {
  vec2 p = invert(invert(uv) * crop.zw + crop.xy);
  gl_FragColor =
    step(0.0, p.x) *
    step(0.0, p.y) *
    step(p.x, 1.0) *
    step(p.y, 1.0) *
    texture2D(t, p);
}`,
  },
});

interface Props {
  width: number;
  height: number;
  source: {uri: string};
  imageSize: {width: number; height: number};
  resizeMode: string;
  center?: any;
  zoom?: number;
}

const GLImage = GL.createComponent((props: Props) => {
  const {width, height, source, imageSize, resizeMode} = props;
  let {center, zoom} = props;
  let crop;
  switch (resizeMode) {
    case 'cover': {
      if (!center) {
        center = [0.5, 0.5];
      }
      if (!zoom) {
        zoom = 1;
      }
      let rect = rectCrop(zoom, center)({width, height}, imageSize);
      rect = rectClamp(rect, [0, 0, imageSize.width, imageSize.height]);
      crop = [
        rect[0] / imageSize.width,
        rect[1] / imageSize.height,
        rect[2] / imageSize.width,
        rect[3] / imageSize.height,
      ];
      break;
    }
    case 'contain': {
      if (center || zoom) {
        console.warn(
          "gl-react-image: center and zoom props are only supported with resizeMode='cover'",
        );
      }
      const ratio = width / height;
      const imageRatio = imageSize.width / imageSize.height;
      crop =
        ratio > imageRatio
          ? [(1 - ratio / imageRatio) / 2, 0, ratio / imageRatio, 1]
          : [0, (1 - imageRatio / ratio) / 2, 1, imageRatio / ratio];
      break;
    }
    case 'stretch':
      if (center || zoom) {
        console.warn(
          "gl-react-image: center and zoom props are only supported with resizeMode='cover'",
        );
      }
      crop = [0, 0, 1, 1];
      break;

    default:
      throw new Error('gl-react-image: unknown resizeMode=' + resizeMode);
  }
  return (
    <GL.Node
      shader={shaders.image}
      uniforms={{
        t: source,
        crop,
      }}
    />
  );
}) as React.FunctionComponent<Props>;

export default GLImage;

function rectCrop(zoom: number, center: Array<number>) {
  if (!center) {
    center = [0.5, 0.5];
  }
  return function (
    viewport: {width: number; height: number},
    dimension: {width: number; height: number},
  ) {
    const viewportRatio = viewport.width / viewport.height;
    const dimensionRatio = dimension.width / dimension.height;

    const maxRatio = Math.max(viewportRatio, dimensionRatio);
    const zoomedCanvasSize = [
      (viewportRatio / maxRatio) * dimension.width * zoom,
      (dimensionRatio / maxRatio) * dimension.height * zoom,
    ];

    return [
      dimension.width * center[0] - zoomedCanvasSize[0] / 2,
      dimension.height * center[1] - zoomedCanvasSize[1] / 2,
      zoomedCanvasSize[0],
      zoomedCanvasSize[1],
    ];
  };
}

function rectClamp(rect: Array<number>, bounds: Array<number>) {
  let w = rect[2],
    h = rect[3];
  const maxWidth = bounds[2],
    maxHeight = bounds[3];
  const ratio = w / h;
  if (w > maxWidth) {
    w = maxWidth;
    h = ~~(w / ratio);
  }
  if (h > maxHeight) {
    h = maxHeight;
    w = ~~(h * ratio);
  }
  return [
    bounds[0] + Math.max(0, Math.min(rect[0], maxWidth - w)),
    bounds[1] + Math.max(0, Math.min(rect[1], maxHeight - h)),
    w,
    h,
  ];
}
