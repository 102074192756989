import * as React from 'react';

import Form from './partials/Form';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';

import {NewEpisodeCommentReplyRouteProp} from '../../navigators/RouteProps';

import {Params as EpisodeCommentReplyCreateParams} from '../../actions/episode_comment_replies/create';

import EpisodeCommentReply from '../../../domain/entities/EpisodeCommentReply';
import EpisodeComment from '../../../domain/entities/EpisodeComment';

export interface Params {
  episodeCommentId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewEpisodeCommentReplyRouteProp;
}

export interface DispatchProps {
  createEpisodeCommentReply: (
    params: EpisodeCommentReplyCreateParams,
  ) => Promise<EpisodeCommentReply>;
  showEpisodeComment: (id: number) => Promise<EpisodeComment>;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FunctionComponent<Props> = props => {
  const {navigation, route, createEpisodeCommentReply, showEpisodeComment} =
    props;
  const {episodeCommentId: consumerUserEpisodeCommentId} = route.params;
  const [episodeComment, setEpisodeComment] =
    React.useState<EpisodeComment | null>(null);
  React.useEffect(() => {
    showEpisodeComment(consumerUserEpisodeCommentId).then(setEpisodeComment);
  }, []);
  const handleSubmit = React.useCallback((comment: string) => {
    createEpisodeCommentReply({consumerUserEpisodeCommentId, comment}).then(
      episodeCommentReply => {
        showEpisodeComment(episodeCommentReply.episodeCommentId);
      },
    );
    navigation.pop();
  }, []);
  return (
    <Layout
      title={'返信'}
      navigation={navigation}
      close={true}
      hideHeaderBottomBorder={true}>
      {episodeComment && (
        <Form
          initialComment={''}
          episodeComment={episodeComment}
          onSubmit={handleSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(New);
