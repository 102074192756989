import * as React from 'react';

import BaseIndex from '../../../../../shared/generics/character_form/default_character_patterns/Index';

import NavigationProp from '../../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatternsRouteProp} from '../../../../../../navigators/RouteProps';

import * as routers from '../../../../../../routers';

import {Params as ActorCostumeIndexParams} from '../../../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessoryIndexParams} from '../../../../../../actions/actor_accessories/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../../../actions/actor_accessory_sets/index';

import redirectSceneForm from '../../../../../../helpers/redirectSceneForm';

import Actor from '../../../../../../../domain/entities/Actor';
import ActorCostume from '../../../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../../../domain/entities/ActorHairStyle';
import ActorAccessory from '../../../../../../../domain/entities/ActorAccessory';
import ActorAccessorySet from '../../../../../../../domain/entities/ActorAccessorySet';
import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';
import PaginatedResult from '../../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  actorId: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatternsRouteProp;
  sceneForm: SceneForm | null;
  actor: Actor | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessories: (
    params: ActorAccessoryIndexParams,
  ) => Promise<PaginatedResult<ActorAccessory>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        actorId={this.props.route.params.actorId}
        onSelectActorCharacter={this.handleSelectActorCharacter}
      />
    );
  }

  private handleSelectActorCharacter = (
    actorCharacter: ActorCharacter,
    inverted: boolean,
  ) => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
    } = route.params;
    const actorCharacterId = actorCharacter.id;
    routers.linkToSceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        actorCharacterId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
        inverted,
      },
    );
  };
}
