import {CommonActions} from '@react-navigation/native';

import NavigationProp from '../navigators/NavigationProp';

import CoverImageForm from '../../domain/forms/CoverImageForm';

function redirectStoryCoverImageForm(
  navigation: NavigationProp,
  route: {params: {storyId: number; skipTitleValidation?: boolean}},
  coverImageForm: CoverImageForm | null,
) {
  const {storyId} = route.params;
  if (!coverImageForm) {
    setTimeout(() => {
      navigation.getParent()?.dispatch(
        CommonActions.reset({
          index: 1,
          routes: [
            {
              name: 'MainStackNavigator',
              state: {
                index: 1,
                routes: [
                  {name: 'Home'},
                  {
                    params: {storyId},
                    name: 'Story',
                  },
                ],
              },
            },
            {
              name: 'EditStoryNavigation',
              state: {
                index: 0,
                routes: [
                  {
                    name: 'EditStoryFormCoverImage',
                    params: {
                      storyId,
                      skipTitleValidation: true,
                    },
                  },
                ],
              },
            },
          ],
        }),
      );
    }, 300);
    return true;
  }
  return false;
}

export default redirectStoryCoverImageForm;
