import * as React from 'react';
import {StyleProp, StyleSheet, TextStyle} from 'react-native';

import shouldUpdateEntityList from '../../../shared/enhanced/shouldUpdateEntityList';
import DefaultList from '../../../shared/lists/DefaultList';

import {colors} from '../../../../styles/variables';

import Genre from '../../../../../domain/entities/Genre';

interface Props {
  genres: Genre[];
  mainGenreId: number | null;
  subGenreIds: number[] | null;
  onSelectGenre: (genre: Genre) => void;
}

export default class CharacterList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (this.props.mainGenreId !== nextProps.mainGenreId) {
      return true;
    }
    if (this.props.subGenreIds !== nextProps.subGenreIds) {
      return true;
    }
    if (
      shouldUpdateEntityList(
        {entities: this.props.genres},
        {entities: nextProps.genres},
      )
    ) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {genres} = this.props;
    return (
      <DefaultList
        data={genres}
        separator={'right'}
        renderTitle={this.renderTitle}
        onSelectItem={this.handleSelectItem}
        titleStyleExtractor={this.titleStyleExtractor}
      />
    );
  }

  private renderTitle = (item: Genre): React.ReactNode => {
    return item.name;
  };

  private handleSelectItem = (genre: Genre) => {
    const {subGenreIds, onSelectGenre} = this.props;
    if (subGenreIds && subGenreIds.find(id => id === genre.id)) {
      return;
    }
    onSelectGenre(genre);
  };

  private titleStyleExtractor = (genre: Genre): StyleProp<TextStyle> => {
    const {subGenreIds} = this.props;
    if (subGenreIds && subGenreIds.find(id => id === genre.id)) {
      return styles.title;
    } else {
      return null;
    }
  };
}

const styles = StyleSheet.create({
  title: {
    color: colors.powderGray,
  } as TextStyle,
});
