import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Character from '../../../domain/entities/Character';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetCharactersRepository from '../../../data/repositories/writer/NetCharactersRepository';

const types = {
  failure: Types.IndexCharactersFailure,
  request: Types.IndexCharactersRequest,
  success: Types.IndexCharactersSuccess,
};

export interface Params extends BaseParams {
  storyId?: number | null;
  storyIdOfEpisodeId?: number | null;
  storyIdOfSceneId?: number | null;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Character>> {
  return indexGenerics(new NetCharactersRepository(), types, params);
}
