import * as React from 'react';
import {ScrollView, StyleSheet, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import Input from '../../../../shared/forms/Input';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';

import {formStyles} from '../../../../../styles/variables';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

export type FormValuesEpisode = {
  title: string;
};

type FormErrorMessage = {[P in keyof FormValuesEpisode]?: string | string[]};

interface Props {
  values?: Partial<FormValuesEpisode>;
  errorMessages?: FormErrorMessage;
  onSubmit: (values: FormValuesEpisode) => void;
}

const FormStep2Episode: React.FC<Props> = props => {
  const {values, errorMessages, onSubmit} = props;
  const [title, setTitle] = React.useState(values?.title || '');
  const onPress = () => {
    onSubmit({title});
  };
  const disabled = title.length <= 0 || title.length > 40;
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{paddingBottom: 130}}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'エピソードタイトル(最大40文字)'}
            maxLength={40}
            length={title.length}
            requiredOrOptional={'required'}
            entered={title.length > 0 && title.length <= 40}
            errorMessages={errorMessages?.title}
          />
          <Input
            style={[
              {marginBottom: 3, marginTop: 8},
              title.length === 0
                ? requiredInputStyle
                : title.length <= 40
                ? undefined
                : styles.inputError,
            ]}
            value={title}
            placeholder={'エピソードタイトルを入力'}
            onChangeText={setTitle}
          />
        </View>
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <View style={styles.button}>
          <PrimaryButton disabled={disabled} onPress={onPress}>
            エピソードを作成する
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
};

export default React.memo(FormStep2Episode);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  inputError: {
    color: '#f23406',
  },
});
