import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EpisodeCommentReplyMapper, {
  EpisodeCommentReplyData,
} from '../../entities/mappers/EpisodeCommentReplyMapper';

import EpisodeCommentReply from '../../../domain/entities/EpisodeCommentReply';
import EpisodeCommentRepliesRepository from '../../../domain/repositories/writer/EpisodeCommentRepliesRepository';

export default class NetEpisodeCommentRepliesRepository
  extends NetResourcesRepository<EpisodeCommentReply, EpisodeCommentReplyData>
  implements EpisodeCommentRepliesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/episode_comment_replies',
        new EpisodeCommentReplyMapper(),
      ),
    );
  }
}
