import Entity from '../Entity';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class CurrentUserProfile implements Entity {
  constructor(
    public id: number,
    public description: string,
    public hasImage: boolean,
    public originalImageUrl: string,
    public birthday: Date | null,
    public homePageUrl: string | null,
    public twitterAccountUrl: string | null,
    public youtubeChannelUrl: string | null,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }
}
