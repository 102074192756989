import * as React from 'react';
import {FlatList, StyleSheet, ViewStyle} from 'react-native';

import MarkListItem from './MarkListItem';

import Mark from '../../../../domain/entities/Mark';

interface Props {
  marks: Mark[] | null;
  selectedMark: Mark | null;
  width: number;
  visible: boolean;
  onSelectMark: (mark: Mark) => void;
}

const MarkList: React.FunctionComponent<Props> = props => {
  const {marks, selectedMark, width, visible, onSelectMark} = props;
  return (
    <FlatList
      data={[new Mark(0, ''), ...(marks || [])]}
      style={[styles.container, visible ? null : {display: 'none'}]}
      keyExtractor={item => `${item.id}`}
      renderItem={info => {
        return (
          <MarkListItem
            style={styles.content}
            mark={info.item}
            width={
              (width - (MARGIN_HORIZONTAL + CONTENT_MARGIN * NUM_COLUMNS) * 2) /
              NUM_COLUMNS
            }
            selected={selectedMark?.id === info.item.id}
            onSelectMark={onSelectMark}
          />
        );
      }}
      numColumns={NUM_COLUMNS}
      extraData={selectedMark}
    />
  );
};

export default MarkList;

const MARGIN_HORIZONTAL = 14;
const CONTENT_MARGIN = 2;

const NUM_COLUMNS = 3;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: MARGIN_HORIZONTAL,
  } as ViewStyle,
  content: {
    margin: CONTENT_MARGIN,
  } as ViewStyle,
});
