import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import dateformat from 'dateformat';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';

import ImageUrlHelper from '../../../../../domain/helpers/ImageUrlHelper';
import ProjectEpisodeReleaseRequest from '../../../../../domain/entities/ProjectEpisodeReleaseRequest';

interface Props {
  episodeReleaseRequest: ProjectEpisodeReleaseRequest;
}

const EpisodeReleaseRequestListItem: React.FC<Props> = props => {
  const linkTo = useLinkTo();
  const {episodeReleaseRequest} = props;
  const episode = episodeReleaseRequest.episode;
  const onPress = React.useCallback(() => {
    linkTo(
      `/projects/${episodeReleaseRequest.projectId}/episode_release_requests/${episodeReleaseRequest.id}`,
    );
  }, []);
  const source = {
    uri: ImageUrlHelper.getImageUrl(
      episodeReleaseRequest.episode.originalImageUrl,
      {width: 992, height: 622},
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.left}>
        {episodeReleaseRequest.episode.hasImage ? (
          <FastImageAdapter style={styles.image} source={source} />
        ) : (
          <Text style={styles.noImageText}>サムネイル</Text>
        )}
        <View style={styles.readTime}>
          <Text style={styles.readTimeText}>
            読了{episodeReleaseRequest.episode.approximateReadingTimeText}
          </Text>
        </View>
      </View>
      <View style={styles.right}>
        <Text style={styles.title}>{episode.title}</Text>
        <Text style={styles.penName}>
          {episodeReleaseRequest.writerUser.penName}
        </Text>
        <Text style={styles.updatedAt}>
          {dateformat(episodeReleaseRequest.createdAt, 'yyyy.mm.dd')}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(EpisodeReleaseRequestListItem);

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flexDirection: 'row',
  } as ViewStyle,
  left: {
    width: 160,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#d8d8d8',
  } as ViewStyle,
  right: {
    marginLeft: 10,
  } as ViewStyle,
  image: {
    width: 160,
    height: 100,
  } as ImageStyle,
  noImageText: {
    color: '#999',
    fontSize: 20,
    fontWeight: 'bold',
  } as TextStyle,
  readTime: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 3,
    minWidth: 48,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 2,
  } as ViewStyle,
  readTimeText: {
    color: '#fff',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  title: {
    marginVertical: 8,
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  penName: {
    color: '#222',
    fontSize: 10,
  } as TextStyle,
  info: {
    flexDirection: 'row',
    marginVertical: 8,
  } as ViewStyle,
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  } as ViewStyle,
  infoRowText: {
    color: '#999',
    fontSize: 10,
    marginLeft: 4,
  } as TextStyle,
  infoRowUnit: {
    color: '#999',
    fontSize: 10,
    marginLeft: 1,
  } as TextStyle,
  updatedAt: {
    color: '#999',
    fontSize: 10,
    marginTop: 8,
  } as TextStyle,
  headerRight: {
    alignItems: 'center',
    height: 44,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 44,
    zIndex: 100,
  } as ViewStyle,
  more: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
});
