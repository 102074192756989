import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/projects/characters/Index';

import AppState from '../../../reducers/AppState';

import updateCharacter, {
  Params as CharacterUpdateParams,
} from '../../../actions/characters/update';
import destroyCharacter from '../../../actions/characters/destroy';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    updateCharacter: (id: number, params: CharacterUpdateParams) =>
      dispatch(updateCharacter(id, params)),
    destroyCharacter: (id: number) => dispatch(destroyCharacter(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
