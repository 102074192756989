import CharacterPattern from '../../../../domain/entities/CharacterPattern';

interface Props {
  characterPatterns: CharacterPattern[] | null;
}

export default (current: Props, next: Props) => {
  if (current.characterPatterns === next.characterPatterns) {
    return false;
  }
  if (!current.characterPatterns || !next.characterPatterns) {
    return true;
  }
  if (current.characterPatterns.length !== next.characterPatterns.length) {
    return true;
  }
  return !current.characterPatterns.every((currentCharacterPattern, i) => {
    if (!next.characterPatterns) {
      return false;
    }
    const nextCharacterPattern = next.characterPatterns[i];
    if (currentCharacterPattern.id !== nextCharacterPattern.id) {
      return false;
    }
    if (
      currentCharacterPattern.character.updatedAt.getTime() !==
      nextCharacterPattern.character.updatedAt.getTime()
    ) {
      return false;
    }
    return true;
  });
};
