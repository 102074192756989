import * as React from 'react';
import {Text, View} from 'react-native';
import dateformat from 'dateformat';

import DefaultModal from '../../../shared/modals/DefaultModal';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import {sendDefaultCustomEvent} from '../../../../helpers/analytics';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

import Subscription from '../../../../../domain/entities/writer/my_stripe/maker_pro/Subscription';

import NetCurrentUserRepository from '../../../../../data/repositories/writer/NetCurrentUserRepository';
import NetSubscriptionRepository from '../../../../../data/repositories/writer/my_stripe/maker_pro/NetSubscriptionRepository';

interface Props {
  currentUser: CurrentUser;
  setCurrentUser: React.Dispatch<React.SetStateAction<CurrentUser | null>>;
  onCloseModal: () => void;
  onOpenCancelCompleteModal: () => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const CancelModal: React.FunctionComponent<Props> = props => {
  const {setCurrentUser, onCloseModal, onOpenCancelCompleteModal, setLoading} =
    props;
  const [subscription, setSubscription] = React.useState<Subscription | null>(
    null,
  );
  React.useEffect(() => {
    new NetSubscriptionRepository().find().then(setSubscription);
  }, []);
  const onPress = React.useCallback(() => {
    setLoading(true);
    onCloseModal();
    new NetSubscriptionRepository().update({method: 'cancel'}).then(() => {
      const intervalId = setInterval(() => {
        new NetCurrentUserRepository().find().then(currentUser => {
          if (currentUser.paidSubscriberCancelAt) {
            clearInterval(intervalId);
            setLoading(false);
            setCurrentUser(currentUser);
            onOpenCancelCompleteModal();
            sendDefaultCustomEvent({
              resourceType: 'writer/paid_subscriber_user',
              resourceId: currentUser.id,
              actionName: 'destroy',
            });
          }
        });
      }, 1000);
    });
  }, []);
  if (!subscription) {
    return null;
  }
  return (
    <DefaultModal
      visible={true}
      disableCloseByOutsideTouch={true}
      onCloseModal={onCloseModal}>
      <Text
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          textAlign: 'center',
        }}>
        退会してもよろしいですか？
      </Text>
      <View
        style={{justifyContent: 'center', alignItems: 'center', margin: 24}}>
        {subscription.currentPeriodEnd ? (
          <>
            <Text style={{marginBottom: 16, textAlign: 'center'}}>
              {dateformat(
                subscription.currentPeriodEnd,
                'yyyy年mm月dd日 HH:MM',
              )}
              まで{'\n'}
              MakerPro会員をご利用いただけます
            </Text>
            <PrimaryButton style={{width: 250}} onPress={onPress}>
              退会する
            </PrimaryButton>
          </>
        ) : null}
      </View>
    </DefaultModal>
  );
};

export default React.memo(CancelModal);
