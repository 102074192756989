import Entity from '../../../../domain/entities/Entity';

interface Props {
  entities: Entity[] | null;
}

export default (current: Props, next: Props) => {
  if (current.entities === next.entities) {
    return false;
  }
  if (!current.entities || !next.entities) {
    return true;
  }
  if (current.entities.length !== next.entities.length) {
    return true;
  }
  return !current.entities.every((currentEntity, i) => {
    if (!next.entities) {
      return false;
    }
    const nextEntity = next.entities[i];
    if (currentEntity.id !== nextEntity.id) {
      return false;
    }
    return true;
  });
};
