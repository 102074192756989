import * as React from 'react';
import {StyleProp, StyleSheet, TouchableOpacity, ViewStyle} from 'react-native';

import StarListIcon from './icons/StarListIcon';

interface Props {
  style?: StyleProp<ViewStyle>;
  onPress?: (() => void) | undefined;
}

const ResourceFavoriteListLink: React.FunctionComponent<Props> = props => {
  const {style, onPress} = props;
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <StarListIcon />
    </TouchableOpacity>
  );
};

export default React.memo(ResourceFavoriteListLink);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f5c71c',
    width: 32,
    height: 32,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
