import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import WriterTemporaryUserRegistrationCompletion from '../../../../containers/writer/temporary_user_registration_completion/Index';
import NewWriterTemporaryUsersTermsAcceptance from '../../../../containers/writer/temporary_users/terms_acceptance/New';
import NewWriterUserRegistration from '../../../../containers/writer/user_registrations/New';
import EditWriterUser from '../../../../containers/writer/users/Edit';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="NewWriterUserRegistration">
      <RootStack.Screen
        name="WriterTemporaryUserRegistrationCompletion"
        component={WriterTemporaryUserRegistrationCompletion}
        options={{title: '仮会員登録完了'}}
      />

      <RootStack.Screen
        name="NewWriterTemporaryUsersTermsAcceptance"
        component={NewWriterTemporaryUsersTermsAcceptance}
        options={{title: 'サービス利用開始'}}
      />

      <RootStack.Screen
        name="EditWriterUser"
        component={EditWriterUser}
        options={{title: 'プロフィール'}}
      />

      <RootStack.Screen
        name="NewWriterUserRegistration"
        component={NewWriterUserRegistration}
        options={{title: '会員登録'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
