import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import Episode from '../../../../../domain/entities/Episode';

interface Props {
  episode: Episode;
  onSelectEpisode: (episode: Episode) => void;
}

const EpisodeListItem: React.FunctionComponent<Props> = props => {
  const {episode, onSelectEpisode} = props;
  const onPress = React.useCallback(() => {
    onSelectEpisode(episode);
  }, []);
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.episodeInfo}>
        <Text style={styles.title}>{episode.title}</Text>
      </View>
    </Pressable>
  );
};

export default React.memo(EpisodeListItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    margin: 10,
    backgroundColor: 'white',
  } as ViewStyle,
  episodeInfo: {
    flex: 1,
    margin: 10,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
});
