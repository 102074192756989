import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import SpeechBalloonMapper, {
  SpeechBalloonData,
} from '../entities/mappers/SpeechBalloonMapper';

import SpeechBalloon from '../../domain/entities/SpeechBalloon';
import SpeechBalloonsRepository from '../../domain/repositories/SpeechBalloonsRepository';

export default class NetSpeechBalloonsRepository
  extends NetResourcesRepository<SpeechBalloon, SpeechBalloonData>
  implements SpeechBalloonsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/speech_balloons',
        new SpeechBalloonMapper(),
      ),
    );
  }
}
