import EntityMapper from '../EntityMapper';

import Extension from '../../../../domain/entities/writer/Extension';

export interface ExtensionData {
  id: number;
  code: string;
  name: string;
  name_with_newline: string | null;
  description: string;
  created_at: string;
  updated_at: string;
}

export default class ExtensionMapper
  implements EntityMapper<ExtensionData, Extension>
{
  public map(obj: ExtensionData): Extension {
    return new Extension(
      obj.id,
      obj.code,
      obj.name,
      obj.name_with_newline,
      obj.description,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
