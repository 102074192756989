import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props<Value> {
  value: Value;
  label: string;
  isActive: boolean;
  onChangeTab: (value: Value) => void;
}

export default class Tab<Value> extends React.PureComponent<Props<Value>> {
  public render(): React.ReactNode {
    const {value, label, isActive, onChangeTab} = this.props;
    return (
      <Pressable onPress={() => onChangeTab(value)}>
        <View
          style={[styles.container, isActive ? styles.containerActive : null]}>
          <Text style={[styles.label, isActive ? styles.labelActive : null]}>
            {label}
          </Text>
        </View>
      </Pressable>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    minWidth: 75,
    height: 24,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    marginHorizontal: 2,
  } as ViewStyle,
  containerActive: {
    backgroundColor: '#efefef',
  } as ViewStyle,
  label: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#666666',
  } as TextStyle,
  labelActive: {
    color: '#383838',
  } as TextStyle,
});
