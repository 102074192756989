import * as React from 'react';
import {View, ViewStyle} from 'react-native';

import OfferManuscriptList from './OfferManuscriptList';

import ScrollableTabViewAdapter from '../../../shared/scrollable_tab_view/ScrollableTabViewAdapter';

import OfferManuscript from '../../../../../domain/entities/writers_gate/OfferManuscript';

interface Props {
  unlockedOfferManuscripts: OfferManuscript[];
  lockedOfferManuscripts: OfferManuscript[];
}

interface State {
  currentPage: number;
}

const Tabs: React.FC<Props> = props => {
  const {unlockedOfferManuscripts, lockedOfferManuscripts} = props;
  return (
    <ScrollableTabViewAdapter
      tabs={[
        {
          label: '審査中',
          element: (
            <OfferManuscriptList
              title={'審査中の'}
              offerManuscripts={unlockedOfferManuscripts}
            />
          ),
        },
        {
          label: '結果発表',
          element: (
            <OfferManuscriptList
              title={'結果発表された'}
              offerManuscripts={lockedOfferManuscripts}
            />
          ),
        },
      ]}
    />
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(Tabs, areEqual);
