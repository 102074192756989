import {getStateFromPath, getActionFromState} from '@react-navigation/native';
import {navigationRef} from './navigationRef';
import LinkingConfig from './LinkingConfig';

const dispatchAction = (path: string) => {
  const state = getStateFromPath(path, {screens: LinkingConfig});

  if (!state) {
    return;
  }

  const action = getActionFromState(state);

  if (!action) {
    return;
  }

  try {
    navigationRef?.current?.dispatch(action);
  } catch (error) {
    // noop
  }
};

export default dispatchAction;
