import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EpisodeCommentMapper, {
  EpisodeCommentData,
} from '../../entities/mappers/EpisodeCommentMapper';

import EpisodeComment from '../../../domain/entities/EpisodeComment';
import EpisodeCommentsRepository from '../../../domain/repositories/writer/EpisodeCommentsRepository';

export default class NetEpisodeCommentsRepository
  extends NetResourcesRepository<EpisodeComment, EpisodeCommentData>
  implements EpisodeCommentsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/episode_comments',
        new EpisodeCommentMapper(),
      ),
    );
  }
}
