import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import ElasticSpeechBalloon from '../../../../../shared/ElasticSpeechBalloon';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';

import NameLabelColor from '../../../../../../view_models/NameLabelColor';

import OrientedSpeechBalloon from '../../../../../../../domain/entities/OrientedSpeechBalloon';

interface Props {
  orientedSpeechBalloon: OrientedSpeechBalloon;
  text: string;
  name?: string;
  nameLabelColor?: NameLabelColor;
  hasVoice?: boolean;
  hasSound?: boolean;
  children?: React.ReactNode;
}

const SpeechTextView: React.FunctionComponent<Props> = props => {
  const {
    orientedSpeechBalloon,
    text,
    name,
    nameLabelColor,
    hasVoice,
    hasSound,
  } = props;
  return (
    <View style={styles.container}>
      <DimensionContext.Consumer>
        {context => (
          <ElasticSpeechBalloon
            orientedSpeechBalloon={orientedSpeechBalloon}
            text={text}
            width={context.content.width}
            name={name}
            nameLabelColor={nameLabelColor}
            hasVoice={hasVoice}
            hasSound={hasSound}
          />
        )}
      </DimensionContext.Consumer>
    </View>
  );
};

export default React.memo(SpeechTextView);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: '100%',
    marginVertical: 5,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
});
