import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import New, {
  DispatchProps,
  StateProps,
} from '../../../components/writer/user_sessions/New';

import AppState from '../../../reducers/AppState';

import createUserSession, {
  Params as UserSessionCreateParams,
} from '../../../actions/writer/users/session/create';
import createFacebookAccountSession, {
  Params as FacebookAccountSessionCreateParams,
} from '../../../actions/writer/social_accounts/facebook_accounts/session/create';
import createLineAccountSession, {
  Params as LineAccountSessionCreateParams,
} from '../../../actions/writer/social_accounts/line_accounts/session/create';
import createTwitterAccountSession, {
  Params as TwitterAccountSessionCreateParams,
} from '../../../actions/writer/social_accounts/twitter_accounts/session/create';

import selectEntity from '../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  return {
    navigation,
    currentUser,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createUserSession: (params: UserSessionCreateParams) =>
      dispatch(createUserSession(params)),
    createFacebookAccountSession: (
      params: FacebookAccountSessionCreateParams,
    ) => dispatch(createFacebookAccountSession(params)),
    createLineAccountSession: (params: LineAccountSessionCreateParams) =>
      dispatch(createLineAccountSession(params)),
    createTwitterAccountSession: (params: TwitterAccountSessionCreateParams) =>
      dispatch(createTwitterAccountSession(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
