import * as React from 'react';

import Layout from '../../../../shared/Layout';
import SearchResult from '../../../../shared/scene_form/SearchResult';
import ResourceFavoriteButton from '../../../ResourceFavoriteButton';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormBackgroundShowSceneCommandNewBaseBackgroundsRouteProp,
  CoverImageFormBaseBackgroundsRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as BaseBackgroundIndexParams} from '../../../../../actions/base_backgrounds/index';

import {baseBackgroundImageUrl} from '../../../../../helpers/images';
import {getNextPage} from '../../../../../helpers/selectEntities';

import {QueryState} from '../../../../../reducers/queries/Response';

import BackgroundCategory from '../../../../../../domain/entities/BackgroundCategory';
import BaseBackground from '../../../../../../domain/entities/BaseBackground';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

export interface Props {
  navigation: NavigationProp;
  route:
    | SceneFormBackgroundShowSceneCommandNewBaseBackgroundsRouteProp
    | CoverImageFormBaseBackgroundsRouteProp;
  baseBackgroundsParams: BaseBackgroundIndexParams;
  baseBackgrounds: BaseBackground[] | null;
  totalCount: number | null;
  backgroundCategory: BackgroundCategory | null;
  baseBackgroundQueries: QueryState;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
  enabledFavorite?: boolean;
  indexBaseBackgrounds: (
    params: BaseBackgroundIndexParams,
  ) => Promise<PaginatedResult<BaseBackground>>;
  showBackgroundCategory: (id: number) => Promise<BackgroundCategory>;
  onPressSearchConditionChangeButton: () => void;
  onSelectBaseBackground: (baseBackground: BaseBackground) => void;
}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    this.fetchEntities();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {route} = this.props;
    const {route: prevRoute} = prevProps;
    const {query, backgroundCategoryId, sort} = route.params;
    const {
      query: prevQuery,
      backgroundCategoryId: prevBackgroundCategoryId,
      sort: prevSort,
    } = prevRoute.params;
    if (
      query === prevQuery &&
      backgroundCategoryId === prevBackgroundCategoryId &&
      sort === prevSort
    ) {
      return;
    }
    this.fetchEntities();
  }

  public render(): React.ReactNode {
    const {
      navigation,
      baseBackgrounds,
      totalCount,
      sort,
      favorite,
      onPressSearchConditionChangeButton,
      onSelectBaseBackground,
    } = this.props;
    return (
      <Layout
        title={`${favorite ? 'お気に入り' : ''}背景`}
        navigation={navigation}
        scrollable={false}
        back={true}
        close={true}>
        <SearchResult
          entities={baseBackgrounds}
          totalCount={totalCount}
          title={this.getTitle()}
          aspectRatio={aspectRatio}
          resizeMode={'cover'}
          sort={sort}
          favorite={favorite}
          resourceName={'背景'}
          onPressSearchConditionChangeButton={
            onPressSearchConditionChangeButton
          }
          onSelectEntity={onSelectBaseBackground}
          onPressSort={this.handlePressSort}
          onEndReached={this.handleEndReached}
          imageUrlExtractor={this.imageUrlExtractor}
          modalRenderFavoriteButton={this.modalRenderFavoriteButton}
        />
      </Layout>
    );
  }

  private fetchEntities = () => {
    const {
      route,
      baseBackgroundsParams,
      baseBackgrounds,
      backgroundCategory,
      favorite,
      indexBaseBackgrounds,
      showBackgroundCategory,
    } = this.props;
    const {backgroundCategoryId} = route.params;
    if (!baseBackgrounds || favorite) {
      indexBaseBackgrounds(baseBackgroundsParams);
    }
    if (!backgroundCategory && backgroundCategoryId) {
      showBackgroundCategory(backgroundCategoryId);
    }
  };

  private imageUrlExtractor = (
    baseBackground: BaseBackground,
    width: number,
  ) => {
    return baseBackgroundImageUrl(baseBackground, 'middle');
  };

  private handleEndReached = (info: {distanceFromEnd: number}): void => {
    const {baseBackgroundQueries, baseBackgroundsParams, indexBaseBackgrounds} =
      this.props;
    const nextPage = getNextPage(baseBackgroundQueries, baseBackgroundsParams);
    if (nextPage) {
      indexBaseBackgrounds({...baseBackgroundsParams, page: nextPage});
    }
  };

  private getTitle = () => {
    const ary: Array<string> = [];
    const {route, backgroundCategory, favorite} = this.props;
    const {query} = route.params;
    if (favorite) {
      ary.push('お気に入り');
    }
    if (query) {
      ary.push(query);
    }
    if (backgroundCategory) {
      ary.push(backgroundCategory.name);
    }
    return ary.join(' / ');
  };

  private handlePressSort = (sort: 'popularity' | 'new_arrival') => {
    const {navigation} = this.props;
    navigation.setParams({sort});
  };

  private modalRenderFavoriteButton = (baseBackground: BaseBackground) => {
    const {enabledFavorite} = this.props;
    if (!enabledFavorite) {
      return null;
    }
    return (
      <ResourceFavoriteButton
        style={{top: 55, right: 10}}
        resourceType={'BaseBackground'}
        resourceId={baseBackground.id}
      />
    );
  };
}

const aspectRatio = 1.25;
