import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  style?: ViewStyle;
  totalCount: number | null;
  sort?: 'first' | 'last';
  onPress: (sort: 'first' | 'last') => void;
}

const TotalCountWithSort: React.FunctionComponent<Props> = props => {
  const {style, totalCount, onPress} = props;
  const sort = props.sort || 'first';
  const handlePressFirst = React.useCallback(() => {
    onPress('first');
  }, []);
  const handlePressLast = React.useCallback(() => {
    onPress('last');
  }, []);
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.totalCount}>
        {totalCount === null ? '' : `全${totalCount}件`}
      </Text>
      <View style={styles.sortButtons}>
        <Pressable disabled={sort === 'first'} onPress={handlePressFirst}>
          <Text
            style={[
              styles.sortButton,
              sort === 'first' ? styles.sortButtonActive : null,
            ]}>
            最初から
          </Text>
        </Pressable>
        <Text style={styles.bar}>|</Text>
        <Pressable disabled={sort === 'last'} onPress={handlePressLast}>
          <Text
            style={[
              styles.sortButton,
              sort === 'last' ? styles.sortButtonActive : null,
            ]}>
            最新エピソードから
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

export default React.memo(TotalCountWithSort);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 12,
  } as ViewStyle,
  totalCount: {
    color: '#222222',
    fontSize: 13,
  } as TextStyle,
  sortButtons: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  sortButton: {
    color: '#222222',
    fontSize: 13,
  } as TextStyle,
  sortButtonActive: {
    color: '#ff8f13',
    fontWeight: 'bold',
  } as TextStyle,
  bar: {
    color: '#e5e5e5',
    fontSize: 15,
    marginHorizontal: 5,
  } as TextStyle,
});
