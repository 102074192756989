import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import OrientedSpeechBalloonMapper, {
  OrientedSpeechBalloonData,
} from '../entities/mappers/OrientedSpeechBalloonMapper';

import OrientedSpeechBalloon from '../../domain/entities/OrientedSpeechBalloon';
import OrientedSpeechBalloonsRepository from '../../domain/repositories/OrientedSpeechBalloonsRepository';

export default class NetOrientedSpeechBalloonsRepository
  extends NetResourcesRepository<
    OrientedSpeechBalloon,
    OrientedSpeechBalloonData
  >
  implements OrientedSpeechBalloonsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/oriented_speech_balloons',
        new OrientedSpeechBalloonMapper(),
      ),
    );
  }
}
