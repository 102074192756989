import EntityMapper from '../EntityMapper';

import CurrentUserProfileMapper, {
  CurrentUserProfileData,
} from './CurrentUserProfileMapper';
import GradeMapper, {GradeData} from './GradeMapper';

import CurrentUser, {
  PaymentMethod,
} from '../../../../domain/entities/writer/CurrentUser';
import CurrentUserProfile from '../../../../domain/entities/writer/CurrentUserProfile';
import Grade from '../../../../domain/entities/writer/Grade';

export interface CurrentUserData {
  id: number;
  pen_name: string;
  email: string;
  unconfirmed_email: string | null;
  confirmed: boolean;
  enabled: boolean;
  paid_subscriber_id: number | null;
  enabled_paid_subscriber: boolean;
  using_payment_method: PaymentMethod | null;
  paid_subscriber_cancel_at: string | null;
  accepted_terms: boolean;
  writer_user_id: number;
  writer_grade_id: number;
  enabled_consumer_user: boolean;
  community_member_status:
    | 'unsupported'
    | 'joined_fb_group'
    | 'canceled'
    | null;
  user_profile: CurrentUserProfileData | null;
  grade: GradeData | null;
}

export default class CurrentUserMapper
  implements EntityMapper<CurrentUserData, CurrentUser>
{
  private userProfile: CurrentUserProfileMapper;
  private grade: GradeMapper;

  constructor() {
    this.userProfile = new CurrentUserProfileMapper();
    this.grade = new GradeMapper();
  }

  public map(obj: CurrentUserData, headers: Headers | null): CurrentUser {
    let userProfile: CurrentUserProfile | null = null;
    let grade: Grade | null = null;
    if (obj.user_profile) {
      userProfile = this.userProfile.map(obj.user_profile);
    }
    if (obj.grade) {
      grade = this.grade.map(obj.grade);
    }
    let accessToken: string | null = null;
    if (headers) {
      accessToken = headers.get('authorization');
    }
    return new CurrentUser(
      obj.id,
      obj.pen_name,
      obj.email,
      obj.unconfirmed_email,
      obj.confirmed,
      obj.enabled,
      obj.paid_subscriber_id,
      obj.enabled_paid_subscriber,
      obj.using_payment_method,
      obj.paid_subscriber_cancel_at
        ? new Date(obj.paid_subscriber_cancel_at)
        : null,
      obj.accepted_terms,
      obj.writer_user_id,
      obj.writer_grade_id,
      obj.enabled_consumer_user,
      obj.community_member_status || 'unsupported',
      accessToken,
      userProfile,
      grade,
    );
  }
}
