import React from 'react';

import RootStack from '../../../../RootStack';

import SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm from '../../../../../containers/scene_form/background_music_show_scene_command/new/sound_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm">
      <RootStack.Screen
        name="SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm"
        component={SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm}
        options={{title: 'BGM検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
