import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import Settings from '../../../containers/settings/Index';
import SettingsNotifications from '../../../containers/settings/notifications/Index';
import SettingsBillingPaymentMethods from '../../../containers/settings/billing/payment_methods/Index';
import NewSettingsBillingPaymentMethod from '../../../containers/settings/billing/payment_methods/New';

import NewContact from '../../../containers/contacts/New';

import PoliciesPrivacy from '../../../containers/policies/privacy/Index';
import PoliciesTerms from '../../../containers/policies/terms/Index';
import PoliciesSettlementLaw from '../../../containers/policies/settlement_law/Index';
import PoliciesSpecifiedCommercialTransactionLaw from '../../../containers/policies/specified_commercial_transaction_law/Index';

import WriterUserAccount from '../../../containers/writer/user_accounts/Show';
import WriterUserVideoShareServiceAccounts from '../../../containers/writer/user_video_share_service_accounts/Index';

import EditWriterUserRegistrationEmail from '../../../containers/writer/user_registrations/emails/Edit';
import EditWriterUserRegistrationPassword from '../../../containers/writer/user_registrations/passwords/Edit';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="Settings">
      <RootStack.Screen
        name="Settings"
        component={Settings}
        options={{title: '設定'}}
      />

      <RootStack.Screen
        name="SettingsNotifications"
        component={SettingsNotifications}
        options={{title: '通知設定'}}
      />

      <RootStack.Screen
        name="SettingsBillingPaymentMethods"
        component={SettingsBillingPaymentMethods}
        options={{title: 'お支払い方法'}}
      />
      <RootStack.Screen
        name="NewSettingsBillingPaymentMethod"
        component={NewSettingsBillingPaymentMethod}
        options={{title: 'お支払い方法の追加'}}
      />

      <RootStack.Screen
        name="NewContact"
        component={NewContact}
        options={{title: 'お問い合わせ'}}
      />

      <RootStack.Screen
        name="PoliciesPrivacy"
        component={PoliciesPrivacy}
        options={{title: 'プライバシーポリシー'}}
      />

      <RootStack.Screen
        name="PoliciesTerms"
        component={PoliciesTerms}
        options={{title: '利用規約'}}
      />

      <RootStack.Screen
        name="PoliciesSettlementLaw"
        component={PoliciesSettlementLaw}
        options={{title: '資金決済法に基づく表示'}}
      />

      <RootStack.Screen
        name="PoliciesSpecifiedCommercialTransactionLaw"
        component={PoliciesSpecifiedCommercialTransactionLaw}
        options={{title: '特定商取引法に基づく表示'}}
      />

      <RootStack.Screen
        name="WriterUserAccount"
        component={WriterUserAccount}
        options={{title: 'アカウント設定'}}
      />

      <RootStack.Screen
        name="WriterUserVideoShareServiceAccounts"
        component={WriterUserVideoShareServiceAccounts}
        options={{title: '動画サービス連携'}}
      />

      <RootStack.Screen
        name="EditWriterUserRegistrationEmail"
        component={EditWriterUserRegistrationEmail}
        options={{title: 'メールアドレス変更'}}
      />

      <RootStack.Screen
        name="EditWriterUserRegistrationPassword"
        component={EditWriterUserRegistrationPassword}
        options={{title: 'パスワード変更'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
