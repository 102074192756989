import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ArrowForwardIcon from '../icons/ArrowForwardIcon';

interface Props<T> {
  item: T;
  onSelectItem: (item: T) => void;
  buttonType?: boolean;
  extraData?: any;
  disable?: boolean;
  renderCustom?: (item: T) => React.ReactNode;
  renderTitle?: (item: T) => React.ReactNode;
  renderIcon?: (item: T) => React.ReactNode;
  titleStyleExtractor?: (item: T) => StyleProp<TextStyle>;
}

export default class DefeaultListItem<T> extends React.PureComponent<Props<T>> {
  public render(): React.ReactNode {
    const {
      item,
      buttonType,
      disable,
      renderCustom,
      renderTitle,
      renderIcon,
      titleStyleExtractor,
    } = this.props;
    const containerStyle = buttonType
      ? styles.containerButton
      : styles.containerNormal;
    const titleStyle = titleStyleExtractor
      ? titleStyleExtractor(item)
      : undefined;
    const titleButtonStyle = buttonType ? styles.titleButton : undefined;
    return (
      <TouchableOpacity
        activeOpacity={0.8}
        disabled={disable}
        onPress={this.handlePress}>
        {renderCustom ? (
          <View style={styles.containerCustom}>{renderCustom(item)}</View>
        ) : (
          <View style={[styles.container, containerStyle]}>
            <Text style={[styles.title, titleButtonStyle, titleStyle]}>
              {renderTitle ? renderTitle(item) : null}
            </Text>
            {!buttonType && (
              <View style={styles.icon}>
                {renderIcon ? renderIcon(item) : <ArrowForwardIcon />}
              </View>
            )}
          </View>
        )}
      </TouchableOpacity>
    );
  }

  private handlePress = () => {
    const {item, onSelectItem} = this.props;
    onSelectItem(item);
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: 'white',
    height: 50,
  } as ViewStyle,
  containerButton: {
    borderRadius: 3,
    borderWidth: 1,
    margin: 10,
    padding: 20,
  } as ViewStyle,
  containerCustom: {
    flex: 1,
  } as ViewStyle,
  containerNormal: {
    flexDirection: 'row',
    height: 50,
    justifyContent: 'space-between',
  } as ViewStyle,
  icon: {
    marginHorizontal: 10,
  } as ViewStyle,
  title: {
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
    marginHorizontal: 15,
  } as TextStyle,
  titleButton: {
    fontWeight: 'bold',
  } as TextStyle,
});
