import * as React from 'react';

import NavigationProp from '../../../../../navigators/NavigationProp';

import FormIdea, {FormValuesIdea} from './FormIdea';
import FormPlot, {FormValuesPlot} from './FormPlot';
import FormEpisode, {FormValuesEpisode} from './FormEpisode';

import Offer from '../../../../../../domain/entities/writers_gate/Offer';

export type FormValues = {writersGateOfferId: number} & (
  | FormValuesIdea
  | FormValuesPlot
  | FormValuesEpisode
);

interface Props {
  navigation: NavigationProp;
  offer: Offer;
  onSubmit: (formValues: FormValues) => void;
}

const Form: React.FC<Props> = props => {
  const {offer, onSubmit} = props;
  const onSubmitForm = React.useCallback(
    (formValues: FormValuesIdea | FormValuesPlot | FormValuesEpisode) => {
      if (formValues) {
        onSubmit({...formValues, writersGateOfferId: offer.id});
      }
    },
    [],
  );
  return (
    <>
      {offer.typeCode === 'idea' ? (
        <FormIdea onSubmit={onSubmitForm} />
      ) : offer.typeCode === 'plot' ? (
        <FormPlot onSubmit={onSubmitForm} />
      ) : offer.typeCode === 'episode' ? (
        <FormEpisode onSubmit={onSubmitForm} />
      ) : null}
    </>
  );
};

export default React.memo(Form);
