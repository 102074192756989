import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PopularTagButton from './PopularTagButton';

import PopularTag from '../../../domain/entities/PopularTag';

interface Props {
  style?: ViewStyle;
  popularTags: Array<PopularTag> | null;
  onSelectPopularTag: (popularTag: PopularTag) => void;
}

const PopularTagsBox: React.FunctionComponent<Props> = props => {
  const {style, popularTags, onSelectPopularTag} = props;
  if (!popularTags || popularTags.length === 0) {
    return null;
  }
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.title}>人気のキーワード</Text>
      <View style={styles.buttons}>
        {popularTags.map(popularTag => (
          <PopularTagButton
            key={`${popularTag.id}`}
            popularTag={popularTag}
            onSelectPopularTag={onSelectPopularTag}
          />
        ))}
      </View>
    </View>
  );
};

export default React.memo(PopularTagsBox);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: '#fafafa',
    paddingBottom: 8,
  } as ViewStyle,
  title: {
    fontSize: 11,
    fontWeight: 'bold',
    color: '#222222',
    marginBottom: 8,
  } as TextStyle,
  buttons: {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
  } as ViewStyle,
});
