import * as React from 'react';
import {
  ImageStyle,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import EditableLaterMessage from '../../shared/EditableLaterMessage';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import Input from '../../shared/forms/Input';
import TextArea from '../../shared/forms/TextArea';
import MultiSwitch from '../../shared/forms/MultiSwitch';
import LabelWithOption from '../../shared/forms/LabelWithOption';
import RadioButton from '../../shared/forms/RadioButton';
import TooltipModal from '../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../shared/KeyboardSpacer';
import AlertModal from '../../shared/modals/AlertModal';

import convertImageSource from '../../../helpers/convertImageSource';

import {colors, formStyles} from '../../../styles/variables';

import {Rating, Format} from '../../../../domain/entities/Story';
import Genre from '../../../../domain/entities/Genre';
import StoryForm from '../../../../domain/forms/StoryForm';

import storyIntroductionUri from '../../../../assets/images/tips/story_introduction.png';
import storyCatchPhraseUri from '../../../../assets/images/tips/story_catch_phrase.png';
import storyKeywordsUri from '../../../../assets/images/tips/story_keywords.png';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

type TooltipModalType =
  | 'introduction'
  | 'catch_phrase'
  | 'keyword'
  | 'rating'
  | 'format'
  | 'serialized'
  | 'allowed_video_sharing';

const TypeToTooltipInfo = {
  introduction: {
    title: 'イントロダクション',
    description:
      'ストーリーの詳細ページに表示される紹介文となります。読者が読みたくなるような紹介文を記入しましょう。',
    source: convertImageSource(storyIntroductionUri),
    imageStyle: {width: 279, height: 220},
  },
  catch_phrase: {
    title: 'キャッチコピー',
    description:
      '検索結果のページに表示されるキャッチコピーとなります。読者が読みたくなるようなキャッチコピーを記入しましょう。',
    source: convertImageSource(storyCatchPhraseUri),
    imageStyle: {width: 279, height: 160},
  },
  keyword: {
    title: 'キーワード',
    description:
      '読者がストーリーを探しやすくなります。\nそのストーリーの特徴となるキーワードを登録しましょう。',
    source: convertImageSource(storyKeywordsUri),
    imageStyle: {width: 279, height: 160},
  },
  rating: {
    title: 'レイティング',
    description:
      '内容に暴力的な表現や性的な表現が含まれる場合は設定してください。',
    imageStyle: {},
  },
  format: {
    title: '形式',
    description:
      '読者がストーリーを選択しやすくなります。\nストーリーの長さを設定してください。',
    imageStyle: {},
  },
  serialized: {
    title: '掲載状況',
    description:
      'ストーリーが完結した場合は設定してください。\n1話読切のストーリーは自動的に完結となります。',
    imageStyle: {},
  },
  allowed_video_sharing: {
    title: '第三者による動画共有サービス設定',
    description:
      'このストーリーのキャプチャー画像や動画を、第三者が動画共有サービスへ投稿（配信・収益化を含む）することに対しての許可を設定することができます。\n公開範囲で「一般公開」が選択されたエピソードが共有対象となります。',
    imageStyle: {},
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

export type Fields = 'introduction' | 'catchPhrase';

interface Props {
  storyForm: StoryForm;
  submitButtonText?: string;
  genres: Genre[];
  errorMessages?: {
    title?: Array<string>;
    introduction?: Array<string>;
  };
  disableAllowedVideoSharing?: boolean;
  episodesCount: number;
  requiredFields?: Array<Fields> | null;
  onForwardToMainGenre: (
    mainGenreId: number | null,
    subGenreIds: number[] | null,
  ) => void;
  onForwardToSubGenres: (
    mainGenreId: number | null,
    subGenreIds: number[] | null,
  ) => void;
  onChangeRating: (rating: Rating) => void;
  onChangeFormat: (format: Format) => void;
  onToggleSerialized: (checked: boolean) => void;
  onToggleAllowedVideoSharing: (checked: boolean) => void;
  onForwardToKeywords: (keywordNames: string[] | null) => void;
  onSubmit: (params: {
    title: string;
    introduction: string;
    catchPhrase: string;
  }) => void;
}

interface State {
  title: string;
  introduction: string;
  catchPhrase: string;
  tooltipModalType: TooltipModalType | null;
  alertMessage?: string;
}

export default class Form extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    const {storyForm} = props;
    const {title, introduction, catchPhrase} = storyForm;
    this.state = {
      title: title || '',
      introduction: introduction || '',
      catchPhrase: catchPhrase || '',
      tooltipModalType: null,
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {
      storyForm,
      submitButtonText,
      errorMessages,
      disableAllowedVideoSharing,
      requiredFields,
    } = this.props;
    const {title, introduction, catchPhrase, tooltipModalType, alertMessage} =
      this.state;
    const modalInfo = tooltipModalType
      ? TypeToTooltipInfo[tooltipModalType]
      : null;
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{paddingBottom: 130}}>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'ストーリータイトル(最大50文字)'}
              length={title.length}
              maxLength={50}
              requiredOrOptional={'required'}
              entered={title.length > 0 && title.length <= 50}
              errorMessages={errorMessages?.title}
            />
            <Input
              style={[
                {marginBottom: 3, marginTop: 8},
                title.length === 0
                  ? requiredInputStyle
                  : this.validTitle()
                  ? undefined
                  : styles.inputError,
              ]}
              value={title}
              placeholder={'ストーリータイトルを入力'}
              onChangeText={this.handleChangeTitle}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'メインジャンル'}
              requiredOrOptional={'required'}
              entered={!!storyForm.mainGenreId}
            />
            <RadioButton
              style={[
                {marginBottom: 3, marginTop: 8},
                storyForm.mainGenreId ? undefined : requiredInputStyle,
              ]}
              name={this.getMainGenreName()}
              placeholder={'メインジャンルを選択'}
              onPress={this.handleForwardToMainGenre}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'サブジャンル（2つまで）'}
              requiredOrOptional={'optional'}
              entered={(storyForm.subGenreIds?.length || 0) > 0}
            />
            <RadioButton
              name={this.getSubGenreNames()}
              placeholder={'サブジャンルを選択'}
              onPress={this.handleForwardToSubGenres}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'イントロダクション（最大200文字）'}
              length={introduction.length}
              maxLength={200}
              requiredOrOptional={
                this.requiredIntroduction() ? 'required' : 'optional'
              }
              entered={introduction.length > 0}
              errorMessages={errorMessages?.introduction}
              onPressQuestion={this.handlePressQuestionIntroduction}
            />
            <TextArea
              style={[
                styles.textArea,
                this.validIntroduction() ? undefined : styles.inputError,
                !this.requiredIntroduction() || introduction
                  ? undefined
                  : requiredInputStyle,
              ]}
              value={introduction}
              onChangeText={this.handleChangeIntroduction}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'キャッチコピー（最大25文字）'}
              length={catchPhrase.length}
              maxLength={25}
              requiredOrOptional={
                this.requiredCatchPhrase() ? 'required' : 'optional'
              }
              entered={catchPhrase.length > 0}
              onPressQuestion={this.handlePressQuestionCatchPhrase}
            />
            <Input
              value={catchPhrase}
              style={[
                this.validCatchPhrase() ? null : styles.inputError,
                !this.requiredCatchPhrase() || catchPhrase
                  ? undefined
                  : requiredInputStyle,
              ]}
              placeholder={'キャッチコピーを入力'}
              onChangeText={this.handleChangeCatchPhrase}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'キーワード'}
              requiredOrOptional={'optional'}
              entered={(storyForm.keywordNames?.length || 0) > 0}
              onPressQuestion={this.handlePressQuestionKeyword}
            />
            <RadioButton
              name={this.getKeywordNames()}
              onPress={this.handleForwardToKeywords}
            />
          </View>

          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'レイティング'}
              onPressQuestion={this.handlePressQuestionRating}
            />
            <MultiSwitch
              items={RATING_ITEMS}
              value={storyForm.rating || 'rating_none'}
              onSelectItem={this.handleChangeRating}
            />
          </View>

          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'形式'}
              onPressQuestion={this.handlePressQuestionFormat}
            />
            <MultiSwitch
              items={FORMAT_ITEMS}
              value={storyForm.format || 'format_long'}
              onSelectItem={this.handleChangeFormat}
            />
          </View>

          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'掲載状況'}
              onPressQuestion={this.handlePressQuestionSerialized}
            />
            <MultiSwitch
              items={SERIALIZED_ITEMS}
              value={
                storyForm.serialized === null
                  ? 'serializing'
                  : storyForm.serialized
                  ? 'serializing'
                  : 'completed'
              }
              disabled={storyForm.format === 'format_one_shot'}
              onSelectItem={this.handleSelectItemSerialized}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'第三者による動画共有サービス設定'}
              onPressQuestion={this.handlePressQuestionAllowedVideoSharing}
            />
            <MultiSwitch
              items={ALLOWED_VIDEO_SHARING_ITEMS}
              value={
                storyForm.allowedVideoSharing === null
                  ? 'false'
                  : storyForm.allowedVideoSharing
                  ? 'true'
                  : 'false'
              }
              disabled={disableAllowedVideoSharing}
              onSelectItem={this.handleSelectItemAllowedVideoSharing}
            />
          </View>
          {modalInfo ? (
            <TooltipModal
              visible={true}
              title={modalInfo.title}
              description={modalInfo.description}
              imageStyle={modalInfo.imageStyle}
              source={modalInfo.source}
              onCloseModal={this.handleCloseModal}></TooltipModal>
          ) : null}
          <KeyboardSpacer />
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }}>
          <View style={styles.button}>
            <EditableLaterMessage
              beforeText={storyForm.id ? '' : 'まだ公開されません。'}
            />
            <PrimaryButton
              disabled={this.disabledButton()}
              onPress={this.handleSubmit}>
              {submitButtonText || '次へ'}
            </PrimaryButton>
          </View>
        </View>
        {alertMessage && (
          <AlertModal
            visible={true}
            onCloseModal={() => {
              this.setState({alertMessage: undefined});
            }}>
            {alertMessage}
          </AlertModal>
        )}
      </View>
    );
  }

  private handleForwardToMainGenre = () => {
    const {storyForm, onForwardToMainGenre} = this.props;
    onForwardToMainGenre(storyForm.mainGenreId, storyForm.subGenreIds);
  };

  private handleForwardToSubGenres = () => {
    const {storyForm, onForwardToSubGenres} = this.props;
    onForwardToSubGenres(storyForm.mainGenreId, storyForm.subGenreIds);
  };

  private handleChangeTitle = (title: string) => {
    if (this.mounted) {
      this.setState({title});
    }
  };

  private handleChangeIntroduction = (introduction: string) => {
    if (this.mounted) {
      this.setState({introduction});
    }
  };

  private getMainGenreName = (): string => {
    const {genres, storyForm} = this.props;
    const mainGenreId = storyForm.mainGenreId;
    if (!mainGenreId) {
      return '';
    }
    const foundGenre = genres.find(genre => {
      return genre.id === mainGenreId;
    });
    if (!foundGenre) {
      return '';
    }
    return foundGenre.name;
  };

  private getSubGenreNames = (): string => {
    const {genres, storyForm} = this.props;
    const subGenreIds = storyForm.subGenreIds;
    if (!subGenreIds) {
      return '';
    }
    const foundGenres = genres.filter(genre => {
      return subGenreIds.includes(genre.id);
    });
    return foundGenres.map(genre => genre.name).join('、');
  };

  private requiredIntroduction = () => {
    const {requiredFields} = this.props;
    return !!requiredFields && requiredFields.includes('introduction');
  };

  private requiredCatchPhrase = () => {
    const {requiredFields} = this.props;
    return !!requiredFields && requiredFields.includes('catchPhrase');
  };

  private validTitle = () => {
    const {title} = this.state;
    return title.length <= 50;
  };

  private validIntroduction = () => {
    const {introduction} = this.state;
    return (
      introduction.length <= 200 &&
      (this.requiredIntroduction() ? introduction.length > 0 : true)
    );
  };

  private validCatchPhrase = () => {
    const {catchPhrase} = this.state;
    return (
      catchPhrase.length <= 25 &&
      (this.requiredCatchPhrase() ? catchPhrase.length > 0 : true)
    );
  };

  private handleChangeCatchPhrase = (catchPhrase: string) => {
    this.setState({catchPhrase});
  };

  private handleForwardToKeywords = () => {
    const {storyForm, onForwardToKeywords} = this.props;
    onForwardToKeywords(storyForm.keywordNames);
  };

  private handleSelectItemSerialized = (item: {
    label: string;
    value: string;
  }) => {
    const {onToggleSerialized} = this.props;
    onToggleSerialized(item.value === 'serializing');
  };

  private handleSelectItemAllowedVideoSharing = (item: {
    label: string;
    value: string;
  }) => {
    const {onToggleAllowedVideoSharing} = this.props;
    const {storyForm} = this.props;
    if (!storyForm.allowedVideoSharing && item.value === 'true') {
      this.setState({
        alertMessage:
          '一度共有を許可したストーリーは、後から変更することはできなくなります。\n公開範囲で「一般公開」が選択されたエピソードが共有対象となります。',
      });
    }
    onToggleAllowedVideoSharing(item.value === 'true');
  };

  private handleChangeRating = (item: {label: string; value: string}) => {
    const {onChangeRating} = this.props;
    const value = item.value;
    if (
      value === 'rating_none' ||
      value === 'rating_r15' ||
      value === 'rating_r18'
    ) {
      onChangeRating(value);
    }
  };

  private handleChangeFormat = (item: {label: string; value: string}) => {
    const {episodesCount, onChangeFormat, onToggleSerialized} = this.props;
    const value = item.value;
    if (episodesCount >= 10 && value !== 'format_long') {
      this.setState({alertMessage: '既に10話以上登録されております'});
      return onChangeFormat('format_long');
    } else if (episodesCount >= 2 && value === 'format_one_shot') {
      this.setState({alertMessage: '既に2話以上登録されております'});
      return onChangeFormat('format_short');
    }
    if (value === 'format_one_shot') {
      onToggleSerialized(false);
    }
    if (
      value === 'format_one_shot' ||
      value === 'format_short' ||
      value === 'format_long'
    ) {
      onChangeFormat(value);
    }
  };

  private handleSubmit = () => {
    const {onSubmit} = this.props;
    const {title, introduction, catchPhrase} = this.state;
    onSubmit({title, introduction, catchPhrase});
  };

  private getKeywordNames(): string {
    const {storyForm} = this.props;
    const {keywordNames} = storyForm;
    if (!keywordNames) {
      return '';
    }
    return keywordNames.join('、');
  }

  private disabledButton = (): boolean => {
    const {storyForm} = this.props;
    const {title} = this.state;
    if (!storyForm.mainGenreId) {
      return true;
    }
    if (!this.validTitle() || !title) {
      return true;
    }
    if (!this.validIntroduction()) {
      return true;
    }
    if (!this.validCatchPhrase()) {
      return true;
    }
    return false;
  };

  private handlePressQuestionIntroduction = () => {
    this.setState({tooltipModalType: 'introduction'});
  };

  private handlePressQuestionCatchPhrase = () => {
    this.setState({tooltipModalType: 'catch_phrase'});
  };

  private handlePressQuestionKeyword = () => {
    this.setState({tooltipModalType: 'keyword'});
  };

  private handlePressQuestionRating = () => {
    this.setState({tooltipModalType: 'rating'});
  };

  private handlePressQuestionFormat = () => {
    this.setState({tooltipModalType: 'format'});
  };

  private handlePressQuestionSerialized = () => {
    this.setState({tooltipModalType: 'serialized'});
  };

  private handlePressQuestionAllowedVideoSharing = () => {
    this.setState({tooltipModalType: 'allowed_video_sharing'});
  };

  private handleCloseModal = () => {
    this.setState({tooltipModalType: null});
  };
}

const RATING_ITEMS = [
  {label: 'なし', value: 'rating_none'},
  {label: 'R-15', value: 'rating_r15'},
  {label: 'R-18', value: 'rating_r18'},
];

const FORMAT_ITEMS: Array<{
  label: string;
  value: 'format_long' | 'format_short' | 'format_one_shot';
  optionLabel?: string;
}> = [
  {
    label: '長編',
    value: 'format_long',
    optionLabel: '（10話以上）',
  },
  {
    label: '短編',
    value: 'format_short',
    optionLabel: '（9話以下）',
  },
  {label: '1話読切', value: 'format_one_shot'},
];

const SERIALIZED_ITEMS = [
  {label: '連載中', value: 'serializing'},
  {label: '完結', value: 'completed'},
];

const ALLOWED_VIDEO_SHARING_ITEMS = [
  {label: '投稿NG', value: 'false'},
  {label: '投稿OK', value: 'true'},
];

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
});
