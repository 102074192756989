import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import {colors} from '../../../../styles/variables';

interface Props {
  onCreateKeyword: (text: string, callback?: () => void) => void;
}

interface State {
  value: string;
}

export default class Form extends React.Component<Props, State> {
  private textInput: React.RefObject<TextInput>;

  constructor(props: Props) {
    super(props);
    this.state = {
      value: '',
    };
    this.textInput = React.createRef<TextInput>();
  }

  public render(): React.ReactNode {
    const {value} = this.state;
    return (
      <View style={styles.container}>
        <TextInput
          ref={this.textInput}
          style={styles.input}
          value={value}
          returnKeyType={'done'}
          placeholder={'キーワードを入力'}
          placeholderTextColor={colors.lightGray}
          autoFocus={true}
          onChangeText={this.handleChangeText}
          onSubmitEditing={this.handlePress}
          onLayout={() => {
            this.textInput.current?.focus();
          }}
        />
        <TouchableOpacity onPress={this.handlePress}>
          <Text style={styles.button}>追加</Text>
        </TouchableOpacity>
      </View>
    );
  }

  private handleChangeText = (value: string) => {
    this.setState({
      value,
    });
  };

  private handlePress = () => {
    const {onCreateKeyword} = this.props;
    const {value} = this.state;
    // eslint-disable-next-line no-irregular-whitespace
    const text = value
      .trim()
      .replace(/[\s,.*+?^=!:${}()|[\]/\\・／「」【】（）『』。、]+/g, '');
    if (text.length === 0) {
      return;
    }
    onCreateKeyword(text, () => {
      this.setState({value: ''}, () => {
        if (!this.textInput.current?.isFocused()) {
          this.textInput.current?.focus();
        }
      });
    });
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    height: 44,
    justifyContent: 'space-between',
  } as ViewStyle,
  input: {
    backgroundColor: 'white',
    color: 'black',
    flex: 1,
    fontSize: 15,
    padding: 15,
  } as TextStyle,
  button: {
    color: colors.orange,
    fontSize: 15,
    fontWeight: 'bold',
    margin: 15,
  } as ViewStyle,
});
