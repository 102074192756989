import Entity from '../Entity';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class EpisodeVideoExportJobExecution implements Entity {
  constructor(
    public id: number,
    public episode: {
      id: number;
      title: string;
      version: number;
      story: {
        id: number;
        title: string;
        hasImage: boolean;
        originalImageUrl: string;
        hasCoverImage: boolean;
        originalCoverImageUrl: string;
      };
    },
    public episodeVideo: {
      id: number;
      episodeVersion: number;
      createdAt: Date;
      updatedAt: Date;
      videoInlineUrl: string;
      videoDownloadUrl: string;
    } | null,
    public videoType: 'vertical_full' | 'horizontal_full',
    public videoAutoPlaySpeed: '1.0' | '1.5' | '2.0',
    public title: string | null,
    public hasImage: boolean,
    public originalImageUrl: string,
    public videoDownloadUrl: string | null,
    public imageDownloadUrl: string | null,
    public status: 'pending' | 'running' | 'succeeded' | 'failed' | 'cancelled',
    public startedAt: Date | null,
    public finishedAt: Date | null,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getSmallImageUrl(): string {
    return this.getImageUrl(this.converDoubleSize(this.getSmallImageSize()));
  }

  public getLargeImageUrl(): string {
    return this.getImageUrl(this.converDoubleSize(this.getLargeImageSize()));
  }

  private converDoubleSize = (size: {width: number; height: number}) => {
    return {
      width: size.width * 2,
      height: size.height * 2,
    };
  };

  public getSmallImageSize = () => {
    return this.videoType === 'vertical_full'
      ? {width: 70, height: 125}
      : {width: 120, height: 68};
  };

  public getLargeImageSize = () => {
    return this.videoType === 'vertical_full'
      ? {width: 234, height: 416}
      : {width: 343, height: 193};
  };
}
