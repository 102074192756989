import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ActorCharacterFaceMapper, {
  ActorCharacterFaceData,
} from '../../entities/mappers/ActorCharacterFaceMapper';

import ActorCharacterFace from '../../../domain/entities/ActorCharacterFace';
import ActorCharacterFacesRepository from '../../../domain/repositories/writer/ActorCharacterFacesRepository';

export default class NetActorCharacterFacesRepository
  extends NetResourcesRepository<ActorCharacterFace, ActorCharacterFaceData>
  implements ActorCharacterFacesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/actor_character_faces',
        new ActorCharacterFaceMapper(),
      ),
    );
  }
}
