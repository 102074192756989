import * as React from 'react';
import {useLinkTo} from '@react-navigation/native';

import Form, {FormValues} from './partials/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewProjectTopicRouteProp} from '../../../navigators/RouteProps';

import {Params as ApplicationModalUpdateParams} from '../../../actions/application_modal/update';

import ProjectTopic from '../../../../domain/entities/ProjectTopic';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewProjectTopicRouteProp;
}

export interface DispatchProps {
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, route, updateApplicationModal} = props;
  const {projectId} = route.params;
  const onSubmit = React.useCallback((values: FormValues) => {
    TapNovelTypedRestApi.post<ProjectTopic>('/api/writer/project_topics', {
      projectTopic: values,
      projectId,
    }).then(result => {
      navigation.navigate('Project', {id: projectId});
      updateApplicationModal({
        options: {message: 'プロジェクトにトピックを作成しました。'},
      });
    });
  }, []);
  return (
    <Layout title={'トピック作成'} navigation={navigation} back={true}>
      <Form onSubmit={onSubmit} />
    </Layout>
  );
};

export default React.memo(New);
