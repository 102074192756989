import * as React from 'react';

import BaseIndex from '../../shared/generics/background_show_scene_command/backgrounds/Index';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormBackgroundsRouteProp} from '../../../navigators/RouteProps';

import {Params as BackgroundTimeZoneIndexParams} from '../../../actions/background_time_zones/index';
import {Params as BackgroundWeatherIndexParams} from '../../../actions/background_weathers/index';
import {Params as BackgroundIndexParams} from '../../../actions/backgrounds/index';
import {Params as BackgroundUsageHistoryUpdateParams} from '../../../actions/background_usage_histories/update';

import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import Background from '../../../../domain/entities/Background';
import BackgroundTimeZone from '../../../../domain/entities/BackgroundTimeZone';
import BackgroundWeather from '../../../../domain/entities/BackgroundWeather';
import BackgroundUsageHistory from '../../../../domain/entities/BackgroundUsageHistory';
import BaseBackground from '../../../../domain/entities/BaseBackground';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  baseBackgroundId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormBackgroundsRouteProp;
  coverImageForm: CoverImageForm | null;
  backgroundsParams: BackgroundIndexParams;
  backgroundTimeZonesParams: BackgroundTimeZoneIndexParams;
  backgroundWeathersParams: BackgroundWeatherIndexParams;
  baseBackground: BaseBackground | null;
  backgrounds: Background[] | null;
  backgroundTimeZones: BackgroundTimeZone[] | null;
  backgroundWeathers: BackgroundWeather[] | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexBackgrounds: (
    params: BackgroundIndexParams,
  ) => Promise<PaginatedResult<Background>>;
  indexBackgroundTimeZones: (
    params: BackgroundTimeZoneIndexParams,
  ) => Promise<PaginatedResult<BackgroundTimeZone>>;
  indexBackgroundWeathers: (
    params: BackgroundWeatherIndexParams,
  ) => Promise<PaginatedResult<BackgroundWeather>>;
  showBaseBackground: (id: number) => Promise<BaseBackground>;
  updateBackgroundUsageHistory: (
    id: number,
    params: BackgroundUsageHistoryUpdateParams,
  ) => Promise<BackgroundUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (background: Background) => {
    const {navigation, route, updateBackgroundUsageHistory} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;
    if (!background) {
      return;
    }
    updateBackgroundUsageHistory(background.id, {storyId});
    const uri = background.getImageUrl({width: 2480, height: 2015});
    navigation.navigate('CoverImageFormImageCrop', {
      storyId,
      aspectRatio,
      imageKey,
      uri,
    });
  };
}
