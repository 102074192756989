import * as React from 'react';
import {FlatList, ListRenderItemInfo, Platform} from 'react-native';
import {shallowEqual} from 'recompose';

import ManualListItem from './ManualListItem';

import shouldUpdateEntityList from '../../shared/enhanced/shouldUpdateEntityList';
import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import Manual from '../../../../domain/entities/writer/Manual';

interface Item {
  manual: Manual;
  collapsed: boolean;
}

interface Props {
  manuals: Manual[];
  manualIdToCollapsed: {[key: string]: boolean};
  onPressManual: (manual: Manual) => void;
}

export default class ManualList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (
      !shallowEqual(
        this.props.manualIdToCollapsed,
        nextProps.manualIdToCollapsed,
      )
    ) {
      return true;
    }
    return shouldUpdateEntityList(
      {entities: this.props.manuals},
      {entities: nextProps.manuals},
    );
  }

  public render(): React.ReactNode {
    return (
      <FlatList
        scrollEnabled={Platform.select({web: true, default: false})}
        data={this.generateDate()}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
        ListFooterComponent={ListItemNormalSeparator}
      />
    );
  }

  private keyExtractor = (item: Item, index: number): string => {
    return `${item.manual.id}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<Item>,
  ): React.ReactElement<any> | null => {
    const {manualIdToCollapsed, onPressManual} = this.props;
    const {item} = info;
    return (
      <ManualListItem
        manual={item.manual}
        collapsed={
          manualIdToCollapsed[item.manual.id] === undefined
            ? true
            : manualIdToCollapsed[item.manual.id]
        }
        onPressManual={onPressManual}
      />
    );
  };

  private generateDate = (): Item[] => {
    const {manuals, manualIdToCollapsed} = this.props;
    return manuals.map(manual => {
      return {
        collapsed:
          manualIdToCollapsed[manual.id] === undefined
            ? true
            : manualIdToCollapsed[manual.id],
        manual,
      };
    });
  };
}
