import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import dateformat from 'dateformat';

import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import Offer from '../../../../../domain/entities/writers_gate/Offer';

interface Props {
  offer: Offer;
  wrriterUserId: number;
  onForceUpdate: () => void;
}

const OfferListItem: React.FC<Props> = props => {
  const {offer, wrriterUserId, onForceUpdate} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(`/writers_gate/offers/${offer.id}`);
  }, []);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.left}>
        <Text style={styles.title}>{offer.title}</Text>
        <Text style={styles.offerType}>提出物：{offer.typeName}</Text>
        <Text style={styles.deadline}>
          {dateformat(offer.deadlineAt, 'yyyy.mm.dd')} 締切
        </Text>
      </View>
      <ArrowForwardIcon />
    </TouchableOpacity>
  );
};

export default React.memo(OfferListItem);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    paddingHorizontal: 16,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 92,
  } as ViewStyle,
  left: {
    flex: 1,
    height: '100%',
    paddingTop: 20,
    paddingBottom: 15,
    justifyContent: 'space-between',
    alignItems: 'stretch',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  offerType: {
    color: '#666666',
    fontSize: 10,
  } as TextStyle,
  deadline: {
    color: '#666666',
    fontSize: 10,
  } as TextStyle,
});
