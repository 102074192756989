import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import SearchForm from './SearchForm';
import {SortValue} from './types';
interface Props {
  value?: string;
  sort: SortValue;
}

const SearchFormSection: React.FC<Props> = props => {
  const {value, sort} = props;
  return (
    <View style={styles.container}>
      <SearchForm
        value={value}
        sort={sort}
        textInputStyle={{borderWidth: 1, borderColor: '#d5d5d5'}}
        placeholder={'検索'}
      />
    </View>
  );
};

export default React.memo(SearchFormSection);

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
    marginBottom: 16,
    marginHorizontal: 16,
  } as ViewStyle,
});
