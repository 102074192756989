import * as React from 'react';
import {
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import SvgUriAdapter from '../svg/SvgUriAdapter';

import CloseIcon from '../icons/CloseIcon';

type NumberProp = string | number;

interface Props {
  style?: StyleProp<ViewStyle>;
  uri: string;
  title: string;
  description: string;
  width?: NumberProp;
  height?: NumberProp;
  onRequestClose?: () => void;
}

const Base: React.FunctionComponent<Props> = props => {
  const {style, uri, title, description, width, height, onRequestClose} = props;
  return (
    <View style={[styles.container, style]}>
      <View style={styles.header}>
        <Pressable onPress={onRequestClose}>
          <View style={styles.closeButton}>
            <CloseIcon color={'#ffffff'} size={20} />
          </View>
        </Pressable>
      </View>
      <View style={styles.box}>
        <Text style={styles.text}>{title}</Text>
        <View style={styles.image}>
          <SvgUriAdapter uri={uri} width={width} height={height} />
        </View>
        <Text style={styles.text}>{description}</Text>
      </View>
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(Base, propsAreEqual);

const styles = StyleSheet.create({
  container: {} as ViewStyle,
  box: {
    backgroundColor: 'white',
    paddingVertical: 32,
    paddingHorizontal: 20,
    borderRadius: 4,
  } as ViewStyle,
  image: {
    marginTop: 20,
    marginBottom: 32,
  } as ViewStyle,
  text: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
  header: {
    alignItems: 'flex-end',
  } as ViewStyle,
  closeButton: {
    width: 20,
    height: 20,
    marginBottom: 16,
  } as ViewStyle,
});
