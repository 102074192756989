import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../shared/buttons/PrimaryButton';

import AlertModal from '../../shared/modals/AlertModal';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import Project from '../../../../domain/entities/Project';
import ProjectUserStatus from '../../../../domain/entities/ProjectUserStatus';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  project: Project;
  userStatus: ProjectUserStatus;
  onForceUpdate: () => void;
}

const ActionButton: React.FC<Props> = props => {
  const {project, userStatus, onForceUpdate} = props;
  const code = generateCode(project, userStatus);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onPress = React.useCallback(() => {
    switch (code) {
      case 'request': {
        TapNovelTypedRestApi.post(
          '/api/writer/user/project_member_join_requests',
          {projectId: project.id},
        )
          .then(() => {
            onForceUpdate();
          })
          .catch(e => {
            setAlertMessage(formatErrorMessages({}, e));
          });
        break;
      }
      case 'join': {
        TapNovelTypedRestApi.post('/api/writer/user/project_members', {
          projectId: project.id,
        })
          .then(() => {
            onForceUpdate();
          })
          .catch(e => {
            setAlertMessage(formatErrorMessages({}, e));
          });
        break;
      }
      case 'pending': {
        break;
      }
      case 'rejected': {
        break;
      }
      case 'joined': {
        break;
      }
    }
  }, [code]);
  const onCloseModal = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const disabled = ['pending', 'rejected', 'joined', 'dismissed'].includes(
    code,
  );
  return (
    <View style={styles.container}>
      <PrimaryButton disabled={disabled} onPress={onPress}>
        {codeToText[code]}
      </PrimaryButton>
      {alertMessage && (
        <AlertModal visible={!!alertMessage} onCloseModal={onCloseModal}>
          {alertMessage}
        </AlertModal>
      )}
    </View>
  );
};

export default React.memo(ActionButton);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 8,
    marginBottom: 40,
  } as ViewStyle,
});

type Code =
  | 'request'
  | 'join'
  | 'pending'
  | 'rejected'
  | 'joined'
  | 'dismissed';

const generateCode = (
  project: Project,
  userStatus: ProjectUserStatus,
): Code => {
  switch (userStatus.joinRequestStatus) {
    case 'approved': {
      if (userStatus.role === 'unmember') {
        return 'dismissed';
      } else {
        return 'joined';
      }
    }
    case 'pending':
      return 'pending';
    case 'rejected':
      return 'rejected';
    default: {
      if (userStatus.role !== 'unmember') {
        return 'joined';
      }
      if (project.participationMethod === 'approval_system') {
        return 'request';
      } else {
        return 'join';
      }
    }
  }
};

const codeToText: {[key in Code]: string} = {
  request: 'このプロジェクトに参加申請を送る',
  join: 'このプロジェクトに参加する',
  pending: '承認待ち',
  rejected: '非承認',
  joined: '参加中',
  dismissed: '追放されました',
};
