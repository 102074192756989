import * as React from 'react';
import {ImageStyle, StyleSheet, View} from 'react-native';

import NoFlickerImage from '../../../../../shared/no_flicker_image/NoFlickerImage';

import {markImageUrl} from '../../../../../../helpers/images';

import ActorFacePart from '../../../../../../../domain/entities/character_maker/ActorFacePart';
import ActorEye from '../../../../../../../domain/entities/character_maker/ActorEye';
import ActorHairStyle from '../../../../../../../domain/entities/character_maker/ActorHairStyle';
import ActorCostume from '../../../../../../../domain/entities/character_maker/ActorCostume';
import Mark from '../../../../../../../domain/entities/Mark';

const resizeMode = 'contain';
const MARK_IMAGE_SIZE = 'large';

interface Props {
  actorFacePart: ActorFacePart | null | undefined;
  actorEye: ActorEye | null | undefined;
  actorHairStyle: ActorHairStyle | null | undefined;
  actorCostume: ActorCostume | null | undefined;
  mark?: Mark | null;
  inverted?: boolean;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {
    actorFacePart,
    actorEye,
    actorHairStyle,
    actorCostume,
    mark,
    inverted,
  } = props;
  return (
    <View style={{height: characterHeight, width: characterWidth}}>
      {mark && mark.id !== 0 ? (
        <NoFlickerImage
          style={styles.mark}
          resizeMode={resizeMode}
          source={{uri: markImageUrl(mark, MARK_IMAGE_SIZE)}}
        />
      ) : null}
      {actorFacePart && actorEye && actorHairStyle && actorCostume && (
        <View style={inverted ? styles.inverted : null}>
          <NoFlickerImage
            style={styles.image}
            resizeMode={resizeMode}
            source={{
              uri: actorFacePart.getActorOneThirdImageUrl(),
            }}
          />
          <NoFlickerImage
            style={styles.image}
            resizeMode={resizeMode}
            source={{
              uri: actorHairStyle.getOneThirdImageUrl(),
            }}
          />
          <NoFlickerImage
            style={styles.image}
            resizeMode={resizeMode}
            source={{
              uri: actorCostume.getOneThirdImageUrl(),
            }}
          />
          <NoFlickerImage
            style={styles.image}
            resizeMode={resizeMode}
            source={{
              uri: actorFacePart.getOneThirdImageUrl(),
            }}
          />
          <NoFlickerImage
            style={styles.image}
            resizeMode={resizeMode}
            source={{
              uri: actorEye.getOneThirdImageUrl(),
            }}
          />
        </View>
      )}
    </View>
  );
};

export default React.memo(CharacterView);

const markWidth = 25;
const markHeight = 25;
const markAspectRatio = markWidth / markHeight;

const characterWidth = 200;
const characterHeight = 200;
const characterAspectRatio = characterWidth / characterHeight;

const styles = StyleSheet.create({
  image: {
    aspectRatio: characterAspectRatio,
    height: characterHeight,
    width: characterWidth,
    position: 'absolute',
  } as ImageStyle,
  imageWrapper: {
    alignItems: 'center',
    height: 200,
    overflow: 'hidden',
    width: 200,
  } as ImageStyle,
  mark: {
    aspectRatio: markAspectRatio,
    height: markHeight,
    left: 50,
    position: 'absolute',
    width: markWidth,
    zIndex: 10,
  } as ImageStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ImageStyle,
});
