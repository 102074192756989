import * as React from 'react';
import {StyleProp, StyleSheet, TouchableOpacity, ViewStyle} from 'react-native';

import ShoppingListIcon from './icons/ShoppingListIcon';

import {enabledShop} from '../../helpers/features';

interface Props {
  style?: StyleProp<ViewStyle>;
  onPress?: (() => void) | undefined;
}

const ResourceShoppingListLink: React.FunctionComponent<Props> = props => {
  const {style, onPress} = props;
  if (!enabledShop) {
    return null;
  }
  return (
    <TouchableOpacity style={[styles.container, style]} onPress={onPress}>
      <ShoppingListIcon />
    </TouchableOpacity>
  );
};

export default React.memo(ResourceShoppingListLink);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#e7004c',
    width: 32,
    height: 32,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
