import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Episode from '../../../domain/entities/Episode';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetEpisodesRepository from '../../../data/repositories/writer/NetEpisodesRepository';

const types = {
  failure: Types.IndexEpisodesFailure,
  request: Types.IndexEpisodesRequest,
  success: Types.IndexEpisodesSuccess,
};

export interface Params extends BaseParams {
  storyId: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Episode>> {
  return indexGenerics(new NetEpisodesRepository(), types, params);
}
