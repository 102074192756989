import Entity from './Entity';

import Genre from './Genre';
import Keyword from './Keyword';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export type Rating = 'rating_none' | 'rating_r15' | 'rating_r18';

export type Format =
  | 'format_one_shot'
  | 'format_short'
  | 'format_long'
  | 'format_series'
  | 'format_omnibus';

export default class Story implements Entity {
  constructor(
    public id: number,
    public writerId: number,
    public title: string,
    public url: string,
    public catchPhrase: string,
    public summary: string,
    public introduction: string,
    public coverIllustrator: string | null,
    public published: boolean,
    public violated: boolean,
    public regulatoryViolationStatus:
      | 'requesting'
      | 'applying'
      | 'rejected'
      | null,
    public serialized: boolean,
    public allowedVideoSharing: boolean,
    public rating: Rating | null,
    public format: Format | null,
    public hasImage: boolean,
    public originalImageUrl: string,
    public hasCoverImage: boolean,
    public originalCoverImageUrl: string,
    public ogpImageDefaultUrl: string,
    public ogpImageStoryPublicationUrl: string,
    public ogpImageEpisodePublicationUrl: string,
    public ogpImageEpisodeReservedPublicationUrl: string,
    public entryContestId: number | null,
    public updatedAt: Date,
    public createdAt: Date,
    public genres: Genre[] = [],
    public keywords: Keyword[] = [],
    public estimatedTotalTapCount: number | null = null,
    public lastEpisodePublishedTitle: string | null = null,
  ) {}

  public getMainGenreName(): string {
    const genre = this.getMainGenre();
    if (genre) {
      return genre.name;
    } else {
      return '';
    }
  }

  public getMainGenreId(): number | null {
    const genre = this.getMainGenre();
    if (genre) {
      return genre.id;
    } else {
      return null;
    }
  }

  public getMainGenre(): Genre | null {
    return this.genres[0];
  }

  public getSubGenreIds(): number[] {
    return this.getSubGenres().map(genre => genre.id);
  }

  public getSubGenres(): Genre[] {
    return this.genres.slice(1, this.genres.length);
  }

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public enabledPublication(): boolean {
    return this.published;
  }
}
