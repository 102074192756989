import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ExportEpisodeRouteProp} from '../../../navigators/RouteProps';

export interface Params {
  episodeId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ExportEpisodeRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {episodeId} = route.params;
  return (
    <Layout title={'エクスポート形式'} navigation={navigation} back={true}>
      <Form episodeId={episodeId} />
    </Layout>
  );
};

export default React.memo(Show);
