import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import ImageListItem, {Entity} from './ImageListItem';

import DragScrollView from '../../../../../shared/drag_scroll_view';

interface Props<E extends Entity> {
  entities: E[] | null;
  selectedEntityId: number;
  visible: boolean;
  onSelectEntity: (entity: E) => void;
}

export default class ImageList<E extends Entity> extends React.PureComponent<
  Props<E>
> {
  public render(): React.ReactNode {
    const {entities, selectedEntityId, visible, onSelectEntity} = this.props;
    return (
      <DragScrollView
        style={visible ? null : styles.hide}
        horizontal={true}
        bounces={false}
        showsHorizontalScrollIndicator={false}>
        <View style={styles.container}>
          {(entities || []).map(entity => (
            <ImageListItem
              key={entity.id}
              entity={entity}
              checked={entity.id === selectedEntityId}
              onSelectEntity={onSelectEntity}
            />
          ))}
        </View>
      </DragScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 10,
    overflow: 'hidden',
    paddingHorizontal: 7,
    height: 50,
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});
