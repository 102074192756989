import * as React from 'react';

import BaseIndex from '../../shared/generics/character_show_or_update_scene_command/actor_character_faces/Index';
import PreviewBox from '../../shared/cover_image_form/PreviewBox';
import WarningModal from '../../shared/modals/WarningModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormActorCharacterFacesRouteProp} from '../../../navigators/RouteProps';

import {Params as ActorCharacterFaceIndexParams} from '../../../actions/actor_character_faces/index';
import {Params as ActorCostumeIndexParams} from '../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../actions/actor_hair_styles/index';
import {Params as ActorAccessorySetIndexParams} from '../../../actions/actor_accessory_sets/index';
import {Params as MarkIndexParams} from '../../../actions/marks/index';
import {Params as CharacterPatternIndexParams} from '../../../actions/character_patterns/index';
import {Params as CharacterPatternCreateParams} from '../../../actions/character_patterns/create';
import {Params as CoverImageFormUpdateParams} from '../../../actions/cover_image_forms/update';

import {actorCharacterFaceImageUrl} from '../../../helpers/images';
import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import ActorCharacterFace from '../../../../domain/entities/ActorCharacterFace';
import Mark from '../../../../domain/entities/Mark';
import Character from '../../../../domain/entities/Character';
import CharacterPattern from '../../../../domain/entities/CharacterPattern';
import ActorCostume from '../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../domain/entities/ActorHairStyle';
import ActorAccessorySet from '../../../../domain/entities/ActorAccessorySet';
import PaginatedResult from '../../../../domain/results/PaginatedResult';
import File from '../../../../domain/entities/File';

import fetchBlob from '../../../../data/data_stores/net/FetchBlob';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  characterId: number;
  characterPatternId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormActorCharacterFacesRouteProp;
  coverImageForm: CoverImageForm | null;
  marksParams: MarkIndexParams;
  character: Character | null;
  characterPattern: CharacterPattern | null;
  marks: Mark[] | null;
}

export interface DispatchProps {
  showCharacter: (id: number) => Promise<Character>;
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  showCharacterPattern: (id: number) => Promise<CharacterPattern>;
  createCharacterPattern: (
    params: CharacterPatternCreateParams,
  ) => Promise<CharacterPattern>;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  indexMarks: (params: MarkIndexParams) => Promise<PaginatedResult<Mark>>;
  updateCharacterPatternUsageHistory: (id: number) => Promise<CharacterPattern>;
  updateCoverImageForm: (params: CoverImageFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  visibleWarningModal: boolean;
  selectedActorCharacterFace: ActorCharacterFace | null;
  fetchedBackgroundImageUri: boolean;
  backgroundImageUri: string | null;
  fetchedCharacterImageUri: boolean;
  characterImageUri: string | null;
}

export default class Index extends React.Component<Props, State> {
  private ref = React.createRef<PreviewBox>();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      visibleWarningModal: false,
      selectedActorCharacterFace: null,
      fetchedBackgroundImageUri: false,
      backgroundImageUri: null,
      fetchedCharacterImageUri: false,
      characterImageUri: null,
    };
  }

  public async componentDidMount() {
    const {navigation, route, coverImageForm, showCharacterPattern} =
      this.props;
    const {characterPatternId} = route.params;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
    showCharacterPattern(characterPatternId);
    if (coverImageForm) {
      coverImageForm.getBackgroundImageUri().then(backgroundImageUri => {
        this.setState({
          fetchedBackgroundImageUri: true,
          backgroundImageUri,
        });
      });
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {coverImageForm} = this.props;
    if (coverImageForm && !prevProps.coverImageForm) {
      coverImageForm.getBackgroundImageUri().then(backgroundImageUri => {
        this.setState({
          fetchedBackgroundImageUri: true,
          backgroundImageUri,
        });
      });
    }
  }

  public render(): React.ReactNode {
    const {coverImageForm, route, characterPattern} = this.props;
    const {
      loading,
      visibleWarningModal,
      fetchedBackgroundImageUri,
      backgroundImageUri,
      fetchedCharacterImageUri,
      characterImageUri,
    } = this.state;
    const {aspectRatio, characterId, characterPatternId} = route.params;
    if (!characterPattern) {
      return null;
    }
    return (
      <>
        <BaseIndex
          {...this.props}
          characterPattern={characterPattern}
          back={true}
          loading={loading}
          characterId={characterId}
          characterPatternId={characterPatternId}
          hideTab={true}
          onSelectActorCharacterFace={this.handleSelectActorCharacterFace}
        />
        <WarningModal
          visible={visibleWarningModal}
          onAccept={this.handleAccept}
          onRequestClose={this.handleRequestClose}>
          キャラは上書きされます。{'\n'}
          よろしいですか？
        </WarningModal>
        {coverImageForm &&
          fetchedCharacterImageUri &&
          fetchedBackgroundImageUri && (
            <PreviewBox
              ref={this.ref}
              backgroundImageUri={backgroundImageUri}
              characterImageUri={characterImageUri}
              aspectRatio={aspectRatio}
              imageTextInfoList={coverImageForm.getImageTextInfoList()}
              onDrawCanvas={this.handleDrawCanvas}
            />
          )}
      </>
    );
  }

  private handleAccept = () => {
    this.setState({
      fetchedCharacterImageUri: true,
      loading: true,
      visibleWarningModal: false,
    });
  };

  private handleRequestClose = () => {
    this.setState({
      characterImageUri: null,
      visibleWarningModal: false,
    });
  };

  private handleSelectActorCharacterFace = async (
    characterPattern: CharacterPattern,
    actorCharacterFace: ActorCharacterFace,
  ) => {
    const {route, coverImageForm} = this.props;
    if (!coverImageForm) {
      return null;
    }
    const uri = await fetchBlob(
      actorCharacterFaceImageUrl(actorCharacterFace, 'large'),
    );
    if (coverImageForm.getCharacterImageUri()) {
      this.setState({characterImageUri: uri, visibleWarningModal: true});
    } else {
      this.setState({
        fetchedCharacterImageUri: true,
        characterImageUri: uri,
        loading: true,
      });
    }
  };

  private handleDrawCanvas = async (image: File | null) => {
    const {navigation, coverImageForm, updateCoverImageForm} = this.props;
    const {characterImageUri} = this.state;
    if (!this.ref.current || !coverImageForm) {
      return;
    }
    const backgroundImageUri = await coverImageForm.getBackgroundImageUri();
    if (image) {
      updateCoverImageForm({
        image,
        characterImageUri,
        ...(backgroundImageUri
          ? {}
          : {
              backgroundImageUri:
                'data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7',
            }),
      });
      this.setState({loading: false}, () => {
        navigation.getParent()?.goBack();
      });
    } else {
      this.setState({loading: false});
    }
  };
}
