import selectEntity from './selectEntity';

import AppState from '../reducers/AppState';

export const enabledResourceFavorite = (state: AppState) => {
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  if (!currentUser) {
    return false;
  }
  return currentUser.enabledPaidSubscriber || currentUser.isGradeBlack();
};
