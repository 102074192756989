import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics';

import Announcement from '../../../../domain/entities/writer/Announcement';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetAnnouncementsRepository from '../../../../data/repositories/writer/NetAnnouncementsRepository';

const types = {
  failure: Types.IndexWriterAnnouncementsFailure,
  request: Types.IndexWriterAnnouncementsRequest,
  success: Types.IndexWriterAnnouncementsSuccess,
};

export interface Params extends BaseParams {
  writerAnnouncementCategoryId?: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Announcement>> {
  return indexGenerics(new NetAnnouncementsRepository(), types, params);
}
