import PaymentIntentMapper, {
  PaymentIntentData,
} from '../../../entities/mappers/writer/my_stripe/PaymentIntentMapper';

import PaymentIntent from '../../../../domain/entities/writer/my_stripe/PaymentIntent';
import ResourceProductPaymentIntentsRepository from '../../../../domain/repositories/writer/my_stripe/ResourceProductPaymentIntentsRepository';

import TapNovelRestApi from '../../../data_stores/net/TapNovelRestApi';

export default class NetResourceProductPaymentIntentsRepository
  implements ResourceProductPaymentIntentsRepository
{
  private endPoint = '/api/writer/my_stripe/resource_product_payment_intents';
  public mapper = new PaymentIntentMapper();

  constructor() {}

  public async create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<PaymentIntent> {
    const path = this.endPoint;
    const obj = await TapNovelRestApi.post<PaymentIntentData>(
      path,
      params,
      options,
    );
    return this.mapper.map(obj.body);
  }
}
