import * as React from 'react';
import {camelizeKeys} from 'humps';

import TapNovelWebView from '../shared/TapNovelWebView';
import MetaTags from '../shared/MetaTags';

import NavigationProp from '../../navigators/NavigationProp';
import {ContestRouteProp} from '../../navigators/RouteProps';

import CurrentUser from '../../../domain/entities/writer/CurrentUser';

import {getUserAgent} from '../../../data/data_stores/net/UserAgent';

import TapNovelRestApi from '../../../data/data_stores/net/TapNovelRestApi';

interface Contest {
  id: number;
  metaTitle: string;
  metaDescription: string;
  metaKeywords: Array<string>;
}

export interface Params {
  contestId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ContestRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {
  children?: React.ReactNode;
}

const Show: React.FunctionComponent<Props> = props => {
  const {route, currentUser} = props;
  const {contestId} = route.params;
  const [userAgent, setUserAgent] = React.useState<string | null>(null);
  const [contest, setContest] = React.useState<Contest | null>(null);

  React.useEffect(() => {
    TapNovelRestApi.get(`/contests/${contestId}.json`).then(response => {
      setContest(camelizeKeys(response.body as any) as unknown as Contest);
    });
    getUserAgent().then(ua => {
      setUserAgent(ua);
    });
  }, []);
  return userAgent && contest ? (
    <>
      <MetaTags
        title={`${contest.metaTitle} - TapNovelMaker`}
        description={contest.metaDescription}
        keywords={contest.metaKeywords.join(',')}
        noindex={true}
        indexifembedded={true}
      />
      <TapNovelWebView
        style={{flex: 1}}
        originWhitelist={['*']}
        path={`/contests/${contestId}?mode=maker&signed_in=${!!currentUser}`}
        userAgent={userAgent}
      />
    </>
  ) : null;
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(Show, areEqual);
