// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SocialLogin, {ErrorData, Params, SuccessData} from './SocialLogin';

export type SuccessData = SuccessData;
export type ErrorData = ErrorData;

export default class FacebookLogin implements SocialLogin {
  public login(params: Params) {
    const {onSuccess, onError} = params;
    (window as any).fbAsyncInit = function () {
      if ((window as any).FB) {
        (window as any).FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v10.0',
        });
      }
    };
    this.loadScript(
      'https://connect.facebook.net/en_US/sdk.js',
      () => {
        (window as any).FB.login(function (response: any) {
          if (response.authResponse) {
            (window as any).FB.api(
              '/me',
              'GET',
              {fields: 'id,name,email,picture.type(large)'},
              function (response: any) {
                const {id, name, email} = response;
                (window as any).FB.getLoginStatus(function (response: any) {
                  if (response.status === 'connected') {
                    const data = {
                      accessToken: response.authResponse.accessToken,
                      email: email,
                      id: id,
                      provider: 'facebook',
                      screenName: name,
                    };
                    onSuccess(data);
                  }
                });
              },
            );
          } else {
            onError({
              error: 'User cancelled login or did not fully authorize.',
            });
          }
        });
      },
      () => {},
    );
  }

  private loadScript = (src: string, success: () => void, fail: () => void) => {
    const head = document.head || document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = src;
    script.onload = function () {
      this.onerror = this.onload = null;
      success();
    };
    script.onerror = function () {
      this.onerror = this.onload = null;
      fail();
    };
    head.appendChild(script);
  };
}
