import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/character_form/new/actor_search_form/Index';

import AppState from '../../../../reducers/AppState';

import indexActorCategories, {
  Params as ActorCategoryIndexParams,
} from '../../../../actions/actor_categories/index';
import indexActors, {
  Params as ActorIndexParams,
} from '../../../../actions/actors/index';

import selectEntities from '../../../../helpers/selectEntities';

import {CharacterFormNewActorSearchFormRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CharacterFormNewActorSearchFormRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries} = state;
  const actorCategoriesParams: ActorCategoryIndexParams = {};
  const actorCategories = selectEntities(
    entities.actorCategories,
    queries.actorCategories,
    actorCategoriesParams,
  );
  return {
    actorCategories,
    actorCategoriesParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexActorCategories: (params: ActorCategoryIndexParams) =>
      dispatch(indexActorCategories(params)),
    indexActors: (params: ActorIndexParams) => dispatch(indexActors(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
