import EntityMapper from './EntityMapper';

import BackgroundWeather from '../../../domain/entities/BackgroundWeather';

export interface BackgroundWeatherData {
  id: number;
  name: string;
}

export default class BackgroundWeatherMapper
  implements EntityMapper<BackgroundWeatherData, BackgroundWeather>
{
  public map(obj: BackgroundWeatherData): BackgroundWeather {
    return new BackgroundWeather(obj.id, obj.name);
  }
}
