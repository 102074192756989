import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import Question from '../../../../../domain/entities/writer/faq/Question';

interface Props {
  question: Question;
  selfWriterUserId?: number;
}

const NoAnswers: React.FC<Props> = props => {
  const {question, selfWriterUserId} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    if (selfWriterUserId) {
      linkTo(`/faq/questions/${question.id}/answers/new`);
    } else {
      linkTo(`/users/sign_in`);
    }
  }, [selfWriterUserId]);
  if (question.writerUser.id === selfWriterUserId) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        このお問い合わせにはまだ回答がございません。{'\n'}
        回答していただけませんか？
      </Text>
      <PrimaryButton
        style={styles.button}
        disabled={question.writerUser.id === selfWriterUserId}
        onPress={onPress}>
        回答する
      </PrimaryButton>
    </View>
  );
};

export default React.memo(NoAnswers);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderRadius: 4,
    marginVertical: 24,
    marginHorizontal: 16,
    padding: 16,
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 12,
    textAlign: 'center',
  } as TextStyle,
  button: {
    marginTop: 16,
    width: 240,
    height: 46,
  } as ViewStyle,
});
