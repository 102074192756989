import url from 'url';

import PaginatedResult, {Params} from '../results/PaginatedResult';

export default class PaginatedResultFactory {
  public create<E>(
    headers: Headers,
    records: E[],
    params: Params,
  ): PaginatedResult<E> {
    const totalStr = headers.get('total');
    if (totalStr) {
      const total = Number(totalStr);
      const perPage = Number(headers.get('per-page')) || total;
      const linkPages = this.parseLinkHeader(headers.get('link'));
      return new PaginatedResult(
        records,
        total,
        params,
        perPage,
        linkPages.firstPage,
        linkPages.prevPage,
        linkPages.nextPage,
        linkPages.lastPage,
      );
    } else {
      return new PaginatedResult(
        records,
        records.length,
        params,
        records.length,
      );
    }
  }

  private parseLinkHeader(linkHeader: string | null): {
    firstPage?: number;
    lastPage?: number;
    nextPage?: number;
    prevPage?: number;
  } {
    if (!linkHeader) {
      return {};
    }
    const parsedHeader: any = linkHeader
      .split(/,\s*</)
      .map(this.parseLink)
      .reduce(this.intoRels, {});
    const firstPage = parsedHeader.first ? parsedHeader.first.page : undefined;
    const lastPage = parsedHeader.last ? parsedHeader.last.page : undefined;
    const nextPage = parsedHeader.next ? parsedHeader.next.page : undefined;
    const prevPage = parsedHeader.prev ? parsedHeader.prev.page : undefined;
    return {
      firstPage,
      lastPage,
      nextPage,
      prevPage,
    };
  }

  /**
   *
   * @param link {string}
   * A sample link is below.
   * <https://example.com/api/base_backgrounds?all_background_category_id=1&page=2>; rel="last"
   */
  private parseLink(link: string) {
    const m = link.match(/<?([^>]*)>(.*)/);
    if (!m) {
      return null;
    }
    const linkUrl = m[1];
    const parts = m[2].split(';');
    // parts = '; rel="last"'
    const parsedUrl = url.parse(linkUrl, true);
    const {page} = parsedUrl.query;
    parts.shift();
    const info = parts.reduce((acc: {[key: string]: any}, p: string) => {
      const n = p.match(/\s*(.+)\s*=\s*"?([^"]+)"?/);
      if (n) {
        acc[n[1]] = n[2];
      }
      return acc;
    }, {});
    return {
      page: page ? Number(page) : undefined,
      ...info,
    };
  }

  private intoRels(acc: {[key: string]: any}, x: {[key: string]: any} | null) {
    if (!x || !x.rel) {
      return acc;
    }
    x.rel.split(/\s+/).forEach((rel: string) => {
      acc[rel] = {...x, rel};
    });
    return acc;
  }
}
