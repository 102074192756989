import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import showGeneric from '../../generics/show';

import Announcement from '../../../../domain/entities/writer/Announcement';

import NetAnnouncementsRepository from '../../../../data/repositories/writer/NetAnnouncementsRepository';

const types = {
  failure: Types.ShowWriterAnnouncementFailure,
  request: Types.ShowWriterAnnouncementRequest,
  success: Types.ShowWriterAnnouncementSuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<Announcement> {
  return showGeneric(new NetAnnouncementsRepository(), types, id);
}
