import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import StoryInfo from './partials/StoryInfo';
import EditProjectButton from './partials/EditProjectButton';

import ProjectInfo from '../partials/ProjectInfo';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectSummaryRouteProp} from '../../../navigators/RouteProps';

import Project from '../../../../domain/entities/Project';
import ProjectUserStatus from '../../../../domain/entities/ProjectUserStatus';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectSummaryRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {id} = route.params;
  const [project, setProject] = React.useState<Project | null>(null);
  const [userStatus, setUserStatus] = React.useState<ProjectUserStatus | null>(
    null,
  );
  useFocusEffect(
    React.useCallback(() => {
      TapNovelTypedRestApi.get<Project>(`/api/writer/user/projects/${id}`).then(
        result => {
          setProject(result.body);
        },
      );
    }, []),
  );
  React.useEffect(() => {
    TapNovelTypedRestApi.get<ProjectUserStatus>(
      '/api/writer/project_user_status',
      {projectId: id},
    ).then(result => {
      setUserStatus(result.body);
    });
  }, []);
  return (
    <Layout
      title={'プロジェクト概要'}
      navigation={navigation}
      back={true}
      scrollViewStyle={{paddingBottom: 100}}
      footer={
        userStatus?.role === 'leader' &&
        project && <EditProjectButton projectId={project.id} />
      }>
      {project && (
        <>
          <StoryInfo project={project} />
          <ProjectInfo project={project} />
        </>
      )}
    </Layout>
  );
};

export default React.memo(Show);
