import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/character_show_scene_command/new/character_patterns/Index';

import AppState from '../../../../../reducers/AppState';

import indexCharacterPatterns, {
  Params as CharacterPatternIndexParams,
} from '../../../../../actions/character_patterns/index';

import selectEntities from '../../../../../helpers/selectEntities';
import selectEntity from '../../../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../../../helpers/ResourceAuthorizattion';
import {SceneFormCharacterShowSceneCommandNewCharacterPatternsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterPatternsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const {storyId} = route.params;
  const sceneForm = forms.sceneForm;
  const characterPatternsParams: CharacterPatternIndexParams = {storyId};
  const characterPatterns = selectEntities(
    entities.characterPatterns,
    queries.characterPatterns,
    characterPatternsParams,
  );
  return {
    currentUserStatus,
    sceneForm,
    characterPatterns,
    characterPatternsParams,
    navigation,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexCharacterPatterns: (params: CharacterPatternIndexParams) =>
      dispatch(indexCharacterPatterns(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
