import EntityMapper from './EntityMapper';

import BackgroundTimeZone from '../../../domain/entities/BackgroundTimeZone';

export interface BackgroundTimeZoneData {
  id: number;
  name: string;
}

export default class BackgroundTimeZoneMapper
  implements EntityMapper<BackgroundTimeZoneData, BackgroundTimeZone>
{
  public map(obj: BackgroundTimeZoneData): BackgroundTimeZone {
    return new BackgroundTimeZone(obj.id, obj.name);
  }
}
