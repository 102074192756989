import * as React from 'react';
import {
  Linking,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Config from 'react-native-config';

import AlertIcon from './icons/AlertIcon';

interface Props {
  entryContestInfo: {
    contest_id: number;
    errors: string[];
  };
  note?: boolean;
  children?: React.ReactNode;
}

const generateUrl = (path: string) => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  } else {
    return Config.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  }
};

const ContestErrorMessagesBox: React.FunctionComponent<Props> = props => {
  const {entryContestInfo, note, children} = props;
  const contestErrorMessages = entryContestInfo.errors;

  const handlePressForContest = React.useCallback(() => {
    Linking.openURL(generateUrl(`/contests/${entryContestInfo.contest_id}`));
  }, []);
  const handlePressForContestApplicationTerms = React.useCallback(() => {
    Linking.openURL(
      generateUrl(`/contests/${entryContestInfo.contest_id}/application_terms`),
    );
  }, []);
  return (
    <View style={styles.container}>
      <Text style={[styles.title, note ? null : {marginBottom: 5}]}>
        <AlertIcon color={'#f23406'} />
        コンテストへの応募キーワードが設定されています
      </Text>
      {note && (
        <Text style={styles.note}>
          <TouchableOpacity onPress={handlePressForContest}>
            <Text style={{textDecorationLine: 'underline'}}>注意事項</Text>
          </TouchableOpacity>
          及び
          <TouchableOpacity onPress={handlePressForContestApplicationTerms}>
            <Text style={{textDecorationLine: 'underline'}}>応募規約</Text>
          </TouchableOpacity>
          を必ずご確認の上ご登録ください。
        </Text>
      )}
      {contestErrorMessages && contestErrorMessages.length > 0 && (
        <>
          <Text style={styles.invalidMessage}>
            コンテストへの応募条件を満たしておりません。
          </Text>
          {contestErrorMessages.map((contestErrorMessage, i) => (
            <Text key={`${i}`} style={styles.invalidMessage}>
              ・{contestErrorMessage}
            </Text>
          ))}
        </>
      )}
      {children}
    </View>
  );
};

export default React.memo(ContestErrorMessagesBox);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 16,
    padding: 16,
    borderRadius: 4,
  } as ViewStyle,
  title: {
    color: '#f23406',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  note: {
    color: '#666',
    fontSize: 11,
    marginVertical: 10,
  } as TextStyle,
  invalidMessage: {
    color: '#f23406',
    fontSize: 11,
  } as TextStyle,
});
