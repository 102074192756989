import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import ProjectManuscript from '../../../../../domain/entities/ProjectManuscript';

interface Props extends React.PropsWithChildren {
  manuscript: ProjectManuscript;
  commentsCount: number;
}

const CommentListHeader: React.FC<Props> = props => {
  const {manuscript, commentsCount, children} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(
      `/projects/${manuscript.projectId}/manuscripts/${manuscript.id}/comments/new`,
    );
  }, []);
  return (
    <>
      {children}
      <View style={styles.button}>
        {manuscript.iterationCompleted ? (
          <PrimaryButton style={{width: 240}} disabled={true} onPress={onPress}>
            タームが終了しました
          </PrimaryButton>
        ) : (
          <PrimaryButton style={{width: 240}} onPress={onPress}>
            コメント
          </PrimaryButton>
        )}
      </View>

      {commentsCount ? (
        <View style={styles.container}>
          <Text style={styles.title}>
            みんなのコメント（全{commentsCount}件）
          </Text>
        </View>
      ) : null}
    </>
  );
};

export default React.memo(CommentListHeader);

const styles = StyleSheet.create({
  button: {
    margin: 16,
    alignItems: 'center',
  } as ViewStyle,
  container: {
    margin: 16,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
});
