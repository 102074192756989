import EntityMapper from './EntityMapper';

import Reaction from '../../../domain/entities/Reaction';

export interface ReactionData {
  id: number;
  code: string;
  name: string;
}

export default class OauthAccountMapper
  implements EntityMapper<ReactionData, Reaction>
{
  public map(obj: ReactionData): Reaction {
    return new Reaction(obj.id, obj.code, obj.name);
  }
}
