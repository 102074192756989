import * as React from 'react';
import {Image, ImageStyle, StyleProp, View} from 'react-native';

import SelectableLabeledImageGridList from '../../../../../shared/SelectableLabeledImageGridList';

import BaseEffect from '../../../../../../../domain/entities/BaseEffect';
import Effect from '../../../../../../../domain/entities/Effect';
import PositionedEffect from '../../../../../../../domain/entities/PositionedEffect';

const noBaseEffect = new BaseEffect(
  0,
  'なし',
  '',
  0,
  new Effect(0, 0, 'なし', '', new PositionedEffect(0, 0, 0, 'center', '', [])),
);
interface Props {
  uri: string;
  baseEffects: Array<BaseEffect>;
  selectedBaseEffect: BaseEffect | null;
  onSelectBaseEffect: (baseEffect: BaseEffect) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null | undefined;
}

export default class BackgroundEffectList extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {baseEffects, selectedBaseEffect, onSelectBaseEffect, onEndReached} =
      this.props;
    return (
      <SelectableLabeledImageGridList
        entities={[noBaseEffect, ...baseEffects]}
        aspectRatio={aspectRatio}
        selectedEntity={selectedBaseEffect || noBaseEffect}
        onSelectEntity={onSelectBaseEffect}
        renderLabel={this.renderLabel}
        keyExtractor={this.keyExtractor}
        isActive={this.isActive}
        renderImage={this.renderImage}
        onEndReached={onEndReached}
      />
    );
  }

  private renderLabel = (baseEffect: BaseEffect) => {
    return `${baseEffect.name}${
      baseEffect.effectsCount > 1 ? `(${baseEffect.effectsCount}種類)` : ''
    }`;
  };

  private keyExtractor = (baseEffect: BaseEffect) => {
    return `${baseEffect.id}`;
  };

  private isActive = (
    baseEffect: BaseEffect,
    selectedBaseEffect: BaseEffect | null,
  ) => {
    return baseEffect === selectedBaseEffect;
  };

  private renderImage = (
    baseEffect: BaseEffect,
    width: number,
    imageStyle: StyleProp<ImageStyle>,
  ) => {
    const {uri} = this.props;
    const height = width / aspectRatio;
    return (
      <View style={imageStyle}>
        <Image
          style={{width, height}}
          source={{uri: uri}}
          resizeMode={'contain'}
        />
        <Image
          style={{width, height, position: 'absolute'}}
          source={{uri: baseEffect.originalImageUrl}}
          resizeMode={'contain'}
        />
      </View>
    );
  };
}

const aspectRatio = 1.25;
