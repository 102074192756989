import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import EffectCategory from '../../../domain/entities/EffectCategory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetEffectCategoriesRepository from '../../../data/repositories/NetEffectCategoriesRepository';

const types = {
  failure: Types.IndexEffectCategoriesFailure,
  request: Types.IndexEffectCategoriesRequest,
  success: Types.IndexEffectCategoriesSuccess,
};

export interface Params extends BaseParams {
  id?: number | null;
  parentId?: number | null;
  exceptBackground?: boolean | null;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<EffectCategory>> {
  return indexGenerics(new NetEffectCategoriesRepository(), types, params);
}
