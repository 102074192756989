import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import BaseButton, {FontSizeClass} from './BaseButton';

interface Props {
  style?: StyleProp<ViewStyle>;
  buttonSize?: {width: number; height: number};
  fontSizeClass?: FontSizeClass;
  disabled?: boolean;
  unwrappedText?: boolean;
  children?: React.ReactNode;
  onPress: () => void;
}

const BlackButton: React.FunctionComponent<Props> = props => {
  const {
    style,
    buttonSize,
    fontSizeClass,
    disabled,
    unwrappedText,
    children,
    onPress,
  } = props;
  return (
    <BaseButton
      style={style}
      tintColor={'black'}
      buttonSize={buttonSize || DEFAULT_BUTTON_SIZE}
      fontSizeClass={fontSizeClass}
      disabled={disabled}
      unwrappedText={unwrappedText}
      onPress={onPress}>
      {children}
    </BaseButton>
  );
};

export default React.memo(BlackButton);

const DEFAULT_BUTTON_SIZE = {
  width: 311,
  height: 46,
};
