import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import IterationListItem from './IterationListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectIteration from '../../../../../domain/entities/ProjectIteration';

interface Props {
  iterations: ProjectIteration[];
}

const EpisodeList: React.FC<Props> = props => {
  const {iterations} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<any>) => (
      <IterationListItem iteration={info.item} />
    ),
    [],
  );
  return (
    <FlatList
      data={iterations}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(EpisodeList);
