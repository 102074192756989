import * as React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import SearchResultWrapper from './SearchResultWrapper';
import SearchResultHeader from './SearchResultHeader';
import TotalCountWithSort from './TotalCountWithSort';
import SoundList from './SoundList';
import {SoundEntity} from './SoundListItem';
import SearchEmpty from './SearchEmpty';

import {colors} from '../../../styles/variables';

import UserResourceFavorite from '../../../../domain/entities/writer/UserResourceFavorite';

interface Props<T extends SoundEntity> {
  entities: T[] | null;
  totalCount: number | null;
  title: string;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
  resourceName?: string;
  enabledFavorite?: boolean;
  userResourceFavorites?: UserResourceFavorite[] | null;
  onPressSearchConditionChangeButton: () => void;
  onSelectEntity: (entity: T) => void;
  onPressSort: (sort: 'popularity' | 'new_arrival') => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
}

export default class SoundSearchResult<
  T extends SoundEntity,
> extends React.PureComponent<Props<T>> {
  public render(): React.ReactNode {
    const {
      entities,
      totalCount,
      title,
      sort,
      favorite,
      resourceName,
      enabledFavorite,
      userResourceFavorites,
      onPressSearchConditionChangeButton,
      onSelectEntity,
      onPressSort,
      onEndReached,
    } = this.props;

    return (
      <SearchResultWrapper>
        {entities && (
          <>
            {entities.length > 0 ? (
              <SearchResultHeader
                title={title}
                onPress={onPressSearchConditionChangeButton}
              />
            ) : null}
            {entities && (
              <SoundList
                entities={entities}
                enabledFavorite={enabledFavorite}
                userResourceFavorites={userResourceFavorites}
                ListHeaderComponent={
                  entities.length > 0 ? (
                    <TotalCountWithSort
                      style={styles.totalCountWithSort}
                      totalCount={totalCount}
                      sort={sort}
                      favorite={favorite}
                      onPress={onPressSort}
                    />
                  ) : null
                }
                ListEmptyComponent={
                  resourceName ? (
                    <SearchEmpty
                      favorite={favorite}
                      resourceName={resourceName}
                    />
                  ) : null
                }
                onSelectEntity={onSelectEntity}
                onEndReached={onEndReached}
              />
            )}
          </>
        )}
      </SearchResultWrapper>
    );
  }
}

const styles = StyleSheet.create({
  totalCountWithSort: {
    padding: 16,
    marginVertical: 0,
    borderBottomWidth: 1,
    borderBottomColor: colors.powderGray,
  } as ViewStyle,
});
