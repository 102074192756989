import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import {useNavigation} from '@react-navigation/native';

import ArrowForwardIcon from './icons/ArrowForwardIcon';

import NavigationProp from '../../navigators/NavigationProp';

interface Props {}

const ProLink: React.FunctionComponent<Props> = props => {
  const navigation = useNavigation<NavigationProp>();
  const onPress = React.useCallback(() => {
    navigation.navigate('ProNavigation', {});
  }, [navigation]);
  return (
    <TouchableOpacity style={styles.link} onPress={onPress}>
      <Text style={styles.linkText}>MakerProの詳細を見る</Text>
      <ArrowForwardIcon color={'#ff8f13'} size={10} />
    </TouchableOpacity>
  );
};

export default React.memo(ProLink);

const styles = StyleSheet.create({
  link: {
    flexDirection: 'row',
    marginVertical: 4,
    alignItems: 'center',
  } as ViewStyle,
  linkText: {
    color: '#ff8f13',
    fontSize: 12,
    fontWeight: 'bold',
    borderColor: '#ff8f13',
    borderBottomWidth: 1,
    marginRight: 4,
  } as TextStyle,
});
