import {AnyAction} from 'redux';
import Types from '../../actions/Types';

export default function reducer(
  state: {
    message: string;
    title?: string;
    action?: {
      label: string;
      callback: () => void;
    };
  } | null = null,
  action: AnyAction,
): {
  message: string;
  title?: string;
  action?: {
    label: string;
    callback: () => void;
  };
} | null {
  switch (action.type) {
    case Types.UpdateApplicationModal:
      return action.payload;
    default:
      return state;
  }
}
