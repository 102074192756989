import * as React from 'react';

import Form, {FormValues} from './partials/form/Form';

import AlertModal from '../shared/modals/AlertModal';

import NavigationProp from '../../navigators/NavigationProp';
import {EditProjectRouteProp} from '../../navigators/RouteProps';

import {formatErrorMessages} from '../../helpers/errorMessages';

import Project from '../../../domain/entities/Project';
import TapNovelTypedRestApi from '../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditProjectRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Edit: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {id} = route.params;
  const [formValues, setFormValues] = React.useState<FormValues | null>(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalTooltip = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<Project>(`/api/writer/user/projects/${id}`).then(
      result => {
        const project = result.body;
        const {id, story, ...rest} = project;
        const {
          title,
          introduction,
          catchPhrase,
          rating,
          format,
          allowedVideoSharing,
        } = story;
        const mainGenreId = story.genres[0].id;
        const subGenreIds = story.genres.slice(1).map(g => g.id);
        const keywordNames = story.keywords.map(k => k.name);
        setFormValues({
          ...rest,
          title,
          introduction,
          catchPhrase,
          rating,
          format,
          allowedVideoSharing,
          mainGenreId,
          subGenreIds,
          keywordNames,
        });
      },
    );
  }, []);
  const onSubmit = React.useCallback((formValues: FormValues) => {
    const {
      title,
      introduction,
      catchPhrase,
      rating,
      format,
      allowedVideoSharing,
      mainGenreId,
      subGenreIds,
      keywordNames,
      ...projectValues
    } = formValues;
    TapNovelTypedRestApi.patch(`/api/writer/user/projects/${id}`, {
      project: {
        ...projectValues,
        storyAttributes: {
          title,
          introduction,
          catchPhrase,
          rating,
          format,
          allowedVideoSharing,
          mainGenreId,
          subGenreIds,
          keywordNames,
        },
      },
    })
      .then(() => {
        navigation.goBack();
      })
      .catch(e => {
        setAlertMessage(formatErrorMessages({}, e));
      });
  }, []);
  if (!formValues) {
    return null;
  }
  return (
    <>
      <Form
        navigation={navigation}
        isNew={false}
        formValues={formValues}
        editableEpisodeCopyright={false}
        onSubmit={onSubmit}
      />
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
    </>
  );
};

export default React.memo(Edit);
