import Entity from '../Entity';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class Story implements Entity {
  constructor(
    public id: number,
    public title: string,
    public hasCoverImage: boolean,
    public originalCoverImageUrl: string,
    public tipsCount: number,
    public reactionsCount: number,
    public commentsCount: number,
    public confirmed: boolean,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalCoverImageUrl, options);
  }
}
