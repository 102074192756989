import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories from '../../../../containers/scene_form/background_show_scene_command/new/background_usage_histories/Index'; // tslint:disable-next-line:max-line-length
import SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm from '../../../../containers/scene_form/background_show_scene_command/new/base_background_search_form/Index'; // tslint:disable-next-line:max-line-length
import SceneFormBackgroundShowSceneCommandNewBackgroundFilters from '../../../../containers/scene_form/background_show_scene_command/new/background_filters/Index'; // tslint:disable-next-line:max-line-length
import SceneFormBackgroundShowSceneCommandNewBackgrounds from '../../../../containers/scene_form/background_show_scene_command/new/backgrounds/Index';
import SceneFormBackgroundShowSceneCommandNewBaseBackgrounds from '../../../../containers/scene_form/background_show_scene_command/new/base_backgrounds/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories">
      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories"
        component={
          SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories
        }
        options={{title: '背景'}}
      />

      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm"
        component={
          SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm
        }
        options={{title: '背景検索条件'}}
      />

      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBackgroundFilters"
        component={SceneFormBackgroundShowSceneCommandNewBackgroundFilters}
        options={{title: '背景フィルター'}}
      />

      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBackgrounds"
        component={SceneFormBackgroundShowSceneCommandNewBackgrounds}
        options={{title: '背景'}}
      />

      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBaseBackgrounds"
        component={SceneFormBackgroundShowSceneCommandNewBaseBackgrounds}
        options={{title: '背景'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
