import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../components/commercial_video/stories/Show';

import AppState from '../../../reducers/AppState';

import {CommercialVideoStoryRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CommercialVideoStoryRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {};
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
