import {TextStyle, ViewStyle} from 'react-native';

import Entity from './Entity';

import OrientedSpeechBalloonPart from './OrientedSpeechBalloonPart';

import FramePartPosition from '../value_objects/FramePartPosition';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class SpeechBalloon implements Entity {
  constructor(
    public id: number,
    public originalImageUrl: string,
    public centerSpeechBalloonParts: OrientedSpeechBalloonPart[],
    public containerStyle: ViewStyle,
    public textStyle: TextStyle,
    public voiceIconStyle?: ViewStyle,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getPartImageUrlOf(
    partPosition: FramePartPosition,
  ): OrientedSpeechBalloonPart {
    const found = this.centerSpeechBalloonParts.find(
      orientedSpeechBalloonPart => {
        return orientedSpeechBalloonPart.framePartPosition === partPosition;
      },
    );
    if (found) {
      return found;
    } else {
      throw Error(`Not found part of ${partPosition} for ${this.id}`);
    }
  }
}
