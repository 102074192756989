import Entity from '../Entity';

import UserProfile from './UserProfile';

export default class User implements Entity {
  constructor(
    public id: number,
    public penName: string,
    public writerGradeId: number,
    public writerGradeCode: string | null,
    public writerRank: number,
    public writerScore: number,
    public fansCount: number | undefined,
    public profileComment: string | null,
    public userProfile: UserProfile | null,
  ) {}
}
