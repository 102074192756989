import * as React from 'react';

import BaseIndex from '../../../../shared/generics/background_show_scene_command/base_backgrounds/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundShowSceneCommandNewBaseBackgroundsRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as BaseBackgroundIndexParams} from '../../../../../actions/base_backgrounds/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import {QueryState} from '../../../../../reducers/queries/Response';

import BackgroundCategory from '../../../../../../domain/entities/BackgroundCategory';
import BaseBackground from '../../../../../../domain/entities/BaseBackground';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  backgroundCategoryId?: number;
  selectedLargeBackgroundCategoryId?: number | null;
  selectedMiddleBackgroundCategoryId?: number | null;
  selectedSmallBackgroundCategoryId?: number | null;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBaseBackgroundsRouteProp;
  sceneForm: SceneForm | null;
  baseBackgroundsParams: BaseBackgroundIndexParams;
  baseBackgrounds: BaseBackground[] | null;
  backgroundCategory: BackgroundCategory | null;
  baseBackgroundQueries: QueryState;
  totalCount: number | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexBaseBackgrounds: (
    params: BaseBackgroundIndexParams,
  ) => Promise<PaginatedResult<BaseBackground>>;
  showBackgroundCategory: (id: number) => Promise<BackgroundCategory>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {route} = this.props;
    const {sort, favorite} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        sort={sort}
        favorite={favorite}
        onPressSearchConditionChangeButton={
          this.handlePressSearchConditionChangeButton
        }
        onSelectBaseBackground={this.handleSelectBaseBackground}
      />
    );
  }

  private handlePressSearchConditionChangeButton = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      query,
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId,
      favorite,
    } = route.params;
    navigation.navigate(
      'SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormNavigations',
      {
        screen:
          'SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm',
        params: {
          storyId,
          episodeId,
          sceneId,
          query,
          selectedLargeBackgroundCategoryId,
          selectedMiddleBackgroundCategoryId,
          selectedSmallBackgroundCategoryId,
          hideBack: true,
          favorite,
        },
      } as any,
    );
  };

  private handleSelectBaseBackground = (baseBackground: BaseBackground) => {
    const {navigation, route} = this.props;
    const baseBackgroundId = baseBackground.id;
    const {storyId, episodeId, sceneId} = route.params;
    routers.linkToSceneFormBackgroundShowSceneCommandNewBackgrounds(
      navigation,
      {
        baseBackgroundId,
        storyId,
        episodeId,
        sceneId,
      },
    );
  };
}
