import EntityMapper from '../EntityMapper';

import AnalyticsParticularEvent from '../../../../domain/entities/writer/AnalyticsParticularEvent';

export interface AnalyticsParticularEventData {
  id: number;
  user_id: number;
  writer_user_id: number;
  resource_type: string;
  resource_id: number;
  action_name: string;
  same_events_count: number;
}

export default class AnalyticsParticularEventMapper
  implements
    EntityMapper<AnalyticsParticularEventData, AnalyticsParticularEvent>
{
  public map(obj: AnalyticsParticularEventData): AnalyticsParticularEvent {
    return new AnalyticsParticularEvent(
      obj.id,
      obj.user_id,
      obj.writer_user_id,
      obj.resource_type,
      obj.resource_id,
      obj.action_name,
      obj.same_events_count,
    );
  }
}
