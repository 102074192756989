import Entity from '../Entity';

export default class AnalyticsParticularEvent implements Entity {
  constructor(
    public id: number,
    public userId: number,
    public writerUserId: number,
    public resourceType: string,
    public resourceId: number,
    public actionName: string,
    public sameEventsCount: number,
  ) {}
}
