import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/faq/home/Index';

import AppState from '../../../reducers/AppState';

import {FaqHomeRouteProp} from '../../../navigators/RouteProps';

import selectEntity from '../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
  route: FaqHomeRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  return {
    navigation,
    currentUser,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {};
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
