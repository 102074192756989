import Entity from '../Entity';

import ContactCategory from './ContactCategory';

export default class Contact implements Entity {
  constructor(
    public id: number,
    public writerContactCategory: ContactCategory,
    public email: string,
    public content: string,
    public deviceName: string,
    public platformName: string,
    public platformVersion: string,
    public applicationVersion: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
