import * as React from 'react';

import BaseIndex from '../../common/illustration_search_form/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as IllustrationCategoryIndexParams} from '../../../../../actions/illustration_categories/index';
import {Params as IllustrationIndexParams} from '../../../../../actions/illustrations/index';

import Illustration from '../../../../../../domain/entities/Illustration';
import IllustrationCategory from '../../../../../../domain/entities/IllustrationCategory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  selectedLargeIllustrationCategoryId?: number | null;
  selectedMiddleIllustrationCategoryId?: number | null;
  selectedSmallIllustrationCategoryId?: number | null;
  favorite?: boolean;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormRouteProp;
  sceneForm: SceneForm | null;
  largeIllustrationCategoriesParams: IllustrationCategoryIndexParams;
  largeIllustrationCategories: IllustrationCategory[] | null;
  middleIllustrationCategoriesParams: IllustrationCategoryIndexParams;
  middleIllustrationCategories: IllustrationCategory[] | null;
  smallIllustrationCategoriesParams: IllustrationCategoryIndexParams;
  smallIllustrationCategories: IllustrationCategory[] | null;
}

export interface DispatchProps {
  indexIllustrationCategories: (
    params: IllustrationCategoryIndexParams,
  ) => Promise<PaginatedResult<IllustrationCategory>>;
  indexIllustrations: (
    params: IllustrationIndexParams,
  ) => Promise<PaginatedResult<Illustration>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  totalCount: number;
  query: string;
}

export default class Index extends React.Component<Props, State> {
  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (params: {
    query?: string;
    illustrationCategoryId?: number;
  }) => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      selectedLargeIllustrationCategoryId,
      selectedMiddleIllustrationCategoryId,
      selectedSmallIllustrationCategoryId,
      favorite,
    } = route.params;
    routers.linkToSceneFormIllustrationShowSceneCommandNewIllustrations(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        selectedLargeIllustrationCategoryId,
        selectedMiddleIllustrationCategoryId,
        selectedSmallIllustrationCategoryId,
        favorite,
        ...params,
      },
    );
  };
}
