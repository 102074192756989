import * as React from 'react';
import {FlatList, ListRenderItemInfo, TouchableOpacity} from 'react-native';
import {useActionSheet} from '@expo/react-native-action-sheet';

import BaseCharacterList from '../../../characters/partials/CharacterList';
import CharacterInfoWitMenu from '../../../characters/partials/CharacterInfoWitMenu';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Character from '../../../../../domain/entities/Character';

interface Props {
  characters: Character[];
  sortingEnabled?: boolean;
  leader?: boolean;
  currentWriterUserId: number | null;
  onSelectCharacter: (character: Character) => void;
  onDeleteCharacter: (character: Character) => void;
  onChangeOrder: (character: Character, rowOrderPosition: number) => void;
  onStartSort?: () => void;
  onEndSort?: () => void;
}

const CharacterList: React.FC<Props> = props => {
  const {leader, currentWriterUserId, onDeleteCharacter} = props;
  const {showActionSheetWithOptions} = useActionSheet();
  const onOpenActionSheet = React.useCallback(
    (character: Character) => {
      const options = ['削除', 'キャンセル'];
      const destructiveButtonIndex = 0;
      const cancelButtonIndex = 1;
      const disabledButtonIndices: number[] = [];
      if (
        !currentWriterUserId ||
        !character.writerUserId ||
        currentWriterUserId !== character.writerUserId
      ) {
        disabledButtonIndices.push(destructiveButtonIndex);
      }
      disabledButtonIndices.push();
      showActionSheetWithOptions(
        {
          cancelButtonIndex,
          destructiveButtonIndex,
          options,
          disabledButtonIndices,
        },
        (buttonIndex?: number) => {
          switch (buttonIndex) {
            case destructiveButtonIndex:
              onDeleteCharacter(character);
              break;
            case cancelButtonIndex:
              break;
          }
        },
      );
    },
    [currentWriterUserId],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Character>) => (
      <TouchableOpacity onPress={() => props.onSelectCharacter(info.item)}>
        <CharacterInfoWitMenu
          character={info.item}
          onOpenActionSheet={onOpenActionSheet}
        />
      </TouchableOpacity>
    ),
    [onOpenActionSheet],
  );
  if (leader) {
    return (
      <BaseCharacterList {...props} onOpenActionSheet={onOpenActionSheet} />
    );
  }
  return (
    <FlatList
      data={props.characters}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(CharacterList);
