import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Edit, {
  DispatchProps,
  StateProps,
} from '../../../components/projects/episode_images/Edit';

import AppState from '../../../reducers/AppState';

import createCoverImageForm, {
  Params as CoverImageFormCreateParams,
} from '../../../actions/cover_image_forms/create';
import updateCoverImageForm, {
  Params as CoverImageFormUpdateParams,
} from '../../../actions/cover_image_forms/update';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {forms} = state;
  const coverImageForm =
    forms.coverImageForm?.type === 'videoImage' ? forms.coverImageForm : null;
  return {
    navigation,
    coverImageForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createCoverImageForm: (params: CoverImageFormCreateParams) =>
      dispatch(createCoverImageForm(params)),
    updateCoverImageForm: (params: CoverImageFormUpdateParams) =>
      dispatch(updateCoverImageForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
