import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import BackgroundTimeZoneMapper, {
  BackgroundTimeZoneData,
} from '../entities/mappers/BackgroundTimeZoneMapper';

import BackgroundTimeZone from '../../domain/entities/BackgroundTimeZone';
import BackgroundTimeZonesRepository from '../../domain/repositories/BackgroundTimeZonesRepository';

export default class NetBackgroundTimeZonesRepository
  extends NetResourcesRepository<BackgroundTimeZone, BackgroundTimeZoneData>
  implements BackgroundTimeZonesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/background_time_zones',
        new BackgroundTimeZoneMapper(),
      ),
    );
  }
}
