import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import TextArea from '../../../shared/forms/TextArea';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

import AlertIcon from '../../../shared/icons/AlertIcon';

import {colors, formStyles} from '../../../../styles/variables';

export type FormValues = {
  comment: string;
};

type FormErrorMessage = {[P in keyof FormValues]?: string | string[]};

interface Props {
  values?: Partial<FormValues>;
  errorMessages?: FormErrorMessage;
  onSubmit: (values: FormValues) => void;
}

const Form: React.FC<Props> = props => {
  const {values, errorMessages, onSubmit} = props;
  const [comment, setComment] = React.useState(values?.comment || '');
  const onPress = () => {
    onSubmit({comment});
  };
  const disabled = comment.length <= 0 || comment.length > 400;
  return (
    <View style={styles.container}>
      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'コメント'}
          length={comment.length}
          maxLength={400}
          entered={comment.length > 0}
          errorMessages={errorMessages?.comment}
        />
        <TextArea
          style={[
            styles.textArea,
            comment.length <= 400 ? undefined : styles.inputError,
          ]}
          value={comment}
          placeholder={'コメントを入力'}
          onChangeText={setComment}
        />
      </View>
      <View style={styles.alert}>
        <View style={styles.alertTitle}>
          <AlertIcon color={'#f23406'} size={12} />
          <Text style={styles.alertTitleText}>ご注意</Text>
        </View>
        <Text style={styles.alertMessage}>
          批判されることは痛みを伴います。{'\n'}
          作者への尊敬と作品への愛のこもったコメント{'\n'}
          を心がけましょう。
        </Text>
      </View>
      <View style={styles.button}>
        <PrimaryButton disabled={disabled} onPress={onPress}>
          投稿する
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
  alert: {
    backgroundColor: '#fafafa',
    margin: 16,
    marginTop: 0,
    padding: 16,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  alertTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 4,
  } as ViewStyle,
  alertTitleText: {
    color: '#f23406',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  alertMessage: {
    color: '#f23406',
    fontSize: 11,
    textAlign: 'center',
  } as TextStyle,
});
