import FullScreenIllustrationMapper, {
  FullScreenIllustrationData,
} from '../../../entities/mappers/FullScreenIllustrationMapper';

import FullScreenIllustrationShowSceneCommand from '../../../../domain/value_objects/scene_commands/FullScreenIllustrationShowSceneCommand';
import SoundMapper, {SoundData} from '../../../entities/mappers/SoundMapper';

export interface FullScreenIllustrationShowSceneCommandData {
  type: string;
  full_screen_illustration: FullScreenIllustrationData;
  sound?: SoundData | null;
  start_time?: number;
  end_time?: number;
}

export default class FullScreenIllustrationShowSceneCommandMapper {
  private fullScreenIllustrationMapper: FullScreenIllustrationMapper;
  private soundMapper: SoundMapper;

  constructor() {
    this.fullScreenIllustrationMapper = new FullScreenIllustrationMapper();
    this.soundMapper = new SoundMapper();
  }

  public map(
    obj: FullScreenIllustrationShowSceneCommandData,
  ): FullScreenIllustrationShowSceneCommand {
    const fullScreenIllustration = this.fullScreenIllustrationMapper.map(
      obj.full_screen_illustration,
    );
    const sound = obj.sound ? this.soundMapper.map(obj.sound) : null;
    return new FullScreenIllustrationShowSceneCommand(
      fullScreenIllustration,
      sound,
      obj.start_time,
      obj.end_time,
    );
  }
}
