import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/episode_form/edit/episode_publication_completion/Index';

import AppState from '../../../../reducers/AppState';

import showStory from '../../../../actions/stories/show';
import showEpisode from '../../../../actions/episodes/show';

import selectEntity from '../../../../helpers/selectEntity';

import {EpisodeFormEditEpisodePublicationCompletionRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EpisodeFormEditEpisodePublicationCompletionRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities} = state;
  const {episodeId} = route.params;
  const episode = selectEntity(entities.episodes, episodeId);
  const story = episode
    ? selectEntity(entities.stories, episode.storyId)
    : null;
  return {
    navigation,
    story,
    episode,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showStory: (id: number) => dispatch(showStory(id)),
    showEpisode: (id: number) => dispatch(showEpisode(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
