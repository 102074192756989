import * as React from 'react';
import {StyleSheet, ViewStyle} from 'react-native';

import Form, {Inputs} from './components/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation} = props;
  const onPress = React.useCallback((params: Inputs) => {
    TapNovelRestApi.patch('/api/writer/transfer_request', {
      transferRequest: params,
    }).then(response => {
      if ((response.body as any | null)?.id) {
        navigation.replace('TransferRequestComplete', {});
      }
    });
  }, []);
  return (
    <Layout
      title={'振込申請'}
      navigation={navigation}
      back={true}
      scrollViewStyle={styles.container}>
      <Form onSubmit={onPress} />
    </Layout>
  );
};

export default React.memo(New);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
  } as ViewStyle,
});
