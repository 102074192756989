import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import DetailEpisode from './partials/DetailEpisode';
import DetailIdea from './partials/DetailIdea';
import DetailPlot from './partials/DetailPlot';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {WritersGateManuscriptRouteProp} from '../../../navigators/RouteProps';

import OfferManuscript from '../../../../domain/entities/writers_gate/OfferManuscript';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: WritersGateManuscriptRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {id} = route.params;
  const [manuscript, setManuscript] = React.useState<OfferManuscript | null>(
    null,
  );
  const fetchEntities = React.useCallback(() => {
    TapNovelTypedRestApi.get<OfferManuscript>(
      `/api/writer/writers_gate/offer_manuscripts/${id}`,
    ).then(result => {
      setManuscript(result.body);
    });
  }, []);
  useFocusEffect(fetchEntities);
  const title =
    manuscript?.writersGateOffer.typeCode === 'idea'
      ? 'エピソードアイデア'
      : manuscript?.writersGateOffer.typeCode === 'plot'
      ? 'エピソードプロット'
      : manuscript?.writersGateOffer.typeCode === 'episode'
      ? 'エピソード'
      : '';
  return (
    <Layout
      title={title}
      navigation={navigation}
      back={true}
      enabledUserSelect={true}>
      {manuscript &&
        (manuscript.writersGateOffer.typeCode === 'idea' ? (
          <DetailIdea manuscript={manuscript} />
        ) : manuscript.writersGateOffer.typeCode === 'plot' ? (
          <DetailPlot manuscript={manuscript} />
        ) : manuscript.writersGateOffer.typeCode === 'episode' ? (
          <DetailEpisode manuscript={manuscript} />
        ) : null)}
    </Layout>
  );
};

export default React.memo(Show);
