import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const DescriptiveTextIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 19;
  const color = props.color || 'black';
  return <Icon name={'descriptiveText'} size={size} color={color} />;
};

export default React.memo(DescriptiveTextIcon);
