import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import CoverImageForm from '../../../domain/forms/CoverImageForm';

export default function reducer(
  state: CoverImageForm | null = null,
  action: AnyAction,
): CoverImageForm | null {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return null;
    case Types.CreateCoverImageForm:
      return reducerCreateCoverImageForm(state, action);
    case Types.UpdateCoverImageForm:
      return reducerUpdateCoverImageForm(state, action);
    default:
      return state;
  }
}

function reducerCreateCoverImageForm(
  state: CoverImageForm | null = null,
  action: AnyAction,
): CoverImageForm | null {
  const coverImageForm = new CoverImageForm();
  const {
    type,
    hasImage,
    originalImageUrl,
    image,
    backgroundImageUri,
    characterImageUri,
    uploadedSelfImage,
    imageTextInfoList,
  } = action.payload;
  if (type !== undefined) {
    coverImageForm.type = type;
  }
  if (hasImage !== undefined) {
    coverImageForm.hasImage = hasImage;
  }
  if (originalImageUrl !== undefined) {
    coverImageForm.originalImageUrl = originalImageUrl;
  }
  if (image !== undefined) {
    coverImageForm.image = image;
  }
  if (backgroundImageUri !== undefined) {
    coverImageForm.backgroundImageUri = backgroundImageUri;
  }
  if (characterImageUri !== undefined) {
    coverImageForm.characterImageUri = characterImageUri;
  }
  if (uploadedSelfImage !== undefined) {
    coverImageForm.uploadedSelfImage = uploadedSelfImage;
  }
  if (imageTextInfoList !== undefined) {
    coverImageForm.imageTextInfoList = imageTextInfoList;
  }
  return coverImageForm;
}

function reducerUpdateCoverImageForm(
  state: CoverImageForm | null = null,
  action: AnyAction,
): CoverImageForm | null {
  const currentStoryForm = state;
  const coverImageForm = new CoverImageForm();
  if (currentStoryForm) {
    coverImageForm.type = currentStoryForm.type;
    coverImageForm.hasImage = currentStoryForm.hasImage;
    coverImageForm.originalImageUrl = currentStoryForm.originalImageUrl;
    coverImageForm.image = currentStoryForm.image;
    coverImageForm.backgroundImageUri = currentStoryForm.backgroundImageUri;
    coverImageForm.characterImageUri = currentStoryForm.characterImageUri;
    coverImageForm.uploadedSelfImage = currentStoryForm.uploadedSelfImage;
    coverImageForm.imageTextInfoList = currentStoryForm.imageTextInfoList;
  }
  const {
    type,
    hasImage,
    originalImageUrl,
    image,
    backgroundImageUri,
    characterImageUri,
    uploadedSelfImage,
    imageTextInfoList,
  } = action.payload;
  if (type !== undefined) {
    coverImageForm.type = type;
  }
  if (hasImage !== undefined) {
    coverImageForm.hasImage = hasImage;
  }
  if (originalImageUrl !== undefined) {
    coverImageForm.originalImageUrl = originalImageUrl;
  }
  if (image !== undefined) {
    coverImageForm.image = image;
  }
  if (backgroundImageUri !== undefined) {
    coverImageForm.backgroundImageUri = backgroundImageUri;
  }
  if (characterImageUri !== undefined) {
    coverImageForm.characterImageUri = characterImageUri;
  }
  if (uploadedSelfImage !== undefined) {
    coverImageForm.uploadedSelfImage = uploadedSelfImage;
  }
  if (imageTextInfoList !== undefined) {
    coverImageForm.imageTextInfoList = imageTextInfoList;
  }
  return coverImageForm;
}
