import BackgroundMapper, {
  BackgroundData,
} from '../../../entities/mappers/BackgroundMapper';

import BackgroundShowSceneCommand from '../../../../domain/value_objects/scene_commands/BackgroundShowSceneCommand';

export interface BackgroundShowSceneCommandData {
  type: string;
  background: BackgroundData;
}

export default class BackgroundShowSceneCommandMapper {
  private backgroundMapper: BackgroundMapper;

  constructor() {
    this.backgroundMapper = new BackgroundMapper();
  }

  public map(obj: BackgroundShowSceneCommandData): BackgroundShowSceneCommand {
    const background = this.backgroundMapper.map(obj.background);
    return new BackgroundShowSceneCommand(background);
  }
}
