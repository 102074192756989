import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import UserExtensionMissionMapper, {
  UserExtensionMissionData,
} from '../../entities/mappers/writer/UserExtensionMissionMapper';

import UserExtensionMission from '../../../domain/entities/writer/UserExtensionMission';
import UserExtensionMissionsRepository from '../../../domain/repositories/writer/UserExtensionMissionsRepository';

export default class NetUserExtensionMissionsRepository
  extends NetResourcesRepository<UserExtensionMission, UserExtensionMissionData>
  implements UserExtensionMissionsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/user_extension_missions',
        new UserExtensionMissionMapper(),
      ),
    );
  }
}
