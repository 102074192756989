import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import TextFrame from '../../../domain/entities/TextFrame';

export default function reducer(
  state: {[key: number]: TextFrame} = {},
  action: AnyAction,
): {[key: number]: TextFrame} {
  switch (action.type) {
    case Types.IndexTextFramesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: TextFrame}, record: TextFrame) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.ShowTextFrameSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
