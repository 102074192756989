import EntityMapper from './EntityMapper';

import EpisodeComment from '../../../domain/entities/EpisodeComment';

import EpisodeTipMapper, {EpisodeTipData} from './EpisodeTipMapper';
import EpisodeReactionMapper, {
  EpisodeReactionData,
} from './EpisodeReactionMapper';
import EpisodeCommentReplyMapper, {
  EpisodeCommentReplyData,
} from './EpisodeCommentReplyMapper';

import UserMapper, {UserData} from './consumer/UserMapper';

export interface EpisodeCommentData {
  id: number;
  episode_id: number;
  comment: string;
  spoiled: boolean;
  user_episode_comment_likes_count: number;
  display_on_story_page: boolean;
  created_at: string;
  updated_at: string;
  consumer_user: UserData;
  consumer_user_episode_tip?: EpisodeTipData | null;
  consumer_user_episode_reaction?: EpisodeReactionData | null;
  consumer_user_episode_comment_reply?: EpisodeCommentReplyData | null;
}

export default class EpisodeCommentMapper
  implements EntityMapper<EpisodeCommentData, EpisodeComment>
{
  private episodeTipMapper: EpisodeTipMapper;
  private episodeReactionMapper: EpisodeReactionMapper;
  private userMapper: UserMapper;
  private episodeCommentReplyMapper: EpisodeCommentReplyMapper;

  constructor() {
    this.episodeTipMapper = new EpisodeTipMapper();
    this.episodeReactionMapper = new EpisodeReactionMapper();
    this.episodeCommentReplyMapper = new EpisodeCommentReplyMapper();
    this.userMapper = new UserMapper();
  }

  public map(obj: EpisodeCommentData): EpisodeComment {
    return new EpisodeComment(
      obj.id,
      obj.episode_id,
      obj.comment,
      obj.spoiled,
      obj.user_episode_comment_likes_count,
      obj.display_on_story_page,
      new Date(obj.created_at),
      new Date(obj.updated_at),
      this.userMapper.map(obj.consumer_user),
      obj.consumer_user_episode_tip
        ? this.episodeTipMapper.map(obj.consumer_user_episode_tip)
        : null,
      obj.consumer_user_episode_reaction
        ? this.episodeReactionMapper.map(obj.consumer_user_episode_reaction)
        : null,
      obj.consumer_user_episode_comment_reply
        ? this.episodeCommentReplyMapper.map(
            obj.consumer_user_episode_comment_reply,
          )
        : null,
    );
  }
}
