import * as React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ScaleModal from './ScaleModal';

import PrimaryButton from '../buttons/PrimaryButton';
import DimensionContext from '../dimension/DimensionContext';

interface Props {
  visible: boolean;
  title: string;
  description?: React.ReactNode | string;
  source?: ImageSourcePropType;
  imageStyle?: ImageStyle;
  buttonText?: string;
  onCloseModal: () => void;
}

const TooltipModal: React.FunctionComponent<Props> = props => {
  const {
    visible,
    title,
    description,
    source,
    imageStyle,
    buttonText,
    onCloseModal,
  } = props;
  return (
    <DimensionContext.Consumer>
      {context => {
        return (
          <ScaleModal
            style={{width: context.content.width * 0.82}}
            visible={visible}
            onCloseModal={onCloseModal}>
            <View style={styles.modal}>
              {title ? <Text style={styles.title}>{title}</Text> : null}
              {description && (
                <Text style={styles.description}>{description}</Text>
              )}
              {source ? (
                <View style={styles.image}>
                  <Image
                    style={imageStyle}
                    source={source}
                    resizeMode={'contain'}
                  />
                </View>
              ) : null}
              <View style={styles.button}>
                <PrimaryButton buttonSize={BUTTON_SIZE} onPress={onCloseModal}>
                  {buttonText || 'OK'}
                </PrimaryButton>
              </View>
            </View>
          </ScaleModal>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(TooltipModal);

const BUTTON_SIZE = {width: 160, height: 46};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  modal: {
    marginVertical: 24,
    marginHorizontal: 16,
  } as ViewStyle,
  title: {
    color: '#ff8f13',
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 8,
    alignSelf: 'center',
  } as TextStyle,
  description: {
    color: '#222222',
    fontSize: 12,
    lineHeight: 18,
    marginVertical: 8,
  } as TextStyle,
  image: {
    alignItems: 'center',
    marginVertical: 8,
  } as ViewStyle,
});
