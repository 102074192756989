import * as React from 'react';

import DefaultList from '../../shared/lists/DefaultList';

import Setting, {getSetting} from '../../../view_models/Setting';

import WriterCurrentUser from '../../../../domain/entities/writer/CurrentUser';
import WriterCurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

interface Props {
  currentUser: WriterCurrentUser | null;
  currentUserStatus: WriterCurrentUserStatus | null;
  onSelectSetting: (setting: Setting) => void;
}

export default class SettingList extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {onSelectSetting} = this.props;
    return (
      <DefaultList
        data={this.filterSettings()}
        renderTitle={this.renderTitle}
        onSelectItem={onSelectSetting}
      />
    );
  }

  private renderTitle = (item: Setting): string => {
    return `${item}`;
  };

  private filterSettings = () => {
    const {currentUser, currentUserStatus} = this.props;
    let data = getSetting();
    if (!currentUserStatus?.transfersCount) {
      data = data.filter(item => item !== Setting.SETTINGS_TRANSFER_HISTORY);
    }
    if (currentUser && currentUser.enabled) {
      if (
        currentUserStatus?.serviceAgreementCodes.includes('partner_program')
      ) {
        return data;
      } else {
        return data.filter(
          item => item !== Setting.PARTNER_PROGRAM_POLICIES_TERMS,
        );
      }
    } else if (currentUser) {
      return data.filter(
        item =>
          item !== Setting.SETTINGS_ACCOUNT &&
          item !== Setting.DELETE_ACCOUNT &&
          item !== Setting.PARTNER_PROGRAM_POLICIES_TERMS,
      );
    } else {
      return data.filter(
        item =>
          item !== Setting.SETTINGS_ACCOUNT &&
          item !== Setting.SETTINGS_BILLING_PAYMENT_METHODS &&
          item !== Setting.ACCOUNT_BILLING_HISTORY &&
          item !== Setting.DELETE_ACCOUNT &&
          item !== Setting.CONTACTS &&
          item !== Setting.SETTINGS_NOTIFICATIONS &&
          item !== Setting.PARTNER_PROGRAM_POLICIES_TERMS &&
          item !== Setting.LOGOUT,
      );
    }
  };
}
