import {Dispatch} from 'redux';

import Types from '../Types';

import SoundUsageHistory from '../../../domain/entities/SoundUsageHistory';

import NetSoundUsageHistoriesRepository from '../../../data/repositories/writer/NetSoundUsageHistoriesRepository';

export interface Params {
  storyId: number;
  soundType: 'background_music' | 'sound_effect';
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<SoundUsageHistory> {
  return async (dispatch: Dispatch<any>): Promise<SoundUsageHistory> => {
    try {
      const payload = await new NetSoundUsageHistoriesRepository().update(
        id,
        params,
      );
      dispatch({
        payload,
        type: Types.UpdateSoundUsageHistorySuccess,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateSoundUsageHistoryFailure});
      throw err;
    }
  };
}
