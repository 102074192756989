import * as React from 'react';

import ManualCategoryList from './partials/ManualCategoryList';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';

import {linkToManual} from '../../routers';

import {Params as ManualCategoryIndexParams} from '../../actions/writer/manual_categories';

import ManualCategory from '../../../domain/entities/writer/ManualCategory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  manualCategoriesParams: ManualCategoryIndexParams;
  manualCategories: ManualCategory[] | null;
}

export interface DispatchProps {
  indexManualCategories: (
    params: ManualCategoryIndexParams,
  ) => Promise<PaginatedResult<ManualCategory>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {manualCategories, indexManualCategories, manualCategoriesParams} =
      this.props;
    if (!manualCategories) {
      indexManualCategories(manualCategoriesParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, manualCategories} = this.props;
    return (
      <Layout
        title={'マニュアル'}
        navigation={navigation}
        scrollable={false}
        back={true}>
        {manualCategories && (
          <ManualCategoryList
            manualCategories={manualCategories}
            onSelectManualCategory={this.handleSelectManualCategory}
          />
        )}
      </Layout>
    );
  }

  private handleSelectManualCategory = (manualCategory: ManualCategory) => {
    const {navigation} = this.props;
    linkToManual(navigation, {writerManualCategoryId: manualCategory.id});
  };
}
