import * as React from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import Select from '../../../../../shared/forms/Select';

import EffectCategory from '../../../../../../../domain/entities/EffectCategory';

type ItemValue = number | string;

interface Props {
  value: ItemValue | null;
  effectCategories: EffectCategory[] | null;
  onValueChange: (itemValue: ItemValue, itemPosition: number) => void;
}

const EffectCategorySelect: React.FunctionComponent<Props> = props => {
  const {effectCategories, value, onValueChange} = props;
  const filteredEffectCategories = (effectCategories || []).filter(
    effectCategory => effectCategory.baseEffectCount > 0,
  );
  if (filteredEffectCategories.length === 0) {
    return null;
  }
  return (
    <View style={styles.container}>
      <View style={styles.selectWrapper}>
        <Select
          style={styles.select}
          items={[{label: 'すべて', value: 0}].concat(
            filteredEffectCategories.map(effectCategory => {
              return {
                label: effectCategory.name,
                value: effectCategory.id,
              };
            }),
          )}
          value={value}
          confirmText={'決定'}
          cancelText={'キャンセル'}
          rightIcon={<View style={styles.arrowIcon} />}
          onValueChange={onValueChange}
        />
      </View>
    </View>
  );
};

export default React.memo(EffectCategorySelect);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 14,
  } as ViewStyle,
  title: {
    marginHorizontal: 16,
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  selectWrapper: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 16,
    height: 30,
  } as ViewStyle,
  rangeSymbol: {
    marginHorizontal: 16,
    alignSelf: 'center',
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
  select: {
    flex: 1,
    justifyContent: 'center',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  },
});
