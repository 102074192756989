import CharacterHideSceneCommandForm from '../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import CharacterShowSceneCommandForm from '../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import SceneCommandForm from '../../domain/forms/scene_commands/SceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';

export default class CompositeSequenceSceneCommandFormFactory {
  public static add(
    sceneCommandForm: SceneCommandForm,
    addSceneCommandForm: SceneCommandForm,
    options?: {insertIndex?: number},
  ): CompositeSequenceSceneCommandForm {
    if (
      addSceneCommandForm instanceof CharacterHideSceneCommandForm ||
      addSceneCommandForm instanceof CharacterShowSceneCommandForm ||
      addSceneCommandForm instanceof CharacterUpdateSceneCommandForm
    ) {
      return CompositeSequenceSceneCommandFormFactory.addCharacterSceneCommandForm(
        sceneCommandForm,
        addSceneCommandForm,
        options,
      );
    } else {
      if (
        sceneCommandForm instanceof CharacterHideSceneCommandForm ||
        sceneCommandForm instanceof CharacterShowSceneCommandForm ||
        sceneCommandForm instanceof CharacterUpdateSceneCommandForm
      ) {
        return new CompositeSequenceSceneCommandForm(
          [
            new CompositeParallelSceneCommandForm([sceneCommandForm.copy()]),
            addSceneCommandForm,
          ],
          sceneCommandForm.sceneCommandId,
        );
      } else if (
        sceneCommandForm instanceof CompositeParallelSceneCommandForm
      ) {
        return new CompositeSequenceSceneCommandForm(
          [sceneCommandForm.copy(), addSceneCommandForm],
          sceneCommandForm.sceneCommandId,
        );
      } else if (
        sceneCommandForm instanceof CompositeSequenceSceneCommandForm
      ) {
        const commandForms =
          CompositeSequenceSceneCommandFormFactory.insertIntoCompositeSequenceSceneCommandForm(
            sceneCommandForm,
            addSceneCommandForm,
            options?.insertIndex,
          );
        options?.insertIndex === undefined
          ? [...sceneCommandForm.commandForms, addSceneCommandForm]
          : [
              ...sceneCommandForm.commandForms.slice(0, options.insertIndex),
              addSceneCommandForm,
              ...sceneCommandForm.commandForms.slice(options.insertIndex),
            ];
        return new CompositeSequenceSceneCommandForm(
          commandForms,
          sceneCommandForm.sceneCommandId,
        );
      } else if (
        sceneCommandForm instanceof DescriptiveTextShowSceneCommandForm ||
        sceneCommandForm instanceof
          FullScreenIllustrationShowSceneCommandForm ||
        sceneCommandForm instanceof IllustrationShowSceneCommandForm ||
        sceneCommandForm instanceof EffectShowSceneCommandForm ||
        sceneCommandForm instanceof SoundEffectShowSceneCommandForm ||
        sceneCommandForm instanceof BackgroundMusicShowSceneCommandForm ||
        sceneCommandForm instanceof BackgroundMusicHideSceneCommandForm ||
        sceneCommandForm instanceof SpeechTextShowSceneCommandForm
      ) {
        return new CompositeSequenceSceneCommandForm(
          [sceneCommandForm.copy(), addSceneCommandForm],
          sceneCommandForm.sceneCommandId,
        );
      } else {
        throw new Error('CompositeSequenceSceneCommandFormFactory.add error');
      }
    }
  }

  public static update = (
    sceneCommandForm: SceneCommandForm,
    updateSceneCommandForm: SceneCommandForm,
  ): SceneCommandForm => {
    if (
      sceneCommandForm.sceneCommandId === updateSceneCommandForm.sceneCommandId
    ) {
      return updateSceneCommandForm;
    } else if (sceneCommandForm instanceof CompositeParallelSceneCommandForm) {
      return new CompositeParallelSceneCommandForm(
        sceneCommandForm.commandForms.map(commandForm => {
          if (
            commandForm.sceneCommandId ===
              updateSceneCommandForm.sceneCommandId &&
            (updateSceneCommandForm instanceof CharacterHideSceneCommandForm ||
              updateSceneCommandForm instanceof CharacterShowSceneCommandForm ||
              updateSceneCommandForm instanceof CharacterUpdateSceneCommandForm)
          ) {
            return updateSceneCommandForm;
          } else {
            return commandForm;
          }
        }),
        sceneCommandForm.sceneCommandId,
      );
    } else if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      return new CompositeSequenceSceneCommandForm(
        sceneCommandForm.commandForms.map(commandForm => {
          if (
            commandForm.sceneCommandId === updateSceneCommandForm.sceneCommandId
          ) {
            return updateSceneCommandForm;
          } else if (commandForm instanceof CompositeParallelSceneCommandForm) {
            return new CompositeParallelSceneCommandForm(
              commandForm.commandForms.map(subCommandForm => {
                if (
                  subCommandForm.sceneCommandId ===
                    updateSceneCommandForm.sceneCommandId &&
                  (updateSceneCommandForm instanceof
                    CharacterHideSceneCommandForm ||
                    updateSceneCommandForm instanceof
                      CharacterShowSceneCommandForm ||
                    updateSceneCommandForm instanceof
                      CharacterUpdateSceneCommandForm)
                ) {
                  return updateSceneCommandForm;
                } else {
                  return subCommandForm;
                }
              }),
            );
          } else {
            return commandForm;
          }
        }),
        sceneCommandForm.sceneCommandId,
      );
    } else {
      throw new Error('CompositeSequenceSceneCommandFormFactory.update error');
    }
  };

  public static remove = (
    sceneCommandForm: SceneCommandForm,
    removeSceneCommandForm: SceneCommandForm,
  ): SceneCommandForm | null => {
    if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      const headSceneCommandForm = sceneCommandForm.commandForms[0];
      const restSceneCommandForms = sceneCommandForm.commandForms
        .slice(1, sceneCommandForm.commandForms.length)
        .filter(
          commandForm =>
            commandForm.sceneCommandId !==
            removeSceneCommandForm.sceneCommandId,
        );
      if (
        headSceneCommandForm instanceof CharacterHideSceneCommandForm ||
        headSceneCommandForm instanceof CharacterShowSceneCommandForm ||
        headSceneCommandForm instanceof CharacterUpdateSceneCommandForm
      ) {
        if (
          headSceneCommandForm.sceneCommandId ===
          removeSceneCommandForm.sceneCommandId
        ) {
          if (restSceneCommandForms.length === 0) {
            return null;
          } else if (restSceneCommandForms.length === 1) {
            const tailSceneCommandForm = restSceneCommandForms[0];
            return tailSceneCommandForm.copy(sceneCommandForm.sceneCommandId);
          } else {
            return new CompositeSequenceSceneCommandForm(
              restSceneCommandForms,
              sceneCommandForm.sceneCommandId,
            );
          }
        } else {
          return new CompositeSequenceSceneCommandForm(
            [headSceneCommandForm, ...restSceneCommandForms],
            sceneCommandForm.sceneCommandId,
          );
        }
      } else if (
        headSceneCommandForm instanceof CompositeParallelSceneCommandForm
      ) {
        const commandForms = headSceneCommandForm.commandForms.filter(
          commandForm =>
            commandForm.sceneCommandId !==
            removeSceneCommandForm.sceneCommandId,
        );
        if (commandForms.length === 0) {
          if (restSceneCommandForms.length === 0) {
            return null;
          } else {
            return new CompositeSequenceSceneCommandForm(
              restSceneCommandForms,
              sceneCommandForm.sceneCommandId,
            );
          }
        } else {
          return new CompositeSequenceSceneCommandForm(
            [
              new CompositeParallelSceneCommandForm(commandForms),
              ...restSceneCommandForms,
            ],
            sceneCommandForm.sceneCommandId,
          );
        }
      } else {
        const commandForms = sceneCommandForm.commandForms.filter(
          commandForm =>
            commandForm.sceneCommandId !==
            removeSceneCommandForm.sceneCommandId,
        );
        if (commandForms.length === 0) {
          return null;
        } else {
          return new CompositeSequenceSceneCommandForm(
            commandForms,
            sceneCommandForm.sceneCommandId,
          );
        }
      }
    } else if (sceneCommandForm instanceof CompositeParallelSceneCommandForm) {
      const commandForms = sceneCommandForm.commandForms.filter(
        commandForm =>
          commandForm.sceneCommandId !== removeSceneCommandForm.sceneCommandId,
      );
      if (commandForms.length === 0) {
        return null;
      } else {
        return new CompositeParallelSceneCommandForm(
          commandForms,
          sceneCommandForm.sceneCommandId,
        );
      }
    } else {
      if (
        sceneCommandForm.sceneCommandId !==
        removeSceneCommandForm.sceneCommandId
      ) {
        return sceneCommandForm;
      } else {
        return null;
      }
    }
  };

  private static addCharacterSceneCommandForm(
    sceneCommandForm: SceneCommandForm,
    characterSceneCommandForm:
      | CharacterHideSceneCommandForm
      | CharacterShowSceneCommandForm
      | CharacterUpdateSceneCommandForm,
    options?: {insertIndex?: number},
  ): CompositeSequenceSceneCommandForm {
    if (
      sceneCommandForm instanceof CharacterHideSceneCommandForm ||
      sceneCommandForm instanceof CharacterShowSceneCommandForm ||
      sceneCommandForm instanceof CharacterUpdateSceneCommandForm
    ) {
      if (
        sceneCommandForm instanceof CharacterShowSceneCommandForm &&
        characterSceneCommandForm instanceof CharacterShowSceneCommandForm
      ) {
        characterSceneCommandForm.waiting = sceneCommandForm.waiting;
      }
      return new CompositeSequenceSceneCommandForm(
        [
          CompositeSequenceSceneCommandFormFactory.buildCompositeParallelSceneCommandForm(
            [sceneCommandForm.copy()],
            characterSceneCommandForm,
          ),
        ],
        sceneCommandForm.sceneCommandId,
      );
    } else if (sceneCommandForm instanceof CompositeParallelSceneCommandForm) {
      const firstCommandForm = sceneCommandForm.commandForms[0];
      if (
        firstCommandForm instanceof CharacterShowSceneCommandForm &&
        characterSceneCommandForm instanceof CharacterShowSceneCommandForm
      ) {
        characterSceneCommandForm.waiting = firstCommandForm.waiting;
      }
      return new CompositeSequenceSceneCommandForm(
        [
          CompositeSequenceSceneCommandFormFactory.buildCompositeParallelSceneCommandForm(
            sceneCommandForm.commandForms.map(commandForm =>
              commandForm.copy(),
            ),
            characterSceneCommandForm,
          ),
        ],
        sceneCommandForm.sceneCommandId,
      );
    } else if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      if (options?.insertIndex === undefined) {
        const headSceneCommandForm = sceneCommandForm.commandForms[0];
        let restSceneCommandForms = sceneCommandForm.commandForms.slice(
          1,
          sceneCommandForm.commandForms.length,
        );
        let newHeadSceneCommandForm = new CompositeParallelSceneCommandForm([
          characterSceneCommandForm,
        ]);
        if (
          headSceneCommandForm instanceof CharacterHideSceneCommandForm ||
          headSceneCommandForm instanceof CharacterShowSceneCommandForm ||
          headSceneCommandForm instanceof CharacterUpdateSceneCommandForm
        ) {
          if (
            headSceneCommandForm instanceof CharacterShowSceneCommandForm &&
            characterSceneCommandForm instanceof CharacterShowSceneCommandForm
          ) {
            characterSceneCommandForm.waiting = headSceneCommandForm.waiting;
          }
          newHeadSceneCommandForm =
            CompositeSequenceSceneCommandFormFactory.buildCompositeParallelSceneCommandForm(
              [headSceneCommandForm],
              characterSceneCommandForm,
            );
        } else if (
          headSceneCommandForm instanceof CompositeParallelSceneCommandForm
        ) {
          const firstCommandForm = headSceneCommandForm.commandForms[0];
          if (
            firstCommandForm instanceof CharacterShowSceneCommandForm &&
            characterSceneCommandForm instanceof CharacterShowSceneCommandForm
          ) {
            characterSceneCommandForm.waiting = firstCommandForm.waiting;
          }
          newHeadSceneCommandForm =
            CompositeSequenceSceneCommandFormFactory.buildCompositeParallelSceneCommandForm(
              headSceneCommandForm.commandForms.map(commandForm =>
                commandForm.copy(),
              ),
              characterSceneCommandForm,
            );
        } else {
          restSceneCommandForms = sceneCommandForm.commandForms;
        }
        return new CompositeSequenceSceneCommandForm(
          [newHeadSceneCommandForm, ...restSceneCommandForms],
          sceneCommandForm.sceneCommandId,
        );
      } else {
        return new CompositeSequenceSceneCommandForm(
          CompositeSequenceSceneCommandFormFactory.insertIntoCompositeSequenceSceneCommandForm(
            sceneCommandForm,
            characterSceneCommandForm,
            options.insertIndex,
          ),
          sceneCommandForm.sceneCommandId,
        );
      }
    } else if (
      sceneCommandForm instanceof DescriptiveTextShowSceneCommandForm ||
      sceneCommandForm instanceof FullScreenIllustrationShowSceneCommandForm ||
      sceneCommandForm instanceof IllustrationShowSceneCommandForm ||
      sceneCommandForm instanceof EffectShowSceneCommandForm ||
      sceneCommandForm instanceof SoundEffectShowSceneCommandForm ||
      sceneCommandForm instanceof BackgroundMusicShowSceneCommandForm ||
      sceneCommandForm instanceof BackgroundMusicHideSceneCommandForm ||
      sceneCommandForm instanceof SpeechTextShowSceneCommandForm
    ) {
      return new CompositeSequenceSceneCommandForm(
        [
          new CompositeParallelSceneCommandForm([characterSceneCommandForm]),
          sceneCommandForm.copy(),
        ],
        sceneCommandForm.sceneCommandId,
      );
    } else {
      throw new Error(
        'CompositeSequenceSceneCommandFormFactory.addCharacterSceneCommandForm error',
      );
    }
  }

  private static buildCompositeParallelSceneCommandForm = (
    sceneCommandForms: Array<
      | CharacterHideSceneCommandForm
      | CharacterShowSceneCommandForm
      | CharacterUpdateSceneCommandForm
    >,
    characterSceneCommandForm:
      | CharacterHideSceneCommandForm
      | CharacterShowSceneCommandForm
      | CharacterUpdateSceneCommandForm,
  ): CompositeParallelSceneCommandForm => {
    const filteredSceneCommandForms = sceneCommandForms.filter(
      sceneCommandForm =>
        sceneCommandForm.position !== characterSceneCommandForm.position,
    );
    return new CompositeParallelSceneCommandForm([
      ...filteredSceneCommandForms,
      characterSceneCommandForm,
    ]);
  };

  private static insertIntoCompositeSequenceSceneCommandForm = (
    sceneCommandForm: CompositeSequenceSceneCommandForm,
    addSceneCommandForm: SceneCommandForm,
    insertIndex?: number,
  ) => {
    return insertIndex === undefined
      ? [...sceneCommandForm.commandForms, addSceneCommandForm]
      : [
          ...sceneCommandForm.commandForms.slice(0, insertIndex),
          addSceneCommandForm,
          ...sceneCommandForm.commandForms.slice(insertIndex),
        ];
  };
}
