import * as React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

import PopularTag from '../../../domain/entities/PopularTag';

interface Props {
  popularTag: PopularTag;
  onSelectPopularTag: (popularTag: PopularTag) => void;
}

const PopularTagButton: React.FunctionComponent<Props> = props => {
  const {popularTag, onSelectPopularTag} = props;
  const onPress = React.useCallback(
    () => onSelectPopularTag(popularTag),
    [popularTag],
  );
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <Text style={styles.label}>{popularTag.tagName}</Text>
    </Pressable>
  );
};

export default React.memo(PopularTagButton);

const styles = StyleSheet.create({
  container: {
    height: 22,
    backgroundColor: '#ffffff',
    borderRadius: 100,
    paddingVertical: 5,
    paddingHorizontal: 12,
    marginRight: 8,
    marginBottom: 8,
    justifyContent: 'center',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: Platform.select({android: 12, default: 13}),
    fontWeight: 'bold',
    lineHeight: Platform.select({android: 13, default: undefined}),
  } as TextStyle,
});
