import * as React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import SoundIcon from '../../../../../shared/icons/SoundIcon';

import Sound from '../../../../../../../domain/entities/Sound';

const soundIcon = <SoundIcon color={'#222'} size={21} />;

interface Props {
  sound: Sound;
  children?: React.ReactNode;
}

const SoundEffectView: React.FunctionComponent<Props> = props => {
  const {sound} = props;
  return (
    <View style={styles.container}>
      <View style={styles.box}>
        {soundIcon}
        <Text style={styles.text}>{sound.name}</Text>
      </View>
    </View>
  );
};

export default React.memo(SoundEffectView);

const width = 343;
const height = 61;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: '100%',
    paddingTop: 24,
    paddingBottom: 16,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
  box: {
    backgroundColor: '#d5d5d5',
    width,
    height,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 8,
  } as TextStyle,
});
