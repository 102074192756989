export const twitterShareUrl = (
  message: string,
  url: string,
  title: string,
) => {
  return `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    url,
  )}&text=${encodeURIComponent(
    normalizeShareText(message),
  )}&hashtags=${encodeURI(hashtags(title))}`;
};

export const facebookShareUrl = (url: string) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url,
  )}`;
};

export const hatenaShareUrl = (url: string) => {
  return `https://b.hatena.ne.jp/add?url=${encodeURIComponent(
    url,
  )}&=mode=${encodeURIComponent('confirm')}`;
};

export const lineShareUrl = (message: string, url: string) => {
  return `http://line.me/R/msg/text/?${encodeURIComponent(
    `${normalizeShareText(message)}\r\n${url}`,
  )}`;
};

const hashtags = (text: string) => {
  return `TapNovel,ゲーム小説,${text.replace(
    /[\s。、\-.,/#!$%^&*;:{}=\-_`~()]/g,
    '',
  )}`;
};

const normalizeShareText = (text: string) => {
  return `${text} | TapNovel`;
};
