import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import Illustration from '../../entities/Illustration';
import Sound from '../../entities/Sound';

const CLASS_NAME: SceneCommandFormClass = 'IllustrationShowSceneCommandForm';

export default class IllustrationShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(
    public illustration: Illustration,
    public sound?: Sound | null,
    public startTime?: number | null,
    public endTime?: number | null,
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      illustration_id: this.illustration.id,
      sound_id: this.sound?.id,
      start_time: this.startTime,
      end_time: this.endTime,
      type: 'SceneCommands::IllustrationShowSceneCommand',
    };
  }

  public copy(sceneCommandId?: number): IllustrationShowSceneCommandForm {
    return new IllustrationShowSceneCommandForm(
      this.illustration,
      this.sound,
      this.startTime,
      this.endTime,
      sceneCommandId,
    );
  }
}
