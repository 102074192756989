import * as React from 'react';

import BaseIndex from '../../common/descriptive_texts/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTextsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as TextFrameIndexParams} from '../../../../../actions/text_frames/index';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import CurrentUserStatus from '../../../../../../domain/entities/writer/CurrentUserStatus';
import TextFrame from '../../../../../../domain/entities/TextFrame';
import Voice from '../../../../../../domain/entities/Voice';
import Sound from '../../../../../../domain/entities/Sound';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  textFrameId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  text?: string;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTextsRouteProp;
  currentUserStatus: CurrentUserStatus | null;
  sceneForm: SceneForm | null;
  textFrames: TextFrame[] | null;
  textFramesParams: TextFrameIndexParams;
  parentSceneCommandForm: SceneCommandForm | null;
}

export interface DispatchProps {
  indexTextFrames: (
    params: TextFrameIndexParams,
  ) => Promise<PaginatedResult<TextFrame>>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    const {
      route,
      textFrames,
      textFramesParams,
      currentUserStatus,
      indexTextFrames,
    } = this.props;
    const text = route.params.text || '';
    const textFrameId = route.params.textFrameId || 1;
    return (
      <BaseIndex
        {...this.props}
        text={text}
        textFrames={textFrames}
        textFramesParams={textFramesParams}
        enableVoice={currentUserStatus?.extensionsCodes.includes('voice')}
        indexTextFrames={indexTextFrames}
        textFrameId={textFrameId}
        onSelectText={this.handleSelectText}
      />
    );
  }

  private handleSelectText = (
    text: string,
    textFrameId: number,
    voice: Voice | null,
    sound: Sound | null,
    startTime?: number | null,
    endTime?: number | null,
  ) => {
    const {
      navigation,
      route,
      textFrames,
      parentSceneCommandForm,
      createSceneCommandForm,
      updateSceneCommandForm,
    } = this.props;
    const {sceneCommandIndex, subSceneCommandIndex} = route.params;
    const textFrame = textFrames?.find(o => o.id === textFrameId);
    if (!textFrame) {
      return;
    }
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const sceneCommandForm = new DescriptiveTextShowSceneCommandForm(
      textFrame,
      text,
      voice,
      sound,
      startTime || null,
      endTime || null,
    );
    if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.add(
          parentSceneCommandForm,
          sceneCommandForm,
          subSceneCommandIndex !== undefined
            ? {insertIndex: subSceneCommandIndex}
            : undefined,
        ),
      });
    } else {
      createSceneCommandForm({
        index: sceneCommandIndex,
        sceneCommandForm,
      });
    }
    (navigation.getParent() || navigation).goBack();
  };
}
