import * as React from 'react';

import BaseIndex from '../../shared/generics/background_show_scene_command/base_background_search_form/Index';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormBaseBackgroundSearchFormRouteProp} from '../../../navigators/RouteProps';

import {Params as BackgroundCategoryIndexParams} from '../../../actions/background_categories/index';
import {Params as BaseBackgroundIndexParams} from '../../../actions/base_backgrounds/index';

import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import BaseBackground from '../../../../domain/entities/BaseBackground';
import BackgroundCategory from '../../../../domain/entities/BackgroundCategory';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  query?: string;
  selectedLargeBackgroundCategoryId?: number | null;
  selectedMiddleBackgroundCategoryId?: number | null;
  selectedSmallBackgroundCategoryId?: number | null;
  hideBack?: boolean;
  favorite?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormBaseBackgroundSearchFormRouteProp;
  coverImageForm: CoverImageForm | null;
  largeBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  largeBackgroundCategories: BackgroundCategory[] | null;
  middleBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  middleBackgroundCategories: BackgroundCategory[] | null;
  smallBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  smallBackgroundCategories: BackgroundCategory[] | null;
}

export interface DispatchProps {
  indexBackgroundCategories: (
    params: BackgroundCategoryIndexParams,
  ) => Promise<PaginatedResult<BackgroundCategory>>;
  indexBaseBackgrounds: (
    params: BaseBackgroundIndexParams,
  ) => Promise<PaginatedResult<BaseBackground>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (params: {
    query?: string;
    backgroundCategoryId?: number;
    selectedLargeBackgroundCategoryId?: number | null;
    selectedMiddleBackgroundCategoryId?: number | null;
    selectedSmallBackgroundCategoryId?: number | null;
  }) => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey, favorite} = route.params;
    const {
      query,
      backgroundCategoryId,
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId,
    } = params;
    navigation.navigate('CoverImageFormBaseBackgrounds', {
      storyId,
      aspectRatio,
      imageKey,
      query,
      backgroundCategoryId,
      selectedSmallBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedLargeBackgroundCategoryId,
      favorite,
    });
  };
}
