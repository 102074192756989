import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Index, {DispatchProps, StateProps} from '../../components/home/Index';

import AppState from '../../reducers/AppState';

import showCurrentUser, {
  Params as CurrentUserShowParams,
} from '../../actions/writer/current_user/show';
import indexStories, {
  Params as StoryIndexParams,
} from '../../actions/stories/index';
import destroyStory from '../../actions/stories/destroy';
import createFacebookAccountSession, {
  Params as FacebookAccountSessionCreateParams,
} from '../../actions/writer/social_accounts/facebook_accounts/session/create';
import createLineAccountSession, {
  Params as LineAccountSessionCreateParams,
} from '../../actions/writer/social_accounts/line_accounts/session/create';
import createTwitterAccountSession, {
  Params as TwitterAccountSessionCreateParams,
} from '../../actions/writer/social_accounts/twitter_accounts/session/create';
import showCurrentUserStatus from '../../actions/writer/current_user_status/show';

import selectEntities from '../../helpers/selectEntities';
import selectEntity from '../../helpers/selectEntity';

import {HomeRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: HomeRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, accessToken, writerCurrentUserId} = state;
  const page = route.params ? route.params.page : undefined;
  const serializedStoriesParams: StoryIndexParams = {page, writing: true};
  const serializedStories = selectEntities(
    entities.stories,
    queries.stories,
    serializedStoriesParams,
  );
  const completedStoriesParams: StoryIndexParams = {page, writing: false};
  const completedStories = selectEntities(
    entities.stories,
    queries.stories,
    completedStoriesParams,
  );
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  return {
    accessToken,
    currentUser,
    navigation,
    serializedStories,
    serializedStoriesParams,
    completedStories,
    completedStoriesParams,
    currentUserStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUser: (params?: CurrentUserShowParams) =>
      dispatch(showCurrentUser(params)),
    indexStories: (params: StoryIndexParams) => dispatch(indexStories(params)),
    destroyStory: (id: number) => dispatch(destroyStory(id)),
    createFacebookAccountSession: (
      params: FacebookAccountSessionCreateParams,
    ) => dispatch(createFacebookAccountSession(params)),
    createLineAccountSession: (params: LineAccountSessionCreateParams) =>
      dispatch(createLineAccountSession(params)),
    createTwitterAccountSession: (params: TwitterAccountSessionCreateParams) =>
      dispatch(createTwitterAccountSession(params)),
    showCurrentUserStatus: () => dispatch(showCurrentUserStatus()),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
