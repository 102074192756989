import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Input from '../../../shared/forms/Input';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import {formStyles} from '../../../../styles/variables';

interface Props {
  email: string;
  errorMessages: string;
  disabled?: boolean;
  onChangeEmail: (email: string) => void;
  onSubmit: () => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {email, errorMessages, disabled, onChangeEmail, onSubmit} = props;
  return (
    <View style={styles.container}>
      <Text style={{paddingHorizontal: 16}}>
        アカウント認証を行うメールアドレスをご入力ください。
      </Text>

      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'メールアドレス'}
          errorMessages={errorMessages}
        />
        <Input
          style={errorMessages ? styles.fieldWithErrors : null}
          placeholder={'メールアドレスを入力'}
          textContentType={'emailAddress'}
          value={email}
          onChangeText={onChangeEmail}
          keyboardType="email-address"
          autoCapitalize="none"
          onSubmitEditing={onSubmit}
        />
      </View>
      <View style={{alignItems: 'center', marginTop: 32}}>
        <PrimaryButton disabled={disabled} onPress={onSubmit}>
          認証メールを再送信する
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 40,
  } as ViewStyle,
  fieldWithErrors: {
    backgroundColor: 'rgba(242, 52, 6, 0.3)',
    color: '#999999',
  } as ViewStyle,
});
