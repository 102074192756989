import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import Character from '../../../domain/entities/Character';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexCharactersSuccess: {
      const result = action.payload as PaginatedResult<Character>;
      return mergeResponse(state, result);
    }
    case Types.CreateCharacterSuccess: {
      const character = action.payload as Character;
      const newState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const query = {...state[stateKey]};
        if (stateKey.includes(`"storyId":${character.storyId}`)) {
          query.pageToIds[1] = [...query.pageToIds[1], character.id];
        }
        newState[stateKey] = query;
      });
      return newState;
    }
    default:
      return state;
  }
}
