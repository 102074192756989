import * as React from 'react';

import BaseIndex from '../../common/illustration_usage_histories/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistoriesRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as IllustrationUsageHistoryIndexParams} from '../../../../../actions/illustration_usage_histories/index';
import {Params as IllustrationUsageHistoryUpdateParams} from '../../../../../actions/illustration_usage_histories/update';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import IllustrationUsageHistory from '../../../../../../domain/entities/IllustrationUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  back?: boolean;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistoriesRouteProp;
  sceneForm: SceneForm | null;
  illustrationUsageHistoriesParams: IllustrationUsageHistoryIndexParams;
  illustrationUsageHistories: IllustrationUsageHistory[] | null;
  parentSceneCommandForm: SceneCommandForm | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexIllustrationUsageHistories: (
    params: IllustrationUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<IllustrationUsageHistory>>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateIllustrationUsageHistory: (
    id: number,
    params: IllustrationUsageHistoryUpdateParams,
  ) => Promise<IllustrationUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        onForwardToNewIllustrationUsageHistory={
          this.handleForwardToNewIllustrationUsageHistory
        }
        onSelectIllustrationUsageHistory={
          this.handleSelectIllustrationUsageHistory
        }
        onForwardToFavoriteList={this.handleForwardToFavoriteList}
      />
    );
  }

  private handleSelectIllustrationUsageHistory = (
    illustrationUsageHistory: IllustrationUsageHistory,
  ) => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      createSceneCommandForm,
      updateSceneCommandForm,
      updateIllustrationUsageHistory,
    } = this.props;
    const {
      storyId,
      sceneCommandIndex: index,
      subSceneCommandIndex,
    } = route.params;
    const {illustration} = illustrationUsageHistory;
    const sceneCommandForm = new IllustrationShowSceneCommandForm(illustration);
    if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.add(
          parentSceneCommandForm,
          sceneCommandForm,
          subSceneCommandIndex !== undefined
            ? {insertIndex: subSceneCommandIndex}
            : undefined,
        ),
      });
    } else {
      createSceneCommandForm({
        index,
        sceneCommandForm,
      });
    }
    updateIllustrationUsageHistory(illustration.id, {storyId}).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };

  private handleForwardToNewIllustrationUsageHistory = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    routers.linkToSceneFormIllustrationShowSceneCommandNewIllustrationSearchForm(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
      },
    );
  };

  private handleForwardToFavoriteList = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    routers.linkToSceneFormIllustrationShowSceneCommandNewIllustrations(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        favorite: true,
      },
    );
  };
}
