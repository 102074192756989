import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Edit, {
  DispatchProps,
  StateProps,
} from '../../components/episode_comment_replies/Edit';

import AppState from '../../reducers/AppState';

import showEpisodeCommentReply from '../../actions/episode_comment_replies/show';
import updateEpisodeCommentReply, {
  Params as EpisodeCommentReplyUpdateParams,
} from '../../actions/episode_comment_replies/update';
import showEpisodeComment from '../../actions/episode_comments/show';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showEpisodeCommentReply: (id: number) =>
      dispatch(showEpisodeCommentReply(id)),
    updateEpisodeCommentReply: (
      id: number,
      params: EpisodeCommentReplyUpdateParams,
    ) => dispatch(updateEpisodeCommentReply(id, params)),
    showEpisodeComment: (id: number) => dispatch(showEpisodeComment(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
