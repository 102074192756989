import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import EpisodeListItem from './EpisodeAggregationListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import EpisodeAggregation from '../../../../../domain/value_objects/partner_program/EpisodeAggregation';

interface Props {
  episodeAggregations: Array<EpisodeAggregation> | null;
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const EpisodeAggregationList: React.FunctionComponent<Props> = props => {
  const {episodeAggregations, ListHeaderComponent} = props;
  const keyExtractor = React.useCallback(
    (episodeAggregation: EpisodeAggregation) => `${episodeAggregation.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<EpisodeAggregation>) => (
      <EpisodeListItem episodeAggregation={info.item} />
    ),
    [],
  );
  return (
    <FlatList
      data={episodeAggregations}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(EpisodeAggregationList);
