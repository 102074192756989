import Entity from './Entity';

import Tip from './Tip';

export default class EpisodeTip implements Entity {
  constructor(
    public id: number,
    public writerUserId: number,
    public storyId: number,
    public tip: Tip,
    public createdAt: Date,
  ) {}
}
