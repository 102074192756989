import EntityMapper from './EntityMapper';

import PopularTag from '../../../domain/entities/PopularTag';

export interface PopularTagData {
  id: number;
  tag_name: string;
  screen_name: string;
  taggable_type: string;
  priority: string;
  created_at: string;
  updated_at: string;
}

export default class OauthAccountMapper
  implements EntityMapper<PopularTagData, PopularTag>
{
  public map(obj: PopularTagData): PopularTag {
    return new PopularTag(
      obj.id,
      obj.tag_name,
      obj.taggable_type,
      obj.priority,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
