import * as React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';

import CharacterView from './CharacterView';

import {CharacterSceneCommandForm} from '../../../../../../view_models/SceneFrame';

import convertImageSource from '../../../../../../helpers/convertImageSource';

import Position from '../../../../../../../domain/value_objects/Position';

import leftUri from '../../../../../../../assets/images/character_positions/left.png';
import centerUri from '../../../../../../../assets/images/character_positions/center.png';
import rightUri from '../../../../../../../assets/images/character_positions/right.png';

interface Props {
  characterSceneCommandForm: CharacterSceneCommandForm | null;
  position: Position;
  width: number;
  onSelectPosition: (position: Position) => void;
}

export default class CharacterPosition extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {characterSceneCommandForm, width} = this.props;
    const height = Math.ceil(width * ASPECT_RATIO);
    return (
      <TouchableOpacity
        style={[styles.imageWrapper, {width}]}
        onPress={this.handleSelectLeft}>
        <CharacterView
          width={width}
          characterSceneCommandForm={characterSceneCommandForm}
        />
        <Image
          style={[styles.image, {width, height}]}
          source={this.getSource()}
          resizeMode={'contain'}
        />
      </TouchableOpacity>
    );
  }

  private handleSelectLeft = () => {
    const {onSelectPosition, position} = this.props;
    onSelectPosition(position);
  };

  private getSource = () => {
    const {position} = this.props;
    switch (position) {
      case Position.Left:
        return convertImageSource(leftUri);
      case Position.Center:
        return convertImageSource(centerUri);
      case Position.Right:
        return convertImageSource(rightUri);
    }
  };
}

const ASPECT_RATIO = 290 / 180;

const styles = StyleSheet.create({
  image: {
    marginTop: 12,
  } as ImageStyle,
  imageWrapper: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    margin: 6,
  } as ViewStyle,
});
