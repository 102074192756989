import ImageUrlHelper, {Options} from '../../domain/helpers/ImageUrlHelper';

interface Story {
  hasCoverImage: boolean;
  originalCoverImageUrl: string;
  originalImageUrl: string;
}
export default class StoryCoverImageDecorator {
  constructor(private story: Story) {}

  public getImageUrl(options: Options): string {
    if (this.story.hasCoverImage) {
      return ImageUrlHelper.getImageUrl(
        this.story.originalCoverImageUrl,
        options,
      );
    } else {
      return ImageUrlHelper.getImageUrl(this.story.originalImageUrl, options);
    }
  }
}
