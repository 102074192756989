import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Genre from '../../../domain/entities/Genre';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetGenresRepository from '../../../data/repositories/NetGenresRepository';

const types = {
  failure: Types.IndexGenresFailure,
  request: Types.IndexGenresRequest,
  success: Types.IndexGenresSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Genre>> {
  return indexGenerics(new NetGenresRepository(), types, params);
}
