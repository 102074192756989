import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import Character from '../../../domain/entities/Character';

import NetCharactersRepository from '../../../data/repositories/writer/NetCharactersRepository';

const types = {
  failure: Types.ShowCharacterFailure,
  request: Types.ShowCharacterRequest,
  success: Types.ShowCharacterSuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Character> {
  return showGeneric(new NetCharactersRepository(), types, id);
}
