import * as React from 'react';
import {
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import NoticeIcon from '../../../shared/NoticeIcon';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';

import {storyCoverImageUrl} from '../../../../helpers/images';

import {colors} from '../../../../styles/variables';

import Story from '../../../../../domain/entities/response/Story';

interface Props {
  story: Story;
  onSelectStory: (story: Story) => void;
}

const StoryListItem: React.FunctionComponent<Props> = props => {
  const {story, onSelectStory} = props;
  const handleSelectStory = React.useCallback(() => {
    onSelectStory(story);
  }, [story.id]);
  const source = {
    uri: storyCoverImageUrl(story, 'small'),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  return (
    <Pressable style={styles.container} onPress={handleSelectStory}>
      <View style={styles.body}>
        {story.hasCoverImage ? (
          <FastImageAdapter style={styles.image} source={source} />
        ) : (
          <View style={styles.image}>
            <Text style={styles.noImageText}>表紙なし</Text>
          </View>
        )}
        <View style={styles.storyInfo}>
          <Text style={styles.title}>{story.title}</Text>
          <View style={styles.bottomItem}>
            <Text style={styles.bottomItemKey}>リアクション</Text>
            <Text style={styles.bottomItemValue}>
              {numeral(story.reactionsCount).format('0,0')}
            </Text>
          </View>
          <View style={styles.bottomItem}>
            <Text style={styles.bottomItemKey}>コメント</Text>
            <Text style={styles.bottomItemValue}>
              {numeral(story.commentsCount).format('0,0')}
            </Text>
          </View>
        </View>
      </View>

      {story.confirmed || <NoticeIcon style={styles.noticeIcon} />}
    </Pressable>
  );
};

export default React.memo(StoryListItem);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    flexDirection: 'row',
    margin: 10,
  } as ViewStyle,
  container: {
    backgroundColor: 'white',
  } as ViewStyle,
  header: {
    margin: 10,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  storyInfo: {
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 5,
  } as TextStyle,
  bottomItem: {
    flexDirection: 'row',
    width: '100%',
    paddingRight: 10,
  } as ViewStyle,
  bottomItemKey: {
    flex: 1,
    color: '#383838',
    fontSize: 11,
    alignSelf: 'center',
  } as TextStyle,
  bottomItemValue: {
    flex: 1,
    textAlign: 'right',
    color: '#ff8f13',
    fontSize: 11,
    alignSelf: 'center',
  } as TextStyle,
  noticeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
  } as ViewStyle,
});
