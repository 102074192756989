import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import New, {
  DispatchProps,
  StateProps,
} from '../../../../components/writer/temporary_users/terms_acceptance/New';

import showCurrentUser from '../../../../actions/writer/current_user/show';
import createWriterTemporaryUsersTermsAcceptance from '../../../../actions/writer/temporary_users/terms_acceptance/create';

import AppState from '../../../../reducers/AppState';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUser: () => dispatch(showCurrentUser()),
    createWriterTemporaryUsersTermsAcceptance: () =>
      dispatch(createWriterTemporaryUsersTermsAcceptance()),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
