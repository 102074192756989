import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import EpisodeMapper, {
  EpisodeData,
} from '../../../entities/mappers/response/EpisodeMapper';

import Episode from '../../../../domain/entities/response/Episode';
import EpisodesRepository from '../../../../domain/repositories/writer/response/EpisodesRepository';

export default class NetEpisodesRepository
  extends NetResourcesRepository<Episode, EpisodeData>
  implements EpisodesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/response/episodes',
        new EpisodeMapper(),
      ),
    );
  }
}
