import * as React from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  ViewStyle,
  Platform,
} from 'react-native';

import AnnouncementCategoryListItem from './AnnouncementCategoryListItem';

import shouldUpdateEntity from '../../shared/enhanced/shouldUpdateEntity';
import shouldUpdateEntityList from '../../shared/enhanced/shouldUpdateEntityList';
import ListItemRightSeparator from '../../shared/lists/separators/ListItemRightSeparator';

import AnnouncementCategory from '../../../../domain/entities/writer/AnnouncementCategory';

interface Props {
  announcementCategories: AnnouncementCategory[];
  selectedAnnouncementCategory: AnnouncementCategory;
  onSelectAnnouncementCategory: (
    announcementCategory: AnnouncementCategory,
  ) => void;
}

export default class AnnouncementCategoryList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (
      shouldUpdateEntity(
        {entity: this.props.selectedAnnouncementCategory},
        {entity: nextProps.selectedAnnouncementCategory},
      )
    ) {
      return true;
    }
    return shouldUpdateEntityList(
      {entities: this.props.announcementCategories},
      {entities: nextProps.announcementCategories},
    );
  }

  public render(): React.ReactNode {
    const {announcementCategories} = this.props;
    return (
      <FlatList
        scrollEnabled={Platform.select({web: true, default: false})}
        contentContainerStyle={styles.container}
        data={announcementCategories}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        ItemSeparatorComponent={ListItemRightSeparator}
      />
    );
  }

  private keyExtractor = (
    announcementCategory: AnnouncementCategory,
    index: number,
  ): string => {
    return `${announcementCategory.id}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<AnnouncementCategory>,
  ): React.ReactElement<any> | null => {
    const {selectedAnnouncementCategory, onSelectAnnouncementCategory} =
      this.props;
    const {item: announcementCategory} = info;
    return (
      <AnnouncementCategoryListItem
        announcementCategory={announcementCategory}
        checked={selectedAnnouncementCategory.id === announcementCategory.id}
        onSelectAnnouncementCategory={onSelectAnnouncementCategory}
      />
    );
  };
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    elevation: 1, // Android
    shadowColor: 'black', // iOS
    shadowOffset: {width: 0, height: 3}, // iOS
    shadowOpacity: 0.2, // iOS
    shadowRadius: 2, // iOS
  } as ViewStyle,
});
