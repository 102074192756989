import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/background_show_scene_command/new/backgrounds/Index';

import AppState from '../../../../../reducers/AppState';

import indexBackgroundTimeZones, {
  Params as BackgroundTimeZoneIndexParams,
} from '../../../../../actions/background_time_zones/index';
import updateBackgroundUsageHistory, {
  Params as BackgroundUsageHistoryUpdateParams,
} from '../../../../../actions/background_usage_histories/update';
import indexBackgroundWeathers, {
  Params as BackgroundWeatherIndexParams,
} from '../../../../../actions/background_weathers/index';
import indexBackgrounds, {
  Params as BackgroundIndexParams,
} from '../../../../../actions/backgrounds/index';
import showBaseBackground from '../../../../../actions/base_backgrounds/show';
import updateSceneForm, {
  Params as SceneFormUpdateParams,
} from '../../../../../actions/scene_forms/update';
import updateCoachmarkModal, {
  Params as CoachmarkModalUpdateParams,
} from '../../../../../actions/coachmark_modal/update';

import selectEntities from '../../../../../helpers/selectEntities';
import selectEntity from '../../../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../../../helpers/ResourceAuthorizattion';
import {SceneFormBackgroundShowSceneCommandNewBackgroundsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBackgroundsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms, modals} = state;
  const {baseBackgroundId} = route.params;
  const sceneForm = forms.sceneForm;
  const backgroundTimeZonesParams: BackgroundTimeZoneIndexParams = {};
  const backgroundWeathersParams: BackgroundWeatherIndexParams = {};
  const backgroundsParams: BackgroundIndexParams = {baseBackgroundId};
  const backgroundTimeZones = selectEntities(
    entities.backgroundTimeZones,
    queries.backgroundTimeZones,
    backgroundWeathersParams,
  );
  const backgroundWeathers = selectEntities(
    entities.backgroundWeathers,
    queries.backgroundWeathers,
    backgroundWeathersParams,
  );
  const backgrounds = selectEntities(
    entities.backgrounds,
    queries.backgrounds,
    backgroundsParams,
  );
  const baseBackground = selectEntity(
    entities.baseBackgrounds,
    baseBackgroundId,
  );
  const {coachmarkModal} = modals;
  return {
    backgroundTimeZones,
    backgroundTimeZonesParams,
    backgroundWeathers,
    backgroundWeathersParams,
    backgrounds,
    backgroundsParams,
    baseBackground,
    navigation,
    sceneForm,
    coachmarkModal,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexBackgroundTimeZones: (params: BackgroundTimeZoneIndexParams) =>
      dispatch(indexBackgroundTimeZones(params)),
    indexBackgroundWeathers: (params: BackgroundWeatherIndexParams) =>
      dispatch(indexBackgroundWeathers(params)),
    indexBackgrounds: (params: BackgroundIndexParams) =>
      dispatch(indexBackgrounds(params)),
    showBaseBackground: (id: number) => dispatch(showBaseBackground(id)),
    updateBackgroundUsageHistory: (
      id: number,
      params: BackgroundUsageHistoryUpdateParams,
    ) => dispatch(updateBackgroundUsageHistory(id, params)),
    updateSceneForm: (params: SceneFormUpdateParams) =>
      dispatch(updateSceneForm(params)),
    updateCoachmarkModal: (params: CoachmarkModalUpdateParams) =>
      dispatch(updateCoachmarkModal(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
