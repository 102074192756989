import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import NetStoriesRepository from '../../../data/repositories/writer/NetStoriesRepository';

export default function action(
  id: number,
): (dispatch: Dispatch<any>) => Promise<number> {
  return async (dispatch: Dispatch<any>): Promise<number> => {
    try {
      await new NetStoriesRepository().destroy(id);
      const payload = id;
      dispatch({payload, type: Types.DestroyStorySuccess});
      sendDefaultCustomEvent({
        resourceType: 'story',
        resourceId: id,
        actionName: 'destroy',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.DestroyStoryFailure});
      throw err;
    }
  };
}
