import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import Actor from '../../../domain/entities/Actor';

import NetActorsRepository from '../../../data/repositories/writer/NetActorsRepository';

const types = {
  failure: Types.ShowActorFailure,
  request: Types.ShowActorRequest,
  success: Types.ShowActorSuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Actor> {
  return showGeneric(new NetActorsRepository(), types, id);
}
