import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import BackgroundCategoryMapper, {
  BackgroundCategoryData,
} from '../entities/mappers/BackgroundCategoryMapper';

import BackgroundCategory from '../../domain/entities/BackgroundCategory';
import BackgroundCategoriesRepository from '../../domain/repositories/BackgroundCategoriesRepository';

export default class NetBackgroundCategoriesRepository
  extends NetResourcesRepository<BackgroundCategory, BackgroundCategoryData>
  implements BackgroundCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/background_categories',
        new BackgroundCategoryMapper(),
      ),
    );
  }
}
