import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import EffectUsageHistory from '../../../domain/entities/EffectUsageHistory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetEffectUsageHistoriesRepository from '../../../data/repositories/writer/NetEffectUsageHistoriesRepository';

const types = {
  failure: Types.IndexEffectUsageHistoriesFailure,
  request: Types.IndexEffectUsageHistoriesRequest,
  success: Types.IndexEffectUsageHistoriesSuccess,
};

export interface Params extends BaseParams {
  storyId: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<EffectUsageHistory>> {
  return indexGenerics(new NetEffectUsageHistoriesRepository(), types, params);
}
