import {Dispatch} from 'redux';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Types from '../Types';

import Episode from '../../../domain/entities/Episode';

import NetAutoGenerationEpisodesRepository from '../../../data/repositories/writer/NetAutoGenerationEpisodesRepository';

import EpisodeFormCreateEvent from '../../../domain/services/analytics_particular_events/EpisodeFormCreateEvent';

export interface Params {
  storyId: number;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Episode> {
  return async (dispatch: Dispatch<any>): Promise<Episode> => {
    try {
      const payload = await new NetAutoGenerationEpisodesRepository().create(
        params,
      );
      dispatch({payload, type: Types.CreateEpisodeSuccess});
      sendDefaultCustomEvent({
        resourceType: 'episode',
        resourceId: payload.id,
        resourceName: payload.title,
        actionName: 'create',
      });
      new EpisodeFormCreateEvent().create().then(sameEventsCount => {
        if (sameEventsCount === 1) {
          dispatch({
            payload: 'first_episode_form_created',
            type: Types.UpdateCoachmarkModal,
          });
        }
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateEpisodeFailure});
      throw err;
    }
  };
}
