import * as React from 'react';

import AddSceneCommandLink from './AddSceneCommandLink';

import StillIcon from '../../../../shared/icons/StillIcon';

import {colors} from '../../../../../styles/variables';

interface Props {
  onPress: () => void;
}

const AddFullScreenIllustrationShowSceneCommandLink: React.FunctionComponent<
  Props
> = props => {
  const {onPress} = props;
  return (
    <AddSceneCommandLink title={'スチル'} onPress={onPress}>
      <StillIcon color={colors.textColor} />
    </AddSceneCommandLink>
  );
};

export default React.memo(AddFullScreenIllustrationShowSceneCommandLink);
