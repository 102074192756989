import * as React from 'react';

interface Props {
  src?: string;
  style?: React.CSSProperties | undefined;
  onEnded?: () => void;
}

const VideoPlayer: React.FunctionComponent<Props> = props => {
  const {style, src, onEnded} = props;
  return <video style={style} src={src} autoPlay={true} onEnded={onEnded} />;
};

export default React.memo(VideoPlayer);
