import * as React from 'react';
import {ActivityIndicator, StyleSheet, View, ViewStyle} from 'react-native';
import {useIsFocused} from '@react-navigation/native';

import {EffectImageSequence} from '../../../vendor/react-native-tapnovel-viewer';

interface Props {
  id: number | undefined;
  urls: string[];
  loop: boolean;
  size: {width: number; height: number};
  pause?: boolean;
  onReady?: () => void;
  onFinish?: () => void;
}

const defaultOnFinish = function () {};

const INDICATOR_SIZE = 'small';
const INDICATOR_COLOR = '#999999';

const BaseEffectImageSequence: React.FunctionComponent<Props> = props => {
  const {urls, loop, size, pause, onFinish} = props;
  const [loaded, setLoaded] = React.useState(urls.length === 0);
  const handleStart = React.useCallback(() => {
    setLoaded(true);
    props.onReady?.();
  }, []);
  if (!useIsFocused()) {
    return null;
  }
  return (
    <View style={styles.container}>
      <EffectImageSequence
        loop={loop}
        urls={urls}
        size={size}
        pause={pause}
        onStart={handleStart}
        onFinish={onFinish || defaultOnFinish}
      />
      {loaded ? null : (
        <View style={styles.loading}>
          <ActivityIndicator size={INDICATOR_SIZE} color={INDICATOR_COLOR} />
        </View>
      )}
    </View>
  );
};

export default React.memo(BaseEffectImageSequence);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  } as ViewStyle,
  loading: {
    justifyContent: 'center',
    alignContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  } as ViewStyle,
});
