import * as React from 'react';

import StoryList from './partials/StoryList';
import ServiceExplanation from './partials/ServiceExplanation';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ExportStoriesRouteProp} from '../../../navigators/RouteProps';

import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

import Story from '../../../../domain/entities/Story';

import NetStoriesRepository from '../../../../data/repositories/writer/NetStoriesRepository';
import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: ExportStoriesRouteProp;
}

export interface DispatchProps {
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, showCurrentUserStatus} = props;
  const [currentUserStatus, setCurrentUserStatus] =
    React.useState<CurrentUserStatus | null>(null);
  const [stories, setStories] = React.useState<Array<Story> | null>(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    showCurrentUserStatus().then(setCurrentUserStatus);
    new NetStoriesRepository().findAll().then(result => {
      setStories(result.records);
    });
  }, []);
  const onAgreeServicePolicy = React.useCallback(() => {
    setLoading(true);
    TapNovelRestApi.post('/api/writer/user_service_agreements', {
      writer_user_service_agreement: {
        writer_service_agreement_code: 'export',
      },
    })
      .then(response => {
        setTimeout(() => {
          showCurrentUserStatus().then(setCurrentUserStatus);
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const onSelectStory = React.useCallback((story: Story) => {
    navigation.navigate('ExportStory', {storyId: story.id});
  }, []);
  return (
    <Layout
      title={
        currentUserStatus?.serviceAgreementCodes?.includes('export')
          ? 'エクスポートするストーリーを選択'
          : 'エクスポート'
      }
      navigation={navigation}
      scrollable={false}
      back={true}
      loading={loading}>
      {currentUserStatus ? (
        currentUserStatus.serviceAgreementCodes.includes('export') ? (
          <StoryList stories={stories} onSelectStory={onSelectStory} />
        ) : (
          <ServiceExplanation onAgreeServicePolicy={onAgreeServicePolicy} />
        )
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
