import * as React from 'react';
import {FlatList, ListRenderItemInfo, Platform} from 'react-native';

import QuestionListItem from './QuestionListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Question from '../../../../../domain/entities/writer/faq/Question';

interface Props {
  questions: Array<Question> | null;
}

const QuestionList: React.FC<Props> = props => {
  const {questions} = props;
  const keyExtractor = React.useCallback(
    (question: Question) => `${question.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Question>) => (
      <QuestionListItem question={info.item} />
    ),
    [],
  );
  if (!questions || questions?.length === 0) {
    return null;
  }
  return (
    <FlatList
      data={questions}
      scrollEnabled={Platform.select({web: true, default: false})}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(QuestionList);
