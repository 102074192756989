import * as React from 'react';
import {
  ImageBackground,
  ImageStyle,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import * as Immutable from 'immutable';

import AddSceneCommandLinksGroup from './AddSceneCommandLinksGroup';

import {ModalParams} from '../Modal';

import PlusIcon from '../../../shared/icons/PlusIcon';
import CloseIcon from '../../../shared/icons/CloseIcon';

import SceneFrame from '../../../../view_models/SceneFrame';
import CoachmarkState from '../../../../view_models/CoachmarkState';

import {colors} from '../../../../styles/variables';

import convertImageSource from '../../../../helpers/convertImageSource';

import SceneCommandForm from '../../../../../domain/forms/scene_commands/SceneCommandForm';
import Position from '../../../../../domain/value_objects/Position';

import insertButtonBackgroundUri from '../../../../../assets/images/insert_button_background.png';

export interface Props {
  style?: StyleProp<ViewStyle>;
  sceneFrame: SceneFrame;
  sceneCommandIndex: number;
  sceneCommandForms: SceneCommandForm[];
  sceneCommandForm: SceneCommandForm | null;
  enableFullScreenIllustration?: boolean;
  enableSoundEffect?: boolean;
  enableBackgroundMusic?: boolean;
  insertIntoCompositeSequence?: boolean;
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
  onRequestOpenModal: (modalParams: ModalParams) => void;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
  onForwardToSpeechBalloons: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    positions?: Position[];
  }) => void;
  onForwardToTextFrames: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToIllustrations: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToFullScreenIllustrations: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToEffects: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToSoundEffects: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToBackgroundMusic: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onRequestUpdateCoachmarkModal?: (
    coachmarkState: CoachmarkState | null,
  ) => void;
}

interface State {
  toggle: boolean;
  sceneCommandForms: SceneCommandForm[] | null;
}

export default class InsertSceneCommandLinksGroup extends React.PureComponent<
  Props,
  State
> {
  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (
      !Immutable.is(nextProps.sceneCommandForms, prevState.sceneCommandForms)
    ) {
      return {toggle: false, sceneCommandForms: nextProps.sceneCommandForms};
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      sceneCommandForms: null,
      toggle: false,
    };
  }

  public render(): React.ReactNode {
    const {style} = this.props;
    const {toggle} = this.state;
    return (
      <View style={[styles.container, style]}>
        <Pressable onPress={this.handlePress}>
          <View style={styles.button}>
            <ImageBackground
              style={styles.image}
              source={convertImageSource(insertButtonBackgroundUri)}>
              <View style={styles.icon}>
                {toggle ? (
                  <CloseIcon size={8} color={'#383838'} />
                ) : (
                  <PlusIcon />
                )}
              </View>
              <Text style={styles.iconText}>{toggle ? '閉じる' : '挿入'}</Text>
            </ImageBackground>
          </View>
        </Pressable>
        {toggle ? <AddSceneCommandLinksGroup {...this.props} /> : null}
      </View>
    );
  }

  private handlePress = () => {
    this.setState({toggle: !this.state.toggle});
  };
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'flex-end',
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 10,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
  container: {} as ViewStyle,
  icon: {
    backgroundColor: 'transparent',
    marginRight: 3,
  } as ViewStyle,
  iconText: {
    backgroundColor: 'transparent',
    color: colors.gray,
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  image: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 24,
    justifyContent: 'center',
    paddingLeft: 3,
    width: 56,
  } as ImageStyle,
});
