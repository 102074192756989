import * as React from 'react';

import EpisodeInfo from './partials/EpisodeInfo';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {AnalysisEpisodeRouteProp} from '../../../navigators/RouteProps';

import EpisodeAggregation from '../../../../domain/value_objects/summary/EpisodeAggregation';
import EpisodeBrowsingRetentionRate from '../../../../domain/value_objects/summary/EpisodeBrowsingRetentionRate';
import SceneCommand from '../../../../domain/value_objects/summary/SceneCommand';

import {Option as SummaryEpisodeAggregationOption} from '../../../actions/summary/episode_aggregations/show';
import {Params as SummaryEpisodeBrowsingRetentionRateIndexParams} from '../../../actions/summary/episode_browsing_retention_rates/index';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

import {
  TermRange,
  convertTermRangeToQueryParams,
} from '../../../view_models/TermRange';

export interface Params {
  episodeId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: AnalysisEpisodeRouteProp;
}

export interface DispatchProps {
  showSummaryEpisodeAggregation: (
    id: number,
    options?: SummaryEpisodeAggregationOption,
  ) => Promise<EpisodeAggregation>;
  indexSummaryEpisodeBrowsingRetentionRates: (
    params: SummaryEpisodeBrowsingRetentionRateIndexParams,
  ) => Promise<Array<EpisodeBrowsingRetentionRate>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  episodeAggregation: EpisodeAggregation | null;
  episodeBrowsingRetentionRates: Array<EpisodeBrowsingRetentionRate> | null;
  sceneCommands: Array<SceneCommand> | null;
  termRange: TermRange;
}

export default class Show extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      episodeAggregation: null,
      episodeBrowsingRetentionRates: null,
      sceneCommands: null,
      termRange: 'all',
    };
  }

  public componentDidMount() {
    const {route} = this.props;
    const {episodeId} = route.params;
    TapNovelRestApi.get(
      `/api/writer/summary/episode_commands?episode_id=${episodeId}`,
    ).then(ret => {
      this.setState({sceneCommands: ret.body as Array<SceneCommand>});
    });
    this.fetchAggregations();
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {
      episodeAggregation,
      episodeBrowsingRetentionRates,
      sceneCommands,
      termRange,
    } = this.state;
    return (
      <Layout
        title={episodeAggregation?.title || '分析'}
        navigation={navigation}
        back={true}>
        {episodeAggregation &&
          episodeBrowsingRetentionRates &&
          sceneCommands && (
            <EpisodeInfo
              episodeAggregation={episodeAggregation}
              episodeBrowsingRetentionRates={episodeBrowsingRetentionRates}
              sceneCommands={sceneCommands}
              termRange={termRange}
              onSelectTermRange={this.handleSelectTermRange}
            />
          )}
      </Layout>
    );
  }

  private handleSelectTermRange = (termRange: TermRange) => {
    this.setState({termRange}, () => {
      this.fetchAggregations();
    });
  };

  private fetchAggregations = () => {
    const {
      route,
      showSummaryEpisodeAggregation,
      indexSummaryEpisodeBrowsingRetentionRates,
    } = this.props;
    const {termRange} = this.state;
    const {episodeId} = route.params;
    showSummaryEpisodeAggregation(
      episodeId,
      convertTermRangeToQueryParams(termRange),
    ).then(episodeAggregation => {
      this.setState({episodeAggregation});
    });
    indexSummaryEpisodeBrowsingRetentionRates({
      ...convertTermRangeToQueryParams(termRange),
      episodeId,
    }).then(episodeBrowsingRetentionRates =>
      this.setState({episodeBrowsingRetentionRates}),
    );
  };
}
