import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';
import {useNavigation} from '@react-navigation/native';

import DragScrollView from '../../../../shared/drag_scroll_view';

import {getDoubleResizeImageUrl} from '../../../../../helpers/images';

import Actor from '../../../../../../domain/entities/shop/Actor';

interface Props {
  message: string;
  actors: Actor[];
  disableNavigation?: boolean;
}

const RecommendActors: React.FC<Props> = props => {
  const {message, actors, disableNavigation} = props;
  if (actors.length === 0) {
    return null;
  }
  const navigation = useNavigation<any>();
  const onPress = React.useCallback((actor: Actor) => {
    navigation.navigate('ShopActor', {id: actor.id, disableNavigation});
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.message}>{message}</Text>
      <DragScrollView
        horizontal={true}
        bounces={false}
        showsHorizontalScrollIndicator={false}
        scrollEventThrottle={16}
        style={{flex: 1}}>
        <View style={{flexDirection: 'row'}}>
          {actors.map(actor => (
            <TouchableOpacity
              style={styles.actor}
              onPress={() => onPress(actor)}>
              <View style={styles.imageWrapper}>
                <Image
                  source={{
                    uri: getDoubleResizeImageUrl(
                      actor,
                      {
                        width: IMAGE_WIDTH,
                        height: IMAGE_HEIGHT,
                      },
                      {nocanvas: true},
                    ),
                  }}
                  style={styles.image}
                />
              </View>
              <Text style={styles.price}>
                ￥{numeral(actor.amount).format('0')}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
      </DragScrollView>
    </View>
  );
};

export default React.memo(RecommendActors);

const IMAGE_WIDTH = 169;
const IMAGE_HEIGHT = 240;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginHorizontal: 16,
    marginBottom: 16,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontWeight: 'bold',
    fontSize: 10,
    marginBottom: 16,
  } as TextStyle,
  actor: {
    alignItems: 'center',
    marginRight: 4,
  } as ViewStyle,
  imageWrapper: {
    width: 96,
    height: 136,
    alignItems: 'center',
  } as ViewStyle,
  image: {
    width: 74,
    height: 128,
  } as ImageStyle,
  price: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
});
