const errorBackup = console.error;
console.error = function filterWarnings(msg: string) {
  const supressedWarnings = [
    'Function components cannot be given refs.',
    'Invalid style property of',
    'TouchableWithoutFeedback does not work well with Text children. Wrap children in a View instead.',
    'Unable to preventDefault inside passive event listener due to target being treated as passive.',
  ];

  if (!supressedWarnings.some(entry => `${msg}`.includes(entry))) {
    errorBackup.apply(console, [msg]);
  }
};

const backupWarn = console.warn;
console.warn = function filterWarnings(msg: string) {
  const supressedWarnings = [
    'React.createFactory() is deprecated and will be removed in a future major release.',
    'componentWillMount has been renamed, and is not recommended for use.',
    'componentWillReceiveProps has been renamed, and is not recommended for use.',
    'componentWillUpdate has been renamed, and is not recommended for use.',
    'Animated: `useNativeDriver` is not supported because the native animated module is missing. Falling back to JS-based animation.',
  ];

  if (!supressedWarnings.some(entry => `${msg}`.includes(entry))) {
    backupWarn.apply(console, [msg]);
  }
};

export {};
