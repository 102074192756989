import {Dispatch} from 'redux';

import Types from '../../Types';

import Story from '../../../../domain/entities/response/Story';

import NetStoriesRepository from '../../../../data/repositories/writer/response/NetStoriesRepository';

export interface Params {
  confirmed?: boolean;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Story> {
  return async (dispatch: Dispatch<any>): Promise<Story> => {
    try {
      const payload = await new NetStoriesRepository().update(id, {
        responseStory: params,
      });
      dispatch({payload, type: Types.UpdateResponseStorySuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateResponseStoryFailure});
      throw err;
    }
  };
}
