import * as React from 'react';

import Confirm from './partials/Confirm';
import Form from './partials/Form';

import Layout from '../shared/Layout';
import Alert from '../shared/alert/Alert';

import NavigationProp from '../../navigators/NavigationProp';

import * as routers from '../../routers';

import {Params as WriterContactCategoryIndexParams} from '../../actions/writer/contact_categories/index';
import {Params as WriterContactCreateParams} from '../../actions/writer/contacts/create';
import {Params as WriterHelpIndexParams} from '../../actions/writer/helps/index';

import {formatErrorMessages} from '../../helpers/errorMessages';

import {colors} from '../../styles/variables';

import WriterCurrentUser from '../../../domain/entities/writer/CurrentUser';
import Contact from '../../../domain/entities/writer/Contact';
import ContactCategory from '../../../domain/entities/writer/ContactCategory';
import Help from '../../../domain/entities/writer/Help';
import PaginatedResult from '../../../domain/results/PaginatedResult';
import WriterContactForm from '../../../domain/forms/writer/ContactForm';

export interface Params {}

export interface StateProps {
  writerCurrentUser: WriterCurrentUser | null;
  navigation: NavigationProp;
  contactCategories: ContactCategory[] | null;
  contactCategoriesParams: WriterContactCategoryIndexParams;
  helps: Help[] | null;
  helpsParams: WriterHelpIndexParams;
}

export interface DispatchProps {
  indexWriterContactCategories: (
    params: WriterContactCategoryIndexParams,
  ) => Promise<PaginatedResult<ContactCategory>>;
  indexWriterHelps: (
    params: WriterHelpIndexParams,
  ) => Promise<PaginatedResult<Help>>;
  createWriterContact: (params: WriterContactCreateParams) => Promise<Contact>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  writerContactForm: WriterContactForm | null;
  confirmMode: boolean;
  loading: boolean;
}

export default class Edit extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      confirmMode: false,
      writerContactForm: null,
      loading: false,
    };
  }

  public componentDidMount() {
    const {
      indexWriterContactCategories,
      indexWriterHelps,
      contactCategoriesParams,
      helpsParams,
    } = this.props;
    this.mounted = true;
    if (!this.props.contactCategories) {
      indexWriterContactCategories(contactCategoriesParams);
    }
    if (!this.props.helps) {
      indexWriterHelps(helpsParams);
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {navigation, contactCategories, helps, writerCurrentUser} =
      this.props;
    const {writerContactForm, confirmMode} = this.state;
    return (
      <Layout
        title={'お問い合わせ'}
        scrollable={false}
        navigation={navigation}
        back={true}
        containerStyle={{backgroundColor: colors.backgroundGray}}>
        {contactCategories &&
          helps &&
          writerCurrentUser &&
          (!confirmMode ? (
            <Form
              writerCurrentUser={writerCurrentUser}
              contactCategories={contactCategories}
              writerContactForm={writerContactForm}
              helps={helps}
              onPressCommunity={this.handlePressCommunityButton}
              onSubmit={this.handleForwardConfirm}
              onFowrardToHelps={this.handleFowrardToHelps}
            />
          ) : (
            writerContactForm && (
              <Confirm
                contactCategories={contactCategories}
                writerContactForm={writerContactForm}
                onSubmit={this.handleSubmit}
                onBack={this.handleForwardForm}
              />
            )
          ))}
      </Layout>
    );
  }

  private handleForwardConfirm = (writerContactForm: WriterContactForm) => {
    this.setState({writerContactForm, confirmMode: true});
  };

  private handleForwardForm = () => {
    this.setState({confirmMode: false});
  };

  private handlePressCommunityButton = () => {
    const {navigation} = this.props;
    navigation.navigate('CommunityNavigation', {});
  };

  private handleSubmit = (writerContactForm: WriterContactForm) => {
    const {navigation, createWriterContact} = this.props;
    const params = writerContactForm.getParams();
    if (!params) {
      return;
    }
    this.setStateIfMounted({loading: true}, () => {
      createWriterContact(params)
        .then(() => {
          this.setStateIfMounted({loading: false}, () => {
            navigation.goBack();
            Alert.alert('お問い合わせが完了しました。');
          });
        })
        .catch(e => {
          this.setStateIfMounted({loading: false}, () => {
            Alert.alert(formatErrorMessages({}, e));
          });
        });
    });
  };

  private handleFowrardToHelps = () => {
    const {navigation} = this.props;
    routers.linkToHelps(navigation);
  };

  public setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ) {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }
}
