import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import ActorAccessory from '../../../domain/entities/ActorAccessory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetActorAccessoriesRepository from '../../../data/repositories/writer/NetActorAccessoriesRepository';

const types = {
  failure: Types.IndexActorAccessoriesFailure,
  request: Types.IndexActorAccessoriesRequest,
  success: Types.IndexActorAccessoriesSuccess,
};

export interface Params extends BaseParams {
  actorId: number;
  actorCostumeId?: number;
  actorHairStyleId?: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<ActorAccessory>> {
  return indexGenerics(new NetActorAccessoriesRepository(), types, params);
}
