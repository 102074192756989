import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import CharacterMakerActorGroupMapper, {
  CharacterMakerActorGroupData,
} from '../../entities/mappers/CharacterMakerActorGroupMapper';

import CharacterMakerActorGroup from '../../../domain/entities/CharacterMakerActorGroup';
import CharacterMakerActorGroupsRepository from '../../../domain/repositories/writer/CharacterMakerActorGroupsRepository';

export default class NetCharacterMakerActorGroupsRepository
  extends NetResourcesRepository<
    CharacterMakerActorGroup,
    CharacterMakerActorGroupData
  >
  implements CharacterMakerActorGroupsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_maker_actor_groups',
        new CharacterMakerActorGroupMapper(),
      ),
    );
  }
}
