import * as React from 'react';
import {FlatList, ListRenderItemInfo, View, Text} from 'react-native';

import EpisodetListItem from './EpisodetListItem';

import ListItemNormalSeparator from '../../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectEpisode from '../../../../../../domain/entities/ProjectEpisode';

interface Props {
  episodes: ProjectEpisode[];
  label: string;
  onForceUpdate: () => void;
}

const EpisodeList: React.FC<Props> = props => {
  const {episodes, label, onForceUpdate} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<any>) => (
      <EpisodetListItem episode={info.item} onForceUpdate={onForceUpdate} />
    ),
    [],
  );
  if (episodes.length === 0) {
    return <NoEpisodeList label={label} />;
  }
  return (
    <FlatList
      data={episodes}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(EpisodeList);

const NoEpisodeList: React.FC<{label: string}> = props => {
  const {label} = props;
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Text style={{color: '#222', fontSize: 14}}>{label}はありません</Text>
    </View>
  );
};
