import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const SpeechTextIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 25;
  const color = props.color || 'black';
  return <Icon name={'speechText'} size={size} color={color} />;
};

export default React.memo(SpeechTextIcon);
