import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/cover_image_form/actor_character_faces/Index';

import indexActorCharacterFaces, {
  Params as ActorCharacterFaceIndexParams,
} from '../../../actions/actor_character_faces/index';
import indexActorCostumes, {
  Params as ActorCostumeIndexParams,
} from '../../../actions/actor_costumes/index';
import indexActorHairStyles, {
  Params as ActorHairStyleIndexParams,
} from '../../../actions/actor_hair_styles/index';
import indexActorAccessorySets, {
  Params as ActorAccessorySetIndexParams,
} from '../../../actions/actor_accessory_sets/index';
import indexMarks, {
  Params as MarkIndexParams,
} from '../../../actions/marks/index';
import updateCharacterPatternUsageHistory from '../../../actions/character_pattern_usage_histories/update';
import indexCharacterPatterns, {
  Params as CharacterPatternIndexParams,
} from '../../../actions/character_patterns/index';
import showCharacterPattern from '../../../actions/character_patterns/show';
import createCharacterPattern, {
  Params as CharacterPatternCreateParams,
} from '../../../actions/character_patterns/create';
import showCharacter from '../../../actions/characters/show';
import updateCoverImageForm, {
  Params as CoverImageFormUpdateParams,
} from '../../../actions/cover_image_forms/update';

import AppState from '../../../reducers/AppState';

import selectEntities from '../../../helpers/selectEntities';
import selectEntity from '../../../helpers/selectEntity';
import {CoverImageFormActorCharacterFacesRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CoverImageFormActorCharacterFacesRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {characterId, characterPatternId} = route.params;
  const marksParams: MarkIndexParams = {};
  const marks = selectEntities(entities.marks, queries.marks, marksParams);
  const character = selectEntity(entities.characters, characterId);
  const characterPattern = selectEntity(
    entities.characterPatterns,
    characterPatternId,
  );
  const {coverImageForm} = forms;
  return {
    coverImageForm,
    marks,
    marksParams,
    character,
    characterPattern,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexActorCharacterFaces: (params: ActorCharacterFaceIndexParams) =>
      dispatch(indexActorCharacterFaces(params)),
    indexActorCostumes: (params: ActorCostumeIndexParams) =>
      dispatch(indexActorCostumes(params)),
    indexActorHairStyles: (params: ActorHairStyleIndexParams) =>
      dispatch(indexActorHairStyles(params)),
    indexActorAccessorySets: (params: ActorAccessorySetIndexParams) =>
      dispatch(indexActorAccessorySets(params)),
    indexMarks: (params: MarkIndexParams) => dispatch(indexMarks(params)),
    showCharacter: (id: number) => dispatch(showCharacter(id)),
    indexCharacterPatterns: (params: CharacterPatternIndexParams) =>
      dispatch(indexCharacterPatterns(params)),
    showCharacterPattern: (id: number) => dispatch(showCharacterPattern(id)),
    createCharacterPattern: (params: CharacterPatternCreateParams) =>
      dispatch(createCharacterPattern(params)),
    updateCharacterPatternUsageHistory: (id: number) =>
      dispatch(updateCharacterPatternUsageHistory(id)),
    updateCoverImageForm: (params: CoverImageFormUpdateParams) =>
      dispatch(updateCoverImageForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
