import * as React from 'react';
import {
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';

import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../../styles/variables';
import {storyCoverImageUrl} from '../../../../helpers/images';

import Story from '../../../../../domain/entities/writer/vertical_short_video/Story';

interface Props {
  story: Story;
  onSelectStory: (story: Story) => void;
}

const StoryListItem: React.FunctionComponent<Props> = props => {
  const {story, onSelectStory} = props;
  const source = React.useMemo(() => {
    return {
      uri: storyCoverImageUrl(new StoryCoverImageDecorator(story), 'small'),
      headers: {Accept: 'image/webp,image/apng,*/*'},
    };
  }, []);
  const onPress = React.useCallback(() => {
    onSelectStory(story);
  }, []);
  return (
    <Pressable style={styles.container} onPress={onPress}>
      {story.hasCoverImage ? (
        <FastImageAdapter style={styles.image} source={source} />
      ) : (
        <View style={styles.image}>
          <Text style={styles.noImageText}>表紙なし</Text>
        </View>
      )}
      <View style={styles.storyInfo}>
        <Text style={styles.title}>{story.title}</Text>
        {story.status === 'up-to-date' ? (
          <Text style={styles.labelUpToDate}>CM動画作成済み</Text>
        ) : story.status === 'job_running' ? (
          <Text style={styles.labelJobRunning}>作成中</Text>
        ) : null}
      </View>
    </Pressable>
  );
};

export default React.memo(StoryListItem);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    margin: 10,
    backgroundColor: 'white',
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  storyInfo: {
    flex: 1,
    margin: 10,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
  labelUpToDate: {
    backgroundColor: '#2c86d7',
    color: '#fff',
    fontSize: 8,
    justifyContent: 'center',
    textAlign: 'center',
    width: 70,
  } as TextStyle,
  labelJobRunning: {
    backgroundColor: 'white',
    borderColor: '#2c86d7',
    borderWidth: 1,
    color: '#2c86d7',
    fontWeight: 'bold',
    fontSize: 8,
    justifyContent: 'center',
    textAlign: 'center',
    width: 40,
  } as TextStyle,
});
