import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import GenreList from './GenreList';

import Genre from '../../../../../../domain/entities/Genre';

interface Props {
  genres: Genre[];
}

const GenreSearch: React.FC<Props> = props => {
  const {genres} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>ジャンルからプロジェクトを探す</Text>
      <GenreList genres={genres} />
    </View>
  );
};

export default React.memo(GenreSearch);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginBottom: 8,
    backgroundColor: '#fafafa',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 4,
  } as TextStyle,
});
