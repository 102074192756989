import * as React from 'react';
import ScrollableTabViewAdapter, {
  ChangedTabInfo,
} from '../../../shared/scrollable_tab_view/ScrollableTabViewAdapter';

import CommentList from './CommentList';
import CommentListHeader from './CommentListHeader';
import ReactionSearchForm from './ReactionSearchForm';
import ReactionList from './ReactionList';

import DimensionContext from '../../../shared/dimension/DimensionContext';
import NoticeIcon from '../../../shared/NoticeIcon';

import Episode from '../../../../../domain/entities/response/Episode';
import EpisodeReaction from '../../../../../domain/entities/EpisodeReaction';
import EpisodeComment from '../../../../../domain/entities/EpisodeComment';
import EpisodeCommentReply from '../../../../../domain/entities/EpisodeCommentReply';
import EpisodeCommentBlockingUser from '../../../../../domain/entities/EpisodeCommentBlockingUser';
import User from '../../../../../domain/entities/consumer/User';
import WriterCurrentUser from '../../../../../domain/entities/writer/CurrentUser';

interface Props {
  currentUser: WriterCurrentUser;
  episode: Episode;
  episodeReactions: EpisodeReaction[] | null;
  episodeComments: EpisodeComment[] | null;
  reactionIds: number[];
  episodeCommentBlockingUsers: EpisodeCommentBlockingUser[];
  initialPage?: number;
  onChangeTab: (changedTabInfo: ChangedTabInfo) => void;
  onRequestToggleReaction: (id: number) => void;
  onRequestCreateEpisodeCommentBlockingUser: (user: User) => void;
  onRequestDestroyEpisodeCommentBlockingUser: (
    episodeCommentBlockingUser: EpisodeCommentBlockingUser,
  ) => void;
  onRequestDestroyEpisodeComment: (episodeComment: EpisodeComment) => void;
  onRequestCreateEpisodeCommentStoryPage: (
    episodeComment: EpisodeComment,
  ) => void;
  onRequestDestroyEpisodeCommentStoryPage: (
    episodeComment: EpisodeComment,
  ) => void;
  onOpenNewEpisodeCommentReply: (episodeComment: EpisodeComment) => void;
  onOpenEditEpisodeCommentReply: (
    episodeCommentReply: EpisodeCommentReply,
  ) => void;
  onRequestDestroyEpisodeCommentReply: (
    episodeCommentReply: EpisodeCommentReply,
  ) => void;
}

const EpisodeDetailTabs: React.FunctionComponent<Props> = props => {
  const {
    currentUser,
    episode,
    episodeReactions,
    episodeComments,
    reactionIds,
    episodeCommentBlockingUsers,
    initialPage,
    onChangeTab,
    onRequestToggleReaction,
    onRequestCreateEpisodeCommentBlockingUser,
    onRequestDestroyEpisodeCommentBlockingUser,
    onRequestDestroyEpisodeComment,
    onRequestCreateEpisodeCommentStoryPage,
    onRequestDestroyEpisodeCommentStoryPage,
    onOpenNewEpisodeCommentReply,
    onOpenEditEpisodeCommentReply,
    onRequestDestroyEpisodeCommentReply,
  } = props;
  const tabs = [
    {
      label: 'リアクション',
      element: (
        <ReactionList
          episodeReactions={episodeReactions}
          ListHeaderComponent={
            <ReactionSearchForm
              reactionIds={reactionIds}
              totalCount={episodeReactions ? episodeReactions.length : null}
              visibleIconsTips={getVisibleIconsTip(episodeReactions)}
              onRequestToggleReaction={onRequestToggleReaction}
            />
          }
        />
      ),
    },
    {
      label: 'コメント',
      element: (
        <CommentList
          currentUser={currentUser}
          episodeComments={episodeComments}
          episodeCommentBlockingUsers={episodeCommentBlockingUsers}
          ListHeaderComponent={
            <CommentListHeader
              totalCount={episodeComments ? episodeComments.length : null}
              visibleIconsTips={getVisibleIconsTip(episodeComments)}
            />
          }
          onRequestCreateEpisodeCommentBlockingUser={
            onRequestCreateEpisodeCommentBlockingUser
          }
          onRequestDestroyEpisodeCommentBlockingUser={
            onRequestDestroyEpisodeCommentBlockingUser
          }
          onRequestDestroyEpisodeComment={onRequestDestroyEpisodeComment}
          onRequestCreateEpisodeCommentStoryPage={
            onRequestCreateEpisodeCommentStoryPage
          }
          onRequestDestroyEpisodeCommentStoryPage={
            onRequestDestroyEpisodeCommentStoryPage
          }
          onOpenNewEpisodeCommentReply={onOpenNewEpisodeCommentReply}
          onOpenEditEpisodeCommentReply={onOpenEditEpisodeCommentReply}
          onRequestDestroyEpisodeCommentReply={
            onRequestDestroyEpisodeCommentReply
          }
        />
      ),
    },
  ];
  return (
    <>
      <ScrollableTabViewAdapter
        initialPage={initialPage}
        tabs={tabs}
        onChangeTab={onChangeTab}
      />
      <DimensionContext.Consumer>
        {context => {
          const width = context.content.width / tabs.length;
          return tabs.map((tab, i) =>
            (tab.label === 'リアクション' && !episode.confirmedReactions) ||
            (tab.label === 'コメント' && !episode.confirmedComments) ? (
              <NoticeIcon
                style={{
                  position: 'absolute',
                  left: width * (i + 1) - 16,
                  top: 8,
                }}
              />
            ) : null,
          );
        }}
      </DimensionContext.Consumer>
    </>
  );
};

const getVisibleIconsTip = (
  records: EpisodeReaction[] | EpisodeComment[] | null,
) => {
  if (!records) {
    return false;
  }
  return records.some(
    record => record.user.inhouseStaff || !!record.user.linkedWriterUser,
  );
};

export default React.memo(EpisodeDetailTabs);
