import {Dispatch} from 'redux';

import Types from '../Types';

import CharacterPattern from '../../../domain/entities/CharacterPattern';

import NetCharacterPatternUsageHistoriesRepository from '../../../data/repositories/writer/NetCharacterPatternUsageHistoriesRepository';

export default function action(
  id: number,
): (dispatch: Dispatch<any>) => Promise<CharacterPattern> {
  return async (dispatch: Dispatch<any>): Promise<CharacterPattern> => {
    try {
      const payload =
        await new NetCharacterPatternUsageHistoriesRepository().update(id, {});
      dispatch({
        payload,
        type: Types.UpdateCharacterPatternUsageHistorySuccess,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateCharacterPatternUsageHistoryFailure});
      throw err;
    }
  };
}
