import * as React from 'react';

import BaseIndex from '../../../../shared/generics/character_patterns/Index';
import shouldUpdateCharacterPatternList from '../../../../shared/enhanced/shouldUpdateCharacterPatternList';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterPatternsRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as CharacterPatternIndexParams} from '../../../../../actions/character_patterns/index';

import {equalForKeys} from '../../../../../helpers/equalForKeys';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import CharacterPattern from '../../../../../../domain/entities/CharacterPattern';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../domain/forms/SceneForm';
import CurrentUserStatus from '../../../../../../domain/entities/writer/CurrentUserStatus';
import ActorSearchSetting from '../../../../../../domain/value_objects/ActorSearchSetting';

import StorageActorSearchSettingRepository from '../../../../../../data/repositories/StorageActorSearchSettingRepository';

const actorSearchSettingRepository = new StorageActorSearchSettingRepository();

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
  back?: boolean;
  page?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterPatternsRouteProp;
  currentUserStatus?: CurrentUserStatus;
  sceneForm: SceneForm | null;
  characterPatternsParams: CharacterPatternIndexParams;
  characterPatterns: CharacterPattern[] | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  private actorSearchSetting: ActorSearchSetting | null = null;

  constructor(props: Props) {
    super(props);
    actorSearchSettingRepository
      .find()
      .then(actorSearchSetting => {
        this.actorSearchSetting = actorSearchSetting;
      })
      .catch(() => {});
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!equalForKeys(this.props, nextProps, ['characterPatternsParams'])) {
      return true;
    }
    if (shouldUpdateCharacterPatternList(this.props, nextProps)) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        onSelectCharacterPattern={this.handleSelectCharacterPattern}
        onForwardToNewCharacterPattern={this.handleForwardToNewCharacterPattern}
        onForwardToNewCharacter={this.handleForwardToNewCharacter}
        onForwardToFavoriteList={this.handleForwardToFavoriteList}
        onPressShoppingList={this.handleForwardToShoppingList}
        labelExtractor={this.labelExtractor}
      />
    );
  }

  private handleSelectCharacterPattern = (
    characterPattern: CharacterPattern,
  ) => {
    const {navigation, route} = this.props;
    const {
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
    } = route.params;
    const characterId = characterPattern.character.id;
    const characterPatternId = characterPattern.id;
    routers.linkToSceneFormCharacterShowSceneCommandNewActorCharacterFaces(
      navigation,
      {
        episodeId,
        sceneId,
        characterId,
        characterPatternId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
      },
    );
  };

  private handleForwardToNewCharacterPattern = () => {
    const {navigation, route, currentUserStatus} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
    } = route.params;
    const withPaid =
      this.actorSearchSetting &&
      (this.actorSearchSetting.withPaid === true ||
        this.actorSearchSetting.withPaid === false)
        ? this.actorSearchSetting.withPaid
        : false;
    routers.linkToSceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
        withPaid,
      },
    );
  };

  private handleForwardToNewCharacter = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
    } = route.params;
    navigation.navigate(
      'SceneFormCharacterShowSceneCommandNewCharacterMakerHome',
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
      },
    );
  };

  private handleForwardToFavoriteList = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
    } = route.params;
    routers.linkToSceneFormCharacterShowSceneCommandNewCharacterFormActors(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
        formValues: {
          query: '',
          ageMin: 0,
          ageMax: 6,
          rootCategoryIdToActorCategoryIds: {},
          favorite: true,
        },
      },
    );
  };

  private handleForwardToShoppingList = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
    } = route.params;
    routers.linkToSceneFormCharacterShowSceneCommandNewCharacterFormActors(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
        formValues: {
          query: '',
          ageMin: 0,
          ageMax: 6,
          rootCategoryIdToActorCategoryIds: {},
          purchase: true,
        },
      },
    );
  };

  private labelExtractor = (characterPattern: CharacterPattern) => {
    return characterPattern.character.name;
  };
}
