import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import Select from '../../../shared/forms/Select';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

interface Props {
  value?: string;
  onValueChange: (itemValue: string | number, itemPosition: number) => void;
}

const HorizontalAlignmentSelect: React.FunctionComponent<Props> = props => {
  const {value, onValueChange} = props;
  return (
    <View style={styles.container}>
      <LabelWithOption title={'左右の配置'} />
      <Select
        items={items}
        value={value}
        onValueChange={onValueChange}
        cancelText={'キャンセル'}
        confirmText={'決定'}
      />
    </View>
  );
};

export default HorizontalAlignmentSelect;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 24,
  } as ViewStyle,
});

const items = [
  {label: '左', value: 'left'},
  {label: '中央', value: 'center'},
  {label: '右', value: 'right'},
];
