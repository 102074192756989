import * as React from 'react';
import {StyleSheet, TextStyle, Text, View, ViewStyle} from 'react-native';
import {DateObject} from 'react-native-calendars';

import DateCalendarWithTimeSelect from '../../../episode_form/edit/episode_publication/partials/DateCalendarWithTimeSelect';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import MultiSwitch from '../../../shared/forms/MultiSwitch';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

import {formStyles} from '../../../../styles/variables';

export type PublicationState = 'public' | 'reserved' | 'private';
export type Option = 'free' | '';

export type FormValues = {
  option: Option;
  publicationState: PublicationState;
  scheduledDeliveredAt: Date | undefined;
};

type Form1ErrorMessage = {[P in keyof FormValues]?: string | string[]};

interface Props {
  values?: Partial<FormValues>;
  errorMessages?: Form1ErrorMessage;
  onSubmit: (values: FormValues) => void;
}

const FormEdit: React.FC<Props> = props => {
  const {values, onSubmit} = props;
  const [option, setOption] = React.useState<Option>(values?.option || 'free');
  const [publicationState, setPublicationState] =
    React.useState<PublicationState>(values?.publicationState || 'public');
  const defaultReserveDate = getDefaultReserveDate();
  const [selectedDate, setSelectedDate] = React.useState<DateObject>(
    generateDateObject(
      typeof values?.scheduledDeliveredAt === 'string'
        ? new Date(values?.scheduledDeliveredAt)
        : values?.scheduledDeliveredAt || defaultReserveDate,
    ),
  );
  const [selectedTime, setSelectedTime] = React.useState<Date>(
    typeof values?.scheduledDeliveredAt === 'string'
      ? new Date(values?.scheduledDeliveredAt)
      : values?.scheduledDeliveredAt || defaultReserveDate,
  );
  const onSelectItemOption = React.useCallback(
    (item: {label: string; value: string}) => {
      if (item.value === 'free' || item.value === '') {
        setOption(item.value);
      }
    },
    [],
  );
  const onSelectPublicationState = React.useCallback(
    (item: {label: string; value: string}) => {
      if (
        item.value === 'public' ||
        item.value === 'reserved' ||
        item.value === 'private'
      ) {
        setPublicationState(item.value);
      }
    },
    [],
  );
  const onDayPress = React.useCallback((selectedDate: DateObject) => {
    setSelectedDate(selectedDate);
  }, []);
  const onTimePress = React.useCallback((selectedTime: Date) => {
    setSelectedTime(selectedTime);
  }, []);
  const onPress = React.useCallback(() => {
    const scheduledDeliveredAt =
      publicationState === 'public'
        ? new Date()
        : publicationState === 'reserved'
        ? new Date(
            selectedDate.year,
            selectedDate.month - 1,
            selectedDate.day,
            selectedTime.getHours(),
            selectedTime.getMinutes(),
            0,
            0,
          )
        : undefined;
    onSubmit({
      option,
      publicationState,
      scheduledDeliveredAt,
    });
  }, [selectedDate, selectedTime, publicationState, option]);
  return (
    <View style={styles.container}>
      <View style={[formStyles.formGroup, {marginTop: 8}]}>
        <LabelWithOption title={'公開範囲'} />
        <MultiSwitch
          items={OPTION_ITEMS}
          value={option}
          onSelectItem={onSelectItemOption}
        />
      </View>
      <View style={formStyles.formGroup}>
        <LabelWithOption title={'公開設定'} />
        <MultiSwitch
          items={PUBLICATION_STATE_ITEMS}
          value={publicationState}
          onSelectItem={onSelectPublicationState}
        />
      </View>
      <DateCalendarWithTimeSelect
        visible={publicationState === 'reserved'}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        onDayPress={onDayPress}
        onTimePress={onTimePress}
      />
      <View style={styles.button}>
        <PrimaryButton onPress={onPress}>決定</PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(FormEdit);

const OPTION_ITEMS = [
  {label: '一般公開', value: 'free'},
  {label: '会員限定', value: ''},
];

const PUBLICATION_STATE_ITEMS = [
  {label: '今すぐ公開', value: 'public'},
  {label: '予約公開', value: 'reserved'},
  {label: '非公開', value: 'private'},
];

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginBottom: 12,
    marginTop: 20,
  } as ViewStyle,
  inputError: {
    color: '#f23406',
  } as TextStyle,
});

const getDefaultReserveDate = () => {
  const now = new Date();
  return new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes() + 5,
    0,
    0,
  );
};

const generateDateObject = (date: Date): DateObject => {
  const ary = [
    date.getFullYear(),
    zeroPadding(date.getMonth() + 1, 2),
    zeroPadding(date.getDate(), 2),
  ];
  return {
    dateString: ary.join('-'),
    day: date.getDate(),
    month: date.getMonth() + 1,
    timestamp: date.getTime(),
    year: date.getFullYear(),
  };
};

const zeroPadding = (n: number, length: number): string => {
  return `0000000000${n}`.slice(-length);
};
