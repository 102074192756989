import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EffectUsageHistoryMapper, {
  EffectUsageHistoryData,
} from '../../entities/mappers/EffectUsageHistoryMapper';

import EffectUsageHistory from '../../../domain/entities/EffectUsageHistory';
import EffectUsageHistoriesRepository from '../../../domain/repositories/writer/EffectUsageHistoriesRepository';

export default class NetEffectUsageHistoriesRepository
  extends NetResourcesRepository<EffectUsageHistory, EffectUsageHistoryData>
  implements EffectUsageHistoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/effect_usage_histories',
        new EffectUsageHistoryMapper(),
      ),
    );
  }
}
