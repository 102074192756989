import * as React from 'react';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import CharactersStage from './visualizations/CharactersStage';
import CharacterView from './visualizations/CharacterView';
import CharacterGhostView from './visualizations/CharacterGhostView';

import {ModalParams} from '../../Modal';

import SceneFrame, {
  CharacterSceneCommandForm,
} from '../../../../../view_models/SceneFrame';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import Position from '../../../../../../domain/value_objects/Position';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';

interface Props {
  sceneFrame: SceneFrame;
  sceneCommandIndex: number;
  parentSceneCommandForm?: SceneCommandForm | null;
  updateMode?: boolean;
  activePositions?: Position[];
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
  onRequestOpenModal: (modalParams: ModalParams) => void;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
}

export default class CurrentSceneFrameBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, [
      'sceneFrame',
      'activePositions',
    ]);
  }

  public render(): React.ReactNode {
    const {sceneFrame, onChangeOrder} = this.props;
    return (
      <CharactersStage
        leftCharacter={
          sceneFrame.leftCharacter && sceneFrame.isActive(Position.Left)
            ? this.renderCharacterForSceneFrame(sceneFrame.leftCharacter)
            : this.renderGhostCharacter(Position.Left)
        }
        centerCharacter={
          sceneFrame.centerCharacter && sceneFrame.isActive(Position.Center)
            ? this.renderCharacterForSceneFrame(sceneFrame.centerCharacter)
            : this.renderGhostCharacter(Position.Center)
        }
        rightCharacter={
          sceneFrame.rightCharacter && sceneFrame.isActive(Position.Right)
            ? this.renderCharacterForSceneFrame(sceneFrame.rightCharacter)
            : this.renderGhostCharacter(Position.Right)
        }
        characterNameDisplay={false}
        onChangeOrder={onChangeOrder}
      />
    );
  }

  private renderCharacterForSceneFrame = (
    sceneCommandForm: CharacterSceneCommandForm,
  ): React.ReactNode => {
    const {activePositions} = this.props;
    const {position} = sceneCommandForm;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <CharacterView
            actorCharacterFace={sceneCommandForm.actorCharacterFace}
            mark={sceneCommandForm.mark}
            inverted={sceneCommandForm.characterPattern.character.inverted}
            transparent={!(activePositions || []).includes(position)}
          />
        }
        onRequestOpenModal={() => {
          this.handleRequestOpenModal(position);
        }}
      />
    );
  };

  private renderGhostCharacter = (position: Position): React.ReactNode => {
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={<CharacterGhostView position={position} />}
        onRequestOpenModal={() => {
          this.handleRequestOpenModal(position);
        }}
      />
    );
  };

  private handleRequestOpenModal = (position: Position) => {
    const {
      sceneCommandIndex,
      sceneFrame,
      onRequestOpenModal,
      onForwardToCharacters,
    } = this.props;
    const updateMode =
      this.props.updateMode !== undefined ? this.props.updateMode : true;
    const parentSceneCommandForm = updateMode
      ? this.props.parentSceneCommandForm
      : undefined;
    const parentSceneCommandId = updateMode
      ? this.props.parentSceneCommandForm?.sceneCommandId
      : undefined;

    switch (position) {
      case Position.Left:
        if (sceneFrame.leftCharacter && sceneFrame.isActive(Position.Left)) {
          onRequestOpenModal({
            type: 'CurrentSceneFrameModal',
            sceneCommandForm: sceneFrame.leftCharacter,
            sceneCommandIndex,
            parentSceneCommandForm,
          });
        } else {
          onForwardToCharacters({
            sceneCommandIndex,
            parentSceneCommandId,
            position: Position.Left,
          });
        }
        break;
      case Position.Center:
        if (
          sceneFrame.centerCharacter &&
          sceneFrame.isActive(Position.Center)
        ) {
          onRequestOpenModal({
            type: 'CurrentSceneFrameModal',
            sceneCommandForm: sceneFrame.centerCharacter,
            sceneCommandIndex,
            parentSceneCommandForm,
          });
        } else {
          onForwardToCharacters({
            sceneCommandIndex,
            parentSceneCommandId,
            position: Position.Center,
          });
        }
        break;
      case Position.Right:
        if (sceneFrame.rightCharacter && sceneFrame.isActive(Position.Right)) {
          onRequestOpenModal({
            type: 'CurrentSceneFrameModal',
            sceneCommandForm: sceneFrame.rightCharacter,
            sceneCommandIndex,
            parentSceneCommandForm,
          });
        } else {
          onForwardToCharacters({
            sceneCommandIndex,
            parentSceneCommandId,
            position: Position.Right,
          });
        }
        break;
    }
  };
}
