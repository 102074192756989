import * as React from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {shouldUpdate} from 'recompose';

import shouldUpdateEntity from '../../shared/enhanced/shouldUpdateEntity';

import dateformat from 'dateformat';

import {colors} from '../../../styles/variables';

import Announcement from '../../../../domain/entities/writer/Announcement';

interface Props extends React.PropsWithChildren {
  announcement: Announcement;
}

const Detail: React.FunctionComponent<Props> = props => {
  const {announcement} = props;
  return (
    <View style={styles.container}>
      <ScrollView>
        <Text style={styles.categoryLabel}>
          {announcement.writerAnnouncementCategory.name}
        </Text>
        <View style={styles.title}>
          <Text style={styles.titleText}>{announcement.title}</Text>
        </View>
        <View style={styles.publishedDate}>
          <Text style={styles.publishedDateText}>
            {dateformat(announcement.publishedAt, 'yyyy.mm.dd')}
          </Text>
        </View>
        <View style={styles.content}>
          <Text style={styles.contentText}>{announcement.content}</Text>
        </View>
      </ScrollView>
    </View>
  );
};

const propsAreEqual = (
  prevProps: Readonly<Props>,
  nextProps: Readonly<Props>,
) => {
  return !shouldUpdateEntity(
    {entity: prevProps.announcement},
    {entity: nextProps.announcement},
  );
};

export default React.memo(Detail, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
    padding: 16,
  } as ViewStyle,
  categoryLabel: {
    color: colors.orange,
    fontSize: 11,
  } as TextStyle,
  title: {
    marginTop: 8,
  } as ViewStyle,
  titleText: {
    color: colors.textColor,
    fontSize: 17,
    fontWeight: 'bold',
    lineHeight: Platform.select({
      android: 20,
      ios: 16,
    }),
  } as TextStyle,
  publishedDate: {
    marginTop: 8,
  } as ViewStyle,
  publishedDateText: {
    color: colors.lightGray,
    fontSize: 11,
  } as TextStyle,
  content: {
    marginVertical: 20,
  } as ViewStyle,
  contentText: {
    color: colors.textColor,
    fontSize: 15,
    lineHeight: Platform.select({
      android: 24,
      ios: 20,
    }),
  } as TextStyle,
});
