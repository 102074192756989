import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import ActorFaceMapper, {
  ActorFaceData,
} from '../../../entities/mappers/character_maker/ActorFaceMapper';

import PaginatedResult from '../../../../domain/results/PaginatedResult';
import ActorFace from '../../../../domain/entities/character_maker/ActorFace';
import ActorFacesRepository from '../../../../domain/repositories/writer/character_maker/ActorFacesRepository';

export default class NetActorFacesRepository
  extends NetResourcesRepository<ActorFace, ActorFaceData>
  implements ActorFacesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_maker/actor_faces',
        new ActorFaceMapper(),
      ),
    );
  }

  public async findBy(
    params:
      | {
          characterMakerActorGroupId: number;
          actorId?: number;
          facePatternId?: number;
          colorPatternId?: number;
        }
      | {
          characterMakerActorGroupId?: number;
          actorId: number;
          facePatternId?: number;
          colorPatternId?: number;
        },
  ): Promise<PaginatedResult<ActorFace>> {
    return super.findBy(params);
  }
}
