import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import StoryForm from '../../../domain/forms/StoryForm';

export default function reducer(
  state: StoryForm | null = null,
  action: AnyAction,
): StoryForm | null {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return null;
    case Types.CreateStoryForm:
      return reducerCreateStoryForm(state, action);
    case Types.UpdateStoryForm:
      return reducerUpdateStoryForm(state, action);
    default:
      return state;
  }
}

function reducerCreateStoryForm(
  state: StoryForm | null = null,
  action: AnyAction,
): StoryForm | null {
  const storyForm = new StoryForm();
  const {
    id,
    title,
    catchPhrase,
    summary,
    introduction,
    coverIllustrator,
    rating,
    format,
    hasImage,
    originalImageUrl,
    hasCoverImage,
    originalCoverImageUrl,
    image,
    coverImage,
    uploadedSelfImage,
    uploadedSelfCoverImage,
    published,
    serialized,
    allowedVideoSharing,
    mainGenreId,
    subGenreIds,
    keywordNames,
    imageBackgroundUri,
    imageCharacterUri,
    imageTextInfoList,
    coverImageBackgroundUri,
    coverImageCharacterUri,
    coverImageTextInfoList,
  } = action.payload;
  if (id !== undefined) {
    storyForm.id = id;
  }
  if (title !== undefined) {
    storyForm.title = title;
  }
  if (catchPhrase !== undefined) {
    storyForm.catchPhrase = catchPhrase;
  }
  if (summary !== undefined) {
    storyForm.summary = summary;
  }
  if (introduction !== undefined) {
    storyForm.introduction = introduction;
  }
  if (coverIllustrator !== undefined) {
    storyForm.coverIllustrator = coverIllustrator;
  }
  if (rating !== undefined) {
    storyForm.rating = rating;
  }
  if (format !== undefined) {
    storyForm.format = format;
  }
  if (hasImage !== undefined) {
    storyForm.hasImage = hasImage;
  }
  if (originalImageUrl !== undefined) {
    storyForm.originalImageUrl = originalImageUrl;
  }
  if (hasCoverImage !== undefined) {
    storyForm.hasCoverImage = hasCoverImage;
  }
  if (originalCoverImageUrl !== undefined) {
    storyForm.originalCoverImageUrl = originalCoverImageUrl;
  }
  if (image !== undefined) {
    storyForm.image = image;
  }
  if (coverImage !== undefined) {
    storyForm.coverImage = coverImage;
  }
  if (uploadedSelfImage !== undefined) {
    storyForm.uploadedSelfImage = uploadedSelfImage;
  }
  if (uploadedSelfCoverImage !== undefined) {
    storyForm.uploadedSelfCoverImage = uploadedSelfCoverImage;
  }
  if (published !== undefined) {
    storyForm.published = published;
  }
  if (serialized !== undefined) {
    storyForm.serialized = serialized;
  }
  if (allowedVideoSharing !== undefined) {
    storyForm.allowedVideoSharing = allowedVideoSharing;
  }
  if (mainGenreId !== undefined) {
    storyForm.mainGenreId = mainGenreId;
  }
  if (subGenreIds !== undefined) {
    storyForm.subGenreIds = subGenreIds;
  }
  if (keywordNames !== undefined) {
    storyForm.keywordNames = keywordNames;
  }
  if (imageBackgroundUri !== undefined) {
    storyForm.imageBackgroundUri = imageBackgroundUri;
  }
  if (imageCharacterUri !== undefined) {
    storyForm.imageCharacterUri = imageCharacterUri;
  }
  if (imageTextInfoList !== undefined) {
    storyForm.imageTextInfoList = imageTextInfoList;
  }
  if (coverImageBackgroundUri !== undefined) {
    storyForm.coverImageBackgroundUri = coverImageBackgroundUri;
  }
  if (coverImageCharacterUri !== undefined) {
    storyForm.coverImageCharacterUri = coverImageCharacterUri;
  }
  if (coverImageTextInfoList !== undefined) {
    storyForm.coverImageTextInfoList = coverImageTextInfoList;
  }
  return storyForm;
}

function reducerUpdateStoryForm(
  state: StoryForm | null = null,
  action: AnyAction,
): StoryForm | null {
  const currentStoryForm = state;
  const storyForm = new StoryForm();
  if (currentStoryForm) {
    storyForm.id = currentStoryForm.id;
    storyForm.title = currentStoryForm.title;
    storyForm.catchPhrase = currentStoryForm.catchPhrase;
    storyForm.summary = currentStoryForm.summary;
    storyForm.introduction = currentStoryForm.introduction;
    storyForm.coverIllustrator = currentStoryForm.coverIllustrator;
    storyForm.rating = currentStoryForm.rating;
    storyForm.format = currentStoryForm.format;
    storyForm.serialized = currentStoryForm.serialized;
    storyForm.allowedVideoSharing = currentStoryForm.allowedVideoSharing;
    storyForm.mainGenreId = currentStoryForm.mainGenreId;
    storyForm.subGenreIds = currentStoryForm.subGenreIds;
    storyForm.keywordNames = currentStoryForm.keywordNames;
    storyForm.hasImage = currentStoryForm.hasImage;
    storyForm.originalImageUrl = currentStoryForm.originalImageUrl;
    storyForm.hasCoverImage = currentStoryForm.hasCoverImage;
    storyForm.originalCoverImageUrl = currentStoryForm.originalCoverImageUrl;
    storyForm.image = currentStoryForm.image;
    storyForm.coverImage = currentStoryForm.coverImage;
    storyForm.uploadedSelfImage = currentStoryForm.uploadedSelfImage;
    storyForm.uploadedSelfCoverImage = currentStoryForm.uploadedSelfCoverImage;
    storyForm.published = currentStoryForm.published;
    storyForm.imageBackgroundUri = currentStoryForm.imageBackgroundUri;
    storyForm.imageCharacterUri = currentStoryForm.imageCharacterUri;
    storyForm.imageTextInfoList = currentStoryForm.imageTextInfoList;
    storyForm.coverImageBackgroundUri =
      currentStoryForm.coverImageBackgroundUri;
    storyForm.coverImageCharacterUri = currentStoryForm.coverImageCharacterUri;
    storyForm.coverImageTextInfoList = currentStoryForm.coverImageTextInfoList;
  }
  const {
    id,
    title,
    catchPhrase,
    summary,
    introduction,
    coverIllustrator,
    rating,
    format,
    hasImage,
    originalImageUrl,
    hasCoverImage,
    originalCoverImageUrl,
    image,
    coverImage,
    uploadedSelfImage,
    uploadedSelfCoverImage,
    published,
    serialized,
    allowedVideoSharing,
    mainGenreId,
    subGenreIds,
    keywordNames,
    imageBackgroundUri,
    imageCharacterUri,
    imageTextInfoList,
    coverImageBackgroundUri,
    coverImageCharacterUri,
    coverImageTextInfoList,
  } = action.payload;
  if (id !== undefined) {
    storyForm.id = id;
  }
  if (title !== undefined) {
    storyForm.title = title;
  }
  if (catchPhrase !== undefined) {
    storyForm.catchPhrase = catchPhrase;
  }
  if (summary !== undefined) {
    storyForm.summary = summary;
  }
  if (introduction !== undefined) {
    storyForm.introduction = introduction;
  }
  if (coverIllustrator !== undefined) {
    storyForm.coverIllustrator = coverIllustrator;
  }
  if (rating !== undefined) {
    storyForm.rating = rating;
  }
  if (format !== undefined) {
    storyForm.format = format;
  }
  if (hasImage !== undefined) {
    storyForm.hasImage = hasImage;
  }
  if (originalImageUrl) {
    storyForm.originalImageUrl = originalImageUrl;
  }
  if (originalCoverImageUrl) {
    storyForm.originalCoverImageUrl = originalCoverImageUrl;
  }
  if (hasCoverImage !== undefined) {
    storyForm.hasCoverImage = hasCoverImage;
  }
  if (image !== undefined) {
    storyForm.image = image;
  }
  if (coverImage !== undefined) {
    storyForm.coverImage = coverImage;
  }
  if (uploadedSelfImage !== undefined) {
    storyForm.uploadedSelfImage = uploadedSelfImage;
  }
  if (uploadedSelfCoverImage !== undefined) {
    storyForm.uploadedSelfCoverImage = uploadedSelfCoverImage;
  }
  if (published !== undefined) {
    storyForm.published = published;
  }
  if (serialized !== undefined) {
    storyForm.serialized = serialized;
  }
  if (allowedVideoSharing !== undefined) {
    storyForm.allowedVideoSharing = allowedVideoSharing;
  }
  if (mainGenreId !== undefined) {
    storyForm.mainGenreId = mainGenreId;
  }
  if (subGenreIds !== undefined) {
    storyForm.subGenreIds = subGenreIds;
  }
  if (keywordNames !== undefined) {
    storyForm.keywordNames = keywordNames;
  }
  if (imageBackgroundUri !== undefined) {
    storyForm.imageBackgroundUri = imageBackgroundUri;
  }
  if (imageCharacterUri !== undefined) {
    storyForm.imageCharacterUri = imageCharacterUri;
  }
  if (imageTextInfoList !== undefined) {
    storyForm.imageTextInfoList = imageTextInfoList;
  }
  if (coverImageBackgroundUri !== undefined) {
    storyForm.coverImageBackgroundUri = coverImageBackgroundUri;
  }
  if (coverImageCharacterUri !== undefined) {
    storyForm.coverImageCharacterUri = coverImageCharacterUri;
  }
  if (coverImageTextInfoList !== undefined) {
    storyForm.coverImageTextInfoList = coverImageTextInfoList;
  }
  return storyForm;
}
