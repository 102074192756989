import * as React from 'react';

import EffectUsageHistoryList from '../../common/effect_usage_histories/partials/EffectUsageHistoryList';
import BottomNewEffectUsageHistoryButton from '../../common/effect_usage_histories/partials/BottomNewEffectUsageHistoryButton';
import NoEffectUsageHistories from '../../common/effect_usage_histories/partials/NoEffectUsageHistories';

import Layout from '../../../../shared/Layout';
import ResourceFavoriteListLink from '../../../../shared/ResourceFavoriteListLink';
import ResourceFavoriteButton from '../../../../shared/ResourceFavoriteButton';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormEffectShowSceneCommandNewEffectUsageHistoriesRouteProp,
  SceneFormEffectShowSceneCommandEditEffectUsageHistoriesRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as EffectUsageHistoryIndexParams} from '../../../../../actions/effect_usage_histories/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import EffectUsageHistory from '../../../../../../domain/entities/EffectUsageHistory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  back?: boolean;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormEffectShowSceneCommandNewEffectUsageHistoriesRouteProp
    | SceneFormEffectShowSceneCommandEditEffectUsageHistoriesRouteProp;
  sceneForm: SceneForm | null;
  effectUsageHistoriesParams: EffectUsageHistoryIndexParams;
  effectUsageHistories: EffectUsageHistory[] | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexEffectUsageHistories: (
    params: EffectUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<EffectUsageHistory>>;
}

interface Props extends StateProps, DispatchProps {
  onSelectEffectUsageHistory: (effectUsageHistory: EffectUsageHistory) => void;
  onForwardToNewEffectUsageHistory: () => void;
  onForwardToFavoriteList?: () => void;
}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      effectUsageHistoriesParams,
      effectUsageHistories,
      indexEffectUsageHistories,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!effectUsageHistories) {
      indexEffectUsageHistories(effectUsageHistoriesParams);
    }
  }

  public render(): React.ReactNode {
    const {
      route,
      navigation,
      effectUsageHistories,
      enabledFavorite,
      onSelectEffectUsageHistory,
      onForwardToNewEffectUsageHistory,
      onForwardToFavoriteList,
    } = this.props;

    const {back} = route.params;
    return (
      <Layout
        title={'エフェクト'}
        scrollable={false}
        back={back}
        navigation={navigation}
        close={true}>
        {effectUsageHistories ? (
          effectUsageHistories.length > 0 ? (
            <>
              <EffectUsageHistoryList
                effectUsageHistories={effectUsageHistories.filter(
                  (x, i, self) => {
                    return (
                      self
                        .map(o => o.baseEffect.id)
                        .indexOf(x.baseEffect.id) === i
                    );
                  },
                )}
                onSelectEffectUsageHistory={onSelectEffectUsageHistory}
                onForwardToFavoriteList={
                  enabledFavorite ? onForwardToFavoriteList : undefined
                }
                modalRenderFavoriteButton={this.modalRenderFavoriteButton}
              />

              <BottomNewEffectUsageHistoryButton
                onPress={onForwardToNewEffectUsageHistory}
              />
            </>
          ) : (
            <>
              <NoEffectUsageHistories
                onForwardToNewEffectUsageHistory={
                  onForwardToNewEffectUsageHistory
                }
              />
              {enabledFavorite && (
                <ResourceFavoriteListLink
                  style={{position: 'absolute', top: 16, right: 16}}
                  onPress={onForwardToFavoriteList}
                />
              )}
            </>
          )
        ) : null}
      </Layout>
    );
  }

  private modalRenderFavoriteButton = (
    effectUsageHistory: EffectUsageHistory,
  ) => {
    const {enabledFavorite} = this.props;
    if (!enabledFavorite) {
      return null;
    }
    return (
      <ResourceFavoriteButton
        style={{top: 55, right: 10}}
        resourceType={'BaseEffect'}
        resourceId={effectUsageHistory.baseEffect.id}
      />
    );
  };
}
