import * as React from 'react';
import {
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import EditableLaterMessage from '../../../../../shared/EditableLaterMessage';
import PrimaryButton from '../../../../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../../../../shared/buttons/DefaultOutlineButton';
import LabelWithOption from '../../../../../shared/forms/LabelWithOption';
import CoverPicker from '../../../../../shared/image_picker/CoverPicker';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';
import AlertModal from '../../../../../shared/modals/AlertModal';
import TooltipModal from '../../../../../shared/modals/TooltipModal';
import MaterialRightCheck from '../../../../../shared/MaterialRightCheck';
import AttachmentSoundView from '../../../../../shared/sound/AttachmentSoundView';
import UserStatusContext from '../../../../../shared/user_status/UserStatusContext';

import convertImageSource from '../../../../../../helpers/convertImageSource';

import File from '../../../../../../../domain/entities/File';
import Sound from '../../../../../../../domain/entities/Sound';

import fullScreenIllustrationUri from '../../../../../../../assets/images/tips/full_screen_illustration.png';

type TooltipModalType = 'full_screen_illustration';

const TypeToTooltipInfo = {
  full_screen_illustration: {
    title: 'スチル',
    description:
      'ストーリーの途中に挿絵を入れることができます。\nエロ・グロ・過度な暴力表現等は規約で禁止と\nなっておりますのでご注意ください。',
    source: convertImageSource(fullScreenIllustrationUri),
    imageStyle: {width: 183, height: 304},
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source: ImageSourcePropType;
    imageStyle?: ImageStyle;
  };
};

interface Props {
  onSubmit: (file: File, sound: Sound | null) => void;
  onForwardFullScreenIllustrations?: () => void;
  addSound: (callback: (sound: Sound) => void) => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {onSubmit, onForwardFullScreenIllustrations, addSound} = props;
  const [source, setSource] = React.useState<File | null>(null);
  const [sound, setSound] = React.useState<Sound | null>(null);
  const [tooltipModalType, setTooltipModal] =
    React.useState<TooltipModalType | null>(null);
  const [imagePickerError, setImagePickerError] = React.useState<string | null>(
    null,
  );
  const [confirmedMaterialRight, setConfirmedMaterialRight] =
    React.useState(false);
  const disabled = !source || !confirmedMaterialRight;
  const handlePressConfirmedMaterialRight = React.useCallback(() => {
    setConfirmedMaterialRight(!confirmedMaterialRight);
  }, [confirmedMaterialRight]);
  const onChangeImage = React.useCallback((file: File) => {
    setSource(file);
  }, []);
  const onRequestReset = React.useCallback(() => {
    setSource(null);
    setConfirmedMaterialRight(false);
  }, []);
  const handleSubmit = React.useCallback(() => {
    if (source) {
      onSubmit(source, sound);
    }
  }, [source, sound]);

  const handleAddSound = React.useCallback(() => {
    addSound(setSound);
  }, []);
  const handleDeleteSound = React.useCallback(() => {
    setSound(null);
  }, []);
  const tooltipModal = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  return (
    <View style={styles.container}>
      <View style={styles.thumbnailWrapper}>
        <>
          <View style={styles.labelWrapper}>
            <LabelWithOption
              title={'スチル'}
              onPressQuestion={() => {
                setTooltipModal('full_screen_illustration');
              }}
            />
            <DefaultOutlineButton
              style={styles.resetButton}
              fontSizeClass={'small'}
              onPress={onRequestReset}>
              リセット
            </DefaultOutlineButton>
          </View>
          <DimensionContext.Consumer>
            {context => {
              const width = context.content.width - MARGIN_HORIZONTAL * 2 - 50;
              const height = width / ASPECT_RATIO;
              return (
                <>
                  <View style={{width, height}}>
                    <CoverPicker
                      style={{height, width}}
                      source={source}
                      noImageText={'スチルなし'}
                      recommendedSize={'2480×3508'}
                      onChangeImage={onChangeImage}
                      onFailImage={setImagePickerError}
                    />
                  </View>
                  <UserStatusContext.Consumer>
                    {userStatusContext =>
                      (userStatusContext.currentUser?.enabledPaidSubscriber ||
                        userStatusContext.currentUser?.isGradeBlack()) && (
                        <View
                          style={{
                            width: width + 16,
                            height: 45,
                          }}>
                          <AttachmentSoundView
                            sound={sound}
                            addSound={handleAddSound}
                            deleteSound={handleDeleteSound}
                          />
                        </View>
                      )
                    }
                  </UserStatusContext.Consumer>
                </>
              );
            }}
          </DimensionContext.Consumer>
        </>
      </View>

      {onForwardFullScreenIllustrations ? (
        <View style={styles.button}>
          <DefaultOutlineButton
            buttonSize={{width: 200, height: 46}}
            style={{borderRadius: 23}}
            onPress={onForwardFullScreenIllustrations}>
            設定されたスチルから選ぶ
          </DefaultOutlineButton>
        </View>
      ) : null}
      <View style={{marginTop: 16}}>
        <MaterialRightCheck
          checked={confirmedMaterialRight}
          onPress={handlePressConfirmedMaterialRight}
        />
      </View>
      <View style={styles.button}>
        <EditableLaterMessage />
        <PrimaryButton disabled={disabled} onPress={handleSubmit}>
          決定
        </PrimaryButton>
      </View>
      <AlertModal
        visible={!!imagePickerError}
        onCloseModal={() => setImagePickerError(null)}>
        {imagePickerError}
      </AlertModal>
      <TooltipModal
        visible={tooltipModal ? true : false}
        title={tooltipModal?.title || ''}
        description={tooltipModal?.description || ''}
        source={tooltipModal?.source}
        imageStyle={tooltipModal?.imageStyle}
        onCloseModal={() => {
          setTooltipModal(null);
        }}></TooltipModal>
    </View>
  );
};

export default React.memo(Form);

const IMAGE_HEIGHT = 415;
const IMAGE_WIDTH = 293;
const ASPECT_RATIO = IMAGE_WIDTH / IMAGE_HEIGHT;

const MARGIN_HORIZONTAL = 40;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 32,
  } as ViewStyle,
  thumbnailWrapper: {
    flex: 1,
    alignItems: 'center',
  } as ViewStyle,
  labelWrapper: {
    flexDirection: 'row',
    height: 30,
    alignSelf: 'stretch',
    marginBottom: 14,
    marginHorizontal: MARGIN_HORIZONTAL,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    paddingVertical: 20,
  } as ViewStyle,
  editButtons: {
    flexDirection: 'row',
    marginVertical: 16,
  } as ViewStyle,
  editButton: {
    borderRadius: 20,
    width: 120,
    height: 40,
    marginHorizontal: 4,
  } as ViewStyle,
  resetButton: {
    borderRadius: 24,
    width: 84,
    height: 32,
    marginHorizontal: 4,
  } as ViewStyle,
});
