import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';
import dateformat from 'dateformat';

import FreeLabel from './FreeLabel';

import PublicStateLabel from '../../shared/PublicStateLabel';
import MoreIcon from '../../shared/icons/ionicons/MoreIcon';

import Episode from '../../../../domain/entities/Episode';

import {colors} from '../../../styles/variables';

interface Props {
  episode: Episode;
  titleStyle?: TextStyle;
  freeLabelStyle?: ViewStyle;
  copyLabel?: string | null;
  onOpenActionSheet?: (episode: Episode) => void;
}

const EpisodeInfo: React.FunctionComponent<Props> = props => {
  const {episode, titleStyle, freeLabelStyle, copyLabel, onOpenActionSheet} =
    props;
  const handleOpenActionSheet = React.useCallback(() => {
    if (onOpenActionSheet) {
      onOpenActionSheet(episode);
    }
  }, [episode]);
  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <View style={styles.titleWrapper}>
          <Text style={[styles.title, titleStyle]}>{episode.title}</Text>
          {copyLabel ? (
            <View style={styles.copyLabel}>
              <Text style={styles.copyLabelText}>{copyLabel}</Text>
            </View>
          ) : null}
        </View>
        <View style={styles.lastUpdatedAtWrapper}>
          <Text style={styles.lastUpdatedAtText}>
            {dateformat(episode.updatedAt, 'yyyy.mm.dd')}更新
          </Text>
          <PublicStateLabel
            published={episode.published}
            scheduled={
              !!episode.scheduledDeliveredAt &&
              episode.scheduledDeliveredAt > new Date()
            }
          />
          {episode.scheduledDeliveredAt && (
            <Text style={styles.scheduledDeliveredAt}>
              {dateformat(episode.scheduledDeliveredAt, 'yyyy.mm.dd HH:MM')}{' '}
              公開
              {episode.publishedDelivery() ? '' : '予定'}
            </Text>
          )}
        </View>
      </View>
      <View style={[styles.freeLabel, freeLabelStyle]}>
        {onOpenActionSheet && (
          <TouchableOpacity onPress={handleOpenActionSheet}>
            <View style={styles.moreIcon}>
              <MoreIcon />
            </View>
          </TouchableOpacity>
        )}
        <FreeLabel episode={episode} />
      </View>
    </View>
  );
};

export default onlyUpdateForKeys(['episode', 'copyLabel'])(EpisodeInfo);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 16,
  } as ViewStyle,
  top: {
    flex: 1,
  } as ViewStyle,
  titleWrapper: {
    flexDirection: 'row',
  } as ViewStyle,
  title: {
    color: '#222222',
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
  lastUpdatedAtWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    paddingTop: 10,
  } as ViewStyle,
  lastUpdatedAtText: {
    color: '#666666',
    fontSize: 11,
    paddingRight: 16,
  } as TextStyle,
  freeLabel: {
    width: 35,
    marginLeft: 16,
    alignItems: 'flex-end',
  } as ViewStyle,
  scheduledDeliveredAt: {
    color: '#666666',
    fontSize: 11,
    paddingLeft: 4,
  },
  copyLabel: {
    borderColor: colors.lightGray,
    borderWidth: 1,
    borderRadius: 100,
    height: 25,
    marginLeft: 8,
  } as ViewStyle,
  copyLabelText: {
    paddingVertical: 2,
    paddingHorizontal: 8,
    fontSize: 9,
    fontWeight: 'bold',
    lineHeight: 13,
    color: colors.gray,
  } as TextStyle,
  moreIcon: {
    marginTop: -12,
    marginRight: -4,
    marginBottom: 4,
    padding: 8,
  } as ViewStyle,
});
