import * as React from 'react';
import {InteractionManager, Text} from 'react-native';

import BackgroundEffectList from './partials/BackgroundEffectList';
import SelectedBackgroundEffectImage from './partials/SelectedBackgroundEffectImage';

import HeaderRightButton from '../../../../shared/buttons/HeaderRightButton';
import Layout from '../../../../shared/Layout';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffectsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as BaseEffectIndexParams} from '../../../../../actions/base_effects/index';
import {Params as EffectIndexParams} from '../../../../../actions/effects/index';
import {Params as SceneFormUpdateParams} from '../../../../../actions/scene_forms/update';

import {backgroundImageUrl} from '../../../../../helpers/images';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';
import scrollableLayout from '../../../../../helpers/scrollableLayout';
import {getNextPage} from '../../../../../helpers/selectEntities';

import {QueryState} from '../../../../../reducers/queries/Response';

import {linkToSceneFormBackgroundShowSceneCommandNewBackgroundEffects} from '../../../../../routers';

import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import Effect from '../../../../../../domain/entities/Effect';
import SceneForm, {
  Filter,
  EffectOptions,
} from '../../../../../../domain/forms/SceneForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

import fetchBlob from '../../../../../../data/data_stores/net/FetchBlob';

export interface Params {
  episodeId?: number;
  sceneId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffectsRouteProp;
  sceneForm: SceneForm | null;
  baseEffectsParams: BaseEffectIndexParams;
  baseEffects: Array<BaseEffect> | null;
  baseEffectQueries: QueryState;
}

export interface DispatchProps {
  indexBaseEffects: (
    params: BaseEffectIndexParams,
  ) => Promise<PaginatedResult<BaseEffect>>;
  indexEffects: (params: EffectIndexParams) => Promise<PaginatedResult<Effect>>;

  updateSceneForm: (params: SceneFormUpdateParams) => Promise<SceneForm>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  base64Uri: string | null;
  backgroundUrl: string | null;
  selectedBaseEffect: BaseEffect | null;
  selectedFilter?: Filter;
  selectedEffectLayerOrder: 'background' | 'foreground';
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedBaseEffect: null,
      selectedFilter:
        props.sceneForm && props.sceneForm.options
          ? props.sceneForm.options.filter
          : undefined,
      selectedEffectLayerOrder:
        props.sceneForm?.effectOptions?.layerOrder || 'background',
      base64Uri: null,
      backgroundUrl: null,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      baseEffects,
      baseEffectsParams,
      indexBaseEffects,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (this.props.sceneForm && this.props.sceneForm.background) {
      const {background} = this.props.sceneForm;
      InteractionManager.runAfterInteractions(() => {
        const backgroundUrl = backgroundImageUrl(background, 'large');
        this.fetchBlob(backgroundUrl).then(base64Uri => {
          this.setState({base64Uri, backgroundUrl});
        });
        const callback = (baseEffects: Array<BaseEffect>) => {
          const positionedEffect = this.props.sceneForm?.positionedEffect;
          if (!positionedEffect) {
            return;
          }
          const selectedBaseEffect = baseEffects.find(
            record => record.id === positionedEffect.baseEffectId,
          );
          if (selectedBaseEffect) {
            this.setState({selectedBaseEffect});
          }
        };
        if (!baseEffects) {
          indexBaseEffects(baseEffectsParams).then(result => {
            callback(result.records);
          });
        } else {
          callback(baseEffects);
        }
      });
    }
  }

  public render(): React.ReactNode {
    const {navigation, baseEffects, sceneForm} = this.props;
    const {
      base64Uri,
      backgroundUrl,
      selectedFilter,
      selectedBaseEffect,
      selectedEffectLayerOrder,
    } = this.state;
    const filter = selectedFilter || 'normal';
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={'背景エフェクト'}
            scrollable={scrollableLayout(context)}
            navigation={navigation}
            leftButton={{
              tintColor: '#383838',
              title: <Text>キャンセル</Text>,
              handler: () => {
                navigation.goBack();
              },
            }}
            rightButton={{
              tintColor: '#383838',
              title: (
                <HeaderRightButton onPress={this.handleSubmit}>
                  決定
                </HeaderRightButton>
              ),
            }}
            close={true}>
            {base64Uri && backgroundUrl && baseEffects && (
              <>
                <SelectedBackgroundEffectImage
                  uri={base64Uri}
                  filter={filter}
                  selectedBaseEffect={selectedBaseEffect}
                  defaultLayerOrder={selectedEffectLayerOrder}
                  onChangeLayerOrder={this.handleChangeLayerOrder}
                />
                <BackgroundEffectList
                  uri={backgroundUrl}
                  baseEffects={baseEffects}
                  selectedBaseEffect={selectedBaseEffect}
                  onSelectBaseEffect={this.handleSelectBaseEffect}
                  onEndReached={this.handleEndReached}
                />
              </>
            )}
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private handleEndReached = (info: {distanceFromEnd: number}): void => {
    const {baseEffectQueries, baseEffectsParams, indexBaseEffects} = this.props;
    const nextPage = getNextPage(baseEffectQueries, baseEffectsParams);
    if (nextPage) {
      indexBaseEffects({...baseEffectsParams, page: nextPage});
    }
  };

  private handleChangeLayerOrder = (
    selectedEffectLayerOrder: 'background' | 'foreground',
  ) => {
    this.setState({selectedEffectLayerOrder});
  };

  private handleSelectBaseEffect = (selectedBaseEffect: BaseEffect) => {
    this.setState({selectedBaseEffect});
  };

  private handleSubmit = async () => {
    const {navigation, route, sceneForm, updateSceneForm, indexEffects} =
      this.props;
    const {selectedFilter, selectedBaseEffect, selectedEffectLayerOrder} =
      this.state;
    if (!sceneForm) {
      return;
    }
    if (this.disabledSubmit) {
      return;
    }
    if (selectedBaseEffect && selectedBaseEffect.id > 0) {
      const result = await indexEffects({
        baseEffectId: selectedBaseEffect.id,
      });
      if (result.total > 1) {
        linkToSceneFormBackgroundShowSceneCommandNewBackgroundEffects(
          navigation,
          {
            baseEffectId: selectedBaseEffect.id,
            effectId: selectedBaseEffect.effect.id,
            effectLayerOrder: selectedEffectLayerOrder,
            ...route.params,
          },
        );
        return;
      }
    }
    this.disabledSubmit = true;
    const {id, background, storyId, episodeId} = sceneForm;
    const options = {filter: selectedFilter};
    const effect = selectedBaseEffect?.effect;
    const positionedEffect =
      effect && effect.id > 0 ? effect?.centerPositionedEffect : null;
    const effectOptions = positionedEffect
      ? ({
          layerOrder: selectedEffectLayerOrder || 'background',
        } as EffectOptions)
      : null;
    updateSceneForm({
      id,
      background,
      storyId,
      episodeId,
      options,
      positionedEffect,
      effectOptions,
    });
    (navigation.getParent() || navigation).goBack();
  };

  private async fetchBlob(url: string): Promise<string> {
    return fetchBlob(url);
  }
}
