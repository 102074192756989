import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';

import LinkButton from '../../shared/buttons/LinkButton';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import DefaultModal from '../../shared/modals/DefaultModal';

import {modalStyles} from '../../../styles/variables';

interface Props {
  visible: boolean;
  onLeave: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
}

const UnupdateConfirmModal: React.FunctionComponent<Props> = props => {
  const {visible, onLeave, onCancel} = props;
  return (
    <DefaultModal
      visible={visible}
      disableCloseByOutsideTouch={true}
      footer={
        <View style={styles.buttons}>
          <LinkButton style={styles.button} onPress={onCancel}>
            キャンセル
          </LinkButton>
          <PrimaryButton style={styles.button} onPress={onLeave}>
            変更しない
          </PrimaryButton>
        </View>
      }>
      <View style={styles.body}>
        <Text style={[modalStyles.text, styles.message]}>
          シーンが更新されておりません。
        </Text>
        <Text style={[modalStyles.text, styles.message]}>
          シーンへの変更が反映されませんが{'\n'}
          よろしいですか？
        </Text>
      </View>
    </DefaultModal>
  );
};

export default onlyUpdateForKeys(['visible'])(UnupdateConfirmModal);

const styles = StyleSheet.create({
  body: {
    marginHorizontal: 10,
    marginTop: 40,
    marginBottom: 16,
  } as ViewStyle,
  button: {
    paddingHorizontal: 5,
    width: 145,
    height: 46,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 32,
  } as ViewStyle,
  message: {
    paddingVertical: 5,
  } as TextStyle,
});
