import Entity from './Entity';

import Effect from './Effect';
import BaseEffect from './BaseEffect';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class EffectUsageHistory implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public effect: Effect,
    public baseEffect: BaseEffect,
    public lastUsedAt: Date,
    public createdAt: Date,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(
      this.baseEffect.originalImageUrl,
      options,
    );
  }
}
