import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';
import Input from '../../../shared/forms/Input';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

import {formStyles} from '../../../../styles/variables';

import UserRegistrationForm from '../../../../../domain/forms/writer/UserRegistrationForm';

interface Props {
  form: UserRegistrationForm;
  errorMessages: {
    email: Array<string> | undefined;
    password: Array<string> | undefined;
    passwordConfirmation: Array<string> | undefined;
  } | null;
  onChangeEmail: (email: string) => void;
  onChangePassword: (password: string) => void;
  onChangePasswordConfirmation: (passwordConfirmation: string) => void;
  onPressPoliciesPrivacy: () => void;
  onPressPoliciesTerms: () => void;
  onSubmit: () => void;
  onForwardToLogin: () => void;
  children?: React.ReactNode;
}

const Form: React.FunctionComponent<Props> = props => {
  const {
    form,
    errorMessages,
    onChangeEmail,
    onChangePassword,
    onChangePasswordConfirmation,
    onPressPoliciesPrivacy,
    onPressPoliciesTerms,
    onSubmit,
    onForwardToLogin,
  } = props;
  return (
    <>
      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'メールアドレス'}
          errorMessages={errorMessages?.email}
          convertMessage={message => {
            if (
              message ===
              '既に「TapNovel（読書サービス）」の会員となっております。'
            ) {
              return null;
            } else if (message === '既に会員となっております。') {
              return (
                <Text>
                  既に会員となっております。
                  <Pressable onPress={onForwardToLogin}>
                    <Text
                      style={{
                        fontWeight: 'bold',
                        textDecorationLine: 'underline',
                      }}>
                      ログイン
                    </Text>
                  </Pressable>
                  ください。
                </Text>
              );
            } else {
              return message;
            }
          }}
        />
        {errorMessages?.email?.includes(
          '既に「TapNovel（読書サービス）」の会員となっております。',
        ) ? (
          <Text
            style={{
              color: '#f23406',
              fontSize: 10,
            }}>
            既に「TapNovel（読書サービス）」の会員となっております。{'\n'}
            同一のアカウントでTapNovelMakerを利用するため、
            <Pressable onPress={onForwardToLogin}>
              <Text
                style={{
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                }}>
                ログイン
              </Text>
            </Pressable>
            ください。
          </Text>
        ) : null}
        <Input
          placeholder={'メールアドレスを入力'}
          style={errorMessages?.email ? styles.fieldWithErrors : undefined}
          value={form.email}
          textContentType={'emailAddress'}
          onChangeText={onChangeEmail}
          keyboardType="email-address"
          autoCapitalize="none"
          onSubmitEditing={() => handleSubmitEditing(form, onSubmit)}
        />
      </View>
      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'パスワード(8文字以上)'}
          errorMessages={errorMessages?.password}
        />

        <Input
          placeholder={'パスワードを入力'}
          style={errorMessages?.password ? styles.fieldWithErrors : undefined}
          value={form.password}
          textContentType={'newPassword'}
          onChangeText={onChangePassword}
          secureTextEntry={true}
          onSubmitEditing={() => handleSubmitEditing(form, onSubmit)}
        />
      </View>
      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'確認用パスワード'}
          errorMessages={errorMessages?.passwordConfirmation}
        />
        <Input
          placeholder={'パスワードを再度入力'}
          style={
            errorMessages?.passwordConfirmation
              ? styles.fieldWithErrors
              : undefined
          }
          value={form.passwordConfirmation}
          textContentType={'password'}
          onChangeText={onChangePasswordConfirmation}
          secureTextEntry={true}
          onSubmitEditing={() => handleSubmitEditing(form, onSubmit)}
        />
      </View>
      <View style={styles.policyBox}>
        <Text style={styles.policyText}>
          <Pressable onPress={onPressPoliciesTerms}>
            <Text style={styles.policyLink}>利用規約</Text>
          </Pressable>
          と
          <Pressable onPress={onPressPoliciesPrivacy}>
            <Text style={styles.policyLink}>プライバシーポリシー</Text>
          </Pressable>
          に同意の上ご登録ください
        </Text>
      </View>
      <View style={styles.signupButton}>
        <PrimaryButton onPress={onSubmit} disabled={!validate(form)}>
          会員登録する
        </PrimaryButton>
      </View>
      <View style={styles.login}>
        <Text style={styles.loginTitle}>会員登録が済んでいる方</Text>
        <View style={styles.loginButton}>
          <DefaultOutlineButton
            buttonSize={{width: 180, height: 34}}
            onPress={onForwardToLogin}>
            ログイン
          </DefaultOutlineButton>
        </View>
      </View>
    </>
  );
};

const handleSubmitEditing = (
  form: UserRegistrationForm,
  onSubmit: () => void,
) => {
  if (!validate(form)) {
    return;
  }
  onSubmit();
};

const validate = (form: UserRegistrationForm) => {
  if (!form.email) {
    return false;
  }
  if (!form.password) {
    return false;
  }
  if (!form.passwordConfirmation) {
    return false;
  }
  return true;
};

export default React.memo(Form);

const styles = StyleSheet.create({
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    marginTop: 30,
  } as ViewStyle,
  login: {
    alignItems: 'center',
    marginVertical: 14,
  } as ViewStyle,
  loginButton: {
    marginTop: 13,
  } as ViewStyle,
  loginTitle: {
    color: '#383838',
    fontSize: 11,
    textAlign: 'center',
  } as TextStyle,
  policyBox: {
    alignItems: 'center',
    marginTop: 32,
  } as ViewStyle,
  policyText: {
    fontSize: 11,
  } as TextStyle,
  policyLink: {
    color: '#ff8f13',
    textDecorationLine: 'underline',
  } as TextStyle,
  signupButton: {
    alignItems: 'center',
    marginVertical: 18,
  } as ViewStyle,
  fieldWithErrors: {
    backgroundColor: 'rgba(242, 52, 6, 0.3)',
    color: '#999999',
  } as ViewStyle,
});
