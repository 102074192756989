import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EpisodeReactionMapper, {
  EpisodeReactionData,
} from '../../entities/mappers/EpisodeReactionMapper';

import EpisodeReaction from '../../../domain/entities/EpisodeReaction';
import EpisodeReactionsRepository from '../../../domain/repositories/writer/EpisodeReactionsRepository';

export default class NetEpisodeReactionsRepository
  extends NetResourcesRepository<EpisodeReaction, EpisodeReactionData>
  implements EpisodeReactionsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/episode_reactions',
        new EpisodeReactionMapper(),
      ),
    );
  }
}
