import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import UserExtensionMissionListHeader from './UserExtensionMissionListHeader';
import UserExtensionMissionListItem from './UserExtensionMissionListItem';
import ProSection from './ProSection';

import UserExtensionMission from '../../../../domain/entities/writer/UserExtensionMission';

interface Props {
  userExtensionMissions: Array<UserExtensionMission>;
  onRequestExtension: (userExtensionMission: UserExtensionMission) => void;
  onPressPro: () => void;
}

const UserExtensionMissionList: React.FunctionComponent<Props> = props => {
  const {userExtensionMissions, onRequestExtension, onPressPro} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<UserExtensionMission>) => {
      return (
        <UserExtensionMissionListItem
          userExtensionMission={info.item}
          onRequestExtension={onRequestExtension}
        />
      );
    },
    [],
  );
  return (
    <FlatList
      style={{marginHorizontal: 16}}
      data={userExtensionMissions}
      ListHeaderComponent={UserExtensionMissionListHeader}
      ListFooterComponent={<ProSection onPress={onPressPro} />}
      renderItem={renderItem}
    />
  );
};

export default React.memo(UserExtensionMissionList);
