import * as React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import TextCounter from './TextCounter';

import QuestionIcon from '../../shared/icons/QuestionIcon';
import CheckCircleFillIcon from '../../shared/icons/CheckCircleFillIcon';

const REQUIRED_TYPE = 'required';

const REQUIRED_VALUE = '必須';
const OPTIONAL_VALUE = '任意';

const checked = <CheckCircleFillIcon color={'#3baf49'} size={12} />;

interface Props {
  title?: string;
  length?: number;
  maxLength?: number;
  errorMessages?: Array<string> | string;
  withUnit?: boolean;
  requiredOrOptional?: 'required' | 'optional';
  entered?: boolean;
  left?: React.ReactNode;
  convertMessage?: (message: string) => React.ReactNode;
  onPressQuestion?: () => void;
}

const LabelWithOption: React.FunctionComponent<Props> = props => {
  const {
    title,
    length,
    maxLength,
    errorMessages,
    withUnit,
    requiredOrOptional,
    entered,
    left,
    convertMessage,
    onPressQuestion,
  } = props;
  const ary = Array.isArray(errorMessages)
    ? errorMessages
    : errorMessages
    ? [errorMessages]
    : [];
  if (length && maxLength && length > maxLength) {
    ary.push('文字数オーバーです');
  }
  const message = ary.join(' / ');
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <View style={styles.labelWrapper}>
          {left ? left : null}
          {title && <Text style={styles.label}>{title}</Text>}
          {onPressQuestion && (
            <Pressable onPress={onPressQuestion}>
              <View style={styles.questionIcon}>
                <QuestionIcon />
              </View>
            </Pressable>
          )}
          {requiredOrOptional && (
            <View
              style={[
                styles.requiredOrOptionalBadge,
                requiredOrOptional === REQUIRED_TYPE
                  ? styles.requiredBadge
                  : styles.optionalBadge,
              ]}>
              <Text style={styles.requiredOrOptionalText}>
                {requiredOrOptional === REQUIRED_TYPE
                  ? REQUIRED_VALUE
                  : OPTIONAL_VALUE}
              </Text>
            </View>
          )}
          {entered && (
            <View style={styles.entered}>
              {checked}
              <Text style={styles.enteredText}>入力済</Text>
            </View>
          )}
          {message.length > 0 ? (
            <Text style={styles.message}>
              {convertMessage ? convertMessage(message) : message}
            </Text>
          ) : null}
        </View>
      </View>
      {maxLength && (
        <TextCounter
          length={length}
          maxLength={maxLength}
          withUnit={withUnit}
        />
      )}
    </View>
  );
};

export default React.memo(LabelWithOption);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  left: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  labelWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
    marginRight: 4,
    ...Platform.select({web: {whiteSpace: 'nowrap'}, default: {}}),
  } as TextStyle,
  questionIcon: {
    marginRight: 12,
  } as ViewStyle,
  message: {
    color: '#f23406',
    fontSize: 11,
    marginHorizontal: 8,
  } as TextStyle,
  requiredOrOptionalBadge: {
    borderRadius: 8,
    paddingVertical: 1,
    paddingHorizontal: 4,
  } as ViewStyle,
  requiredBadge: {
    backgroundColor: '#ff8f13',
  } as ViewStyle,
  optionalBadge: {
    backgroundColor: '#d5d5d5',
  } as ViewStyle,
  requiredOrOptionalText: {
    color: '#fff',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  entered: {
    marginLeft: 8,
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  enteredText: {
    marginLeft: 3,
    color: '#3baf49',
    fontSize: 10,
  } as TextStyle,
});
