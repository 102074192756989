import * as React from 'react';

import * as Immutable from 'immutable';

import BaseShow, {
  CommonProps,
  commonPropsAreEqual,
} from '../../base_viewers/Show';

import NetSceneScriptsRepository from '../../../../data/repositories/writer/NetSceneScriptsRepository';

export interface Params {
  episodeId: number;
  backgroundId: number | null;
  commandsAttributes: {[key: string]: any};
  options?: {filter?: any} | null;
  scriptVersion?: number;
}

interface Props extends CommonProps {
  params: Params;
  withNextScenes?: boolean;
}

const Show: React.FunctionComponent<Props> = props => {
  const {params, withNextScenes} = props;
  const sceneScriptGetTask = React.useMemo(
    () =>
      new NetSceneScriptsRepository().create({scene: params}, {withNextScenes}),
    [params],
  );
  return <BaseShow sceneScriptGetTask={sceneScriptGetTask} {...props} />;
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    Immutable.is(prevProps.params, nextProps.params) &&
    commonPropsAreEqual(prevProps, nextProps)
  );
};

export default React.memo(Show, areEqual);
