import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import EffectCategoryMapper, {
  EffectCategoryData,
} from '../entities/mappers/EffectCategoryMapper';

import EffectCategory from '../../domain/entities/EffectCategory';
import EffectCategoriesRepository from '../../domain/repositories/EffectCategoriesRepository';

export default class NetEffectCategoriesRepository
  extends NetResourcesRepository<EffectCategory, EffectCategoryData>
  implements EffectCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/effect_categories',
        new EffectCategoryMapper(),
      ),
    );
  }
}
