import * as React from 'react';
import {Platform, StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import SoundView from '../../../../../shared/sound/SoundView';
import TrimmedSoundEffectView from '../../../../../shared/sound/TrimmedSoundEffectView';
import UserStatusContext from '../../../../../shared/user_status/UserStatusContext';

import Sound from '../../../../../../../domain/entities/Sound';

import {enabledTrimmedSound} from '../../../../../../helpers/features';

interface Props {
  sound: Sound;
  startTime?: number | null;
  endTime?: number | null;
  enableTrimming?: boolean;
  onChagenRegion?: (start?: number, end?: number) => void;
  onRequestCloseModal?: () => void;
}

const SoundEffectView: React.FunctionComponent<Props> = props => {
  const {
    sound,
    startTime,
    endTime,
    enableTrimming,
    onChagenRegion,
    onRequestCloseModal,
  } = props;
  return (
    <UserStatusContext.Consumer>
      {userStatusContext =>
        enabledTrimmedSound &&
        enableTrimming &&
        (userStatusContext.currentUser?.enabledPaidSubscriber ||
          userStatusContext.currentUser?.isGradeBlack()) ? (
          <View
            style={[
              {width: '100%'},
              Platform.select({
                default: {},
                web: {
                  touchAction: 'none',
                } as any,
              }),
            ]}>
            <TrimmedSoundEffectView
              sound={sound}
              startTime={startTime}
              endTime={endTime}
              playButtonStyle={'large'}
              onChagenRegion={onChagenRegion}
              onRequestCloseModal={onRequestCloseModal}
            />
          </View>
        ) : (
          <View style={styles.container}>
            <SoundView sound={sound} iconSize={32} labelStyle={styles.label} />
          </View>
        )
      }
    </UserStatusContext.Consumer>
  );
};

export default React.memo(SoundEffectView);

const styles = StyleSheet.create({
  container: {
    height: 208,
  } as ViewStyle,
  label: {
    fontSize: 20,
    marginLeft: 8,
  } as TextStyle,
});
