import EntityMapper from './EntityMapper';

import BackgroundMapper, {BackgroundData} from './BackgroundMapper';
import PositionedEffectMapper, {
  PositionedEffectData,
} from './PositionedEffectMapper';

import Scene, {Options, EffectOptions} from '../../../domain/entities/Scene';

export interface SceneData {
  id: number;
  story_id: number;
  episode_id: number;
  title?: string | null;
  number_of_episode: number;
  background: BackgroundData | null;
  positioned_effect: PositionedEffectData | null;
  options?: Options;
  effect_options?: {
    layer_order: 'background' | 'foreground';
  };
  original_image_url: string;
  updated_at: string;
  created_at: string;
  characters_count: number;
  illustrations_count: number;
  speech_texts_count: number;
  descriptive_texts_count: number;
  tap_count: number;
  total_speech_text_length: number;
  total_descriptive_text_length: number;
}

export default class SceneMapper implements EntityMapper<SceneData, Scene> {
  private backgroundMapper: BackgroundMapper;
  private positionedEffectMapper: PositionedEffectMapper;

  constructor() {
    this.backgroundMapper = new BackgroundMapper();
    this.positionedEffectMapper = new PositionedEffectMapper();
  }

  public map(obj: SceneData): Scene {
    const background = obj.background
      ? this.backgroundMapper.map(obj.background)
      : null;
    const positionedEffect = obj.positioned_effect
      ? this.positionedEffectMapper.map(obj.positioned_effect)
      : null;
    return new Scene(
      obj.id,
      obj.story_id,
      obj.episode_id,
      obj.title || null,
      obj.number_of_episode,
      background,
      positionedEffect,
      obj.options || null,
      obj.effect_options ? {layerOrder: obj.effect_options.layer_order} : null,
      new Date(obj.updated_at),
      new Date(obj.created_at),
      obj.characters_count,
      obj.illustrations_count,
      obj.speech_texts_count,
      obj.descriptive_texts_count,
      obj.tap_count,
      obj.total_speech_text_length,
      obj.total_descriptive_text_length,
    );
  }
}
