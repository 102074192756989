import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import EntityImageGridListWithModal from '../../../../shared/EntityImageGridListWithModal';

import {characterPatternImageUrl} from '../../../../../helpers/images';

import CharacterPattern from '../../../../../../domain/entities/CharacterPattern';

const IMAGE_SIZE = 'middle';

interface Props {
  characterPatterns: CharacterPattern[];
  onEndReachedThreshold?: number | null;
  labelExtractor?: (item: CharacterPattern) => string | null;
  onSelectCharacterPattern: (characterPattern: CharacterPattern) => void;
  modalRenderFavoriteButton?: (item: CharacterPattern) => React.ReactNode;
}

export default class CharacterPatternList extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      characterPatterns,
      labelExtractor,
      onSelectCharacterPattern,
      modalRenderFavoriteButton,
    } = this.props;
    return (
      <EntityImageGridListWithModal
        entities={characterPatterns}
        style={styles.container}
        aspectRatio={aspectRatio}
        ListFooterComponent={ListFooterComponent}
        labelExtractor={labelExtractor}
        onSelectEntity={onSelectCharacterPattern}
        imageUrlExtractor={this.imageUrlExtractor}
        imageStyleExtractor={this.imageStyleExtractor}
        modalRenderFavoriteButton={modalRenderFavoriteButton}
      />
    );
  }

  private imageUrlExtractor = (item: CharacterPattern, width: number) => {
    return characterPatternImageUrl(item, IMAGE_SIZE);
  };

  private imageStyleExtractor = (item: CharacterPattern) => {
    if (!item.character.inverted) {
      return null;
    }
    return {transform: [{scaleX: -1}]};
  };
}

const aspectRatio = 0.7;

const styles = StyleSheet.create({
  container: {
    paddingBottom: Platform.select({web: 150, default: undefined}),
  } as ViewStyle,
  spacer: {
    height: 150,
    width: '100%',
  } as ViewStyle,
});

const ListFooterComponent = Platform.select({
  web: undefined,
  default: <View style={styles.spacer} />,
});
