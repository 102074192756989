import * as React from 'react';

import Icon from './Icon';

import {colors} from '../../../styles/variables';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const PartnerProgramIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 9;
  const color = props.color || colors.orange;
  return <Icon name={'partner_program'} size={size} color={color} />;
};

export default React.memo(PartnerProgramIcon);
