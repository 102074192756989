import * as React from 'react';
import {
  Platform,
  StyleSheet,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {NameLabel as BaseNameLabel} from '../../../vendor/react-native-tapnovel-viewer';

import PenIcon from './icons/PenIcon';

import NameLabelColor from '../../view_models/NameLabelColor';

import {isMobile} from '../../../data/data_stores/net/UserAgent';

const PEN_COLOR = 'white';

interface Props {
  name: string;
  color?: NameLabelColor;
  editable?: boolean;
  onChangeName?: (name: string) => void;
}

export default class NameLabel extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return (
      this.props.name !== nextProps.name || this.props.color !== nextProps.color
    );
  }

  public render(): React.ReactNode {
    const {name, editable, onChangeName} = this.props;
    const color = this.props.color || NameLabelColor.Green;
    return (
      <BaseNameLabel
        name={name}
        color={color}
        windowWidth={300}
        height={editable ? 30 : 21}
        fontSize={13}
        defaultTextWidth={editable ? 120 : undefined}
        active={true}>
        {editable ? (
          <>
            <TextInput
              style={styles.nameTextInput}
              defaultValue={name}
              editable={editable}
              onChangeText={onChangeName}
            />
            <View style={styles.penIcon}>
              <PenIcon color={PEN_COLOR} size={15} />
            </View>
          </>
        ) : undefined}
      </BaseNameLabel>
    );
  }
}

const styles = StyleSheet.create({
  nameTextInput: {
    position: 'absolute',
    width: 120,
    top: 2,
    left: 5,
    lineHeight: Platform.select({
      android: 1,
      default: undefined,
    }),
    color: 'white',
    ...(isMobile ? {} : {fontFamily: 'sans-serif'}),
    letterSpacing: 1.0,
    paddingBottom: 0,
  } as TextStyle,
  penIcon: {
    position: 'absolute',
    left: 130,
    top: 5,
  } as ViewStyle,
});
