import * as React from 'react';
import {
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {WebView} from 'react-native-webview';

import KeyboardSpacer from '../../shared/KeyboardSpacer';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import BlackButton from '../../shared/buttons/BlackButton';
import Select from '../../shared/forms/Select';
import TextArea from '../../shared/forms/TextArea';
import LabelWithOption from '../../shared/forms/LabelWithOption';
import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import HelpList from '../../helps/partials/HelpList';

import {formStyles} from '../../../styles/variables';

import WriterCurrentUser from '../../../../domain/entities/writer/CurrentUser';
import ContactCategory from '../../../../domain/entities/writer/ContactCategory';
import Help from '../../../../domain/entities/writer/Help';
import WriterContactForm from '../../../../domain/forms/writer/ContactForm';

interface Props {
  writerCurrentUser: WriterCurrentUser;
  writerContactForm: WriterContactForm | null;
  contactCategories: ContactCategory[];
  helps: Help[];
  onPressCommunity: () => void;
  onSubmit: (writerContactForm: WriterContactForm) => void;
  onFowrardToHelps: () => void;
}

interface State {
  writerContactForm: WriterContactForm;
  helpIdToCollapsed: {[key: string]: boolean};
  noteHtmlHeight: number;
}

export default class Form extends React.PureComponent<Props, State> {
  private ref = React.createRef<ScrollView>();

  constructor(props: Props) {
    super(props);
    const email =
      props.writerCurrentUser.verifiedEmailAddress() &&
      props.writerCurrentUser.enabled
        ? props.writerCurrentUser.email
        : '';
    this.state = {
      writerContactForm:
        this.props.writerContactForm || new WriterContactForm(null, email, ''),
      helpIdToCollapsed: {},
      noteHtmlHeight: 0,
    };
  }

  public render(): React.ReactNode {
    const {helps, contactCategories, onFowrardToHelps} = this.props;
    const {writerContactForm, helpIdToCollapsed} = this.state;
    const {writerContactCategoryId, email, content} = writerContactForm;
    const currentContactCategory = contactCategories.find(
      contactCategory => contactCategory.id === writerContactCategoryId,
    );
    return (
      <View style={styles.container}>
        <ScrollView ref={this.ref}>
          <View style={[styles.formGroup, styles.formGroupHelp]}>
            <Text style={styles.helpText}>
              こちらはTapNovelMaker（執筆サービス）のお問い合わせページとなります。
              {'\n'}
              TapNovel（読書サービス）へのお問い合わせは、TapNovelの画面よりお願いいたします。
              {'\n\n'}
              よくいただくご質問を
              <Pressable onPress={onFowrardToHelps}>
                <View style={styles.helpLink}>
                  <Text style={styles.helpLinkText}>ヘルプ</Text>
                </View>
              </Pressable>
              にてまとめてございます。{'\n\n'}
              同じ質問がないか必ずご確認の上、お問い合わせいただきますようお願いいたします。
            </Text>
          </View>

          <View style={styles.formGroup}>
            <Text style={styles.headlineText}>よくあるご質問</Text>
          </View>
          <ListItemNormalSeparator />
          <HelpList
            helps={helps}
            helpIdToCollapsed={helpIdToCollapsed}
            onPressHelp={this.handlePressHelp}
          />
          <View style={{alignItems: 'center', margin: 24}}>
            <BlackButton style={{width: 240}} onPress={onFowrardToHelps}>
              ヘルプを見る
            </BlackButton>
          </View>
          <View style={styles.formGroup}>
            <Text style={styles.helpMessage}>
              ヘルプを読んでも解決しない場合や、ご要望以外のお問い合わせは、以下のフォームよりご入力ください。
            </Text>
          </View>

          <View style={styles.formGroup}>
            <Text style={styles.headlineText}>お問い合わせフォーム</Text>
            <View style={styles.contactMessageWrapper}>
              <Text style={styles.contactMessage}>
                お問い合わせは、土日祝日を除く平日10時〜17時に順次返答させていただいておりますが、ご返答までにお時間を頂戴する可能性がございますのであらかじめご了承ください。
                {'\n'}
                また、「info@tapnovel.com」からのメールを受信できる設定になっているか必ずご確認ください。
              </Text>
            </View>
          </View>

          <View style={styles.formGroup}>
            <LabelWithOption title={'返信用メールアドレス'} />
            <View style={{borderBottomColor: '#efefef', borderBottomWidth: 1}}>
              <TextInput
                style={formStyles.formInput}
                keyboardType={'email-address'}
                autoCapitalize={'none'}
                placeholder={'返信用メールアドレスを入力'}
                value={email}
                onChangeText={this.handleChangeEmail}
                returnKeyType={'done'}
              />
            </View>
          </View>

          <View style={styles.formGroup}>
            <LabelWithOption title={'お問い合わせカテゴリ'} />
            <Select
              value={writerContactCategoryId || ''}
              items={this.generateContactCategoryItems()}
              onValueChange={this.handleonValueWriterContactCategory}
              confirmText={'決定'}
              cancelText={'キャンセル'}
              rightIcon={<View style={styles.arrowIcon} />}
            />
          </View>

          {currentContactCategory && (
            <View
              onLayout={() => {
                this.ref.current?.scrollToEnd();
              }}>
              {currentContactCategory.note && (
                <View style={{marginHorizontal: 16}}>
                  <WebView
                    source={{
                      html: this.generateNoteHtml(currentContactCategory.note),
                    }}
                    style={{
                      maxHeight: this.state.noteHtmlHeight,
                      marginBottom: 16,
                    }}
                    onLoad={event => {
                      const target = event.target as any;
                      if (target && target.contentDocument) {
                        const noteHtmlHeight =
                          target.contentDocument.querySelector(
                            '#main',
                          ).clientHeight;
                        this.setState({noteHtmlHeight});
                      }
                    }}
                    scrollEnabled={false}
                  />
                </View>
              )}
              <View style={[styles.formGroup, styles.formGroupTextArea]}>
                <LabelWithOption title={'お問い合わせ内容'} />
                <TextArea
                  style={[formStyles.formTextArea, styles.formTextArea]}
                  value={content}
                  placeholder={'お問い合わせ内容を入力'}
                  onChangeText={this.handleChangeContent}
                />
              </View>

              <View style={styles.submitButton}>
                <PrimaryButton
                  disabled={!writerContactForm.validate()}
                  onPress={this.handlePressButton}>
                  内容を確認
                </PrimaryButton>
              </View>
            </View>
          )}
        </ScrollView>
        <KeyboardSpacer />
      </View>
    );
  }

  private handleonValueWriterContactCategory = (
    writerContactCategoryId: string | number,
  ) => {
    const writerContactForm = new WriterContactForm(
      writerContactCategoryId ? Number(writerContactCategoryId) : null,
      this.state.writerContactForm.email,
      this.state.writerContactForm.content,
    );
    this.setState({writerContactForm});
  };

  private handleChangeEmail = (email: string) => {
    const writerContactForm = new WriterContactForm(
      this.state.writerContactForm.writerContactCategoryId,
      email,
      this.state.writerContactForm.content,
    );
    this.setState({writerContactForm});
  };

  private handleChangeContent = (content: string) => {
    const writerContactForm = new WriterContactForm(
      this.state.writerContactForm.writerContactCategoryId,
      this.state.writerContactForm.email,
      content,
    );
    this.setState({writerContactForm});
  };

  private handlePressButton = () => {
    const {onSubmit} = this.props;
    const {writerContactForm} = this.state;
    if (writerContactForm.validate()) {
      onSubmit(writerContactForm);
    }
  };

  private generateContactCategoryItems = () => {
    return [
      {value: '', label: '選択してください'},
      ...this.props.contactCategories.map(contactCategory => {
        return {value: contactCategory.id, label: contactCategory.name};
      }),
    ];
  };

  private handlePressHelp = (help: Help) => {
    const {helpIdToCollapsed} = this.state;
    const collapsed =
      helpIdToCollapsed[help.id] === undefined
        ? true
        : helpIdToCollapsed[help.id];
    this.setState({
      helpIdToCollapsed: {
        ...helpIdToCollapsed,
        [help.id]: !collapsed,
      },
    });
  };

  private generateNoteHtml = (note: string) => {
    return `
    <html>
      <head>
        <style>
          html, body {
            margin: 0;
            padding: 0;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
              "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
              sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            overscroll-behavior-y: contain;
          }

          a, p {
            color: #222;
            margin: 0;
            padding: 0;
            border: 0;
          }
          a {
            font-weight: bold;

          }
          p {
            font-size: 14px;
            font-weight: 400;
          }
        </style>
      </head>
      <body>
        <div id="main">
          <p>
            ${note}
          </p>
        </div>
      </body>
    </html>
  `;
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  } as ViewStyle,
  headlineText: {
    color: '#383838',
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
  formGroup: {
    margin: 16,
  } as ViewStyle,
  formGroupHelp: {
    marginTop: 41,
  } as ViewStyle,
  formGroupTextArea: {
    marginBottom: 0,
  } as ViewStyle,
  submitButton: {
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  helpText: {
    color: '#222222',
    fontSize: 15,
    lineHeight: 20,
  } as TextStyle,
  helpLink: {
    borderBottomWidth: 1,
    borderBottomColor: '#383838',
  } as ViewStyle,
  helpLinkText: {
    color: '#222222',
    fontWeight: 'bold',
  } as TextStyle,
  helpMessage: {
    color: '#383838',
    fontSize: 15,
    lineHeight: 17,
  } as TextStyle,
  contactMessageWrapper: {
    marginTop: 16,
  } as ViewStyle,
  contactMessage: {
    color: '#222222',
    fontSize: 15,
    lineHeight: 20,
  } as TextStyle,
  formTextArea: {
    height: 174,
  } as TextStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
    right: 3,
  } as ViewStyle,
});
