import * as React from 'react';
import {InteractionManager, View} from 'react-native';

import DimensionContext from '../../../../../shared/dimension/DimensionContext';

import {GLImageFilter} from '../../../../../../../vendor/react-native-tapnovel-viewer';

import {Filter} from '../../../../../../../domain/forms/SceneForm';

interface Props {
  uri: string;
  filter: Filter;
}

const SelectedBackgroundFilterImage: React.FunctionComponent<Props> = props => {
  const {uri, filter} = props;
  const [readly, setReadly] = React.useState(false);
  React.useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      setReadly(true);
    });
  });
  return (
    <DimensionContext.Consumer>
      {context => {
        const {width} = context.content;
        const size = {width, height: width / aspectRatio};
        return (
          <View style={size}>
            {readly ? (
              <GLImageFilter size={size} filter={filter} uri={uri} />
            ) : null}
          </View>
        );
      }}
    </DimensionContext.Consumer>
  );
};

const aspectRatio = 1.25;

export default React.memo(SelectedBackgroundFilterImage);
