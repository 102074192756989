import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import SceneFrame from '../../view_models/SceneFrame';

import {Params as CreatePayload} from '../../actions/scene_command_forms/create';
import {Params as UpdatePayload} from '../../actions/scene_command_forms/update';
import {Params as DestroyPayload} from '../../actions/scene_command_forms/destroy';

import CharacterPatternMapping from '../../view_models/CharacterPatternMapping';

import CharacterHideSceneCommandForm from '../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import CharacterShowSceneCommandForm from '../../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';

import SceneCommandForm from '../../../domain/forms/scene_commands/SceneCommandForm';

import Position from '../../../domain/value_objects/Position';

export default function reducer(
  state: SceneCommandForm[] | null = null,
  action: AnyAction,
): SceneCommandForm[] | null {
  const sceneCommandForms = state || [];
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return null;
    case Types.CreateSceneCommandFormList:
      return action.payload;
    case Types.UpdateSceneCommandFormList:
      return action.payload;
    case Types.DestroySceneForm:
      return null;
    case Types.CreateSceneCommandForm:
      return createSceneCommandForm(action, sceneCommandForms);
    case Types.UpdateSceneCommandForm:
      return updateSceneCommandForm(action, sceneCommandForms);
    case Types.DestroySceneCommandForm:
      return destroySceneCommandForm(action, sceneCommandForms);
    default:
      return state;
  }
}

function createSceneCommandForm(
  action: AnyAction,
  sceneCommandForms: SceneCommandForm[],
) {
  const payload = action.payload as CreatePayload;
  if (Number.isInteger(payload.index)) {
    const sceneCommandForm = payload.sceneCommandForm;
    const followingSceneCommandForms = sceneCommandForms.slice(payload.index);
    return optimizeSameBackgroundMusicSceneCommandForm(
      optimizeSameCharacterSceneCommandForm(
        updateCharacterPatternByMapping(
          sceneCommandForms
            .slice(0, payload.index)
            .concat([payload.sceneCommandForm])
            .concat(
              sceneCommandForm instanceof CharacterHideSceneCommandForm
                ? filterSameCharacterSceneCommandFormForPositionAfterHide(
                    followingSceneCommandForms,
                    sceneCommandForm.position,
                  )
                : followingSceneCommandForms,
            ),
          sceneCommandForm,
          payload.characterPatternMapping,
        ),
      ),
    );
  } else {
    return sceneCommandForms.concat(payload.sceneCommandForm);
  }
}

function updateSceneCommandForm(
  action: AnyAction,
  sceneCommandForms: SceneCommandForm[],
) {
  const payload = action.payload as UpdatePayload;
  const ret = sceneCommandForms.map(sceneCommandForm => {
    if (
      sceneCommandForm.sceneCommandId ===
      payload.sceneCommandForm.sceneCommandId
    ) {
      return payload.sceneCommandForm;
    } else {
      return sceneCommandForm;
    }
  });
  return optimizeSameBackgroundMusicSceneCommandForm(
    updateCharacterSceneCommandWaiting(
      optimizeSameCharacterSceneCommandForm(
        updateCharacterPatternByMapping(
          normalizeCompositeSequenceSceneCommandForm(ret),
          payload.sceneCommandForm,
          payload.characterPatternMapping,
        ),
      ),
    ),
  );
}

function destroySceneCommandForm(
  action: AnyAction,
  sceneCommandForms: SceneCommandForm[],
) {
  const payload = action.payload as DestroyPayload;
  const removeTargetCommand = payload.sceneCommandForm;
  const removeIndex = sceneCommandForms.findIndex(
    command => command.sceneCommandId === removeTargetCommand.sceneCommandId,
  );
  if (removeIndex === -1) {
    return sceneCommandForms;
  }

  const removeIndexArr: number[] = [removeIndex];
  if (
    removeTargetCommand instanceof CharacterShowSceneCommandForm ||
    removeTargetCommand instanceof CharacterUpdateSceneCommandForm
  ) {
    const position = removeTargetCommand.position;
    const prevCharacterCommand = getSamePositionCharacterCommand(
      position,
      sceneCommandForms.slice(0, removeIndex).reverse(),
      removeTargetCommand.characterPattern.id,
    );
    const nextCharacterCommand = getSamePositionCharacterCommand(
      position,
      sceneCommandForms.slice(removeIndex + 1, sceneCommandForms.length),
      removeTargetCommand.characterPattern.id,
    );

    if (
      (prevCharacterCommand === undefined ||
        prevCharacterCommand instanceof CharacterHideSceneCommandForm) &&
      nextCharacterCommand instanceof CharacterHideSceneCommandForm
    ) {
      removeIndexArr.push(sceneCommandForms.indexOf(nextCharacterCommand));
    }
  }

  return optimizeSameBackgroundMusicSceneCommandForm(
    optimizeSameCharacterSceneCommandForm(
      sceneCommandForms.filter(
        (command, index) => removeIndexArr.indexOf(index) === -1,
      ),
    ),
  );
}

function getSamePositionCharacterCommand(
  position: Position,
  commands: SceneCommandForm[],
  characterPatternId: number,
) {
  return commands.find(command => {
    if (
      command instanceof CharacterHideSceneCommandForm &&
      command.position === position
    ) {
      return true;
    } else if (
      (command instanceof CharacterShowSceneCommandForm ||
        command instanceof CharacterUpdateSceneCommandForm) &&
      command.position === position &&
      command.characterPattern.id === characterPatternId
    ) {
      return true;
    } else {
      return false;
    }
  });
}

function optimizeSameCharacterSceneCommandForm(
  sceneCommandForms: SceneCommandForm[],
) {
  return switchCharacterSceneCommand(
    filterSameCharacterSceneCommandForm(sceneCommandForms),
  );
}

function filterSameCharacterSceneCommandForm(
  sceneCommandForms: SceneCommandForm[],
) {
  const sceneFrame = new SceneFrame();
  return sceneCommandForms.filter(sceneCommandForm => {
    if (
      sceneCommandForm instanceof CharacterShowSceneCommandForm ||
      sceneCommandForm instanceof CharacterUpdateSceneCommandForm ||
      sceneCommandForm instanceof CharacterHideSceneCommandForm
    ) {
      const prevCharacterSceneCommandForm = sceneFrame.get(
        sceneCommandForm.position,
      );
      sceneFrame.update(sceneCommandForm.position, sceneCommandForm);
      if (
        (prevCharacterSceneCommandForm &&
          !(
            prevCharacterSceneCommandForm instanceof
            CharacterHideSceneCommandForm
          ) &&
          sceneCommandForm instanceof CharacterHideSceneCommandForm) ||
        (prevCharacterSceneCommandForm instanceof
          CharacterHideSceneCommandForm &&
          !(sceneCommandForm instanceof CharacterHideSceneCommandForm))
      ) {
        return true;
      } else if (
        prevCharacterSceneCommandForm &&
        prevCharacterSceneCommandForm.actorCharacterFace.id ===
          sceneCommandForm.actorCharacterFace.id &&
        prevCharacterSceneCommandForm.characterPattern.id ===
          sceneCommandForm.characterPattern.id &&
        ((prevCharacterSceneCommandForm.mark === null &&
          sceneCommandForm.mark === null) ||
          (prevCharacterSceneCommandForm.mark &&
            sceneCommandForm.mark &&
            prevCharacterSceneCommandForm.mark.id === sceneCommandForm.mark.id))
      ) {
        return false;
      }
      return true;
    } else if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      sceneCommandForm.commandForms = sceneCommandForm.commandForms.filter(
        subCommandForm => {
          if (subCommandForm instanceof CompositeParallelSceneCommandForm) {
            subCommandForm.commandForms = subCommandForm.commandForms.filter(
              commandForm => {
                const prevCharacterSceneCommandForm = sceneFrame.get(
                  commandForm.position,
                );
                sceneFrame.update(commandForm.position, commandForm);
                if (
                  (prevCharacterSceneCommandForm &&
                    !(
                      prevCharacterSceneCommandForm instanceof
                      CharacterHideSceneCommandForm
                    ) &&
                    commandForm instanceof CharacterHideSceneCommandForm) ||
                  (prevCharacterSceneCommandForm instanceof
                    CharacterHideSceneCommandForm &&
                    !(commandForm instanceof CharacterHideSceneCommandForm))
                ) {
                  return true;
                } else if (
                  prevCharacterSceneCommandForm &&
                  prevCharacterSceneCommandForm.actorCharacterFace.id ===
                    commandForm.actorCharacterFace.id &&
                  prevCharacterSceneCommandForm.characterPattern.id ===
                    commandForm.characterPattern.id &&
                  ((prevCharacterSceneCommandForm.mark === null &&
                    commandForm.mark === null) ||
                    (prevCharacterSceneCommandForm.mark &&
                      commandForm.mark &&
                      prevCharacterSceneCommandForm.mark.id ===
                        commandForm.mark.id))
                ) {
                  return false;
                } else {
                  return true;
                }
              },
            );
            return subCommandForm.commandForms.length > 0;
          } else if (
            subCommandForm instanceof CharacterShowSceneCommandForm ||
            subCommandForm instanceof CharacterUpdateSceneCommandForm ||
            subCommandForm instanceof CharacterHideSceneCommandForm
          ) {
            const prevCharacterSceneCommandForm = sceneFrame.get(
              subCommandForm.position,
            );
            sceneFrame.update(subCommandForm.position, subCommandForm);
            if (
              (prevCharacterSceneCommandForm &&
                !(
                  prevCharacterSceneCommandForm instanceof
                  CharacterHideSceneCommandForm
                ) &&
                subCommandForm instanceof CharacterHideSceneCommandForm) ||
              (prevCharacterSceneCommandForm instanceof
                CharacterHideSceneCommandForm &&
                !(subCommandForm instanceof CharacterHideSceneCommandForm))
            ) {
              return true;
            } else if (
              prevCharacterSceneCommandForm &&
              prevCharacterSceneCommandForm.actorCharacterFace.id ===
                subCommandForm.actorCharacterFace.id &&
              prevCharacterSceneCommandForm.characterPattern.id ===
                subCommandForm.characterPattern.id &&
              ((prevCharacterSceneCommandForm.mark === null &&
                subCommandForm.mark === null) ||
                (prevCharacterSceneCommandForm.mark &&
                  subCommandForm.mark &&
                  prevCharacterSceneCommandForm.mark.id ===
                    subCommandForm.mark.id))
            ) {
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        },
      );
      return sceneCommandForm.commandForms.length > 0;
    } else if (sceneCommandForm instanceof CompositeParallelSceneCommandForm) {
      sceneCommandForm.commandForms = sceneCommandForm.commandForms.filter(
        subCommandForm => {
          const prevCharacterSceneCommandForm = sceneFrame.get(
            subCommandForm.position,
          );
          sceneFrame.update(subCommandForm.position, subCommandForm);
          if (
            (prevCharacterSceneCommandForm &&
              !(
                prevCharacterSceneCommandForm instanceof
                CharacterHideSceneCommandForm
              ) &&
              subCommandForm instanceof CharacterHideSceneCommandForm) ||
            (prevCharacterSceneCommandForm instanceof
              CharacterHideSceneCommandForm &&
              !(subCommandForm instanceof CharacterHideSceneCommandForm))
          ) {
            return true;
          } else if (
            prevCharacterSceneCommandForm &&
            prevCharacterSceneCommandForm.actorCharacterFace.id ===
              subCommandForm.actorCharacterFace.id &&
            prevCharacterSceneCommandForm.characterPattern.id ===
              subCommandForm.characterPattern.id &&
            ((prevCharacterSceneCommandForm.mark === null &&
              subCommandForm.mark === null) ||
              (prevCharacterSceneCommandForm.mark &&
                subCommandForm.mark &&
                prevCharacterSceneCommandForm.mark.id ===
                  subCommandForm.mark.id))
          ) {
            return false;
          } else {
            return true;
          }
        },
      );
      return sceneCommandForm.commandForms.length > 0;
    } else {
      return true;
    }
  });
}

function switchCharacterSceneCommand(sceneCommandForms: SceneCommandForm[]) {
  const sceneFrame = new SceneFrame();
  return sceneCommandForms.map(sceneCommandForm => {
    if (
      sceneCommandForm instanceof CharacterShowSceneCommandForm ||
      sceneCommandForm instanceof CharacterUpdateSceneCommandForm ||
      sceneCommandForm instanceof CharacterHideSceneCommandForm
    ) {
      const prevCharacterSceneCommandForm = sceneFrame.get(
        sceneCommandForm.position,
      );
      sceneFrame.update(sceneCommandForm.position, sceneCommandForm);
      if (
        (!prevCharacterSceneCommandForm ||
          prevCharacterSceneCommandForm instanceof
            CharacterHideSceneCommandForm ||
          prevCharacterSceneCommandForm.characterPattern.id !==
            sceneCommandForm.characterPattern.id) &&
        sceneCommandForm instanceof CharacterUpdateSceneCommandForm
      ) {
        const newSceneCommandForm = new CharacterShowSceneCommandForm(
          sceneCommandForm.characterPattern,
          sceneCommandForm.actorCharacterFace,
          sceneCommandForm.mark,
          sceneCommandForm.position,
          false,
        );
        sceneFrame.update(newSceneCommandForm.position, newSceneCommandForm);
        return newSceneCommandForm;
      } else if (
        (prevCharacterSceneCommandForm instanceof
          CharacterShowSceneCommandForm ||
          prevCharacterSceneCommandForm instanceof
            CharacterUpdateSceneCommandForm) &&
        sceneCommandForm instanceof CharacterShowSceneCommandForm &&
        prevCharacterSceneCommandForm.characterPattern.id ===
          sceneCommandForm.characterPattern.id
      ) {
        const newSceneCommandForm = new CharacterUpdateSceneCommandForm(
          sceneCommandForm.characterPattern,
          sceneCommandForm.actorCharacterFace,
          sceneCommandForm.mark,
          sceneCommandForm.position,
        );
        sceneFrame.update(newSceneCommandForm.position, newSceneCommandForm);
        return newSceneCommandForm;
      } else if (
        (prevCharacterSceneCommandForm instanceof
          CharacterShowSceneCommandForm ||
          prevCharacterSceneCommandForm instanceof
            CharacterUpdateSceneCommandForm) &&
        sceneCommandForm instanceof CharacterHideSceneCommandForm &&
        prevCharacterSceneCommandForm.characterPattern.id ===
          sceneCommandForm.characterPattern.id &&
        (prevCharacterSceneCommandForm.mark?.id !== sceneCommandForm.mark?.id ||
          prevCharacterSceneCommandForm.actorCharacterFace.id !==
            sceneCommandForm.actorCharacterFace.id)
      ) {
        const newSceneCommandForm = new CharacterHideSceneCommandForm(
          sceneCommandForm.characterPattern,
          prevCharacterSceneCommandForm.actorCharacterFace,
          prevCharacterSceneCommandForm.mark,
          sceneCommandForm.position,
        );
        sceneFrame.update(newSceneCommandForm.position, newSceneCommandForm);
        return newSceneCommandForm;
      }
      return sceneCommandForm;
    } else if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      sceneCommandForm.commandForms.forEach(subCommandForm => {
        if (subCommandForm instanceof CompositeParallelSceneCommandForm) {
          subCommandForm.commandForms.forEach((commandForm, i) => {
            const prevCharacterSceneCommandForm = sceneFrame.get(
              commandForm.position,
            );
            if (
              (!prevCharacterSceneCommandForm ||
                prevCharacterSceneCommandForm instanceof
                  CharacterHideSceneCommandForm ||
                prevCharacterSceneCommandForm.characterPattern.id !==
                  commandForm.characterPattern.id) &&
              commandForm instanceof CharacterUpdateSceneCommandForm
            ) {
              subCommandForm.commandForms[i] =
                new CharacterShowSceneCommandForm(
                  commandForm.characterPattern,
                  commandForm.actorCharacterFace,
                  commandForm.mark,
                  commandForm.position,
                  false,
                );
            } else if (
              (prevCharacterSceneCommandForm instanceof
                CharacterShowSceneCommandForm ||
                prevCharacterSceneCommandForm instanceof
                  CharacterUpdateSceneCommandForm) &&
              commandForm instanceof CharacterShowSceneCommandForm &&
              prevCharacterSceneCommandForm.characterPattern.id ===
                commandForm.characterPattern.id
            ) {
              subCommandForm.commandForms[i] =
                new CharacterUpdateSceneCommandForm(
                  commandForm.characterPattern,
                  commandForm.actorCharacterFace,
                  commandForm.mark,
                  commandForm.position,
                );
            } else if (
              (prevCharacterSceneCommandForm instanceof
                CharacterShowSceneCommandForm ||
                prevCharacterSceneCommandForm instanceof
                  CharacterUpdateSceneCommandForm) &&
              commandForm instanceof CharacterHideSceneCommandForm &&
              prevCharacterSceneCommandForm.characterPattern.id ===
                commandForm.characterPattern.id &&
              (prevCharacterSceneCommandForm.mark?.id !==
                commandForm.mark?.id ||
                prevCharacterSceneCommandForm.actorCharacterFace.id !==
                  commandForm.actorCharacterFace.id)
            ) {
              subCommandForm.commandForms[i] =
                new CharacterHideSceneCommandForm(
                  commandForm.characterPattern,
                  prevCharacterSceneCommandForm.actorCharacterFace,
                  prevCharacterSceneCommandForm.mark,
                  commandForm.position,
                );
            }
            const newCommandForm = subCommandForm.commandForms[i];
            sceneFrame.update(newCommandForm.position, newCommandForm);
          });
        }
      });
    }
    return sceneCommandForm;
  });
}

function updateCharacterSceneCommandWaiting(
  sceneCommandForms: SceneCommandForm[],
) {
  let waiting = true;
  sceneCommandForms.forEach((sceneCommandForm, i) => {
    if (
      sceneCommandForm instanceof FullScreenIllustrationShowSceneCommandForm ||
      sceneCommandForm instanceof BackgroundMusicShowSceneCommandForm
    ) {
      return;
    }
    if (!(sceneCommandForm instanceof CharacterShowSceneCommandForm)) {
      waiting = false;
      return;
    }
    if (!waiting && sceneCommandForm.waiting) {
      sceneCommandForm.waiting = false;
      return;
    }
    if (!sceneCommandForm.waiting) {
      waiting = false;
      return;
    }
  });
  return sceneCommandForms;
}

function filterSameCharacterSceneCommandFormForPositionAfterHide(
  sceneCommandForms: SceneCommandForm[],
  position: Position,
) {
  let skip = false;
  return sceneCommandForms.filter(sceneCommandForm => {
    if (skip) {
      return true;
    }
    if (
      (sceneCommandForm instanceof CharacterShowSceneCommandForm ||
        sceneCommandForm instanceof CharacterHideSceneCommandForm) &&
      sceneCommandForm.position === position
    ) {
      skip = true;
      return sceneCommandForm instanceof CharacterShowSceneCommandForm;
    } else if (
      sceneCommandForm instanceof CharacterUpdateSceneCommandForm &&
      sceneCommandForm.position === position
    ) {
      return false;
    } else if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      const firstSubSceneCommandForm = sceneCommandForm.commandForms[0];
      if (
        firstSubSceneCommandForm instanceof CompositeParallelSceneCommandForm
      ) {
        firstSubSceneCommandForm.commandForms =
          firstSubSceneCommandForm.commandForms.filter(commandForm => {
            if (
              (commandForm instanceof CharacterShowSceneCommandForm ||
                commandForm instanceof CharacterHideSceneCommandForm) &&
              commandForm.position === position
            ) {
              skip = true;
              return commandForm instanceof CharacterShowSceneCommandForm;
            } else if (
              commandForm instanceof CharacterUpdateSceneCommandForm &&
              commandForm.position === position
            ) {
              return false;
            }
            return true;
          });
        return true;
      }
    }
    return true;
  });
}

function updateCharacterPatternByMapping(
  sceneCommandForms: SceneCommandForm[],
  targetSceneCommandForm: SceneCommandForm,
  characterPatternMapping: CharacterPatternMapping | undefined,
) {
  if (!characterPatternMapping) {
    return sceneCommandForms;
  }
  const index = sceneCommandForms.findIndex(
    sceneCommandForm =>
      sceneCommandForm.sceneCommandId === targetSceneCommandForm.sceneCommandId,
  );
  if (index === -1) {
    return sceneCommandForms;
  }
  return sceneCommandForms.slice(0, index).concat(
    [sceneCommandForms[index]].concat(
      sceneCommandForms
        .slice(index + 1, sceneCommandForms.length)
        .map(sceneCommandForm => {
          if (
            sceneCommandForm instanceof CharacterShowSceneCommandForm ||
            sceneCommandForm instanceof CharacterUpdateSceneCommandForm ||
            sceneCommandForm instanceof CharacterHideSceneCommandForm
          ) {
            return updateCharacterPatternByMappingForCharacterSceneCommandForm(
              sceneCommandForm,
              characterPatternMapping,
            );
          } else if (
            sceneCommandForm instanceof CompositeSequenceSceneCommandForm
          ) {
            return updateCharacterPatternByMappingForCompositeSequenceSceneCommandForm(
              sceneCommandForm,
              characterPatternMapping,
            );
          } else if (
            sceneCommandForm instanceof CompositeParallelSceneCommandForm
          ) {
            return updateCharacterPatternByMappingForCompositeParallelSceneCommandForm(
              sceneCommandForm,
              characterPatternMapping,
            );
          }
          return sceneCommandForm;
        }),
    ),
  );
}

function updateCharacterPatternByMappingForCharacterSceneCommandForm(
  sceneCommandForm:
    | CharacterShowSceneCommandForm
    | CharacterUpdateSceneCommandForm
    | CharacterHideSceneCommandForm,
  characterPatternMapping: CharacterPatternMapping,
) {
  const characterPatternAndActorCharacterFace =
    characterPatternMapping.getCharacterPatternAndActorCharacterFace(
      sceneCommandForm.characterPattern,
      sceneCommandForm.actorCharacterFace,
      sceneCommandForm.position,
    );
  if (characterPatternAndActorCharacterFace) {
    const newSceneCommandForm = sceneCommandForm.copy();
    newSceneCommandForm.characterPattern =
      characterPatternAndActorCharacterFace.characterPattern;
    newSceneCommandForm.actorCharacterFace =
      characterPatternAndActorCharacterFace.actorCharacterFace;
    return newSceneCommandForm;
  } else {
    return sceneCommandForm;
  }
}

function updateCharacterPatternByMappingForCompositeSequenceSceneCommandForm(
  sceneCommandForm: CompositeSequenceSceneCommandForm,
  characterPatternMapping: CharacterPatternMapping,
) {
  const newSceneCommandForm = sceneCommandForm.copy();
  let changed = false;
  newSceneCommandForm.commandForms = sceneCommandForm.commandForms.map(
    subCommandForm => {
      if (subCommandForm instanceof CompositeParallelSceneCommandForm) {
        const newSubCommandForm =
          updateCharacterPatternByMappingForCompositeParallelSceneCommandForm(
            subCommandForm,
            characterPatternMapping,
          );
        if (newSubCommandForm !== subCommandForm) {
          changed = true;
        }
        return newSubCommandForm;
      } else if (
        subCommandForm instanceof CharacterShowSceneCommandForm ||
        subCommandForm instanceof CharacterUpdateSceneCommandForm ||
        subCommandForm instanceof CharacterHideSceneCommandForm
      ) {
        const newSubCommandForm =
          updateCharacterPatternByMappingForCharacterSceneCommandForm(
            subCommandForm,
            characterPatternMapping,
          );
        if (newSubCommandForm !== subCommandForm) {
          changed = true;
        }
        return newSubCommandForm;
      } else {
        return subCommandForm;
      }
    },
  );
  if (changed) {
    return newSceneCommandForm;
  } else {
    return sceneCommandForm;
  }
}

function updateCharacterPatternByMappingForCompositeParallelSceneCommandForm(
  sceneCommandForm: CompositeParallelSceneCommandForm,
  characterPatternMapping: CharacterPatternMapping,
) {
  const newSceneCommandForm = sceneCommandForm.copy();
  let changed = false;
  newSceneCommandForm.commandForms = sceneCommandForm.commandForms.map(
    subCommandForm => {
      if (
        subCommandForm instanceof CharacterShowSceneCommandForm ||
        subCommandForm instanceof CharacterUpdateSceneCommandForm ||
        subCommandForm instanceof CharacterHideSceneCommandForm
      ) {
        const newSubCommandForm =
          updateCharacterPatternByMappingForCharacterSceneCommandForm(
            subCommandForm,
            characterPatternMapping,
          );
        if (newSubCommandForm !== subCommandForm) {
          changed = true;
        }
        return newSubCommandForm;
      } else {
        return subCommandForm;
      }
    },
  );
  if (changed) {
    return newSceneCommandForm;
  } else {
    return sceneCommandForm;
  }
}

function optimizeSameBackgroundMusicSceneCommandForm(
  sceneCommandForms: SceneCommandForm[],
) {
  let currentBackgroundMusicSceneCommandForm: BackgroundMusicShowSceneCommandForm | null =
    null;
  return sceneCommandForms
    .map(sceneCommandForm => {
      if (sceneCommandForm instanceof BackgroundMusicShowSceneCommandForm) {
        const tmpCurrentBackgroundMusicSceneCommandForm =
          currentBackgroundMusicSceneCommandForm;
        currentBackgroundMusicSceneCommandForm = sceneCommandForm;
        if (tmpCurrentBackgroundMusicSceneCommandForm) {
          if (
            tmpCurrentBackgroundMusicSceneCommandForm.sound.id ===
            sceneCommandForm.sound.id
          ) {
            return null;
          }
        }
      } else if (
        sceneCommandForm instanceof BackgroundMusicHideSceneCommandForm
      ) {
        const tmpCurrentBackgroundMusicSceneCommandForm =
          currentBackgroundMusicSceneCommandForm;
        currentBackgroundMusicSceneCommandForm = null;
        if (tmpCurrentBackgroundMusicSceneCommandForm) {
          if (
            tmpCurrentBackgroundMusicSceneCommandForm.sound.id !==
            sceneCommandForm.sound.id
          ) {
            const newSceneCommandForm = sceneCommandForm.copy();
            newSceneCommandForm.sound =
              tmpCurrentBackgroundMusicSceneCommandForm.sound;
            return newSceneCommandForm;
          }
        } else {
          return null;
        }
      }
      return sceneCommandForm;
    })
    .filter(notEmpty);
}

function normalizeCompositeSequenceSceneCommandForm(
  sceneCommandForms: SceneCommandForm[],
): SceneCommandForm[] {
  return recomposeSceneCommandForms(
    sceneCommandForms.flatMap(sceneCommandForm => {
      if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
        return decomposeByNotCompositeSequenceCommandForm(
          decomposeByBackgroundMusicSceneCommandForm(sceneCommandForm),
        );
      } else {
        return sceneCommandForm;
      }
    }),
  );
}

function decomposeByBackgroundMusicSceneCommandForm(
  sceneCommandForm: CompositeSequenceSceneCommandForm,
): SceneCommandForm[] {
  if (sceneCommandForm.commandForms.length === 0) {
    return [];
  }
  const foundIndex = sceneCommandForm.commandForms.findIndex(
    commandForm =>
      commandForm instanceof BackgroundMusicShowSceneCommandForm ||
      commandForm instanceof BackgroundMusicHideSceneCommandForm,
  );
  if (foundIndex === -1) {
    return [sceneCommandForm];
  }
  if (sceneCommandForm.commandForms.length <= 2) {
    return sceneCommandForm.commandForms;
  } else {
    const ret = [];
    const forward = sceneCommandForm.commandForms.slice(0, foundIndex);
    const backward = sceneCommandForm.commandForms.slice(foundIndex + 1);
    if (forward.length > 0) {
      ret.push(
        forward.length === 1 &&
          !(forward[0] instanceof CompositeParallelSceneCommandForm)
          ? forward[0]
          : new CompositeSequenceSceneCommandForm(forward),
      );
    }
    ret.push(sceneCommandForm.commandForms[foundIndex]);
    if (backward.length > 0) {
      ret.push(
        backward.length === 1 &&
          !(backward[0] instanceof CompositeParallelSceneCommandForm)
          ? backward[0]
          : new CompositeSequenceSceneCommandForm(backward),
      );
    }
    return ret;
  }
}

function decomposeByNotCompositeSequenceCommandForm(
  sceneCommandForms: SceneCommandForm[],
): SceneCommandForm[] {
  return sceneCommandForms.flatMap(sceneCommandForm => {
    if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      const first = sceneCommandForm.commandForms[0];
      const second = sceneCommandForm.commandForms[1];
      const third = sceneCommandForm.commandForms[2];
      if (
        first instanceof CompositeParallelSceneCommandForm &&
        (second instanceof CharacterShowSceneCommandForm ||
          second instanceof CharacterUpdateSceneCommandForm ||
          second instanceof CharacterHideSceneCommandForm)
      ) {
        if (second instanceof CharacterShowSceneCommandForm) {
          second.waiting = false;
        }
        return [
          new CompositeSequenceSceneCommandForm(
            [first],
            sceneCommandForm.sceneCommandId,
          ),
          new CompositeSequenceSceneCommandForm(
            [new CompositeParallelSceneCommandForm([second]), third].filter(
              notEmpty,
            ),
          ),
        ];
      }
      return [
        new CompositeSequenceSceneCommandForm(
          sceneCommandForm.commandForms.slice(0, 2),
          sceneCommandForm.sceneCommandId,
        ),
        ...sceneCommandForm.commandForms.slice(2),
      ];
    } else {
      return sceneCommandForm;
    }
  });
}

function recomposeSceneCommandForms(
  sceneCommandForms: SceneCommandForm[],
): SceneCommandForm[] {
  let skip = false;
  return sceneCommandForms.flatMap((sceneCommandForm, index) => {
    const nextSceneCommandForm = sceneCommandForms[index + 1];
    if (skip) {
      skip = false;
      return [];
    }
    if (
      sceneCommandForm instanceof CompositeSequenceSceneCommandForm &&
      sceneCommandForm.commandForms.length === 1
    ) {
      if (kindOfCharacterSceneCommandForm(sceneCommandForm.commandForms[0])) {
        if (kindOfComposableSceneCommandForm(nextSceneCommandForm)) {
          skip = true;
          return buildCompositeParallelSceneCommandFormForRecompose(
            sceneCommandForm.commandForms[0],
            nextSceneCommandForm,
          );
        } else if (
          nextSceneCommandForm instanceof CompositeSequenceSceneCommandForm &&
          nextSceneCommandForm.commandForms.length === 1 &&
          kindOfComposableSceneCommandForm(nextSceneCommandForm.commandForms[0])
        ) {
          skip = true;
          return buildCompositeParallelSceneCommandFormForRecompose(
            sceneCommandForm.commandForms[0],
            nextSceneCommandForm.commandForms[0],
          );
        }
        return sceneCommandForm;
      }
    } else if (kindOfCharacterSceneCommandForm(sceneCommandForm)) {
      if (kindOfComposableSceneCommandForm(nextSceneCommandForm)) {
        skip = true;
        return buildCompositeParallelSceneCommandFormForRecompose(
          sceneCommandForm,
          nextSceneCommandForm,
        );
      } else if (
        nextSceneCommandForm instanceof CompositeSequenceSceneCommandForm &&
        nextSceneCommandForm.commandForms.length === 1 &&
        kindOfComposableSceneCommandForm(nextSceneCommandForm.commandForms[0])
      ) {
        skip = true;
        return buildCompositeParallelSceneCommandFormForRecompose(
          sceneCommandForm,
          nextSceneCommandForm.commandForms[0],
        );
      }
      return sceneCommandForm;
    }
    return sceneCommandForm;
  });
}

function buildCompositeParallelSceneCommandFormForRecompose(
  first: SceneCommandForm,
  second: SceneCommandForm,
) {
  return new CompositeSequenceSceneCommandForm(
    [
      first instanceof CharacterShowSceneCommandForm ||
      first instanceof CharacterUpdateSceneCommandForm ||
      first instanceof CharacterHideSceneCommandForm
        ? new CompositeParallelSceneCommandForm([first])
        : first,
      second,
    ],
    first.sceneCommandId,
  );
}

function kindOfCharacterSceneCommandForm(sceneCommandForm: SceneCommandForm) {
  return (
    sceneCommandForm instanceof CompositeParallelSceneCommandForm ||
    sceneCommandForm instanceof CharacterShowSceneCommandForm ||
    sceneCommandForm instanceof CharacterUpdateSceneCommandForm ||
    sceneCommandForm instanceof CharacterHideSceneCommandForm
  );
}

function kindOfComposableSceneCommandForm(sceneCommandForm: SceneCommandForm) {
  return (
    sceneCommandForm instanceof SpeechTextShowSceneCommandForm ||
    sceneCommandForm instanceof DescriptiveTextShowSceneCommandForm ||
    sceneCommandForm instanceof EffectShowSceneCommandForm ||
    sceneCommandForm instanceof SoundEffectShowSceneCommandForm ||
    sceneCommandForm instanceof IllustrationShowSceneCommandForm ||
    sceneCommandForm instanceof FullScreenIllustrationShowSceneCommandForm
  );
}

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
