import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import FacebookIcon from '../../../shared/icons/FacebookIcon';
import TwitterIcon from '../../../shared/icons/TwitterIcon';
import LineIcon from '../../../shared/icons/LineIcon';

import {colors} from '../../../../styles/variables';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';
import OauthAccount from '../../../../../domain/entities/OauthAccount';

interface Props {
  currentUser: CurrentUser;
  oauthAccounts: OauthAccount[] | null;
  onPressEmailChange: () => void;
  onPressPasswordChange: () => void;
  onPressTwitterLogin: () => void;
  onPressFacebookLogin: () => void;
  onPressLineLogin: () => void;
}

export default class Settings extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      currentUser,
      oauthAccounts,
      onPressEmailChange,
      onPressPasswordChange,
      onPressTwitterLogin,
      onPressFacebookLogin,
      onPressLineLogin,
    } = this.props;
    return (
      <View style={styles.container}>
        <TouchableOpacity activeOpacity={0.8} onPress={onPressEmailChange}>
          <View style={styles.formGroup}>
            <Text style={styles.formLabel}>メールアドレス</Text>
            <Text style={styles.formValue}>
              {currentUser.getDisplayableEmail()}
            </Text>
          </View>
        </TouchableOpacity>

        <TouchableOpacity activeOpacity={0.8} onPress={onPressPasswordChange}>
          <View style={styles.formGroup}>
            <Text style={styles.formLabel}>パスワード</Text>
            <Text style={styles.formValue}>**************</Text>
          </View>
        </TouchableOpacity>

        {oauthAccounts && (
          <View style={[styles.formGroup, styles.formGroupSns]}>
            <Text style={[styles.formLabel, styles.formLabelSns]}>SNS連携</Text>
            <View style={styles.formValueSns}>
              <TouchableOpacity
                activeOpacity={0.8}
                onPress={onPressFacebookLogin}>
                <View style={styles.snsItemContainer}>
                  <View style={styles.snsItemIconWrapper}>
                    <View style={styles.snsItemIconFacebookWrapper}>
                      <View style={styles.snsItemIconFacebookInner}>
                        <FacebookIcon size={12} color={'white'} />
                      </View>
                    </View>
                  </View>
                  <Text style={styles.snsItemLabel}>Facebook</Text>
                  <View style={styles.connected}>
                    <Text style={styles.snsItemValue}>
                      {this.getFacebookOauthAccounts() ? '連携済' : ''}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
              <TouchableOpacity activeOpacity={0.8} onPress={onPressLineLogin}>
                <View style={styles.snsItemContainer}>
                  <View style={styles.snsItemIconWrapper}>
                    <LineIcon size={12} color={colors.line} />
                  </View>
                  <Text style={styles.snsItemLabel}>Line</Text>
                  <View style={styles.connected}>
                    <Text style={styles.snsItemValue}>
                      {this.getLineOauthAccounts() ? '連携済' : ''}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    );
  }

  private getTwitterOauthAccounts = (): OauthAccount | undefined => {
    return this.getOauthAccount('twitter');
  };

  private getFacebookOauthAccounts = (): OauthAccount | undefined => {
    return this.getOauthAccount('facebook');
  };

  private getLineOauthAccounts = (): OauthAccount | undefined => {
    return this.getOauthAccount('line');
  };

  private getOauthAccount = (
    oauthProviderCode: string,
  ): OauthAccount | undefined => {
    const {oauthAccounts} = this.props;
    if (!oauthAccounts) {
      return undefined;
    }
    return oauthAccounts.find(
      oauthAccount => oauthAccount.oauthProviderCode === oauthProviderCode,
    );
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  formGroup: {
    flexDirection: 'row',
    paddingHorizontal: 16,
    alignItems: 'center',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    height: 47,
  } as ViewStyle,
  formGroupSns: {
    height: 129,
    paddingTop: 2,
  } as ViewStyle,
  formLabel: {
    width: 100,
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  formLabelSns: {
    height: 129,
    paddingTop: 18,
  } as TextStyle,
  formValue: {
    color: '#383838',
    fontSize: 15,
  } as TextStyle,
  formValueSns: {
    flex: 1,
    height: 129,
    paddingTop: 6,
  } as ViewStyle,
  snsItemContainer: {
    height: 38,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  snsItemIconWrapper: {
    width: 32,
  } as ViewStyle,
  snsItemIconFacebookWrapper: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: '#6c6c6c',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  } as ViewStyle,
  snsItemIconFacebookInner: {
    position: 'absolute',
    bottom: 0,
    right: 4,
  } as ViewStyle,
  snsItemLabel: {
    color: '#383838',
    fontSize: 15,
    lineHeight: 15,
  } as TextStyle,
  snsItemValue: {
    color: '#383838',
    fontSize: 11,
  } as TextStyle,
  connected: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
  } as ViewStyle,
});
