import * as React from 'react';
import {
  LayoutChangeEvent,
  ScrollView,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {useActionSheet} from '@expo/react-native-action-sheet';

import CharacterList from './CharacterList';
import NewCharacterButton from './NewCharacterButton';

import Character from '../../../../domain/entities/Character';

interface Props {
  characters: Character[] | null;
  scrollEnabled?: boolean;
  sortingEnabled?: boolean;
  disableNewCharacter?: boolean;
  onSelectCharacter: (character: Character) => void;
  onForwardToNewCharacter: () => void;
  onLayout?: (event: LayoutChangeEvent) => void;
  onChangeOrder: (character: Character, rowOrderPosition: number) => void;
  onStartSort?: () => void;
  onEndSort?: () => void;
  onDeleteCharacter: (character: Character) => void;
  children?: React.ReactNode;
}

const CharacterListWithNewButton: React.FunctionComponent<Props> = props => {
  const {
    characters,
    scrollEnabled,
    sortingEnabled,
    disableNewCharacter,
    onSelectCharacter,
    onLayout,
    onChangeOrder,
    onForwardToNewCharacter,
    onStartSort,
    onEndSort,
  } = props;
  const {showActionSheetWithOptions} = useActionSheet();
  if (!characters) {
    return null;
  }
  const onOpenActionSheet = React.useCallback((character: Character) => {
    const options = ['削除', 'キャンセル'];
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        destructiveButtonIndex,
        options,
      },
      (buttonIndex?: number) => {
        switch (buttonIndex) {
          case destructiveButtonIndex:
            props.onDeleteCharacter(character);
            break;
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, []);
  const key = Math.max(...characters.map(c => c.updatedAt.getTime()));
  return (
    <ScrollView bounces={false} scrollEnabled={scrollEnabled}>
      <View key={key} style={styles.container} onLayout={onLayout}>
        <CharacterList
          characters={characters}
          sortingEnabled={sortingEnabled}
          onSelectCharacter={onSelectCharacter}
          onChangeOrder={onChangeOrder}
          onStartSort={onStartSort}
          onEndSort={onEndSort}
          onOpenActionSheet={onOpenActionSheet}
        />
        {disableNewCharacter ? null : (
          <NewCharacterButton
            label={
              characters.length === 0 ? '新しいキャラクターをつくる' : null
            }
            onForwardToNewCharacter={onForwardToNewCharacter}
          />
        )}
      </View>
    </ScrollView>
  );
};

export default CharacterListWithNewButton;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
  } as ViewStyle,
});
