import * as React from 'react';
import {
  ImageStyle,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';
import ExitIcon from '../../../../../shared/icons/ExitIcon';
import EnterIcon from '../../../../../shared/icons/EnterIcon';
import WaitingIcon from '../../../../../shared/icons/WaitingIcon';

import {
  actorCharacterFaceImageUrl,
  markImageUrl,
} from '../../../../../../helpers/images';

import {colors} from '../../../../../../styles/variables';

import ActorCharacterFace from '../../../../../../../domain/entities/ActorCharacterFace';
import Mark from '../../../../../../../domain/entities/Mark';

const RESIZE_MODE = 'contain';
const MARK_IMAGE_SIZE = 'large';
const CHARACTER_IMAGE_SIZE = 'middle';
const headers = {Accept: 'image/webp,image/apng,*/*'};

interface Props {
  actorCharacterFace: ActorCharacterFace;
  mark?: Mark | null;
  label?: string;
  characterName?: string;
  transparent?: boolean;
  characterHide?: boolean;
  characterShow?: boolean;
  characterWaiting?: boolean;
  inverted?: boolean;
  children?: React.ReactNode;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {
    actorCharacterFace,
    mark,
    label,
    characterName,
    transparent,
    characterHide,
    characterShow,
    characterWaiting,
    inverted,
  } = props;
  return (
    <View style={styles.container}>
      <View
        style={[
          transparent ? styles.containerTransparent : undefined,
          characterHide ? styles.hideTransparent : undefined,
        ]}>
        {mark && (
          <FastImageAdapter
            style={styles.mark}
            resizeMode={RESIZE_MODE}
            source={{
              uri: markImageUrl(mark, MARK_IMAGE_SIZE),
              headers,
            }}
          />
        )}
        <FastImageAdapter
          style={[styles.image, inverted ? styles.inverted : null]}
          resizeMode={RESIZE_MODE}
          source={{
            uri: actorCharacterFaceImageUrl(
              actorCharacterFace,
              CHARACTER_IMAGE_SIZE,
            ),
            headers,
          }}
        />
      </View>
      {label && (
        <View style={styles.label}>
          <View
            style={[
              styles.labelInner,
              !characterHide && styles.labelBackground,
            ]}>
            {characterShow && enterIcon}
            {characterHide && exitIcon}
            {characterWaiting && waitingIcon}
            <Text
              style={[styles.labelText, characterHide && styles.hideLabelText]}>
              {label}
            </Text>
          </View>
        </View>
      )}
      {characterName ? (
        <View style={styles.characterName}>
          <Text style={styles.characterNameLabel}>{characterName}</Text>
        </View>
      ) : null}
    </View>
  );
};

export default React.memo(CharacterView);

const markWidth = 25;
const markHeight = 25;
const markAspectRatio = markWidth / markHeight;

const characterWidth = 150;
const characterHeight = 150;
const characterAspectRatio = characterWidth / characterHeight;

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
    height: 150,
    alignItems: 'center',
    justifyContent: 'flex-end',
  } as ViewStyle,
  containerTransparent: {
    opacity: 0.5,
  } as ViewStyle,
  hideIcon: {
    marginLeft: 3,
  } as ViewStyle,
  hideLabelText: {
    color: colors.gray,
  } as TextStyle,
  hideTransparent: {
    opacity: 0.2,
  } as ViewStyle,
  icon: {
    backgroundColor: 'transparent',
    marginBottom: 4,
  } as ViewStyle,
  image: {
    aspectRatio: characterAspectRatio,
    height: characterHeight,
    justifyContent: 'center',
    width: characterWidth,
  } as ImageStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ImageStyle,
  label: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    height: characterHeight,
    justifyContent: 'flex-end',
    paddingBottom: 20,
    position: 'absolute',
    width: characterWidth,
  } as ViewStyle,
  labelBackground: {
    backgroundColor: 'rgba(0, 0, 0, .5)',
    borderRadius: 3,
  } as ViewStyle,
  labelInner: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 10,
    paddingVertical: 6,
  } as ViewStyle,
  labelText: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  characterName: {
    position: 'absolute',
    bottom: -20,
    width: characterWidth,
  } as ViewStyle,
  characterNameLabel: {
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 'bold',
    color: '#666666',
  } as TextStyle,
  mark: {
    aspectRatio: markAspectRatio,
    height: markHeight,
    left: 30,
    position: 'absolute',
    width: markWidth,
    zIndex: 10,
  } as ImageStyle,
  showIcon: {
    marginRight: 3,
  } as ViewStyle,
  waitingIcon: {} as ViewStyle,
});

const enterIcon = (
  <View style={[styles.icon, styles.showIcon]}>
    <EnterIcon color={'white'} />
  </View>
);

const exitIcon = (
  <View style={[styles.icon, styles.hideIcon]}>
    <ExitIcon color={'#383838'} />
  </View>
);

const waitingIcon = (
  <View style={[styles.icon, styles.waitingIcon]}>
    <WaitingIcon color={'white'} />
  </View>
);
