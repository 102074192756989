import * as React from 'react';
import {View, ViewStyle} from 'react-native';

import EpisodeVideoExportJobExecutionList from './EpisodeVideoExportJobExecutionList';
import NewEpisodeVideoExportJobExecutionButton from './NewEpisodeVideoExportJobExecutionButton';
import NoEpisodeVideoExportJobExecutions from './NoEpisodeVideoExportJobExecutions';

import EpisodeVideoExportJobExecution from '../../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

interface Props {
  episodeVideoExportJobExecutions: Array<EpisodeVideoExportJobExecution>;
  onSelectEpisodeVideoExportJobExecution: (
    episodeVideoExportJobExecution: EpisodeVideoExportJobExecution,
  ) => void;
  onDeleteEpisodeVideoExportJobExecution: (
    episodeVideoExportJobExecution: EpisodeVideoExportJobExecution,
  ) => void;
  onForwardToNewEpisodeVideoExportJobExecution: () => void;
}

const EpisodeVideoExportJobExecutionListWithNewButton: React.FunctionComponent<
  Props
> = props => {
  const {
    episodeVideoExportJobExecutions,
    onForwardToNewEpisodeVideoExportJobExecution,
  } = props;
  if (episodeVideoExportJobExecutions.length === 0) {
    return (
      <NoEpisodeVideoExportJobExecutions
        onForwardToNewEpisodeVideoExportJobExecution={
          onForwardToNewEpisodeVideoExportJobExecution
        }
      />
    );
  }
  return (
    <>
      <EpisodeVideoExportJobExecutionList {...props} />
      <View style={buttonWrapperStyle}>
        <NewEpisodeVideoExportJobExecutionButton
          style={buttonStyle}
          onForwardToNewEpisodeVideoExportJobExecution={
            onForwardToNewEpisodeVideoExportJobExecution
          }
        />
      </View>
    </>
  );
};

export default React.memo(EpisodeVideoExportJobExecutionListWithNewButton);

const buttonWrapperStyle = {
  position: 'absolute',
  bottom: 0,
  left: 0,
  right: 0,
} as ViewStyle;

const buttonStyle = {
  backgroundColor: 'rgba(255,255,255,0.9)',
  paddingTop: 16,
} as ViewStyle;
