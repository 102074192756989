export default class TextNormalizer {
  public static normalize(
    text: string,
    type?: 'descriptive_text' | 'speech_text' | null,
  ) {
    switch (type) {
      case 'descriptive_text':
        return TextNormalizer.normalizeForDescriptiveText(text);
      case 'speech_text':
        return TextNormalizer.normalizeForSpeechText(text);
      default:
        return TextNormalizer.doNormalize(text);
    }
  }

  private static normalizeForDescriptiveText(text: string) {
    return TextNormalizer.doNormalize(text);
  }

  private static normalizeForSpeechText(text: string) {
    return TextNormalizer.doNormalize(text)
      .replaceAll(/^「([^」]+)」$/g, '$1')
      .replaceAll(/。$/g, '');
  }

  private static doNormalize(text: string) {
    return (
      text
        .trim()
        // eslint-disable-next-line no-irregular-whitespace
        .replaceAll(/^　+/g, '')
        // eslint-disable-next-line no-irregular-whitespace
        .replaceAll(/　+$/g, '')
        .replaceAll(/[０-９]/g, TextNormalizer.converHalfWidthNumber)
        .replaceAll(
          /[^0-9.]([0-9])(?![0-9.])/g,
          TextNormalizer.converFullWidthNumberAll,
        )
        .replaceAll(
          /^([0-9])(?![0-9.])/g,
          TextNormalizer.converFullWidthNumberAll,
        )
        .replaceAll('！', '!')
        .replaceAll('？', '?')
        .replaceAll(
          /[^!?][!?](?![!?])/g,
          TextNormalizer.convertFullWidthSymbolAll,
        )
        .replaceAll('…', '・・・')
        .replaceAll(/(^|[^ー])ーー$|(^|[^―])――$/g, TextNormalizer.convertDash)
        .replaceAll('(', '（')
        .replaceAll(')', '）')
        .replaceAll('[', '［')
        .replaceAll(']', '］')
        .replaceAll('｢', '「')
        .replaceAll('｣', '」')
        .replaceAll(':', '：')
    );
  }

  public static converFullWidthNumberAll(text: string) {
    return text.replace(/[0-9]/g, TextNormalizer.converFullWidthNumber);
  }

  public static convertFullWidthSymbolAll(text: string) {
    return text.replaceAll('!', '！').replaceAll('?', '？');
  }

  public static converFullWidthNumber(str: string) {
    return String.fromCharCode(str.charCodeAt(0) + 0xfee0);
  }

  public static converHalfWidthNumber(str: string) {
    return String.fromCharCode(str.charCodeAt(0) - 0xfee0);
  }

  public static convertDash(text: string) {
    return text.replaceAll(/ー|―/g, '─');
  }
}
