import * as React from 'react';
import {InteractionManager, ScrollView, View} from 'react-native';

import SoundCategorySelect from './partials/SoundCategorySelect';
import SearchInput from './partials/SearchInput';
import SearchButtonWithTotalCount from './partials/SearchButtonWithTotalCount';

import PopularTagsBox from '../../../../shared/PopularTagsBox';
import Layout from '../../../../shared/Layout';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormRouteProp,
  SceneFormBackgroundMusicShowSceneCommandEditSoundSearchFormRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as SoundCategoryIndexParams} from '../../../../../actions/sound_categories/index';
import {Params as SoundIndexParams} from '../../../../../actions/sounds/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import Sound from '../../../../../../domain/entities/Sound';
import SoundCategory from '../../../../../../domain/entities/SoundCategory';
import PopularTag from '../../../../../../domain/entities/PopularTag';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import NetPopularTagsRepository from '../../../../../../data/repositories/writer/NetPopularTagsRepository';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  selectedLargeSoundCategoryId?: number | null;
  selectedMiddleSoundCategoryId?: number | null;
  selectedSmallSoundCategoryId?: number | null;
  favorite?: boolean;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormRouteProp
    | SceneFormBackgroundMusicShowSceneCommandEditSoundSearchFormRouteProp;
  sceneForm: SceneForm | null;
  largeSoundCategoriesParams: SoundCategoryIndexParams;
  largeSoundCategories: SoundCategory[] | null;
  middleSoundCategoriesParams: SoundCategoryIndexParams;
  middleSoundCategories: SoundCategory[] | null;
  smallSoundCategoriesParams: SoundCategoryIndexParams;
  smallSoundCategories: SoundCategory[] | null;
}

export interface DispatchProps {
  indexSoundCategories: (
    params: SoundCategoryIndexParams,
  ) => Promise<PaginatedResult<SoundCategory>>;
  indexSounds: (params: SoundIndexParams) => Promise<PaginatedResult<Sound>>;
}

interface Props extends StateProps, DispatchProps {
  onSubmit: (params: {query?: string; soundCategoryId?: number}) => void;
}

interface State {
  popularTags?: Array<PopularTag> | null;
  totalCount: number;
  query: string;
}

export default class Index extends React.Component<Props, State> {
  private timerId: any | null = null;

  constructor(props: Props) {
    super(props);
    const {query} = props.route.params;
    this.state = {
      totalCount: 0,
      query: query || '',
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      largeSoundCategories,
      largeSoundCategoriesParams,
      indexSoundCategories,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    InteractionManager.runAfterInteractions(() => {
      new NetPopularTagsRepository()
        .findBy({taggableType: 'BackgroundMusic'})
        .then(result => {
          this.setState({popularTags: result.records});
        });
      if (!largeSoundCategories) {
        indexSoundCategories(largeSoundCategoriesParams);
      }
      this.fetchTotalCount();
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {
      route,
      indexSoundCategories,
      middleSoundCategoriesParams,
      smallSoundCategoriesParams,
    } = this.props;
    const {
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId,
    } = route.params;
    const {
      selectedLargeSoundCategoryId: prevSelectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId: prevSelectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId: prevSelectedSmallSoundCategoryId,
    } = prevProps.route.params;
    let updated = false;
    if (selectedLargeSoundCategoryId !== prevSelectedLargeSoundCategoryId) {
      indexSoundCategories(middleSoundCategoriesParams);
      updated = true;
    }
    if (selectedMiddleSoundCategoryId !== prevSelectedMiddleSoundCategoryId) {
      indexSoundCategories(smallSoundCategoriesParams);
      updated = true;
    }
    if (selectedSmallSoundCategoryId !== prevSelectedSmallSoundCategoryId) {
      updated = true;
    }
    if (updated) {
      this.fetchTotalCount();
    }
  }

  public render(): React.ReactNode {
    const {
      navigation,
      route,
      largeSoundCategories,
      middleSoundCategories,
      smallSoundCategories,
    } = this.props;
    const {query, totalCount, popularTags} = this.state;
    const {
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId,
      hideBack,
    } = route.params;
    return (
      <Layout
        title={'BGM検索条件'}
        back={hideBack ? false : true}
        close={true}
        navigation={navigation}
        scrollable={false}>
        <ScrollView>
          {largeSoundCategories && popularTags && (
            <>
              <SearchInput query={query} onChangeText={this.handleChangeText} />
              <PopularTagsBox
                popularTags={popularTags}
                onSelectPopularTag={this.handleSelectPopularTag}
              />
              <View style={{marginHorizontal: 16, marginTop: 16}}>
                <LabelWithOption title={'カテゴリ'} />
              </View>
              <SoundCategorySelect
                value={selectedLargeSoundCategoryId || 0}
                soundCategories={largeSoundCategories}
                onValueChange={this.handleValueChangeForLargeSoundCategoryId}
              />
              <SoundCategorySelect
                value={selectedMiddleSoundCategoryId || 0}
                soundCategories={middleSoundCategories}
                onValueChange={this.handleValueChangeForMiddleSoundCategoryId}
              />
              <SoundCategorySelect
                value={selectedSmallSoundCategoryId || 0}
                soundCategories={smallSoundCategories}
                onValueChange={this.handleValueChangeForSmallSoundCategoryId}
              />
            </>
          )}
        </ScrollView>
        <SearchButtonWithTotalCount
          totalCount={totalCount}
          onPress={this.handleSubmit}
        />
      </Layout>
    );
  }

  private handleChangeText = (query: string) => {
    this.setStateAndFetchTotalCount({query});
  };

  private handleValueChangeForLargeSoundCategoryId = (
    selectedLargeSoundCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId: null,
      selectedSmallSoundCategoryId: null,
    });
  };

  private handleValueChangeForMiddleSoundCategoryId = (
    selectedMiddleSoundCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId: null,
    });
  };

  private handleValueChangeForSmallSoundCategoryId = (
    selectedSmallSoundCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({selectedSmallSoundCategoryId});
  };

  private setStateAndFetchTotalCount<K extends keyof State>(
    state: Pick<State, K>,
  ): void {
    this.setState(state, () => {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.fetchTotalCount();
      }, 250);
    });
  }

  private fetchTotalCount = () => {
    const {indexSounds} = this.props;
    indexSounds({...this.buildSearchParams(), perPage: 1}).then(
      searchResult => {
        this.setState({totalCount: searchResult.total});
      },
    );
  };

  private buildSearchParams = () => {
    const {route} = this.props;
    const {query} = this.state;
    const {
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId,
      favorite,
    } = route.params;
    return {
      query,
      favorite,
      onlyBackgroundMusic: true,
      allSoundCategoryId:
        this.covertSoundCategoryId(selectedSmallSoundCategoryId) ||
        this.covertSoundCategoryId(selectedMiddleSoundCategoryId) ||
        this.covertSoundCategoryId(selectedLargeSoundCategoryId) ||
        undefined,
    };
  };

  private handleSubmit = () => {
    const {route, onSubmit} = this.props;
    const {
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId,
    } = route.params;
    const {query} = this.state;
    onSubmit({
      query,
      soundCategoryId:
        this.covertSoundCategoryId(selectedSmallSoundCategoryId) ||
        this.covertSoundCategoryId(selectedMiddleSoundCategoryId) ||
        this.covertSoundCategoryId(selectedLargeSoundCategoryId) ||
        undefined,
    });
  };

  private covertSoundCategoryId = (
    soundCategoryId: number | null | undefined,
  ) => {
    if (soundCategoryId && Number(soundCategoryId) != 0) {
      return Number(soundCategoryId);
    }
    return undefined;
  };

  private handleSelectPopularTag = (popularTag: PopularTag) => {
    const {onSubmit} = this.props;
    onSubmit({query: popularTag.tagName});
  };
}
