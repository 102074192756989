import * as React from 'react';
import {ActivityIndicator, StyleSheet, View, ViewStyle} from 'react-native';
import Form, {FormValues} from './partials/form/Form';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewWritersGateManuscriptRouteProp} from '../../../navigators/RouteProps';

import {Params as ApplicationModalUpdateParams} from '../../../actions/application_modal/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import Offer from '../../../../domain/entities/writers_gate/Offer';
import OfferManuscript from '../../../../domain/entities/writers_gate/OfferManuscript';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  offerId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewWritersGateManuscriptRouteProp;
}

export interface DispatchProps {
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, route, updateApplicationModal} = props;
  const {offerId} = route.params;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [offer, setOffer] = React.useState<Offer | null>(null);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const submitting = React.useRef(false);
  const onSubmit = React.useCallback((formValues: FormValues) => {
    if (submitting.current) {
      return;
    }
    setLoading(true);
    submitting.current = true;
    TapNovelTypedRestApi.post<OfferManuscript>(
      '/api/writer/writers_gate/offer_manuscripts',
      {
        offerManuscript: formValues,
      },
      {multipart: true},
    )
      .then(manuscript => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
        if (manuscript.body.episodeId) {
          navigation.goBack();
          navigation.push('Episode', {
            episodeId: manuscript.body.episodeId,
          });
        } else {
          const message =
            manuscript.body.writersGateOffer.typeCode === 'idea'
              ? 'オファーにアイデアを提出しました。'
              : 'オファーにプロットを提出しました。';
          updateApplicationModal({options: {message}});
          submitting.current = false;
          navigation.goBack();
        }
      })
      .catch(e => {
        setLoading(false);
        setAlertMessage(formatErrorMessages({}, e));
        submitting.current = false;
      });
  }, []);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<Offer>(
      `/api/writer/writers_gate/offers/${offerId}`,
    )
      .then(result => {
        setOffer(result.body);
      })
      .catch(e => {});
  }, []);
  return (
    <Layout
      navigation={navigation}
      title={`エピソード${
        offer?.typeCode === 'idea' ? 'アイデア' : 'プロット'
      }`}
      back={true}>
      {offer ? (
        <Form navigation={navigation} offer={offer} onSubmit={onSubmit} />
      ) : null}
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalAlert}>
          {alertMessage}
        </AlertModal>
      )}
      {loading && (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color="#999999" />
        </View>
      )}
    </Layout>
  );
};

export default React.memo(New);

const styles = StyleSheet.create({
  loading: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
});
