import * as React from 'react';
import {
  RefreshControl,
  StyleSheet,
  ScrollView,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  email: string;
  refreshing: boolean;
  onRefresh: () => void;
}

export default class Content extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {email, refreshing, onRefresh} = this.props;
    return (
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }>
        {email && (
          <View style={styles.container}>
            <View style={[styles.section, styles.header]}>
              <Text style={styles.title}>メールを送信しました</Text>
            </View>
            <View style={[styles.section, styles.header]}>
              <Text style={styles.text}>
                {email}
                にメールを送信しました。{'\n'}
                メールをご確認いただき、パスワードの再設定を行ってください。
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={[styles.text, styles.textBold]}>
                確認メールが届かない方
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.text}>
                入力したメールアドレスに誤りがないかご確認ください。{'\n'}
                {'\n'}
                迷惑メールフォルダに振り分けられている可能性がございますので、必ずご確認をお願いいたします。
                {'\n'}
                {'\n'}
                ドメイン指定受信を利用している場合は{'\n'}
                【tapnovel.com】の設定をお願いいたします。{'\n'}
              </Text>
            </View>
          </View>
        )}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
  } as ViewStyle,
  header: {
    alignItems: 'center',
  } as ViewStyle,
  section: {
    justifyContent: 'center',
    marginVertical: 16,
    marginHorizontal: 24,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 17,
    fontWeight: 'bold',
  } as TextStyle,
  text: {
    color: '#383838',
    fontSize: 15,
    lineHeight: 20,
  } as TextStyle,
  textBold: {
    fontWeight: 'bold',
  } as TextStyle,
});
