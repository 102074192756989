import Entity from './Entity';

import Illustration from './Illustration';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class IllustrationUsageHistory implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public illustration: Illustration,
    public lastUsedAt: Date,
    public createdAt: Date,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(
      this.illustration.originalImageUrl,
      options,
    );
  }
}
