import {combineReducers} from 'redux';

import announcementCategories from './announcementCategories';
import announcements from './announcements';
import contactCategories from './contactCategories';
import currentUsers from './currentUsers';
import currentUserStatuses from './currentUserStatuses';
import helpCategories from './helpCategories';
import helps from './helps';
import manualCategories from './manualCategories';
import manuals from './manuals';
import oauthAccounts from './oauthAccounts';
import userEpisodeComments from './userEpisodeComments';
import userNotifications from './userNotifications';
import userNotificationSettingsBatches from './userNotificationSettingsBatches';

export default combineReducers({
  announcementCategories,
  announcements,
  contactCategories,
  currentUsers,
  currentUserStatuses,
  helpCategories,
  helps,
  manualCategories,
  manuals,
  oauthAccounts,
  userEpisodeComments,
  userNotifications,
  userNotificationSettingsBatches,
});
