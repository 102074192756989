import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import Projects from '../../../containers/projects/Index';
import NewProject from '../../../containers/projects/New';
import Project from '../../../containers/projects/Show';
import EditProject from '../../../containers/projects/Edit';
import ProjectsSearch from '../../../containers/projects/search/Index';
import ProjectsSearchHome from '../../../containers/projects/search/home/Index';
import ProjectMembers from '../../../containers/projects/members/Index';
import ProjectEpisodes from '../../../containers/projects/episodes/Index';
import ProjectSummary from '../../../containers/projects/summary/Show';
import ProjectIterations from '../../../containers/projects/iterations/Index';
import ProjectIteration from '../../../containers/projects/iterations/Show';
import ProjectManuscript from '../../../containers/projects/manuscripts/Show';
import NewProjectManuscriptComment from '../../../containers/projects/manuscript_comments/New';
import ProjectTopic from '../../../containers/projects/topics/Show';
import NewProjectTopic from '../../../containers/projects/topics/New';
import NewProjectTopicComment from '../../../containers/projects/topic_comments/New';
import NewProjectTopicCommentReply from '../../../containers/projects/topic_comment_replies/New';
import EditProjectTopic from '../../../containers/projects/topics/Edit';
import ProjectUserEpisodes from '../../../containers/projects/user/episodes/Index';
import ProjectUserEpisode from '../../../containers/projects/user/episodes/Show';
import ProjectUserManuscript from '../../../containers/projects/user/manuscripts/Show';
import NewProjectUserManuscript from '../../../containers/projects/user/manuscripts/New';
import ProjectRequests from '../../../containers/projects/requests/Index';
import NewProjectEpisodeReleaseRequest from '../../../containers/projects/episode_release_requests/New';
import ProjectEpisodeReleaseRequest from '../../../containers/projects/episode_release_requests/Show';
import ProjectCharacters from '../../../containers/projects/characters/Index';
import EditProjectStoryCoverImage from '../../../containers/projects/story_cover_images/Edit';
import EditProjectStoryImage from '../../../containers/projects/story_images/Edit';
import EditProjectEpisodeImage from '../../../containers/projects/episode_images/Edit';
import ProjectCharacter from '../../../containers/characters/Show';
import NewProjectCharacter from '../../../containers/characters/New';
import EditProjectCharacter from '../../../containers/characters/Edit';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="Projects">
      <RootStack.Screen
        name="Projects"
        component={Projects}
        options={{
          title: 'プロジェクト一覧',
        }}
      />
      <RootStack.Screen
        name="Project"
        component={Project}
        options={{
          title: 'プロジェクト',
        }}
      />
      <RootStack.Screen
        name="NewProject"
        component={NewProject}
        options={{
          title: 'プロジェクト作成',
        }}
      />
      <RootStack.Screen
        name="EditProject"
        component={EditProject}
        options={{
          title: 'プロジェクト編集',
        }}
      />
      <RootStack.Screen
        name="ProjectsSearch"
        component={ProjectsSearch}
        options={{
          title: 'プロジェクト',
        }}
      />
      <RootStack.Screen
        name="ProjectsSearchHome"
        component={ProjectsSearchHome}
        options={{
          title: 'プロジェクト検索',
        }}
      />
      <RootStack.Screen
        name="ProjectMembers"
        component={ProjectMembers}
        options={{
          title: 'プロジェクトメンバー一覧',
        }}
      />
      <RootStack.Screen
        name="ProjectEpisodes"
        component={ProjectEpisodes}
        options={{
          title: 'エピソード一覧',
        }}
      />
      <RootStack.Screen
        name="ProjectSummary"
        component={ProjectSummary}
        options={{
          title: 'プロジェクト概要',
        }}
      />
      <RootStack.Screen
        name="ProjectIterations"
        component={ProjectIterations}
        options={{
          title: '過去のターム一覧',
        }}
      />
      <RootStack.Screen
        name="ProjectIteration"
        component={ProjectIteration}
        options={{
          title: '過去のターム',
        }}
      />
      <RootStack.Screen
        name="ProjectManuscript"
        component={ProjectManuscript}
        options={{
          title: 'エピソードアイデア',
        }}
      />
      <RootStack.Screen
        name="NewProjectManuscriptComment"
        component={NewProjectManuscriptComment}
        options={{
          title: 'コメント',
        }}
      />
      <RootStack.Screen
        name="ProjectTopic"
        component={ProjectTopic}
        options={{
          title: 'トピック詳細',
        }}
      />
      <RootStack.Screen
        name="NewProjectTopic"
        component={NewProjectTopic}
        options={{
          title: 'トピック作成',
        }}
      />
      <RootStack.Screen
        name="NewProjectTopicComment"
        component={NewProjectTopicComment}
        options={{
          title: 'コメント',
        }}
      />
      <RootStack.Screen
        name="NewProjectTopicCommentReply"
        component={NewProjectTopicCommentReply}
        options={{
          title: '返信',
        }}
      />
      <RootStack.Screen
        name="EditProjectTopic"
        component={EditProjectTopic}
        options={{
          title: 'トピック編集',
        }}
      />
      <RootStack.Screen
        name="ProjectUserEpisodes"
        component={ProjectUserEpisodes}
        options={{
          title: 'エピソード管理',
        }}
      />
      <RootStack.Screen
        name="ProjectUserEpisode"
        component={ProjectUserEpisode}
        options={{
          title: 'エピソード履歴',
        }}
      />
      <RootStack.Screen
        name="ProjectUserManuscript"
        component={ProjectUserManuscript}
        options={{
          title: 'エピソードアイデア',
        }}
      />
      <RootStack.Screen
        name="NewProjectUserManuscript"
        component={NewProjectUserManuscript}
        options={{
          title: 'エピソードフェーズ',
        }}
      />
      <RootStack.Screen
        name="ProjectRequests"
        component={ProjectRequests}
        options={{
          title: '申請管理',
        }}
      />
      <RootStack.Screen
        name="NewProjectEpisodeReleaseRequest"
        component={NewProjectEpisodeReleaseRequest}
        options={{
          title: 'エピソード情報',
        }}
      />
      <RootStack.Screen
        name="ProjectEpisodeReleaseRequest"
        component={ProjectEpisodeReleaseRequest}
        options={{
          title: 'エピソード公開申請',
        }}
      />
      <RootStack.Screen
        name="EditProjectStoryCoverImage"
        component={EditProjectStoryCoverImage}
        options={{
          title: '表紙',
        }}
      />
      <RootStack.Screen
        name="EditProjectStoryImage"
        component={EditProjectStoryImage}
        options={{
          title: '中表紙',
        }}
      />

      <RootStack.Screen
        name="EditProjectEpisodeImage"
        component={EditProjectEpisodeImage}
        options={{
          title: 'サムネイル',
        }}
      />
      <RootStack.Screen
        name="ProjectCharacters"
        component={ProjectCharacters}
        options={{
          title: 'キャラクター一覧',
        }}
      />
      <RootStack.Screen
        name="ProjectCharacter"
        component={ProjectCharacter as any}
        options={{
          title: 'キャラ作成',
        }}
      />
      <RootStack.Screen
        name="NewProjectCharacter"
        component={NewProjectCharacter}
        options={{
          title: 'キャラ作成',
        }}
      />
      <RootStack.Screen
        name="EditProjectCharacter"
        component={EditProjectCharacter as any}
        options={{title: 'キャラ編集'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
