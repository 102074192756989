import * as React from 'react';
import {
  ImageStyle,
  ImageSourcePropType,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import TermSelect from './TermSelect';
import EpisodeDailyAggregationGraph from './StoryDailyAggregationGraph';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import TooltipModal from '../../../shared/modals/TooltipModal';
import QuestionIcon from '../../../shared/icons/QuestionIcon';

import {storyCoverImageUrl} from '../../../../helpers/images';

import {colors} from '../../../../styles/variables';

import convertImageSource from '../../../../helpers/convertImageSource';

import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';

import StoryAggregation from '../../../../../domain/value_objects/partner_program/StoryAggregation';
import StoryDailyAggregation from '../../../../../domain/value_objects/partner_program/StoryDailyAggregation';

import {TermRange} from '../../../../view_models/TermRange';

import validViewsCountUri from '../../../../../assets/images/tips/valid_views_count.png';
import tipsAmountUri from '../../../../../assets/images/tips/tips_amount.png';

type TooltipModalType =
  | 'valid_views_count'
  | 'tips_amount'
  | 'valid_views_point'
  | 'tips_point';

const TypeToTooltipInfo: {
  [key: string]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle?: ImageStyle;
  };
} = {
  valid_views_count: {
    title: '有効閲覧数',
    description:
      '閲覧による報酬の対象となった閲覧数です。\nポイントを獲得できるのは、TapNovel会員限定のエピソードとなります。',
    source: convertImageSource(validViewsCountUri),
    imageStyle: {width: 279, height: 160},
  },
  tips_amount: {
    title: 'ギフト',
    description: '読者から受け取ったギフトアイテムの金額です。',
    source: convertImageSource(tipsAmountUri),
    imageStyle: {width: 279, height: 160},
  },
  valid_views_point: {
    title: '閲覧ポイント',
    description:
      'あなたが投稿したゲーム小説が、TapNovel会員に読まれることで獲得したポイントです。',
  },
  tips_point: {
    title: 'ギフトポイント',
    description:
      'あなたが投稿したゲーム小説の読者から、ギフトアイテムを受け取ることで獲得したポイントです。',
  },
};

interface Props {
  storyAggregation: StoryAggregation;
  storyDailyAggregations: Array<StoryDailyAggregation>;
  termRange: TermRange;
  onTermRangeChange: (termRange: TermRange) => void;
}

const StoryAggregationView: React.FunctionComponent<Props> = props => {
  const {
    storyAggregation,
    storyDailyAggregations,
    termRange,
    onTermRangeChange,
  } = props;
  const [tooltipModalType, setTooltipModalType] =
    React.useState<TooltipModalType | null>(null);
  const onCloseModal = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onPressValidViewsCount = React.useCallback(() => {
    setTooltipModalType('valid_views_count');
  }, []);
  const onPressTipsAmount = React.useCallback(() => {
    setTooltipModalType('tips_amount');
  }, []);
  const onPressValidViewsPoint = React.useCallback(() => {
    setTooltipModalType('valid_views_point');
  }, []);
  const onPressTipsPoint = React.useCallback(() => {
    setTooltipModalType('tips_point');
  }, []);
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  const source = {
    uri: storyCoverImageUrl(
      new StoryCoverImageDecorator(storyAggregation),
      'small',
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  return (
    <View style={styles.container}>
      <TermSelect termRange={termRange} onTermRangeChange={onTermRangeChange} />
      <View style={styles.storyInfo}>
        {storyAggregation.hasCoverImage ? (
          <FastImageAdapter style={styles.image} source={source} />
        ) : (
          <View style={styles.image}>
            <Text style={styles.noImageText}>表紙なし</Text>
          </View>
        )}
        <View style={styles.storyInfoRight}>
          <View style={styles.rowsTop}>
            <View style={styles.row}>
              <View style={styles.labelWithQuestion}>
                <Text style={styles.label}>有効閲覧数</Text>
                <Pressable onPress={onPressValidViewsCount}>
                  <View style={styles.question}>
                    <QuestionIcon />
                  </View>
                </Pressable>
              </View>
              <Text style={styles.value}>
                {numeral(storyAggregation.validViewsCount).format('0,0')}
              </Text>
            </View>
            <View style={styles.row}>
              <View style={styles.labelWithQuestion}>
                <Text style={styles.label}>ギフト</Text>
                <Pressable onPress={onPressTipsAmount}>
                  <View style={styles.question}>
                    <QuestionIcon />
                  </View>
                </Pressable>
              </View>
              <Text style={styles.value}>
                {numeral(storyAggregation.tipsAmount).format('0,0')}
              </Text>
            </View>
          </View>
          <View style={styles.rowsBottom}>
            <View style={styles.row}>
              <View style={styles.labelWithQuestion}>
                <Text style={styles.label}>閲覧ポイント</Text>
                <Pressable onPress={onPressValidViewsPoint}>
                  <View style={styles.question}>
                    <QuestionIcon />
                  </View>
                </Pressable>
              </View>
              <Text style={styles.value}>
                {numeral(storyAggregation.validViewsPoint).format('0,0.0')} pt
              </Text>
            </View>
            <View style={styles.row}>
              <View style={styles.labelWithQuestion}>
                <Text style={styles.label}>ギフトポイント</Text>
                <Pressable onPress={onPressTipsPoint}>
                  <View style={styles.question}>
                    <QuestionIcon />
                  </View>
                </Pressable>
              </View>
              <Text style={styles.value}>
                {numeral(storyAggregation.tipsPoint).format('0,0.0')} pt
              </Text>
            </View>
          </View>
          <View style={styles.line} />
          <View style={styles.totalRow}>
            <Text style={styles.totalRowLabel}>合計獲得ポイント</Text>
            <Text style={styles.totalRowValue}>
              {numeral(storyAggregation.totalPoint).format('0,0.0')} pt
            </Text>
          </View>
        </View>
      </View>
      <EpisodeDailyAggregationGraph
        storyDailyAggregations={storyDailyAggregations}
      />
      <Text style={styles.title}>エピソード別</Text>
      {modalInfo ? (
        <TooltipModal
          visible={true}
          title={modalInfo.title}
          description={modalInfo.description}
          source={modalInfo.source}
          imageStyle={modalInfo.imageStyle}
          onCloseModal={onCloseModal}></TooltipModal>
      ) : null}
    </View>
  );
};

export default React.memo(StoryAggregationView);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
  } as ViewStyle,
  storyInfo: {
    backgroundColor: 'white',
    padding: 16,
    flexDirection: 'row',
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    marginHorizontal: 16,
    marginBottom: 12,
  } as TextStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  storyInfoRight: {
    flex: 1,
    marginLeft: 16,
    justifyContent: 'center',
  } as ViewStyle,
  rowsTop: {
    flexDirection: 'row',
    marginBottom: 6,
  } as ViewStyle,
  rowsBottom: {
    flexDirection: 'row',
    marginTop: 6,
  } as ViewStyle,
  row: {
    flex: 1,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  labelWithQuestion: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  question: {
    marginLeft: 5,
  } as ViewStyle,
  value: {
    color: '#ff8f13',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  line: {
    borderWidth: 0.5,
    borderColor: '#f5f5f5',
    marginTop: 10,
    marginBottom: 12,
  } as ViewStyle,
  totalRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  totalRowLabel: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  totalRowValue: {
    color: '#ff8f13',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
