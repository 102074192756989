import Entity from './Entity';

import Background from './Background';
import BaseBackground from './BaseBackground';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class BackgroundUsageHistory implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public background: Background,
    public baseBackground: BaseBackground,
    public lastUsedAt: Date,
    public createdAt: Date,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(
      this.baseBackground.originalImageUrl,
      options,
    );
  }
}
