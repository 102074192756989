import * as React from 'react';
import {useActionSheet} from '@expo/react-native-action-sheet';

import StoryList from './StoryList';

import Story from '../../../../domain/entities/Story';

const options = ['削除', 'キャンセル'];

interface Props {
  stories: Story[];
  type: string;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  onSelectStory: (story: Story) => void;
  onDeleteStory: (story: Story) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
}

const StoryListWithActionSheet: React.FunctionComponent<Props> = props => {
  const {showActionSheetWithOptions} = useActionSheet();
  const onOpenActionSheet = React.useCallback((story: Story) => {
    const destructiveButtonIndex = 0;
    const cancelButtonIndex = 1;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        destructiveButtonIndex,
        options,
      },
      (buttonIndex?: number) => {
        switch (buttonIndex) {
          case destructiveButtonIndex:
            props.onDeleteStory(story);
            break;
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, []);
  return <StoryList {...props} onOpenActionSheet={onOpenActionSheet} />;
};

export default StoryListWithActionSheet;
