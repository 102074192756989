import * as React from 'react';
import {Text, View} from 'react-native';
import DefaultModal from '../../../../shared/modals/DefaultModal';
import PrimaryButton from '../../../../shared/buttons/PrimaryButton';

import ResourceProduct from '../../../../../../domain/entities/shop/ResourceProduct';

import NetUserActorPurchasesRepository from '../../../../../../data/repositories/writer/NetUserActorPurchasesRepository';

interface Props {
  resourceProduct: ResourceProduct;
  consumptionPoint?: number | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  success: () => void;
  fail: (e: any) => void;
  onCloseModal: () => void;
}

const FormModalPointOnly: React.FunctionComponent<Props> = props => {
  const {resourceProduct, setLoading, success, fail, onCloseModal} = props;
  const onPress = React.useCallback(() => {
    setLoading(true);
    new NetUserActorPurchasesRepository()
      .create({
        writerUserResourcePurchase: {
          resourceId: resourceProduct.resourceId,
        },
      })
      .then(userResourcePurchase => {
        setLoading(false);
        success();
      })
      .catch(e => {
        setLoading(false);
        fail(e);
      });
  }, []);
  return (
    <DefaultModal
      visible={true}
      disableCloseByOutsideTouch={true}
      onCloseModal={onCloseModal}>
      <View
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 32,
        }}>
        <Text
          style={{
            color: '#222',
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
          }}>
          キャラクターの購入
        </Text>
        <Text
          style={{
            color: '#222',
            marginVertical: 24,
          }}>
          0円
        </Text>
        <PrimaryButton style={{maxWidth: 260}} onPress={onPress}>
          確定
        </PrimaryButton>
      </View>
    </DefaultModal>
  );
};

export default React.memo(FormModalPointOnly);
