import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import EpisodeCommentBlockingUser from '../../../domain/entities/EpisodeCommentBlockingUser';

export default function reducer(
  state: {[key: number]: EpisodeCommentBlockingUser} = {},
  action: AnyAction,
): {[key: number]: EpisodeCommentBlockingUser} {
  switch (action.type) {
    case Types.IndexEpisodeCommentBlockingUsersSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (
            obj: {[key: number]: EpisodeCommentBlockingUser},
            record: EpisodeCommentBlockingUser,
          ) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.CreateEpisodeCommentBlockingUserSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case Types.DestroyEpisodeCommentBlockingUserSuccess: {
      const deleteState = {...state};
      delete deleteState[action.payload.id];
      return deleteState;
    }
    default:
      return state;
  }
}
