import * as React from 'react';
import {
  ImageStyle,
  ImageURISource,
  ImageSourcePropType,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import CoverIllustratorFormField from './CoverIllustratorFormField';

import EditableLaterMessage from '../../../shared/EditableLaterMessage';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import CoverPicker from '../../../shared/image_picker/CoverPicker';
import DimensionContext from '../../../shared/dimension/DimensionContext';
import AlertModal from '../../../shared/modals/AlertModal';
import TooltipModal from '../../../shared/modals/TooltipModal';
import MaterialRightCheck from '../../../shared/MaterialRightCheck';

import convertImageSource from '../../../../helpers/convertImageSource';

import File from '../../../../../domain/entities/File';
import StoryForm from '../../../../../domain/forms/StoryForm';

import storyImageUri from '../../../../../assets/images/tips/story_image.png';
import storyCoverIllustratorUri from '../../../../../assets/images/tips/story_cover_illustrator.png';

type TooltipModalType = 'image' | 'cover_illustrator';

const TypeToTooltipInfo = {
  image: {
    title: '中表紙',
    description:
      'ストーリーの詳細ページに表示される表紙画像となります。\n読者が読みたくなるような中表紙を設定しましょう。',
    source: convertImageSource(storyImageUri),
    imageStyle: {width: 279, height: 240},
  },
  cover_illustrator: {
    title: 'イラストレーター名',
    description:
      '中表紙に、担当したイラストレーター名を記載することができます。',
    source: convertImageSource(storyCoverIllustratorUri),
    imageStyle: {width: 279, height: 180},
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source: ImageSourcePropType;
    imageStyle?: ImageStyle;
  };
};

interface Props {
  storyForm: StoryForm;
  onChangeImage: (file: File) => void;
  onChangeCoverIllustrator: (coverIllustrator: string) => void;
  onSubmit: () => void;
  onRequestReset: () => void;
  onForwardToImageSelection: () => void;
  onForwardToCharacterImageSelection: () => void;
  onForwardToTextInput: () => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {
    storyForm,
    onChangeImage,
    onChangeCoverIllustrator,
    onSubmit,
    onRequestReset,
    onForwardToImageSelection,
    onForwardToCharacterImageSelection,
    onForwardToTextInput,
  } = props;
  let converSource: ImageURISource | ImageURISource[] | null = null;
  let disabled = true;
  const [confirmedMaterialRight, setConfirmedMaterialRight] =
    React.useState(false);
  if (storyForm.image) {
    converSource = {uri: storyForm.image.uri};
    disabled = false;
  } else if (storyForm.hasImage && storyForm.originalImageUrl) {
    converSource = {uri: storyForm.originalImageUrl};
    disabled = false;
  }
  if (storyForm.uploadedSelfImage && !confirmedMaterialRight) {
    disabled = true;
  }
  const handlePressConfirmedMaterialRight = React.useCallback(() => {
    setConfirmedMaterialRight(!confirmedMaterialRight);
  }, [confirmedMaterialRight]);
  const [tooltipModalType, setTooltipModal] =
    React.useState<TooltipModalType | null>(null);
  const [imagePickerError, setImagePickerError] = React.useState<string | null>(
    null,
  );
  const tooltipModal = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  return (
    <View style={styles.container}>
      <View style={styles.thumbnailWrapper}>
        <>
          <View style={styles.labelWrapper}>
            <LabelWithOption
              title={'中表紙'}
              onPressQuestion={() => {
                setTooltipModal('image');
              }}
            />
            <DefaultOutlineButton
              style={styles.resetButton}
              fontSizeClass={'small'}
              onPress={onRequestReset}>
              リセット
            </DefaultOutlineButton>
          </View>
          <DimensionContext.Consumer>
            {context => {
              const width = context.content.width - MARGIN_HORIZONTAL * 2 - 50;
              const height = width / ASPECT_RATIO;
              return (
                <View style={{width, height}}>
                  <CoverPicker
                    style={{height, width}}
                    source={converSource}
                    noImageText={'中表紙なし'}
                    onChangeImage={onChangeImage}
                    onFailImage={setImagePickerError}
                  />
                </View>
              );
            }}
          </DimensionContext.Consumer>
          <View style={styles.editButtons}>
            <DefaultOutlineButton
              style={styles.editButton}
              fontSizeClass={'small'}
              onPress={onForwardToImageSelection}>
              壁紙から選ぶ
            </DefaultOutlineButton>
            <DefaultOutlineButton
              style={styles.editButton}
              fontSizeClass={'small'}
              disabled={!converSource}
              onPress={onForwardToCharacterImageSelection}>
              キャラ配置
            </DefaultOutlineButton>
            <DefaultOutlineButton
              style={styles.editButton}
              fontSizeClass={'small'}
              disabled={!converSource}
              onPress={onForwardToTextInput}>
              テキストを入力
            </DefaultOutlineButton>
          </View>
        </>
      </View>
      <CoverIllustratorFormField
        value={storyForm.coverIllustrator || ''}
        onChangeText={onChangeCoverIllustrator}
        onPressQuestion={() => {
          setTooltipModal('cover_illustrator');
        }}
      />
      {storyForm.uploadedSelfImage ? (
        <View style={{marginTop: 16}}>
          <MaterialRightCheck
            checked={confirmedMaterialRight}
            onPress={handlePressConfirmedMaterialRight}
          />
        </View>
      ) : null}
      <View style={styles.button}>
        <EditableLaterMessage />
        <PrimaryButton disabled={disabled} onPress={onSubmit}>
          保存
        </PrimaryButton>
      </View>
      <AlertModal
        visible={!!imagePickerError}
        onCloseModal={() => setImagePickerError(null)}>
        {imagePickerError}
      </AlertModal>
      <TooltipModal
        visible={tooltipModal ? true : false}
        title={tooltipModal?.title || ''}
        description={tooltipModal?.description || ''}
        source={tooltipModal?.source}
        imageStyle={tooltipModal?.imageStyle}
        onCloseModal={() => {
          setTooltipModal(null);
        }}></TooltipModal>
    </View>
  );
};

export default React.memo(Form);

const ASPECT_RATIO = 1.6;

const MARGIN_HORIZONTAL = 16;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 60,
  } as ViewStyle,
  thumbnailWrapper: {
    flex: 1,
    alignItems: 'center',
  } as ViewStyle,
  labelWrapper: {
    flexDirection: 'row',
    height: 30,
    alignSelf: 'stretch',
    marginBottom: 14,
    marginHorizontal: MARGIN_HORIZONTAL,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    paddingVertical: 20,
  } as ViewStyle,
  editButtons: {
    flexDirection: 'row',
    marginVertical: 16,
  } as ViewStyle,
  editButton: {
    borderRadius: 20,
    width: 120,
    height: 40,
    marginHorizontal: 4,
  } as ViewStyle,
  resetButton: {
    borderRadius: 24,
    width: 84,
    height: 32,
    marginHorizontal: 4,
  } as ViewStyle,
});
