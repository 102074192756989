import * as React from 'react';

import SoundRecorder from './SoundRecorder';

export interface Props {
  onStart: () => void;
  onFinish: (file: File) => void;
  onError: (error: any) => void;
}

const SoundRecordable: React.FunctionComponent<Props> = props => {
  const {onStart, onFinish, onError} = props;
  try {
    const soundRecorderRef = React.useRef(new SoundRecorder());
    React.useEffect(() => {
      soundRecorderRef.current.start().then(onStart).catch(onError);
      return () => {
        soundRecorderRef.current.stop().then(onFinish);
      };
    }, []);
  } catch (e) {
    onError(e);
  }
  return null;
};

export default SoundRecordable;
