import Scene from '../../../../domain/entities/Scene';

interface Props {
  scenes: Scene[] | null;
}

export default (current: Props, next: Props) => {
  if (current.scenes === next.scenes) {
    return false;
  }
  if (!current.scenes || !next.scenes) {
    return true;
  }
  if (current.scenes.length !== next.scenes.length) {
    return true;
  }
  return !current.scenes.every((currentScene, i) => {
    if (!next.scenes) {
      return false;
    }
    const nextScene = next.scenes[i];
    if (currentScene.id !== nextScene.id) {
      return false;
    }
    if (currentScene.updatedAt.getTime() !== nextScene.updatedAt.getTime()) {
      return false;
    }
    return true;
  });
};
