import * as React from 'react';
import {camelizeKeys} from 'humps';

import SearchFormSection from './components/SearchFormSection';
import QuestionListSection from './components/QuestionListSection';
import UnresolveCase from './components/UnresolveCase';
import {SortValue} from './components/types';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {FaqQuestionsRouteProp} from '../../../navigators/RouteProps';

import Question from '../../../../domain/entities/writer/faq/Question';
import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  query?: string;
  sort?: SortValue;
}

export interface StateProps {
  navigation: NavigationProp;
  route: FaqQuestionsRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, route, currentUser} = props;
  const {query} = route.params || {query: undefined};
  const sort = (route.params || {sort: undefined}).sort || 'id_desc';
  const pageRef = React.useRef(1);
  const hasNextPageRef = React.useRef(false);
  let page = pageRef.current;
  const [pageToQuestions, setPageToQuestions] = React.useState<{
    [key: number]: Array<Question>;
  }>({});
  React.useEffect(() => {
    pageRef.current = 1;
    page = pageRef.current;
    TapNovelRestApi.get('/api/writer/faq/questions', {
      query,
      page,
      sort,
    })
      .then(response => {
        hasNextPageRef.current = !!response.headers
          .get('Link')
          ?.includes('rel="next"');
        setPageToQuestions({
          [page]: camelizeKeys(response.body) as Array<Question>,
        });
      })
      .catch(() => {});
  }, [query, sort]);
  const onPressMore = React.useCallback(() => {
    pageRef.current = pageRef.current + 1;
    page = pageRef.current;
    TapNovelRestApi.get('/api/writer/faq/questions', {query, page, sort})
      .then(response => {
        hasNextPageRef.current = !!response.headers
          .get('Link')
          ?.includes('rel="next"');
        setPageToQuestions({
          ...pageToQuestions,
          [page]: camelizeKeys(response.body) as Array<Question>,
        });
      })
      .catch(() => {});
  }, [page, pageToQuestions]);
  const questions = Object.values(pageToQuestions).flatMap(v => v);
  return (
    <Layout
      title={'お問い合わせ'}
      navigation={navigation}
      scrollable={true}
      back={true}>
      {questions ? (
        <>
          <SearchFormSection value={query} sort={sort} />
          <QuestionListSection
            query={query}
            sort={sort}
            questions={questions}
            onPressMore={hasNextPageRef.current ? onPressMore : undefined}
          />
          <UnresolveCase signedIn={!!currentUser} />
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
