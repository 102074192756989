import Entity from '../Entity';

export default class UserResourcePurchase implements Entity {
  constructor(
    public id: number,
    public resourceType: string,
    public resourceId: number,
    public resourceOwnerUserId: number,
    public createdAt: Date,
  ) {}
}
