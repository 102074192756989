import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import ActorAccessorySet from '../../../domain/entities/ActorAccessorySet';

export default function reducer(
  state: {[key: number]: ActorAccessorySet} = {},
  action: AnyAction,
): {[key: number]: ActorAccessorySet} {
  switch (action.type) {
    case Types.IndexActorAccessoriesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (
            obj: {[key: number]: ActorAccessorySet},
            record: ActorAccessorySet,
          ) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    default:
      return state;
  }
}
