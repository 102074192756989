import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import SoundCategoryMapper, {
  SoundCategoryData,
} from '../entities/mappers/SoundCategoryMapper';

import SoundCategory from '../../domain/entities/SoundCategory';
import SoundCategoriesRepository from '../../domain/repositories/SoundCategoriesRepository';

export default class NetSoundCategoriesRepository
  extends NetResourcesRepository<SoundCategory, SoundCategoryData>
  implements SoundCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/sound_categories',
        new SoundCategoryMapper(),
      ),
    );
  }
}
