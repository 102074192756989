import Entity from './Entity';

import Reaction from './Reaction';
import User from './consumer/User';

export default class EpisodeReaction implements Entity {
  constructor(
    public id: number,
    public writerUserId: number,
    public storyId: number,
    public reaction: Reaction,
    public updatedAt: Date,
    public createdAt: Date,
    public user: User,
  ) {}
}
