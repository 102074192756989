import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import New, {
  DispatchProps,
  StateProps,
} from '../../../../components/projects/user/manuscripts/New';

import AppState from '../../../../reducers/AppState';

import updateApplicationModal, {
  Params as ApplicationModalUpdateParams,
} from '../../../../actions/application_modal/update';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    updateApplicationModal: (params: ApplicationModalUpdateParams) =>
      dispatch(updateApplicationModal(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
