import Entity from './Entity';

export default class Contest implements Entity {
  constructor(
    public id: number,
    public contestGroupId: number | null,
    public title: string,
    public description: string,
    public keywordName: string,
    public status: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
