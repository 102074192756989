import Entity from './Entity';

import ActorCharacterFace from './ActorCharacterFace';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

import Gender from '../value_objects/Gender';
import NameLabelColor from '../value_objects/NameLabelColor';

export default class Character implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public actorId: number,
    public actorCharacterId: number,
    public characterMakerOnly: boolean,
    public name: string,
    public writerUserId: number | null,
    public description: string,
    public nameLabelColor: NameLabelColor | undefined,
    public voiceActorName: string | null | undefined,
    public inverted: boolean,
    public published: boolean,
    public originalImageUrl: string,
    public defaultCharacterPatternId: number,
    public characterPatternsCount: number,
    public createdAt: Date,
    public updatedAt: Date,
    public actorCharacterFace: ActorCharacterFace,
    public option: {
      gender: Gender;
    },
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }
}
