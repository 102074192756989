import * as React from 'react';
import {useLinkTo, useFocusEffect} from '@react-navigation/native';

import EpisodeTabs from './partials/EpisodeTabs';

import Layout from '../../../shared/Layout';

import BottomPrimaryButton from '../../../shared/buttons/BottomPrimaryButton';

import NavigationProp from '../../../../navigators/NavigationProp';
import {ProjectUserEpisodesRouteProp} from '../../../../navigators/RouteProps';

import ProjectEpisode from '../../../../../domain/entities/ProjectEpisode';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectUserEpisodesRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {projectId} = route.params;
  const linkTo = useLinkTo();
  const [currentTabPage, setCurrentTabPage] = React.useState(0);
  const [activeEpisodes, setActiveEpisodes] = React.useState<
    ProjectEpisode[] | null
  >(null);
  const [deactiveEpisodes, setDeactiveEpisodes] = React.useState<
    ProjectEpisode[] | null
  >(null);
  const onPressNewEpisode = React.useCallback(() => {
    linkTo(`/projects/${projectId}/user/manuscripts/new`);
  }, []);
  const fetchEntities = React.useCallback(() => {
    TapNovelTypedRestApi.get<ProjectEpisode[]>(
      '/api/writer/user/project_episodes',
      {projectId, working: true},
    ).then(result => {
      setActiveEpisodes(result.body);
    });
    TapNovelTypedRestApi.get<ProjectEpisode[]>(
      '/api/writer/user/project_episodes',
      {projectId, working: false},
    ).then(result => {
      setDeactiveEpisodes(result.body);
    });
  }, []);
  useFocusEffect(fetchEntities);
  return (
    <Layout
      title={'エピソード管理'}
      navigation={navigation}
      back={true}
      scrollable={false}
      footer={
        currentTabPage === 0 ? (
          <BottomPrimaryButton
            bottomButtonStyle={{backgroundColor: 'rgba(255, 255, 255, 0.9)'}}
            onPress={onPressNewEpisode}>
            新しくエピソードを提出する
          </BottomPrimaryButton>
        ) : null
      }>
      {activeEpisodes && deactiveEpisodes && (
        <EpisodeTabs
          projectId={projectId}
          activeEpisodes={activeEpisodes}
          deactiveEpisodes={deactiveEpisodes}
          setCurrentTabPage={setCurrentTabPage}
          onForceUpdate={fetchEntities}
        />
      )}
    </Layout>
  );
};

export default React.memo(Index);
