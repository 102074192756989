import * as React from 'react';

import {View, StyleSheet, Text} from 'react-native';

import Button from './Button';

import DragScrollView from '../../../presentation/components/shared/drag_scroll_view';

export interface Props {
  goToPage?: (pageNumber: number) => void;
  activeTab?: number;
  backgroundColor?: string;
  activeTextColor?: string;
  inactiveTextColor?: string;
  tabs?: JSX.Element[];
}

class ScrollableTabBar extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <View
        style={[
          styles.container,
          {
            backgroundColor: this.props.backgroundColor,
          },
        ]}>
        <DragScrollView
          automaticallyAdjustContentInsets={false}
          horizontal={true}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          directionalLockEnabled={true}>
          <View style={[styles.tabs]}>
            {this.props.tabs &&
              this.props.tabs.map((name: any, page: number) => {
                const isTabActive = this.props.activeTab === page;
                const renderTab = this.renderTab;
                return renderTab(name, page, isTabActive, this.props.goToPage);
              })}
          </View>
        </DragScrollView>
      </View>
    );
  }

  public renderTab = (
    name: string,
    page: number,
    isTabActive: boolean,
    onPressHandler?: (page: number) => void,
  ) => {
    const {activeTextColor, inactiveTextColor} = this.props;
    const textColor = isTabActive
      ? activeTextColor || 'black'
      : inactiveTextColor || 'black';
    const fontWeight = isTabActive ? 'bold' : 'normal';

    return (
      <Button
        key={`${name}_${page}`}
        accessible={true}
        accessibilityLabel={name}
        accessibilityTraits="button"
        onPress={() => onPressHandler && onPressHandler(page)}>
        <View style={[styles.tab]}>
          <Text style={[{color: textColor, fontWeight}]}>{name}</Text>
        </View>
      </Button>
    );
  };
}

const styles = StyleSheet.create({
  tab: {
    height: 49,
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  container: {
    height: 50,
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: '#ccc',
  },
  tabs: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
});

export default ScrollableTabBar;
