import {Dispatch} from 'redux';

import AppState from '../../../reducers/AppState';

import AccountAggregation from '../../../../domain/value_objects/summary/AccountAggregation';

import NetAccountAggregationsRepository from '../../../../data/repositories/writer/summary/NetAccountAggregationsRepository';

export interface Option {
  from?: Date | undefined;
  to?: Date | undefined;
}

export default function action(
  option: Option = {},
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<AccountAggregation> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<AccountAggregation> => {
    const repository = new NetAccountAggregationsRepository();
    const payload = await repository.find(option);
    return payload;
  };
}
