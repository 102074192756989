import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import CharacterMakerActorGroup from '../../../domain/entities/CharacterMakerActorGroup';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetCharacterMakerActorGroupsRepository from '../../../data/repositories/writer/NetCharacterMakerActorGroupsRepository';

const types = {
  failure: Types.IndexCharacterMakerActorGroupsFailure,
  request: Types.IndexCharacterMakerActorGroupsRequest,
  success: Types.IndexCharacterMakerActorGroupsSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<CharacterMakerActorGroup>> {
  return indexGenerics(
    new NetCharacterMakerActorGroupsRepository(),
    types,
    params,
  );
}
