import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import ManualCategory from '../../../../domain/entities/writer/ManualCategory';

export default function reducer(
  state: {[key: number]: ManualCategory} = {},
  action: AnyAction,
): {[key: number]: ManualCategory} {
  switch (action.type) {
    case Types.IndexWriterManualCategoriesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: ManualCategory}, record: ManualCategory) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.ShowWriterManualCategorySuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
