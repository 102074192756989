import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
}

const ProLabel: React.FunctionComponent<Props> = (props: Props) => {
  const {style, textStyle} = props;
  return (
    <View style={[styles.container, style]}>
      <Text style={[styles.text, textStyle]}>Pro</Text>
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(ProLabel, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#383838',
    borderRadius: 4,
    width: 25,
    height: 14,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#fff',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
});
