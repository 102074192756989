import * as React from 'react';
import {LayoutChangeEvent, StyleSheet, View, ViewStyle} from 'react-native';

interface Props {
  tabLabel: string;
  onLayout?: (event: LayoutChangeEvent) => void;
  children?: React.ReactNode;
}

const TabContent: React.FunctionComponent<Props> = props => {
  const {children, onLayout} = props;
  return (
    <View style={styles.container} onLayout={onLayout}>
      {children}
    </View>
  );
};

export default TabContent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
});
