import * as React from 'react';

import Reaction from '../../../../../domain/entities/Reaction';

import LikeIcon from '../../../shared/icons/reaction/LikeIcon';
import HahaIcon from '../../../shared/icons/reaction/HahaIcon';
import SurprisedIcon from '../../../shared/icons/reaction/SurprisedIcon';
import SadIcon from '../../../shared/icons/reaction/SadIcon';
import CreepyIcon from '../../../shared/icons/reaction/CreepyIcon';

interface Props {
  reaction: Reaction;
  size: number;
}

const ReactionIcon: React.FunctionComponent<Props> = props => {
  const {reaction, size} = props;
  switch (reaction.id) {
    case 1:
      return <LikeIcon color={color} size={size} />;
    case 2:
      return <HahaIcon color={color} size={size} />;
    case 3:
      return <SurprisedIcon color={color} size={size} />;
    case 4:
      return <SadIcon color={color} size={size} />;
    case 5:
      return <CreepyIcon color={color} size={size} />;
  }
  return null;
};

const color = '#ff8f13';

export default React.memo(ReactionIcon);
