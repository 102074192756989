import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import AnnouncementMapper, {
  AnnouncementData,
} from '../../entities/mappers/writer/AnnouncementMapper';

import Announcement from '../../../domain/entities/writer/Announcement';
import AnnouncementsRepository from '../../../domain/repositories/writer/AnnouncementsRepository';

export default class NetAnnouncementsRepository
  extends NetResourcesRepository<Announcement, AnnouncementData>
  implements AnnouncementsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/announcements',
        new AnnouncementMapper(),
      ),
    );
  }
}
