import loadScript from './loadScript';

const loadStripeScript = (success: () => void, fail: () => void) => {
  const successDefer = function () {
    setTimeout(function () {
      success();
    }, 1000);
  };
  if ((window as any).Stripe) {
    success();
  } else {
    loadScript(
      'https://js.stripe.com/v3/',
      successDefer,
      fail ||
        function () {
          alert('読み込みに失敗しました');
        },
    );
  }
};

export default loadStripeScript;
