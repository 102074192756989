import * as React from 'react';
import {Image, StyleSheet, Text, View, ViewStyle} from 'react-native';

interface Props {
  style?: ViewStyle;
  children?: React.ReactNode;
}

const ReactionListItem: React.FunctionComponent<Props> = props => {
  const {style, children} = props;
  return (
    <>
      <View style={styles.arrow} />
      <View style={[styles.box, style]}>{children}</View>
    </>
  );
};

export default React.memo(ReactionListItem);

const styles = StyleSheet.create({
  arrow: {
    marginLeft: 15,
    width: 10,
    height: 6,
    borderRightColor: 'transparent',
    borderRightWidth: 5,
    borderLeftColor: 'transparent',
    borderLeftWidth: 5,
    borderBottomWidth: 6,
    borderBottomColor: '#efefef',
  } as ViewStyle,
  box: {
    backgroundColor: '#efefef',
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
