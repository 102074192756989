import * as React from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {StackedBarChart} from 'react-native-chart-kit';
import dateFormat from 'dateformat';

import StoryDailyAggregation from '../../../../../domain/value_objects/partner_program/StoryDailyAggregation';

interface Props {
  storyDailyAggregations: StoryDailyAggregation[];
}

const StoryDailyAggregationGraph: React.FC<Props> = props => {
  const {storyDailyAggregations} = props;
  const [width, setWidth] = React.useState(0);
  const onLayout = React.useCallback((e: LayoutChangeEvent) => {
    setWidth(e.nativeEvent.layout.width);
  }, []);
  const data = React.useMemo(
    () => generateData(storyDailyAggregations),
    [storyDailyAggregations],
  );
  const dataLength = data.data.length;
  const chartConfig = React.useMemo(
    () => ({
      ...DEFAULT_CHART_CONFIG,
      barPercentage: Math.min(5 / dataLength, 5),
    }),
    [dataLength],
  );
  const hidePointsAtIndex = React.useMemo(
    () =>
      Array.from({length: data.data.length}, (v, k) => k).filter(
        i => i % Math.round(dataLength / 10),
      ),
    [data],
  );
  const max = Math.max(...data.data.map(v => v.reduce((sum, i) => sum + i, 0)));
  return (
    <View style={styles.container} onLayout={onLayout}>
      <Text style={styles.title}>報酬獲得推移</Text>
      <View>
        <StackedBarChart
          style={styles.chart}
          data={data}
          width={Math.round((width / 375) * 309)}
          height={height}
          hideLegend={true}
          segments={segments}
          chartConfig={chartConfig}
          hidePointsAtIndex={hidePointsAtIndex}
          verticalLabelsHeightPercentage={verticalLabelsHeightPercentage}
          withHorizontalLabels={false}
        />
        <HorizontalLabels max={max} />
      </View>
    </View>
  );
};

export default React.memo(StoryDailyAggregationGraph);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    padding: 16,
    paddingBottom: 0,
    marginVertical: 16,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
  chart: {
    backgroundColor: 'white',
    paddingTop: 0,
    paddingRight: -10,
  } as ViewStyle,
  horizontalLabels: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 30,
  } as ViewStyle,
});

const height = 270;
const verticalLabelsHeightPercentage = 0.75;
const segments = 4;

const HorizontalLabels: React.FC<{max: number}> = props => {
  const {max} = props;
  return (
    <View style={styles.horizontalLabels}>
      {new Array(segments + 1).fill(0).map((_, i) => (
        <HorizontalLabel
          key={i}
          top={(i / segments) * height * verticalLabelsHeightPercentage - 5}
          label={((segments - i) / segments) * max}
        />
      ))}
    </View>
  );
};

const HorizontalLabel: React.FC<{
  top: number;
  label: string | number;
}> = props => {
  const {top, label} = props;
  return <Text style={{...labelStyle, top: top}}>{label}</Text>;
};

const labelStyle = {
  color: '#383838',
  fontSize: 10,
  textAlign: 'right',
  position: 'absolute',
  right: 0,
} as TextStyle;

const DEFAULT_CHART_CONFIG = {
  color: () => '#ff8f13',
  backgroundGradientFrom: 'white',
  backgroundGradientTo: 'white',
  propsForBackgroundLines: {
    strokeDasharray: '0',
    strokeWidth: 0.5,
    stroke: '#efefef',
  },
  propsForVerticalLabels: {
    fill: '#383838',
    fontSize: 10,
  },
  verticalLabelRotation: 1,
};

const generateData = (episodeDailyAggregations: StoryDailyAggregation[]) => {
  return {
    labels: episodeDailyAggregations.map(o => dateFormat(o.acquiredOn, 'm/d')),
    legend: ['sales_revenue', 'earnings'],
    data: episodeDailyAggregations.map(o => [
      Math.round(o.validViewsPoint),
      Math.round(o.tipsPoint),
    ]),
    barColors: ['#ff8f13', '#ffb059'],
  };
};
