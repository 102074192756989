import * as React from 'react';

import BaseIndex from '../../../../../shared/generics/character_form/actors/Index';

import NavigationProp from '../../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterFormActorsRouteProp} from '../../../../../../navigators/RouteProps';

import * as routers from '../../../../../../routers';

import {Params as ActorIndexParams} from '../../../../../../actions/actors/index';
import {Params as ActorCategoryIndexParams} from '../../../../../../actions/actor_categories/index';

import redirectSceneForm from '../../../../../../helpers/redirectSceneForm';

import {QueryState} from '../../../../../../reducers/queries/Response';

import CurrentUser from '../../../../../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../../../../../domain/entities/writer/CurrentUserStatus';
import Actor from '../../../../../../../domain/entities/Actor';
import ActorCategory from '../../../../../../../domain/entities/ActorCategory';
import PaginatedResult from '../../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
  formValues: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    purchase?: boolean;
  };
  sort?: 'popularity' | 'new_arrival';
  withPaid?: boolean;
  page?: number;
  back?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterFormActorsRouteProp;
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
  sceneForm: SceneForm | null;
  actors: Actor[] | null;
  actorsParams: ActorIndexParams;
  actorCategories: ActorCategory[] | null;
  actorCategoriesParams: ActorCategoryIndexParams;
  actorQueries: QueryState;
  totalCount: number | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexActors: (params: ActorIndexParams) => Promise<PaginatedResult<Actor>>;
  indexActorCategories: (
    params: ActorCategoryIndexParams,
  ) => Promise<PaginatedResult<ActorCategory>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {route} = this.props;
    return (
      <>
        <BaseIndex
          {...this.props}
          formValues={route.params.formValues}
          sort={route.params.sort}
          withPaid={route.params.withPaid}
          onPressForActor={this.handlePressForActor}
          onPressForSearch={this.handlePressForSearch}
        />
      </>
    );
  }

  private handlePressForSearch = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
      formValues,
      withPaid,
    } = route.params;
    navigation.navigate(
      'SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchFormNavigations',
      {
        screen:
          'SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm',
        params: {
          storyId,
          episodeId,
          sceneId,
          sceneCommandIndex,
          subSceneCommandIndex,
          parentSceneCommandId,
          position,
          formValues,
          withPaid,
          hideBack: true,
        },
      } as any,
    );
  };

  private handlePressForActor = async (actor: Actor) => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
    } = route.params;
    const actorId = actor.id;
    routers.linkToSceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        actorId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
      },
    );
  };
}
