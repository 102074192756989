import * as React from 'react';

import BaseIndex from '../../common/speech_text_positions/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormSpeechTextShowSceneCommandNewSpeechTextPositionsRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import Position from '../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  lazyload?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormSpeechTextShowSceneCommandNewSpeechTextPositionsRouteProp;
  sceneForm: SceneForm | null;
  sceneCommandForms: SceneCommandForm[] | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

interface State {
  loaded: boolean;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const loaded = !props.route?.params?.lazyload;
    this.state = {loaded};
  }

  public componentDidMount() {
    if (!this.state.loaded) {
      setTimeout(() => {
        this.setState({loaded: true});
      }, 500);
    }
  }

  public render(): React.ReactNode {
    const {route} = this.props;
    const {sceneCommandIndex} = route.params;
    if (!this.state.loaded) {
      return null;
    }
    return (
      <BaseIndex
        {...this.props}
        sceneCommandIndex={sceneCommandIndex}
        onSelectPositions={this.handleSelectPositions}
      />
    );
  }

  private handleSelectPositions = (
    positions: Position[],
    overrideCharacterName?: boolean,
    characterName?: string,
  ) => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    routers.linkToSceneFormSpeechTextShowSceneCommandNewSpeechTexts(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        positions,
        sceneCommandIndex,
        subSceneCommandIndex,
        speechBalloonId: 1,
        parentSceneCommandId,
        overrideCharacterName,
        characterName,
      },
    );
  };
}
