import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

interface Props {
  style?: ViewStyle;
}

const NoticeIcon: React.FunctionComponent<Props> = props => {
  return <View style={[styles.container, props.style]} />;
};

export default React.memo(NoticeIcon);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f23406',
    width: 8,
    height: 8,
    borderRadius: 4,
  } as ViewStyle,
});
