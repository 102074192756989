import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import BlackButton from '../../../shared/buttons/BlackButton';

interface Props {
  signedIn?: boolean;
}

const UnresolveCase: React.FC<Props> = props => {
  const {signedIn} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    if (signedIn) {
      linkTo('/faq/questions/new');
    } else {
      linkTo('/users/sign_in');
    }
  }, [signedIn]);
  return (
    <View style={styles.container}>
      <Text style={styles.title}>解決しない場合</Text>
      <Text style={styles.text}>
        同じお問い合わせが見つからない場合は、お問い合わせください。
      </Text>
      <View style={styles.buttonWrapper}>
        <BlackButton style={styles.button} onPress={onPress}>
          お問い合わせ
        </BlackButton>
      </View>
    </View>
  );
};

export default React.memo(UnresolveCase);

const styles = StyleSheet.create({
  container: {
    marginVertical: 24,
    marginHorizontal: 16,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 8,
  } as TextStyle,
  text: {
    color: '#222',
    fontSize: 14,
    marginVertical: 8,
  } as TextStyle,
  buttonWrapper: {
    alignItems: 'center',
  } as ViewStyle,
  button: {
    width: 240,
    height: 46,
  } as ViewStyle,
});
