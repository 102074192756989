import CurrentUser from '../../domain/entities/writer/CurrentUser';

import StorageUserPropertiesRepository from '../../data/repositories/StorageUserPropertiesRepository';

type ActionName = 'show' | 'create' | 'update' | 'destroy';

export function sendDefaultCustomEvent(args: {
  resourceType: string;
  resourceId: number;
  resourceName?: string;
  actionName: ActionName;
  source?: string;
  resourceOwnerUserId?: number;
  amount?: number;
  price?: number;
}) {
  if (!(window as any).gtag) {
    return;
  }
  (window as any).gtag('event', 'default_custom_event', {
    eventName: `${args.actionName}_${args.resourceType.replaceAll('/', '_')}`,
    resourceType: args.resourceType,
    resourceId: args.resourceId,
    resourceName: args.resourceName,
    actionName: args.actionName,
    source: args.source,
    resourceOwnerUserId: args.resourceOwnerUserId,
    amount: args.amount,
    price: args.price,
  });
}

export function setUser(currentUser: CurrentUser | null) {
  setUserId(currentUser?.id || null);
  if (currentUser) {
    new StorageUserPropertiesRepository().create({
      user_id: currentUser.id,
      writer_grade_id: currentUser.writerGradeId,
    });
    setUserProperties({
      user_id: currentUser.id,
      writer_grade_id: currentUser.writerGradeId,
    });
  } else {
    new StorageUserPropertiesRepository().destroy();
    setUserProperties({
      user_id: null,
      writer_grade_id: null,
    });
  }
}

export function setUserId(userId: number | null) {
  if (!(window as any).gtag) {
    return;
  }
  if (equalsCurrentUserId(userId)) {
    return;
  }
  (window as any).gtag('set', 'user_id', userId);
}

export function setUserProperties(props: {
  user_id?: number | null;
  pen_name?: string | null;
  writer_grade_id?: number | null;
  enabled?: boolean | null;
}) {
  if (!(window as any).gtag) {
    return;
  }
  if (equalsUserProperties(props)) {
    return;
  }
  (window as any).gtag('set', 'user_properties', props);
}

export function getCurrentUserId(): number | null {
  if (!(window as any).dataLayer) {
    return null;
  }
  const copyDataLayer = [...(window as any).dataLayer];
  const found = copyDataLayer.reverse().find(function (item: any) {
    return item[0] == 'set' && item[1] == 'user_id';
  });
  if (!found) {
    return null;
  }
  if (!found[2]) {
    return null;
  }
  return found[2];
}

function equalsCurrentUserId(userId: number | null) {
  if (!(window as any).dataLayer) {
    return false;
  }
  const copyDataLayer = [...(window as any).dataLayer];
  const found = copyDataLayer.reverse().find(function (item: any) {
    return item[0] == 'set' && item[1] == 'user_id';
  });
  if (!found) {
    return false;
  }
  if (!found[2]) {
    return false;
  }
  return found[2] === userId;
}

function equalsUserProperties(props: {writer_grade_id?: number | null}) {
  if (!(window as any).dataLayer) {
    return false;
  }
  const copyDataLayer = [...(window as any).dataLayer];
  const found = copyDataLayer.reverse().find(function (item: any) {
    return item[0] == 'set' && item[1] == 'user_properties';
  });
  if (!found) {
    return false;
  }
  const prevProps = found[2];
  if (!prevProps) {
    return false;
  }
  return prevProps.writer_grade_id === props.writer_grade_id;
}
