import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {}

const NotInTermRange: React.FunctionComponent<Props> = props => {
  return (
    <>
      <View style={styles.container} />
      <View style={styles.textWrapper}>
        <Text style={styles.text}>
          期間中にエピソードの修正が{'\n'}
          あったため表示できません。
        </Text>
        <Text style={styles.text}>
          表示する場合は修正後の期間を{'\n'}
          選択ください。
        </Text>
      </View>
    </>
  );
};

const propsAreEqual = () => true;

export default React.memo(NotInTermRange, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    alignSelf: 'center',
    top: 50,
    width: 260,
    height: 134,
    borderRadius: 5,
    backgroundColor: '#000',
    opacity: 0.05,
  } as ViewStyle,
  textWrapper: {
    position: 'absolute',
    alignSelf: 'center',
    top: 50,
    width: 260,
    height: 134,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    fontSize: 12,
    color: '#222',
    marginVertical: 4,
  } as TextStyle,
});
