import {combineReducers} from 'redux';

import accessToken from './accessToken';
import writerCurrentUserId from './writerCurrentUserId';

import entities from './entities';
import forms from './forms';
import modals from './modals';
import queries from './queries';

export default combineReducers<any>({
  entities,
  forms,
  modals,
  queries,
  accessToken,
  writerCurrentUserId,
});
