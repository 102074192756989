import TwitterAccessToken from '../../../domain/value_objects/TwitterAccessToken';

export interface TwitterAccessTokenData {
  oauth_token: string;
  oauth_token_secret: string;
  user_id: string;
  screen_name: string;
}

export default class TwitterAccessTokenMapper {
  public map(obj: TwitterAccessTokenData): TwitterAccessToken {
    return new TwitterAccessToken(
      obj.oauth_token,
      obj.oauth_token_secret,
      obj.user_id,
      obj.screen_name,
    );
  }
}
