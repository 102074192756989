import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration from '../../../../containers/scene_form/full_screen_illustration_show_scene_command/new/full_screen_illustrations/New'; // tslint:disable-next-line:max-line-length
import SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrations from '../../../../containers/scene_form/full_screen_illustration_show_scene_command/new/full_screen_illustrations/Index'; // tslint:disable-next-line:max-line-length

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration">
      <RootStack.Screen
        name="SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration"
        component={
          SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration
        }
        options={{title: 'スチル'}}
      />
      <RootStack.Screen
        name="SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrations"
        component={
          SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrations
        }
        options={{title: 'スチル'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
