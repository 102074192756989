import TapNovelRestApi, {Result} from '../data_stores/net/TapNovelRestApi';

import EntityMapper from '../entities/mappers/EntityMapper';

import Entity from '../../domain/entities/Entity';
import PaginatedResultFactory from '../../domain/factories/PaginatedResultFactory';
import ResourcesRepository from '../../domain/repositories/ResourcesRepository';
import PaginatedResult from '../../domain/results/PaginatedResult';

export default class NetResourcesRepositoryAdapter<E extends Entity, D>
  implements ResourcesRepository<E>
{
  constructor(
    private endPoint: string,
    public mapper: EntityMapper<D, E>,
    private suffix?: string,
  ) {}

  public async find(id: number): Promise<E> {
    const path = `${this.endPoint}/${id}`;
    const obj = await TapNovelRestApi.get<D>(path);
    return this.mapper.map(obj.body, obj.headers);
  }

  public findAll(): Promise<PaginatedResult<E>> {
    return this.findBy({});
  }

  public async findBy(params: {
    [key: string]: any;
  }): Promise<PaginatedResult<E>> {
    const path = this.endPoint;
    const result = await TapNovelRestApi.get<D[]>(path, params);
    return this.generatePagenatedResult(params, result);
  }

  public async create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    const path = this.endPoint;
    const obj = await TapNovelRestApi.post<D>(path, params, options);
    return this.mapper.map(obj.body, obj.headers);
  }

  public async update(
    id: number,
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<E> {
    const path = `${this.endPoint}/${id}${
      this.suffix ? `/${this.suffix}` : ''
    }`;
    const obj = await TapNovelRestApi.patch<D>(path, params, options);
    return this.mapper.map(obj.body, obj.headers);
  }

  public async destroy(id: number): Promise<void> {
    const path = `${this.endPoint}/${id}${
      this.suffix ? `/${this.suffix}` : ''
    }`;
    await TapNovelRestApi.delete(path);
    return;
  }

  private generatePagenatedResult(
    params: {[key: string]: any},
    result: Result<D[]>,
  ): PaginatedResult<E> {
    const records = result.body.map(obj => {
      return this.mapper.map(obj, result.headers);
    });
    const factory = new PaginatedResultFactory();
    const page = params.page ? Number(params.page) : 1;
    return factory.create(result.headers, records, {...params, page});
  }
}
