import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import BaseSceneCommandModal from './BaseSceneCommandModal';

import CharacterView from './visualizations/CharacterView';

import DeleteButton from './buttons/DeleteButton';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import CharacterHideSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';

interface Props {
  sceneCommandForm: CharacterHideSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestCloseModal: () => void;
  onRemoveCharacterHideCommand: (
    sceneCommandForm: CharacterHideSceneCommandForm,
  ) => void;
  onChangeCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
}

export default class CharacterHideSceneCommandModal extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {sceneCommandForm, onRequestCloseModal} = this.props;
    return (
      <BaseSceneCommandModal
        title={'キャラクターの退場'}
        onRequestCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.footer}>
            <DeleteButton onPress={this.handlePress} />
          </View>
        }>
        <View style={styles.container}>
          <CharacterView
            actorCharacterFace={sceneCommandForm.actorCharacterFace}
            inverted={sceneCommandForm.characterPattern.character.inverted}
          />
        </View>
      </BaseSceneCommandModal>
    );
  }

  private handlePress = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onRemoveCharacterHideCommand,
      onChangeCommand,
      onRemoveCommand,
    } = this.props;
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.remove(
          parentSceneCommandForm,
          sceneCommandForm,
        );
      if (newParentSceneCommandForm) {
        onChangeCommand(newParentSceneCommandForm);
      } else {
        onRemoveCommand(parentSceneCommandForm);
      }
    } else {
      onRemoveCharacterHideCommand(sceneCommandForm);
    }
    onRequestCloseModal();
  };
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15,
    height: 200,
  } as ViewStyle,
  footer: {
    marginVertical: 5,
    padding: 10,
  } as ViewStyle,
});
