import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import NameLabelColorButton from './NameLabelColorButton';
import DragScrollView from './drag_scroll_view';

import NameLabelColor, {
  ALL_NAME_LABEL_COLORS,
} from '../../../domain/value_objects/NameLabelColor';

interface Props {
  style?: ViewStyle;
  nameLabelColor?: NameLabelColor | null;
  onSelectNameLabelColor: (nameLabelColor: NameLabelColor) => void;
}

const NameLabelColorButtons: React.FunctionComponent<Props> = props => {
  const {onSelectNameLabelColor} = props;
  const style = React.useMemo(() => [styles.container, props.style], []);
  return (
    <View style={style}>
      <DragScrollView
        horizontal={true}
        bounces={false}
        showsHorizontalScrollIndicator={false}>
        <View style={styles.nameLableColors}>
          {ALL_NAME_LABEL_COLORS.map(nameLabelColor => (
            <NameLabelColorButton
              nameLabelColor={nameLabelColor}
              active={nameLabelColor === props.nameLabelColor}
              onSelectNameLabelColor={onSelectNameLabelColor}
            />
          ))}
        </View>
      </DragScrollView>
    </View>
  );
};

const propsAreEqual = (
  prevProps: Readonly<Props>,
  nextProps: Readonly<Props>,
): boolean => {
  return prevProps.nameLabelColor === nextProps.nameLabelColor;
};

export default React.memo(NameLabelColorButtons, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  } as ViewStyle,
  nameLableColors: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 25,
  } as ViewStyle,
});
