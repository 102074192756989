import * as React from 'react';
import {Animated, Platform, StyleSheet, ViewStyle} from 'react-native';
import SortableList, {RowProps} from 'react-native-sortable-list';
import {shallowEqual} from 'recompose';

import EpisodeListItemContent from './EpisodeListItemContent';
import NoEpisodes from './NoEpisodes';

import shouldUpdateEpisodeList from '../../shared/enhanced/shouldUpdateEpisodeList';

import Episode from '../../../../domain/entities/Episode';

interface Props {
  episodes: Episode[];
  sortingEnabled?: boolean;
  idToCopyLabel?: {[key: number]: string};
  onSelectEpisode: (episode: Episode) => void;
  onChangeOrder: (
    episode: Episode,
    rowOrderPosition: number,
    options?: {error?: () => void},
  ) => void;
  onStartSort?: () => void;
  onEndSort?: () => void;
  onOpenActionSheet?: (episode: Episode) => void;
}

interface State {
  episodes: Episode[];
  order: number[];
}

export default class EpisodeList extends React.Component<Props, State> {
  private mounted = false;

  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    const nextOrder = EpisodeList.getOrder(nextProps.episodes);
    if (shouldUpdateEpisodeList(nextProps, prevState)) {
      return {order: nextOrder, episodes: nextProps.episodes};
    }
    return null;
  }

  private static getOrder = (episodes: Episode[]) => {
    return episodes.map(episode => episode.id);
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      episodes: props.episodes,
      order: this.getOrder(props.episodes),
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {episodes, sortingEnabled} = this.props;
    const {order} = this.state;
    if (episodes.length === 0) {
      return <NoEpisodes />;
    }
    return (
      <SortableList
        contentContainerStyle={styles.contentContainerStyle}
        scrollEnabled={Platform.select({web: true, default: false})}
        data={this.getData()}
        order={order}
        sortingEnabled={sortingEnabled === undefined ? true : sortingEnabled}
        renderRow={this.renderCustom}
        onPressRow={this.handlePressRow}
        onActivateRow={this.handleActivateRow}
        onChangeOrder={this.handleChangeOrder}
        onReleaseRow={this.handleReleaseRow}
      />
    );
  }

  private getData = (): {[key: number]: Episode} => {
    const {episodes} = this.props;
    const data: {[key: number]: Episode} = {};
    episodes.forEach(episode => {
      data[episode.id] = episode;
    });
    return data;
  };

  private renderCustom = (props: RowProps<Episode, number>) => {
    const {data, active} = props;
    const {idToCopyLabel, onOpenActionSheet} = this.props;
    return (
      <Animated.View
        key={data.id}
        style={[styles.row, active ? styles.rowActive : null]}>
        <EpisodeListItemContent
          episode={data}
          copyLabel={idToCopyLabel ? idToCopyLabel[data.id] : undefined}
          onOpenActionSheet={onOpenActionSheet}
        />
      </Animated.View>
    );
  };

  private handlePressRow = (key: number) => {
    const {sortingEnabled, onSelectEpisode} = this.props;
    if (sortingEnabled === false) {
      return;
    }
    const episode = this.fetchEpisodeFromKey(key);
    if (episode) {
      onSelectEpisode(episode);
    }
  };

  private handleActivateRow = (key: number) => {
    const {onStartSort} = this.props;
    onStartSort && onStartSort();
  };

  private handleChangeOrder = (nextOrder: number[]) => {
    this.setStateIfMounted({order: nextOrder});
  };

  private handleReleaseRow = (key: number) => {
    const {episodes, onChangeOrder, onEndSort} = this.props;
    const {order} = this.state;
    const episode = this.fetchEpisodeFromKey(key);
    onEndSort && onEndSort();
    if (!order || !episode || shallowEqual(order, this.getOrder(episodes))) {
      return;
    }
    const rowOrderPosition = order.map(o => Number(o)).indexOf(Number(key));
    onChangeOrder(episode, rowOrderPosition, {
      error: () => {
        this.setStateIfMounted({order: this.getOrder(this.props.episodes)});
      },
    });
  };

  private fetchEpisodeFromKey = (key: number): Episode | null => {
    const {episodes} = this.props;
    const id = Number(key);
    const episode = episodes.find(row => row.id === id) || null;
    return episode;
  };

  private getOrder = (episodes: Episode[]) => {
    return EpisodeList.getOrder(episodes);
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ): void {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    backgroundColor: 'white',
    display: Platform.select({
      web: 'block',
      default: undefined,
    }) as any,
  },
  row: {
    flex: 1,
    backgroundColor: 'white',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      } as any,
      default: {},
    }),
  } as ViewStyle,
  rowActive: {
    transform: [{scale: 1.03}],
    borderTopColor: '#efefef',
    borderTopWidth: 1,
  } as ViewStyle,
});
