import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {}

const SelectWrapper: React.FunctionComponent<Props> = props => {
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        TapNovel（読書サービス）とは別サービスの、{'\n'}
        TapNovelMaker（投稿サービス）のプロフィールとなります。
      </Text>
    </View>
  );
};

export default React.memo(SelectWrapper);

const styles = StyleSheet.create({
  container: {
    height: 54,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#ff8f13',
    marginTop: 32,
    marginHorizontal: 16,
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    fontSize: 10,
    textAlign: 'center',
    color: '#ff8f13',
  } as TextStyle,
});
