import * as React from 'react';
import {
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import Episode from '../../../../../domain/entities/writer/full_video/Episode';

interface Props {
  episode: Episode;
  onSelectEpisode: (episode: Episode) => void;
}

const EpisodeListItem: React.FunctionComponent<Props> = props => {
  const {episode, onSelectEpisode} = props;
  const onPress = React.useCallback(() => {
    onSelectEpisode(episode);
  }, []);
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.episodeInfo}>
        <Text style={styles.title}>{episode.title}</Text>
        {episode.videoTypes.length > 0 ? (
          <View style={styles.labels}>
            <Text style={styles.labelSucceeded}>動画作成済み</Text>
            {episode.videoTypes.includes('vertical_full') ? (
              <Text style={styles.labelVertical}>タテ型</Text>
            ) : null}
            {episode.videoTypes.includes('horizontal_full') ? (
              <Text style={styles.labelHorizontal}>ヨコ型</Text>
            ) : null}
          </View>
        ) : null}
      </View>
    </Pressable>
  );
};

export default React.memo(EpisodeListItem);

const label = {
  justifyContent: 'center',
  alignItems: 'center',
  padding: 2,
  fontSize: 8,
  fontWeight: 'bold',
  marginRight: 5,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    margin: 10,
    backgroundColor: 'white',
  } as ViewStyle,
  episodeInfo: {
    flex: 1,
    margin: 10,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
  labels: {
    flexDirection: 'row',
  } as ViewStyle,
  labelSucceeded: {
    ...label,
    backgroundColor: '#2c86d7',
    color: 'white',
  } as TextStyle,
  labelVertical: {
    ...label,
    borderColor: '#2c86d7',
    borderWidth: 1,
    borderRadius: 2,
    paddingHorizontal: 3,
    color: '#2c86d7',
  } as TextStyle,
  labelHorizontal: {
    ...label,
    borderColor: '#2c86d7',
    borderWidth: 1,
    borderRadius: 2,
    paddingHorizontal: 3,
    color: '#2c86d7',
  } as TextStyle,
});
