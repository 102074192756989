import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import ManuscriptListItem from './ManuscriptListItem';

import ListItemNormalSeparator from '../../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectManuscript from '../../../../../../domain/entities/ProjectManuscript';

interface Props {
  manuscripts: ProjectManuscript[];
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
  ListFooterComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const ManuscriptList: React.FC<Props> = props => {
  const {manuscripts, ListHeaderComponent, ListFooterComponent} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<any>) => (
      <ManuscriptListItem manuscript={info.item} />
    ),
    [],
  );
  return (
    <FlatList
      data={manuscripts}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListFooterComponent}
      ListHeaderComponent={ListHeaderComponent}
    />
  );
};

export default React.memo(ManuscriptList);
