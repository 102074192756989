import * as React from 'react';
import {View, ViewStyle} from 'react-native';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import IllustrationView from './visualizations/IllustrationView';

import {ModalParams} from '../../Modal';

import SoundIcon from '../../../../shared/icons/SoundIcon';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';

const soundIconStyle = {
  position: 'absolute',
  right: 80,
  top: 32,
} as ViewStyle;

const soundIcon = (
  <View style={soundIconStyle}>
    <SoundIcon />
  </View>
);

interface Props {
  sceneCommandForm: IllustrationShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestOpenModal: (modalParams: ModalParams) => void;
}

export default class IllustrationShowSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, ['sceneCommandForm']);
  }

  public render(): React.ReactNode {
    const {sceneCommandForm} = this.props;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <>
            {sceneCommandForm.sound ? soundIcon : null}
            <IllustrationView illustration={sceneCommandForm.illustration} />
          </>
        }
        onRequestOpenModal={this.handleRequestOpen}
      />
    );
  }

  private handleRequestOpen = () => {
    const {sceneCommandForm, parentSceneCommandForm, onRequestOpenModal} =
      this.props;
    onRequestOpenModal({
      type: 'IllustrationShowSceneCommandModal',
      sceneCommandForm,
      parentSceneCommandForm,
    });
  };
}
