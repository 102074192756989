import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewFullVideoExportJobExecutionRouteProp} from '../../../navigators/RouteProps';

import Episode from '../../../../domain/entities/Episode';

import NetEpisodesRepository from '../../../../data/repositories/writer/NetEpisodesRepository';

export interface Params {
  episodeId: number;
  orientation?: 'vertical' | 'horizontal';
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewFullVideoExportJobExecutionRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const New: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const [loading, setLoading] = React.useState(false);
  const {episodeId, orientation} = route.params;
  const [episode, setEpisode] = React.useState<Episode | null>(null);
  React.useEffect(() => {
    new NetEpisodesRepository().find(episodeId).then(setEpisode);
  }, []);
  const onSubmitComplete = React.useCallback(() => {
    navigation.navigate('FullVideoExportJobExecutions', {});
  }, []);
  const onForwardImage = React.useCallback(
    (params: {
      title: string;
      orientation: 'vertical' | 'horizontal';
      autoPlaySpeed: '1.0' | '1.5' | '2.0';
      openingMovie: 'blank' | 'present';
      endingMovie: 'blank' | 'present';
    }) => {
      if (episode) {
        navigation.navigate('NewFullVideoExportJobExecutionFormImage', {
          episodeId: episode.id,
          storyId: episode.storyId,
          ...params,
        });
      }
    },
    [episode],
  );
  return (
    <Layout
      title={'動画情報'}
      navigation={navigation}
      hideHeaderBottomBorder={true}
      back={true}
      loading={loading}>
      <Form
        episodeId={episodeId}
        orientation={orientation}
        setLoading={setLoading}
        onForwardImage={onForwardImage}
        onSubmitComplete={onSubmitComplete}
      />
    </Layout>
  );
};

export default React.memo(New);
