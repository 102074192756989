import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import ActorCharacter from '../../../domain/entities/ActorCharacter';

export default function reducer(
  state: {[key: number]: ActorCharacter} = {},
  action: AnyAction,
): {[key: number]: ActorCharacter} {
  switch (action.type) {
    case Types.ShowActorCharacterSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
