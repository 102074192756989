import EntityMapper from './EntityMapper';

import Keyword from '../../../domain/entities/Keyword';

export interface KeywordData {
  id: number;
  name: string;
}

export default class KeywordMapper
  implements EntityMapper<KeywordData, Keyword>
{
  public map(obj: KeywordData): Keyword {
    return new Keyword(obj.id, obj.name);
  }
}
