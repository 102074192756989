import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Episode from '../../../domain/entities/Episode';

import NetEpisodesRepository from '../../../data/repositories/writer/NetEpisodesRepository';
import NetStoriesRepository from '../../../data/repositories/writer/NetStoriesRepository';
import NetContestsRepository from '../../../data/repositories/writer/NetContestsRepository';

import EpisodePublicationCreateEvent from '../../../domain/services/analytics_particular_events/EpisodePublicationCreateEvent';
import LimitEpisodePublicationCreateEvent from '../../../domain/services/analytics_particular_events/LimitEpisodePublicationCreateEvent';

export interface Params {
  published: boolean;
  scheduledDeliveredAt?: Date;
  confirmedMaterialRight?: boolean;
  free?: boolean;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Episode> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<Episode> => {
    try {
      const {entities} = getState();
      const episode =
        entities.episodes[id] || (await new NetEpisodesRepository().find(id));
      const oldStory = await new NetStoriesRepository().find(episode.storyId);
      const payload = await new NetEpisodesRepository().update(id, {
        episode: params,
      });
      dispatch({payload, type: Types.UpdateEpisodeSuccess});
      sendDefaultCustomEvent({
        resourceType: 'episode_publication',
        resourceId: id,
        actionName: payload.published ? 'create' : 'destroy',
      });
      new EpisodePublicationCreateEvent().create(payload);
      new LimitEpisodePublicationCreateEvent().create(payload);
      new NetStoriesRepository().find(episode.storyId).then(async newStory => {
        if (!oldStory.entryContestId && newStory.entryContestId) {
          const contest = await new NetContestsRepository().find(
            newStory.entryContestId,
          );
          if (contest.contestGroupId) {
            sendDefaultCustomEvent({
              resourceType: `contest_group_entry_${contest.contestGroupId}`,
              resourceId: episode.storyId,
              actionName: 'create',
            });
          }
        }
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateEpisodeFailure});
      throw err;
    }
  };
}
