import * as React from 'react';
import {Platform} from 'react-native';
import {WebView, WebViewProps} from 'react-native-webview';

// tslint:disable-next-line:no-implicit-dependencies
import Config from 'react-native-config';

interface Props extends WebViewProps {
  source?: {uri: string};
  path: string;
}

const TapNovelWebView: React.FunctionComponent<Props> = props => {
  return (
    <WebView
      {...props}
      source={{uri: `${getTapnovelApiEndpoint()}${props.path}`}}
    />
  );
};

export default React.memo(TapNovelWebView);

const getTapnovelApiEndpoint = () => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT;
  } else {
    return Config.REACT_APP_TAPNOVEL_API_ENDPOINT;
  }
};
