import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
}

const ProfileIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 14;
  const color = props.color || '#999999';
  return <Icon name={'question'} size={size} color={color} />;
};

export default React.memo(ProfileIcon);
