import * as React from 'react';

import Base from './Base';

import uri from '../../../../assets/svgs/publication-step-2.svg';

interface Props {
  onRequestClose?: () => void;
}

const Step1: React.FunctionComponent<Props> = props => {
  const {onRequestClose} = props;
  return (
    <Base
      uri={uri}
      title={'エピソードを作成したら・・・'}
      description={'TapNovelに公開してみましょう！'}
      onRequestClose={onRequestClose}
      width={278}
      height={97}
    />
  );
};

const propsAreEqual = () => true;

export default React.memo(Step1, propsAreEqual);
