import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/cover_image_form/character_form/actors/Index';

import AppState from '../../../../reducers/AppState';

import indexActorCategories, {
  Params as ActorCategoryIndexParams,
} from '../../../../actions/actor_categories/index';
import indexActors, {
  Params as ActorIndexParams,
} from '../../../../actions/actors/index';

import selectEntities, {
  getTotalCount,
} from '../../../../helpers/selectEntities';
import selectEntity from '../../../../helpers/selectEntity';

import {CoverImageFormCharacterFormActorsRouteProp} from '../../../../navigators/RouteProps';

import buildActorSearchParams from '../../../../view_models/buildActorSearchParams';

interface Props {
  navigation: NavigationProp;
  route: CoverImageFormCharacterFormActorsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const {formValues, sort, withPaid, page} = route.params;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const {coverImageForm} = forms;
  const actorsParams: ActorIndexParams = {
    ...buildActorSearchParams(formValues),
    withPaid,
    page,
    sort: sort || 'popularity',
  };
  const actors = selectEntities(entities.actors, queries.actors, actorsParams);
  const totalCount = getTotalCount(queries.actors, actorsParams);
  const actorCategoriesParams: ActorCategoryIndexParams = {};
  const actorCategories = selectEntities(
    entities.actorCategories,
    queries.actorCategories,
    actorCategoriesParams,
  );
  const actorQueries = queries.actors;
  return {
    currentUser,
    currentUserStatus,
    coverImageForm,
    actors,
    actorsParams,
    actorCategories,
    actorCategoriesParams,
    actorQueries,
    navigation,
    totalCount,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexActorCategories: (params: ActorCategoryIndexParams) =>
      dispatch(indexActorCategories(params)),
    indexActors: (params: ActorIndexParams) => dispatch(indexActors(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
