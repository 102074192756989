import * as React from 'react';

import BaseIndex from '../../../../shared/generics/background_show_scene_command/backgrounds/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundShowSceneCommandNewBackgroundsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as BackgroundTimeZoneIndexParams} from '../../../../../actions/background_time_zones/index';
import {Params as BackgroundUsageHistoryUpdateParams} from '../../../../../actions/background_usage_histories/update';
import {Params as BackgroundWeatherIndexParams} from '../../../../../actions/background_weathers/index';
import {Params as BackgroundIndexParams} from '../../../../../actions/backgrounds/index';
import {Params as SceneFormUpdateParams} from '../../../../../actions/scene_forms/update';
import {Params as CoachmarkModalUpdateParams} from '../../../../../actions/coachmark_modal/update';

import CoachmarkState from '../../../../../view_models/CoachmarkState';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import Background from '../../../../../../domain/entities/Background';
import BackgroundTimeZone from '../../../../../../domain/entities/BackgroundTimeZone';
import BackgroundUsageHistory from '../../../../../../domain/entities/BackgroundUsageHistory';
import BackgroundWeather from '../../../../../../domain/entities/BackgroundWeather';
import BaseBackground from '../../../../../../domain/entities/BaseBackground';
import SceneForm from '../../../../../../domain/forms/SceneForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  baseBackgroundId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBackgroundsRouteProp;
  sceneForm: SceneForm | null;
  backgroundsParams: BackgroundIndexParams;
  backgroundTimeZonesParams: BackgroundTimeZoneIndexParams;
  backgroundWeathersParams: BackgroundWeatherIndexParams;
  baseBackground: BaseBackground | null;
  backgrounds: Background[] | null;
  backgroundTimeZones: BackgroundTimeZone[] | null;
  backgroundWeathers: BackgroundWeather[] | null;
  coachmarkModal: CoachmarkState | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexBackgrounds: (
    params: BackgroundIndexParams,
  ) => Promise<PaginatedResult<Background>>;
  indexBackgroundTimeZones: (
    params: BackgroundTimeZoneIndexParams,
  ) => Promise<PaginatedResult<BackgroundTimeZone>>;
  indexBackgroundWeathers: (
    params: BackgroundWeatherIndexParams,
  ) => Promise<PaginatedResult<BackgroundWeather>>;
  showBaseBackground: (id: number) => Promise<BaseBackground>;
  updateBackgroundUsageHistory: (
    id: number,
    params: BackgroundUsageHistoryUpdateParams,
  ) => Promise<BackgroundUsageHistory>;
  updateSceneForm: (params: SceneFormUpdateParams) => Promise<SceneForm>;
  updateCoachmarkModal: (params: CoachmarkModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  private disabledSubmit = false;

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (background: Background) => {
    const {
      navigation,
      updateBackgroundUsageHistory,
      sceneForm,
      coachmarkModal,
      updateSceneForm,
      updateCoachmarkModal,
    } = this.props;
    if (!background) {
      return;
    }
    if (!sceneForm) {
      return;
    }
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const id = sceneForm.id;
    const storyId = sceneForm.storyId;
    const episodeId = sceneForm.episodeId;
    const options = {};
    const params = {id, background, storyId, episodeId, options};
    if (coachmarkModal === 'first_episode_form_created') {
      updateCoachmarkModal({value: 'first_episode_form_background_done'});
    }
    updateSceneForm(params);
    updateBackgroundUsageHistory(background.id, {storyId}).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };
}
