import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import UserEpisodeComment from '../../../../domain/entities/writer/UserEpisodeComment';

export default function reducer(
  state: {[key: number]: UserEpisodeComment} = {},
  action: AnyAction,
): {[key: number]: UserEpisodeComment} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.ShowWriterUserEpisodeCommentSuccess:
    case Types.CreateWriterUserEpisodeCommentSuccess:
    case Types.UpdateWriterUserEpisodeCommentSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
