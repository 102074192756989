import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useFocusEffect} from '@react-navigation/native';

import PrimaryButton from '../buttons/PrimaryButton';

import SoundPlayable from '../SoundPlayable';
import ResourceFavoriteButton from '../ResourceFavoriteButton';

import PlayIcon from '../icons/PlayIcon';
import StopIcon from '../icons/StopIcon';
import Entity from '../../../../domain/entities/Entity';
import UserResourceFavorite from '../../../../domain/entities/writer/UserResourceFavorite';

export interface SoundEntity {
  id: number;
  name: string;
  audioUrl: string;
}

interface Props<T extends SoundEntity> {
  entity: T;
  playing: boolean;
  enabledFavorite?: boolean;
  userResourceFavorite?: UserResourceFavorite | null;
  onSelectEntity: (entity: T) => void;
  onUpdatePlayingEntityId: (entity: Entity, playing: boolean) => void;
}

const SoundListItem = <T extends SoundEntity>(props: Props<T>) => {
  const {
    entity,
    playing,
    enabledFavorite,
    userResourceFavorite,
    onSelectEntity,
    onUpdatePlayingEntityId,
  } = props;
  const handlePlay = React.useCallback(() => {
    onUpdatePlayingEntityId(entity, !playing);
  }, [playing]);
  const handleFinishPlay = React.useCallback(() => {
    onUpdatePlayingEntityId(entity, false);
  }, []);
  useFocusEffect(
    React.useCallback(() => {
      return () => {
        onUpdatePlayingEntityId(entity, false);
      };
    }, []),
  );
  const hnaleSelectEntity = React.useCallback(() => {
    onSelectEntity(entity);
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <TouchableOpacity onPress={handlePlay}>
          {playing ? <StopIcon size={28} /> : <PlayIcon size={28} />}
        </TouchableOpacity>
        {playing ? (
          <SoundPlayable
            uri={entity.audioUrl}
            onFinishPlay={handleFinishPlay}
          />
        ) : null}
        {entity.name ? <Text style={styles.label}>{entity.name}</Text> : null}
      </View>
      {enabledFavorite && (
        <ResourceFavoriteButton
          style={styles.favoriteButton}
          resourceType={'Sound'}
          resourceId={entity.id}
          iconSize={13}
          initialized={true}
          defaultValue={userResourceFavorite}
        />
      )}
      <PrimaryButton
        buttonSize={{width: 60, height: 28}}
        onPress={hnaleSelectEntity}>
        選択
      </PrimaryButton>
    </View>
  );
};

export default React.memo(SoundListItem) as typeof SoundListItem;

const styles = StyleSheet.create({
  container: {
    margin: 16,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  left: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  label: {
    color: '#222',
    fontSize: 15,
    fontWeight: 'bold',
    marginLeft: 8,
  } as TextStyle,
  favoriteButton: {
    position: 'relative',
    top: '',
    right: 16,
    width: 28,
    height: 28,
    borderRadius: 14,
  } as ViewStyle,
});
