import * as React from 'react';
import {
  KeyboardType,
  NativeSyntheticEvent,
  StyleProp,
  TextInput,
  TextStyle,
  TextInputFocusEventData,
  TextInputSubmitEditingEventData,
  View,
} from 'react-native';

import {colors, formStyles} from '../../../styles/variables';

interface Props {
  style?: StyleProp<TextStyle>;
  placeholder?: string;
  value: string;
  textContentType?:
    | 'none'
    | 'URL'
    | 'addressCity'
    | 'addressCityAndState'
    | 'addressState'
    | 'countryName'
    | 'creditCardNumber'
    | 'emailAddress'
    | 'familyName'
    | 'fullStreetAddress'
    | 'givenName'
    | 'jobTitle'
    | 'location'
    | 'middleName'
    | 'name'
    | 'namePrefix'
    | 'nameSuffix'
    | 'nickname'
    | 'organizationName'
    | 'postalCode'
    | 'streetAddressLine1'
    | 'streetAddressLine2'
    | 'sublocality'
    | 'telephoneNumber'
    | 'username'
    | 'password'
    | 'newPassword'
    | 'oneTimeCode';
  placeholderTextColor?: string;
  keyboardType?: KeyboardType;
  autoCapitalize?: 'none' | 'sentences' | 'words' | 'characters';
  secureTextEntry?: boolean;
  onChangeText: (text: string) => void;
  onBlur?: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void;
  onSubmitEditing?: (
    e: NativeSyntheticEvent<TextInputSubmitEditingEventData>,
  ) => void;
}

export default class Input extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      style,
      placeholder,
      value,
      textContentType,
      placeholderTextColor,
      keyboardType,
      autoCapitalize,
      secureTextEntry,
      onChangeText,
      onBlur,
      onSubmitEditing,
    } = this.props;
    return (
      <View style={formStyles.formFieldWrapper}>
        <TextInput
          style={[formStyles.input, style]}
          placeholder={placeholder}
          value={value}
          textContentType={textContentType}
          placeholderTextColor={placeholderTextColor || colors.lightGray}
          onChangeText={onChangeText}
          keyboardType={keyboardType}
          autoCapitalize={autoCapitalize}
          secureTextEntry={secureTextEntry}
          onBlur={onBlur}
          onSubmitEditing={onSubmitEditing}
        />
      </View>
    );
  }
}
