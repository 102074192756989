import * as React from 'react';

import Layout from '../shared/Layout';

import UserExtensionMissionList from './partials/UserExtensionMissionList';
import ExtensionActivationModal from './partials/ExtensionActivationModal';

import NavigationProp from '../../navigators/NavigationProp';
import {getImageSource} from '../../helpers/extensionImages';
import {sendDefaultCustomEvent} from '../../helpers/analytics';

import UserExtensionMission from '../../../domain/entities/writer/UserExtensionMission';
import Extension from '../../../domain/entities/writer/Extension';
import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';

import NetUserExtensionMissionsRepository from '../../../data/repositories/writer/NetUserExtensionMissionsRepository';
import TapNovelRestApi from '../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, showCurrentUserStatus} = props;
  const [userExtensionMissions, setUserExtensionMissions] =
    React.useState<Array<UserExtensionMission> | null>(null);
  const [activateExtension, setActivateExtension] =
    React.useState<Extension | null>(null);
  const requestAndStore = React.useCallback(() => {
    new NetUserExtensionMissionsRepository().findAll().then(result => {
      setUserExtensionMissions(result.records);
    });
  }, []);
  React.useEffect(requestAndStore, []);
  const onRequestExtension = React.useCallback(
    (userExtensionMission: UserExtensionMission) => {
      TapNovelRestApi.post<{
        id: number;
        mission_name: string;
        writer_extension: {id: number; code: string; name: string};
      }>('/api/writer/user_extension_activations', {
        writer_extension_id:
          userExtensionMission.writerExtensionMission.writerExtension.id,
      }).then(response => {
        setActivateExtension(
          userExtensionMission.writerExtensionMission.writerExtension,
        );
        requestAndStore();
        sendDefaultCustomEvent({
          resourceType: 'writer/user_extension',
          resourceId: response.body.id,
          resourceName: response.body.writer_extension.code,
          actionName: 'create',
        });
        showCurrentUserStatus();
      });
    },
    [],
  );
  const onCloseModal = React.useCallback(() => {
    setActivateExtension(null);
  }, []);

  const onPressPro = React.useCallback(() => {
    navigation.navigate('ProNavigation', {});
  }, []);
  return (
    <Layout
      title={'機能拡張'}
      navigation={navigation}
      scrollable={false}
      back={true}>
      {userExtensionMissions && (
        <UserExtensionMissionList
          userExtensionMissions={userExtensionMissions}
          onRequestExtension={onRequestExtension}
          onPressPro={onPressPro}
        />
      )}
      {activateExtension && (
        <ExtensionActivationModal
          visible={true}
          title={activateExtension.name}
          description={activateExtension.description}
          source={getImageSource(activateExtension.code)}
          imageStyle={{width: 279, height: 220}}
          onCloseModal={onCloseModal}
        />
      )}
    </Layout>
  );
};

export default React.memo(Index);
