import React from 'react';
import {
  FlatList,
  LayoutChangeEvent,
  ListRenderItemInfo,
  StyleProp,
  ViewStyle,
} from 'react-native';

import ActorFaceGridListItem from './ActorCharacterFaceGridListItem';

import ActorCharacterFace from '../../../../../domain/entities/shop/ActorCharacterFace';

import {scaledValue} from '../../../../styles';

interface Props {
  actorCharacterFaces: ActorCharacterFace[];
  selectedActorCharacterFace: ActorCharacterFace;
  onSelectActorCharacterFace: (actorCharacterFace: ActorCharacterFace) => void;
}

const ActorCharacterFaceGridList: React.FC<Props> = props => {
  const {
    actorCharacterFaces,
    selectedActorCharacterFace,
    onSelectActorCharacterFace,
  } = props;
  const [width, setWidth] = React.useState<number | undefined>(undefined);
  const onLayout = React.useCallback((event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  }, []);
  const contentContainerStyle = React.useMemo(():
    | StyleProp<ViewStyle>
    | undefined => {
    if (!width) {
      return undefined;
    }
    return {marginHorizontal: scaledValue(width, 16), paddingBottom: 46};
  }, [width]);
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ActorCharacterFace>) => {
      return (
        <ActorFaceGridListItem
          actorCharacterFace={info.item}
          width={width}
          active={info.item.id === selectedActorCharacterFace.id}
          onSelectActorCharacterFace={onSelectActorCharacterFace}
        />
      );
    },
    [width, selectedActorCharacterFace],
  );
  return (
    <FlatList
      data={actorCharacterFaces}
      contentContainerStyle={contentContainerStyle}
      onLayout={onLayout}
      numColumns={3}
      renderItem={renderItem}
      extraData={[selectedActorCharacterFace]}
    />
  );
};

export default React.memo(ActorCharacterFaceGridList);

const style = {};
