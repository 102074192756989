import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import KeyboardSpacer from '../../shared/KeyboardSpacer';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import TextArea from '../../shared/forms/TextArea';
import LabelWithOption from '../../shared/forms/LabelWithOption';

import Comment from '../../response/episodes/partials/Comment';

import {formStyles} from '../../../styles/variables';

import EpisodeComment from '../../../../domain/entities/EpisodeComment';

interface Props {
  initialComment: string;
  episodeComment: EpisodeComment;
  onSubmit: (comment: string) => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {initialComment, episodeComment, onSubmit} = props;
  const [comment, setComment] = React.useState(initialComment);
  const [visibleComment, setVisibleComment] = React.useState(false);
  const handleToggleComment = React.useCallback(() => {
    setVisibleComment(!visibleComment);
  }, [visibleComment]);
  const handleSubmit = React.useCallback(() => {
    onSubmit(comment);
  }, [comment]);
  return (
    <View style={styles.container}>
      {visibleComment && <Comment episodeComment={episodeComment} />}
      <View style={[styles.formGroup, styles.formGroupTextArea]}>
        <LabelWithOption
          left={
            <TouchableOpacity
              style={{flexDirection: 'row'}}
              onPress={handleToggleComment}>
              <View
                style={
                  visibleComment ? styles.arrowDownIcon : styles.arrowUpIcon
                }
              />
              <Text style={{color: '#999', fontSize: 10, fontWeight: 'bold'}}>
                {visibleComment
                  ? '返信元コメントを非表示'
                  : '返信元コメントを表示'}
              </Text>
            </TouchableOpacity>
          }
          length={comment.length}
          maxLength={MAX_COMMENT_SIZE}
          withUnit={true}
        />
        <TextArea
          style={[formStyles.formTextArea, styles.formTextArea]}
          value={comment}
          onChangeText={setComment}
        />
      </View>
      <View style={styles.submitButton}>
        <PrimaryButton
          disabled={comment.length === 0 || comment.length > MAX_COMMENT_SIZE}
          onPress={handleSubmit}>
          投稿する
        </PrimaryButton>
      </View>
      <KeyboardSpacer />
    </View>
  );
};

export default React.memo(Form);

const MAX_COMMENT_SIZE = 200;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  } as ViewStyle,
  formGroup: {
    margin: 16,
  } as ViewStyle,
  formGroupTextArea: {
    marginBottom: 0,
  } as ViewStyle,
  submitButton: {
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  formTextArea: {
    height: 174,
  } as TextStyle,
  arrowDownIcon: {
    width: 7,
    height: 7,
    borderColor: '#999999',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    transform: [{rotate: '45deg'}],
    top: 2,
    marginRight: 5,
  } as ViewStyle,
  arrowUpIcon: {
    width: 7,
    height: 7,
    borderColor: '#999999',
    borderRightWidth: 1,
    borderBottomWidth: 1,
    transform: [{rotate: '225deg'}],
    top: 6,
    marginRight: 5,
  } as ViewStyle,
});
