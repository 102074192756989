import {Dispatch} from 'redux';

import Types from '../../Types';

import {sendDefaultCustomEvent} from '../../../helpers/analytics';

import Contact from '../../../../domain/entities/writer/Contact';

import NetContactsRepository from '../../../../data/repositories/writer/NetContactsRepository';

export interface Params {
  writerContactCategoryId: number;
  email: string;
  content: string;
  deviceName?: string;
  platformName?: string;
  platformVersion?: string;
  applicationVersion?: string;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Contact> {
  return async (dispatch: Dispatch<any>): Promise<Contact> => {
    try {
      const payload = await new NetContactsRepository().create({
        writerContact: params,
      });
      dispatch({payload, type: Types.CreateWriterContactSuccess});
      sendDefaultCustomEvent({
        resourceType: 'writer/contact',
        resourceId: payload.id,
        resourceName: payload.writerContactCategory.name,
        actionName: 'create',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateWriterContactFailure});
      throw err;
    }
  };
}
