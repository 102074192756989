import * as React from 'react';
import {
  Image,
  ImageBackground,
  ImageSourcePropType,
  PixelRatio,
  Platform,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import NameLabel from './NameLabel';
import ElasticBoxBadges from './ElasticBoxBadges';

import NameLabelColor from '../../view_models/NameLabelColor';

import {isAndroid} from '../../../data/data_stores/net/UserAgent';

const isWeb = Platform.OS === 'web';

const uriToSize: {[key: string]: {width: number; height: number}} = {};

const headers = {Accept: 'image/webp,image/apng,*/*'};
const cache = 'force-cache';

const RESIZE_MODE = 'stretch';

const RESIZE_METHOD = 'resize';

interface Props extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
  text: string;
  name?: string;
  nameLabelColor?: NameLabelColor;
  hasVoice?: boolean;
  hasSound?: boolean;
  top: string;
  middle: string;
  bottom: string;
  height: number;
  width: number;
  useStyledFrame?: boolean;
  middleStyle?: StyleProp<ViewStyle>;
  voiceIconStyle?: ViewStyle;
  onChangeName?: (name: string) => void;
}

export default class ElasticBox extends React.PureComponent<Props> {
  private mounted = false;
  private loaded = false;
  private topRatio: number | null = null;
  private middleRatio: number | null = null;
  private bottomRatio: number | null = null;
  private topSource: ImageSourcePropType;
  private middleSource: ImageSourcePropType;
  private bottomSource: ImageSourcePropType;

  constructor(props: Props) {
    super(props);
    const {top, middle, bottom, middleStyle} = this.props;
    this.topSource = {uri: props.top, cache, headers};
    this.middleSource = {uri: props.middle, cache, headers};
    this.bottomSource = {uri: props.bottom, cache, headers};
    if (uriToSize[top]) {
      this.generateTopImageSizeState(
        uriToSize[top].width,
        uriToSize[top].height,
      );
    }
    if (uriToSize[middle]) {
      this.generateMiddleImageSizeState(
        uriToSize[middle].width,
        uriToSize[middle].height,
      );
    }
    if (uriToSize[bottom]) {
      this.generateBottomImageSizeState(
        uriToSize[bottom].width,
        uriToSize[bottom].height,
      );
    }
  }

  public componentDidMount() {
    this.mounted = true;
    const {top, middle, bottom, useStyledFrame} = this.props;
    if (useStyledFrame) {
      return true;
    }
    this.getImageSize(top, this.handleTopImageSize);
    this.getImageSize(middle, this.handleMiddleImageSize);
    this.getImageSize(bottom, this.handleBottomImageSize);
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {top, middle, bottom, middleStyle} = this.props;
    if (
      top !== prevProps.top ||
      middle !== prevProps.middle ||
      bottom !== prevProps.bottom
    ) {
      this.loaded = false;
    }
    if (top !== prevProps.top) {
      this.topRatio = null;
      this.topSource = {uri: top, cache, headers};
      this.getImageSize(top, this.handleTopImageSize);
    }
    if (middle !== prevProps.middle) {
      this.middleRatio = null;
      this.middleSource = {uri: middle, cache, headers};
      this.getImageSize(middle, this.handleMiddleImageSize);
    }
    if (bottom !== prevProps.bottom) {
      this.bottomRatio = null;
      this.bottomSource = {uri: bottom, cache, headers};
      this.getImageSize(bottom, this.handleBottomImageSize);
    }
  }

  public render(): React.ReactNode {
    const {
      style,
      name,
      nameLabelColor,
      hasVoice,
      hasSound,
      height,
      width,
      useStyledFrame,
      middleStyle,
      voiceIconStyle,
      onChangeName,
      children,
    } = this.props;
    if (useStyledFrame) {
      return (
        <View style={[style, styles.styledFrame, {height, width}]}>
          {children}
          <ElasticBoxBadges
            style={voiceIconStyle}
            hasSound={hasSound}
            hasVoice={hasVoice}
          />
        </View>
      );
    }
    if (!(this.topRatio && this.middleRatio && this.bottomRatio)) {
      return null;
    }
    return (
      <View style={[styles.container, style, {width}]}>
        <Image
          resizeMode={RESIZE_MODE}
          resizeMethod={RESIZE_METHOD}
          style={{height: getRoundSize(width / this.topRatio), width}}
          source={this.topSource}
        />
        <ImageBackground
          resizeMode={RESIZE_MODE}
          resizeMethod={RESIZE_METHOD}
          style={{height: getRoundSize(height), width}}
          source={this.middleSource}>
          <View style={middleStyle}>{children}</View>
          <View style={[middleStyle, styles.nameLabel]}>
            {name !== undefined && (
              <NameLabel
                name={name}
                color={nameLabelColor}
                editable={!!onChangeName}
                onChangeName={onChangeName}
              />
            )}
          </View>
          <ElasticBoxBadges
            style={voiceIconStyle}
            hasSound={hasSound}
            hasVoice={hasVoice}
          />
        </ImageBackground>
        <Image
          resizeMode={RESIZE_MODE}
          resizeMethod={RESIZE_METHOD}
          style={{height: getRoundSize(width / this.bottomRatio), width}}
          source={this.bottomSource}
        />
      </View>
    );
  }

  private getImageSize(
    uri: string,
    callback: (width: number, height: number) => void,
    retry = 3,
  ) {
    const size = uriToSize[uri];
    if (size) {
      return callback(size.width, size.height);
    }
    Image.getSize(
      uri,
      (width, height) => {
        if (!this.mounted) {
          return;
        }
        uriToSize[uri] = {width, height};
        callback(width, height);
      },
      () => {
        if (retry > 0) {
          this.getImageSize(uri, callback, retry - 1);
        } else {
          throw Error(`Cannot get image size: ${uri}`);
        }
      },
    );
  }

  private handleTopImageSize = (imageWidth: number, imageHeight: number) => {
    this.generateTopImageSizeState(imageWidth, imageHeight);
  };

  private handleMiddleImageSize = (imageWidth: number, imageHeight: number) => {
    this.generateMiddleImageSizeState(imageWidth, imageHeight);
  };

  private handleBottomImageSize = (imageWidth: number, imageHeight: number) => {
    this.generateBottomImageSizeState(imageWidth, imageHeight);
  };

  private generateTopImageSizeState = (
    imageWidth: number,
    imageHeight: number,
  ) => {
    const {width, middleStyle} = this.props;
    const topSize = {
      height: getRoundSize((width * imageHeight) / imageWidth),
      width: getRoundSize(width),
    };
    this.topRatio = imageWidth / imageHeight;
    this.forceUpdateIfLoaded();
  };

  private generateMiddleImageSizeState = (
    imageWidth: number,
    imageHeight: number,
  ) => {
    this.middleRatio = imageWidth / imageHeight;
    this.forceUpdateIfLoaded();
  };

  private generateBottomImageSizeState = (
    imageWidth: number,
    imageHeight: number,
  ) => {
    const {width} = this.props;
    const bottomSize = {
      height: getRoundSize((width * imageHeight) / imageWidth),
      width: getRoundSize(width),
    };
    this.bottomRatio = imageWidth / imageHeight;
    this.forceUpdateIfLoaded();
  };

  private forceUpdateIfLoaded = () => {
    if (this.loaded) {
      return;
    }
    if (this.topRatio && this.middleRatio && this.bottomRatio) {
      this.loaded = true;
      if (this.mounted) {
        this.forceUpdate();
      }
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  styledFrame: {
    backgroundColor: 'black',
    opacity: 0.8,
  } as ViewStyle,
  nameLabel: {
    position: 'absolute',
    left: 10,
  } as ViewStyle,
});

const getRoundSize = (size: number) => {
  if (isWeb && !isAndroid) {
    return Math.round(size);
  } else {
    return PixelRatio.roundToNearestPixel(size);
  }
};
