import * as React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {getStateFromPath, getActionFromState} from '@react-navigation/native';
import styled from 'styled-components';

import LogoWhite from '../images/LogoWhite';
import CloseIcon from '../icons/CloseIcon';
import MenuIcon from '../icons/MenuIcon';
import ArrowForwardIcon from '../icons/ArrowForwardIcon';
import DefaultList from '../lists/DefaultList';

import {colors} from '../../../styles/variables';

import destroyWriterCurrentUserSession from '../../../actions/writer/users/session/destroy';

import store from '../../../store/configureStore';
import {navigationRef} from '../../../navigators/navigationRef';
import dispatchAction from '../../../navigators/dispatchAction';

interface Props {
  signedIn?: boolean;
}

const REGISTRATION_URL = '/users/sign_up';
const SESSION_URL = '/users/sign_in';

const MENU_ITEMS = [
  {title: '会員登録', path: REGISTRATION_URL},
  {title: 'ログイン', path: SESSION_URL},
];

let removeListener: (() => void) | undefined;

const Header: React.FunctionComponent<Props> = props => {
  const {signedIn} = props;
  const [showMenu, setShowMenu] = React.useState(false);
  React.useEffect(() => {
    removeListener = navigationRef?.current?.addListener('state', () => {
      if (showMenu) {
        setShowMenu(false);
        removeListener && removeListener();
      }
    });
  }, [showMenu, setShowMenu]);
  return (
    <Container signedIn={signedIn}>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          flexDirection: 'row',
          height: '100%',
          alignItems: 'center',
        }}>
        <TouchableOpacity
          style={{alignContent: 'center'}}
          onPress={() => dispatchAction('')}>
          <LogoWhite width={187} height={29} />
        </TouchableOpacity>
      </View>
      <MenuButton type="button">
        <TouchableOpacity onPress={() => setShowMenu(!showMenu)}>
          {showMenu ? (
            <CloseIcon color={'white'} />
          ) : (
            <MenuIcon color={'white'} />
          )}
        </TouchableOpacity>
      </MenuButton>
      {showMenu && (
        <DefaultList
          data={MENU_ITEMS}
          renderTitle={item => item.title}
          onSelectItem={item => {
            dispatchAction(item.path);
          }}
        />
      )}
      <Menu>
        <ul>
          {signedIn ? (
            <li>
              {/* eslint-disable-next-line */}
              <a
                onClick={() => {
                  store.dispatch(destroyWriterCurrentUserSession() as never);
                  dispatchAction('');
                }}>
                <span>ログアウト</span>
                <ArrowForwardIcon />
              </a>
            </li>
          ) : (
            <>
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => dispatchAction(REGISTRATION_URL)}>
                  <span>会員登録</span>
                </a>
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a onClick={() => dispatchAction(SESSION_URL)}>
                  <span>ログイン</span>
                </a>
              </li>
            </>
          )}
        </ul>
      </Menu>
    </Container>
  );
};

interface ContainerProps {
  signedIn?: boolean;
}

const Container = styled.header<ContainerProps>`
  position: absolute;
  width: 100%;
  height: 44px;
  background-color: ${colors.lightBlack};
  box-shadow: 0 1px 0 black;
  z-index: 3;
  @media (min-width: 767px) {
    height: 50px;
  }
  ${props =>
    props.signedIn
      ? `
      display: none;
      @media (min-width: 767px) {
        display: block;
      }
  `
      : null}
  @media (max-width: 480px) {
    display: none;
  }
`;

const MenuButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  @media (min-width: 767px) {
    display: none;
  }
`;

const Menu = styled.nav`
  display: none;
  position: absolute;
  top: 44px;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  @media (min-width: 767px) {
    display: block;
    top: 0;
    right: 0;
    width: auto;
    background-color: transparent;
    margin: 0 15px;
    box-shadow: none;
  }

  li {
    position: relative;
    border-bottom: 1px solid ${colors.powderGray};
    @media (min-width: 767px) {
      display: inline-block;
      border-bottom: 0;
    }

    a {
      display: block;
      padding: 18px 15px;
      font-size: 15px;
      cursor: pointer;
      @media (min-width: 767px) {
        height: 50px;
        color: white;
        padding: 17px 15px;

        :hover {
          color: #ddd;
        }
      }
    }
  }
`;

export default React.memo(Header);
