import * as React from 'react';
import {Image, ImageStyle, StyleSheet, View, ViewStyle} from 'react-native';

import {
  actorCharacterFaceImageUrl,
  markImageUrl,
} from '../../.././../../../helpers/images';

import ActorCharacterFace from '../../../../../../../domain/entities/ActorCharacterFace';
import Mark from '../../../../../../../domain/entities/Mark';

interface Props {
  actorCharacterFace: ActorCharacterFace | null;
  mark: Mark | null;
  width: number;
  inverted?: boolean;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {actorCharacterFace, mark, width, inverted} = props;
  const characterSize = width * ASPECT_RATIO - MARGIN;
  return (
    <View style={[styles.container, {width, height: width * ASPECT_RATIO}]}>
      <View style={styles.imageWrapper}>
        {mark && mark.id !== 0 ? (
          <View style={styles.markWrapper}>
            <Image
              style={styles.mark}
              resizeMode={'contain'}
              source={{uri: markImageUrl(mark, 'large')}}
            />
          </View>
        ) : null}
        {actorCharacterFace && (
          <Image
            style={[
              {width: characterSize, height: characterSize},
              inverted ? styles.inverted : null,
            ]}
            resizeMode={'contain'}
            source={{
              uri: actorCharacterFaceImageUrl(actorCharacterFace, 'large'),
            }}
          />
        )}
      </View>
    </View>
  );
};

export default React.memo(CharacterView);

const ASPECT_RATIO = 256 / 375;

const MARGIN = 16;

const MARK_SIZE = 30;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    padding: 8,
  } as ViewStyle,
  imageWrapper: {
    flex: 1,
  } as ViewStyle,
  mark: {
    width: MARK_SIZE,
    height: MARK_SIZE,
  } as ImageStyle,
  markWrapper: {
    position: 'absolute',
    left: 0,
    right: 100,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  } as ViewStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ImageStyle,
});
