import * as React from 'react';

import ProjectList from './partials/ProjectList';
import NewProjectButton from './partials/NewProjectButton';
import Header from './partials/Header';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectsSearchRouteProp} from '../../../navigators/RouteProps';

import Project from '../../../../domain/entities/Project';
import Genre from '../../../../domain/entities/Genre';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  query: string;
  genre_id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectsSearchRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {query, genre_id} = route.params;
  const [sort, setSort] = React.useState<'post_updated_at' | 'new_arrival'>(
    'post_updated_at',
  );
  const [title, setTitle] = React.useState<string>('プロジェクト一覧');
  const [genre, setGenre] = React.useState<Genre | null>(null);
  const [projects, setProjects] = React.useState<Project[] | null>(null);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  React.useEffect(() => {
    if (genre_id) {
      TapNovelTypedRestApi.get<Genre>(`/api/genres/${genre_id}`).then(
        result => {
          setGenre(result.body);
        },
      );
    }
  }, []);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<Project[]>('/api/writer/projects', {
      query,
      genre_id,
      sort,
    }).then(result => {
      setTotalCount(Number.parseInt(result.headers.get('Total') || ''));
      setProjects(result.body);
    });
  }, [sort]);
  React.useEffect(() => {
    if (genre) {
      setTitle(`${query ? `${query}/` : ''}${genre.name}のプロジェクト一覧`);
    } else if (query) {
      setTitle(`${query}のプロジェクト一覧`);
    }
  }, [genre]);
  return (
    <Layout
      title={'プロジェクト'}
      navigation={navigation}
      back={true}
      close={true}
      containerStyle={{paddingBottom: 100}}
      footer={<NewProjectButton />}>
      {projects && (
        <ProjectList
          projects={projects}
          ListHeaderComponent={
            <Header
              title={title}
              totalCount={totalCount}
              sort={sort}
              onChangeSort={setSort}
            />
          }
        />
      )}
    </Layout>
  );
};

export default React.memo(Index);
