import Entity from './Entity';

import ActorCharacter from './ActorCharacter';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class ActorCostume implements Entity {
  constructor(
    public id: number,
    public name: string,
    public originalImageUrl: string,
    public actorCharacter: ActorCharacter,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getLabel() {
    return this.name;
  }
}
