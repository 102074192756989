import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import ManualCategoryListItem from './ManualCategoryListItem';

import shouldUpdateEntityList from '../../shared/enhanced/shouldUpdateEntityList';
import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import ManualCategory from '../../../../domain/entities/writer/ManualCategory';

interface Props {
  manualCategories: ManualCategory[];
  onSelectManualCategory: (manualCategory: ManualCategory) => void;
}

export default class ManualList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return shouldUpdateEntityList(
      {entities: this.props.manualCategories},
      {entities: nextProps.manualCategories},
    );
  }

  public render(): React.ReactNode {
    const {manualCategories} = this.props;
    return (
      <FlatList
        data={manualCategories}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
        ListFooterComponent={ListItemNormalSeparator}
      />
    );
  }

  private keyExtractor = (item: ManualCategory, index: number): string => {
    return `${item.id}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<ManualCategory>,
  ): React.ReactElement<any> | null => {
    const {onSelectManualCategory} = this.props;
    return (
      <ManualCategoryListItem
        manualCategory={info.item}
        onSelectManualCategory={onSelectManualCategory}
      />
    );
  };
}
