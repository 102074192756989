import {CommonActions} from '@react-navigation/native';

import NavigationProp from '../navigators/NavigationProp';

import SceneForm from '../../domain/forms/SceneForm';

interface Props {
  navigation: NavigationProp;
  params: {episodeId?: number; sceneId?: number};
  sceneForm: SceneForm | null;
}

function redirectSceneForm(
  navigation: NavigationProp,
  route: {params: {episodeId?: number; sceneId?: number}},
  sceneForm: SceneForm | null,
) {
  const {episodeId, sceneId} = route.params;
  if (!sceneForm) {
    if (sceneId) {
      setTimeout(() => {
        navigation.getParent()?.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {name: 'Home'},
              {
                params: {sceneId},
                name: 'EditScene',
              },
            ],
          }),
        );
        navigation.goBack();
      }, 300);
    } else if (episodeId) {
      setTimeout(() => {
        navigation.getParent()?.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {name: 'Home'},
              {
                params: {episodeId},
                name: 'NewScene',
              },
            ],
          }),
        );
        navigation.goBack();
      }, 300);
    } else {
      navigation.goBack();
    }
    return true;
  }
  return false;
}

export default redirectSceneForm;
