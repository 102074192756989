import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import BackgroundTimeZone from '../../../domain/entities/BackgroundTimeZone';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetBackgroundTimeZonesRepository from '../../../data/repositories/NetBackgroundTimeZonesRepository';

const types = {
  failure: Types.IndexBackgroundTimeZonesFailure,
  request: Types.IndexBackgroundTimeZonesRequest,
  success: Types.IndexBackgroundTimeZonesSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<BackgroundTimeZone>> {
  return indexGenerics(new NetBackgroundTimeZonesRepository(), types, params);
}
