import * as React from 'react';

import BaseIndex from '../../common/effect_positions/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormEffectShowSceneCommandNewEffectPositionsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as EffectUsageHistoryUpdateParams} from '../../../../../actions/effect_usage_histories/update';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import Effect from '../../../../../../domain/entities/Effect';
import EffectUsageHistory from '../../../../../../domain/entities/EffectUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import Position from '../../../../../../domain/value_objects/Position';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  effectId: number;
  parentSceneCommandId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandNewEffectPositionsRouteProp;
  sceneForm: SceneForm | null;
  effect: Effect | null;
  parentSceneCommandForm: SceneCommandForm | null;
}

export interface DispatchProps {
  showEffect: (id: number) => Promise<Effect>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateEffectUsageHistory: (
    id: number,
    params: EffectUsageHistoryUpdateParams,
  ) => Promise<EffectUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  private disabledSubmit = false;

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (position: Position): void => {
    if (this.disabledSubmit) {
      return;
    }
    const {effect} = this.props;
    if (!effect) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      createSceneCommandForm,
      updateSceneCommandForm,
      updateEffectUsageHistory,
    } = this.props;
    const {storyId, sceneCommandIndex, subSceneCommandIndex} = route.params;
    const sceneCommandForm = new EffectShowSceneCommandForm(effect, position);
    if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.add(
          parentSceneCommandForm,
          sceneCommandForm,
          subSceneCommandIndex !== undefined
            ? {insertIndex: subSceneCommandIndex}
            : undefined,
        ),
      });
    } else {
      createSceneCommandForm({
        index: sceneCommandIndex,
        sceneCommandForm,
      });
    }
    updateEffectUsageHistory(effect.id, {storyId}).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };
}
