import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import SikinColorButton from './SikinColorButton';

import MultiSwitch from '../../../../forms/MultiSwitch';

import PrimaryButton from '../../../../buttons/PrimaryButton';

import CharacterMakerActorGroup from '../../../../../../../domain/entities/CharacterMakerActorGroup';

type Gender = 'male' | 'female';
type Generation = 'adult' | 'child' | 'elder';

interface Props {
  contentWidth: number;
  characterMakerActorGroups: CharacterMakerActorGroup[] | null;
  onSelectCharacterMakerActorGroup: (
    characterMakerActorGroup: CharacterMakerActorGroup,
    skinColorPatternId: number,
  ) => void;
}

const CharacterMakerActorSelectForm: React.FC<Props> = props => {
  const {characterMakerActorGroups, onSelectCharacterMakerActorGroup} = props;
  const [skinColorPatternId, setSkinColorPatternId] = React.useState<number>(1);
  const [gender, setGender] = React.useState<Gender>('male');
  const [generation, setGeneration] = React.useState<Generation>('adult');
  const onSelectGender = React.useCallback(({value}: {value: string}) => {
    if (value === 'male' || value === 'female') {
      setGender(value);
    }
  }, []);
  const onSelectGeneration = React.useCallback(({value}: {value: string}) => {
    if (value === 'adult' || value === 'child' || value === 'elder') {
      setGeneration(value);
    }
  }, []);
  const onSubmit = React.useCallback(() => {
    const characterMakerActorGroupId =
      CHARACTER_MAKER_ACTOR_GROOUPS[`${gender}/${generation}`];
    if (!characterMakerActorGroupId) {
      return;
    }
    const characterMakerActorGroup = characterMakerActorGroups?.find(
      group => group.id === characterMakerActorGroupId,
    );
    if (!characterMakerActorGroup) {
      return;
    }
    onSelectCharacterMakerActorGroup(
      characterMakerActorGroup,
      skinColorPatternId,
    );
  }, [characterMakerActorGroups, skinColorPatternId, gender, generation]);
  return (
    <View style={styles.container}>
      <View style={styles.boxes}>
        <View style={styles.box}>
          <Text style={styles.label}>肌の色</Text>
          <View style={styles.skinColors}>
            {SKIN_COLOR_ITEMS.map(({label, color, value}) => (
              <SikinColorButton
                label={label}
                color={color}
                value={value}
                checked={skinColorPatternId === value}
                onPress={setSkinColorPatternId}
              />
            ))}
          </View>
        </View>
        <View style={styles.box}>
          <Text style={styles.label}>性別</Text>
          <MultiSwitch
            items={GENDER_ITEMS}
            value={gender}
            style={styles.multiSwitch}
            onSelectItem={onSelectGender}
          />
        </View>
        <View style={styles.box}>
          <Text style={styles.label}>年齢</Text>
          <MultiSwitch
            items={GENERATION_ITEMS}
            value={generation}
            style={styles.multiSwitch}
            onSelectItem={onSelectGeneration}
          />
        </View>
      </View>
      <View style={styles.button}>
        <PrimaryButton onPress={onSubmit}>
          この素体でキャラクターをつくる
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(CharacterMakerActorSelectForm);

const SKIN_COLOR_ITEMS = [
  {label: '通常', color: '#f7dec5', value: 1},
  {label: '色白', color: '#ffede2', value: 2},
  {label: '色黒', color: '#e5bf94', value: 3},
  {label: '褐色', color: '#cca07a', value: 4},
];

const GENDER_ITEMS = [
  {label: '男性', value: 'male'},
  {label: '女性', value: 'female'},
];

const GENERATION_ITEMS = [
  {label: '青年', value: 'adult'},
  {label: '子供', value: 'child'},
  {label: '高齢', value: 'elder'},
];

const CHARACTER_MAKER_ACTOR_GROOUPS = {
  'male/adult': 1,
  'female/adult': 2,
  'male/child': 3,
  'female/child': 4,
  'male/elder': 5,
  'female/elder': 6,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 22,
    padding: 16,
  } as ViewStyle,
  boxes: {
    flex: 1,
  } as ViewStyle,
  box: {
    marginBottom: 16,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 6,
  } as TextStyle,
  skinColors: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 6,
    marginHorizontal: 16,
  } as ViewStyle,
  multiSwitch: {
    marginTop: 0,
  } as ViewStyle,
  button: {
    alignItems: 'center',
  } as ViewStyle,
});
