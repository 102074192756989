import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import MemberList from './MemberList';
import MemberListItem from './MemberListItem';

import ProjectMember from '../../../../../domain/entities/ProjectMember';

interface Props {
  leader: ProjectMember;
  members: ProjectMember[];
  onForceUpdate: () => void;
}

const MemberListWithLeader: React.FC<Props> = props => {
  const {leader, members, onForceUpdate} = props;
  return (
    <MemberList
      members={members}
      ListHeaderComponent={<ListHeaderComponent leader={leader} />}
      onForceUpdate={onForceUpdate}
    />
  );
};

export default React.memo(MemberListWithLeader);

const ListHeaderComponent: React.FC<{leader: ProjectMember}> = props => {
  const {leader} = props;
  return (
    <>
      <View style={styles.headerTitle}>
        <Text style={styles.headerTitleText}>リーダー</Text>
      </View>
      <View style={styles.leader}>
        <MemberListItem member={leader} leaderSection={true} />
      </View>
      <View style={styles.headerTitle}>
        <Text style={styles.headerTitleText}>メンバー</Text>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  headerTitle: {
    backgroundColor: '#fafafa',
    height: 39,
    paddingHorizontal: 16,
    justifyContent: 'center',
    borderColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  headerTitleText: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  leader: {
    borderColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
});
