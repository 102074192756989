import Story, {Rating, Format} from '../entities/Story';

import File from '../entities/File';

import ImageTextInfo from '../value_objects/ImageTextInfo';

import fetchBlob from '../../data/data_stores/net/FetchBlob';

export default class StoryForm {
  public id: number | null = null;
  public title: string | null = null;
  public catchPhrase: string | null = null;
  public summary: string | null = null;
  public introduction: string | null = null;
  public coverIllustrator: string | null = null;
  public rating: Rating | null = null;
  public format: Format | null = null;
  public image: File | null | undefined = null;
  public coverImage: File | null | undefined = null;
  public uploadedSelfImage: boolean | null | undefined = null;
  public uploadedSelfCoverImage: boolean | null | undefined = null;
  public hasImage: boolean | null = null;
  public originalImageUrl: string | null = null;
  public hasCoverImage: boolean | null = null;
  public originalCoverImageUrl: string | null = null;
  public published: boolean | undefined = undefined;
  public serialized: boolean | null | undefined = null;
  public allowedVideoSharing: boolean | null | undefined = undefined;
  public mainGenreId: number | null = null;
  public subGenreIds: number[] | null = null;
  public keywordNames: string[] | null = null;

  public imageBackgroundUri: string | null = null;
  public imageCharacterUri: string | null = null;
  public imageTextInfoList: ImageTextInfo[] | null = null;

  public coverImageBackgroundUri: string | null = null;
  public coverImageCharacterUri: string | null = null;
  public coverImageTextInfoList: ImageTextInfo[] | null = null;

  public bind(story: Story) {
    this.id = story.id;
    this.title = story.title;
    this.catchPhrase = story.catchPhrase;
    this.summary = story.summary;
    this.introduction = story.introduction;
    this.coverIllustrator = story.coverIllustrator;
    this.rating = story.rating;
    this.format = story.format;
    this.hasImage = story.hasImage;
    this.originalImageUrl = story.originalImageUrl;
    this.hasCoverImage = story.hasCoverImage;
    this.originalCoverImageUrl = story.originalCoverImageUrl;
    this.serialized = story.serialized;
    this.allowedVideoSharing = story.allowedVideoSharing;
    const mainGenre = story.getMainGenre();
    if (mainGenre) {
      this.mainGenreId = mainGenre.id;
    }
    this.subGenreIds = story.getSubGenres().map(genre => genre.id);
    this.keywordNames = story.keywords.map(keyword => keyword.name);
  }

  public toParams(): {[key: string]: any} {
    const image = this.image;
    const coverImage = this.coverImage;
    const keywordNames =
      this.keywordNames && this.keywordNames.length === 0
        ? ['']
        : this.keywordNames;
    let baseParams: {[key: string]: any} = {
      catchPhrase: this.catchPhrase,
      id: this.id,
      introduction: this.introduction,
      coverIllustrator: this.coverIllustrator,
      rating: this.rating,
      format: this.format,
      keywordNames: keywordNames,
      mainGenreId: this.mainGenreId,
      published: this.published,
      serialized: this.serialized,
      allowedVideoSharing: this.allowedVideoSharing,
      subGenreIds: this.subGenreIds,
      summary: this.summary,
      title: this.title,
      uploadedSelfMaterial:
        this.uploadedSelfImage || this.uploadedSelfCoverImage,
    };
    if (image !== undefined) {
      baseParams = {image, ...baseParams};
    }
    if (coverImage !== undefined) {
      baseParams = {coverImage, ...baseParams};
    }
    return baseParams;
  }

  public getBackgroundImageUri = async (imageKey: 'coverImage' | 'image') => {
    switch (imageKey) {
      case 'coverImage': {
        if (this.coverImageBackgroundUri) {
          return this.coverImageBackgroundUri;
        }
        if (this.coverImage) {
          return this.coverImage.uri;
        }
        if (this.hasCoverImage && this.originalCoverImageUrl) {
          return await fetchBlob(this.originalCoverImageUrl);
        }
        break;
      }
      case 'image': {
        if (this.imageBackgroundUri) {
          return this.imageBackgroundUri;
        }
        if (this.image) {
          return this.image.uri;
        }
        if (this.hasImage && this.originalImageUrl) {
          return await fetchBlob(this.originalImageUrl);
        }
        break;
      }
    }
    return null;
  };

  public getCharacterImageUri = (imageKey: 'coverImage' | 'image') => {
    switch (imageKey) {
      case 'coverImage':
        return this.coverImageCharacterUri;
      case 'image':
        return this.imageCharacterUri;
    }
    return null;
  };

  public getImageTextInfoList = (imageKey: 'coverImage' | 'image') => {
    switch (imageKey) {
      case 'coverImage':
        return this.coverImageTextInfoList || [];
      case 'image':
        return this.imageTextInfoList || [];
    }
    return [];
  };
}
