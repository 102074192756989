import EntityMapper from './EntityMapper';

import EpisodeReaction from '../../../domain/entities/EpisodeReaction';

import ReactionMapper, {ReactionData} from './ReactionMapper';
import UserMapper, {UserData} from './consumer/UserMapper';

export interface EpisodeReactionData {
  id: number;
  writer_user_id: number;
  story: {id: number};
  reaction: ReactionData;
  updated_at: string;
  created_at: string;
  consumer_user: UserData;
}

export default class EpisodeReactionMapper
  implements EntityMapper<EpisodeReactionData, EpisodeReaction>
{
  private reactionMapper: ReactionMapper;
  private userMapper: UserMapper;

  constructor() {
    this.reactionMapper = new ReactionMapper();
    this.userMapper = new UserMapper();
  }

  public map(obj: EpisodeReactionData): EpisodeReaction {
    return new EpisodeReaction(
      obj.id,
      obj.writer_user_id,
      obj.story.id,
      this.reactionMapper.map(obj.reaction),
      new Date(obj.updated_at),
      new Date(obj.created_at),
      this.userMapper.map(obj.consumer_user),
    );
  }
}
