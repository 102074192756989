import * as React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import shouldUpdateEntity from '../../shared/enhanced/shouldUpdateEntity';

import {colors} from '../../../styles/variables';

import AnnouncementCategory from '../../../../domain/entities/writer/AnnouncementCategory';

interface Props {
  announcementCategory: AnnouncementCategory;
  checked: boolean;
  onSelectAnnouncementCategory: (
    announcementCategory: AnnouncementCategory,
  ) => void;
}

export default class AnnouncementCategoryListItem extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (this.props.checked !== nextProps.checked) {
      return true;
    }
    return shouldUpdateEntity(
      {entity: this.props.announcementCategory},
      {entity: nextProps.announcementCategory},
    );
  }

  public render(): React.ReactNode {
    const {announcementCategory, checked} = this.props;
    return (
      <Pressable onPress={this.handlePress}>
        <View style={styles.container}>
          <View style={styles.containerInner}>
            <View style={styles.title}>
              <Text
                style={[
                  styles.titleText,
                  checked ? styles.titleTextChecked : undefined,
                ]}>
                {announcementCategory.name}
              </Text>
            </View>
          </View>
        </View>
      </Pressable>
    );
  }

  private handlePress = () => {
    const {announcementCategory, onSelectAnnouncementCategory} = this.props;
    onSelectAnnouncementCategory(announcementCategory);
  };
}

const styles = StyleSheet.create({
  container: {
    height: 42,
    justifyContent: 'center',
    paddingHorizontal: 16,
  } as ViewStyle,
  containerInner: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  title: {
    marginLeft: Platform.select({
      android: 22,
    }),
  } as ViewStyle,
  titleText: {
    color: colors.gray,
    fontSize: 14,
  } as TextStyle,
  titleTextChecked: {
    color: colors.orange,
    fontWeight: 'bold',
  } as TextStyle,
});
