import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import IllustrationMapper, {
  IllustrationData,
} from '../../entities/mappers/IllustrationMapper';

import Illustration from '../../../domain/entities/Illustration';
import IllustrationsRepository from '../../../domain/repositories/writer/IllustrationsRepository';

export default class NetIllustrationsRepository
  extends NetResourcesRepository<Illustration, IllustrationData>
  implements IllustrationsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/illustrations',
        new IllustrationMapper(),
      ),
    );
  }
}
