import SceneCommand from './SceneCommand';

import Sound from '../../entities/Sound';

import SoundEffectShowSceneCommandForm from '../../forms/scene_commands/SoundEffectShowSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class SoundEffectShowSceneCommand implements SceneCommand {
  constructor(
    public sound: Sound,
    public startTime?: number | null,
    public endTime?: number | null,
  ) {}

  public getValue() {
    return {
      sound_id: this.sound.id,
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }

  public toForm(): SceneCommandForm {
    return new SoundEffectShowSceneCommandForm(
      this.sound,
      this.startTime === undefined ? null : this.startTime,
      this.endTime === undefined ? null : this.endTime,
    );
  }
}
