import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Episode from '../../../../domain/entities/Episode';

interface Props {
  episode: Episode;
}

const FreeLabel: React.FunctionComponent<Props> = props => {
  const {episode} = props;
  if (!episode.free) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text style={styles.text}>無料</Text>
    </View>
  );
};

export default React.memo(FreeLabel);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#33a1dc',
    borderRadius: 3,
    paddingVertical: 5,
    paddingHorizontal: 6,
  } as ViewStyle,
  text: {
    color: '#fafafa',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
});
