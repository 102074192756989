import * as React from 'react';
import {LayoutChangeEvent, View} from 'react-native';

import shouldUpdateCharacterList from '../../shared/enhanced/shouldUpdateCharacterList';
import shouldUpdateEpisodeList from '../../shared/enhanced/shouldUpdateEpisodeList';
import ScrollableTabViewAdapter, {
  ChangedTabInfo,
} from '../../shared/scrollable_tab_view/ScrollableTabViewAdapter';

import CharacterListWithNewButton from '../../characters/partials/CharacterListWithNewButton';
import EpisodeListWithNewButton from '../../episodes/partials/EpisodeListWithNewButton';

import Character from '../../../../domain/entities/Character';
import Episode from '../../../../domain/entities/Episode';

interface Props {
  episodes: Episode[] | null;
  characters: Character[] | null;
  currentTabPage: number;
  scrollEnabled?: boolean;
  disableNewEpisode?: boolean;
  idToCopyLabel?: {[key: number]: string};
  onForwardToNewEpisode: () => void;
  onSelectEpisode: (episode: Episode) => void;
  onDeleteEpisode: (episode: Episode) => void;
  onCopyEpisode: (episode: Episode) => void;
  onForwardToNewCharacter: () => void;
  onSelectCharacter: (character: Character) => void;
  onDeleteCharacter: (character: Character) => void;
  onChangeTab?: (changedTabInfo: ChangedTabInfo) => void;
  onChangeOrderEpisode: (
    episode: Episode,
    rowOrderPosition: number,
    options?: {error?: () => void},
  ) => void;
  onChangeOrderCharacter: (
    character: Character,
    rowOrderPosition: number,
  ) => void;
  onStartSort?: () => void;
  onEndSort?: () => void;
}

interface State {
  heights: number[];
  sortingEnabled: boolean;
  mountedCharacters: boolean;
}

export default class StoryDetailTabs extends React.Component<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      heights: [0, 0],
      sortingEnabled: true,
      mountedCharacters: false,
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    if (shouldUpdateEpisodeList(this.props, nextProps)) {
      return true;
    }
    if (shouldUpdateCharacterList(this.props, nextProps)) {
      return true;
    }
    if (this.props.currentTabPage !== nextProps.currentTabPage) {
      return true;
    }
    if (this.props.scrollEnabled !== nextProps.scrollEnabled) {
      return true;
    }
    if (this.props.disableNewEpisode !== nextProps.disableNewEpisode) {
      return true;
    }
    if (
      Object.keys(this.props.idToCopyLabel || {}) !==
      Object.keys(nextProps.idToCopyLabel || {})
    ) {
      return true;
    }
    if (this.state.heights !== nextState.heights) {
      return true;
    }
    if (this.state.sortingEnabled !== nextState.sortingEnabled) {
      return true;
    }
    if (this.state.mountedCharacters !== nextState.mountedCharacters) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {currentTabPage, scrollEnabled} = this.props;
    const {heights} = this.state;
    return (
      <View style={{height: heights[currentTabPage] + 150}}>
        <ScrollableTabViewAdapter
          tabs={[
            {label: 'エピソード', element: this.generateEpisodes()},
            {label: 'キャラクター', element: this.generateCharacters()},
          ]}
          locked={!scrollEnabled}
          onChangeTab={this.handleChangeTab}
          onTransitionStartForWeb={this.handleTransitionStartForWeb}
          onTransitionEndForWeb={this.handleTransitionEndForWeb}
        />
      </View>
    );
  }

  private generateEpisodes = (): React.ReactNode => {
    const {
      episodes,
      scrollEnabled,
      idToCopyLabel,
      onSelectEpisode,
      onDeleteEpisode,
      onCopyEpisode,
      onForwardToNewEpisode,
      onChangeOrderEpisode,
      onStartSort,
      onEndSort,
    } = this.props;
    const {sortingEnabled} = this.state;
    return (
      <EpisodeListWithNewButton
        episodes={episodes}
        disableNewEpisode={true}
        scrollEnabled={scrollEnabled}
        sortingEnabled={sortingEnabled}
        idToCopyLabel={idToCopyLabel}
        onSelectEpisode={onSelectEpisode}
        onDeleteEpisode={onDeleteEpisode}
        onCopyEpisode={onCopyEpisode}
        onLayout={this.handleLayoutForEpisodes}
        onForwardToNewEpisode={onForwardToNewEpisode}
        onChangeOrder={onChangeOrderEpisode}
        onStartSort={onStartSort}
        onEndSort={onEndSort}
      />
    );
  };

  private generateCharacters = (): React.ReactNode => {
    const {
      characters,
      scrollEnabled,
      onForwardToNewCharacter,
      onSelectCharacter,
      onDeleteCharacter,
      onChangeOrderCharacter,
      onStartSort,
      onEndSort,
    } = this.props;
    const {mountedCharacters, sortingEnabled} = this.state;
    if (!mountedCharacters) {
      return null;
    }
    return (
      <CharacterListWithNewButton
        characters={characters}
        disableNewCharacter={true}
        scrollEnabled={scrollEnabled}
        sortingEnabled={sortingEnabled}
        onForwardToNewCharacter={onForwardToNewCharacter}
        onSelectCharacter={onSelectCharacter}
        onDeleteCharacter={onDeleteCharacter}
        onLayout={this.handleLayoutForCharacters}
        onChangeOrder={onChangeOrderCharacter}
        onStartSort={onStartSort}
        onEndSort={onEndSort}
      />
    );
  };

  private handleLayoutForEpisodes = (event: LayoutChangeEvent) => {
    const newHeights = [...this.state.heights];
    if (event.nativeEvent.layout.height <= 0) {
      return;
    }
    newHeights[0] = event.nativeEvent.layout.height;
    this.setStateIfMounted(
      {
        heights: newHeights,
      },
      () => {
        setTimeout(() => {
          if (!this.state.mountedCharacters) {
            this.setStateIfMounted({mountedCharacters: true});
          }
        }, 1000);
      },
    );
  };

  private handleLayoutForCharacters = (event: LayoutChangeEvent) => {
    const newHeights = [...this.state.heights];
    if (event.nativeEvent.layout.height <= 0) {
      return;
    }
    newHeights[1] = event.nativeEvent.layout.height;
    this.setStateIfMounted({
      heights: newHeights,
    });
  };

  private handleChangeTab = (changedTabInfo: ChangedTabInfo) => {
    const {onChangeTab} = this.props;
    const {mountedCharacters} = this.state;
    if (!mountedCharacters && changedTabInfo.i === 1) {
      this.setStateIfMounted({mountedCharacters: true});
    }
    if (onChangeTab) {
      onChangeTab(changedTabInfo);
    }
  };

  private handleTransitionStartForWeb = () => {
    this.setState({sortingEnabled: false});
  };

  private handleTransitionEndForWeb = () => {
    this.setState({sortingEnabled: true});
  };

  private setStateIfMounted<K extends keyof State>(
    state: Pick<State, K> | null,
    callback?: () => void,
  ): void {
    if (!this.mounted) {
      return;
    }
    this.setState(state, callback);
  }
}
