import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props<Value> {
  value: Value;
  label: string;
  isActive: boolean;
  onChangeTab: (value: Value) => void;
}

export default class Tab<Value> extends React.PureComponent<Props<Value>> {
  public render(): React.ReactNode {
    const {label, isActive} = this.props;
    return (
      <Pressable onPress={this.handlePress}>
        <View style={isActive ? styles.containerActive : styles.container}>
          <Text style={isActive ? styles.labelActive : styles.label}>
            {label}
          </Text>
        </View>
      </Pressable>
    );
  }

  private handlePress = () => {
    const {value, onChangeTab} = this.props;
    onChangeTab(value);
  };
}

const container = {
  minWidth: 75,
  height: 24,
  borderRadius: 12,
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  marginHorizontal: 2,
} as ViewStyle;

const label = {
  fontSize: 13,
  fontWeight: 'bold',
  color: '#666666',
} as TextStyle;

const styles = StyleSheet.create({
  container,
  containerActive: {
    ...container,
    backgroundColor: '#efefef',
  } as ViewStyle,
  label,
  labelActive: {
    ...label,
    color: '#383838',
  } as TextStyle,
});
