import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics';

import UserNotification from '../../../../domain/entities/writer/UserNotification';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetUserNotificationsRepository from '../../../../data/repositories/writer/NetUserNotificationsRepository';

const types = {
  failure: Types.IndexWriterUserNotificationsFailure,
  request: Types.IndexWriterUserNotificationsRequest,
  success: Types.IndexWriterUserNotificationsSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<UserNotification>> {
  return indexGenerics(new NetUserNotificationsRepository(), types, params);
}
