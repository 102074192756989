import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Actor from '../../../domain/entities/Actor';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetActorsRepository from '../../../data/repositories/writer/NetActorsRepository';

const types = {
  failure: Types.IndexActorsFailure,
  request: Types.IndexActorsRequest,
  success: Types.IndexActorsSuccess,
};

export interface Params extends BaseParams {
  allActorCategoryId?: number;
  actorCategoryId?: number;
  query?: string;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
  purchase?: boolean;
  paid?: boolean;
  withPaid?: boolean;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Actor>> {
  return indexGenerics(new NetActorsRepository(), types, params);
}
