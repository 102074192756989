import {OauthAccountData} from '../../../data/entities/mappers/OauthAccountMapper';
import TapNovelRestApi from '../../../data/data_stores/net/TapNovelRestApi';

import {isSafari} from '../../../data/data_stores/net/UserAgent';

let currentOauthAccount: OauthAccountData | null = null;

export default class TikTokOauth {
  public authorize = async (callback: () => void) => {
    try {
      const oauthAccount = isSafari
        ? currentOauthAccount
        : await this.getOauthAccount();

      if (!oauthAccount) {
        this.openTikTok(callback);
      } else {
        callback();
      }
    } catch (e) {
      this.openTikTok(callback);
    }
  };

  public getOauthAccount = async () => {
    currentOauthAccount = (
      await TapNovelRestApi.get<OauthAccountData>(
        '/api/writer/social_accounts/tiktok_accounts/oauth_account',
      )
    ).body;
    return currentOauthAccount;
  };

  private openTikTok = (callback: () => void) => {
    const listener = async (event: MessageEvent) => {
      if (event.data && event.data.type !== 'tiktok_oauth') {
        return;
      }
      TapNovelRestApi.post(
        '/api/writer/social_accounts/tiktok_accounts/oauth_account',
        {code: event.data.params.code},
      ).then(callback);
      window.removeEventListener('message', listener);
    };
    window.addEventListener('message', listener, false);
    window.open(
      `${
        process.env.REACT_APP_TAPNOVEL_API_ENDPOINT
      }/api/writer/social_accounts/tiktok_accounts/oauth_account/new?redirect_uri=${this.getRedirectUri()}`,
      undefined,
      `width=500,height=700,toolbar=no,menubar=no,left=${
        window.innerWidth / 2 - 250
      },top=${window.innerHeight / 2 - 300}`,
    );
  };

  private getRedirectUri = () => {
    return process.env.REACT_APP_TIKTOK_WRITER_AUTH_REDIRECT_URI as string;
  };
}
