import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ActorHairStyleMapper, {
  ActorHairStyleData,
} from '../../entities/mappers/ActorHairStyleMapper';

import ActorHairStyle from '../../../domain/entities/ActorHairStyle';
import ActorHairStylesRepository from '../../../domain/repositories/writer/ActorHairStylesRepository';

export default class NetActorHairStylesRepository
  extends NetResourcesRepository<ActorHairStyle, ActorHairStyleData>
  implements ActorHairStylesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/actor_hair_styles',
        new ActorHairStyleMapper(),
      ),
    );
  }
}
