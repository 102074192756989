import * as React from 'react';
import {
  ImageStyle,
  ImageURISource,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ImageIcon from '../icons/ImageIcon';

import File from '../../../../domain/entities/File';

import ImagePickerAdapter from './ImagePickerAdapter';

interface Props {
  style?: StyleProp<ImageStyle>;
  source: ImageURISource | ImageURISource[] | null;
  noImageText?: string;
  recommendedSize?: string;
  onChangeImage?: (file: File) => void;
  onFailImage?: (message: string) => void;
  children?: React.ReactNode;
}

const CoverPicker: React.FunctionComponent<Props> = props => {
  const {
    style,
    source,
    noImageText,
    recommendedSize,
    onChangeImage,
    onFailImage,
  } = props;
  return (
    <ImagePickerAdapter
      imageSource={source}
      imageStyle={[styles.image, style]}
      imageContainerStyle={styles.imageContainer}
      onChangeImage={onChangeImage}
      onFailImage={onFailImage}
      visibleImageIcon={true}
      imageIconStyle={{right: -7, bottom: 7}}
      noImage={
        <View style={styles.noImage}>
          <Text style={styles.noImageText}>{noImageText || '表紙なし'}</Text>
          <ImageIcon size={35} />
          <Text style={styles.uploadText}>画像をアップロード</Text>
          {recommendedSize && (
            <Text style={styles.recommendedSize}>
              （推奨サイズ：{recommendedSize} px）
            </Text>
          )}
        </View>
      }
    />
  );
};

export default React.memo(CoverPicker);

const styles = StyleSheet.create({
  image: {
    backgroundColor: '#efefef',
  } as ImageStyle,
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  noImage: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  noImageText: {
    color: '#999999',
    fontSize: 17,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
  uploadText: {
    color: '#383838',
    fontSize: 17,
    fontWeight: 'bold',
    marginTop: 16,
  } as TextStyle,
  recommendedSize: {
    marginTop: 8,
    color: '#666',
    fontSize: 13,
  } as TextStyle,
});
