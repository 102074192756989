import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import Sound from '../../entities/Sound';

const CLASS_NAME: SceneCommandFormClass = 'SoundEffectShowSceneCommandForm';

export default class SoundEffectShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(
    public sound: Sound,
    public startTime: number | null,
    public endTime: number | null,
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      sound_id: this.sound.id,
      start_time: this.startTime,
      end_time: this.endTime,
      type: 'SceneCommands::SoundEffectShowSceneCommand',
    };
  }

  public copy(sceneCommandId?: number): SoundEffectShowSceneCommandForm {
    return new SoundEffectShowSceneCommandForm(
      this.sound,
      this.startTime,
      this.endTime,
      sceneCommandId,
    );
  }
}
