import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import BaseEffect from '../../../domain/entities/BaseEffect';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetBaseEffectsRepository from '../../../data/repositories/writer/NetBaseEffectsRepository';

const types = {
  failure: Types.IndexBaseEffectsFailure,
  request: Types.IndexBaseEffectsRequest,
  success: Types.IndexBaseEffectsSuccess,
};

export interface Params extends BaseParams {
  allEffectCategoryId?: number | null;
  effectCategoryId?: number | null;
  query?: string;
  sort?: 'popularity' | 'new_arrival' | 'id_asc' | 'category_order';
  exceptBackground?: boolean | null;
  favorite?: boolean;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<BaseEffect>> {
  return indexGenerics(new NetBaseEffectsRepository(), types, params);
}
