import React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import DocumentPickerAdapter from '../../../shared/document_picker/DocumentPickerAdapter';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import PlusCircleSolidIcon from '../../../shared/icons/PlusCircleSolidIcon';

export type Inputs = {
  fullname?: string;
  image?: File;
  originalImageFileName?: string;
};

export type Errors = {
  fullname?: string[];
  image?: string[];
};

interface Props {
  defaultValues?: Inputs;
  errors?: Errors;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {defaultValues, errors, onSubmit} = props;
  const [fullname, setFullname] = React.useState(defaultValues?.fullname || '');
  const [originalImageFileName, setOriginalImageFileName] = React.useState(
    defaultValues?.originalImageFileName || '',
  );
  const [image, setImage] = React.useState<File | undefined>(undefined);
  const onPress = React.useCallback(() => {
    onSubmit({
      fullname,
      image,
    });
  }, [fullname, image]);
  const handleChangeFile = React.useCallback((file: File) => {
    setImage(file);
  }, []);
  const resetImage = React.useCallback(() => {
    setImage(undefined);
    setOriginalImageFileName('');
  }, []);
  const disabled = fullname.length === 0 || (!image && !originalImageFileName);
  return (
    <View style={styles.container}>
      <View style={[styles.field, {height: 48}]}>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>お名前（フルネーム）</Text>
          {errors?.fullname ? (
            <Text style={styles.error}>{errors.fullname}</Text>
          ) : null}
        </View>
        <TextInput
          style={styles.input}
          placeholder={'名前を入力'}
          placeholderTextColor={'#999'}
          value={fullname}
          onChangeText={setFullname}
        />
      </View>
      <View style={styles.field}>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>身分証明書の写し</Text>
          {errors?.image ? (
            <Text style={styles.error}>{errors.image}</Text>
          ) : null}
        </View>
        <Text style={styles.message}>
          ※運転免許証、保険証、マイナンバーカード、パスポートのいずれか
        </Text>
        <View style={styles.pickerWrapper}>
          <DocumentPickerAdapter
            key={image?.name}
            type={'image'}
            onChangeFile={handleChangeFile}
            onFailFile={error => {
              console.log(error);
            }}>
            <View style={[styles.button, {width: 240, height: 46}]}>
              <PlusCircleSolidIcon size={16} color={'#383838'} />
              <Text style={[styles.buttonText, {fontSize: 14}]}>
                ファイル添付
              </Text>
            </View>
          </DocumentPickerAdapter>
        </View>
        {(image || originalImageFileName) && (
          <View
            style={[
              styles.pickerWrapper,
              {
                marginTop: 8,
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingHorizontal: 16,
              },
            ]}>
            <Text
              ellipsizeMode={'head'}
              numberOfLines={1}
              style={{
                width: 250,
                color: '#666',
                fontSize: 13,
                fontWeight: 'bold',
                marginRight: 16,
              }}>
              {image?.name || originalImageFileName}
            </Text>
            <TouchableOpacity onPress={resetImage}>
              <View style={[styles.button, {width: 75, height: 24}]}>
                <Text style={[styles.buttonText, {fontSize: 12}]}>
                  リセット
                </Text>
              </View>
            </TouchableOpacity>
          </View>
        )}
      </View>
      <View style={styles.submit}>
        <PrimaryButton disabled={disabled} onPress={onPress}>
          登録
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form) as typeof Form;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: 26,
    paddingBottom: 67,
    paddingHorizontal: 16,
  } as ViewStyle,
  field: {
    marginVertical: 16,
    justifyContent: 'space-between',
  } as ViewStyle,
  labelWrapper: {
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  input: {
    height: 30,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    fontSize: 14,
  } as TextStyle,
  error: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: 10,
    paddingHorizontal: 8,
  } as TextStyle,
  message: {
    color: '#383838',
    fontSize: 10,
    marginVertical: 8,
  } as TextStyle,
  pickerWrapper: {
    height: 78,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  button: {
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#383838',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#fff',
  } as ViewStyle,
  buttonText: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 3,
  } as TextStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
});
