import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import Transfer from '../../../containers/transfer/Index';
import TransferRequestNew from '../../../containers/transfer/requests/New';
import TransferRequestComplete from '../../../containers/transfer/requests/Complete';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="Transfer">
      <RootStack.Screen
        name="Transfer"
        component={Transfer}
        options={{
          title: '振込申請',
        }}
      />
      <RootStack.Screen
        name="TransferRequestNew"
        component={TransferRequestNew}
        options={{
          title: '振込申請',
        }}
      />
      <RootStack.Screen
        name="TransferRequestComplete"
        component={TransferRequestComplete}
        options={{
          title: '振込申請',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
