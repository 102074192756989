import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import LinkButton from '../../shared/buttons/LinkButton';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import DefaultModal from '../../shared/modals/DefaultModal';

import Episode from '../../../../domain/entities/Episode';

interface Props {
  episode: Episode;
  onRequestDeleteEpisode: (episode: Episode) => void;
  onRequestCloseModal: () => void;
}

const EpisodeDeleteConfirmModal: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const {episode, onRequestDeleteEpisode, onRequestCloseModal} = props;
  const handlePress = React.useCallback(() => {
    onRequestDeleteEpisode(episode);
  }, []);

  return (
    <DefaultModal
      visible={true}
      onCloseModal={onRequestCloseModal}
      footer={
        <View style={styles.buttons}>
          <LinkButton style={styles.button} onPress={onRequestCloseModal}>
            キャンセル
          </LinkButton>
          <PrimaryButton style={styles.button} onPress={handlePress}>
            削除
          </PrimaryButton>
        </View>
      }>
      <View style={styles.body}>
        <Text style={[styles.text, styles.title]}>
          {episode.title}を削除する
        </Text>
        <Text style={styles.text}>
          一度削除したエピソードは復元できません。{'\n'}
          作成したエピソードは削除されますが{'\n'}
          よろしいですか？
        </Text>
      </View>
    </DefaultModal>
  );
};

export default EpisodeDeleteConfirmModal;

const styles = StyleSheet.create({
  body: {
    marginBottom: 20,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    marginVertical: 15,
  } as ViewStyle,
  button: {
    marginHorizontal: 3,
    width: 145,
    height: 46,
  } as ViewStyle,
  title: {
    fontWeight: 'bold',
    marginTop: -16,
    marginBottom: 16,
  } as TextStyle,
  text: {
    color: '#222',
    fontSize: 14,
    lineHeight: 20,
    textAlign: 'center',
  } as TextStyle,
});
