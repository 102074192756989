import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import EpisodeVideoExportJobExecutionListWithNewButton from './EpisodeVideoExportJobExecutionListWithNewButton';

import EpisodeVideoExportJobExecution from '../../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

import NetEpisodeVideoExportJobExecutionsRepository from '../../../../../data/repositories/writer/NetEpisodeVideoExportJobExecutionsRepository';

interface Props {
  videoType: 'vertical_full' | 'horizontal_full';
  onSelectEpisodeVideoExportJobExecution: (
    episodeVideoExportJobExecution: EpisodeVideoExportJobExecution,
  ) => void;
  onForwardToNewEpisodeVideoExportJobExecution: () => void;
}

const FetchingEpisodeVideoExportJobExecutionListWithNewButton: React.FunctionComponent<
  Props
> = props => {
  const {
    videoType,
    onSelectEpisodeVideoExportJobExecution,
    onForwardToNewEpisodeVideoExportJobExecution,
  } = props;
  const [episodeVideoExportJobExecutions, setEpisodeVideoExportJobExecutions] =
    React.useState<Array<EpisodeVideoExportJobExecution> | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      new NetEpisodeVideoExportJobExecutionsRepository()
        .findBy({videoType})
        .then(result => {
          setEpisodeVideoExportJobExecutions(result.records);
        });
    }, []),
  );
  const onDeleteEpisodeVideoExportJobExecution = React.useCallback(
    (episodeVideoExportJobExecution: EpisodeVideoExportJobExecution) => {
      new NetEpisodeVideoExportJobExecutionsRepository()
        .destroy(episodeVideoExportJobExecution.id)
        .then(() => {
          new NetEpisodeVideoExportJobExecutionsRepository()
            .findBy({videoType})
            .then(result => {
              setEpisodeVideoExportJobExecutions(result.records);
            });
        });
    },
    [],
  );
  if (!episodeVideoExportJobExecutions) {
    return null;
  }
  return (
    <EpisodeVideoExportJobExecutionListWithNewButton
      episodeVideoExportJobExecutions={episodeVideoExportJobExecutions}
      onSelectEpisodeVideoExportJobExecution={
        onSelectEpisodeVideoExportJobExecution
      }
      onDeleteEpisodeVideoExportJobExecution={
        onDeleteEpisodeVideoExportJobExecution
      }
      onForwardToNewEpisodeVideoExportJobExecution={
        onForwardToNewEpisodeVideoExportJobExecution
      }
    />
  );
};

export default React.memo(
  FetchingEpisodeVideoExportJobExecutionListWithNewButton,
);
