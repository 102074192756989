import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import PaymentMethodListItem from './PaymentMethodListItem';
import NoPaymentMethods from './NoPaymentMethods';

import PaymentMethod from '../../../../../../domain/entities/writer/my_stripe/PaymentMethod';

interface Props {
  paymentMethods: Array<PaymentMethod> | null;
  onSelectPaymentMethod: (paymentMethod: PaymentMethod) => void;
  onDeletePaymentMethod: (paymentMethod: PaymentMethod) => void;
}

const PaymentMethodList: React.FunctionComponent<Props> = props => {
  const {paymentMethods, onSelectPaymentMethod, onDeletePaymentMethod} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<PaymentMethod>) => {
      return (
        <PaymentMethodListItem
          paymentMethod={info.item}
          onSelectPaymentMethod={onSelectPaymentMethod}
          onDeletePaymentMethod={onDeletePaymentMethod}
        />
      );
    },
    [],
  );
  return (
    <FlatList
      contentContainerStyle={contentContainerStyle}
      data={paymentMethods}
      renderItem={renderItem}
      ListEmptyComponent={NoPaymentMethods}
    />
  );
};

export default PaymentMethodList;

const contentContainerStyle = {flex: 1};
