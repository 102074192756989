import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {useActionSheet} from '@expo/react-native-action-sheet';
import dateformat from 'dateformat';
import {formatElapsedTime} from '../../../../../helpers/times';

import MoreIcon from '../../../../shared/icons/ionicons/MoreIcon';

import ProjectEpisode from '../../../../../../domain/entities/ProjectEpisode';

import TapNovelTypedRestApi from '../../../../../../data/data_stores/net/TapNovelTypedRestApi';

type Status =
  | 'draft'
  | 'submitting'
  | 'waiting_for_release'
  | 'released'
  | 'suspended';

interface Props {
  episode: ProjectEpisode;
  onForceUpdate: () => void;
}

const EpisodeListItem: React.FC<Props> = props => {
  const {episode, onForceUpdate} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(`/projects/${episode.projectId}/user/episodes/${episode.id}`);
  }, []);
  const {showActionSheetWithOptions} = useActionSheet();
  const episodeId = episode.latestProjectManuscript?.episodeId;
  const onOpenActionSheet = React.useCallback(() => {
    const active = episode.working;
    if (active) {
      const options = ['執筆を中止する'];
      if (episodeId) {
        options.push('エクスポートする');
      }
      options.push('キャンセル');
      const stopWritingIndex = 0;
      const exportEpisodeIndex = episodeId ? 1 : undefined;
      const cancelButtonIndex = options.length - 1;
      showActionSheetWithOptions(
        {
          cancelButtonIndex,
          options,
          destructiveButtonIndex: stopWritingIndex,
        },
        async (buttonIndex?: number) => {
          switch (buttonIndex) {
            case stopWritingIndex:
              TapNovelTypedRestApi.patch(
                `/api/writer/user/project_episodes/${episode.id}`,
                {projectEpisode: {status: 'suspended'}},
              ).then(onForceUpdate);
              break;
            case exportEpisodeIndex:
              if (episodeId) {
                linkTo(`/export/episodes/${episodeId}`);
              }
              break;
            case cancelButtonIndex:
              break;
          }
        },
      );
    } else {
      const options = ['執筆を再開する'];
      if (episodeId) {
        options.push('エクスポートする');
      }
      options.push('キャンセル');
      const restartWritingIndex = 0;
      const exportEpisodeIndex = episodeId ? 1 : undefined;
      const cancelButtonIndex = options.length - 1;
      showActionSheetWithOptions(
        {
          cancelButtonIndex,
          options,
        },
        async (buttonIndex?: number) => {
          switch (buttonIndex) {
            case restartWritingIndex:
              TapNovelTypedRestApi.patch(
                `/api/writer/user/project_episodes/${episode.id}`,
                {projectEpisode: {status: 'draft'}},
              ).then(onForceUpdate);
              break;
            case exportEpisodeIndex:
              if (episodeId) {
                linkTo(`/export/episodes/${episodeId}`);
              }
              break;
            case cancelButtonIndex:
              break;
          }
        },
      );
    }
  }, []);
  const {labelStyle, labelTextStyle, labelText} = statusToStyle[episode.status];
  return (
    <>
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <Text style={styles.title}>{episode.title}</Text>
        <View style={styles.phaseAndUpdatedAt}>
          <Text>
            {episode.latestProjectManuscript.typeName}（
            {episode.latestProjectManuscript.manuscriptNumber === 1
              ? '初'
              : episode.latestProjectManuscript.manuscriptNumber}
            稿）
          </Text>
          <Text>
            {dateformat(
              episode.latestProjectManuscript.updatedAt,
              'yyyy.mm.dd',
            )}{' '}
            更新
          </Text>
        </View>
        <View style={styles.labelAndElapsedDate}>
          <View style={[styles.label, labelStyle]}>
            <Text style={[styles.labelText, labelTextStyle]}>{labelText}</Text>
          </View>
          {episode.latestProjectManuscript.submittedAt && (
            <Text style={styles.elapsedDate}>
              {formatElapsedTime(
                new Date(episode.latestProjectManuscript.submittedAt),
                '経過',
              )}{' '}
            </Text>
          )}
        </View>
      </TouchableOpacity>
      <TouchableOpacity style={styles.more} onPress={onOpenActionSheet}>
        <MoreIcon />
      </TouchableOpacity>
    </>
  );
};

export default React.memo(EpisodeListItem);

const statusToStyle: {
  [key in Status]: {
    labelStyle: ViewStyle;
    labelTextStyle: TextStyle;
    labelText: string;
  };
} = {
  draft: {
    labelStyle: {
      borderColor: '#2c86d7',
      borderWidth: 1,
    },
    labelTextStyle: {
      color: '#2c86d7',
    },
    labelText: '執筆中',
  },
  submitting: {
    labelStyle: {
      backgroundColor: '#2c86d7',
    },
    labelTextStyle: {
      color: '#fff',
    },
    labelText: '申請中',
  },
  waiting_for_release: {
    labelStyle: {
      borderColor: '#ff8f13',
      borderWidth: 1,
    },
    labelTextStyle: {
      color: '#ff8f13',
    },
    labelText: '公開待ち',
  },
  released: {
    labelStyle: {
      backgroundColor: '#ff8f13',
    },
    labelTextStyle: {
      color: '#fff',
    },
    labelText: '公開中',
  },
  suspended: {
    labelStyle: {
      backgroundColor: '#999',
    },
    labelTextStyle: {
      color: '#fff',
    },
    labelText: '執筆中止',
  },
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 16,
    paddingHorizontal: 10,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: 75,
  } as TextStyle,
  phaseAndUpdatedAt: {
    flexDirection: 'row',
    marginVertical: 10,
  } as ViewStyle,
  phase: {
    color: '#222',
    fontSize: 10,
    marginRight: 10,
  } as TextStyle,
  updatedAt: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  labelAndElapsedDate: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  label: {
    paddingVertical: 2,
    paddingHorizontal: 6,
    marginRight: 10,
  } as ViewStyle,
  labelText: {
    fontSize: 8,
    fontWeight: 'bold',
  } as TextStyle,
  elapsedDate: {
    color: '#222',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  more: {
    alignItems: 'center',
    height: 44,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 44,
  } as ViewStyle,
});
