import AccessTokenRepository from '../../repositories/AccessTokenRepository';

import CurrentUser from '../../entities/writer/CurrentUser';

import CurrentUserRepository from '../../repositories/writer/CurrentUserRepository';
import RegistrationRepository from '../../repositories/writer/users/RegistrationRepository';

export default class UserAuthentication {
  private accessToken: string | null = null;

  constructor(
    private accessTokenRepository: AccessTokenRepository,
    private registrationRepository: RegistrationRepository,
    private userRepository: CurrentUserRepository,
  ) {}

  public isAuthenticated(): boolean {
    return this.accessToken !== null;
  }

  public authenticate(params: {[key: string]: any} = {}): Promise<CurrentUser> {
    if (this.accessToken) {
      return new Promise(() => {
        return this.accessToken;
      });
    }
    return this.accessTokenRepository.find().then(accessToken => {
      if (accessToken === null || accessToken === '') {
        return this.registrationRepository.create(params).then(currentUser => {
          if (!currentUser.accessToken) {
            throw new Error('Authrication Error!');
          }
          this.accessToken = currentUser.accessToken;
          this.accessTokenRepository.update(this.accessToken);
          return currentUser;
        });
      } else {
        this.accessToken = accessToken;
        return this.userRepository.find().then(currentUser => {
          return currentUser;
        });
      }
    });
  }
}
