import * as React from 'react';
import {
  ImageStyle,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import TextArea from '../../../shared/forms/TextArea';
import MultiSwitch from '../../../shared/forms/MultiSwitch';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import TooltipModal from '../../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../../shared/KeyboardSpacer';
import CheckButton from '../../../shared/forms/CheckButton';

import PlusIcon from '../../../shared/icons/project/PlusIcon';
import MinusIcon from '../../../shared/icons/project/MinusIcon';

import {colors, formStyles} from '../../../../styles/variables';

import {
  EpisodeCopyright,
  ParticipationMethod,
} from '../../../../../domain/entities/Project';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

export type Form3Values = {
  participationMethod: ParticipationMethod;
  idealCandidateProfile: string;
  admissionCapacityRequired: boolean;
  admissionCapacity: number | null;
  recruiting: boolean;
};

type Form3ErrorMessage = {[P in keyof Form3Values]?: string | string[]};

interface Props {
  values?: Form3Values;
  errorMessages?: Form3ErrorMessage;
  episodeCopyright?: EpisodeCopyright;
  isNew: boolean;
  onSubmit: (values: Form3Values) => void;
}

const FormStep1: React.FC<Props> = props => {
  const {values, errorMessages, episodeCopyright, isNew, onSubmit} = props;
  const [participationMethod, setParticipationMethod] =
    React.useState<ParticipationMethod>(
      values?.participationMethod || 'free_participation',
    );
  const [idealCandidateProfile, setIdealCandidateProfile] =
    React.useState<string>(values?.idealCandidateProfile || '');
  const [admissionCapacityRequired, setAdmissionCapacityRequired] =
    React.useState<boolean>(
      values?.admissionCapacityRequired === undefined
        ? false
        : values?.admissionCapacityRequired,
    );
  const [admissionCapacity, setAdmissionCapacity] = React.useState<
    number | null
  >(admissionCapacityRequired ? values?.admissionCapacity || 1 : null);
  const [recruiting, setRecruiting] = React.useState<boolean>(
    values?.recruiting === undefined ? true : values?.recruiting,
  );
  const onChangeParticipationMethod = React.useCallback(
    (item: {label: string; value: string}) => {
      setParticipationMethod(item.value as ParticipationMethod);
    },
    [],
  );
  const onChangeAdmissionCapacityRequired = React.useCallback(
    (item: {label: string; value: string}) => {
      setAdmissionCapacityRequired(item.value === 'true');
    },
    [],
  );
  const onChangeRecruiting = React.useCallback(
    (item: {label: string; value: string}) => {
      setRecruiting(item.value === 'true');
    },
    [],
  );
  const [checked1, setChecked1] = React.useState<boolean>(false);
  const [checked2, setChecked2] = React.useState<boolean>(false);
  const [checked3, setChecked3] = React.useState<boolean>(false);
  const [checked4, setChecked4] = React.useState<boolean>(false);
  const [tooltipModalType, setTooltipModalType] = React.useState<
    keyof Form3Values | null
  >(null);
  const onCloseModalTooltip = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onPressQuestionWayToParticipate = React.useCallback(() => {
    setTooltipModalType('participationMethod');
  }, []);
  const onPressQuestionDesiredPersonImage = React.useCallback(() => {
    setTooltipModalType('idealCandidateProfile');
  }, []);
  const onPressQuestionAdmissionCapacityRequired = React.useCallback(() => {
    setTooltipModalType('admissionCapacityRequired');
  }, []);
  const onPressAdmissionCapacityMinus = React.useCallback(() => {
    if (!admissionCapacityRequired || admissionCapacity === null) {
      return;
    }
    const newAdmissionCapacity = admissionCapacity - 1;
    if (newAdmissionCapacity > 0) {
      setAdmissionCapacity(newAdmissionCapacity);
    }
  }, [admissionCapacity, admissionCapacityRequired]);
  const onPressAdmissionCapacityPlus = React.useCallback(() => {
    if (!admissionCapacityRequired || admissionCapacity === null) {
      return;
    }
    const newAdmissionCapacity = admissionCapacity + 1;
    if (newAdmissionCapacity > 0) {
      setAdmissionCapacity(newAdmissionCapacity);
    }
  }, [admissionCapacity, admissionCapacityRequired]);
  React.useEffect(() => {
    if (admissionCapacityRequired) {
      setAdmissionCapacity(values?.admissionCapacity || 1);
    } else {
      setAdmissionCapacity(null);
    }
  }, [admissionCapacityRequired]);
  const onPress = () => {
    onSubmit({
      participationMethod,
      idealCandidateProfile,
      admissionCapacityRequired,
      admissionCapacity,
      recruiting: recruiting,
    });
  };
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  const disabled = isNew
    ? idealCandidateProfile.length <= 0 ||
      idealCandidateProfile.length > 200 ||
      (episodeCopyright === 'author_owned' ? !checked1 : false) ||
      !checked2 ||
      !checked3 ||
      !checked4
    : idealCandidateProfile.length <= 0 || idealCandidateProfile.length > 200;
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{paddingBottom: 130}}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'参加方法'}
            onPressQuestion={onPressQuestionWayToParticipate}
          />
          <MultiSwitch
            items={PARTICIPATION_METHOD_ITEMS}
            value={participationMethod}
            onSelectItem={onChangeParticipationMethod}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'求めるタップライター(最大200文字)'}
            requiredOrOptional={'required'}
            length={idealCandidateProfile.length}
            maxLength={200}
            entered={idealCandidateProfile.length > 0}
            errorMessages={errorMessages?.idealCandidateProfile}
            onPressQuestion={onPressQuestionDesiredPersonImage}
          />
          <TextArea
            style={[
              styles.textArea,
              idealCandidateProfile.length <= 200
                ? undefined
                : styles.inputError,
              idealCandidateProfile ? undefined : requiredInputStyle,
            ]}
            containerStyle={
              idealCandidateProfile ? undefined : requiredInputStyle
            }
            value={idealCandidateProfile}
            placeholder={'求めるタップライターを入力'}
            onChangeText={setIdealCandidateProfile}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'募集定員'}
            onPressQuestion={onPressQuestionAdmissionCapacityRequired}
          />
          <MultiSwitch
            items={ADMISSION_CAPACITY_REQUIRED_ITEMS}
            value={`${admissionCapacityRequired}`}
            onSelectItem={onChangeAdmissionCapacityRequired}
          />
        </View>
        {admissionCapacityRequired ? (
          <View style={formStyles.formGroup}>
            <LabelWithOption title={'定員人数'} />
            <View style={styles.admissionCapacity}>
              <View style={styles.admissionCapacityLeft}>
                <Text style={styles.admissionCapacityLeftValue}>
                  {numeral(admissionCapacity).format('0,0')}
                </Text>
                <Text style={styles.admissionCapacityLeftUnit}>人</Text>
              </View>
              <View style={styles.admissionCapacityRight}>
                <TouchableOpacity
                  style={styles.admissionCapacityRightMinusButton}
                  onPress={onPressAdmissionCapacityMinus}>
                  <MinusIcon />
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.admissionCapacityRightPlusButton}
                  onPress={onPressAdmissionCapacityPlus}>
                  <PlusIcon />
                </TouchableOpacity>
              </View>
            </View>
          </View>
        ) : null}
        <View style={formStyles.formGroup}>
          <LabelWithOption title={'募集設定'} />
          <MultiSwitch
            items={ADMISSION_STATUS_ITEMS}
            value={`${recruiting}`}
            onSelectItem={onChangeRecruiting}
          />
        </View>
        {isNew ? (
          <View style={{marginTop: 16}}>
            {episodeCopyright === 'author_owned' ? (
              <CheckButton
                style={styles.checkBox}
                checked={checked1}
                onPress={() => setChecked1(!checked1)}>
                エピソードの権利はエピソードを投稿したメンバーが保有します
              </CheckButton>
            ) : null}
            <CheckButton
              style={styles.checkBox}
              checked={checked2}
              onPress={() => setChecked2(!checked2)}>
              条件の違う同プロジェクトではございません
            </CheckButton>
            <CheckButton
              style={styles.checkBox}
              checked={checked3}
              onPress={() => setChecked3(!checked3)}>
              本企画は二次創作ではございません
            </CheckButton>
            <CheckButton
              style={styles.checkBox}
              checked={checked4}
              onPress={() => setChecked4(!checked4)}>
              営利利用の際の窓口はTapNovelが行います
            </CheckButton>
          </View>
        ) : null}
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            imageStyle={modalInfo.imageStyle}
            source={modalInfo.source}
            onCloseModal={onCloseModalTooltip}></TooltipModal>
        ) : null}
        <KeyboardSpacer />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <View style={styles.button}>
          <PrimaryButton disabled={disabled} onPress={onPress}>
            {isNew ? 'この条件でプロジェクトを立ち上げる' : '保存'}
          </PrimaryButton>
        </View>
      </View>
    </View>
  );
};

export default React.memo(FormStep1);

const PARTICIPATION_METHOD_ITEMS: Array<{
  label: string;
  value: ParticipationMethod;
}> = [
  {
    label: '自由参加',
    value: 'free_participation',
  },
  {
    label: '承認制',
    value: 'approval_system',
  },
];

const ADMISSION_CAPACITY_REQUIRED_ITEMS: Array<{
  label: string;
  value: 'true' | 'false';
}> = [
  {
    label: '無し',
    value: 'false',
  },
  {
    label: '有り',
    value: 'true',
  },
];

const ADMISSION_STATUS_ITEMS: Array<{
  label: string;
  value: 'true' | 'false';
}> = [
  {
    label: '募集開始',
    value: 'true',
  },
  {
    label: '募集停止',
    value: 'false',
  },
];

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
  modalText: {
    marginVertical: 4,
  } as TextStyle,
  admissionCapacity: {
    flexDirection: 'row',
  } as ViewStyle,
  admissionCapacityLeft: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#efefef',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 8,
  } as ViewStyle,
  admissionCapacityLeftUnit: {
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
  admissionCapacityLeftValue: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  admissionCapacityRight: {
    flexDirection: 'row',
    width: 65,
    height: 32,
  } as ViewStyle,
  admissionCapacityRightMinusButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#383838',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    borderRightWidth: 0.5,
    borderRightColor: '#383838',
  } as ViewStyle,
  admissionCapacityRightPlusButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#383838',
    borderTopEndRadius: 3,
    borderBottomEndRadius: 3,
    borderLeftWidth: 0.5,
    borderLeftColor: '#383838',
  } as ViewStyle,
  checkBox: {
    marginVertical: 8,
    marginHorizontal: 32,
  } as ViewStyle,
});

const TypeToTooltipInfo = {
  participationMethod: {
    title: '参加方法',
    description:
      '自由参加は条件に該当すれば誰でも参加できます。\n承認制は参加リクエスト後、リーダーが承認した\n場合にのみ参加できます。',
  },
  idealCandidateProfile: {
    title: '求めるタップライター',
    description:
      'プロジェクトに求めるタップライターの志向や適性を記入しましょう。\n（タップライターのみが閲覧できる情報です）',
  },
  admissionCapacityRequired: {
    title: '募集定員',
    description:
      'プロジェクトの参加メンバーの最大人数を設定できます。\nメンバーが定員に達した場合は、募集が打ち切られます。',
  },
} as {
  [key in keyof Form3Values]: {
    title: string;
    description: React.ReactNode | string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};
