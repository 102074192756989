import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import DefaultOutlineButton from '../../../../../shared/buttons/DefaultOutlineButton';

interface Props {
  value: boolean;
  onPress: () => void;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {value, onPress} = props;
  return (
    <View style={styles.container}>
      <DefaultOutlineButton
        buttonSize={BUTTON_SIZE}
        fontSizeClass={'small'}
        onPress={onPress}>
        登場キャラ{value ? '' : '以外'}を選択
      </DefaultOutlineButton>
    </View>
  );
};

export default CharacterView;

const BUTTON_SIZE = {width: 152, height: 32};

const styles = StyleSheet.create({
  container: {
    alignItems: 'flex-end',
    marginHorizontal: 16,
    marginTop: 10,
  } as ViewStyle,
});
