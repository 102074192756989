import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import PreviewBox from '../../shared/cover_image_form/PreviewBox';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {EditProjectEpisodeImageRouteProp} from '../../../navigators/RouteProps';

import {Params as CoverImageFormCreateParams} from '../../../actions/cover_image_forms/create';
import {Params as CoverImageFormUpdateParams} from '../../../actions/cover_image_forms/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';
import {sendDefaultCustomEvent} from '../../../helpers/analytics';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import File from '../../../../domain/entities/File';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

import NetEpisodesRepository from '../../../../data/repositories/writer/NetEpisodesRepository';

export interface Params {
  projectId: number;
  storyId: number;
  episodeId: number;
  projectManuscriptId: number;
  toBeRelease?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditProjectEpisodeImageRouteProp;
  coverImageForm: CoverImageForm | null;
}

export interface DispatchProps {
  createCoverImageForm: (params: CoverImageFormCreateParams) => Promise<any>;
  updateCoverImageForm: (params: CoverImageFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  fetchedBackgroundImageUri: boolean;
  backgroundImageUri: string | null;
  alertMessage: string | null;
}

export default class New extends React.PureComponent<Props, State> {
  private ref = React.createRef<PreviewBox>();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      fetchedBackgroundImageUri: false,
      backgroundImageUri: null,
      alertMessage: null,
    };
  }

  public componentDidMount() {
    this.setupCoverImageForm();
  }

  public render(): React.ReactNode {
    const {navigation, route, coverImageForm} = this.props;
    const {
      loading,
      fetchedBackgroundImageUri,
      backgroundImageUri,
      alertMessage,
    } = this.state;
    const aspectRatio = horizontalAspectRatio;
    const widthAspectRatio = widthHorizontalAspectRatio;
    const recommendedSize = '2480x1555';
    return (
      <Layout
        title={'サムネイル'}
        navigation={navigation}
        close={true}
        onPressClose={this.handlePressClose}
        loading={loading}
        hideHeaderBottomBorder={true}>
        <Form
          aspectRatio={aspectRatio}
          widthAspectRatio={widthAspectRatio}
          coverImageForm={coverImageForm}
          recommendedSize={recommendedSize}
          onChangeImage={this.handleChangeImage}
          onSubmit={this.handleSubmit}
          onRequestReset={this.handleRequestReset}
          onForwardToImageSelection={this.handleForwardToImageSelection}
          onForwardToCharacterImageSelection={
            this.handleForwardToCharacterImageSelection
          }
          onForwardToTextInput={this.handleForwardToTextInput}
        />
        {coverImageForm && fetchedBackgroundImageUri && (
          <PreviewBox
            ref={this.ref}
            backgroundImageUri={backgroundImageUri}
            characterImageUri={coverImageForm.getCharacterImageUri()}
            aspectRatio={horizontalAspectRatio}
            imageTextInfoList={coverImageForm.getImageTextInfoList()}
            onDrawCanvas={this.handleDrawCanvas}
          />
        )}
        {alertMessage && (
          <AlertModal
            visible={true}
            onCloseModal={() => {
              this.setState({alertMessage: null});
            }}>
            {alertMessage}
          </AlertModal>
        )}
      </Layout>
    );
  }

  private handleChangeImage = (image: File) => {
    this.setState({
      fetchedBackgroundImageUri: true,
      backgroundImageUri: image.uri,
    });
  };

  private handleDrawCanvas = (image: File | null) => {
    const {updateCoverImageForm} = this.props;
    const {backgroundImageUri} = this.state;
    if (!this.ref.current) {
      return;
    }
    if (image) {
      updateCoverImageForm({
        image,
        backgroundImageUri: backgroundImageUri,
        uploadedSelfImage: true,
      });
      this.setState({fetchedBackgroundImageUri: false});
    }
  };

  private handleSubmit = () => {
    const {navigation, route, coverImageForm} = this.props;
    const {episodeId, toBeRelease, projectId, storyId, projectManuscriptId} =
      route.params;
    if (!coverImageForm) {
      return;
    }
    if (coverImageForm.image) {
      this.setState({loading: true});
      TapNovelTypedRestApi.patch(
        `/api/writer/episodes/${episodeId}`,
        {
          episode: {
            image: coverImageForm.image,
          },
        },
        {multipart: true},
      ).then(() => {
        if (toBeRelease) {
          navigation.replace('NewProjectEpisodeReleaseRequest', {
            projectId,
            episodeId,
            projectManuscriptId,
          });
        } else {
          navigation.goBack();
        }
      });
    } else if (coverImageForm.hasImage) {
      navigation.replace('NewProjectEpisodeReleaseRequest', {
        projectId,
        episodeId,
        projectManuscriptId,
      });
    }
  };

  private handleRequestReset = () => {
    this.setupCoverImageForm();
  };

  private handleForwardToImageSelection = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    (navigation as any).navigate('CoverImageNavigation', {
      screen: 'CoverImageFormBackgroundUsageHistories',
      params: {
        storyId,
        aspectRatio: horizontalAspectRatio,
        imageKey: 'coverImage',
      },
    });
  };

  private handleForwardToCharacterImageSelection = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    (navigation as any).navigate('CoverImageNavigation', {
      screen: 'CoverImageFormCharacterPatterns',
      params: {
        storyId,
        aspectRatio: horizontalAspectRatio,
        imageKey: 'coverImage',
      },
    });
  };

  private handleForwardToTextInput = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    (navigation as any).navigate('CoverImageNavigation', {
      screen: 'CoverImageFormText',
      params: {
        storyId,
        aspectRatio: horizontalAspectRatio,
        imageKey: 'coverImage',
      },
    });
  };

  private setupCoverImageForm = async () => {
    const {createCoverImageForm, route} = this.props;
    const {episodeId} = route.params;
    const episode = await new NetEpisodesRepository().find(episodeId);
    await createCoverImageForm({
      type: 'videoImage',
      image: null,
      hasImage: episode.hasImage,
      originalImageUrl: episode.originalImageUrl,
      backgroundImageUri: null,
      characterImageUri: null,
      uploadedSelfImage: null,
      imageTextInfoList: null,
    });
  };

  private handlePressClose = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };
}

const horizontalAspectRatio = 2480 / 1555;

const widthHorizontalAspectRatio = 343 / 375;
