import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import PositionedEffectMapper, {
  PositionedEffectData,
} from '../../entities/mappers/PositionedEffectMapper';

import PositionedEffect from '../../../domain/entities/PositionedEffect';
import PositionedEffectsRepository from '../../../domain/repositories/writer/PositionedEffectsRepository';

export default class NetPositionedEffectsRepository
  extends NetResourcesRepository<PositionedEffect, PositionedEffectData>
  implements PositionedEffectsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/positioned_effects',
        new PositionedEffectMapper(),
      ),
    );
  }
}
