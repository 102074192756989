import StoryCoverImageDecorator from '../view_models/StoryCoverImageDecorator';

import Story from '../../domain/entities/Story';
import BaseBackground from '../../domain/entities/BaseBackground';
import Background from '../../domain/entities/Background';
import BackgroundUsageHistory from '../../domain/entities/BackgroundUsageHistory';
import Actor from '../../domain/entities/Actor';
import ActorCharacter from '../../domain/entities/ActorCharacter';
import ActorCharacterFace from '../../domain/entities/ActorCharacterFace';
import CharacterPattern from '../../domain/entities/CharacterPattern';
import Mark from '../../domain/entities/Mark';
import Illustration from '../../domain/entities/Illustration';
import IllustrationUsageHistory from '../../domain/entities/IllustrationUsageHistory';
import FullScreenIllustration from '../../domain/entities/FullScreenIllustration';
import SpeechBalloon from '../../domain/entities/SpeechBalloon';
import OrientedSpeechBalloonPart from '../../domain/entities/OrientedSpeechBalloonPart';
import TextFrame from '../../domain/entities/TextFrame';
import TextFramePart from '../../domain/entities/TextFramePart';
import Wallpaper from '../../domain/entities/Wallpaper';
import WallpaperUsageHistory from '../../domain/entities/WallpaperUsageHistory';
import WriterUserProfile from '../../domain/entities/writer/CurrentUserProfile';
import ResponseStory from '../../domain/entities/response/Story';
import BaseEffect from '../../domain/entities/BaseEffect';
import Effect from '../../domain/entities/Effect';
import EffectUsageHistory from '../../domain/entities/EffectUsageHistory';

import {Options} from '../../domain/helpers/ImageUrlHelper';

interface Entity {
  getImageUrl(options: Options): string;
}

export function squareImageUrl(entity: Entity, width: number): string {
  return imageUrl(entity, width, 1);
}

export function imageUrl(
  entity: Entity,
  width: number,
  aspectRatio: number,
): string {
  return entity.getImageUrl({
    height: Math.round((width / aspectRatio) * 2),
    width: Math.round(width * 2),
  });
}

type StoryImageSizeClass = 'large';

const STORY_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in StoryImageSizeClass]: number;
} = {
  large: 450,
};
const STORY_ASPECT_RATIO = 1.6;

export function storyImageUrl(
  story: Story,
  size: StoryImageSizeClass = 'large',
) {
  return imageUrl(
    story,
    STORY_IMAGE_SIZE_CLASS_TO_SIZE[size],
    STORY_ASPECT_RATIO,
  );
}

type StoryCoverImageSizeClass = 'small' | 'large';

const STORY_COVER_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in StoryCoverImageSizeClass]: number;
} = {
  small: 100,
  large: 300,
};
const STORY_COVER_ASPECT_RATIO = 0.7;

export function storyCoverImageUrl(
  story: StoryCoverImageDecorator | ResponseStory,
  size: StoryCoverImageSizeClass = 'small',
) {
  return imageUrl(
    story,
    STORY_COVER_IMAGE_SIZE_CLASS_TO_SIZE[size],
    STORY_COVER_ASPECT_RATIO,
  );
}

type BaseBackgroundImageSizeClass = 'middle';

const BASE_BACKGROUND_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in BaseBackgroundImageSizeClass]: number;
} = {
  middle: 150,
};
const BACKGROUND_ASPECT_RATIO = 1.25;

export function baseBackgroundImageUrl(
  baseBackground: BaseBackground,
  size: BaseBackgroundImageSizeClass = 'middle',
) {
  return imageUrl(
    baseBackground,
    BASE_BACKGROUND_IMAGE_SIZE_CLASS_TO_SIZE[size],
    BACKGROUND_ASPECT_RATIO,
  );
}

export function backgroundUsageHistoryImageUrl(
  backgroundUsageHistory: BackgroundUsageHistory,
  size: BaseBackgroundImageSizeClass = 'middle',
) {
  return imageUrl(
    backgroundUsageHistory,
    BASE_BACKGROUND_IMAGE_SIZE_CLASS_TO_SIZE[size],
    BACKGROUND_ASPECT_RATIO,
  );
}

type BackgroundImageSizeClass = 'middle' | 'large';

const BACKGROUND_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in BackgroundImageSizeClass]: number;
} = {
  middle: 150,
  large: 450,
};

export function backgroundImageUrl(
  background: Background,
  size: BackgroundImageSizeClass,
) {
  return imageUrl(
    background,
    BACKGROUND_IMAGE_SIZE_CLASS_TO_SIZE[size],
    BACKGROUND_ASPECT_RATIO,
  );
}

const ACTOR_CHARACTER_ASPECT_RATIO = 0.7;

type ActorCharacterImageSizeClass = 'middle' | 'large';

const ACTOR_CHARACTER_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in ActorCharacterImageSizeClass]: number;
} = {
  middle: 200,
  large: 300,
};

export function actorCharacterImageUrl(
  actorCharacter: ActorCharacter,
  size: ActorCharacterImageSizeClass,
) {
  return imageUrl(
    actorCharacter,
    ACTOR_CHARACTER_IMAGE_SIZE_CLASS_TO_SIZE[size],
    ACTOR_CHARACTER_ASPECT_RATIO,
  );
}

type ActorCharacterFaceImageSizeClass = 'middle' | 'large';

const ACTOR_CHARACTER_FACE_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in ActorCharacterFaceImageSizeClass]: number;
} = {
  middle: 200,
  large: 300,
};

export function actorCharacterFaceImageUrl(
  actorCharacterFace: ActorCharacterFace,
  size: ActorCharacterFaceImageSizeClass,
) {
  return imageUrl(
    actorCharacterFace,
    ACTOR_CHARACTER_FACE_IMAGE_SIZE_CLASS_TO_SIZE[size],
    ACTOR_CHARACTER_ASPECT_RATIO,
  );
}
export function actorImageUrl(
  actor: Actor,
  size: ActorCharacterImageSizeClass,
) {
  return imageUrl(
    actor,
    ACTOR_CHARACTER_IMAGE_SIZE_CLASS_TO_SIZE[size],
    ACTOR_CHARACTER_ASPECT_RATIO,
  );
}

export function characterPatternImageUrl(
  characterPattern: CharacterPattern,
  size: ActorCharacterImageSizeClass,
) {
  return imageUrl(
    characterPattern,
    ACTOR_CHARACTER_IMAGE_SIZE_CLASS_TO_SIZE[size],
    ACTOR_CHARACTER_ASPECT_RATIO,
  );
}

type MarkImageSizeClass = 'large';

const MARK_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in MarkImageSizeClass]: number;
} = {
  large: 80,
};

export function markImageUrl(mark: Mark, size: MarkImageSizeClass) {
  return squareImageUrl(mark, MARK_IMAGE_SIZE_CLASS_TO_SIZE[size]);
}

type IllustrationImageSizeClass = 'middle' | 'large';

const ILLUSTRATION_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in IllustrationImageSizeClass]: number;
} = {
  middle: 150,
  large: 250,
};

export function illustrationUsageHistoryImageUrl(
  illustrationUsageHistory: IllustrationUsageHistory,
  size: IllustrationImageSizeClass,
) {
  return squareImageUrl(
    illustrationUsageHistory,
    ILLUSTRATION_IMAGE_SIZE_CLASS_TO_SIZE[size],
  );
}

export function illustrationImageUrl(
  illustration: Illustration,
  size: IllustrationImageSizeClass,
) {
  return squareImageUrl(
    illustration,
    ILLUSTRATION_IMAGE_SIZE_CLASS_TO_SIZE[size],
  );
}

type FullScreenIllustrationImageSizeClass = 'middle' | 'large';

const FULL_SCREEN_ILLUSTRATION_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in FullScreenIllustrationImageSizeClass]: number;
} = {
  middle: 150,
  large: 250,
};

export function fullScreenIllustrationImageUrl(
  fullScreenIllustration: FullScreenIllustration,
  size: FullScreenIllustrationImageSizeClass,
) {
  return squareImageUrl(
    fullScreenIllustration,
    FULL_SCREEN_ILLUSTRATION_IMAGE_SIZE_CLASS_TO_SIZE[size],
  );
}

type SpeechBalloonImageSizeClass = 'large';

const SPEECH_BALLOON_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in SpeechBalloonImageSizeClass]: number;
} = {
  large: 200,
};

export function speechBalloonImageUrl(
  speechBalloon: SpeechBalloon,
  size: SpeechBalloonImageSizeClass,
) {
  return squareImageUrl(
    speechBalloon,
    SPEECH_BALLOON_IMAGE_SIZE_CLASS_TO_SIZE[size],
  );
}

type OrientedSpeechBalloonPartImageSizeClass = 'large';

const ORIENTED_SPEECH_BALLOON_PART_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in SpeechBalloonImageSizeClass]: number;
} = {
  large: 450,
};

export function orientedSpeechBalloonPartImageUrl(
  orientedSpeechBalloonPart: OrientedSpeechBalloonPart,
  size: OrientedSpeechBalloonPartImageSizeClass,
) {
  return squareImageUrl(
    orientedSpeechBalloonPart,
    ORIENTED_SPEECH_BALLOON_PART_IMAGE_SIZE_CLASS_TO_SIZE[size],
  );
}

type TextFrameImageSizeClass = 'large';

const TEXT_FRAME_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in TextFrameImageSizeClass]: number;
} = {
  large: 200,
};

export function textFrameImageUrl(
  textFrame: TextFrame,
  size: TextFrameImageSizeClass,
) {
  return squareImageUrl(textFrame, TEXT_FRAME_IMAGE_SIZE_CLASS_TO_SIZE[size]);
}

type TextFramePartImageSizeClass = 'large';

const TEXT_FRAME_PART_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in TextFramePartImageSizeClass]: number;
} = {
  large: 200,
};

export function textFramePartImageUrl(
  textFramePart: TextFramePart,
  size: TextFramePartImageSizeClass,
) {
  return squareImageUrl(
    textFramePart,
    TEXT_FRAME_PART_IMAGE_SIZE_CLASS_TO_SIZE[size],
  );
}

type WallpaperImageSizeClass = 'middle' | 'large';

const WALLPAPER_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in WallpaperImageSizeClass]: number;
} = {
  middle: 150,
  large: 250,
};

const WALLPAPER_ASPECT_RATIO = 1.25;

export function wallpaperImageUrl(
  wallpaper: Wallpaper,
  size: WallpaperImageSizeClass,
) {
  return imageUrl(
    wallpaper,
    WALLPAPER_IMAGE_SIZE_CLASS_TO_SIZE[size],
    WALLPAPER_ASPECT_RATIO,
  );
}

export function wallpaperUsageHistoryImageUrl(
  wallpaperUsageHistory: WallpaperUsageHistory,
  size: BaseBackgroundImageSizeClass = 'middle',
) {
  return imageUrl(
    wallpaperUsageHistory,
    WALLPAPER_IMAGE_SIZE_CLASS_TO_SIZE[size],
    WALLPAPER_ASPECT_RATIO,
  );
}

type WriterUserImageSizeClass = 'middle';

const WRITER_USER_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in WriterUserImageSizeClass]: number;
} = {
  middle: 100,
};

export function writerUserImageUrl(
  writerUserProfile: WriterUserProfile,
  size: WriterUserImageSizeClass,
) {
  return squareImageUrl(
    writerUserProfile,
    WRITER_USER_IMAGE_SIZE_CLASS_TO_SIZE[size],
  );
}

type EffectImageSizeClass = 'middle' | 'large';

const EFFECT_IMAGE_SIZE_CLASS_TO_SIZE: {
  [key in EffectImageSizeClass]: number;
} = {
  middle: 150,
  large: 250,
};

const EFFECT_ASPECT_RATIO = 1.23;

export function baseEffectImageUrl(
  effect: BaseEffect,
  size: EffectImageSizeClass,
) {
  return imageUrl(
    effect,
    EFFECT_IMAGE_SIZE_CLASS_TO_SIZE[size],
    EFFECT_ASPECT_RATIO,
  );
}

export function effectImageUrl(effect: Effect, size: EffectImageSizeClass) {
  return imageUrl(
    effect,
    EFFECT_IMAGE_SIZE_CLASS_TO_SIZE[size],
    EFFECT_ASPECT_RATIO,
  );
}

export function effectUsageHistoryImageUrl(
  effectUsageHistory: EffectUsageHistory,
  size: EffectImageSizeClass,
) {
  return imageUrl(
    effectUsageHistory,
    EFFECT_IMAGE_SIZE_CLASS_TO_SIZE[size],
    EFFECT_ASPECT_RATIO,
  );
}

export interface HasOriginalImageUrl {
  originalImageUrl: string;
}

export const getResizedImageUrl = (
  entity: HasOriginalImageUrl,
  {width, height}: {width: number; height: number},
  options?: {nocanvas: boolean},
) => {
  return entity.originalImageUrl.replace(
    /\/original\//,
    `/${options?.nocanvas ? 'nc-' : ''}${width}x${height}/`,
  );
};

export const getDoubleResizeImageUrl = (
  entity: HasOriginalImageUrl,
  {width, height}: {width: number; height: number},
  options?: {nocanvas: boolean},
) => {
  return getResizedImageUrl(
    entity,
    {width: width * 2, height: height * 2},
    options,
  );
};
