import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/character_form/edit/name_label_colors/Index';

import AppState from '../../../../reducers/AppState';

import updateCharacter, {
  Params as CharacterUpdateParams,
} from '../../../../actions/characters/update';
import createCharacterForm, {
  Params as CharacterFormCreateParams,
} from '../../../../actions/character_forms/create';
import updateCharacterForm, {
  Params as CharacterFormUpdateParams,
} from '../../../../actions/character_forms/update';
import showSpeechBalloon from '../../../../actions/speech_balloons/show';

import selectEntity from '../../../../helpers/selectEntity';

import {CharacterFormEditNameLabelColorsRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CharacterFormEditNameLabelColorsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, forms} = state;
  const {characterId} = route.params;
  const character = selectEntity(entities.characters, Number(characterId));
  const characterForm =
    forms.characterForm && forms.characterForm.id === Number(characterId)
      ? forms.characterForm
      : null;
  const speechBalloonId = 1;
  const speechBalloon = selectEntity(entities.speechBalloons, speechBalloonId);
  return {
    navigation,
    character,
    characterForm,
    speechBalloonId,
    speechBalloon,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    updateCharacter: (id: number, params: CharacterUpdateParams) =>
      dispatch(updateCharacter(id, params)),
    createCharacterForm: (params: CharacterFormCreateParams) =>
      dispatch(createCharacterForm(params)),
    updateCharacterForm: (params: CharacterFormUpdateParams) =>
      dispatch(updateCharacterForm(params)),
    showSpeechBalloon: (id: number) => dispatch(showSpeechBalloon(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
