import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import SortButtons from './SortButtons';
import {SortValue} from './types';

import QuestionList from '../../questions/components/QuestionList';

import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';

import Question from '../../../../../domain/entities/writer/faq/Question';

interface Props {
  query?: string;
  sort: SortValue;
  questions: Array<Question> | null;
  onPressMore?: () => void;
}

const QuestionListSection: React.FC<Props> = props => {
  const {questions, query, sort, onPressMore} = props;
  if (!questions || questions.length === 0) {
    return null;
  }
  return (
    <View style={styles.container}>
      <SortButtons query={query} sort={sort} />
      <QuestionList questions={questions} />
      {onPressMore ? (
        <DefaultOutlineButton style={styles.button} onPress={onPressMore}>
          もっと見る
        </DefaultOutlineButton>
      ) : null}
    </View>
  );
};

export default React.memo(QuestionListSection);

const styles = StyleSheet.create({
  container: {
    marginVertical: 24,
    marginTop: 8,
  } as ViewStyle,
  button: {
    alignSelf: 'center',
    width: 240,
    height: 46,
    marginTop: 24,
  } as ViewStyle,
});
