// tslint:disable-next-line:no-submodule-imports
import 'react-app-polyfill/ie9';
// tslint:disable-next-line:no-submodule-imports
import 'react-app-polyfill/stable';

// tslint:disable-next-line:no-submodule-imports
import 'resize-observer-polyfill/dist/ResizeObserver.global';
import {} from './presentation/helpers/ignoreWarning';

import React from 'react';
import {Linking} from 'react-native';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import urlparser from 'url';
import queryString from 'query-string';

import store from './presentation/store/configureStore';

import Alert from './presentation/components/shared/alert/Alert';

import App from './presentation/containers/App';
import * as serviceWorker from './serviceWorker';

import loadIonicons from './presentation/helpers/font';
import oauthCallback from './presentation/helpers/oauthCallback';
import {getStateFromPath, getActionFromState} from '@react-navigation/native';
import {navigationRef} from './presentation/navigators/navigationRef';
import LinkingConfig from './presentation/navigators/LinkingConfig';
import dispatchAction from './presentation/navigators/dispatchAction';

import OtherHostReferrer from './data/data_stores/storage/OtherHostReferrer';
import AccessTokenRepository from './data/repositories/StorageAccessTokenRepository';

const referrer = document.referrer;
if (referrer) {
  const otherHost = urlparser.parse(referrer).host;
  if (otherHost && otherHost !== location.host) {
    OtherHostReferrer.set(otherHost);
  }
}

loadIonicons();

Linking.getInitialURL().then((url: string | null) => {
  return url && handleOpenURL(url);
});

const handleOpenURL = (url: string | null) => {
  if (!url) {
    return;
  }
  const parsedQuery = urlparser.parse(url);
  if (!parsedQuery.pathname) {
    return;
  }
  const path = parsedQuery.pathname.substr(1);
  if (path === '') {
    return;
  }
  const params = queryString.parse(parsedQuery.search || '');
  oauthCallback(parsedQuery, params);
  const state = getStateFromPath(path, {
    screens: LinkingConfig,
  });
  if (!state) {
    return;
  }

  const action = getActionFromState(state);

  if (!action) {
    return;
  }

  try {
    navigationRef?.current?.dispatch(action);
    // eslint-disable-next-line no-empty
  } catch (error) {}
};

window.addEventListener('message', event => {
  if (
    typeof event.data === 'string' &&
    event.data.startsWith('dispatchAction')
  ) {
    const path = event.data.split(',')[1];
    dispatchAction(path);
  } else if (
    typeof event.data === 'string' &&
    event.data.startsWith('sendAccessToken')
  ) {
    const accessToken = event.data.split(',')[1];
    new AccessTokenRepository().update(accessToken);
  }
});

ReactDOM.render(
  <Provider store={store}>
    <App />
    <Alert.AlertView />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
