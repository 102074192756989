import EntityMapper from '../EntityMapper';

import UserProfile from '../../../../domain/entities/writer/CurrentUserProfile';

export interface CurrentUserProfileData {
  id: number;
  description: string;
  has_image: boolean;
  original_image_url: string;
  birthday: string | null;
  home_page_url: string | null;
  twitter_account_url: string | null;
  youtube_channel_url: string | null;
}

export default class UserProfileMapper
  implements EntityMapper<CurrentUserProfileData, UserProfile>
{
  public map(obj: CurrentUserProfileData): UserProfile {
    return new UserProfile(
      obj.id,
      obj.description,
      obj.has_image,
      obj.original_image_url,
      obj.birthday ? new Date(obj.birthday) : null,
      obj.home_page_url,
      obj.twitter_account_url,
      obj.youtube_channel_url,
    );
  }
}
