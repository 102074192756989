import {Dispatch} from 'redux';

import Types from '../../../Types';

import {sendDefaultCustomEvent, setUser} from '../../../../helpers/analytics';

import NetCurrentUserRepository from '../../../../../data/repositories/writer/NetCurrentUserRepository';
import AccessTokenRepository from '../../../../../data/repositories/StorageAccessTokenRepository';

export interface Params {}

export default function action(): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      new NetCurrentUserRepository().find().then(currentUser => {
        sendDefaultCustomEvent({
          resourceType: 'writer/user_session',
          resourceId: currentUser.id,
          actionName: 'destroy',
        });
      });
      await new AccessTokenRepository().destroy();
      dispatch({
        type: Types.ResetWriterCurrentUser,
      });
      setUser(null);
    } catch (err) {
      dispatch({type: Types.CreateWriterCurrentUserFailure});
      throw err;
    }
  };
}
