import * as React from 'react';

import DefaultOutlineButton from '../../shared/buttons/DefaultOutlineButton';

const style = {width: 240};

interface Props {
  disabled?: boolean;
  onForwardToNewScene: () => void;
}

const NewSceneButton: React.FunctionComponent<Props> = props => {
  const {disabled, onForwardToNewScene} = props;
  return (
    <DefaultOutlineButton
      disabled={disabled}
      style={style}
      onPress={onForwardToNewScene}>
      シーンを追加
    </DefaultOutlineButton>
  );
};

export default React.memo(NewSceneButton);
