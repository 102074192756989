import Entity from '../Entity';

import AnnouncementCategory from './AnnouncementCategory';

export default class Announcement implements Entity {
  constructor(
    public id: number,
    public title: string,
    public content: string,
    public publishedAt: Date,
    public writerAnnouncementCategory: AnnouncementCategory,
  ) {}
}
