import TapNovelRestApi from '../../../../data_stores/net/TapNovelRestApi';

import TwitterAccessTokenMapper, {
  TwitterAccessTokenData,
} from '../../../../value_objects/mappers/TwitterAccessTokenMapper';

import TwitterAccessToken from '../../../../../domain/value_objects/TwitterAccessToken';

import AccessTokensRepository, {
  CreateParams,
} from '../../../../../domain/repositories/writer/social_accounts/twitter_accounts/AccessTokensRepository';

export default class NetAccessTokensRepository
  implements AccessTokensRepository
{
  private endPoint =
    '/api/writer/social_accounts/twitter_accounts/access_token';

  private mapper: TwitterAccessTokenMapper;

  constructor() {
    this.mapper = new TwitterAccessTokenMapper();
  }

  public create(params: CreateParams): Promise<TwitterAccessToken> {
    const path = this.endPoint;
    return TapNovelRestApi.post<TwitterAccessTokenData>(path, params).then(
      obj => {
        return this.mapper.map(obj.body);
      },
    );
  }
}
