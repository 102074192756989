import {Dispatch} from 'redux';

import Types from '../Types';

import File from '../../../domain/entities/File';
import {Rating, Format} from '../../../domain/entities/Story';

import ImageTextInfo from '../../../domain/value_objects/ImageTextInfo';

export interface Params {
  id?: number | null;
  title?: string | null;
  catchPhrase?: string | null;
  summary?: string | null;
  introduction?: string | null;
  coverIllustrator?: string | null;
  rating?: Rating | null;
  format?: Format | null;
  hasImage?: boolean | null;
  originalImageUrl?: string | null;
  hasCoverImage?: boolean | null;
  originalCoverImageUrl?: string | null;
  serialized?: boolean | null;
  allowedVideoSharing?: boolean | null;
  image?: File | null;
  coverImage?: File | null;
  uploadedSelfImage?: boolean | null;
  uploadedSelfCoverImage?: boolean | null;
  mainGenreId?: number | null;
  subGenreIds?: number[] | null;
  keywordNames?: string[] | null;
  imageBackgroundUri?: string | null;
  imageCharacterUri?: string | null;
  imageTextInfoList?: ImageTextInfo[] | null;
  coverImageBackgroundUri?: string | null;
  coverImageCharacterUri?: string | null;
  coverImageTextInfoList?: ImageTextInfo[] | null;
}

export default function action(payload: Params) {
  return (dispatch: Dispatch<any>) => {
    if (payload.title) {
      payload.title = payload.title.match(/^無題(\d+)?$/) ? '' : payload.title;
    }
    dispatch({
      payload,
      type: Types.CreateStoryForm,
    });
    return new Promise(resolve => resolve(payload));
  };
}
