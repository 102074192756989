import * as React from 'react';
import {
  ImageStyle,
  ImageSourcePropType,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import EditableLaterMessage from '../../../shared/EditableLaterMessage';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import RadioButton from '../../../shared/forms/RadioButton';
import MultiSwitch from '../../../shared/forms/MultiSwitch';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import Input from '../../../shared/forms/Input';
import TooltipModal from '../../../shared/modals/TooltipModal';
import AlertModal from '../../../shared/modals/AlertModal';
import KeyboardSpacer from '../../../shared/KeyboardSpacer';

import convertImageSource from '../../../../helpers/convertImageSource';

import {formStyles} from '../../../../styles/variables';

import {Rating, Format} from '../../../../../domain/entities/Story';
import StoryForm from '../../../../../domain/forms/StoryForm';

import storyCatchPhraseUri from '../../../../../assets/images/tips/story_catch_phrase.png';
import storyKeywordsUri from '../../../../../assets/images/tips/story_keywords.png';

type TooltipModalType =
  | 'catch_phrase'
  | 'keyword'
  | 'rating'
  | 'format'
  | 'serialized';

const TypeToTooltipInfo = {
  catch_phrase: {
    title: 'キャッチコピー',
    description:
      '検索結果のページに表示されるキャッチコピーとなります。読者が読みたくなるようなキャッチコピーを記入しましょう。',
    source: convertImageSource(storyCatchPhraseUri),
    imageStyle: {width: 279, height: 160},
  },
  keyword: {
    title: 'キーワード',
    description:
      '読者がストーリーを探しやすくなります。\nそのストーリーの特徴となるキーワードを登録しましょう。',
    source: convertImageSource(storyKeywordsUri),
    imageStyle: {width: 279, height: 160},
  },
  rating: {
    title: 'レイティング',
    description:
      '内容に暴力的な表現や性的な表現が含まれる場合は設定してください。',
    imageStyle: {},
  },
  format: {
    title: '形式',
    description:
      '読者がストーリーを選択しやすくなります。\nストーリーの長さを設定してください。',
    imageStyle: {},
  },
  serialized: {
    title: '掲載状況',
    description:
      'ストーリーが完結した場合は設定してください。\n1話読切のストーリーは自動的に完結となります。',
    imageStyle: {},
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

interface Props {
  storyForm: StoryForm;
  submitButtonText?: string;
  onChangeCatchPhrase: (text: string) => void;
  onChangeRating: (rating: Rating) => void;
  onChangeFormat: (format: Format) => void;
  onToggleSerialized: (checked: boolean) => void;
  onForwardToKeywords: (keywordNames: string[] | null) => void;
  onSubmit: () => void;
}

interface State {
  storyForm: StoryForm;
  catchPhrase: string;
  tooltipModalType: TooltipModalType | null;
  alertMessage?: string;
}

export default class Form extends React.PureComponent<Props, State> {
  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (
      nextProps.storyForm.title !== prevState.storyForm.title ||
      nextProps.storyForm.introduction !== prevState.storyForm.introduction
    ) {
      return {
        storyForm: nextProps.storyForm,
        catchPhrase: nextProps.storyForm.catchPhrase || '',
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    const {storyForm} = props;
    const {catchPhrase} = storyForm;
    this.state = {
      storyForm,
      catchPhrase: catchPhrase || '',
      tooltipModalType: null,
    };
  }

  public render(): React.ReactNode {
    const {storyForm, submitButtonText} = this.props;
    const {catchPhrase, tooltipModalType, alertMessage} = this.state;
    const modalInfo = tooltipModalType
      ? TypeToTooltipInfo[tooltipModalType]
      : null;
    return (
      <View style={styles.container}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'キャッチコピー（最大25文字）'}
            length={catchPhrase.length}
            maxLength={25}
            onPressQuestion={() => {
              this.setState({tooltipModalType: 'catch_phrase'});
            }}
          />
          <Input
            value={catchPhrase}
            style={this.validCatchPhrase() ? null : styles.inputError}
            placeholder={'キャッチコピーを入力'}
            onChangeText={this.handleChangeCatchPhrase}
            onBlur={() => {
              this.props.onChangeCatchPhrase(this.state.catchPhrase);
            }}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'キーワード'}
            onPressQuestion={() => {
              this.setState({tooltipModalType: 'keyword'});
            }}
          />
          <RadioButton
            name={this.getKeywordNames()}
            onPress={this.handleForwardToKeywords}
          />
        </View>

        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'レイティング'}
            onPressQuestion={() => {
              this.setState({tooltipModalType: 'rating'});
            }}
          />
          <MultiSwitch
            items={RATING_ITEMS}
            value={storyForm.rating || 'rating_none'}
            onSelectItem={this.handleChangeRating}
          />
        </View>

        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'形式'}
            onPressQuestion={() => {
              this.setState({tooltipModalType: 'format'});
            }}
          />
          <MultiSwitch
            items={FORMAT_ITEMS}
            value={storyForm.format || 'format_long'}
            onSelectItem={this.handleChangeFormat}
          />
        </View>

        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'掲載状況'}
            onPressQuestion={() => {
              this.setState({tooltipModalType: 'serialized'});
            }}
          />
          <MultiSwitch
            items={SERIALIZED_ITEMS}
            value={
              storyForm.serialized === null
                ? 'serializing'
                : storyForm.serialized
                ? 'serializing'
                : 'completed'
            }
            disabled={storyForm.format === 'format_one_shot'}
            onSelectItem={this.handleSelectItemSerialized}
          />
        </View>
        <View style={styles.button}>
          <EditableLaterMessage />
          <PrimaryButton
            disabled={this.disabledButton()}
            onPress={this.handleSubmit}>
            {submitButtonText || '次へ'}
          </PrimaryButton>
        </View>
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            imageStyle={modalInfo.imageStyle}
            source={modalInfo.source}
            onCloseModal={() => {
              this.setState({tooltipModalType: null});
            }}></TooltipModal>
        ) : null}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage || ''}
        </AlertModal>
        <KeyboardSpacer />
      </View>
    );
  }

  private handleChangeCatchPhrase = (catchPhrase: string) => {
    this.setState({catchPhrase});
  };

  private handleForwardToKeywords = () => {
    const {storyForm, onForwardToKeywords} = this.props;
    onForwardToKeywords(storyForm.keywordNames);
  };

  private handleSelectItemSerialized = (item: {
    label: string;
    value: string;
  }) => {
    const {onToggleSerialized} = this.props;
    onToggleSerialized(item.value === 'serializing');
  };

  private handleChangeRating = (item: {label: string; value: string}) => {
    const {onChangeRating} = this.props;
    const value = item.value;
    if (
      value === 'rating_none' ||
      value === 'rating_r15' ||
      value === 'rating_r18'
    ) {
      onChangeRating(value);
    }
  };

  private handleChangeFormat = (item: {label: string; value: string}) => {
    const {onChangeFormat, onToggleSerialized} = this.props;
    const value = item.value;
    if (value === 'format_one_shot') {
      onToggleSerialized(false);
    }
    if (
      value === 'format_one_shot' ||
      value === 'format_short' ||
      value === 'format_long'
    ) {
      onChangeFormat(value);
    }
  };

  private handleSubmit = () => {
    const {onChangeCatchPhrase, onSubmit} = this.props;
    onChangeCatchPhrase(this.state.catchPhrase);
    onSubmit();
  };

  private getKeywordNames(): string {
    const {storyForm} = this.props;
    const {keywordNames} = storyForm;
    if (!keywordNames) {
      return '';
    }
    return keywordNames.join('、');
  }

  private disabledButton = (): boolean => {
    if (!this.validCatchPhrase()) {
      return true;
    }
    return false;
  };

  private validCatchPhrase = () => {
    const {catchPhrase} = this.state;
    return catchPhrase.length <= 25;
  };
}

const RATING_ITEMS = [
  {label: 'なし', value: 'rating_none'},
  {label: 'R-15', value: 'rating_r15'},
  {label: 'R-18', value: 'rating_r18'},
];

const FORMAT_ITEMS: Array<{
  label: string;
  value: 'format_long' | 'format_short' | 'format_one_shot';
  optionLabel?: string;
}> = [
  {
    label: '長編',
    value: 'format_long',
    optionLabel: '（10話以上）',
  },
  {
    label: '短編',
    value: 'format_short',
    optionLabel: '（9話以下）',
  },
  {label: '1話読切', value: 'format_one_shot'},
];

const SERIALIZED_ITEMS = [
  {label: '連載中', value: 'serializing'},
  {label: '完結', value: 'completed'},
];

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 20,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  inputError: {
    color: '#f23406',
  },
});
