import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/pro/home/Index';

import AppState from '../../../reducers/AppState';

import showCurrentUser from '../../../actions/writer/current_user/show';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUser: () => dispatch(showCurrentUser()),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
