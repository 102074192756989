import * as React from 'react';
import {
  ImageStyle,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import dateformat from 'dateformat';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import MoreIcon from '../../../shared/icons/ionicons/MoreIcon';

import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../../styles/variables';
import {storyCoverImageUrl} from '../../../../helpers/images';

import EpisodeVideoExportJobExecution from '../../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

interface Props {
  episodeVideoExportJobExecution: EpisodeVideoExportJobExecution;
  onSelectEpisodeVideoExportJobExecution: (
    episodeVideoExportJobExecution: EpisodeVideoExportJobExecution,
  ) => void;
  onOpenActionSheet?: (
    episodeVideoExportJobExecution: EpisodeVideoExportJobExecution,
  ) => void;
}

const EpisodeVideoExportJobExecutionListItem: React.FunctionComponent<
  Props
> = props => {
  const {
    episodeVideoExportJobExecution,
    onSelectEpisodeVideoExportJobExecution,
    onOpenActionSheet,
  } = props;
  const source = React.useMemo(() => {
    return {
      uri: episodeVideoExportJobExecution.getSmallImageUrl(),
      headers: {Accept: 'image/webp,image/apng,*/*'},
    };
  }, []);
  const onPress = React.useCallback(() => {
    onSelectEpisodeVideoExportJobExecution(episodeVideoExportJobExecution);
  }, []);
  const handleOpenActionSheet = React.useCallback(() => {
    onOpenActionSheet && onOpenActionSheet(episodeVideoExportJobExecution);
  }, []);
  const style =
    episodeVideoExportJobExecution.videoType === 'horizontal_full'
      ? ({paddingBottom: 18} as ViewStyle)
      : null;
  return (
    <Pressable
      style={[styles.container, style]}
      disabled={episodeVideoExportJobExecution.status !== 'succeeded'}
      onPress={onPress}>
      {episodeVideoExportJobExecution.hasImage ? (
        <FastImageAdapter
          style={[
            styles.image,
            episodeVideoExportJobExecution.getSmallImageSize(),
          ]}
          source={source}
        />
      ) : (
        <View
          style={[
            styles.image,
            episodeVideoExportJobExecution.getSmallImageSize(),
          ]}>
          <Text style={styles.noImageText}>表紙なし</Text>
        </View>
      )}
      <View style={styles.storyInfo}>
        <Text style={styles.title}>
          {episodeVideoExportJobExecution.title ||
            episodeVideoExportJobExecution.episode.story.title}
        </Text>
        <Text style={styles.autoPlaySpeed}>
          {episodeVideoExportJobExecution.videoAutoPlaySpeed}倍速
        </Text>
        {episodeVideoExportJobExecution.status === 'succeeded' &&
        episodeVideoExportJobExecution.finishedAt ? (
          <Text style={styles.createdAt}>
            {dateformat(
              episodeVideoExportJobExecution.finishedAt,
              'yyyy.mm.dd HH:MM',
            )}{' '}
            作成
          </Text>
        ) : (
          <Text style={styles.label}>作成中</Text>
        )}
      </View>
      <View>
        {onOpenActionSheet && (
          <Pressable
            style={Platform.select({
              web: {pointerEvents: 'all'} as any,
              default: {},
            })}
            onPress={handleOpenActionSheet}>
            <MoreIcon />
          </Pressable>
        )}
      </View>
    </Pressable>
  );
};

export default React.memo(EpisodeVideoExportJobExecutionListItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 10,
    backgroundColor: 'white',
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  storyInfo: {
    flex: 1,
    margin: 10,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
  autoPlaySpeed: {
    fontSize: 10,
    color: '#383838',
    marginBottom: 10,
  } as TextStyle,
  createdAt: {
    color: '#666',
    fontSize: 10,
  } as TextStyle,
  label: {
    borderColor: '#2c86d7',
    borderWidth: 1,
    color: '#2c86d7',
    fontSize: 8,
    justifyContent: 'center',
    textAlign: 'center',
    width: 35,
  } as TextStyle,
});
