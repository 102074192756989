import {Dispatch} from 'redux';

import Types from '../../../Types';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

import NetTermsAcceptanceRepository from '../../../../../data/repositories/writer/temporary_users/NetTermsAcceptanceRepository';

export default function action(): (
  dispatch: Dispatch<any>,
) => Promise<CurrentUser> {
  return async (dispatch: Dispatch<any>): Promise<CurrentUser> => {
    const repository = new NetTermsAcceptanceRepository();
    try {
      const payload = await repository.create({});
      dispatch({payload, type: Types.CreateWriterCurrentUserSuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateWriterCurrentUserFailure});
      throw err;
    }
  };
}
