import {TextStyle, ViewStyle} from 'react-native';

import {camelizeKeys} from 'humps';

import EntityMapper from './EntityMapper';

import OrientedSpeechBalloonPartMapper, {
  OrientedSpeechBalloonPartData,
} from './OrientedSpeechBalloonPartMapper';

import SpeechBalloon from '../../../domain/entities/SpeechBalloon';

export interface SpeechBalloonData {
  id: number;
  original_image_url: string;
  center_speech_balloon_parts: OrientedSpeechBalloonPartData[];
  container_style: {[key: string]: any};
  text_style: {[key: string]: any};
  voice_icon_style?: {[key: string]: any};
}

export default class SpeechBalloonMapper
  implements EntityMapper<SpeechBalloonData, SpeechBalloon>
{
  private orientedSpeechBalloonPartMapper: OrientedSpeechBalloonPartMapper;

  constructor() {
    this.orientedSpeechBalloonPartMapper =
      new OrientedSpeechBalloonPartMapper();
  }

  public map(obj: SpeechBalloonData): SpeechBalloon {
    const centerSpeechBalloonParts = obj.center_speech_balloon_parts.map(
      orientedSpeechBalloonPartObj => {
        return this.orientedSpeechBalloonPartMapper.map(
          orientedSpeechBalloonPartObj,
        );
      },
    );
    const containerStyle = camelizeKeys(obj.container_style) as ViewStyle;
    const textStyle = camelizeKeys(obj.text_style) as TextStyle;
    const voiceIconStyle = obj.voice_icon_style
      ? (camelizeKeys(obj.voice_icon_style) as ViewStyle)
      : undefined;
    return new SpeechBalloon(
      obj.id,
      obj.original_image_url,
      centerSpeechBalloonParts,
      containerStyle,
      textStyle,
      voiceIconStyle,
    );
  }
}
