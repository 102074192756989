import MarkMapper, {MarkData} from '../../../entities/mappers/MarkMapper';

import PositionMapper, {PositionData} from '../PositionMapper';

import MarkShowSceneCommand from '../../../../domain/value_objects/scene_commands/MarkShowSceneCommand';

export interface MarkShowSceneCommandData {
  type: string;
  mark: MarkData;
  position: PositionData;
}

export default class MarkShowSceneCommandMapper {
  private markMapper: MarkMapper;
  private positionMapper: PositionMapper;

  constructor() {
    this.markMapper = new MarkMapper();
    this.positionMapper = new PositionMapper();
  }

  public map(obj: MarkShowSceneCommandData): MarkShowSceneCommand {
    const mark = this.markMapper.map(obj.mark);
    const position = this.positionMapper.map(obj.position);
    return new MarkShowSceneCommand(mark, position);
  }
}
