import * as React from 'react';

import BaseIndex from '../../../shared/generics/character_maker/actors/Index';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CoverImageFormCharacterMakerActorsRouteProp} from '../../../../navigators/RouteProps';

import ActorCharacter from '../../../../../domain/entities/ActorCharacter';

export interface Params {
  characterMakerActorGroupId: number;
  skinColorPatternId: number;
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormCharacterMakerActorsRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {
    characterMakerActorGroupId,
    skinColorPatternId,
    storyId,
    aspectRatio,
    imageKey,
  } = route.params;
  const onSelectActorCharacter = React.useCallback(
    (actorCharacter: ActorCharacter) => {
      navigation.navigate('CoverImageFormCharacterFormCharacterNames', {
        storyId,
        aspectRatio,
        imageKey,
        actorCharacterId: actorCharacter.id,
      });
    },
    [],
  );
  return (
    <BaseIndex
      navigation={navigation}
      characterMakerActorGroupId={characterMakerActorGroupId}
      skinColorPatternId={skinColorPatternId}
      onSelectActorCharacter={onSelectActorCharacter}
    />
  );
};

export default React.memo(Index);
