import EntityMapper from '../EntityMapper';

import HelpCategory from '../../../../domain/entities/writer/HelpCategory';

export interface HelpCategoryData {
  id: number;
  name: string;
}

export default class HelpCategoryMapper
  implements EntityMapper<HelpCategoryData, HelpCategory>
{
  public map(obj: HelpCategoryData): HelpCategory {
    return new HelpCategory(obj.id, obj.name);
  }
}
