import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import ServiceExplanation from './partials/service_explanation/ServiceExplanation';
import OfferList from './partials/OfferList';
import NoOffers from './partials/NoOffers';
import SearchOffersButton from './partials/SearchOffersButton';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import Offer from '../../../../domain/entities/writers_gate/Offer';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  showCurrentUser: () => Promise<CurrentUser>;
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, showCurrentUser, showCurrentUserStatus} = props;
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | null>(
    null,
  );
  const [currentUserStatus, setCurrentUserStatus] =
    React.useState<CurrentUserStatus | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState('ライターズゲート');
  const [offers, setOffers] = React.useState<Offer[] | null>(null);
  const onForceUpdate = React.useCallback(() => {
    TapNovelTypedRestApi.get<Offer[]>('/api/writer/writers_gate/offers')
      .then(result => {
        setOffers(result.body);
      })
      .catch(error => {
        if (error.status === 403) {
          navigation.navigate('Home', {});
        }
      });
  }, []);
  useFocusEffect(onForceUpdate);
  React.useEffect(() => {
    showCurrentUser().then(currentUser => {
      setCurrentUser(currentUser);
    });
    showCurrentUserStatus().then(currentUserStatus => {
      setCurrentUserStatus(currentUserStatus);
    });
  }, []);
  const onAgreeServicePolicy = React.useCallback(() => {
    setLoading(true);
    TapNovelTypedRestApi.post('/api/writer/user_service_agreements', {
      writer_user_service_agreement: {
        writer_service_agreement_code: 'writers_gate',
      },
    })
      .then(response => {
        setTimeout(() => {
          showCurrentUserStatus().then(setCurrentUserStatus);
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const agreed =
    currentUser?.grade && currentUserStatus
      ? currentUserStatus.serviceAgreementCodes.includes('writers_gate')
      : false;
  useFocusEffect(
    React.useCallback(() => {
      if (agreed) {
        return;
      }
      showCurrentUserStatus().then(currentUserStatus => {
        setCurrentUserStatus(currentUserStatus);
      });
    }, [agreed]),
  );
  React.useEffect(() => {
    if (agreed) {
      setTitle('オファー一覧');
    }
  }, [agreed]);
  const joinedPartnerProgram =
    currentUser?.grade && currentUserStatus
      ? currentUserStatus.serviceAgreementCodes.includes('partner_program')
      : false;
  return (
    <Layout
      title={title}
      navigation={navigation}
      back={true}
      loading={loading}
      scrollable={
        currentUser && currentUserStatus
          ? !agreed
            ? false
            : offers && offers.length > 0
            ? true
            : false
          : false
      }
      containerStyle={{paddingBottom: 100}}
      footer={agreed && offers ? <SearchOffersButton /> : null}>
      {currentUser && currentUserStatus ? (
        agreed ? (
          offers ? (
            offers.length > 0 ? (
              <OfferList
                offers={offers}
                wrriterUserId={currentUser.writerUserId}
                onForceUpdate={onForceUpdate}
              />
            ) : (
              <NoOffers />
            )
          ) : null
        ) : (
          <ServiceExplanation
            setTitle={setTitle}
            joinedPartnerProgram={joinedPartnerProgram}
            onAgreeServicePolicy={onAgreeServicePolicy}
          />
        )
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
