import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/background_show_scene_command/new/background_usage_histories/Index';

import AppState from '../../../../../reducers/AppState';

import indexBackgroundUsageHistories, {
  Params as BackgroundUsageHistoryIndexParams,
} from '../../../../../actions/background_usage_histories/index';

import selectEntities from '../../../../../helpers/selectEntities';
import {enabledResourceFavorite} from '../../../../../helpers/ResourceAuthorizattion';
import {SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistoriesRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistoriesRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const sceneForm = forms.sceneForm;
  const {storyId} = route.params;
  const backgroundUsageHistoryParams: BackgroundUsageHistoryIndexParams = {
    storyId,
  };
  const backgroundUsageHistories = selectEntities(
    entities.backgroundUsageHistories,
    queries.backgroundUsageHistories,
    backgroundUsageHistoryParams,
  );
  return {
    sceneForm,
    backgroundUsageHistories,
    backgroundUsageHistoryParams,
    navigation,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexBackgroundUsageHistories: (
      params: BackgroundUsageHistoryIndexParams,
    ) => dispatch(indexBackgroundUsageHistories(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
