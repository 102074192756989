import * as React from 'react';
import {StyleSheet, TouchableOpacity, View, ViewStyle} from 'react-native';

import ClearIcon from './icons/ClearIcon';

interface Props {
  onPress?: () => void;
  children?: React.ReactNode;
}

const TagRmoveButton: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={styles.container}>
        <ClearIcon />
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(TagRmoveButton);

const styles = StyleSheet.create({
  container: {
    padding: 6,
  } as ViewStyle,
});
