import * as React from 'react';

import Layout from '../../../../shared/Layout';
import SearchResult from '../../../../shared/scene_form/SearchResult';
import ResourceFavoriteButton from '../../../../shared/ResourceFavoriteButton';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormIllustrationShowSceneCommandNewIllustrationsRouteProp,
  SceneFormIllustrationShowSceneCommandEditIllustrationsRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as IllustrationIndexParams} from '../../../../../actions/illustrations/index';

import {illustrationImageUrl} from '../../../../../helpers/images';
import {getNextPage} from '../../../../../helpers/selectEntities';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import {QueryState} from '../../../../../reducers/queries/Response';

import Illustration from '../../../../../../domain/entities/Illustration';
import IllustrationCategory from '../../../../../../domain/entities/IllustrationCategory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  episodeId?: number;
  sceneId?: number;
  illustrationCategoryId: number;
  selectedLargeIllustrationCategoryId?: number | null;
  selectedMiddleIllustrationCategoryId?: number | null;
  selectedSmallIllustrationCategoryId?: number | null;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormIllustrationShowSceneCommandNewIllustrationsRouteProp
    | SceneFormIllustrationShowSceneCommandEditIllustrationsRouteProp;
  sceneForm: SceneForm | null;
  illustrationsParams: IllustrationIndexParams;
  illustrations: Illustration[] | null;
  illustrationCategory: IllustrationCategory | null;
  illustrationQueries: QueryState;
  totalCount: number | null;
  sort?: 'popularity' | 'new_arrival';
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexIllustrations: (
    params: IllustrationIndexParams,
  ) => Promise<PaginatedResult<Illustration>>;
  showIllustrationCategory: (id: number) => Promise<IllustrationCategory>;
  onSelectIllustration: (illustration: Illustration) => void;
  onPressSearchConditionChangeButton: () => void;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    this.fetchEntities();
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {route} = this.props;
    const {route: prevRoute} = prevProps;
    const {query, illustrationCategoryId, sort} = route.params;
    const {
      query: prevQuery,
      illustrationCategoryId: prevIllustrationCategoryId,
      sort: prevSort,
    } = prevRoute.params;
    if (
      query === prevQuery &&
      illustrationCategoryId === prevIllustrationCategoryId &&
      sort === prevSort
    ) {
      return;
    }
    this.fetchEntities();
  }

  public render(): React.ReactNode {
    const {
      navigation,
      route,
      illustrations,
      sort,
      totalCount,
      onSelectIllustration,
      onPressSearchConditionChangeButton,
    } = this.props;
    const {favorite} = route.params;
    return (
      <Layout
        title={`${favorite ? 'お気に入り' : ''}アイテム`}
        navigation={navigation}
        back={true}
        close={true}
        scrollable={false}>
        <SearchResult
          entities={illustrations}
          totalCount={totalCount}
          sort={sort}
          title={this.getTitle()}
          aspectRatio={aspectRatio}
          favorite={favorite}
          resourceName={'アイテム'}
          onPressSearchConditionChangeButton={
            onPressSearchConditionChangeButton
          }
          onSelectEntity={onSelectIllustration}
          onPressSort={this.handlePressSort}
          onEndReached={this.handleEndReached}
          imageUrlExtractor={this.imageUrlExtractor}
          modalRenderFavoriteButton={this.modalRenderFavoriteButton}
        />
      </Layout>
    );
  }

  private fetchEntities = () => {
    const {
      route,
      illustrationsParams,
      illustrationCategory,
      illustrations,
      showIllustrationCategory,
      indexIllustrations,
    } = this.props;
    const {illustrationCategoryId, favorite} = route.params;
    if (!illustrationCategory && illustrationCategoryId) {
      showIllustrationCategory(illustrationCategoryId);
    }
    if (!illustrations || favorite) {
      indexIllustrations(illustrationsParams);
    }
  };

  private imageUrlExtractor = (illustration: Illustration, width: number) => {
    return illustrationImageUrl(illustration, 'middle');
  };

  private modalRenderFavoriteButton = (illustration: Illustration) => {
    const {enabledFavorite} = this.props;
    if (!enabledFavorite) {
      return null;
    }
    return (
      <ResourceFavoriteButton
        style={{top: 55, right: 10}}
        resourceType={'Illustration'}
        resourceId={illustration.id}
      />
    );
  };

  private getTitle = () => {
    const ary: Array<string> = [];
    const {route, illustrationCategory} = this.props;
    const {query, favorite} = route.params;
    if (favorite) {
      ary.push('お気に入り');
    }
    if (query) {
      ary.push(query);
    }
    if (illustrationCategory) {
      ary.push(illustrationCategory.name);
    }
    return ary.join(' / ');
  };

  private handleEndReached = (info: {distanceFromEnd: number}): void => {
    const {illustrationQueries, illustrationsParams, indexIllustrations} =
      this.props;
    const nextPage = getNextPage(illustrationQueries, illustrationsParams);
    if (nextPage) {
      indexIllustrations({...illustrationsParams, page: nextPage});
    }
  };

  private handlePressSort = (sort: 'popularity' | 'new_arrival') => {
    const {navigation} = this.props;
    navigation.setParams({sort});
  };
}

const aspectRatio = 112 / 90;
