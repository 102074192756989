import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

const updatePoint = (
  width: number,
  dataLength: number,
  offsetX: number,
  setPoint: (point: {top: number; left: number; commandIndex: number}) => void,
) => {
  if (offsetX >= 0 && offsetX <= width - 60) {
    const commandIndex = Math.floor((offsetX / (width - 60)) * dataLength);
    if (0 <= commandIndex && commandIndex <= dataLength - 1) {
      setPoint({
        top: 0,
        left: 45 + offsetX,
        commandIndex,
      });
    }
  }
};

interface Props {
  width: number;
  dataLength: number;
  setPoint: (point: {top: number; left: number; commandIndex: number}) => void;
}

const DraggableView = (props: Props) => {
  const {width, dataLength, setPoint} = props;
  const onTouchMove = React.useCallback((e: any) => {
    const offsetX = e.nativeEvent.touches[0].pageX - 60;
    updatePoint(width, dataLength, offsetX, setPoint);
  }, []);
  const onMouseMove = React.useCallback((e: any) => {
    const offsetX = e.nativeEvent.offsetX;
    updatePoint(width, dataLength, offsetX, setPoint);
  }, []);
  return (
    <View style={[styles.container, {width: width - 60}]}>
      <View style={styles.inner} onTouchMove={onTouchMove} {...{onMouseMove}} />
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(DraggableView, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 45,
    top: 10,
    height: 210,
    opacity: 0.5,
  } as ViewStyle,
  inner: {
    width: '100%',
    height: '100%',
  } as ViewStyle,
});
