import {Dispatch} from 'redux';

import ForbiddenWordValidationResult from '../../../domain/value_objects/ForbiddenWordValidationResult';

import NetForbiddenWordValidationResultsRepository from '../../../data/repositories/writer/NetForbiddenWordValidationResultsRepository';

export default function action(
  id: string,
): (dispatch: Dispatch<any>) => Promise<ForbiddenWordValidationResult> {
  return async (
    dispatch: Dispatch<any>,
  ): Promise<ForbiddenWordValidationResult> => {
    const repository = new NetForbiddenWordValidationResultsRepository();
    const payload = await repository.find(id);
    return payload;
  };
}
