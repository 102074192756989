import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import GridList from './GridList';

import AccountAggregation from '../../../../../domain/value_objects/summary/AccountAggregation';

interface Props {
  accountAggregation: AccountAggregation;
}

const WriterSummary: React.FunctionComponent<Props> = props => {
  const {accountAggregation} = props;
  return (
    <View style={styles.container}>
      <GridList
        items={[
          {
            label: '公開中のストーリー',
            value: accountAggregation.publishedStoriesCount,
          },
          {
            label: 'ファン登録数',
            value: accountAggregation.fannedConsumerUserCount,
          },
        ]}
      />
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(WriterSummary, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    backgroundColor: '#fafafa',
  } as ViewStyle,
});
