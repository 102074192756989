import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import EpisodeListItem from './EpisodeListItem';

import shouldUpdateEntityWithUpdatedAtList from '../../../shared/enhanced/shouldUpdateEntityWithUpdatedAtList';
import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Episode from '../../../../../domain/entities/response/Episode';

interface Props {
  episodes: Episode[];
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  onSelectEpisode: (episode: Episode) => void;
}

const EpisodeList: React.FunctionComponent<Props> = props => {
  const {episodes, ListHeaderComponent} = props;
  const keyExtractor = React.useCallback(
    (episode: Episode, index: number): string => `${episode.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Episode>): React.ReactElement<any> | null => {
      const {item: episode} = info;
      const {onSelectEpisode} = props;
      return (
        <EpisodeListItem episode={episode} onSelectEpisode={onSelectEpisode} />
      );
    },
    [],
  );
  return (
    <FlatList
      data={episodes}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
      onEndReachedThreshold={0.1}
    />
  );
};

const propsAreEqual = (
  prevProps: Readonly<Props>,
  nextProps: Readonly<Props>,
) => {
  return !shouldUpdateEntityWithUpdatedAtList(
    {entities: prevProps.episodes},
    {entities: nextProps.episodes},
  );
};

export default React.memo(EpisodeList, propsAreEqual);
