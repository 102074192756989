import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

export interface Params {
  options: {
    message: string | null;
    title?: string;
    action?: {
      label: string;
      callback: () => void;
    };
  } | null;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>, getState: () => AppState) => void {
  return (dispatch: Dispatch<any>, getState: () => AppState): void => {
    if (params.options) {
      dispatch({payload: params.options, type: Types.UpdateApplicationModal});
    } else {
      dispatch({payload: null, type: Types.UpdateApplicationModal});
    }
  };
}
