import EntityMapper from '../EntityMapper';

import ActorHairStyle from '../../../../domain/entities/character_maker/ActorHairStyle';

export interface ActorHairStyleData {
  id: number;
  actor_id: number;
  name: string;
  color_pattern_id: number;
  color_pattern_name: string;
  original_image_url: string;
  no_hair_color: boolean;
}

export default class ActorHairStyleMapper
  implements EntityMapper<ActorHairStyleData, ActorHairStyle>
{
  public map(obj: ActorHairStyleData): ActorHairStyle {
    return new ActorHairStyle(
      obj.id,
      obj.actor_id,
      obj.name,
      obj.color_pattern_id,
      obj.color_pattern_name,
      obj.original_image_url,
      obj.no_hair_color,
    );
  }
}
