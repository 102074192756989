import * as React from 'react';

import BaseIndex from '../../../shared/generics/character_form/default_character_patterns/Index';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CoverImageFormCharacterFormDefaultCharacterPatternsRouteProp} from '../../../../navigators/RouteProps';

import {Params as ActorCostumeIndexParams} from '../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessoryIndexParams} from '../../../../actions/actor_accessories/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../actions/actor_accessory_sets/index';

import redirectStoryCoverImageForm from '../../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../../domain/forms/CoverImageForm';
import Actor from '../../../../../domain/entities/Actor';
import ActorCostume from '../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../domain/entities/ActorHairStyle';
import ActorAccessory from '../../../../../domain/entities/ActorAccessory';
import ActorAccessorySet from '../../../../../domain/entities/ActorAccessorySet';
import ActorCharacter from '../../../../../domain/entities/ActorCharacter';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  actorId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormCharacterFormDefaultCharacterPatternsRouteProp;
  coverImageForm: CoverImageForm | null;
  actor: Actor | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessories: (
    params: ActorAccessoryIndexParams,
  ) => Promise<PaginatedResult<ActorAccessory>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        actorId={this.props.route.params.actorId}
        onSelectActorCharacter={this.handleSelectActorCharacter}
      />
    );
  }

  private handleSelectActorCharacter = (actorCharacter: ActorCharacter) => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;
    const actorCharacterId = actorCharacter.id;
    navigation.navigate('CoverImageFormCharacterFormCharacterNames', {
      storyId,
      aspectRatio,
      imageKey,
      actorCharacterId,
    });
  };
}
