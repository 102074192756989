import * as React from 'react';
import {ScrollView} from 'react-native';

import NavigationProp from '../../../../../../navigators/NavigationProp';

import Layout from '../../../../../shared/Layout';

import FormStep1, {Form1Values} from './FormStep1';
import FormStep2Idea, {Form2ValuesIdea} from './FormStep2Idea';
import FormStep2Plot, {Form2ValuesPlot} from './FormStep2Plot';
import FormStep2Episode, {Form2ValuesEpisode} from './FormStep2Episode';

import DimensionContext from '../../../../../shared/dimension/DimensionContext';

export type FormValuesIdea = Form1Values & Form2ValuesIdea;
export type FormValuesPlot = Form1Values & Form2ValuesPlot;
export type FormValuesEpisode = Form1Values & Form2ValuesEpisode;
export type FormValues = FormValuesIdea | FormValuesPlot | FormValuesEpisode;

interface InnerProps {
  navigation: NavigationProp;
  width: number;
  projectEpisodeId?: number;
  onSubmit: (formValues: FormValues) => void;
}

const FormInnter: React.FC<InnerProps> = props => {
  const {navigation, width, projectEpisodeId, onSubmit} = props;
  const scrollViewRef = React.useRef<ScrollView | null>(null);
  const [form1Values, setForm1Values] = React.useState<Form1Values | null>(
    null,
  );
  const [step, setStep] = React.useState<0 | 1 | 2 | 3>(0);
  const preventDowbleClick = React.useRef(false);
  const goNext = React.useCallback(() => {
    if (preventDowbleClick.current) {
      return;
    }
    preventDowbleClick.current = true;
    setTimeout(() => {
      preventDowbleClick.current = false;
    }, 1000);
    const nextStep = step + 1;
    if (nextStep === 0 || nextStep === 1 || nextStep === 2 || nextStep === 3) {
      setStep(nextStep);
    }
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: width * nextStep});
    }
  }, [step]);
  const goPrev = React.useCallback(() => {
    if (preventDowbleClick.current) {
      return;
    }
    preventDowbleClick.current = true;
    setTimeout(() => {
      preventDowbleClick.current = false;
    }, 1000);
    const nextStep = step - 1;
    if (nextStep === 0 || nextStep === 1 || nextStep === 2 || nextStep === 3) {
      setStep(nextStep);
    }
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({x: width * nextStep});
    }
  }, [step]);

  const onSubmitForm1 = React.useCallback(
    (form1Values: Form1Values) => {
      setForm1Values(form1Values);
      goNext();
    },
    [step],
  );
  const onSubmitForm2 = React.useCallback(
    (form2Values: Form2ValuesIdea | Form2ValuesPlot | Form2ValuesEpisode) => {
      if (form1Values && form2Values) {
        onSubmit({
          ...form1Values,
          ...form2Values,
        });
      }
    },
    [step],
  );
  const style = React.useMemo(() => {
    return {width, flex: 1};
  }, [width]);
  return (
    <ScrollView
      ref={scrollViewRef}
      pagingEnabled={true}
      horizontal={true}
      scrollEnabled={false}>
      <Layout
        navigation={navigation}
        title={'エピソードフェーズ'}
        containerStyle={style}
        back={true}
        scrollable={false}>
        <FormStep1 values={{projectEpisodeId}} onSubmit={onSubmitForm1} />
      </Layout>
      <Layout
        navigation={navigation}
        title={`エピソード${
          form1Values?.typeCode === 'idea' ? 'アイデア' : 'プロット'
        }`}
        containerStyle={style}
        scrollable={false}
        back={true}
        onPressBack={goPrev}>
        {form1Values?.typeCode === 'idea' ? (
          <FormStep2Idea
            values={
              form1Values.projectManuscript
                ? {
                    title: form1Values.projectManuscript.title,
                    outlinePlan: form1Values.projectManuscript.outlinePlan,
                    highlightOfStory:
                      form1Values.projectManuscript.highlightOfStory,
                  }
                : undefined
            }
            onSubmit={onSubmitForm2}
          />
        ) : form1Values?.typeCode === 'plot' ? (
          <FormStep2Plot
            manuscriptNumber={form1Values.manuscriptNumber}
            values={
              form1Values.projectManuscript
                ? {
                    title: form1Values.projectManuscript.title,
                    plot: form1Values.projectManuscript.plot,
                    notes: form1Values.projectManuscript.notes,
                  }
                : undefined
            }
            onSubmit={onSubmitForm2}
          />
        ) : form1Values?.typeCode === 'episode' ? (
          <FormStep2Episode
            values={
              form1Values.projectManuscript
                ? {
                    title: form1Values.projectManuscript.title,
                  }
                : undefined
            }
            onSubmit={onSubmitForm2}
          />
        ) : null}
      </Layout>
    </ScrollView>
  );
};

interface Props {
  navigation: NavigationProp;
  projectEpisodeId?: number;
  onSubmit: (formValues: FormValues) => void;
}

const Form: React.FC<Props> = props => {
  return (
    <DimensionContext.Consumer>
      {context => {
        const width = context.content.width;
        return <FormInnter {...props} width={width} />;
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(Form);
