import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import Header from './table/Header';
import Body from './table/Body';
import {enabledPartnerProgram} from '../../../../helpers/features';

interface Props {
  bodyRows?: Array<{0: string; 1: string; 2: string}>;
  withoutNote?: boolean;
  label0Style?: StyleProp<TextStyle>;
  label1Style?: StyleProp<TextStyle>;
  label2Style?: StyleProp<TextStyle>;
}

const HEADER_ROW: {0: string; 1: string; 2: string} = [
  '',
  '無料プラン',
  'MakerPro',
];

const BODY_ROWS: Array<{0: string; 1: string; 2: string}> = [
  ['MakerPro限定\nキャラクターの利用', '×', '〇'],
  ['作品の動画化', '×', '〇'],
  ['キャラクターの\n肌の色変更', '×', '〇'],
  ['共同執筆機能', '×', '〇'],
  ['イラストの\nお気に入り登録', '×', '〇'],
  ['効果音の\nお気に入り登録', '×', '〇'],
  ['BGMの\nお気に入り登録', '×', '〇'],
  ['エフェクトの\nお気に入り登録', '×', '〇'],
  ['テキストデータの\nエクスポート', '×', '〇'],
  ['セリフへの\n効果音挿入', '×', '〇'],
  ['テキストへの\n効果音挿入', '×', '〇'],
  ['スチルへの\n効果音挿入', '×', '〇'],
  ['効果音の\n長さ調整', '×', '〇'],
  ['エフェクトの\n長さ調整', '×', '〇'],
  ['スチル挿入', '△', '〇'],
  ['効果音挿入', '△', '〇'],
  ['BGM挿入', '△', '〇'],
  ['ボイス挿入', '△', '〇'],
  ['CM動画作成', '△', '〇'],
];

if (enabledPartnerProgram) {
  BODY_ROWS.push(['パートナー\nプログラム', '△', '〇']);
}

const Table: React.FunctionComponent<Props> = props => {
  const {bodyRows, withoutNote, ...rest} = props;
  return (
    <View style={styles.container}>
      <Header labels={HEADER_ROW} />
      <Body rows={bodyRows || BODY_ROWS} {...rest} />
      {!withoutNote && <Text style={styles.note}>△ 条件クリアで利用可</Text>}
    </View>
  );
};

export default React.memo(Table);

const styles = StyleSheet.create({
  container: {
    marginTop: 32,
    marginBottom: 24,
  } as ViewStyle,
  note: {
    color: '#383838',
    fontSize: 12,
    textAlign: 'right',
    marginTop: 8,
  } as TextStyle,
});
