import {Dispatch} from 'redux';

import Types from '../../../../Types';

import NetRegistrationRepository from '../../../../../../data/repositories/writer/social_accounts/twitter_accounts/NetRegistrationRepository';

export default function action(
  id: number,
): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      await new NetRegistrationRepository().destroy();
      const payload = id;
      dispatch({
        payload,
        type: Types.DestroyWriterOauthAccountSuccess,
      });
      return;
    } catch (err) {
      dispatch({type: Types.DestroyWriterOauthAccountFailure});
      throw err;
    }
  };
}
