import Entity from '../Entity';

import CurrentUserProfile from './CurrentUserProfile';
import Grade from './Grade';

export type PaymentMethod = 'app_store' | 'play_store' | 'stripe';

export default class CurrentUser implements Entity {
  constructor(
    public id: number,
    public penName: string,
    public email: string,
    public unconfirmedEmail: string | null,
    public confirmed: boolean,
    public enabled: boolean,
    public paidSubscriberId: number | null,
    public enabledPaidSubscriber: boolean,
    public usingPaymentMethod: PaymentMethod | null,
    public paidSubscriberCancelAt: Date | null,
    public acceptedTerms: boolean,
    public writerUserId: number,
    public writerGradeId: number,
    public enabledConsumerUser: boolean,
    public communityMemberStatus:
      | 'unsupported'
      | 'joined_fb_group'
      | 'canceled',
    public accessToken: string | null,
    public userProfile: CurrentUserProfile | null,
    public grade: Grade | null,
  ) {}

  public getDisplayableEmail(): string {
    const str = this.unconfirmedEmail || this.email;
    const ary = str.split('@');
    const localPart = ary[0];
    const displayCharNum =
      localPart.length >= 4
        ? 3
        : localPart.length > 1
        ? localPart.length - 1
        : 1;
    const displayLocalPart = `${localPart.slice(0, displayCharNum)}${'*'.repeat(
      localPart.length - displayCharNum,
    )}`;
    const domain = ary[1];
    return `${displayLocalPart}@${domain}`;
  }

  public verifiedEmailAddress(): boolean {
    if (!this.email) {
      return false;
    }
    if (this.unconfirmedEmail && this.unconfirmedEmail.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  public isNotGradeBlack(): boolean {
    return this.writerGradeId !== 12;
  }

  public isGradeBlack(): boolean {
    return this.writerGradeId === 12;
  }

  public isActivated(): boolean {
    return this.enabled && this.confirmed;
  }

  public isBlackOrSubscriber(): boolean {
    return this.isGradeBlack() || this.enabledPaidSubscriber;
  }
}
