import * as React from 'react';
import {Platform, StyleSheet} from 'react-native';
import SortableList, {RowProps} from 'react-native-sortable-list';
import {shallowEqual} from 'recompose';

import SceneListItemContent from './SceneListItemContent';
import NoScenes from './NoScenes';

import shouldUpdateSceneList from '../../shared/enhanced/shouldUpdateSceneList';

import {backgroundImageUrl} from '../../../helpers/images';

import {baseStyleWidth} from '../../../styles/variables';

import Scene, {Filter} from '../../../../domain/entities/Scene';

import GLCapturedSequentialImages from '../../../../vendor/react-native-tapnovel-viewer/presentation/components/shared/GLCapturedSequentialImages';

interface Props {
  scenes: Scene[];
  idToCopyLabel?: {[key: number]: string};
  contentWidth: number;
  renderHeader?: () => React.ReactElement;
  renderFooter?: () => React.ReactElement;
  onSelectScene: (scene: Scene) => void;
  onOpenActionSheet: (scene: Scene) => void;
  onChangeOrder: (scene: Scene, rowOrderPosition: number) => void;
}

interface State {
  scenes: Scene[];
  order: number[];
  sorting: boolean;
  keyToBase64: {[key: string]: string} | null;
}

export default class SceneList extends React.Component<Props, State> {
  private mounted = false;

  private ref = React.createRef<SortableList<Scene, number>>();

  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    const nextOrder = SceneList.getOrder(nextProps.scenes);
    if (shouldUpdateSceneList(nextProps, prevState)) {
      return {order: nextOrder, scenes: nextProps.scenes};
    }
    return null;
  }

  private static getOrder = (scenes: Scene[]) => {
    return scenes.map(scene => scene.id);
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      scenes: props.scenes,
      order: this.getOrder(props.scenes),
      sorting: false,
      keyToBase64: null,
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const {scenes} = this.props;
    if (
      Object.keys(prevProps.idToCopyLabel || {}).length <
      Object.keys(this.props.idToCopyLabel || {}).length
    ) {
      setTimeout(() => {
        try {
          const lastScene = scenes[scenes.length - 1];
          if (lastScene) {
            this.ref.current?.scrollToRowKey({
              key: lastScene.id,
              animated: true,
            });
          }
        } catch (e) {
          console.log(e);
        }
      }, 300);
    }
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {scenes, contentWidth, renderHeader, renderFooter} = this.props;
    const {order} = this.state;
    const imageWidth = contentWidth * (323 / baseStyleWidth);
    const imageHeight = imageWidth * (111 / 323);
    if (scenes.length === 0) {
      return (
        <>
          {renderHeader && renderHeader()}
          <NoScenes />
          {renderFooter && renderFooter()}
        </>
      );
    }
    return (
      <>
        <SortableList
          ref={this.ref}
          style={styles.container}
          contentContainerStyle={styles.contentContainerStyle}
          innerContainerStyle={styles.innerContainerStyle}
          scrollEnabled={true}
          data={this.getData()}
          order={order}
          renderHeader={renderHeader}
          renderFooter={renderFooter}
          renderRow={this.renderCustom}
          onPressRow={this.handlePressRow}
          onActivateRow={this.handleActivateRow}
          onChangeOrder={this.handleChangeOrder}
          onReleaseRow={this.handleReleaseRow}
        />
        <GLCapturedSequentialImages
          key={JSON.stringify(this.filterItems())}
          size={{height: imageHeight, width: imageWidth}}
          items={this.filterItems()}
          onLoad={keyToBase64 => {
            this.setState({keyToBase64});
          }}
        />
      </>
    );
  }

  private filterItems = () => {
    const {scenes} = this.props;
    const ret: Array<{key: string; uri: string; filter: Filter}> = [];
    scenes.forEach(scene => {
      if (
        scene.background &&
        scene.options?.filter &&
        scene.options.filter !== 'normal'
      ) {
        ret.push({
          key: `${scene.id}`,
          uri: backgroundImageUrl(scene.background, 'large'),
          filter: scene.options?.filter,
        });
      }
    });
    return ret;
  };

  private getData = (): {[key: number]: Scene} => {
    const {scenes} = this.props;
    const data: {[key: number]: Scene} = {};
    scenes.forEach(scene => {
      data[scene.id] = scene;
    });
    return data;
  };

  private renderCustom = (props: RowProps) => {
    const {data, active} = props;
    const {idToCopyLabel, contentWidth, onOpenActionSheet} = this.props;
    const {keyToBase64} = this.state;
    return (
      <SceneListItemContent
        scene={data}
        contentWidth={contentWidth}
        copyLabel={idToCopyLabel ? idToCopyLabel[data.id] : undefined}
        active={active}
        sourceUri={keyToBase64 ? keyToBase64[`${data.id}`] : undefined}
        onOpenActionSheet={onOpenActionSheet}
      />
    );
  };

  private handlePressRow = (key: any) => {
    const {onSelectScene} = this.props;
    const scene = this.fetchSceneFromKey(key);
    if (scene) {
      onSelectScene(scene);
    }
  };

  private handleActivateRow = (key: number) => {
    this.setStateIfMounted({sorting: true});
  };

  private handleChangeOrder = (nextOrder: number[]) => {
    this.setStateIfMounted({order: nextOrder});
  };

  private handleReleaseRow = (key: number) => {
    const {scenes, onChangeOrder} = this.props;
    const {order} = this.state;
    const scene = this.fetchSceneFromKey(key);
    if (!order || !scene || shallowEqual(order, this.getOrder(scenes))) {
      this.setStateIfMounted({sorting: false});
      return;
    }
    const rowOrderPosition = order.map(o => Number(o)).indexOf(Number(key));
    this.setStateIfMounted({sorting: false}, () => {
      onChangeOrder(scene, rowOrderPosition);
    });
  };

  private fetchSceneFromKey = (key: number): Scene | null => {
    const {scenes} = this.props;
    const id = Number(key);
    const scene = scenes.find(row => row.id === id) || null;
    return scene;
  };

  private getOrder = (scenes: Scene[]) => {
    return SceneList.getOrder(scenes);
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ): void {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fafafa',
  },
  contentContainerStyle: {
    display: Platform.select({
      web: 'block',
      default: undefined,
    }) as any,
  },
  innerContainerStyle: {
    marginVertical: 8,
  },
});
