import Episode from '../../../../domain/entities/Episode';

interface Props {
  episode: Episode | null;
}

export default (current: Props, next: Props) => {
  if (current.episode === next.episode) {
    return false;
  }
  if (!current.episode || !next.episode) {
    return true;
  }
  if (current.episode.id !== next.episode.id) {
    return true;
  }
  if (
    current.episode.updatedAt.getTime() !== next.episode.updatedAt.getTime()
  ) {
    return true;
  }
  return false;
};
