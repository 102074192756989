import * as React from 'react';
import {
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import Input from '../../../shared/forms/Input';
import TextArea from '../../../shared/forms/TextArea';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import AlertModal from '../../../shared/modals/AlertModal';
import TooltipModal from '../../../shared/modals/TooltipModal';
import CheckButton from '../../../shared/forms/CheckButton';

import convertImageSource from '../../../../helpers/convertImageSource';
import {colors, formStyles} from '../../../../styles/variables';

import Project from '../../../../../domain/entities/Project';

import episodeCommentUri from '../../../../../assets/images/tips/episode_comment.png';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

export type FormValues = {
  title: string;
  writerUserEpisodeCommentId?: number;
  writerUserEpisodeCommentComment: string;
};

type FormErrorMessage = {[P in keyof FormValues]?: string | string[]};

const TypeToTooltipInfo = {
  writerUserEpisodeCommentComment: {
    title: 'タップライターコメント',
    description:
      'ストーリーを読み終えたあとに表示されるコメントとなります。\n読者に向けたメッセージを記入しましょう。',
    source: convertImageSource(episodeCommentUri),
    imageStyle: {
      width: 279,
      height: 160,
    },
  },
} as {
  [key in keyof FormValues]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

interface Props {
  values?: Partial<FormValues>;
  errorMessages?: FormErrorMessage;
  project: Project;
  onSubmit: (values: FormValues) => void;
}

const FormNew: React.FC<Props> = props => {
  const {values, errorMessages, project, onSubmit} = props;
  const [title, setTitle] = React.useState(values?.title || '');
  const [writerUserEpisodeCommentComment, setWriterUserEpisodeCommentComment] =
    React.useState(values?.writerUserEpisodeCommentComment || '');
  const [tooltipModalType, setTooltipModalType] = React.useState<
    keyof FormValues | null
  >(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [checked3, setChecked3] = React.useState(false);
  const [checked4, setChecked4] = React.useState(false);
  const [checked5, setChecked5] = React.useState(false);
  const [checked6, setChecked6] = React.useState(false);
  const onPressQuestionWriterUserEpisodeCommentComment =
    React.useCallback(() => {
      setTooltipModalType('writerUserEpisodeCommentComment');
    }, []);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const onCloseModalTooltip = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onPress = () => {
    const writerUserEpisodeCommentId = values?.writerUserEpisodeCommentId;
    onSubmit({
      title,
      writerUserEpisodeCommentComment,
      writerUserEpisodeCommentId,
    });
  };
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  const disabled =
    !checked1 ||
    !checked2 ||
    (project.secondaryUse === 'only_uncommercial_personal_use' && !checked3) ||
    (project.allowedChangeOnSecondaryUse && !checked4) ||
    !checked5 ||
    !checked6;
  return (
    <View style={styles.container}>
      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'エピソードタイトル（最大40文字）'}
          maxLength={40}
          length={title.length}
          requiredOrOptional={'required'}
          entered={title.length > 0 && title.length <= 40}
          errorMessages={errorMessages?.title}
        />
        <Input
          style={[
            {marginBottom: 3, marginTop: 8},
            title.length === 0
              ? requiredInputStyle
              : title.length <= 40
              ? undefined
              : styles.inputError,
          ]}
          value={title}
          placeholder={'エピソードタイトルを入力'}
          onChangeText={setTitle}
        />
      </View>
      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'タップライターコメント（最大200文字）'}
          length={writerUserEpisodeCommentComment.length}
          maxLength={200}
          requiredOrOptional={'required'}
          entered={
            writerUserEpisodeCommentComment.length > 0 &&
            writerUserEpisodeCommentComment.length <= 200
          }
          errorMessages={errorMessages?.writerUserEpisodeCommentComment}
          onPressQuestion={onPressQuestionWriterUserEpisodeCommentComment}
        />
        <TextArea
          style={[
            styles.textArea,
            writerUserEpisodeCommentComment.length === 0
              ? requiredInputStyle
              : writerUserEpisodeCommentComment.length <= 200
              ? undefined
              : styles.inputError,
          ]}
          containerStyle={[
            writerUserEpisodeCommentComment ? undefined : requiredInputStyle,
            {marginBottom: 8},
          ]}
          value={writerUserEpisodeCommentComment}
          placeholder={'タップライターコメントを入力'}
          onChangeText={setWriterUserEpisodeCommentComment}
        />
        <View style={{alignItems: 'flex-end'}}>
          <Text style={{color: '#383838', fontSize: 10}}>
            ※エピソードを読み終えた後に表示されます
          </Text>
        </View>
      </View>
      <View style={{marginTop: 16}}>
        <Text
          style={{
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 'bold',
            marginBottom: 8,
          }}>
          本プロジェクトの権利について再度ご確認ください
        </Text>
        <CheckButton
          style={styles.checkBox}
          checked={checked1}
          onPress={() => setChecked1(!checked1)}>
          エピソードの権利はエピソードを投稿したメンバーが保有します
        </CheckButton>
        <CheckButton
          style={styles.checkBox}
          checked={checked2}
          onPress={() => setChecked2(!checked2)}>
          {project.secondaryUse === 'only_uncommercial_personal_use'
            ? '二次利用については非営利目的での個人利用は許可します'
            : '二次利用については許可しません'}
        </CheckButton>
        {project.secondaryUse === 'only_uncommercial_personal_use' ? (
          <CheckButton
            style={styles.checkBox}
            checked={checked3}
            onPress={() => setChecked3(!checked3)}>
            {project.allowedChangeOnSecondaryUse
              ? '二次利用時の改変を許可します'
              : '二次利用時の改変を許可しません'}
          </CheckButton>
        ) : null}
        {project.allowedChangeOnSecondaryUse ? (
          <CheckButton
            style={styles.checkBox}
            checked={checked4}
            onPress={() => setChecked4(!checked4)}>
            {project.requiredChangePermissionOnSecondaryUse
              ? '二次利用時の改変にあたって許可を必要とします（事前確認）'
              : '二次利用時の改変にあたって許可を不要とします（事後差し止めは可）'}
          </CheckButton>
        ) : null}
        <CheckButton
          style={styles.checkBox}
          checked={checked5}
          onPress={() => setChecked5(!checked5)}>
          {project.story.allowedVideoSharing
            ? '第三者による動画共有サービス設定を許可します'
            : '第三者による動画共有サービス設定を許可しません'}
        </CheckButton>
        <CheckButton
          style={styles.checkBox}
          checked={checked6}
          onPress={() => setChecked6(!checked6)}>
          トラブル防止のため営利利用の際の窓口はTapNovelが行います
        </CheckButton>
      </View>

      <View style={styles.button}>
        <Text style={{color: '#383838', fontSize: 10, marginBottom: 8}}>
          公開設定はリーダーが行います
        </Text>
        <PrimaryButton disabled={disabled} onPress={onPress}>
          エピソードを提出する
        </PrimaryButton>
      </View>
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
      {modalInfo ? (
        <TooltipModal
          visible={true}
          title={modalInfo.title}
          description={modalInfo.description}
          source={modalInfo.source}
          imageStyle={modalInfo.imageStyle}
          onCloseModal={onCloseModalTooltip}></TooltipModal>
      ) : null}
    </View>
  );
};

export default React.memo(FormNew);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
  checkBox: {
    marginVertical: 8,
    marginHorizontal: 32,
  } as ViewStyle,
});
