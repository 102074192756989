import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import numeral from 'numeral';

import EpisodeAggregation from '../../../../../domain/value_objects/partner_program/EpisodeAggregation';

interface Props {
  episodeAggregation: EpisodeAggregation;
}

const EpisodeAggregationListItem: React.FunctionComponent<Props> = props => {
  const {episodeAggregation} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{episodeAggregation.title}</Text>
      <View style={[styles.row, {marginBottom: 6}]}>
        <Text style={styles.rowLabel}>有効閲覧数</Text>
        <Text style={styles.rowValue}>
          {numeral(episodeAggregation.validViewsCount).format('0,0.0')}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowLabel}>ギフト</Text>
        <Text style={styles.rowValue}>
          {numeral(episodeAggregation.tipsAmount).format('0,0.0')}
        </Text>
      </View>
      <View style={styles.line} />
      <View style={styles.row}>
        <Text style={styles.rowLabel}>獲得ポイント</Text>
        <Text style={styles.rowValue}>
          {numeral(episodeAggregation.totalPoint).format('0,0.0')} pt
        </Text>
      </View>
    </View>
  );
};

export default React.memo(EpisodeAggregationListItem);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 16,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  rowLabel: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  rowValue: {
    color: '#ff8f13',
    fontSize: 11,
  } as TextStyle,
  line: {
    borderWidth: 1,
    borderColor: '#f5f5f5',
    marginVertical: 8,
  } as ViewStyle,
});
