import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import CharacterMapper, {
  CharacterData,
} from '../../entities/mappers/CharacterMapper';

import Character from '../../../domain/entities/Character';
import CharactersRepository from '../../../domain/repositories/writer/CharactersRepository';

export default class NetProjectCharactersRepository
  extends NetResourcesRepository<Character, CharacterData>
  implements CharactersRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/project_characters',
        new CharacterMapper(),
      ),
    );
  }
}
