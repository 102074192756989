import * as React from 'react';

import CharacterView from './CharacterView';
import Tabs from './Tabs';
import EntityList from './EntityList';
import MarkList from './MarkList';

import Layout from '../Layout';
import HeaderRightButton from '../buttons/HeaderRightButton';

import DimensionContext from '../dimension/DimensionContext';

import NavigationProp from '../../../navigators/NavigationProp';

import {Params as ActorCharacterFaceIndexParams} from '../../../actions/actor_character_faces/index';
import {Params as CharacterPatternCreateParams} from '../../../actions/character_patterns/create';

import scrollableLayout from '../../../helpers/scrollableLayout';

import NetActorFacesRepository from '../../../../data/repositories/writer/character_maker/NetActorFacesRepository';
import NetActorFacePartsRepository from '../../../../data/repositories/writer/character_maker/NetActorFacePartsRepository';
import NetActorCostumesRepository from '../../../../data/repositories/writer/character_maker/NetActorCostumesRepository';
import NetActorHairColorsRepository from '../../../../data/repositories/writer/character_maker/NetActorHairColorsRepository';
import NetActorEyesRepository from '../../../../data/repositories/writer/character_maker/NetActorEyesRepository';
import NetActorCharactersRepository from '../../../../data/repositories/writer/NetActorCharactersRepository';
import NetCharactersRepository from '../../../../data/repositories/writer/NetCharactersRepository';

import ActorFace from '../../../../domain/entities/character_maker/ActorFace';
import ActorFacePart from '../../../../domain/entities/character_maker/ActorFacePart';
import ActorEye from '../../../../domain/entities/character_maker/ActorEye';
import ActorCostume from '../../../../domain/entities/character_maker/ActorCostume';
import ActorHairColor from '../../../../domain/entities/character_maker/ActorHairColor';
import Character from '../../../../domain/entities/Character';
import CharacterPattern from '../../../../domain/entities/CharacterPattern';
import MetaCharacterPattern from '../../../../domain/entities/MetaCharacterPattern';
import ActorCharacterFace from '../../../../domain/entities/ActorCharacterFace';
import Mark from '../../../../domain/entities/Mark';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

const actorFacesRepository = new NetActorFacesRepository();
const actorFacePartsRepository = new NetActorFacePartsRepository();
const actorCostumesRepository = new NetActorCostumesRepository();
const actorHairColorsRepository = new NetActorHairColorsRepository();
const actorEyesRepository = new NetActorEyesRepository();
const actorCharactersRepository = new NetActorCharactersRepository();

type Value = 'face' | 'mark' | 'hair_color' | 'costume';

const TAB_DATA: Array<{value: Value; label: string}> = [
  {value: 'face', label: '表情'},
  {value: 'mark', label: 'アイコン'},
  {value: 'costume', label: '衣装'},
  {value: 'hair_color', label: '髪色'},
];

const TAB_DATA_WITHOUT_HAIR_COLOR: Array<{value: Value; label: string}> = [
  {value: 'face', label: '表情'},
  {value: 'mark', label: 'アイコン'},
  {value: 'costume', label: '衣装'},
];

interface Props {
  navigation: NavigationProp;
  characterId: number;
  metaCharacterPattern: MetaCharacterPattern;
  marks: Mark[] | null;
  hideTab?: boolean;
  onSelectActorCharacterFace: (
    characterPattern: CharacterPattern,
    actorCharacterFace: ActorCharacterFace,
    mark?: Mark | null,
  ) => void;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  createCharacterPattern: (
    params: CharacterPatternCreateParams,
  ) => Promise<CharacterPattern>;
}

const ActorCharacterBuilder: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    characterId,
    metaCharacterPattern,
    marks,
    hideTab,
    onSelectActorCharacterFace,
    indexActorCharacterFaces,
    createCharacterPattern,
  } = props;
  const {actorId} = metaCharacterPattern;
  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<Value>('face');

  const [character, setCharacter] = React.useState<Character | null>(null);
  const [currentColorPatternId, setCurrentColorPatternId] = React.useState(
    metaCharacterPattern.colorPatternId,
  );
  const [currentHairStyleName, setCurrentHairStyleName] =
    React.useState<string>(metaCharacterPattern.hairStyleName);
  const [currentFacePatternId, setCurrentFacePatternId] = React.useState(
    metaCharacterPattern.facePatternId,
  );
  const [currentEyeColorPatternId, setCurrentEyeColorPatternId] =
    React.useState(metaCharacterPattern.eyeColorPatternId);
  const [currentCostumeName, setCurrentCostumeName] = React.useState<
    string | null
  >(metaCharacterPattern.costumeName);
  const [currentMark, setCurrentMark] = React.useState<Mark>(new Mark(0, ''));

  const [actorHairColors, setActorHairColors] = React.useState<
    ActorHairColor[] | null
  >(null);
  const [actorFaceParts, setActorFaceParts] = React.useState<
    ActorFacePart[] | null
  >(null);
  const [actorFaces, setActorFaces] = React.useState<ActorFace[] | null>(null);
  const [actorEyes, setActorEyes] = React.useState<ActorEye[] | null>(null);
  const [actorCostumes, setActorCostumes] = React.useState<
    ActorCostume[] | null
  >(null);

  const updateActorHairColors = (
    actorId: number,
    hairStyleName: string,
    callback: () => void,
  ) => {
    actorHairColorsRepository
      .findBy({actorId, name: hairStyleName, sort: 'hair_color'})
      .then(actorHairColorsResult => {
        setActorHairColors(actorHairColorsResult.records);
        callback();
      });
  };

  const updateActorFaces = () => {
    actorFacesRepository
      .findBy({
        actorId,
        facePatternId: metaCharacterPattern.facePatternId,
      })
      .then(actorFacesResult => {
        setActorFaces(actorFacesResult.records);
      });
  };

  const updateActorCostumes = (actorId: number) => {
    actorCostumesRepository.findBy({actorId}).then(actorCostumesResult => {
      setActorCostumes(actorCostumesResult.records);
      const selectActorCostume = actorCostumesResult.records[0];
      if (!selectActorCostume) {
        return;
      }
      if (!currentCostumeName) {
        setCurrentCostumeName(selectActorCostume.name);
      }
    });
  };

  const updateActorEyes = (actorId: number, callback: () => void) => {
    actorEyesRepository
      .findBy({actorId, eyeColorPatternId: currentEyeColorPatternId})
      .then(actorEyesResult => {
        setActorEyes(actorEyesResult.records);
        callback();
      });
  };

  const updateEntities = (actorFacePart: ActorFacePart) => {
    const actorId = actorFacePart.actorId;
    updateActorFaces();
    updateActorEyes(actorId, () => {});
    updateActorHairColors(actorId, currentHairStyleName, () => {});
    updateActorCostumes(actorId);
  };

  const updateActorFaceParts = (
    colorPatternId: number,
    callback?: (actorFacePart: ActorFacePart) => void,
  ) => {
    actorFacePartsRepository
      .findBy({
        actorId,
        colorPatternId,
        sort: 'face_pattern_id',
      })
      .then(actorFacePartsResult => {
        setActorFaceParts(actorFacePartsResult.records);
        const selectActorFacePart =
          actorFacePartsResult.records.find(
            actorFacePart =>
              actorFacePart.actorId === currentActorFacePart?.actorId,
          ) || actorFacePartsResult.records[0];
        if (!selectActorFacePart) {
          return;
        }
        callback && callback(selectActorFacePart);
      });
  };

  React.useEffect(() => {
    new NetCharactersRepository().find(characterId).then(character => {
      setCharacter(character);
    });
    updateActorFaceParts(currentColorPatternId, actorFacePart =>
      updateEntities(actorFacePart),
    );
  }, []);

  const onChangeTab = React.useCallback((value: Value) => {
    setActiveTab(value);
  }, []);
  const onSelectActorFacePart = React.useCallback(
    (actorFacePart: ActorFacePart) => {
      setCurrentFacePatternId(actorFacePart.facePatternId);
    },
    [],
  );
  const onSelectMark = React.useCallback((mark: Mark) => {
    setCurrentMark(mark);
  }, []);
  const onSelectActorCostume = React.useCallback(
    (actorCostume: ActorCostume) => {
      setCurrentCostumeName(actorCostume.name);
    },
    [],
  );
  const onSelectActorHairColor = React.useCallback(
    (actorHairColor: ActorHairColor) => {
      setCurrentColorPatternId(actorHairColor.colorPatternId);
      updateActorFaceParts(actorHairColor.colorPatternId);
    },
    [],
  );
  const extractActorFacePartLabel = React.useCallback(
    (actorFacePart: ActorFacePart) => actorFacePart.facePatternName,
    [],
  );
  const extractFaceTopForegroundImageUris = React.useCallback(
    (actorFacePart: ActorFacePart) => {
      const foundActorEye = actorEyes?.find(
        actorEye => actorEye.facePatternId === actorFacePart.facePatternId,
      );
      return foundActorEye ? [foundActorEye.getOneThirdImageUrl()] : [];
    },
    [actorEyes],
  );
  const currentActorFacePart = React.useMemo(
    () =>
      actorFaceParts?.find(
        actorFacePart => actorFacePart.facePatternId === currentFacePatternId,
      ),
    [actorFaceParts, currentFacePatternId],
  );
  const currentActorHairColor = React.useMemo(
    () =>
      actorHairColors?.find(
        actorHairColor =>
          actorHairColor.colorPatternId === currentColorPatternId,
      ),
    [actorHairColors, currentColorPatternId],
  );
  const currentActorEye = React.useMemo(
    () =>
      actorEyes?.find(
        actorEye => actorEye.facePatternId === currentFacePatternId,
      ),
    [actorEyes, currentFacePatternId],
  );
  const currentActorCostume = React.useMemo(
    () =>
      actorCostumes?.find(
        actorCostume => actorCostume.name === currentCostumeName,
      ),
    [actorCostumes, currentCostumeName],
  );

  const defaultActorFacePart = React.useMemo(
    () =>
      actorFaceParts?.find(
        actorFacePart =>
          actorFacePart.facePatternId === metaCharacterPattern.facePatternId,
      ),
    [!!actorFaceParts, metaCharacterPattern],
  );
  const defaultActorEye = React.useMemo(
    () =>
      actorEyes?.find(
        actorEye =>
          actorEye.facePatternId === metaCharacterPattern.facePatternId,
      ),
    [!!actorEyes, metaCharacterPattern],
  );

  const extractHairColorTopForegroundImageUris = React.useCallback(
    (actorHairColor: ActorHairColor) => {
      const foundActorFace = actorFaces?.find(
        actorFacePart =>
          actorFacePart.colorPatternId === actorHairColor.colorPatternId,
      );
      return foundActorFace && defaultActorEye
        ? [
            foundActorFace.getOneThirdImageUrl(),
            defaultActorEye.getOneThirdImageUrl(),
          ]
        : [];
    },
    [actorFaces, defaultActorEye],
  );

  const faceBackgroundImageUris = React.useMemo(() => {
    return defaultActorFacePart && currentActorHairColor
      ? [
          defaultActorFacePart.getActorOneThirdImageUrl(),
          currentActorHairColor.getOneThirdImageUrl(),
        ]
      : undefined;
  }, [defaultActorFacePart, currentActorHairColor]);
  const faceForegroundImageUris = React.useMemo(() => {
    return currentActorCostume
      ? [currentActorCostume.getOneThirdImageUrl()]
      : undefined;
  }, [currentActorCostume]);

  const costumeBackgroundImageUris = React.useMemo(() => {
    return defaultActorFacePart && currentActorHairColor
      ? [
          defaultActorFacePart.getActorOneThirdImageUrl(),
          currentActorHairColor.getOneThirdImageUrl(),
        ]
      : undefined;
  }, [defaultActorFacePart, currentActorHairColor]);

  const costumeForegroundImageUris = React.useMemo(() => {
    return defaultActorFacePart && defaultActorEye
      ? [
          defaultActorFacePart.getOneThirdImageUrl(),
          defaultActorEye.getOneThirdImageUrl(),
        ]
      : undefined;
  }, [defaultActorFacePart, defaultActorEye]);

  const hairColorBackgroundImageUris = React.useMemo(() => {
    return defaultActorFacePart
      ? [defaultActorFacePart.getActorOneThirdImageUrl()]
      : undefined;
  }, [defaultActorFacePart]);

  const hairColorForegroundImageUris = React.useMemo(() => {
    return currentActorCostume
      ? [currentActorCostume.getOneThirdImageUrl()]
      : undefined;
  }, [currentActorCostume]);

  const handleSubmit = React.useCallback(() => {
    if (
      currentActorFacePart &&
      currentActorHairColor &&
      currentActorCostume &&
      currentActorEye &&
      currentActorFacePart
    ) {
      setLoading(true);
      actorCharactersRepository
        .create({
          actorCharacter: {
            actorId: currentActorFacePart.actorId,
            actorCostumeId: currentActorCostume.id,
            actorHairStyleId: currentActorHairColor.id,
            eyeColorPatternId: currentActorEye.eyeColorPatternId,
          },
        })
        .then(actorCharacter => {
          indexActorCharacterFaces({
            actorCharacterId: actorCharacter.id,
          }).then(actorCharacterFaces => {
            const foundActorCharacterFace = actorCharacterFaces.records.find(
              actorCharacterFace => {
                return (
                  actorCharacterFace.faceName ===
                  currentActorFacePart.facePatternName
                );
              },
            );
            if (foundActorCharacterFace) {
              createCharacterPattern({
                actorCharacterId: actorCharacter.id,
                characterId,
              })
                .then(characterPattern => {
                  onSelectActorCharacterFace(
                    characterPattern,
                    foundActorCharacterFace,
                    currentMark.id === 0 ? null : currentMark,
                  );
                  setLoading(false);
                })
                .catch(() => {
                  setLoading(false);
                });
            }
          });
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [
    loading,
    currentActorFacePart,
    currentActorHairColor,
    currentActorCostume,
    currentActorEye,
    currentMark,
  ]);
  return (
    <DimensionContext.Consumer>
      {context => (
        <Layout
          title={
            activeTab === 'face'
              ? '表情'
              : activeTab === 'mark'
              ? 'アイコン'
              : activeTab === 'hair_color'
              ? '髪色'
              : activeTab === 'costume'
              ? '衣装'
              : ''
          }
          scrollable={scrollableLayout(context)}
          navigation={navigation}
          loading={loading}
          back={true}
          rightButton={{
            tintColor: 'white',
            title: (
              <HeaderRightButton onPress={handleSubmit}>次へ</HeaderRightButton>
            ),
          }}>
          <>
            <CharacterView
              actorFacePart={currentActorFacePart}
              actorHairStyle={currentActorHairColor}
              actorCostume={currentActorCostume}
              actorEye={currentActorEye}
              mark={currentMark}
              width={context.content.width}
              inverted={character?.inverted}
            />

            {actorCostumes && currentActorHairColor && (
              <>
                <Tabs
                  hide={hideTab}
                  data={
                    currentActorHairColor.noHairColor
                      ? TAB_DATA_WITHOUT_HAIR_COLOR
                      : TAB_DATA
                  }
                  activeTab={activeTab}
                  onChangeTab={onChangeTab}
                />
                <EntityList
                  entities={actorFaceParts}
                  visible={activeTab === 'face'}
                  selectedEntityId={
                    currentActorFacePart ? currentActorFacePart.id : null
                  }
                  clipped={true}
                  inverted={character?.inverted}
                  width={context.content.width}
                  backgroundImageUris={faceBackgroundImageUris}
                  foregroundImageUris={faceForegroundImageUris}
                  onSelectEntity={onSelectActorFacePart}
                  extractLabel={extractActorFacePartLabel}
                  extractTopForegroundImageUris={
                    extractFaceTopForegroundImageUris
                  }
                  top={-15}
                />
                <MarkList
                  visible={activeTab === 'mark'}
                  marks={marks}
                  selectedMark={currentMark}
                  width={context.content.width}
                  onSelectMark={onSelectMark}
                />
                <EntityList
                  entities={actorCostumes}
                  visible={activeTab === 'costume'}
                  selectedEntityId={
                    currentActorCostume ? currentActorCostume.id : null
                  }
                  clipped={false}
                  inverted={character?.inverted}
                  width={context.content.width}
                  backgroundImageUris={costumeBackgroundImageUris}
                  foregroundImageUris={costumeForegroundImageUris}
                  onSelectEntity={onSelectActorCostume}
                />
                <EntityList
                  entities={actorHairColors}
                  visible={activeTab === 'hair_color'}
                  selectedEntityId={
                    currentActorHairColor ? currentActorHairColor.id : null
                  }
                  clipped={true}
                  inverted={character?.inverted}
                  width={context.content.width}
                  backgroundImageUris={hairColorBackgroundImageUris}
                  foregroundImageUris={hairColorForegroundImageUris}
                  extractTopForegroundImageUris={
                    extractHairColorTopForegroundImageUris
                  }
                  onSelectEntity={onSelectActorHairColor}
                  top={-15}
                />
              </>
            )}
          </>
        </Layout>
      )}
    </DimensionContext.Consumer>
  );
};

export default React.memo(ActorCharacterBuilder);
