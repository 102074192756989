import * as React from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
  Animated,
} from 'react-native';

import Button from './Button';

export interface Props {
  style: ViewStyle;
  containerWidth: number;
  activeTab: number;
  scrollValue: Animated.Value;
  tabs: JSX.Element[];
  locked?: boolean;
  goToPage: (pageNumber: number) => void;
  backgroundColor?: string;
  activeTextColor?: string;
  inactiveTextColor?: string;
  textStyle?: TextStyle;
  tabStyle?: ViewStyle;
  renderTab?: (
    name: string,
    pageIndex: number,
    isTabActive: boolean,
    onPressHandler: (pageNumber: number) => void,
    onLayoutHandler?: (event: LayoutChangeEvent) => void,
  ) => JSX.Element;
  underlineStyle?: ViewStyle;
}

export default class DefaultTabBar extends React.Component<Props> {
  public render(): React.ReactNode {
    const containerWidth = this.props.containerWidth;
    const numberOfTabs = this.props.tabs.length;
    const tabUnderlineStyle = {
      position: 'absolute',
      width: containerWidth / numberOfTabs,
      height: 4,
      backgroundColor: 'navy',
      bottom: 0,
    } as ViewStyle;
    return (
      <View
        style={[
          styles.tabs,
          {backgroundColor: this.props.backgroundColor},
          this.props.style,
        ]}>
        {this.props.tabs.map((name: any, page: number) => {
          const isTabActive = this.props.activeTab === page;
          const renderTab = this.props.renderTab || this.renderTab;
          return renderTab(name, page, isTabActive, this.props.goToPage);
        })}
        <Animated.View style={[tabUnderlineStyle, this.props.underlineStyle]} />
      </View>
    );
  }

  public renderTab = (
    name: string,
    page: number,
    isTabActive: boolean,
    onPressHandler?: (page: number) => void,
  ) => {
    const {activeTextColor, inactiveTextColor, textStyle, locked} = this.props;
    const textColor = isTabActive
      ? activeTextColor || '#383838'
      : locked
      ? '#aaa'
      : inactiveTextColor || '#666666';
    const fontWeight = isTabActive ? 'bold' : 'normal';

    return (
      <Button
        style={styles.button}
        key={name}
        accessible={true}
        accessibilityLabel={name}
        accessibilityTraits="button"
        disabled={!!locked}
        onPress={() => onPressHandler && onPressHandler(page)}>
        <View
          style={[
            styles.tab,
            isTabActive ? styles.tabActive : null,
            this.props.tabStyle,
          ]}>
          <Text
            style={[textStyle, {color: textColor, fontSize: 14, fontWeight}]}>
            {name}
          </Text>
        </View>
      </Button>
    );
  };
}

const styles = StyleSheet.create({
  tabs: {
    height: 50,
    flexDirection: 'row',
    justifyContent: 'space-around',
    borderWidth: 1,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderColor: '#efefef',
  },
  button: {
    flex: 1,
  } as ViewStyle,
  tab: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 10,
    backgroundColor: '#fafafa',
    borderBottomColor: 'transparent',
    borderBottomWidth: 3,
  },
  tabActive: {
    backgroundColor: '#ffffff',
    borderBottomColor: '#ff8f13',
  },
});
