import EntityMapper from './EntityMapper';

import SceneCommandMapper, {
  SceneCommandData,
} from '../../value_objects/mappers/scene_commands/SceneCommandMapper';
import SceneMapper, {SceneData} from './SceneMapper';

import SceneWithCommandsWrapper from '../../../domain/entities/SceneWithCommandsWrapper';

export interface SceneWithCommandsWrapperData {
  id: number;
  scene: SceneData;
  scene_commands: SceneCommandData[];
  updated_at: string;
}

export default class SceneWithCommandsWrapperMapper
  implements
    EntityMapper<SceneWithCommandsWrapperData, SceneWithCommandsWrapper>
{
  private sceneMapper: SceneMapper;
  private sceneCommandMapper: SceneCommandMapper;

  constructor() {
    this.sceneMapper = new SceneMapper();
    this.sceneCommandMapper = new SceneCommandMapper();
  }

  public map(obj: SceneWithCommandsWrapperData): SceneWithCommandsWrapper {
    const scene = this.sceneMapper.map(obj.scene);
    const sceneCommands = obj.scene_commands.map(command => {
      return this.sceneCommandMapper.map(command);
    });
    return new SceneWithCommandsWrapper(
      obj.id,
      scene,
      sceneCommands,
      new Date(obj.updated_at),
    );
  }
}
