import * as React from 'react';
import {
  ImageStyle,
  ImageURISource,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import ImageIcon from '../icons/ImageIcon';

import File from '../../../../domain/entities/File';

import ImagePickerAdapter from './ImagePickerAdapter';

interface Props {
  source: ImageURISource | ImageURISource[] | null;
  imageIconStyle?: StyleProp<ViewStyle>;
  onChangeImage?: (file: File) => void;
  renderNoImage?: () => React.ReactNode;
  children?: React.ReactNode;
}

const AvatorPicker: React.FunctionComponent<Props> = props => {
  const {source, imageIconStyle, onChangeImage, renderNoImage} = props;
  return (
    <ImagePickerAdapter
      imageSource={source}
      imageStyle={styles.image}
      imageContainerStyle={styles.imageContainer}
      imageIconStyle={imageIconStyle}
      onChangeImage={onChangeImage}
      noImage={
        <View style={styles.noImage}>
          <View style={styles.imageIcon}>
            {renderNoImage ? renderNoImage() : <ImageIcon size={30} />}
          </View>
        </View>
      }
      visibleImageIcon={true}
    />
  );
};

export default React.memo(AvatorPicker);

const styles = StyleSheet.create({
  image: {
    backgroundColor: '#efefef',
    borderColor: '#efefef',
    borderWidth: 1,
    borderRadius: 50,
    height: 100,
    width: 100,
  } as ImageStyle,
  imageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  imageIcon: {} as ViewStyle,
  noImage: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
});
