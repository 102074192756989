import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Select from '../../../shared/forms/Select';

import {Status} from '../../../../../domain/entities/ProjectMemberJoinRequest';

const items: Array<{label: string; value: Status}> = [
  {label: '承認待ち', value: 'pending'},
  {label: '承認済み', value: 'approved'},
  {label: '非承認', value: 'rejected'},
];

interface Props {
  status: Status;
  onStatusChange: (status: Status) => void;
}

const MemberJoinRequestListHeader: React.FC<Props> = props => {
  const {status, onStatusChange} = props;
  const onValueChange = React.useCallback(
    (itemValue: string | number, itemPosition: number) => {
      onStatusChange(itemValue as Status);
    },
    [],
  );
  return (
    <View style={styles.container}>
      <Select
        style={styles.select}
        pickerStyle={styles.selectPicker}
        items={items}
        value={status}
        onValueChange={onValueChange}
        rightIcon={<View style={styles.arrowIcon} />}
      />
    </View>
  );
};

export default React.memo(MemberJoinRequestListHeader);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    padding: 16,
    paddingTop: 0,
    height: 56,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  select: {
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  selectPicker: {
    backgroundColor: '#fafafa',
    height: '100%',
    paddingVertical: 0,
  } as TextStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
    top: 5,
    right: 5,
  } as ViewStyle,
});
