import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import ActorCharacter from '../../../domain/entities/ActorCharacter';

import NetActorCharactersRepository from '../../../data/repositories/writer/NetActorCharactersRepository';

const types = {
  failure: Types.ShowActorCharacterFailure,
  request: Types.ShowActorCharacterRequest,
  success: Types.ShowActorCharacterSuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<ActorCharacter> {
  return showGeneric(new NetActorCharactersRepository(), types, id);
}
