import * as React from 'react';

import FetchingEpisodeVideoExportJobExecutionListWithNewButton from './FetchingEpisodeVideoExportJobExecutionListWithNewButton';

import ScrollableTabViewAdapter from '../../../shared/scrollable_tab_view/ScrollableTabViewAdapter';

import EpisodeVideoExportJobExecution from '../../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

interface Props {
  onSelectEpisodeVideoExportJobExecution: (
    episodeVideoExportJobExecution: EpisodeVideoExportJobExecution,
  ) => void;
  onForwardToNewVerticalEpisodeVideoExportJobExecution: () => void;
  onForwardToNewHorizontalEpisodeVideoExportJobExecution: () => void;
}

const Tabs: React.FunctionComponent<Props> = props => {
  const {
    onSelectEpisodeVideoExportJobExecution,
    onForwardToNewVerticalEpisodeVideoExportJobExecution,
    onForwardToNewHorizontalEpisodeVideoExportJobExecution,
  } = props;
  return (
    <ScrollableTabViewAdapter
      tabs={[
        {
          label: 'タテ型動画',
          element: (
            <FetchingEpisodeVideoExportJobExecutionListWithNewButton
              videoType={'vertical_full'}
              onSelectEpisodeVideoExportJobExecution={
                onSelectEpisodeVideoExportJobExecution
              }
              onForwardToNewEpisodeVideoExportJobExecution={
                onForwardToNewVerticalEpisodeVideoExportJobExecution
              }
            />
          ),
        },
        {
          label: 'ヨコ型動画',
          element: (
            <FetchingEpisodeVideoExportJobExecutionListWithNewButton
              videoType={'horizontal_full'}
              onSelectEpisodeVideoExportJobExecution={
                onSelectEpisodeVideoExportJobExecution
              }
              onForwardToNewEpisodeVideoExportJobExecution={
                onForwardToNewHorizontalEpisodeVideoExportJobExecution
              }
            />
          ),
        },
      ]}
      onChangeTab={() => {}}
    />
  );
};

export default Tabs;
