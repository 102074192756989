import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import EpisodeBrowsingRetentionRateGraphWrapper from './EpisodeBrowsingRetentionRateGraphWrapper';

import TermSelect from '../../stories/partials/TermSelect';

import TooltipModal from '../../../shared/modals/TooltipModal';
import QuestionIcon from '../../../shared/icons/QuestionIcon';

import {TermRange, inTermRange} from '../../../../view_models/TermRange';

import EpisodeAggregation from '../../../../../domain/value_objects/summary/EpisodeAggregation';
import EpisodeBrowsingRetentionRate from '../../../../../domain/value_objects/summary/EpisodeBrowsingRetentionRate';
import SceneCommand from '../../../../../domain/value_objects/summary/SceneCommand';

type TooltipModalType =
  | 'finished_reading_rate'
  | 'tap_count'
  | 'speech_text_length_rate'
  | 'retention_rate';

const TypeToTooltipInfo = {
  finished_reading_rate: {
    title: '読了率',
    description:
      'エピソードを閲覧した人がどのくらいの割合で最後まで読んでくれたかを示す指標です。\nエピソード自体の満足度を計測するのに役立ちます。',
  },
  tap_count: {
    title: 'エピソードTap数',
    description:
      'そのエピソードを読み切るのに必要なTap数です。\nスマートフォンを使ってスキマ時間で読む読者が多いので、1エピソード200Tap以内を推奨しております。',
  },
  speech_text_length_rate: {
    title: 'セリフ割合',
    description:
      'エピソードに含まれる合計文字数のうち、会話の文字数が占める割合を示す指標です。\n地の文やト書きが多いと読者が離脱する傾向にあるため、セリフ割合75％以上を推奨しております。',
  },
  retention_rate: {
    title: '閲覧維持率',
    description:
      'エピソードを閲覧した人がどのくらい読み続けたかを示す指標です。\n閲覧維持率の下がった箇所は読者が離脱した箇所となります。\nエピソードの改善にお役立てください。',
  },
};

interface Props {
  termRange: TermRange;
  episodeAggregation: EpisodeAggregation;
  episodeBrowsingRetentionRates: Array<EpisodeBrowsingRetentionRate>;
  sceneCommands: Array<SceneCommand>;
  onSelectTermRange: (termRange: TermRange) => void;
}

const StoryInfo: React.FunctionComponent<Props> = props => {
  const {
    termRange,
    episodeAggregation,
    episodeBrowsingRetentionRates,
    sceneCommands,
    onSelectTermRange,
  } = props;
  const [tooltipModalType, setTooltipModalType] =
    React.useState<TooltipModalType | null>(null);
  const onCloseModal = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onPressFinishedReadingRate = React.useCallback(() => {
    setTooltipModalType('finished_reading_rate');
  }, []);
  const onPressTapCount = React.useCallback(() => {
    setTooltipModalType('tap_count');
  }, []);
  const onPressSpeechTextLengthRate = React.useCallback(() => {
    setTooltipModalType('speech_text_length_rate');
  }, []);
  const onPressRetentionRate = React.useCallback(() => {
    setTooltipModalType('retention_rate');
  }, []);
  const handleValueChange = React.useCallback((value: string | number) => {
    onSelectTermRange(value as TermRange);
  }, []);
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  return (
    <View style={styles.container}>
      <TermSelect
        style={styles.termSelect}
        selectPicker={styles.termSelectPicker}
        termRange={termRange}
        onTermRangeChange={handleValueChange}
      />
      <View style={styles.rows}>
        <View style={styles.row}>
          <Text style={styles.rowLabel}>閲覧数(人)</Text>
          <Text style={styles.rowValue}>
            {numeral(episodeAggregation.episodePageViewCount).format('0,0')} (
            {numeral(
              episodeAggregation.episodePageViewUniqueConsumerUserCount,
            ).format('0,0')}
            )
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.rowLabel}>読了数(人)</Text>
          <Text style={styles.rowValue}>
            {numeral(
              episodeAggregation.finishedReadingEpisodePageViewCount,
            ).format('0,0')}{' '}
            (
            {numeral(
              episodeAggregation.finishedReadingEpisodePageViewUniqueConsumerUserCount,
            ).format('0,0')}
            )
          </Text>
        </View>
      </View>
      <View style={styles.rows}>
        <View style={styles.row}>
          <View style={styles.rowLabelWithQuestion}>
            <Text style={styles.rowLabel}>読了率</Text>
            <Pressable onPress={onPressFinishedReadingRate}>
              <View style={styles.question}>
                <QuestionIcon />
              </View>
            </Pressable>
          </View>
          <Text style={styles.rowValue}>
            {numeral(episodeAggregation.finishedReadingRate * 100).format(
              '0,0.0',
            )}
            %
          </Text>
          <Text style={styles.rowValueNote}>
            カテゴリ平均 (
            {numeral(
              episodeAggregation.genreAverageFinishedReadingRate * 100,
            ).format('0,0.0')}
            ％)
          </Text>
        </View>

        <View style={styles.row}>
          <View style={styles.rowLabelWithQuestion}>
            <Text style={styles.rowLabel}>エピソードTap数</Text>
            <Pressable onPress={onPressTapCount}>
              <View style={styles.question}>
                <QuestionIcon />
              </View>
            </Pressable>
          </View>
          <Text style={styles.rowValue}>
            {numeral(episodeAggregation.tapCount).format('0,0')}Tap
          </Text>
        </View>
      </View>
      <View style={styles.rows}>
        <View style={styles.row}>
          <Text style={styles.rowLabel}>合計文字数</Text>
          <Text style={styles.rowValue}>
            {numeral(episodeAggregation.getTotalTextLength()).format('0,0')}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.rowLabelWithQuestion}>
            <Text style={styles.rowLabel}>セリフ割合</Text>
            <Pressable onPress={onPressSpeechTextLengthRate}>
              <View style={styles.question}>
                <QuestionIcon />
              </View>
            </Pressable>
          </View>
          <Text style={styles.rowValue}>
            {numeral(episodeAggregation.getSpeechTextLengthRate() * 100).format(
              '0,0.0',
            )}
            ％
          </Text>
        </View>
      </View>
      <EpisodeBrowsingRetentionRateGraphWrapper
        key={termRange}
        inTermRange={inTermRange(termRange, episodeAggregation.version)}
        episodeBrowsingRetentionRates={episodeBrowsingRetentionRates}
        sceneCommands={sceneCommands}
        onPressRetentionRate={onPressRetentionRate}
      />
      {modalInfo ? (
        <TooltipModal
          visible={true}
          title={modalInfo.title}
          description={modalInfo.description}
          onCloseModal={onCloseModal}></TooltipModal>
      ) : null}
    </View>
  );
};

export default React.memo(StoryInfo);

const styles = StyleSheet.create({
  container: {
    margin: 16,
    backgroundColor: 'white',
  } as ViewStyle,
  termSelect: {
    margin: 0,
    marginBottom: 16,
  } as ViewStyle,
  termSelectPicker: {
    backgroundColor: undefined,
  } as TextStyle,
  rows: {
    flex: 1,
    flexDirection: 'row',
  } as ViewStyle,
  row: {
    flex: 1,
    marginVertical: 10,
  } as ViewStyle,
  rowLabel: {
    color: '#383838',
    fontSize: 11,
  } as TextStyle,
  rowLabelWithQuestion: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  question: {
    marginLeft: 5,
  } as ViewStyle,
  rowValue: {
    color: '#ff8f13',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 8,
  } as TextStyle,
  rowValueNote: {
    color: '#ff8f13',
    fontSize: 10,
  } as TextStyle,
});
