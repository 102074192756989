import Entity from '../../../domain/entities/Entity';
import PaginatedResult, {Params} from '../../../domain/results/PaginatedResult';

export interface Response {
  pageToIds: {[key: number]: number[]};
  total: number;
  params: Params;
  perPage: number;
  prevPage?: number;
  nextPage?: number;
  firstPage?: number;
  lastPage?: number;
}

export interface QueryState {
  [key: string]: Response;
}

export const mergeResponse = <T extends Entity>(
  state: {[key: string]: Response},
  result: PaginatedResult<T>,
) => {
  const key = result.getObjectKey();
  const page = result.getCurrentPage();
  const ids = result.records.map(record => record.id);
  const pageToIds = (state[key] || {pageToIds: {}}).pageToIds;
  return {
    ...state,
    [key]: {
      firstPage: result.firstPage,
      lastPage: result.lastPage,
      nextPage: result.nextPage,
      pageToIds: {
        ...pageToIds,
        [page]: ids,
      },
      params: result.params,
      perPage: result.perPage,
      prevPage: result.prevPage,
      total: result.total,
    },
  };
};
