import EntityMapper from './EntityMapper';

import IllustrationMapper, {IllustrationData} from './IllustrationMapper';

import IllustrationUsageHistory from '../../../domain/entities/IllustrationUsageHistory';

export interface IllustrationUsageHistoryData {
  id: number;
  story_id: number;
  illustration: IllustrationData;
  last_used_at: string;
  created_at: string;
}

export default class FullScreenIllustrationUsageHistoryMapper
  implements
    EntityMapper<IllustrationUsageHistoryData, IllustrationUsageHistory>
{
  private illustrationMapper = new IllustrationMapper();

  public map(obj: IllustrationUsageHistoryData): IllustrationUsageHistory {
    return new IllustrationUsageHistory(
      obj.id,
      obj.story_id,
      this.illustrationMapper.map(obj.illustration),
      new Date(obj.last_used_at),
      new Date(obj.created_at),
    );
  }
}
