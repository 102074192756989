import * as React from 'react';

import SelectedBackgroundImage from './partials/SelectedBackgroundImage';

import Layout from '../../../../shared/Layout';
import SelectableLabeledEntityImageGridList from '../../../../shared/SelectableLabeledEntityImageGridList';
import HeaderRightButton from '../../../../shared/buttons/HeaderRightButton';
import DimensionContext from '../../../dimension/DimensionContext';
import ResourceFavoriteButton from '../../../ResourceFavoriteButton';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormBackgroundShowSceneCommandNewBackgroundsRouteProp,
  CoverImageFormBackgroundsRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as BackgroundTimeZoneIndexParams} from '../../../../../actions/background_time_zones/index';
import {Params as BackgroundWeatherIndexParams} from '../../../../../actions/background_weathers/index';
import {Params as BackgroundIndexParams} from '../../../../../actions/backgrounds/index';

import {backgroundImageUrl} from '../../../../../helpers/images';
import scrollableLayout from '../../../../../helpers/scrollableLayout';

import Background from '../../../../../../domain/entities/Background';
import BackgroundTimeZone from '../../../../../../domain/entities/BackgroundTimeZone';
import BackgroundWeather from '../../../../../../domain/entities/BackgroundWeather';
import BaseBackground from '../../../../../../domain/entities/BaseBackground';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

export interface Props {
  navigation: NavigationProp;
  route:
    | SceneFormBackgroundShowSceneCommandNewBackgroundsRouteProp
    | CoverImageFormBackgroundsRouteProp;
  backgroundsParams: BackgroundIndexParams;
  backgroundTimeZonesParams: BackgroundTimeZoneIndexParams;
  backgroundWeathersParams: BackgroundWeatherIndexParams;
  baseBackground: BaseBackground | null;
  backgrounds: Background[] | null;
  backgroundTimeZones: BackgroundTimeZone[] | null;
  backgroundWeathers: BackgroundWeather[] | null;
  enabledFavorite?: boolean;
  indexBackgrounds: (
    params: BackgroundIndexParams,
  ) => Promise<PaginatedResult<Background>>;
  indexBackgroundTimeZones: (
    params: BackgroundTimeZoneIndexParams,
  ) => Promise<PaginatedResult<BackgroundTimeZone>>;
  indexBackgroundWeathers: (
    params: BackgroundWeatherIndexParams,
  ) => Promise<PaginatedResult<BackgroundWeather>>;
  showBaseBackground: (id: number) => Promise<BaseBackground>;
  onSubmit: (background: Background) => void;
}

interface State {
  selectedBackground: Background | null;
}

const aspectRatio = 1.25;

export default class Index extends React.PureComponent<Props, State> {
  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (prevState.selectedBackground === null && nextProps.backgrounds) {
      return {selectedBackground: nextProps.backgrounds[0]};
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedBackground: props.backgrounds ? props.backgrounds[0] : null,
    };
  }

  public componentDidMount() {
    const {
      route,
      backgroundsParams,
      backgroundTimeZonesParams,
      backgroundWeathersParams,
      baseBackground,
      backgroundTimeZones,
      backgroundWeathers,
      backgrounds,
      enabledFavorite,
      indexBackgrounds,
      indexBackgroundTimeZones,
      indexBackgroundWeathers,
      showBaseBackground,
    } = this.props;
    const {baseBackgroundId} = route.params;
    if (!baseBackground) {
      showBaseBackground(baseBackgroundId);
    }
    if (!backgrounds) {
      indexBackgrounds(backgroundsParams);
    }
    if (!backgroundTimeZones) {
      indexBackgroundTimeZones(backgroundTimeZonesParams);
    }
    if (!backgroundWeathers) {
      indexBackgroundWeathers(backgroundWeathersParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, baseBackground, backgrounds, enabledFavorite} =
      this.props;
    const {selectedBackground} = this.state;
    const {baseBackgroundId} = route.params;

    const title = baseBackground ? baseBackground.name : '';
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={title}
            scrollable={scrollableLayout(context)}
            navigation={navigation}
            back={true}
            rightButton={{
              tintColor: 'white',
              title: (
                <HeaderRightButton onPress={this.handleSubmit}>
                  決定
                </HeaderRightButton>
              ),
            }}>
            {selectedBackground && (
              <SelectedBackgroundImage background={selectedBackground} />
            )}
            {enabledFavorite && (
              <ResourceFavoriteButton
                resourceType={'BaseBackground'}
                resourceId={baseBackgroundId}
              />
            )}
            {backgrounds && (
              <SelectableLabeledEntityImageGridList
                entities={backgrounds}
                selectedEntity={selectedBackground}
                onSelectEntity={this.handleSelectEntity}
                aspectRatio={aspectRatio}
                renderLabel={this.renderLabel}
                imageUrlExtractor={this.imageUrlExtractor}
              />
            )}
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private imageUrlExtractor = (background: Background, width: number) => {
    return backgroundImageUrl(background, 'middle');
  };

  private renderLabel = (background: Background) => {
    const {backgroundTimeZones, backgroundWeathers} = this.props;
    const ary: string[] = [];
    if (backgroundTimeZones) {
      const backgroundTimeZone = backgroundTimeZones.find(
        backgroundTimeZone =>
          backgroundTimeZone.id === background.backgroundTimeZoneId,
      );
      if (backgroundTimeZone) {
        ary.push(backgroundTimeZone.name);
      }
    }
    if (backgroundWeathers) {
      const backgroundWeather = backgroundWeathers.find(
        backgroundWeather =>
          backgroundWeather.id === background.backgroundWeatherId,
      );
      if (backgroundWeather) {
        ary.push(backgroundWeather.name);
      }
    }
    return ary.join(' ');
  };

  private handleSelectEntity = (selectedBackground: Background) => {
    this.setState({selectedBackground});
  };

  private handleSubmit = () => {
    const {onSubmit} = this.props;
    const {selectedBackground} = this.state;
    if (selectedBackground) {
      onSubmit(selectedBackground);
    }
  };
}
