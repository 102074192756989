import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const ShuffleIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 18;
  const color = props.color || '#383838';
  return <Icon name={'shuffle'} size={size} color={color} />;
};

export default React.memo(ShuffleIcon);
