import * as React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

interface Props {
  value: string;
  label: string;
  checked?: boolean;
  optionLabel?: string;
  last?: boolean;
  disabled?: boolean;
  onPress: () => void;
}

const MultiSwitchButton: React.FunctionComponent<Props> = props => {
  const {value, label, checked, optionLabel, last, disabled, onPress} = props;
  return (
    <Pressable
      style={[
        styles.button,
        checked ? null : {borderRightColor: last ? 'transparent' : '#efefef'},
      ]}
      disabled={disabled}
      key={`${value}`}
      onPress={onPress}>
      <Text
        style={[
          styles.buttonLabel,
          checked && styles.buttonLabelChecked,
          disabled && {color: '#999999'},
        ]}>
        {label}
      </Text>
      {optionLabel ? (
        <Text
          style={[
            styles.buttonLabelOption,
            checked && styles.buttonLabelOptionChecked,
            disabled && {color: '#999999'},
          ]}>
          {optionLabel}
        </Text>
      ) : null}
    </Pressable>
  );
};

export default MultiSwitchButton;

const styles = StyleSheet.create({
  button: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderRadius: 4,
    borderColor: 'transparent',
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      } as any,
      default: {},
    }),
  } as ViewStyle,
  buttonLabel: {
    fontSize: 13,
    fontWeight: 'bold',
    lineHeight: 13,
    color: '#999999',
  } as TextStyle,
  buttonLabelChecked: {
    color: '#ff8f13',
  } as TextStyle,
  buttonLabelOption: {
    marginTop: 2,
    fontSize: 9,
    lineHeight: 9,
    color: '#999999',
  } as TextStyle,
  buttonLabelOptionChecked: {
    color: '#ff8f13',
  } as TextStyle,
});
