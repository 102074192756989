import EntityMapper from '../EntityMapper';

import Story from '../../../../domain/entities/response/Story';

export interface StoryData {
  id: number;
  title: string;
  has_cover_image: boolean;
  original_cover_image_url: string;
  tips_count: number;
  reactions_count: number;
  comments_count: number;
  confirmed: boolean;
  created_at: string;
  updated_at: string;
}

export default class StoryMapper implements EntityMapper<StoryData, Story> {
  public map(obj: StoryData): Story {
    return new Story(
      obj.id,
      obj.title,
      obj.has_cover_image,
      obj.original_cover_image_url,
      obj.tips_count,
      obj.reactions_count,
      obj.comments_count,
      obj.confirmed,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
