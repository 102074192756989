import Story from '../../../../domain/entities/Story';

interface Props {
  story: Story | null;
}

export default (current: Props, next: Props) => {
  if (current.story === next.story) {
    return false;
  }
  if (!current.story || !next.story) {
    return true;
  }
  if (current.story.id !== next.story.id) {
    return true;
  }
  if (current.story.updatedAt.getTime() !== next.story.updatedAt.getTime()) {
    return true;
  }
  return false;
};
