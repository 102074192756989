import TapNovelRestApi from '../../../../data_stores/net/TapNovelRestApi';

import TwitterRequestTokenMapper, {
  TwitterRequestTokenData,
} from '../../../../value_objects/mappers/TwitterRequestTokenMapper';

import TwitterRequestToken from '../../../../../domain/value_objects/TwitterRequestToken';

import RequestTokensRepository, {
  CreateParams,
} from '../../../../../domain/repositories/writer/social_accounts/twitter_accounts/RequestTokensRepository';

export default class NetRequestTokensRepository
  implements RequestTokensRepository
{
  private endPoint =
    '/api/writer/social_accounts/twitter_accounts/request_token';

  private mapper: TwitterRequestTokenMapper;

  constructor() {
    this.mapper = new TwitterRequestTokenMapper();
  }

  public create(params: CreateParams): Promise<TwitterRequestToken> {
    const path = this.endPoint;
    return TapNovelRestApi.post<TwitterRequestTokenData>(path, params).then(
      obj => {
        return this.mapper.map(obj.body);
      },
    );
  }
}
