import * as React from 'react';
import {StyleSheet, TextStyle, View} from 'react-native';

import TextArea from '../../../shared/forms/TextArea';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import DimensionContext from '../../../shared/dimension/DimensionContext';

import {formStyles} from '../../../../styles/variables';

interface Props {
  onPressNext: (text: string) => void;
  onPressCancel: () => void;
}

const FormStep2: React.FunctionComponent<Props> = props => {
  const {onPressNext} = props;
  const [value, setValue] = React.useState('');
  const onChangeText = React.useCallback((text: string) => {
    setValue(text);
  }, []);
  const onPress = React.useCallback(() => {
    onPressNext(value);
  }, [value]);
  return (
    <DimensionContext.Consumer>
      {context => (
        <>
          <View style={formStyles.formGroup}>
            <LabelWithOption title={'退会理由'} />
            <TextArea
              style={[
                styles.textArea,
                {width: Math.floor((context.content.width / 375) * 343)},
              ]}
              value={value}
              placeholder={'退会理由を入力'}
              onChangeText={onChangeText}
            />
          </View>
          <PrimaryButton disabled={value.length === 0} onPress={onPress}>
            次へ
          </PrimaryButton>
        </>
      )}
    </DimensionContext.Consumer>
  );
};

export default React.memo(FormStep2);

const styles = StyleSheet.create({
  textArea: {
    width: 300,
  } as TextStyle,
});
