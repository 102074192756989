import * as React from 'react';
import {
  ImageSourcePropType,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {DateObject} from 'react-native-calendars';

import DateCalendarWithTimeSelect from './DateCalendarWithTimeSelect';

import ContestErrorMessagesBox from '../../../../shared/ContestErrorMessagesBox';
import MaterialRightCheck from '../../../../shared/MaterialRightCheck';
import LabelAndCheck from '../../../../shared/LabelAndCheck';
import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';
import MultiSwitch from '../../../../shared/forms/MultiSwitch';
import CheckButton from '../../../../shared/forms/CheckButton';
import TooltipModal from '../../../../shared/modals/TooltipModal';

import AlertIcon from '../../../../shared/icons/AlertIcon';

import {formStyles} from '../../../../../styles/variables';

import Episode from '../../../../../../domain/entities/Episode';

export type PublicationState = 'public' | 'reserved' | 'private';

interface Props {
  episode: Episode;
  publicationState: PublicationState;
  selectedDate: DateObject | null;
  selectedTime: Date;
  entryContestInfo?: {
    contest_id: number;
    errors: string[];
  };
  uploadedSelfImage?: boolean;
  uploadedSelfVoice?: boolean;
  firstPost?: boolean;
  onChangePublicationState: (publicationState: PublicationState) => void;
  onDayPress: (date: DateObject) => void;
  onTimePress: (time: Date) => void;
  onSubmit: () => void;
}

interface State {
  tooltipModalType: TooltipModalType | null;
  checkedTerm: boolean;
  checkedNotEditable: boolean;
  confirmedImageRight: boolean;
  confirmedVoiceRight: boolean;
  confirmedNotFanFiction: boolean;
  confirmedNotTest: boolean;
}

export default class Form extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tooltipModalType: null,
      checkedTerm: false,
      checkedNotEditable: false,
      confirmedImageRight: false,
      confirmedVoiceRight: false,
      confirmedNotFanFiction: false,
      confirmedNotTest: false,
    };
  }

  public render(): React.ReactNode {
    const {
      episode,
      publicationState,
      selectedDate,
      selectedTime,
      entryContestInfo,
      uploadedSelfImage,
      uploadedSelfVoice,
      firstPost,
      onDayPress,
      onTimePress,
      onSubmit,
    } = this.props;
    const {
      tooltipModalType,
      checkedNotEditable,
      checkedTerm,
      confirmedImageRight,
      confirmedVoiceRight,
      confirmedNotFanFiction,
      confirmedNotTest,
    } = this.state;
    const modalInfo = tooltipModalType
      ? TypeToTooltipInfo[tooltipModalType]
      : null;
    let disabled =
      publicationState !== 'private' &&
      (entryContestInfo
        ? entryContestInfo.errors.length === 0
          ? !(checkedTerm && checkedNotEditable)
          : true
        : false);
    if (
      publicationState !== 'private' &&
      uploadedSelfImage &&
      !confirmedImageRight
    ) {
      disabled = true;
    }
    if (
      publicationState !== 'private' &&
      uploadedSelfVoice &&
      !confirmedVoiceRight
    ) {
      disabled = true;
    }
    if (publicationState !== 'private' && !confirmedNotFanFiction) {
      disabled = true;
    }
    if (publicationState !== 'private' && firstPost && !confirmedNotTest) {
      disabled = true;
    }
    return (
      <>
        <View style={formStyles.formGroup}>
          <Text style={styles.title}>{episode.title}</Text>
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption title={'公開設定'} />
          <MultiSwitch
            items={PUBLICATION_STATE_ITEMS}
            value={publicationState}
            onSelectItem={this.handleSelectItem}
          />
        </View>
        <DateCalendarWithTimeSelect
          visible={publicationState === 'reserved'}
          selectedDate={selectedDate}
          selectedTime={selectedTime}
          onDayPress={onDayPress}
          onTimePress={onTimePress}
        />

        {publicationState !== 'private' && entryContestInfo && (
          <ContestErrorMessagesBox
            note={true}
            entryContestInfo={entryContestInfo}>
            {entryContestInfo.errors.length === 0 && (
              <View style={styles.checkButtons}>
                <CheckButton
                  checked={checkedTerm}
                  onPress={() => {
                    this.setState({checkedTerm: !checkedTerm});
                  }}>
                  注意事項及び応募規約を確認しました
                </CheckButton>
                <CheckButton
                  checked={checkedNotEditable}
                  onPress={() => {
                    this.setState({checkedNotEditable: !checkedNotEditable});
                  }}>
                  コンテストに応募した作品は、受付期限を過ぎると編集及び削除等はできなくなります
                </CheckButton>
              </View>
            )}
          </ContestErrorMessagesBox>
        )}
        {publicationState !== 'private' && uploadedSelfImage ? (
          <MaterialRightCheck
            style={{marginVertical: 0}}
            checked={confirmedImageRight}
            renderHeader={
              <Text
                style={{
                  color: '#f23406',
                  fontSize: 11,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: 10,
                }}>
                <AlertIcon color={'#f23406'} />
                表紙及びスチルに画像が設定されています
              </Text>
            }
            messageText={
              '他人の著作物を無断で転載することは法律で禁止されています。'
            }
            labelText={'無断使用ではございません'}
            onPress={this.handlePressConfirmedImageRight}
          />
        ) : null}
        {publicationState !== 'private' && uploadedSelfVoice ? (
          <MaterialRightCheck
            style={{
              marginTop: uploadedSelfImage ? 16 : 0,
              marginBottom: 0,
            }}
            checked={confirmedVoiceRight}
            renderHeader={
              <Text
                style={{
                  color: '#f23406',
                  fontSize: 11,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  marginBottom: 10,
                }}>
                <AlertIcon color={'#f23406'} />
                ボイスが設定されています
              </Text>
            }
            messageText={'TapNovelMakerではフリー素材の音源は利用できません。'}
            labelText={'フリー素材の音源ではございません'}
            onPress={this.handlePressConfirmedVoiceRight}
          />
        ) : null}
        {publicationState !== 'private' ? (
          <LabelAndCheck
            style={
              uploadedSelfImage || uploadedSelfVoice
                ? null
                : {marginVertical: 0}
            }
            checked={confirmedNotFanFiction}
            messageText={'TapNovelMakerでは二次創作の投稿を禁止しております。'}
            labelText={'二次創作ではございません'}
            onPress={this.handlePressConfirmedNotFanFiction}
          />
        ) : null}
        {publicationState !== 'private' && firstPost ? (
          <LabelAndCheck
            checked={confirmedNotTest}
            messageText={'TapNovelMakerではテスト投稿を禁止しております。'}
            labelText={'テスト作品ではございません'}
            onPress={this.handlePressConfirmedNotText}
          />
        ) : null}
        <View style={styles.buttonWrapper}>
          <PrimaryButton disabled={disabled} onPress={onSubmit}>
            決定
          </PrimaryButton>
        </View>
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            onCloseModal={() => {
              this.setState({tooltipModalType: null});
            }}></TooltipModal>
        ) : null}
      </>
    );
  }

  private handleSelectItem = (item: {label: string; value: string}) => {
    const {onChangePublicationState} = this.props;
    const publicationState = item.value;
    if (
      publicationState === 'public' ||
      publicationState === 'reserved' ||
      publicationState === 'private'
    ) {
      onChangePublicationState(publicationState);
    }
  };

  private handlePressConfirmedImageRight = () => {
    this.setState({
      confirmedImageRight: !this.state.confirmedImageRight,
    });
  };

  private handlePressConfirmedVoiceRight = () => {
    this.setState({
      confirmedVoiceRight: !this.state.confirmedVoiceRight,
    });
  };

  private handlePressConfirmedNotFanFiction = () => {
    this.setState({
      confirmedNotFanFiction: !this.state.confirmedNotFanFiction,
    });
  };
  private handlePressConfirmedNotText = () => {
    this.setState({
      confirmedNotTest: !this.state.confirmedNotTest,
    });
  };
}

const PUBLICATION_STATE_ITEMS = [
  {label: '今すぐ公開', value: 'public'},
  {label: '予約公開', value: 'reserved'},
  {label: '非公開', value: 'private'},
];

type TooltipModalType = 'published';

const TypeToTooltipInfo = {
  published: {
    title: '公開設定',
    description: '',
    source: null,
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source: ImageSourcePropType | null;
  };
};

const styles = StyleSheet.create({
  title: {
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  buttonWrapper: {
    alignItems: 'center',
    marginVertical: 32,
  } as ViewStyle,
  checkButtons: {
    marginTop: 11,
  } as ViewStyle,
  message: {
    alignSelf: 'center',
    color: '#666',
    fontSize: 11,
  } as TextStyle,
});
