import * as React from 'react';
import {
  LayoutChangeEvent,
  Platform,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

import ElasticBox from './ElasticBox';

import NameLabelColor from '../../view_models/NameLabelColor';

import {getViewerTextBox} from '../../../vendor/react-native-tapnovel-viewer/presentation/styles/variables';

interface Props {
  text: string;
  name?: string;
  nameLabelColor?: NameLabelColor;
  hasVoice?: boolean;
  hasSound?: boolean;
  top: string;
  middle: string;
  bottom: string;
  width: number;
  height?: number;
  textStyle: StyleProp<TextStyle>;
  middleStyle?: StyleProp<ViewStyle>;
  voiceIconStyle?: ViewStyle;
  numberOfLines?: number;
  useStyledFrame?: boolean;
}

interface State {
  height: number;
}

export default class ElasticBoxText extends React.Component<Props, State> {
  private isUnmounted: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {
      height: 45,
    };
    this.isUnmounted = false;
  }

  public componentWillUnmount() {
    this.isUnmounted = true;
  }

  public render(): React.ReactNode {
    const {
      text,
      name,
      nameLabelColor,
      hasVoice,
      hasSound,
      top,
      middle,
      bottom,
      width,
      height,
      textStyle,
      middleStyle,
      voiceIconStyle,
      numberOfLines,
      useStyledFrame,
    } = this.props;
    return (
      <ElasticBox
        style={styles.container}
        text={text}
        name={name}
        nameLabelColor={nameLabelColor}
        top={top}
        middle={middle}
        bottom={bottom}
        height={height || this.state.height}
        width={width}
        useStyledFrame={useStyledFrame}
        hasVoice={hasVoice}
        hasSound={hasSound}
        middleStyle={middleStyle}
        voiceIconStyle={voiceIconStyle}>
        <Text
          key={`${JSON.stringify(textStyle)}`}
          numberOfLines={numberOfLines}
          style={[getViewerTextBox(width), styles.text, textStyle]}
          onLayout={this.handleLayout}>
          {this.appendNewLineIfLastCharIsNewLine(text)}
        </Text>
      </ElasticBox>
    );
  }

  private handleLayout = (e: LayoutChangeEvent) => {
    if (this.isUnmounted) {
      return;
    }
    if (e.nativeEvent.layout.height < 45) {
      return;
    }
    if (this.state.height === e.nativeEvent.layout.height) {
      return;
    }
    this.setState({
      height: e.nativeEvent.layout.height,
    });
  };

  private appendNewLineIfLastCharIsNewLine = (text: string) => {
    if (Platform.OS !== 'web') {
      return text;
    }
    return text.replace(/\n$/, '\n\n');
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  text: {
    backgroundColor: 'transparent',
    position: 'absolute',
  } as TextStyle,
});
