import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import Tabs from './partials/Tabs';
import ServiceExplanation from './partials/ServiceExplanation';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {FullVideoExportJobExecutionsRouteProp} from '../../../navigators/RouteProps';

import EpisodeVideoExportJobExecution from '../../../../domain/entities/writer/EpisodeVideoExportJobExecution';
import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: FullVideoExportJobExecutionsRouteProp;
}

export interface DispatchProps {
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, showCurrentUserStatus} = props;
  const [title, setTitle] = React.useState('動画一覧');
  const [currentUserStatus, setCurrentUserStatus] =
    React.useState<CurrentUserStatus | null>(null);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    showCurrentUserStatus().then(currentUserStatus => {
      setCurrentUserStatus(currentUserStatus);
      if (currentUserStatus.serviceAgreementCodes.includes('full_video')) {
        setTitle('動画一覧');
      } else {
        setTitle('動画化');
      }
    });
  }, []);
  const onSelectEpisodeVideoExportJobExecution = React.useCallback(
    (episodeVideoExportJobExecution: EpisodeVideoExportJobExecution) => {
      navigation.navigate('FullVideoExportJobExecution', {
        exportJobExecutionId: episodeVideoExportJobExecution.id,
      });
    },
    [],
  );
  const onForwardToNewVerticalEpisodeVideoExportJobExecution =
    React.useCallback(() => {
      navigation.navigate('FullVideoStories', {orientation: 'vertical'});
    }, []);
  const onForwardToNewHorizontalEpisodeVideoExportJobExecution =
    React.useCallback(() => {
      navigation.navigate('FullVideoStories', {orientation: 'horizontal'});
    }, []);
  const onAgreeServicePolicy = React.useCallback(() => {
    setLoading(true);
    TapNovelRestApi.post('/api/writer/user_service_agreements', {
      writer_user_service_agreement: {
        writer_service_agreement_code: 'full_video',
      },
    })
      .then(response => {
        setTimeout(() => {
          showCurrentUserStatus().then(setCurrentUserStatus);
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Layout
      title={title}
      navigation={navigation}
      scrollable={false}
      back={true}
      loading={loading}>
      {currentUserStatus ? (
        currentUserStatus.serviceAgreementCodes.includes('full_video') ? (
          <Tabs
            onSelectEpisodeVideoExportJobExecution={
              onSelectEpisodeVideoExportJobExecution
            }
            onForwardToNewVerticalEpisodeVideoExportJobExecution={
              onForwardToNewVerticalEpisodeVideoExportJobExecution
            }
            onForwardToNewHorizontalEpisodeVideoExportJobExecution={
              onForwardToNewHorizontalEpisodeVideoExportJobExecution
            }
          />
        ) : (
          <ServiceExplanation
            onAgreeServicePolicy={onAgreeServicePolicy}
            setTitle={setTitle}
          />
        )
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
