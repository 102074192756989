import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import IllustrationUsageHistoryMapper, {
  IllustrationUsageHistoryData,
} from '../../entities/mappers/IllustrationUsageHistoryMapper';

import IllustrationUsageHistory from '../../../domain/entities/IllustrationUsageHistory';
import IllustrationUsageHistoriesRepository from '../../../domain/repositories/writer/IllustrationUsageHistoriesRepository';

export default class NetIllustrationUsageHistoriesRepository
  extends NetResourcesRepository<
    IllustrationUsageHistory,
    IllustrationUsageHistoryData
  >
  implements IllustrationUsageHistoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/illustration_usage_histories',
        new IllustrationUsageHistoryMapper(),
      ),
    );
  }
}
