import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import CharacterView from './CharacterView';

import Tabs from '../tabs/Tabs';
import ConfirmButton from '../buttons/ConfirmButton';
import LabelList from '../lists/LabelList';
import ImageList from '../lists/ImageList';

import DimensionContext from '../../../../../shared/dimension/DimensionContext';

import {Params as ActorCharacterFaceIndexParams} from '../../../../../../actions/actor_character_faces/index';
import {Params as ActorCostumeIndexParams} from '../../../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../../../actions/actor_accessory_sets/index';

import {QueryState} from '../../../../../../reducers/queries/Response';

import selectEntities from '../../../../../../helpers/selectEntities';

import {CharacterSceneCommandForm} from '../../../../../../view_models/SceneFrame';

import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';
import ActorCharacterFace from '../../../../../../../domain/entities/ActorCharacterFace';
import ActorCostume from '../../../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../../../domain/entities/ActorHairStyle';
import ActorAccessorySet from '../../../../../../../domain/entities/ActorAccessorySet';
import Mark from '../../../../../../../domain/entities/Mark';
import PaginatedResult from '../../../../../../../domain/results/PaginatedResult';

type TabValue = 'face' | 'mark' | 'costume' | 'hair_style' | 'accessory_set';

interface Props {
  sceneCommandForm: CharacterSceneCommandForm;
  actorCharacterFaceEntities: {[key: number]: ActorCharacterFace};
  actorCharacterFaceQueries: QueryState;
  marks: Mark[] | null;
  inverted?: boolean;
  onRequestIndexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  onRequestIndexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  onRequestIndexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  onRequestIndexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  onRequestIndexMarks: () => Promise<PaginatedResult<Mark>>;
  onSubmit: (
    selectedActorCharacterFace: ActorCharacterFace,
    selectedMark: Mark | null,
  ) => void;
}

interface State {
  currentTab: TabValue;
  actorCharacterFaces: ActorCharacterFace[] | null;
  marks: Mark[] | null;
  actorCostumes: ActorCostume[] | null;
  actorHairStyles: ActorHairStyle[] | null;
  actorAccessorySets: ActorAccessorySet[] | null;
  selectedActorCharacter: ActorCharacter;
  selectedActorCharacterFace: ActorCharacterFace;
  selectedMark: Mark;
}

export default class CharacterViewWithEditor extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    const {sceneCommandForm} = props;
    this.state = {
      currentTab: 'face',
      actorCharacterFaces: this.getActorCharacterFaces(
        props.sceneCommandForm.actorCharacterFace,
      ),
      marks: null,
      actorCostumes: null,
      actorHairStyles: null,
      actorAccessorySets: null,
      selectedActorCharacter: sceneCommandForm.characterPattern.actorCharacter,
      selectedActorCharacterFace: sceneCommandForm.actorCharacterFace,
      selectedMark: sceneCommandForm.mark || noMark,
    };
  }

  public componentDidMount() {
    this.requestEntities();
  }

  public render(): React.ReactNode {
    const {marks, inverted} = this.props;
    const {
      currentTab,
      actorCharacterFaces,
      actorCostumes,
      actorHairStyles,
      actorAccessorySets,
      selectedActorCharacter,
      selectedActorCharacterFace,
      selectedMark,
    } = this.state;
    if (!actorCharacterFaces || !marks) {
      return null;
    }
    return (
      <DimensionContext.Consumer>
        {context => (
          <View style={styles.body}>
            <CharacterView
              actorCharacterFace={selectedActorCharacterFace}
              mark={selectedMark}
              inverted={inverted}
            />
            <View style={styles.bodyFooter}>
              <Tabs<TabValue>
                items={[
                  {value: 'face', label: '表情'},
                  {value: 'mark', label: 'アイコン'},
                  {
                    value: 'costume',
                    label: '衣装',
                    disabled: (actorCostumes || []).length <= 1,
                  },
                  {
                    value: 'hair_style',
                    label: '髪色',
                    disabled: (actorHairStyles || []).length <= 1,
                  },
                  {
                    value: 'accessory_set',
                    label: 'アクセサリー',
                    disabled: (actorAccessorySets || []).length <= 1,
                  },
                ]}
                currentValue={currentTab}
                onChangeTab={this.handleChangeTab}
              />
              <LabelList
                visible={currentTab === 'face'}
                entities={actorCharacterFaces}
                selectedEntityId={selectedActorCharacterFace.id}
                onSelectEntity={this.handleSelectActorCharacterFace}
              />
              <ImageList
                visible={currentTab === 'mark'}
                entities={[noMark, ...marks]}
                selectedEntityId={selectedMark.id}
                onSelectEntity={this.handleSelectMark}
              />
              <LabelList
                visible={currentTab === 'costume'}
                entities={actorCostumes}
                selectedEntityId={selectedActorCharacter.actorCostumeId}
                onSelectEntity={this.handleSelectActorCostume}
              />
              <LabelList
                visible={currentTab === 'hair_style'}
                entities={actorHairStyles}
                selectedEntityId={selectedActorCharacter.actorHairStyleId}
                onSelectEntity={this.handleSelectActorHairStyle}
              />
              <LabelList
                visible={currentTab === 'accessory_set'}
                entities={actorAccessorySets}
                selectedEntityId={selectedActorCharacter.actorAccessorySetId}
                onSelectEntity={this.handleSelectActorAccessorySet}
              />
              <View
                style={{
                  alignItems: 'center',
                  marginVertical: context.content.width > 320 ? 15 : 10,
                }}>
                <ConfirmButton onPress={this.handlePress} />
              </View>
            </View>
          </View>
        )}
      </DimensionContext.Consumer>
    );
  }

  private requestEntities = () => {
    const {
      marks,
      onRequestIndexActorCharacterFaces,
      onRequestIndexMarks,
      onRequestIndexActorAccessorySets,
      onRequestIndexActorCostumes,
      onRequestIndexActorHairStyles,
    } = this.props;
    const {selectedActorCharacter, selectedActorCharacterFace} = this.state;
    const actorCharacterId = selectedActorCharacterFace.actorCharacterId;
    const actorCharacterFacesParams = {actorCharacterId};
    const actorCharacterFaces = this.getActorCharacterFaces(
      selectedActorCharacterFace,
    );
    if (!actorCharacterFaces) {
      onRequestIndexActorCharacterFaces(actorCharacterFacesParams).then(
        result => {
          this.setState({actorCharacterFaces: result.records});
        },
      );
    }
    onRequestIndexActorCostumes({
      actorId: selectedActorCharacter.actorId,
      actorHairStyleId: selectedActorCharacter.actorHairStyleId,
      actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
    }).then(result => {
      this.setState({actorCostumes: result.records});
    });
    onRequestIndexActorHairStyles({
      actorId: selectedActorCharacter.actorId,
      actorCostumeId: selectedActorCharacter.actorCostumeId,
      actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
    }).then(result => {
      this.setState({actorHairStyles: result.records});
    });
    onRequestIndexActorAccessorySets({
      actorId: selectedActorCharacter.actorId,
      actorCostumeId: selectedActorCharacter.actorCostumeId,
      actorHairStyleId: selectedActorCharacter.actorHairStyleId,
    }).then(result => {
      this.setState({actorAccessorySets: result.records});
    });
    if (!marks) {
      onRequestIndexMarks().then(result => {
        this.setState({marks: result.records});
      });
    }
  };

  private getActorCharacterFaces = (actorCharacterFace: ActorCharacterFace) => {
    const {actorCharacterFaceEntities, actorCharacterFaceQueries} = this.props;
    const actorCharacterId = actorCharacterFace.actorCharacterId;
    const actorCharacterFacesParams = {actorCharacterId};
    return selectEntities(
      actorCharacterFaceEntities,
      actorCharacterFaceQueries,
      actorCharacterFacesParams,
    );
  };

  private handleChangeTab = (currentTab: TabValue) => {
    const {
      onRequestIndexActorCharacterFaces,
      onRequestIndexActorCostumes,
      onRequestIndexActorHairStyles,
    } = this.props;
    const {selectedActorCharacter} = this.state;
    if (this.state.currentTab === currentTab) {
      return;
    }
    this.setState({currentTab});
    switch (currentTab) {
      case 'face':
        onRequestIndexActorCharacterFaces({
          actorCharacterId: selectedActorCharacter.id,
        }).then(result => {
          this.setState({actorCharacterFaces: result.records});
        });
        return;
      case 'costume':
        onRequestIndexActorCostumes({
          actorId: selectedActorCharacter.actorId,
          actorHairStyleId: selectedActorCharacter.actorHairStyleId,
          actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
        }).then(result => {
          this.setState({actorCostumes: result.records});
        });
        return;
      case 'hair_style':
        onRequestIndexActorHairStyles({
          actorId: selectedActorCharacter.actorId,
          actorCostumeId: selectedActorCharacter.actorCostumeId,
          actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
        }).then(result => {
          this.setState({actorHairStyles: result.records});
        });
        return;
    }
  };

  private handleSelectActorCharacterFace = (
    selectedActorCharacterFace: ActorCharacterFace,
  ) => {
    this.setState({selectedActorCharacterFace});
  };

  private handleSelectMark = (selectedMark: Mark) => {
    this.setState({selectedMark});
  };

  private handleSelectActorCostume = (actorCostume: ActorCostume) => {
    const {onRequestIndexActorCharacterFaces} = this.props;
    const {selectedActorCharacterFace: oldSelectedActorCharacterFace} =
      this.state;
    onRequestIndexActorCharacterFaces({
      actorCharacterId: actorCostume.actorCharacter.id,
    }).then(result => {
      const actorCharacterFaces = result.records;
      const selectedActorCharacterFace =
        actorCharacterFaces.find(
          actorCharacterFace =>
            actorCharacterFace.faceName ===
            oldSelectedActorCharacterFace.faceName,
        ) || actorCharacterFaces[0];
      this.setState({
        selectedActorCharacter: actorCostume.actorCharacter,
        actorCharacterFaces,
        selectedActorCharacterFace,
      });
    });
  };

  private handleSelectActorHairStyle = (actorHairStyle: ActorHairStyle) => {
    const {onRequestIndexActorCharacterFaces} = this.props;
    const {selectedActorCharacterFace: oldSelectedActorCharacterFace} =
      this.state;
    onRequestIndexActorCharacterFaces({
      actorCharacterId: actorHairStyle.actorCharacter.id,
    }).then(result => {
      const actorCharacterFaces = result.records;
      const selectedActorCharacterFace =
        actorCharacterFaces.find(
          actorCharacterFace =>
            actorCharacterFace.faceName ===
            oldSelectedActorCharacterFace.faceName,
        ) || actorCharacterFaces[0];
      this.setState({
        selectedActorCharacter: actorHairStyle.actorCharacter,
        actorCharacterFaces,
        selectedActorCharacterFace,
      });
    });
  };

  private handleSelectActorAccessorySet = (
    actorAccessorySet: ActorAccessorySet,
  ) => {
    const {onRequestIndexActorCharacterFaces} = this.props;
    const {selectedActorCharacterFace: oldSelectedActorCharacterFace} =
      this.state;
    onRequestIndexActorCharacterFaces({
      actorCharacterId: actorAccessorySet.actorCharacter.id,
    }).then(result => {
      const actorCharacterFaces = result.records;
      const selectedActorCharacterFace =
        actorCharacterFaces.find(
          actorCharacterFace =>
            actorCharacterFace.faceName ===
            oldSelectedActorCharacterFace.faceName,
        ) || actorCharacterFaces[0];
      this.setState({
        selectedActorCharacter: actorAccessorySet.actorCharacter,
        actorCharacterFaces,
        selectedActorCharacterFace,
      });
    });
  };

  private handlePress = () => {
    const {onSubmit} = this.props;
    const {selectedActorCharacterFace, selectedMark} = this.state;
    onSubmit(
      selectedActorCharacterFace,
      selectedMark?.id === 0 ? null : selectedMark,
    );
  };
}

const noMark = new Mark(0, '');

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#fafafa',
  } as ViewStyle,
  bodyFooter: {
    backgroundColor: 'white',
    width: '100%',
  } as ViewStyle,
});
