import EntityMapper from './EntityMapper';

import Genre from '../../../domain/entities/Genre';

export interface GenreData {
  id: number;
  code: string;
  name: string;
}

export default class GenreMapper implements EntityMapper<GenreData, Genre> {
  public map(obj: GenreData): Genre {
    return new Genre(obj.id, obj.code, obj.name);
  }
}
