import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/effect_show_scene_command/edit/effect_usage_histories/Index';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import indexEffectUsageHistories, {
  Params as EffectUsageHistoryIndexParams,
} from '../../../../../actions/effect_usage_histories/index';
import updateEffectUsageHistory, {
  Params as EffectUsageHistoryUpdateParams,
} from '../../../../../actions/effect_usage_histories/update';
import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../../../../actions/scene_command_forms/create';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import selectEntities from '../../../../../helpers/selectEntities';
import {enabledResourceFavorite} from '../../../../../helpers/ResourceAuthorizattion';

import {SceneFormEffectShowSceneCommandEditEffectUsageHistoriesRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandEditEffectUsageHistoriesRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {storyId, parentSceneCommandId} = route.params;
  const sceneForm = forms.sceneForm;
  const effectUsageHistoriesParams: EffectUsageHistoryIndexParams = {
    storyId,
  };
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  const effectUsageHistories = selectEntities(
    entities.effectUsageHistories,
    queries.effectUsageHistories,
    effectUsageHistoriesParams,
  );
  return {
    sceneForm,
    effectUsageHistories,
    effectUsageHistoriesParams,
    navigation,
    parentSceneCommandForm,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
    indexEffectUsageHistories: (params: EffectUsageHistoryIndexParams) =>
      dispatch(indexEffectUsageHistories(params)),
    updateEffectUsageHistory: (
      id: number,
      params: EffectUsageHistoryUpdateParams,
    ) => dispatch(updateEffectUsageHistory(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
