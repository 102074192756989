import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import AnnouncementListItem from './AnnouncementListItem';

import shouldUpdateEntityList from '../../shared/enhanced/shouldUpdateEntityList';
import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import Announcement from '../../../../domain/entities/writer/Announcement';

interface Props {
  announcements: Announcement[];
  refreshing?: boolean | null;
  onSelectAnnouncement: (announcement: Announcement) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  onRefresh?: () => void;
}

export default class AnnouncementList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (this.props.refreshing !== nextProps.refreshing) {
      return true;
    }
    return shouldUpdateEntityList(
      {entities: this.props.announcements},
      {entities: nextProps.announcements},
    );
  }

  public render(): React.ReactNode {
    const {announcements, refreshing, onEndReached, onRefresh} = this.props;
    return (
      <FlatList
        data={announcements}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
        ListFooterComponent={ListItemNormalSeparator}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.1}
        refreshing={refreshing}
        onRefresh={onRefresh}
      />
    );
  }

  private keyExtractor = (
    announcement: Announcement,
    index: number,
  ): string => {
    return `${announcement.id}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<Announcement>,
  ): React.ReactElement<any> | null => {
    const {item: announcement} = info;
    const {onSelectAnnouncement} = this.props;
    return (
      <AnnouncementListItem
        announcement={announcement}
        onSelectAnnouncement={onSelectAnnouncement}
      />
    );
  };
}
