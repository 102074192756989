import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import EpisodeCommentReply from '../../../domain/entities/EpisodeCommentReply';

import NetEpisodeCommentRepliesRepository from '../../../data/repositories/writer/NetEpisodeCommentRepliesRepository';

const types = {
  failure: Types.ShowEpisodeCommentReplyFailure,
  request: Types.ShowEpisodeCommentReplyRequest,
  success: Types.ShowEpisodeCommentReplySuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<EpisodeCommentReply> {
  return showGeneric(new NetEpisodeCommentRepliesRepository(), types, id);
}
