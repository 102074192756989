import * as React from 'react';

import GenreList from './partials/GenreList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {StoryFormMainGenreRouteProp} from '../../../navigators/RouteProps';

import {Params as GenreIndexParams} from '../../../actions/genres/index';
import {Params as StoryFormUpdateParams} from '../../../actions/story_forms/update';

import Genre from '../../../../domain/entities/Genre';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {
  storyId?: number;
  mainGenreId: number | null;
  subGenreIds: number[] | null;
  callback?: (mainGenreId: number) => void;
}

export interface StateProps {
  navigation: NavigationProp;
  route: StoryFormMainGenreRouteProp;
  genresParams: GenreIndexParams;
  genres: Genre[] | null;
}

export interface DispatchProps {
  indexGenres: (params: GenreIndexParams) => Promise<PaginatedResult<Genre>>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {genresParams, indexGenres, genres} = this.props;
    if (!genres) {
      indexGenres(genresParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, genres} = this.props;
    const {mainGenreId, subGenreIds} = route.params;
    return (
      <Layout title={'メインジャンル'} navigation={navigation} back={true}>
        {genres && (
          <GenreList
            genres={genres}
            mainGenreId={mainGenreId}
            subGenreIds={subGenreIds}
            onSelectGenre={this.handleSelectGenre}
          />
        )}
      </Layout>
    );
  }

  private handleSelectGenre = (genre: Genre) => {
    const {navigation, route, updateStoryForm} = this.props;
    const {callback} = route.params;
    if (callback) {
      callback(genre.id);
    } else {
      updateStoryForm({mainGenreId: genre.id});
    }
    navigation.goBack();
  };
}
