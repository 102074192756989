import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import PenIcon from '../../shared/icons/PenIcon';
import {colors} from '../../../styles/variables';

interface Props {
  disabled?: boolean | undefined;
  onPress: () => void;
}

const EditButton: React.FC<Props> = props => {
  const {disabled, onPress} = props;
  return (
    <TouchableOpacity activeOpacity={0.6} disabled={disabled} onPress={onPress}>
      <View
        style={[
          styles.container,
          disabled ? {borderColor: colors.disableColor} : null,
        ]}>
        <PenIcon
          size={13}
          color={disabled ? colors.disableTextColor : '#383838'}
        />
        <Text
          style={[
            styles.label,
            disabled ? {color: colors.disableTextColor} : null,
          ]}>
          編集
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(EditButton);

const styles = StyleSheet.create({
  container: {
    width: 100,
    height: 28,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#383838',
    borderWidth: 1,
    borderRadius: 16,
    marginHorizontal: 5,
  } as ViewStyle,
  label: {
    marginLeft: 4,
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
