import * as React from 'react';

import BaseIndex from '../../../shared/generics/character_maker/home/Index';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CoverImageFormCharacterMakerHomeRouteProp} from '../../../../navigators/RouteProps';

import {Params as CharacterMakerActorGroupIndexParams} from '../../../../actions/character_maker_actor_groups/index';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';
import CharacterMakerActorGroup from '../../../../../domain/entities/CharacterMakerActorGroup';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormCharacterMakerHomeRouteProp;
  currentUser: CurrentUser | null;
  characterMakerActorGroups: CharacterMakerActorGroup[] | null;
  characterMakerActorGroupsParams: CharacterMakerActorGroupIndexParams;
}

export interface DispatchProps {
  indexCharacterMakerActorGroups: (
    params: CharacterMakerActorGroupIndexParams,
  ) => Promise<PaginatedResult<CharacterMakerActorGroup>>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, route, currentUser} = props;
  const {params} = route;
  const onSelectCharacterMakerActorGroup = React.useCallback(
    (
      characterMakerActorGroup: CharacterMakerActorGroup,
      skinColorPatternId: number,
    ) => {
      navigation.navigate('CoverImageFormCharacterMakerActors', {
        ...params,
        characterMakerActorGroupId: characterMakerActorGroup.id,
        skinColorPatternId,
      });
    },
    [],
  );
  return (
    <BaseIndex
      {...props}
      enableSkinColorPattern={currentUser?.isBlackOrSubscriber()}
      onSelectCharacterMakerActorGroup={onSelectCharacterMakerActorGroup}
    />
  );
};

export default React.memo(Index);
