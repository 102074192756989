import * as React from 'react';
import {StyleProp, TextStyle} from 'react-native';

import ElasticBoxText from './ElasticBoxText';

import NameLabelColor from '../../view_models/NameLabelColor';

import {orientedSpeechBalloonPartImageUrl} from '../../helpers/images';

import SpeechBalloon from '../../../domain/entities/SpeechBalloon';
import OrientedSpeechBalloon from '../../../domain/entities/OrientedSpeechBalloon';

import FramePartPosition from '../../../domain/value_objects/FramePartPosition';

interface Props {
  text: string;
  name?: string;
  nameLabelColor?: NameLabelColor;
  hasVoice?: boolean;
  hasSound?: boolean;
  orientedSpeechBalloon: OrientedSpeechBalloon | SpeechBalloon;
  width: number;
  height?: number;
  numberOfLines?: number;
  textStyle?: StyleProp<TextStyle>;
}

const ElasticSpeechBalloon: React.FunctionComponent<Props> = props => {
  const {
    text,
    name,
    nameLabelColor,
    hasVoice,
    hasSound,
    orientedSpeechBalloon,
    width,
    height,
    numberOfLines,
    textStyle,
  } = props;
  const top = orientedSpeechBalloon.getPartImageUrlOf(FramePartPosition.Top);
  const middle = orientedSpeechBalloon.getPartImageUrlOf(
    FramePartPosition.Middle,
  );
  const bottom = orientedSpeechBalloon.getPartImageUrlOf(
    FramePartPosition.Bottom,
  );
  return (
    <ElasticBoxText
      key={`speech_balloon_${orientedSpeechBalloon.id}_${text}_${width}`}
      text={text}
      top={orientedSpeechBalloonPartImageUrl(top, 'large')}
      middle={orientedSpeechBalloonPartImageUrl(middle, 'large')}
      bottom={orientedSpeechBalloonPartImageUrl(bottom, 'large')}
      width={width}
      height={height}
      textStyle={[
        orientedSpeechBalloon.textStyle,
        name !== undefined
          ? {paddingTop: 30}
          : {paddingTop: 15, paddingBottom: 15},
        textStyle,
      ]}
      middleStyle={name ? orientedSpeechBalloon.containerStyle : undefined}
      voiceIconStyle={orientedSpeechBalloon.voiceIconStyle}
      name={name}
      nameLabelColor={nameLabelColor}
      hasVoice={hasVoice}
      hasSound={hasSound}
      numberOfLines={numberOfLines}
    />
  );
};

export default React.memo(ElasticSpeechBalloon);
