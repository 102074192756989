import * as React from 'react';

import CharacterPositionList from './partials/CharacterPositionList';
import ToggleButton from './partials/ToggleButton';

import BottomPrimaryButton from '../../../../shared/buttons/BottomPrimaryButton';
import Layout from '../../../../shared/Layout';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormSpeechTextShowSceneCommandNewSpeechTextPositionsRouteProp} from '../../../../../navigators/RouteProps';

import SceneFrame from '../../../../../view_models/SceneFrame';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';
import scrollableLayout from '../../../../../helpers/scrollableLayout';

import Position from '../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../domain/forms/SceneForm';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';

export interface Params {
  episodeId?: number;
  sceneId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormSpeechTextShowSceneCommandNewSpeechTextPositionsRouteProp;
  sceneForm: SceneForm | null;
  sceneCommandIndex?: number;
  sceneCommandForms: SceneCommandForm[] | null;
}

export interface DispatchProps {
  onSelectPositions: (
    positions: Position[],
    overrideCharacterName?: boolean,
    characterName?: string,
  ) => void;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  enabledMultiplePositions: boolean;
  selectedPositions: Position[];
  overrideCharacterName: boolean;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      enabledMultiplePositions: false,
      selectedPositions: [],
      overrideCharacterName: false,
    };
  }

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {navigation, sceneCommandForms, sceneCommandIndex} = this.props;
    const {selectedPositions, overrideCharacterName, enabledMultiplePositions} =
      this.state;
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={'吹き出しの向き'}
            scrollable={scrollableLayout(context)}
            navigation={navigation}
            close={true}>
            <ToggleButton
              value={overrideCharacterName}
              onPress={this.toggleOverrideCharacterName}
            />
            <CharacterPositionList
              selectedPositions={selectedPositions}
              overrideCharacterName={overrideCharacterName}
              sceneCommandForms={sceneCommandForms}
              sceneCommandIndex={sceneCommandIndex}
              onSelectPositions={this.handleSelectPositions}
              onToggleMultiplePositions={this.handleToggleMultiplePositions}
            />
            {enabledMultiplePositions && (
              <BottomPrimaryButton
                disabled={selectedPositions.length === 0}
                onPress={this.handleSubmit}>
                確定
              </BottomPrimaryButton>
            )}
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private handleSelectPositions = (
    positions: Position[],
    sceneFrame: SceneFrame,
  ) => {
    const {onSelectPositions} = this.props;
    const {overrideCharacterName} = this.state;
    if (this.state.enabledMultiplePositions) {
      this.updateSelectedPositions(positions);
    } else {
      let characterName = undefined;
      if (positions.length === 1) {
        const position = positions[0];
        if (sceneFrame.isActive(position)) {
          const characterSceneCommandForm = sceneFrame.get(position);
          if (characterSceneCommandForm) {
            characterName =
              characterSceneCommandForm.characterPattern.character.name;
          }
        }
      }
      onSelectPositions(positions, overrideCharacterName, characterName);
    }
  };

  private handleToggleMultiplePositions = (positions: Position[]) => {
    const {enabledMultiplePositions} = this.state;
    this.setState({enabledMultiplePositions: !enabledMultiplePositions}, () => {
      if (enabledMultiplePositions) {
        this.setState({selectedPositions: []});
      } else {
        this.updateSelectedPositions(positions);
      }
    });
  };

  private handleSubmit = () => {
    const {onSelectPositions} = this.props;
    const {selectedPositions, overrideCharacterName} = this.state;
    if (selectedPositions.length === 0) {
      return;
    }
    if (selectedPositions.length === 1) {
      onSelectPositions(selectedPositions, overrideCharacterName);
    } else {
      onSelectPositions(selectedPositions);
    }
  };

  private updateSelectedPositions = (positions: Position[]) => {
    const {selectedPositions} = this.state;
    const map = new Map<Position, boolean>();
    selectedPositions.forEach(pos => map.set(pos, true));
    positions.forEach(pos => {
      const val = map.get(pos);
      if (val === true) {
        map.delete(pos);
      } else {
        map.set(pos, true);
      }
    });
    this.setState({
      selectedPositions: Array.from(map.keys()),
    });
  };

  private toggleOverrideCharacterName = () => {
    const {overrideCharacterName} = this.state;
    this.setState({
      overrideCharacterName: !overrideCharacterName,
      enabledMultiplePositions: false,
      selectedPositions: [],
    });
  };
}
