import * as React from 'react';

import TapNovelWebView from '../../shared/TapNovelWebView';
import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {getUserAgent} from '../../../../data/data_stores/net/UserAgent';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {
  children?: React.ReactNode;
}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation} = props;
  const [userAgent, setUserAgent] = React.useState<string | null>(null);
  React.useEffect(() => {
    getUserAgent().then(ua => {
      setUserAgent(ua);
    });
  });
  return (
    <Layout
      title={'資金決済法に基づく表示'}
      scrollable={false}
      navigation={navigation}
      back={true}>
      {userAgent && (
        <TapNovelWebView
          style={{flex: 1}}
          originWhitelist={['*']}
          path={'/policies/settlement_law?without_layout=on'}
          userAgent={userAgent}
        />
      )}
    </Layout>
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(Index, areEqual);
