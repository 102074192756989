import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ProjectList from './ProjectList';

import Project from '../../../../../../domain/entities/Project';

interface Props {
  title: string;
  projects: Project[];
}

const ProjectListSection: React.FC<Props> = props => {
  const {title, projects} = props;
  if (projects.length === 0) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <ProjectList projects={projects} />
    </View>
  );
};

export default React.memo(ProjectListSection);

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    marginVertical: 8,
    backgroundColor: '#fff',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 8,
  } as TextStyle,
});
