import * as React from 'react';

import CharacterView from './CharacterView';
import Tabs from './Tabs';
import ActorCharacterFaceList from './ActorCharacterFaceList';
import MarkList from './MarkList';
import EntityList from './EntityList';

import NavigationProp from '../../../../../../navigators/NavigationProp';

import Layout from '../../../../Layout';
import HeaderRightButton from '../../../../buttons/HeaderRightButton';

import DimensionContext from '../../../../dimension/DimensionContext';

import {Params as CharacterPatternIndexParams} from '../../../../../../actions/character_patterns/index';
import {Params as CharacterPatternCreateParams} from '../../../../../../actions/character_patterns/create';
import {Params as ActorCharacterFaceIndexParams} from '../../../../../../actions/actor_character_faces/index';
import {Params as ActorCostumeIndexParams} from '../../../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../../../actions/actor_accessory_sets/index';
import {Params as MarkIndexParams} from '../../../../../../actions/marks/index';

import scrollableLayout from '../../../../../../helpers/scrollableLayout';

import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';
import ActorCharacterFace from '../../../../../../../domain/entities/ActorCharacterFace';
import ActorCostume from '../../../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../../../domain/entities/ActorHairStyle';
import ActorAccessorySet from '../../../../../../../domain/entities/ActorAccessorySet';
import Character from '../../../../../../../domain/entities/Character';
import CharacterPattern from '../../../../../../../domain/entities/CharacterPattern';
import Mark from '../../../../../../../domain/entities/Mark';
import PaginatedResult from '../../../../../../../domain/results/PaginatedResult';

type Tab = 'face' | 'mark' | 'costume' | 'hair_style' | 'actor_accessory';

interface Props {
  navigation: NavigationProp;
  back?: boolean;
  loading?: boolean;
  characterId: number;
  characterPatternId: number;
  marksParams: MarkIndexParams;
  marks: Mark[] | null;
  character: Character | null;
  characterPattern: CharacterPattern;
  hideTab?: boolean;
  onSelectActorCharacterFace: (
    characterPattern: CharacterPattern,
    actorCharacterFace: ActorCharacterFace,
    mark?: Mark | null,
  ) => void;
  showCharacter: (id: number) => Promise<Character>;
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  createCharacterPattern: (
    params: CharacterPatternCreateParams,
  ) => Promise<CharacterPattern>;
  updateCharacterPatternUsageHistory: (id: number) => Promise<CharacterPattern>;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  indexMarks: (params: MarkIndexParams) => Promise<PaginatedResult<Mark>>;
}

interface State {
  activeTab: Tab;
  actorCharacterFaces: ActorCharacterFace[] | null;
  actorCostumes: ActorCostume[] | null;
  actorHairStyles: ActorHairStyle[] | null;
  actorAccessorySets: ActorAccessorySet[] | null;
  selectedActorCharacter: ActorCharacter;
  selectedActorCharacterFace: ActorCharacterFace | null;
  selectedMark: Mark | null;
}

export default class Index extends React.PureComponent<Props, State> {
  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (
      !prevState.selectedActorCharacterFace &&
      prevState.actorCharacterFaces
    ) {
      return {selectedActorCharacterFace: prevState.actorCharacterFaces[0]};
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      activeTab: 'face',
      actorCharacterFaces: null,
      actorCostumes: null,
      actorHairStyles: null,
      actorAccessorySets: null,
      selectedActorCharacter: props.characterPattern.actorCharacter,
      selectedActorCharacterFace: null,
      selectedMark: new Mark(0, ''),
    };
  }

  public componentDidMount() {
    const {
      character,
      marks,
      characterId,
      marksParams,
      showCharacter,
      indexActorCharacterFaces,
      indexActorCostumes,
      indexActorHairStyles,
      indexActorAccessorySets,
      indexMarks,
    } = this.props;
    const {actorCharacterFaces, selectedActorCharacter} = this.state;
    if (!character) {
      showCharacter(characterId);
    }
    if (!actorCharacterFaces) {
      const actorCharacterFacesParams = {
        actorCharacterId: selectedActorCharacter.id,
      };
      indexActorCharacterFaces(actorCharacterFacesParams).then(result => {
        this.setState({actorCharacterFaces: result.records});
      });
    }
    if (!marks) {
      indexMarks(marksParams);
    }
    indexActorCostumes({
      actorId: selectedActorCharacter.actorId,
      actorHairStyleId: selectedActorCharacter.actorHairStyleId,
      actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
    }).then(result => {
      this.setState({actorCostumes: result.records});
    });
    indexActorHairStyles({
      actorId: selectedActorCharacter.actorId,
      actorCostumeId: selectedActorCharacter.actorCostumeId,
      actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
    }).then(result => {
      this.setState({actorHairStyles: result.records});
    });
    indexActorAccessorySets({
      actorId: selectedActorCharacter.actorId,
      actorCostumeId: selectedActorCharacter.actorCostumeId,
      actorHairStyleId: selectedActorCharacter.actorHairStyleId,
    }).then(result => {
      this.setState({actorAccessorySets: result.records});
    });
  }

  public render(): React.ReactNode {
    const {navigation, back, loading, marks, hideTab, character} = this.props;
    const {
      actorCharacterFaces,
      actorCostumes,
      actorHairStyles,
      actorAccessorySets,
      activeTab,
      selectedActorCharacter,
      selectedActorCharacterFace,
      selectedMark,
    } = this.state;
    return (
      <DimensionContext.Consumer>
        {context => {
          const scrollable = scrollableLayout(context);
          return (
            <Layout
              title={this.getTitle() + '選択'}
              scrollable={scrollable}
              navigation={navigation}
              back={back}
              loading={loading}
              rightButton={{
                tintColor: 'white',
                title: (
                  <HeaderRightButton onPress={this.handleSubmit}>
                    決定
                  </HeaderRightButton>
                ),
              }}>
              <>
                <CharacterView
                  actorCharacterFace={selectedActorCharacterFace}
                  mark={selectedMark}
                  width={context.content.width}
                  inverted={character?.inverted}
                />
                <Tabs
                  activeTab={activeTab}
                  hide={hideTab}
                  disabledCostume={(actorCostumes?.length || 0) <= 1}
                  disabledHairStyle={(actorHairStyles?.length || 0) <= 1}
                  disabledActorAccessory={
                    (actorAccessorySets?.length || 0) <= 1
                  }
                  onChangeTab={this.switchTab}
                />
                <ActorCharacterFaceList
                  visible={activeTab === 'face'}
                  actorCharacterFaces={actorCharacterFaces}
                  selectedActorCharacterFace={selectedActorCharacterFace}
                  width={context.content.width}
                  inverted={character?.inverted}
                  scrollEnabled={!scrollable}
                  onSelectActorCharacterFace={
                    this.handleSelectActorCharacterFace
                  }
                />
                <MarkList
                  visible={activeTab === 'mark'}
                  marks={marks}
                  selectedMark={selectedMark}
                  width={context.content.width}
                  scrollEnabled={!scrollable}
                  onSelectMark={this.handleSelectMark}
                />
                <EntityList
                  entities={actorCostumes}
                  visible={activeTab === 'costume'}
                  selectedEntityId={
                    selectedActorCharacter?.actorCostumeId || null
                  }
                  clipped={false}
                  width={context.content.width}
                  inverted={character?.inverted}
                  scrollEnabled={!scrollable}
                  onSelectEntity={this.handleSelectActorCostume}
                />
                <EntityList
                  entities={actorHairStyles}
                  visible={activeTab === 'hair_style'}
                  selectedEntityId={
                    selectedActorCharacter?.actorHairStyleId || null
                  }
                  clipped={true}
                  width={context.content.width}
                  inverted={character?.inverted}
                  scrollEnabled={!scrollable}
                  onSelectEntity={this.handleSelectActorHairStyle}
                />
                <EntityList
                  entities={actorAccessorySets}
                  visible={activeTab === 'actor_accessory'}
                  selectedEntityId={
                    selectedActorCharacter?.actorAccessorySetId || null
                  }
                  clipped={false}
                  width={context.content.width}
                  inverted={character?.inverted}
                  scrollEnabled={!scrollable}
                  onSelectEntity={this.handleSelectActorAccessorySet}
                />
              </>
            </Layout>
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private getTitle = () => {
    const {activeTab} = this.state;
    switch (activeTab) {
      case 'face':
        return '表情';
      case 'mark':
        return 'アイコン';
      case 'costume':
        return '衣装';
      case 'hair_style':
        return '髪色';
      case 'actor_accessory':
        return 'アクセサリー';
    }
  };

  private handleSelectActorCharacterFace = (
    selectedActorCharacterFace: ActorCharacterFace,
  ) => {
    this.setState({selectedActorCharacterFace});
  };

  private handleSelectActorCostume = (actorCostume: ActorCostume) => {
    const {indexActorCharacterFaces} = this.props;
    const {selectedActorCharacterFace: oldSelectedActorCharacterFace} =
      this.state;
    if (!oldSelectedActorCharacterFace) {
      return;
    }
    indexActorCharacterFaces({
      actorCharacterId: actorCostume.actorCharacter.id,
    }).then(result => {
      const actorCharacterFaces = result.records;
      const selectedActorCharacterFace =
        actorCharacterFaces.find(
          actorCharacterFace =>
            actorCharacterFace.faceName ===
            oldSelectedActorCharacterFace.faceName,
        ) || actorCharacterFaces[0];
      this.setState({
        selectedActorCharacter: actorCostume.actorCharacter,
        actorCharacterFaces,
        selectedActorCharacterFace,
      });
    });
  };

  private handleSelectActorHairStyle = (actorHairStyle: ActorHairStyle) => {
    const {indexActorCharacterFaces} = this.props;
    const {selectedActorCharacterFace: oldSelectedActorCharacterFace} =
      this.state;
    if (!oldSelectedActorCharacterFace) {
      return;
    }
    indexActorCharacterFaces({
      actorCharacterId: actorHairStyle.actorCharacter.id,
    }).then(result => {
      const actorCharacterFaces = result.records;
      const selectedActorCharacterFace =
        actorCharacterFaces.find(
          actorCharacterFace =>
            actorCharacterFace.faceName ===
            oldSelectedActorCharacterFace.faceName,
        ) || actorCharacterFaces[0];
      this.setState({
        selectedActorCharacter: actorHairStyle.actorCharacter,
        actorCharacterFaces,
        selectedActorCharacterFace,
      });
    });
  };

  private handleSelectActorAccessorySet = (
    actorAccessorySet: ActorAccessorySet,
  ) => {
    const {indexActorCharacterFaces} = this.props;
    const {selectedActorCharacterFace: oldSelectedActorCharacterFace} =
      this.state;
    if (!oldSelectedActorCharacterFace) {
      return;
    }
    indexActorCharacterFaces({
      actorCharacterId: actorAccessorySet.actorCharacter.id,
    }).then(result => {
      const actorCharacterFaces = result.records;
      const selectedActorCharacterFace =
        actorCharacterFaces.find(
          actorCharacterFace =>
            actorCharacterFace.faceName ===
            oldSelectedActorCharacterFace.faceName,
        ) || actorCharacterFaces[0];
      this.setState({
        selectedActorCharacter: actorAccessorySet.actorCharacter,
        actorCharacterFaces,
        selectedActorCharacterFace,
      });
    });
  };

  private handleSelectMark = (selectedMark: Mark) => {
    this.setState({selectedMark});
  };

  private switchTab = (activeTab: Tab) => {
    const {indexActorCharacterFaces, indexActorCostumes, indexActorHairStyles} =
      this.props;
    const {selectedActorCharacter} = this.state;
    if (this.state.activeTab === activeTab) {
      return;
    }
    this.setState({activeTab});
    switch (activeTab) {
      case 'face':
        indexActorCharacterFaces({
          actorCharacterId: selectedActorCharacter.id,
        }).then(result => {
          this.setState({actorCharacterFaces: result.records});
        });
        return;
      case 'costume':
        indexActorCostumes({
          actorId: selectedActorCharacter.actorId,
          actorHairStyleId: selectedActorCharacter.actorHairStyleId,
          actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
        }).then(result => {
          this.setState({actorCostumes: result.records});
        });
        return;
      case 'hair_style':
        indexActorHairStyles({
          actorId: selectedActorCharacter.actorId,
          actorCostumeId: selectedActorCharacter.actorCostumeId,
          actorAccessorySetId: selectedActorCharacter.actorAccessorySetId,
        }).then(result => {
          this.setState({actorHairStyles: result.records});
        });
        return;
    }
  };

  private handleSubmit = async () => {
    const {
      characterId,
      indexCharacterPatterns,
      createCharacterPattern,
      onSelectActorCharacterFace,
    } = this.props;
    const {selectedActorCharacter, selectedActorCharacterFace, selectedMark} =
      this.state;
    if (!selectedActorCharacter) {
      return;
    }
    const params = {
      characterId,
      actorCharacterId: selectedActorCharacter.id,
    };
    let characterPattern = (await indexCharacterPatterns(params)).records[0];
    if (!characterPattern) {
      characterPattern = await createCharacterPattern(params);
    }
    if (selectedActorCharacterFace) {
      onSelectActorCharacterFace(
        characterPattern,
        selectedActorCharacterFace,
        selectedMark?.id === 0 ? null : selectedMark,
      );
    }
  };
}
