import * as React from 'react';

import Icon from '../Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const CandyIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 35;
  const color = props.color || '#d652a2';
  return <Icon name={'support-candy'} size={size} color={color} />;
};

export default React.memo(CandyIcon);
