import UserPropertiesMapper, {
  UserPropertiesData,
} from '../value_objects/mappers/UserPropertiesMapper';

import JsonAsyncStorage from '../data_stores/storage/JsonAsyncStorage';

import UserProperties from '../../domain/value_objects/UserProperties';

import UserPropertiesRepository from '../../domain/repositories/UserPropertiesRepository';

export default class StorageUserPropertiesRepository
  implements UserPropertiesRepository
{
  private static KEY = 'user_properties';

  private mapper: UserPropertiesMapper;

  constructor() {
    this.mapper = new UserPropertiesMapper();
  }

  public async find(): Promise<UserProperties> {
    const row: UserPropertiesData | null = await JsonAsyncStorage.get(
      StorageUserPropertiesRepository.KEY,
    );
    if (!row) {
      throw new Error(`Not found ${StorageUserPropertiesRepository.KEY}`);
    }
    return this.mapper.map(row);
  }

  public async create(params: {
    user_id: number;
    writer_grade_id: number;
  }): Promise<UserProperties> {
    const row: UserPropertiesData = params;
    await JsonAsyncStorage.set(StorageUserPropertiesRepository.KEY, row);
    return this.mapper.map(row);
  }

  public async update(params: {
    user_id?: number;
    writer_grade_id?: number;
  }): Promise<UserProperties> {
    const lastRow: UserPropertiesData =
      (await JsonAsyncStorage.get(StorageUserPropertiesRepository.KEY)) || {};
    const row = {
      ...lastRow,
      ...params,
    };
    await JsonAsyncStorage.set(StorageUserPropertiesRepository.KEY, row);
    return this.mapper.map(row);
  }

  public async destroy(): Promise<void> {
    const row: UserPropertiesData = await JsonAsyncStorage.get(
      StorageUserPropertiesRepository.KEY,
    );
    if (!row) {
      return;
    }
    await JsonAsyncStorage.remove(StorageUserPropertiesRepository.KEY);
    return;
  }
}
