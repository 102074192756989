import * as React from 'react';

import BaseIndex from '../../common/full_screen_illustrations/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrationsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as FullScreenIllustrationIndexParams} from '../../../../../actions/full_screen_illustrations/index';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import FullScreenIllustration from '../../../../../../domain/entities/FullScreenIllustration';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandId: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrationsRouteProp;
  sceneForm: SceneForm | null;
  fullScreenIllustrationsParams: FullScreenIllustrationIndexParams;
  fullScreenIllustrations: FullScreenIllustration[] | null;
  parentSceneCommandForm: SceneCommandForm | null;
}

export interface DispatchProps {
  indexFullScreenIllustrations: (
    params: FullScreenIllustrationIndexParams,
  ) => Promise<PaginatedResult<FullScreenIllustration>>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        onSelectFullScreenIllustration={this.handleSelectFullScreenIllustration}
      />
    );
  }

  private handleSelectFullScreenIllustration = (
    fullScreenIllustration: FullScreenIllustration,
  ): void => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {navigation, route, parentSceneCommandForm, updateSceneCommandForm} =
      this.props;
    const {sceneCommandId} = route.params;
    const sceneCommandForm = new FullScreenIllustrationShowSceneCommandForm(
      fullScreenIllustration,
      null,
      null,
      null,
      sceneCommandId,
    );
    if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          sceneCommandForm,
        ),
      });
    } else {
      updateSceneCommandForm({
        sceneCommandForm,
      });
    }
    (navigation.getParent() || navigation).goBack();
  };
}
