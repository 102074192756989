import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import SocialLoginButton from './SocialLoginButton';

import LineLogin, {
  ErrorData,
  SuccessData,
} from '../../../../../domain/services/social_logins/LineLogin';

import LineIcon from '../../icons/LineIcon';

import {colors} from '../../../../styles/variables';

interface Props {
  onSuccess: (data: SuccessData) => void;
  onError: (error: ErrorData) => void;
  children?: React.ReactNode;
}

export default class LineLoginButton extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <SocialLoginButton color={'#efefef'} onPress={this.handlePress}>
        <View style={styles.button}>
          <LineIcon size={18} />
        </View>
      </SocialLoginButton>
    );
  }

  private handlePress = () => {
    const {onSuccess, onError} = this.props;
    new LineLogin().login({
      onError,
      onSuccess,
    });
  };
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 15,
    backgroundColor: colors.line,
    height: 30,
    justifyContent: 'center',
    width: 30,
  } as ViewStyle,
});
