import * as React from 'react';

import Headline from '../../../shared/Headline';
import EntityImageGridListWithModal from '../../../shared/EntityImageGridListWithModal';

import {baseBackgroundImageUrl} from '../../../../helpers/images';

import BaseBackground from '../../../../../domain/entities/BaseBackground';

interface Props {
  baseBackgrounds: BaseBackground[];
  onSelectBaseBackground: (baseBackground: BaseBackground) => void;
}

export default class WallpaperList extends React.Component<Props> {
  public render(): React.ReactNode {
    const {baseBackgrounds, onSelectBaseBackground} = this.props;
    if (baseBackgrounds.length === 0) {
      return null;
    }
    return (
      <>
        <Headline title={'壁紙'} />
        <EntityImageGridListWithModal
          entities={baseBackgrounds}
          aspectRatio={aspectRatio}
          resizeMode={'cover'}
          onSelectEntity={onSelectBaseBackground}
          imageUrlExtractor={this.imageUrlExtractor}
        />
      </>
    );
  }

  private imageUrlExtractor = (baseBackground: BaseBackground) => {
    return baseBackgroundImageUrl(baseBackground, 'middle');
  };
}

const aspectRatio = 1.25;
