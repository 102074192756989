import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import SpeechBalloon from '../../../domain/entities/SpeechBalloon';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetSpeechBalloonsRepository from '../../../data/repositories/NetSpeechBalloonsRepository';

const types = {
  failure: Types.IndexSpeechBalloonsFailure,
  request: Types.IndexSpeechBalloonsRequest,
  success: Types.IndexSpeechBalloonsSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<SpeechBalloon>> {
  return indexGenerics(new NetSpeechBalloonsRepository(), types, params);
}
