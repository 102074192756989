import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import ActorCategoryMapper, {
  ActorCategoryData,
} from '../entities/mappers/ActorCategoryMapper';

import ActorCategory from '../../domain/entities/ActorCategory';
import ActorCategoriesRepository from '../../domain/repositories/ActorCategoriesRepository';

export default class NetActorCategoriesRepository
  extends NetResourcesRepository<ActorCategory, ActorCategoryData>
  implements ActorCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/actor_categories',
        new ActorCategoryMapper(),
      ),
    );
  }
}
