import * as React from 'react';
import {Dispatch} from 'redux';
import {NavigationState, PartialState} from '@react-navigation/core';
import {
  NavigationContainer,
  LinkingOptions,
  DocumentTitleOptions,
} from '@react-navigation/native';

import DimensionProvider from './shared/dimension/DimensionProvider';
import UserStatusProvider from './shared/user_status/UserStatusProvider';
import Layout from './shared/application_layout/Layout';
import CoachmarkContext from './shared/coachmark/CoachmarkContext';
import ApplicationModal from './shared/modals/ApplicationModal';
import WarningModal from './shared/modals/WarningModal';

import ServerError from './errors/server_error/Index';

import {Params as CurrentUserShowParams} from '../actions/writer/current_user/show';
import {Params as ApplicationModalUpdateParams} from '../actions/application_modal/update';
import {Params as ApplicationActionModalUpdateParams} from '../actions/application_action_modal/update';

import CoachmarkState from '../view_models/CoachmarkState';

import Navigator from '../navigators/Index';
import {navigationRef} from '../navigators/navigationRef';
import LinkingConfig from '../navigators/LinkingConfig';
import {RootStackParamList} from '../navigators/RootStackParamList';

import CurrentUser from '../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../domain/entities/writer/CurrentUserStatus';

export interface StateProps {
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
  accessToken: {value: string | null} | null;
  applicationModal: {
    message: string;
    title?: string;
    action?: {label: string; callback: () => void};
  } | null;
  applicationActionModal: {
    message: string;
    callback: () => void;
  } | null;
  coachmarkModal: CoachmarkState | null;
}

export interface DispatchProps {
  dispatch: Dispatch<any>;
  showCurrentUser: (params?: CurrentUserShowParams) => Promise<CurrentUser>;
  showAccessToken: () => Promise<string | null>;
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
  updateApplicationActionModal: (
    params: ApplicationActionModalUpdateParams,
  ) => void;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  activeRouteName: string;
  serverError: boolean;
  ready: boolean;
}

export default class App extends React.PureComponent<Props, State> {
  private linking: LinkingOptions<RootStackParamList> = {
    prefixes: [],
    config: {
      screens: LinkingConfig,
      initialRouteName: 'MainStackNavigator',
    },
  };

  private documentTitle: DocumentTitleOptions = {
    formatter: (options, route) =>
      `${options?.title ?? route?.name}${
        (route?.name || '').startsWith('Reservation') ||
        (route?.name || '').startsWith('Community')
          ? ''
          : ' - TapNovelMaker'
      }`,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      activeRouteName: 'Home',
      serverError: false,
      ready: false,
    };
  }

  public componentDidMount() {
    const {showAccessToken, showCurrentUser} = this.props;
    const done = () => {
      this.setState({ready: true});
    };
    showAccessToken().then(() => {
      showCurrentUser().then(done).catch(done);
    });
  }

  public render(): React.ReactNode {
    const {
      currentUser,
      currentUserStatus,
      applicationModal,
      applicationActionModal,
      coachmarkModal,
    } = this.props;
    const {activeRouteName, serverError, ready} = this.state;
    if (!ready) {
      return null;
    }
    if (serverError) {
      return <ServerError onPressRetry={this.handlePressRetry} />;
    }
    return (
      <CoachmarkContext.Provider value={coachmarkModal}>
        <DimensionProvider>
          <UserStatusProvider
            currentUser={currentUser}
            currentUserStatus={currentUserStatus}>
            <Layout
              signedIn={!!currentUser}
              initial={!currentUser && activeRouteName === 'Home'}
              noLayout={
                (activeRouteName === 'Home' && !currentUser?.isActivated()) ||
                activeRouteName.includes('Monitor') ||
                activeRouteName.includes('Reservation') ||
                activeRouteName.includes('Community') ||
                activeRouteName.includes('Contest')
              }>
              <NavigationContainer
                ref={navigationRef}
                linking={this.linking}
                documentTitle={this.documentTitle}
                onStateChange={this.handleStateChange}
                onReady={this.handleReady}>
                <Navigator
                  signedIn={!!currentUser}
                  isPro={
                    !!currentUser?.enabledPaidSubscriber ||
                    !!currentUser?.isGradeBlack()
                  }
                />
              </NavigationContainer>
              {applicationModal && (
                <ApplicationModal
                  visible={true}
                  title={applicationModal.title}
                  action={applicationModal.action}
                  onRequestClose={this.handleRequestCloseApplicationModal}>
                  {applicationModal.message}
                </ApplicationModal>
              )}
              {applicationActionModal && (
                <WarningModal
                  visible={true}
                  onAccept={this.handleAcceptApplicationActionModal}
                  onRequestClose={
                    this.handleRequestCloseApplicationActionModal
                  }>
                  {applicationActionModal.message}
                </WarningModal>
              )}
            </Layout>
          </UserStatusProvider>
        </DimensionProvider>
      </CoachmarkContext.Provider>
    );
  }

  private handlePressRetry = () => {
    const {showAccessToken} = this.props;
    showAccessToken();
  };

  private handleStateChange = (state: NavigationState | undefined) => {
    if (state) {
      this.setState({activeRouteName: getActiveRouteName(state)});
    }
  };

  private handleReady = () => {
    if (!navigationRef.current) {
      return;
    }
    const route = navigationRef.current.getCurrentRoute();
    this.setState({activeRouteName: route?.name || ''});
  };

  private handleRequestCloseApplicationModal = () => {
    const {updateApplicationModal} = this.props;
    updateApplicationModal({options: null});
  };

  private handleAcceptApplicationActionModal = () => {
    const {applicationActionModal} = this.props;
    if (!applicationActionModal) {
      return;
    }
    if (!navigationRef.current) {
      return;
    }
    this.handleRequestCloseApplicationActionModal();
    applicationActionModal.callback();
  };

  private handleRequestCloseApplicationActionModal = () => {
    const {updateApplicationActionModal} = this.props;
    updateApplicationActionModal({action: null});
  };
}

const getActiveRouteName = (
  state: NavigationState | PartialState<NavigationState>,
): string => {
  const route = state.routes[state.index || 0];
  if (route.state) {
    return getActiveRouteName(route.state);
  }
  return route.name;
};
