import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import BaseEffect from '../../../domain/entities/BaseEffect';

import NetBaseEffectsRepository from '../../../data/repositories/writer/NetBaseEffectsRepository';

const types = {
  failure: Types.ShowBaseEffectFailure,
  request: Types.ShowBaseEffectRequest,
  success: Types.ShowBaseEffectSuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<BaseEffect> {
  return showGeneric(new NetBaseEffectsRepository(), types, id);
}
