import * as React from 'react';
import {
  Image,
  LayoutChangeEvent,
  NativeScrollEvent,
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import {ModalParams} from '../Modal';

import AddCharacterShowSceneCommandLink from './add_scene_command_Links/AddCharacterShowSceneCommandLink';
import AddDescriptiveTextShowSceneCommandLink from './add_scene_command_Links/AddDescriptiveTextShowSceneCommandLink';
import AddFullScreenIllustrationShowSceneCommandLink from './add_scene_command_Links/AddFullScreenIllustrationShowSceneCommandLink';
import AddIllustrationShowSceneCommandLink from './add_scene_command_Links/AddIllustrationShowSceneCommandLink';
import AddSpeechTextShowSceneCommandLink from './add_scene_command_Links/AddSpeechTextShowSceneCommandLink';
import AddEffectShowSceneCommandLink from './add_scene_command_Links/AddEffectShowSceneCommandLink';
import AddSoundEffectShowSceneCommandLink from './add_scene_command_Links/AddSoundEffectShowSceneCommandLink';
import AddBackgroundMusicShowSceneCommandLink from './add_scene_command_Links/AddBackgroundMusicShowSceneCommandLink';

import CurrentSceneFrameBox from './scene_command_boxes/CurrentSceneFrameBox';

import DragScrollView from '../../../shared/drag_scroll_view';
import Coachmark from '../../../shared/coachmark/Coachmark';
import CoachmarkContext from '../../../shared/coachmark/CoachmarkContext';

import SceneFrame from '../../../../view_models/SceneFrame';
import CoachmarkState from '../../../../view_models/CoachmarkState';

import convertImageSource from '../../../../helpers/convertImageSource';

import SceneCommandForm from '../../../../../domain/forms/scene_commands/SceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../../../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import Position from '../../../../../domain/value_objects/Position';

import leftUri from '../../../../../assets/images/speech_text_positions/left-s.png';
import centerUri from '../../../../../assets/images/speech_text_positions/center-s.png';
import rightUri from '../../../../../assets/images/speech_text_positions/right-s.png';

interface Props {
  sceneFrame: SceneFrame;
  sceneCommandIndex: number;
  sceneCommandForm: SceneCommandForm | null;
  enableFullScreenIllustration?: boolean;
  enableSoundEffect?: boolean;
  enableBackgroundMusic?: boolean;
  lastSceneCommand?: boolean;
  insertIntoCompositeSequence?: boolean;
  showSpeechTextPositions?: boolean;
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
  onRequestOpenModal: (modalParams: ModalParams) => void;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
  onForwardToSpeechBalloons: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    positions?: Position[];
  }) => void;
  onForwardToTextFrames: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToIllustrations: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToFullScreenIllustrations: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToEffects: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToSoundEffects: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToBackgroundMusic: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onRequestUpdateCoachmarkModal?: (
    coachmarkState: CoachmarkState | null,
  ) => void;
}

interface State {
  scrollViewWidth: number;
  leftShadow: boolean;
  rightShadow: boolean;
}

export default class AddSceneCommandLinksGroup extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      leftShadow: false,
      rightShadow: true,
      scrollViewWidth: 0,
    };
  }

  public render(): React.ReactNode {
    const {
      sceneFrame,
      sceneCommandIndex,
      sceneCommandForm,
      lastSceneCommand,
      showSpeechTextPositions,
      onChangeOrder,
      onRequestOpenModal,
      onForwardToCharacters,
    } = this.props;
    return (
      <>
        {this.shouldDisplayCurrentSceneFrameBox() ? (
          <CurrentSceneFrameBox
            sceneFrame={sceneFrame}
            sceneCommandIndex={sceneCommandIndex + (lastSceneCommand ? 1 : 0)}
            parentSceneCommandForm={sceneCommandForm}
            updateMode={false}
            onChangeOrder={onChangeOrder}
            onRequestOpenModal={onRequestOpenModal}
            onForwardToCharacters={onForwardToCharacters}
          />
        ) : sceneFrame.playingBGM() ? (
          <View style={{height: 24}} />
        ) : null}
        {showSpeechTextPositions ? (
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: 16,
            }}>
            <TouchableOpacity
              onPress={() =>
                this.handleForwardToSpeechBalloonText([Position.Left])
              }>
              <Image
                source={convertImageSource(leftUri)}
                style={{width: 109, height: 66}}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                this.handleForwardToSpeechBalloonText([Position.Center])
              }>
              <Image
                source={convertImageSource(centerUri)}
                style={{width: 109, height: 66}}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() =>
                this.handleForwardToSpeechBalloonText([Position.Right])
              }>
              <Image
                source={convertImageSource(rightUri)}
                style={{width: 109, height: 66}}
              />
            </TouchableOpacity>
          </View>
        ) : null}
        <CoachmarkContext.Consumer>
          {context => {
            return (
              <Coachmark
                autoShow={context === 'first_episode_form_background_done'}
                coachmarkViewChildren={
                  'シーンに必要なものを\nこの中から選びましょう！'
                }
                onHide={this.handleHide}>
                <View style={styles.container}>
                  <DragScrollView
                    style={styles.scrollableButtons}
                    horizontal={true}
                    bounces={false}
                    showsHorizontalScrollIndicator={false}
                    scrollEventThrottle={16}
                    onScroll={this.handleScroll}
                    onLayout={this.handleLayout}>
                    {this.renderButtons()}
                  </DragScrollView>
                </View>
              </Coachmark>
            );
          }}
        </CoachmarkContext.Consumer>
      </>
    );
  }

  public renderButtons(): React.ReactNode {
    const {
      enableFullScreenIllustration,
      enableSoundEffect,
      enableBackgroundMusic,
    } = this.props;
    return (
      <View style={styles.buttons}>
        <AddCharacterShowSceneCommandLink
          onPress={this.handleForwardToCharacters}
        />
        <AddSpeechTextShowSceneCommandLink
          onPress={this.handleForwardToSpeechBalloons}
        />
        <AddDescriptiveTextShowSceneCommandLink
          onPress={this.handleForwardToTextFrames}
        />
        <AddIllustrationShowSceneCommandLink
          onPress={this.handleForwardToIllustrations}
        />
        <AddEffectShowSceneCommandLink onPress={this.handleForwardToEffects} />
        {enableSoundEffect && (
          <AddSoundEffectShowSceneCommandLink
            onPress={this.handleForwardToSoundEffects}
          />
        )}
        {enableBackgroundMusic && (
          <AddBackgroundMusicShowSceneCommandLink
            onPress={this.handleForwardToBackgroundMusic}
          />
        )}
        {enableFullScreenIllustration && (
          <AddFullScreenIllustrationShowSceneCommandLink
            onPress={this.handleForwardToFullScreenIllustrations}
          />
        )}
      </View>
    );
  }

  private handleForwardToCharacters = () => {
    const {
      onForwardToCharacters,
      sceneCommandForm,
      sceneCommandIndex,
      lastSceneCommand,
      insertIntoCompositeSequence,
    } = this.props;
    onForwardToCharacters({
      sceneCommandIndex: sceneCommandIndex + (lastSceneCommand ? 1 : 0),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
      parentSceneCommandId: !insertIntoCompositeSequence
        ? undefined
        : sceneCommandForm?.sceneCommandId,
    });
  };

  private handleForwardToSpeechBalloons = () => {
    const {
      onForwardToSpeechBalloons,
      sceneCommandForm,
      insertIntoCompositeSequence,
    } = this.props;
    onForwardToSpeechBalloons({
      sceneCommandIndex: this.getSceneCommandIndexWithOffset(),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
      parentSceneCommandId: this.shouldDisplayCurrentSceneFrameBox()
        ? undefined
        : sceneCommandForm?.sceneCommandId,
    });
  };

  private handleForwardToSpeechBalloonText = (positions: Position[]) => {
    const {
      onForwardToSpeechBalloons,
      sceneCommandForm,
      insertIntoCompositeSequence,
    } = this.props;
    onForwardToSpeechBalloons({
      sceneCommandIndex: this.getSceneCommandIndexWithOffset(),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
      parentSceneCommandId: this.shouldDisplayCurrentSceneFrameBox()
        ? undefined
        : sceneCommandForm?.sceneCommandId,
      positions,
    });
  };

  private handleForwardToTextFrames = () => {
    const {
      onForwardToTextFrames,
      sceneCommandForm,
      insertIntoCompositeSequence,
    } = this.props;
    onForwardToTextFrames({
      sceneCommandIndex: this.getSceneCommandIndexWithOffset(),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
      parentSceneCommandId: this.shouldDisplayCurrentSceneFrameBox()
        ? undefined
        : sceneCommandForm?.sceneCommandId,
    });
  };

  private handleForwardToIllustrations = () => {
    const {
      onForwardToIllustrations,
      sceneCommandForm,
      insertIntoCompositeSequence,
    } = this.props;
    onForwardToIllustrations({
      sceneCommandIndex: this.getSceneCommandIndexWithOffset(),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
      parentSceneCommandId: this.shouldDisplayCurrentSceneFrameBox()
        ? undefined
        : sceneCommandForm?.sceneCommandId,
    });
  };

  private handleForwardToFullScreenIllustrations = () => {
    const {
      onForwardToFullScreenIllustrations,
      sceneCommandForm,
      insertIntoCompositeSequence,
    } = this.props;
    onForwardToFullScreenIllustrations({
      sceneCommandIndex: this.getSceneCommandIndexWithOffset(),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
    });
  };

  private handleForwardToEffects = () => {
    const {onForwardToEffects, sceneCommandForm, insertIntoCompositeSequence} =
      this.props;
    onForwardToEffects({
      sceneCommandIndex: this.getSceneCommandIndexWithOffset(),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
      parentSceneCommandId: this.shouldDisplayCurrentSceneFrameBox()
        ? undefined
        : sceneCommandForm?.sceneCommandId,
    });
  };

  private handleForwardToSoundEffects = () => {
    const {
      onForwardToSoundEffects,
      sceneCommandForm,
      insertIntoCompositeSequence,
    } = this.props;
    onForwardToSoundEffects({
      sceneCommandIndex: this.getSceneCommandIndexWithOffset(),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
      parentSceneCommandId: this.shouldDisplayCurrentSceneFrameBox()
        ? undefined
        : sceneCommandForm?.sceneCommandId,
    });
  };

  private handleForwardToBackgroundMusic = () => {
    const {
      onForwardToBackgroundMusic,
      sceneCommandForm,
      insertIntoCompositeSequence,
    } = this.props;
    onForwardToBackgroundMusic({
      sceneCommandIndex: this.getSceneCommandIndexWithOffset(),
      subSceneCommandIndex: insertIntoCompositeSequence ? 1 : undefined,
      parentSceneCommandId:
        this.shouldDisplayCurrentSceneFrameBox() || !insertIntoCompositeSequence
          ? undefined
          : sceneCommandForm?.sceneCommandId,
    });
  };

  private handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    if (this.state.scrollViewWidth <= 0) {
      return;
    }
    const scrollContentWidh = event.nativeEvent.contentSize.width;
    const scrollRange = scrollContentWidh - this.state.scrollViewWidth;
    if (scrollRange <= 0) {
      return;
    }
    const offsetX = event.nativeEvent.contentOffset.x;
    this.setState({
      leftShadow: offsetX !== 0,
      rightShadow: offsetX !== scrollRange,
    });
  };

  private handleLayout = (event: LayoutChangeEvent) => {
    this.setState({scrollViewWidth: event.nativeEvent.layout.width});
  };

  private shouldDisplayCurrentSceneFrameBox = (): boolean => {
    const {sceneCommandForm, insertIntoCompositeSequence} = this.props;
    if (insertIntoCompositeSequence) {
      return false;
    }
    if (!sceneCommandForm) {
      return true;
    }
    if (
      sceneCommandForm instanceof DescriptiveTextShowSceneCommandForm ||
      sceneCommandForm instanceof FullScreenIllustrationShowSceneCommandForm ||
      sceneCommandForm instanceof IllustrationShowSceneCommandForm ||
      sceneCommandForm instanceof EffectShowSceneCommandForm ||
      sceneCommandForm instanceof SoundEffectShowSceneCommandForm ||
      sceneCommandForm instanceof BackgroundMusicShowSceneCommandForm ||
      sceneCommandForm instanceof BackgroundMusicHideSceneCommandForm ||
      sceneCommandForm instanceof SpeechTextShowSceneCommandForm
    ) {
      return true;
    }
    if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      const subSceneCommandForm =
        sceneCommandForm.commandForms[sceneCommandForm.commandForms.length - 1];
      if (
        subSceneCommandForm instanceof DescriptiveTextShowSceneCommandForm ||
        subSceneCommandForm instanceof
          FullScreenIllustrationShowSceneCommandForm ||
        subSceneCommandForm instanceof IllustrationShowSceneCommandForm ||
        subSceneCommandForm instanceof EffectShowSceneCommandForm ||
        subSceneCommandForm instanceof SoundEffectShowSceneCommandForm ||
        subSceneCommandForm instanceof BackgroundMusicShowSceneCommandForm ||
        subSceneCommandForm instanceof BackgroundMusicHideSceneCommandForm ||
        subSceneCommandForm instanceof SpeechTextShowSceneCommandForm
      ) {
        return true;
      }
    }
    return false;
  };

  private getSceneCommandIndexWithOffset = () => {
    const {sceneCommandIndex} = this.props;
    return sceneCommandIndex + this.getSceneCommandOffset();
  };

  private getSceneCommandOffset = () => {
    const {sceneCommandForm, lastSceneCommand} = this.props;
    if (!lastSceneCommand) {
      return 0;
    }
    if (this.shouldDisplayCurrentSceneFrameBox()) {
      return 1;
    }
    return 0;
  };

  private handleHide = () => {
    const {onRequestUpdateCoachmarkModal} = this.props;
    onRequestUpdateCoachmarkModal && onRequestUpdateCoachmarkModal(null);
  };
}

const styles = StyleSheet.create({
  buttons: {
    alignItems: 'center',
    flex: 1,
    justifyContent: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  container: {
    marginHorizontal: 10,
    marginVertical: 18,
    height: 58,
    ...Platform.select({default: {flex: 1}, web: {}}),
  } as ViewStyle,
  gradient: {
    height: 75,
    position: 'absolute',
    top: 0,
    width: 20,
  } as ViewStyle,
  gradientClose: {
    height: 20,
    right: 0,
  } as ViewStyle,
  gradientLeft: {
    left: 0,
  } as ViewStyle,
  gradientRight: {
    right: 0,
  } as ViewStyle,
  inner: {
    borderRadius: 3,
    overflow: 'hidden',
    padding: 7,
  } as ViewStyle,
  scrollableButtons: Platform.select({
    web: {
      marginBottom: -16,
    },
    default: {},
  }) as any,
});
