import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo, useFocusEffect} from '@react-navigation/native';
import dateformat from 'dateformat';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import Offer from '../../../../../domain/entities/writers_gate/Offer';
import OfferManuscript from '../../../../../domain/entities/writers_gate/OfferManuscript';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

const DEFAULT_DISPLAY_CONTENT_LENGTH = 500;

interface Props {
  offer: Offer;
  submittedCount: number;
}

const Detail: React.FC<Props> = props => {
  const {offer, submittedCount} = props;
  const linkTo = useLinkTo();
  const [unsubmittedOfferManuscripts, setUnsubmittedOfferManuscripts] =
    React.useState<OfferManuscript[] | null>(null);
  const [displayAll, setDisplayAll] = React.useState<boolean>(false);
  const requiredDisplayAll =
    offer.content.length > DEFAULT_DISPLAY_CONTENT_LENGTH;
  const onPressDisplayAll = React.useCallback(() => {
    setDisplayAll(!displayAll);
  }, [displayAll]);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelTypedRestApi.get<OfferManuscript[]>(
        `/api/writer/writers_gate/offer_manuscripts`,
        {
          writersGateOfferId: offer.id,
          submitted: false,
        },
      ).then(result => {
        setUnsubmittedOfferManuscripts(result.body);
      });
    }, []),
  );
  const onPressNew = React.useCallback(() => {
    TapNovelTypedRestApi.get<OfferManuscript[]>(
      `/api/writer/writers_gate/offer_manuscripts`,
      {
        writersGateOfferId: offer.id,
        submitted: false,
      },
    )
      .then(result => {
        const offerManuscripts = result.body;
        const offerManuscript = offerManuscripts[0];
        if (offerManuscript?.episodeId) {
          linkTo(`/episodes/${offerManuscript.episodeId}`);
        } else {
          linkTo(`/writers_gate/offers/${offer.id}/manuscripts/new`);
        }
      })
      .catch(e => {});
  }, []);
  const onPressOld = React.useCallback(() => {
    linkTo(`/writers_gate/stories?offerId=${offer.id}`);
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.rowKey}>オファータイトル</Text>
        <Text style={styles.rowValue}>{offer.title}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowKey}>オファー内容</Text>
        <Text style={styles.rowValue}>
          {!requiredDisplayAll
            ? offer.content
            : displayAll
            ? offer.content
            : `${offer.content.substring(0, DEFAULT_DISPLAY_CONTENT_LENGTH)}…`}
        </Text>
        {requiredDisplayAll ? (
          <TouchableOpacity
            style={styles.displayAll}
            onPress={onPressDisplayAll}>
            <View style={displayAll ? styles.arrowUp : styles.arrowDown} />
            <Text style={styles.displayAllText}>
              {displayAll ? '隠す' : 'すべて表示'}
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
      <View style={styles.row}>
        <Text style={styles.rowKey}>提出物</Text>
        <Text style={styles.rowValue}>{offer.typeName}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowKey}>提出上限</Text>
        <Text style={styles.rowValue}>
          一人{offer.submissionLimit}提案まで。
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowKey}>締切</Text>
        <Text style={styles.rowValue}>
          {dateformat(offer.deadlineAt, 'yyyy.mm.dd HH:MM')}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowKey}>報酬</Text>
        <Text style={styles.rowValue}>
          採用された方に{offer.rewardPoints}ポイント
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowKey}>権利</Text>
        <Text style={styles.rowValue}>{offer.right}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowKey}>条件</Text>
        <Text style={styles.rowValue}>
          ライターランク{offer.minimumWriterGrade.name}以上
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowKey}>備考</Text>
        <Text style={styles.rowValue}>{offer.notes}</Text>
      </View>
      <View style={styles.button}>
        <PrimaryButton
          disabled={offer.submissionLimit <= submittedCount}
          onPress={onPressNew}>
          {offer.submissionLimit <= submittedCount
            ? '提出上限に達しています'
            : (unsubmittedOfferManuscripts?.length || 0) === 0
            ? '新しい作品を作成して提出する'
            : '提出する'}
        </PrimaryButton>
      </View>
      {offer.typeCode === 'episode' &&
      (unsubmittedOfferManuscripts?.length || 0) === 0 ? (
        <View style={styles.button}>
          <PrimaryButton
            disabled={offer.submissionLimit <= submittedCount}
            onPress={onPressOld}>
            {offer.submissionLimit <= submittedCount
              ? '提出上限に達しています'
              : '過去に作成したを作品から提出する'}
          </PrimaryButton>
        </View>
      ) : null}
    </View>
  );
};

export default React.memo(Detail);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingVertical: 32,
    paddingHorizontal: 10,
  } as ViewStyle,
  row: {
    marginBottom: 32,
  } as ViewStyle,
  rowKey: {
    color: '#222222',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 5,
  } as TextStyle,
  rowValue: {
    color: '#222222',
    fontSize: 14,
  } as TextStyle,
  displayAll: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 16,
  } as ViewStyle,
  displayAllText: {
    color: '#999999',
    fontSize: 12,
    marginLeft: 5,
  } as TextStyle,
  arrowDown: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  } as ViewStyle,
  arrowUp: {
    marginTop: 5,
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '225deg'}],
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
});
