import * as React from 'react';

import PasswordChangeForm from './partials/Form';

import Layout from '../../../shared/Layout';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import Alert from '../../../shared/alert/Alert';

import NavigationProp from '../../../../navigators/NavigationProp';

import * as routers from '../../../../routers';

import {Params as UserRegistrationUpdateParams} from '../../../../actions/writer/users/registration/update';

import {formatErrorMessages} from '../../../../helpers/errorMessages';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';
import UserRegistrationPasswordEditForm from '../../../../../domain/forms/writer/UserRegistrationPasswordEditForm';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  updateConsumerUserRegistration: (
    params: UserRegistrationUpdateParams,
  ) => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  userRegistrationPasswordEditForm: UserRegistrationPasswordEditForm;
}

export default class Edit extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      userRegistrationPasswordEditForm: new UserRegistrationPasswordEditForm(
        '',
        '',
        '',
      ),
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {navigation, currentUser} = this.props;
    const {loading, userRegistrationPasswordEditForm} = this.state;
    return (
      <Layout
        title={'パスワード変更'}
        navigation={navigation}
        back={true}
        loading={loading}
        rightButton={{
          title: (
            <PrimaryButton
              buttonSize={{width: 64, height: 32}}
              onPress={this.handleSubmit}>
              保存
            </PrimaryButton>
          ),
          tintColor: 'white',
        }}>
        {currentUser && (
          <PasswordChangeForm
            currentUser={currentUser}
            userRegistrationPasswordEditForm={userRegistrationPasswordEditForm}
            onChangeCurrentPassword={this.handleChangeCurrentPassword}
            onChangePassword={this.handleChangePassword}
            onChangePasswordConfirmation={this.handleChangePasswordConfirmation}
          />
        )}
      </Layout>
    );
  }

  private handleChangeCurrentPassword = (currentPassword: string) => {
    const {userRegistrationPasswordEditForm} = this.state;
    this.setState({
      userRegistrationPasswordEditForm: new UserRegistrationPasswordEditForm(
        currentPassword,
        userRegistrationPasswordEditForm.password,
        userRegistrationPasswordEditForm.passwordConfirmation,
      ),
    });
  };

  private handleChangePassword = (password: string) => {
    const {userRegistrationPasswordEditForm} = this.state;
    this.setState({
      userRegistrationPasswordEditForm: new UserRegistrationPasswordEditForm(
        userRegistrationPasswordEditForm.currentPassword,
        password,
        userRegistrationPasswordEditForm.passwordConfirmation,
      ),
    });
  };

  private handleChangePasswordConfirmation = (passwordConfirmation: string) => {
    const {userRegistrationPasswordEditForm} = this.state;
    this.setState({
      userRegistrationPasswordEditForm: new UserRegistrationPasswordEditForm(
        userRegistrationPasswordEditForm.currentPassword,
        userRegistrationPasswordEditForm.password,
        passwordConfirmation,
      ),
    });
  };

  private handleSubmit = () => {
    const {navigation, updateConsumerUserRegistration} = this.props;
    const {userRegistrationPasswordEditForm} = this.state;
    this.setStateIfMounted({loading: true}, () => {
      updateConsumerUserRegistration(
        userRegistrationPasswordEditForm.getParams(),
      )
        .then(() => {
          this.setStateIfMounted({loading: false});
          routers.linkToWriterUserAccount(navigation, {
            showPasswordChangeModal: true,
          });
        })
        .catch(error => {
          this.setStateIfMounted({loading: false}, () => {
            setTimeout(() => {
              Alert.alert(formatErrorMessages({}, error));
            }, 300);
          });
        });
    });
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ) {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }
}
