import * as React from 'react';
import {FlatList, StyleSheet, ViewStyle} from 'react-native';

import EntityListItem from './EntityListItem';

import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';

interface Entity {
  id: number;
  actorCharacter: ActorCharacter;
  getLabel: () => string;
}

interface Props<E extends Entity> {
  entities: E[] | null;
  width: number;
  visible: boolean;
  selectedEntityId: number | null;
  clipped: boolean;
  inverted?: boolean;
  scrollEnabled?: boolean;
  onSelectEntity: (entity: E) => void;
}

function EntityList<E extends Entity>(props: Props<E>) {
  const {
    entities,
    width,
    visible,
    selectedEntityId,
    clipped,
    inverted,
    scrollEnabled,
    onSelectEntity,
  } = props;
  return (
    <FlatList
      style={[styles.container, visible ? null : {display: 'none'}]}
      data={entities}
      scrollEnabled={scrollEnabled}
      renderItem={info => (
        <EntityListItem
          entity={info.item}
          width={
            (width - (MARGIN_HORIZONTAL + CONTENT_MARGIN * NUM_COLUMNS) * 2) /
            NUM_COLUMNS
          }
          selected={selectedEntityId === info.item.id}
          clipped={clipped}
          inverted={inverted}
          onSelectEntity={onSelectEntity}
        />
      )}
      numColumns={3}
      extraData={selectedEntityId}
    />
  );
}

export default EntityList;

const MARGIN_HORIZONTAL = 14;
const CONTENT_MARGIN = 2;

const NUM_COLUMNS = 3;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: MARGIN_HORIZONTAL,
  } as ViewStyle,
});
