import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import NotificationSettingListItem from './NotificationSettingListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import {WriterUserNotificationSetting} from '../../../../../domain/entities/writer/UserNotificationSettingsBatch';

interface Props {
  writerUserNotificationSettings: WriterUserNotificationSetting[];
  index: number;
  onValueChangeWriterUserNotificationSetting: (
    writerUserNotificationSetting: WriterUserNotificationSetting,
    enabled: boolean,
  ) => void;
}

export default class NotificationSettingList extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {writerUserNotificationSettings} = this.props;
    return (
      <FlatList
        data={writerUserNotificationSettings}
        ItemSeparatorComponent={ListItemNormalSeparator}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
      />
    );
  }

  private keyExtractor = (
    item: WriterUserNotificationSetting,
    index: number,
  ): string => {
    return `${index}_${item.writerNotificationSetting.id}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<WriterUserNotificationSetting>,
  ): React.ReactElement<any> | null => {
    const {onValueChangeWriterUserNotificationSetting} = this.props;
    return (
      <NotificationSettingListItem
        writerUserNotificationSetting={info.item}
        onValueChangeWriterUserNotificationSetting={
          onValueChangeWriterUserNotificationSetting
        }
      />
    );
  };
}
