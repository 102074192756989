import ActorCharacterFaceMapper, {
  ActorCharacterFaceData,
} from '../../../entities/mappers/ActorCharacterFaceMapper';
import CharacterPatternMapper, {
  CharacterPatternData,
} from '../../../entities/mappers/CharacterPatternMapper';
import MarkMapper, {MarkData} from '../../../entities/mappers/MarkMapper';

import PositionMapper, {PositionData} from '../PositionMapper';

import CharacterHideSceneCommand from '../../../../domain/value_objects/scene_commands/CharacterHideSceneCommand';

export interface CharacterHideSceneCommandData {
  type: string;
  actor_character_face: ActorCharacterFaceData;
  character_pattern: CharacterPatternData;
  position: PositionData;
  mark: MarkData | null;
}

export default class CharacterHideSceneCommandMapper {
  private characterPatternMapper: CharacterPatternMapper;
  private actorCharacterFaceMapper: ActorCharacterFaceMapper;
  private positionMapper: PositionMapper;
  private markMapper: MarkMapper;

  constructor() {
    this.characterPatternMapper = new CharacterPatternMapper();
    this.actorCharacterFaceMapper = new ActorCharacterFaceMapper();
    this.positionMapper = new PositionMapper();
    this.markMapper = new MarkMapper();
  }

  public map(obj: CharacterHideSceneCommandData): CharacterHideSceneCommand {
    const characterPattern = this.characterPatternMapper.map(
      obj.character_pattern,
    );
    const actorCharacterFace = this.actorCharacterFaceMapper.map(
      obj.actor_character_face,
    );
    const position = this.positionMapper.map(obj.position);
    const mark = obj.mark ? this.markMapper.map(obj.mark) : null;
    return new CharacterHideSceneCommand(
      characterPattern,
      actorCharacterFace,
      position,
      mark,
    );
  }
}
