import * as React from 'react';

import BaseIndex from '../../common/sounds/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundMusicShowSceneCommandEditSoundsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as SoundUsageHistoryUpdateParams} from '../../../../../actions/sound_usage_histories/update';
import {Params as SoundIndexParams} from '../../../../../actions/sounds/index';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import {QueryState} from '../../../../../reducers/queries/Response';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import Sound from '../../../../../../domain/entities/Sound';
import SoundCategory from '../../../../../../domain/entities/SoundCategory';
import SoundUsageHistory from '../../../../../../domain/entities/SoundUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  soundCategoryId?: number;
  selectedLargeSoundCategoryId?: number | null;
  selectedMiddleSoundCategoryId?: number | null;
  selectedSmallSoundCategoryId?: number | null;
  favorite?: boolean;
  sceneCommandId: number;
  parentSceneCommandId?: number;
  sort?: 'popularity' | 'new_arrival';
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundMusicShowSceneCommandEditSoundsRouteProp;
  sceneForm: SceneForm | null;
  soundsParams: SoundIndexParams;
  sounds: Sound[] | null;
  soundCategory: SoundCategory | null;
  sceneCommandForm: SceneCommandForm | null;
  parentSceneCommandForm: SceneCommandForm | null;
  soundQueries: QueryState;
  totalCount: number | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexSounds: (params: SoundIndexParams) => Promise<PaginatedResult<Sound>>;
  showSoundCategory: (id: number) => Promise<SoundCategory>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateSoundUsageHistory: (
    id: number,
    params: SoundUsageHistoryUpdateParams,
  ) => Promise<SoundUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedSound: Sound | null;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    const {route} = this.props;
    const {sort} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        sort={sort}
        onSelectSound={this.handleSelectSound}
        onPressSearchConditionChangeButton={
          this.handlePressSearchConditionChangeButton
        }
      />
    );
  }

  private handlePressSearchConditionChangeButton = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      query,
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId,
      sceneCommandId,
      parentSceneCommandId,
      favorite,
    } = route.params;
    navigation.navigate(
      'SceneFormBackgroundMusicShowSceneCommandEditSoundSearchFormNavigations',
      {
        screen: 'SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm',
        params: {
          storyId,
          episodeId,
          sceneId,
          query,
          selectedLargeSoundCategoryId,
          selectedMiddleSoundCategoryId,
          selectedSmallSoundCategoryId,
          sceneCommandId,
          parentSceneCommandId,
          favorite,
          hideBack: true,
        },
      } as any,
    );
  };

  private handleSelectSound = (sound: Sound): void => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      updateSceneCommandForm,
      updateSoundUsageHistory,
    } = this.props;
    const {storyId, sceneCommandId} = route.params;
    const illustrationShowSceneCommandForm =
      this.fetchIllustrationShowSceneCommandFormFromParent();
    if (illustrationShowSceneCommandForm) {
      const sceneCommandForm = new IllustrationShowSceneCommandForm(
        illustrationShowSceneCommandForm.illustration,
        sound,
        null,
        null,
        sceneCommandId,
      );
      if (parentSceneCommandForm) {
        updateSceneCommandForm({
          sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            sceneCommandForm,
          ),
        });
      } else {
        updateSceneCommandForm({sceneCommandForm});
      }
    } else {
      const sceneCommandForm = new BackgroundMusicShowSceneCommandForm(
        sound,
        sceneCommandId,
      );
      if (parentSceneCommandForm) {
        updateSceneCommandForm({
          sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            sceneCommandForm,
          ),
        });
      } else {
        updateSceneCommandForm({
          sceneCommandForm,
        });
      }
    }
    updateSoundUsageHistory(sound.id, {
      storyId,
      soundType: 'background_music',
    }).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };

  private fetchIllustrationShowSceneCommandFormFromParent =
    (): IllustrationShowSceneCommandForm | null => {
      const {sceneCommandForm, parentSceneCommandForm} = this.props;
      if (sceneCommandForm instanceof IllustrationShowSceneCommandForm) {
        return sceneCommandForm;
      }
      if (parentSceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
        const found = parentSceneCommandForm.commandForms.find(
          c => c instanceof IllustrationShowSceneCommandForm,
        );
        if (found instanceof IllustrationShowSceneCommandForm) {
          return found;
        }
      }
      return null;
    };
}
