import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import Entity from '../../../domain/entities/Entity';

import ResourcesRepository from '../../../domain/repositories/ResourcesRepository';

export interface ActionTypes {
  request: Types;
  success: Types;
  failure: Types;
}

export default function action<T extends Entity>(
  repository: ResourcesRepository<T>,
  types: ActionTypes,
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<T> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<T> => {
    try {
      const payload = await repository.find(id);
      dispatch({payload, type: types.success});
      return payload;
    } catch (err) {
      dispatch({type: types.failure});
      throw err;
    }
  };
}
