import * as React from 'react';

import SelectableEntityListWithModal, {
  Entity,
  Props as BaseProps,
  State as BaseState,
} from './SelectableEntityListWithModal';

import EntityImageGridList from './lists/EntityImageGridList';

import {equalForKeys} from '../../helpers/equalForKeys';

interface Props<T extends Entity> extends BaseProps<T> {
  entities: T[];
  showsVerticalScrollIndicator?: boolean;
}

export default class EntityGridListWithModal<
  T extends Entity,
> extends SelectableEntityListWithModal<Props<T>, T> {
  public shouldComponentUpdate(
    nextProps: Readonly<Props<T>>,
    nextState: Readonly<BaseState<T>>,
  ): boolean {
    if (
      !equalForKeys(this.props, nextProps, [
        'modalWidth',
        'modalHeight',
        'entities',
        'ListHeaderComponent',
        'extraData',
      ])
    ) {
      return true;
    }
    if (!equalForKeys(this.state, nextState)) {
      return true;
    }
    return false;
  }

  protected renderEntityList(): React.ReactNode {
    const {
      entities,
      aspectRatio,
      imageAspectRatio,
      onEndReachedThreshold,
      style,
      containerStyle,
      imageMaskStyle,
      resizeMode,
      showsVerticalScrollIndicator,
      frame,
      ListHeaderComponent,
      ListFooterComponent,
      ListEmptyComponent,
      extraData,
      onSelectEntity,
      imageUrlExtractor,
      labelExtractor,
      imageFrameStyleExtractor,
      imageStyleExtractor,
      renderCustomLabel,
      onEndReached,
      disabledItem,
    } = this.props;
    return (
      <EntityImageGridList
        key={'entityImageGridList'}
        entities={entities}
        style={style}
        containerStyle={containerStyle}
        imageMaskStyle={imageMaskStyle}
        aspectRatio={aspectRatio}
        imageAspectRatio={imageAspectRatio}
        resizeMode={resizeMode}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        frame={frame}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={ListFooterComponent}
        ListEmptyComponent={ListEmptyComponent}
        extraData={extraData}
        onSelectEntity={onSelectEntity}
        onLongPressEntity={this.handleLongPressEntity}
        onTouchEnd={this.handleTouchEnd}
        imageUrlExtractor={imageUrlExtractor}
        labelExtractor={labelExtractor}
        imageFrameStyleExtractor={imageFrameStyleExtractor}
        imageStyleExtractor={imageStyleExtractor}
        renderCustomLabel={renderCustomLabel}
        onEndReachedThreshold={onEndReachedThreshold}
        onEndReached={onEndReached}
        disabledItem={disabledItem}
      />
    );
  }
}
