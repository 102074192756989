import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import dateformat from 'dateformat';

import WriterUserImage from '../../members/partials/WriterUserImage';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';

import HeartIcon from '../../../shared/icons/project/HeartIcon';

import ProjectMemberJoinRequest from '../../../../../domain/entities/ProjectMemberJoinRequest';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  memberJoinRequest: ProjectMemberJoinRequest;
  onForceUpdate: () => void;
}

const MemberJoinRequestListItem: React.FC<Props> = props => {
  const {memberJoinRequest, onForceUpdate} = props;
  const user = memberJoinRequest.writerUser;
  const onPressApprove = React.useCallback(() => {
    TapNovelTypedRestApi.patch(
      `/api/writer/leader/project_member_join_requests/${memberJoinRequest.id}`,
      {status: 'approved'},
    ).then(onForceUpdate);
  }, []);
  const onPressReject = React.useCallback(() => {
    TapNovelTypedRestApi.patch(
      `/api/writer/leader/project_member_join_requests/${memberJoinRequest.id}`,
      {status: 'rejected'},
    ).then(onForceUpdate);
  }, []);
  const onPressNop = React.useCallback(() => {}, []);
  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <View style={styles.imageWrapper}>
          <WriterUserImage
            user={user}
            imageSize={IMAGE_SIZE}
            withRankColor={true}
          />
        </View>
        <View style={styles.right}>
          <View style={styles.rightTop}>
            <Text style={styles.penName}>{user.penName}</Text>
            {user.fansCount ? (
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <HeartIcon />
                <Text style={{color: '#222', fontSize: 12, marginLeft: 4}}>
                  ファン
                </Text>
                <Text style={{color: '#222', fontSize: 12, marginLeft: 4}}>
                  {user.fansCount}
                </Text>
              </View>
            ) : null}
          </View>
          <Text style={styles.date}>
            {dateformat(memberJoinRequest.createdAt, 'yyyy.mm.dd')} 申請
          </Text>
        </View>
      </View>
      <View style={{marginLeft: 76, flexDirection: 'row'}}>
        {memberJoinRequest.status === 'pending' ? (
          <>
            <PrimaryButton buttonSize={BUTTON_SIZE} onPress={onPressApprove}>
              承認
            </PrimaryButton>
            <DefaultOutlineButton
              style={{marginLeft: 8}}
              buttonSize={BUTTON_SIZE}
              onPress={onPressReject}>
              非承認
            </DefaultOutlineButton>
          </>
        ) : memberJoinRequest.status === 'approved' ? (
          <PrimaryButton
            buttonSize={BUTTON_SIZE}
            disabled={true}
            fontStyle={{color: '#ff8f13'}}
            onPress={onPressNop}>
            承認完了
          </PrimaryButton>
        ) : (
          <PrimaryButton
            buttonSize={BUTTON_SIZE}
            disabled={true}
            onPress={onPressNop}>
            非承認
          </PrimaryButton>
        )}
      </View>
    </View>
  );
};

export default React.memo(MemberJoinRequestListItem);

const IMAGE_SIZE = 50;

const BUTTON_SIZE = {
  width: 96,
  height: 32,
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 9,
  } as ViewStyle,
  top: {
    flexDirection: 'row',
    marginHorizontal: 16,
  } as ViewStyle,
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMAGE_SIZE / 2,
    backgroundColor: '#ffffff',
    height: IMAGE_SIZE,
    overflow: 'hidden',
    width: IMAGE_SIZE,
  } as ViewStyle,
  right: {
    flex: 1,
    paddingTop: 3,
    paddingHorizontal: 10,
  } as ViewStyle,
  rightTop: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  penName: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  date: {
    color: '#999',
    fontSize: 10,
    marginVertical: 6,
  } as TextStyle,
});
