import TapNovelRestApi from '../../../../data_stores/net/TapNovelRestApi';

import LineAccessTokenMapper, {
  LineAccessTokenData,
} from '../../../../value_objects/mappers/LineAccessTokenMapper';

import LineAccessToken from '../../../../../domain/value_objects/LineAccessToken';

import AccessTokensRepository, {
  CreateParams,
} from '../../../../../domain/repositories/writer/social_accounts/line_accounts/AccessTokensRepository';

export default class NetAccessTokensRepository
  implements AccessTokensRepository
{
  private endPoint = '/api/writer/social_accounts/line_accounts/access_token';

  private mapper: LineAccessTokenMapper;

  constructor() {
    this.mapper = new LineAccessTokenMapper();
  }

  public create(params: CreateParams): Promise<LineAccessToken> {
    const path = this.endPoint;
    return TapNovelRestApi.post<LineAccessTokenData>(path, params).then(obj => {
      return this.mapper.map(obj.body);
    });
  }
}
