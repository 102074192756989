import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import EditableLaterMessage from '../../../../EditableLaterMessage';
import PrimaryButton from '../../../../buttons/PrimaryButton';
import Input from '../../../../forms/Input';
import LabelWithOption from '../../../../forms/LabelWithOption';
import KeyboardSpacer from '../../../../KeyboardSpacer';

import {formStyles} from '../../../../../../styles/variables';

export interface FormParams {
  name: string;
}

interface Props {
  name?: string;
  onSubmit: (params: FormParams) => void;
}

interface State {
  name: string;
  disabled: boolean;
}

export default class Form extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const name = props.name || '';
    this.state = {
      disabled: name.length === 0,
      name,
    };
  }

  public render(): React.ReactNode {
    const {name, disabled} = this.state;
    return (
      <View style={styles.container}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'キャラクター名'}
            length={name.length}
            maxLength={20}
          />
          <Input
            placeholder={'キャラクター名を入力'}
            value={name}
            onChangeText={this.handleChangeText}
          />
        </View>

        <View style={styles.button}>
          <EditableLaterMessage />

          <PrimaryButton disabled={disabled} onPress={this.handlePress}>
            キャラクター作成
          </PrimaryButton>
        </View>
        <KeyboardSpacer />
      </View>
    );
  }

  private handleChangeText = (name: string) => {
    this.setState({
      disabled: name.length === 0 || name.length > 20,
      name,
    });
  };

  private handlePress = () => {
    const {onSubmit} = this.props;
    const {disabled, name} = this.state;
    if (disabled) {
      return;
    }
    onSubmit({name});
  };
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    marginBottom: 12,
    marginTop: 20,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
});
