import {Dispatch} from 'redux';

import Types from '../Types';

import EpisodeCommentReply from '../../../domain/entities/EpisodeCommentReply';

import NetEpisodeCommentRepliesRepository from '../../../data/repositories/writer/NetEpisodeCommentRepliesRepository';

export interface Params {
  consumerUserEpisodeCommentId: number;
  comment: string;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<EpisodeCommentReply> {
  return async (dispatch: Dispatch<any>): Promise<EpisodeCommentReply> => {
    try {
      const payload = await new NetEpisodeCommentRepliesRepository().create({
        episodeCommentReply: params,
      });
      dispatch({payload, type: Types.CreateEpisodeCommentReplySuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateEpisodeCommentReplyFailure});
      throw err;
    }
  };
}
