import Entity from './Entity';

export default class BackgroundCategory implements Entity {
  constructor(
    public id: number,
    public parentId: number | null,
    public name: string,
    public leaf: boolean,
    public baseBackgroundCount: number,
  ) {}
}
