import * as React from 'react';

import Form, {FormValues} from './partials/Form';
import AlertModal from '../../shared/modals/AlertModal';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewProjectTopicCommentRouteProp} from '../../../navigators/RouteProps';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  topicId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewProjectTopicCommentRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {topicId} = route.params;
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const onPressClose = React.useCallback(() => navigation.goBack(), []);
  const onSubmit = React.useCallback((values: FormValues) => {
    TapNovelTypedRestApi.post('/api/writer/project_topic_comments', {
      projectTopicComment: {...values},
      projectTopicId: topicId,
    })
      .then(() => navigation.goBack())
      .catch(e => {
        setAlertMessage(formatErrorMessages({}, e));
      });
  }, []);
  return (
    <Layout
      title={'コメント'}
      navigation={navigation}
      close={true}
      onPressClose={onPressClose}>
      <Form onSubmit={onSubmit} />
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalAlert}>
          {alertMessage}
        </AlertModal>
      )}
    </Layout>
  );
};

export default React.memo(New);
