import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/story_form/story_publication_completion/Index';

import AppState from '../../../reducers/AppState';

import showStory from '../../../actions/stories/show';

import selectEntity from '../../../helpers/selectEntity';

import {StoryFormStoryPublicationCompletionRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: StoryFormStoryPublicationCompletionRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities} = state;
  const {storyId} = route.params;
  const story = selectEntity(entities.stories, storyId);
  return {
    story,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showStory: (id: number) => dispatch(showStory(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
