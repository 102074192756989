import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ProLink from '../../shared/ProLink';

import {enabledMakerPro} from '../../../helpers/features';

interface Props {
  onPress: () => void;
}

const ProSection: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  if (!enabledMakerPro) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        <Text style={styles.textBold}>MakerPro会員（有料）</Text>
        なら、ミッションのクリアなしで{'\n'}
        全機能を拡張することができます。
      </Text>
      <ProLink />
    </View>
  );
};

export default React.memo(ProSection);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 32,
  } as ViewStyle,
  text: {
    color: '#383838',
    fontSize: 12,
    textAlign: 'center',
  } as TextStyle,
  textBold: {
    fontWeight: 'bold',
  } as TextStyle,
});
