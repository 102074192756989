import * as React from 'react';

import Form from '../../story_form/image/partials/Form';

import Layout from '../../shared/Layout';
import PreviewBox from '../../shared/cover_image_form/PreviewBox';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {EditStoryFormImageRouteProp} from '../../../navigators/RouteProps';

import {isFulfilledEpisode} from '../../../view_models/navigateStoryPublication';

import * as routers from '../../../routers';

import {Params as StoryUpdateParams} from '../../../actions/stories/update';
import {Params as StoryFormUpdateParams} from '../../../actions/story_forms/update';
import {Params as CoverImageFormCreateParams} from '../../../actions/cover_image_forms/create';
import {Params as CoverImageFormUpdateParams} from '../../../actions/cover_image_forms/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import File from '../../../../domain/entities/File';
import Story from '../../../../domain/entities/Story';
import StoryForm from '../../../../domain/forms/StoryForm';
import CoverImageForm from '../../../../domain/forms/CoverImageForm';

export interface Params {
  storyId: number;
  episodeIdToBePublished?: number;
  skipTitleValidation?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditStoryFormImageRouteProp;
  storyForm: StoryForm | null;
  coverImageForm: CoverImageForm | null;
}

export interface DispatchProps {
  updateStory: (id: number, params: StoryUpdateParams) => Promise<Story>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
  createCoverImageForm: (params: CoverImageFormCreateParams) => Promise<any>;
  updateCoverImageForm: (params: CoverImageFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
  fetchedBackgroundImageUri: boolean;
  backgroundImageUri: string | null;
}

export default class Edit extends React.PureComponent<Props, State> {
  private ref = React.createRef<PreviewBox>();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      fetchedBackgroundImageUri: false,
      backgroundImageUri: null,
    };
  }

  public componentDidMount() {
    const {navigation, route, storyForm} = this.props;
    const {storyId} = route.params;
    if (!storyForm) {
      navigation.replace('EditStoryFormCoverImage', {storyId});
    }
    this.setupCoverImageForm();
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {coverImageForm, updateStoryForm} = this.props;
    if (
      coverImageForm &&
      prevProps.coverImageForm &&
      coverImageForm !== prevProps.coverImageForm
    ) {
      updateStoryForm({
        image: coverImageForm.image,
        hasImage: coverImageForm.hasImage,
        imageCharacterUri: coverImageForm.characterImageUri,
        imageBackgroundUri: coverImageForm.backgroundImageUri,
        imageTextInfoList: coverImageForm.imageTextInfoList,
        uploadedSelfImage: coverImageForm.uploadedSelfImage,
      });
    }
  }
  public render(): React.ReactNode {
    const {navigation, storyForm} = this.props;
    const {
      loading,
      alertMessage,
      fetchedBackgroundImageUri,
      backgroundImageUri,
    } = this.state;
    return (
      <Layout
        title={'中表紙'}
        navigation={navigation}
        back={true}
        close={true}
        onPressClose={this.handlePressClose}
        hideHeaderBottomBorder={true}
        loading={loading}>
        {storyForm && (
          <Form
            storyForm={storyForm}
            onChangeImage={this.handleChangeImage}
            onChangeCoverIllustrator={this.handleChangeCoverIllustrator}
            onSubmit={this.handleSubmit}
            onRequestReset={this.handleRequestReset}
            onForwardToImageSelection={this.handleForwardToImageSelection}
            onForwardToCharacterImageSelection={
              this.handleForwardToCharacterImageSelection
            }
            onForwardToTextInput={this.handleForwardToTextInput}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
        {storyForm && fetchedBackgroundImageUri && (
          <PreviewBox
            ref={this.ref}
            backgroundImageUri={backgroundImageUri}
            characterImageUri={storyForm.imageCharacterUri}
            aspectRatio={aspectRatio}
            imageTextInfoList={storyForm.imageTextInfoList || []}
            onDrawCanvas={this.handleDrawCanvas}
          />
        )}
      </Layout>
    );
  }

  private handleChangeImage = (image: File) => {
    this.setupCoverImageForm().then(() => {
      this.setState({
        fetchedBackgroundImageUri: true,
        backgroundImageUri: image.uri,
      });
    });
  };

  private handleDrawCanvas = (image: File | null) => {
    const {updateCoverImageForm} = this.props;
    const {backgroundImageUri} = this.state;
    if (!this.ref.current) {
      return;
    }
    if (image) {
      updateCoverImageForm({
        image,
        backgroundImageUri,
        uploadedSelfImage: true,
      });
      this.setState({fetchedBackgroundImageUri: false});
    }
  };

  private handleChangeCoverIllustrator = (coverIllustrator: string) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({coverIllustrator});
  };

  private handleSubmit = () => {
    const {route, navigation, storyForm, updateStory} = this.props;
    const {episodeIdToBePublished, skipTitleValidation} = route.params;
    const storyId = storyForm?.id;
    if (!storyForm || !storyId) {
      return;
    }
    const {title, ...restParams} = storyForm.toParams();
    const params = skipTitleValidation ? restParams : {title, ...restParams};
    this.setState({loading: true}, () => {
      updateStory(storyId, params)
        .then(() => {
          this.setState({loading: false}, async () => {
            navigation.goBack();
            navigation.goBack();
            if (episodeIdToBePublished) {
              if (await isFulfilledEpisode(episodeIdToBePublished)) {
                routers.linkToEpisodeFormEditEpisodePublication(navigation, {
                  episodeId: episodeIdToBePublished,
                });
              } else {
                routers.linkToEditEpisode(navigation, {
                  episodeId: episodeIdToBePublished,
                  toBePublished: true,
                });
              }
            }
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private handleRequestReset = () => {
    const {updateCoverImageForm} = this.props;
    updateCoverImageForm({
      type: 'image',
      image: null,
      hasImage: false,
      originalImageUrl: null,
      backgroundImageUri: null,
      characterImageUri: null,
      imageTextInfoList: null,
      uploadedSelfImage: false,
    });
  };

  private handleForwardToImageSelection = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    this.setupCoverImageForm().then(() => {
      (navigation as any).navigate('CoverImageNavigation', {
        screen: 'CoverImageFormBackgroundUsageHistories',
        params: {storyId, aspectRatio, imageKey: 'image'},
      });
    });
  };

  private handleForwardToCharacterImageSelection = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    this.setupCoverImageForm().then(() => {
      (navigation as any).navigate('CoverImageNavigation', {
        screen: 'CoverImageFormCharacterPatterns',
        params: {storyId, aspectRatio, imageKey: 'image'},
      });
    });
  };

  private handleForwardToTextInput = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    this.setupCoverImageForm().then(() => {
      (navigation as any).navigate('CoverImageNavigation', {
        screen: 'CoverImageFormText',
        params: {storyId, aspectRatio, imageKey: 'image'},
      });
    });
  };

  private setupCoverImageForm = async () => {
    const {storyForm, createCoverImageForm} = this.props;
    if (!storyForm) {
      return;
    }
    await createCoverImageForm({
      type: 'image',
      image: storyForm.image,
      hasImage: storyForm.hasImage,
      originalImageUrl: storyForm.originalImageUrl,
      backgroundImageUri: storyForm.imageBackgroundUri,
      characterImageUri: storyForm.imageCharacterUri,
      uploadedSelfImage: storyForm.uploadedSelfImage,
      imageTextInfoList: storyForm.imageTextInfoList,
    });
  };

  private handlePressClose = () => {
    const {navigation} = this.props;
    navigation.goBack();
    navigation.goBack();
  };
}

const aspectRatio = 2480 / 1555;
