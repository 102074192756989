import * as React from 'react';
import {
  Image,
  ImageStyle,
  Linking,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Config from 'react-native-config';

import SpeachBalloon from './SpeachBalloon';
import TipIcon from './TipIcon';
import ReactionIcon from './ReactionIcon';

import MoreIcon from '../../../shared/icons/ionicons/MoreIcon';
import StaffIcon from '../../../shared/icons/StaffIcon';
import WriterIcon from '../../../shared/icons/WriterIcon';
import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import convertImageUri from '../../../../helpers/convertImageSource';
import {formatElapsedTime} from '../../../../helpers/times';

import EpisodeComment from '../../../../../domain/entities/EpisodeComment';

import noimageUserUri from '../../../../../assets/images/response/noimage_user.png';

interface Props {
  episodeComment: EpisodeComment;
  blocked?: boolean;
  children?: React.ReactNode;
  onRequestActionSheetForComment?: (episodeComment: EpisodeComment) => void;
}

const generateUrl = (path: string) => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  } else {
    return Config.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  }
};

const Comment: React.FunctionComponent<Props> = props => {
  const {episodeComment, blocked, children, onRequestActionSheetForComment} =
    props;
  const handleRequestActionSheetForComment = React.useCallback(() => {
    onRequestActionSheetForComment &&
      onRequestActionSheetForComment(episodeComment);
  }, [episodeComment, onRequestActionSheetForComment]);
  const handlePressWriter = React.useCallback(() => {
    if (episodeComment.user.linkedWriterUser) {
      Linking.openURL(
        generateUrl(`/writers/${episodeComment.user.linkedWriterUser.id}`),
      );
    }
  }, []);
  return (
    <View style={styles.container}>
      <View style={{alignItems: 'center'}}>
        {episodeComment.user.linkedWriterUser &&
        episodeComment.user.linkedWriterUser.userProfile?.hasImage ? (
          <Image
            style={[styles.image, {borderRadius: 23}]}
            source={{
              uri: episodeComment.user.linkedWriterUser.userProfile.getImageUrl(
                {
                  width: 47 * 2,
                  height: 47 * 2,
                },
              ),
            }}
          />
        ) : episodeComment.user.userProfile &&
          episodeComment.user.userProfile.hasImage ? (
          <Image
            style={[styles.image, {borderRadius: 23}]}
            source={{
              uri: episodeComment.user.userProfile.getImageUrl({
                width: 47 * 2,
                height: 47 * 2,
              }),
            }}
          />
        ) : (
          <Image
            style={styles.image}
            source={convertImageUri(noimageUserUri)}
            resizeMode={'stretch'}
          />
        )}
        {episodeComment.user.linkedWriterUser ? (
          <TouchableOpacity
            disabled={!episodeComment.user.linkedWriterUser}
            onPress={handlePressWriter}>
            <Text style={{color: '#999', fontSize: 9, marginTop: 4}}>
              詳細
              <Text style={{paddingLeft: 4}}>
                <ArrowForwardIcon size={7} color={'#999'} />
              </Text>
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
      <View style={styles.body}>
        <View style={styles.header}>
          <View style={styles.headerRight}>
            <TouchableOpacity
              disabled={!episodeComment.user.linkedWriterUser}
              onPress={handlePressWriter}>
              <Text style={styles.userName}>
                {episodeComment.user.linkedWriterUser
                  ? episodeComment.user.linkedWriterUser.penName
                  : episodeComment.user.userName || 'ゲスト'}
              </Text>
            </TouchableOpacity>
            {episodeComment.user.inhouseStaff ? (
              <View style={styles.icon}>
                <StaffIcon />
              </View>
            ) : null}
            {episodeComment.user.linkedWriterUser ? (
              <View style={styles.icon}>
                <WriterIcon />
              </View>
            ) : null}
            {blocked && <Text style={styles.block}>ブロック中</Text>}
          </View>
          {onRequestActionSheetForComment && (
            <Pressable onPress={handleRequestActionSheetForComment}>
              <MoreIcon />
            </Pressable>
          )}
        </View>
        <Text style={styles.postDate}>
          {formatElapsedTime(episodeComment.createdAt)}
        </Text>
        <SpeachBalloon style={styles.speachBalloon}>
          <Text style={styles.comment}>
            {episodeComment.episodeTip ? (
              <Text
                style={{
                  marginRight: 4,
                }}>
                <TipIcon tip={episodeComment.episodeTip.tip} size={12} />
              </Text>
            ) : null}
            {episodeComment.episodeReaction ? (
              <Text
                style={{
                  marginRight: 4,
                }}>
                <ReactionIcon
                  reaction={episodeComment.episodeReaction.reaction}
                  size={12}
                />
              </Text>
            ) : null}
            {episodeComment.comment}
          </Text>
        </SpeachBalloon>
        {children}
      </View>
    </View>
  );
};

export default React.memo(Comment);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 8,
    paddingHorizontal: 16,
  } as ViewStyle,
  image: {
    width: 47,
    height: 47,
  } as ImageStyle,
  body: {
    flex: 1,
    marginLeft: 10,
    marginTop: 4,
  } as ViewStyle,
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  userName: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 2,
  } as TextStyle,
  icon: {
    marginHorizontal: 2,
  } as ViewStyle,
  postDate: {
    color: '#999',
    fontSize: 10,
    marginTop: 2,
    marginBottom: 4,
  } as TextStyle,
  speachBalloon: {
    width: '100%',
  } as ViewStyle,
  comment: {
    padding: 10,
    width: '100%',
    color: '#222',
    fontSize: 12,
  } as TextStyle,
  block: {
    backgroundColor: '#383838',
    color: 'white',
    fontSize: 8,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 4,
    lineHeight: 15,
    marginHorizontal: 4,
  } as TextStyle,
});
