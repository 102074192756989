import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Select from '../../../shared/forms/Select';

type ItemValue = number | string;

interface Item {
  label: string;
  value: ItemValue;
}

interface Props {
  items: Array<Item>;
  value: any;
  label: string;
  onValueChange: (itemValue: number, itemPosition: number) => void;
}

const SelectWrapper: React.FunctionComponent<Props> = props => {
  const {items, value, label, onValueChange} = props;
  const handleValueChange = React.useCallback(
    (itemValue: ItemValue, itemPosition: number) => {
      onValueChange(Number(itemValue), itemPosition);
    },
    [],
  );
  return (
    <View style={label === '年' ? styles.containerYear : styles.container}>
      <Select
        style={styles.selectStyle}
        pickerStyle={styles.selectPickerStyle}
        items={items}
        value={value}
        rightIcon={<View style={styles.arrowIcon} />}
        onValueChange={handleValueChange}
      />
      <Text style={styles.textLabel}>{label}</Text>
    </View>
  );
};

export default React.memo(SelectWrapper);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
  } as ViewStyle,
  containerYear: {
    flex: 1.5,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 4,
  } as ViewStyle,
  selectStyle: {
    width: undefined,
    flex: 1,
  } as ViewStyle,
  selectPickerStyle: {
    fontSize: 14,
    textAlign: 'left',
  } as TextStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  } as ViewStyle,
  textLabel: {
    fontSize: 12,
    paddingLeft: 8,
    fontWeight: 'bold',
  } as TextStyle,
});
