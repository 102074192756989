import * as React from 'react';
import {
  GestureResponderEvent,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
  highlightStyle?: StyleProp<ViewStyle>;
  fontStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  unwrappedText?: boolean;
  children?: React.ReactNode;
  onPress?: (e: GestureResponderEvent) => void;
  onPressIn?: (e: GestureResponderEvent) => void;
}

const Button: React.FunctionComponent<Props> = props => {
  const {
    style,
    highlightStyle,
    fontStyle,
    disabled,
    unwrappedText,
    children,
    onPress,
    onPressIn,
  } = props;
  return (
    <Pressable disabled={disabled} onPress={onPress} onPressIn={onPressIn}>
      {({pressed}) => (
        <View
          style={[styles.container, style, pressed ? highlightStyle : null]}>
          {unwrappedText ? (
            children
          ) : (
            <Text style={[styles.font, fontStyle]}>{children}</Text>
          )}
        </View>
      )}
    </Pressable>
  );
};

export default React.memo(Button);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    borderRadius: 24,
    borderStyle: 'solid',
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  } as ViewStyle,
  font: {
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
});
