import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import BaseBackground from '../../../domain/entities/BaseBackground';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetBaseBackgroundsRepository from '../../../data/repositories/writer/NetBaseBackgroundsRepository';

const types = {
  failure: Types.IndexBaseBackgroundsFailure,
  request: Types.IndexBaseBackgroundsRequest,
  success: Types.IndexBaseBackgroundsSuccess,
};

export interface Params extends BaseParams {
  allBackgroundCategoryId?: number | null;
  backgroundCategoryId?: number | null;
  query?: string;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
  onlyWallpaper?: boolean;
  wallpaperGenreId?: number[] | null;
  storyId?: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<BaseBackground>> {
  return indexGenerics(new NetBaseBackgroundsRepository(), types, params);
}
