import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/full_screen_illustration_show_scene_command/edit/full_screen_illustrations/Index';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import indexFullScreenIllustrations, {
  Params as FullScreenIllustrationIndexParams,
} from '../../../../../actions/full_screen_illustrations/index';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import selectEntities from '../../../../../helpers/selectEntities';
import {SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrationsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrationsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {storyId, parentSceneCommandId} = route.params;
  const sceneForm = forms.sceneForm;
  const fullScreenIllustrationsParams: FullScreenIllustrationIndexParams = {
    storyId,
  };
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  const fullScreenIllustrations = selectEntities(
    entities.fullScreenIllustrations,
    queries.fullScreenIllustrations,
    fullScreenIllustrationsParams,
  );
  return {
    sceneForm,
    fullScreenIllustrations,
    fullScreenIllustrationsParams,
    navigation,
    parentSceneCommandForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexFullScreenIllustrations: (params: FullScreenIllustrationIndexParams) =>
      dispatch(indexFullScreenIllustrations(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
