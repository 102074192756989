import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Story from '../../../domain/entities/Story';

import NetStoriesRepository from '../../../data/repositories/writer/NetStoriesRepository';

import StoryPublicationCreateEvent from '../../../domain/services/analytics_particular_events/StoryPublicationCreateEvent';

export interface Params {
  enableSyncEpisodesPrivateStatus?: boolean;
  enableSyncCharactersPrivateStatus?: boolean;
  published: boolean;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Story> {
  return async (dispatch: Dispatch<any>): Promise<Story> => {
    try {
      const payload = await new NetStoriesRepository().update(
        id,
        {story: params},
        {multipart: true},
      );
      dispatch({payload, type: Types.UpdateStorySuccess});
      sendDefaultCustomEvent({
        resourceType: 'story_publication',
        resourceId: id,
        actionName: payload.published ? 'create' : 'destroy',
      });
      new StoryPublicationCreateEvent().create(payload);
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateStoryFailure});
      throw err;
    }
  };
}
