import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import EpisodeCommentBlockingUser from '../../../domain/entities/EpisodeCommentBlockingUser';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexEpisodeCommentBlockingUsersSuccess: {
      const result =
        action.payload as PaginatedResult<EpisodeCommentBlockingUser>;
      return mergeResponse(state, result);
    }
    case Types.CreateEpisodeCommentBlockingUserSuccess: {
      const episodeCommentBlockingUser =
        action.payload as EpisodeCommentBlockingUser;
      const createState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        response.pageToIds[1] = [
          ...response.pageToIds[1],
          episodeCommentBlockingUser.id,
        ];
        createState[stateKey] = response;
      });
      return createState;
    }
    case Types.DestroyEpisodeCommentBlockingUserSuccess: {
      const id = action.payload as number;
      const destroyState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        Object.keys(response.pageToIds).forEach((pageKey: string) => {
          const nPageKey = Number(pageKey);
          response.pageToIds[nPageKey] = response.pageToIds[nPageKey].filter(
            episodeCommentBlockingUserId => {
              return episodeCommentBlockingUserId !== id;
            },
          );
        });
        destroyState[stateKey] = response;
      });
      return destroyState;
    }
    default:
      return state;
  }
}
