import * as React from 'react';
import {
  Image,
  ImageStyle,
  Platform,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import Position from '../../../../../../../domain/value_objects/Position';

import convertImageSource from '../../../../../../helpers/convertImageSource';

import leftUri from '../../../../../../../assets/images/character_positions/left.png';
import centerUri from '../../../../../../../assets/images/character_positions/center.png';
import rightUri from '../../../../../../../assets/images/character_positions/right.png';

const resizeMode = 'contain';

interface Props {
  style?: ViewStyle;
  position: Position;
}

const CharacterGhostView: React.FunctionComponent<Props> = props => {
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={
          props.position === Position.Left
            ? convertImageSource(leftUri)
            : props.position === Position.Center
            ? convertImageSource(centerUri)
            : convertImageSource(rightUri)
        }
        resizeMode={resizeMode}
      />
    </View>
  );
};

export default React.memo(CharacterGhostView);

const IMAGE_SIZE = 130;

const styles = StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
    height: 150,
    alignItems: 'center',
    justifyContent: 'flex-end',
  } as ViewStyle,
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  } as ImageStyle,
});
