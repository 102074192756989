import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import New, {
  DispatchProps,
  StateProps,
} from '../../../components/faq/questions/New';

import AppState from '../../../reducers/AppState';

import createWriterContact, {
  Params as WriterContactCreateParams,
} from '../../../actions/writer/contacts/create';
import updateApplicationModal, {
  Params as ApplicationModalUpdateParams,
} from '../../../actions/application_modal/update';

import {NewFaqQuestionRouteProp} from '../../../navigators/RouteProps';

import selectEntity from '../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
  route: NewFaqQuestionRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  return {
    navigation,
    currentUser,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createWriterContact: (params: WriterContactCreateParams) =>
      dispatch(createWriterContact(params)),
    updateApplicationModal: (params: ApplicationModalUpdateParams) =>
      dispatch(updateApplicationModal(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
