import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import MemberList from './MemberList';

import ProjectMember from '../../../../domain/entities/ProjectMember';

interface Props {
  projectId: number;
  members: ProjectMember[];
  totalMembersCount: number;
}

const MemberListSection: React.FC<Props> = props => {
  const {projectId, members, totalMembersCount} = props;
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.title}>このプロジェクトのメンバー</Text>
        <Text style={styles.memberCount}>全{totalMembersCount}人</Text>
      </View>
      <MemberList projectId={projectId} members={members} />
    </View>
  );
};

export default React.memo(MemberListSection);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
  } as ViewStyle,
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  memberCount: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
});
