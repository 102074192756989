import * as React from 'react';

import ManuscriptList from '../partials/ManuscriptList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectIterationRouteProp} from '../../../navigators/RouteProps';

import ProjectIteration from '../../../../domain/entities/ProjectIteration';
import ProjectManuscript from '../../../../domain/entities/ProjectManuscript';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectIterationRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {id, projectId} = route.params;
  const [iteration, setIteration] = React.useState<ProjectIteration | null>(
    null,
  );
  const [manuscripts, setManuscripts] = React.useState<
    ProjectManuscript[] | null
  >(null);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<ProjectIteration>(
      `/api/writer/project_iterations/${id}`,
      {projectId},
    ).then(result => {
      setIteration(result.body);
    });

    TapNovelTypedRestApi.get<ProjectManuscript[]>(
      `/api/writer/project_manuscripts`,
      {projectId, projectIterationId: id},
    )
      .then(result => {
        setManuscripts(result.body);
      })
      .catch(() => {});
  }, []);
  return (
    <Layout
      title={iteration ? `第${iteration.number}ターム` : ''}
      navigation={navigation}
      back={true}
      scrollable={false}>
      {manuscripts && <ManuscriptList manuscripts={manuscripts} />}
    </Layout>
  );
};

export default React.memo(Show);
