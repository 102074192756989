import SceneCommand from './SceneCommand';

import Mark from '../../entities/Mark';

import Position from '../Position';

import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class MarkShowSceneCommand implements SceneCommand {
  constructor(public mark: Mark, public position: Position) {}

  public getValue() {
    return {
      mark_id: this.mark.id,
      position: this.position.toString(),
    };
  }

  public toForm(): SceneCommandForm {
    throw Error('MarkShowSceneCommand#toForm() error');
  }
}
