import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import dateformat from 'dateformat';

import CommandIndexBar from './CommandIndexBar';
import EpisodeBrowsingRetentionRateGraph from './EpisodeBrowsingRetentionRateGraph';
import DraggableView from './DraggableView';
import TitleWithQuestion from './TitleWithQuestion';
import NotInTermRange from './NotInTermRange';

import DimensionContext from '../../../shared/dimension/DimensionContext';

import EpisodeBrowsingRetentionRate from '../../../../../domain/value_objects/summary/EpisodeBrowsingRetentionRate';
import SceneCommand from '../../../../../domain/value_objects/summary/SceneCommand';

interface Props {
  episodeBrowsingRetentionRates: Array<EpisodeBrowsingRetentionRate>;
  sceneCommands: Array<SceneCommand>;
  inTermRange: boolean;
  onPressRetentionRate: () => void;
}

const EpisodeBrowsingRetentionRateGraphWrapper: React.FunctionComponent<
  Props
> = props => {
  const {
    episodeBrowsingRetentionRates,
    sceneCommands,
    inTermRange,
    onPressRetentionRate,
  } = props;
  const [point, setPoint] = React.useState<{
    top: number;
    left: number;
    commandIndex: number;
  } | null>(null);
  const onTouchPoint = React.useCallback(
    (point: {top: number; left: number; commandIndex: number}) => {
      setPoint(point);
    },
    [],
  );
  return (
    <>
      <View style={styles.container}>
        <TitleWithQuestion onPressRetentionRate={onPressRetentionRate} />
        <DimensionContext.Consumer>
          {context => {
            const width = context.content.width - 32;
            return (
              <>
                <EpisodeBrowsingRetentionRateGraph
                  episodeBrowsingRetentionRates={episodeBrowsingRetentionRates}
                  width={width}
                  onTouchPoint={onTouchPoint}
                />
                <CommandIndexBar point={point} />
                <DraggableView
                  width={width}
                  dataLength={episodeBrowsingRetentionRates.length}
                  setPoint={setPoint}
                />
              </>
            );
          }}
        </DimensionContext.Consumer>
        {!inTermRange && <NotInTermRange />}
        <View style={styles.bottom}>
          {/**
            <View style={styles.lineSamples}>
              <View style={styles.lineSample} />
              <Text style={styles.lineLabel}>指定期間</Text>
              <View style={styles.lineAvgSample}>
                <View style={styles.lineAvgSamplePart} />
                <View style={styles.lineAvgSamplePart} />
              </View>
              <Text style={styles.lineLabel}>カテゴリ平均</Text>
            </View>               
               */}
          <Text style={styles.updatedTime}>
            更新日時：{dateformat(new Date(), 'yyyy.mm.dd HH:MM')}
          </Text>
        </View>
      </View>
      {point && (
        <View style={styles.selectCommandBox}>
          <Text style={styles.selectCommandBoxText}>
            {point.commandIndex + 1}Tap
          </Text>
          <Text style={styles.selectCommandBoxText}>
            {sceneCommands[point.commandIndex].content}
          </Text>
        </View>
      )}
    </>
  );
};

export default React.memo(EpisodeBrowsingRetentionRateGraphWrapper);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    marginVertical: 16,
  } as ViewStyle,
  bottom: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  } as ViewStyle,
  updatedTime: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  selectCommandBox: {
    backgroundColor: '#fafafa',
    borderRadius: 3,
    borderColor: '#efefef',
    borderWidth: 1,
    padding: 16,
  } as ViewStyle,
  selectCommandBoxText: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
});
