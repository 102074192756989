import * as React from 'react';

import AddSceneCommandLink from './AddSceneCommandLink';

import MusicNoteOutlineIcon from '../../../../shared/icons/MusicNoteOutlineIcon';

import {colors} from '../../../../../styles/variables';

interface Props {
  onPress: () => void;
}

const AddBackgroundMusicShowSceneCommandLink: React.FunctionComponent<
  Props
> = props => {
  const {onPress} = props;
  return (
    <AddSceneCommandLink title={'BGM'} onPress={onPress}>
      <MusicNoteOutlineIcon color={colors.textColor} />
    </AddSceneCommandLink>
  );
};

export default React.memo(AddBackgroundMusicShowSceneCommandLink);
