import {Dispatch} from 'redux';

import Types from '../Types';

export default function action() {
  return (dispatch: Dispatch<any>): Promise<null> => {
    dispatch({
      type: Types.DestroySceneForm,
    });
    return new Promise(resolve => resolve(null));
  };
}
