import * as React from 'react';
import {Platform, StyleSheet, Text, TextStyle} from 'react-native';

import {colors} from '../../../styles/variables';

interface Props {
  serialized: boolean;
}

const SeriesStateLabel: React.FunctionComponent<Props> = props => {
  const {serialized} = props;
  return (
    <Text style={serialized ? styles.serializing : styles.finished}>
      {serialized ? '連載中' : '完結'}
    </Text>
  );
};

export default React.memo(SeriesStateLabel);

const label = {
  fontSize: 8,
  height: Platform.select({web: 15, android: 14, default: 11}),
  width: 32,
  paddingHorizontal: 3,
  paddingTop: 1,
  textAlign: 'center',
} as TextStyle;

const styles = StyleSheet.create({
  finished: {
    ...label,
    borderColor: colors.lightGray,
    borderWidth: 1,
    color: colors.gray,
  } as TextStyle,
  serializing: {
    ...label,
    backgroundColor: colors.lightGray,
    color: 'white',
  } as TextStyle,
});
