import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import showGeneric from '../../generics/show';

import ManualCategory from '../../../../domain/entities/writer/ManualCategory';

import NetManualCategoriesRepository from '../../../../data/repositories/writer/NetManualCategoriesRepository';

const types = {
  failure: Types.ShowWriterManualCategoryFailure,
  request: Types.ShowWriterManualCategoryRequest,
  success: Types.ShowWriterManualCategorySuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<ManualCategory> {
  return showGeneric(new NetManualCategoriesRepository(), types, id);
}
