import EntityMapper from '../EntityMapper';

import ExtensionMissionMapper, {
  ExtensionMissionData,
} from './ExtensionMissionMapper';
import UserExtensionMission from '../../../../domain/entities/writer/UserExtensionMission';

export interface UserExtensionMissionData {
  id: number;
  completed: boolean;
  enabled_extension: boolean;
  writer_extension_mission: ExtensionMissionData;
}

export default class UserExtensionMissionMapper
  implements EntityMapper<UserExtensionMissionData, UserExtensionMission>
{
  private extensionMissionMapper = new ExtensionMissionMapper();

  public map(obj: UserExtensionMissionData): UserExtensionMission {
    return new UserExtensionMission(
      obj.id,
      obj.completed,
      obj.enabled_extension,
      this.extensionMissionMapper.map(obj.writer_extension_mission),
    );
  }
}
