import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import Question from '../../../../../domain/entities/writer/faq/Question';

interface Props {
  question: Question;
  selfWriterUserId?: number;
  onRequestResolve: () => void;
}

const ResolveButton: React.FC<Props> = props => {
  const {question, selfWriterUserId, onRequestResolve} = props;
  if (question.writerUser.id !== selfWriterUserId) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text>お問い合わせは解決しましたか？</Text>
      <PrimaryButton
        style={styles.button}
        disabled={
          question.writerUser.id !== selfWriterUserId || !!question.resolvedAt
        }
        onPress={onRequestResolve}>
        {question.resolvedAt ? '解決済み' : '解決済みにする'}
      </PrimaryButton>
    </View>
  );
};

export default React.memo(ResolveButton);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderRadius: 4,
    marginVertical: 24,
    marginHorizontal: 16,
    padding: 16,
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 12,
    textAlign: 'center',
  } as TextStyle,
  button: {
    marginTop: 16,
    width: 240,
    height: 46,
  } as ViewStyle,
});
