interface Dictionary {
  [key: string]: string;
}

interface Error {
  body?: {
    [key: string]: string[];
  };
  status?: number;
  headers?: Headers;
}

export function formatErrorMessages(dic: Dictionary, error: Error): string {
  const messages = generateErrorMessages(dic, error);
  if (messages.length <= 1) {
    return messages.join('\n');
  } else {
    return messages.map(m => `・${m}`).join('\n');
  }
}

export function generateErrorMessages(dic: Dictionary, error: Error): string[] {
  const messages: string[] = [];
  if (!error.body) {
    return ['エラーが発生しました。'];
  }
  const {body} = error;
  Object.keys(body).forEach(key => {
    if (body[key]) {
      if (typeof body[key] === 'string') {
        messages.push(`${body[key]}`);
      } else {
        body[key].forEach(message => {
          messages.push(`${message}`);
        });
      }
    } else {
      messages.push(`${key}`);
    }
  });
  return messages;
}
