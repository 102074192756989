import NetResourceRepository from '../../NetResourceRepository';
import NetResourceRepositoryAdapter from '../../NetResourceRepositoryAdapter';

import TapNovelRestApi from '../../../data_stores/net/TapNovelRestApi';

import CurrentUserMapper, {
  CurrentUserData,
} from '../../../entities/mappers/writer/CurrentUserMapper';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import SessionRepository from '../../../../domain/repositories/writer/users/SessionRepository';

export default class NetSessionRepository
  extends NetResourceRepository<CurrentUser, CurrentUserData>
  implements SessionRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/writer/users/sign_in',
        new CurrentUserMapper(),
      ),
    );
  }

  public async destroy(): Promise<void> {
    const path = '/api/writer/users/sign_out';
    await TapNovelRestApi.delete(path);
    return;
  }
}
