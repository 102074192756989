import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {
  type: 'none' | 'partial' | 'all';
}

const VideoShareLabel: React.FunctionComponent<Props> = props => {
  const {type} = props;
  if (type === 'none') {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {type === 'all' ? '動画共有OK' : '動画共有一部OK'}
      </Text>
    </View>
  );
};

export default React.memo(VideoShareLabel);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#d9edff',
    marginLeft: 8,
    paddingHorizontal: 4,
    height: 15,
  } as ViewStyle,
  text: {
    paddingTop: 1,
    textAlign: 'center',
    color: '#2c86d7',
    fontSize: 8,
    fontWeight: 'bold',
    lineHeight: 12,
  } as TextStyle,
});
