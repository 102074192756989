import * as React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import Layout from '../../shared/Layout';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import UnlockIcon from '../../shared/icons/UnlockIcon';

import NavigationProp from '../../../navigators/NavigationProp';
import {UserExtensionRouteProp} from '../../../navigators/RouteProps';

import {getImageSource} from '../../../helpers/extensionImages';
import {colors} from '../../../styles/variables';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

interface UserExtension {
  id: number;
  enabled: boolean;
  mission_name: string;
  writer_extension: {
    id: number;
    code: string;
    name: string;
    name_with_newline: string | null;
    description: string;
    created_at: string;
    updated_at: string;
  };
}

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: UserExtensionRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

interface State {
  userExtension: UserExtension | null;
}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const [userExtension, setUserExtension] =
    React.useState<UserExtension | null>(null);
  const {id} = route.params;
  React.useEffect(() => {
    TapNovelRestApi.get<UserExtension>(`/api/writer/user_extensions/${id}`)
      .then(response => {
        setUserExtension(response.body);
      })
      .catch(e => {
        if (e.status === 404) {
          navigation.goBack();
        }
      });
  }, []);
  const source = React.useMemo(
    () =>
      userExtension
        ? getImageSource(userExtension.writer_extension.code)
        : null,
    [userExtension],
  );
  const onPress = React.useCallback(() => {
    setTimeout(() => {});
    navigation.navigate('Extensions', {});
  }, []);
  return (
    <Layout
      title={'通知'}
      navigation={navigation}
      scrollable={false}
      back={true}>
      {userExtension && (
        <View style={styles.container}>
          <View style={styles.titleWrapper}>
            <UnlockIcon color={colors.orange} size={20} />
            <Text style={styles.title}>機能を拡張しよう！</Text>
          </View>

          <>
            <View style={styles.mission}>
              <Text style={styles.missionMessage}>
                「{userExtension.mission_name}」{'\n'}
                のミッションをクリアしました。{'\n'}
                拡張すれば以下の機能が使えるようになります。
              </Text>
            </View>
            <View style={styles.extension}>
              <Text style={styles.extensionName}>
                {userExtension.writer_extension.name}
              </Text>
              <Text style={styles.extensionDescription}>
                {userExtension.writer_extension.description}
              </Text>
              {source && <Image source={source} style={styles.image} />}
            </View>
          </>
          <PrimaryButton buttonSize={buttonSize} onPress={onPress}>
            機能拡張ページへ
          </PrimaryButton>
        </View>
      )}
    </Layout>
  );
};

export default React.memo(Show, () => true);

const buttonSize = {width: 240, height: 46};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  } as ViewStyle,
  titleWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 25,
  } as ViewStyle,
  title: {
    marginLeft: 5,
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.orange,
  } as TextStyle,
  mission: {
    marginBottom: 25,
  } as ViewStyle,
  missionMessage: {
    fontSize: 14,
    fontWeight: 'bold',
    color: colors.textColor,
    textAlign: 'center',
  } as TextStyle,
  extension: {
    marginHorizontal: 16,
    marginBottom: 25,
    padding: 25,
    backgroundColor: '#f5f5f5',
    alignItems: 'center',
  } as ViewStyle,
  extensionName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: colors.orange,
    marginBottom: 8,
  } as TextStyle,
  extensionDescription: {
    fontSize: 12,
    color: colors.textColor,
    marginBottom: 16,
  } as TextStyle,
  image: {
    width: 279,
    height: 220,
  } as ImageStyle,
});
