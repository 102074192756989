import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import SearchForm from '../../questions/components/SearchForm';

interface Props {}

const SearchFormSection: React.FC<Props> = props => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>お問い合わせは何ですか？</Text>
      <SearchForm />
    </View>
  );
};

export default React.memo(SearchFormSection);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    padding: 16,
    backgroundColor: '#efefef',
    borderRadius: 4,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 15,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 4,
  } as TextStyle,
});
