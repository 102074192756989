import EntityMapper from './EntityMapper';

import Contest from '../../../domain/entities/Contest';

export interface ContestData {
  id: number;
  contest_group_id: number | null;
  title: string;
  description: string;
  keyword_name: string;
  status: string;
  created_at: Date;
  updated_at: Date;
}

export default class ContestMapper
  implements EntityMapper<ContestData, Contest>
{
  public map(obj: ContestData): Contest {
    return new Contest(
      obj.id,
      obj.contest_group_id,
      obj.title,
      obj.description,
      obj.keyword_name,
      obj.status,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
