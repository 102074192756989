import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import New, {
  DispatchProps,
  StateProps,
} from '../../components/episode_comment_replies/New';

import AppState from '../../reducers/AppState';

import createEpisodeCommentReply, {
  Params as EpisodeCommentReplyCreateParams,
} from '../../actions/episode_comment_replies/create';
import showEpisodeComment from '../../actions/episode_comments/show';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createEpisodeCommentReply: (params: EpisodeCommentReplyCreateParams) =>
      dispatch(createEpisodeCommentReply(params)),
    showEpisodeComment: (id: number) => dispatch(showEpisodeComment(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
