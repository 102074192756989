import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Show, {DispatchProps, StateProps} from '../../components/helps/Show';

import AppState from '../../reducers/AppState';

import showHelpCategory from '../../actions/writer/help_categories/show';
import indexHelps, {
  Params as HelpIndexParams,
} from '../../actions/writer/helps';

import selectEntities from '../../helpers/selectEntities';
import selectEntity from '../../helpers/selectEntity';
import {HelpRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: HelpRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries} = state;
  const {writerHelpCategoryId} = route.params;
  const helpCategory = selectEntity(
    entities.writer.helpCategories,
    writerHelpCategoryId,
  );
  const helpsParams = {writerHelpCategoryId} as HelpIndexParams;
  const helps = selectEntities(
    entities.writer.helps,
    queries.writer.helps,
    helpsParams,
  );
  return {
    helpCategory,
    helps,
    helpsParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  props: Props,
) => {
  return {
    indexHelps: (params: HelpIndexParams) => dispatch(indexHelps(params)),
    showHelpCategory: (id: number) => dispatch(showHelpCategory(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
