import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import EpisodeComment from '../../../domain/entities/EpisodeComment';

export default function reducer(
  state: {[key: number]: EpisodeComment} = {},
  action: AnyAction,
): {[key: number]: EpisodeComment} {
  switch (action.type) {
    case Types.IndexEpisodeCommentsSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: EpisodeComment}, record: EpisodeComment) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.ShowEpisodeCommentSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case Types.DestroyEpisodeCommentSuccess: {
      const deleteState = {...state};
      delete deleteState[action.payload.id];
      return deleteState;
    }
    default:
      return state;
  }
}
