import ActorCategory from '../../domain/entities/ActorCategory';

export default (
  actorCategories: ActorCategory[],
  formValues: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    allowedInversion?: Array<'true' | 'false'>;
  },
) => {
  const {
    query,
    ageMin,
    ageMax,
    rootCategoryIdToActorCategoryIds,
    favorite,
    allowedInversion,
  } = formValues;
  const values = [];
  if (favorite) {
    values.push('お気に入り');
  }
  if (query) {
    values.push(query);
  }
  let ageRange = '';
  if (!(ageMin === 0 && ageMax === 6)) {
    if (ageMin !== 0) {
      ageRange = ageRange.concat(`${ageMin}0代`);
    }
    if (ageMin !== ageMax || ageMin === 0 || ageMax === 6) {
      ageRange = ageRange.concat('〜');
    }
    if (ageMax === 0) {
      ageRange = ageRange.concat(`10代未満`);
    } else if (ageMax !== 6 && ageMin !== ageMax) {
      ageRange = ageRange.concat(`${ageMax}0代`);
    }
  }
  if (ageRange) {
    values.push(ageRange);
  }
  if (allowedInversion) {
    if (
      allowedInversion.includes('true') &&
      allowedInversion.includes('false')
    ) {
      //
    } else if (allowedInversion.includes('true')) {
      values.push('反転機能:使用可');
    } else if (allowedInversion.includes('false')) {
      values.push('反転機能:使用不可');
    }
  }
  const idToObj: {[key: number]: ActorCategory} = {};
  actorCategories.forEach(actorCategory => {
    idToObj[actorCategory.id] = actorCategory;
  });
  if (typeof rootCategoryIdToActorCategoryIds === 'object') {
    Object.keys(rootCategoryIdToActorCategoryIds).forEach(parentId => {
      const ids = rootCategoryIdToActorCategoryIds[parentId];
      ids.forEach(id => {
        const actorCategory = idToObj[id];
        if (actorCategory) {
          values.push(actorCategory.name);
        }
      });
    });
  }
  return values.join('/');
};
