import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import TextArea from '../../shared/forms/TextArea';
import LabelWithOption from '../../shared/forms/LabelWithOption';
import TooltipModal from '../../shared/modals/TooltipModal';

import {formStyles} from '../../../styles/variables';

import convertImageSource from '../../../helpers/convertImageSource';

import episodeCommentForSupporterUri from '../../../../assets/images/tips/episode_comment_for_supporter.png';

interface Props {
  comment: string;
  onChangeComment: (comment: string) => void;
}

const FormFieldCommentForSupporter: React.FunctionComponent<Props> = props => {
  const {comment, onChangeComment: onChangeText} = props;
  const [visibleTooltipModal, setVisibleTooltipModal] = React.useState(false);
  return (
    <View style={formStyles.formGroup}>
      <LabelWithOption
        title={`サポーター向けコメント（最大${MAX_COMMENT_SIZE}文字）`}
        onPressQuestion={() => {
          setVisibleTooltipModal(true);
        }}
        length={comment.length}
        maxLength={MAX_COMMENT_SIZE}
      />
      <TextArea
        value={comment}
        style={comment.length <= MAX_COMMENT_SIZE ? null : styles.inputError}
        placeholder={'サポーター向けコメントを入力（任意）'}
        onChangeText={onChangeText}
      />
      <View style={styles.noteWrapper}>
        <Text style={styles.note}>
          ※読者からギフトアイテムが贈られた際に表示されます
        </Text>
      </View>
      <TooltipModal
        visible={visibleTooltipModal}
        title={'サポーター向けコメント'}
        description={
          '読者がギフトアイテムを贈ったあとに表示されるコメントなります。\nサポーターに向けた感謝のメッセージを記入しましょう。'
        }
        source={convertImageSource(episodeCommentForSupporterUri)}
        imageStyle={styles.tooltipModalImage}
        onCloseModal={() => {
          setVisibleTooltipModal(false);
        }}
      />
    </View>
  );
};

export default React.memo(FormFieldCommentForSupporter);

const MAX_COMMENT_SIZE = 200;

const styles = StyleSheet.create({
  noteWrapper: {
    alignItems: 'flex-end',
    width: '100%',
  } as ViewStyle,
  note: {
    color: '#383838',
    fontSize: 11,
    marginTop: -8,
  } as TextStyle,
  tooltipModalImage: {
    width: 279,
    height: 160,
  } as ImageStyle,
  inputError: {
    color: '#f23406',
  } as TextStyle,
});
