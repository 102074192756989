import EntityMapper from './EntityMapper';

import EffectMapper, {EffectData} from './EffectMapper';
import BaseEffectMapper, {BaseEffectData} from './BaseEffectMapper';

import EffectUsageHistory from '../../../domain/entities/EffectUsageHistory';

export interface EffectUsageHistoryData {
  id: number;
  story_id: number;
  effect: EffectData;
  base_effect: BaseEffectData;
  last_used_at: string;
  created_at: string;
}

export default class EffectUsageHistoryMapper
  implements EntityMapper<EffectUsageHistoryData, EffectUsageHistory>
{
  private effectMapper = new EffectMapper();
  private baseEffectMapper = new BaseEffectMapper();

  public map(obj: EffectUsageHistoryData): EffectUsageHistory {
    return new EffectUsageHistory(
      obj.id,
      obj.story_id,
      this.effectMapper.map(obj.effect),
      this.baseEffectMapper.map(obj.base_effect),
      new Date(obj.last_used_at),
      new Date(obj.created_at),
    );
  }
}
