import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import SearchInput from './partials/SearchInput';
import GenreSearch from './partials/GenreSearch';
import ProjectListSection from './partials/ProjectListSection';
import NewProjectButton from '../partials/NewProjectButton';

import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';

import Genre from '../../../../../domain/entities/Genre';
import Project from '../../../../../domain/entities/Project';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';
import NetGenresRepository from '../../../../../data/repositories/NetGenresRepository';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation} = props;
  const [genres, setGenres] = React.useState<Genre[] | null>(null);
  const [newArrivalProjects, setNewArrivalProjects] = React.useState<
    Project[] | null
  >(null);
  const [nearingCapacityProjects, setNearingCapacityProjects] = React.useState<
    Project[] | null
  >(null);
  const [lastUpdatedProjects, setLastUpdatedProjects] = React.useState<
    Project[] | null
  >(null);
  const [popularProjects, setPopularProjects] = React.useState<
    Project[] | null
  >(null);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelTypedRestApi.get<Project[]>('/api/writer/projects/new_arrival')
        .then(result => {
          setNewArrivalProjects(result.body);
        })
        .catch(error => {
          if (error.status === 403) {
            navigation.navigate('Home', {});
          }
        });
      TapNovelTypedRestApi.get<Project[]>(
        '/api/writer/projects/nearing_capacity',
      ).then(result => {
        setNearingCapacityProjects(result.body);
      });
      TapNovelTypedRestApi.get<Project[]>(
        '/api/writer/projects/last_updated',
      ).then(result => {
        setLastUpdatedProjects(result.body);
      });
      TapNovelTypedRestApi.get<Project[]>('/api/writer/projects/popular').then(
        result => {
          setPopularProjects(result.body);
        },
      );
      new NetGenresRepository().findAll().then(result => {
        setGenres(result.records);
      });
    }, []),
  );
  return (
    <Layout
      title={'プロジェクト検索'}
      navigation={navigation}
      scrollViewStyle={{paddingBottom: 100}}
      back={true}
      footer={<NewProjectButton />}>
      {newArrivalProjects &&
      nearingCapacityProjects &&
      lastUpdatedProjects &&
      popularProjects &&
      genres ? (
        <>
          <SearchInput />
          <GenreSearch genres={genres} />
          <ProjectListSection
            title={'新着プロジェクト'}
            projects={newArrivalProjects}
          />
          <ProjectListSection
            title={'募集定員間近のプロジェクト'}
            projects={nearingCapacityProjects}
          />
          <ProjectListSection
            title={'最近更新されたプロジェクト'}
            projects={lastUpdatedProjects}
          />
          <ProjectListSection
            title={'直近の人気プロジェクト'}
            projects={popularProjects}
          />
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
