import * as React from 'react';
import {
  Platform,
  StyleProp,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {Picker} from '@react-native-picker/picker';

import ArrowForwardIcon from '../icons/ArrowForwardIcon';

import {size} from '../../../styles/variables';

type ItemValue = number | string;

interface Item {
  label: string;
  value: ItemValue;
}

export interface Props {
  style?: StyleProp<ViewStyle>;
  pickerStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  items: Array<Item>;
  value: any;
  confirmText?: string;
  cancelText?: string;
  rightIcon?: React.ReactNode;
  onValueChange: (itemValue: ItemValue, itemPosition: number) => void;
}

interface State {
  selectedValue: any;
  selectedPosition: number;
  visiblePicker: boolean;
  userIsInteractingWithPicker: boolean;
}

export default class Select extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedPosition: -1,
      selectedValue: props.value,
      userIsInteractingWithPicker: false,
      visiblePicker: false,
    };
  }

  public render(): React.ReactNode {
    const {style, pickerStyle, items, rightIcon} = this.props;
    return (
      <View style={[styles.container, style]}>
        <Picker
          style={[styles.picker, pickerStyle]}
          selectedValue={this.state.selectedValue}
          onValueChange={this.handleValueChange}>
          {items.map(item => (
            <Picker.Item
              key={item.label}
              label={item.label}
              color={item.value === null ? '#9EA0A4' : undefined}
              value={item.value}
            />
          ))}
        </Picker>
        {rightIcon || <ArrowForwardIcon />}
      </View>
    );
  }

  private handleValueChange = (
    itemValue: ItemValue,
    selectedPosition: number,
  ) => {
    const {onValueChange} = this.props;
    const selectedValue = itemValue;
    this.setState({
      selectedPosition,
      selectedValue,
    });
    onValueChange(selectedValue, selectedPosition);
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#efefef',
    width: '100%',
    height: size.minTapHeight,
  } as ViewStyle,
  picker: Platform.select({
    web: {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      background: 'none',
      backgroundColor: 'white',
      border: 'none',
      borderTopWidth: 0,
      borderBottomWidth: 0,
      borderLeftWidth: 0,
      borderRightWidth: 0,
      fontSize: 15,
      outline: 'none',
      paddingVertical: 8,
      width: '100%',
    },
    default: {
      backgroundColor: 'white',
      fontSize: 15,
      height: size.minTapHeight,
      flex: 1,
    },
  }) as TextStyle,
});
