import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import EntitiesSearchButton from '../../../buttons/EntitiesSearchButton';

interface Props {
  totalCount: number;
  onPress: () => void;
}

const SearchButtonWithTotalCount: React.FunctionComponent<Props> = props => {
  const {totalCount, onPress} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.totalCount}>{totalCount}件該当</Text>

      <EntitiesSearchButton disabled={totalCount === 0} onPress={onPress} />
    </View>
  );
};

export default React.memo(SearchButtonWithTotalCount);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    height: 48,
    width: '100%',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: 16,
  } as ViewStyle,
  totalCount: {
    color: '#ff8f13',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
});
