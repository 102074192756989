import * as React from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import Input from '../../../shared/forms/Input';
import TextArea from '../../../shared/forms/TextArea';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import AlertModal from '../../../shared/modals/AlertModal';

import {colors, formStyles} from '../../../../styles/variables';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

export type FormValues = {
  title: string;
  content: string;
};

type FormErrorMessage = {[P in keyof FormValues]?: string | string[]};

interface Props {
  values?: Partial<FormValues>;
  errorMessages?: FormErrorMessage;
  onSubmit: (values: FormValues) => void;
}

const Form: React.FC<Props> = props => {
  const {values, errorMessages, onSubmit} = props;
  const [title, setTitle] = React.useState(values?.title || '');
  const [content, setContent] = React.useState(values?.content || '');
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalTooltip = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const onPress = () => {
    onSubmit({content, title});
  };
  const disabled =
    title.length <= 0 ||
    title.length > 40 ||
    content.length <= 0 ||
    content.length > 1000;
  return (
    <View style={styles.container}>
      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'トピックタイトル（最大40文字）'}
          maxLength={40}
          length={title.length}
          requiredOrOptional={'required'}
          entered={title.length > 0 && title.length <= 40}
          errorMessages={errorMessages?.title}
        />
        <Input
          style={[
            {marginBottom: 3, marginTop: 8},
            title.length === 0
              ? requiredInputStyle
              : title.length <= 40
              ? undefined
              : styles.inputError,
          ]}
          value={title}
          placeholder={'トピックタイトルを入力'}
          onChangeText={setTitle}
        />
      </View>
      <View style={formStyles.formGroup}>
        <LabelWithOption
          title={'本文（最大1000文字）'}
          length={content.length}
          maxLength={1000}
          requiredOrOptional={'required'}
          entered={content.length > 0 && content.length <= 1000}
          errorMessages={errorMessages?.content}
        />
        <TextArea
          style={[
            styles.textArea,
            content.length === 0
              ? requiredInputStyle
              : content.length <= 1000
              ? undefined
              : styles.inputError,
          ]}
          containerStyle={content ? undefined : requiredInputStyle}
          value={content}
          placeholder={'コメントを入力'}
          onChangeText={setContent}
        />
      </View>
      <View style={styles.button}>
        <PrimaryButton disabled={disabled} onPress={onPress}>
          トピックを作る
        </PrimaryButton>
      </View>
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
});
