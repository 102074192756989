// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SocialLogin, {ErrorData, Params, SuccessData} from './SocialLogin';

import TwitterRequestToken from '../../../domain/value_objects/TwitterRequestToken';

import RequestTokensRepository from '../../../data/repositories/writer/social_accounts/twitter_accounts/NetRequestTokensRepository';
import AccessTokensRepository from '../../../data/repositories/writer/social_accounts/twitter_accounts/NetAccessTokensRepository';

import {isSafari} from '../../../data/data_stores/net/UserAgent';

export type SuccessData = SuccessData;
export type ErrorData = ErrorData;

let currentRequestToken: TwitterRequestToken | null = null;

export default class TwitterLogin implements SocialLogin {
  public login = async (params: Params) => {
    const redirectUri = this.getRedirectUri();

    const requestToken = isSafari
      ? currentRequestToken || (await this.getRequestToken())
      : await this.getRequestToken();
    const {onSuccess} = params;
    const listener = async (event: MessageEvent) => {
      if (event.data && event.data.type !== 'twitter_oauth') {
        return;
      }
      window.removeEventListener('message', listener);
      const accessToken = await new AccessTokensRepository().create({
        redirectUri,
        token: event.data.params['oauth_token'],
        verifier: event.data.params['oauth_verifier'],
      });
      onSuccess({
        accessToken: accessToken.oauthToken,
        accessTokenSecret: accessToken.oauthTokenSecret,
        email: '',
        id: accessToken.userId,
        provider: 'twitter',
        screenName: accessToken.screenName,
      });
    };
    window.addEventListener('message', listener, false);

    window.open(
      `https://api.twitter.com/oauth/authenticate?oauth_token=${requestToken.token}`,
      undefined,
      `width=500,height=600,toolbar=no,menubar=no,left=${
        window.innerWidth / 2 - 250
      },top=${window.innerHeight / 2 - 300}`,
    );
    if (isSafari) {
      this.getRequestToken();
    }
  };

  public getRequestToken = async () => {
    const redirectUri = this.getRedirectUri();
    currentRequestToken = await new RequestTokensRepository().create({
      redirectUri,
    });
    return currentRequestToken;
  };

  private getRedirectUri = () => {
    return process.env.REACT_APP_TWITTER_WRITER_AUTH_REDIRECT_URI as string;
  };
}
