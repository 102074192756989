import * as React from 'react';
import {
  Image,
  ImageStyle,
  Linking,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Config from 'react-native-config';

import SpeachBalloon from './SpeachBalloon';
import ReactionIcon from './ReactionIcon';

import StaffIcon from '../../../shared/icons/StaffIcon';
import WriterIcon from '../../../shared/icons/WriterIcon';
import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import convertImageUri from '../../../../helpers/convertImageSource';
import {formatElapsedTime} from '../../../../helpers/times';

import EpisodeReaction from '../../../../../domain/entities/EpisodeReaction';

import noimageUserUri from '../../../../../assets/images/response/noimage_user.png';

interface Props {
  episodeReaction: EpisodeReaction;
}

const generateUrl = (path: string) => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  } else {
    return Config.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  }
};

const ReactionListItem: React.FunctionComponent<Props> = props => {
  const {episodeReaction} = props;
  const handlePressWriter = React.useCallback(() => {
    if (episodeReaction.user.linkedWriterUser) {
      Linking.openURL(
        generateUrl(`/writers/${episodeReaction.user.linkedWriterUser.id}`),
      );
    }
  }, []);
  return (
    <View style={styles.container}>
      <View style={{alignItems: 'center'}}>
        {episodeReaction.user.linkedWriterUser &&
        episodeReaction.user.linkedWriterUser.userProfile?.hasImage ? (
          <Image
            style={[styles.image, {borderRadius: 23}]}
            source={{
              uri: episodeReaction.user.linkedWriterUser.userProfile.getImageUrl(
                {
                  width: 47 * 2,
                  height: 47 * 2,
                },
              ),
            }}
          />
        ) : episodeReaction.user.userProfile &&
          episodeReaction.user.userProfile.hasImage ? (
          <Image
            style={[styles.image, {borderRadius: 23}]}
            source={{
              uri: episodeReaction.user.userProfile.getImageUrl({
                width: 47 * 2,
                height: 47 * 2,
              }),
            }}
          />
        ) : (
          <Image
            style={styles.image}
            source={convertImageUri(noimageUserUri)}
            resizeMode={'stretch'}
          />
        )}
        {episodeReaction.user.linkedWriterUser ? (
          <TouchableOpacity
            disabled={!episodeReaction.user.linkedWriterUser}
            onPress={handlePressWriter}>
            <Text style={{color: '#999', fontSize: 9, marginTop: 4}}>
              詳細
              <Text style={{paddingLeft: 4}}>
                <ArrowForwardIcon size={7} color={'#999'} />
              </Text>
            </Text>
          </TouchableOpacity>
        ) : null}
      </View>
      <View style={styles.right}>
        <View style={{flexDirection: 'row', alignItems: 'center'}}>
          <TouchableOpacity
            disabled={!episodeReaction.user.linkedWriterUser}
            onPress={handlePressWriter}>
            <Text style={styles.userName}>
              {episodeReaction.user.linkedWriterUser
                ? episodeReaction.user.linkedWriterUser.penName
                : episodeReaction.user.userName || 'ゲスト'}
            </Text>
          </TouchableOpacity>
          {episodeReaction.user.inhouseStaff ? (
            <View style={styles.icon}>
              <StaffIcon />
            </View>
          ) : null}
          {episodeReaction.user.linkedWriterUser ? (
            <View style={styles.icon}>
              <WriterIcon />
            </View>
          ) : null}
        </View>
        <Text style={styles.postDate}>
          {formatElapsedTime(episodeReaction.updatedAt)}
        </Text>
        <SpeachBalloon style={styles.speachBalloon}>
          <ReactionIcon reaction={episodeReaction.reaction} size={32} />
        </SpeachBalloon>
      </View>
    </View>
  );
};

export default React.memo(ReactionListItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 8,
    paddingHorizontal: 16,
  } as ViewStyle,
  image: {
    width: 47,
    height: 47,
  } as ImageStyle,
  right: {
    marginLeft: 10,
    marginTop: 4,
  } as ViewStyle,
  userName: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 2,
  } as TextStyle,
  icon: {
    marginHorizontal: 2,
  } as ViewStyle,
  postDate: {
    color: '#999',
    fontSize: 10,
    marginTop: 2,
    marginBottom: 4,
  } as TextStyle,
  speachBalloon: {
    width: 87,
    height: 64,
  } as ViewStyle,
});
