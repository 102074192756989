import * as React from 'react';

import RootStack from '../../RootStack';

import CoverImageFormBaseBackgroundSearchForm from '../../../containers/cover_image_form/base_background_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="CoverImageFormBaseBackgroundSearchForm">
      <RootStack.Screen
        name="CoverImageFormBaseBackgroundSearchForm"
        component={CoverImageFormBaseBackgroundSearchForm}
        options={{title: '背景検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
