import Story from '../entities/Story';

import File from '../entities/File';

import ImageTextInfo from '../value_objects/ImageTextInfo';

import fetchBlob from '../../data/data_stores/net/FetchBlob';

export default class CoverImageForm {
  public type: 'coverImage' | 'image' | 'videoImage' | null = null;
  public image: File | null | undefined = null;
  public hasImage: boolean | null = null;
  public originalImageUrl: string | null = null;
  public backgroundImageUri: string | null = null;
  public characterImageUri: string | null = null;
  public uploadedSelfImage: boolean | null | undefined = null;
  public imageTextInfoList: ImageTextInfo[] | null = null;

  public getBackgroundImageUri = async () => {
    if (this.backgroundImageUri) {
      return this.backgroundImageUri;
    }
    if (this.image) {
      return this.image.uri;
    }
    if (this.hasImage && this.originalImageUrl) {
      return await fetchBlob(this.originalImageUrl);
    }
    return null;
  };

  public getCharacterImageUri = () => {
    return this.characterImageUri;
  };

  public getImageTextInfoList = () => {
    return this.imageTextInfoList || [];
  };
}
