import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Sound from '../../../domain/entities/Sound';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetSoundsRepository from '../../../data/repositories/writer/NetSoundsRepository';

const types = {
  failure: Types.IndexSoundsFailure,
  request: Types.IndexSoundsRequest,
  success: Types.IndexSoundsSuccess,
};

export interface Params extends BaseParams {
  allSoundCategoryId?: number;
  soundCategoryId?: number;
  query?: string;
  onlyBackgroundMusic?: boolean;
  onlySoundEffect?: boolean;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Sound>> {
  return indexGenerics(new NetSoundsRepository(), types, params);
}
