import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import BaseSceneCommandModal from './BaseSceneCommandModal';

import IllustrationView from './visualizations/IllustrationView';
import AttachmentSoundView from '../../../../shared/sound/AttachmentSoundView';

import DeleteButton from './buttons/DeleteButton';
import ChangeButton from './buttons/ChangeButton';

import {ModalParams} from '../../Modal';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import Sound from '../../../../../../domain/entities/Sound';

interface Props {
  sceneCommandForm: IllustrationShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  enableSoundEffect?: boolean;
  onRequestCloseModal: () => void;
  onForwardToChangeIllustrations: (
    sceneCommandForm: IllustrationShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => void;
  onRemoveIllustrationShowCommand: (
    sceneCommandForm: IllustrationShowSceneCommandForm,
  ) => void;
  onForwardToAddSoundEffects: (
    sceneCommandForm: IllustrationShowSceneCommandForm,
    parentSceneCommandId?: number,
    callback?: (sound: Sound) => void,
  ) => void;
  onChangeIllustrationShowSceneCommandForm: (
    sceneCommandForm: IllustrationShowSceneCommandForm,
  ) => void;
  onChangeCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRequestUpdateModal: (modalParams: ModalParams) => void;
}

interface State {
  selectedSound: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
}

export default class IllustrationShowSceneCommandModal extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedSound: props.sceneCommandForm.sound || null,
      selectedSoundStartTime: props.sceneCommandForm.startTime || null,
      selectedSoundEndTime: props.sceneCommandForm.endTime || null,
    };
  }

  public render(): React.ReactNode {
    const {sceneCommandForm, enableSoundEffect, onRequestCloseModal} =
      this.props;
    const {selectedSound, selectedSoundStartTime, selectedSoundEndTime} =
      this.state;
    return (
      <BaseSceneCommandModal
        title={'アイテムの編集'}
        onRequestCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.footer}>
            <View style={styles.deleteButtons}>
              <DeleteButton onPress={this.destroySceneCommandForm} />
            </View>
          </View>
        }>
        <View style={styles.body}>
          <IllustrationView illustration={sceneCommandForm.illustration} />
          <View style={{position: 'absolute', right: 8, top: 8}}>
            <ChangeButton
              title={'アイテム変更'}
              onPress={this.changeIllustration}
            />
          </View>
          {enableSoundEffect ? (
            <AttachmentSoundView
              style={{width: '95%', marginVertical: 10}}
              sound={selectedSound}
              startTime={selectedSoundStartTime}
              endTime={selectedSoundEndTime}
              addSound={this.addSound}
              deleteSound={this.deleteSound}
              onChagenRegion={this.handleChagenRegion}
            />
          ) : null}
        </View>
      </BaseSceneCommandModal>
    );
  }

  private changeIllustration = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onForwardToChangeIllustrations,
    } = this.props;
    onForwardToChangeIllustrations(
      sceneCommandForm,
      parentSceneCommandForm?.sceneCommandId,
    );
    onRequestCloseModal();
  };

  private addSound = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onForwardToAddSoundEffects,
      onChangeCommand,
      onChangeIllustrationShowSceneCommandForm,
    } = this.props;
    onForwardToAddSoundEffects(
      sceneCommandForm,
      parentSceneCommandForm?.sceneCommandId,
      sound => {
        const newSceneCommandForm = new IllustrationShowSceneCommandForm(
          sceneCommandForm.illustration,
          sound,
          null,
          null,
          sceneCommandForm.sceneCommandId,
        );
        if (parentSceneCommandForm) {
          const newParentSceneCommandForm =
            CompositeSequenceSceneCommandFormFactory.update(
              parentSceneCommandForm,
              newSceneCommandForm,
            );
          onChangeCommand(newParentSceneCommandForm);
        } else {
          onChangeIllustrationShowSceneCommandForm(newSceneCommandForm);
        }
        this.setState({selectedSound: sound});
      },
    );
  };

  private deleteSound = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onChangeIllustrationShowSceneCommandForm,
      onChangeCommand,
    } = this.props;
    const newSceneCommandForm = new IllustrationShowSceneCommandForm(
      sceneCommandForm.illustration,
      null,
      null,
      null,
      sceneCommandForm.sceneCommandId,
    );
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          newSceneCommandForm,
        );
      onChangeCommand(newParentSceneCommandForm);
    } else {
      onChangeIllustrationShowSceneCommandForm(newSceneCommandForm);
    }
    this.setState({
      selectedSound: null,
      selectedSoundStartTime: null,
      selectedSoundEndTime: null,
    });
  };

  private handleChagenRegion = (start?: number, end?: number) => {
    const {sceneCommandForm, parentSceneCommandForm, onChangeCommand} =
      this.props;
    const newSceneCommandForm = sceneCommandForm.copy(
      sceneCommandForm.sceneCommandId,
    );
    newSceneCommandForm.sound = this.state.selectedSound;
    if (start == undefined && end == undefined) {
      newSceneCommandForm.startTime = null;
      newSceneCommandForm.endTime = null;
    } else {
      newSceneCommandForm.startTime = start === undefined ? null : start;
      newSceneCommandForm.endTime = end === undefined ? null : end;
    }
    this.setState({
      selectedSoundStartTime: start || null,
      selectedSoundEndTime: end || null,
    });
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          newSceneCommandForm,
        );
      onChangeCommand(newParentSceneCommandForm);
    } else {
      onChangeCommand(newSceneCommandForm);
    }
  };

  private destroySceneCommandForm = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onRemoveIllustrationShowCommand,
      onChangeCommand,
      onRemoveCommand,
    } = this.props;
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.remove(
          parentSceneCommandForm,
          sceneCommandForm,
        );
      if (newParentSceneCommandForm) {
        onChangeCommand(newParentSceneCommandForm);
      } else {
        onRemoveCommand(parentSceneCommandForm);
      }
    } else {
      onRemoveIllustrationShowCommand(sceneCommandForm);
    }
    onRequestCloseModal();
  };
}

const styles = StyleSheet.create({
  body: {
    flex: 1,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    width: '100%',
  } as ViewStyle,
  button: {
    marginVertical: 5,
  } as ViewStyle,
  deleteButtons: {
    marginTop: 5,
  } as ViewStyle,
  footer: {
    padding: 10,
  } as ViewStyle,
});
