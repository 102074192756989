import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import SearchConditionChangeButton from '../buttons/SearchConditionChangeButton';

interface Props {
  title: string;
  onPress?: () => void;
}

const SearchResultHeader: React.FunctionComponent<Props> = props => {
  const {title, onPress} = props;

  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      {onPress ? <SearchConditionChangeButton onPress={onPress} /> : null}
    </View>
  );
};

export default React.memo(SearchResultHeader);

const styles = StyleSheet.create({
  container: {
    height: 56,
    width: '100%',
    backgroundColor: '#fafafa',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    alignItems: 'center',
  } as ViewStyle,
  title: {
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
