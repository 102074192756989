import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import ReservationHome from '../../../containers/reservation/home/Index';
import ReservationApplicationCompletion from '../../../containers/reservation/application_completion/Show';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="ReservationHome">
      <RootStack.Screen
        name="ReservationHome"
        component={ReservationHome}
        options={{
          title: 'TapNovelMaker（クローズドベータ版）予約受付中！ ',
        }}
      />
      <RootStack.Screen
        name="ReservationApplicationCompletion"
        component={ReservationApplicationCompletion}
        options={{
          title:
            '予約しました！ゲーム小説投稿サービス『TapNovelMaker』予約受付中！',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
