import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories from '../../../../containers/scene_form/sound_effect_show_scene_command/new/sound_usage_histories/Index';
import SceneFormSoundEffectShowSceneCommandNewSoundSearchForm from '../../../../containers/scene_form/sound_effect_show_scene_command/new/sound_search_form/Index';
import SceneFormSoundEffectShowSceneCommandNewSounds from '../../../../containers/scene_form/sound_effect_show_scene_command/new/sounds/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories">
      <RootStack.Screen
        name="SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories"
        component={SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories}
        options={{title: '効果音'}}
      />
      <RootStack.Screen
        name="SceneFormSoundEffectShowSceneCommandNewSoundSearchForm"
        component={SceneFormSoundEffectShowSceneCommandNewSoundSearchForm}
        options={{title: '効果音検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormSoundEffectShowSceneCommandNewSounds"
        component={SceneFormSoundEffectShowSceneCommandNewSounds}
        options={{title: '効果音'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
