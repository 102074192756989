import * as React from 'react';

import Form, {FormValues} from './partials/Form';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewProjectManuscriptCommentRouteProp} from '../../../navigators/RouteProps';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  manuscriptId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewProjectManuscriptCommentRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {projectId, manuscriptId} = route.params;
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const onPressClose = React.useCallback(() => navigation.goBack(), []);
  const onSubmit = React.useCallback((values: FormValues) => {
    TapNovelTypedRestApi.post('/api/writer/project_manuscript_comments', {
      projectManuscriptComment: {...values},
      projectManuscriptId: manuscriptId,
    })
      .then(() => navigation.goBack())
      .catch(e => {
        setAlertMessage(formatErrorMessages({}, e));
      });
  }, []);
  return (
    <>
      <Layout
        title={'コメント'}
        navigation={navigation}
        close={true}
        onPressClose={onPressClose}>
        <Form onSubmit={onSubmit} />
      </Layout>
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalAlert}>
          {alertMessage}
        </AlertModal>
      )}
    </>
  );
};

export default React.memo(New);
