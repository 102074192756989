import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import showGeneric from '../../generics/show';

import Episode from '../../../../domain/entities/response/Episode';

import NetEpisodesRepository from '../../../../data/repositories/writer/response/NetEpisodesRepository';

const types = {
  failure: Types.ShowResponseEpisodeFailure,
  request: Types.ShowResponseEpisodeRequest,
  success: Types.ShowResponseEpisodeSuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Episode> {
  return showGeneric(new NetEpisodesRepository(), types, id);
}
