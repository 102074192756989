import * as React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

interface Props {
  published: boolean;
  scheduled?: boolean;
  children?: React.ReactNode;
}

const PublicStateLabel: React.FunctionComponent<Props> = props => {
  const {published, scheduled} = props;
  return (
    <Text
      style={[
        styles.label,
        published
          ? scheduled
            ? styles.scheduledDeliveredAt
            : styles.published
          : styles.notPublished,
      ]}>
      {published ? (scheduled ? '予約' : '公開') : '非公開'}
    </Text>
  );
};

export default React.memo(PublicStateLabel);

const styles = StyleSheet.create({
  label: {
    lineHeight: 12,
    fontSize: 8,
    fontWeight: 'bold',
    height: 15,
    minWidth: 35,
    maxWidth: 40,
    paddingHorizontal: 3,
    paddingTop: 1,
    textAlign: 'center',
  } as TextStyle,
  notPublished: {
    backgroundColor: '#383838',
    color: 'white',
  } as TextStyle,
  published: {
    backgroundColor: '#ff8f13',
    color: 'white',
  } as TextStyle,
  scheduledDeliveredAt: {
    borderColor: '#ff8f13',
    borderWidth: 1,
    color: '#ff8f13',
  } as TextStyle,
});
