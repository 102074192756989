import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import ActorCharacterFace from '../../../domain/entities/ActorCharacterFace';

import NetActorCharacterFacesRepository from '../../../data/repositories/writer/NetActorCharacterFacesRepository';

const types = {
  failure: Types.ShowActorCharacterFaceFailure,
  request: Types.ShowActorCharacterFaceRequest,
  success: Types.ShowActorCharacterFaceSuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<ActorCharacterFace> {
  return showGeneric(new NetActorCharacterFacesRepository(), types, id);
}
