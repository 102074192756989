import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Effect from '../../../domain/entities/Effect';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetEffectsRepository from '../../../data/repositories/writer/NetEffectsRepository';

const types = {
  failure: Types.IndexEffectsFailure,
  request: Types.IndexEffectsRequest,
  success: Types.IndexEffectsSuccess,
};

export interface Params extends BaseParams {
  baseEffectId?: number;
  allEffectCategoryId?: number;
  effectCategoryId?: number;
  query?: string;
  sort?: 'popularity' | 'new_arrival';
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Effect>> {
  return indexGenerics(new NetEffectsRepository(), types, params);
}
