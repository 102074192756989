import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import Effect from '../../../domain/entities/Effect';

export default function reducer(
  state: {[key: number]: Effect} = {},
  action: AnyAction,
): {[key: number]: Effect} {
  switch (action.type) {
    case Types.IndexEffectsSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: Effect}, record: Effect) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.ShowEffectSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
