import * as React from 'react';

import Setting from './Setting';

import convertImageSource from '../../../../helpers/convertImageSource';

import OauthAccount from '../../../../../domain/entities/OauthAccount';

import TikTokOauth from '../../../../../domain/services/social_logins/TikTokOauth';
import GoogleOauth from '../../../../../domain/services/social_logins/GoogleOauth';

// import instagramIconUri from "../../../../../assets/images/icons/instagram.png";
import tiktokIconUri from '../../../../../assets/images/icons/tiktok.png';
import youtubeIconUri from '../../../../../assets/images/icons/youtube.png';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

interface Props {
  oauthAccounts: OauthAccount[];
  onRequestUpdate: () => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {oauthAccounts, onRequestUpdate} = props;
  const youtubeConnected = !!oauthAccounts.find(
    o => o.oauthProviderCode === 'google_oauth2',
  );
  const tiktokConnected = !!oauthAccounts.find(
    o => o.oauthProviderCode === 'tiktok',
  );
  React.useEffect(() => {
    new TikTokOauth().getOauthAccount();
  }, []);
  const onPressYoutube = React.useCallback(() => {
    if (youtubeConnected) {
      TapNovelRestApi.delete(
        '/api/writer/social_accounts/google_accounts/oauth_account',
      ).then(onRequestUpdate);
    } else {
      new GoogleOauth().authorize(onRequestUpdate);
    }
  }, [youtubeConnected]);
  const onPressTiktok = React.useCallback(() => {
    if (tiktokConnected) {
      TapNovelRestApi.delete(
        '/api/writer/social_accounts/tiktok_accounts/oauth_account',
      ).then(onRequestUpdate);
    } else {
      new TikTokOauth().authorize(onRequestUpdate);
    }
  }, [tiktokConnected]);
  return (
    <>
      {/**
      <Setting
        label={"YouTube"}
        source={convertImageSource(youtubeIconUri)}
        connected={youtubeConnected}
        onPress={onPressYoutube}
      />
      */}
      <Setting
        label={'TikTok'}
        source={convertImageSource(tiktokIconUri)}
        connected={tiktokConnected}
        onPress={onPressTiktok}
      />
    </>
  );
};

export default React.memo(Form);
