import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import BackgroundUsageHistoryMapper, {
  BackgroundUsageHistoryData,
} from '../../entities/mappers/BackgroundUsageHistoryMapper';

import BackgroundUsageHistory from '../../../domain/entities/BackgroundUsageHistory';
import BackgroundUsageHistoriesRepository from '../../../domain/repositories/writer/BackgroundUsageHistoriesRepository';

export default class NetBackgroundUsageHistoriesRepository
  extends NetResourcesRepository<
    BackgroundUsageHistory,
    BackgroundUsageHistoryData
  >
  implements BackgroundUsageHistoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/background_usage_histories',
        new BackgroundUsageHistoryMapper(),
      ),
    );
  }
}
