import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import LabelWithOption from '../../../shared/forms/LabelWithOption';
import MultiSwitch from '../../../shared/forms/MultiSwitch';

import WritingMode from '../../../../../domain/value_objects/WritingMode';

interface Props {
  value?: WritingMode;
  onSelectItem: (item: {value: string}) => void;
}

export default class FontsSelect extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {value, onSelectItem} = this.props;
    return (
      <View style={styles.container}>
        <LabelWithOption title={'文字の並び'} />
        <MultiSwitch
          items={writingModeItems}
          value={value}
          onSelectItem={onSelectItem}
        />
      </View>
    );
  }
}

type WritingModeItem = {label: string; value: WritingMode};

const writingModeItems = [
  {label: '横', value: 'horizontal'},
  {label: '縦', value: 'vertical'},
] as Array<WritingModeItem>;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
  } as ViewStyle,
});
