import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import CheckCircleIcon from '../icons/CheckCircleIcon';

interface Props {
  style?: StyleProp<ViewStyle>;
  checked: boolean;
  children?: React.ReactNode;
  onPress: () => void;
}

const CheckButton: React.FunctionComponent<Props> = props => {
  const {style, checked, children, onPress} = props;
  return (
    <TouchableOpacity activeOpacity={0.6} onPress={onPress}>
      <View
        style={[
          styles.checkButton,
          checked ? styles.checkButtonActive : null,
          style,
        ]}>
        <Text style={styles.checkButtonIcon}>
          <CheckCircleIcon size={12} color={checked ? '#ff8f13' : '#d5d5d5'} />
        </Text>
        <Text
          style={[
            styles.checkButtonLabel,
            checked ? styles.checkButtonLabelActive : null,
          ]}>
          {children}
        </Text>
      </View>
    </TouchableOpacity>
  );
};
export default React.memo(CheckButton);

const styles = StyleSheet.create({
  checkButton: {
    backgroundColor: '#fff',
    borderColor: '#d5d5d5',
    borderWidth: 1,
    borderRadius: 4,
    padding: 8,
    flexDirection: 'row',
    marginVertical: 5,
  } as ViewStyle,
  checkButtonIcon: {
    paddingLeft: 1,
    paddingRight: 5,
  } as TextStyle,
  checkButtonLabel: {
    color: '#666',
    fontSize: 12,
  } as TextStyle,
  checkButtonActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
  checkButtonLabelActive: {
    color: '#ff8f13',
  } as TextStyle,
});
