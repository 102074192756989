import * as React from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';

import TrimmedSoundEffectView from './TrimmedSoundEffectView';

import Sound from '../../../../domain/entities/Sound';

interface Props {
  style?: StyleProp<ViewStyle>;
  sound: Sound;
  startTime?: number | null;
  endTime?: number | null;
  deleteSound: () => void;
  onChagenRegion?: (start?: number, end?: number) => void;
}

const AttachmentSoundAddViewTrimmed: React.FC<Props> = props => {
  const {style, sound, startTime, endTime, onChagenRegion, deleteSound} = props;
  return (
    <TrimmedSoundEffectView
      style={[{height: 36, width: '100%'}, style]}
      sound={sound}
      startTime={startTime}
      endTime={endTime}
      autofix={true}
      onChagenRegion={onChagenRegion}
      deleteSound={deleteSound}
    />
  );
};

export default React.memo(AttachmentSoundAddViewTrimmed);
