import * as React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import dateformat from 'dateformat';

import shouldUpdateEntity from '../../shared/enhanced/shouldUpdateEntity';

import {colors} from '../../../styles/variables';

import Announcement from '../../../../domain/entities/writer/Announcement';

interface Props {
  announcement: Announcement;
  onSelectAnnouncement: (announcement: Announcement) => void;
}

export default class AnnouncementListItem extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return shouldUpdateEntity(
      {entity: this.props.announcement},
      {entity: nextProps.announcement},
    );
  }

  public render(): React.ReactNode {
    const {announcement} = this.props;
    return (
      <Pressable onPress={this.handlePress}>
        <View style={styles.container}>
          <Text style={styles.categoryLabel}>
            {announcement.writerAnnouncementCategory.name}
          </Text>
          <View style={styles.title}>
            <Text style={styles.titleText}>{announcement.title}</Text>
          </View>
          <View style={styles.publishedDate}>
            <Text style={styles.publishedDateText}>
              {dateformat(announcement.publishedAt, 'yyyy.mm.dd')}
            </Text>
          </View>
        </View>
      </Pressable>
    );
  }

  private handlePress = () => {
    const {announcement, onSelectAnnouncement} = this.props;
    onSelectAnnouncement(announcement);
  };
}

const styles = StyleSheet.create({
  categoryLabel: {
    color: colors.orange,
    fontSize: 11,
  } as TextStyle,
  container: {
    paddingHorizontal: 16,
    paddingVertical: 10,
  } as ViewStyle,
  publishedDate: {
    marginTop: 5,
  } as TextStyle,
  publishedDateText: {
    color: colors.lightGray,
    fontSize: 11,
  } as TextStyle,
  title: {
    marginTop: 8,
  } as ViewStyle,
  titleText: {
    color: colors.textColor,
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: Platform.select({
      android: 18,
      ios: 14,
    }),
  } as TextStyle,
});
