import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import {mergeResponse, QueryState} from '../Response';

import OauthAccount from '../../../../domain/entities/OauthAccount';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.IndexWriterOauthAccountsSuccess: {
      const result = action.payload as PaginatedResult<OauthAccount>;
      return mergeResponse(state, result);
    }
    case Types.CreateWriterOauthAccountSuccess: {
      const oauthAccount = action.payload as OauthAccount;
      const createState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        response.pageToIds[1] = [oauthAccount.id, ...response.pageToIds[1]];
        createState[stateKey] = response;
      });
      return createState;
    }
    case Types.DestroyWriterOauthAccountSuccess: {
      const id = action.payload as number;
      const destroyState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        Object.keys(response.pageToIds).forEach((pageKey: string) => {
          const nPageKey = Number(pageKey);
          if (response.pageToIds[nPageKey]) {
            response.pageToIds[nPageKey] = response.pageToIds[nPageKey].filter(
              oauthAccountId => {
                return oauthAccountId !== id;
              },
            );
          }
        });
        destroyState[stateKey] = response;
      });
      return destroyState;
    }
    default:
      return state;
  }
}
