import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../../shared/Layout';
import PreviewBox from '../../../shared/cover_image_form/PreviewBox';
import AlertModal from '../../../shared/modals/AlertModal';

import NavigationProp from '../../../../navigators/NavigationProp';
import {NewFullVideoExportJobExecutionFormImageRouteProp} from '../../../../navigators/RouteProps';

import {Params as CoverImageFormCreateParams} from '../../../../actions/cover_image_forms/create';
import {Params as CoverImageFormUpdateParams} from '../../../../actions/cover_image_forms/update';

import {formatErrorMessages} from '../../../../helpers/errorMessages';
import {sendDefaultCustomEvent} from '../../../../helpers/analytics';

import CoverImageForm from '../../../../../domain/forms/CoverImageForm';
import File from '../../../../../domain/entities/File';

import NetEpisodeVideoExportJobExecutionsRepository from '../../../../../data/repositories/writer/NetEpisodeVideoExportJobExecutionsRepository';

export interface Params {
  storyId: number;
  episodeId: number;
  title: string;
  orientation: 'vertical' | 'horizontal';
  autoPlaySpeed: '1.0' | '1.5' | '2.0';
  openingMovie: 'blank' | 'present';
  endingMovie: 'blank' | 'present';
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewFullVideoExportJobExecutionFormImageRouteProp;
  coverImageForm: CoverImageForm | null;
}

export interface DispatchProps {
  createCoverImageForm: (params: CoverImageFormCreateParams) => Promise<any>;
  updateCoverImageForm: (params: CoverImageFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  fetchedBackgroundImageUri: boolean;
  backgroundImageUri: string | null;
  alertMessage: string | null;
}

export default class New extends React.PureComponent<Props, State> {
  private ref = React.createRef<PreviewBox>();

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      fetchedBackgroundImageUri: false,
      backgroundImageUri: null,
      alertMessage: null,
    };
  }

  public componentDidMount() {
    this.setupCoverImageForm();
  }

  public render(): React.ReactNode {
    const {navigation, route, coverImageForm} = this.props;
    const {
      loading,
      fetchedBackgroundImageUri,
      backgroundImageUri,
      alertMessage,
    } = this.state;
    const {orientation} = route.params;
    const aspectRatio =
      orientation === 'horizontal'
        ? horizontalAspectRatio
        : verticalAspectRatio;
    const widthAspectRatio =
      orientation === 'horizontal'
        ? widthHorizontalAspectRatio
        : widthVerticalAspectRatio;
    const recommendedSize =
      orientation === 'horizontal' ? '1280x720' : '1080x1920';
    return (
      <Layout
        title={'サムネイル'}
        navigation={navigation}
        back={true}
        close={true}
        loading={loading}
        hideHeaderBottomBorder={true}>
        <Form
          aspectRatio={aspectRatio}
          widthAspectRatio={widthAspectRatio}
          coverImageForm={coverImageForm}
          recommendedSize={recommendedSize}
          onChangeImage={this.handleChangeImage}
          onSubmit={this.handleSubmit}
          onRequestReset={this.handleRequestReset}
          onForwardToImageSelection={this.handleForwardToImageSelection}
          onForwardToCharacterImageSelection={
            this.handleForwardToCharacterImageSelection
          }
          onForwardToTextInput={this.handleForwardToTextInput}
        />
        {coverImageForm && fetchedBackgroundImageUri && (
          <PreviewBox
            ref={this.ref}
            backgroundImageUri={backgroundImageUri}
            characterImageUri={coverImageForm.getCharacterImageUri()}
            aspectRatio={
              orientation === 'horizontal'
                ? horizontalAspectRatio
                : verticalAspectRatio
            }
            imageTextInfoList={coverImageForm.getImageTextInfoList()}
            onDrawCanvas={this.handleDrawCanvas}
          />
        )}
        {alertMessage && (
          <AlertModal
            visible={true}
            onCloseModal={() => {
              this.setState({alertMessage: null});
            }}>
            {alertMessage}
          </AlertModal>
        )}
      </Layout>
    );
  }

  private handleChangeImage = (image: File) => {
    this.setState({
      fetchedBackgroundImageUri: true,
      backgroundImageUri: image.uri,
    });
  };

  private handleDrawCanvas = (image: File | null) => {
    const {updateCoverImageForm} = this.props;
    const {backgroundImageUri} = this.state;
    if (!this.ref.current) {
      return;
    }
    if (image) {
      updateCoverImageForm({
        image,
        backgroundImageUri: backgroundImageUri,
        uploadedSelfImage: true,
      });
      this.setState({fetchedBackgroundImageUri: false});
    }
  };

  private handleSubmit = () => {
    const {navigation, route, coverImageForm} = this.props;
    const {
      episodeId,
      title,
      orientation,
      autoPlaySpeed,
      openingMovie,
      endingMovie,
    } = route.params;
    if (!coverImageForm || !coverImageForm.image) {
      return;
    }
    this.setState({loading: true});
    new NetEpisodeVideoExportJobExecutionsRepository()
      .create(
        {
          episodeVideoExportJobExecution: {
            episodeId,
            title,
            orientation,
            commercial: false,
            autoPlaySpeed,
            image: coverImageForm.image,
            openingMovie: openingMovie === 'present',
            endingMovie: endingMovie === 'present',
          },
        },
        {multipart: true},
      )
      .then(response => {
        sendDefaultCustomEvent({
          resourceType: `${orientation}_full_video`,
          resourceId: response.id,
          resourceName: title,
          actionName: 'create',
        });
        this.setState({loading: false}, () => {
          navigation.navigate('FullVideoExportJobExecutions', {});
        });
      })
      .catch(error => {
        this.setState({loading: false});
        this.setState({alertMessage: formatErrorMessages({}, error)});
      });
  };

  private handleRequestReset = () => {
    this.setupCoverImageForm();
  };

  private handleForwardToImageSelection = () => {
    const {navigation, route} = this.props;
    const {storyId, orientation} = route.params;
    (navigation as any).navigate('CoverImageNavigation', {
      screen: 'CoverImageFormBackgroundUsageHistories',
      params: {
        storyId,
        aspectRatio:
          orientation === 'vertical'
            ? verticalAspectRatio
            : horizontalAspectRatio,
        imageKey: 'coverImage',
      },
    });
  };

  private handleForwardToCharacterImageSelection = () => {
    const {navigation, route} = this.props;
    const {storyId, orientation} = route.params;
    (navigation as any).navigate('CoverImageNavigation', {
      screen: 'CoverImageFormCharacterPatterns',
      params: {
        storyId,
        aspectRatio:
          orientation === 'vertical'
            ? verticalAspectRatio
            : horizontalAspectRatio,
        imageKey: 'coverImage',
      },
    });
  };

  private handleForwardToTextInput = () => {
    const {navigation, route} = this.props;
    const {storyId, orientation} = route.params;
    (navigation as any).navigate('CoverImageNavigation', {
      screen: 'CoverImageFormText',
      params: {
        storyId,
        aspectRatio:
          orientation === 'vertical'
            ? verticalAspectRatio
            : horizontalAspectRatio,
        imageKey: 'coverImage',
      },
    });
  };

  private setupCoverImageForm = async () => {
    const {createCoverImageForm} = this.props;
    await createCoverImageForm({
      type: 'videoImage',
      image: null,
      hasImage: null,
      originalImageUrl: null,
      backgroundImageUri: null,
      characterImageUri: null,
      uploadedSelfImage: null,
      imageTextInfoList: null,
    });
  };
}

const horizontalAspectRatio = 1280 / 720;
const verticalAspectRatio = 1080 / 1920;

const widthHorizontalAspectRatio = 343 / 375;
const widthVerticalAspectRatio = 193 / 375;
