import * as React from 'react';

import PrimaryButton from '../../../../../shared/buttons/PrimaryButton';

interface Props {
  onPress: () => void;
}

const NewSoundUsageHistoryButton: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return <PrimaryButton onPress={onPress}>効果音を選ぶ</PrimaryButton>;
};

export default React.memo(NewSoundUsageHistoryButton);
