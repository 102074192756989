import Entity from './Entity';

export default class SoundCategory implements Entity {
  constructor(
    public id: number,
    public parentId: number | null,
    public name: string,
    public leaf: boolean,
    public soundCount: number,
  ) {}
}
