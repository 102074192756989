import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import MarkMapper, {MarkData} from '../entities/mappers/MarkMapper';

import Mark from '../../domain/entities/Mark';
import MarksRepository from '../../domain/repositories/MarksRepository';

export default class NetMarksRepository
  extends NetResourcesRepository<Mark, MarkData>
  implements MarksRepository
{
  constructor() {
    super(new NetResourcesRepositoryAdapter('/api/marks', new MarkMapper()));
  }
}
