import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import CommentListItem from './CommentListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectTopic from '../../../../../domain/entities/ProjectTopic';
import ProjectTopicComment from '../../../../../domain/entities/ProjectTopicComment';
import ProjectTopicCommentLike from '../../../../../domain/entities/ProjectTopicCommentLike';
import ProjectTopicCommentReplyLike from '../../../../../domain/entities/ProjectTopicCommentReplyLike';

interface Props {
  topic: ProjectTopic;
  comments: ProjectTopicComment[];
  commentLikes: ProjectTopicCommentLike[];
  commentReplyLikes: ProjectTopicCommentReplyLike[];
  topicOwner?: boolean;
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const CommentList: React.FC<Props> = props => {
  const {
    topic,
    comments,
    commentLikes,
    commentReplyLikes,
    topicOwner,
    ListHeaderComponent,
  } = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ProjectTopicComment>) => {
      const commentLike =
        commentLikes.find(
          commentLike => commentLike.projectTopicCommentId === info.item.id,
        ) || null;
      const commentReplyLike =
        commentReplyLikes.find(
          commentReplyLike =>
            commentReplyLike.projectTopicCommentId === info.item.id,
        ) || null;
      return (
        <CommentListItem
          topic={topic}
          comment={info.item}
          commentLike={commentLike}
          commentReplyLike={commentReplyLike}
          topicOwner={topicOwner}
        />
      );
    },
    [],
  );
  return (
    <FlatList
      data={comments}
      renderItem={renderItem}
      ListHeaderComponent={ListHeaderComponent}
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(CommentList);
