import * as React from 'react';

import CharacterView from './CharacterView';
import Tabs from './Tabs';
import EntityList from './EntityList';

import Layout from '../Layout';
import HeaderRightButton from '../buttons/HeaderRightButton';

import DimensionContext from '../dimension/DimensionContext';

import NavigationProp from '../../../navigators/NavigationProp';

import scrollableLayout from '../../../helpers/scrollableLayout';

import NetActorFacePartsRepository from '../../../../data/repositories/writer/character_maker/NetActorFacePartsRepository';
import NetActorCostumesRepository from '../../../../data/repositories/writer/character_maker/NetActorCostumesRepository';
import NetActorHairStylesRepository from '../../../../data/repositories/writer/character_maker/NetActorHairStylesRepository';
import NetActorHairColorsRepository from '../../../../data/repositories/writer/character_maker/NetActorHairColorsRepository';
import NetActorEyesRepository from '../../../../data/repositories/writer/character_maker/NetActorEyesRepository';
import NetActorCharactersRepository from '../../../../data/repositories/writer/NetActorCharactersRepository';

import ActorFacePart from '../../../../domain/entities/character_maker/ActorFacePart';
import ActorEye from '../../../../domain/entities/character_maker/ActorEye';
import ActorCostume from '../../../../domain/entities/character_maker/ActorCostume';
import ActorHairStyle from '../../../../domain/entities/character_maker/ActorHairStyle';
import ActorHairColor from '../../../../domain/entities/character_maker/ActorHairColor';
import ActorCharacter from '../../../../domain/entities/ActorCharacter';

const actorFacePartsRepository = new NetActorFacePartsRepository();
const actorCostumesRepository = new NetActorCostumesRepository();
const actorHairStylesRepository = new NetActorHairStylesRepository();
const actorHairColorsRepository = new NetActorHairColorsRepository();
const actorEyesRepository = new NetActorEyesRepository();
const actorCharactersRepository = new NetActorCharactersRepository();

const facePatternId = 1;

type Value = 'hair_style' | 'hair_color' | 'actor' | 'eye_color' | 'costume';

const TAB_DATA: Array<{value: Value; label: string}> = [
  {value: 'hair_style', label: '髪型'},
  {value: 'hair_color', label: '髪色'},
  {value: 'actor', label: '顔パーツ'},
  {value: 'eye_color', label: '目の色'},
  {value: 'costume', label: '衣装'},
];

interface Props {
  navigation: NavigationProp;
  characterMakerActorGroupId: number;
  skinColorPatternId: number;
  onSelectActorCharacter: (
    actorCharacer: ActorCharacter,
    inverted?: boolean,
  ) => void;
}

const ActorCharacterBuilder: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    characterMakerActorGroupId,
    skinColorPatternId,
    onSelectActorCharacter,
  } = props;
  const [inverted, setInverted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<Value>('hair_style');

  const [currentColorPatternId, setCurrentColorPatternId] = React.useState(3);
  const [currentHairStyleName, setCurrentHairStyleName] = React.useState<
    string | null
  >(null);
  const [currentEyeColorPatternId, setCurrentEyeColorPatternId] =
    React.useState(1);
  const [currentCostumeName, setCurrentCostumeName] = React.useState<
    string | null
  >(null);

  const [actorHairStyles, setActorHairStyles] = React.useState<
    ActorHairStyle[] | null
  >(null);
  const [actorHairColors, setActorHairColors] = React.useState<
    ActorHairColor[] | null
  >(null);
  const [actorFaceParts, setActorFaceParts] = React.useState<
    ActorFacePart[] | null
  >(null);
  const [actorEyes, setActorEyes] = React.useState<ActorEye[] | null>(null);
  const [actorCostumes, setActorCostumes] = React.useState<
    ActorCostume[] | null
  >(null);

  const [currentActorFacePart, setCurrentActorFacePart] =
    React.useState<ActorFacePart | null>(null);

  const updateActorHairColors = (
    actorId: number,
    hairStyleName: string,
    callback: () => void,
  ) => {
    actorHairColorsRepository
      .findBy({actorId, name: hairStyleName, sort: 'hair_color'})
      .then(actorHairColorsResult => {
        setActorHairColors(actorHairColorsResult.records);
        callback();
      });
  };

  const updateActorHairStyles = (
    actorId: number,
    colorPatternId: number,
    callback: (hairStyleName: string) => void,
  ) => {
    actorHairStylesRepository
      .findBy({actorId, colorPatternId})
      .then(actorHairStylesResult => {
        setActorHairStyles(actorHairStylesResult.records);
        const selectActorHairStyle = actorHairStylesResult.records[0];
        if (!selectActorHairStyle) {
          return;
        }
        if (!currentHairStyleName) {
          setCurrentHairStyleName(selectActorHairStyle.name);
        }
        callback(currentHairStyleName || selectActorHairStyle.name);
      });
  };

  const updateActorCostumes = (actorId: number) => {
    actorCostumesRepository.findBy({actorId}).then(actorCostumesResult => {
      setActorCostumes(actorCostumesResult.records);
      const selectActorCostume = actorCostumesResult.records[0];
      if (!selectActorCostume) {
        return;
      }
      if (!currentCostumeName) {
        setCurrentCostumeName(selectActorCostume.name);
      }
    });
  };

  const updateActorEyes = (actorId: number, callback: () => void) => {
    actorEyesRepository
      .findBy({actorId, facePatternId})
      .then(actorEyesResult => {
        setActorEyes(actorEyesResult.records);
        callback();
        const selectActorEye = actorEyesResult.records[0];
        if (!selectActorEye) {
          return;
        }
        if (!currentCostumeName) {
          setCurrentEyeColorPatternId(selectActorEye.eyeColorPatternId);
        }
      });
  };

  const updateEntities = (actorFacePart: ActorFacePart) => {
    const actorId = actorFacePart.actorId;
    updateActorEyes(actorId, () => {
      setCurrentActorFacePart(actorFacePart);
    });
    updateActorHairStyles(
      actorId,
      currentColorPatternId,
      (hairStyleName: string) => {
        updateActorHairColors(actorId, hairStyleName, () => {});
      },
    );
    updateActorCostumes(actorId);
  };

  const updateActorFaceParts = (
    colorPatternId: number,
    callback?: (actorFacePart: ActorFacePart) => void,
  ) => {
    actorFacePartsRepository
      .findBy({
        characterMakerActorGroupId,
        skinColorPatternId,
        facePatternId,
        colorPatternId,
        sort: 'name',
      })
      .then(actorFacePartsResult => {
        setActorFaceParts(actorFacePartsResult.records);
        const selectActorFacePart =
          actorFacePartsResult.records.find(
            actorFacePart =>
              actorFacePart.actorId === currentActorFacePart?.actorId,
          ) || actorFacePartsResult.records[0];
        if (!selectActorFacePart) {
          return;
        }
        callback && callback(selectActorFacePart);
      });
  };

  React.useEffect(() => {
    updateActorFaceParts(currentColorPatternId, actorFacePart =>
      updateEntities(actorFacePart),
    );
  }, []);

  const onChangeTab = React.useCallback((value: Value) => {
    setActiveTab(value);
  }, []);
  const onSelectActorCostume = React.useCallback(
    (actorCostume: ActorCostume) => {
      setCurrentCostumeName(actorCostume.name);
    },
    [],
  );
  const onSelectActorHairStyle = React.useCallback(
    (actorHairStyle: ActorHairStyle) => {
      setCurrentHairStyleName(actorHairStyle.name);
      if (currentActorFacePart) {
        updateActorHairColors(
          currentActorFacePart.actorId,
          actorHairStyle.name,
          () => {},
        );
      }
    },
    [currentActorFacePart],
  );
  const onSelectActorHairColor = React.useCallback(
    (actorHairColor: ActorHairColor) => {
      setCurrentColorPatternId(actorHairColor.colorPatternId);
      if (currentActorFacePart) {
        updateActorHairStyles(
          currentActorFacePart.actorId,
          actorHairColor.colorPatternId,
          () => {
            updateActorFaceParts(
              actorHairColor.colorPatternId,
              actorFacePart => {
                setCurrentActorFacePart(actorFacePart);
              },
            );
          },
        );
      }
    },
    [currentActorFacePart, currentHairStyleName, currentColorPatternId],
  );
  const onSelectActorFacePart = React.useCallback(
    (actorFacePart: ActorFacePart) => {
      updateEntities(actorFacePart);
    },
    [currentHairStyleName, currentColorPatternId, currentCostumeName],
  );
  const onSelectActorEye = React.useCallback(
    (actorEye: ActorEye) => {
      setCurrentEyeColorPatternId(actorEye.eyeColorPatternId);
    },
    [actorEyes, currentEyeColorPatternId],
  );
  const currentActorHairStyle = React.useMemo(
    () =>
      actorHairStyles?.find(
        actorHairStyle => actorHairStyle.name === currentHairStyleName,
      ),
    [actorHairStyles, currentHairStyleName, currentColorPatternId],
  );
  const currentActorHairColor = React.useMemo(
    () =>
      actorHairColors?.find(
        actorHairColor =>
          actorHairColor.colorPatternId === currentColorPatternId,
      ),
    [actorHairColors, currentColorPatternId],
  );
  const currentActorEye = React.useMemo(
    () =>
      actorEyes?.find(
        actorEye => actorEye.eyeColorPatternId === currentEyeColorPatternId,
      ),
    [actorEyes, currentEyeColorPatternId],
  );
  const currentActorCostume = React.useMemo(
    () =>
      actorCostumes?.find(
        actorCostume => actorCostume.name === currentCostumeName,
      ),
    [actorCostumes, currentCostumeName],
  );

  const handleSubmit = React.useCallback(() => {
    if (
      currentActorFacePart &&
      currentActorHairStyle &&
      currentActorCostume &&
      currentActorEye
    ) {
      setLoading(true);
      actorCharactersRepository
        .create({
          actorCharacter: {
            actorId: currentActorFacePart.actorId,
            actorCostumeId: currentActorCostume.id,
            actorHairStyleId: currentActorHairStyle.id,
            eyeColorPatternId: currentActorEye.eyeColorPatternId,
          },
        })
        .then(actorCharacter => {
          setLoading(false);
          onSelectActorCharacter(actorCharacter, inverted);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [
    loading,
    currentActorFacePart,
    currentActorHairStyle,
    currentActorCostume,
    currentActorEye,
    inverted,
  ]);
  const onPressShuffle = () => {
    if (actorFaceParts && actorHairColors) {
      const actorFacePart = getRandamItem(actorFaceParts);
      const actorHairColor = getRandamItem(actorHairColors);
      const {actorId} = actorFacePart;
      const {colorPatternId} = actorHairColor;

      actorEyesRepository
        .findBy({actorId, facePatternId})
        .then(actorEyesResult => {
          const selectActorEye = getRandamItem(actorEyesResult.records);
          if (!selectActorEye) {
            return;
          }
          actorCostumesRepository
            .findBy({actorId})
            .then(actorCostumesResult => {
              const selectActorCostume = getRandamItem(
                actorCostumesResult.records,
              );
              if (!selectActorCostume) {
                return;
              }
              actorHairStylesRepository
                .findBy({actorId, colorPatternId})
                .then(actorHairStylesResult => {
                  const selectActorHairStyle = getRandamItem(
                    actorHairStylesResult.records,
                  );
                  if (!selectActorHairStyle) {
                    return;
                  }
                  actorHairColorsRepository
                    .findBy({
                      actorId,
                      name: selectActorHairStyle.name,
                      sort: 'hair_color',
                    })
                    .then(actorHairColorsResult => {
                      setCurrentActorFacePart(actorFacePart);
                      setCurrentCostumeName(selectActorCostume.name);
                      setCurrentHairStyleName(selectActorHairStyle.name);
                      setCurrentColorPatternId(actorHairColor.colorPatternId);
                      setCurrentEyeColorPatternId(
                        selectActorEye.eyeColorPatternId,
                      );
                      setActorCostumes(actorCostumesResult.records);
                      setActorHairStyles(actorHairStylesResult.records);
                      setActorEyes(actorEyesResult.records);
                      setActorHairColors(actorHairColorsResult.records);
                    });
                });
            });
        });
    }
  };
  const onPressInversion = React.useCallback(() => {
    setInverted(!inverted);
  }, [inverted]);
  return (
    <DimensionContext.Consumer>
      {context => {
        const scrollable = scrollableLayout(context);
        return (
          <Layout
            title={
              activeTab === 'hair_style'
                ? '髪型'
                : activeTab === 'hair_color'
                ? '髪色'
                : activeTab === 'actor'
                ? '顔パーツ'
                : activeTab === 'eye_color'
                ? '目の色'
                : activeTab === 'costume'
                ? '衣装'
                : ''
            }
            scrollable={scrollable}
            navigation={navigation}
            loading={loading}
            back={true}
            rightButton={{
              tintColor: 'white',
              title: (
                <HeaderRightButton onPress={handleSubmit}>
                  次へ
                </HeaderRightButton>
              ),
            }}>
            <>
              <CharacterView
                actorFacePart={currentActorFacePart}
                actorHairStyle={currentActorHairStyle}
                actorCostume={currentActorCostume}
                actorEye={currentActorEye}
                width={context.content.width}
                inverted={inverted}
                onPressShuffle={onPressShuffle}
                onPressInversion={onPressInversion}
              />

              {actorCostumes && actorHairStyles && (
                <>
                  <Tabs
                    data={TAB_DATA}
                    activeTab={activeTab}
                    disableValues={
                      currentActorHairStyle?.noHairColor ? ['hair_color'] : []
                    }
                    onChangeTab={onChangeTab}
                  />
                  <EntityList
                    entities={actorHairStyles}
                    visible={activeTab === 'hair_style'}
                    selectedEntityId={
                      currentActorHairStyle ? currentActorHairStyle.id : null
                    }
                    clipped={true}
                    width={context.content.width}
                    inverted={inverted}
                    scrollEnabled={!scrollable}
                    onSelectEntity={onSelectActorHairStyle}
                  />
                  <EntityList
                    entities={actorHairColors}
                    visible={activeTab === 'hair_color'}
                    selectedEntityId={
                      currentActorHairColor ? currentActorHairColor.id : null
                    }
                    clipped={true}
                    width={context.content.width}
                    inverted={inverted}
                    scrollEnabled={!scrollable}
                    onSelectEntity={onSelectActorHairColor}
                  />
                  <EntityList
                    entities={actorFaceParts}
                    visible={activeTab === 'actor'}
                    selectedEntityId={
                      currentActorFacePart ? currentActorFacePart.id : null
                    }
                    clipped={true}
                    zoom={3}
                    top={-30}
                    width={context.content.width}
                    inverted={inverted}
                    scrollEnabled={!scrollable}
                    onSelectEntity={onSelectActorFacePart}
                  />
                  <EntityList
                    entities={actorEyes}
                    visible={activeTab === 'eye_color'}
                    selectedEntityId={
                      currentActorEye ? currentActorEye.id : null
                    }
                    clipped={true}
                    zoom={3}
                    top={-30}
                    width={context.content.width}
                    inverted={inverted}
                    scrollEnabled={!scrollable}
                    onSelectEntity={onSelectActorEye}
                  />
                  <EntityList
                    entities={actorCostumes}
                    visible={activeTab === 'costume'}
                    selectedEntityId={
                      currentActorCostume ? currentActorCostume.id : null
                    }
                    clipped={false}
                    width={context.content.width}
                    inverted={inverted}
                    top={-40}
                    centeringByTop={true}
                    scrollEnabled={!scrollable}
                    onSelectEntity={onSelectActorCostume}
                  />
                </>
              )}
            </>
          </Layout>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(ActorCharacterBuilder);

const getRandamItem = <T,>(array: Array<T>) => {
  return array[Math.floor(Math.random() * array.length)];
};
