import Entity from '../Entity';

import ManualCategory from './ManualCategory';

export default class Help implements Entity {
  constructor(
    public id: number,
    public title: string,
    public content: string,
    public writerManualCategory: ManualCategory,
  ) {}
}
