import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/cover_image_form/base_backgrounds/Index';

import AppState from '../../../reducers/AppState';

import showBackgroundCategory from '../../../actions/background_categories/show';
import indexBaseBackgrounds, {
  Params as BaseBackgroundIndexParams,
} from '../../../actions/base_backgrounds/index';

import selectEntities, {getTotalCount} from '../../../helpers/selectEntities';
import selectEntity from '../../../helpers/selectEntity';
import {CoverImageFormBaseBackgroundsRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CoverImageFormBaseBackgroundsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {query, backgroundCategoryId, sort, favorite, storyId} = route.params;
  const {coverImageForm} = forms;
  const backgroundCategory = backgroundCategoryId
    ? selectEntity(entities.backgroundCategories, backgroundCategoryId)
    : null;
  const baseBackgroundsParams: BaseBackgroundIndexParams = {
    query,
    allBackgroundCategoryId: backgroundCategoryId,
    sort: sort || 'popularity',
    favorite,
    storyId,
  };
  const baseBackgrounds = selectEntities(
    entities.baseBackgrounds,
    queries.baseBackgrounds,
    baseBackgroundsParams,
  );
  const totalCount = getTotalCount(
    queries.baseBackgrounds,
    baseBackgroundsParams,
  );
  const baseBackgroundQueries = queries.baseBackgrounds;
  return {
    coverImageForm,
    backgroundCategory,
    baseBackgrounds,
    baseBackgroundsParams,
    baseBackgroundQueries,
    navigation,
    totalCount,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexBaseBackgrounds: (params: BaseBackgroundIndexParams) =>
      dispatch(indexBaseBackgrounds(params)),
    showBackgroundCategory: (id: number) =>
      dispatch(showBackgroundCategory(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
