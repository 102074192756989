import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import ActorFacePartMapper, {
  ActorFacePartData,
} from '../../../entities/mappers/character_maker/ActorFacePartMapper';

import PaginatedResult from '../../../../domain/results/PaginatedResult';
import ActorFacePart from '../../../../domain/entities/character_maker/ActorFacePart';
import ActorFacePartsRepository from '../../../../domain/repositories/writer/character_maker/ActorFacePartsRepository';

export default class NetActorFacePartsRepository
  extends NetResourcesRepository<ActorFacePart, ActorFacePartData>
  implements ActorFacePartsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_maker/actor_faces',
        new ActorFacePartMapper(),
      ),
    );
  }

  public async findBy(
    params:
      | {
          characterMakerActorGroupId: number;
          actorId?: number;
          facePatternId?: number;
          colorPatternId?: number;
          skinColorPatternId?: number;
          sort?: 'name' | 'face_pattern_id';
        }
      | {
          characterMakerActorGroupId?: number;
          actorId: number;
          facePatternId?: number;
          colorPatternId?: number;
          skinColorPatternId?: number;
          sort?: 'name' | 'face_pattern_id';
        },
  ): Promise<PaginatedResult<ActorFacePart>> {
    return super.findBy(params);
  }
}
