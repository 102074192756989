import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import CharacterFormEditCharacterPatterns from '../../../../containers/character_form/edit/character_patterns/Index';
import CharacterFormEditNameLabelColors from '../../../../containers/character_form/edit/name_label_colors/Index';
import CharacterFormEditCharacterPublication from '../../../../containers/character_form/edit/character_publication/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="CharacterFormEditCharacterPatterns">
      <RootStack.Screen
        name="CharacterFormEditCharacterPatterns"
        component={CharacterFormEditCharacterPatterns}
        options={{title: '使用したキャラクター差分'}}
      />

      <RootStack.Screen
        name="CharacterFormEditNameLabelColors"
        component={CharacterFormEditNameLabelColors}
        options={{title: 'キャラクター情報編集'}}
      />

      <RootStack.Screen
        name="CharacterFormEditCharacterPublication"
        component={CharacterFormEditCharacterPublication}
        options={{title: '公開設定'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
