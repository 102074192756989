import * as React from 'react';
import {
  Image,
  ImageStyle,
  Linking,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Config from 'react-native-config';

import Comment from './Comment';
import SpeachBalloon from './SpeachBalloon';
import TipIcon from './TipIcon';
import ReactionIcon from './ReactionIcon';

import MoreIcon from '../../../shared/icons/ionicons/MoreIcon';
import StaffIcon from '../../../shared/icons/StaffIcon';
import WriterIcon from '../../../shared/icons/WriterIcon';
import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import convertImageUri from '../../../../helpers/convertImageSource';
import {formatElapsedTime} from '../../../../helpers/times';

import EpisodeComment from '../../../../../domain/entities/EpisodeComment';
import EpisodeCommentReply from '../../../../../domain/entities/EpisodeCommentReply';
import WriterCurrentUser from '../../../../../domain/entities/writer/CurrentUser';

import noimageUserUri from '../../../../../assets/images/response/noimage_user.png';

interface Props {
  currentUser: WriterCurrentUser;
  episodeComment: EpisodeComment;
  blocked?: boolean;
  onRequestActionSheetForComment: (episodeComment: EpisodeComment) => void;
  onRequestActionSheetForCommentReply: (
    episodeCommentReply: EpisodeCommentReply,
  ) => void;
  onOpenNewEpisodeCommentReply: (episodeComment: EpisodeComment) => void;
  onOpenEditEpisodeCommentReply: (
    episodeCommentReply: EpisodeCommentReply,
  ) => void;
}

const generateUrl = (path: string) => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  } else {
    return Config.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  }
};

const CommentListItem: React.FunctionComponent<Props> = props => {
  const {
    currentUser,
    episodeComment,
    blocked,
    onRequestActionSheetForComment,
    onRequestActionSheetForCommentReply,
    onOpenNewEpisodeCommentReply,
    onOpenEditEpisodeCommentReply,
  } = props;
  const handleRequestActionSheetForComment = React.useCallback(() => {
    onRequestActionSheetForComment(episodeComment);
  }, [episodeComment, onRequestActionSheetForComment]);
  const handleRequestActionSheetForCommentReply = React.useCallback(() => {
    if (episodeComment.episodeCommentReply) {
      onRequestActionSheetForCommentReply(episodeComment.episodeCommentReply);
    }
  }, [episodeComment]);
  const handlePressWriter = React.useCallback(() => {
    if (episodeComment.user.linkedWriterUser) {
      Linking.openURL(
        generateUrl(`/writers/${episodeComment.user.linkedWriterUser.id}`),
      );
    }
  }, []);
  const handleOpenEpisodeCommentReply = React.useCallback(() => {
    if (episodeComment.episodeCommentReply) {
      onOpenEditEpisodeCommentReply(episodeComment.episodeCommentReply);
    } else {
      onOpenNewEpisodeCommentReply(episodeComment);
    }
  }, [episodeComment, episodeComment.episodeCommentReply]);
  return (
    <View style={styles.container}>
      <Comment
        episodeComment={episodeComment}
        blocked={blocked}
        onRequestActionSheetForComment={onRequestActionSheetForComment}>
        <View style={{marginTop: 8}}>
          <View
            style={{
              flex: 1,
              flexDirection: 'row',
            }}>
            <View style={{flex: 1}}>
              {episodeComment.displayOnStoryPage ? (
                <View
                  style={{
                    backgroundColor: '#ff8f13',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 78,
                  }}>
                  <Text
                    style={{color: '#fff', fontSize: 8, fontWeight: 'bold'}}>
                    作品ページに表示中
                  </Text>
                </View>
              ) : null}
            </View>
            <TouchableOpacity onPress={handleOpenEpisodeCommentReply}>
              <Text
                style={{
                  flex: 1,
                  color: '#999',
                  fontSize: 11,
                  fontWeight: 'bold',
                }}>
                返信する
              </Text>
            </TouchableOpacity>
          </View>
        </View>
        {episodeComment.episodeCommentReply ? (
          <View style={{flexDirection: 'row', width: '100%', paddingTop: 24}}>
            {currentUser.userProfile && currentUser.userProfile.hasImage ? (
              <Image
                style={[styles.writerImage, {borderRadius: 20, marginTop: 8}]}
                source={{
                  uri: currentUser.userProfile.getImageUrl({
                    width: 40 * 2,
                    height: 40 * 2,
                  }),
                }}
              />
            ) : (
              <Image
                style={styles.writerImage}
                source={convertImageUri(noimageUserUri)}
                resizeMode={'stretch'}
              />
            )}
            <View style={styles.body}>
              <View style={styles.header}>
                <Text style={styles.userName}>{currentUser.penName}</Text>
                <Pressable onPress={handleRequestActionSheetForCommentReply}>
                  <MoreIcon />
                </Pressable>
              </View>
              <Text style={styles.postDate}>
                {formatElapsedTime(
                  episodeComment.episodeCommentReply.createdAt,
                )}
              </Text>
              <SpeachBalloon style={styles.speachBalloon}>
                <Text style={styles.comment}>
                  {episodeComment.episodeCommentReply.comment}
                </Text>
              </SpeachBalloon>
            </View>
          </View>
        ) : null}
      </Comment>
    </View>
  );
};

export default React.memo(CommentListItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    width: '100%',
    paddingVertical: 8,
    paddingHorizontal: 16,
  } as ViewStyle,
  image: {
    width: 47,
    height: 47,
  } as ImageStyle,
  body: {
    flex: 1,
    marginLeft: 10,
    marginTop: 4,
  } as ViewStyle,
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  userName: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginRight: 2,
  } as TextStyle,
  icon: {
    marginHorizontal: 2,
  } as ViewStyle,
  postDate: {
    color: '#999',
    fontSize: 10,
    marginTop: 2,
    marginBottom: 4,
  } as TextStyle,
  speachBalloon: {
    width: '100%',
  } as ViewStyle,
  comment: {
    padding: 10,
    width: '100%',
    color: '#222',
    fontSize: 12,
  } as TextStyle,
  writerImage: {
    width: 40,
    height: 40,
  },
  block: {
    backgroundColor: '#383838',
    color: 'white',
    fontSize: 8,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 4,
    lineHeight: 15,
    marginHorizontal: 4,
  } as TextStyle,
});
