import Entity from './Entity';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class ActorCharacterFace implements Entity {
  public name: string;
  constructor(
    public id: number,
    public actorCharacterId: number,
    public faceName: string,
    public originalImageUrl: string,
    public faceCenterPosition?: {
      top: number;
      left: number;
    },
  ) {
    this.name = faceName;
  }

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getPositionForCenteringFace = (
    offset: {top?: number; left?: number} = {},
  ) => {
    const offsetTop = offset.top || 0;
    const offsetLeft = offset.left || 0;
    if (!this.faceCenterPosition) {
      return {};
    }
    return {
      top: `${-(this.faceCenterPosition.top - 0.15 + offsetTop) * 100}%`,
      left: `${-(this.faceCenterPosition.left + offsetLeft) * 100}%`,
    };
  };

  public getLabel() {
    return this.name;
  }
}
