import * as React from 'react';

import BaseShow, {
  CommonProps,
  commonPropsAreEqual,
} from '../../base_viewers/Show';

import NetEpisodeScriptsRepository from '../../../../data/repositories/writer/NetEpisodeScriptsRepository';

interface Props extends CommonProps {
  episodeId: number;
}

const Show: React.FunctionComponent<Props> = props => {
  const {episodeId} = props;
  const sceneScriptGetTask = React.useMemo(
    () => new NetEpisodeScriptsRepository().find(episodeId),
    [episodeId],
  );
  return (
    <BaseShow
      key={episodeId}
      sceneScriptGetTask={sceneScriptGetTask}
      {...props}
    />
  );
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.episodeId === nextProps.episodeId &&
    commonPropsAreEqual(prevProps, nextProps)
  );
};

export default React.memo(Show, areEqual);
