import {combineReducers} from 'redux';

import characterForm from './characterForm';
import sceneCommandForms from './sceneCommandForms';
import sceneForm from './sceneForm';
import storyForm from './storyForm';
import coverImageForm from './coverImageForm';

export default combineReducers({
  characterForm,
  sceneCommandForms,
  sceneForm,
  storyForm,
  coverImageForm,
});
