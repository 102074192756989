import * as React from 'react';
import {FlatList, ListRenderItemInfo, Platform} from 'react-native';
import {shallowEqual} from 'recompose';

import HelpListItem from './HelpListItem';

import shouldUpdateEntityList from '../../shared/enhanced/shouldUpdateEntityList';
import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import Help from '../../../../domain/entities/writer/Help';

interface Item {
  help: Help;
  collapsed: boolean;
}

interface Props {
  helps: Help[];
  helpIdToCollapsed: {[key: string]: boolean};
  onPressHelp: (help: Help) => void;
}

export default class HelpList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (
      !shallowEqual(this.props.helpIdToCollapsed, nextProps.helpIdToCollapsed)
    ) {
      return true;
    }
    return shouldUpdateEntityList(
      {entities: this.props.helps},
      {entities: nextProps.helps},
    );
  }

  public render(): React.ReactNode {
    return (
      <FlatList
        scrollEnabled={Platform.select({web: true, default: false})}
        data={this.generateDate()}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
        ListFooterComponent={ListItemNormalSeparator}
      />
    );
  }

  private keyExtractor = (item: Item, index: number): string => {
    return `${item.help.id}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<Item>,
  ): React.ReactElement<any> | null => {
    const {helpIdToCollapsed, onPressHelp} = this.props;
    const {item} = info;
    return (
      <HelpListItem
        help={item.help}
        collapsed={
          helpIdToCollapsed[item.help.id] === undefined
            ? true
            : helpIdToCollapsed[item.help.id]
        }
        onPressHelp={onPressHelp}
      />
    );
  };

  private generateDate = (): Item[] => {
    const {helps, helpIdToCollapsed} = this.props;
    return helps.map(help => {
      return {
        collapsed:
          helpIdToCollapsed[help.id] === undefined
            ? true
            : helpIdToCollapsed[help.id],
        help,
      };
    });
  };
}
