import * as React from 'react';

import TapNovelWebView from '../../shared/TapNovelWebView';
import MetaTags from '../../shared/MetaTags';

import NavigationProp from '../../../navigators/NavigationProp';

import {sendDefaultCustomEvent} from '../../../helpers/analytics';

import {getUserAgent} from '../../../../data/data_stores/net/UserAgent';
import OtherHostReferrer from '../../../../data/data_stores/storage/OtherHostReferrer';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const [userAgent, setUserAgent] = React.useState<string | null>(null);
  React.useEffect(() => {
    getUserAgent().then(ua => {
      setUserAgent(ua);
    });
    const listener = (event: MessageEvent) => {
      if (typeof event.data === 'string') {
        if (
          event.data.startsWith('writer_reservation_application_completion/')
        ) {
          const resourceId = Number(event.data.split('/')[1]);
          OtherHostReferrer.get().then(referrer => {
            sendDefaultCustomEvent({
              resourceType: 'writer/reservation_application',
              resourceId,
              actionName: 'create',
              source: referrer || undefined,
            });
          });
        }
      }
    };
    window.addEventListener('message', listener);
    return () => {
      window.removeEventListener('message', listener);
    };
  }, []);
  return userAgent ? (
    <>
      <TapNovelWebView
        style={{flex: 1}}
        originWhitelist={['*']}
        path={'/writer/reservation/applications/completion?mode=maker'}
        userAgent={userAgent}
      />
      <MetaTags
        description={
          '現在、誰でも簡単にゲーム小説を投稿できる『TapNovelMaker』を開発中です。サーバー負荷テスト実施のため、ご予約いただいたお客様より段階的にご案内をしております。'
        }
        keywords={
          'TapNovelMaker,クローズドベータ版,予約受付中,TapNovel,タップノベル,ゲーム小説,イラスト小説,Web小説,無料'
        }
      />
    </>
  ) : null;
};

export default React.memo(Index);
