import * as React from 'react';

import BaseIndex from '../../common/base_effects/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormEffectShowSceneCommandNewBaseEffectsRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as EffectUsageHistoryUpdateParams} from '../../../../../actions/effect_usage_histories/update';
import {Params as BaseEffectIndexParams} from '../../../../../actions/base_effects/index';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import {QueryState} from '../../../../../reducers/queries/Response';

import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import EffectCategory from '../../../../../../domain/entities/EffectCategory';
import EffectUsageHistory from '../../../../../../domain/entities/EffectUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  effectCategoryId?: number;
  selectedLargeEffectCategoryId?: number | null;
  selectedMiddleEffectCategoryId?: number | null;
  selectedSmallEffectCategoryId?: number | null;
  favorite?: boolean;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  sort?: 'popularity' | 'new_arrival';
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandNewBaseEffectsRouteProp;
  sceneForm: SceneForm | null;
  baseEffectsParams: BaseEffectIndexParams;
  baseEffects: BaseEffect[] | null;
  effectCategory: EffectCategory | null;
  parentSceneCommandForm: SceneCommandForm | null;
  baseEffectQueries: QueryState;
  totalCount: number | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexBaseEffects: (
    params: BaseEffectIndexParams,
  ) => Promise<PaginatedResult<BaseEffect>>;
  showEffectCategory: (id: number) => Promise<EffectCategory>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateEffectUsageHistory: (
    id: number,
    params: EffectUsageHistoryUpdateParams,
  ) => Promise<EffectUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedBaseEffect: BaseEffect | null;
}

export default class Index extends React.PureComponent<Props, State> {
  public render(): React.ReactNode {
    const {route} = this.props;
    const {sort} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        sort={sort}
        onSelectBaseEffect={this.handleSelectBaseEffect}
        onPressSearchConditionChangeButton={
          this.handlePressSearchConditionChangeButton
        }
      />
    );
  }

  private handlePressSearchConditionChangeButton = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      query,
      selectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      favorite,
    } = route.params;
    navigation.navigate(
      'SceneFormEffectShowSceneCommandNewBaseEffectSearchFormNavigations',
      {
        screen: 'SceneFormEffectShowSceneCommandNewBaseEffectSearchForm',
        params: {
          storyId,
          episodeId,
          sceneId,
          query,
          selectedLargeEffectCategoryId,
          selectedMiddleEffectCategoryId,
          selectedSmallEffectCategoryId,
          sceneCommandIndex,
          subSceneCommandIndex,
          parentSceneCommandId,
          favorite,
          hideBack: true,
        },
      } as any,
    );
  };

  private handleSelectBaseEffect = (baseEffect: BaseEffect): void => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    routers.linkToSceneFormEffectShowSceneCommandNewEffects(navigation, {
      baseEffectId: baseEffect.id,
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    });
  };
}
