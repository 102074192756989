import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import Table from './partials/Table';
import FormModal from './partials/FormModal';
import FormCompleteModal from './partials/FormCompleteModal';
import CancelModal from './partials/CancelModal';
import CancelCompleteModal from './partials/CancelCompleteModal';
import ReactivateModal from './partials/ReactivateModal';

import Layout from '../../shared/Layout';
import SvgUriAdapter from '../../shared/svg/SvgUriAdapter';

import PrimaryButton from '../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../shared/buttons/DefaultOutlineButton';

import NavigationProp from '../../../navigators/NavigationProp';

import {sendDefaultCustomEvent} from '../../../helpers/analytics';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import PaymentMethod from '../../../../domain/entities/writer/my_stripe/PaymentMethod';
import Price from '../../../../domain/entities/writer/my_stripe/maker_pro/Price';
import PaymentIntent from '../../../../domain/entities/writer/my_stripe/PaymentIntent';

import NetPriceRepository from '../../../../data/repositories/writer/my_stripe/maker_pro/NetPriceRepository';

import makerProUri from '../../../../assets/svgs/maker-pro.svg';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, showCurrentUser} = props;
  const [loading, setLoading] = React.useState(false);
  const [visibleFormModal, setVisibleFormModal] = React.useState(false);
  const [visibleFormCompleteModal, setVisibleFormCompleteModal] =
    React.useState(false);
  const [visibleCancelModal, setVisibleCancelModal] = React.useState(false);
  const [visibleCancelCompleteModal, setVisibleCancelCompleteModal] =
    React.useState(false);
  const [visibleReactivateModal, setVisibleReactivateModal] =
    React.useState(false);
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | null>(
    null,
  );
  const [price, setPrice] = React.useState<Price | null>(null);
  const [paymentMethod, setPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const [paymentIntent, setPaymentIntent] =
    React.useState<PaymentIntent | null>(null);
  const fetchCurrentUser = React.useCallback(() => {
    showCurrentUser().then(setCurrentUser);
  }, []);
  React.useEffect(() => {
    fetchCurrentUser();
    new NetPriceRepository().find().then(setPrice);
  }, []);
  const onPressEntry = React.useCallback(() => {
    setVisibleFormModal(true);
  }, []);
  const onPressCancel = React.useCallback(() => {
    setVisibleCancelModal(true);
  }, []);
  const onPressReactivate = React.useCallback(() => {
    setVisibleReactivateModal(true);
  }, []);
  const onPressLoading = React.useCallback(() => {}, []);
  const onPressTerms = React.useCallback(() => {
    navigation.navigate('PoliciesTermsModal', {});
  }, []);
  const onPressPrivacy = React.useCallback(() => {
    navigation.navigate('PoliciesPrivacyModal', {});
  }, []);
  const onCloseFormModal = React.useCallback(() => {
    setVisibleFormModal(false);
  }, []);
  const onCloseCancelModal = React.useCallback(() => {
    setVisibleCancelModal(false);
  }, []);
  const onCloseReactivateModal = React.useCallback(() => {
    setVisibleReactivateModal(false);
  }, []);
  const onOpenFormCompleteModal = React.useCallback(() => {
    setVisibleFormCompleteModal(true);
  }, []);
  const onOpenCancelCompleteModal = React.useCallback(() => {
    setVisibleCancelCompleteModal(true);
  }, []);
  const onCloseFormCompleteModal = React.useCallback(() => {
    setVisibleFormCompleteModal(false);
  }, []);
  const onCloseCancelCompleteModal = React.useCallback(() => {
    setVisibleCancelCompleteModal(false);
  }, []);
  const success = React.useCallback(() => {
    onCloseFormModal();
    const intervalId = setInterval(() => {
      showCurrentUser().then(currentUser => {
        if (currentUser.enabledPaidSubscriber) {
          clearInterval(intervalId);
          setLoading(false);
          setCurrentUser(currentUser);
          onOpenFormCompleteModal();
          sendDefaultCustomEvent({
            resourceType: 'writer/paid_subscriber_user',
            resourceId: currentUser.id,
            actionName: 'create',
          });
        }
      });
    }, 1000);
  }, []);
  const fail = React.useCallback(() => {}, []);
  return (
    <Layout
      title={'MakerPro'}
      navigation={navigation}
      back={true}
      loading={loading}>
      <View style={styles.container}>
        <Text style={styles.message}>
          MakerProは、ゲーム小説の制作に{'\n'}
          もっとこだわりたい方向けの有料プランです。
        </Text>
        <SvgUriAdapter uri={makerProUri} width={327} height={256} />
        <Text style={styles.features}>
          MakerPro会員は、以下の機能をご利用できます。
        </Text>
        <Table />
        {currentUser ? (
          !currentUser.enabledPaidSubscriber ? (
            <PrimaryButton disabled={!price} onPress={onPressEntry}>
              MakerPro会員になる（￥{price ? price.amountIncludingTax : '-'}
              /月）
            </PrimaryButton>
          ) : currentUser.paidSubscriberCancelAt ? (
            <PrimaryButton disabled={!price} onPress={onPressReactivate}>
              MakerProを再開する（￥{price ? price.amountIncludingTax : '-'}
              /月）
            </PrimaryButton>
          ) : (
            <DefaultOutlineButton onPress={onPressCancel}>
              退会する
            </DefaultOutlineButton>
          )
        ) : (
          <PrimaryButton disabled={true} onPress={onPressLoading}>
            読込中
          </PrimaryButton>
        )}
        <View style={styles.about}>
          <Text style={styles.aboutText}>◆MakerPro会員（有料）について</Text>
          <Text style={styles.aboutText}>
            MakerPro会員になれば、ゲーム小説の制作における様々な{'\n'}
            機能を利用することができるようになります。
          </Text>
          <Text style={styles.aboutText}>
            また、本来条件をクリアしないと利用できない機能も、{'\n'}
            無条件で利用することができます。
          </Text>
          <Text style={styles.aboutText}>
            MakerPro会員のお支払いはStripe経由での請求となります。 {'\n'}
            期間終了日のまでに解除しない場合、自動的に更新されます。{'\n'}
            解約はこの画面から行うことができます。{'\n'}
            当月分のキャンセルについては受け付けておりませんのでご了承ください。
          </Text>
        </View>
        <View style={styles.links}>
          <TouchableOpacity style={styles.link} onPress={onPressTerms}>
            <Text style={styles.linkLabel}>利用規約</Text>
          </TouchableOpacity>
          <TouchableOpacity style={styles.link} onPress={onPressPrivacy}>
            <Text style={styles.linkLabel}>プライバシーポリシー</Text>
          </TouchableOpacity>
        </View>
      </View>
      {visibleFormModal && (
        <FormModal
          paymentMethod={paymentMethod}
          paymentIntent={paymentIntent}
          setPaymentMethod={setPaymentMethod}
          setPaymentIntent={setPaymentIntent}
          setCurrentUser={setCurrentUser}
          onCloseModal={onCloseFormModal}
          onComplete={onOpenFormCompleteModal}
          setLoading={setLoading}
          success={success}
          fail={fail}
        />
      )}
      {currentUser && visibleCancelModal && (
        <CancelModal
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          onCloseModal={onCloseCancelModal}
          onOpenCancelCompleteModal={onOpenCancelCompleteModal}
          setLoading={setLoading}
        />
      )}
      {currentUser && visibleReactivateModal && (
        <ReactivateModal
          currentUser={currentUser}
          setCurrentUser={setCurrentUser}
          onCloseModal={onCloseReactivateModal}
          setLoading={setLoading}
        />
      )}
      {visibleFormCompleteModal && (
        <FormCompleteModal onCloseModal={onCloseFormCompleteModal} />
      )}
      {visibleCancelCompleteModal && (
        <CancelCompleteModal onCloseModal={onCloseCancelCompleteModal} />
      )}
    </Layout>
  );
};

export default React.memo(Index);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 32,
  } as ViewStyle,
  message: {
    fontSize: 14,
    fontWeight: 'bold',
    lineHeight: 20,
    textAlign: 'center',
  } as TextStyle,
  features: {
    fontSize: 14,
  } as TextStyle,
  about: {
    marginHorizontal: 24,
    marginTop: 32,
    marginBottom: 16,
  },
  aboutText: {
    color: '#383838',
    fontSize: 12,
    marginBottom: 8,
  },
  links: {
    marginHorizontal: 24,
    flexDirection: 'row',
    alignSelf: 'flex-start',
  } as ViewStyle,
  link: {
    borderBottomColor: '#383838',
    borderBottomWidth: 1,
    marginRight: 8,
  } as ViewStyle,
  linkLabel: {
    color: '#383838',
    fontSize: 12,
  } as TextStyle,
});
