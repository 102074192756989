import JsonAsyncStorage from '../data_stores/storage/JsonAsyncStorage';

import ActorSearchSetting from '../../domain/value_objects/ActorSearchSetting';
import ActorSearchSettingRepository from '../../domain/repositories/ActorSearchSettingRepository';

export default class StorageActorSearchSettingRepository
  implements ActorSearchSettingRepository
{
  private static KEY = 'actor_search_setting';

  public find(): Promise<ActorSearchSetting | null> {
    return JsonAsyncStorage.get(StorageActorSearchSettingRepository.KEY);
  }

  public update(value: ActorSearchSetting): Promise<void> {
    return JsonAsyncStorage.set(StorageActorSearchSettingRepository.KEY, value);
  }

  public destroy(): Promise<void> {
    return JsonAsyncStorage.remove(StorageActorSearchSettingRepository.KEY);
  }
}
