import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import NavigationBarButton, {
  Props as NavigationBarButtonProps,
} from './NavigationBarButton';

type ButtonProps = NavigationBarButtonProps | null;

interface Props {
  title?: string | null;
  element?: React.ReactElement<any> | React.ReactText | null;
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
  innerRightButton?: ButtonProps;
  hideBottomBorder?: boolean;
}

export default class NavigationBar extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      title,
      element,
      leftButton,
      rightButton,
      innerRightButton,
      hideBottomBorder,
    } = this.props;
    const titleElement = element ? (
      element
    ) : (
      <Text
        style={[styles.navBarTitleText]}
        ellipsizeMode={'tail'}
        numberOfLines={1}>
        {title}
      </Text>
    );
    return (
      <View
        style={[
          styles.navBarContainer,
          {borderBottomWidth: hideBottomBorder ? 0 : 1},
        ]}>
        <View style={[styles.navBar]}>
          <View style={styles.navBarTitleContainer}>{titleElement}</View>
          <View style={styles.navBarButtonContainer}>
            {leftButton ? this.generateButton(leftButton) : null}
          </View>

          <View style={styles.navBarButtonContainer}>
            {innerRightButton ? this.generateButton(innerRightButton) : null}

            {rightButton ? this.generateButton(rightButton) : null}
          </View>
        </View>
      </View>
    );
  }

  private generateButton(props: ButtonProps): React.ReactNode {
    if (!props) {
      return null;
    }
    return (
      <NavigationBarButton
        title={props.title}
        tintColor={props.tintColor}
        disabled={props.disabled}
        handler={props.handler}
        children={props.children}
        renderButtonWrapper={props.renderButtonWrapper}
      />
    );
  }
}

const NAV_BAR_HEIGHT = 44;

const styles = StyleSheet.create({
  navBar: {
    alignItems: 'stretch',
    flexDirection: 'row',
    height: NAV_BAR_HEIGHT,
    justifyContent: 'space-between',
  } as ViewStyle,
  navBarButtonContainer: {
    alignItems: 'stretch',
    flexDirection: 'row',
    justifyContent: 'center',
  } as ViewStyle,
  navBarContainer: {
    backgroundColor: 'white',
    borderBottomColor: '#efefef',
  } as ViewStyle,
  navBarTitleContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 44,
    right: 44,
  } as ViewStyle,
  navBarTitleText: {
    color: '#383838',
    fontSize: 17,
    fontWeight: 'bold',
    letterSpacing: 0.5,
  } as TextStyle,
});
