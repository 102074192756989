import Entity from './Entity';

export default class EffectCategory implements Entity {
  constructor(
    public id: number,
    public parentId: number | null,
    public name: string,
    public leaf: boolean,
    public baseEffectCount: number,
  ) {}
}
