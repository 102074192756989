import EntityMapper from './EntityMapper';

import ActorAccessoryMapper, {ActorAccessoryData} from './ActorAccessoryMapper';
import ActorCharacterMapper, {ActorCharacterData} from './ActorCharacterMapper';

import ActorAccessorySet from '../../../domain/entities/ActorAccessorySet';

export interface ActorAccessorySetData {
  id: number;
  name: string;
  actor_character: ActorCharacterData;
  actor_accessories: ActorAccessoryData[];
}

export default class ActorAccessorySetMapper
  implements EntityMapper<ActorAccessorySetData, ActorAccessorySet>
{
  private actorCharacterMapper: ActorCharacterMapper;
  private actorAccessoryMapper: ActorAccessoryMapper;

  constructor() {
    this.actorCharacterMapper = new ActorCharacterMapper();
    this.actorAccessoryMapper = new ActorAccessoryMapper();
  }

  public map(obj: ActorAccessorySetData): ActorAccessorySet {
    const actorCharacter = this.actorCharacterMapper.map(obj.actor_character);
    const actorAccessories = obj.actor_accessories.map(actorAccessoryObj => {
      return this.actorAccessoryMapper.map(actorAccessoryObj);
    });
    return new ActorAccessorySet(
      obj.id,
      obj.name,
      actorCharacter,
      actorAccessories,
    );
  }
}
