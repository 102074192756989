import * as React from 'react';

import BaseIndex from '../../../../../shared/generics/character_maker/actors/Index';

import * as routers from '../../../../../../routers';

import NavigationProp from '../../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterMakerActorsRouteProp} from '../../../../../../navigators/RouteProps';

import redirectSceneForm from '../../../../../../helpers/redirectSceneForm';

import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';
import Position from '../../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../../domain/forms/SceneForm';

export interface Params {
  characterMakerActorGroupId: number;
  skinColorPatternId: number;
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterMakerActorsRouteProp;
  sceneForm: SceneForm | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, route, sceneForm} = props;
  const {characterMakerActorGroupId, skinColorPatternId} = route.params;
  React.useEffect(() => {
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }, []);
  const onSelectActorCharacter = React.useCallback(
    (actorCharacter: ActorCharacter, inverted?: boolean) => {
      const {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
      } = route.params;
      const actorCharacterId = actorCharacter.id;
      routers.linkToSceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames(
        navigation,
        {
          storyId,
          episodeId,
          sceneId,
          actorCharacterId,
          sceneCommandIndex,
          subSceneCommandIndex,
          parentSceneCommandId,
          position,
          inverted,
        },
      );
    },
    [],
  );
  return (
    <BaseIndex
      navigation={navigation}
      characterMakerActorGroupId={characterMakerActorGroupId}
      skinColorPatternId={skinColorPatternId}
      onSelectActorCharacter={onSelectActorCharacter}
    />
  );
};

export default React.memo(Index);
