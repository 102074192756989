import * as React from 'react';

import EpisodeDetail from './partials/EpisodeDetail';
import Footer from './partials/Footer';

import Layout from '../shared/Layout';
import AlertModal from '../shared/modals/AlertModal';
import ActionModal from '../shared/modals/ActionModal';
import buildPublicationSettingButton from '../shared/buttons/buildPublicationSettingButton';
import shouldUpdateEpisode from '../shared/enhanced/shouldUpdateEpisode';
import shouldUpdateStory from '../shared/enhanced/shouldUpdateStory';
import shouldUpdateSceneList from '../shared/enhanced/shouldUpdateSceneList';
import Coachmark from '../shared/coachmark/Coachmark';
import CoachmarkContext from '../shared/coachmark/CoachmarkContext';
import Step2 from '../shared/publication_steps/Step2';

import SceneListSection from '../scenes/partials/SceneListSection';
import SceneDeleteConfirmModal from '../scenes/partials/SceneDeleteConfirmModal';

import navigateStoryPublication, {
  isFulfilledEpisode,
} from '../../view_models/navigateStoryPublication';

import {equalForKeys} from '../../helpers/equalForKeys';

import NavigationProp from '../../navigators/NavigationProp';
import {EpisodeRouteProp} from '../../navigators/RouteProps';

import * as routers from '../../routers';

import {Params as SceneIndexParams} from '../../actions/scenes/index';
import {Params as SceneCreateParams} from '../../actions/scenes/create';
import {Params as SceneUpdateParams} from '../../actions/scenes/update';
import {Params as CoachmarkModalUpdateParams} from '../../actions/coachmark_modal/update';
import {Params as ApplicationModalUpdateParams} from '../../actions/application_modal/update';

import {formatErrorMessages} from '../../helpers/errorMessages';

import Episode from '../../../domain/entities/Episode';
import Scene from '../../../domain/entities/Scene';
import Story from '../../../domain/entities/Story';
import ProjectManuscript from '../../../domain/entities/ProjectManuscript';
import OfferManuscript from '../../../domain/entities/writers_gate/OfferManuscript';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import TapNovelRestApi from '../../../data/data_stores/net/TapNovelRestApi';
import TapNovelTypedRestApi from '../../../data/data_stores/net/TapNovelTypedRestApi';

const title = 'シーン一覧';
const containerStyle = {backgroundColor: '#fafafa'};
const coachmarkViewStyle = {top: -22};
const arrowStyle = {
  left: 40,
  transform: [{rotate: '220deg'}],
};

interface EpisodeAvailableAction {
  id: number;
  show: boolean;
  update: boolean;
  destroy: boolean;
}

export interface Params {
  episodeId: number;
  page?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EpisodeRouteProp;
  episode: Episode | null;
  story: Story | null;
  scenes: Scene[] | null;
  scenesParams: SceneIndexParams;
}

export interface DispatchProps {
  showEpisode: (id: number) => Promise<Episode>;
  showStory: (id: number) => Promise<Story>;
  indexScenes: (params: SceneIndexParams) => Promise<PaginatedResult<Scene>>;
  createScene: (params: SceneCreateParams) => Promise<Scene>;
  updateScene: (id: number, params: SceneUpdateParams) => Promise<Scene>;
  destroyScene: (id: number) => Promise<number>;
  updateCoachmarkModal: (params: CoachmarkModalUpdateParams) => void;
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  nextPage: number | undefined;
  deletingScene: Scene | null;
  idToCopyLabel: {[key: number]: string};
  alertMessage?: string;
  editable: boolean;
  projectManuscript?: ProjectManuscript | null;
  offerManuscript?: OfferManuscript | null;
  visibleWritersGatePost?: boolean;
}

export default class Show extends React.Component<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      nextPage: undefined,
      deletingScene: null,
      idToCopyLabel: {},
      editable: !props.story?.entryContestId,
      visibleWritersGatePost: false,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    if (!equalForKeys(this.props.scenesParams, nextProps.scenesParams)) {
      return true;
    }
    if (shouldUpdateStory(this.props, nextProps)) {
      return true;
    }
    if (shouldUpdateEpisode(this.props, nextProps)) {
      return true;
    }
    if (shouldUpdateSceneList(this.props, nextProps)) {
      return true;
    }
    if (!equalForKeys(this.state, nextState)) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {navigation} = this.props;
    this.mounted = true;
    this.fetchEntities();
    navigation.addListener('focus', this.handleFocus);
    navigation.addListener('blur', this.handleBlur);
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const currentPage = this.props.route.params.page || 1;
    const prevPage = prevProps.route.params.page || 1;
    const curentEpisodeId = this.props.route.params.episodeId;
    const prevEpisodeId = prevProps.route.params.episodeId;
    if (currentPage !== prevPage) {
      this.fetchScenes();
    }
    if (curentEpisodeId !== prevEpisodeId) {
      this.fetchEntities();
    }
  }

  public componentWillUnmount() {
    const {navigation} = this.props;
    navigation.removeListener('blur', this.handleBlur);
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {navigation, story, episode, scenes, updateCoachmarkModal} =
      this.props;
    const {
      loading,
      deletingScene,
      idToCopyLabel,
      alertMessage,
      editable,
      projectManuscript,
      offerManuscript,
    } = this.state;
    return (
      <Layout
        title={title}
        navigation={navigation}
        back={this.enableBackButton()}
        scrollable={false}
        onScrollEndDrag={this.handleScrollEndDrag}
        loading={loading}
        rightButton={buildPublicationSettingButton({
          disabled: this.disableRightButton(),
          text: projectManuscript || offerManuscript ? '提出' : undefined,
          handler: this.handleOpenPublishEpisodeModal,
          renderButtonWrapper: btn => {
            return (
              <CoachmarkContext.Consumer>
                {context => {
                  return (
                    <Coachmark
                      autoShow={context === 'first_episode_created'}
                      coachmarkViewStyle={coachmarkViewStyle}
                      arrowStyle={arrowStyle}
                      arrowSize={170}
                      dialog={true}
                      onHide={this.onRequestCoachmark}
                      coachmarkViewChildren={
                        <Step2 onRequestClose={this.onRequestCoachmark} />
                      }>
                      {btn}
                    </Coachmark>
                  );
                }}
              </CoachmarkContext.Consumer>
            );
          },
        })}
        containerStyle={containerStyle}>
        {episode && scenes && (
          <SceneListSection
            scenes={scenes}
            idToCopyLabel={idToCopyLabel}
            renderHeader={() => (
              <EpisodeDetail
                episode={episode}
                disabledPreview={scenes.length === 0}
                onFowardToEditEpisode={this.handleFowardToEditEpisode}
                onForwardToEpisodePreview={this.handleForwardToEpisodePreview}
              />
            )}
            renderFooter={() => (
              <Footer
                disabled={!editable}
                onForwardToNewScene={this.handleForwardToNewScene}
              />
            )}
            onSelectScene={this.handleSelectScene}
            onEditScene={this.handleEditScene}
            onDeleteScene={this.handleDeleteScene}
            onCopyScene={this.handleCopyScene}
            onChangeOrder={this.handleChangeOrder}
            onRenderError={message => {
              this.setState({alertMessage: message});
            }}
          />
        )}
        {deletingScene && (
          <SceneDeleteConfirmModal
            scene={deletingScene}
            onRequestCloseModal={this.handleRequestCloseModal}
            onRequestDeleteScene={this.handleRequestDeleteScene}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
        <ActionModal
          visible={!!this.state.visibleWritersGatePost}
          title={'提出後の修正・取り下げはできません。'}
          description={'提出しますか？'}
          descriptionStyle={{textAlign: 'center'}}
          onAccept={this.handleAccept}
          onRequestClose={this.handleRequestClose}
        />
      </Layout>
    );
  }

  private fetchEntities = () => {
    const {navigation, route, story, episode, scenes, showStory, showEpisode} =
      this.props;
    const {episodeId} = route.params;
    const fetchStory = (storyId: number) => {
      if (!story) {
        showStory(storyId);
      }
    };
    TapNovelTypedRestApi.get<ProjectManuscript>(
      `/api/writer/episode_project_manuscripts/${episodeId}`,
    )
      .then(result => {
        this.setState({projectManuscript: result.body});
      })
      .catch(() => {
        this.setState({projectManuscript: null});
      });
    TapNovelTypedRestApi.get<OfferManuscript[]>(
      `/api/writer/writers_gate/offer_manuscripts`,
      {episodeId},
    )
      .then(result => {
        if (result.body[0]) {
          this.setState({offerManuscript: result.body[0]});
        } else {
          this.setState({offerManuscript: null});
        }
      })
      .catch(() => {
        this.setState({offerManuscript: null});
      });
    if (!episode) {
      showEpisode(episodeId)
        .then(episode => {
          fetchStory(episode.storyId);
        })
        .catch(e => {
          if (e.status === 401 || e.status === 404) {
            navigation.goBack();
          }
        });
    } else {
      fetchStory(episode.storyId);
    }
    if (!scenes) {
      this.fetchScenes();
    }
  };

  private handleBlur = () => {
    if (this.mounted) {
      this.setState({idToCopyLabel: {}});
    }
  };

  private handleFocus = () => {
    const {route} = this.props;
    const {episodeId} = route.params;
    TapNovelRestApi.get<EpisodeAvailableAction>(
      `/api/writer/episodes/${episodeId}/available_action`,
    ).then(res => {
      this.setState({editable: res.body.update});
    });
  };

  private handleScrollEndDrag = () => {
    const {nextPage} = this.state;
    if (nextPage) {
      this.props.navigation.setParams({page: nextPage});
    }
  };

  private handleFowardToEditEpisode = () => {
    const {navigation, route} = this.props;
    const {episodeId} = route.params;
    routers.linkToEditEpisode(navigation, {episodeId});
  };

  private handleOpenPublishEpisodeModal = () => {
    const {navigation, episode, story, showStory} = this.props;
    const {projectManuscript, offerManuscript} = this.state;
    if (!episode) {
      return;
    }
    if (projectManuscript) {
      (navigation as any).push('ProjectsNavigation', {
        screen: 'EditProjectEpisodeImage',
        params: {
          projectId: projectManuscript.projectId,
          storyId: episode.storyId,
          episodeId: projectManuscript.episodeId,
          projectManuscriptId: projectManuscript.id,
          toBeRelease: true,
        },
      });
      return;
    }
    if (offerManuscript) {
      this.setState({visibleWritersGatePost: true});
      return;
    }
    const callback = async (s: Story) => {
      if (s.enabledPublication()) {
        if (await isFulfilledEpisode(episode.id)) {
          routers.linkToEpisodeFormEditEpisodePublication(navigation, {
            episodeId: episode.id,
          });
        } else {
          routers.linkToEditEpisode(navigation, {
            episodeId: episode.id,
            toBePublished: true,
          });
        }
      } else {
        navigateStoryPublication(navigation, s, {
          episodeIdToBePublished: episode.id,
        });
      }
    };
    if (story) {
      callback(story);
    } else {
      showStory(episode.storyId).then(callback);
    }
  };

  private handleSelectScene = (scene: Scene) => {
    const {navigation} = this.props;
    const sceneId = scene.id;
    routers.linkToEditScene(navigation, {sceneId});
  };

  private handleEditScene = (scene: Scene) => {
    const {navigation} = this.props;
    const sceneId = scene.id;
    routers.linkToShowScene(navigation, {sceneId});
  };

  private handleDeleteScene = (scene: Scene) => {
    this.setState({deletingScene: scene});
  };

  private handleRequestDeleteScene = (scene: Scene) => {
    const {route, destroyScene, showEpisode} = this.props;
    const {episodeId} = route.params;
    this.setState({deletingScene: null}, () => {
      destroyScene(scene.id)
        .then(() => {
          this.fetchScenes();
          showEpisode(episodeId);
        })
        .catch(error => {
          this.setState({alertMessage: formatErrorMessages({}, error)});
        });
    });
  };

  private handleRequestCloseModal = () => {
    this.setState({deletingScene: null});
  };

  private handleCopyScene = (scene: Scene) => {
    const {route, createScene, showEpisode} = this.props;
    const {episodeId} = route.params;
    this.setState({loading: true});
    createScene({copyFrom: scene.id})
      .then(newScene => {
        showEpisode(episodeId);
        this.setState({
          loading: false,
          idToCopyLabel: {
            ...this.state.idToCopyLabel,
            [newScene.id]: `シーン${scene.numberOfEpisode}からコピーしました`,
          },
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          alertMessage: formatErrorMessages({}, error),
        });
      });
  };

  private handleChangeOrder = (scene: Scene, rowOrderPosition: number) => {
    const {updateScene} = this.props;
    this.setState({loading: true}, () => {
      updateScene(scene.id, {rowOrderPosition, ignoreUpdate: true})
        .then(() => {
          this.fetchScenes(
            () => {
              this.setState({loading: false});
            },
            () => {
              this.setState({loading: false});
            },
          );
        })
        .catch(error => {
          this.fetchScenes(() => {
            this.setState({
              loading: false,
              alertMessage: formatErrorMessages({}, error),
            });
          });
        });
    });
  };

  private handleForwardToNewScene = () => {
    const {navigation, route} = this.props;
    const {episodeId} = route.params;
    routers.linkToNewScene(navigation, {episodeId, fromEpisode: true});
  };

  private handleForwardToEpisodePreview = () => {
    const {navigation, route} = this.props;
    const {episodeId} = route.params;
    routers.linkToEpisodeViewer(navigation, {episodeId});
  };

  private enableBackButton(): boolean {
    return true;
  }

  private fetchScenes(onSuccess?: () => void, onFail?: () => void) {
    const {indexScenes, scenesParams} = this.props;
    indexScenes(scenesParams)
      .then(result => {
        if (this.mounted) {
          this.setState({nextPage: result.nextPage});
        }
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(() => {
        if (onFail) {
          onFail();
        }
      });
  }

  private onRequestCoachmark = () => {
    const {updateCoachmarkModal} = this.props;
    updateCoachmarkModal({value: null});
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };

  private disableRightButton = () => {
    const {story, scenes} = this.props;
    const {projectManuscript, offerManuscript, editable} = this.state;
    if (scenes?.length === 0) {
      return true;
    }
    if (projectManuscript) {
      return projectManuscript.status === 'submitted';
    } else if (offerManuscript) {
      return offerManuscript.submitted;
    } else {
      return !editable || story?.regulatoryViolationStatus === 'rejected';
    }
  };

  private handleAccept = () => {
    const {navigation, updateApplicationModal} = this.props;
    const {offerManuscript} = this.state;
    if (!offerManuscript) {
      return;
    }
    this.setState({loading: true}, () => {
      TapNovelTypedRestApi.patch<OfferManuscript>(
        `/api/writer/writers_gate/offer_manuscripts/${offerManuscript.id}`,
        {offerManuscript: {submitted: true}},
      )
        .then(() => {
          navigation.navigate('WritersGateOffer', {
            id: offerManuscript.writersGateOffer.id,
          });

          setTimeout(() => {
            updateApplicationModal({
              options: {message: 'オファーにエピソードを提出しました。'},
            });
          }, 500);
        })
        .catch(error => {
          this.fetchScenes(() => {
            this.setState({
              loading: false,
              alertMessage: formatErrorMessages({}, error),
            });
          });
        });
    });
  };

  private handleRequestClose = () => {
    this.setState({visibleWritersGatePost: false});
  };

  private handlePressBack = () => {
    const {navigation} = this.props;
    const {projectManuscript} = this.state;
    if (!projectManuscript) {
      return;
    }
    navigation.navigate('ProjectUserEpisode', {
      id: projectManuscript.projectEpisodeId,
      projectId: projectManuscript.projectId,
    });
  };
}
