import * as React from 'react';
import {ImageSourcePropType, View} from 'react-native';

import Layout from '../../../shared/Layout';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import MultiSwitch from '../../../shared/forms/MultiSwitch';
import TooltipModal from '../../../shared/modals/TooltipModal';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CharacterFormEditCharacterPublicationRouteProp} from '../../../../navigators/RouteProps';

import {Params as CharacterPublicationUpdateParams} from '../../../../actions/character_publications/update';

import {formStyles} from '../../../../styles/variables';

import Character from '../../../../../domain/entities/Character';

const PUBLISHED_ITEMS = [
  {label: '公開', value: 'true'},
  {label: '非公開', value: 'false'},
];

type TooltipModalType = 'published';

const TypeToTooltipInfo = {
  published: {
    title: '公開設定',
    description: '',
    source: null,
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source: ImageSourcePropType | null;
  };
};

export interface Params {
  characterId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CharacterFormEditCharacterPublicationRouteProp;
  character: Character | null;
}

export interface DispatchProps {
  showCharacter: (id: number) => Promise<Character>;
  updateCharacterPublication: (
    id: number,
    params: CharacterPublicationUpdateParams,
  ) => Promise<Character>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  published: boolean;
  tooltipModalType: TooltipModalType | null;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tooltipModalType: null,
      published: props.character?.published || false,
    };
  }

  public componentDidMount() {
    const {showCharacter, route} = this.props;
    const {characterId} = route.params;
    showCharacter(characterId);
  }

  public render(): React.ReactNode {
    const {navigation, character} = this.props;
    const {tooltipModalType} = this.state;
    const modalInfo = tooltipModalType
      ? TypeToTooltipInfo[tooltipModalType]
      : null;
    return (
      <Layout
        title={`${character ? `${character.name}の` : ''}公開設定`}
        close={true}
        navigation={navigation}>
        <View style={formStyles.formGroup}>
          <LabelWithOption title={'公開設定'} />
          <MultiSwitch
            items={PUBLISHED_ITEMS}
            value={`${this.state.published}`}
            onSelectItem={item => {
              this.setState({published: item.value === 'true'});
            }}
          />
        </View>
        <View style={{alignItems: 'center', marginVertical: 16}}>
          <PrimaryButton onPress={this.handleSubmit}>決定</PrimaryButton>
        </View>
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            onCloseModal={() => {
              this.setState({tooltipModalType: null});
            }}></TooltipModal>
        ) : null}
      </Layout>
    );
  }

  private handleSubmit = () => {
    const {navigation, route, updateCharacterPublication} = this.props;
    const {characterId} = route.params;
    const {published} = this.state;
    updateCharacterPublication(characterId, {published}).then(() => {
      navigation.goBack();
    });
  };
}
