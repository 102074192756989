import {Dispatch} from 'redux';

import Types from '../Types';

import Background from '../../../domain/entities/Background';
import PositionedEffect from '../../../domain/entities/PositionedEffect';

import SceneForm, {
  Options,
  EffectOptions,
} from '../../../domain/forms/SceneForm';

export interface Params {
  id: number | null;
  storyId: number;
  episodeId: number;
  background: Background | null;
  positionedEffect?: PositionedEffect | null;
  options: Options | null;
  effectOptions?: EffectOptions | null;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<SceneForm> {
  return (dispatch: Dispatch<any>): Promise<SceneForm> => {
    const payload = new SceneForm(params.storyId, params.episodeId);
    payload.id = params.id;
    payload.options = params.options || null;
    payload.background = params.background;
    payload.positionedEffect = params.positionedEffect || null;
    payload.effectOptions = params.effectOptions || null;
    dispatch({
      payload,
      type: Types.UpdateSceneForm,
    });
    return new Promise(resolve => resolve(payload));
  };
}
