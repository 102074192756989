import EntityMapper from '../../../EntityMapper';

import Subscription, {
  Status,
} from '../../../../../../domain/entities/writer/my_stripe/maker_pro/Subscription';

export interface SubscriptionData {
  id: number;
  my_stripe_customer_id: number;
  cancel_at: string | null;
  cancel_at_period_end: boolean | null;
  canceled_at: string | null;
  current_period_start: string | null;
  current_period_end: string | null;
  status: Status;
  created_at: string;
  updated_at: string;
}

export default class SubscriptionMapper
  implements EntityMapper<SubscriptionData, Subscription>
{
  public map(obj: SubscriptionData): Subscription {
    return new Subscription(
      obj.id,
      obj.my_stripe_customer_id,
      obj.cancel_at ? new Date(obj.cancel_at) : null,
      obj.cancel_at_period_end,
      obj.canceled_at ? new Date(obj.canceled_at) : null,
      obj.current_period_start ? new Date(obj.current_period_start) : null,
      obj.current_period_end ? new Date(obj.current_period_end) : null,
      obj.status,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
