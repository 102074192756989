import * as React from 'react';
import {
  Animated,
  Dimensions,
  PanResponder,
  PanResponderInstance,
  PanResponderGestureState,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import MultiSwitchButton from './MultiSwitchButton';

import getContentDimension from '../../../helpers/getContentDimension';

const window = Dimensions.get('window');
const {width} = getContentDimension(window);

interface Item {
  label: string;
  value: string;
  optionLabel?: string;
}

interface Props {
  style?: StyleProp<ViewStyle>;
  items: Item[];
  disabled?: boolean;
  value?: string;
  onSelectItem: (item: Item) => void;
}

interface State {
  value?: string;
  position: Animated.Value;
  posValue: number;
  selectedPosition: number | undefined;
  duration: number;
  mainWidth: number;
  switcherWidth: number;
  thresholdDistance: number;
}

export default class MultiSwitch extends React.Component<Props, State> {
  private isParentScrollDisabled: boolean;

  private _panResponder: PanResponderInstance;

  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (nextProps.value !== prevState.value) {
      const foundIndex = nextProps.items.findIndex(
        row => row.value === nextProps.value,
      );
      const selectedPosition = foundIndex >= 0 ? foundIndex : undefined;
      if (selectedPosition === undefined) {
        return {value: nextProps.value, selectedPosition, posValue: 0};
      }
      const posValue = prevState.switcherWidth * selectedPosition;
      Animated.timing(prevState.position, {
        toValue: posValue,
        duration: 100,
        useNativeDriver: true,
      }).start();
      return {value: nextProps.value, selectedPosition, posValue};
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    const {items, value} = props;
    const itemsLength = items.length;
    const foundIndex = items.findIndex(row => row.value === value);
    const selectedPosition = foundIndex >= 0 ? foundIndex : undefined;
    const switcherWidth =
      (width - CONTAINER_MARGIN_HORIZONTAL * 2) / itemsLength -
      SWITCHER_PADDING;
    this.state = {
      value: props.value,
      position: new Animated.Value(0),
      posValue: 0,
      selectedPosition,
      duration: 100,
      mainWidth: width - CONTAINER_MARGIN_HORIZONTAL * 2,
      switcherWidth,
      thresholdDistance:
        width - CONTAINER_MARGIN_HORIZONTAL * 2 - switcherWidth,
    };
    this.isParentScrollDisabled = false;
    this._panResponder = PanResponder.create({
      onStartShouldSetPanResponder: () => true,
      onStartShouldSetPanResponderCapture: () => true,
      onMoveShouldSetPanResponder: () => true,
      onMoveShouldSetPanResponderCapture: () => true,

      onPanResponderGrant: () => {
        if (!this.isParentScrollDisabled) {
          this.isParentScrollDisabled = true;
        }
      },

      onPanResponderMove: (e, gestureState) => {
        const {disabled} = this.props;
        const {posValue, thresholdDistance, position} = this.state;
        if (disabled) {
          return;
        }

        const finalValue = gestureState.dx + posValue;
        if (finalValue >= 0 && finalValue <= thresholdDistance)
          position.setValue(posValue + gestureState.dx);
      },

      onPanResponderTerminationRequest: (e, gestureState) => {
        const {disabled} = this.props;
        if (disabled) {
          return true;
        }
        if (this.isParentScrollDisabled) {
          this.isParentScrollDisabled = false;
          this.handlePanResponderRelease(gestureState);
        }
        return true;
      },

      onPanResponderRelease: (e, gestureState) => {
        const {disabled} = this.props;
        if (disabled) {
          return;
        }
        this.handlePanResponderRelease(gestureState);
      },

      onPanResponderTerminate: () => {},
      onShouldBlockNativeResponder: () => true,
    });
  }

  public componentDidMount() {
    this.moveInitialState();
  }

  public render(): React.ReactNode {
    const {style, items, disabled} = this.props;
    const {position, switcherWidth, selectedPosition} = this.state;
    const currentItem =
      selectedPosition === undefined ? null : items[selectedPosition];

    return (
      <View style={[styles.container, style]}>
        {items.map((item, i) => (
          <MultiSwitchButton
            key={`${i}`}
            value={item.value}
            label={item.label}
            optionLabel={item.optionLabel}
            disabled={disabled}
            last={items.length === i + 1}
            onPress={() => this.handleSelectItem(item)}
          />
        ))}
        {currentItem && (
          <Animated.View
            {...this._panResponder.panHandlers}
            style={[
              styles.switcher,
              selectedPosition === 0 ? {left: 4} : null,
              {width: switcherWidth, transform: [{translateX: position}]},
              disabled ? {borderColor: '#999999'} : null,
            ]}>
            <MultiSwitchButton
              value={currentItem.value}
              label={currentItem.label}
              optionLabel={currentItem.optionLabel}
              checked={true}
              disabled={disabled}
              onPress={() => {}}
            />
          </Animated.View>
        )}
      </View>
    );
  }

  public selectItem = (item: Item) => {
    this.handleSelectItem(item);
  };

  private handleSelectItem = (item: Item, triggerSelectItem = true) => {
    const {items, disabled, onSelectItem} = this.props;
    const {position, switcherWidth, duration} = this.state;
    const selectedPosition =
      items.findIndex(row => row.value === item.value) || 0;
    const posValue = switcherWidth * selectedPosition;
    Animated.timing(position, {
      toValue: posValue,
      duration,
      useNativeDriver: true,
    }).start();
    setTimeout(() => {
      this.setState({posValue, selectedPosition, value: item.value});
    }, 100);
    if (triggerSelectItem) {
      onSelectItem(item);
    }
  };

  private moveInitialState = () => {
    const {items} = this.props;
    const {selectedPosition} = this.state;
    if (selectedPosition === undefined) {
      return;
    }
    this.handleSelectItem(items[selectedPosition], false);
  };

  private handlePanResponderRelease = (
    gestureState: PanResponderGestureState,
  ) => {
    const {items} = this.props;
    const {posValue, switcherWidth} = this.state;
    const finalValue = gestureState.dx + posValue;
    this.isParentScrollDisabled = false;
    const item = items.find((item, i) => {
      return (
        switcherWidth * (i - 0.5) <= finalValue &&
        finalValue < switcherWidth * (i + 0.5)
      );
    });
    if (item) {
      this.handleSelectItem(item);
    }
  };
}

const CONTAINER_MARGIN_HORIZONTAL = 16;
const CONTAINER_MARGIN_VERTICAL = 14;

const SWITCHER_PADDING = 2;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderRadius: 4,
    height: 38,
    width: '100%',
    flexDirection: 'row',
    marginVertical: CONTAINER_MARGIN_VERTICAL,
    padding: 4,
  } as ViewStyle,
  switcher: {
    flexDirection: 'row',
    position: 'absolute',
    top: 4,
    left: SWITCHER_PADDING,
    bottom: 4,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderRadius: 4,
    backgroundColor: '#ffffff',
    borderColor: '#ff8f13',
  },
});
