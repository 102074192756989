import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import NoticeIcon from '../../../shared/NoticeIcon';
import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import Episode from '../../../../../domain/entities/response/Episode';

interface Props {
  episode: Episode;
  onSelectEpisode: (episode: Episode) => void;
}

const EpisodeListItem: React.FunctionComponent<Props> = props => {
  const {episode, onSelectEpisode} = props;
  const handleSelectEpisode = React.useCallback(() => {
    onSelectEpisode(episode);
  }, [episode.id]);
  return (
    <Pressable style={styles.container} onPress={handleSelectEpisode}>
      <View style={styles.body}>
        <View style={styles.episodeInfo}>
          <Text style={styles.title}>{episode.title}</Text>
          <View style={styles.bottomItem}>
            <Text style={styles.bottomItemKey}>リアクション</Text>
            <Text style={styles.bottomItemValue}>
              {numeral(episode.reactionsCount).format('0,0')}
            </Text>
          </View>
          <View style={styles.bottomItem}>
            <Text style={styles.bottomItemKey}>コメント</Text>
            <Text style={styles.bottomItemValue}>
              {numeral(episode.commentsCount).format('0,0')}
            </Text>
          </View>
        </View>
        <View style={{justifyContent: 'center'}}>
          <ArrowForwardIcon />
        </View>
      </View>
      {episode.confirmed || <NoticeIcon style={styles.noticeIcon} />}
    </Pressable>
  );
};

export default React.memo(EpisodeListItem);

const styles = StyleSheet.create({
  body: {
    flex: 1,
    flexDirection: 'row',
    margin: 16,
  } as ViewStyle,
  container: {
    backgroundColor: 'white',
  } as ViewStyle,
  episodeInfo: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: 5,
  } as TextStyle,
  bottomItem: {
    flexDirection: 'row',
    width: '100%',
    paddingRight: 40,
  } as ViewStyle,
  bottomItemKey: {
    flex: 1,
    color: '#383838',
    fontSize: 11,
    alignSelf: 'center',
  } as TextStyle,
  bottomItemValue: {
    flex: 1,
    textAlign: 'right',
    color: '#ff8f13',
    fontSize: 11,
    alignSelf: 'center',
  } as TextStyle,
  noticeIcon: {
    position: 'absolute',
    top: 9,
    right: 10,
  } as ViewStyle,
});
