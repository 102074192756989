import * as React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

interface Props {
  onSubmit: (password: string, passwordConfirmation: string) => void;
}

interface State {
  password: string;
  passwordConfirmation: string;
}

export default class Form extends React.PureComponent<Props, State> {
  private passwordInput: TextInput | null = null;
  private passwordConfirmationInput: TextInput | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirmation: '',
    };
  }

  public render(): React.ReactNode {
    const {password, passwordConfirmation} = this.state;
    return (
      <View style={styles.container}>
        <View style={styles.section}>
          <View style={styles.section}>
            <Text style={styles.formLabel}>パスワード</Text>
          </View>
          <View style={styles.textInputWrapper}>
            <TextInput
              style={styles.textInput}
              keyboardType={Platform.select({
                ios: 'ascii-capable',
                default: 'default',
              })}
              secureTextEntry={true}
              placeholder={'新しいパスワード(8文字以上)'}
              value={password}
              onChangeText={this.handleChangePassword}
              returnKeyType={'next'}
              ref={this.refPasswordInput}
              onSubmitEditing={this.handleSubmitEditingPassword}
            />
          </View>
        </View>
        <View style={styles.section}>
          <View style={styles.section}>
            <Text style={styles.formLabel}>確認用パスワード</Text>
          </View>
          <View style={styles.textInputWrapper}>
            <TextInput
              style={styles.textInput}
              keyboardType={Platform.select({
                ios: 'ascii-capable',
                default: 'default',
              })}
              secureTextEntry={true}
              placeholder={'新しいパスワードの確認'}
              value={passwordConfirmation}
              onChangeText={this.handleChangePasswordConfirmation}
              returnKeyType={'done'}
              ref={this.refPasswordConfirmationInput}
              onSubmitEditing={this.handlePressButton}
            />
          </View>
        </View>
        <View style={styles.submitButton}>
          <PrimaryButton
            disabled={!this.validPassword(password, passwordConfirmation)}
            onPress={this.handlePressButton}>
            送信する
          </PrimaryButton>
        </View>
      </View>
    );
  }

  private refPasswordInput = (instance: TextInput | null) => {
    this.passwordInput = instance;
  };

  private refPasswordConfirmationInput = (instance: TextInput | null) => {
    this.passwordConfirmationInput = instance;
  };

  private handleSubmitEditingPassword = () => {
    if (this.passwordConfirmationInput) {
      this.passwordConfirmationInput.focus();
    }
  };

  private handleChangePassword = (password: string) => {
    this.setState({password});
  };

  private handleChangePasswordConfirmation = (passwordConfirmation: string) => {
    this.setState({passwordConfirmation});
  };

  private handlePressButton = () => {
    const {onSubmit} = this.props;
    const {password, passwordConfirmation} = this.state;
    if (this.validPassword(password, passwordConfirmation)) {
      onSubmit(password, passwordConfirmation);
    }
  };

  private validPassword = (password: string, passwordConfirmation: string) => {
    return password.length >= 8 && password === passwordConfirmation;
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: 16,
    marginTop: 25,
  } as ViewStyle,
  errorMessage: {
    color: 'red',
    fontSize: 11,
  } as TextStyle,
  section: {
    marginVertical: 8,
  } as ViewStyle,
  formLabel: {
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  textInputWrapper: {
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  textInput: {
    fontSize: 15,
    marginVertical: 8,
  } as TextStyle,
  submitButton: {
    alignItems: 'center',
    marginTop: 24,
  } as ViewStyle,
});
