import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import File from '../../../domain/entities/File';
import Story from '../../../domain/entities/Story';

import NetStoriesRepository from '../../../data/repositories/writer/NetStoriesRepository';

export interface Params {
  title?: string | null;
  catchPhrase?: string | null;
  summary?: string | null;
  introduction?: string | null;
  coverIllustrator?: string | null;
  image?: File | null;
  coverImage?: File | null;
  enableSyncEpisodesPrivateStatus?: boolean;
  enableSyncCharactersPrivateStatus?: boolean;
  genreIds?: number[] | null;
  subGenreIds?: number[] | null;
  published?: boolean;
  requestViolationModification?: boolean;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Story> {
  return async (dispatch: Dispatch<any>): Promise<Story> => {
    try {
      const payload = await new NetStoriesRepository().update(
        id,
        {story: params},
        {multipart: true},
      );
      dispatch({payload, type: Types.UpdateStorySuccess});
      sendDefaultCustomEvent({
        resourceType: 'story',
        resourceId: payload.id,
        resourceName: payload.title,
        actionName: 'update',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateStoryFailure});
      throw err;
    }
  };
}
