import * as React from 'react';

import BaseIndex from '../../common/sound_search_form/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as SoundCategoryIndexParams} from '../../../../../actions/sound_categories/index';
import {Params as SoundIndexParams} from '../../../../../actions/sounds/index';

import Sound from '../../../../../../domain/entities/Sound';
import SoundCategory from '../../../../../../domain/entities/SoundCategory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  selectedLargeSoundCategoryId?: number | null;
  selectedMiddleSoundCategoryId?: number | null;
  selectedSmallSoundCategoryId?: number | null;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  favorite?: boolean;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundMusicShowSceneCommandNewSoundSearchFormRouteProp;
  sceneForm: SceneForm | null;
  largeSoundCategoriesParams: SoundCategoryIndexParams;
  largeSoundCategories: SoundCategory[] | null;
  middleSoundCategoriesParams: SoundCategoryIndexParams;
  middleSoundCategories: SoundCategory[] | null;
  smallSoundCategoriesParams: SoundCategoryIndexParams;
  smallSoundCategories: SoundCategory[] | null;
}

export interface DispatchProps {
  indexSoundCategories: (
    params: SoundCategoryIndexParams,
  ) => Promise<PaginatedResult<SoundCategory>>;
  indexSounds: (params: SoundIndexParams) => Promise<PaginatedResult<Sound>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  totalCount: number;
  query: string;
}

export default class Index extends React.Component<Props, State> {
  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (params: {
    query?: string;
    soundCategoryId?: number;
  }) => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId,
      favorite,
    } = route.params;
    routers.linkToSceneFormBackgroundMusicShowSceneCommandNewSounds(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        selectedLargeSoundCategoryId,
        selectedMiddleSoundCategoryId,
        selectedSmallSoundCategoryId,
        favorite,
        ...params,
      },
    );
  };
}
