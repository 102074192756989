import * as React from 'react';
import {ImageStyle, Platform, StyleSheet, View, ViewStyle} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';

import {fullScreenIllustrationImageUrl} from '../../../../../../helpers/images';

import FullScreenIllustration from '../../../../../../../domain/entities/FullScreenIllustration';

const RESIZE_MODE = 'contain';
const IMAGE_SIZE = 'large';
const headers = {Accept: 'image/webp,image/apng,*/*'};

interface Props {
  fullScreenIllustration: FullScreenIllustration;
  children?: React.ReactNode;
}

const IllustrationView: React.FunctionComponent<Props> = props => {
  const {fullScreenIllustration} = props;
  return (
    <View style={styles.container}>
      <FastImageAdapter
        resizeMode={RESIZE_MODE}
        style={styles.image}
        source={{
          uri: fullScreenIllustrationImageUrl(
            fullScreenIllustration,
            IMAGE_SIZE,
          ),
          headers,
        }}
      />
    </View>
  );
};

export default React.memo(IllustrationView);

const width = 174;
const height = 246;
const aspectRatio = width / height;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height,
    width: '100%',
    margin: 5,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
  image: {
    aspectRatio,
    flex: 1,
    height,
    width,
  } as ImageStyle,
});
