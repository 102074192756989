export const buildPathWithQuery = (path: string, obj: {[key: string]: any}) => {
  const newObj = Object.fromEntries(
    Object.entries(obj).filter(([, v]) => v !== undefined),
  );
  const queryString = new URLSearchParams(
    Object.fromEntries(
      Object.entries(newObj).filter(([_k, v]) => v !== undefined),
    ),
  ).toString();
  return `${path}${queryString ? `?${queryString}` : ''}`;
};
