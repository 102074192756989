import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

interface Props {
  children?: React.ReactNode;
}

const ModalFooter: React.FunctionComponent<Props> = props => {
  const {children} = props;
  if (!children) {
    return null;
  }
  return <View style={styles.footer}>{children}</View>;
};

export default ModalFooter;

const styles = StyleSheet.create({
  footer: {
    alignItems: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
});
