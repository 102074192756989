import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import WarningModal from '../../shared/modals/WarningModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormTextRouteProp} from '../../../navigators/RouteProps';

import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import TextPosition from '../../../../domain/value_objects/TextPosition';
import WritingMode from '../../../../domain/value_objects/WritingMode';
import FontColor from '../../../../domain/value_objects/FontColor';
import FontSize from '../../../../domain/value_objects/FontSize';

interface FormData {
  text: string;
  fontColor: FontColor;
  fontSize: FontSize;
  writingMode: WritingMode;
  textPosition: TextPosition;
  fontFamily?: string;
}

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormTextRouteProp;
  coverImageForm: CoverImageForm | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

interface State {
  visibleWarningModal: boolean;
  formData: FormData | null;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visibleWarningModal: false,
      formData: null,
    };
  }

  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {visibleWarningModal} = this.state;
    return (
      <Layout
        title={'テキスト入力'}
        scrollable={false}
        navigation={navigation}
        close={true}>
        <Form onSubmit={this.handleSubmit} />
        <WarningModal
          visible={visibleWarningModal}
          onAccept={this.handleAccept}
          onRequestClose={this.handleRequestCloseModal}>
          指定した配置はすでにテキストが存在します。{'\n'}
          変更してもよろしいですか？
        </WarningModal>
      </Layout>
    );
  }

  private handleAccept = () => {
    const {formData} = this.state;
    if (!formData) {
      return;
    }
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey} = route.params;
    this.setState({visibleWarningModal: false}, () => {
      navigation.navigate('CoverImageFormTextPreview', {
        storyId,
        aspectRatio,
        imageKey,
        ...formData,
      });
    });
  };

  private handleRequestCloseModal = () => {
    this.setState({formData: null, visibleWarningModal: false});
  };

  private handleSubmit = (params: {
    text: string;
    fontColor: FontColor;
    fontSize: FontSize;
    writingMode: WritingMode;
    textPosition: TextPosition;
    fontFamily?: string;
  }) => {
    const {navigation, route, coverImageForm} = this.props;
    if (!coverImageForm) {
      return;
    }
    const {storyId, aspectRatio, imageKey} = route.params;
    if (this.existsTextPosition(coverImageForm, params.textPosition)) {
      this.setState({
        visibleWarningModal: true,
        formData: params,
      });
    } else {
      navigation.navigate('CoverImageFormTextPreview', {
        storyId,
        aspectRatio,
        imageKey,
        ...params,
      });
    }
  };

  private existsTextPosition = (
    coverImageForm: CoverImageForm,
    textPosition: TextPosition,
  ) => {
    return !!coverImageForm.getImageTextInfoList().find(imageTextInfo => {
      return imageTextInfo.textPosition === textPosition;
    });
  };
}
