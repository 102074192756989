import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import dateformat from 'dateformat';

import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import OfferManuscript from '../../../../../domain/entities/writers_gate/OfferManuscript';

interface Props {
  offerManuscript: OfferManuscript;
}

const OfferManuscriptListItem: React.FC<Props> = props => {
  const {offerManuscript} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(`/writers_gate/manuscripts/${offerManuscript.id}`);
  }, []);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={styles.submittedAt}>
        提出：
        {offerManuscript.submittedAt
          ? dateformat(offerManuscript.submittedAt, 'yyyy.mm.dd HH:MM')
          : ''}
      </Text>
      <View style={styles.box}>
        <View style={styles.left}>
          <Text style={styles.offerTitle}>
            {offerManuscript.writersGateOffer.title}
          </Text>
          <Text style={styles.title}>{offerManuscript.title}</Text>
          <Text style={styles.title}>
            {offerManuscript.writersGateOffer.typeName}
          </Text>
        </View>
        <ArrowForwardIcon />
      </View>
      {offerManuscript.locked ? (
        offerManuscript.approved ? (
          <View style={styles.labelApproved}>
            <Text style={styles.labelTextApproved}>採用</Text>
          </View>
        ) : (
          <View style={styles.labelRejected}>
            <Text style={styles.labelTextRejected}>不採用</Text>
          </View>
        )
      ) : null}
    </TouchableOpacity>
  );
};

export default React.memo(OfferManuscriptListItem);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    marginHorizontal: 16,
    marginTop: 10,
    marginBottom: 8,
  } as ViewStyle,
  submittedAt: {
    color: '#999999',
    fontSize: 10,
    marginBottom: 2,
  } as TextStyle,
  box: {
    borderColor: '#999999',
    height: 75,
    borderWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderRadius: 4,
  } as ViewStyle,
  left: {
    flex: 1,
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  } as ViewStyle,
  offerTitle: {
    color: '#666',
    fontSize: 10,
  } as TextStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  offerType: {
    color: '#666666',
    fontSize: 10,
  } as TextStyle,
  deadline: {
    color: '#666666',
    fontSize: 10,
  } as TextStyle,
  labelApproved: {
    width: 32,
    backgroundColor: '#FF8F13',
    marginTop: 8,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  labelRejected: {
    width: 32,
    borderColor: '#666666',
    borderWidth: 1,
    marginTop: 8,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  labelTextApproved: {
    color: '#fff',
    fontSize: 8,
  } as TextStyle,
  labelTextRejected: {
    color: '#666666',
    fontSize: 8,
  } as TextStyle,
});
