import * as React from 'react';
import {FlatList, ListRenderItemInfo, View} from 'react-native';

import InsertSceneCommandLinksGroup from './InsertSceneCommandLinksGroup';
import AddSceneCommandLinksGroup from './AddSceneCommandLinksGroup';
import BackgroundMusicChangeButton from './BackgroundMusicChangeButton';

import CharacterHideSceneCommandBox from './scene_command_boxes/CharacterHideSceneCommandBox';
import CharacterShowOrUpdateSceneCommandBox from './scene_command_boxes/CharacterShowOrUpdateSceneCommandBox';
import SpeechTextShowSceneCommandBox from './scene_command_boxes/SpeechTextShowSceneCommandBox';
import DescriptiveTextShowSceneCommandBox from './scene_command_boxes/DescriptiveTextShowSceneCommandBox';
import FullScreenIllustrationShowSceneCommandBox from './scene_command_boxes/FullScreenIllustrationShowSceneCommandBox';
import IllustrationShowSceneCommandBox from './scene_command_boxes/IllustrationShowSceneCommandBox';
import EffectShowSceneCommandBox from './scene_command_boxes/EffectShowSceneCommandBox';
import SoundEffectShowSceneCommandBox from './scene_command_boxes/SoundEffectShowSceneCommandBox';
import BackgroundMusicShowSceneCommandBox from './scene_command_boxes/BackgroundMusicShowSceneCommandBox';
import BackgroundMusicHideSceneCommandBox from './scene_command_boxes/BackgroundMusicHideSceneCommandBox';
import CompositeSequenceSceneCommandBox from './scene_command_boxes/CompositeSequenceSceneCommandBox';
import CurrentSceneFrameBox from './scene_command_boxes/CurrentSceneFrameBox';

import {ModalParams} from '../Modal';

import shouldUpdateSceneCommandFormList from '../../../shared/enhanced/shouldUpdateSceneCommandFormList';

import SceneFrame from '../../../../view_models/SceneFrame';
import CoachmarkState from '../../../../view_models/CoachmarkState';

import SceneCommandForm from '../../../../../domain/forms/scene_commands/SceneCommandForm';
import SceneForm from '../../../../../domain/forms/SceneForm';
import CharacterHideSceneCommandForm from '../../../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import CharacterShowSceneCommandForm from '../../../../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../../../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../../../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../../../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import Position from '../../../../../domain/value_objects/Position';

const DEFAULT_ITEM_HEIGHT = 330;

const backgroundMusicShowSceneCommandBoxStyle = {
  marginTop: 16,
};
const backgroundMusicHideSceneCommandBoxStyle = {
  backgroundColor: '#e5e5e5',
  marginBottom: 16,
};

interface Props {
  sceneForm: SceneForm;
  sceneCommandForms: SceneCommandForm[];
  enableFullScreenIllustration?: boolean;
  enableSoundEffect?: boolean;
  enableBackgroundMusic?: boolean;
  backgroundShowSceneCommandComponent: React.ReactNode;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
  onForwardToSpeechBalloons: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    positions?: Position[];
  }) => void;
  onForwardToTextFrames: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToIllustrations: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToFullScreenIllustrations: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToEffects: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToSoundEffects: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToBackgroundMusic: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
  onRequestOpenModal: (modalParams: ModalParams) => void;
  onRequestUpdateCoachmarkModal?: (
    coachmarkState: CoachmarkState | null,
  ) => void;
}

export default class SceneCommandBoxes extends React.Component<Props> {
  private ref = React.createRef<FlatList>();

  private indexToLength: {[key: number]: number} = {};

  private headerLength = 0;
  private footerLength = 0;

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (shouldUpdateSceneCommandFormList(this.props, nextProps)) {
      return true;
    }
    if (this.props.sceneForm !== nextProps.sceneForm) {
      return true;
    }
    if (
      this.props.enableFullScreenIllustration !==
      nextProps.enableFullScreenIllustration
    ) {
      return true;
    }
    if (this.props.enableSoundEffect !== nextProps.enableSoundEffect) {
      return true;
    }
    if (this.props.enableBackgroundMusic !== nextProps.enableBackgroundMusic) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {sceneCommandForms} = this.props;
    const data = generateData(sceneCommandForms);
    return (
      <FlatList
        ref={this.ref}
        data={data}
        ListHeaderComponent={this.renderListHeaderComponent(data)}
        ListFooterComponent={this.renderListFooterComponent(data)}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        getItemLayout={this.getItemLayout}
        windowSize={5}
        initialNumToRender={5}
        removeClippedSubviews={true}
      />
    );
  }

  public scrollToEnd(options?: {animated: boolean}) {
    this.ref.current?.scrollToEnd(options);
  }

  private getItemLayout = (data: Item[] | null | undefined, index: number) => {
    const length = this.indexToLength[index] || DEFAULT_ITEM_HEIGHT;
    const offset = Array.from(Array(index + 1).keys()).reduce(
      (previousValue, currentValue) => {
        return (
          previousValue +
          (this.indexToLength[currentValue] || DEFAULT_ITEM_HEIGHT)
        );
      },
      this.headerLength + this.footerLength,
    );
    return {length, offset, index};
  };

  private keyExtractor = (item: Item, index: number): string => {
    return `container_${item.sceneCommandForm.sceneCommandId}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<Item>,
  ): React.ReactElement<any> | null => {
    const {sceneCommandIndex, sceneCommandForm, sceneFrame, waitable} =
      info.item;
    const {
      sceneCommandForms,
      onChangeOrder,
      onRequestOpenModal,
      onForwardToCharacters,
    } = this.props;
    if (
      sceneCommandForm instanceof CharacterShowSceneCommandForm ||
      sceneCommandForm instanceof CharacterUpdateSceneCommandForm
    ) {
      const waiting = this.existsWaitingCharacters(sceneCommandForm);
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <CharacterShowOrUpdateSceneCommandBox
            key={sceneCommandForm.sceneCommandId}
            sceneCommandForm={sceneCommandForm}
            waitable={waitable}
            sceneFrame={sceneFrame}
            sceneCommandIndex={sceneCommandIndex}
            parentSceneCommandForm={sceneCommandForm}
            onChangeOrder={onChangeOrder}
            onRequestOpenModal={onRequestOpenModal}
            onForwardToCharacters={onForwardToCharacters}
          />,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
        waiting,
      );
    } else if (sceneCommandForm instanceof CharacterHideSceneCommandForm) {
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <CharacterHideSceneCommandBox
            key={sceneCommandForm.sceneCommandId}
            sceneCommandForm={sceneCommandForm}
            sceneFrame={sceneFrame}
            sceneCommandIndex={sceneCommandIndex}
            parentSceneCommandForm={sceneCommandForm}
            onChangeOrder={onChangeOrder}
            onRequestOpenModal={onRequestOpenModal}
            onForwardToCharacters={onForwardToCharacters}
          />,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
      );
    } else if (sceneCommandForm instanceof SpeechTextShowSceneCommandForm) {
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <React.Fragment key={sceneCommandForm.sceneCommandId}>
            <CurrentSceneFrameBox
              sceneFrame={sceneFrame}
              sceneCommandIndex={sceneCommandIndex}
              parentSceneCommandForm={sceneCommandForm}
              activePositions={sceneCommandForm.getPositions()}
              onChangeOrder={onChangeOrder}
              onRequestOpenModal={onRequestOpenModal}
              onForwardToCharacters={onForwardToCharacters}
            />
            <SpeechTextShowSceneCommandBox
              sceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          </React.Fragment>,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
      );
    } else if (
      sceneCommandForm instanceof DescriptiveTextShowSceneCommandForm
    ) {
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <React.Fragment key={sceneCommandForm.sceneCommandId}>
            <CurrentSceneFrameBox
              sceneFrame={sceneFrame}
              sceneCommandIndex={sceneCommandIndex}
              parentSceneCommandForm={sceneCommandForm}
              onChangeOrder={onChangeOrder}
              onRequestOpenModal={onRequestOpenModal}
              onForwardToCharacters={onForwardToCharacters}
            />
            <DescriptiveTextShowSceneCommandBox
              key={sceneCommandForm.sceneCommandId}
              sceneCommandForm={sceneCommandForm}
              onRequestOpenModal={onRequestOpenModal}
            />
          </React.Fragment>,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
      );
    } else if (sceneCommandForm instanceof IllustrationShowSceneCommandForm) {
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <React.Fragment key={sceneCommandForm.sceneCommandId}>
            <CurrentSceneFrameBox
              sceneFrame={sceneFrame}
              sceneCommandIndex={sceneCommandIndex}
              parentSceneCommandForm={sceneCommandForm}
              onChangeOrder={onChangeOrder}
              onRequestOpenModal={onRequestOpenModal}
              onForwardToCharacters={onForwardToCharacters}
            />
            <IllustrationShowSceneCommandBox
              key={sceneCommandForm.sceneCommandId}
              sceneCommandForm={sceneCommandForm}
              onRequestOpenModal={onRequestOpenModal}
            />
          </React.Fragment>,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
      );
    } else if (
      sceneCommandForm instanceof FullScreenIllustrationShowSceneCommandForm
    ) {
      const waiting = this.existsWaitingCharacters(sceneCommandForm);
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <React.Fragment key={sceneCommandForm.sceneCommandId}>
            <FullScreenIllustrationShowSceneCommandBox
              key={sceneCommandForm.sceneCommandId}
              sceneCommandForm={sceneCommandForm}
              onRequestOpenModal={onRequestOpenModal}
            />
          </React.Fragment>,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
        waiting,
      );
    } else if (sceneCommandForm instanceof EffectShowSceneCommandForm) {
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <React.Fragment key={sceneCommandForm.sceneCommandId}>
            <CurrentSceneFrameBox
              sceneFrame={sceneFrame}
              sceneCommandIndex={sceneCommandIndex}
              parentSceneCommandForm={sceneCommandForm}
              onChangeOrder={onChangeOrder}
              onRequestOpenModal={onRequestOpenModal}
              onForwardToCharacters={onForwardToCharacters}
            />
            <EffectShowSceneCommandBox
              key={sceneCommandForm.sceneCommandId}
              sceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          </React.Fragment>,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
      );
    } else if (sceneCommandForm instanceof SoundEffectShowSceneCommandForm) {
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <React.Fragment key={sceneCommandForm.sceneCommandId}>
            <CurrentSceneFrameBox
              sceneFrame={sceneFrame}
              sceneCommandIndex={sceneCommandIndex}
              parentSceneCommandForm={sceneCommandForm}
              onChangeOrder={onChangeOrder}
              onRequestOpenModal={onRequestOpenModal}
              onForwardToCharacters={onForwardToCharacters}
            />
            <SoundEffectShowSceneCommandBox
              key={sceneCommandForm.sceneCommandId}
              sceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          </React.Fragment>,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
      );
    } else if (
      sceneCommandForm instanceof BackgroundMusicShowSceneCommandForm
    ) {
      const waiting = this.existsWaitingCharacters(sceneCommandForm);
      return (
        <View
          style={
            sceneFrame.startBGM ? backgroundMusicShowSceneCommandBoxStyle : null
          }>
          {appendInsertSceneCommandLinksGroup(
            this.props,
            this.indexToLength,
            [
              <BackgroundMusicShowSceneCommandBox
                key={sceneCommandForm.sceneCommandId}
                sceneCommandForm={sceneCommandForm}
                sceneFrame={sceneFrame}
                onRequestOpenModal={onRequestOpenModal}
              />,
            ],
            sceneCommandForms,
            sceneCommandIndex,
            sceneFrame,
            waiting,
          )}
        </View>
      );
    } else if (
      sceneCommandForm instanceof BackgroundMusicHideSceneCommandForm
    ) {
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <View
            style={backgroundMusicHideSceneCommandBoxStyle}
            key={sceneCommandForm.sceneCommandId}>
            <BackgroundMusicHideSceneCommandBox
              key={sceneCommandForm.sceneCommandId}
              sceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          </View>,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
      );
    } else if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      const waiting = this.existsWaitingCharacters(sceneCommandForm);
      return appendInsertSceneCommandLinksGroup(
        this.props,
        this.indexToLength,
        [
          <CompositeSequenceSceneCommandBox
            key={sceneCommandForm.sceneCommandId}
            sceneCommandForm={sceneCommandForm}
            waitable={waitable}
            sceneFrame={sceneFrame}
            sceneCommandIndex={sceneCommandIndex}
            insertSceneCommandLinksGroupProps={{
              ...this.props,
              sceneFrame,
              sceneCommandForm,
              sceneCommandIndex: sceneCommandIndex,
            }}
            onChangeOrder={onChangeOrder}
            onRequestOpenModal={onRequestOpenModal}
            onForwardToCharacters={onForwardToCharacters}
          />,
        ],
        sceneCommandForms,
        sceneCommandIndex,
        sceneFrame,
        waiting,
      );
    } else {
      throw new Error('');
    }
  };

  private renderListHeaderComponent = (data: Item[]) => {
    return (
      <View
        onLayout={e => {
          const {height} = e.nativeEvent.layout;
          if (height > 0) {
            this.headerLength = height;
          }
        }}>
        {this.props.backgroundShowSceneCommandComponent}
        {this.renderListHeaderComponentInsertSceneCommandLinksGroup(data)}
      </View>
    );
  };

  private renderListHeaderComponentInsertSceneCommandLinksGroup = (
    data: Item[],
  ) => {
    const {sceneCommandForms} = this.props;
    return data.length > 0 &&
      !this.existsWaitingCharactersInSubSceneCommandForm(sceneCommandForms)
      ? buildInsertSceneCommandLinksGroup(
          this.props,
          sceneCommandForms,
          0,
          new SceneFrame(),
        )
      : null;
  };

  private renderListFooterComponent = (data: Item[]) => {
    const {sceneCommandForms} = this.props;
    if (data.length > 0) {
      return null;
    }
    return (
      <View
        onLayout={e => {
          const {height} = e.nativeEvent.layout;
          if (height > 0) {
            this.footerLength = height;
          }
        }}>
        {buildAddSceneCommandLinksGroup(
          this.props,
          sceneCommandForms,
          sceneCommandForms.length + 1,
          new SceneFrame(),
        )}
      </View>
    );
  };

  private existsWaitingCharacters = (sceneCommandForm: SceneCommandForm) => {
    const {sceneCommandForms} = this.props;
    const index = sceneCommandForms.indexOf(sceneCommandForm);
    if (index === -1) {
      return false;
    }
    const filteredSceneCommandForms = sceneCommandForms.slice(
      index + 1,
      sceneCommandForms.length,
    );
    return this.existsWaitingCharactersInSubSceneCommandForm(
      filteredSceneCommandForms,
    );
  };

  private existsWaitingCharactersInSubSceneCommandForm = (
    sceneCommandForms: SceneCommandForm[],
  ): boolean => {
    return sceneCommandForms.some(sceneCommandForm => {
      if (
        sceneCommandForm instanceof CharacterShowSceneCommandForm &&
        sceneCommandForm.waiting
      ) {
        return true;
      } else if (
        sceneCommandForm instanceof CompositeParallelSceneCommandForm ||
        sceneCommandForm instanceof CompositeSequenceSceneCommandForm
      ) {
        return this.existsWaitingCharactersInSubSceneCommandForm(
          sceneCommandForm.commandForms,
        );
      } else {
        return false;
      }
    });
  };
}

const insertSceneCommandLinksGroupWrapperStyle = {backgroundColor: '#e5e5e5'};
const insertSceneCommandLinksGroupInnerStyle = {marginTop: 24};

const appendInsertSceneCommandLinksGroup = (
  props: Props,
  indexToLength: {[key: number]: number},
  nodes: React.ReactNode[],
  sceneCommandForms: SceneCommandForm[],
  sceneCommandIndex: number,
  sceneFrame: SceneFrame,
  disabled = false,
) => {
  const sceneCommandForm = sceneCommandForms[sceneCommandIndex - 1];
  const lastSceneCommand = sceneCommandForms.length === sceneCommandIndex;
  return (
    <View
      style={
        sceneFrame.playingBGM()
          ? insertSceneCommandLinksGroupWrapperStyle
          : null
      }
      onLayout={e => {
        const {height} = e.nativeEvent.layout;
        if (height > 0) {
          indexToLength[sceneCommandIndex] = height;
        }
      }}>
      <View
        style={
          sceneFrame.playingBGM()
            ? insertSceneCommandLinksGroupInnerStyle
            : null
        }>
        {nodes}
      </View>
      {!disabled
        ? lastSceneCommand
          ? buildAddSceneCommandLinksGroup(
              props,
              sceneCommandForms,
              sceneCommandIndex,
              sceneFrame,
              lastSceneCommand,
            )
          : buildInsertSceneCommandLinksGroup(
              props,
              sceneCommandForms,
              sceneCommandIndex,
              sceneFrame,
            )
        : null}
      {sceneFrame.playingBGM() &&
      sceneCommandForm !== sceneFrame.backgroundMusic ? (
        <BackgroundMusicChangeButton
          onPress={() => {
            handleChangeBackgroundMusic(
              props,
              sceneCommandIndex - 1,
              sceneFrame,
            );
          }}
        />
      ) : null}
    </View>
  );
};

const buildInsertSceneCommandLinksGroup = (
  props: Props,
  sceneCommandForms: SceneCommandForm[],
  sceneCommandIndex: number,
  sceneFrame: SceneFrame,
) => {
  const {
    enableFullScreenIllustration,
    enableSoundEffect,
    enableBackgroundMusic,
    onForwardToSpeechBalloons,
    onForwardToTextFrames,
    onForwardToIllustrations,
    onForwardToFullScreenIllustrations,
    onForwardToEffects,
    onForwardToSoundEffects,
    onForwardToBackgroundMusic,
    onChangeOrder,
    onForwardToCharacters,
    onRequestOpenModal,
    onRequestUpdateCoachmarkModal,
  } = props;
  const sceneCommand = sceneCommandForms[sceneCommandIndex - 1];
  return (
    <React.Fragment key={`insert_buttons_${sceneCommandIndex}`}>
      <InsertSceneCommandLinksGroup
        sceneFrame={sceneFrame}
        sceneCommandForm={sceneCommand}
        sceneCommandIndex={sceneCommandIndex}
        sceneCommandForms={sceneCommandForms}
        enableFullScreenIllustration={enableFullScreenIllustration}
        enableSoundEffect={enableSoundEffect}
        enableBackgroundMusic={enableBackgroundMusic}
        onForwardToSpeechBalloons={onForwardToSpeechBalloons}
        onForwardToTextFrames={onForwardToTextFrames}
        onForwardToIllustrations={onForwardToIllustrations}
        onForwardToFullScreenIllustrations={onForwardToFullScreenIllustrations}
        onForwardToEffects={onForwardToEffects}
        onForwardToSoundEffects={onForwardToSoundEffects}
        onForwardToBackgroundMusic={onForwardToBackgroundMusic}
        onChangeOrder={onChangeOrder}
        onForwardToCharacters={onForwardToCharacters}
        onRequestOpenModal={onRequestOpenModal}
        onRequestUpdateCoachmarkModal={onRequestUpdateCoachmarkModal}
      />
    </React.Fragment>
  );
};

const buildAddSceneCommandLinksGroup = (
  props: Props,
  sceneCommandForms: SceneCommandForm[],
  sceneCommandIndex: number,
  sceneFrame: SceneFrame,
  lastSceneCommand?: boolean,
) => {
  const {
    enableFullScreenIllustration,
    enableSoundEffect,
    enableBackgroundMusic,
    onForwardToSpeechBalloons,
    onForwardToTextFrames,
    onForwardToIllustrations,
    onForwardToFullScreenIllustrations,
    onForwardToEffects,
    onForwardToSoundEffects,
    onForwardToBackgroundMusic,
    onChangeOrder,
    onForwardToCharacters,
    onRequestOpenModal,
    onRequestUpdateCoachmarkModal,
  } = props;
  const sceneCommand = sceneCommandForms[sceneCommandIndex - 1];
  return (
    <View key={`add_buttons_${sceneCommandIndex}`}>
      <AddSceneCommandLinksGroup
        sceneFrame={sceneFrame}
        sceneCommandForm={sceneCommand}
        sceneCommandIndex={sceneCommandIndex}
        enableFullScreenIllustration={enableFullScreenIllustration}
        enableSoundEffect={enableSoundEffect}
        enableBackgroundMusic={enableBackgroundMusic}
        lastSceneCommand={lastSceneCommand}
        showSpeechTextPositions={true}
        onForwardToSpeechBalloons={onForwardToSpeechBalloons}
        onForwardToTextFrames={onForwardToTextFrames}
        onForwardToIllustrations={onForwardToIllustrations}
        onForwardToFullScreenIllustrations={onForwardToFullScreenIllustrations}
        onForwardToEffects={onForwardToEffects}
        onForwardToSoundEffects={onForwardToSoundEffects}
        onForwardToBackgroundMusic={onForwardToBackgroundMusic}
        onChangeOrder={onChangeOrder}
        onForwardToCharacters={onForwardToCharacters}
        onRequestOpenModal={onRequestOpenModal}
        onRequestUpdateCoachmarkModal={onRequestUpdateCoachmarkModal}
      />
      {sceneFrame.playingBGM() ? (
        <BackgroundMusicChangeButton
          onPress={() => {
            handleChangeBackgroundMusic(props, sceneCommandIndex, sceneFrame);
          }}
        />
      ) : null}
    </View>
  );
};

const handleChangeBackgroundMusic = (
  props: Props,
  sceneCommandIndex: number,
  sceneFrame: SceneFrame,
) => {
  const {onRequestOpenModal} = props;
  if (sceneFrame.backgroundMusic) {
    onRequestOpenModal({
      type: 'CurrentBackgroundMusicShowSceneCommandModal',
      sceneCommandForm: sceneFrame.backgroundMusic,
      sceneCommandIndex: sceneCommandIndex,
    });
  }
};

interface Item {
  sceneCommandForm: SceneCommandForm;
  sceneCommandIndex: number;
  sceneFrame: SceneFrame;
  waitable: boolean;
}

const generateData = (sceneCommandForms: SceneCommandForm[]): Item[] => {
  let sceneFrame = new SceneFrame();
  let waitable = true;
  return sceneCommandForms
    .map((sceneCommandForm, i) => {
      const sceneCommandIndex = i + 1;
      sceneFrame = SceneFrame.copy(sceneFrame);
      if (
        sceneCommandForm instanceof CharacterShowSceneCommandForm ||
        sceneCommandForm instanceof CharacterUpdateSceneCommandForm
      ) {
        sceneFrame.update(sceneCommandForm.position, sceneCommandForm);
        const currentWaitable = waitable;
        waitable = false;
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable: currentWaitable,
        };
      } else if (sceneCommandForm instanceof CharacterHideSceneCommandForm) {
        waitable = false;
        const characterSceneCommandForm = sceneFrame.get(
          sceneCommandForm.position,
        );
        if (!characterSceneCommandForm) {
          return null;
        }
        sceneFrame = SceneFrame.copy(sceneFrame);
        sceneFrame.update(sceneCommandForm.position, sceneCommandForm);
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (sceneCommandForm instanceof SpeechTextShowSceneCommandForm) {
        waitable = false;
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (
        sceneCommandForm instanceof DescriptiveTextShowSceneCommandForm
      ) {
        waitable = false;
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (sceneCommandForm instanceof IllustrationShowSceneCommandForm) {
        waitable = false;
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (
        sceneCommandForm instanceof FullScreenIllustrationShowSceneCommandForm
      ) {
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (sceneCommandForm instanceof EffectShowSceneCommandForm) {
        waitable = false;
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (sceneCommandForm instanceof SoundEffectShowSceneCommandForm) {
        waitable = false;
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (
        sceneCommandForm instanceof BackgroundMusicShowSceneCommandForm
      ) {
        sceneFrame.setBackgroundMusic(sceneCommandForm);
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (
        sceneCommandForm instanceof BackgroundMusicHideSceneCommandForm
      ) {
        waitable = false;
        sceneFrame.setBackgroundMusic(sceneCommandForm);
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable,
        };
      } else if (
        sceneCommandForm instanceof CompositeSequenceSceneCommandForm
      ) {
        const currentWaitable = waitable;
        let nextWaitable = waitable;
        sceneCommandForm.commandForms.forEach(commandForm => {
          if (commandForm instanceof CompositeParallelSceneCommandForm) {
            commandForm.commandForms.forEach(subCommandForm => {
              sceneFrame.update(subCommandForm.position, subCommandForm);
            });
          } else if (commandForm instanceof CharacterShowSceneCommandForm) {
            sceneFrame.update(commandForm.position, commandForm);
          } else if (commandForm instanceof CharacterUpdateSceneCommandForm) {
            sceneFrame.update(commandForm.position, commandForm);
          } else if (commandForm instanceof CharacterHideSceneCommandForm) {
            sceneFrame.update(commandForm.position, commandForm);
          }
          if (
            !(commandForm instanceof FullScreenIllustrationShowSceneCommandForm)
          ) {
            nextWaitable = false;
          }
          if (
            commandForm instanceof BackgroundMusicShowSceneCommandForm ||
            commandForm instanceof BackgroundMusicHideSceneCommandForm
          ) {
            sceneFrame.setBackgroundMusic(commandForm);
          }
        });
        waitable = nextWaitable;
        return {
          sceneCommandForm,
          sceneCommandIndex,
          sceneFrame,
          waitable: currentWaitable,
        };
      } else {
        return null;
      }
    })
    .filter(notEmpty);
};

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}
