import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import NewSoundUsageHistoryButton from './NewSoundUsageHistoryButton';

interface Props {
  onPress: () => void;
}

const BottomNewSoundUsageHistoryButton: React.FunctionComponent<
  Props
> = props => {
  const {onPress} = props;
  return (
    <View style={styles.container}>
      <NewSoundUsageHistoryButton onPress={onPress} />
    </View>
  );
};

export default React.memo(BottomNewSoundUsageHistoryButton);

const styles = StyleSheet.create({
  container: {
    left: 0,
    right: 0,
    position: 'absolute',
    bottom: 0,
    paddingBottom: 16,
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  } as ViewStyle,
});
