import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';

import partnerProgramKeyvisuallUri from '../../../../../assets/svgs/partner-program-keyvisual.svg';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep1: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  const linkTo = useLinkTo();
  const onPressTerms = React.useCallback(() => {
    linkTo('/partner_program/policies/terms?modal=true');
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        TapNovelMakerパートナープログラムは、{'\n'}
        投稿したゲーム小説を通じて{'\n'}
        報酬を受け取れるプログラムです。
      </Text>
      <View style={styles.keyVisual}>
        <SvgUriAdapter
          uri={partnerProgramKeyvisuallUri}
          width={315}
          height={157}
        />
      </View>
      <Text style={styles.title}>閲覧や読者から受け取ったギフトよる報酬</Text>
      <Text style={styles.note}>
        あなたが投稿したゲーム小説が、{'\n'}
        TapNovel会員に読まれたり、{'\n'}
        読者からギフトアイテムを受け取ることで{'\n'}
        現金に交換できるポイントを{'\n'}
        獲得することができます。
      </Text>
      <View style={styles.agreement}>
        <TouchableOpacity onPress={onPressTerms}>
          <Text style={styles.agreementLink}>パートナープログラム約款</Text>
        </TouchableOpacity>
        <Text style={styles.agreementText}>を確認の上、ご同意ください</Text>
      </View>
      <PrimaryButton onPress={onPress}>
        パートナープログラムに参加する
      </PrimaryButton>
    </View>
  );
};

export default React.memo(ServiceExplanationStep1);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
  keyVisual: {
    marginTop: 16,
    marginBottom: 24,
  } as ViewStyle,
  title: {
    color: '#ff8f13',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
  note: {
    fontSize: 14,
    color: '#383838',
    textAlign: 'center',
    marginBottom: 32,
  } as TextStyle,
  agreement: {
    flexDirection: 'row',
  } as ViewStyle,
  agreementLink: {
    color: '#ff8f13',
    fontSize: 10,
    marginBottom: 18,
    textDecorationLine: 'underline',
  } as TextStyle,
  agreementText: {
    color: '#222',
    fontSize: 10,
    marginBottom: 18,
  } as TextStyle,
});
