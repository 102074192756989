import * as React from 'react';
import {ImageResizeMode, StyleProp, ViewStyle} from 'react-native';
import SearchResultWrapper from './SearchResultWrapper';
import SearchResultHeader from './SearchResultHeader';
import TotalCountWithSort from './TotalCountWithSort';
import SearchEmpty from './SearchEmpty';
import PaidToggleButton from './PaidToggleButton';

import EntityImageGridListWithModal from '../EntityImageGridListWithModal';
import {Entity} from '../SelectableEntityListWithModal';

interface Props<T extends Entity> {
  entities: T[] | null;
  totalCount: number | null;
  title: string;
  aspectRatio: number;
  resizeMode?: ImageResizeMode;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
  purchase?: boolean;
  paid?: boolean;
  resourceName?: string;
  containerStyle?: StyleProp<ViewStyle>;
  imageMaskStyle?: StyleProp<ViewStyle>;
  extraData?: any;
  withPaid?: boolean;
  onPressSearchConditionChangeButton: () => void;
  onSelectEntity: (entity: T) => void;
  onPressSort: (sort: 'popularity' | 'new_arrival') => void;
  imageUrlExtractor?: (item: T, width: number) => string;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  labelExtractor?: (item: T) => string | null;
  renderCustomLabel?: (item: T, width: number) => React.ReactNode;
  modalRenderFavoriteButton?: (item: T) => React.ReactNode;
  disabledItem?: (item: T) => boolean;
  onPaidValueChange?: (value: boolean) => void;
}

export default class SoundSearchResult<
  T extends Entity,
> extends React.PureComponent<Props<T>> {
  public render(): React.ReactNode {
    const {
      entities,
      totalCount,
      title,
      aspectRatio,
      resizeMode,
      sort,
      favorite,
      purchase,
      paid,
      containerStyle,
      imageMaskStyle,
      resourceName,
      extraData,
      withPaid,
      onPressSearchConditionChangeButton,
      onSelectEntity,
      onPressSort,
      onEndReached,
      imageUrlExtractor,
      labelExtractor,
      renderCustomLabel,
      modalRenderFavoriteButton,
      disabledItem,
      onPaidValueChange,
    } = this.props;
    return (
      <SearchResultWrapper>
        {entities && (
          <>
            {entities.length > 0 ? (
              <SearchResultHeader
                title={title}
                onPress={onPressSearchConditionChangeButton}
              />
            ) : null}
            <EntityImageGridListWithModal
              entities={entities}
              aspectRatio={aspectRatio}
              resizeMode={resizeMode}
              showsVerticalScrollIndicator={true}
              containerStyle={containerStyle}
              imageMaskStyle={imageMaskStyle}
              ListHeaderComponent={
                <>
                  {onPaidValueChange && (
                    <PaidToggleButton
                      value={withPaid}
                      onValueChange={onPaidValueChange}
                    />
                  )}
                  {entities.length > 0 ? (
                    <TotalCountWithSort
                      totalCount={totalCount}
                      sort={sort}
                      favorite={favorite}
                      purchase={purchase}
                      paid={paid}
                      onPress={onPressSort}
                    />
                  ) : null}
                </>
              }
              ListEmptyComponent={
                resourceName ? (
                  <SearchEmpty
                    favorite={favorite}
                    purchase={purchase}
                    resourceName={resourceName}
                  />
                ) : null
              }
              extraData={extraData}
              onSelectEntity={onSelectEntity}
              onEndReachedThreshold={0.8}
              onEndReached={onEndReached}
              imageUrlExtractor={imageUrlExtractor}
              labelExtractor={labelExtractor}
              renderCustomLabel={renderCustomLabel}
              modalRenderFavoriteButton={modalRenderFavoriteButton}
              disabledItem={disabledItem}
            />
          </>
        )}
      </SearchResultWrapper>
    );
  }
}
