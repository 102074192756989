import NavigationProp from '../navigators/NavigationProp';

import * as EditCharacter from '../components/characters/Edit';
import * as NewCharacter from '../components/characters/New';

import * as EpisodeFormEditEpisodePublication from '../components/episode_form/edit/episode_publication/Index';

import * as CharacterFormNew from '../components/character_form/new/home/Index';
import * as CharacterFormNewActorSearchForm from '../components/character_form/new/actor_search_form/Index';
import * as CharacterFormNewActors from '../components/character_form/new/actors/Index';
import * as CharacterFormNewDefaultCharacterPatterns from '../components/character_form/new/default_character_patterns/Index';
import * as CharacterFormNewCharacterMaker from '../components/character_form/new/character_maker/home/Index';
import * as CharacterFormNewCharacterMakerActors from '../components/character_form/new/character_maker/actors/Index';
import * as CharacterFormEditCharacterPatterns from '../components/character_form/edit/character_patterns/Index';
import * as CharacterFormEditCharacterPublication from '../components/character_form/edit/character_publication/Index';

import * as EditEpisode from '../components/episodes/Edit';
import * as Episode from '../components/episodes/Show';

import * as MenuNavigation from '../components/menu/Index';

import * as Home from '../components/home/Index';

import * as SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories from '../components/scene_form/background_show_scene_command/new/background_usage_histories/Index';
import * as SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm from '../components/scene_form/background_show_scene_command/new/base_background_search_form/Index';
import * as SceneFormBackgroundShowSceneCommandNewBackgroundFilters from '../components/scene_form/background_show_scene_command/new/background_filters/Index';
import * as SceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations from '../components/scene_form/background_show_scene_command/new/background_filters/Index'; // tslint:disable-next-line:max-line-length
import * as SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects from '../components/scene_form/background_show_scene_command/new/background_base_effects/Index';
import * as SceneFormBackgroundShowSceneCommandNewBackgroundEffects from '../components/scene_form/background_show_scene_command/new/background_effects/Index';
import * as SceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations from '../components/scene_form/background_show_scene_command/new/background_base_effects/Index'; // tslint:disable-next-line:max-line-length
import * as SceneFormBackgroundShowSceneCommandNewBackgrounds from '../components/scene_form/background_show_scene_command/new/backgrounds/Index';
import * as SceneFormBackgroundShowSceneCommandNewBaseBackgrounds from '../components/scene_form/background_show_scene_command/new/base_backgrounds/Index';
import * as SceneFormBackgroundShowSceneCommandNewNavigations from '../components/scene_form/background_show_scene_command/new/background_usage_histories/Index';

import * as SceneFormCharacterShowSceneCommandNewCharacterPatterns from '../components/scene_form/character_show_scene_command/new/character_patterns/Index';
import * as SceneFormCharacterShowSceneCommandNewActorCharacterFaces from '../components/scene_form/character_show_scene_command/new/actor_character_faces/Index';
import * as SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm from '../components/scene_form/character_show_scene_command/new/character_form/actor_search_form/Index';
import * as SceneFormCharacterShowSceneCommandNewCharacterFormActors from '../components/scene_form/character_show_scene_command/new/character_form/actors/Index';
import * as SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames from '../components/scene_form/character_show_scene_command/new/character_form/character_names/Index'; // tslint:disable-next-line:max-line-length
import * as SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns from '../components/scene_form/character_show_scene_command/new/character_form/default_character_patterns/Index'; // tslint:disable-next-line:max-line-length
import * as SceneFormCharacterShowSceneCommandNewCharacterPositions from '../components/scene_form/character_show_scene_command/new/character_positions/Index';
import * as SceneFormCharacterShowSceneCommandNewNavigations from '../components/scene_form/character_show_scene_command/new/character_patterns/Index';

import * as SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories from '../components/scene_form/illustration_show_scene_command/edit/illustration_usage_histories/Index';
import * as SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm from '../components/scene_form/illustration_show_scene_command/edit/illustration_search_form/Index';
import * as SceneFormIllustrationShowSceneCommandEditIllustrations from '../components/scene_form/illustration_show_scene_command/edit/illustrations/Index';
import * as SceneFormIllustrationShowSceneCommandEditNavigations from '../components/scene_form/illustration_show_scene_command/edit/illustration_usage_histories/Index';

import * as SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories from '../components/scene_form/illustration_show_scene_command/new/illustration_usage_histories/Index';
import * as SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm from '../components/scene_form/illustration_show_scene_command/new/illustration_search_form/Index';
import * as SceneFormIllustrationShowSceneCommandNewIllustrations from '../components/scene_form/illustration_show_scene_command/new/illustrations/Index';
import * as SceneFormIllustrationShowSceneCommandNewNavigations from '../components/scene_form/illustration_show_scene_command/new/illustration_usage_histories/Index';

import * as SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration from '../components/scene_form/full_screen_illustration_show_scene_command/edit/full_screen_illustrations/New';
import * as SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations from '../components/scene_form/full_screen_illustration_show_scene_command/edit/full_screen_illustrations/Index';
import * as SceneFormFullScreenIllustrationShowSceneCommandEditNavigations from '../components/scene_form/full_screen_illustration_show_scene_command/edit/full_screen_illustrations/New';

import * as SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration from '../components/scene_form/full_screen_illustration_show_scene_command/new/full_screen_illustrations/New';
import * as SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrations from '../components/scene_form/full_screen_illustration_show_scene_command/new/full_screen_illustrations/Index';
import * as SceneFormFullScreenIllustrationShowSceneCommandNewNavigations from '../components/scene_form/full_screen_illustration_show_scene_command/new/full_screen_illustrations/New';

import * as SceneFormEffectShowSceneCommandEditEffectUsageHistories from '../components/scene_form/effect_show_scene_command/edit/effect_usage_histories/Index';
import * as SceneFormEffectShowSceneCommandEditBaseEffectSearchForm from '../components/scene_form/effect_show_scene_command/edit/base_effect_search_form/Index';
import * as SceneFormEffectShowSceneCommandEditBaseEffects from '../components/scene_form/effect_show_scene_command/edit/base_effects/Index';
import * as SceneFormEffectShowSceneCommandEditEffects from '../components/scene_form/effect_show_scene_command/edit/effects/Index';
import * as SceneFormEffectShowSceneCommandEditEffectPositions from '../components/scene_form/effect_show_scene_command/edit/effect_positions/Index';
import * as SceneFormEffectShowSceneCommandEditNavigations from '../components/scene_form/effect_show_scene_command/edit/effect_usage_histories/Index';

import * as SceneFormEffectShowSceneCommandNewEffectUsageHistories from '../components/scene_form/effect_show_scene_command/new/effect_usage_histories/Index';
import * as SceneFormEffectShowSceneCommandNewBaseEffectSearchForm from '../components/scene_form/effect_show_scene_command/new/base_effect_search_form/Index';
import * as SceneFormEffectShowSceneCommandNewBaseEffects from '../components/scene_form/effect_show_scene_command/new/base_effects/Index';
import * as SceneFormEffectShowSceneCommandNewEffects from '../components/scene_form/effect_show_scene_command/new/effects/Index';
import * as SceneFormEffectShowSceneCommandNewEffectPositions from '../components/scene_form/effect_show_scene_command/new/effect_positions/Index';
import * as SceneFormEffectShowSceneCommandNewNavigations from '../components/scene_form/effect_show_scene_command/new/effect_usage_histories/Index';

import * as SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories from '../components/scene_form/sound_effect_show_scene_command/edit/sound_usage_histories/Index';
import * as SceneFormSoundEffectShowSceneCommandEditSoundSearchForm from '../components/scene_form/sound_effect_show_scene_command/edit/sound_search_form/Index';
import * as SceneFormSoundEffectShowSceneCommandEditSounds from '../components/scene_form/sound_effect_show_scene_command/edit/sounds/Index';
import * as SceneFormSoundEffectShowSceneCommandEditNavigations from '../components/scene_form/sound_effect_show_scene_command/edit/sound_usage_histories/Index';

import * as SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories from '../components/scene_form/sound_effect_show_scene_command/new/sound_usage_histories/Index';
import * as SceneFormSoundEffectShowSceneCommandNewSoundSearchForm from '../components/scene_form/sound_effect_show_scene_command/new/sound_search_form/Index';
import * as SceneFormSoundEffectShowSceneCommandNewSounds from '../components/scene_form/sound_effect_show_scene_command/new/sounds/Index';
import * as SceneFormSoundEffectShowSceneCommandNewNavigations from '../components/scene_form/sound_effect_show_scene_command/new/sound_usage_histories/Index';

import * as SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories from '../components/scene_form/background_music_show_scene_command/edit/sound_usage_histories/Index';
import * as SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm from '../components/scene_form/background_music_show_scene_command/edit/sound_search_form/Index';
import * as SceneFormBackgroundMusicShowSceneCommandEditSounds from '../components/scene_form/background_music_show_scene_command/edit/sounds/Index';
import * as SceneFormBackgroundMusicShowSceneCommandEditNavigations from '../components/scene_form/background_music_show_scene_command/edit/sound_usage_histories/Index';

import * as SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories from '../components/scene_form/background_music_show_scene_command/new/sound_usage_histories/Index';
import * as SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm from '../components/scene_form/background_music_show_scene_command/new/sound_search_form/Index';
import * as SceneFormBackgroundMusicShowSceneCommandNewSounds from '../components/scene_form/background_music_show_scene_command/new/sounds/Index';
import * as SceneFormBackgroundMusicShowSceneCommandNewNavigations from '../components/scene_form/background_music_show_scene_command/new/sound_usage_histories/Index';

import * as SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts from '../components/scene_form/descriptive_text_show_scene_command/new/descriptive_texts/Index';
import * as SceneFormDescriptiveTextShowSceneCommandNewNavigations from '../components/scene_form/descriptive_text_show_scene_command/new/descriptive_texts/Index';

import * as SceneFormSpeechTextShowSceneCommandNewNavigations from '../components/scene_form/speech_text_show_scene_command/new/speech_text_positions/Index';
import * as SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions from '../components/scene_form/speech_text_show_scene_command/new/speech_text_positions/Index';
import * as SceneFormSpeechTextShowSceneCommandNewSpeechTexts from '../components/scene_form/speech_text_show_scene_command/new/speech_texts/Index';

import * as ShowScene from '../components/scenes/Show';
import * as EditScene from '../components/scenes/Edit';
import * as NewScene from '../components/scenes/New';

import * as EditStory from '../components/stories/Edit';
import * as EditStoryNavigation from '../components/stories/Edit';

import * as NewStory from '../components/stories/New';
import * as NewStoryNavigation from '../components/stories/New';

import * as Story from '../components/stories/Show';

import * as EditStoryFormCoverImage from '../components/story_form/cover_image/Edit';
import * as NewStoryFormCoverImage from '../components/story_form/cover_image/New';
import * as EditStoryFormDetailInfo from '../components/story_form/detail_info/Edit';
import * as NewStoryFormDetailInfo from '../components/story_form/detail_info/New';
import * as EditStoryFormImage from '../components/story_form/image/Edit';
import * as NewStoryFormImage from '../components/story_form/image/New';
import * as StoryFormKeywords from '../components/story_form/keywords/Index';
import * as StoryFormMainGenre from '../components/story_form/main_genre/Index';
import * as StoryFormSubGenres from '../components/story_form/sub_genres/Index';

import * as WriterTemporaryUserRegistrationCompletion from '../components/writer/temporary_user_registration_completion/Index';
import * as WriterUserAccount from '../components/writer/user_accounts/Show';
import * as EditWriterUserRegistrationEmail from '../components/writer/user_registrations/emails/Edit';
import * as EditWriterUserRegistrationPassword from '../components/writer/user_registrations/passwords/Edit';

import * as WriterUserRegistrationNavigation from '../components/writer/user_registrations/New';
import * as NewWriterUserRegistration from '../components/writer/user_registrations/New';

import * as WriterUserSessionNavigation from '../components/writer/user_sessions/New';
import * as NewWriterUserSession from '../components/writer/user_sessions/New';

import * as EditWriterUser from '../components/writer/users/Edit';
import * as NewWriterUserCancellation from '../components/writer/user_cancellations/New';

import * as EpisodeViewer from '../components/episode_viewers/Show';
import * as PreviewSceneViewer from '../components/preview_scene_viewers/Show';
import * as SceneViewer from '../components/scene_viewers/Show';

import * as Announcements from '../components/announcements/Index';
import * as Announcement from '../components/announcements/Show';

import * as Helps from '../components/helps/Index';
import * as Help from '../components/helps/Show';

import * as Manuals from '../components/manuals/Index';
import * as Manual from '../components/manuals/Show';

import * as NewContact from '../components/contacts/New';

import * as PoliciesPrivacy from '../components/policies/privacy/Index';
import * as PoliciesTerms from '../components/policies/terms/Index';
import * as PoliciesSpecifiedCommercialTransactionLaw from '../components/policies/specified_commercial_transaction_law/Index';
import * as PoliciesSettlementLaw from '../components/policies/settlement_law/Index';

import * as SettingsNotifications from '../components/settings/notifications/Index';

export function linkToCharacter(
  navigation: NavigationProp,
  params: EditCharacter.Params,
) {
  navigation.navigate('Character', params);
}

export function linkToEditCharacter(
  navigation: NavigationProp,
  params: EditCharacter.Params,
) {
  navigation.navigate('EditCharacter', params);
}

export function linkToNewCharacter(
  navigation: NavigationProp,
  params: NewCharacter.Params,
) {
  navigation.navigate('NewCharacter', params);
}

export function linkToEpisodeFormEditEpisodePublication(
  navigation: any,
  params: EpisodeFormEditEpisodePublication.Params,
  screen = 'EpisodeFormEditEpisodePublication',
) {
  navigation.navigate('EpisodeFormEditNavigations', {screen, params});
}

export function linkToCharacterFormNew(
  navigation: any,
  params: CharacterFormNew.Params,
  screen = 'CharacterFormNew',
) {
  navigation.navigate('CharacterFormNewNavigations', {screen, params});
}

export function linkToCharacterFormNewActorSearchForm(
  navigation: any,
  params: CharacterFormNewActorSearchForm.Params,
  screen = 'CharacterFormNewActorSearchForm',
) {
  navigation.navigate('CharacterFormNewNavigations', {screen, params});
}

export function linkToCharacterFormNewActors(
  navigation: NavigationProp,
  params: CharacterFormNewActors.Params,
) {
  navigation.navigate('CharacterFormNewActors', params);
}

export function linkToCharacterFormNewDefaultCharacterPatterns(
  navigation: NavigationProp,
  params: CharacterFormNewDefaultCharacterPatterns.Params,
) {
  navigation.navigate('CharacterFormNewDefaultCharacterPatterns', params);
}

export function linkToCharacterFormNewCharacterMaker(
  navigation: NavigationProp,
  params: CharacterFormNewCharacterMaker.Params,
) {
  navigation.navigate('CharacterFormNewCharacterMaker', params);
}

export function linkToCharacterFormNewCharacterMakerActors(
  navigation: NavigationProp,
  params: CharacterFormNewCharacterMakerActors.Params,
) {
  navigation.navigate('CharacterFormNewCharacterMakerActors', params);
}

export function linkToCharacterFormEditCharacterPatterns(
  navigation: any,
  params: CharacterFormEditCharacterPatterns.Params,
  screen = 'CharacterFormEditCharacterPatterns',
) {
  navigation.navigate('CharacterFormEditNavigations', {screen, params});
}

export function linkToCharacterFormEditCharacterPublication(
  navigation: any,
  params: CharacterFormEditCharacterPublication.Params,
  screen = 'CharacterFormEditCharacterPublication',
) {
  navigation.navigate('CharacterFormEditNavigations', {screen, params});
}

export function linkToEditEpisode(
  navigation: any,
  params: EditEpisode.Params,
  screen = 'EditEpisode',
) {
  navigation.navigate('EpisodeFormEditNavigations', {params, screen});
}

export function linkToEpisode(
  navigation: NavigationProp,
  params: Episode.Params,
) {
  navigation.navigate('Episode', params);
}

export function linkToMenu(
  navigation: NavigationProp,
  params: MenuNavigation.Params,
) {
  navigation.navigate('Menu', params);
}

export function linkToHome(
  navigation: NavigationProp,
  params: Home.Params = {},
) {
  navigation.navigate('Home', params);
}

export function linkToSceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories(
  navigation: NavigationProp,
  params: SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories.Params,
) {
  if (!navigation.isFocused()) {
    return;
  }
  navigation.push(
    'SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories',
    params,
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm(
  navigation: NavigationProp,
  params: SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm.Params,
) {
  navigation.navigate(
    'SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm',
    params,
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewBackgroundFilters(
  navigation: NavigationProp,
  params: SceneFormBackgroundShowSceneCommandNewBackgroundFilters.Params,
) {
  navigation.navigate(
    'SceneFormBackgroundShowSceneCommandNewBackgroundFilters',
    params,
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations(
  navigation: any,
  params: SceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations.Params,
  screen = 'SceneFormBackgroundShowSceneCommandNewBackgroundFilters',
) {
  navigation.navigate(
    'SceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations',
    {screen, params},
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects(
  navigation: NavigationProp,
  params: SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects.Params,
) {
  navigation.navigate(
    'SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects',
    params,
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewBackgroundEffects(
  navigation: NavigationProp,
  params: SceneFormBackgroundShowSceneCommandNewBackgroundEffects.Params,
) {
  navigation.navigate(
    'SceneFormBackgroundShowSceneCommandNewBackgroundEffects',
    params,
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations(
  navigation: any,
  params: SceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations.Params,
  screen = 'SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects',
) {
  navigation.navigate(
    'SceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations',
    {screen, params},
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewBaseBackgrounds(
  navigation: NavigationProp,
  params: SceneFormBackgroundShowSceneCommandNewBaseBackgrounds.Params,
) {
  navigation.navigate(
    'SceneFormBackgroundShowSceneCommandNewBaseBackgrounds',
    params,
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewBackgrounds(
  navigation: NavigationProp,
  params: SceneFormBackgroundShowSceneCommandNewBackgrounds.Params,
) {
  navigation.navigate(
    'SceneFormBackgroundShowSceneCommandNewBackgrounds',
    params,
  );
}

export function linkToSceneFormBackgroundShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormBackgroundShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistories',
) {
  navigation.navigate('SceneFormBackgroundShowSceneCommandNewNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormCharacterShowSceneCommandNewCharacterPatterns(
  navigation: NavigationProp,
  params: SceneFormCharacterShowSceneCommandNewCharacterPatterns.Params,
) {
  navigation.navigate(
    'SceneFormCharacterShowSceneCommandNewCharacterPatterns',
    params,
  );
}

export function linkToSceneFormCharacterShowSceneCommandNewActorCharacterFaces(
  navigation: NavigationProp,
  params: SceneFormCharacterShowSceneCommandNewActorCharacterFaces.Params,
) {
  navigation.navigate(
    'SceneFormCharacterShowSceneCommandNewActorCharacterFaces',
    params,
  );
}

export function replaceToSceneFormCharacterShowSceneCommandNewActorCharacterFaces(
  navigation: NavigationProp,
  params: SceneFormCharacterShowSceneCommandNewActorCharacterFaces.Params,
) {
  navigation.replace(
    'SceneFormCharacterShowSceneCommandNewActorCharacterFaces',
    params,
  );
}

export function linkToSceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm(
  navigation: NavigationProp,
  params: SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm.Params,
) {
  navigation.navigate(
    'SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm',
    params,
  );
}
export function linkToSceneFormCharacterShowSceneCommandNewCharacterFormActors(
  navigation: NavigationProp,
  params: SceneFormCharacterShowSceneCommandNewCharacterFormActors.Params,
) {
  navigation.navigate(
    'SceneFormCharacterShowSceneCommandNewCharacterFormActors',
    params,
  );
}

export function linkToSceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames(
  navigation: NavigationProp,
  params: SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames.Params,
) {
  navigation.navigate(
    'SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNames',
    params,
  );
}
export function linkToSceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns(
  navigation: NavigationProp,
  params: SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns.Params,
) {
  navigation.navigate(
    'SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatterns',
    params,
  );
}

export function linkToSceneFormCharacterShowSceneCommandNewCharacterPositions(
  navigation: NavigationProp,
  params: SceneFormCharacterShowSceneCommandNewCharacterPositions.Params,
) {
  navigation.navigate(
    'SceneFormCharacterShowSceneCommandNewCharacterPositions',
    params,
  );
}

export function linkToSceneFormCharacterShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormCharacterShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormCharacterShowSceneCommandNewCharacterPatterns',
) {
  navigation.navigate('SceneFormCharacterShowSceneCommandNewNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts(
  navigation: NavigationProp,
  params: SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts.Params,
) {
  navigation.navigate(
    'SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts',
    params,
  );
}

export function linkToSceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories(
  navigation: NavigationProp,
  params: SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories.Params,
) {
  navigation.push(
    'SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories',
    params,
  );
}

export function linkToSceneFormIllustrationShowSceneCommandEditIllustrationSearchForm(
  navigation: NavigationProp,
  params: SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm.Params,
) {
  navigation.push(
    'SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm',
    params,
  );
}

export function linkToSceneFormIllustrationShowSceneCommandEditIllustrations(
  navigation: NavigationProp,
  params: SceneFormIllustrationShowSceneCommandEditIllustrations.Params,
) {
  navigation.navigate(
    'SceneFormIllustrationShowSceneCommandEditIllustrations',
    params,
  );
}

export function linkToSceneFormIllustrationShowSceneCommandEditNavigations(
  navigation: any,
  params: SceneFormIllustrationShowSceneCommandEditNavigations.Params,
  screen = 'SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories',
) {
  navigation.navigate('SceneFormIllustrationShowSceneCommandEditNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories(
  navigation: NavigationProp,
  params: SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories.Params,
) {
  navigation.push(
    'SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories',
    params,
  );
}

export function linkToSceneFormIllustrationShowSceneCommandNewIllustrationSearchForm(
  navigation: NavigationProp,
  params: SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm.Params,
) {
  navigation.push(
    'SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm',
    params,
  );
}

export function linkToSceneFormIllustrationShowSceneCommandNewIllustrations(
  navigation: NavigationProp,
  params: SceneFormIllustrationShowSceneCommandNewIllustrations.Params,
) {
  navigation.navigate(
    'SceneFormIllustrationShowSceneCommandNewIllustrations',
    params,
  );
}

export function linkToSceneFormIllustrationShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormIllustrationShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories',
) {
  navigation.navigate('SceneFormIllustrationShowSceneCommandNewNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration(
  navigation: NavigationProp,
  params: SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration.Params,
) {
  navigation.navigate(
    'SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration',
    params,
  );
}

export function linkToSceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations(
  navigation: NavigationProp,
  params: SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations.Params,
) {
  navigation.navigate(
    'SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations',
    params,
  );
}

export function linkToSceneFormFullScreenIllustrationShowSceneCommandEditNavigations(
  navigation: any,
  params: SceneFormFullScreenIllustrationShowSceneCommandEditNavigations.Params,
  screen = 'SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration',
) {
  navigation.navigate(
    'SceneFormFullScreenIllustrationShowSceneCommandEditNavigations',
    {screen, params},
  );
}

export function linkToSceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration(
  navigation: NavigationProp,
  params: SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration.Params,
) {
  navigation.navigate(
    'SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration',
    params,
  );
}

export function linkToSceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrations(
  navigation: NavigationProp,
  params: SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrations.Params,
) {
  navigation.navigate(
    'SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrations',
    params,
  );
}

export function linkToSceneFormFullScreenIllustrationShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormFullScreenIllustrationShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustration',
) {
  navigation.navigate(
    'SceneFormFullScreenIllustrationShowSceneCommandNewNavigations',
    {screen, params},
  );
}

export function linkToSceneFormEffectShowSceneCommandEditEffectUsageHistories(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandEditEffectUsageHistories.Params,
) {
  navigation.push(
    'SceneFormEffectShowSceneCommandEditEffectUsageHistories',
    params,
  );
}

export function linkToSceneFormEffectShowSceneCommandEditBaseEffectSearchForm(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandEditBaseEffectSearchForm.Params,
) {
  navigation.push(
    'SceneFormEffectShowSceneCommandEditBaseEffectSearchForm',
    params,
  );
}

export function linkToSceneFormEffectShowSceneCommandEditBaseEffects(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandEditBaseEffects.Params,
) {
  navigation.navigate('SceneFormEffectShowSceneCommandEditBaseEffects', params);
}

export function linkToSceneFormEffectShowSceneCommandEditEffects(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandEditEffects.Params,
) {
  navigation.navigate('SceneFormEffectShowSceneCommandEditEffects', params);
}

export function linkToSceneFormEffectShowSceneCommandEditEffectPositions(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandEditEffectPositions.Params,
) {
  navigation.navigate(
    'SceneFormEffectShowSceneCommandEditEffectPositions',
    params,
  );
}

export function linkToSceneFormEffectShowSceneCommandEditNavigations(
  navigation: any,
  params: SceneFormEffectShowSceneCommandEditNavigations.Params,
  screen = 'SceneFormEffectShowSceneCommandEditEffectUsageHistories',
) {
  navigation.navigate('SceneFormEffectShowSceneCommandEditNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormEffectShowSceneCommandNewEffectUsageHistories(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandNewEffectUsageHistories.Params,
) {
  navigation.push(
    'SceneFormEffectShowSceneCommandNewEffectUsageHistories',
    params,
  );
}

export function linkToSceneFormEffectShowSceneCommandNewBaseEffectSearchForm(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandNewBaseEffectSearchForm.Params,
) {
  navigation.push(
    'SceneFormEffectShowSceneCommandNewBaseEffectSearchForm',
    params,
  );
}

export function linkToSceneFormEffectShowSceneCommandNewBaseEffects(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandNewBaseEffects.Params,
) {
  navigation.navigate('SceneFormEffectShowSceneCommandNewBaseEffects', params);
}

export function linkToSceneFormEffectShowSceneCommandNewEffects(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandNewEffects.Params,
) {
  navigation.navigate('SceneFormEffectShowSceneCommandNewEffects', params);
}

export function linkToSceneFormEffectShowSceneCommandNewEffectPositions(
  navigation: NavigationProp,
  params: SceneFormEffectShowSceneCommandNewEffectPositions.Params,
) {
  navigation.navigate(
    'SceneFormEffectShowSceneCommandNewEffectPositions',
    params,
  );
}

export function linkToSceneFormEffectShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormEffectShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormEffectShowSceneCommandNewEffectUsageHistories',
) {
  navigation.navigate('SceneFormEffectShowSceneCommandNewNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormSoundEffectShowSceneCommandEditSoundUsageHistories(
  navigation: NavigationProp,
  params: SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories.Params,
) {
  navigation.push(
    'SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories',
    params,
  );
}

export function linkToSceneFormSoundEffectShowSceneCommandEditSoundSearchForm(
  navigation: NavigationProp,
  params: SceneFormSoundEffectShowSceneCommandEditSoundSearchForm.Params,
) {
  navigation.push(
    'SceneFormSoundEffectShowSceneCommandEditSoundSearchForm',
    params,
  );
}

export function linkToSceneFormSoundEffectShowSceneCommandEditSounds(
  navigation: NavigationProp,
  params: SceneFormSoundEffectShowSceneCommandEditSounds.Params,
) {
  navigation.navigate('SceneFormSoundEffectShowSceneCommandEditSounds', params);
}

export function linkToSceneFormSoundEffectShowSceneCommandEditNavigations(
  navigation: any,
  params: SceneFormSoundEffectShowSceneCommandEditNavigations.Params,
  screen = 'SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories',
) {
  navigation.navigate('SceneFormSoundEffectShowSceneCommandEditNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormSoundEffectShowSceneCommandNewSoundUsageHistories(
  navigation: NavigationProp,
  params: SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories.Params,
) {
  navigation.push(
    'SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories',
    params,
  );
}

export function linkToSceneFormSoundEffectShowSceneCommandNewSoundSearchForm(
  navigation: NavigationProp,
  params: SceneFormSoundEffectShowSceneCommandNewSoundSearchForm.Params,
) {
  navigation.push(
    'SceneFormSoundEffectShowSceneCommandNewSoundSearchForm',
    params,
  );
}

export function linkToSceneFormSoundEffectShowSceneCommandNewSounds(
  navigation: NavigationProp,
  params: SceneFormSoundEffectShowSceneCommandNewSounds.Params,
) {
  navigation.navigate('SceneFormSoundEffectShowSceneCommandNewSounds', params);
}

export function linkToSceneFormSoundEffectShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormSoundEffectShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormSoundEffectShowSceneCommandNewSoundUsageHistories',
) {
  navigation.navigate('SceneFormSoundEffectShowSceneCommandNewNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories(
  navigation: NavigationProp,
  params: SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories.Params,
) {
  navigation.push(
    'SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories',
    params,
  );
}

export function linkToSceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm(
  navigation: NavigationProp,
  params: SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm.Params,
) {
  navigation.push(
    'SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm',
    params,
  );
}

export function linkToSceneFormBackgroundMusicShowSceneCommandEditSounds(
  navigation: NavigationProp,
  params: SceneFormBackgroundMusicShowSceneCommandEditSounds.Params,
) {
  navigation.navigate(
    'SceneFormBackgroundMusicShowSceneCommandEditSounds',
    params,
  );
}

export function linkToSceneFormBackgroundMusicShowSceneCommandEditNavigations(
  navigation: any,
  params: SceneFormBackgroundMusicShowSceneCommandEditNavigations.Params,
  screen = 'SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories',
) {
  navigation.navigate(
    'SceneFormBackgroundMusicShowSceneCommandEditNavigations',
    {
      screen,
      params,
    },
  );
}

export function linkToSceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories(
  navigation: NavigationProp,
  params: SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories.Params,
) {
  navigation.push(
    'SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories',
    params,
  );
}

export function linkToSceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm(
  navigation: NavigationProp,
  params: SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm.Params,
) {
  navigation.push(
    'SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm',
    params,
  );
}

export function linkToSceneFormBackgroundMusicShowSceneCommandNewSounds(
  navigation: NavigationProp,
  params: SceneFormBackgroundMusicShowSceneCommandNewSounds.Params,
) {
  navigation.navigate(
    'SceneFormBackgroundMusicShowSceneCommandNewSounds',
    params,
  );
}

export function linkToSceneFormBackgroundMusicShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormBackgroundMusicShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories',
) {
  navigation.navigate(
    'SceneFormBackgroundMusicShowSceneCommandNewNavigations',
    {
      screen,
      params,
    },
  );
}

export function linkToSceneFormSpeechTextShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormSpeechTextShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions',
) {
  navigation.navigate('SceneFormSpeechTextShowSceneCommandNewNavigations', {
    screen,
    params,
  });
}

export function linkToSceneFormSpeechTextShowSceneCommandNewSpeechTextPositions(
  navigation: NavigationProp,
  params: SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions.Params,
) {
  navigation.navigate(
    'SceneFormSpeechTextShowSceneCommandNewSpeechTextPositions',
    params,
  );
}

export function linkToSceneFormSpeechTextShowSceneCommandNewSpeechTexts(
  navigation: NavigationProp,
  params: SceneFormSpeechTextShowSceneCommandNewSpeechTexts.Params,
) {
  navigation.navigate(
    'SceneFormSpeechTextShowSceneCommandNewSpeechTexts',
    params,
  );
}

export function linkToSceneFormDescriptiveTextShowSceneCommandNewNavigations(
  navigation: any,
  params: SceneFormDescriptiveTextShowSceneCommandNewNavigations.Params,
  screen = 'SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts',
) {
  navigation.navigate(
    'SceneFormDescriptiveTextShowSceneCommandNewNavigations',
    {screen, params},
  );
}

export function linkToShowScene(
  navigation: NavigationProp,
  params: ShowScene.Params,
) {
  navigation.navigate('ShowScene', params);
}

export function linkToEditScene(
  navigation: NavigationProp,
  params: EditScene.Params,
) {
  navigation.navigate('EditScene', params);
}

export function linkToNewScene(
  navigation: NavigationProp,
  params: NewScene.Params,
) {
  navigation.navigate('NewScene', params);
}

export function linkToEditStory(
  navigation: NavigationProp,
  params: EditStory.Params,
) {
  navigation.navigate('EditStory', params);
}

export function linkToEditStoryNavigation(
  navigation: any,
  params: EditStoryNavigation.Params,
  screen = 'EditStory',
) {
  navigation.navigate('EditStoryNavigation', {screen, params});
}

export function linkToNewStory(
  navigation: NavigationProp,
  params: NewStory.Params,
) {
  navigation.navigate('NewStory', params);
}

export function linkToNewStoryNavigation(
  navigation: NavigationProp,
  params: NewStoryNavigation.Params,
  screen = 'NewStory',
) {
  navigation.navigate('NewStoryNavigation', {screen, params});
}

export function linkToStory(navigation: NavigationProp, params: Story.Params) {
  navigation.navigate('Story', params);
}

export function replacePreviousStory(
  navigation: NavigationProp,
  params: Story.Params,
) {
  navigation.navigate('Story', params);
}

export function linkToEditStoryFormCoverImage(
  navigation: NavigationProp,
  params: EditStoryFormCoverImage.Params,
) {
  navigation.push('EditStoryFormCoverImage', params);
}

export function linkToNewStoryFormCoverImage(
  navigation: NavigationProp,
  params: NewStoryFormCoverImage.Params,
) {
  navigation.navigate('NewStoryFormCoverImage', params);
}

export function linkToEditStoryFormDetailInfo(
  navigation: NavigationProp,
  params: EditStoryFormDetailInfo.Params,
) {
  navigation.navigate('EditStoryFormDetailInfo', params);
}

export function linkToNewStoryFormDetailInfo(
  navigation: NavigationProp,
  params: NewStoryFormDetailInfo.Params,
) {
  navigation.navigate('NewStoryFormDetailInfo', params);
}

export function linkToEditStoryFormImage(
  navigation: NavigationProp,
  params: EditStoryFormImage.Params,
) {
  navigation.navigate('EditStoryFormImage', params);
}

export function linkToNewStoryFormImage(
  navigation: NavigationProp,
  params: NewStoryFormImage.Params,
) {
  navigation.navigate('NewStoryFormImage', params);
}

export function linkToStoryFormKeywords(
  navigation: NavigationProp,
  params: StoryFormKeywords.Params,
) {
  navigation.navigate('StoryFormKeywords', params);
}

export function linkToStoryFormMainGenre(
  navigation: NavigationProp,
  params: StoryFormMainGenre.Params,
) {
  navigation.navigate('StoryFormMainGenre', params);
}

export function linkToStoryFormSubGenres(
  navigation: NavigationProp,
  params: StoryFormSubGenres.Params,
) {
  navigation.navigate('StoryFormSubGenres', params);
}

export function linkToWriterTemporaryUserRegistrationCompletion(
  navigation: any,
  params: WriterTemporaryUserRegistrationCompletion.Params = {},
) {
  navigation.navigate('WriterUserRegistrationNavigation', {
    screen: 'WriterTemporaryUserRegistrationCompletion',
    params,
  });
}

export function linkToWriterUserAccount(
  navigation: NavigationProp,
  params: WriterUserAccount.Params = {},
) {
  navigation.navigate('WriterUserAccount', params);
}

export function linkToEditWriterUserRegistrationEmail(
  navigation: NavigationProp,
  params: EditWriterUserRegistrationEmail.Params = {},
) {
  navigation.navigate('EditWriterUserRegistrationEmail', params);
}

export function linkToEditWriterUserRegistrationPassword(
  navigation: NavigationProp,
  params: EditWriterUserRegistrationPassword.Params = {},
) {
  navigation.navigate('EditWriterUserRegistrationPassword', params);
}

export function linkToWriterUserRegistrationNavigation(
  navigation: any,
  params: WriterUserRegistrationNavigation.Params,
  screen = 'NewWriterUserRegistration',
) {
  navigation.navigate('WriterUserRegistrationNavigation', {screen, params});
}

export function linkToNewWriterUserRegistration(
  navigation: any,
  params: NewWriterUserRegistration.Params,
) {
  navigation.navigate('WriterUserRegistrationNavigation', {
    screen: 'NewWriterUserRegistration',
    params,
  });
}

export function replaceToNewWriterUserRegistration(
  navigation: any,
  params: NewWriterUserRegistration.Params,
) {
  navigation.replace('WriterUserRegistrationNavigation', {
    screen: 'NewWriterUserRegistration',
    params,
  });
}

export function linkToWriterUserSessionNavigation(
  navigation: any,
  params: WriterUserSessionNavigation.Params,
  screen = 'NewWriterUserSession',
) {
  navigation.navigate('WriterUserSessionNavigation', {screen, params});
}

export function linkToNewWriterUserSession(
  navigation: any,
  params: NewWriterUserSession.Params,
) {
  navigation.navigate('WriterUserSessionNavigation', {
    screen: 'NewWriterUserSession',
    params,
  });
}

export function replaceToNewWriterUserSession(
  navigation: NavigationProp,
  params: NewWriterUserSession.Params,
) {
  navigation.replace('WriterUserSessionNavigation', params);
}

export function linkToEditWriterUser(
  navigation: NavigationProp,
  params: EditWriterUser.Params,
) {
  navigation.navigate('EditWriterUser', params);
}

export function linkToNewWriterUserCancellation(
  navigation: NavigationProp,
  params: NewWriterUserCancellation.Params = {},
) {
  navigation.navigate('NewWriterUserCancellation', params);
}

export function replaceToEditWriterUser(
  navigation: NavigationProp,
  params: EditWriterUser.Params,
) {
  navigation.replace('EditWriterUser', params);
}

export function linkToSceneViewer(
  navigation: NavigationProp,
  params: SceneViewer.Params,
) {
  navigation.navigate('SceneViewer', params);
}

export function linkToPreviewSceneViewer(
  navigation: NavigationProp,
  params: PreviewSceneViewer.Params,
) {
  navigation.navigate('PreviewSceneViewer', params);
}

export function linkToEpisodeViewer(
  navigation: NavigationProp,
  params: EpisodeViewer.Params,
) {
  navigation.navigate('EpisodeViewer', params);
}

export function linkToAnnouncements(
  navigation: NavigationProp,
  params: Announcements.Params = {},
) {
  navigation.navigate('Announcements', params);
}

export function linkToAnnouncement(
  navigation: NavigationProp,
  params: Announcement.Params,
) {
  navigation.navigate('Announcement', params);
}

export function linkToHelps(
  navigation: NavigationProp,
  params: Helps.Params = {},
) {
  navigation.navigate('Helps', params);
}

export function linkToHelp(navigation: NavigationProp, params: Help.Params) {
  navigation.navigate('Help', params);
}

export function linkToManuals(
  navigation: NavigationProp,
  params: Manuals.Params = {},
) {
  navigation.navigate('Manuals', params);
}

export function linkToManual(
  navigation: NavigationProp,
  params: Manual.Params,
) {
  navigation.navigate('Manual', params);
}

export function linkToNewContact(
  navigation: NavigationProp,
  params: NewContact.Params = {},
) {
  navigation.navigate('NewContact', params);
}

export function linkToPoliciesPrivacy(
  navigation: NavigationProp,
  params: PoliciesPrivacy.Params = {},
) {
  navigation.navigate('PoliciesPrivacy', params);
}

export function linkToPoliciesPrivacyModal(
  navigation: NavigationProp,
  params: PoliciesPrivacy.Params = {},
) {
  navigation.navigate('PoliciesPrivacyModal', params);
}

export function linkToPoliciesTerms(
  navigation: NavigationProp,
  params: PoliciesTerms.Params = {},
) {
  navigation.navigate('PoliciesTerms', params);
}

export function linkToPoliciesTermsModal(
  navigation: NavigationProp,
  params: PoliciesTerms.Params = {},
) {
  navigation.navigate('PoliciesTermsModal', params);
}

export function linkToPoliciesSpecifiedCommercialTransactionLaw(
  navigation: NavigationProp,
  params: PoliciesSpecifiedCommercialTransactionLaw.Params = {},
) {
  navigation.navigate('PoliciesSpecifiedCommercialTransactionLaw', params);
}

export function linkToPoliciesSettlementLaw(
  navigation: NavigationProp,
  params: PoliciesSettlementLaw.Params = {},
) {
  navigation.navigate('PoliciesSettlementLaw', params);
}

export function linkToSettingsNotifications(
  navigation: NavigationProp,
  params: SettingsNotifications.Params = {},
) {
  navigation.navigate('SettingsNotifications', params);
}
