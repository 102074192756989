import * as React from 'react';

import BaseIndex from '../../common/sounds/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormSoundEffectShowSceneCommandEditSoundsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as SoundUsageHistoryUpdateParams} from '../../../../../actions/sound_usage_histories/update';
import {Params as SoundIndexParams} from '../../../../../actions/sounds/index';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import {QueryState} from '../../../../../reducers/queries/Response';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import Sound from '../../../../../../domain/entities/Sound';
import SoundCategory from '../../../../../../domain/entities/SoundCategory';
import SoundUsageHistory from '../../../../../../domain/entities/SoundUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  soundCategoryId?: number;
  selectedLargeSoundCategoryId?: number | null;
  selectedMiddleSoundCategoryId?: number | null;
  selectedSmallSoundCategoryId?: number | null;
  sceneCommandId: number;
  parentSceneCommandId?: number;
  callback?: (sound: Sound) => void;
  favorite?: boolean;
  sort?: 'popularity' | 'new_arrival';
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormSoundEffectShowSceneCommandEditSoundsRouteProp;
  sceneForm: SceneForm | null;
  soundsParams: SoundIndexParams;
  sounds: Sound[] | null;
  soundCategory: SoundCategory | null;
  sceneCommandForm: SceneCommandForm | null;
  parentSceneCommandForm: SceneCommandForm | null;
  soundQueries: QueryState;
  totalCount: number | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexSounds: (params: SoundIndexParams) => Promise<PaginatedResult<Sound>>;
  showSoundCategory: (id: number) => Promise<SoundCategory>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateSoundUsageHistory: (
    id: number,
    params: SoundUsageHistoryUpdateParams,
  ) => Promise<SoundUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedSound: Sound | null;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    const {route} = this.props;
    const {sort} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        sort={sort}
        onSelectSound={this.handleSelectSound}
        onPressSearchConditionChangeButton={
          this.handlePressSearchConditionChangeButton
        }
      />
    );
  }

  private handlePressSearchConditionChangeButton = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      query,
      selectedLargeSoundCategoryId,
      selectedMiddleSoundCategoryId,
      selectedSmallSoundCategoryId,
      sceneCommandId,
      parentSceneCommandId,
      favorite,
      callback,
    } = route.params;
    navigation.navigate(
      'SceneFormSoundEffectShowSceneCommandEditSoundSearchFormNavigations',
      {
        screen: 'SceneFormSoundEffectShowSceneCommandEditSoundSearchForm',
        params: {
          storyId,
          episodeId,
          sceneId,
          query,
          selectedLargeSoundCategoryId,
          selectedMiddleSoundCategoryId,
          selectedSmallSoundCategoryId,
          sceneCommandId,
          parentSceneCommandId,
          callback,
          favorite,
          hideBack: true,
        },
      } as any,
    );
  };

  private handleSelectSound = (sound: Sound): void => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      updateSceneCommandForm,
      updateSoundUsageHistory,
    } = this.props;
    const {storyId, sceneCommandId, callback} = route.params;
    const illustrationShowSceneCommandForm =
      this.fetchIllustrationShowSceneCommandFormFromParent();
    const fullScreenIllustrationShowSceneCommandForm =
      this.fetchFullScreenIllustrationShowSceneCommandFormFromParent();
    const effectShowSceneCommandForm =
      this.fetchEffectShowSceneCommandFormFromParent();
    const speechTextShowSceneCommandForm =
      this.fetchSpeechTextShowSceneCommandFormFromParent();
    const descriptiveTextShowSceneCommandForm =
      this.fetchDescriptiveTextShowSceneCommandFormFromParent();
    if (callback) {
      callback(sound);
    } else if (illustrationShowSceneCommandForm) {
      const sceneCommandForm = new IllustrationShowSceneCommandForm(
        illustrationShowSceneCommandForm.illustration,
        sound,
        null,
        null,
        sceneCommandId,
      );
      if (parentSceneCommandForm) {
        updateSceneCommandForm({
          sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            sceneCommandForm,
          ),
        });
      } else {
        updateSceneCommandForm({sceneCommandForm});
      }
    } else if (fullScreenIllustrationShowSceneCommandForm) {
      const sceneCommandForm = new FullScreenIllustrationShowSceneCommandForm(
        fullScreenIllustrationShowSceneCommandForm.fullScreenIllustration,
        sound,
        null,
        null,
        sceneCommandId,
      );
      if (parentSceneCommandForm) {
        updateSceneCommandForm({
          sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            sceneCommandForm,
          ),
        });
      } else {
        updateSceneCommandForm({sceneCommandForm});
      }
    } else if (effectShowSceneCommandForm) {
      const sceneCommandForm = new EffectShowSceneCommandForm(
        effectShowSceneCommandForm.effect,
        effectShowSceneCommandForm.position,
        effectShowSceneCommandForm.startIndex,
        effectShowSceneCommandForm.endIndex,
        sound,
        null,
        null,
        sceneCommandId,
      );
      if (parentSceneCommandForm) {
        updateSceneCommandForm({
          sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            sceneCommandForm,
          ),
        });
      } else {
        updateSceneCommandForm({sceneCommandForm});
      }
    } else if (speechTextShowSceneCommandForm) {
      const sceneCommandForm = new SpeechTextShowSceneCommandForm(
        speechTextShowSceneCommandForm.orientedSpeechBalloon,
        speechTextShowSceneCommandForm.text,
        speechTextShowSceneCommandForm.overrideCharacterName,
        speechTextShowSceneCommandForm.characterName,
        speechTextShowSceneCommandForm.voice,
        sound,
        null, // TODO
        null, // TODO
        sceneCommandId,
      );
      if (parentSceneCommandForm) {
        updateSceneCommandForm({
          sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            sceneCommandForm,
          ),
        });
      } else {
        updateSceneCommandForm({sceneCommandForm});
      }
    } else if (descriptiveTextShowSceneCommandForm) {
      const sceneCommandForm = new DescriptiveTextShowSceneCommandForm(
        descriptiveTextShowSceneCommandForm.textFrame,
        descriptiveTextShowSceneCommandForm.text,
        descriptiveTextShowSceneCommandForm.voice,
        sound,
        null, // TODO
        null, // TODO
        sceneCommandId,
      );
      if (parentSceneCommandForm) {
        updateSceneCommandForm({
          sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            sceneCommandForm,
          ),
        });
      } else {
        updateSceneCommandForm({sceneCommandForm});
      }
    } else {
      const sceneCommandForm = new SoundEffectShowSceneCommandForm(
        sound,
        null,
        null,
        sceneCommandId,
      );
      if (parentSceneCommandForm) {
        updateSceneCommandForm({
          sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            sceneCommandForm,
          ),
        });
      } else {
        updateSceneCommandForm({
          sceneCommandForm,
        });
      }
    }
    updateSoundUsageHistory(sound.id, {
      storyId,
      soundType: 'sound_effect',
    }).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };

  private fetchIllustrationShowSceneCommandFormFromParent =
    (): IllustrationShowSceneCommandForm | null => {
      const {sceneCommandForm, parentSceneCommandForm} = this.props;
      if (sceneCommandForm instanceof IllustrationShowSceneCommandForm) {
        return sceneCommandForm;
      }
      if (parentSceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
        const found = parentSceneCommandForm.commandForms.find(
          c => c instanceof IllustrationShowSceneCommandForm,
        );
        if (found instanceof IllustrationShowSceneCommandForm) {
          return found;
        }
      }
      return null;
    };

  private fetchFullScreenIllustrationShowSceneCommandFormFromParent =
    (): FullScreenIllustrationShowSceneCommandForm | null => {
      const {sceneCommandForm, parentSceneCommandForm} = this.props;
      if (
        sceneCommandForm instanceof FullScreenIllustrationShowSceneCommandForm
      ) {
        return sceneCommandForm;
      }
      if (parentSceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
        const found = parentSceneCommandForm.commandForms.find(
          c => c instanceof FullScreenIllustrationShowSceneCommandForm,
        );
        if (found instanceof FullScreenIllustrationShowSceneCommandForm) {
          return found;
        }
      }
      return null;
    };

  private fetchEffectShowSceneCommandFormFromParent =
    (): EffectShowSceneCommandForm | null => {
      const {sceneCommandForm, parentSceneCommandForm} = this.props;
      if (sceneCommandForm instanceof EffectShowSceneCommandForm) {
        return sceneCommandForm;
      }
      if (parentSceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
        const found = parentSceneCommandForm.commandForms.find(
          c => c instanceof EffectShowSceneCommandForm,
        );
        if (found instanceof EffectShowSceneCommandForm) {
          return found;
        }
      }
      return null;
    };

  private fetchSpeechTextShowSceneCommandFormFromParent =
    (): SpeechTextShowSceneCommandForm | null => {
      const {sceneCommandForm, parentSceneCommandForm} = this.props;
      if (sceneCommandForm instanceof SpeechTextShowSceneCommandForm) {
        return sceneCommandForm;
      }
      if (parentSceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
        const found = parentSceneCommandForm.commandForms.find(
          c => c instanceof SpeechTextShowSceneCommandForm,
        );
        if (found instanceof SpeechTextShowSceneCommandForm) {
          return found;
        }
      }
      return null;
    };

  private fetchDescriptiveTextShowSceneCommandFormFromParent =
    (): DescriptiveTextShowSceneCommandForm | null => {
      const {sceneCommandForm, parentSceneCommandForm} = this.props;
      if (sceneCommandForm instanceof DescriptiveTextShowSceneCommandForm) {
        return sceneCommandForm;
      }
      if (parentSceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
        const found = parentSceneCommandForm.commandForms.find(
          c => c instanceof DescriptiveTextShowSceneCommandForm,
        );
        if (found instanceof DescriptiveTextShowSceneCommandForm) {
          return found;
        }
      }
      return null;
    };
}
