import {Dispatch} from 'redux';

import Types from '../../../Types';

import {sendDefaultCustomEvent, setUser} from '../../../../helpers/analytics';

import NetRegistrationRepository from '../../../../../data/repositories/writer/users/NetRegistrationRepository';
import NetCurrentUserRepository from '../../../../../data/repositories/writer/NetCurrentUserRepository';
import AccessTokenRepository from '../../../../../data/repositories/StorageAccessTokenRepository';

export interface Params {
  reason?: string | null;
}

export default function action(
  params?: Params,
): (dispatch: Dispatch<any>) => Promise<void> {
  return async (dispatch: Dispatch<any>): Promise<void> => {
    const currentUser = await new NetCurrentUserRepository().find();
    await new NetRegistrationRepository().destroy(params);
    await new AccessTokenRepository().update('');
    dispatch({
      type: Types.ResetWriterCurrentUser,
    });
    sendDefaultCustomEvent({
      resourceType: 'writer/user',
      resourceId: currentUser.id,
      actionName: 'destroy',
    });
    setUser(null);
    return;
  };
}
