import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import TextFrameMapper, {
  TextFrameData,
} from '../entities/mappers/TextFrameMapper';

import TextFrame from '../../domain/entities/TextFrame';
import TextFramesRepository from '../../domain/repositories/TextFramesRepository';

export default class NetTextFramesRepository
  extends NetResourcesRepository<TextFrame, TextFrameData>
  implements TextFramesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/text_frames',
        new TextFrameMapper(),
      ),
    );
  }
}
