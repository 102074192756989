import * as React from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import FacebookLoginButton from './buttons/social_login/FacebookLoginButton';
import LineLoginButton from './buttons/social_login/LineLoginButton';
import TwitterLoginButton from './buttons/social_login/TwitterLoginButton';

import {colors} from '../../styles/variables';

import {
  ErrorData,
  SuccessData,
} from '../../../domain/services/social_logins/SocialLogin';

interface Props {
  onSuccessFacebookLogin: (data: SuccessData) => void;
  onErrorFacebookLogin: (data: ErrorData) => void;
  onSuccessTwitterLogin: (data: SuccessData) => void;
  onErrorTwitterLogin: (data: ErrorData) => void;
  onSuccessLineLogin: (data: SuccessData) => void;
  onErrorLineLogin: (data: ErrorData) => void;
  children?: React.ReactNode;
}

const SocialLoginButtons: React.FunctionComponent<Props> = props => {
  const {
    onSuccessLineLogin,
    onErrorLineLogin,
    onSuccessTwitterLogin,
    onErrorTwitterLogin,
    onSuccessFacebookLogin,
    onErrorFacebookLogin,
  } = props;
  return (
    <View style={styles.container}>
      <View style={styles.buttons}>
        <FacebookLoginButton
          onSuccess={onSuccessFacebookLogin}
          onError={onErrorFacebookLogin}
        />
        <LineLoginButton
          onSuccess={onSuccessLineLogin}
          onError={onErrorLineLogin}
        />
      </View>
    </View>
  );
};

export default React.memo(SocialLoginButtons);

const styles = StyleSheet.create({
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 124,
  } as ViewStyle,
  container: {
    alignItems: 'center',
    marginVertical: 16,
  } as ViewStyle,
  title: {
    color: colors.gray,
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center',
  } as TextStyle,
});
