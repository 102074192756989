import * as React from 'react';
import {Image} from 'react-native';

import convertImageSource from '../../../helpers/convertImageSource';

import logoUri from '../../../../assets/images/logo.png';

interface Props {
  width?: number;
  height?: number;
}

const Logo: React.FunctionComponent<Props> = props => {
  const {width, height} = props;
  return (
    <Image
      source={convertImageSource(logoUri)}
      style={{width: width || 155, height: height || 24}}
    />
  );
};

export default React.memo(Logo);
