import EntityMapper from '../EntityMapper';

import ActorCostume from '../../../../domain/entities/character_maker/ActorCostume';

export interface ActorCostumeData {
  id: number;
  actor_id: number;
  name: string;
  original_image_url: string;
}

export default class ActorCostumeMapper
  implements EntityMapper<ActorCostumeData, ActorCostume>
{
  public map(obj: ActorCostumeData): ActorCostume {
    return new ActorCostume(
      obj.id,
      obj.actor_id,
      obj.name,
      obj.original_image_url,
    );
  }
}
