import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

const CLASS_NAME: SceneCommandFormClass = 'CompositeSequenceSceneCommandForm';

export default class CompositeSequenceSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(
    public commandForms: SceneCommandForm[],
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      type: 'SceneCommands::CompositeSequenceSceneCommand',
      commands: this.commandForms.map(commandForm => commandForm.toParams()),
    };
  }

  public copy(sceneCommandId?: number): CompositeSequenceSceneCommandForm {
    return new CompositeSequenceSceneCommandForm(
      this.commandForms.map(commandForm => commandForm.copy()),
      sceneCommandId,
    );
  }
}
