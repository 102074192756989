import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../../components/scene_form/character_show_scene_command/new/character_form/default_character_patterns/Index';

import AppState from '../../../../../../reducers/AppState';

import indexActorCostumes, {
  Params as ActorCostumeIndexParams,
} from '../../../../../../actions/actor_costumes/index';
import indexActorHairStyles, {
  Params as ActorHairStyleIndexParams,
} from '../../../../../../actions/actor_hair_styles/index';
import indexActorAccessories, {
  Params as ActorAccessoryIndexParams,
} from '../../../../../../actions/actor_accessories/index';
import indexActorAccessorySets, {
  Params as ActorAccessorySetIndexParams,
} from '../../../../../../actions/actor_accessory_sets/index';

import selectEntity from '../../../../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../../../../helpers/ResourceAuthorizattion';
import {SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatternsRouteProp} from '../../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterFormDefaultCharacterPatternsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, forms} = state;
  const sceneForm = forms.sceneForm;
  const {actorId}: ActorCostumeIndexParams = route.params;
  const actor = selectEntity(entities.actors, actorId);
  return {
    sceneForm,
    actor,
    navigation,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexActorCostumes: (params: ActorCostumeIndexParams) =>
      dispatch(indexActorCostumes(params)),
    indexActorHairStyles: (params: ActorHairStyleIndexParams) =>
      dispatch(indexActorHairStyles(params)),
    indexActorAccessories: (params: ActorAccessoryIndexParams) =>
      dispatch(indexActorAccessories(params)),
    indexActorAccessorySets: (params: ActorAccessorySetIndexParams) => {
      return dispatch(indexActorAccessorySets(params));
    },
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
