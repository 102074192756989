import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
}

const SettingIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 20;
  const color = props.color || 'black';
  return <Icon name={'setting'} size={size} color={color} />;
};

export default React.memo(SettingIcon);
