import * as React from 'react';
import {ImageStyle, StyleSheet, View, ViewStyle} from 'react-native';

import NewEffectUsageHistoryButton from './NewEffectUsageHistoryButton';

import SvgUriAdapter from '../../../../../shared/svg/SvgUriAdapter';

import effectKeyVisualUri from '../../../../../../../assets/svgs/effect-key-visual.svg';

interface Props {
  onForwardToNewEffectUsageHistory: () => void;
}

const NoEffectUsageHistories: React.FunctionComponent<Props> = props => {
  const {onForwardToNewEffectUsageHistory} = props;
  return (
    <View style={styles.container}>
      <View style={styles.image}>
        <SvgUriAdapter uri={effectKeyVisualUri} width={100} height={130} />
      </View>
      <View style={styles.button}>
        <NewEffectUsageHistoryButton
          onPress={onForwardToNewEffectUsageHistory}
        />
      </View>
    </View>
  );
};

export default NoEffectUsageHistories;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  image: {
    height: 100,
    width: 130,
    margin: 16,
  } as ImageStyle,
  button: {
    margin: 16,
  },
});
