import EntityMapper from '../EntityMapper';

import UserResourcePurchase from '../../../../domain/entities/writer/UserResourcePurchase';

export interface UserResourcePurchaseData {
  id: number;
  resource_type: string;
  resource_id: number;
  resource_owner_user_id: number;
  created_at: string;
}

export default class UserResourcePurchaseMapper
  implements EntityMapper<UserResourcePurchaseData, UserResourcePurchase>
{
  public map(obj: UserResourcePurchaseData): UserResourcePurchase {
    return new UserResourcePurchase(
      obj.id,
      obj.resource_type,
      obj.resource_id,
      obj.resource_owner_user_id,
      new Date(obj.created_at),
    );
  }
}
