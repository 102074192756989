import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../components/analysis/stories/Show';

import AppState from '../../../reducers/AppState';

import showSummaryStoryAggregation, {
  Option as SummaryStoryAggregationShowOption,
} from '../../../actions/summary/story_aggregations/show';
import indexSummaryEpisodeAggregations, {
  Params as SummaryEpisodeAggregationIndexParams,
} from '../../../actions/summary/episode_aggregations/index';
import indexSummaryEpisodePageViewAggregations, {
  Params as SummaryEpisodePageViewAggregationIndexParams,
} from '../../../actions/summary/episode_page_view_aggregations/index';

import {AnalysisStoryRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: AnalysisStoryRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showSummaryStoryAggregation: (
      id: number,
      option: SummaryStoryAggregationShowOption,
    ) => dispatch(showSummaryStoryAggregation(id, option)),
    indexSummaryEpisodeAggregations: (
      params: SummaryEpisodeAggregationIndexParams,
    ) => dispatch(indexSummaryEpisodeAggregations(params)),
    indexSummaryEpisodePageViewAggregations: (
      params: SummaryEpisodePageViewAggregationIndexParams,
    ) => dispatch(indexSummaryEpisodePageViewAggregations(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
