import PositionMapper, {PositionData} from '../PositionMapper';

import MarkHideSceneCommand from '../../../../domain/value_objects/scene_commands/MarkHideSceneCommand';

export interface MarkHideSceneCommandData {
  type: string;
  position: PositionData;
}

export default class MarkHideSceneCommandMapper {
  private positionMapper: PositionMapper;

  constructor() {
    this.positionMapper = new PositionMapper();
  }

  public map(obj: MarkHideSceneCommandData): MarkHideSceneCommand {
    const position = this.positionMapper.map(obj.position);
    return new MarkHideSceneCommand(position);
  }
}
