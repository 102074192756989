import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import ContactCategory from '../../../../domain/entities/writer/ContactCategory';

export default function reducer(
  state: {[key: number]: ContactCategory} = {},
  action: AnyAction,
): {[key: number]: ContactCategory} {
  switch (action.type) {
    case Types.IndexWriterContactCategoriesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: ContactCategory}, record: ContactCategory) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    default:
      return state;
  }
}
