import * as React from 'react';
import {View} from 'react-native';

import DimensionContext from '../../../../../shared/dimension/DimensionContext';
import BaseEffectImageSequence from '../../../../../shared/BaseEffectImageSequence';

import {GLImageFilter} from '../../../../../../../vendor/react-native-tapnovel-viewer';

import Effect from '../../../../../../../domain/entities/Effect';

import {Filter} from '../../../../../../../domain/forms/SceneForm';

interface Props {
  uri?: string | null;
  filter?: Filter;
  selectedEffect: Effect | null;
}

const SelectedBackgroundEffectImage: React.FunctionComponent<Props> = props => {
  const {uri, filter, selectedEffect} = props;
  const urls =
    selectedEffect?.centerPositionedEffect.getMaxSizeFrameUrls() || [];
  return (
    <DimensionContext.Consumer>
      {context => {
        const {width} = context.content;
        const size = {width, height: width / aspectRatio};
        return (
          <View style={size}>
            {uri ? (
              <GLImageFilter size={size} filter={filter} uri={uri} />
            ) : (
              <View style={[size, {backgroundColor: 'black'}]} />
            )}
            <BaseEffectImageSequence
              key={`${selectedEffect?.id}`}
              id={selectedEffect?.id}
              urls={urls}
              size={size}
              loop={false}
            />
          </View>
        );
      }}
    </DimensionContext.Consumer>
  );
};

const aspectRatio = 1.25;

export default React.memo(SelectedBackgroundEffectImage);
