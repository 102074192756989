import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import FontColor from '../../../../domain/value_objects/FontColor';
import FontSize from '../../../../domain/value_objects/FontSize';
import WritingMode from '../../../../domain/value_objects/WritingMode';
import TextPosition from '../../../../domain/value_objects/TextPosition';

import File from '../../../../domain/entities/File';
import ImageTextInfo from '../../../../domain/value_objects/ImageTextInfo';

import {
  convertFontColor,
  convertFontSize,
} from '../../../../vendor/react-native-tapnovel-viewer/presentation/styles/variables';

interface Props {
  style?: ViewStyle;
  visible?: boolean;
  backgroundImageUri: string | null;
  characterImageUri?: string | null;
  width: number;
  height: number;
  imageTextInfoList: ImageTextInfo[];
  onDrawCanvas?: (file: File | null) => void;
}

export default abstract class PreviewBase extends React.Component<Props> {
  public render(): React.ReactNode {
    const {style} = this.props;
    return <View style={[styles.container, style]}>{this.renderCanvas()}</View>;
  }

  public abstract getImageFile: () => Promise<File | null>;

  protected abstract renderCanvas: () => React.ReactNode;

  protected abstract measureText: (
    context: CanvasRenderingContext2D | any,
    text: string,
  ) => Promise<TextMetrics>;

  protected setupContext = async (
    context: CanvasRenderingContext2D | any,
    options: {
      background?: {
        image: HTMLImageElement | any;
        naturalWidth: number;
        naturalHeight: number;
      };
      character?: {
        image: HTMLImageElement | any;
        naturalWidth: number;
        naturalHeight: number;
      };
    } = {},
  ) => {
    const {width, height, imageTextInfoList, onDrawCanvas} = this.props;
    if (Platform.OS === 'web') {
      context.resetTransform();
    }
    context.clearRect(0, 0, width * SCALE, height * SCALE);
    context.scale(SCALE, SCALE);
    context.fillStyle = '#cccccc';
    context.fillRect(0, 0, width * SCALE, height * SCALE);
    if (options.background) {
      const {sx, sy, sw, sh, dx, dy, dw, dh} = this.drawBackgroundImageRange(
        width,
        height,
        options.background.naturalWidth,
        options.background.naturalHeight,
      );
      context.drawImage(
        options.background.image,
        sx,
        sy,
        sw,
        sh,
        dx,
        dy,
        dw,
        dh,
      );
    }
    if (options.character) {
      const {sx, sy, sw, sh, dx, dy, dw, dh} = this.drawCharacterImageRange(
        width,
        height,
        options.character.naturalWidth,
        options.character.naturalHeight,
      );
      context.drawImage(
        options.character.image,
        sx,
        sy,
        sw,
        sh,
        dx,
        dy,
        dw,
        dh,
      );
    }
    for (let i = 0; i < imageTextInfoList.length; i++) {
      const imageTextInfo = imageTextInfoList[i];
      const {
        text,
        fontColor,
        fontFrameColor,
        fontSize,
        fontFamily,
        writingMode,
        textPosition,
      } = imageTextInfo;
      context.strokeStyle = convertFontColor(fontFrameColor) || 'black';
      context.fillStyle = convertFontColor(fontColor) || 'black';
      context.font = this.getContextFont(fontSize, fontFamily);
      context.textAlign = 'left';

      const m = await this.measureText(context, 'あ');
      const charWidth = m.width;
      let charHeight = this.getCharHeight(m, writingMode);
      if (fontFamily?.includes('Kosugi') && writingMode === 'vertical') {
        charHeight = charHeight * 1.2;
      }

      await this.renderText(
        writingMode,
        textPosition,
        text,
        fontFrameColor,
        width,
        height,
        charWidth,
        charHeight,
        context,
      );
    }
    if (onDrawCanvas) {
      setTimeout(async () => {
        const file = await this.getImageFile();
        onDrawCanvas(file);
      }, 500);
    }
  };

  protected getContextFont = (fontSize: FontSize, fontFamily?: string) => {
    return `${convertFontSize(fontSize) * 1.5}px '${fontFamily}'`;
  };

  private drawBackgroundImageRange = (
    width: number,
    height: number,
    naturalWidth: number,
    naturalHeight: number,
  ) => {
    if (width / height > naturalWidth / naturalHeight) {
      return {
        sx: 0,
        sy: (naturalHeight - naturalWidth * (height / width)) / 2,
        sw: naturalWidth,
        sh: naturalWidth * (height / width),
        dx: 0,
        dy: 0,
        dw: width,
        dh: height,
      };
    } else {
      return {
        sx: (naturalWidth - naturalHeight * (width / height)) / 2,
        sy: 0,
        sw: naturalHeight * (width / height),
        sh: naturalHeight,
        dx: 0,
        dy: 0,
        dw: width,
        dh: height,
      };
    }
  };

  private drawCharacterImageRange = (
    width: number,
    height: number,
    naturalWidth: number,
    naturalHeight: number,
  ) => {
    if (height > width) {
      const scale = 0.8;
      const scaledHeight = height * scale;
      const scaledWidth = (scaledHeight / naturalHeight) * naturalWidth;
      return {
        sx: 0,
        sy: 0,
        sw: naturalWidth,
        sh: naturalHeight,
        dx: (width - scaledWidth) / 2,
        dy: height - scaledHeight,
        dw: scaledWidth,
        dh: scaledHeight,
      };
    } else {
      const scale = 1.1;
      const scaledHeight = height * scale;
      const scaledWidth = (scaledHeight / naturalHeight) * naturalWidth;
      return {
        sx: 0,
        sy: 0,
        sw: naturalWidth,
        sh: naturalHeight,
        dx: (width - scaledWidth) / 2,
        dy: (scaledHeight - height) / 2,
        dw: scaledWidth,
        dh: scaledHeight,
      };
    }
  };

  private renderText = async (
    writingMode: WritingMode,
    textPosition: TextPosition,
    text: string,
    fontFrameColor: FontColor,
    width: number,
    height: number,
    charWidth: number,
    charHeight: number,
    context: CanvasRenderingContext2D,
  ) => {
    switch (writingMode) {
      case 'horizontal': {
        await this.renderHorizontalText(
          textPosition,
          await this.normalizeTextForHorizontalText(
            text,
            width,
            height,
            context,
          ),
          fontFrameColor,
          width,
          height,
          charWidth,
          charHeight,
          context,
        );
        break;
      }
      case 'vertical':
        await this.renderVerticalText(
          textPosition,
          await this.normalizeTextForVerticalText(text, width, height, context),
          fontFrameColor,
          width,
          height,
          charWidth,
          charHeight,
          context,
        );
        break;
    }
  };

  private renderHorizontalText = async (
    textPosition: TextPosition,
    text: string,
    fontFrameColor: FontColor,
    width: number,
    height: number,
    charWidth: number,
    charHeight: number,
    context: CanvasRenderingContext2D,
  ) => {
    const lines = text.split('\n');
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const {x, y} = await this.calcStartPointForHorizontalText(
        textPosition,
        text,
        line,
        width,
        height,
        charWidth,
        charHeight,
        context,
      );
      if (fontFrameColor !== FontColor.None) {
        context.strokeText(line, x, y + charHeight * i);
      }
      context.fillText(line, x, y + charHeight * i);
    }
  };

  private renderVerticalText = async (
    textPosition: TextPosition,
    text: string,
    fontFrameColor: FontColor,
    width: number,
    height: number,
    charWidth: number,
    charHeight: number,
    context: CanvasRenderingContext2D,
  ) => {
    const rowToColToSize = await this.getRowToColToSize(text, context);
    const lines = text.split('\n');
    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];
      const {x, y} = await this.calcStartPointForVerticalText(
        textPosition,
        text,
        line,
        width,
        height,
        charWidth,
        charHeight,
        context,
      );
      Array.prototype.forEach.call(line, (ch, j) => {
        const size = rowToColToSize[i][j];
        if ('{}()[]「」『』（）【】［］｛｝…─━ー=＝～〜｜|'.indexOf(ch) > -1) {
          context.textBaseline = 'bottom';
          context.rotate(Math.PI / 2);
          if (fontFrameColor !== FontColor.None) {
            context.strokeText(ch, y + charHeight * j, -(x - charWidth * i));
          }
          context.fillText(ch, y + charHeight * j, -(x - charWidth * i));
          context.rotate(-Math.PI / 2);
        } else {
          context.textBaseline = 'top';
          if (fontFrameColor !== FontColor.None) {
            context.strokeText(
              ch,
              x - charWidth * i + (charWidth - size.width) / 2,
              y + charHeight * j,
            );
          }
          context.fillText(
            ch,
            x - charWidth * i + (charWidth - size.width) / 2,
            y + charHeight * j,
          );
        }
      });
    }
  };

  private normalizeTextForHorizontalText = async (
    text: string,
    width: number,
    height: number,
    context: CanvasRenderingContext2D,
  ) => {
    const rowToColToSize = await this.getRowToColToSize(text, context);
    const ary: Array<string> = [];
    text.split('\n').map((line, rowNumber) => {
      let restWidth = width - MARGIN * 2;
      const chs: Array<string> = [];
      Array.prototype.map.call(line, (ch, colNumber) => {
        const charWidth = rowToColToSize[rowNumber][colNumber].width;
        if (restWidth < charWidth) {
          restWidth = width - MARGIN * 2;
          chs.push('\n');
        }
        restWidth -= charWidth;
        chs.push(ch);
      });
      if (chs.length > 0) {
        ary.push(chs.join(''));
      }
    });
    return ary.join('\n');
  };

  private normalizeTextForVerticalText = async (
    text: string,
    width: number,
    height: number,
    context: CanvasRenderingContext2D,
  ) => {
    const rowToColToSize = await this.getRowToColToSize(text, context);
    const ary: Array<string> = [];
    text.split('\n').map((line, rowNumber) => {
      let restHeight = height - MARGIN * 2;
      const chs: Array<string> = [];
      Array.prototype.map.call(line, (ch, colNumber) => {
        const charHeight = rowToColToSize[rowNumber][colNumber].height;
        if (restHeight < charHeight) {
          restHeight = height - MARGIN * 2;
          chs.push('\n');
        }
        restHeight -= charHeight;
        chs.push(ch);
      });
      if (chs.length > 0) {
        ary.push(chs.join(''));
      }
    });
    return ary.join('\n');
  };

  private calcStartPointForHorizontalText = async (
    textPosition: TextPosition,
    text: string,
    currentLine: string,
    width: number,
    height: number,
    charWidth: number,
    charHeight: number,
    context: CanvasRenderingContext2D,
  ) => {
    const maxTextHeight = text.split('\n').length * charHeight;
    const currentTextWidth = (await this.measureText(context, currentLine))
      .width;
    switch (textPosition) {
      case 'upper_left':
        context.textBaseline = 'top';
        return {x: MARGIN, y: MARGIN};
      case 'upper_center':
        context.textBaseline = 'top';
        return {x: (width - currentTextWidth) / 2, y: MARGIN};
      case 'upper_right':
        context.textBaseline = 'top';
        return {x: width - currentTextWidth - MARGIN, y: MARGIN};

      case 'middle_left':
        context.textBaseline = 'middle';
        return {x: MARGIN, y: (height - maxTextHeight + charHeight) / 2};
      case 'middle_center':
        context.textBaseline = 'middle';
        return {
          x: (width - currentTextWidth) / 2,
          y: (height - maxTextHeight + charHeight) / 2,
        };
      case 'middle_right':
        context.textBaseline = 'middle';
        return {
          x: width - currentTextWidth - MARGIN,
          y: (height - maxTextHeight + charHeight) / 2,
        };

      case 'bottom_left':
        context.textBaseline = 'bottom';
        return {x: MARGIN, y: height - maxTextHeight - MARGIN + charHeight};
      case 'bottom_center':
        context.textBaseline = 'bottom';
        return {
          x: (width - currentTextWidth) / 2,
          y: height - maxTextHeight - MARGIN + charHeight,
        };
      case 'bottom_right':
        context.textBaseline = 'bottom';
        return {
          x: width - currentTextWidth - MARGIN,
          y: height - maxTextHeight - MARGIN + charHeight,
        };
    }
  };

  private calcStartPointForVerticalText = async (
    textPosition: TextPosition,
    text: string,
    currentLine: string,
    width: number,
    height: number,
    charWidth: number,
    charHeight: number,
    context: CanvasRenderingContext2D,
  ) => {
    const maxTextWidth = text.split('\n').length * charWidth;
    const charHeights = (await Promise.all(
      Array.prototype.map.call(currentLine, async ch => {
        const m = await this.measureText(context, ch);
        return this.getCharHeight(m);
      }),
    )) as Array<number>;
    const currentTextHeight = charHeights.reduce((sum, val) => sum + val, 0);
    switch (textPosition) {
      case 'upper_left':
        return {x: maxTextWidth - charWidth + MARGIN, y: MARGIN};
      case 'upper_center':
        return {x: (width + maxTextWidth) / 2 - charWidth, y: MARGIN};
      case 'upper_right':
        return {x: width - charWidth - MARGIN, y: MARGIN};

      case 'middle_left':
        return {
          x: maxTextWidth - charWidth + MARGIN,
          y: (height - currentTextHeight) / 2,
        };
      case 'middle_center':
        return {
          x: (width + maxTextWidth) / 2 - charWidth,
          y: (height - currentTextHeight) / 2,
        };
      case 'middle_right':
        return {
          x: width - charWidth - MARGIN,
          y: (height - currentTextHeight) / 2,
        };

      case 'bottom_left':
        return {
          x: maxTextWidth - charWidth + MARGIN,
          y: height - currentTextHeight - MARGIN,
        };
      case 'bottom_center':
        return {
          x: (width + maxTextWidth) / 2 - charWidth,
          y: height - currentTextHeight - MARGIN,
        };
      case 'bottom_right':
        return {
          x: width - charWidth - MARGIN,
          y: height - currentTextHeight - MARGIN,
        };
    }
  };

  private getRowToColToSize = async (
    text: string,
    context: CanvasRenderingContext2D,
  ) => {
    const rowToColToSize: {
      [key: number]: {[key: number]: {width: number; height: number}};
    } = {};
    await Promise.all(
      text.split('\n').map(async (line, rowNumber) => {
        if (!rowToColToSize[rowNumber]) {
          rowToColToSize[rowNumber] = {};
        }
        await Promise.all(
          Array.prototype.map.call(line, async (ch, colNumber) => {
            const m = await context.measureText(ch);
            rowToColToSize[rowNumber][colNumber] = {
              width: this.getCharWidth(m),
              height: this.getCharHeight(m),
            };
          }),
        );
      }),
    );
    return rowToColToSize;
  };

  private getCharWidth = (m: TextMetrics) => {
    return m.width;
  };

  private getCharHeight = (m: TextMetrics, writingMode?: WritingMode) => {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (m.fontBoundingBoxAscent + m.fontBoundingBoxDescent) *
      (writingMode && writingMode === 'horizontal' ? 1 : 0.8)
    );
  };
}

const MARGIN = 16;

const styles = StyleSheet.create({
  container: {
    marginVertical: 32,
  } as ViewStyle,
});

export const SCALE = Platform.select({web: 3, default: 1});
