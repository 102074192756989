import * as React from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  StyleSheet,
  TextInput,
  TextInputSelectionChangeEventData,
  View,
  ViewStyle,
} from 'react-native';

import AttachmentSoundView from '../../../../../shared/sound/AttachmentSoundView';

import Tabs from '../tabs/Tabs';
import ConfirmButton from '../buttons/ConfirmButton';
import ImageGridList from '../lists/ImageGridList';
import PositionList from '../lists/PositionList';

import AddTextCommandLinksGroup from '../../../../../shared/AddFrequentlySymbolButtonsGroup';
import ElasticSpeechBalloonInput from '../../../../../shared/ElasticSpeechBalloonInput';
import ElasticBoxInputTextCounter from '../../../../../shared/ElasticBoxInputTextCounter';
import VoiceForm from '../../../../../shared/VoiceForm';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';
import UserStatusContext from '../../../../../shared/user_status/UserStatusContext';

import SceneFrame from '../../../../../../view_models/SceneFrame';
import NameLabelColor from '../../../../../../view_models/NameLabelColor';

import {validText} from '../../../../../../helpers/textInputHelper';

import SpeechTextShowSceneCommandForm from '../../../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import OrientedSpeechBalloon from '../../../../../../../domain/entities/OrientedSpeechBalloon';
import SpeechBalloon from '../../../../../../../domain/entities/SpeechBalloon';
import Voice from '../../../../../../../domain/entities/Voice';
import Sound from '../../../../../../../domain/entities/Sound';
import Position from '../../../../../../../domain/value_objects/Position';
import Gender from '../../../../../../../domain/value_objects/Gender';

export type TabValue = 'text' | 'speech_balloon' | 'position' | 'voice';

const TAB_ITEMS = [
  {value: 'text', label: '内容'},
  {value: 'speech_balloon', label: '吹き出し'},
  {value: 'position', label: '向き'},
  {value: 'voice', label: 'ボイス'},
] as Array<{value: TabValue; label: string}>;

interface Props {
  storyId: number;
  sceneCommandForm: SpeechTextShowSceneCommandForm;
  speechBalloons: SpeechBalloon[];
  orientedSpeechBalloons: OrientedSpeechBalloon[];
  sceneFrame: SceneFrame;
  enableVoice?: boolean;
  textInputRef: React.RefObject<TextInput>;
  currentTab: TabValue;
  selectedText: string;
  selectedCharacterName: string | null;
  selectedOrientedSpeechBalloon: OrientedSpeechBalloon;
  selectedSpeechBalloon: SpeechBalloon;
  selectedPositions: Position[];
  selectedVoice: Voice | null;
  selectedSound: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
  multiplePositions: boolean;
  selection?: {
    start: number;
    end: number;
  };
  onSubmit: () => void;
  onChangeTab: (currentTab: TabValue) => void;
  onSelectText: (selectedText: string) => void;
  onChangeName?: (text: string) => void;
  onPressSymbol: (symbol: string) => void;
  onSelectionChange: (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => void;
  onSelectSpeechBalloon: (selectedSpeechBalloon: SpeechBalloon) => void;
  onUpdateVoice: (voice: Voice | null) => void;
  onSelectPosition: (
    selectedPosition: Position,
    toggleMultiple?: boolean,
  ) => void;
  addSound: () => void;
  deleteSound: () => void;
  onChagenSoundRegion: (start?: number, end?: number) => void;
}

export default class SpeechTextViewWithEditor extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      storyId,
      speechBalloons,
      enableVoice,
      textInputRef,
      currentTab,
      selectedText,
      selectedPositions,
      selectedSpeechBalloon,
      selectedOrientedSpeechBalloon,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      selection,
      onSubmit,
      onChangeTab,
      onSelectText,
      onChangeName,
      onPressSymbol,
      onSelectionChange,
      onSelectSpeechBalloon,
      onSelectPosition,
      onUpdateVoice,
      addSound,
      deleteSound,
      onChagenSoundRegion,
    } = this.props;
    return (
      <UserStatusContext.Consumer>
        {userStatusContext => (
          <View style={styles.container}>
            <DimensionContext.Consumer>
              {context => (
                <View style={styles.speechText}>
                  <ElasticSpeechBalloonInput
                    textInputRef={textInputRef}
                    text={selectedText}
                    orientedSpeechBalloon={selectedOrientedSpeechBalloon}
                    width={Math.floor(context.content.width * ASPECT_RATIO)}
                    height={SPEECH_BALLOON_HEIGHT}
                    name={this.getCharacterName()}
                    nameLabelColor={this.getCharacterNameLabelColor()}
                    selection={selection}
                    hasVoice={!!selectedVoice}
                    onChangeName={onChangeName}
                    onChangeText={onSelectText}
                    onSelectionChange={onSelectionChange}
                  />
                  {(userStatusContext.currentUser?.enabledPaidSubscriber ||
                    userStatusContext.currentUser?.isGradeBlack()) && (
                    <View
                      style={{
                        position: 'absolute',
                        bottom: 16,
                        left: 50,
                        right: 50,
                      }}>
                      <AttachmentSoundView
                        style={{height: '100%'}}
                        sound={selectedSound}
                        startTime={selectedSoundStartTime}
                        endTime={selectedSoundEndTime}
                        addSound={addSound}
                        deleteSound={deleteSound}
                        onChagenRegion={onChagenSoundRegion}
                      />
                    </View>
                  )}
                </View>
              )}
            </DimensionContext.Consumer>
            <ElasticBoxInputTextCounter
              style={styles.textCounter}
              length={selectedText.length}
            />
            <Tabs<TabValue>
              items={TAB_ITEMS.filter(
                item => enableVoice || item.value !== 'voice',
              )}
              currentValue={currentTab}
              onChangeTab={onChangeTab}
            />
            <View style={styles.footer}>
              <View style={styles.commands}>
                <View
                  style={[
                    styles.addTextCommands,
                    currentTab === 'text' ? null : {display: 'none'},
                  ]}>
                  <AddTextCommandLinksGroup
                    buttonStyle={{width: 38, height: 36}}
                    onPressSymbol={onPressSymbol}
                  />
                </View>
                <ImageGridList
                  visible={currentTab === 'speech_balloon'}
                  entities={speechBalloons}
                  selectedEntity={selectedSpeechBalloon}
                  onSelectEntity={onSelectSpeechBalloon}
                />
                <PositionList
                  visible={currentTab === 'position'}
                  selectedPositions={selectedPositions}
                  onSelectPosition={onSelectPosition}
                />
                <VoiceForm
                  style={{margin: 16}}
                  visible={currentTab === 'voice'}
                  storyId={storyId}
                  voice={selectedVoice}
                  onUpdateVoice={onUpdateVoice}
                />
              </View>
              <View style={styles.button}>
                <ConfirmButton
                  disabled={
                    !userStatusContext.currentUser?.isGradeBlack() &&
                    !validText(selectedText)
                  }
                  onPress={onSubmit}
                />
              </View>
            </View>
          </View>
        )}
      </UserStatusContext.Consumer>
    );
  }

  private getCharacterName = (): string | undefined => {
    const {sceneCommandForm, selectedCharacterName, selectedPositions} =
      this.props;
    if (sceneCommandForm.overrideCharacterName) {
      return selectedCharacterName || '';
    }
    const characters = this.getCharacters();
    if (selectedPositions.length > 1) {
      return undefined;
    }
    if (characters.length > 1) {
      return undefined;
    }
    return characters.map(character => character.name)[0];
  };

  private getCharacterNameLabelColor = (): NameLabelColor | undefined => {
    const {sceneCommandForm, selectedPositions} = this.props;
    if (sceneCommandForm.overrideCharacterName) {
      return NameLabelColor.Black;
    }
    if (selectedPositions.length > 1) {
      return undefined;
    }
    const characters = this.getCharacters();
    if (characters) {
      if (characters.length === 1) {
        const character = characters[0];
        if (character.nameLabelColor) {
          return character.nameLabelColor;
        }
        switch (character.option.gender) {
          case Gender.Male:
            return NameLabelColor.Blue;
          case Gender.Female:
            return NameLabelColor.Pink;
          case Gender.Other:
            return NameLabelColor.Green;
          default:
          // noop
        }
      } else {
        return NameLabelColor.Green;
      }
    } else {
      return undefined;
    }
  };

  private getCharacters = () => {
    const {sceneFrame, selectedPositions} = this.props;
    const left = sceneFrame.leftCharacter;
    const center = sceneFrame.centerCharacter;
    const right = sceneFrame.rightCharacter;
    const ret = [];
    if (
      left &&
      sceneFrame.isActive(Position.Left) &&
      selectedPositions.includes(Position.Left)
    ) {
      ret.push(left.characterPattern.character);
    }
    if (
      center &&
      sceneFrame.isActive(Position.Center) &&
      selectedPositions.includes(Position.Center)
    ) {
      ret.push(center.characterPattern.character);
    }
    if (
      right &&
      sceneFrame.isActive(Position.Right) &&
      selectedPositions.includes(Position.Right)
    ) {
      ret.push(right.characterPattern.character);
    }
    return ret;
  };
}

const ASPECT_RATIO = 290 / 375;

const CONTENT_HEIGHT = 220;

const SPEECH_BALLOON_HEIGHT = 180;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  } as ViewStyle,
  speechText: {
    height: CONTENT_HEIGHT,
    marginVertical: 15,
    justifyContent: 'center',
    alignItems: 'center',
    ...Platform.select({
      web: {width: '100%'},
      default: {},
    }),
  } as ViewStyle,
  footer: {
    backgroundColor: 'white',
    width: '100%',
  } as ViewStyle,
  addTextCommands: {
    height: 70,
    paddingHorizontal: 6,
    width: '100%',
  } as ViewStyle,
  commands: {
    height: 183,
    width: '100%',
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  textCounter: {
    width: '100%',
    height: 20,
    marginTop: -8,
    marginBottom: 8,
    marginHorizontal: 0,
    paddingHorizontal: 48,
  } as ViewStyle,
});
