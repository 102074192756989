import * as React from 'react';
import {StatusBar} from 'react-native';

import MenuCommands from '../shared/viewer/MenuCommands';

import NavigationProp from '../../navigators/NavigationProp';
import {SceneViewerRouteProp} from '../../navigators/RouteProps';

import StorageViewerSettingsRepository from '../../../data/repositories/StorageViewerSettingsRepository';

import {WriterSceneViewer} from '../../../vendor/react-native-tapnovel-viewer';

const orientation = 'vertical';

export interface Params {
  sceneId: number | string;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneViewerRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

interface State {
  visibleProgressBar: boolean;
  textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect';
  enabledSound: boolean;
  autoPlaySpeed: 0 | 1 | 1.5 | 2;
}

export default class Show extends React.PureComponent<Props, State> {
  private viewerSettingsRepository: StorageViewerSettingsRepository =
    new StorageViewerSettingsRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      visibleProgressBar: true,
      textSpeed: 'no_effect',
      enabledSound: true,
      autoPlaySpeed: 0,
    };
  }

  public async componentDidMount(): Promise<void> {
    StatusBar.setHidden(true);
    const viewerSetting = await this.viewerSettingsRepository.find();
    const visibleProgressBar = viewerSetting.visibleProgressBar;
    const textSpeed = viewerSetting.textSpeed;
    const enabledSound =
      viewerSetting.enabledSound === undefined
        ? true
        : viewerSetting.enabledSound;
    const autoPlaySpeed =
      viewerSetting.autoPlaySpeed === undefined
        ? 1
        : viewerSetting.autoPlaySpeed;
    this.setState({
      visibleProgressBar,
      textSpeed,
      enabledSound,
      autoPlaySpeed,
    });
  }

  public componentWillUnmount(): void {
    StatusBar.setHidden(false);
  }

  public render(): React.ReactNode {
    const {route} = this.props;
    const {visibleProgressBar, textSpeed, enabledSound, autoPlaySpeed} =
      this.state;
    const sceneId = Number(route.params.sceneId);
    return (
      <WriterSceneViewer.default
        sceneId={sceneId}
        visibleProgressBar={visibleProgressBar}
        textSpeed={textSpeed}
        enabledSound={enabledSound}
        autoPlaySpeed={autoPlaySpeed}
        orientation={orientation}
        renderMenuCommands={this.renderMenuCommands}
        onRequestClose={this.handleRequestClose}
        onVisibleProgressBarChange={this.handleVisibleProgressBarChange}
        onTextSpeedChange={this.handleTextSpeedChange}
        onAutoPlaySpeedChange={this.handleAutoPlaySpeedChange}
        onEnabledSoundChange={this.handleEnabledSoundChange}
      />
    );
  }

  private renderMenuCommands = (): React.ReactNode => {
    return <MenuCommands onPressFinish={this.handleRequestClose} />;
  };

  private handleRequestClose = () => {
    const {navigation} = this.props;
    (navigation.getParent() || navigation).goBack();
  };

  private handleVisibleProgressBarChange = (visibleProgressBar: boolean) => {
    this.setState({visibleProgressBar});
    this.viewerSettingsRepository.update({visibleProgressBar});
  };

  private handleTextSpeedChange = (
    textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect',
  ) => {
    this.setState({textSpeed});
    this.viewerSettingsRepository.update({textSpeed});
  };

  private handleAutoPlaySpeedChange = (autoPlaySpeed: 0 | 1 | 1.5 | 2) => {
    this.setState({autoPlaySpeed});
    this.viewerSettingsRepository.update({autoPlaySpeed});
  };

  private handleEnabledSoundChange = (enabledSound: boolean) => {
    this.setState({enabledSound});
    this.viewerSettingsRepository.update({enabledSound});
  };
}
