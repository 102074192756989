import convertImageSource from './convertImageSource';

import fullScreenIllustrationUri from '../../assets/images/extensions/full_screen_illustration.png';
import backgroundMusicUri from '../../assets/images/extensions/background_music.png';
import soundEffectUri from '../../assets/images/extensions/sound_effect.png';
import voiceUri from '../../assets/images/extensions/voice.png';
import commercialVideoUri from '../../assets/images/extensions/commercial_video.png';
import analysisUri from '../../assets/images/extensions/analysis.png';
import partnerProgramUri from '../../assets/images/extensions/partner_program.png';

export const getImageSource = (code: string) => {
  const uri = getImageSourceUri(code);
  if (!uri) {
    return undefined;
  } else {
    return convertImageSource(uri);
  }
};

const getImageSourceUri = (code: string) => {
  switch (code) {
    case 'full_screen_illustration':
      return fullScreenIllustrationUri;
    case 'background_music':
      return backgroundMusicUri;
    case 'sound_effect':
      return soundEffectUri;
    case 'voice':
      return voiceUri;
    case 'commercial_video':
      return commercialVideoUri;
    case 'analysis':
      return analysisUri;
    case 'partner_program':
      return partnerProgramUri;
  }
};
