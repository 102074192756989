import {Dispatch} from 'redux';

import Types from '../Types';

import EffectUsageHistory from '../../../domain/entities/EffectUsageHistory';

import NetEffectUsageHistoriesRepository from '../../../data/repositories/writer/NetEffectUsageHistoriesRepository';

export interface Params {
  storyId: number;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<EffectUsageHistory> {
  return async (dispatch: Dispatch<any>): Promise<EffectUsageHistory> => {
    try {
      const payload = await new NetEffectUsageHistoriesRepository().update(
        id,
        params,
      );
      dispatch({
        payload,
        type: Types.UpdateEffectUsageHistorySuccess,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateEffectUsageHistoryFailure});
      throw err;
    }
  };
}
