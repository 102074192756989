import * as React from 'react';

import Tip from '../../../../../domain/entities/Tip';

import SmileIcon from '../../../shared/icons/support/SmileIcon';
import CandyIcon from '../../../shared/icons/support/CandyIcon';
import OnigiriIcon from '../../../shared/icons/support/OnigiriIcon';
import RamenIcon from '../../../shared/icons/support/RamenIcon';

interface Props {
  tip: Tip;
  size: number;
}

const TipIcon: React.FunctionComponent<Props> = props => {
  const {tip} = props;
  const size = props.size;
  switch (tip.id) {
    case 1:
      return <SmileIcon size={size} />;
    case 2:
      return <CandyIcon size={size} />;
    case 3:
      return <OnigiriIcon size={size} />;
    case 4:
      return <RamenIcon size={size} />;
  }
  return null;
};

export default React.memo(TipIcon);
