import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import LabelAndCheck from './LabelAndCheck';

interface Props {
  style?: StyleProp<ViewStyle>;
  checked: boolean;
  messageText?: string;
  labelText?: string;
  renderHeader?: React.ReactNode;
  onPress: () => void;
}

const MaterialRightCheck: React.FunctionComponent<Props> = props => {
  const {style, checked, messageText, labelText, renderHeader, onPress} = props;
  return (
    <LabelAndCheck
      style={style}
      checked={checked}
      messageText={
        messageText || '許可なく他人の著作物を掲載することはできません。'
      }
      labelText={labelText || '本人の著作物もしくは許可を得た画像です'}
      renderHeader={renderHeader}
      onPress={onPress}
    />
  );
};

export default React.memo(MaterialRightCheck);
