import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {camelizeKeys} from 'humps';
import numeral from 'numeral';

import Layout from '../shared/Layout';
import PrimaryButton from '../shared/buttons/PrimaryButton';

import NavigationProp from '../../navigators/NavigationProp';

import UserPoint from '../../../domain/entities/writer/UserPoint';
import TransferRequest from '../../../domain/entities/TransferRequest';

import TapNovelRestApi from '../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation} = props;
  const [userPoint, setUserPoint] = React.useState<UserPoint | null>(null);
  const [transferRequest, setTransferRequest] =
    React.useState<TransferRequest | null>(null);
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    TapNovelRestApi.get('/api/writer/user_identification').then(response => {
      if (response.body) {
        TapNovelRestApi.get('/api/writer/bank_account').then(response => {
          if (response.body) {
            linkTo('/transfer/requests/new');
          } else {
            linkTo('/settings/bank_account?from=transfer');
          }
        });
      } else {
        linkTo('/settings/user_identification?from=transfer');
      }
    });
  }, []);
  React.useEffect(() => {
    TapNovelRestApi.get('/api/writer/transfer_request').then(response => {
      setTransferRequest(camelizeKeys(response.body) as TransferRequest);
    });
    TapNovelRestApi.get('/api/writer/user_point').then(response => {
      setUserPoint(camelizeKeys(response.body) as UserPoint);
    });
  }, []);
  return (
    <Layout
      title={'振込申請'}
      navigation={navigation}
      back={true}
      scrollViewStyle={styles.container}>
      {transferRequest && (
        <>
          <Text style={styles.label}>保有ポイント</Text>
          <View style={styles.box}>
            <Text style={styles.value}>
              {numeral(userPoint?.point).format('0,0')}{' '}
              <Text style={styles.unit}>pt</Text>
            </Text>
          </View>
          <View style={styles.submit}>
            <PrimaryButton
              disabled={!transferRequest.available}
              onPress={onPress}>
              {transferRequest.requested ? '申請中' : '振込申請する'}
            </PrimaryButton>
            <Text style={styles.note}>
              ※振込申請は
              {numeral(transferRequest.amountMin).format('0,0')}
              ptから行うことができます。
            </Text>
          </View>
        </>
      )}
    </Layout>
  );
};

export default React.memo(Index);

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: '#fafafa',
  } as ViewStyle,
  label: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
  box: {
    backgroundColor: '#fff',
    borderRadius: 3,
    height: 65,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  value: {
    color: '#383838',
    fontSize: 25,
    fontWeight: 'bold',
  } as TextStyle,
  unit: {
    fontSize: 20,
  } as TextStyle,
  submit: {
    marginTop: 30,
    alignItems: 'center',
  } as ViewStyle,
  note: {
    color: '#383838',
    fontSize: 11,
    marginTop: 15,
  } as TextStyle,
});
