export type SceneCommandFormClass =
  | 'BackgroundMusicHideSceneCommandForm'
  | 'BackgroundMusicShowSceneCommandForm'
  | 'BackgroundShowSceneCommandForm'
  | 'CharacterHideSceneCommandForm'
  | 'CharacterShowSceneCommandForm'
  | 'CharacterUpdateSceneCommandForm'
  | 'CompositeParallelSceneCommandForm'
  | 'CompositeSequenceSceneCommandForm'
  | 'DescriptiveTextShowSceneCommandForm'
  | 'EffectShowSceneCommandForm'
  | 'FullScreenIllustrationShowSceneCommandForm'
  | 'IllustrationShowSceneCommandForm'
  | 'SoundEffectShowSceneCommandForm'
  | 'SpeechTextShowSceneCommandForm';

abstract class SceneCommandForm {
  public readonly sceneCommandId: number;

  public abstract className: SceneCommandFormClass;

  constructor(sceneCommandId?: number) {
    this.sceneCommandId = sceneCommandId || this.generateUniqueId();
  }
  public abstract toParams(): {[key: string]: any};

  public abstract copy(sceneCommandId?: number): SceneCommandForm;

  private generateUniqueId(): number {
    const randam = Math.floor(Math.random() * 100000);
    const date = new Date();
    const time = date.getTime();
    return Number(randam + time.toString());
  }
}

export default SceneCommandForm;
