import {Dispatch} from 'redux';

import Types from '../Types';

import NetEpisodeCommentRepliesRepository from '../../../data/repositories/writer/NetEpisodeCommentRepliesRepository';

export default function action(
  id: number,
): (dispatch: Dispatch<any>) => Promise<number> {
  return async (dispatch: Dispatch<any>): Promise<number> => {
    try {
      await new NetEpisodeCommentRepliesRepository().destroy(id);
      const payload = id;
      dispatch({payload, type: Types.DestroyEpisodeCommentReplySuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.DestroyEpisodeCommentReplyFailure});
      throw err;
    }
  };
}
