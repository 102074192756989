import * as React from 'react';
import {
  ImageStyle,
  Text,
  TextStyle,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import CommentLikeButton from './CommentLikeButton';

import MemberImage from '../../members/partials/MemberImage';

import {formatElapsedTime} from '../../../../helpers/times';

import ProjectManuscriptComment from '../../../../../domain/entities/ProjectManuscriptComment';
import ProjectManuscriptCommentLike from '../../../../../domain/entities/ProjectManuscriptCommentLike';

interface Props {
  comment: ProjectManuscriptComment;
  commentLike: ProjectManuscriptCommentLike | null;
}

const CommentListItem: React.FC<Props> = props => {
  const {comment, commentLike} = props;
  const member = comment.projectMember;
  const liked = true;
  return (
    <View style={styles.container}>
      <View style={styles.imageWrapper}>
        <MemberImage member={member} imageSize={IMAGE_SIZE} />
      </View>
      <View style={styles.right}>
        <View style={styles.rightTop}>
          <Text style={styles.penName}>{member.writerUser.penName}</Text>
          {member.leader ? (
            <View style={styles.leaderLabel}>
              <Text style={styles.leaderText}>リーダー</Text>
            </View>
          ) : null}
        </View>
        <Text style={styles.date}>
          {formatElapsedTime(new Date(comment.createdAt))}
        </Text>
        <View style={styles.triangle} />
        <View style={styles.commentFrame}>
          <Text style={styles.comment}>{comment.comment}</Text>
        </View>
        <CommentLikeButton comment={comment} commentLike={commentLike} />
      </View>
    </View>
  );
};

export default React.memo(CommentListItem);

const IMAGE_SIZE = 50;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 9,
    marginHorizontal: 16,
  } as ViewStyle,
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMAGE_SIZE / 2,
    backgroundColor: '#ffffff',
    height: IMAGE_SIZE,
    overflow: 'hidden',
    width: IMAGE_SIZE,
  } as ViewStyle,
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  } as ImageStyle,
  right: {
    flex: 1,
    paddingTop: 3,
    paddingHorizontal: 10,
  } as ViewStyle,
  rightTop: {
    flexDirection: 'row',
  } as ViewStyle,
  penName: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  leaderLabel: {
    marginLeft: 6,
  } as ViewStyle,
  leaderText: {
    color: '#fff',
    fontSize: 8,
    fontWeight: 'bold',
    backgroundColor: '#ff8f13',
    padding: 2,
  } as TextStyle,
  date: {
    color: '#999',
    fontSize: 10,
    marginVertical: 6,
  } as TextStyle,
  triangle: {
    width: 7,
    borderLeftWidth: 7,
    borderLeftColor: 'transparent',
    borderRightWidth: 7,
    borderRightColor: 'transparent',
    borderBottomWidth: 7,
    borderBottomColor: '#efefef',
    marginLeft: 12,
  } as ViewStyle,
  commentFrame: {
    backgroundColor: '#efefef',
    borderRadius: 3,
    paddingTop: 5,
    paddingBottom: 12,
    paddingHorizontal: 10,
  } as ViewStyle,
  comment: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
});
