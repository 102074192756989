import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Edit, {
  DispatchProps,
  StateProps,
} from '../../../../components/writer/user_registrations/passwords/Edit';

import AppState from '../../../../reducers/AppState';

import updateConsumerUserRegistration, {
  Params as ConsumerUserRegistrationUpdateParams,
} from '../../../../actions/writer/users/registration/update';

import selectEntity from '../../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  return {
    currentUser,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  props: Props,
) => {
  return {
    updateConsumerUserRegistration: (
      params: ConsumerUserRegistrationUpdateParams,
    ) => dispatch(updateConsumerUserRegistration(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
