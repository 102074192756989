import {Dispatch} from 'redux';

import Types from '../Types';

import CoachmarkState from '../../view_models/CoachmarkState';

export interface Params {
  value: CoachmarkState | null;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => void {
  return (dispatch: Dispatch<any>): void => {
    dispatch({payload: params.value, type: Types.UpdateCoachmarkModal});
  };
}
