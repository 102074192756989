import * as React from 'react';

import GroupedNotificationSettingList from './partials/GroupedNotificationSettingList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {Params as WriterUserNotificationSettingsBatchUpdateParams} from '../../../actions/writer/user_notification_settings_batch/update';

import WriterUserNotificationSettingsBatch, {
  WriterUserNotificationSetting,
} from '../../../../domain/entities/writer/UserNotificationSettingsBatch';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  writerUserNotificationSettingsBatch: WriterUserNotificationSettingsBatch | null;
}

export interface DispatchProps {
  showWriterUserNotificationSettingsBatch: () => Promise<WriterUserNotificationSettingsBatch>;
  updateWriterUserNotificationSettingsBatch: (
    params: WriterUserNotificationSettingsBatchUpdateParams,
  ) => Promise<WriterUserNotificationSettingsBatch>;
}

interface Props extends StateProps, DispatchProps {}

interface State {}

export default class Index extends React.PureComponent<Props, State> {
  public componentDidMount() {
    const {navigation} = this.props;
    const {
      writerUserNotificationSettingsBatch,
      showWriterUserNotificationSettingsBatch,
    } = this.props;
    if (!writerUserNotificationSettingsBatch) {
      showWriterUserNotificationSettingsBatch().catch(e => {
        if (e.status === 401 || e.status === 404) {
          (navigation.getParent() || navigation).goBack();
          navigation.navigate('WriterUserSessionNavigation', {});
        }
      });
    }
  }

  public render(): React.ReactNode {
    const {navigation, writerUserNotificationSettingsBatch} = this.props;
    return (
      <Layout title={'通知設定'} navigation={navigation} back={true}>
        {writerUserNotificationSettingsBatch && (
          <GroupedNotificationSettingList
            writerUserNotificationSettingsBatch={
              writerUserNotificationSettingsBatch
            }
            onValueChangeWriterUserNotificationSetting={
              this.handleValueChangeConsumerUserNotificationSetting
            }
          />
        )}
      </Layout>
    );
  }

  private handleValueChangeConsumerUserNotificationSetting = (
    writerUserNotificationSetting: WriterUserNotificationSetting,
    enabled: boolean,
  ) => {
    const {updateWriterUserNotificationSettingsBatch} = this.props;
    const writerNotificationSettingId =
      writerUserNotificationSetting.writerNotificationSetting.id;
    updateWriterUserNotificationSettingsBatch({
      writerUserNotificationSettings: [
        {
          enabled,
          writerNotificationSettingId,
        },
      ],
    });
  };
}
