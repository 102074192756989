import * as React from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import Cell from './Cell';

interface Props {
  labels: {0: string; 1: string; 2: string};
}

const Header: React.FunctionComponent<Props> = props => {
  const {labels} = props;
  return (
    <View style={styles.container}>
      <Cell style={styles.cell1} label={labels['0']} />
      <Cell style={styles.cell2} label={labels['1']} />
      <Cell
        style={styles.cell3}
        label={labels['2']}
        labelStyle={styles.label3}
      />
    </View>
  );
};

export default React.memo(Header);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
  cell1: {
    width: 110,
    backgroundColor: undefined,
    borderBottomWidth: 0,
    borderTopColor: 'transparent',
    borderTopWidth: 1,
  } as ViewStyle,
  cell2: {
    backgroundColor: '#efefef',
    borderBottomWidth: 0,
    borderTopWidth: 1,
  } as ViewStyle,
  cell3: {
    backgroundColor: '#ffe5c8',
    borderBottomWidth: 0,
    borderTopWidth: 1,
  } as ViewStyle,
  label3: {
    color: '#ff8f13',
  } as TextStyle,
});
