import React from 'react';

import RootStack from '../../../../RootStack';
import {DefaultTransition} from '../../../../BaseNavigator';

import CharacterFormNewActorSearchForm from '../../../../../containers/character_form/new/actor_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="CharacterFormNewActorSearchForm">
      <RootStack.Screen
        name="CharacterFormNewActorSearchForm"
        component={CharacterFormNewActorSearchForm}
        options={{title: 'キャラクター検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
