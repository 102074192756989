import * as React from 'react';
import {
  Image,
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import NoFlickerImage from '../no_flicker_image/NoFlickerImage';

interface Entity {
  id: number;
  getOneThirdImageUrl: () => string;
  getLabel: () => string;
}

interface Props<T extends Entity> {
  entity: T;
  selected: boolean;
  width: number;
  clipped: boolean;
  inverted?: boolean;
  zoom?: number;
  top?: number;
  centeringByTop?: boolean;
  foregroundImageUris?: string[];
  backgroundImageUris?: string[];
  onSelectEntity: (entity: T) => void;
  extractLabel?: (entity: T) => string;
  extractTopForegroundImageUris?: (entity: T) => string[];
}

type EntityListItemComponent = <T extends Entity>(
  props: Props<T>,
) => React.ReactElement<Props<T>>;

const EntityListItem: EntityListItemComponent = props => {
  const {
    entity,
    selected,
    clipped,
    width,
    inverted,
    zoom: customZoom,
    top,
    centeringByTop,
    foregroundImageUris,
    backgroundImageUris,
    onSelectEntity,
    extractLabel,
    extractTopForegroundImageUris,
  } = props;
  const imageWrapperHeight = width / (clipped ? 1 : ASPECT_RATIO);
  const zoom = customZoom || (clipped ? IMAGE_ZOOM : 1);
  const uri = entity.getOneThirdImageUrl();
  const offsetImageWrapperHeight = centeringByTop && top ? top : 0;

  const handleSelectEntity = React.useCallback(() => {
    onSelectEntity(entity);
  }, [entity, onSelectEntity]);
  const imageStyle = {
    width: width * zoom,
    height: imageWrapperHeight * zoom,
    top,
    position: 'absolute',
  } as ImageStyle;
  const topForegroundImageUris = extractTopForegroundImageUris
    ? extractTopForegroundImageUris(entity)
    : null;
  return (
    <Pressable onPress={handleSelectEntity}>
      <View
        style={[
          styles.container,
          {
            width,
            height:
              imageWrapperHeight + LABEL_HEIGHT + offsetImageWrapperHeight,
          },
        ]}>
        <View
          style={[
            styles.imageWrapper,
            selected ? styles.imageWrapperActive : null,
            {width, height: imageWrapperHeight + offsetImageWrapperHeight},
            inverted ? styles.inverted : null,
          ]}>
          {backgroundImageUris?.map(backgroundImageUri => (
            <NoFlickerImage
              style={imageStyle}
              source={{uri: backgroundImageUri}}
              resizeMode={'contain'}
            />
          ))}
          <Image style={imageStyle} resizeMode={'contain'} source={{uri}} />
          {foregroundImageUris?.map(foregroundImageUri => (
            <NoFlickerImage
              style={imageStyle}
              source={{uri: foregroundImageUri}}
              resizeMode={'contain'}
            />
          ))}
          {topForegroundImageUris?.map(foregroundImageUri => (
            <NoFlickerImage
              style={imageStyle}
              source={{uri: foregroundImageUri}}
              resizeMode={'contain'}
            />
          ))}
        </View>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>
            {(extractLabel ? extractLabel(entity) : entity.getLabel()) ||
              'なし'}
          </Text>
        </View>
      </View>
    </Pressable>
  );
};

export default React.memo(EntityListItem) as typeof EntityListItem;

const ASPECT_RATIO = 0.7;

const LABEL_HEIGHT = 20;

const IMAGE_ZOOM = 2.5;

const styles = StyleSheet.create({
  container: {
    margin: 2,
  } as ViewStyle,
  imageWrapper: {
    alignItems: 'center',
    borderWidth: 2,
    borderRadius: 4,
    borderColor: 'transparent',
    overflow: 'hidden',
  } as ViewStyle,
  imageWrapperActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
  labelWrapper: {
    marginVertical: 4,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  label: {} as TextStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ViewStyle,
});
