import * as React from 'react';
import {Animated, Easing, Platform, StyleSheet, ViewStyle} from 'react-native';

import DimensionContext from '../../../../../shared/dimension/DimensionContext';

interface Props extends React.PropsWithChildren {
  active: boolean;
}

export default class CharactersStagePosition extends React.PureComponent<Props> {
  private scaleValue = new Animated.Value(0);

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (this.props.active === prevProps.active) {
      return;
    }
    const animationConfig = {
      duration: 50,
      easing: Easing.linear,
      toValue: this.props.active ? 1 : 0,
      useNativeDriver: true,
    };
    Animated.timing(this.scaleValue, animationConfig).start();
  }

  public render(): React.ReactNode {
    const {children} = this.props;
    const scale = this.scaleValue.interpolate({
      inputRange: [0, 1],
      outputRange: [1, 1.1],
    });
    return (
      <DimensionContext.Consumer>
        {context => (
          <Animated.View
            style={[
              styles.stage,
              {
                width: (context.content.width - 5 * 2) / 3,
                transform: [{scale}],
              },
            ]}>
            {children}
          </Animated.View>
        )}
      </DimensionContext.Consumer>
    );
  }
}

const styles = StyleSheet.create({
  stage: {
    alignItems: 'center',
    flex: 1,
  } as ViewStyle,
});
