import {Dispatch} from 'redux';

import NetPasswordRepository from '../../../../../data/repositories/writer/users/NetPasswordRepository';

export interface Params {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Record<string, unknown>> {
  return async (dispatch: Dispatch<any>): Promise<Record<string, unknown>> => {
    const payload = await new NetPasswordRepository().update({
      apiWriterUser: params,
    });
    return payload;
  };
}
