import * as React from 'react';
import styled from 'styled-components';

import Footer from './Footer';
import Header from './Header';

import DimensionContext from '../dimension/DimensionContext';

import {isMobile} from '../../../../data/data_stores/net/UserAgent';

interface Props {
  initial?: boolean;
  signedIn?: boolean;
  noLayout?: boolean;
  children?: React.ReactNode;
}

const Layout: React.FunctionComponent<Props> = props => {
  const {initial, signedIn, noLayout, children} = props;
  return (
    <DimensionContext.Consumer>
      {context => (
        <Container
          style={
            noLayout
              ? {}
              : {
                  width: context.window.width,
                  height: context.window.height,
                }
          }>
          {!noLayout && <Header signedIn={signedIn} />}

          <Contents initial={initial} signedIn={signedIn} noLayout={noLayout}>
            <InnerContents>
              <Content
                initial={initial}
                signedIn={signedIn}
                noLayout={noLayout}
                style={
                  (context.window.width < 481 ||
                    context.content.height < 822) &&
                  (isMobile ? true : initial ? false : !noLayout)
                    ? {height: context.content.height - 1}
                    : {}
                }>
                {children}
              </Content>
            </InnerContents>
          </Contents>

          {!noLayout && ((isMobile && initial) || !isMobile) && <Footer />}
        </Container>
      )}
    </DimensionContext.Consumer>
  );
};

export default React.memo(Layout);

const Container = styled.div``;

interface ContentsProps {
  initial?: boolean;
  signedIn?: boolean;
  noLayout?: boolean;
}

const Contents = styled.div<ContentsProps>`
  position: relative;
  width: 100%;
  height: 100%;
  display: table;
  margin: 0 auto;
  z-index: 1;
  ${props =>
    props.noLayout
      ? null
      : `
  @media (min-width: 767px) {
    padding: 70px 0;
    padding-top: 115px;
  }
  ${props.initial ? null : `background-color: #555;`}
  ${
    props.signedIn
      ? `
      padding: 0;
      @media (min-width: 767px) {
        padding: 70px 0;
        padding-top: 115px;
      }
      `
      : `
      padding: 0;
      @media (min-width: 481px) {
        padding: 44px 0;
      }
      `
  }
  `}
`;

const InnerContents = styled.div`
  height: 100%;
  @media (min-width: 767px) {
    display: table-cell;
    vertical-align: middle;
  }
`;

interface ContentProps {
  initial?: boolean;
  signedIn?: boolean;
  noLayout?: boolean;
}

const Content = styled.div<ContentProps>`
  display: flex;
  width: 100vw;
  height: 100vh;
  margin: auto;
  ${props =>
    props.noLayout
      ? null
      : `
  media (min-width: 481px) and (max-width: 767px) {
    width: 433px;
    height: 822px;
  }
  @media (min-width: 767px) {
    width: ${props.initial ? 600 : 433}px;
    height: ${props.initial ? 780 : 822}px;
  }
  @media (max-height: 822px) {
    height: 100vh;
  }
  ${
    props.signedIn
      ? null
      : `
      padding: 0;
      @media (min-width: 481px) {
        padding-bottom: 44px;
      }
    `
  }
  `}
  @
`;
