import {Dispatch} from 'redux';

import Types from '../Types';

import CharacterPattern from '../../../domain/entities/CharacterPattern';

import NetCharacterPatternsRepository from '../../../data/repositories/writer/NetCharacterPatternsRepository';

export interface Params {
  characterId: number;
  actorCharacterId: number;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<CharacterPattern> {
  return async (dispatch: Dispatch<any>): Promise<CharacterPattern> => {
    try {
      const payload = await new NetCharacterPatternsRepository().create({
        character_pattern: params,
      });
      dispatch({payload, type: Types.CreateCharacterPatternSuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateCharacterPatternFailure});
      throw err;
    }
  };
}
