import EntityMapper from './EntityMapper';

import Illustration from '../../../domain/entities/Illustration';

export interface IllustrationData {
  id: number;
  name: string;
  original_image_url: string;
}

export default class IllustrationMapper
  implements EntityMapper<IllustrationData, Illustration>
{
  public map(obj: IllustrationData): Illustration {
    return new Illustration(obj.id, obj.name, obj.original_image_url);
  }
}
