import EntityMapper from '../EntityMapper';

import ActorEye from '../../../../domain/entities/character_maker/ActorEye';

export interface ActorEyeData {
  id: number;
  actor_id: number;
  face_pattern_id: number;
  face_pattern_name: string;
  eye_color_pattern_id: number;
  eye_color_pattern_name: string;
  original_image_url: string;
}

export default class ActorEyeMapper
  implements EntityMapper<ActorEyeData, ActorEye>
{
  public map(obj: ActorEyeData): ActorEye {
    return new ActorEye(
      obj.id,
      obj.actor_id,
      obj.face_pattern_id,
      obj.face_pattern_name,
      obj.eye_color_pattern_id,
      obj.eye_color_pattern_name,
      obj.original_image_url,
    );
  }
}
