import EntityMapper from './EntityMapper';

import Tip from '../../../domain/entities/Tip';

export interface TipData {
  id: number;
  name: string;
  amount: number;
  score: number;
}

export default class TipMapper implements EntityMapper<TipData, Tip> {
  public map(obj: TipData): Tip {
    return new Tip(obj.id, obj.name, obj.amount, obj.score);
  }
}
