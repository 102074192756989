enum FramePartPosition {
  Top,
  Middle,
  Bottom,
}

export default FramePartPosition;

export class FramePartPositionValueMapper {
  public static map(framePartPosition: FramePartPosition): string {
    switch (framePartPosition) {
      case FramePartPosition.Top:
        return 'top';
      case FramePartPosition.Middle:
        return 'middle';
      case FramePartPosition.Bottom:
        return 'bottom';
      default:
        throw new Error('Unsupported frame part position error!');
    }
  }
}
