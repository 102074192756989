import {TextStyle, ViewStyle} from 'react-native';

import {camelizeKeys} from 'humps';

import EntityMapper from './EntityMapper';

import OrientedSpeechBalloonPartMapper, {
  OrientedSpeechBalloonPartData,
} from './OrientedSpeechBalloonPartMapper';

import SpeechBalloonDirectionMapper, {
  SpeechBalloonDirectionData,
} from '../../value_objects/mappers/SpeechBalloonDirectionMapper';

import OrientedSpeechBalloon from '../../../domain/entities/OrientedSpeechBalloon';

export interface OrientedSpeechBalloonData {
  id: number;
  speech_balloon_id: number;
  speech_balloon_direction: SpeechBalloonDirectionData;
  original_image_url: string;
  oriented_speech_balloon_parts: OrientedSpeechBalloonPartData[];
  container_style: {[key: string]: any};
  text_style: {[key: string]: any};
  voice_icon_style?: {[key: string]: any};
}

export default class OrientedSpeechBalloonMapper
  implements EntityMapper<OrientedSpeechBalloonData, OrientedSpeechBalloon>
{
  private speechBalloonDirectionMapper: SpeechBalloonDirectionMapper;
  private orientedSpeechBalloonPartMapper: OrientedSpeechBalloonPartMapper;

  constructor() {
    this.speechBalloonDirectionMapper = new SpeechBalloonDirectionMapper();
    this.orientedSpeechBalloonPartMapper =
      new OrientedSpeechBalloonPartMapper();
  }

  public map(obj: OrientedSpeechBalloonData): OrientedSpeechBalloon {
    const speechBalloonDirection = this.speechBalloonDirectionMapper.map(
      obj.speech_balloon_direction,
    );
    const orientedSpeechBalloonParts = obj.oriented_speech_balloon_parts.map(
      orientedSpeechBalloonPartObj => {
        return this.orientedSpeechBalloonPartMapper.map(
          orientedSpeechBalloonPartObj,
        );
      },
    );
    const containerStyle = camelizeKeys(obj.container_style) as ViewStyle;
    const textStyle = camelizeKeys(obj.text_style) as TextStyle;
    const voiceIconStyle = obj.voice_icon_style
      ? (camelizeKeys(obj.voice_icon_style) as ViewStyle)
      : undefined;
    return new OrientedSpeechBalloon(
      obj.id,
      obj.speech_balloon_id,
      speechBalloonDirection,
      obj.original_image_url,
      orientedSpeechBalloonParts,
      containerStyle,
      textStyle,
      voiceIconStyle,
    );
  }
}
