export default class Price {
  constructor(
    public id: string,
    public billingScheme: string,
    public currency: string,
    public recurring: string,
    public taxBehavior: string,
    public type: string,
    public unitAmount: number,
    public unitAmountDecimal: string,
    public amountIncludingTax: number,
  ) {}
}
