import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import EpisodeReaction from '../../../domain/entities/EpisodeReaction';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetEpisodeReactionsRepository from '../../../data/repositories/writer/NetEpisodeReactionsRepository';

const types = {
  failure: Types.IndexEpisodeReactionsFailure,
  request: Types.IndexEpisodeReactionsRequest,
  success: Types.IndexEpisodeReactionsSuccess,
};

export interface Params extends BaseParams {
  episodeId: number;
  reactionIds?: number[];
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<EpisodeReaction>> {
  return indexGenerics(new NetEpisodeReactionsRepository(), types, params);
}
