import * as React from 'react';

import BaseShow, {
  CommonProps,
  commonPropsAreEqual,
} from '../../base_viewers/Show';

import NetSceneScriptsRepository from '../../../../data/repositories/writer/NetSceneScriptsRepository';

interface Props extends CommonProps {
  sceneId: number;
}

const Show: React.FunctionComponent<Props> = props => {
  const {sceneId} = props;
  const sceneScriptGetTask = React.useMemo(
    () => new NetSceneScriptsRepository().find(sceneId),
    [sceneId],
  );

  return <BaseShow sceneScriptGetTask={sceneScriptGetTask} {...props} />;
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.sceneId === nextProps.sceneId &&
    commonPropsAreEqual(prevProps, nextProps)
  );
};

export default React.memo(Show, areEqual);
