import BackgroundShowSceneCommandMapper, {
  BackgroundShowSceneCommandData,
} from './BackgroundShowSceneCommandMapper';
import CharacterHideSceneCommandMapper, {
  CharacterHideSceneCommandData,
} from './CharacterHideSceneCommandMapper';
import CharacterShowSceneCommandMapper, {
  CharacterShowSceneCommandData,
} from './CharacterShowSceneCommandMapper';
import CharacterUpdateSceneCommandMapper, {
  CharacterUpdateSceneCommandData,
} from './CharacterUpdateSceneCommandMapper';
import DescriptiveTextShowSceneCommandMapper, {
  DescriptiveTextShowSceneCommandData,
} from './DescriptiveTextShowSceneCommandMapper';
import FullScreenIllustrationShowSceneCommandMapper, {
  FullScreenIllustrationShowSceneCommandData,
} from './FullScreenIllustrationShowSceneCommandMapper';
import IllustrationShowSceneCommandMapper, {
  IllustrationShowSceneCommandData,
} from './IllustrationShowSceneCommandMapper';
import MarkHideSceneCommandMapper, {
  MarkHideSceneCommandData,
} from './MarkHideSceneCommandMapper';
import MarkShowSceneCommandMapper, {
  MarkShowSceneCommandData,
} from './MarkShowSceneCommandMapper';
import SpeechTextShowSceneCommandMapper, {
  SpeechTextShowSceneCommandData,
} from './SpeechTextShowSceneCommandMapper';
import EffectShowSceneCommandMapper, {
  EffectShowSceneCommandData,
} from './EffectShowSceneCommandMapper';
import SoundEffectShowSceneCommandMapper, {
  SoundEffectShowSceneCommandData,
} from './SoundEffectShowSceneCommandMapper';
import BackgroundMusicShowSceneCommandMapper, {
  BackgroundMusicShowSceneCommandData,
} from './BackgroundMusicShowSceneCommandMapper';
import BackgroundMusicHideSceneCommandMapper, {
  BackgroundMusicHideSceneCommandData,
} from './BackgroundMusicHideSceneCommandMapper';
import CompositeParallelSceneCommandMapper, {
  CompositeParallelSceneCommandData,
} from './CompositeParallelSceneCommandMapper';
import CompositeSequenceSceneCommandMapper, {
  CompositeSequenceSceneCommandData,
} from './CompositeSequenceSceneCommandMapper';

import SceneCommand from '../../../../domain/value_objects/scene_commands/SceneCommand';

export type SceneCommandData = BackgroundShowSceneCommandData &
  CharacterHideSceneCommandData &
  CharacterShowSceneCommandData &
  CharacterUpdateSceneCommandData &
  DescriptiveTextShowSceneCommandData &
  FullScreenIllustrationShowSceneCommandData &
  IllustrationShowSceneCommandData &
  MarkHideSceneCommandData &
  MarkShowSceneCommandData &
  SpeechTextShowSceneCommandData &
  EffectShowSceneCommandData &
  SoundEffectShowSceneCommandData &
  BackgroundMusicShowSceneCommandData &
  BackgroundMusicHideSceneCommandData &
  CompositeParallelSceneCommandData &
  CompositeSequenceSceneCommandData;

export default class SceneCommandMapper {
  private backgroundShowSceneCommandMapper: BackgroundShowSceneCommandMapper;
  private characterHideSceneCommandMapper: CharacterHideSceneCommandMapper;
  private characterShowSceneCommandMapper: CharacterShowSceneCommandMapper;
  private characterUpdateSceneCommandMapper: CharacterUpdateSceneCommandMapper;
  private descriptiveTextShowSceneCommandMapper: DescriptiveTextShowSceneCommandMapper;
  private fullScreenIllustrationShowSceneCommandMapper: FullScreenIllustrationShowSceneCommandMapper;
  private illustrationShowSceneCommandMapper: IllustrationShowSceneCommandMapper;
  private markHideSceneCommandMapper: MarkHideSceneCommandMapper;
  private markShowSceneCommandMapper: MarkShowSceneCommandMapper;
  private speechTextShowSceneCommandMapper: SpeechTextShowSceneCommandMapper;
  private effectShowSceneCommandMapper: EffectShowSceneCommandMapper;
  private soundEffectShowSceneCommandMapper: SoundEffectShowSceneCommandMapper;
  private backgroundMusicShowSceneCommandMapper: BackgroundMusicShowSceneCommandMapper;
  private backgroundMusicHideSceneCommandMapper: BackgroundMusicHideSceneCommandMapper;
  private compositeParallelCommandMapper: CompositeParallelSceneCommandMapper;
  private compositeSequenceCommandMapper: CompositeSequenceSceneCommandMapper;

  constructor() {
    this.backgroundShowSceneCommandMapper =
      new BackgroundShowSceneCommandMapper();
    this.characterHideSceneCommandMapper =
      new CharacterHideSceneCommandMapper();
    this.characterShowSceneCommandMapper =
      new CharacterShowSceneCommandMapper();
    this.characterUpdateSceneCommandMapper =
      new CharacterUpdateSceneCommandMapper();
    this.descriptiveTextShowSceneCommandMapper =
      new DescriptiveTextShowSceneCommandMapper();
    this.fullScreenIllustrationShowSceneCommandMapper =
      new FullScreenIllustrationShowSceneCommandMapper();
    this.illustrationShowSceneCommandMapper =
      new IllustrationShowSceneCommandMapper();
    this.markHideSceneCommandMapper = new MarkHideSceneCommandMapper();
    this.markShowSceneCommandMapper = new MarkShowSceneCommandMapper();
    this.speechTextShowSceneCommandMapper =
      new SpeechTextShowSceneCommandMapper();
    this.effectShowSceneCommandMapper = new EffectShowSceneCommandMapper();
    this.soundEffectShowSceneCommandMapper =
      new SoundEffectShowSceneCommandMapper();
    this.backgroundMusicShowSceneCommandMapper =
      new BackgroundMusicShowSceneCommandMapper();
    this.backgroundMusicHideSceneCommandMapper =
      new BackgroundMusicHideSceneCommandMapper();
    this.compositeParallelCommandMapper =
      new CompositeParallelSceneCommandMapper();
    this.compositeSequenceCommandMapper =
      new CompositeSequenceSceneCommandMapper();
  }

  public map(obj: SceneCommandData): SceneCommand {
    switch (obj.type) {
      case 'SceneCommands::BackgroundShowSceneCommand':
        return this.backgroundShowSceneCommandMapper.map(obj);
      case 'SceneCommands::CharacterHideSceneCommand':
        return this.characterHideSceneCommandMapper.map(obj);
      case 'SceneCommands::CharacterShowSceneCommand':
        return this.characterShowSceneCommandMapper.map(obj);
      case 'SceneCommands::CharacterUpdateSceneCommand':
        return this.characterUpdateSceneCommandMapper.map(obj);
      case 'SceneCommands::DescriptiveTextShowSceneCommand':
        return this.descriptiveTextShowSceneCommandMapper.map(obj);
      case 'SceneCommands::FullScreenIllustrationShowSceneCommand':
        return this.fullScreenIllustrationShowSceneCommandMapper.map(obj);
      case 'SceneCommands::IllustrationShowSceneCommand':
        return this.illustrationShowSceneCommandMapper.map(obj);
      case 'SceneCommands::MarkHideSceneCommand':
        return this.markHideSceneCommandMapper.map(obj);
      case 'SceneCommands::MarkShowSceneCommand':
        return this.markShowSceneCommandMapper.map(obj);
      case 'SceneCommands::SpeechTextShowSceneCommand':
        return this.speechTextShowSceneCommandMapper.map(obj);
      case 'SceneCommands::EffectShowSceneCommand':
        return this.effectShowSceneCommandMapper.map(obj);
      case 'SceneCommands::SoundEffectShowSceneCommand':
        return this.soundEffectShowSceneCommandMapper.map(obj);
      case 'SceneCommands::BackgroundMusicShowSceneCommand':
        return this.backgroundMusicShowSceneCommandMapper.map(obj);
      case 'SceneCommands::BackgroundMusicHideSceneCommand':
        return this.backgroundMusicHideSceneCommandMapper.map(obj);
      case 'SceneCommands::CompositeParallelSceneCommand':
        return this.compositeParallelCommandMapper.map(obj);
      case 'SceneCommands::CompositeSequenceSceneCommand':
        return this.compositeSequenceCommandMapper.map(obj);
      default:
        throw Error('Command Mapper Error');
    }
  }
}
