import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import SocialLoginButton from './SocialLoginButton';

import FacebookLogin, {
  ErrorData,
  SuccessData,
} from '../../../../../domain/services/social_logins/FacebookLogin';

import FacebookIcon from '../../icons/FacebookIcon';

interface Props {
  onSuccess: (data: SuccessData) => void;
  onError: (error: ErrorData) => void;
  children?: React.ReactNode;
}

export default class FacebookLoginButton extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    return (
      <SocialLoginButton color={'#efefef'} onPress={this.handlePress}>
        <View style={styles.button}>
          <View style={styles.icon}>
            <FacebookIcon size={23} />
          </View>
        </View>
      </SocialLoginButton>
    );
  }

  private handlePress = () => {
    const {onError, onSuccess} = this.props;
    new FacebookLogin().login({
      onError,
      onSuccess,
    });
  };
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    borderRadius: 15,
    backgroundColor: '#1877f2',
    height: 30,
    justifyContent: 'center',
    width: 30,
  } as ViewStyle,
  icon: {
    position: 'absolute',
    bottom: 0,
  } as ViewStyle,
});
