import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import numeral from 'numeral';
import dateformat from 'dateformat';

import TermSelect from './TermSelect';

import ViewIcon from '../../../shared/icons/ViewIcon';
import ReactionIcon from '../../../shared/icons/ReactionIcon';
import CommentIcon from '../../../shared/icons/CommentIcon';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import {TermRange} from '../../../../view_models/TermRange';

import AccountAggregation from '../../../../../domain/value_objects/summary/AccountAggregation';

interface Props {
  accountAggregation: AccountAggregation;
  termRange: TermRange;
  onTermRangeChange: (termRange: TermRange) => void;
}

const WriterAggregationView: React.FunctionComponent<Props> = props => {
  const {accountAggregation, termRange, onTermRangeChange} = props;
  return (
    <>
      <View style={styles.container}>
        <TermSelect
          termRange={termRange}
          onTermRangeChange={onTermRangeChange}
        />
        <View style={{paddingHorizontal: 12}}>
          <Text style={styles.title}>全体</Text>
          <View style={styles.rows}>
            <View style={styles.row}>
              <ViewIcon size={16} color={'#383838'} />
              <Text style={styles.rowValue}>
                {numeral(accountAggregation.episodePageViewCount).format('0,0')}
              </Text>
              <Text style={styles.rowLabel}>閲覧数</Text>
            </View>
            <View style={styles.row}>
              <ReactionIcon size={16} color={'#383838'} />
              <Text style={styles.rowValue}>
                {numeral(accountAggregation.reactionsCount).format('0,0')}
              </Text>
              <Text style={styles.rowLabel}>リアクション数</Text>
            </View>
            <View style={styles.row}>
              <CommentIcon size={16} color={'#383838'} />
              <Text style={styles.rowValue}>
                {numeral(accountAggregation.commentsCount).format('0,0')}
              </Text>
              <Text style={styles.rowLabel}>コメント数</Text>
            </View>
          </View>
          <Text style={styles.aggregationTime}>
            集計時刻：{dateformat(new Date(), 'yyyy.mm.dd HH:MM')}
          </Text>
          <Text style={styles.title}>ストーリー別</Text>
        </View>
      </View>
      <ListItemNormalSeparator />
    </>
  );
};

export default React.memo(WriterAggregationView);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
  },
  rows: {
    flex: 1,
    flexDirection: 'row',
  } as ViewStyle,
  row: {
    flex: 1,
    margin: 4,
    padding: 12,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  rowValue: {
    color: '#ff8f13',
    fontSize: 16,
    fontWeight: 'bold',
    marginVertical: 10,
  } as TextStyle,
  rowLabel: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  title: {
    color: '#222',
    fontWeight: 'bold',
    fontSize: 12,
    marginHorizontal: 4,
    marginBottom: 6,
  } as TextStyle,
  aggregationTime: {
    color: '#999',
    fontSize: 10,
    marginTop: 1,
    textAlign: 'right',
    marginHorizontal: 4,
  } as TextStyle,
});
