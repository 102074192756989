import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/cover_image_form/character_form/character_names/Index';

import AppState from '../../../../reducers/AppState';

import showActorCharacter from '../../../../actions/actor_characters/show';
import indexCharacterPatterns, {
  Params as CharacterPatternIndexParams,
} from '../../../../actions/character_patterns/index';
import createCharacter, {
  Params as CharacterCreateParams,
} from '../../../../actions/characters/create';

import selectEntity from '../../../../helpers/selectEntity';
import {CoverImageFormCharacterFormCharacterNamesRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CoverImageFormCharacterFormCharacterNamesRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, forms} = state;
  const {actorCharacterId} = route.params;
  const {coverImageForm} = forms;
  const actorCharacter = selectEntity(
    entities.actorCharacters,
    actorCharacterId,
  );
  return {
    coverImageForm,
    actorCharacter,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createCharacter: (params: CharacterCreateParams) =>
      dispatch(createCharacter(params)),
    indexCharacterPatterns: (params: CharacterPatternIndexParams) =>
      dispatch(indexCharacterPatterns(params)),
    showActorCharacter: (id: number) => dispatch(showActorCharacter(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
