import Entity from '../Entity';
import NotificationSettingCategory from './NotificationSettingCategory';

export default class NotificationSetting implements Entity {
  constructor(
    public id: number,
    public name: string,
    public writerNotificationSettingCategory: NotificationSettingCategory,
  ) {}
}
