import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import StoryAggregationView from './partials/StoryAggregationView';

import EpisodeAggregationList from '../episodes/partials/EpisodeAggregationList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {PartnerProgramStoryRouteProp} from '../../../navigators/RouteProps';

import {
  TermRange,
  convertTermRangeToQueryParams,
} from '../../../view_models/TermRange';

import StoryAggregation from '../../../../domain/value_objects/partner_program/StoryAggregation';
import EpisodeAggregation from '../../../../domain/value_objects/partner_program/EpisodeAggregation';
import StoryDailyAggregation from '../../../../domain/value_objects/partner_program/StoryDailyAggregation';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: PartnerProgramStoryRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {id} = route.params;
  const [storyAggregation, setStoryAggregation] =
    React.useState<StoryAggregation | null>(null);
  const [episodeAggregations, setEpisodeAggregations] =
    React.useState<Array<EpisodeAggregation> | null>(null);
  const [storyDailyAggregations, setStoryDailyAggregations] =
    React.useState<Array<StoryDailyAggregation> | null>(null);
  const [termRange, setTermRange] = React.useState<TermRange>('all');
  useFocusEffect(
    React.useCallback(() => {
      const params = convertTermRangeToQueryParams(termRange);
      TapNovelRestApi.get(
        `/api/writer/partner_program/story_aggregations/${id}`,
        params,
      ).then(response => {
        setStoryAggregation(
          camelizeKeys(response.body as any) as StoryAggregation,
        );
      });
      TapNovelRestApi.get('/api/writer/partner_program/episode_aggregations', {
        storyId: id,
        ...params,
      }).then(response => {
        setEpisodeAggregations(
          camelizeKeys(response.body as any) as Array<EpisodeAggregation>,
        );
      });
      TapNovelRestApi.get(
        '/api/writer/partner_program/story_daily_aggregations',
        {storyId: id, ...params},
      ).then(response => {
        setStoryDailyAggregations(
          camelizeKeys(response.body as any) as Array<StoryDailyAggregation>,
        );
      });
    }, [termRange]),
  );
  return (
    <Layout
      title={storyAggregation ? `${storyAggregation.title}の報酬` : ''}
      navigation={navigation}
      back={true}>
      <EpisodeAggregationList
        ListHeaderComponent={
          <>
            {storyAggregation && storyDailyAggregations ? (
              <StoryAggregationView
                storyAggregation={storyAggregation}
                storyDailyAggregations={storyDailyAggregations}
                termRange={termRange}
                onTermRangeChange={setTermRange}
              />
            ) : null}
          </>
        }
        episodeAggregations={episodeAggregations}
      />
    </Layout>
  );
};

export default React.memo(Show);
