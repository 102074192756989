import * as React from 'react';

import BaseEffectImageSequence from './BaseEffectImageSequence';

interface Props {
  id: number | undefined;
  urls: string[];
  size: {width: number; height: number};
}

const BackgroundEffectImageSequence: React.FunctionComponent<Props> = props => {
  const {id, urls, size} = props;
  return (
    <BaseEffectImageSequence id={id} urls={urls} size={size} loop={true} />
  );
};

export default React.memo(BackgroundEffectImageSequence);
