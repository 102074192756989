import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import SoundListItem, {SoundEntity} from './SoundListItem';

import DefaultList from '../lists/DefaultList';
import Entity from '../../../../domain/entities/Entity';
import UserResourceFavorite from '../../../../domain/entities/writer/UserResourceFavorite';

interface Props<T extends SoundEntity> {
  entities: T[] | null;
  style?: StyleProp<ViewStyle>;
  enabledFavorite?: boolean;
  userResourceFavorites?: UserResourceFavorite[] | null;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  onSelectEntity: (entity: T) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
}

interface State {
  plaingEntityId: number | null;
}
export default class SoundList<
  T extends SoundEntity,
> extends React.PureComponent<Props<T>, State> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      plaingEntityId: null,
    };
  }

  public render(): React.ReactNode {
    const {
      entities,
      style,
      enabledFavorite,
      ListHeaderComponent,
      ListFooterComponent,
      ListEmptyComponent,
      onSelectEntity,
      onEndReached,
    } = this.props;
    const {plaingEntityId} = this.state;
    if (!entities) {
      return null;
    }
    return (
      entities && (
        <DefaultList
          data={entities}
          style={style}
          ListHeaderComponent={ListHeaderComponent}
          ListFooterComponent={ListFooterComponent}
          ListEmptyComponent={ListEmptyComponent}
          buttonType={false}
          contentContainerStyle={{flex: 1}}
          onSelectItem={onSelectEntity}
          onEndReachedThreshold={0.8}
          onEndReached={onEndReached}
          renderCustom={this.renderCustom}
          extraData={[plaingEntityId, enabledFavorite]}
        />
      )
    );
  }

  private renderCustom = (entity: T) => {
    const {enabledFavorite, userResourceFavorites, onSelectEntity} = this.props;
    const {plaingEntityId} = this.state;
    const userResourceFavorite = userResourceFavorites?.find(
      o => o.resourceId === entity.id,
    );
    return (
      <SoundListItem
        entity={entity}
        playing={entity.id === plaingEntityId}
        enabledFavorite={enabledFavorite}
        userResourceFavorite={userResourceFavorite}
        onSelectEntity={onSelectEntity}
        onUpdatePlayingEntityId={this.handleUpdatePlayingEntityId}
      />
    );
  };

  private handleUpdatePlayingEntityId = (entity: Entity, playing: boolean) => {
    if (!entity) {
      this.setState({plaingEntityId: null});
      return;
    }
    if (this.state.plaingEntityId !== entity.id && !playing) {
      return;
    }
    this.setState({plaingEntityId: playing ? entity.id : null});
  };
}
