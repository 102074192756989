import SceneCommand from './SceneCommand';

import TextFrame from '../../entities/TextFrame';
import Voice from '../../entities/Voice';
import Sound from '../../entities/Sound';

import DescriptiveTextShowSceneCommandForm from '../../forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class DescriptiveTextShowSceneCommand implements SceneCommand {
  constructor(
    public textFrame: TextFrame,
    public text: string,
    public voice?: Voice | null,
    public sound?: Sound | null,
    public startTime?: number | null,
    public endTime?: number | null,
  ) {}

  public getValue() {
    return {
      text: this.text,
      text_frame_id: this.textFrame.id,
      voice_id: this.voice ? this.voice.id : null,
      sound_id: this.sound ? this.sound.id : null,
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }

  public toForm(): SceneCommandForm {
    return new DescriptiveTextShowSceneCommandForm(
      this.textFrame,
      this.text,
      this.voice || null,
      this.sound || null,
      this.startTime || null,
      this.endTime || null,
    );
  }
}
