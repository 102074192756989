import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const CharacterImageIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 15;
  const color = props.color || 'black';
  return <Icon name={'character-image'} size={size} color={color} />;
};

export default React.memo(CharacterImageIcon);
