import * as React from 'react';

import Headline from '../../Headline';
import CharacterPatternList from './partials/CharacterPatternList';
import NoCharacterPatterns from './partials/NoCharacterPatterns';
import BottomButtons from './partials/BottomButtons';

import ResourceFavoriteListLink from '../../ResourceFavoriteListLink';
import ResourceShoppingListLink from '../../ResourceShoppingListLink';

import NavigationProp from '../../../../navigators/NavigationProp';

import Layout from '../../../shared/Layout';
import ResourceFavoriteButton from '../../../shared/ResourceFavoriteButton';

import {Params as CharacterPatternIndexParams} from '../../../../actions/character_patterns/index';

import CharacterPattern from '../../../../../domain/entities/CharacterPattern';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';
import {
  SceneFormCharacterShowSceneCommandNewCharacterPatternsRouteProp,
  CoverImageFormCharacterPatternsRouteProp,
  CharacterFormNewRouteProp,
} from '../../../../navigators/RouteProps';

export interface Params {
  storyId: number;
}

interface Props {
  navigation: NavigationProp;
  route:
    | SceneFormCharacterShowSceneCommandNewCharacterPatternsRouteProp
    | CoverImageFormCharacterPatternsRouteProp
    | CharacterFormNewRouteProp;

  characterPatternsParams: CharacterPatternIndexParams;
  characterPatterns: CharacterPattern[] | null;
  enabledFavorite?: boolean;
  onSelectCharacterPattern: (characterPattern: CharacterPattern) => void;
  onForwardToNewCharacterPattern: () => void;
  onForwardToNewCharacter: () => void;
  onForwardToFavoriteList?: () => void;
  onPressShoppingList?: () => void;
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  labelExtractor?: (item: CharacterPattern) => string | null;
}

interface State {
  nextPages: {[key: string]: number | undefined};
}

export default class Index extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      nextPages: {},
    };
  }

  public async componentDidMount() {
    const {characterPatternsParams, indexCharacterPatterns} = this.props;
    await indexCharacterPatterns(characterPatternsParams);
  }

  public render(): React.ReactNode {
    const {
      navigation,
      characterPatterns,
      enabledFavorite,
      onSelectCharacterPattern,
      onForwardToNewCharacterPattern,
      onForwardToNewCharacter,
      onForwardToFavoriteList,
      onPressShoppingList,
      labelExtractor,
    } = this.props;
    return (
      <Layout
        title={'キャラクター'}
        scrollable={false}
        navigation={navigation}
        close={true}>
        {characterPatterns ? (
          characterPatterns.length > 0 ? (
            <>
              <Headline
                title={'作成したキャラクター'}
                onPressShoppingList={onPressShoppingList}
                onPressFavoriteList={
                  enabledFavorite ? onForwardToFavoriteList : undefined
                }
              />
              <CharacterPatternList
                characterPatterns={characterPatterns}
                onSelectCharacterPattern={onSelectCharacterPattern}
                labelExtractor={labelExtractor}
                modalRenderFavoriteButton={this.modalRenderFavoriteButton}
              />
              <BottomButtons
                onForwardToNewCharacterPattern={onForwardToNewCharacterPattern}
                onForwardToNewCharacter={onForwardToNewCharacter}
              />
            </>
          ) : (
            <>
              <NoCharacterPatterns
                onForwardToNewCharacterPattern={onForwardToNewCharacterPattern}
                onForwardToNewCharacter={onForwardToNewCharacter}
              />
              <ResourceShoppingListLink
                style={{
                  position: 'absolute',
                  top: 16,
                  right: enabledFavorite ? 58 : 16,
                }}
                onPress={onPressShoppingList}
              />
              {enabledFavorite && (
                <ResourceFavoriteListLink
                  style={{position: 'absolute', top: 16, right: 16}}
                  onPress={onForwardToFavoriteList}
                />
              )}
            </>
          )
        ) : null}
      </Layout>
    );
  }

  private modalRenderFavoriteButton = (characterPattern: CharacterPattern) => {
    const {enabledFavorite} = this.props;
    if (!enabledFavorite) {
      return null;
    }
    return (
      <ResourceFavoriteButton
        style={{top: 55, right: 10}}
        resourceType={'Actor'}
        resourceId={characterPattern.actorCharacter.actorId}
      />
    );
  };
}
