import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import SoundMapper, {SoundData} from '../../entities/mappers/SoundMapper';

import Sound from '../../../domain/entities/Sound';
import SoundsRepository from '../../../domain/repositories/writer/SoundsRepository';

export default class NetSoundsRepository
  extends NetResourcesRepository<Sound, SoundData>
  implements SoundsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/sounds',
        new SoundMapper(),
      ),
    );
  }
}
