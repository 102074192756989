import * as React from 'react';
import {InteractionManager, StyleSheet, View, ViewStyle} from 'react-native';

import {ModalParams} from './Modal';

import SceneCommandBoxes from './form/SceneCommandBoxes';
import ScenePreviewButton from './form/ScenePreviewButton';
import SceneSubmitButton from './form/SceneSubmitButton';
import BackgroundShowSceneCommandBox from './form/scene_command_boxes/BackgroundShowSceneCommandBox';

import Coachmark from '../../shared/coachmark/Coachmark';
import CoachmarkContext from '../../shared/coachmark/CoachmarkContext';

import shouldUpdateSceneCommandFormList from '../../shared/enhanced/shouldUpdateSceneCommandFormList';

import CoachmarkState from '../../../view_models/CoachmarkState';

import {equalForKeys} from '../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../domain/forms/scene_commands/SceneCommandForm';
import SceneForm from '../../../../domain/forms/SceneForm';
import Position from '../../../../domain/value_objects/Position';

interface Props {
  sceneForm: SceneForm;
  sceneCommandForms: SceneCommandForm[];
  newScene: boolean;
  enableFullScreenIllustration?: boolean;
  enableSoundEffect?: boolean;
  enableBackgroundMusic?: boolean;
  disabledSubmitButton: boolean;
  onForwardToBackgrounds: () => void;
  onForwardToBackgroundFilters: () => void;
  onForwardToBackgroundEffects: () => void;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
  onForwardToSpeechBalloons: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    positions?: Position[];
  }) => void;
  onForwardToTextFrames: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToIllustrations: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToFullScreenIllustrations: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToEffects: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToSoundEffects: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToBackgroundMusic: (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => void;
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
  onRequestOpenModal: (modalParams: ModalParams) => void;
  onRequestUpdateCoachmarkModal?: (
    coachmarkState: CoachmarkState | null,
  ) => void;
  onPreview: () => void;
  onSubmit: () => void;
}

export default class Form extends React.Component<Props> {
  private ref = React.createRef<SceneCommandBoxes>();

  public scrollToEnd() {
    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        this.ref.current?.scrollToEnd();
      }, 500);
    });
  }

  public shouldComponentUpdate?(nextProps: Readonly<Props>): boolean {
    if (
      !equalForKeys(this.props, nextProps, [
        'sceneForm',
        'newScene',
        'enableFullScreenIllustration',
        'enableSoundEffect',
        'enableBackgroundMusic',
        'disabledSubmitButton',
      ])
    ) {
      return true;
    }
    if (shouldUpdateSceneCommandFormList(this.props, nextProps)) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {
      sceneForm,
      sceneCommandForms,
      newScene,
      enableFullScreenIllustration,
      enableSoundEffect,
      enableBackgroundMusic,
      disabledSubmitButton,
      onForwardToBackgrounds,
      onForwardToBackgroundFilters,
      onForwardToBackgroundEffects,
      onForwardToCharacters,
      onForwardToSpeechBalloons,
      onForwardToTextFrames,
      onForwardToIllustrations,
      onForwardToFullScreenIllustrations,
      onForwardToEffects,
      onForwardToSoundEffects,
      onForwardToBackgroundMusic,
      onChangeOrder,
      onRequestOpenModal,
      onRequestUpdateCoachmarkModal,
      onPreview,
    } = this.props;
    const background = sceneForm.background;
    return (
      <View style={styles.container}>
        <SceneCommandBoxes
          ref={this.ref}
          sceneForm={sceneForm}
          sceneCommandForms={sceneCommandForms}
          enableFullScreenIllustration={enableFullScreenIllustration}
          enableSoundEffect={enableSoundEffect}
          enableBackgroundMusic={enableBackgroundMusic}
          backgroundShowSceneCommandComponent={
            <CoachmarkContext.Consumer>
              {context => {
                return (
                  <Coachmark
                    autoShow={context === 'first_episode_form_created'}
                    coachmarkViewChildren={'まずは背景を選びましょう！'}
                    onHide={this.handleHide}>
                    <BackgroundShowSceneCommandBox
                      sceneForm={sceneForm}
                      background={background}
                      onForwardToBackgrounds={onForwardToBackgrounds}
                      onForwardToBackgroundFilters={
                        onForwardToBackgroundFilters
                      }
                      onForwardToBackgroundEffects={
                        onForwardToBackgroundEffects
                      }
                    />
                  </Coachmark>
                );
              }}
            </CoachmarkContext.Consumer>
          }
          onForwardToCharacters={onForwardToCharacters}
          onForwardToSpeechBalloons={onForwardToSpeechBalloons}
          onForwardToTextFrames={onForwardToTextFrames}
          onForwardToIllustrations={onForwardToIllustrations}
          onForwardToEffects={onForwardToEffects}
          onForwardToSoundEffects={onForwardToSoundEffects}
          onForwardToBackgroundMusic={onForwardToBackgroundMusic}
          onForwardToFullScreenIllustrations={
            onForwardToFullScreenIllustrations
          }
          onChangeOrder={onChangeOrder}
          onRequestOpenModal={onRequestOpenModal}
          onRequestUpdateCoachmarkModal={onRequestUpdateCoachmarkModal}
        />
        <View style={styles.bottomButtons}>
          <View style={styles.button}>
            <ScenePreviewButton
              disabled={!background && sceneCommandForms.length === 0}
              onPress={onPreview}
            />
          </View>
          <View style={styles.button}>
            <SceneSubmitButton
              newScene={newScene}
              disabled={disabledSubmitButton}
              onPress={this.handleSubmit}
            />
          </View>
        </View>
      </View>
    );
  }

  private handleSubmit = () => {
    const {onSubmit} = this.props;
    onSubmit();
  };

  private handleHide = () => {
    const {onRequestUpdateCoachmarkModal} = this.props;
    onRequestUpdateCoachmarkModal &&
      onRequestUpdateCoachmarkModal('first_episode_form_background_done');
  };
}

const styles = StyleSheet.create({
  bottomButtons: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 72,
    paddingHorizontal: 5,
    paddingVertical: 12,
    width: '100%',
  } as ViewStyle,
  button: {
    flex: 1,
    alignItems: 'center',
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
});
