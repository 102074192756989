import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {formatElapsedTimeOrFormattedTime} from '../../../helpers/times';

import UserNotification from '../../../../domain/entities/writer/UserNotification';

interface Props {
  writerUserNotification: UserNotification;
  onSelectWriterUserNotification: (
    writerUserNotification: UserNotification,
  ) => void;
}

const NotificationListItem: React.FunctionComponent<Props> = props => {
  const {writerUserNotification, onSelectWriterUserNotification} = props;
  const confirmed = writerUserNotification.confirmed();
  const onPress = React.useCallback(() => {
    onSelectWriterUserNotification(writerUserNotification);
  }, [writerUserNotification]);
  return (
    <Pressable
      style={[styles.container, confirmed ? styles.containerConfirmed : null]}
      onPress={onPress}>
      <View>
        <Text style={[styles.text, confirmed ? styles.textConfirmed : null]}>
          {writerUserNotification.content}
        </Text>
        <Text style={styles.time}>
          {formatElapsedTimeOrFormattedTime(
            writerUserNotification.optionsUpdatedAt ||
              writerUserNotification.createdAt,
          )}
        </Text>
      </View>
    </Pressable>
  );
};

export default React.memo(NotificationListItem);

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: 'white',
  } as ViewStyle,
  containerConfirmed: {
    backgroundColor: '#f4f4f4',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
  textConfirmed: {
    color: '#666',
  } as TextStyle,
  time: {
    color: '#999',
    fontSize: 10,
    marginTop: 8,
  } as TextStyle,
});
