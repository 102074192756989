import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import {mergeResponse, QueryState} from '../Response';

import Announcement from '../../../../domain/entities/writer/Announcement';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.IndexWriterAnnouncementsSuccess: {
      const result = action.payload as PaginatedResult<Announcement>;
      return mergeResponse(state, result);
    }
    default:
      return state;
  }
}
