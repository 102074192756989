import {Dispatch} from 'redux';

import AppState from '../../../reducers/AppState';

import EpisodeAggregation from '../../../../domain/value_objects/summary/EpisodeAggregation';

import NetEpisodeAggregationsRepository from '../../../../data/repositories/writer/summary/NetEpisodeAggregationsRepository';

export interface Params {
  storyId: number;
  from?: Date | undefined;
  to?: Date | undefined;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<Array<EpisodeAggregation>> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<Array<EpisodeAggregation>> => {
    const repository = new NetEpisodeAggregationsRepository();
    const payload = await repository.findBy(params);
    return payload;
  };
}
