import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import showGeneric from '../../generics/show';

import Story from '../../../../domain/entities/response/Story';

import NetStoriesRepository from '../../../../data/repositories/writer/response/NetStoriesRepository';

const types = {
  failure: Types.ShowResponseStoryFailure,
  request: Types.ShowResponseStoryRequest,
  success: Types.ShowResponseStorySuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Story> {
  return showGeneric(new NetStoriesRepository(), types, id);
}
