import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import SceneMapper, {SceneData} from '../../entities/mappers/SceneMapper';

import Scene from '../../../domain/entities/Scene';
import ScenesRepository from '../../../domain/repositories/writer/ScenesRepository';

export default class NetScenesRepository
  extends NetResourcesRepository<Scene, SceneData>
  implements ScenesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/scenes',
        new SceneMapper(),
      ),
    );
  }
}
