import EntityMapper from './EntityMapper';

import PositionedEffect from '../../../domain/entities/PositionedEffect';

export interface PositionedEffectData {
  id: number;
  base_effect_id: number;
  effect_id: number;
  position: 'center' | 'left' | 'right';
  original_image_url: string;
  frame_urls: string[];
}

export default class PositionedEffectMapper
  implements EntityMapper<PositionedEffectData, PositionedEffect>
{
  public map(obj: PositionedEffectData): PositionedEffect {
    return new PositionedEffect(
      obj.id,
      obj.base_effect_id,
      obj.effect_id,
      obj.position,
      obj.original_image_url,
      obj.frame_urls,
    );
  }
}
