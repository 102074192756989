import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import FormStep1 from './FormStep1';
import FormStep2 from './FormStep2';
import FormStep3 from './FormStep3';

import AlertIcon from '../../../shared/icons/AlertIcon';

import CurrentUserStatus from '../../../../../domain/entities/writer/CurrentUserStatus';

type Step = 'step1' | 'step2' | 'step3';

interface Props {
  currentUserStatus: CurrentUserStatus;
  onRequestDeleteAccount: () => void;
  onPressCancel: () => void;
  onToggleLoading: (loading: boolean) => void;
  setReason: React.Dispatch<React.SetStateAction<string>>;
}

const Form: React.FunctionComponent<Props> = props => {
  const {
    currentUserStatus,
    onRequestDeleteAccount,
    onPressCancel,
    onToggleLoading,
    setReason,
  } = props;
  const [step, setStep] = React.useState<Step>('step1');
  const onPressStep1 = React.useCallback(() => {
    onToggleLoading(true);
    setTimeout(() => {
      onToggleLoading(false);
      setStep('step2');
    }, 500);
  }, []);
  const onPressStep2 = React.useCallback((reason: string) => {
    setReason(reason);
    onToggleLoading(true);
    setTimeout(() => {
      onToggleLoading(false);
      setStep('step3');
    }, 500);
  }, []);
  return (
    <View style={styles.container}>
      {step !== 'step2' && (
        <View style={styles.alert}>
          <AlertIcon color={'#ff8f13'} size={36} />
          <Text style={styles.alertText}>注意</Text>
        </View>
      )}
      {step === 'step3' ? (
        <FormStep3
          currentUserStatus={currentUserStatus}
          onPressSubmit={onRequestDeleteAccount}
          onPressCancel={onPressCancel}
        />
      ) : step === 'step2' ? (
        <FormStep2 onPressNext={onPressStep2} onPressCancel={onPressCancel} />
      ) : (
        <FormStep1
          currentUserStatus={currentUserStatus}
          onPressNext={onPressStep1}
        />
      )}
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  alert: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    marginVertical: 32,
  } as ViewStyle,
  alertText: {
    color: '#ff8f13',
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 5,
  } as TextStyle,
});
