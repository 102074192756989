import * as React from 'react';
import {Pressable, StyleSheet, TextStyle, View} from 'react-native';

import NameLabelColor, {
  NAME_LABEL_COLOR_TO_FILL_COLOR,
} from '../../../domain/value_objects/NameLabelColor';

interface Props {
  nameLabelColor: NameLabelColor;
  active: boolean;
  onSelectNameLabelColor: (nameLabelColor: NameLabelColor) => void;
}

const NameLabelColorButton: React.FunctionComponent<Props> = props => {
  const {nameLabelColor, active, onSelectNameLabelColor} = props;
  const onPress = React.useCallback(() => {
    onSelectNameLabelColor(nameLabelColor);
  }, []);
  const style = React.useMemo(() => {
    return [
      styles.nameLableColor,
      {
        backgroundColor: NAME_LABEL_COLOR_TO_FILL_COLOR[nameLabelColor],
      },
      active ? styles.nameLabelColorActive : null,
    ];
  }, [active]);
  return (
    <Pressable key={nameLabelColor} onPress={onPress}>
      <View key={nameLabelColor} style={style} />
    </Pressable>
  );
};

const propsAreEqual = (
  prevProps: Readonly<Props>,
  nextProps: Readonly<Props>,
): boolean => {
  return prevProps.active === nextProps.active;
};

export default React.memo(NameLabelColorButton, propsAreEqual);

const styles = StyleSheet.create({
  nameLableColor: {
    borderRadius: 50,
    height: 24,
    overflow: 'hidden',
    width: 24,
    marginHorizontal: 5,
  } as TextStyle,
  nameLabelColorActive: {
    borderColor: '#ff8f13',
    borderWidth: 2,
  } as TextStyle,
});
