import EntityMapper from './EntityMapper';

import ActorCharacterMapper, {ActorCharacterData} from './ActorCharacterMapper';

import ActorAccessory from '../../../domain/entities/ActorAccessory';

export interface ActorAccessoryData {
  id: number;
  name: string;
  original_image_url: string;
  actor_character: ActorCharacterData;
}

export default class ActorAccessoryMapper
  implements EntityMapper<ActorAccessoryData, ActorAccessory>
{
  private actorCharacterMapper: ActorCharacterMapper;

  constructor() {
    this.actorCharacterMapper = new ActorCharacterMapper();
  }

  public map(obj: ActorAccessoryData): ActorAccessory {
    const actorCharacter = this.actorCharacterMapper.map(obj.actor_character);
    return new ActorAccessory(
      obj.id,
      obj.name,
      obj.original_image_url,
      actorCharacter,
    );
  }
}
