import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import ActorCharacterFace from '../../../domain/entities/ActorCharacterFace';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetActorCharacterFacesRepository from '../../../data/repositories/writer/NetActorCharacterFacesRepository';

const types = {
  failure: Types.IndexActorCharacterFacesFailure,
  request: Types.IndexActorCharacterFacesRequest,
  success: Types.IndexActorCharacterFacesSuccess,
};

export interface Params extends BaseParams {
  characterPatternId?: number;
  actorCharacterId?: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<ActorCharacterFace>> {
  return indexGenerics(new NetActorCharacterFacesRepository(), types, params);
}
