import EntityMapper from '../EntityMapper';

import UserProfileMapper, {UserProfileData} from './UserProfileMapper';
import WriterUserMapper, {
  UserData as WriterUserData,
} from '../writer/UserMapper';

import User from '../../../../domain/entities/consumer/User';

export interface UserData {
  id: number;
  user_name: string;
  inhouse_staff: boolean;
  user_profile: UserProfileData | null | undefined;
  linked_writer_user: WriterUserData | null;
}

export default class UserMapper implements EntityMapper<UserData, User> {
  private userProfileMapper = new UserProfileMapper();
  private writerUserMapper = new WriterUserMapper();

  public map(obj: UserData): User {
    return new User(
      obj.id,
      obj.user_name,
      obj.inhouse_staff,
      obj.user_profile ? this.userProfileMapper.map(obj.user_profile) : null,
      obj.linked_writer_user
        ? this.writerUserMapper.map(obj.linked_writer_user)
        : null,
    );
  }
}
