import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';
import {WriterTemporaryUserRegistrationCompletionRouteProp} from '../../../../navigators/RouteProps';

import * as routers from '../../../../routers';

import {} from '../../../../actions/writer/temporary_users/terms_acceptance/create';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: WriterTemporaryUserRegistrationCompletionRouteProp;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<CurrentUser>;
  createWriterTemporaryUsersTermsAcceptance: () => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
}

export default class New extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {loading: false};
  }

  public componentDidMount() {
    const {navigation, showCurrentUser} = this.props;
    navigation.addListener('focus', () => {
      showCurrentUser().then(currentUser => {
        if (currentUser.acceptedTerms) {
          navigation.goBack();
        }
      });
    });
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {loading} = this.state;
    return (
      <Layout
        title={'サービス利用開始'}
        scrollable={false}
        navigation={navigation}
        loading={loading}>
        <Form
          onPressPoliciesPrivacy={this.handlePressPoliciesPrivacy}
          onPressPoliciesTerms={this.handlePressPoliciesTerms}
          onSubmit={this.handleSubmit}
        />
      </Layout>
    );
  }

  private handlePressPoliciesPrivacy = () => {
    const {navigation} = this.props;
    routers.linkToPoliciesPrivacyModal(navigation);
  };

  private handlePressPoliciesTerms = () => {
    const {navigation} = this.props;
    routers.linkToPoliciesTermsModal(navigation);
  };

  private handleSubmit = () => {
    const {navigation, createWriterTemporaryUsersTermsAcceptance} = this.props;
    createWriterTemporaryUsersTermsAcceptance().then(() => {
      navigation.goBack();
    });
  };
}
