import React from 'react';

import RootStack from '../../../RootStack';

import ShopActorSearchForm from '../../../../containers/shop/actor_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="ShopActorSearchForm">
      <RootStack.Screen
        name="ShopActorSearchForm"
        component={ShopActorSearchForm}
        options={{title: 'キャラクター検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
