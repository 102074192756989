import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../components/response/stories/Show';

import AppState from '../../../reducers/AppState';

import showResponseStory from '../../../actions/response/stories/show';
import updateResponseStory, {
  Params as ResponseStoryUpdateParams,
} from '../../../actions/response/stories/update';
import indexResponseEpisodes, {
  Params as ResponseEpisodeIndexParams,
} from '../../../actions/response/episodes/index';

import selectEntity from '../../../helpers/selectEntity';
import selectEntities from '../../../helpers/selectEntities';

import {ResponseStoryRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: ResponseStoryRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {storyId} = route.params;
  const {entities, queries} = state;
  const episodesParams: ResponseEpisodeIndexParams = {storyId};
  const story = selectEntity(entities.response.stories, storyId);
  const episodes = selectEntities(
    entities.response.episodes,
    queries.response.episodes,
    episodesParams,
  );
  return {
    navigation,
    story,
    episodes,
    episodesParams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showResponseStory: (id: number) => dispatch(showResponseStory(id)),
    updateResponseStory: (id: number, params: ResponseStoryUpdateParams) =>
      dispatch(updateResponseStory(id, params)),
    indexResponseEpisodes: (params: ResponseEpisodeIndexParams) =>
      dispatch(indexResponseEpisodes(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
