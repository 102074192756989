import EntityMapper from '../../EntityMapper';

import PaymentMethod from '../../../../../domain/entities/writer/my_stripe/PaymentMethod';

export interface PaymentMethodData {
  id: number;
  stripe_payment_method_id: string;
  card_brand: string;
  card_last4: string;
  card_exp_month: string;
  card_exp_year: string;
  default_payment_method: boolean;
  created_at: string;
  updated_at: string;
}

export default class PaymentMethodMapper
  implements EntityMapper<PaymentMethodData, PaymentMethod>
{
  public map(obj: PaymentMethodData): PaymentMethod {
    return new PaymentMethod(
      obj.id,
      obj.stripe_payment_method_id,
      obj.card_brand,
      obj.card_last4,
      obj.card_exp_month,
      obj.card_exp_year,
      obj.default_payment_method,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
