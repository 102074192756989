const loadScript = (src: string, success: () => void, fail: () => void) => {
  const head = document.head || document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = src;
  script.onload = function () {
    this.onerror = this.onload = null;
    success();
  };
  script.onerror = function () {
    this.onerror = this.onload = null;
    fail();
  };
  head.appendChild(script);
};

export default loadScript;
