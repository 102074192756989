import * as React from 'react';
import {ImageSourcePropType} from 'react-native';

import Form from './partials/Form';
import PrivateConfirmModal from './partials/PrivateConfirmModal';

import Layout from '../../shared/Layout';
import TooltipModal from '../../shared/modals/TooltipModal';

import * as routers from '../../../routers';

import NavigationProp from '../../../navigators/NavigationProp';
import {StoryFormStoryPublicationRouteProp} from '../../../navigators/RouteProps';

import {Params as StoryPublicationUpdateParams} from '../../../actions/story_publications/update';
import {Params as EpisodeIndexParams} from '../../../actions/episodes/index';
import {Params as CharacterIndexParams} from '../../../actions/characters/index';

import Story from '../../../../domain/entities/Story';
import Episode from '../../../../domain/entities/Episode';
import Character from '../../../../domain/entities/Character';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

type TooltipModalType = 'published';

const TypeToTooltipInfo = {
  published: {
    title: '公開設定',
    description: '',
    source: null,
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source: ImageSourcePropType | null;
  };
};

export interface Params {
  storyId: number;
  episodeIdToBePublished?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: StoryFormStoryPublicationRouteProp;
  story: Story | null;
}

export interface DispatchProps {
  showStory: (id: number) => Promise<Story>;
  updateStoryPublication: (
    id: number,
    params: StoryPublicationUpdateParams,
  ) => Promise<Story>;
  indexEpisodes: (
    params: EpisodeIndexParams,
  ) => Promise<PaginatedResult<Episode>>;
  indexCharacters: (
    params: CharacterIndexParams,
  ) => Promise<PaginatedResult<Character>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  published: boolean;
  loading: boolean;
  tooltipModalType: TooltipModalType | null;
  visiblePrivateConfirmModal: boolean;
  entryContestInfo?: {
    contest_id: number;
    errors: string[];
  };
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      tooltipModalType: null,
      loading: false,
      published: props.story?.published || false,
      visiblePrivateConfirmModal: false,
    };
  }

  public componentDidMount() {
    const {showStory, route} = this.props;
    const {storyId} = route.params;
    showStory(storyId);
    TapNovelRestApi.post('/api/writer/contest_entry_story_validations', {
      storyId,
    }).then(res => {
      if (res.body) {
        this.setState({
          entryContestInfo: res.body as any,
        });
      }
    });
  }

  public render(): React.ReactNode {
    const {navigation, story} = this.props;
    const {
      published,
      loading,
      tooltipModalType,
      visiblePrivateConfirmModal,
      entryContestInfo,
    } = this.state;
    const modalInfo = tooltipModalType
      ? TypeToTooltipInfo[tooltipModalType]
      : null;
    return (
      <Layout
        title={`ストーリーの公開設定`}
        close={true}
        navigation={navigation}
        loading={loading}>
        <Form
          published={published}
          entryContestInfo={entryContestInfo}
          disabled={!!story?.entryContestId}
          onSelectItem={this.handleSelectItem}
          onPress={this.handleSubmit}
        />
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            onCloseModal={() => {
              this.setState({tooltipModalType: null});
            }}></TooltipModal>
        ) : null}
        <PrivateConfirmModal
          visible={visiblePrivateConfirmModal}
          onCancel={this.handleCancel}
          onChangePrivate={this.handleChangePrivate}
        />
      </Layout>
    );
  }

  private handleSelectItem = (item: {label: string; value: string}) => {
    this.setState({published: item.value === 'true'});
  };

  private handleCancel = () => {
    this.setState({visiblePrivateConfirmModal: false});
  };

  private handleChangePrivate = () => {
    this.setState(
      {visiblePrivateConfirmModal: false, loading: true},
      this.updateStoryStatus,
    );
  };

  private handleSubmit = () => {
    const {story} = this.props;
    const {published} = this.state;
    if (!story) {
      return;
    }
    if (published) {
      this.updateStoryStatus();
    } else {
      this.setState({visiblePrivateConfirmModal: true});
    }
  };

  private updateStoryStatus = () => {
    const {
      navigation,
      route,
      updateStoryPublication,
      indexEpisodes,
      indexCharacters,
    } = this.props;
    const {storyId, episodeIdToBePublished} = route.params;
    const {published} = this.state;
    const enableSyncEpisodesPrivateStatus = !published;
    const enableSyncCharactersPrivateStatus = !published;
    this.setState({visiblePrivateConfirmModal: false, loading: true}, () => {
      updateStoryPublication(storyId, {
        published,
        enableSyncEpisodesPrivateStatus,
        enableSyncCharactersPrivateStatus,
      })
        .then(() => {
          indexEpisodes({storyId}).then(() => {
            this.setState({loading: false}, () => {
              if (episodeIdToBePublished) {
                (navigation.getParent() || navigation).goBack();
                if (published) {
                  routers.linkToEpisodeFormEditEpisodePublication(navigation, {
                    episodeId: episodeIdToBePublished,
                  });
                }
              } else if (published) {
                navigation.navigate('StoryFormStoryPublicationCompletion', {
                  storyId,
                });
              } else {
                navigation.goBack();
              }
            });
          });
          indexCharacters({storyId});
        })
        .catch(() => {
          this.setState({loading: false});
        });
    });
  };
}
