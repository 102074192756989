import * as React from 'react';

import SpeechTextViewWithEditorModal from './visualizations/SpeechTextViewWithEditorModal';

import {ModalParams} from '../../Modal';

import SceneFrame from '../../../../../view_models/SceneFrame';

import SpeechBalloon from '../../../../../../domain/entities/SpeechBalloon';
import OrientedSpeechBalloon from '../../../../../../domain/entities/OrientedSpeechBalloon';
import Sound from '../../../../../../domain/entities/Sound';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';

interface Props {
  storyId: number;
  sceneCommandForm: SpeechTextShowSceneCommandForm;
  speechBalloons: SpeechBalloon[] | null;
  orientedSpeechBalloons: OrientedSpeechBalloon[] | null;
  sceneFrame: SceneFrame;
  enableVoice?: boolean;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestCloseModal: () => void;
  onRemoveSpeechTextShowCommand: (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
  ) => void;
  onChangeSpeechTextShowSceneCommandForm: (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
  ) => void;
  onRequestIndexSpeechBalloons: () => void;
  onRequestIndexOrientedSpeechBalloons: () => void;
  onChangeCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
  onForwardToAddSoundEffects: (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
    parentSceneCommandId?: number,
    callback?: (sound: Sound) => void,
  ) => void;
  onRequestUpdateModal: (modalParams: ModalParams) => void;
}

export default class SpeechTextShowSceneCommandModal extends React.PureComponent<Props> {
  public componentDidMount() {
    this.requestEntities();
  }

  public render(): React.ReactNode {
    const {speechBalloons, orientedSpeechBalloons} = this.props;
    if (!speechBalloons || !orientedSpeechBalloons) {
      return null;
    }
    return (
      <SpeechTextViewWithEditorModal
        {...this.props}
        speechBalloons={speechBalloons}
        orientedSpeechBalloons={orientedSpeechBalloons}
      />
    );
  }

  private requestEntities = () => {
    const {
      speechBalloons,
      orientedSpeechBalloons,
      onRequestIndexSpeechBalloons,
      onRequestIndexOrientedSpeechBalloons,
    } = this.props;
    if (!speechBalloons) {
      onRequestIndexSpeechBalloons();
    }
    if (!orientedSpeechBalloons) {
      onRequestIndexOrientedSpeechBalloons();
    }
  };
}
