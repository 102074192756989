import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import StoryListItem from './StoryListItem';

import shouldUpdateEntityWithUpdatedAtList from '../../../shared/enhanced/shouldUpdateEntityWithUpdatedAtList';
import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Story from '../../../../../domain/entities/response/Story';

interface Props {
  stories: Story[];
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  onSelectStory: (story: Story) => void;
}

const StoryList: React.FunctionComponent<Props> = props => {
  const {stories, ListHeaderComponent} = props;
  const keyExtractor = React.useCallback(
    (story: Story, index: number): string => `${story.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Story>): React.ReactElement<any> | null => {
      const {item: story} = info;
      const {onSelectStory} = props;
      return <StoryListItem story={story} onSelectStory={onSelectStory} />;
    },
    [],
  );
  return (
    <FlatList
      data={stories}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
      onEndReachedThreshold={0.1}
    />
  );
};

const propsAreEqual = (
  prevProps: Readonly<Props>,
  nextProps: Readonly<Props>,
) => {
  return !shouldUpdateEntityWithUpdatedAtList(
    {entities: prevProps.stories},
    {entities: nextProps.stories},
  );
};

export default React.memo(StoryList, propsAreEqual);
