import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../components/settings/Index';

import AppState from '../../reducers/AppState';

import showCurrentUser from '../../actions/writer/current_user/show';
import showCurrentUserStatus from '../../actions/writer/current_user_status/show';

import destroyWriterCurrentUserSession from '../../actions/writer/users/session/destroy';

import selectEntity from '../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  return {
    navigation,
    currentUser,
    currentUserStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUser: () => dispatch(showCurrentUser()),
    showCurrentUserStatus: () => dispatch(showCurrentUserStatus()),
    destroyWriterCurrentUserSession: () =>
      dispatch(destroyWriterCurrentUserSession()),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
