import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/character_form/new/character_maker/actors/Index';

import AppState from '../../../../../reducers/AppState';

import indexActorCharacterFaces, {
  Params as ActorCharacterFaceIndexParams,
} from '../../../../../actions/actor_character_faces/index';
import updateCharacterForm, {
  Params as CharacterFormUpdateParams,
} from '../../../../../actions/character_forms/update';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexActorCharacterFaces: (params: ActorCharacterFaceIndexParams) =>
      dispatch(indexActorCharacterFaces(params)),
    updateCharacterForm: (params: CharacterFormUpdateParams) =>
      dispatch(updateCharacterForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
