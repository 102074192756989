import EntityMapper from './EntityMapper';

import CharacterMakerActorGroup from '../../../domain/entities/CharacterMakerActorGroup';

export interface CharacterMakerActorGroupData {
  id: number;
  name: string;
}

export default class CharacterMakerActorGroupMapper
  implements
    EntityMapper<CharacterMakerActorGroupData, CharacterMakerActorGroup>
{
  public map(obj: CharacterMakerActorGroupData): CharacterMakerActorGroup {
    return new CharacterMakerActorGroup(obj.id, obj.name);
  }
}
