import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {BarChart} from 'react-native-chart-kit';

import DimensionContext from '../../../shared/dimension/DimensionContext';

import EpisodePageViewAggregation from '../../../../../domain/value_objects/summary/EpisodePageViewAggregation';

interface Props {
  episodePageViewAggregations: Array<EpisodePageViewAggregation>;
}

function generateData(
  episodePageViewAggregations: Array<EpisodePageViewAggregation>,
) {
  return {
    labels: episodePageViewAggregations.map(
      episodePageViewAggregation => episodePageViewAggregation.formatDate,
    ),
    datasets: [
      {
        data: episodePageViewAggregations.map(
          episodePageViewAggregation => episodePageViewAggregation.pageView,
        ),
        colors: episodePageViewAggregations.map(() => () => '#ff8f13'),
      },
    ],
  };
}

function calcSegments(pageViews: Array<number>) {
  const max = Math.max(...pageViews) || 0;
  if (max === 0) {
    return 1;
  } else if (max < 5) {
    return 2;
  }
  return 4;
}

const StoryBrowsingTransitionGraph: React.FunctionComponent<Props> = props => {
  const {episodePageViewAggregations} = props;
  const indices = episodePageViewAggregations.map((v, i) => i);
  const hidePointsAtIndex =
    indices.length <= 5
      ? []
      : indices.filter(i => i % Math.ceil(indices.length / 5) !== 0);
  const segments = calcSegments(
    episodePageViewAggregations.map(o => o.pageView),
  );
  return (
    <View style={styles.container}>
      <Text style={styles.title}>閲覧推移</Text>
      <DimensionContext.Consumer>
        {context => {
          const width = context.content.width - 32;
          return (
            <BarChart
              style={{
                backgroundColor: 'white',
                alignItems: 'center',
                paddingRight: 8,
              }}
              data={generateData(episodePageViewAggregations)}
              width={width}
              height={(width / 375) * 286}
              xAxisLabel={''}
              yAxisLabel={''}
              yAxisSuffix={''}
              yLabelsOffset={-width + 8}
              fromZero={true}
              showBarTops={false}
              flatColor={true}
              hidePointsAtIndex={hidePointsAtIndex}
              withCustomBarColorFromData={true}
              segments={segments}
              chartConfig={{
                barPercentage: Math.min(
                  1,
                  1 / (episodePageViewAggregations.length * 0.15),
                ),
                color: () => '#ff8f13',
                fillShadowGradientOpacity: 1,
                fillShadowGradient: '#ff8f13',
                backgroundGradientFrom: 'white',
                backgroundGradientTo: 'white',
                propsForBackgroundLines: {
                  strokeDasharray: '0',
                  strokeWidth: 0.5,
                  stroke: '#efefef',
                },
                propsForHorizontalLabels: {
                  fill: '#383838',
                },
                propsForVerticalLabels: {
                  fill: '#383838',
                },
                decimalPlaces: segments == 2 ? 1 : 0,
              }}
              ///            verticalLabelRotation={30}
            />
          );
        }}
      </DimensionContext.Consumer>
    </View>
  );
};

export default React.memo(StoryBrowsingTransitionGraph);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    marginVertical: 16,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    margin: 16,
    marginBottom: 0,
  } as TextStyle,
});
