import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import NewEpisodeVideoExportJobExecutionButton from './NewEpisodeVideoExportJobExecutionButton';

interface Props {
  onForwardToNewEpisodeVideoExportJobExecution: () => void;
}

const NoEpisodeVideoExportJobExecutions: React.FunctionComponent<
  Props
> = props => {
  const {onForwardToNewEpisodeVideoExportJobExecution} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.message}>まだ作成した動画はありません</Text>
      <NewEpisodeVideoExportJobExecutionButton
        onForwardToNewEpisodeVideoExportJobExecution={
          onForwardToNewEpisodeVideoExportJobExecution
        }
      />
    </View>
  );
};

export default React.memo(NoEpisodeVideoExportJobExecutions);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
});
