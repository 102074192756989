import Entity from '../Entity';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class UserProfile implements Entity {
  constructor(
    public id: number,
    public description: string,
    public hasImage: boolean,
    public originalImageUrl: string,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }
}
