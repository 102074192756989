import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormEffectShowSceneCommandEditEffectUsageHistories from '../../../../containers/scene_form/effect_show_scene_command/edit/effect_usage_histories/Index';
import SceneFormEffectShowSceneCommandEditBaseEffectSearchForm from '../../../../containers/scene_form/effect_show_scene_command/edit/base_effect_search_form/Index';
import SceneFormEffectShowSceneCommandEditBaseEffects from '../../../../containers/scene_form/effect_show_scene_command/edit/base_effects/Index';
import SceneFormEffectShowSceneCommandEditEffects from '../../../../containers/scene_form/effect_show_scene_command/edit/effects/Index';
import SceneFormEffectShowSceneCommandEditEffectPositions from '../../../../containers/scene_form/effect_show_scene_command/edit/effect_positions/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormEffectShowSceneCommandEditEffectUsageHistories">
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandEditEffectUsageHistories"
        component={SceneFormEffectShowSceneCommandEditEffectUsageHistories}
        options={{title: 'エフェクト'}}
      />
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandEditBaseEffectSearchForm"
        component={SceneFormEffectShowSceneCommandEditBaseEffectSearchForm}
        options={{title: 'エフェクト検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandEditBaseEffects"
        component={SceneFormEffectShowSceneCommandEditBaseEffects}
        options={{title: 'エフェクト'}}
      />
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandEditEffects"
        component={SceneFormEffectShowSceneCommandEditEffects}
        options={{title: 'エフェクト'}}
      />
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandEditEffectPositions"
        component={SceneFormEffectShowSceneCommandEditEffectPositions}
        options={{title: 'エフェクト'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
