import React from 'react';

import RootStack from '../../../../RootStack';

import SceneFormSoundEffectShowSceneCommandEditSoundSearchForm from '../../../../../containers/scene_form/sound_effect_show_scene_command/edit/sound_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormSoundEffectShowSceneCommandEditSoundSearchForm">
      <RootStack.Screen
        name="SceneFormSoundEffectShowSceneCommandEditSoundSearchForm"
        component={SceneFormSoundEffectShowSceneCommandEditSoundSearchForm}
        options={{title: '効果音検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
