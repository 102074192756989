import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ContestKeywordTag from './ContestKeywordTag';

import Contest from '../../../../../domain/entities/Contest';

interface Props {
  contests?: Contest[] | null;
  onPress: (contest: Contest) => void;
}

const ContestKeywordTags: React.FunctionComponent<Props> = props => {
  const {contests, onPress} = props;
  if (!contests || contests.length === 0) {
    return null;
  }
  return (
    <View style={styles.container}>
      <Text style={styles.title}>開催中のコンテストキーワード</Text>
      <View style={styles.contests}>
        {contests.map(contest => (
          <ContestKeywordTag contest={contest} onPress={onPress} />
        ))}
      </View>
    </View>
  );
};

export default React.memo(ContestKeywordTags);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#efefef',
    width: '100%',
    minHeight: 80,
    padding: 16,
    bottom: 60,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  contests: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  } as ViewStyle,
});
