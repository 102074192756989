import * as React from 'react';

import styled from 'styled-components';

interface Props {}

const Footer: React.FunctionComponent<Props> = props => {
  return (
    <Container signedIn={false}>
      <Copyright>
        <small>© Imagica Infos Co., Ltd.</small>
      </Copyright>
    </Container>
  );
};

interface ContainerProps {
  signedIn: boolean;
}

const Container = styled.footer<ContainerProps>`
  display: block;
  position: relative;
  text-align: center;
  padding: 0 0 10px;
  margin-top: -20px;
  z-index: 2;
  @media (min-width: 1000px) {
    display: block;
  }
  ${props =>
    props.signedIn
      ? `
      display: none;
      @media (min-width: 1000px) {
        display: block;
      }
  `
      : null}
`;

const Copyright = styled.p`
  font-size: 10px;
  color: #a9a097;
  text-align: center;
`;

export default React.memo(Footer);
