import * as React from 'react';

import NotificationSettingList from './NotificationSettingList';

import WriterUserNotificationSettingsBatch, {
  WriterUserNotificationSetting,
} from '../../../../../domain/entities/writer/UserNotificationSettingsBatch';

interface Props {
  writerUserNotificationSettingsBatch: WriterUserNotificationSettingsBatch;
  onValueChangeWriterUserNotificationSetting: (
    writerUserNotificationSetting: WriterUserNotificationSetting,
    enabled: boolean,
  ) => void;
  children?: React.ReactNode;
}

const GroupedNotificationSettingList: React.FunctionComponent<
  Props
> = props => {
  const {
    writerUserNotificationSettingsBatch,
    onValueChangeWriterUserNotificationSetting,
  } = props;
  return (
    <NotificationSettingList
      writerUserNotificationSettings={
        writerUserNotificationSettingsBatch.writerUserNotificationSettings
      }
      index={0}
      onValueChangeWriterUserNotificationSetting={
        onValueChangeWriterUserNotificationSetting
      }
    />
  );
};

export default React.memo(GroupedNotificationSettingList);
