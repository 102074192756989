import * as React from 'react';
import {
  ImageStyle,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import MultiSwitch from '../../../shared/forms/MultiSwitch';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import TooltipModal from '../../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../../shared/KeyboardSpacer';
import AlertModal from '../../../shared/modals/AlertModal';

import PlusIcon from '../../../shared/icons/project/PlusIcon';
import MinusIcon from '../../../shared/icons/project/MinusIcon';

import {colors, formStyles} from '../../../../styles/variables';

import {
  EpisodeCopyright,
  SecondaryUse,
} from '../../../../../domain/entities/Project';

export type Form2Values = {
  episodeCopyright: EpisodeCopyright;
  episodePurchasePrice: number | null;
  secondaryUse: SecondaryUse;
  allowedChangeOnSecondaryUse: boolean | null;
  requiredChangePermissionOnSecondaryUse: boolean | null;
  allowedVideoSharing: boolean;
};

type Form2ErrorMessage = {[P in keyof Form2Values]?: string | string[]};

interface Props {
  values?: Form2Values;
  editableEpisodeCopyright: boolean;
  errorMessages?: Form2ErrorMessage;
  onSubmit: (values: Form2Values) => void;
}

const FormStep1: React.FC<Props> = props => {
  const {values, errorMessages, editableEpisodeCopyright, onSubmit} = props;
  const [episodeCopyright, setEpisodeCopyright] =
    React.useState<EpisodeCopyright>(
      values?.episodeCopyright || 'author_owned',
    );
  const [episodePurchasePrice, setEpisodePurchasePrice] = React.useState<
    number | null
  >(
    episodeCopyright === 'author_owned'
      ? null
      : values?.episodePurchasePrice || 100,
  );
  const [secondaryUse, setSecondaryUse] = React.useState<SecondaryUse>(
    values?.secondaryUse === undefined
      ? 'only_uncommercial_personal_use'
      : values?.secondaryUse,
  );
  const [allowedChangeOnSecondaryUse, setAllowedChangeOnSecondaryUse] =
    React.useState<boolean | null>(
      values?.allowedChangeOnSecondaryUse === undefined
        ? null
        : values?.allowedChangeOnSecondaryUse,
    );
  const [
    requiredChangePermissionOnSecondaryUse,
    setRequiredChangePermissionOnSecondaryUse,
  ] = React.useState<boolean | null>(
    values?.requiredChangePermissionOnSecondaryUse === undefined
      ? null
      : values?.requiredChangePermissionOnSecondaryUse,
  );
  const [allowedVideoSharing, setAllowedVideoSharing] = React.useState<boolean>(
    values?.allowedVideoSharing === undefined
      ? false
      : values?.allowedVideoSharing,
  );
  const [tooltipModalType, setTooltipModalType] = React.useState<
    keyof Form2Values | null
  >(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onChangeEpisodeCopyright = React.useCallback(
    (item: {label: string; value: string}) => {
      setEpisodeCopyright(item.value as EpisodeCopyright);
    },
    [],
  );
  const onChangeSecondaryUse = React.useCallback(
    (item: {label: string; value: string}) => {
      setSecondaryUse(item.value as SecondaryUse);
    },
    [],
  );
  const onChangeAllowedChangeOnSecondaryUse = React.useCallback(
    (item: {label: string; value: string}) => {
      setAllowedChangeOnSecondaryUse(item.value == 'true');
    },
    [],
  );
  const onChangeRequiredChangePermissionOnSecondaryUse = React.useCallback(
    (item: {label: string; value: string}) => {
      setRequiredChangePermissionOnSecondaryUse(item.value == 'true');
    },
    [],
  );
  const onChangeAllowedVideoSharing = React.useCallback(
    (item: {label: string; value: string}) => {
      setAllowedVideoSharing(item.value == 'true');
    },
    [],
  );
  const onCloseModalTooltip = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const onPressQuestionEpisodeCopyright = React.useCallback(() => {
    setTooltipModalType('episodeCopyright');
  }, []);
  const onPressQuestionEpisodePurchasePrice = React.useCallback(() => {
    setTooltipModalType('episodePurchasePrice');
  }, []);
  const onPressQuestionAllowedSecondaryUse = React.useCallback(() => {
    setTooltipModalType('secondaryUse');
  }, []);
  const onPressQuestionAllowedChangeOnSecondaryUse = React.useCallback(() => {
    setTooltipModalType('allowedChangeOnSecondaryUse');
  }, []);
  const onPressQuestionRequiredChangePermissionOnSecondaryUse =
    React.useCallback(() => {
      setTooltipModalType('requiredChangePermissionOnSecondaryUse');
    }, []);
  const onPressQuestionAllowedVideoSharing = React.useCallback(() => {
    setTooltipModalType('allowedVideoSharing');
  }, []);
  const onPressAmountMinus = React.useCallback(() => {
    if (episodeCopyright === 'author_owned' || episodePurchasePrice === null) {
      return;
    }
    const newEpisodePurchasePrice = episodePurchasePrice - 100;
    if (newEpisodePurchasePrice > 0) {
      setEpisodePurchasePrice(newEpisodePurchasePrice);
    }
  }, [episodePurchasePrice, episodeCopyright]);
  const onPressAmountPlus = React.useCallback(() => {
    if (episodeCopyright === 'author_owned' || episodePurchasePrice === null) {
      return;
    }
    const newEpisodePurchasePrice = episodePurchasePrice + 100;
    if (newEpisodePurchasePrice > 0) {
      setEpisodePurchasePrice(newEpisodePurchasePrice);
    }
  }, [episodePurchasePrice, episodeCopyright]);
  React.useEffect(() => {
    if (secondaryUse === 'denied_secondary_use') {
      setAllowedChangeOnSecondaryUse(null);
      setRequiredChangePermissionOnSecondaryUse(null);
    } else if (secondaryUse === 'only_uncommercial_personal_use') {
      setAllowedChangeOnSecondaryUse(true);
    }
  }, [secondaryUse]);
  React.useEffect(() => {
    if (allowedChangeOnSecondaryUse) {
      setRequiredChangePermissionOnSecondaryUse(false);
    } else {
      setRequiredChangePermissionOnSecondaryUse(null);
    }
  }, [allowedChangeOnSecondaryUse]);
  React.useEffect(() => {
    if (episodeCopyright === 'author_owned') {
      setEpisodePurchasePrice(null);
    } else {
      setEpisodePurchasePrice(100);
    }
  }, [episodeCopyright]);
  const onPress = () => {
    onSubmit({
      episodeCopyright,
      episodePurchasePrice,
      secondaryUse,
      allowedChangeOnSecondaryUse,
      requiredChangePermissionOnSecondaryUse,
      allowedVideoSharing,
    });
  };
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{paddingBottom: 130}}>
        {editableEpisodeCopyright ? (
          <>
            <View style={formStyles.formGroup}>
              <LabelWithOption
                title={'エピソードの著作権'}
                onPressQuestion={onPressQuestionEpisodeCopyright}
              />
              <MultiSwitch
                items={EPISODE_COPYRIGHT_ITEMS}
                value={episodeCopyright}
                onSelectItem={onChangeEpisodeCopyright}
              />
            </View>

            {episodeCopyright == 'leader_buy_out' ? (
              <View style={formStyles.formGroup}>
                <LabelWithOption
                  title={'買い取り報酬額'}
                  onPressQuestion={onPressQuestionEpisodePurchasePrice}
                />
                <View style={styles.amount}>
                  <View style={styles.amountLeft}>
                    <Text style={styles.amountLeftUnit}>￥</Text>
                    <Text style={styles.amountLeftValue}>
                      {numeral(episodePurchasePrice).format('0,0')}
                    </Text>
                  </View>
                  <View style={styles.amountRight}>
                    <TouchableOpacity
                      style={styles.amountRightMinusButton}
                      onPress={onPressAmountMinus}>
                      <MinusIcon />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={styles.amountRightPlusButton}
                      onPress={onPressAmountPlus}>
                      <PlusIcon />
                    </TouchableOpacity>
                  </View>
                </View>
              </View>
            ) : null}
          </>
        ) : null}
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'二次利用'}
            onPressQuestion={onPressQuestionAllowedSecondaryUse}
          />
          <MultiSwitch
            items={SECONDARY_USE_ITEMS}
            value={secondaryUse}
            onSelectItem={onChangeSecondaryUse}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'二次利用時の改変'}
            onPressQuestion={onPressQuestionAllowedChangeOnSecondaryUse}
          />
          <MultiSwitch
            items={ALLOWED_CHANGE_ON_SECONDARY_USE_ITEMS}
            value={`${allowedChangeOnSecondaryUse}`}
            disabled={secondaryUse === 'denied_secondary_use'}
            onSelectItem={onChangeAllowedChangeOnSecondaryUse}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'二次利用時の改変の許可'}
            onPressQuestion={
              onPressQuestionRequiredChangePermissionOnSecondaryUse
            }
          />
          <MultiSwitch
            items={REQUIRED_CHANGE_PERMISSION_ON_SECONDARY_USE_ITEMS}
            value={`${requiredChangePermissionOnSecondaryUse}`}
            disabled={
              secondaryUse === 'denied_secondary_use' ||
              !allowedChangeOnSecondaryUse
            }
            onSelectItem={onChangeRequiredChangePermissionOnSecondaryUse}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'第三者による動画共有サービス設定'}
            onPressQuestion={onPressQuestionAllowedVideoSharing}
          />
          <MultiSwitch
            items={ALLOWED_VIDEO_SHARING_ITEMS}
            value={`${allowedVideoSharing}`}
            onSelectItem={onChangeAllowedVideoSharing}
          />
        </View>
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            imageStyle={modalInfo.imageStyle}
            source={modalInfo.source}
            onCloseModal={onCloseModalTooltip}></TooltipModal>
        ) : null}
        <KeyboardSpacer />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <View style={styles.button}>
          <Text
            style={{
              width: '100%',
              textAlign: 'center',
              color: '#f23406',
              fontSize: 10,
              marginBottom: 8,
            }}>
            権利情報はあとで変更できません
          </Text>
          <PrimaryButton disabled={false} onPress={onPress}>
            次へ
          </PrimaryButton>
        </View>
      </View>
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
    </View>
  );
};

export default React.memo(FormStep1);

const EPISODE_COPYRIGHT_ITEMS: Array<{
  label: string;
  value: EpisodeCopyright;
}> = [
  {
    label: '執筆したメンバーが保有',
    value: 'author_owned',
  },
  {
    label: 'リーダーが買い取り',
    value: 'leader_buy_out',
  },
];
const SECONDARY_USE_ITEMS: Array<{
  label: string;
  value: SecondaryUse;
}> = [
  {
    label: '非営利目的での個人利用OK',
    value: 'only_uncommercial_personal_use',
  },
  {
    label: '二次利用NG',
    value: 'denied_secondary_use',
  },
];
const ALLOWED_CHANGE_ON_SECONDARY_USE_ITEMS: Array<{
  label: string;
  value: 'true' | 'false';
}> = [
  {
    label: '改変OK',
    value: 'true',
  },
  {
    label: '改変NG',
    value: 'false',
  },
];
const REQUIRED_CHANGE_PERMISSION_ON_SECONDARY_USE_ITEMS: Array<{
  label: string;
  value: 'true' | 'false';
}> = [
  {
    label: '許可不要（事後差し止め）',
    value: 'false',
  },
  {
    label: '許可必要（事前確認必須）',
    value: 'true',
  },
];
const ALLOWED_VIDEO_SHARING_ITEMS: Array<{
  label: string;
  value: 'true' | 'false';
}> = [
  {
    label: '投稿NG',
    value: 'false',
  },
  {
    label: '投稿OK',
    value: 'true',
  },
];

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
  modalText: {
    marginVertical: 4,
  } as TextStyle,
  amount: {
    flexDirection: 'row',
  } as ViewStyle,
  amountLeft: {
    flex: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#efefef',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: 8,
  } as ViewStyle,
  amountLeftUnit: {
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
  amountLeftValue: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  amountRight: {
    flexDirection: 'row',
    width: 65,
    height: 32,
  } as ViewStyle,
  amountRightMinusButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#383838',
    borderTopLeftRadius: 3,
    borderBottomLeftRadius: 3,
    borderRightWidth: 0.5,
    borderRightColor: '#383838',
  } as ViewStyle,
  amountRightPlusButton: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: '#383838',
    borderTopEndRadius: 3,
    borderBottomEndRadius: 3,
    borderLeftWidth: 0.5,
    borderLeftColor: '#383838',
  } as ViewStyle,
});

const TypeToTooltipInfo = {
  episodeCopyright: {
    title: '著作権',
    description: (
      <View>
        <Text style={styles.modalText}>
          メンバーが執筆したエピソードの著作権について定めることができます。
        </Text>
        <Text style={styles.modalText}>
          メンバーに保有を選択した場合は、エピソードの著作権はエピソードを執筆したメンバーに保持されます。
          {'\n'}
          買い取りを選択した場合は、エピソードの著作権は公開と同時にリーダーに移動されます。
        </Text>
        <Text style={styles.modalText}>
          買い取りの場合は報酬を設定する必要があります。
        </Text>
      </View>
    ),
  },
  episodePurchasePrice: {
    title: '買い取り報酬額',
    description: (
      <View>
        <Text style={styles.modalText}>
          メンバーが執筆したエピソードの著作権を買い取る場合に、エピソードを執筆したメンバーに支払う報酬額となります。
        </Text>
        <Text style={styles.modalText}>
          著作者人格権は行使できないものとなります。
        </Text>
      </View>
    ),
  },
  secondaryUse: {
    title: '二次利用',
    description: (
      <View>
        <Text style={styles.modalText}>
          メンバーが執筆したエピソードの第三者による二次利用について定めることができます。
        </Text>
        <Text style={styles.modalText}>
          非営利目的での利用OKを選択した場合は、非営利の場合に限り、設定する条件に沿って第三者の個人がTapNovel以外で利用することができます。
        </Text>
        <Text style={styles.modalText}>
          法人による利用、営利目的での利用はトラブル防止のため、当社が窓口を行います。
        </Text>
      </View>
    ),
  },
  allowedChangeOnSecondaryUse: {
    title: '二次利用時の改変',
    description: (
      <View>
        <Text style={styles.modalText}>
          メンバーが執筆したエピソードの第三者による二次利時の改変について定めることができます。
        </Text>
        <Text style={styles.modalText}>
          改変OKを選択した場合は、設定する条件に沿って第三者の個人がTapNovel以外で利用する際に改変を認めることができます。
        </Text>
      </View>
    ),
  },
  requiredChangePermissionOnSecondaryUse: {
    title: '二次利用時の改変の許可',
    description: (
      <View>
        <Text style={styles.modalText}>
          メンバーが執筆したエピソードの第三者による二次利時の改変の許可について定めることができます。
        </Text>
        <Text style={styles.modalText}>
          許可不要を選択した場合は、第三者の個人がTapNovel以外の利用において改変する際に、エピソードを執筆したメンバーに許可を取る必要はございません。
          {'\n'}
          常識的な範疇を超える改変については、エピソードを執筆したメンバーによって差し止めが可能なものとします。
        </Text>
        <Text style={styles.modalText}>
          許可必要を選択した場合は、第三者の個人がTapNovel以外の利用において改変する際に、エピソードを執筆したメンバーに許可を取る必要がございます。
          {'\n'}
          メンバーが執筆したエピソードの第三者による二次利時の改変の許可について定めることができます。
        </Text>
        <Text style={styles.modalText}>
          拡散を希望する場合は許可不要にすることを推奨します。
        </Text>
      </View>
    ),
  },
  allowedVideoSharing: {
    title: '第三者による動画共有サービス設定',
    description:
      'このストーリーのキャプチャー画像や動画を、第三者が動画共有サービスへ投稿（配信・収益化を含む）することに対しての許可を設定することができます。\n公開範囲で「一般公開」が選択されたエピソードが共有対象となります。',
  },
} as {
  [key in keyof Form2Values]: {
    title: string;
    description: React.ReactNode | string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};
