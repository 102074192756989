import * as React from 'react';

import Icon from './Icon';

import {colors} from '../../../../styles/variables';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const MoreIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 20;
  const color = props.color || colors.textColor;
  return <Icon name={'ellipsis-horizontal'} size={size} color={color} />;
};

export default React.memo(MoreIcon);
