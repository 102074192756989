import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import FastImageAdapter from '../../../../shared/fast_image/FastImageAdapter';

import {storyCoverImageUrl} from '../../../../../helpers/images';
import StoryCoverImageDecorator from '../../../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../../../styles/variables';

import Project from '../../../../../../domain/entities/Project';
interface Props {
  project: Project;
}

const ProjectListItem: React.FC<Props> = props => {
  const {project} = props;
  const {story} = project;
  const linkTo = useLinkTo();
  const source = {
    uri: storyCoverImageUrl(
      new StoryCoverImageDecorator(project.story),
      'small',
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  const genre = story.genres[0];
  const onPress = React.useCallback(() => {
    linkTo(`/projects/${project.id}`);
  }, []);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      {story.hasCoverImage ? (
        <FastImageAdapter style={styles.image} source={source} />
      ) : (
        <View style={styles.image}>
          <Text style={styles.noImageText}>表紙なし</Text>
        </View>
      )}
      <Text style={styles.title}>{story.title}</Text>
      {genre ? (
        <View style={styles.genre}>
          <Text style={styles.genreName}>{genre.name}</Text>
        </View>
      ) : null}
    </TouchableOpacity>
  );
};

export default React.memo(ProjectListItem);

const ASPECT_RATIO = 0.7;
const width = 109;

const styles = StyleSheet.create({
  container: {
    marginRight: 8,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    borderWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  title: {
    color: '#fff',
    fontSize: 12,
    fontWeight: 'bold',
    paddingHorizontal: 4,
    position: 'absolute',
    bottom: 26,
  } as TextStyle,
  genre: {
    backgroundColor: 'rgba(255, 143, 19, 0.6)',
    borderRadius: 3,
    height: 14,
    position: 'absolute',
    marginHorizontal: 4,
    paddingHorizontal: 4,
    bottom: 8,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  genreName: {
    color: '#fff',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
});
