import {AnyAction} from 'redux';
import Types from '../actions/Types';

export default function reducer(
  state: number | null = null,
  action: AnyAction,
): number | null {
  switch (action.type) {
    case Types.CreateWriterCurrentUserSuccess:
    case Types.ShowWriterCurrentUserSuccess:
      return action.payload.id;
    default:
      return state;
  }
}
