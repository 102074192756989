import EntityMapper from './EntityMapper';

import OauthAccount from '../../../domain/entities/OauthAccount';

export interface OauthAccountData {
  id: number;
  uid: string;
  screen_name: string;
  oauth_provider_code: string;
}

export default class OauthAccountMapper
  implements EntityMapper<OauthAccountData, OauthAccount>
{
  public map(obj: OauthAccountData): OauthAccount {
    return new OauthAccount(
      obj.id,
      obj.uid,
      obj.screen_name,
      obj.oauth_provider_code,
    );
  }
}
