import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics';

import AnnouncementCategory from '../../../../domain/entities/writer/AnnouncementCategory';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetAnnouncementCategoriesRepository from '../../../../data/repositories/writer/NetAnnouncementCategoriesRepository';

const types = {
  failure: Types.IndexWriterAnnouncementCategoriesFailure,
  request: Types.IndexWriterAnnouncementCategoriesRequest,
  success: Types.IndexWriterAnnouncementCategoriesSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<AnnouncementCategory>> {
  return indexGenerics(
    new NetAnnouncementCategoriesRepository(),
    types,
    params,
  );
}
