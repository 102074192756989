import * as React from 'react';
import {Pressable, StyleSheet, Text, View, ViewStyle} from 'react-native';

import CheckCircleIcon from '../icons/CheckCircleIcon';
import DimensionContext from '../../shared/dimension/DimensionContext';

interface Item<T> {
  label: string;
  value: T;
}

interface CheckableItem<T> {
  checked: boolean;
  item: Item<T>;
  width: number;
  height: number;
}

interface Props<T> {
  items: Array<Item<T>>;
  values?: Array<T>;
  onChangeValues: (values: Array<T>) => void;
}

interface State<T> {
  checkableItems: Array<CheckableItem<T>>;
}

export default class CheckBoxList<T> extends React.Component<
  Props<T>,
  State<T>
> {
  constructor(props: Props<T>) {
    super(props);
    const {items, values} = props;
    const checkableItems = items.map(item => {
      const checked = !!values?.find(value => item.value === value);
      return {
        checked,
        item,
        width: DEFAULT_ITEM_WIDTH,
        height: DEFAULT_ITEM_HEIGHT,
      };
    });
    this.state = {checkableItems};
  }

  public render(): React.ReactNode {
    const {checkableItems} = this.state;
    const numColumns = Math.min(this.props.items.length, NUM_COLUMNS);
    return (
      <DimensionContext.Consumer>
        {context => {
          const width =
            (context.content.width -
              (CONTAINER_MARGIN_HORIZONTAL + ITEM_MARGIN * numColumns) * 2) /
            numColumns;
          const height = 35;
          return (
            <View style={styles.container}>
              {this.chunk(checkableItems, numColumns).map(
                (groupCheckableItems, i) => (
                  <View key={`${i}`} style={{flexDirection: 'row'}}>
                    {groupCheckableItems.map((item, j) =>
                      this.renderItem({
                        item: {...item, width, height},
                        index: i * numColumns + j,
                      }),
                    )}
                  </View>
                ),
              )}
            </View>
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private renderItem = (row: {
    item: CheckableItem<T>;
    index: number;
  }): React.ReactElement<any> => {
    return (
      <Pressable
        key={`${row.index}`}
        onPress={() => {
          this.handlePressItem(row.item);
        }}>
        <View
          style={[
            styles.itemContainer,
            {
              borderColor: row.item.checked ? '#ff8f13' : '#efefef',
              width: row.item.width,
              height: row.item.height,
            },
          ]}>
          <View style={styles.checkedIcon}>
            <CheckCircleIcon
              color={row.item.checked ? '#ff8f13' : '#efefef'}
              size={15}
            />
          </View>
          <Text
            style={{
              color: row.item.checked ? '#ff8f13' : '#666666',
              fontWeight: 'bold',
              fontSize: row.item.item.label.length < 6 ? 14 : 11,
            }}>
            {row.item.item.label}
          </Text>
        </View>
      </Pressable>
    );
  };

  private handlePressItem = (selectedCheckableItem: CheckableItem<T>) => {
    const {onChangeValues} = this.props;
    const checkableItems = this.state.checkableItems.map(checkableItem => {
      return {
        ...checkableItem,
        checked:
          checkableItem.item.value === selectedCheckableItem.item.value
            ? !checkableItem.checked
            : checkableItem.checked,
      };
    });
    this.setState({checkableItems});
    const values = checkableItems
      .filter(checkableItem => checkableItem.checked)
      .map(checkableItem => checkableItem.item.value);
    onChangeValues(values);
  };

  private chunk = (arr: Array<CheckableItem<T>>, size: number) => {
    return arr.reduce(
      (newarr, _, i) =>
        i % size ? newarr : [...newarr, arr.slice(i, i + size)],
      [] as Array<Array<CheckableItem<T>>>,
    );
  };
}

const NUM_COLUMNS = 3;

const CONTAINER_MARGIN_HORIZONTAL = 12;
const ITEM_MARGIN = 4;

const DEFAULT_ITEM_WIDTH = 109;
const DEFAULT_ITEM_HEIGHT = 30;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: CONTAINER_MARGIN_HORIZONTAL,
    marginVertical: 8,
  } as ViewStyle,
  itemContainer: {
    flexDirection: 'row',
    borderWidth: 1,
    borderRadius: 4,
    margin: ITEM_MARGIN,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  checkedIcon: {
    position: 'absolute',
    left: 8,
  } as ViewStyle,
});
