import EpisodePageViewAggregation from '../../../../domain/value_objects/summary/EpisodePageViewAggregation';

export interface EpisodePageViewAggregationData {
  format_date: string;
  page_view: number;
}

export default class EpisodePageViewAggregationMapper {
  public map(obj: EpisodePageViewAggregationData): EpisodePageViewAggregation {
    return new EpisodePageViewAggregation(obj.format_date, obj.page_view);
  }
}
