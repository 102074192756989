import TapNovelRestApi from '../../data_stores/net/TapNovelRestApi';

import ForbiddenWordValidationResultMapper, {
  ForbiddenWordValidationResultData,
} from '../../value_objects/mappers/ForbiddenWordValidationResultMapper';

import ForbiddenWordValidationResult from '../../../domain/value_objects/ForbiddenWordValidationResult';
import ForbiddenWordValidationResultsRepository from '../../../domain/repositories/writer/ForbiddenWordValidationResultsRepository';

export default class NetForbiddenWordValidationResultsRepository
  implements ForbiddenWordValidationResultsRepository
{
  private endPoint = '/api/writer/forbidden_word_validation_results';

  private mapper = new ForbiddenWordValidationResultMapper();

  constructor() {}

  public async find(word: string): Promise<ForbiddenWordValidationResult> {
    const path = `${this.endPoint}/${encodeURIComponent(word)}`;
    const obj = await TapNovelRestApi.get<ForbiddenWordValidationResultData>(
      path,
    );
    return this.mapper.map(obj.body);
  }
}
