import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import HelpCategoryMapper, {
  HelpCategoryData,
} from '../../entities/mappers/writer/HelpCategoryMapper';

import HelpCategory from '../../../domain/entities/writer/HelpCategory';
import HelpCategoriesRepository from '../../../domain/repositories/writer/HelpCategoriesRepository';

export default class NetHelpCategoriesRepository
  extends NetResourcesRepository<HelpCategory, HelpCategoryData>
  implements HelpCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/help_categories',
        new HelpCategoryMapper(),
      ),
    );
  }
}
