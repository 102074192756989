import * as React from 'react';
import {
  FlatList,
  ImageStyle,
  Pressable,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import DimensionContext from './dimension/DimensionContext';

export interface Entity {}

export interface Props<T extends Entity> {
  entities: T[];
  aspectRatio: number;
  selectedEntity: T | null;
  extraData?: any;
  onSelectEntity: (entity: T) => void;
  renderLabel: (entity: T) => string;
  keyExtractor: (entity: T) => string;
  isActive: (entity: T, selectedEntity: T | null) => boolean;
  renderImage: (
    entity: T,
    width: number,
    imageStyle: StyleProp<ImageStyle>,
  ) => React.ReactNode;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null | undefined;
}

export default class SelectableLabeledImageGridList<
  T extends Entity,
> extends React.PureComponent<Props<T>> {
  public render(): React.ReactNode {
    const {entities, selectedEntity, extraData, keyExtractor, onEndReached} =
      this.props;
    return (
      <DimensionContext.Consumer>
        {context => {
          const width =
            (context.content.width -
              (CONTAINER_MARGIN_HORIZONTAL +
                ITEM_MARGIN_HORIZONTAL * NUM_COLUMNS) *
                2) /
            NUM_COLUMNS;
          return (
            <FlatList
              style={styles.container}
              data={entities}
              numColumns={NUM_COLUMNS}
              keyExtractor={keyExtractor}
              renderItem={info => this.renderItem(info.item, width)}
              extraData={[extraData, selectedEntity]}
              onEndReached={onEndReached}
            />
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private renderItem = (entity: T, width: number) => {
    const {
      selectedEntity,
      aspectRatio,
      onSelectEntity,
      renderLabel,
      isActive,
      renderImage,
    } = this.props;
    const height = width / aspectRatio;
    return (
      <Pressable onPress={() => onSelectEntity(entity)}>
        <View style={styles.item}>
          {renderImage(entity, width, [
            styles.image,
            isActive(entity, selectedEntity) ? styles.imageActive : null,
            {width, height},
          ])}
          <Text style={styles.label}>{renderLabel(entity)}</Text>
        </View>
      </Pressable>
    );
  };
}

const NUM_COLUMNS = 3;

const CONTAINER_MARGIN_HORIZONTAL = 14;
const ITEM_MARGIN_HORIZONTAL = 2;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: CONTAINER_MARGIN_HORIZONTAL,
    marginVertical: 4.5,
  } as ViewStyle,
  item: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 4.5,
  } as ViewStyle,
  image: {
    marginHorizontal: 2,
    borderWidth: 2,
    borderRadius: 4,
    borderColor: 'transparent',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
  } as ImageStyle,
  imageActive: {
    borderColor: '#ff8f13',
  } as ImageStyle,
  label: {
    marginTop: 4,
    color: '#666666',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
