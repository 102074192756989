import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import GenreListItem from './GenreListItem';

import DragScrollView from '../../../../shared/drag_scroll_view';

import Genre from '../../../../../../domain/entities/Genre';

interface Props {
  genres: Genre[];
}

const GenreList: React.FC<Props> = props => {
  const {genres} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Genre>) => <GenreListItem genre={info.item} />,
    [],
  );
  return (
    <DragScrollView
      horizontal={true}
      bounces={false}
      showsHorizontalScrollIndicator={false}
      scrollEventThrottle={16}>
      <FlatList data={genres} renderItem={renderItem} horizontal={true} />
    </DragScrollView>
  );
};

export default React.memo(GenreList);
