import * as React from 'react';
import {
  ImageStyle,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useNavigation} from '@react-navigation/native';

import EditableLaterMessage from '../../../shared/EditableLaterMessage';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import Input from '../../../shared/forms/Input';
import TextArea from '../../../shared/forms/TextArea';
import MultiSwitch from '../../../shared/forms/MultiSwitch';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import RadioButton from '../../../shared/forms/RadioButton';
import TooltipModal from '../../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../../shared/KeyboardSpacer';
import AlertModal from '../../../shared/modals/AlertModal';

import convertImageSource from '../../../../helpers/convertImageSource';

import {colors, formStyles} from '../../../../styles/variables';

import {Rating, Format} from '../../../../../domain/entities/Story';

import Genre from '../../../../../domain/entities/Genre';

import NetGenresRepository from '../../../../../data/repositories/NetGenresRepository';

import storyIntroductionUri from '../../../../../assets/images/tips/story_introduction.png';
import storyCatchPhraseUri from '../../../../../assets/images/tips/story_catch_phrase.png';
import storyKeywordsUri from '../../../../../assets/images/tips/story_keywords.png';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

export type Form1Values = {
  title: string;
  introduction: string;
  catchPhrase: string;
  planning: string;
  mainGenreId: number;
  subGenreIds: number[];
  keywordNames: string[];
  rating: Rating;
  format: Format;
};

type Form1ErrorMessage = {[P in keyof Form1Values]?: string | string[]};

const TypeToTooltipInfo = {
  introduction: {
    title: 'イントロダクション',
    description:
      'ストーリーの詳細ページに表示される紹介文となります。読者が読みたくなるような紹介文を記入しましょう。',
    source: convertImageSource(storyIntroductionUri),
    imageStyle: {width: 279, height: 220},
  },
  catchPhrase: {
    title: 'キャッチコピー',
    description:
      '検索結果のページに表示されるキャッチコピーとなります。読者が読みたくなるようなキャッチコピーを記入しましょう。',
    source: convertImageSource(storyCatchPhraseUri),
    imageStyle: {width: 279, height: 160},
  },
  planning: {
    title: '企画内容',
    description:
      'プロジェクトの概要を記載ください。\nタップライターが参加したくなるような、企画の魅力を記入しましょう。\n（タップライターのみが閲覧できる情報です）',
  },
  keywordNames: {
    title: 'キーワード',
    description:
      '読者がストーリーを探しやすくなります。\nそのストーリーの特徴となるキーワードを登録しましょう。',
    source: convertImageSource(storyKeywordsUri),
    imageStyle: {width: 279, height: 160},
  },
  rating: {
    title: 'レイティング',
    description:
      '内容に暴力的な表現や性的な表現が含まれる場合は設定してください。',
    imageStyle: {},
  },
  format: {
    title: '形式',
    description:
      '特定の主人公キャラクターがいるものをシリーズ、\n主人公が毎回変わるものをオムニバスとします。',
    imageStyle: {},
  },
} as {
  [key in keyof Form1Values]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

interface Props {
  values?: Form1Values;
  errorMessages?: Form1ErrorMessage;
  onSubmit: (values: Form1Values) => void;
}

const FormStep1: React.FC<Props> = props => {
  const navigation = useNavigation<any>();
  const {values, errorMessages, onSubmit} = props;
  const [genres, setGenres] = React.useState<Genre[] | null>(null);
  React.useEffect(() => {
    new NetGenresRepository().findAll().then(result => {
      setGenres(result.records);
    });
  }, []);
  const [title, setTitle] = React.useState(values?.title || '');
  const [introduction, setIntroduction] = React.useState(
    values?.introduction || '',
  );
  const [catchPhrase, setCatchPhrase] = React.useState(
    values?.catchPhrase || '',
  );
  const [planning, setPlanning] = React.useState(values?.planning || '');
  const [mainGenreId, setMainGenreId] = React.useState(
    values?.mainGenreId || null,
  );
  const [subGenreIds, setSubGenreIds] = React.useState(
    values?.subGenreIds || [],
  );
  const [keywordNames, setKeywordNames] = React.useState(
    values?.keywordNames || [],
  );
  const [rating, setRating] = React.useState<Rating>(
    values?.rating || 'rating_none',
  );
  const [format, setFormat] = React.useState<Format>(
    values?.format || 'format_series',
  );
  const onChangeRating = React.useCallback(
    (item: {label: string; value: string}) => {
      setRating(item.value as Rating);
    },
    [],
  );
  const onChangeFormat = React.useCallback(
    (item: {label: string; value: string}) => {
      setFormat(item.value as Format);
    },
    [],
  );
  const [tooltipModalType, setTooltipModalType] = React.useState<
    keyof Form1Values | null
  >(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalTooltip = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const onPressQuestionIntroduction = React.useCallback(() => {
    setTooltipModalType('introduction');
  }, []);
  const onPressQuestionCatchPhrase = React.useCallback(() => {
    setTooltipModalType('catchPhrase');
  }, []);
  const onPressQuestionPlanning = React.useCallback(() => {
    setTooltipModalType('planning');
  }, []);
  const onPressQuestionKeywordNames = React.useCallback(() => {
    setTooltipModalType('keywordNames');
  }, []);
  const onPressQuestionRating = React.useCallback(() => {
    setTooltipModalType('rating');
  }, []);
  const onPressQuestionFormat = React.useCallback(() => {
    setTooltipModalType('format');
  }, []);
  const onPress = () => {
    if (!mainGenreId) {
      return;
    }
    onSubmit({
      title,
      introduction,
      catchPhrase,
      planning,
      mainGenreId,
      subGenreIds,
      keywordNames,
      rating,
      format,
    });
  };
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  const mainGenre =
    genres && mainGenreId ? genres.find(g => g.id === mainGenreId) : null;
  const subGenres = genres
    ? genres.filter(g => subGenreIds.includes(g.id))
    : [];
  const disabled =
    title.length <= 0 ||
    title.length > 50 ||
    !mainGenreId ||
    introduction.length <= 0 ||
    introduction.length > 200 ||
    catchPhrase.length <= 0 ||
    catchPhrase.length > 25 ||
    planning.length <= 0 ||
    planning.length > 800 ||
    !rating ||
    !format;
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{paddingBottom: 130}}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'ストーリータイトル(最大50文字)'}
            length={title.length}
            maxLength={50}
            requiredOrOptional={'required'}
            entered={title.length > 0 && title.length <= 50}
            errorMessages={errorMessages?.title}
          />
          <Input
            style={[
              {marginBottom: 3, marginTop: 8},
              title.length === 0
                ? requiredInputStyle
                : title.length <= 50
                ? undefined
                : styles.inputError,
            ]}
            value={title}
            placeholder={'ストーリータイトルを入力'}
            onChangeText={setTitle}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'メインジャンル'}
            requiredOrOptional={'required'}
            entered={!!mainGenreId}
          />
          <RadioButton
            style={[
              {marginBottom: 3, marginTop: 8},
              mainGenreId ? undefined : requiredInputStyle,
            ]}
            name={mainGenre?.name || ''}
            placeholder={'メインジャンルを選択'}
            onPress={() => {
              navigation.navigate('NewStoryNavigation', {
                screen: 'StoryFormMainGenre',
                params: {
                  mainGenreId,
                  subGenreIds,
                  callback: setMainGenreId,
                },
              });
            }}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'サブジャンル（2つまで）'}
            requiredOrOptional={'optional'}
            entered={(subGenreIds.length || 0) > 0}
          />
          <RadioButton
            name={subGenres.map(g => g.name).join('、')}
            placeholder={'サブジャンルを選択'}
            onPress={() => {
              navigation.navigate('NewStoryNavigation', {
                screen: 'StoryFormSubGenres',
                params: {
                  mainGenreId,
                  subGenreIds,
                  callback: setSubGenreIds,
                },
              });
            }}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'イントロダクション（最大200文字）'}
            length={introduction.length}
            maxLength={200}
            requiredOrOptional={'required'}
            entered={introduction.length > 0}
            errorMessages={errorMessages?.introduction}
            onPressQuestion={onPressQuestionIntroduction}
          />
          <TextArea
            style={[
              styles.textArea,
              introduction.length <= 200 ? undefined : styles.inputError,
              introduction ? undefined : requiredInputStyle,
            ]}
            containerStyle={introduction ? undefined : requiredInputStyle}
            value={introduction}
            placeholder={'イントロダクションを入力'}
            onChangeText={setIntroduction}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'キャッチコピー（最大25文字）'}
            length={catchPhrase.length}
            maxLength={25}
            requiredOrOptional={'required'}
            entered={catchPhrase.length > 0}
            onPressQuestion={onPressQuestionCatchPhrase}
          />
          <Input
            value={catchPhrase}
            style={[
              catchPhrase.length <= 25 ? null : styles.inputError,
              catchPhrase ? undefined : requiredInputStyle,
            ]}
            placeholder={'キャッチコピーを入力'}
            onChangeText={setCatchPhrase}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'企画内容（最大800文字）'}
            length={planning.length}
            maxLength={800}
            requiredOrOptional={'required'}
            entered={planning.length > 0}
            errorMessages={errorMessages?.planning}
            onPressQuestion={onPressQuestionPlanning}
          />
          <TextArea
            style={[
              styles.textArea,
              planning.length <= 800 ? undefined : styles.inputError,
              planning ? undefined : requiredInputStyle,
            ]}
            containerStyle={planning ? undefined : requiredInputStyle}
            value={planning}
            placeholder={'企画内容を入力'}
            onChangeText={setPlanning}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'形式'}
            onPressQuestion={onPressQuestionFormat}
          />
          <MultiSwitch
            items={FORMAT_ITEMS}
            value={format}
            onSelectItem={onChangeFormat}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'キーワード'}
            requiredOrOptional={'optional'}
            entered={(keywordNames.length || 0) > 0}
            onPressQuestion={onPressQuestionKeywordNames}
          />
          <RadioButton
            name={keywordNames.join('、')}
            onPress={() => {
              navigation.navigate('NewStoryNavigation', {
                screen: 'StoryFormKeywords',
                params: {
                  keywordNames,
                  callback: setKeywordNames,
                },
              });
            }}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'レイティング'}
            onPressQuestion={onPressQuestionRating}
          />
          <MultiSwitch
            items={RATING_ITEMS}
            value={rating}
            onSelectItem={onChangeRating}
          />
        </View>

        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            imageStyle={modalInfo.imageStyle}
            source={modalInfo.source}
            onCloseModal={onCloseModalTooltip}></TooltipModal>
        ) : null}
        <KeyboardSpacer />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <View style={styles.button}>
          <EditableLaterMessage />
          <PrimaryButton disabled={disabled} onPress={onPress}>
            次へ
          </PrimaryButton>
        </View>
      </View>
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
    </View>
  );
};

export default React.memo(FormStep1);

const RATING_ITEMS = [
  {label: 'なし', value: 'rating_none'},
  {label: 'R-15', value: 'rating_r15'},
  {label: 'R-18', value: 'rating_r18'},
];

const FORMAT_ITEMS: Array<{
  label: string;
  value: Format;
  optionLabel?: string;
}> = [
  {
    label: 'シリーズ',
    value: 'format_series',
  },
  {
    label: 'オムニバス',
    value: 'format_omnibus',
  },
];

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
});
