import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/illustration_show_scene_command/edit/illustration_usage_histories/Index';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import indexIllustrationUsageHistories, {
  Params as IllustrationUsageHistoryIndexParams,
} from '../../../../../actions/illustration_usage_histories/index';
import updateIllustrationUsageHistory, {
  Params as IllustrationUsageHistoryUpdateParams,
} from '../../../../../actions/illustration_usage_histories/update';
import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../../../../actions/scene_command_forms/create';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import selectEntities from '../../../../../helpers/selectEntities';
import {enabledResourceFavorite} from '../../../../../helpers/ResourceAuthorizattion';

import {SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistoriesRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistoriesRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {storyId, parentSceneCommandId} = route.params;
  const sceneForm = forms.sceneForm;
  const illustrationUsageHistoriesParams: IllustrationUsageHistoryIndexParams =
    {
      storyId,
    };
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  const illustrationUsageHistories = selectEntities(
    entities.illustrationUsageHistories,
    queries.illustrationUsageHistories,
    illustrationUsageHistoriesParams,
  );
  return {
    sceneForm,
    illustrationUsageHistories,
    illustrationUsageHistoriesParams,
    navigation,
    parentSceneCommandForm,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
    indexIllustrationUsageHistories: (
      params: IllustrationUsageHistoryIndexParams,
    ) => dispatch(indexIllustrationUsageHistories(params)),
    updateIllustrationUsageHistory: (
      id: number,
      params: IllustrationUsageHistoryUpdateParams,
    ) => dispatch(updateIllustrationUsageHistory(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
