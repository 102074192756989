import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';

import {
  actorCharacterFaceImageUrl,
  markImageUrl,
} from '../../../../../../helpers/images';

import {CharacterSceneCommandForm} from '../../../../../../view_models/SceneFrame';

interface Props {
  characterSceneCommandForm: CharacterSceneCommandForm | null;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {characterSceneCommandForm} = props;
  return (
    <View style={styles.container}>
      {characterSceneCommandForm && characterSceneCommandForm.mark && (
        <FastImageAdapter
          style={styles.mark}
          resizeMode={'contain'}
          source={{
            uri: markImageUrl(characterSceneCommandForm.mark, 'large'),
            headers: {Accept: 'image/webp,image/apng,*/*'},
          }}
        />
      )}
      {characterSceneCommandForm && (
        <FastImageAdapter
          resizeMode={'contain'}
          style={styles.image}
          source={{
            uri: actorCharacterFaceImageUrl(
              characterSceneCommandForm.actorCharacterFace,
              'middle',
            ),
            headers: {Accept: 'image/webp,image/apng,*/*'},
          }}
        />
      )}
      <Text style={styles.name}>
        {characterSceneCommandForm?.characterPattern.character.name}
      </Text>
    </View>
  );
};

export default React.memo(CharacterView);

const MARK_ASPECT_RATIO = 1;
const MARK_WIDTH = 25;
const MARK_HEIGHT = MARK_WIDTH / MARK_ASPECT_RATIO;

const CHARACTER_ASPECT_RATIO = 1;
const CHARACTER_WIDTH = 180;
const CHARACTER_HEIGHT = CHARACTER_WIDTH / CHARACTER_ASPECT_RATIO;

const styles = StyleSheet.create({
  container: {
    aspectRatio: CHARACTER_ASPECT_RATIO,
    height: CHARACTER_HEIGHT + 10,
    width: CHARACTER_WIDTH,
  } as ViewStyle,
  image: {
    aspectRatio: CHARACTER_ASPECT_RATIO,
    height: CHARACTER_HEIGHT,
    width: CHARACTER_WIDTH,
  } as ImageStyle,
  mark: {
    aspectRatio: MARK_ASPECT_RATIO,
    height: MARK_HEIGHT,
    left: 30,
    position: 'absolute',
    width: MARK_WIDTH,
  } as ImageStyle,
  name: {
    textAlign: 'center',
    fontSize: 11,
    fontWeight: 'bold',
    color: '#666666',
  } as TextStyle,
});
