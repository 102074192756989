import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {}

const NoTopics: React.FC<Props> = props => {
  return (
    <View style={styles.container}>
      <Text style={styles.message}>まだトピックが投稿されていません。</Text>
    </View>
  );
};

export default React.memo(NoTopics);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 56,
  } as TextStyle,
});
