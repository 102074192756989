import {Dispatch} from 'redux';

import Types from '../Types';

import NameLabelColor from '../../../domain/value_objects/NameLabelColor';

export interface Params {
  id?: number | null;
  name?: string | null;
  description?: string | null;
  actorId?: number | null;
  actorCharacterId?: number | null;
  actorCharacterFaceId?: number | null;
  actorCharacterFaceName?: string | null;
  published?: boolean;
  nameLabelColor?: NameLabelColor | null;
  voiceActorName?: string | null;
}

export default function action(
  payload: Params,
): (dispatch: Dispatch<any>) => void {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      payload,
      type: Types.UpdateCharacterForm,
    });
  };
}
