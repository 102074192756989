import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import BaseBackgroundMapper, {
  BaseBackgroundData,
} from '../../entities/mappers/BaseBackgroundMapper';

import BaseBackground from '../../../domain/entities/BaseBackground';
import BaseBackgroundsRepository from '../../../domain/repositories/writer/BaseBackgroundsRepository';

export default class NetBaseBackgroundsRepository
  extends NetResourcesRepository<BaseBackground, BaseBackgroundData>
  implements BaseBackgroundsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/base_backgrounds',
        new BaseBackgroundMapper(),
      ),
    );
  }
}
