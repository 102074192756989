import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics';

import Manual from '../../../../domain/entities/writer/Manual';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetManualsRepository from '../../../../data/repositories/writer/NetManualsRepository';

const types = {
  failure: Types.IndexWriterManualsFailure,
  request: Types.IndexWriterManualsRequest,
  success: Types.IndexWriterManualsSuccess,
};

export interface Params extends BaseParams {
  writerManualCategoryId?: number;
  primary?: boolean;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Manual>> {
  return indexGenerics(new NetManualsRepository(), types, params);
}
