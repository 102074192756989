import Entity from './Entity';

export default class Voice implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public name: string,
    public audioUrl: string,
    public audioFileName: string,
    public audioContentType: string,
    public audioFileSize: number,
    public audioUpdatedAt: Date,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
