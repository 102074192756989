import urlparser from 'url';

const lineOauth = (parsedQuery: urlparser.UrlWithStringQuery, params: any) => {
  if (
    `${parsedQuery.protocol}//${parsedQuery.host}${parsedQuery.pathname}` ===
    process.env.REACT_APP_LINE_WRITER_AUTH_REDIRECT_URI
  ) {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'line_oauth',
          params,
        },
        '*',
      );
    }
    window.close();
  }
};

const twitterOauth = (
  parsedQuery: urlparser.UrlWithStringQuery,
  params: any,
) => {
  if (
    `${parsedQuery.protocol}//${parsedQuery.host}${parsedQuery.pathname}` ===
    process.env.REACT_APP_TWITTER_WRITER_AUTH_REDIRECT_URI
  ) {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'twitter_oauth',
          params,
        },
        '*',
      );
    }
    window.close();
  }
};

const tiktokOauth = (
  parsedQuery: urlparser.UrlWithStringQuery,
  params: any,
) => {
  if (
    `${parsedQuery.protocol}//${parsedQuery.host}${parsedQuery.pathname}` ===
    process.env.REACT_APP_TIKTOK_WRITER_AUTH_REDIRECT_URI
  ) {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'tiktok_oauth',
          params,
        },
        '*',
      );
    }
    window.close();
  }
};

const googleOauth = (
  parsedQuery: urlparser.UrlWithStringQuery,
  params: any,
) => {
  if (
    `${parsedQuery.protocol}//${parsedQuery.host}${parsedQuery.pathname}` ===
    process.env.REACT_APP_GOOGLE_WRITER_AUTH_REDIRECT_URI
  ) {
    if (window.opener) {
      window.opener.postMessage(
        {
          type: 'google_oauth',
          params,
        },
        '*',
      );
    }
    window.close();
  }
};

const oauthCallback = (
  parsedQuery: urlparser.UrlWithStringQuery,
  params: any,
) => {
  lineOauth(parsedQuery, params);
  twitterOauth(parsedQuery, params);
  tiktokOauth(parsedQuery, params);
  googleOauth(parsedQuery, params);
};

export default oauthCallback;
