import Scene from '../../../../domain/entities/Scene';

interface Props {
  scene: Scene | null;
}

export default (current: Props, next: Props) => {
  if (current.scene === next.scene) {
    return false;
  }
  if (!current.scene || !next.scene) {
    return true;
  }
  if (current.scene.id !== next.scene.id) {
    return true;
  }
  if (current.scene.numberOfEpisode !== next.scene.numberOfEpisode) {
    return true;
  }
  if (current.scene.updatedAt.getTime() !== next.scene.updatedAt.getTime()) {
    return true;
  }
  return false;
};
