import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import BaseButton, {FontSizeClass} from './BaseButton';

interface Props {
  style?: StyleProp<ViewStyle>;
  buttonSize?: {width: number; height: number};
  fontSizeClass?: FontSizeClass;
  disabled?: boolean;
  children?: React.ReactNode;
  onPress: () => void;
}

const PrimaryButton: React.FunctionComponent<Props> = props => {
  const {style, buttonSize, fontSizeClass, disabled, children, onPress} = props;
  return (
    <BaseButton
      style={[
        {backgroundColor: 'transparent', borderColor: 'transparent'},
        style,
      ]}
      tintColor={'white'}
      fontStyle={{color: '#999999'}}
      buttonSize={buttonSize || DEFAULT_BUTTON_SIZE}
      fontSizeClass={fontSizeClass}
      disabled={disabled}
      onPress={onPress}>
      {children}
    </BaseButton>
  );
};

export default React.memo(PrimaryButton);

const DEFAULT_BUTTON_SIZE = {
  width: 311,
  height: 46,
};
