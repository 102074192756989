import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/background_show_scene_command/new/base_background_search_form/Index';

import AppState from '../../../../../reducers/AppState';

import indexBackgroundCategories, {
  Params as BackgroundCategoryIndexParams,
} from '../../../../../actions/background_categories/index';
import indexBaseBackgrounds, {
  Params as BaseBackgroundIndexParams,
} from '../../../../../actions/base_backgrounds/index';

import selectEntities from '../../../../../helpers/selectEntities';
import {SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {
    selectedLargeBackgroundCategoryId,
    selectedMiddleBackgroundCategoryId,
  } = route.params;
  const sceneForm = forms.sceneForm;
  const largeBackgroundCategoriesParams: BackgroundCategoryIndexParams = {
    parentId: null,
  };
  const largeBackgroundCategories = selectEntities(
    entities.backgroundCategories,
    queries.backgroundCategories,
    largeBackgroundCategoriesParams,
  );
  const middleBackgroundCategoriesParams: BackgroundCategoryIndexParams = {
    parentId: selectedLargeBackgroundCategoryId || 0,
  };
  const middleBackgroundCategories = selectEntities(
    entities.backgroundCategories,
    queries.backgroundCategories,
    middleBackgroundCategoriesParams,
  );
  const smallBackgroundCategoriesParams: BackgroundCategoryIndexParams = {
    parentId: selectedMiddleBackgroundCategoryId || 0,
  };
  const smallBackgroundCategories = selectEntities(
    entities.backgroundCategories,
    queries.backgroundCategories,
    smallBackgroundCategoriesParams,
  );
  return {
    sceneForm,
    largeBackgroundCategories,
    largeBackgroundCategoriesParams,
    middleBackgroundCategories,
    middleBackgroundCategoriesParams,
    smallBackgroundCategories,
    smallBackgroundCategoriesParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexBackgroundCategories: (params: BackgroundCategoryIndexParams) =>
      dispatch(indexBackgroundCategories(params)),
    indexBaseBackgrounds: (params: BaseBackgroundIndexParams) =>
      dispatch(indexBaseBackgrounds(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
