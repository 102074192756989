import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import NotificationListItem from './NotificationListItem';

import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import UserNotification from '../../../../domain/entities/writer/UserNotification';

interface Props {
  writerUserNotifications: UserNotification[] | null;
  onSelectWriterUserNotification: (
    writerUserNotification: UserNotification,
  ) => void;
}

const CommentList: React.FunctionComponent<Props> = props => {
  const {writerUserNotifications, onSelectWriterUserNotification} = props;
  const keyExtractor = React.useCallback(
    (item: UserNotification, index: number): string => `${item.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (
      info: ListRenderItemInfo<UserNotification>,
    ): React.ReactElement<any> | null => {
      return (
        <NotificationListItem
          writerUserNotification={info.item}
          onSelectWriterUserNotification={onSelectWriterUserNotification}
        />
      );
    },
    [writerUserNotifications],
  );
  return (
    <FlatList
      data={writerUserNotifications}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
      onEndReachedThreshold={0.1}
    />
  );
};

export default React.memo(CommentList);
