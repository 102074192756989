import AutoSaveUpdatingSceneMapper, {
  AutoSaveUpdatingSceneData,
} from '../entities/mappers/AutoSaveUpdatingSceneMapper';

import JsonAsyncStorage from '../data_stores/storage/JsonAsyncStorage';

import AutoSaveUpdatingScene from '../../domain/entities/AutoSaveUpdatingScene';

import SceneForm from '../../domain/forms/SceneForm';
import SceneCommandForm from '../../domain/forms/scene_commands/SceneCommandForm';

import AutoSaveUpdatingScenesRepository from '../../domain/repositories/AutoSaveUpdatingScenesRepository';

export default class StorageAutoSaveUpdatingScenesRepository
  implements AutoSaveUpdatingScenesRepository
{
  private static KEY = 'auto_save_updating_scenes';

  private mapper: AutoSaveUpdatingSceneMapper;

  constructor() {
    this.mapper = new AutoSaveUpdatingSceneMapper();
  }

  public async find(id: number): Promise<AutoSaveUpdatingScene> {
    const row: AutoSaveUpdatingSceneData | null = await JsonAsyncStorage.get(
      this.generateKey(id),
    );
    if (!row) {
      throw new Error(`Not Found AutoSaveUpdatingScene #${id}`);
    }
    return this.mapper.map(row);
  }

  public async update(
    id: number,
    params: {
      sceneForm: SceneForm;
      sceneCommandForms: SceneCommandForm[];
      updatedAt: Date;
    },
  ): Promise<AutoSaveUpdatingScene> {
    const row: AutoSaveUpdatingSceneData = {
      id,
      ...params,
      updatedAt: params.updatedAt.toString(),
    };
    await JsonAsyncStorage.set(this.generateKey(id), row);
    return this.mapper.map(row);
  }

  public async destroy(id: number): Promise<void> {
    const row: AutoSaveUpdatingSceneData = await JsonAsyncStorage.get(
      this.generateKey(id),
    );
    if (!row) {
      return;
    }
    await JsonAsyncStorage.remove(this.generateKey(id));
    return;
  }

  private generateKey(id: number) {
    return `${StorageAutoSaveUpdatingScenesRepository.KEY}/${id}`;
  }
}
