import Entity from './Entity';

import ActorAccessory from './ActorAccessory';
import ActorCharacter from './ActorCharacter';

export default class ActorAccessorySet implements Entity {
  constructor(
    public id: number,
    public name: string,
    public actorCharacter: ActorCharacter,
    public actorAccessories: ActorAccessory[],
  ) {}

  public getLabel() {
    return this.name;
  }
}
