import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories from '../../../../containers/scene_form/background_music_show_scene_command/new/sound_usage_histories/Index';
import SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm from '../../../../containers/scene_form/background_music_show_scene_command/new/sound_search_form/Index';
import SceneFormBackgroundMusicShowSceneCommandNewSounds from '../../../../containers/scene_form/background_music_show_scene_command/new/sounds/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories">
      <RootStack.Screen
        name="SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories"
        component={
          SceneFormBackgroundMusicShowSceneCommandNewSoundUsageHistories
        }
        options={{title: 'BGM'}}
      />
      <RootStack.Screen
        name="SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm"
        component={SceneFormBackgroundMusicShowSceneCommandNewSoundSearchForm}
        options={{title: 'BGM検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormBackgroundMusicShowSceneCommandNewSounds"
        component={SceneFormBackgroundMusicShowSceneCommandNewSounds}
        options={{title: 'BGM'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
