import * as React from 'react';
import {
  Image,
  Linking,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import dateformat from 'dateformat';
import Config from 'react-native-config';

import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';
import WriterIcon from '../../../shared/icons/WriterIcon';

import convertImageUri from '../../../../helpers/convertImageSource';
import ImageUrlHelper from '../../../../../domain/helpers/ImageUrlHelper';

import WriterUser from '../../../../../domain/entities/writer/faq/WriterUser';

import noimageUserUri from '../../../../../assets/images/response/noimage_user.png';

interface Props {
  writerUser: WriterUser;
  time: string;
  size?: number;
}

const WriterUserAvator: React.FC<Props> = props => {
  const {writerUser, time} = props;
  const onPress = React.useCallback(() => {
    Linking.openURL(generateUrl(`/writers/${writerUser.id}`));
  }, []);
  const size = props.size || 50;
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        {writerUser.userProfile.hasImage ? (
          <Image
            style={[{width: size, height: size}, {borderRadius: 25}]}
            source={{
              uri: ImageUrlHelper.getImageUrl(
                writerUser.userProfile.originalImageUrl,
                {
                  width: 50 * 2,
                  height: 50 * 2,
                },
              ),
            }}
          />
        ) : (
          <Image
            style={{width: size, height: size}}
            source={convertImageUri(noimageUserUri)}
            resizeMode={'stretch'}
          />
        )}
        <TouchableOpacity style={{alignItems: 'center'}} onPress={onPress}>
          <Text style={{color: '#999', fontSize: 9, marginTop: 4}}>
            詳細
            <Text style={{paddingLeft: 4}}>
              <ArrowForwardIcon size={7} color={'#999'} />
            </Text>
          </Text>
        </TouchableOpacity>
      </View>
      <View style={styles.right}>
        <View style={styles.rightPenNameWithIcon}>
          <Text style={styles.rightPenName}>{writerUser.penName}</Text>
          <View style={styles.icon}>
            <WriterIcon />
          </View>
        </View>
        <Text style={styles.rightTime}>
          {dateformat(time, 'yyyy/mm/dd HH:MM')}{' '}
        </Text>
      </View>
    </View>
  );
};

export default React.memo(WriterUserAvator);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  } as ViewStyle,
  left: {
    width: 50,
  } as ViewStyle,
  right: {
    flex: 1,
    marginLeft: 10,
  } as ViewStyle,
  rightPenNameWithIcon: {
    marginTop: 10,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  rightPenName: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  rightTime: {
    color: '#999',
    fontSize: 10,
    marginTop: 8,
  } as TextStyle,
  icon: {
    marginHorizontal: 4,
  } as ViewStyle,
});

const generateUrl = (path: string) => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  } else {
    return Config.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  }
};
