import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import FormEdit, {FormValues as FormEditValues} from './FormEdit';
import RejectFormModal, {RejectFormValues} from './RejectFormModal';

import DimensionContext from '../../../shared/dimension/DimensionContext';
import PlayIcon from '../../../shared/icons/PlayIcon';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';

import ImageUrlHelper from '../../../../../domain/helpers/ImageUrlHelper';
import ProjectEpisodeReleaseRequest from '../../../../../domain/entities/ProjectEpisodeReleaseRequest';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  episodeReleaseRequest: ProjectEpisodeReleaseRequest;
  onForceUpdate: () => void;
  onSubmitForEpisodeRelease: (values: FormEditValues) => void;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const Detail: React.FC<Props> = props => {
  const {
    episodeReleaseRequest,
    onForceUpdate,
    onSubmitForEpisodeRelease,
    setLoading,
  } = props;
  const linkTo = useLinkTo();
  const [visibleRejectModal, setVisibleRejectModal] = React.useState(false);
  const onPressApprove = React.useCallback(() => {
    setLoading(true);
    TapNovelTypedRestApi.patch(
      `/api/writer/leader/project_episode_release_requests/${episodeReleaseRequest.id}`,
      {
        projectEpisodeReleaseRequest: {
          status: 'approved',
        },
      },
    )
      .then(() => {
        onForceUpdate();
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const onPressReject = React.useCallback((values: RejectFormValues) => {
    TapNovelTypedRestApi.patch(
      `/api/writer/leader/project_episode_release_requests/${episodeReleaseRequest.id}`,
      {
        projectEpisodeReleaseRequest: {
          status: 'rejected',
          rejectedReason: values.reason,
        },
      },
    )
      .then(() => {
        onForceUpdate();
        setLoading(false);
        onRequestCloseRejectModal();
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const onPressRejectRequest = React.useCallback(() => {
    setVisibleRejectModal(true);
  }, []);
  const onRequestCloseRejectModal = React.useCallback(() => {
    setVisibleRejectModal(false);
  }, []);
  const onPressEpisodeImage = React.useCallback(() => {
    linkTo(
      `/projects/${episodeReleaseRequest.projectId}/episodes/${episodeReleaseRequest.episode.id}/image/edit?storyId=${episodeReleaseRequest.episode.storyId}`,
    );
  }, []);
  const onPressPreview = React.useCallback(() => {
    linkTo(`/episodes/${episodeReleaseRequest.episode.id}/viewer`);
  }, []);
  const source = {
    uri: ImageUrlHelper.getImageUrl(
      episodeReleaseRequest.episode.originalImageUrl,
      {width: 992, height: 622},
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  const {episode} = episodeReleaseRequest;
  const episodeFormValues: FormEditValues = {
    option: episode.free ? 'free' : '',
    publicationState:
      episode.published && episode.scheduledDeliveredAt
        ? new Date(episode.scheduledDeliveredAt) > new Date()
          ? 'reserved'
          : 'public'
        : 'private',
    scheduledDeliveredAt: episode.scheduledDeliveredAt || undefined,
  };
  return (
    <DimensionContext.Consumer>
      {context => {
        const width = context.content.width - 16 * 2;
        const height = Math.round((width / 343) * 215);
        return (
          <>
            <View style={styles.container}>
              <TouchableOpacity
                style={[styles.image, {width, height}]}
                onPress={onPressEpisodeImage}>
                {episodeReleaseRequest.episode.hasImage ? (
                  <FastImageAdapter style={{width, height}} source={source} />
                ) : (
                  <Text style={styles.imageText}>サムネイル</Text>
                )}
              </TouchableOpacity>
              <Text style={styles.title}>
                {episodeReleaseRequest.episode.title}
              </Text>
              <Text style={styles.penName}>
                {episodeReleaseRequest.writerUser.penName}
              </Text>
              {episodeReleaseRequest.episode.writerUserEpisodeComment ? (
                <>
                  <Text style={styles.writerCommentTitle}>
                    タップライターコメント
                  </Text>
                  <Text style={styles.writerCommentContent}>
                    {
                      episodeReleaseRequest.episode.writerUserEpisodeComment
                        .comment
                    }
                  </Text>
                </>
              ) : null}
              <View style={{alignItems: 'center', marginVertical: 24}}>
                <TouchableOpacity activeOpacity={0.6} onPress={onPressPreview}>
                  <View style={styles.previewButton}>
                    <PlayIcon size={26} color={'white'} />
                    <Text style={styles.previewButtonLabel}>プレビュー</Text>
                  </View>
                </TouchableOpacity>
              </View>
              {episodeReleaseRequest.status === 'pending' ? (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginVertical: 8,
                  }}>
                  <PrimaryButton
                    style={{marginHorizontal: 8}}
                    buttonSize={BUTTON_SIZE}
                    onPress={onPressApprove}>
                    承認
                  </PrimaryButton>
                  <DefaultOutlineButton
                    style={{marginHorizontal: 8}}
                    buttonSize={BUTTON_SIZE}
                    onPress={onPressRejectRequest}>
                    非承認
                  </DefaultOutlineButton>
                </View>
              ) : null}
            </View>
            {episodeReleaseRequest.status === 'approved' ? (
              <FormEdit
                values={episodeFormValues}
                onSubmit={onSubmitForEpisodeRelease}
              />
            ) : null}
            <RejectFormModal
              visible={visibleRejectModal}
              onSubmit={onPressReject}
              onRequestClose={onRequestCloseRejectModal}
            />
          </>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(Detail);

const BUTTON_SIZE = {
  width: 148,
  height: 46,
};

const styles = StyleSheet.create({
  container: {
    marginVertical: 24,
    marginHorizontal: 16,
  } as ViewStyle,
  image: {
    backgroundColor: '#d8d8d8',
    width: 343,
    height: 215,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  imageText: {
    color: '#999',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 16,
  } as TextStyle,
  penName: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  previewButton: {
    width: 311,
    height: 46,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#383838',
    borderRadius: 26,
  } as ViewStyle,
  previewButtonLabel: {
    marginLeft: 6,
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  writerCommentTitle: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 32,
    marginBottom: 8,
  } as TextStyle,
  writerCommentContent: {
    color: '#222',
    fontSize: 14,
    marginBottom: 8,
  } as TextStyle,
});
