import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import CloseIcon from '../../../../shared/icons/CloseIcon';

import PaymentMethod from '../../../../../../domain/entities/writer/my_stripe/PaymentMethod';

interface Props {
  paymentMethod: PaymentMethod;
  onSelectPaymentMethod: (paymentMethod: PaymentMethod) => void;
  onDeletePaymentMethod: (paymentMethod: PaymentMethod) => void;
}

const InvoiceListItem: React.FunctionComponent<Props> = props => {
  const {paymentMethod, onSelectPaymentMethod, onDeletePaymentMethod} = props;
  const onPress = React.useCallback(() => {
    onSelectPaymentMethod(paymentMethod);
  }, []);
  const onPressDelete = React.useCallback(() => {
    onDeletePaymentMethod(paymentMethod);
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <Text style={styles.leftBrand}>{paymentMethod.cardBrand}</Text>
      </View>

      <View style={styles.center}>
        <Text style={styles.centerNumber}>
          •••• •••• •••• {paymentMethod.cardLast4}
        </Text>
        <Text style={styles.centerExp}>
          有効期限 {paymentMethod.cardExpMonth} / {paymentMethod.cardExpYear}
        </Text>
      </View>
      <View style={styles.right}>
        <PrimaryButton
          style={styles.useButton}
          fontStyle={styles.useButtonText}
          disabled={paymentMethod.defaultPaymentMethod}
          onPress={onPress}>
          {paymentMethod.defaultPaymentMethod
            ? 'このカードをご利用中'
            : 'このカードを使用する'}
        </PrimaryButton>
      </View>
      {paymentMethod.defaultPaymentMethod ? null : (
        <Pressable style={styles.deleteButton} onPress={onPressDelete}>
          <CloseIcon size={10} color={'#666'} />
        </Pressable>
      )}
    </View>
  );
};

export default React.memo(InvoiceListItem);

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: '#e5e5e5',
    marginVertical: 8,
    marginHorizontal: 16,
    paddingVertical: 24,
    paddingHorizontal: 16,
    flexDirection: 'row',
  } as ViewStyle,
  left: {
    width: 90,
    justifyContent: 'center',
  } as ViewStyle,
  leftBrand: {
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  center: {
    justifyContent: 'center',
  } as ViewStyle,
  centerNumber: {
    fontSize: 11,
    marginVertical: 4,
  } as TextStyle,
  centerExp: {
    fontSize: 11,
    marginVertical: 4,
  } as TextStyle,
  right: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  } as ViewStyle,
  useButton: {
    width: 120,
    height: 32,
  } as ViewStyle,
  useButtonText: {
    fontSize: 10,
  } as TextStyle,
  deleteButton: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
});
