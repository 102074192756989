import * as React from 'react';

import Icon from '../Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const MinusIcon: React.FC<Props> = props => {
  const size = props.size || 10;
  const color = props.color || '#383838';
  return <Icon name={'project-minus'} size={size} color={color} />;
};

export default React.memo(MinusIcon);
