import * as React from 'react';

import FormWithKeywordTags from './partials/FormWithKeywordTags';

import Layout from '../../shared/Layout';
import HeaderRightButton from '../../shared/buttons/HeaderRightButton';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {StoryFormKeywordsRouteProp} from '../../../navigators/RouteProps';

import {Params as StoryFormUpdateParams} from '../../../actions/story_forms/update';
import {Params as GenreIndexParams} from '../../../actions/genres/index';

import Story from '../../../../domain/entities/Story';
import Genre from '../../../../domain/entities/Genre';
import Contest from '../../../../domain/entities/Contest';
import PaginatedResult from '../../../../domain/results/PaginatedResult';
import ForbiddenWordValidationResult from '../../../../domain/value_objects/ForbiddenWordValidationResult';

import NetContestsRepository from '../../../../data/repositories/writer/NetContestsRepository';

export interface Params {
  storyId?: number;
  keywordNames: string | string[] | null;
  enableContests?: boolean;
  callback?: (keywordNames: string[]) => void;
}

export interface StateProps {
  navigation: NavigationProp;
  route: StoryFormKeywordsRouteProp;
  genresParams: GenreIndexParams;
  genres: Genre[] | null;
  story: Story | null;
}

export interface DispatchProps {
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
  indexGenres: (params: GenreIndexParams) => Promise<PaginatedResult<Genre>>;
  showForbiddenWordValidationResult: (
    word: string,
  ) => Promise<ForbiddenWordValidationResult>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  names: string[];
  contests: Contest[] | null;
  disallowNames: string[];
  errorMessage: string | null;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const {keywordNames} = props.route.params;
    this.state = {
      names: typeof keywordNames === 'string' ? [] : keywordNames || [],
      contests: null,
      disallowNames: [],
      errorMessage: null,
    };
  }

  public componentDidMount() {
    const {navigation, route, genresParams, genres, indexGenres} = this.props;
    const {keywordNames} = route.params;
    if (typeof keywordNames === 'string') {
      navigation.goBack();
    }
    if (!genres) {
      indexGenres(genresParams);
    }
    new NetContestsRepository().findAll().then(contestsResult => {
      this.setState({contests: contestsResult.records});
    });
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {names, contests, disallowNames, errorMessage} = this.state;
    return (
      <Layout
        title={'キーワード'}
        scrollable={false}
        navigation={navigation}
        back={true}
        rightButton={{
          tintColor: 'white',
          title: (
            <HeaderRightButton
              disabled={disallowNames.length > 0}
              onPress={this.handleSubmit}>
              決定
            </HeaderRightButton>
          ),
        }}>
        <FormWithKeywordTags
          names={names}
          contests={contests}
          onRemove={this.handleRemove}
          onCreateKeyword={this.handleCreateKeyword}
        />
        <AlertModal
          visible={!!errorMessage}
          onCloseModal={this.handleCloseModal}>
          {errorMessage || ''}
        </AlertModal>
      </Layout>
    );
  }

  private handleRemove = (name: string) => {
    let {disallowNames} = this.state;
    const names = this.state.names.filter(stateName => stateName !== name);
    if (disallowNames.includes(name)) {
      disallowNames = disallowNames.filter(
        disallowName => disallowName !== name,
      );
    }
    this.setState({names, disallowNames});
  };

  private handleCreateKeyword = (name: string, callback?: () => void) => {
    const {genres, story, showForbiddenWordValidationResult} = this.props;
    const {contests, disallowNames} = this.state;
    if (this.state.names.some(stateName => stateName === name)) {
      callback && callback();
      return;
    }
    if (name.length > MAX_KEYWORD_SIZE) {
      this.setState({
        errorMessage: `キーワードは最大${MAX_KEYWORD_SIZE}文字までとなります。`,
      });
      return;
    }
    if (
      genres &&
      genres.find(
        genre => this.normalizeText(genre.name) === this.normalizeText(name),
      )
    ) {
      this.setState({
        errorMessage: `キーワードにジャンル名は指定できません。`,
      });
      return;
    }
    if (
      story &&
      story.published &&
      contests &&
      contests.map(c => c.keywordName).includes(name)
    ) {
      if (!disallowNames.includes(name)) {
        disallowNames.push(name);
      }
      this.setState({
        errorMessage: `公開している作品でコンテストにご応募いただく場合、作品を一度非公開にしてください。`,
        disallowNames,
      });
    }
    showForbiddenWordValidationResult(name)
      .then(obj => {
        if (obj.error) {
          this.setState({errorMessage: `キーワード${obj.error}`});
        } else {
          const names = [...this.state.names, name];
          if (names.length <= MAX_KEYWORD_LIST_SIZE) {
            this.setState({names});
            callback && callback();
          } else {
            this.setState({
              errorMessage: `キーワードの設定上限は${MAX_KEYWORD_LIST_SIZE}個までとなります。`,
            });
          }
        }
      })
      .catch(() => {});
  };

  private handleCloseModal = () => {
    this.setState({errorMessage: null});
  };

  private handleSubmit = () => {
    const {navigation, route, updateStoryForm} = this.props;
    const {callback} = route.params;
    const {names} = this.state;
    if (callback) {
      callback(names);
    } else {
      updateStoryForm({keywordNames: names});
    }
    navigation.goBack();
  };

  private normalizeText = (text: string) => {
    return text
      .replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
      .toLowerCase();
  };
}

const MAX_KEYWORD_LIST_SIZE = 10;

const MAX_KEYWORD_SIZE = 25;
