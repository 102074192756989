import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import PartnerProgramHome from '../../../containers/partner_program/home/Index';
import PartnerProgramStory from '../../../containers/partner_program/stories/Show';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="PartnerProgramHome">
      <RootStack.Screen
        name="PartnerProgramHome"
        component={PartnerProgramHome}
        options={{
          title: 'パートナープログラム',
        }}
      />
      <RootStack.Screen
        name="PartnerProgramStory"
        component={PartnerProgramStory}
        options={{
          title: '報酬',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
