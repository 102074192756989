import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import TwitterIcon from '../icons/TwitterIcon';
import FacebookIcon from '../icons/FacebookIcon';
import HatebuIcon from '../icons/HatebuIcon';
import LineIcon from '../icons/LineIcon';

import {
  twitterShareUrl,
  facebookShareUrl,
  hatenaShareUrl,
  lineShareUrl,
} from '../../../helpers/shareUrls';

import {colors} from '../../../styles/variables';

interface Props {
  message: string;
  url: string;
  title: string;
}

const Content: React.FunctionComponent<Props> = props => {
  const {message, url, title} = props;
  return (
    <View style={styles.container}>
      <a
        href={twitterShareUrl(message, url, title)}
        className="social-share-button"
        target={'_blank'}>
        <TwitterIcon size={25} color={colors.twitter} />
      </a>
      <a
        href={facebookShareUrl(url)}
        className="social-share-button"
        target={'_blank'}>
        <FacebookIcon size={27} color={colors.facebook} />
      </a>
      <a
        href={hatenaShareUrl(url)}
        className="social-share-button"
        target={'_blank'}>
        <HatebuIcon size={24} color={colors.hatebu} />
      </a>
      <a
        href={lineShareUrl(message, url)}
        className="social-share-button"
        target={'_blank'}>
        <LineIcon size={25} color={colors.line} />
      </a>
    </View>
  );
};

const openUrl = (url: string) => {
  window.open(url, 'blank');
};

const hashtags = (text: string) => {
  return `TapNovel,ゲーム小説,${text.replace(
    /[\s。、\-.,/#!$%^&*;:{}=\-_`~()]/g,
    '',
  )}`;
};

const normalizeShareText = (text: string) => {
  return `${text} | TapNovel`;
};

export default React.memo(Content);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    height: 46,
    width: 200,
    flexDirection: 'row',
  } as ViewStyle,
});
