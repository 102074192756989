export default class UserSessionForm {
  public email = '';
  public password = '';

  public toParams() {
    const email = this.email;
    const password = this.password;
    return {
      email,
      password,
    };
  }
}
