import * as React from 'react';

import Form, {Fields} from './partials/Form';

import Layout from '../shared/Layout';
import AlertModal from '../shared/modals/AlertModal';

import {
  isFulfilledImages,
  isFulfilledEpisode,
} from '../../view_models/navigateStoryPublication';

import NavigationProp from '../../navigators/NavigationProp';
import {EditStoryRouteProp} from '../../navigators/RouteProps';

import * as routers from '../../routers';

import {Params as StoryUpdateParams} from '../../actions/stories/update';
import {Params as GenreIndexParams} from '../../actions/genres/index';
import {Params as EpisodeIndexParams} from '../../actions/episodes/index';
import {Params as StoryFormCreateParams} from '../../actions/story_forms/create';
import {Params as StoryFormUpdateParams} from '../../actions/story_forms/update';

import {formatErrorMessages} from '../../helpers/errorMessages';

import Genre from '../../../domain/entities/Genre';
import Story, {Rating, Format} from '../../../domain/entities/Story';
import Episode from '../../../domain/entities/Episode';
import StoryForm from '../../../domain/forms/StoryForm';
import PaginatedResult from '../../../domain/results/PaginatedResult';

const requiredFields: Array<Fields> = ['introduction', 'catchPhrase'];

export interface Params {
  storyId: number;
  episodeIdToBePublished?: number;
  skipTitleValidation?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditStoryRouteProp;
  story: Story | null;
  genres: Genre[] | null;
  episodes: Episode[] | null;
  episodesParams: EpisodeIndexParams;
  storyForm: StoryForm | null;
  genresParams: GenreIndexParams;
}

export interface DispatchProps {
  showStory: (id: number) => Promise<Story>;
  indexGenres: (params: GenreIndexParams) => Promise<PaginatedResult<Genre>>;
  indexEpisodes: (
    params: EpisodeIndexParams,
  ) => Promise<PaginatedResult<Episode>>;
  createStoryForm: (params: StoryFormCreateParams) => Promise<any>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
  updateStory: (id: number, params: StoryUpdateParams) => Promise<Story>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class Edit extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      genresParams,
      genres,
      episodesParams,
      showStory,
      indexGenres,
      indexEpisodes,
    } = this.props;
    const {storyId} = route.params;
    showStory(storyId)
      .then(this.handleCreateStoryForm)
      .catch(e => {
        if (e.status === 401 || e.status === 404) {
          navigation.goBack();
        }
      });
    if (!genres) {
      indexGenres(genresParams);
    }
    indexEpisodes(episodesParams);
  }

  public render(): React.ReactNode {
    const {navigation, route, story, storyForm, genres, episodes} = this.props;
    const {loading, alertMessage} = this.state;
    const {episodeIdToBePublished} = route.params;
    return (
      <Layout
        title={'ストーリー情報'}
        navigation={navigation}
        close={true}
        loading={loading}
        scrollable={false}
        hideHeaderBottomBorder={true}>
        {story && storyForm && genres && episodes && (
          <Form
            key={`${storyForm.id || 'none'}`}
            storyForm={storyForm}
            genres={genres}
            submitButtonText={!episodeIdToBePublished ? '保存' : undefined}
            disableAllowedVideoSharing={story.allowedVideoSharing}
            episodesCount={episodes.length}
            requiredFields={
              episodeIdToBePublished || story.published ? requiredFields : null
            }
            onForwardToMainGenre={this.handleForwardToMainGenre}
            onForwardToSubGenres={this.handleForwardToSubGenres}
            onToggleSerialized={this.handleToggleSerialized}
            onToggleAllowedVideoSharing={this.handleToggleAllowedVideoSharing}
            onForwardToKeywords={this.handleForwardToKeywords}
            onChangeFormat={this.handleChangeFormat}
            onChangeRating={this.handleChangeRating}
            onSubmit={this.handleSubmit}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleForwardToMainGenre = (
    mainGenreId: number | null,
    subGenreIds: number[] | null,
  ) => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToStoryFormMainGenre(navigation, {
      storyId,
      mainGenreId,
      subGenreIds,
    });
  };

  private handleForwardToSubGenres = (
    mainGenreId: number | null,
    subGenreIds: number[] | null,
  ) => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToStoryFormSubGenres(navigation, {
      storyId,
      mainGenreId,
      subGenreIds,
    });
  };

  private handleToggleSerialized = (serialized: boolean) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({serialized});
  };

  private handleToggleAllowedVideoSharing = (allowedVideoSharing: boolean) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({allowedVideoSharing});
  };

  private handleForwardToKeywords = (keywordNames: string[] | null) => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToStoryFormKeywords(navigation, {
      storyId,
      keywordNames,
      enableContests: true,
    });
  };

  private handleChangeRating = (rating: Rating) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({rating});
  };

  private handleChangeFormat = (format: Format) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({format});
  };

  private handleSubmit = (params: {
    title: string;
    introduction: string;
    catchPhrase: string;
  }) => {
    const {navigation, route, story, storyForm, updateStory, updateStoryForm} =
      this.props;
    const {storyId, episodeIdToBePublished} = route.params;
    if (!storyForm) {
      return;
    }
    updateStoryForm(params);
    if (!story || !storyForm || !storyId) {
      return;
    }
    if (episodeIdToBePublished) {
      if (!isFulfilledImages(story)) {
        routers.linkToEditStoryFormCoverImage(navigation, {
          storyId,
          episodeIdToBePublished,
          back: true,
        });
        return;
      }
    }
    this.setState({loading: true}, () => {
      updateStory(storyId, {...storyForm.toParams(), ...params})
        .then(() => {
          this.setState({loading: false}, async () => {
            (navigation.getParent() || navigation).goBack();
            if (episodeIdToBePublished) {
              if (await isFulfilledEpisode(episodeIdToBePublished)) {
                routers.linkToEpisodeFormEditEpisodePublication(navigation, {
                  episodeId: episodeIdToBePublished,
                });
              } else {
                routers.linkToEditEpisode(navigation, {
                  episodeId: episodeIdToBePublished,
                  toBePublished: true,
                });
              }
            }
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private handleCreateStoryForm = (story: Story) => {
    const {createStoryForm} = this.props;
    const {
      id,
      title,
      introduction,
      catchPhrase,
      serialized,
      allowedVideoSharing,
      hasImage,
      originalImageUrl,
      hasCoverImage,
      originalCoverImageUrl,
      coverIllustrator,
      rating,
      format,
    } = story;
    const mainGenreId = story.getMainGenreId();
    const subGenreIds = story.getSubGenreIds();
    const keywordNames = story.keywords.map(keyword => keyword.name);
    const params = {
      catchPhrase,
      hasCoverImage,
      hasImage,
      id,
      introduction,
      keywordNames,
      mainGenreId,
      originalCoverImageUrl,
      originalImageUrl,
      serialized,
      allowedVideoSharing,
      subGenreIds,
      title,
      coverIllustrator,
      rating,
      format,
    };
    createStoryForm(params);
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };
}
