import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import OrientedSpeechBalloon from '../../entities/OrientedSpeechBalloon';
import Voice from '../../entities/Voice';
import Sound from '../../entities/Sound';

import Position from '../../value_objects/Position';

const CLASS_NAME: SceneCommandFormClass = 'SpeechTextShowSceneCommandForm';

export default class SpeechTextShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  public text: string;

  constructor(
    public orientedSpeechBalloon: OrientedSpeechBalloon,
    text: string,
    public overrideCharacterName: boolean | null,
    public characterName: string | null,
    public voice: Voice | null,
    public sound: Sound | null,
    public startTime: number | null,
    public endTime: number | null,
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
    this.text = text.trim();
  }

  public toParams(): {[key: string]: any} {
    return {
      oriented_speech_balloon_id: this.orientedSpeechBalloon.id,
      text: this.text,
      override_character_name: this.overrideCharacterName,
      character_name: this.characterName,
      voice_id: this.voice ? this.voice.id : null,
      sound_id: this.sound ? this.sound.id : null,
      start_time: this.startTime,
      end_time: this.endTime,
      type: 'SceneCommands::SpeechTextShowSceneCommand',
    };
  }

  public copy(sceneCommandId?: number): SpeechTextShowSceneCommandForm {
    return new SpeechTextShowSceneCommandForm(
      this.orientedSpeechBalloon,
      this.text,
      this.overrideCharacterName,
      this.characterName,
      this.voice,
      this.sound,
      this.startTime,
      this.endTime,
      sceneCommandId,
    );
  }

  public getPositions(): Position[] {
    return this.orientedSpeechBalloon.getPositions();
  }
}
