import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';

interface Props {
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
}

const NewCharacterButton: React.FunctionComponent<Props> = props => {
  const {style, onPress} = props;
  return (
    <PrimaryButton style={style} onPress={onPress}>
      キャラクターをつくる
    </PrimaryButton>
  );
};

export default React.memo(NewCharacterButton);
