import * as React from 'react';
import {
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import TermSelect from './TermSelect';
import StoryBrowsingTransitionGraph from './StoryBrowsingTransitionGraph';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import TooltipModal from '../../../shared/modals/TooltipModal';
import QuestionIcon from '../../../shared/icons/QuestionIcon';

import {storyCoverImageUrl} from '../../../../helpers/images';

import {colors} from '../../../../styles/variables';

import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';

import StoryAggregation from '../../../../../domain/value_objects/summary/StoryAggregation';
import EpisodePageViewAggregation from '../../../../../domain/value_objects/summary/EpisodePageViewAggregation';

import {TermRange} from '../../../../view_models/TermRange';

type TooltipModalType = 'episode_page_view_rate' | 'content_fulfillment_rate';

const TypeToTooltipInfo = {
  episode_page_view_rate: {
    title: '閲覧率',
    description:
      '作品ページを参照した人がどのくらいの割合で作品を読んでくれたかを示す指標です。\n作品自体の訴求力を計測するのに役立ちます。',
  },
  content_fulfillment_rate: {
    title: '作品ページ充実度',
    description:
      '作品ページにどのくらい情報を掲載しているかを示す指標です。\n作品ページの参照数や閲覧率の向上ご活用ください。',
  },
};

interface Props {
  storyAggregation: StoryAggregation;
  termRange: TermRange;
  episodePageViewAggregations: Array<EpisodePageViewAggregation>;
  onTermRangeChange: (termRange: TermRange) => void;
}

const StoryAggregationView: React.FunctionComponent<Props> = props => {
  const {
    storyAggregation,
    termRange,
    episodePageViewAggregations,
    onTermRangeChange,
  } = props;
  const [tooltipModalType, setTooltipModalType] =
    React.useState<TooltipModalType | null>(null);
  const onCloseModal = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onPressEpisodePageViewRate = React.useCallback(() => {
    setTooltipModalType('episode_page_view_rate');
  }, []);
  const onPressContentFulfillmentRate = React.useCallback(() => {
    setTooltipModalType('content_fulfillment_rate');
  }, []);
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  const source = {
    uri: storyCoverImageUrl(
      new StoryCoverImageDecorator(storyAggregation),
      'small',
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  return (
    <View style={styles.container}>
      <TermSelect termRange={termRange} onTermRangeChange={onTermRangeChange} />
      <View style={styles.storyInfo}>
        {storyAggregation.hasCoverImage ? (
          <FastImageAdapter style={styles.image} source={source} />
        ) : (
          <View style={styles.image}>
            <Text style={styles.noImageText}>表紙なし</Text>
          </View>
        )}
        <View style={styles.storyInfoRight}>
          <View style={styles.rowsTop}>
            <View style={styles.row}>
              <Text style={styles.label}>作品ページ参照数 (人)</Text>
              <Text style={styles.value}>
                {numeral(storyAggregation.storyPageViewCount).format('0,0')}(
                {numeral(storyAggregation.storyPageViewUserCount).format('0,0')}
                )
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>閲覧数 (人)</Text>
              <Text style={styles.value}>
                {numeral(storyAggregation.episodePageViewCount).format('0,0')}(
                {numeral(
                  storyAggregation.episodePageViewUniqueConsumerUserCount,
                ).format('0,0')}
                )
              </Text>
            </View>
          </View>
          <View style={styles.rowsBottom}>
            <View style={styles.row}>
              <View style={styles.labelWithQuestion}>
                <Text style={styles.label}>閲覧率</Text>
                <Pressable onPress={onPressEpisodePageViewRate}>
                  <View style={styles.question}>
                    <QuestionIcon />
                  </View>
                </Pressable>
              </View>
              <Text style={styles.value}>
                {numeral(storyAggregation.episodePageViewRate * 100).format(
                  '0,0.0',
                )}
                ％
              </Text>
              <Text style={styles.valueNote}>
                カテゴリ平均 (
                {numeral(
                  storyAggregation.genreAverageEpisodePageViewRate * 100,
                ).format('0,0.0')}
                ％)
              </Text>
            </View>
            <View style={styles.row}>
              <View style={styles.labelWithQuestion}>
                <Text style={styles.label}>作品ページ充実度</Text>
                <Pressable onPress={onPressContentFulfillmentRate}>
                  <View style={styles.question}>
                    <QuestionIcon />
                  </View>
                </Pressable>
              </View>
              <Text style={styles.value}>
                {numeral(storyAggregation.contentFulfillmentRate * 100).format(
                  '0,0',
                )}
                ％
              </Text>
            </View>
          </View>
        </View>
      </View>
      <StoryBrowsingTransitionGraph
        episodePageViewAggregations={episodePageViewAggregations}
      />
      {modalInfo ? (
        <TooltipModal
          visible={true}
          title={modalInfo.title}
          description={modalInfo.description}
          onCloseModal={onCloseModal}></TooltipModal>
      ) : null}
    </View>
  );
};

export default React.memo(StoryAggregationView);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
  } as ViewStyle,
  storyInfo: {
    backgroundColor: 'white',
    padding: 16,
    flexDirection: 'row',
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  storyInfoRight: {
    flex: 1,
    marginLeft: 16,
    justifyContent: 'center',
  } as ViewStyle,
  rowsTop: {
    flexDirection: 'row',
    marginBottom: 6,
  } as ViewStyle,
  rowsBottom: {
    flexDirection: 'row',
    marginTop: 6,
  } as ViewStyle,
  row: {
    flex: 1,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  labelWithQuestion: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  question: {
    marginLeft: 5,
  } as ViewStyle,
  value: {
    color: '#ff8f13',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  valueNote: {
    color: '#ff8f13',
    fontSize: 10,
  } as TextStyle,
});
