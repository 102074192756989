import * as React from 'react';
import {getStateFromPath, getActionFromState} from '@react-navigation/native';

import NotificationList from './partials/NotificationList';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';

import {NotificationsRouteProp} from '../../navigators/RouteProps';

import {Params as WriterUserNotificationIndexParams} from '../../actions/writer/user_notifications/index';
import {Params as WriterUserNotificationUpdateParams} from '../../actions/writer/user_notifications/update';

import UserNotification from '../../../domain/entities/writer/UserNotification';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import {navigationRef} from '../../navigators/navigationRef';
import LinkingConfig from '../../navigators/LinkingConfig';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: NotificationsRouteProp;
  writerUserNotifications: UserNotification[] | null;
  writerUserNotificationsParams: WriterUserNotificationIndexParams;
}

export interface DispatchProps {
  indexWriterUserNotifications: (
    params: WriterUserNotificationIndexParams,
  ) => Promise<PaginatedResult<UserNotification>>;
  updateWriterUserNotification: (
    id: number,
    params: WriterUserNotificationUpdateParams,
  ) => Promise<UserNotification>;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    writerUserNotifications,
    writerUserNotificationsParams,
    indexWriterUserNotifications,
    updateWriterUserNotification,
  } = props;
  React.useEffect(() => {
    indexWriterUserNotifications(writerUserNotificationsParams);
  }, []);
  const onSelectWriterUserNotification = React.useCallback(
    (writerUserNotification: UserNotification) => {
      if (!writerUserNotification.confirmed()) {
        updateWriterUserNotification(writerUserNotification.id, {
          confirmed: true,
        });
      }
      dispathAction(writerUserNotification.fullpath);
    },
    [],
  );
  return (
    <Layout
      title={'通知'}
      navigation={navigation}
      scrollable={false}
      close={true}>
      <NotificationList
        writerUserNotifications={writerUserNotifications}
        onSelectWriterUserNotification={onSelectWriterUserNotification}
      />
    </Layout>
  );
};

const dispathAction = (path: string) => {
  const state = getStateFromPath(path, {screens: LinkingConfig});

  if (!state) {
    return;
  }

  const action = getActionFromState(state);

  if (!action) {
    return;
  }

  try {
    navigationRef?.current?.dispatch(action);
  } catch (error) {
    // noop
  }
};

export default React.memo(New);
