import React from 'react';
import {Helmet} from 'react-helmet';

interface Props {
  description: string;
  keywords: string;
  title?: string;
  noindex?: boolean;
  indexifembedded?: boolean;
}

const MetaTags: React.FunctionComponent<Props> = props => {
  const {description, keywords, title, noindex, indexifembedded} = props;
  return (
    <Helmet>
      {title ? <title>{title}</title> : null}
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords}></meta>
      {noindex ? <meta name="googlebot" content="noindex" /> : null}
      {indexifembedded ? (
        <meta name="googlebot" content="indexifembedded" />
      ) : null}
    </Helmet>
  );
};

export default MetaTags;
