// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SocialLogin, {ErrorData, Params, SuccessData} from './SocialLogin';

import NetAccessTokensRepository from '../../../data/repositories/writer/social_accounts/line_accounts/NetAccessTokensRepository';

export type SuccessData = SuccessData;
export type ErrorData = ErrorData;

export default class LineLogin implements SocialLogin {
  public login = (params: Params) => {
    const {onSuccess} = params;
    const listener = async (event: MessageEvent) => {
      if (event.data && event.data.type !== 'line_oauth') {
        return;
      }
      window.removeEventListener('message', listener);
      const accessToken = await new NetAccessTokensRepository().create({
        code: event.data.params['code'],
        redirectUri: process.env
          .REACT_APP_LINE_WRITER_AUTH_REDIRECT_URI as string,
      });
      onSuccess({
        accessToken: accessToken.accessToken,
        email: '',
        id: accessToken.decodedIdToken[0].sub,
        provider: 'line',
        screenName: accessToken.decodedIdToken[0].name,
      });
    };
    window.addEventListener('message', listener, false);

    window.open(
      `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_LINE_WRITER_CHANNEL_ID}&redirect_uri=${process.env.REACT_APP_LINE_WRITER_AUTH_REDIRECT_URI}&state=state&scope=openid%20profile`,
      undefined,
      `width=500,height=600,toolbar=no,menubar=no,left=${
        window.innerWidth / 2 - 250
      },top=${window.innerHeight / 2 - 300}`,
    );
  };
}
