import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import Story from '../../../domain/entities/Story';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetStoriesRepository from '../../../data/repositories/writer/NetStoriesRepository';

const types = {
  failure: Types.IndexStoriesFailure,
  request: Types.IndexStoriesRequest,
  success: Types.IndexStoriesSuccess,
};

export interface Params extends BaseParams {
  serialized?: boolean;
  writing?: boolean;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Story>> {
  return indexGenerics(new NetStoriesRepository(), types, params);
}
