import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import Story from '../../../domain/entities/Story';

export default function reducer(
  state: {[key: number]: Story} = {},
  action: AnyAction,
): {[key: number]: Story} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexStoriesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: Story}, record: Story) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.ShowStorySuccess:
    case Types.CreateStorySuccess:
    case Types.UpdateStorySuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case Types.DestroyStorySuccess: {
      const deleteState = {...state};
      delete deleteState[action.payload];
      return deleteState;
    }
    default:
      return state;
  }
}
