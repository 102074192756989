import * as React from 'react';
import {
  GestureResponderEvent,
  StyleProp,
  TextStyle,
  ViewStyle,
} from 'react-native';

import Button from './Button';

import {colors} from '../../../styles/variables';

import DimensionContext from '../../shared/dimension/DimensionContext';

import {baseStyleWidth} from '../../../styles/variables';

export type TintColor = 'orange' | 'white' | 'black';

export type FontSizeClass = 'large' | 'small';

interface Props {
  tintColor: TintColor;
  style?: StyleProp<ViewStyle>;
  fontStyle?: StyleProp<TextStyle>;
  buttonSize?: {width: number; height: number};
  fontSizeClass?: FontSizeClass;
  disabled?: boolean;
  unwrappedText?: boolean;
  children?: React.ReactNode;
  onPress: (e: GestureResponderEvent) => void;
  onPressIn?: (e: GestureResponderEvent) => void;
}

const BaseButton: React.FunctionComponent<Props> = props => {
  const {
    tintColor,
    style,
    fontStyle,
    buttonSize,
    fontSizeClass,
    disabled,
    unwrappedText,
    children,
    onPress,
    onPressIn,
  } = props;
  const state = disabled ? 'disabled' : 'enabled';
  const colorStyle = TINT_COLOR_TO_STYLE[tintColor][state];
  const highlightStyle = TINT_COLOR_TO_HIGHTLIGHT_STYLE[tintColor];
  const fontColorStyle = TINT_COLOR_TO_FONT_STYLE[tintColor][state];
  const fontSizeStyle = fontSizeClass === 'small' ? {fontSize: 13} : undefined;
  const borderRadiusStyle =
    fontSizeClass === 'small' ? {borderRadius: 16} : undefined;
  return (
    <DimensionContext.Consumer>
      {context => {
        const rate = context.content.width / baseStyleWidth;
        return (
          <Button
            style={[
              colorStyle,
              borderRadiusStyle,
              scaledSieStyle(buttonSize, rate),
              style,
            ]}
            highlightStyle={highlightStyle}
            fontStyle={[fontColorStyle, fontSizeStyle, fontStyle]}
            disabled={disabled}
            unwrappedText={unwrappedText}
            onPress={onPress}
            onPressIn={onPressIn}>
            {children}
          </Button>
        );
      }}
    </DimensionContext.Consumer>
  );
};

const scaledSieStyle = (sizeStyle: ViewStyle | undefined, rate: number) => {
  if (sizeStyle) {
    const width =
      sizeStyle.width && Number.isInteger(sizeStyle.width)
        ? Number(sizeStyle.width) * rate
        : sizeStyle.width;
    const height =
      sizeStyle.height && Number.isInteger(sizeStyle.height)
        ? Number(sizeStyle.height) * rate
        : sizeStyle.height;
    return {...sizeStyle, width, height};
  }
  return undefined;
};

export default React.memo(BaseButton);

const DEFAULT_DISABLED_STYLE = {
  backgroundColor: colors.disableColor,
  borderColor: colors.disableColor,
} as ViewStyle;

const TINT_COLOR_TO_STYLE = {
  black: {
    disabled: DEFAULT_DISABLED_STYLE,
    enabled: {
      backgroundColor: colors.lightBlack,
      borderColor: colors.lightBlack,
    } as ViewStyle,
  },
  white: {
    disabled: DEFAULT_DISABLED_STYLE,
    enabled: {
      backgroundColor: 'white',
      borderColor: colors.lightBlack,
    } as ViewStyle,
  },
  orange: {
    disabled: DEFAULT_DISABLED_STYLE,
    enabled: {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    } as ViewStyle,
  },
};

const TINT_COLOR_TO_HIGHTLIGHT_STYLE = {
  black: {
    backgroundColor: colors.textColor,
  } as ViewStyle,
  white: {
    backgroundColor: colors.backgroundGray,
  } as ViewStyle,
  orange: {
    backgroundColor: '#rgba(255, 143, 19, 0.85)',
    borderColor: '#rgba(255, 143, 19, 0.85)',
  } as ViewStyle,
};

const DEFAULT_DISABLED_FONT_STYLE = {
  color: colors.disableTextColor,
} as TextStyle;

const TINT_COLOR_TO_FONT_STYLE = {
  black: {
    disabled: DEFAULT_DISABLED_FONT_STYLE,
    enabled: {
      color: 'white',
    } as TextStyle,
  },
  white: {
    disabled: DEFAULT_DISABLED_FONT_STYLE,
    enabled: {
      color: colors.lightBlack,
    } as TextStyle,
  },
  orange: {
    disabled: DEFAULT_DISABLED_FONT_STYLE,
    enabled: {
      color: 'white',
    } as TextStyle,
  },
};
