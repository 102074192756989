import {Dispatch} from 'redux';

import Types from '../../Types';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

import NetCurrentUserRepository from '../../../../data/repositories/writer/NetCurrentUserRepository';

export interface Params {}

export default function action(): (
  dispatch: Dispatch<any>,
) => Promise<CurrentUser> {
  return async (dispatch: Dispatch<any>): Promise<CurrentUser> => {
    try {
      dispatch({type: Types.ResetWriterCurrentUser});
      const payload = await new NetCurrentUserRepository().find();
      dispatch({payload, type: Types.ShowWriterCurrentUserSuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateWriterCurrentUserFailure});
      throw err;
    }
  };
}
