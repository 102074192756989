import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Index, {DispatchProps, StateProps} from '../../components/manuals/Index';

import AppState from '../../reducers/AppState';

import indexManualCategories, {
  Params as ManualCategoryIndexParams,
} from '../../actions/writer/manual_categories';

import selectEntities from '../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries} = state;
  const manualCategoriesParams = {} as ManualCategoryIndexParams;
  const manualCategories = selectEntities(
    entities.writer.manualCategories,
    queries.writer.manualCategories,
    manualCategoriesParams,
  );
  return {
    manualCategories,
    manualCategoriesParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  props: Props,
) => {
  return {
    indexManualCategories: (params: ManualCategoryIndexParams) =>
      dispatch(indexManualCategories(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
