import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import dateformat from 'dateformat';
import numeral from 'numeral';

import SeriesStateLabel from './SeriesStateLabel';
import ViolationStateLabel from './ViolationStateLabel';
import PublicStateLabel from '../../shared/PublicStateLabel';

import {colors} from '../../../styles/variables';

import Story from '../../../../domain/entities/Story';

interface Props {
  className?: string;
  story: Story;
  children?: React.ReactNode;
}

const StoryInfo: React.FunctionComponent<Props> = props => {
  const {story} = props;
  return (
    <>
      <View style={styles.top}>
        <View style={styles.titleBox}>
          <Text style={styles.title}>{story.title}</Text>
        </View>
        {story.catchPhrase.length > 0 ? (
          <View style={styles.catchPhraseBox}>
            <Text style={styles.catchPhrase}>{story.catchPhrase}</Text>
          </View>
        ) : null}
        <View style={styles.labelAndLastEpisode}>
          <SeriesStateLabel serialized={story.serialized} />
          {story.lastEpisodePublishedTitle ? (
            <Text style={styles.lastEpisode}>
              最新 : {story.lastEpisodePublishedTitle}
            </Text>
          ) : null}
        </View>
      </View>
      <View style={styles.bottom}>
        <View style={styles.lastUpdatedAndPublicState}>
          {story.published ? (
            <Text style={styles.lastUpdatedAtText}>
              {dateformat(story.updatedAt, 'yyyy.mm.dd')} 更新
            </Text>
          ) : null}
          {story.regulatoryViolationStatus ? (
            <ViolationStateLabel
              regulatoryViolationStatus={story.regulatoryViolationStatus}
            />
          ) : (
            <PublicStateLabel published={story.published} />
          )}
        </View>
        <Text style={styles.totalTapCount}>
          {numeral(story.estimatedTotalTapCount).format('0,0')}tap
        </Text>
      </View>
    </>
  );
};

export default React.memo(StoryInfo);

const styles = StyleSheet.create({
  top: {
    marginVertical: 4,
  } as ViewStyle,
  titleBox: {
    marginVertical: 4,
    paddingRight: 40,
  } as ViewStyle,
  title: {
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  catchPhraseBox: {
    marginVertical: 4,
  } as ViewStyle,
  catchPhrase: {
    color: '#666666',
    fontSize: 11,
  } as TextStyle,
  labelAndLastEpisode: {
    alignItems: 'baseline',
    flexDirection: 'row',
    marginVertical: 5,
  } as ViewStyle,
  lastEpisode: {
    flex: 1,
    color: '#666666',
    fontSize: 10,
    marginHorizontal: 6,
  } as TextStyle,
  lastUpdatedAtText: {
    color: colors.lightGray,
    fontSize: 9,
    paddingRight: 3,
  } as TextStyle,
  bottom: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  lastUpdatedAndPublicState: {
    flexDirection: 'row',
  } as ViewStyle,
  totalTapCount: {
    color: '#ff8f13',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
});
