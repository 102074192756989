import Entity from './Entity';

import ActorCharacter from './ActorCharacter';
import Gender from '../value_objects/Gender';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class Actor implements Entity {
  constructor(
    public id: number,
    public userId: number | null,
    public name: string,
    public actorCategoryId: number,
    public originalImageUrl: string,
    public actorCharacter: ActorCharacter,
    public gender: Gender,
    public contestIds: Array<number>,
    public price: number | null,
    public amount: number | null,
    public makerProOnly: boolean,
    public options: {allowedInversion?: boolean},
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(
      this.actorCharacter.originalImageUrl,
      options,
    );
  }
}
