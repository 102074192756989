import EntityMapper from '../EntityMapper';

import Manual from '../../../../domain/entities/writer/Manual';

import ManualCategoryMapper, {ManualCategoryData} from './ManualCategoryMapper';

export interface ManualData {
  id: number;
  title: string;
  content: string;
  writer_manual_category: ManualCategoryData;
}

export default class ManualMapper implements EntityMapper<ManualData, Manual> {
  private manualCategoryMapper = new ManualCategoryMapper();

  public map(obj: ManualData): Manual {
    return new Manual(
      obj.id,
      obj.title,
      obj.content,
      this.manualCategoryMapper.map(obj.writer_manual_category),
    );
  }
}
