import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import File from '../../../domain/entities/File';
import Story from '../../../domain/entities/Story';

import NetStoriesRepository from '../../../data/repositories/writer/NetStoriesRepository';

import StoryCreateEvent from '../../../domain/services/analytics_particular_events/StoryCreateEvent';

export interface Params {
  title?: string | null;
  catchPhrase?: string | null;
  summary?: string | null;
  introduction?: string | null;
  coverIllustrator?: string | null;
  image?: File | null;
  coverImage?: File | null;
  enableSyncEpisodesPrivateStatus?: boolean;
  enableSyncCharactersPrivateStatus?: boolean;
  mainGenreId?: number | null;
  subGenreIds?: number[] | null;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Story> {
  return async (dispatch: Dispatch<any>): Promise<Story> => {
    try {
      const payload = await new NetStoriesRepository().create(
        {story: params},
        {multipart: true},
      );
      dispatch({payload, type: Types.CreateStorySuccess});
      sendDefaultCustomEvent({
        resourceType: 'story',
        resourceId: payload.id,
        resourceName: payload.title,
        actionName: 'create',
      });
      new StoryCreateEvent().create(payload).then(sameEventsCount => {
        if (sameEventsCount === 1) {
          dispatch({
            payload: 'first_story_created',
            type: Types.UpdateCoachmarkModal,
          });
        }
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateStoryFailure});
      throw err;
    }
  };
}
