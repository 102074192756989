import * as React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import IconsTips from './IconsTips';

import LikeIcon from '../../../shared/icons/reaction/LikeIcon';
import HahaIcon from '../../../shared/icons/reaction/HahaIcon';
import SurprisedIcon from '../../../shared/icons/reaction/SurprisedIcon';
import SadIcon from '../../../shared/icons/reaction/SadIcon';
import CreepyIcon from '../../../shared/icons/reaction/CreepyIcon';

interface Props {
  reactionIds: number[];
  totalCount: number | null;
  visibleIconsTips: boolean;
  onRequestToggleReaction: (id: number) => void;
}

const ReactionSearchForm: React.FunctionComponent<Props> = props => {
  const {reactionIds, totalCount, visibleIconsTips, onRequestToggleReaction} =
    props;
  return (
    <View style={styles.container}>
      <View style={styles.search}>
        <Text style={styles.searchLabel}>絞り込み検索</Text>
        {[
          {
            id: 1,
            component: LikeIcon,
          },
          {
            id: 2,
            component: HahaIcon,
          },
          {
            id: 3,
            component: SurprisedIcon,
          },
          {
            id: 4,
            component: SadIcon,
          },
          {
            id: 5,
            component: CreepyIcon,
          },
        ].map(row => (
          <Pressable
            style={[
              styles.button,
              reactionIds.includes(row.id) ? null : styles.buttonInactive,
            ]}
            onPress={() => onRequestToggleReaction(row.id)}>
            <row.component
              color={
                reactionIds.includes(row.id)
                  ? BUTTON_COLOR.active
                  : BUTTON_COLOR.inactive
              }
              size={16}
            />
          </Pressable>
        ))}
      </View>
      <View style={styles.bottom}>
        <Text style={styles.totalCount}>
          {totalCount !== null ? `全${totalCount}件` : ''}
        </Text>
        <IconsTips visible={visibleIconsTips} />
      </View>
    </View>
  );
};

const BUTTON_COLOR = {
  active: '#ff8f13',
  inactive: '#999999',
};

export default React.memo(ReactionSearchForm);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    padding: 16,
  } as ViewStyle,
  search: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  searchLabel: {
    color: '#222',
    fontSize: 10,
    fontWeight: 'bold',
    marginRight: 12,
  } as TextStyle,
  button: {
    width: 47,
    height: 28,
    borderColor: '#ff8f13',
    borderWidth: 1,
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 4,
    ...Platform.select({
      web: {
        userSelect: 'none',
      },
      default: {},
    }),
  } as ViewStyle,
  buttonInactive: {
    backgroundColor: '#efefef',
    borderColor: '#efefef',
  } as ViewStyle,
  bottom: {
    marginTop: 16,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  totalCount: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
});
