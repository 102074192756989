import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import MemberListItem from './MemberListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectMember from '../../../../../domain/entities/ProjectMember';

interface Props {
  members: ProjectMember[];
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
  onForceUpdate: () => void;
}

const MemberList: React.FC<Props> = props => {
  const {members, ListHeaderComponent, onForceUpdate} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ProjectMember>) => (
      <MemberListItem member={info.item} onForceUpdate={onForceUpdate} />
    ),
    [],
  );
  return (
    <FlatList
      data={members}
      renderItem={renderItem}
      ListHeaderComponent={ListHeaderComponent}
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(MemberList);
