import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

export default function reducer(
  state: {[key: number]: CurrentUser} = {},
  action: AnyAction,
): {[key: number]: CurrentUser} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.ShowWriterCurrentUserSuccess:
    case Types.CreateWriterCurrentUserSuccess:
    case Types.UpdateWriterCurrentUserSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
