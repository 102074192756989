import {Dispatch} from 'redux';

import AppState from '../../../reducers/AppState';

import EpisodeAggregation from '../../../../domain/value_objects/summary/EpisodeAggregation';

import NetEpisodeAggregationsRepository from '../../../../data/repositories/writer/summary/NetEpisodeAggregationsRepository';

export interface Option {
  from?: Date | undefined;
  to?: Date | undefined;
}

export default function action(
  id: number,
  options: Option = {},
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<EpisodeAggregation> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<EpisodeAggregation> => {
    const repository = new NetEpisodeAggregationsRepository();
    const payload = await repository.find(id, options);
    return payload;
  };
}
