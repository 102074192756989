import NetAnalyticsParticularEventsRepository from '../../../data/repositories/writer/NetAnalyticsParticularEventsRepository';

import {sendDefaultCustomEvent} from '../../../presentation/helpers/analytics';

export default class EpisodeFormCreateEvent {
  public async create() {
    try {
      const result = await new NetAnalyticsParticularEventsRepository().findBy({
        resourceType: 'episode',
        actionName: 'create',
        per: 1,
      });
      const analyticsParticularEvent =
        await new NetAnalyticsParticularEventsRepository().create({
          analyticsParticularEventParams: {
            resourceType: 'episode_form',
            actionName: 'create',
            resourceId: result.total + 1,
          },
        });
      sendDefaultCustomEvent({
        resourceType: 'episode_form',
        resourceId: analyticsParticularEvent.userId,
        actionName: 'create',
      });
      switch (analyticsParticularEvent.sameEventsCount) {
        case 1:
          sendDefaultCustomEvent({
            resourceType: 'first_episode_form',
            resourceId: analyticsParticularEvent.userId,
            actionName: 'create',
          });
          break;
        case 2:
          sendDefaultCustomEvent({
            resourceType: 'second_episode_form',
            resourceId: analyticsParticularEvent.userId,
            actionName: 'create',
          });
          break;
      }
      return analyticsParticularEvent.sameEventsCount;
    } catch (e) {
      return null;
    }
  }
}
