import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Edit, {
  DispatchProps,
  StateProps,
} from '../../components/characters/Edit';

import AppState from '../../reducers/AppState';

import showCharacter from '../../actions/characters/show';
import indexActorCharacterFaces, {
  Params as ActorCharacterFaceIndexParams,
} from '../../actions/actor_character_faces/index';
import createCharacterForm, {
  Params as CharacterFormCreateParams,
} from '../../actions/character_forms/create';
import updateCharacterForm, {
  Params as CharacterFormUpdateParams,
} from '../../actions/character_forms/update';
import showCurrentUserStatus from '../../actions/writer/current_user_status/show';

import selectEntity from '../../helpers/selectEntity';
import selectEntities from '../../helpers/selectEntities';

import {EditCharacterRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EditCharacterRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const {characterId} = route.params;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const character = selectEntity(entities.characters, Number(characterId));
  const characterForm =
    forms.characterForm && forms.characterForm.id === Number(characterId)
      ? forms.characterForm
      : null;
  const actorCharacterFacesParams: ActorCharacterFaceIndexParams | null =
    characterForm?.actorCharacterId
      ? {actorCharacterId: characterForm.actorCharacterId}
      : null;
  const actorCharacterFaces = actorCharacterFacesParams
    ? selectEntities(
        entities.actorCharacterFaces,
        queries.actorCharacterFaces,
        actorCharacterFacesParams,
      )
    : null;
  return {
    navigation,
    character,
    characterForm,
    actorCharacterFacesParams,
    actorCharacterFaces,
    currentUserStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCharacter: (id: number) => dispatch(showCharacter(id)),
    indexActorCharacterFaces: (params: ActorCharacterFaceIndexParams) =>
      dispatch(indexActorCharacterFaces(params)),
    createCharacterForm: (params: CharacterFormCreateParams) =>
      dispatch(createCharacterForm(params)),
    updateCharacterForm: (params: CharacterFormUpdateParams) =>
      dispatch(updateCharacterForm(params)),
    showCurrentUserStatus: () => dispatch(showCurrentUserStatus()),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
