import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import ActorHairColorMapper, {
  ActorHairColorData,
} from '../../../entities/mappers/character_maker/ActorHairColorMapper';

import PaginatedResult from '../../../../domain/results/PaginatedResult';
import ActorHairColor from '../../../../domain/entities/character_maker/ActorHairColor';
import ActorHairColorsRepository from '../../../../domain/repositories/writer/character_maker/ActorHairColorsRepository';

export default class NetActorHairColorsRepository
  extends NetResourcesRepository<ActorHairColor, ActorHairColorData>
  implements ActorHairColorsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_maker/actor_hair_styles',
        new ActorHairColorMapper(),
      ),
    );
  }

  public async findBy(params: {
    actorId: number;
    name?: string;
    colorPatternId?: number;
    sort?: 'hair_style' | 'hair_color';
  }): Promise<PaginatedResult<ActorHairColor>> {
    return super.findBy(params);
  }
}
