import * as React from 'react';

import Detail from './partials/Detail';
import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {CommercialVideoExportJobExecutionRouteProp} from '../../../navigators/RouteProps';

import EpisodeVideoExportJobExecution from '../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

import NetEpisodeVideoExportJobExecutionsRepository from '../../../../data/repositories/writer/NetEpisodeVideoExportJobExecutionsRepository';

export interface Params {
  exportJobExecutionId: number;
  notification?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CommercialVideoExportJobExecutionRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {exportJobExecutionId, notification} = route.params;
  const [episodeVideoExportJobExecution, setEpisodeVideoExportJobExecution] =
    React.useState<EpisodeVideoExportJobExecution | null>(null);
  React.useEffect(() => {
    new NetEpisodeVideoExportJobExecutionsRepository()
      .find(exportJobExecutionId)
      .then(episodeVideoExportJobExecution => {
        setEpisodeVideoExportJobExecution(episodeVideoExportJobExecution);
      });
  }, []);
  return (
    <Layout
      title={
        notification
          ? '通知'
          : episodeVideoExportJobExecution
          ? episodeVideoExportJobExecution.episode.story.title
          : ''
      }
      navigation={navigation}
      hideHeaderBottomBorder={notification ? false : true}
      back={true}>
      {episodeVideoExportJobExecution ? (
        <Detail
          episodeVideoExportJobExecution={episodeVideoExportJobExecution}
          notification={notification}
        />
      ) : null}
    </Layout>
  );
};

export default React.memo(Show);
