import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import SoundCategory from '../../../domain/entities/SoundCategory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetSoundCategoriesRepository from '../../../data/repositories/NetSoundCategoriesRepository';

const types = {
  failure: Types.IndexSoundCategoriesFailure,
  request: Types.IndexSoundCategoriesRequest,
  success: Types.IndexSoundCategoriesSuccess,
};

export interface Params extends BaseParams {
  id?: number | null;
  parentId?: number | null;
  onlyBackgroundMusic?: boolean | null;
  onlySoundEffect?: boolean | null;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<SoundCategory>> {
  return indexGenerics(new NetSoundCategoriesRepository(), types, params);
}
