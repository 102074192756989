import * as React from 'react';
import {
  InteractionManager,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
} from 'react-native';

import LayoutWrapper from './partials/LayoutWrapper';
import SearchInput from './partials/SearchInput';
import AgeRange from './partials/AgeRange';
import CategoryCheckBoxList from './partials/CategoryCheckBoxList';
import SearchButtonWithTotalCount from './partials/SearchButtonWithTotalCount';
import SelectButton from './partials/SelectButton';
import CheckBoxListSection from './partials/CheckBoxListSection';

import PopularTagsBox from '../../PopularTagsBox';
import Layout from '../../Layout';
import MultiSwitch from '../../forms/MultiSwitch';
import ArrowBackIcon from '../../icons/ArrowBackIcon';
import HeaderRightButton from '../../buttons/HeaderRightButton';

import NavigationProp from '../../../../navigators/NavigationProp';

import {Params as ActorCategoryIndexParams} from '../../../../actions/actor_categories/index';
import {Params as ActorIndexParams} from '../../../../actions/actors/index';

import Actor from '../../../../../domain/entities/Actor';
import ActorCategory from '../../../../../domain/entities/ActorCategory';
import PopularTag from '../../../../../domain/entities/PopularTag';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';

import buildActorSearchParams from '../../../../view_models/buildActorSearchParams';

import NetPopularTagsRepository from '../../../../../data/repositories/writer/NetPopularTagsRepository';

export interface StateProps {
  navigation: NavigationProp;
  actorCategoriesParams: ActorCategoryIndexParams;
  actorCategories: ActorCategory[] | null;
  formValues?: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    purchase?: boolean;
    paid?: boolean;
    allowedInversion?: Array<'true' | 'false'>;
  };
  withPaid?: boolean;
  hideBack?: boolean;
}

export interface DispatchProps {
  indexActorCategories: (
    params: ActorCategoryIndexParams,
  ) => Promise<PaginatedResult<ActorCategory>>;
  indexActors: (params: ActorIndexParams) => Promise<PaginatedResult<Actor>>;
  onPress: (params: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    purchase?: boolean;
    paid?: boolean;
    allowedInversion?: Array<'true' | 'false'>;
  }) => void;
}

interface Props extends StateProps, DispatchProps {
  displayAllowedInversion?: boolean;
}

interface State {
  modalType: 'facial_feature' | 'hair_color' | null;
  popularTags?: Array<PopularTag> | null;
  totalCount: number;
  query: string;
  ageMin: number;
  ageMax: number;
  attributeValue: number;
  rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
  favorite?: boolean;
  purchase?: boolean;
  paid?: boolean;
  allowedInversion?: Array<'true' | 'false'>;
  modalRootActorCategoryId: number | null;
  modalActorCategoryIds: Array<number>;
}

export default class Index extends React.Component<Props, State> {
  private timerId: any | null = null;

  constructor(props: Props) {
    super(props);
    const {
      query,
      ageMax,
      ageMin,
      rootCategoryIdToActorCategoryIds,
      favorite,
      purchase,
      paid,
      allowedInversion,
    } = props.formValues || {
      query: '',
      ageMin: 0,
      ageMax: 6,
      rootCategoryIdToActorCategoryIds: {
        [ActorCategory.ATTRIBUTE_ID]: [ActorCategory.HUMAN_ID],
        [ActorCategory.HUMAN_GENDER_ID]: [ActorCategory.HUMAN_GENDER_MALE_ID],
      },
      favorite: false,
      purchase: false,
      paid: false,
    };
    this.state = {
      modalType: null,
      totalCount: 0,
      query,
      ageMin,
      ageMax,
      attributeValue:
        ((rootCategoryIdToActorCategoryIds || {})[ActorCategory.ATTRIBUTE_ID] ||
          {})[0] || ActorCategory.HUMAN_ID,
      rootCategoryIdToActorCategoryIds,
      favorite,
      purchase,
      paid,
      allowedInversion: allowedInversion || ['true', 'false'],
      modalRootActorCategoryId: null,
      modalActorCategoryIds: [],
    };
  }

  public componentDidMount() {
    const {actorCategories, actorCategoriesParams, indexActorCategories} =
      this.props;
    InteractionManager.runAfterInteractions(() => {
      new NetPopularTagsRepository()
        .findBy({taggableType: 'Actor'})
        .then(result => {
          this.setState({popularTags: result.records});
        });

      if (!actorCategories) {
        indexActorCategories(actorCategoriesParams);
      }
      this.fetchTotalCount();
    });
  }

  public render(): React.ReactNode {
    const {navigation, hideBack, displayAllowedInversion} = this.props;
    const {
      modalType,
      query,
      ageMin,
      ageMax,
      totalCount,
      attributeValue,
      rootCategoryIdToActorCategoryIds,
      popularTags,
      allowedInversion,
    } = this.state;
    const genderValue =
      rootCategoryIdToActorCategoryIds[ActorCategory.HUMAN_GENDER_ID] &&
      rootCategoryIdToActorCategoryIds[ActorCategory.HUMAN_GENDER_ID][0];
    const attributeActorCategories = this.getAttributeActorCategories();
    const groupedActorCategories = this.generateGroupedActorCategories() || [];
    const humanGenderActorCategories = this.getHumanGenderActorCategories();
    const facialFeatureActorCategoriesItem =
      this.getFacialFeatureActorCategoriesItem();
    const hairColorActorCategoriesItem = this.getHairColorActorCategoriesItem();
    return (
      <>
        <LayoutWrapper activeValue={'facial_feature'} value={modalType}>
          <Layout
            title={'顔立ち'}
            hideHeaderBottomBorder={true}
            leftButton={this.leftButton}
            rightButton={this.rightButton}>
            {facialFeatureActorCategoriesItem &&
              modalType === 'facial_feature' && (
                <CategoryCheckBoxList
                  key={`${facialFeatureActorCategoriesItem.rootActorCategory.id}`}
                  hideTitle={true}
                  rootActorCategory={
                    facialFeatureActorCategoriesItem.rootActorCategory
                  }
                  actorCategories={
                    facialFeatureActorCategoriesItem.actorCategories
                  }
                  selectedActorCategoryIds={
                    facialFeatureActorCategoriesItem.selectedActorCategoryIds
                  }
                  onChangeValues={(parentId, ids) => {
                    this.setStateAndFetchTotalCount({
                      modalRootActorCategoryId: parentId,
                      modalActorCategoryIds: ids,
                    });
                  }}
                />
              )}
          </Layout>
        </LayoutWrapper>
        <LayoutWrapper activeValue={'hair_color'} value={modalType}>
          <Layout
            title={'髪色'}
            hideHeaderBottomBorder={true}
            leftButton={this.leftButton}
            rightButton={this.rightButton}>
            {hairColorActorCategoriesItem && modalType === 'hair_color' && (
              <CategoryCheckBoxList
                key={`${hairColorActorCategoriesItem.rootActorCategory.id}`}
                hideTitle={true}
                rootActorCategory={
                  hairColorActorCategoriesItem.rootActorCategory
                }
                actorCategories={hairColorActorCategoriesItem.actorCategories}
                selectedActorCategoryIds={
                  hairColorActorCategoriesItem.selectedActorCategoryIds
                }
                onChangeValues={(parentId, ids) => {
                  this.setStateAndFetchTotalCount({
                    modalRootActorCategoryId: parentId,
                    modalActorCategoryIds: ids,
                  });
                }}
              />
            )}
          </Layout>
        </LayoutWrapper>
        <LayoutWrapper activeValue={null} value={modalType}>
          <Layout
            title={'キャラクター検索条件'}
            back={hideBack ? false : true}
            close={true}
            navigation={navigation}
            scrollable={false}>
            <ScrollView contentContainerStyle={{marginBottom: 48}}>
              {attributeActorCategories && popularTags && (
                <>
                  <SearchInput
                    query={query}
                    onChangeText={this.handleChangeText}
                  />
                  <PopularTagsBox
                    style={{marginBottom: 0}}
                    popularTags={popularTags}
                    onSelectPopularTag={this.handleSelectPopularTag}
                  />
                  {attributeActorCategories && (
                    <View style={{marginHorizontal: 16, marginTop: 10}}>
                      <Text style={styles.title}>属性</Text>
                      <MultiSwitch
                        style={{marginTop: 6}}
                        items={attributeActorCategories.actorCategories.map(
                          actorCategory => {
                            return {
                              label: actorCategory.name,
                              value: `${actorCategory.id}`,
                            };
                          },
                        )}
                        value={`${attributeValue}`}
                        onSelectItem={this.handleSelectAttributeItem}
                      />
                    </View>
                  )}
                  {attributeActorCategories &&
                    humanGenderActorCategories &&
                    attributeValue === ActorCategory.HUMAN_ID && (
                      <View style={{marginHorizontal: 16}}>
                        <Text style={styles.title}>性別</Text>
                        <MultiSwitch
                          style={{marginTop: 6}}
                          items={humanGenderActorCategories.map(
                            actorCategory => {
                              return {
                                label: actorCategory.name,
                                value: `${actorCategory.id}`,
                              };
                            },
                          )}
                          value={`${genderValue}`}
                          onSelectItem={this.handleSelectGenderItem}
                        />
                      </View>
                    )}
                  {attributeActorCategories &&
                    attributeValue === ActorCategory.HUMAN_ID && (
                      <AgeRange
                        ageMin={ageMin}
                        ageMax={ageMax}
                        onAgeMinChange={this.handleAgeMinChange}
                        onAgeMaxChange={this.handleAgeMaxChange}
                      />
                    )}
                  {attributeValue === ActorCategory.HUMAN_ID && (
                    <View
                      style={{
                        marginHorizontal: 16,
                        marginTop: 8,
                        marginBottom: 16,
                      }}>
                      <Text style={styles.title}>顔立ち</Text>
                      <SelectButton
                        values={
                          facialFeatureActorCategoriesItem
                            ? facialFeatureActorCategoriesItem.selectedActorCategoryNames
                            : []
                        }
                        placeholder={'顔立ちを選択'}
                        onPress={() =>
                          this.setState({
                            modalType: 'facial_feature',
                            modalRootActorCategoryId:
                              facialFeatureActorCategoriesItem
                                ?.rootActorCategory.id || null,
                            modalActorCategoryIds:
                              facialFeatureActorCategoriesItem?.selectedActorCategoryIds ||
                              [],
                          })
                        }
                      />
                    </View>
                  )}
                  {groupedActorCategories.map(item => (
                    <CategoryCheckBoxList
                      key={`${item.rootActorCategory.id}`}
                      rootActorCategory={item.rootActorCategory}
                      actorCategories={item.actorCategories}
                      selectedActorCategoryIds={item.selectedActorCategoryIds}
                      onChangeValues={this.handleChangeValues}
                    />
                  ))}
                  {attributeValue === ActorCategory.HUMAN_ID && (
                    <View style={{marginHorizontal: 16, marginBottom: 16}}>
                      <Text style={styles.title}>髪色</Text>
                      <SelectButton
                        values={
                          hairColorActorCategoriesItem
                            ? hairColorActorCategoriesItem.selectedActorCategoryNames
                            : []
                        }
                        placeholder={'髪色を選択'}
                        onPress={() =>
                          this.setState({
                            modalType: 'hair_color',
                            modalRootActorCategoryId:
                              hairColorActorCategoriesItem?.rootActorCategory
                                .id || null,
                            modalActorCategoryIds:
                              hairColorActorCategoriesItem?.selectedActorCategoryIds ||
                              [],
                          })
                        }
                      />
                    </View>
                  )}
                </>
              )}
              {displayAllowedInversion ? (
                <CheckBoxListSection
                  title={'反転機能'}
                  items={ALLOWE_INVERSION_ITEMS}
                  values={allowedInversion}
                  onChangeValues={this.handleChangeAllowedInversionValues}
                />
              ) : null}
            </ScrollView>
            <SearchButtonWithTotalCount
              totalCount={totalCount}
              onPress={this.handleSubmit}
            />
          </Layout>
        </LayoutWrapper>
      </>
    );
  }

  private getAttributeActorCategories = () => {
    const {actorCategories} = this.props;
    const {rootCategoryIdToActorCategoryIds} = this.state;
    if (!actorCategories) {
      return null;
    }
    if (!rootCategoryIdToActorCategoryIds) {
      return;
    }
    const filteredActorCategories = actorCategories.filter(
      actorCategory =>
        actorCategory.id === ActorCategory.ATTRIBUTE_ID ||
        actorCategory.parentId === ActorCategory.ATTRIBUTE_ID,
    );
    const rootActorCategory = filteredActorCategories.find(
      actorCategory => !actorCategory.parentId,
    );
    if (!rootActorCategory) {
      return null;
    }
    return {
      rootActorCategory,
      actorCategories: filteredActorCategories.filter(
        actorCategory => actorCategory.parentId,
      ),
      selectedActorCategoryIds:
        rootCategoryIdToActorCategoryIds[rootActorCategory.id],
    };
  };

  private generateGroupedActorCategories = () => {
    const {actorCategories} = this.props;
    const {attributeValue, rootCategoryIdToActorCategoryIds} = this.state;
    if (!actorCategories) {
      return null;
    }
    if (!rootCategoryIdToActorCategoryIds) {
      return;
    }
    const parentIdToActorCategories: {
      [key: number]: Array<ActorCategory>;
    } = {};
    const rootActorCategories = actorCategories.filter(actorCategory => {
      if (actorCategory.isAttribute()) {
        return false;
      }
      if (actorCategory.isHumanAge()) {
        return false;
      }
      if (actorCategory.isHumanGender()) {
        return false;
      }
      if (actorCategory.isHumanFacialFeature()) {
        return false;
      }
      if (actorCategory.isHumanHairColor()) {
        return false;
      }
      if (attributeValue !== actorCategory.getAttributeId()) {
        return false;
      }
      if (actorCategory.parentId) {
        parentIdToActorCategories[actorCategory.parentId] =
          parentIdToActorCategories[actorCategory.parentId] || [];
        parentIdToActorCategories[actorCategory.parentId].push(actorCategory);
      }
      return !actorCategory.parentId;
    });
    return rootActorCategories.map(rootActorCategory => {
      return {
        rootActorCategory,
        actorCategories: parentIdToActorCategories[rootActorCategory.id],
        selectedActorCategoryIds:
          rootCategoryIdToActorCategoryIds[rootActorCategory.id],
      };
    });
  };

  private getHairColorActorCategoriesItem = () => {
    const {actorCategories} = this.props;
    const {rootCategoryIdToActorCategoryIds} = this.state;
    if (!actorCategories) {
      return null;
    }
    const rootActorCategory = actorCategories.find(
      actorCategory => actorCategory.id === ActorCategory.HUMAN_HAIR_COLOR_ID,
    );
    if (!rootActorCategory) {
      return null;
    }
    const filteredActorCategories = actorCategories.filter(
      actorCategory =>
        actorCategory.isHumanHairColor() &&
        actorCategory.id !== ActorCategory.HUMAN_HAIR_COLOR_ID,
    );
    const selectedActorCategoryIds =
      rootCategoryIdToActorCategoryIds[rootActorCategory.id] || [];
    const selectedActorCategoryNames = selectedActorCategoryIds
      .map(
        selectedActorCategoryId =>
          filteredActorCategories.find(o => o.id == selectedActorCategoryId)
            ?.name,
      )
      .filter(notEmpty);
    return {
      rootActorCategory,
      actorCategories: filteredActorCategories,
      selectedActorCategoryIds,
      selectedActorCategoryNames,
    };
  };

  private getFacialFeatureActorCategoriesItem = () => {
    const {actorCategories} = this.props;
    const {rootCategoryIdToActorCategoryIds} = this.state;
    const genderValue =
      rootCategoryIdToActorCategoryIds[ActorCategory.HUMAN_GENDER_ID] &&
      rootCategoryIdToActorCategoryIds[ActorCategory.HUMAN_GENDER_ID][0];
    if (!genderValue) {
      return null;
    }
    if (!actorCategories) {
      return null;
    }
    const rootCategoryId =
      genderValue === ActorCategory.HUMAN_GENDER_MALE_ID
        ? ActorCategory.HUMAN_FACIAL_FEATURE_MALE_ID
        : ActorCategory.HUMAN_FACIAL_FEATURE_FEMALE_ID;
    const rootActorCategory = actorCategories.find(
      actorCategory => actorCategory.id === rootCategoryId,
    );
    if (!rootActorCategory) {
      return null;
    }
    const filteredActorCategories = actorCategories.filter(
      actorCategory =>
        actorCategory.parentId === rootCategoryId &&
        actorCategory.id !== rootCategoryId,
    );
    const selectedActorCategoryIds =
      rootCategoryIdToActorCategoryIds[rootActorCategory.id] || [];
    const selectedActorCategoryNames = selectedActorCategoryIds
      .map(
        selectedActorCategoryId =>
          filteredActorCategories.find(o => o.id == selectedActorCategoryId)
            ?.name,
      )
      .filter(notEmpty);
    return {
      rootActorCategory,
      actorCategories: filteredActorCategories,
      selectedActorCategoryIds,
      selectedActorCategoryNames,
    };
  };

  private getHumanGenderActorCategories = () => {
    const {actorCategories} = this.props;
    if (!actorCategories) {
      return null;
    }
    return actorCategories.filter(
      actorCategory => actorCategory.isHumanGender() && actorCategory.parentId,
    );
  };

  private handleChangeText = (query: string) => {
    this.setStateAndFetchTotalCount({query});
  };

  private handleSelectAttributeItem = (item: {
    label: string;
    value: string;
    optionLabel?: string;
  }) => {
    const {rootCategoryIdToActorCategoryIds} = this.state;
    const attributeValue = Number(item.value);
    if (
      rootCategoryIdToActorCategoryIds &&
      rootCategoryIdToActorCategoryIds[ActorCategory.ATTRIBUTE_ID]
    ) {
      if (
        rootCategoryIdToActorCategoryIds[ActorCategory.ATTRIBUTE_ID][0] ===
        attributeValue
      ) {
        return;
      }
    }
    this.setStateAndFetchTotalCount({
      attributeValue,
      ageMin: 0,
      ageMax: 6,
      rootCategoryIdToActorCategoryIds: {
        [ActorCategory.ATTRIBUTE_ID]: [attributeValue],
        ...(attributeValue === ActorCategory.HUMAN_ID
          ? {
              [ActorCategory.HUMAN_GENDER_ID]: [
                ActorCategory.HUMAN_GENDER_MALE_ID,
              ],
            }
          : {}),
      },
    });
  };

  private handleSelectGenderItem = (item: {
    label: string;
    value: string;
    optionLabel?: string;
  }) => {
    const rootCategoryIdToActorCategoryIds = {
      ...this.state.rootCategoryIdToActorCategoryIds,
      [ActorCategory.HUMAN_GENDER_ID]: [Number(item.value)],
      [ActorCategory.HUMAN_FACIAL_FEATURE_MALE_ID]: [],
      [ActorCategory.HUMAN_FACIAL_FEATURE_FEMALE_ID]: [],
    };
    this.setStateAndFetchTotalCount({
      rootCategoryIdToActorCategoryIds,
    });
  };

  private handleAgeMinChange = (ageMin: string | number) => {
    this.setStateAndFetchTotalCount({ageMin: Number(ageMin)});
  };

  private handleAgeMaxChange = (ageMax: string | number) => {
    this.setStateAndFetchTotalCount({ageMax: Number(ageMax)});
  };

  private handleChangeValues = (parentId: number, ids: Array<number>) => {
    const rootCategoryIdToActorCategoryIds = {
      ...this.state.rootCategoryIdToActorCategoryIds,
      [parentId]: ids,
    };
    this.setStateAndFetchTotalCount({rootCategoryIdToActorCategoryIds});
  };

  private handleChangeAllowedInversionValues = (
    values: Array<'true' | 'false'>,
  ) => {
    this.setStateAndFetchTotalCount({allowedInversion: values});
  };

  private setStateAndFetchTotalCount<K extends keyof State>(
    state: Pick<State, K>,
  ): void {
    this.setState(state, () => {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.fetchTotalCount();
      }, 250);
    });
  }

  private fetchTotalCount = () => {
    const {indexActors} = this.props;
    indexActors({...this.buildSearchParams(), perPage: 1}).then(
      searchResult => {
        this.setState({totalCount: searchResult.total});
      },
    );
  };

  private buildSearchParams = () => {
    const {withPaid} = this.props;
    const {
      query,
      ageMin,
      ageMax,
      rootCategoryIdToActorCategoryIds,
      favorite,
      purchase,
      paid,
      allowedInversion,
    } = this.state;
    return buildActorSearchParams({
      query,
      ageMin,
      ageMax,
      rootCategoryIdToActorCategoryIds,
      favorite,
      purchase,
      paid,
      allowedInversion,
      withPaid,
    });
  };

  private handleSubmit = () => {
    const {onPress} = this.props;
    const {
      query,
      ageMin,
      ageMax,
      rootCategoryIdToActorCategoryIds,
      favorite,
      purchase,
      paid,
      allowedInversion,
    } = this.state;
    onPress({
      query,
      ageMin,
      ageMax,
      rootCategoryIdToActorCategoryIds,
      favorite,
      purchase,
      paid,
      allowedInversion,
    });
  };

  private handleSelectPopularTag = (popularTag: PopularTag) => {
    const {onPress} = this.props;
    onPress({
      query: popularTag.tagName,
      ageMin: 0,
      ageMax: 6,
      rootCategoryIdToActorCategoryIds: {},
    });
  };

  private closeModal = () => {
    this.setState({modalType: null});
  };

  private leftButton = {
    handler: this.closeModal,
    tintColor: 'white',
    title: <ArrowBackIcon />,
  };

  private rightButton = {
    tintColor: 'white',
    title: (
      <HeaderRightButton
        onPress={() => {
          const {modalActorCategoryIds, modalRootActorCategoryId} = this.state;
          if (modalRootActorCategoryId) {
            this.handleChangeValues(
              modalRootActorCategoryId,
              modalActorCategoryIds,
            );
          }
          this.closeModal();
        }}>
        決定
      </HeaderRightButton>
    ),
  };
}

const styles = StyleSheet.create({
  title: {
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
});

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

const ALLOWE_INVERSION_ITEMS = [
  {
    label: '使用可',
    value: 'true',
  },
  {
    label: '使用不可',
    value: 'false',
  },
] as Array<{label: string; value: 'true' | 'false'}>;
