import React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import Actor from '../../../../../../domain/entities/shop/Actor';
import UserPoint from '../../../../../../domain/entities/writer/UserPoint';

import CheckButton from '../../../../shared/forms/CheckButton';
import PrimaryButton from '../../../../shared/buttons/PrimaryButton';

import {enabledPointPurchase} from '../../../../../helpers/features';

interface Props {
  actor: Actor;
  userPoint: UserPoint | null;
  consumptionPoint: number;
  amount: number;
  purchased?: boolean;
  enabledPartnerProgram?: boolean;
  onConfirm: () => void;
  onChangePoint: (text: string) => void;
}

const Form: React.FC<Props> = props => {
  const {
    userPoint,
    consumptionPoint,
    amount,
    purchased,
    enabledPartnerProgram,
    onConfirm,
    onChangePoint,
  } = props;
  const [checked, setChecked] = React.useState(false);
  const toggleChecked = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <View style={styles.container}>
      {enabledPartnerProgram && userPoint && enabledPointPurchase ? (
        <View style={styles.pointRow}>
          <Text style={styles.pointLabel}>ポイント利用</Text>
          <View style={styles.pointRowValue}>
            <TextInput
              style={styles.pointRowValueInput}
              value={`${consumptionPoint}`}
              onChangeText={onChangePoint}
            />
            <Text style={styles.pointRowValueUnit}>pt</Text>
          </View>
          <Text style={styles.pointNote}>
            ※1ptにつき￥1として利用できます。{'\n'}
            ※ポイントは商品の税抜き価格から引かれます。{'\n'}
            ※決済システムの都合上、割引後の商品価格が￥50～￥1となる場合はポイントを利用できません。
            {'\n'}
          </Text>
          <View style={styles.pointBalance}>
            <Text style={styles.pointBalanceLabel}>ポイント残高</Text>
            <Text style={styles.pointBalanceValue}>
              {numeral(userPoint.availablePoint - consumptionPoint).format(
                '0,0',
              )}{' '}
              pt
            </Text>
          </View>
        </View>
      ) : null}
      <Text style={styles.text}>このキャラクターを購入しますか？</Text>
      <Text style={styles.price}>￥{numeral(amount).format('0')}</Text>
      <CheckButton checked={checked} onPress={toggleChecked}>
        購入したイラストはTapNovelMakerのみでご利用できます。
      </CheckButton>
      <PrimaryButton
        disabled={
          purchased ||
          !checked ||
          (userPoint ? userPoint.availablePoint < consumptionPoint : false) ||
          amount < 0
        }
        style={styles.button}
        onPress={onConfirm}>
        {purchased ? '購入済み' : '購入する'}
      </PrimaryButton>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
    marginHorizontal: 16,
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 8,
  } as TextStyle,
  price: {
    color: '#222',
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 24,
  } as TextStyle,
  button: {
    marginTop: 16,
    width: 240,
  } as ViewStyle,
  pointRow: {
    width: '100%',
    marginBottom: 32,
  } as ViewStyle,
  pointLabel: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  pointRowValue: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    marginTop: 8,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  pointRowValueUnit: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    width: 32,
    textAlign: 'right',
  } as TextStyle,
  pointRowValueInput: {
    flex: 1,
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
  } as TextStyle,
  pointNote: {
    color: '#666',
    fontSize: 10,
    marginTop: 8,
    marginBottom: 10,
  } as TextStyle,
  pointBalance: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  pointBalanceLabel: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  pointBalanceValue: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
});
