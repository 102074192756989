import * as React from 'react';

import Form from '../../common/name_label_colors/partials/Form';

import Layout from '../../../shared/Layout';
import AlertModal from '../../../shared/modals/AlertModal';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CharacterFormEditNameLabelColorsRouteProp} from '../../../../navigators/RouteProps';

import {formatErrorMessages} from '../../../../helpers/errorMessages';

import {Params as CharacterFormCreateParams} from '../../../../actions/character_forms/create';
import {Params as CharacterFormUpdateParams} from '../../../../actions/character_forms/update';
import {Params as CharacterUpdateParams} from '../../../../actions/characters/update';

import Character from '../../../../../domain/entities/Character';
import SpeechBalloon from '../../../../../domain/entities/SpeechBalloon';
import CharacterForm from '../../../../../domain/forms/CharacterForm';
import NameLabelColor from '../../../../../domain/value_objects/NameLabelColor';

export interface Params {
  projectId: number;
  characterId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CharacterFormEditNameLabelColorsRouteProp;
  character: Character | null;
  characterForm: CharacterForm | null;
  speechBalloonId: number;
  speechBalloon: SpeechBalloon | null;
}

export interface DispatchProps {
  createCharacterForm: (params: CharacterFormCreateParams) => void;
  updateCharacterForm: (params: CharacterFormUpdateParams) => void;
  updateCharacter: (
    id: number,
    params: CharacterUpdateParams,
  ) => Promise<Character>;
  showSpeechBalloon: (id: number) => Promise<SpeechBalloon>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      speechBalloon,
      speechBalloonId,
      characterForm,
      showSpeechBalloon,
    } = this.props;
    if (!speechBalloon) {
      showSpeechBalloon(speechBalloonId);
    }
    if (!characterForm) {
      navigation.goBack();
    }
  }

  public render(): React.ReactNode {
    const {navigation, character, characterForm, speechBalloon} = this.props;
    const {loading, alertMessage} = this.state;
    return (
      <Layout
        title={'キャラクター情報'}
        navigation={navigation}
        back={true}
        loading={loading}>
        {character && characterForm && speechBalloon && (
          <Form
            characterForm={characterForm}
            speechBalloon={speechBalloon}
            gender={character.option.gender}
            onSelectNameLabelColor={this.handleSelectNameLabelColor}
            onSubmit={this.handleSubmit}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleSelectNameLabelColor = (nameLabelColor: NameLabelColor) => {
    const {updateCharacterForm} = this.props;
    updateCharacterForm({nameLabelColor});
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };

  private handleSubmit = () => {
    const {navigation, character, updateCharacter, characterForm, route} =
      this.props;
    const {projectId} = route.params;
    if (!character || !characterForm) {
      return;
    }
    this.setState({loading: true}, () => {
      const {published, ...params} = characterForm.toParams();
      updateCharacter(character.id, params)
        .then(() => {
          this.setState({loading: false}, () => {
            if (projectId) {
              navigation.navigate('ProjectCharacters', {projectId});
            } else {
              navigation.goBack();
              navigation.goBack();
            }
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };
}
