import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import BaseSceneCommandModal from './BaseSceneCommandModal';

import CharacterViewWithEditor from './visualizations/CharacterViewWithEditor';
import CharacterViewWithEditorForCharacterMaker from './visualizations/CharacterViewWithEditorForCharacterMaker';

import DeleteButton from './buttons/DeleteButton';
import CharacterEnterButton from './buttons/CharacterEnterButton';
import CharacterExitButton from './buttons/CharacterExitButton';
import ChangeButton from './buttons/ChangeButton';

import CharacterPatternMapping from '../../../../../view_models/CharacterPatternMapping';

import {Params as ActorCharacterFaceIndexParams} from '../../../../../actions/actor_character_faces/index';
import {Params as CharacterPatternCreateParams} from '../../../../../actions/character_patterns/create';
import {Params as ActorCostumeIndexParams} from '../../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../../actions/actor_accessory_sets/index';

import {QueryState} from '../../../../../reducers/queries/Response';

import {CharacterSceneCommandForm} from '../../../../../view_models/SceneFrame';
import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import ActorCharacterFace from '../../../../../../domain/entities/ActorCharacterFace';
import CharacterPattern from '../../../../../../domain/entities/CharacterPattern';
import ActorCostume from '../../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../../domain/entities/ActorHairStyle';
import ActorAccessorySet from '../../../../../../domain/entities/ActorAccessorySet';
import Mark from '../../../../../../domain/entities/Mark';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import CharacterShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import CharacterHideSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import Position from '../../../../../../domain/value_objects/Position';

interface Props {
  sceneCommandForm: CharacterSceneCommandForm;
  sceneCommandIndex: number;
  waitable: boolean;
  actorCharacterFaceEntities: {[key: number]: ActorCharacterFace};
  actorCharacterFaceQueries: QueryState;
  marks: Mark[] | null;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestCloseModal: () => void;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
  onChangeCharacterShowOrUpdateCommand: (
    sceneCommandForm: CharacterSceneCommandForm,
    characterPatternMapping?: CharacterPatternMapping,
  ) => void;
  onInsertCharacterHideCommandAt: (
    sceneCommandForm: CharacterSceneCommandForm,
    sceneCommandIndex: number,
  ) => void;
  onRemoveCharacterShowOrUpdateCommand: (
    sceneCommandForm: CharacterSceneCommandForm,
  ) => void;
  onRequestIndexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  onRequestIndexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  onRequestIndexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  onRequestIndexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  onRequestIndexMarks: () => Promise<PaginatedResult<Mark>>;
  onRequestCreateCharacterPattern: (
    params: CharacterPatternCreateParams,
  ) => Promise<CharacterPattern>;
  onChangeCommand: (
    sceneCommandForm: SceneCommandForm,
    characterPatternMapping?: CharacterPatternMapping,
  ) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
}

interface State {
  loading?: boolean;
}

export default class CharacterShowOrUpdateSceneCommandModal extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactNode {
    const {
      sceneCommandForm,
      waitable,
      actorCharacterFaceEntities,
      actorCharacterFaceQueries,
      marks,
      onRequestCloseModal,
      onRequestIndexActorCharacterFaces,
      onRequestIndexActorCostumes,
      onRequestIndexActorHairStyles,
      onRequestIndexActorAccessorySets,
      onRequestIndexMarks,
    } = this.props;
    const {loading} = this.state;
    return (
      <BaseSceneCommandModal
        title={'キャラクターの編集'}
        onRequestCloseModal={onRequestCloseModal}
        loading={loading}
        footer={
          <View style={styles.footer}>
            {sceneCommandForm instanceof CharacterShowSceneCommandForm &&
              waitable && (
                <CharacterEnterButton
                  waiting={!sceneCommandForm.waiting}
                  onPress={this.changeWaiting}
                />
              )}
            <CharacterExitButton onPress={this.addCharacterHideSceneCommand} />
            <DeleteButton onPress={this.destroySceneCommandForm} />
          </View>
        }>
        <View style={styles.container}>
          {sceneCommandForm.characterPattern.character.characterMakerOnly ? (
            <CharacterViewWithEditorForCharacterMaker
              sceneCommandForm={sceneCommandForm}
              actorCharacterFaceEntities={actorCharacterFaceEntities}
              actorCharacterFaceQueries={actorCharacterFaceQueries}
              marks={marks}
              inverted={sceneCommandForm.characterPattern.character.inverted}
              onSubmit={this.handleSubmit}
              onRequestIndexActorCharacterFaces={
                onRequestIndexActorCharacterFaces
              }
              onRequestIndexMarks={onRequestIndexMarks}
              onRequestLoading={this.handleRequestLoading}
            />
          ) : (
            <CharacterViewWithEditor
              sceneCommandForm={sceneCommandForm}
              actorCharacterFaceEntities={actorCharacterFaceEntities}
              actorCharacterFaceQueries={actorCharacterFaceQueries}
              marks={marks}
              inverted={sceneCommandForm.characterPattern.character.inverted}
              onSubmit={this.handleSubmit}
              onRequestIndexActorCharacterFaces={
                onRequestIndexActorCharacterFaces
              }
              onRequestIndexActorCostumes={onRequestIndexActorCostumes}
              onRequestIndexActorHairStyles={onRequestIndexActorHairStyles}
              onRequestIndexActorAccessorySets={
                onRequestIndexActorAccessorySets
              }
              onRequestIndexMarks={onRequestIndexMarks}
            />
          )}
          <View style={styles.characterChangeButton}>
            <ChangeButton
              title={'キャラ変更'}
              onPress={this.handleForwardToCharacters}
            />
          </View>
        </View>
      </BaseSceneCommandModal>
    );
  }

  private handleForwardToCharacters = () => {
    const {
      sceneCommandForm,
      sceneCommandIndex,
      parentSceneCommandForm,
      onForwardToCharacters,
      onRequestCloseModal,
    } = this.props;
    const parentSceneCommandId = parentSceneCommandForm?.sceneCommandId;
    onForwardToCharacters({
      sceneCommandIndex,
      parentSceneCommandId,
      position: sceneCommandForm.position,
    });
    onRequestCloseModal();
  };

  private changeWaiting = async () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onChangeCharacterShowOrUpdateCommand,
      onChangeCommand,
      onRequestCreateCharacterPattern,
    } = this.props;
    const {actorCharacter} = sceneCommandForm.characterPattern;
    const characterPattern =
      sceneCommandForm.characterPattern.actorCharacterId ===
      sceneCommandForm.characterPattern.actorCharacter.id
        ? sceneCommandForm.characterPattern
        : await onRequestCreateCharacterPattern({
            characterId: sceneCommandForm.characterPattern.character.id,
            actorCharacterId: actorCharacter.id,
          });
    let characterPatternMapping: CharacterPatternMapping | undefined =
      undefined;
    if (sceneCommandForm.characterPattern.id !== characterPattern.id) {
      characterPatternMapping = new CharacterPatternMapping();
      await characterPatternMapping.register(
        sceneCommandForm.characterPattern,
        characterPattern,
        sceneCommandForm.position,
      );
    }
    if (sceneCommandForm instanceof CharacterShowSceneCommandForm) {
      const waiting = !sceneCommandForm.waiting;
      const newSceneCommandForm = new CharacterShowSceneCommandForm(
        characterPattern,
        sceneCommandForm.actorCharacterFace,
        sceneCommandForm.mark,
        sceneCommandForm.position,
        waiting,
        sceneCommandForm.sceneCommandId,
      );
      if (
        parentSceneCommandForm &&
        parentSceneCommandForm !== sceneCommandForm
      ) {
        this.updateWaiting(waiting, parentSceneCommandForm);
        const newParentSceneCommandForm =
          CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            newSceneCommandForm,
          );
        onChangeCommand(newParentSceneCommandForm, characterPatternMapping);
      } else {
        onChangeCharacterShowOrUpdateCommand(
          newSceneCommandForm,
          characterPatternMapping,
        );
      }
    }
    onRequestCloseModal();
  };

  private updateWaiting = (
    value: boolean,
    sceneCommandForm: SceneCommandForm,
  ) => {
    if (sceneCommandForm instanceof CharacterShowSceneCommandForm) {
      sceneCommandForm.waiting = value;
    } else if (
      sceneCommandForm instanceof CompositeParallelSceneCommandForm ||
      sceneCommandForm instanceof CompositeSequenceSceneCommandForm
    ) {
      sceneCommandForm.commandForms.forEach(commandForm =>
        this.updateWaiting(value, commandForm),
      );
    }
  };

  private addCharacterHideSceneCommand = () => {
    const {
      sceneCommandForm,
      sceneCommandIndex,
      parentSceneCommandForm,
      onRequestCloseModal,
      onInsertCharacterHideCommandAt,
      onChangeCommand,
    } = this.props;
    if (parentSceneCommandForm) {
      if (sceneCommandForm instanceof CharacterShowSceneCommandForm) {
        onInsertCharacterHideCommandAt(sceneCommandForm, sceneCommandIndex);
      } else {
        const newParentSceneCommandForm =
          CompositeSequenceSceneCommandFormFactory.add(
            parentSceneCommandForm,
            new CharacterHideSceneCommandForm(
              sceneCommandForm.characterPattern,
              sceneCommandForm.actorCharacterFace,
              sceneCommandForm.mark,
              sceneCommandForm.position,
            ),
          );
        onChangeCommand(newParentSceneCommandForm);
      }
    } else {
      onInsertCharacterHideCommandAt(sceneCommandForm, sceneCommandIndex);
    }
    onRequestCloseModal();
  };

  private destroySceneCommandForm = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onRemoveCharacterShowOrUpdateCommand,
      onChangeCommand,
      onRemoveCommand,
    } = this.props;
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.remove(
          parentSceneCommandForm,
          sceneCommandForm,
        );
      if (newParentSceneCommandForm) {
        onChangeCommand(newParentSceneCommandForm);
      } else {
        onRemoveCommand(parentSceneCommandForm);
      }
    } else {
      onRemoveCharacterShowOrUpdateCommand(sceneCommandForm);
    }
    onRequestCloseModal();
  };

  private handleSubmit = async (
    selectedActorCharacterFace: ActorCharacterFace,
    selectedMark: Mark | null,
  ) => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onChangeCharacterShowOrUpdateCommand,
      onChangeCommand,
      onRequestCreateCharacterPattern,
    } = this.props;
    const characterPattern =
      sceneCommandForm.characterPattern.actorCharacterId ===
      selectedActorCharacterFace.actorCharacterId
        ? sceneCommandForm.characterPattern
        : await onRequestCreateCharacterPattern({
            characterId: sceneCommandForm.characterPattern.character.id,
            actorCharacterId: selectedActorCharacterFace.actorCharacterId,
          });
    let characterPatternMapping: CharacterPatternMapping | undefined =
      undefined;
    if (sceneCommandForm.characterPattern.id !== characterPattern.id) {
      characterPatternMapping = new CharacterPatternMapping();
      await characterPatternMapping.register(
        sceneCommandForm.characterPattern,
        characterPattern,
        sceneCommandForm.position,
      );
    }
    if (sceneCommandForm instanceof CharacterShowSceneCommandForm) {
      const newSceneCommandForm = new CharacterShowSceneCommandForm(
        characterPattern,
        selectedActorCharacterFace,
        selectedMark,
        sceneCommandForm.position,
        sceneCommandForm.waiting,
        sceneCommandForm.sceneCommandId,
      );
      if (parentSceneCommandForm) {
        const newParentSceneCommandForm =
          CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            newSceneCommandForm,
          );
        onChangeCommand(newParentSceneCommandForm, characterPatternMapping);
      } else {
        onChangeCharacterShowOrUpdateCommand(
          newSceneCommandForm,
          characterPatternMapping,
        );
      }
    } else if (sceneCommandForm instanceof CharacterUpdateSceneCommandForm) {
      const newSceneCommandForm = new CharacterUpdateSceneCommandForm(
        characterPattern,
        selectedActorCharacterFace,
        selectedMark,
        sceneCommandForm.position,
        sceneCommandForm.sceneCommandId,
      );
      if (parentSceneCommandForm) {
        const newParentSceneCommandForm =
          CompositeSequenceSceneCommandFormFactory.update(
            parentSceneCommandForm,
            newSceneCommandForm,
          );
        onChangeCommand(newParentSceneCommandForm, characterPatternMapping);
      } else {
        onChangeCharacterShowOrUpdateCommand(
          newSceneCommandForm,
          characterPatternMapping,
        );
      }
    }
    onRequestCloseModal();
  };

  private handleRequestLoading = (loading: boolean) => {
    this.setState({loading});
  };
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 368,
  } as ViewStyle,
  characterChangeButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  } as ViewStyle,
  footer: {
    padding: 16,
    width: '100%',
    justifyContent: 'center',
    marginHorizontal: 16,
    flexDirection: 'row',
  } as ViewStyle,
});
