import {AnyAction} from 'redux';
import Types from '../actions/Types';

interface Token {
  value: string | null;
}

export default function reducer(
  state: Token | null = null,
  action: AnyAction,
): Token | null {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {value: null};
    case Types.CreateWriterCurrentUserSuccess:
      if (action.payload.accessToken) {
        return {value: action.payload.accessToken};
      } else {
        return state;
      }
    case Types.ShowAccessTokenSuccess:
      return {value: action.payload};
    default:
      return state;
  }
}
