import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import CharacterFormNew from '../../../../containers/character_form/new/home/Index';
import CharacterFormNewActorSearchForm from '../../../../containers/character_form/new/actor_search_form/Index';
import CharacterFormNewActors from '../../../../containers/character_form/new/actors/Index';
import CharacterFormNewDefaultCharacterPatterns from '../../../../containers/character_form/new/default_character_patterns/Index';
import CharacterFormNewNameLabelColors from '../../../../containers/character_form/new/name_label_colors/Index';
import CharacterFormNewCharacterMaker from '../../../../containers/character_form/new/character_maker/home/Index';
import CharacterFormNewCharacterMakerActors from '../../../../containers/character_form/new/character_maker/actors/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="CharacterFormNew">
      <RootStack.Screen
        name="CharacterFormNew"
        component={CharacterFormNew}
        options={{title: 'キャラクター'}}
      />

      <RootStack.Screen
        name="CharacterFormNewActorSearchForm"
        component={CharacterFormNewActorSearchForm}
        options={{title: 'キャラクター検索条件'}}
      />

      <RootStack.Screen
        name="CharacterFormNewActors"
        component={CharacterFormNewActors}
        options={{title: 'キャラクター'}}
      />

      <RootStack.Screen
        name="CharacterFormNewDefaultCharacterPatterns"
        component={CharacterFormNewDefaultCharacterPatterns}
        options={{title: '衣装'}}
      />
      <RootStack.Screen
        name="CharacterFormNewNameLabelColors"
        component={CharacterFormNewNameLabelColors}
        options={{title: 'キャラクター情報編集'}}
      />
      <RootStack.Screen
        name="CharacterFormNewCharacterMaker"
        component={CharacterFormNewCharacterMaker}
        options={{title: '素体'}}
      />
      <RootStack.Screen
        name="CharacterFormNewCharacterMakerActors"
        component={CharacterFormNewCharacterMakerActors}
        options={{title: 'キャラクター'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
