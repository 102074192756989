import TapNovelRestApi from '../../../data_stores/net/TapNovelRestApi';

import PasswordRepository from '../../../../domain/repositories/writer/users/PasswordRepository';

export default class NetPasswordRepository implements PasswordRepository {
  private endPoint = '/api/writer/users/password';

  public find(): Promise<Record<string, any>> {
    throw new Error('Not implementation error');
  }

  public create(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<Record<string, any>> {
    const path = this.endPoint;
    return TapNovelRestApi.post<Record<string, any>>(path, params, options);
  }

  public update(
    params: {[key: string]: any},
    options?: {[key: string]: any},
  ): Promise<Record<string, any>> {
    const path = `${this.endPoint}`;
    return TapNovelRestApi.patch<Record<string, any>>(path, params, options);
  }

  public destroy(): Promise<void> {
    throw new Error('Not implementation error');
  }
}
