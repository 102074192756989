import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../components/settings/notifications/Index';

import AppState from '../../../reducers/AppState';

import showWriterUserNotificationSettingsBatch from '../../../actions/writer/user_notification_settings_batch/show';
import updateWriterUserNotificationSettingsBatch, {
  Params as WriterUserNotificationSettingsBatchUpdateParams,
} from '../../../actions/writer/user_notification_settings_batch/update';

import selectEntity from '../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, writerCurrentUserId} = state;
  const writerCurrentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const writerUserNotificationSettingsBatch = writerCurrentUser
    ? selectEntity(
        entities.writer.userNotificationSettingsBatches,
        writerCurrentUser.writerUserId,
      )
    : null;
  return {
    navigation,
    writerUserNotificationSettingsBatch,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showWriterUserNotificationSettingsBatch: () =>
      dispatch(showWriterUserNotificationSettingsBatch()),
    updateWriterUserNotificationSettingsBatch: (
      params: WriterUserNotificationSettingsBatchUpdateParams,
    ) => dispatch(updateWriterUserNotificationSettingsBatch(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
