import * as React from 'react';
import {InteractionManager} from 'react-native';

import Layout from '../../../../shared/Layout';
import SearchResult from '../../../../shared/scene_form/SearchResult';
import ResourceFavoriteButton from '../../../../shared/ResourceFavoriteButton';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormEffectShowSceneCommandNewBaseEffectsRouteProp,
  SceneFormEffectShowSceneCommandEditBaseEffectsRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as BaseEffectIndexParams} from '../../../../../actions/base_effects/index';

import {
  baseEffectImageUrl,
  backgroundImageUrl,
} from '../../../../../helpers/images';
import {getNextPage} from '../../../../../helpers/selectEntities';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import {QueryState} from '../../../../../reducers/queries/Response';

import Background from '../../../../../../domain/entities/Background';
import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import EffectCategory from '../../../../../../domain/entities/EffectCategory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import fetchBlob from '../../../../../../data/data_stores/net/FetchBlob';

export interface Params {
  episodeId?: number;
  sceneId?: number;
  effectCategoryId: number;
  selectedLargeEffectCategoryId?: number | null;
  selectedMiddleEffectCategoryId?: number | null;
  selectedSmallEffectCategoryId?: number | null;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormEffectShowSceneCommandNewBaseEffectsRouteProp
    | SceneFormEffectShowSceneCommandEditBaseEffectsRouteProp;
  sceneForm: SceneForm | null;
  baseEffectsParams: BaseEffectIndexParams;
  baseEffects: BaseEffect[] | null;
  effectCategory: EffectCategory | null;
  baseEffectQueries: QueryState;
  totalCount: number | null;
  sort?: 'popularity' | 'new_arrival';
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexBaseEffects: (
    params: BaseEffectIndexParams,
  ) => Promise<PaginatedResult<BaseEffect>>;
  showEffectCategory: (id: number) => Promise<EffectCategory>;
  onSelectBaseEffect: (baseEffect: BaseEffect) => void;
  onPressSearchConditionChangeButton: () => void;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    this.fetchEntities();
    if (this.props.sceneForm && this.props.sceneForm.background) {
      const {background} = this.props.sceneForm;
      InteractionManager.runAfterInteractions(() => {
        this.fetchBlob(background).then(uri => {
          this.setState({uri});
        });
      });
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    const {route} = this.props;
    const {route: prevRoute} = prevProps;
    const {query, effectCategoryId, sort} = route.params;
    const {
      query: prevQuery,
      effectCategoryId: prevEffectCategoryId,
      sort: prevSort,
    } = prevRoute.params;
    if (
      query === prevQuery &&
      effectCategoryId === prevEffectCategoryId &&
      sort === prevSort
    ) {
      return;
    }
    this.fetchEntities();
  }

  public render(): React.ReactNode {
    const {
      navigation,
      baseEffects,
      sort,
      totalCount,
      route,
      onSelectBaseEffect,
      onPressSearchConditionChangeButton,
    } = this.props;
    const {favorite} = route.params;
    return (
      <Layout
        title={`${favorite ? 'お気に入り' : ''}エフェクト`}
        navigation={navigation}
        back={true}
        close={true}
        scrollable={false}>
        <SearchResult
          entities={baseEffects}
          totalCount={totalCount}
          sort={sort}
          title={this.getTitle()}
          aspectRatio={aspectRatio}
          favorite={favorite}
          resourceName={'エフェクト'}
          containerStyle={{marginBottom: 8}}
          imageMaskStyle={{backgroundColor: '#666'}}
          onPressSearchConditionChangeButton={
            onPressSearchConditionChangeButton
          }
          onSelectEntity={onSelectBaseEffect}
          onPressSort={this.handlePressSort}
          onEndReached={this.handleEndReached}
          imageUrlExtractor={this.imageUrlExtractor}
          labelExtractor={this.labelExtractor}
          modalRenderFavoriteButton={this.modalRenderFavoriteButton}
        />
      </Layout>
    );
  }

  private fetchEntities = () => {
    const {
      route,
      baseEffectsParams,
      effectCategory,
      baseEffects,
      showEffectCategory,
      indexBaseEffects,
    } = this.props;
    const {effectCategoryId, favorite} = route.params;
    if (!effectCategory && effectCategoryId) {
      showEffectCategory(effectCategoryId);
    }
    if (!baseEffects || favorite) {
      indexBaseEffects(baseEffectsParams);
    }
  };

  private imageUrlExtractor = (baseEffect: BaseEffect, width: number) => {
    return baseEffectImageUrl(baseEffect, 'middle');
  };

  private labelExtractor = (baseEffect: BaseEffect) => {
    return baseEffect.name;
  };

  private modalRenderFavoriteButton = (baseEffect: BaseEffect) => {
    const {enabledFavorite} = this.props;
    if (!enabledFavorite) {
      return null;
    }
    return (
      <ResourceFavoriteButton
        style={{top: 55, right: 10}}
        resourceType={'BaseEffect'}
        resourceId={baseEffect.id}
      />
    );
  };

  private getTitle = () => {
    const ary: Array<string> = [];
    const {route, effectCategory} = this.props;
    const {query, favorite} = route.params;
    if (favorite) {
      ary.push('お気に入り');
    }
    if (query) {
      ary.push(query);
    }
    if (effectCategory) {
      ary.push(effectCategory.name);
    }
    return ary.join(' / ');
  };

  private handleEndReached = (info: {distanceFromEnd: number}): void => {
    const {baseEffectQueries, baseEffectsParams, indexBaseEffects} = this.props;
    const nextPage = getNextPage(baseEffectQueries, baseEffectsParams);
    if (nextPage) {
      indexBaseEffects({...baseEffectsParams, page: nextPage});
    }
  };

  private handlePressSort = (sort: 'popularity' | 'new_arrival') => {
    const {navigation} = this.props;
    navigation.setParams({sort});
  };

  private async fetchBlob(background: Background): Promise<string> {
    const url = backgroundImageUrl(background, 'large');
    return fetchBlob(url);
  }
}

const aspectRatio = 112 / 90;
