import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';
import {useActionSheet} from '@expo/react-native-action-sheet';

import EpisodeVideoExportJobExecutionListItem from './EpisodeVideoExportJobExecutionListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import EpisodeVideoExportJobExecution from '../../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

const options = ['削除', 'キャンセル'];

interface Props {
  episodeVideoExportJobExecutions: Array<EpisodeVideoExportJobExecution>;
  onSelectEpisodeVideoExportJobExecution: (
    episodeVideoExportJobExecution: EpisodeVideoExportJobExecution,
  ) => void;
  onDeleteEpisodeVideoExportJobExecution: (
    episodeVideoExportJobExecution: EpisodeVideoExportJobExecution,
  ) => void;
}

const EpisodeVideoExportJobExecutionList: React.FunctionComponent<
  Props
> = props => {
  const {
    episodeVideoExportJobExecutions,
    onSelectEpisodeVideoExportJobExecution,
  } = props;
  const {showActionSheetWithOptions} = useActionSheet();
  const keyExtractor = React.useCallback(
    (episodeVideoExportJobExecution: EpisodeVideoExportJobExecution) =>
      `${episodeVideoExportJobExecution.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<EpisodeVideoExportJobExecution>) => (
      <EpisodeVideoExportJobExecutionListItem
        episodeVideoExportJobExecution={info.item}
        onSelectEpisodeVideoExportJobExecution={
          onSelectEpisodeVideoExportJobExecution
        }
        onOpenActionSheet={onOpenActionSheet}
      />
    ),
    [],
  );
  const onOpenActionSheet = React.useCallback(
    (episodeVideoExportJobExecution: EpisodeVideoExportJobExecution) => {
      const destructiveButtonIndex = 0;
      const cancelButtonIndex = 1;
      showActionSheetWithOptions(
        {
          cancelButtonIndex,
          destructiveButtonIndex,
          options,
        },
        (buttonIndex?: number) => {
          switch (buttonIndex) {
            case destructiveButtonIndex:
              props.onDeleteEpisodeVideoExportJobExecution(
                episodeVideoExportJobExecution,
              );
              break;
            case cancelButtonIndex:
              break;
          }
        },
      );
    },
    [],
  );
  return (
    <FlatList
      data={episodeVideoExportJobExecutions}
      style={{backgroundColor: '#fafafa', paddingBottom: 100}}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default EpisodeVideoExportJobExecutionList;
