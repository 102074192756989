import * as React from 'react';
import {useLinkTo, useNavigation} from '@react-navigation/native';
import {
  ImageStyle,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../../../shared/buttons/PrimaryButton';
import Select from '../../../../../shared/forms/Select';
import LabelWithOption from '../../../../../shared/forms/LabelWithOption';
import TooltipModal from '../../../../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../../../../shared/KeyboardSpacer';
import AlertModal from '../../../../../shared/modals/AlertModal';

import {enabledProjectManuscriptEpisode} from '../../../../../../helpers/features';

import {colors, formStyles} from '../../../../../../styles/variables';

import ProjectManuscript from '../../../../../../../domain/entities/ProjectManuscript';

import TapNovelTypedRestApi from '../../../../../../../data/data_stores/net/TapNovelTypedRestApi';

type TypeCode = 'idea' | 'plot' | 'episode';

export type Form1Values = {
  projectEpisodeId?: number;
  typeCode: TypeCode;
  manuscriptNumber: number;
  projectManuscript?: ProjectManuscript | null;
};

type Form1ErrorMessage = {[P in keyof Form1Values]?: string | string[]};

const TypeToTooltipInfo = {
  typeCode: {
    title: 'フェーズ',
    description:
      'アイデア、プロット、エピソードから選択します。\nフェーズごとの運用については、プロジェクトのルールに沿って選択ください。',
  },
} as {
  [key in keyof Form1Values]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

interface Props {
  values?: Partial<Form1Values>;
  errorMessages?: Form1ErrorMessage;
  onSubmit: (values: Form1Values) => void;
}

const FormStep1: React.FC<Props> = props => {
  const {values, errorMessages, onSubmit} = props;
  const linkTo = useLinkTo();
  const navigation = useNavigation();
  const [tooltipModalType, setTooltipModalType] = React.useState<
    keyof Form1Values | null
  >(null);
  const projectEpisodeId = values?.projectEpisodeId;
  const [typeCode, setTypeCode] = React.useState<TypeCode | null>(null);
  const [manuscriptNumber, setManuscriptNumber] = React.useState<number>(1);
  const [loading, setLoading] = React.useState<boolean>(false);
  const onValueChange = React.useCallback(
    (value: string | number) => {
      if (value) {
        setTypeCode(`${value}` as TypeCode);
      } else {
        setTypeCode(null);
      }
    },
    [typeCode],
  );
  const [projectManuscript, setProjectManuscript] =
    React.useState<ProjectManuscript | null>(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onPressQuestionManuscriptPhase = React.useCallback(() => {
    setTooltipModalType('typeCode');
  }, []);
  const onCloseModalTooltip = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onPress = () => {
    if (projectManuscript?.status === 'draft' && projectManuscript.episodeId) {
      navigation.goBack();
      return linkTo(`/episodes/${projectManuscript.episodeId}`);
    }
    if (typeCode && manuscriptNumber) {
      onSubmit({
        projectEpisodeId,
        typeCode,
        manuscriptNumber,
        projectManuscript,
      });
    }
  };
  React.useEffect(() => {
    if (!typeCode) {
      return;
    }
    setLoading(true);
    setProjectManuscript(null);
    TapNovelTypedRestApi.get<ProjectManuscript>(
      '/api/writer/user/latest_project_manuscript',
      {
        typeCode,
        projectEpisodeId,
      },
    )
      .then(result => {
        const projectManuscript = result.body;
        setProjectManuscript(projectManuscript);
        if (
          projectManuscript?.status === 'draft' &&
          projectManuscript.episodeId
        ) {
          setManuscriptNumber(result.body.manuscriptNumber);
        } else {
          setManuscriptNumber(result.body.manuscriptNumber + 1);
        }
        setTimeout(() => {
          setLoading(false);
        }, 500);
      })
      .catch(() => {
        setTimeout(() => {
          setLoading(false);
        }, 500);
        setManuscriptNumber(1);
      });
  }, [typeCode]);
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{paddingBottom: 130}}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'フェーズ'}
            onPressQuestion={onPressQuestionManuscriptPhase}
          />
          <Select
            items={TYPE_CODE_ITEMS}
            value={`${typeCode}`}
            onValueChange={onValueChange}
            rightIcon={<View style={styles.arrowIcon} />}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption title={'稿数'} />
          <View
            style={{
              borderBottomColor: '#efefef',
              borderBottomWidth: 1,
              paddingVertical: 8,
            }}>
            <Text style={{color: '#999', fontSize: 14}}>
              {manuscriptNumber == 1 ? '初' : manuscriptNumber}稿
            </Text>
          </View>
        </View>
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            imageStyle={modalInfo.imageStyle}
            source={modalInfo.source}
            onCloseModal={onCloseModalTooltip}></TooltipModal>
        ) : null}
        <KeyboardSpacer />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <View style={styles.button}>
          <PrimaryButton disabled={!typeCode || loading} onPress={onPress}>
            次へ
          </PrimaryButton>
        </View>
      </View>
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
    </View>
  );
};

export default React.memo(FormStep1);

const TYPE_CODE_ITEMS = [
  {label: 'フェーズを選択', value: ''},
  {label: 'アイデア', value: 'idea'},
  {label: 'プロット', value: 'plot'},
];
if (enabledProjectManuscriptEpisode) {
  TYPE_CODE_ITEMS.push({label: 'エピソード', value: 'episode'});
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  } as ViewStyle,
});
