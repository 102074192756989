import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EpisodeCommentBlockingUserMapper, {
  EpisodeCommentBlockingUserData,
} from '../../entities/mappers/EpisodeCommentBlockingUserMapper';

import EpisodeCommentBlockingUser from '../../../domain/entities/EpisodeCommentBlockingUser';
import EpisodeCommentBlockingUsersRepository from '../../../domain/repositories/writer/EpisodeCommentBlockingUsersRepository';

export default class NetEpisodeCommentBlockingUsersRepository
  extends NetResourcesRepository<
    EpisodeCommentBlockingUser,
    EpisodeCommentBlockingUserData
  >
  implements EpisodeCommentBlockingUsersRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/episode_comment_blocking_users',
        new EpisodeCommentBlockingUserMapper(),
      ),
    );
  }
}
