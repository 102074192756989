import * as React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

export interface Props {
  tintColor: string;
  title?: React.ReactElement<any>;
  handler?: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
  renderButtonWrapper?: (
    button: React.ReactElement<any>,
  ) => React.ReactElement<any>;
}

const NavigationBarButton: React.FunctionComponent<Props> = props => {
  const {tintColor, title, handler, disabled, children, renderButtonWrapper} =
    props;
  const content = children ? (
    children
  ) : (
    <View>
      <Text style={[styles.navBarButtonText, {color: tintColor}]}>{title}</Text>
    </View>
  );
  return disabled || !handler ? (
    <View style={styles.navBarButton}>{content}</View>
  ) : renderButtonWrapper ? (
    renderButtonWrapper(
      <TouchableOpacity style={styles.navBarButton} onPress={handler}>
        {content}
      </TouchableOpacity>,
    )
  ) : (
    <TouchableOpacity style={styles.navBarButton} onPress={handler}>
      {content}
    </TouchableOpacity>
  );
};

export default React.memo(NavigationBarButton);

const styles = StyleSheet.create({
  navBarButton: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: '#fff',
    height: 42,
    margin: 1,
    borderRadius: 4,
  } as ViewStyle,
  navBarButtonText: {
    fontSize: 17,
    letterSpacing: 0.5,
  } as ViewStyle,
});
