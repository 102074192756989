import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import {sendDefaultCustomEvent, setUser} from '../../../helpers/analytics';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

import AccessTokenRepository from '../../../../data/repositories/StorageAccessTokenRepository';

import NetCurrentUserRepository from '../../../../data/repositories/writer/NetCurrentUserRepository';

export interface Params {
  accessToken?: string;
}

export default function action(
  params?: Params,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<CurrentUser> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<CurrentUser> => {
    const {accessToken: oldAccessToken} = getState();
    if (params && params.accessToken) {
      await new AccessTokenRepository().update(params.accessToken);
    }
    const accessTokenRepository = new AccessTokenRepository();
    const accessToken = await accessTokenRepository.find();
    try {
      if (!accessToken) {
        throw new Error('Not found access token');
      }
      const payload = await new NetCurrentUserRepository().find();
      if (oldAccessToken?.value !== accessToken) {
        dispatch({payload: accessToken, type: Types.ShowAccessTokenSuccess});
      }
      dispatch({payload, type: Types.ShowWriterCurrentUserSuccess});
      setUser(payload);
      if (params && params.accessToken) {
        sendDefaultCustomEvent({
          resourceType: 'writer/user_session',
          resourceId: payload.id,
          resourceName: payload.penName,
          actionName: 'create',
        });
      }
      return payload;
    } catch (err: any) {
      dispatch({type: Types.ShowWriterCurrentUserFailure});
      if (err.status === 401) {
        accessTokenRepository.destroy();
      }
      throw err;
    }
  };
}
