import {TextStyle, ViewStyle} from 'react-native';

import Entity from './Entity';

import TextFramePart from './TextFramePart';

import FramePartPosition from '../value_objects/FramePartPosition';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class TextFrame implements Entity {
  constructor(
    public id: number,
    public originalImageUrl: string,
    public caption: boolean,
    public textFrameParts: TextFramePart[],
    public containerStyle: ViewStyle,
    public textStyle: TextStyle,
    public voiceIconStyle?: ViewStyle,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getPartImageUrlOf(partPosition: FramePartPosition): TextFramePart {
    const found = this.textFrameParts.find(textFramePart => {
      return textFramePart.position === partPosition;
    });
    if (found) {
      return found;
    } else {
      throw Error(`Not found part of ${partPosition} for ${this.id}`);
    }
  }
}
