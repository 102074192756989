import Entity from './Entity';

import User from './consumer/User';
import EpisodeTip from './EpisodeTip';
import EpisodeReaction from './EpisodeReaction';
import EpisodeCommentReply from './EpisodeCommentReply';

export default class EpisodeComment implements Entity {
  constructor(
    public id: number,
    public episodeId: number,
    public comment: string,
    public spoiled: boolean,
    public likesCount: number,
    public displayOnStoryPage: boolean,
    public createdAt: Date,
    public updatedAt: Date,
    public user: User,
    public episodeTip?: EpisodeTip | null,
    public episodeReaction?: EpisodeReaction | null,
    public episodeCommentReply?: EpisodeCommentReply | null,
  ) {}
}
