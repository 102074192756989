import {Dispatch} from 'redux';

import Types from '../Types';

import BackgroundUsageHistory from '../../../domain/entities/BackgroundUsageHistory';

import NetBackgroundUsageHistoriesRepository from '../../../data/repositories/writer/NetBackgroundUsageHistoriesRepository';

export interface Params {
  storyId: number;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<BackgroundUsageHistory> {
  return async (dispatch: Dispatch<any>): Promise<BackgroundUsageHistory> => {
    try {
      const payload = await new NetBackgroundUsageHistoriesRepository().update(
        id,
        params,
      );
      dispatch({
        payload,
        type: Types.UpdateBackgroundUsageHistorySuccess,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateBackgroundUsageHistoryFailure});
      throw err;
    }
  };
}
