import * as React from 'react';

import ShareContent from '../../../shared/share/ShareContent';

import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';
import {EpisodeFormEditEpisodePublicationCompletionRouteProp} from '../../../../navigators/RouteProps';

import Story from '../../../../../domain/entities/Story';
import Episode from '../../../../../domain/entities/Episode';

export interface Params {
  episodeId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EpisodeFormEditEpisodePublicationCompletionRouteProp;
  story: Story | null;
  episode: Episode | null;
}

export interface DispatchProps {
  showStory: (id: number) => Promise<Story>;
  showEpisode: (id: number) => Promise<Episode>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {route, showEpisode} = this.props;
    const {episodeId} = route.params;
    showEpisode(episodeId).then(this.handleFetchEpisode);
  }

  public render(): React.ReactNode {
    const {navigation, story, episode} = this.props;
    return (
      <Layout
        title={`エピソードの公開設定`}
        close={true}
        navigation={navigation}
        scrollable={true}>
        {story && episode && (
          <ShareContent
            message={this.buildMessage(story, episode)}
            url={`${story.url}?ogp_type=episode_publication&ogp_episode_id=${episode.id}`}
            imageUrl={
              story.violated
                ? undefined
                : episode.publishedDelivery()
                ? story.ogpImageEpisodePublicationUrl
                : story.ogpImageEpisodeReservedPublicationUrl
            }
            title={story.title}
            text={this.buildText(story, episode)}
            disableShare={story.violated}
          />
        )}
      </Layout>
    );
  }

  private buildMessage = (story: Story, episode: Episode) => {
    if (story.violated) {
      return `${story.title}の公開申請しました。`;
    }
    return `${story.title}の${episode.title}を${
      episode.publishedDelivery() ? '' : '予約'
    }公開しました。`;
  };

  private buildText = (story: Story, episode: Episode) => {
    return `${episode.title}を${
      episode.publishedDelivery() ? '' : '予約'
    }公開しました。${story.title}`;
  };

  private handleFetchEpisode = (episode: Episode) => {
    const {showStory} = this.props;
    showStory(episode.storyId);
  };
}
