import * as React from 'react';

import MemberImage from '../members/partials/MemberImage';

import ProjectMember from '../../../../domain/entities/ProjectMember';

interface Props {
  member: ProjectMember;
  imageSize?: number;
}

const MemberListItem: React.FC<Props> = props => {
  const {member, imageSize} = props;
  return <MemberImage member={member} imageSize={imageSize} />;
};

export default React.memo(MemberListItem);
