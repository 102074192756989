import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import styled from 'styled-components';

import loadStripeScript from '../../../helpers/loadStripeScript';
import {setupForm} from '../../../helpers/stripe';

import PaymentMethod from '../../../../domain/entities/writer/my_stripe/PaymentMethod';
import PaymentIntent from '../../../../domain/entities/writer/my_stripe/PaymentIntent';

import NetPaymentMethodsRepository from '../../../../data/repositories/writer/my_stripe/NetPaymentMethodsRepository';

interface Props {
  onlySetup: boolean;
  paymentMethod: PaymentMethod | null;
  paymentIntent: PaymentIntent | null;
  recurringMonthly?: boolean;
  note?: string;
  createPaymentIntent: () => Promise<PaymentIntent>;
  onReady: () => void;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod | null>>;
  setPaymentIntent: React.Dispatch<React.SetStateAction<PaymentIntent | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  success: () => void;
  fail?: (e: any) => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {
    onlySetup,
    paymentMethod,
    paymentIntent,
    recurringMonthly,
    note,
    createPaymentIntent,
    onReady,
    setPaymentMethod,
    setPaymentIntent,
    success,
    fail,
    setLoading,
  } = props;
  React.useEffect(() => {
    loadStripeScript(
      () => {
        if (!paymentIntent) {
          createPaymentIntent()
            .then(paymentIntent => {
              if (onlySetup) {
                setPaymentIntent(paymentIntent);
              } else {
                new NetPaymentMethodsRepository()
                  .findBy({onlyDefault: true})
                  .then(result => {
                    const paymentMethod = result.records[0];
                    if (paymentMethod) {
                      setPaymentMethod(paymentMethod);
                    }
                    setPaymentIntent(paymentIntent);
                  })
                  .catch(() => {
                    setPaymentIntent(paymentIntent);
                  });
              }
            })
            .catch(e => {
              fail && fail(e);
            });
        }
      },
      () => {},
    );
  }, []);
  React.useEffect(() => {
    if (paymentIntent) {
      setupForm({
        onlySetup,
        clientSecret: paymentIntent.clientSecret,
        paymentMethodId: paymentMethod?.stripePaymentMethodId,
        success,
        setLoading,
      });
      onReady();
    }
  }, [paymentIntent]);
  return (
    <View style={styles.container}>
      {paymentIntent?.amount && (
        <ModalPrice>
          <ModalPriceKey>
            {paymentIntent?.amount}円{recurringMonthly ? '/月' : null}(税込み)
          </ModalPriceKey>
          {note ? <ModalPriceValue>{note}</ModalPriceValue> : null}
        </ModalPrice>
      )}
      <form id="payment-method-form">
        {paymentMethod ? (
          <View>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>クレジットカード番号</Text>
              <View style={styles.rowCard}>
                <Text style={styles.rowCardBrand}>
                  {paymentMethod.cardBrand}
                </Text>
                <Text>•••• •••• •••• {paymentMethod.cardLast4}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>有効期限（月/年）</Text>
              <Text>
                {`${paymentMethod.cardExpMonth} / ${paymentMethod.cardExpYear}`}
              </Text>
            </View>
          </View>
        ) : (
          <>
            <FormColumn>
              <FormHeading>
                クレジットカード番号
                <ErrorMessage id={'payment-method-card-number-error-message'} />
              </FormHeading>
              <FormItem>
                <FormInput>
                  <div id="payment-method-card-number-element"></div>
                </FormInput>
              </FormItem>
            </FormColumn>
            <FormColumn>
              <FormHeading>
                有効期限（月/年）
                <ErrorMessage id={'payment-method-card-expiry-error-message'} />
              </FormHeading>
              <FormItem>
                <FormInput>
                  <div id="payment-method-card-expiry-element"></div>
                </FormInput>
              </FormItem>
            </FormColumn>
            <FormColumn>
              <FormHeading>
                セキュリティコード
                <ErrorMessage id={'payment-method-card-cvc-error-message'} />
              </FormHeading>
              <FormItem>
                <FormInput>
                  <div id="payment-method-card-cvc-element"></div>
                </FormInput>
              </FormItem>
            </FormColumn>
          </>
        )}
        <FormSubmit>
          <ButtonPrimary type="submit">確定</ButtonPrimary>
        </FormSubmit>
      </form>
    </View>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 32,
    marginBottom: 30,
  } as ViewStyle,
  row: {
    flexDirection: 'row',
    marginVertical: 8,
  } as ViewStyle,
  rowLabel: {
    width: 140,
    fontSize: 12,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
  } as TextStyle,
  rowCard: {} as ViewStyle,
  rowCardBrand: {
    marginBottom: 4,
    fontWeight: 'bold',
  } as TextStyle,
});

const ModalPrice = styled.dl`
  text-align: center;
  margin: 8px;
`;

const ModalPriceKey = styled.dt`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #222;
`;

const ModalPriceValue = styled.dd`
  font-size: 0.6875rem;
  color: #222;
`;

const FormColumn = styled.dl``;

const FormHeading = styled.dt`
  margin: 1.5rem 0 0 0;
  font-size: 0.75rem;
  font-weight: bold;
`;

const ErrorMessage = styled.p`
  color: #f23406;
  font-size: 0.75rem;
  font-weight: normal;
  margin: 8px 0;
`;

const FormItem = styled.dd`
  margin-top: 10px;
  border-radius: 4px;
`;

const FormInput = styled.div`
  padding: 14px 10px;
  border-radius: 4px;
  background-color: #efefef;
  min-height: 44.8px;
`;

const FormSubmit = styled.p`
  text-align: center;
  margin: 1rem auto 0;
  margin-top: 30px;
`;

const ButtonPrimary = styled.button`
  max-width: 260px;
  width: 84%;
  background: #ff8f13;
  text-align: center;
  padding: 1rem 0;
  font-size: 1rem;
  box-sizing: border-box;
  color: #fff;
  font-weight: bold;
  border-radius: 24px;
`;
