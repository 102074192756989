import Entity from './Entity';

export default class Tip implements Entity {
  constructor(
    public id: number,
    public name: string,
    public amount: number,
    public score: number,
  ) {}
}
