import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import Select from '../../../shared/forms/Select';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

import {Filter} from '../../../../../vendor/react-native-tapnovel-viewer/domain/value_objects/command_options/BackgroundCommandOptions';

interface Props {
  value?: string;
  onValueChange: (filter: Filter) => void;
}

export default class FontSelect extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {value} = this.props;
    return (
      <View style={styles.container}>
        <LabelWithOption title={'フィルター'} />
        <Select
          items={items}
          value={value}
          onValueChange={this.handleValueChange}
          cancelText={'キャンセル'}
          confirmText={'決定'}
        />
      </View>
    );
  }

  private handleValueChange = (
    itemValue: string | number,
    itemPosition: number,
  ) => {
    const {onValueChange} = this.props;
    items.forEach(item => {
      if (item.value === itemValue) {
        onValueChange(itemValue);
      }
    });
  };
}

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 100,
    padding: 16,
  } as ViewStyle,
});

const items: Array<{label: string; value: Filter}> = [
  {label: 'ノーマル', value: 'normal'},
  {label: 'モノクロ', value: 'monochrome'},
  {label: 'セピア', value: 'sepia'},
  {label: 'モザイク', value: 'mosaic'},
  {label: 'モヤモヤ', value: 'dream'},
  {label: '赤', value: 'red'},
  {label: '青', value: 'blue'},
  {label: '緑', value: 'green'},
];
