import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';

import FastImageAdapter from '../../shared/fast_image/FastImageAdapter';
import PublicStateLabel from '../../shared/PublicStateLabel';

import {actorCharacterFaceImageUrl} from '../../../helpers/images';

import {colors} from '../../../styles/variables';

import Character from '../../../../domain/entities/Character';

interface Props {
  character: Character;
  children?: React.ReactNode;
}

const CharacterInfo: React.FunctionComponent<Props> = props => {
  const {character} = props;
  const position = character.actorCharacterFace.getPositionForCenteringFace();
  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View
          style={[
            styles.contentLeft,
            character.inverted ? styles.inverted : null,
          ]}>
          <FastImageAdapter
            resizeMode={'contain'}
            style={[styles.image, position]}
            source={{
              uri: actorCharacterFaceImageUrl(
                character.actorCharacterFace,
                'middle',
              ),
              headers: {Accept: 'image/webp,image/apng,*/*'},
            }}
          />
        </View>
        <View style={styles.contentCenter}>
          <Text style={styles.name}>{character.name}</Text>
          <PublicStateLabel published={character.published} />
        </View>
      </View>
      {character.description ? (
        <Text style={styles.description}>{character.description}</Text>
      ) : null}
    </View>
  );
};

export default onlyUpdateForKeys(['character'])(CharacterInfo);

const width = 140;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
    padding: 8,
  } as ViewStyle,
  content: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 8,
  } as ViewStyle,
  contentLeft: {
    alignItems: 'center',
    borderColor: colors.powderGray,
    borderRadius: 35,
    borderWidth: 0.5,
    backgroundColor: '#ffffff',
    height: 70,
    overflow: 'hidden',
    width: 70,
    marginHorizontal: 8,
  } as ViewStyle,
  contentCenter: {
    maxWidth: 250,
    justifyContent: 'center',
    marginHorizontal: 8,
  } as ViewStyle,
  contentRight: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginHorizontal: 8,
  } as ViewStyle,
  description: {
    flex: 1,
    margin: 10,
    marginHorizontal: 8,
    fontSize: 10,
    lineHeight: 13,
  } as TextStyle,
  image: {
    height: width,
    width,
    position: 'absolute',
  } as ImageStyle,
  name: {
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 8,
  } as TextStyle,
  button: {
    borderColor: '#383838',
    borderWidth: 1,
    borderRadius: 16,
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ViewStyle,
});
