import shouldUpdateEntityList from './shouldUpdateEntityList';

import Entity from '../../../../domain/entities/Entity';

interface EntityWithUpdatedAt extends Entity {
  updatedAt: Date;
}

interface Props {
  entities: EntityWithUpdatedAt[] | null;
}

export default (current: Props, next: Props) => {
  if (shouldUpdateEntityList(current, next)) {
    return true;
  }
  if (!current.entities || !next.entities) {
    return true;
  }
  return !current.entities.every((currentEntity, i) => {
    if (!next.entities) {
      return false;
    }
    const nextEntity = next.entities[i];
    if (currentEntity.updatedAt.getTime() !== nextEntity.updatedAt.getTime()) {
      return false;
    }
    return true;
  });
};
