import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import DefaultModal from '../../../shared/modals/DefaultModal';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import {colors} from '../../../../styles/variables';

interface Props {
  visible: boolean;
  onRequestClose: () => void;
}

const PasswordChangeModal: React.FunctionComponent<Props> = props => {
  const {visible, onRequestClose} = props;
  return (
    <DefaultModal visible={visible}>
      <View style={styles.container}>
        <View style={styles.inner}>
          <View style={styles.title}>
            <Text style={styles.titleText}>パスワードを変更しました</Text>
          </View>
          <View style={styles.button}>
            <PrimaryButton buttonSize={BUTTON_SIZE} onPress={onRequestClose}>
              OK
            </PrimaryButton>
          </View>
        </View>
      </View>
    </DefaultModal>
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(PasswordChangeModal, areEqual);

const BUTTON_SIZE = {width: 160, height: 46};

const styles = StyleSheet.create({
  body: {
    marginBottom: 16,
  } as ViewStyle,
  bodyText: {
    color: colors.textColor,
    fontSize: 12,
    lineHeight: 16,
  } as TextStyle,
  button: {} as ViewStyle,
  container: {
    padding: 10,
    width: '100%',
  } as ViewStyle,
  inner: {
    alignItems: 'center',
    backgroundColor: colors.backgroundGray,
    paddingHorizontal: 10,
    paddingVertical: 20,
  } as ViewStyle,
  title: {
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
  } as ViewStyle,
  titleText: {
    color: colors.textColor,
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
});
