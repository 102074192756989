import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  activeValue: string | null;
  value: string | null;
  children?: React.ReactNode;
}

const LayoutWrapper: React.FunctionComponent<Props> = props => {
  const {activeValue, value, children} = props;
  const style = React.useMemo(() => {
    return [styles.container, activeValue === value ? null : styles.hide];
  }, [value]);
  return <View style={style}>{children}</View>;
};

export default React.memo(LayoutWrapper);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});
