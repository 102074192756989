import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import {colors} from '../../../styles/variables';

interface Props {}

const Separator: React.FC<Props> = props => {
  return <View style={styles.separator} />;
};

export default React.memo(Separator);

const styles = StyleSheet.create({
  separator: {
    marginVertical: 32,
    backgroundColor: colors.powderGray,
    height: StyleSheet.hairlineWidth,
  } as ViewStyle,
});
