import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../shared/buttons/PrimaryButton';
import Layout from '../../shared/Layout';

interface Props {
  onPressRetry: () => void;
}

class Index extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {onPressRetry} = this.props;
    return (
      <Layout scrollable={false} title={'サーバーエラー'}>
        <View style={styles.container}>
          <View style={styles.inner}>
            <Text style={styles.message}>時間をおいて再度お試し下さい。</Text>
            <PrimaryButton onPress={onPressRetry}>リトライ</PrimaryButton>
          </View>
        </View>
      </Layout>
    );
  }
}

export default Index;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  inner: {
    alignItems: 'center',
  } as ViewStyle,
  message: {
    margin: 10,
  } as TextStyle,
});
