import * as React from 'react';
import {
  NativeSyntheticEvent,
  Platform,
  TextInput,
  TextInputSelectionChangeEventData,
} from 'react-native';

import Form, {TabValue} from './partials/Form';

import Layout from '../../../../shared/Layout';
import HeaderRightButton from '../../../../shared/buttons/HeaderRightButton';
import CloseIcon from '../../../../shared/icons/CloseIcon';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import {validText} from '../../../../../helpers/textInputHelper';

import * as routers from '../../../../../routers';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTextsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as TextFrameIndexParams} from '../../../../../actions/text_frames/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';
import scrollableLayout from '../../../../../helpers/scrollableLayout';
import {addSymbolToTextAndCalcSelection} from '../../../../../helpers/textInputHelper';

import TextFrame from '../../../../../../domain/entities/TextFrame';
import Voice from '../../../../../../domain/entities/Voice';
import Sound from '../../../../../../domain/entities/Sound';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import TextNormalizer from '../../../../../../domain/helpers/TextNormalizer';

import {isAndroid} from '../../../../../../data/data_stores/net/UserAgent';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTextsRouteProp;
  sceneForm: SceneForm | null;
  textFrameId: number;
  text: string;
  textFramesParams: TextFrameIndexParams;
  textFrames: TextFrame[] | null;
  enableVoice?: boolean;
}

export interface DispatchProps {
  onSelectText: (
    text: string,
    textFrameId: number,
    voice: Voice | null,
    sound: Sound | null,
    startTime?: number | null,
    endTime?: number | null,
  ) => void;
  indexTextFrames: (
    params: TextFrameIndexParams,
  ) => Promise<PaginatedResult<TextFrame>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  currentTab: TabValue;
  disabled: boolean;
  text: string;
  selectedTextFrameId: number;
  selectedVoice: Voice | null;
  selectedSound: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
  selection?: {
    start: number;
    end: number;
  };
}

export default class Index extends React.PureComponent<Props, State> {
  private currentSelection:
    | {
        start: number;
        end: number;
      }
    | undefined = undefined;

  private textInputRef: React.RefObject<TextInput>;

  constructor(props: Props) {
    super(props);
    const {text} = props;
    const disabled = !validText(text);
    const textLength = text.length;
    this.state = {
      currentTab: 'text',
      disabled,
      text,
      selectedTextFrameId: props.textFrameId,
      selectedVoice: null,
      selectedSound: null,
      selectedSoundStartTime: null,
      selectedSoundEndTime: null,
      selection: {start: textLength, end: textLength},
    };
    this.textInputRef = React.createRef<TextInput>();
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      textFramesParams,
      textFrames,
      indexTextFrames,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!textFrames) {
      indexTextFrames(textFramesParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, textFrames, enableVoice} = this.props;
    const {
      currentTab,
      text,
      selectedTextFrameId,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      selection,
    } = this.state;
    const {storyId} = route.params;
    const textFrame = textFrames?.find(o => o.id === selectedTextFrameId);
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={currentTab === 'text' ? 'テキスト内容' : '枠組み'}
            scrollable={
              Platform.OS === 'web' && isAndroid
                ? false
                : scrollableLayout(context)
            }
            navigation={navigation}
            rightButton={this.buildRightButton()}>
            {textFrame && textFrames && (
              <Form
                storyId={storyId}
                textInputRef={this.textInputRef}
                currentTab={currentTab}
                textFrame={textFrame}
                text={text}
                textFrames={textFrames}
                selectedTextFrameId={selectedTextFrameId}
                voice={selectedVoice}
                sound={selectedSound}
                selectedSoundStartTime={selectedSoundStartTime}
                selectedSoundEndTime={selectedSoundEndTime}
                enableVoice={enableVoice}
                selection={selection}
                onChangeTab={this.handleChangeTab}
                onChangeText={this.handleChangeText}
                onPressSymbol={this.handlePressSymbol}
                onSelectTextFrame={this.handleSelectTextFrame}
                onUpdateVoice={this.handleUpdateVoice}
                onSelectionChange={this.handleSelectionChange}
                addSound={this.addSound}
                deleteSound={this.deleteSound}
                onChagenRegion={this.handleChagenRegion}
              />
            )}
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private buildRightButton = () => {
    const {text} = this.state;
    if (text.length === 0) {
      return {
        handler: this.handleClose,
        tintColor: 'white',
        title: <CloseIcon />,
      };
    } else {
      return {
        tintColor: 'white',
        title: (
          <HeaderRightButton
            disabled={!validText(text)}
            onPress={this.handlePress}>
            確定
          </HeaderRightButton>
        ),
      };
    }
  };

  private handleClose = () => {
    const {navigation} = this.props;
    (navigation.getParent() || navigation).goBack();
  };

  private handleChangeTab = (currentTab: TabValue) => {
    this.setState({currentTab});
  };

  private handleChangeText = (text: string) => {
    const disabled = !validText(text);
    this.setState({disabled, text});
  };

  private handleSelectTextFrame = (textFrame: TextFrame) => {
    this.setState({selectedTextFrameId: textFrame.id});
  };

  private handlePress = () => {
    const {onSelectText} = this.props;
    const {
      disabled,
      text,
      selectedTextFrameId,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
    } = this.state;
    if (disabled) {
      return;
    }
    const nextText = TextNormalizer.normalize(text, 'descriptive_text');
    const nextDisabled = !validText(nextText);
    this.setState(
      {
        disabled: nextDisabled,
        text: nextText,
      },
      () => {
        if (nextDisabled) {
          return;
        }
        onSelectText(
          nextText,
          selectedTextFrameId,
          selectedVoice,
          selectedSound,
          selectedSoundStartTime,
          selectedSoundEndTime,
        );
      },
    );
  };

  private handlePressSymbol = (symbol: string) => {
    const {text: selectedText} = this.state;
    const {text, selection} = addSymbolToTextAndCalcSelection(
      selectedText,
      symbol,
      this.currentSelection,
    );
    const disabled = !validText(text);
    this.setState({disabled, text, selection});
    this.textInputRef.current?.focus();
  };

  private handleUpdateVoice = (voice: Voice | null) => {
    this.setState({selectedVoice: voice});
  };

  private handleSelectionChange = (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => {
    if (this.state.selection) {
      this.setState({selection: undefined});
    }
    this.currentSelection = e.nativeEvent.selection;
  };

  private addSound = () => {
    const {navigation, route} = this.props;
    const params = route.params;
    routers.linkToSceneFormSoundEffectShowSceneCommandNewNavigations(
      navigation,
      {
        storyId: params.storyId,
        episodeId: params.episodeId,
        sceneId: params.sceneId,
        back: true,
        sceneCommandIndex: params.sceneCommandIndex,
        subSceneCommandIndex: params.subSceneCommandIndex,
        parentSceneCommandId: params.parentSceneCommandId,
        callback: selectedSound => {
          this.setState({selectedSound});
        },
      },
    );
  };

  private deleteSound = () => {
    this.setState({
      selectedSound: null,
      selectedSoundStartTime: null,
      selectedSoundEndTime: null,
    });
  };

  private handleChagenRegion = (start?: number, end?: number) => {
    this.setState({
      selectedSoundStartTime: start || null,
      selectedSoundEndTime: end || null,
    });
  };
}
