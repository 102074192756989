import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import {Value} from './Tabs';

interface Props {
  value: Value;
  label: string;
  isActive: boolean;
  onChangeTab: (value: Value) => void;
}

const Tab: React.FunctionComponent<Props> = props => {
  const {value, label, isActive, onChangeTab} = props;
  return (
    <Pressable onPress={() => onChangeTab(value)}>
      <View style={[styles.tab, isActive ? styles.tabActive : null]}>
        <Text style={[styles.label, isActive ? styles.labelActive : null]}>
          {label}
        </Text>
      </View>
    </Pressable>
  );
};

export default React.memo(Tab);

const styles = StyleSheet.create({
  tab: {
    width: 75,
    height: 25,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 3,
  } as ViewStyle,
  tabActive: {
    backgroundColor: '#efefef',
  } as ViewStyle,
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#666666',
  } as TextStyle,
  labelActive: {
    color: '#383838',
  },
});
