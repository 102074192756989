import EntityMapper from '../EntityMapper';

import UserNotification from '../../../../domain/entities/writer/UserNotification';

export interface UserNotificationData {
  id: number;
  writer_user_id: number;
  content: string;
  fullpath: string;
  options_updated_at: string | null;
  confirmed_at: string | null;
  created_at: string;
  updated_at: string;
}

export default class UserNotificationMapper
  implements EntityMapper<UserNotificationData, UserNotification>
{
  public map(obj: UserNotificationData): UserNotification {
    return new UserNotification(
      obj.id,
      obj.writer_user_id,
      obj.content,
      obj.fullpath,
      obj.options_updated_at ? new Date(obj.options_updated_at) : null,
      obj.confirmed_at ? new Date(obj.confirmed_at) : null,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
