import * as React from 'react';

import CharacterMakerActorGroupList from './partials/CharacterMakerActorGroupList';
import CharacterMakerActorSelectForm from './partials/CharacterMakerActorSelectForm';

import Layout from '../../../Layout';
import DimensionContext from '../../../dimension/DimensionContext';

import NavigationProp from '../../../../../navigators/NavigationProp';

import {Params as CharacterMakerActorGroupIndexParams} from '../../../../../actions/character_maker_actor_groups/index';

import CharacterMakerActorGroup from '../../../../../../domain/entities/CharacterMakerActorGroup';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

export interface StateProps {
  navigation: NavigationProp;
  enableSkinColorPattern?: boolean;
  characterMakerActorGroups: CharacterMakerActorGroup[] | null;
  characterMakerActorGroupsParams: CharacterMakerActorGroupIndexParams;
}

export interface DispatchProps {
  indexCharacterMakerActorGroups: (
    params: CharacterMakerActorGroupIndexParams,
  ) => Promise<PaginatedResult<CharacterMakerActorGroup>>;
}

interface Props extends StateProps, DispatchProps {
  onSelectCharacterMakerActorGroup: (
    characterMakerActorGroup: CharacterMakerActorGroup,
    skinColorPatternId: number,
  ) => void;
}

const Index: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    enableSkinColorPattern,
    characterMakerActorGroups,
    characterMakerActorGroupsParams,
    indexCharacterMakerActorGroups,
    onSelectCharacterMakerActorGroup,
  } = props;
  React.useEffect(() => {
    if (!characterMakerActorGroups) {
      indexCharacterMakerActorGroups(characterMakerActorGroupsParams);
    }
  }, []);
  return (
    <Layout
      title={'素体'}
      back={true}
      close={true}
      navigation={navigation}
      scrollable={false}
      hideHeaderBottomBorder={true}>
      <DimensionContext.Consumer>
        {context => {
          return enableSkinColorPattern ? (
            <CharacterMakerActorSelectForm
              contentWidth={context.content.width}
              characterMakerActorGroups={characterMakerActorGroups}
              onSelectCharacterMakerActorGroup={
                onSelectCharacterMakerActorGroup
              }
            />
          ) : (
            <CharacterMakerActorGroupList
              contentWidth={context.content.width}
              characterMakerActorGroups={characterMakerActorGroups}
              onSelectCharacterMakerActorGroup={
                onSelectCharacterMakerActorGroup
              }
            />
          );
        }}
      </DimensionContext.Consumer>
    </Layout>
  );
};

export default React.memo(Index);
