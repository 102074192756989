import * as React from 'react';
import {
  Alert,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {useActionSheet} from '@expo/react-native-action-sheet';
import dateformat from 'dateformat';

import WriterUserImage from '../members/partials/WriterUserImage';

import ArrowForwardIcon from '../../shared/icons/ArrowForwardIcon';
import ThumbsUpActiveIcon from '../../shared/icons/project/ThumbsUpActiveIcon';
import CommentIcon from '../../shared/icons/project/CommentIcon';
import MoreIcon from '../../shared/icons/ionicons/MoreIcon';

import ProjectTopic from '../../../../domain/entities/ProjectTopic';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  topic: ProjectTopic;
  isLeader?: boolean;
  onForceUpdate: () => void;
}

const TopicListItem: React.FC<Props> = props => {
  const {topic, isLeader, onForceUpdate} = props;
  const linkTo = useLinkTo();
  const {showActionSheetWithOptions} = useActionSheet();
  const onPress = React.useCallback(() => {
    linkTo(`/projects/${topic.projectId}/topics/${topic.id}`);
  }, []);
  const onOpenActionSheet = React.useCallback(() => {
    const options = isLeader
      ? [
          '削除する',
          topic.pinned ? '固定解除' : '固定する',
          '通報する',
          'キャンセル',
        ]
      : ['通報する', 'キャンセル'];
    const destroyTopicIndex = isLeader ? 0 : 99;
    const fixTopicIndex = isLeader ? 1 : 99;
    const reportTopicIndex = isLeader ? 2 : 0;
    const cancelButtonIndex = isLeader ? 3 : 1;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        options,
        destructiveButtonIndex: destroyTopicIndex,
      },
      async (buttonIndex?: number) => {
        switch (buttonIndex) {
          case destroyTopicIndex:
            TapNovelTypedRestApi.delete(
              `/api/writer/leader/project_topics/${topic.id}`,
            ).then(onForceUpdate);
            break;
          case fixTopicIndex:
            TapNovelTypedRestApi.patch(
              `/api/writer/leader/project_topics/${topic.id}`,
              {projectTopic: {pinned: topic.pinned ? false : true}},
            ).then(onForceUpdate);
            break;
          case reportTopicIndex:
            TapNovelTypedRestApi.post(`/api/writer/project_topic_alerts`, {
              projectTopicId: topic.id,
            }).then(() => {
              if (Platform.OS === 'web') {
                alert('通報しました');
              } else {
                Alert.alert('通報しました');
              }
            });
            break;
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, [topic]);
  return (
    <View style={styles.container}>
      <WriterUserImage user={topic.writerUser} imageSize={50} />
      <View style={styles.right}>
        <Text style={styles.title}>
          <Text style={styles.penName}>{topic.writerUser.penName}</Text>
        </Text>
        <Text style={styles.submittedAt}>
          {dateformat(topic.createdAt, 'yyyy.mm.dd HH:MM')}
        </Text>
        <TouchableOpacity style={styles.submission} onPress={onPress}>
          <Text style={styles.submissionText}>{topic.title}</Text>
          <ArrowForwardIcon />
        </TouchableOpacity>
        <View style={styles.info}>
          <View style={styles.infoRow}>
            <ThumbsUpActiveIcon color={'#ff8f13'} size={10} />
            <Text style={styles.infoRowText}>{topic.likesCount}</Text>
          </View>
          <View style={styles.infoRow}>
            <CommentIcon color={'#ff8f13'} size={10} />
            <Text style={styles.infoRowText}>{topic.commentsCount}</Text>
          </View>
        </View>
      </View>
      <TouchableOpacity style={styles.headerRight} onPress={onOpenActionSheet}>
        <View style={styles.more}>
          <MoreIcon />
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(TopicListItem);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: 'row',
  } as ViewStyle,
  right: {
    marginTop: 4,
    marginBottom: 8,
    marginLeft: 10,
    flex: 1,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 12,
    marginBottom: 8,
  } as TextStyle,
  penName: {
    fontWeight: 'bold',
  } as TextStyle,
  submittedAt: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  submission: {
    borderWidth: 1,
    borderColor: '#999',
    borderRadius: 3,
    marginVertical: 8,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  submissionText: {
    color: '#222',
    fontSize: 12,
    paddingRight: 16,
  } as TextStyle,
  info: {
    flexDirection: 'row',
  } as ViewStyle,
  infoRow: {
    flexDirection: 'row',
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  infoRowText: {
    color: '#999',
    fontSize: 10,
    marginLeft: 5,
  } as TextStyle,
  headerRight: {
    alignItems: 'center',
    height: 44,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 44,
    zIndex: 100,
  } as ViewStyle,
  more: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
});
