import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

interface Props {
  point: {
    top: number;
    left: number;
    commandIndex: number;
  } | null;
}

const CommandIndexBar: React.FunctionComponent<Props> = props => {
  const {point} = props;
  if (!point) {
    return null;
  }
  return <View style={[styles.container, {left: point.left}]} />;
};

export default CommandIndexBar;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: 2,
    top: 10,
    height: 210,
    borderRadius: 4,
    backgroundColor: '#ff8f13',
    opacity: 0.5,
  } as ViewStyle,
});
