import * as React from 'react';
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputSelectionChangeEventData,
} from 'react-native';

import ElasticBoxInput from './ElasticBoxInput';

import NameLabelColor from '../../view_models/NameLabelColor';

import {orientedSpeechBalloonPartImageUrl} from '../../helpers/images';

import OrientedSpeechBalloon from '../../../domain/entities/OrientedSpeechBalloon';
import SpeechBalloon from '../../../domain/entities/SpeechBalloon';

import FramePartPosition from '../../../domain/value_objects/FramePartPosition';

interface Props {
  textInputRef?: React.RefObject<TextInput>;
  text: string;
  name?: string;
  nameLabelColor?: NameLabelColor;
  hasVoice?: boolean;
  orientedSpeechBalloon: OrientedSpeechBalloon | SpeechBalloon;
  width: number;
  height: number;
  selection?: {start: number; end?: number};
  onChangeText: (text: string) => void;
  onChangeName?: (text: string) => void;
  onSelectionChange?: (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => void;
}

const ElasticSpeechBalloonInput: React.FunctionComponent<Props> = props => {
  const {
    textInputRef,
    text,
    name,
    nameLabelColor,
    hasVoice,
    orientedSpeechBalloon,
    width,
    height,
    selection,
    onChangeText,
    onChangeName,
    onSelectionChange,
  } = props;
  const top = orientedSpeechBalloon.getPartImageUrlOf(FramePartPosition.Top);
  const middle = orientedSpeechBalloon.getPartImageUrlOf(
    FramePartPosition.Middle,
  );
  const bottom = orientedSpeechBalloon.getPartImageUrlOf(
    FramePartPosition.Bottom,
  );
  return (
    <ElasticBoxInput
      textInputRef={textInputRef}
      text={text}
      name={name}
      nameLabelColor={nameLabelColor}
      hasVoice={hasVoice}
      top={orientedSpeechBalloonPartImageUrl(top, 'large')}
      middle={orientedSpeechBalloonPartImageUrl(middle, 'large')}
      bottom={orientedSpeechBalloonPartImageUrl(bottom, 'large')}
      width={width}
      height={height}
      textStyle={[
        orientedSpeechBalloon.textStyle,
        name !== undefined
          ? {paddingTop: 30}
          : {paddingTop: 30, paddingBottom: 15},
      ]}
      middleStyle={orientedSpeechBalloon.containerStyle}
      voiceIconStyle={orientedSpeechBalloon.voiceIconStyle}
      selection={selection}
      onChangeText={onChangeText}
      onChangeName={onChangeName}
      onSelectionChange={onSelectionChange}
    />
  );
};

export default React.memo(ElasticSpeechBalloonInput);
