import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import Illustration from '../../../domain/entities/Illustration';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.IndexIllustrationsSuccess: {
      const result = action.payload as PaginatedResult<Illustration>;
      return mergeResponse(state, result);
    }
    default:
      return state;
  }
}
