import * as React from 'react';

import BaseIndex from '../../common/base_effect_search_form/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormEffectShowSceneCommandNewBaseEffectSearchFormRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as EffectCategoryIndexParams} from '../../../../../actions/effect_categories/index';
import {Params as BaseEffectIndexParams} from '../../../../../actions/base_effects/index';

import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import EffectCategory from '../../../../../../domain/entities/EffectCategory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  selectedLargeEffectCategoryId?: number | null;
  selectedMiddleEffectCategoryId?: number | null;
  selectedSmallEffectCategoryId?: number | null;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  favorite?: boolean;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandNewBaseEffectSearchFormRouteProp;
  sceneForm: SceneForm | null;
  largeEffectCategoriesParams: EffectCategoryIndexParams;
  largeEffectCategories: EffectCategory[] | null;
  middleEffectCategoriesParams: EffectCategoryIndexParams;
  middleEffectCategories: EffectCategory[] | null;
  smallEffectCategoriesParams: EffectCategoryIndexParams;
  smallEffectCategories: EffectCategory[] | null;
}

export interface DispatchProps {
  indexEffectCategories: (
    params: EffectCategoryIndexParams,
  ) => Promise<PaginatedResult<EffectCategory>>;
  indexBaseEffects: (
    params: BaseEffectIndexParams,
  ) => Promise<PaginatedResult<BaseEffect>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  totalCount: number;
  query: string;
}

export default class Index extends React.Component<Props, State> {
  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (params: {
    query?: string;
    effectCategoryId?: number;
  }) => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      selectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId,
      favorite,
    } = route.params;
    routers.linkToSceneFormEffectShowSceneCommandNewBaseEffects(navigation, {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      selectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId,
      favorite,
      ...params,
    });
  };
}
