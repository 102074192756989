import * as React from 'react';

import EpisodeListItem from './EpisodeListItem';

import DefaultList from '../../../shared/lists/DefaultList';

import EpisodeAggregation from '../../../../../domain/value_objects/summary/EpisodeAggregation';

interface Props {
  episodeAggregations: EpisodeAggregation[];
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  onSelectEpisode: (episodeAggregation: EpisodeAggregation) => void;
}

const EpisodeList: React.FunctionComponent<Props> = props => {
  const {episodeAggregations, ListHeaderComponent, onSelectEpisode} = props;
  const renderCustom = React.useCallback((item: EpisodeAggregation) => {
    return <EpisodeListItem episodeAggregation={item} />;
  }, []);
  return (
    <DefaultList
      data={episodeAggregations}
      style={style}
      ListHeaderComponent={ListHeaderComponent}
      renderCustom={renderCustom}
      onSelectItem={onSelectEpisode}
    />
  );
};

export default EpisodeList;

const style = {backgroundColor: 'white'};
