import * as React from 'react';

import AddSceneCommandLink from './AddSceneCommandLink';

import SoundIcon from '../../../../shared/icons/SoundIcon';

import {colors} from '../../../../../styles/variables';

interface Props {
  onPress: () => void;
}

const AddEffectShowSceneCommandLink: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <AddSceneCommandLink title={'効果音'} onPress={onPress}>
      <SoundIcon color={colors.textColor} />
    </AddSceneCommandLink>
  );
};

export default React.memo(AddEffectShowSceneCommandLink);
