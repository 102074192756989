import * as React from 'react';
import {CommonActions} from '@react-navigation/native';

import BaseIndex, {
  FormParams,
} from '../../../shared/generics/character_form/character_names/Index';
import AlertModal from '../../../shared/modals/AlertModal';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CoverImageFormCharacterFormCharacterNamesRouteProp} from '../../../../navigators/RouteProps';

import {Params as CharacterIndexParams} from '../../../../actions/character_patterns/index';
import {Params as CharacterCreateParams} from '../../../../actions/characters/create';

import {formatErrorMessages} from '../../../../helpers/errorMessages';
import redirectStoryCoverImageForm from '../../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../../domain/forms/CoverImageForm';
import ActorCharacter from '../../../../../domain/entities/ActorCharacter';
import Character from '../../../../../domain/entities/Character';
import CharacterPattern from '../../../../../domain/entities/CharacterPattern';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  actorCharacterId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormCharacterFormCharacterNamesRouteProp;
  coverImageForm: CoverImageForm | null;
  actorCharacter: ActorCharacter | null;
}

export interface DispatchProps {
  createCharacter: (params: CharacterCreateParams) => Promise<Character>;
  indexCharacterPatterns: (
    params: CharacterIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  showActorCharacter: (id: number) => Promise<ActorCharacter>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {loading, alertMessage} = this.state;
    return (
      <>
        <BaseIndex
          {...this.props}
          loading={loading}
          onSubmit={this.handleSubmit}
        />
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </>
    );
  }

  private handleSubmit = (formParams: FormParams) => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {navigation, route, createCharacter, indexCharacterPatterns} =
      this.props;
    const {storyId, aspectRatio, imageKey, actorCharacterId} = route.params;
    const {name} = formParams;
    this.setState({loading: true}, () => {
      createCharacter({actorCharacterId, name, storyId})
        .then(character => {
          const characterId = character.id;
          const characterPatternId = character.defaultCharacterPatternId;
          indexCharacterPatterns({storyId}).then(() => {
            this.setState({loading: false}, () => {
              navigation.dispatch(
                CommonActions.reset({
                  routes: [
                    {
                      name: 'CoverImageFormCharacterPatterns',
                      params: {
                        storyId,
                        aspectRatio,
                        imageKey,
                      },
                    },
                    {
                      name: 'CoverImageFormActorCharacterFaces',
                      params: {
                        storyId,
                        aspectRatio,
                        imageKey,
                        characterId,
                        characterPatternId,
                      },
                    },
                  ],
                }),
              );
            });
          });
        })
        .catch(error => {
          this.setState(
            {loading: false, alertMessage: formatErrorMessages({}, error)},
            () => {
              this.disabledSubmit = false;
            },
          );
        });
    });
  };
}
