import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import dateformat from 'dateformat';

import ArrowForwardIcon from '../../../../shared/icons/ArrowForwardIcon';
import ClapActiveIcon from '../../../../shared/icons/project/ClapActiveIcon';
import CommentIcon from '../../../../shared/icons/project/CommentIcon';

import ProjectManuscript from '../../../../../../domain/entities/ProjectManuscript';

interface Props {
  manuscript: ProjectManuscript;
}

const ManuscriptListItem: React.FC<Props> = props => {
  const {manuscript} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(
      `/projects/${manuscript.projectId}/user/manuscripts/${manuscript.id}`,
    );
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.submitDate}>
        提出：
        {manuscript.submittedAt
          ? dateformat(manuscript.submittedAt, 'yyyy.mm.dd HH:MM')
          : ''}
      </Text>
      <TouchableOpacity style={styles.title} onPress={onPress}>
        <Text style={styles.titleText}>
          {manuscript.title}
          {'\n'}
          {manuscript.typeName}（
          {manuscript.manuscriptNumber === 1
            ? '初'
            : manuscript.manuscriptNumber}
          稿）
        </Text>
        <ArrowForwardIcon />
      </TouchableOpacity>
      <View style={styles.info}>
        <View style={styles.infoRow}>
          <ClapActiveIcon size={10} color={'#ff8f13'} />
          <Text style={styles.infoRowText}>{manuscript.likesCount}</Text>
        </View>
        <View style={styles.infoRow}>
          <CommentIcon size={10} color={'#ff8f13'} />
          <Text style={styles.infoRowText}>{manuscript.commentsCount}</Text>
        </View>
      </View>
    </View>
  );
};

export default React.memo(ManuscriptListItem);

const styles = StyleSheet.create({
  container: {
    padding: 10,
  } as ViewStyle,
  submitDate: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  title: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#999',
    borderRadius: 3,
    marginVertical: 8,
    paddingVertical: 12,
    paddingHorizontal: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  titleText: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
  info: {
    flexDirection: 'row',
  } as ViewStyle,
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
  } as ViewStyle,
  infoRowText: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
});
