import * as React from 'react';

import BaseIndex from '../../shared/generics/actor_search_form/Index';

import NavigationProp from '../../../navigators/NavigationProp';
import {ShopActorSearchFormRouteProp} from '../../../navigators/RouteProps';

import {Params as ActorCategoryIndexParams} from '../../../actions/actor_categories/index';
import {Params as ActorIndexParams} from '../../../actions/actors/index';

import Actor from '../../../../domain/entities/Actor';
import ActorCategory from '../../../../domain/entities/ActorCategory';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {
  formValues?: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    allowedInversion?: Array<'true' | 'false'>;
  };
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ShopActorSearchFormRouteProp;
  actorCategoriesParams: ActorCategoryIndexParams;
  actorCategories: ActorCategory[] | null;
}

export interface DispatchProps {
  indexActorCategories: (
    params: ActorCategoryIndexParams,
  ) => Promise<PaginatedResult<ActorCategory>>;
  indexActors: (params: ActorIndexParams) => Promise<PaginatedResult<Actor>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public render(): React.ReactNode {
    const {formValues, hideBack} = this.props.route.params;
    return (
      <BaseIndex
        {...this.props}
        formValues={formValues}
        hideBack={hideBack}
        displayAllowedInversion={true}
        onPress={this.handlePress}
      />
    );
  }

  private handlePress = (params: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    paid?: boolean;
    allowedInversion?: Array<'true' | 'false'>;
  }) => {
    const {navigation} = this.props;
    navigation.navigate('ShopActors', {
      formValues: params,
    });
  };
}
