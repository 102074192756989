import * as React from 'react';
import {Pressable, StyleSheet, View, ViewStyle} from 'react-native';

import {colors} from '../../../styles/variables';

interface Props {
  page: number;
  currentPage: number;
  onSelectPage: (page: number) => void;
}

export default class Bullet extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {page, currentPage} = this.props;
    return (
      <Pressable onPress={this.handlePress} key={`bullet${page}`}>
        <View
          style={
            page === currentPage
              ? [styles.bullet, styles.chosenBullet]
              : styles.bullet
          }
        />
      </Pressable>
    );
  }

  private handlePress = () => {
    const {page, onSelectPage} = this.props;
    onSelectPage(page);
  };
}

const styles = StyleSheet.create({
  bullet: {
    backgroundColor: colors.lightBlack,
    borderRadius: 3,
    height: 6,
    margin: 4,
    opacity: 0.4,
    width: 6,
  } as ViewStyle,
  chosenBullet: {
    opacity: 1,
  } as ViewStyle,
});
