import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../buttons/PrimaryButton';
import DefaultModal from './DefaultModal';

import {modalStyles} from '../../../styles/variables';

interface Props {
  visible: boolean;
  title?: string;
  action?: {
    label: string;
    callback: () => void;
  };
  children?: React.ReactNode;
  onRequestClose: () => void;
}

const ApplicationModal: React.FunctionComponent<Props> = props => {
  const {visible, title, action, children, onRequestClose} = props;
  return (
    <DefaultModal
      visible={visible}
      onCloseModal={action ? onRequestClose : undefined}
      footer={
        action ? (
          <PrimaryButton
            style={styles.button}
            onPress={() => {
              action.callback();
              onRequestClose();
            }}>
            {action.label}
          </PrimaryButton>
        ) : (
          <PrimaryButton style={styles.button} onPress={onRequestClose}>
            閉じる
          </PrimaryButton>
        )
      }>
      <View style={styles.body}>
        {title ? (
          <Text
            style={{
              textAlign: 'center',
              color: '#ff8f13',
              fontSize: 14,
              fontWeight: 'bold',
              marginBottom: 8,
            }}>
            {title}
          </Text>
        ) : null}
        <Text style={modalStyles.text}>{children}</Text>
      </View>
    </DefaultModal>
  );
};

export default ApplicationModal;

const styles = StyleSheet.create({
  body: {
    marginTop: 24,
    marginBottom: 16,
  } as ViewStyle,
  title: {} as TextStyle,
  button: {
    marginTop: 8,
    marginBottom: 24,
    width: 160,
    height: 46,
  } as ViewStyle,
});
