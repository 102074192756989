import * as React from 'react';

import CharacterPositionList from './partials/CharacterPositionList';

import Layout from '../../../Layout';
import DimensionContext from '../../../dimension/DimensionContext';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterPositionsRouteProp} from '../../../../../navigators/RouteProps';

import SceneFrame from '../../../../../view_models/SceneFrame';

import generateSceneFrame from '../../../../../helpers/generateSceneFrame';
import scrollableLayout from '../../../../../helpers/scrollableLayout';

import ActorCharacterFace from '../../../../../../domain/entities/ActorCharacterFace';
import CharacterPattern from '../../../../../../domain/entities/CharacterPattern';
import Mark from '../../../../../../domain/entities/Mark';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import Position from '../../../../../../domain/value_objects/Position';

export interface Params {
  characterPatternId: number;
  actorCharacterFaceId: number;
  markId: number | null;
  sceneCommandIndex?: number;
}

interface Props {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterPositionsRouteProp;
  characterPattern: CharacterPattern | null;
  actorCharacterFace: ActorCharacterFace | null;
  mark: Mark | null;
  sceneCommandForms: SceneCommandForm[] | null;
  onSelectPosition: (position: Position) => void;
  showCharacterPattern: (id: number) => Promise<CharacterPattern>;
  showActorCharacterFace: (id: number) => Promise<ActorCharacterFace>;
  showMark: (id: number) => Promise<Mark>;
}

interface State {
  sceneCommandForms: SceneCommandForm[] | null;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      sceneCommandForms: props.sceneCommandForms,
    };
  }

  public componentDidMount() {
    const {
      route,
      actorCharacterFace,
      characterPattern,
      mark,
      showCharacterPattern,
      showActorCharacterFace,
      showMark,
    } = this.props;
    const {characterPatternId, actorCharacterFaceId, markId} = route.params;
    if (!actorCharacterFace) {
      showActorCharacterFace(actorCharacterFaceId);
    }
    if (!characterPattern) {
      showCharacterPattern(characterPatternId);
    }
    if (markId && !mark) {
      showMark(markId);
    }
  }

  public render(): React.ReactNode {
    const {navigation, onSelectPosition} = this.props;
    const sceneFrame = this.generateSceneFrame();
    return (
      <DimensionContext.Consumer>
        {context => {
          return (
            <Layout
              title={'立ち位置'}
              scrollable={scrollableLayout(context)}
              navigation={navigation}
              back={true}
              close={true}>
              <CharacterPositionList
                sceneFrame={sceneFrame}
                width={context.content.width}
                onSelectPosition={onSelectPosition}
              />
            </Layout>
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private generateSceneFrame(): SceneFrame {
    const {sceneCommandForms} = this.state;
    const {sceneCommandIndex} = this.props.route.params;
    return generateSceneFrame(sceneCommandForms, sceneCommandIndex);
  }
}
