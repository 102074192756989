import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';
import PreviewBox from '../../shared/cover_image_form/PreviewBox';

import NavigationProp from '../../../navigators/NavigationProp';
import {EditStoryFormCoverImageRouteProp} from '../../../navigators/RouteProps';

import {isFulfilledEpisode} from '../../../view_models/navigateStoryPublication';

import * as routers from '../../../routers';

import {Params as StoryUpdateParams} from '../../../actions/stories/update';
import {Params as StoryFormCreateParams} from '../../../actions/story_forms/create';
import {Params as StoryFormUpdateParams} from '../../../actions/story_forms/update';
import {Params as CoverImageFormCreateParams} from '../../../actions/cover_image_forms/create';
import {Params as CoverImageFormUpdateParams} from '../../../actions/cover_image_forms/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import File from '../../../../domain/entities/File';
import Story from '../../../../domain/entities/Story';
import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import StoryForm from '../../../../domain/forms/StoryForm';
import CoverImageForm from '../../../../domain/forms/CoverImageForm';

export interface Params {
  storyId: number;
  episodeIdToBePublished?: number;
  skipTitleValidation?: boolean;
  back?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditStoryFormCoverImageRouteProp;
  story: Story | null;
  storyForm: StoryForm | null;
  coverImageForm: CoverImageForm | null;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  showStory: (id: number) => Promise<Story>;
  updateStory: (id: number, params: StoryUpdateParams) => Promise<Story>;
  createStoryForm: (params: StoryFormCreateParams) => Promise<any>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
  createCoverImageForm: (params: CoverImageFormCreateParams) => Promise<any>;
  updateCoverImageForm: (params: CoverImageFormUpdateParams) => Promise<any>;
  showCurrentUser: () => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
  fetchedBackgroundImageUri: boolean;
  backgroundImageUri: string | null;
}

export default class Edit extends React.PureComponent<Props, State> {
  private ref = React.createRef<PreviewBox>();

  private attachedCoverImageBackgroundUri: string | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      fetchedBackgroundImageUri: false,
      backgroundImageUri: null,
    };
  }

  public componentDidMount() {
    const {route, storyForm, showStory, showCurrentUser} = this.props;
    const {storyId, episodeIdToBePublished} = route.params;
    if (!storyId) {
      return;
    }
    if (episodeIdToBePublished && storyForm && storyForm.id === storyId) {
      return;
    }
    showStory(storyId).then(this.handleCreateStoryForm);
    showCurrentUser();
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {storyForm, coverImageForm, currentUser, updateStoryForm} =
      this.props;
    if (
      coverImageForm &&
      prevProps.coverImageForm &&
      coverImageForm !== prevProps.coverImageForm
    ) {
      updateStoryForm({
        coverImage: coverImageForm.image,
        hasCoverImage: coverImageForm.hasImage,
        coverImageCharacterUri: coverImageForm.characterImageUri,
        coverImageBackgroundUri: coverImageForm.backgroundImageUri,
        coverImageTextInfoList: coverImageForm.imageTextInfoList,
        uploadedSelfCoverImage: coverImageForm.uploadedSelfImage,
      });
    }
    if (
      currentUser &&
      currentUser.isNotGradeBlack() &&
      storyForm?.coverImageBackgroundUri !==
        prevProps.storyForm?.coverImageBackgroundUri &&
      storyForm?.coverImageBackgroundUri !==
        this.attachedCoverImageBackgroundUri
    ) {
      this.attachedCoverImageBackgroundUri = null;
      updateStoryForm({coverIllustrator: null});
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, storyForm, currentUser} = this.props;
    const {
      loading,
      fetchedBackgroundImageUri,
      backgroundImageUri,
      alertMessage,
    } = this.state;
    const {back} = route.params;
    return (
      <Layout
        title={'表紙'}
        navigation={navigation}
        back={back}
        close={true}
        hideHeaderBottomBorder={true}
        loading={loading}>
        {storyForm && (
          <Form
            storyForm={storyForm}
            buttonName={
              currentUser && currentUser.isNotGradeBlack() ? '保存' : undefined
            }
            onChangeImage={this.handleChangeImage}
            onChangeCoverIllustrator={
              this.editableCoverIllustrator()
                ? this.handleChangeCoverIllustrator
                : undefined
            }
            onSubmit={this.handleSubmit}
            onRequestReset={this.handleRequestReset}
            onForwardToImageSelection={this.handleForwardToImageSelection}
            onForwardToCharacterImageSelection={
              this.handleForwardToCharacterImageSelection
            }
            onForwardToTextInput={this.handleForwardToTextInput}
          />
        )}
        {storyForm && fetchedBackgroundImageUri && (
          <PreviewBox
            ref={this.ref}
            backgroundImageUri={backgroundImageUri}
            characterImageUri={storyForm.coverImageCharacterUri}
            aspectRatio={aspectRatio}
            imageTextInfoList={storyForm.coverImageTextInfoList || []}
            onDrawCanvas={this.handleDrawCanvas}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private editableCoverIllustrator = () => {
    const {storyForm, currentUser} = this.props;
    if (!(currentUser && currentUser.isNotGradeBlack())) {
      return false;
    }
    if (storyForm?.coverIllustrator) {
      return true;
    }
    if (
      this.attachedCoverImageBackgroundUri &&
      this.attachedCoverImageBackgroundUri ===
        storyForm?.coverImageBackgroundUri
    ) {
      return true;
    }
    return false;
  };

  private handleChangeImage = (coverImage: File) => {
    this.attachedCoverImageBackgroundUri = coverImage.uri;
    this.setupCoverImageForm().then(() => {
      this.setState({
        fetchedBackgroundImageUri: true,
        backgroundImageUri: coverImage.uri,
      });
    });
  };

  private handleChangeCoverIllustrator = (coverIllustrator: string) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({coverIllustrator});
  };

  private handleDrawCanvas = (image: File | null) => {
    const {updateCoverImageForm} = this.props;
    const {backgroundImageUri} = this.state;
    if (!this.ref.current) {
      return;
    }
    if (image) {
      updateCoverImageForm({
        image,
        backgroundImageUri: backgroundImageUri,
        uploadedSelfImage: true,
      });
      this.setState({fetchedBackgroundImageUri: false});
    }
  };

  private handleSubmit = () => {
    const {navigation, route, storyForm, currentUser, updateStory} = this.props;
    const {storyId, episodeIdToBePublished, skipTitleValidation} = route.params
      ? route.params
      : {
          storyId: undefined,
          episodeIdToBePublished: undefined,
          skipTitleValidation: undefined,
        };
    if (!storyForm || !storyId || !currentUser) {
      return;
    }
    if (currentUser.isNotGradeBlack()) {
      const {title, ...restParams} = storyForm.toParams();
      const params = skipTitleValidation ? restParams : {title, ...restParams};
      this.setState({loading: true}, () => {
        updateStory(storyId, params)
          .then(() => {
            this.setState({loading: false}, async () => {
              (navigation.getParent() || navigation).goBack();
              if (episodeIdToBePublished) {
                if (await isFulfilledEpisode(episodeIdToBePublished)) {
                  routers.linkToEpisodeFormEditEpisodePublication(navigation, {
                    episodeId: episodeIdToBePublished,
                  });
                } else {
                  routers.linkToEditEpisode(navigation, {
                    episodeId: episodeIdToBePublished,
                    toBePublished: true,
                  });
                }
              }
            });
          })
          .catch((error: any) => {
            this.setState({
              loading: false,
              alertMessage: formatErrorMessages({}, error),
            });
          });
      });
    } else {
      routers.linkToEditStoryFormImage(navigation, {
        storyId,
        episodeIdToBePublished,
        skipTitleValidation,
      });
    }
  };

  private handleCreateStoryForm = (story: Story) => {
    const {createStoryForm} = this.props;
    const {
      id,
      title,
      introduction,
      catchPhrase,
      serialized,
      hasImage,
      originalImageUrl,
      hasCoverImage,
      originalCoverImageUrl,
      coverIllustrator,
      rating,
      format,
    } = story;
    const mainGenreId = story.getMainGenreId();
    const subGenreIds = story.getSubGenreIds();
    const keywordNames = story.keywords.map(keyword => keyword.name);
    const params = {
      catchPhrase,
      hasCoverImage,
      hasImage,
      id,
      introduction,
      keywordNames,
      mainGenreId,
      originalCoverImageUrl,
      originalImageUrl,
      serialized,
      subGenreIds,
      title,
      coverIllustrator,
      rating,
      format,
    };
    createStoryForm(params).then(this.setupCoverImageForm);
  };

  private handleRequestReset = () => {
    const {currentUser, updateStoryForm, updateCoverImageForm} = this.props;
    this.attachedCoverImageBackgroundUri = null;
    if (currentUser && currentUser.isNotGradeBlack()) {
      updateStoryForm({coverIllustrator: null});
    }
    updateCoverImageForm({
      type: 'coverImage',
      image: null,
      hasImage: null,
      originalImageUrl: null,
      backgroundImageUri: null,
      characterImageUri: null,
      imageTextInfoList: null,
      uploadedSelfImage: false,
    });
  };

  private handleForwardToImageSelection = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    this.setupCoverImageForm().then(() => {
      (navigation as any).navigate('CoverImageNavigation', {
        screen: 'CoverImageFormBackgroundUsageHistories',
        params: {storyId, aspectRatio, imageKey: 'coverImage'},
      });
    });
  };

  private handleForwardToCharacterImageSelection = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    this.setupCoverImageForm().then(() => {
      (navigation as any).navigate('CoverImageNavigation', {
        screen: 'CoverImageFormCharacterPatterns',
        params: {storyId, aspectRatio, imageKey: 'coverImage'},
      });
    });
  };

  private handleForwardToTextInput = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    this.setupCoverImageForm().then(() => {
      (navigation as any).navigate('CoverImageNavigation', {
        screen: 'CoverImageFormText',
        params: {storyId, aspectRatio, imageKey: 'coverImage'},
      });
    });
  };

  private setupCoverImageForm = async () => {
    const {storyForm, createCoverImageForm} = this.props;
    if (!storyForm) {
      return;
    }
    await createCoverImageForm({
      type: 'coverImage',
      image: storyForm.coverImage,
      hasImage: storyForm.hasCoverImage,
      originalImageUrl: storyForm.originalCoverImageUrl,
      backgroundImageUri: storyForm.coverImageBackgroundUri,
      characterImageUri: storyForm.coverImageCharacterUri,
      uploadedSelfImage: storyForm.uploadedSelfCoverImage,
      imageTextInfoList: storyForm.coverImageTextInfoList,
    });
  };
}

const aspectRatio = 2480 / 3508;
