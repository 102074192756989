import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import New, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/full_screen_illustration_show_scene_command/new/full_screen_illustrations/New';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../../../../actions/scene_command_forms/create';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import {SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustrationRouteProp} from '../../../../../navigators/RouteProps';

import indexFullScreenIllustrations, {
  Params as FullScreenIllustrationIndexParams,
} from '../../../../../actions/full_screen_illustrations/index';
import createFullScreenIllustration, {
  Params as FullScreenIllustrationCreateParams,
} from '../../../../../actions/full_screen_illustrations/create';

import selectEntities from '../../../../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
  route: SceneFormFullScreenIllustrationShowSceneCommandNewNewFullScreenIllustrationRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {parentSceneCommandId, storyId} = route.params;
  const sceneForm = forms.sceneForm;
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  const fullScreenIllustrationsParams: FullScreenIllustrationIndexParams = {
    storyId,
  };
  const fullScreenIllustrations = selectEntities(
    entities.fullScreenIllustrations,
    queries.fullScreenIllustrations,
    fullScreenIllustrationsParams,
  );
  return {
    sceneForm,
    navigation,
    fullScreenIllustrationsParams,
    fullScreenIllustrations,
    parentSceneCommandForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexFullScreenIllustrations: (params: FullScreenIllustrationIndexParams) =>
      dispatch(indexFullScreenIllustrations(params)),
    createFullScreenIllustration: (
      params: FullScreenIllustrationCreateParams,
    ) => dispatch(createFullScreenIllustration(params)),
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
