import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Select from '../../../../shared/forms/Select';

interface Props {
  items: Array<{label: string; value: number}>;
  value: number;
  label: string;
  onValueChange: (itemValue: string | number, itemPosition: number) => void;
}

const SelectWithLabel: React.FunctionComponent<Props> = props => {
  const {items, value, label, onValueChange} = props;
  return (
    <View style={styles.container}>
      <Select
        style={styles.select}
        pickerStyle={styles.selectPicker}
        items={items}
        value={value}
        confirmText={'決定'}
        cancelText={'キャンセル'}
        onValueChange={onValueChange}
        rightIcon={<View style={styles.arrow} />}
      />
      <View style={styles.labelWrapper}>
        <Text style={styles.label}>{label}</Text>
      </View>
    </View>
  );
};

export default React.memo(SelectWithLabel);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: 12,
  } as ViewStyle,
  select: {
    width: 121,
    height: 32,
  } as ViewStyle,
  selectPicker: {
    height: 30,
  } as ViewStyle,
  labelWrapper: {
    justifyContent: 'center',
    marginLeft: 16,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 15,
  } as TextStyle,
  arrow: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  } as ViewStyle,
});
