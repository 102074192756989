import EntityMapper from './EntityMapper';

import BaseBackground from '../../../domain/entities/BaseBackground';

export interface BaseBackgroundData {
  id: number;
  name: string;
  original_image_url: string;
}

export default class BackgroundMapper
  implements EntityMapper<BaseBackgroundData, BaseBackground>
{
  public map(obj: BaseBackgroundData): BaseBackground {
    return new BaseBackground(obj.id, obj.name, obj.original_image_url);
  }
}
