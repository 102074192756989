import * as React from 'react';

import Icon from './Icon';

import {colors} from '../../../styles/variables';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const PlusIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 9;
  const color = props.color || colors.gray;
  return <Icon name={'plus'} size={size} color={color} />;
};

export default React.memo(PlusIcon);
