import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import AnalyticsParticularEventMapper, {
  AnalyticsParticularEventData,
} from '../../entities/mappers/writer/AnalyticsParticularEventMapper';

import AnalyticsParticularEvent from '../../../domain/entities/writer/AnalyticsParticularEvent';
import AnalyticsParticularEventsRepository from '../../../domain/repositories/writer/AnalyticsParticularEventsRepository';

export default class NetAnalyticsParticularEventsRepository
  extends NetResourcesRepository<
    AnalyticsParticularEvent,
    AnalyticsParticularEventData
  >
  implements AnalyticsParticularEventsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/analytics_particular_events',
        new AnalyticsParticularEventMapper(),
      ),
    );
  }

  public create(
    params: {
      analyticsParticularEventParams: {
        resourceType: string;
        resourceId: number;
        actionName: string;
      };
    },
    options?: {[key: string]: any},
  ): Promise<AnalyticsParticularEvent> {
    return super.create(params, options);
  }
}
