import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ScaleModal from './ScaleModal';

import PrimaryButton from '../buttons/PrimaryButton';
import LinkButton from '../buttons/LinkButton';
import AlertIcon from '../icons/AlertIcon';
import DimensionContext from '../dimension/DimensionContext';

interface Props {
  visible: boolean;
  children?: React.ReactNode;
  onAccept: () => void;
  onRequestClose: () => void;
}

const WarningModal: React.FunctionComponent<Props> = props => {
  const {visible, children, onAccept, onRequestClose} = props;
  return (
    <DimensionContext.Consumer>
      {context => {
        return (
          <ScaleModal
            style={{width: context.content.width * 0.82}}
            visible={visible}>
            <View style={styles.modal}>
              <View style={styles.icon}>
                <AlertIcon size={32} color={'#ff8f13'} />
              </View>
              <Text style={styles.content}>{children}</Text>
              <View style={styles.buttons}>
                <LinkButton
                  buttonSize={BUTTON_SIZE}
                  style={styles.button}
                  onPress={onRequestClose}>
                  キャンセル
                </LinkButton>
                <PrimaryButton
                  buttonSize={BUTTON_SIZE}
                  style={styles.button}
                  onPress={onAccept}>
                  OK
                </PrimaryButton>
              </View>
            </View>
          </ScaleModal>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(WarningModal);

const BUTTON_SIZE = {width: 120, height: 46};

const styles = StyleSheet.create({
  modal: {
    margin: 16,
  } as ViewStyle,
  icon: {
    marginTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  content: {
    color: '#222222',
    fontSize: 13,
    lineHeight: 20,
    marginVertical: 16,
    justifyContent: 'center',
    textAlign: 'center',
  } as TextStyle,
  buttons: {
    flexDirection: 'row',
    marginBottom: 16,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
});
