import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import BackgroundUsageHistory from '../../../domain/entities/BackgroundUsageHistory';

export default function reducer(
  state: {[key: number]: BackgroundUsageHistory} = {},
  action: AnyAction,
): {[key: number]: BackgroundUsageHistory} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexBackgroundUsageHistoriesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (
            obj: {[key: number]: BackgroundUsageHistory},
            record: BackgroundUsageHistory,
          ) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.UpdateBackgroundUsageHistorySuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
