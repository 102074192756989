import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import SceneWithCommandsWrapper from '../../../domain/entities/SceneWithCommandsWrapper';

import NetSceneWithCommandsWrappersRepository from '../../../data/repositories/writer/NetSceneWithCommandsWrappersRepository';

const types = {
  failure: Types.ShowSceneWithCommandsWrapperFailure,
  request: Types.ShowSceneWithCommandsWrapperRequest,
  success: Types.ShowSceneWithCommandsWrapperSuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<SceneWithCommandsWrapper> {
  return showGeneric(new NetSceneWithCommandsWrappersRepository(), types, id);
}
