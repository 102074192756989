import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Character from '../../../domain/entities/Character';

import NetCharactersRepository from '../../../data/repositories/writer/NetCharactersRepository';

export interface Params {
  published?: boolean;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Character> {
  return async (dispatch: Dispatch<any>): Promise<Character> => {
    try {
      const payload = await new NetCharactersRepository().update(id, {
        character: params,
      });
      dispatch({payload, type: Types.UpdateCharacterSuccess});
      sendDefaultCustomEvent({
        resourceType: 'character_publication',
        resourceId: id,
        actionName: payload.published ? 'create' : 'destroy',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateCharacterFailure});
      throw err;
    }
  };
}
