import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import StoryList from './partials/StoryList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {FullVideoStoriesRouteProp} from '../../../navigators/RouteProps';

import Story from '../../../../domain/entities/writer/vertical_short_video/Story';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  orientation?: 'vertical' | 'horizontal';
}

export interface StateProps {
  navigation: NavigationProp;
  route: FullVideoStoriesRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {orientation} = route.params;
  const [stories, setStories] = React.useState<Array<Story> | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelRestApi.get('/api/writer/full_video/stories').then(response => {
        setStories(camelizeKeys(response.body as any) as Array<Story>);
      });
    }, []),
  );
  const onSelectStory = React.useCallback((story: Story) => {
    navigation.navigate('FullVideoStory', {storyId: story.id, orientation});
  }, []);
  return (
    <Layout
      title={'動画化するストーリーを選択'}
      navigation={navigation}
      back={true}>
      <StoryList stories={stories} onSelectStory={onSelectStory} />
    </Layout>
  );
};

export default React.memo(Index);
