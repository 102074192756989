import EntityMapper from './EntityMapper';

import Effect from '../../../domain/entities/Effect';

import PositionedEffectMapper, {
  PositionedEffectData,
} from './PositionedEffectMapper';

export interface EffectData {
  id: number;
  base_effect_id: number;
  name: string;
  original_image_url: string;
  center_positioned_effect: PositionedEffectData;
}

export default class EffectMapper implements EntityMapper<EffectData, Effect> {
  private positionedEffectMapper = new PositionedEffectMapper();

  public map(obj: EffectData): Effect {
    return new Effect(
      obj.id,
      obj.base_effect_id,
      obj.name,
      obj.original_image_url,
      this.positionedEffectMapper.map(obj.center_positioned_effect),
    );
  }
}
