import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import App, {DispatchProps, StateProps} from '../components/App';

import AppState from '../reducers/AppState';

import showCurrentUser, {
  Params as CurrentUserShowParams,
} from '../actions/writer/current_user/show';
import showAccessToken from '../actions/access_token/show';
import updateApplicationModal, {
  Params as ApplicationModalUpdateParams,
} from '../actions/application_modal/update';
import updateApplicationActionModal, {
  Params as ApplicationActionModalUpdateParams,
} from '../actions/application_action_modal/update';

import selectEntity from '../helpers/selectEntity';

interface Props {}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {entities, modals, accessToken, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const {applicationModal, applicationActionModal, coachmarkModal} = modals;
  return {
    accessToken,
    currentUser,
    currentUserStatus,
    applicationModal,
    applicationActionModal,
    coachmarkModal,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUser: (params?: CurrentUserShowParams) =>
      dispatch(showCurrentUser(params)),
    showAccessToken: () => dispatch(showAccessToken()),
    updateApplicationModal: (params: ApplicationModalUpdateParams) =>
      dispatch(updateApplicationModal(params)),
    updateApplicationActionModal: (
      params: ApplicationActionModalUpdateParams,
    ) => dispatch(updateApplicationActionModal(params)),
    dispatch,
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(App);
