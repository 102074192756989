import {Dispatch} from 'redux';

import Types from '../Types';

import File from '../../../domain/entities/File';

import ImageTextInfo from '../../../domain/value_objects/ImageTextInfo';

export interface Params {
  type?: 'coverImage' | 'image' | 'videoImage' | null;
  hasImage?: boolean | null;
  originalImageUrl?: string | null;
  image?: File | null;
  backgroundImageUri?: string | null;
  characterImageUri?: string | null;
  uploadedSelfImage?: boolean | null;
  imageTextInfoList?: ImageTextInfo[] | null;
}

export default function action(payload: Params) {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      payload,
      type: Types.UpdateCoverImageForm,
    });
    return new Promise(resolve => resolve(payload));
  };
}
