import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import BottomNewCharacterButton from './BottomNewCharacterButton';
import BottomNewCharacterPatternButton from './BottomNewCharacterPatternButton';

interface Props {
  onForwardToNewCharacterPattern: () => void;
  onForwardToNewCharacter: () => void;
}

const BottomButtons: React.FunctionComponent<Props> = props => {
  const {onForwardToNewCharacterPattern, onForwardToNewCharacter} = props;
  return (
    <View style={styles.container}>
      <BottomNewCharacterPatternButton
        onPress={onForwardToNewCharacterPattern}
      />
      <BottomNewCharacterButton onPress={onForwardToNewCharacter} />
    </View>
  );
};

export default React.memo(BottomButtons);

const styles = StyleSheet.create({
  container: {
    left: 0,
    right: 0,
    position: 'absolute',
    bottom: 0,
    paddingVertical: 16,
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
  } as ViewStyle,
});
