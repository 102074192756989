import * as React from 'react';
import {InteractionManager} from 'react-native';

import SelectedEffectImage from './partials/SelectedEffectImage';
import EffectList from './partials/EffectList';

import Layout from '../../../../shared/Layout';
import HeaderRightButton from '../../../../shared/buttons/HeaderRightButton';
import DimensionContext from '../../../../shared/dimension/DimensionContext';
import ResourceFavoriteButton from '../../../../shared/ResourceFavoriteButton';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormEffectShowSceneCommandNewEffectsRouteProp,
  SceneFormEffectShowSceneCommandEditEffectsRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as EffectIndexParams} from '../../../../../actions/effects/index';

import {backgroundImageUrl} from '../../../../../helpers/images';
import scrollableLayout from '../../../../../helpers/scrollableLayout';

import Background from '../../../../../../domain/entities/Background';
import Effect from '../../../../../../domain/entities/Effect';
import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import SceneForm, {Filter} from '../../../../../../domain/forms/SceneForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

import fetchBlob from '../../../../../../data/data_stores/net/FetchBlob';

export interface Props {
  navigation: NavigationProp;
  route:
    | SceneFormEffectShowSceneCommandNewEffectsRouteProp
    | SceneFormEffectShowSceneCommandEditEffectsRouteProp;
  effectsParams: EffectIndexParams;
  baseEffect: BaseEffect | null;
  effects: Effect[] | null;
  sceneForm: SceneForm | null;
  enabledFavorite?: boolean;
  indexEffects: (params: EffectIndexParams) => Promise<PaginatedResult<Effect>>;
  showBaseEffect: (id: number) => Promise<BaseEffect>;
  onSubmit: (effect: Effect) => void;
}

interface State {
  uri: string | null;
  selectedEffect: Effect | null;
  selectedFilter?: Filter;
}

const aspectRatio = 1.25;

export default class Index extends React.PureComponent<Props, State> {
  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (prevState.selectedEffect === null && nextProps.effects) {
      return {selectedEffect: nextProps.effects[0]};
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedEffect: null,
      selectedFilter:
        props.sceneForm && props.sceneForm.options
          ? props.sceneForm.options.filter
          : undefined,
      uri: null,
    };
  }

  public componentDidMount() {
    const {
      route,
      effectsParams,
      baseEffect,
      effects,
      indexEffects,
      showBaseEffect,
    } = this.props;
    const {baseEffectId} = route.params;
    if (!baseEffect) {
      showBaseEffect(baseEffectId);
    }
    if (!effects) {
      indexEffects(effectsParams);
    }
    if (this.props.sceneForm && this.props.sceneForm.background) {
      const {background} = this.props.sceneForm;
      InteractionManager.runAfterInteractions(() => {
        this.fetchBlob(background).then(uri => {
          this.setState({uri});
        });
      });
    }
  }

  public render(): React.ReactNode {
    const {navigation, baseEffect, effects, enabledFavorite} = this.props;
    const {uri, selectedEffect, selectedFilter} = this.state;

    const title = baseEffect ? baseEffect.name : '';
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={title}
            scrollable={scrollableLayout(context)}
            navigation={navigation}
            back={true}
            rightButton={{
              tintColor: 'white',
              title: (
                <HeaderRightButton onPress={this.handleSubmit}>
                  決定
                </HeaderRightButton>
              ),
            }}>
            {selectedEffect && (
              <SelectedEffectImage
                selectedEffect={selectedEffect}
                uri={uri}
                filter={selectedFilter}
              />
            )}
            {effects && (
              <EffectList
                effects={effects}
                uri={uri}
                selectedEffect={selectedEffect}
                onSelectEffect={this.handleSelectEffect}
              />
            )}
            {enabledFavorite && baseEffect && (
              <ResourceFavoriteButton
                style={{top: 10, right: 10}}
                resourceType={'BaseEffect'}
                resourceId={baseEffect.id}
              />
            )}
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private handleSelectEffect = (selectedEffect: Effect) => {
    this.setState({selectedEffect});
  };

  private handleSubmit = () => {
    const {onSubmit} = this.props;
    const {selectedEffect} = this.state;
    if (selectedEffect) {
      onSubmit(selectedEffect);
    }
  };

  private async fetchBlob(background: Background): Promise<string> {
    const url = backgroundImageUrl(background, 'large');
    return fetchBlob(url);
  }
}
