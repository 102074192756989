import * as React from 'react';

import BaseIndex from '../../../../shared/generics/character_maker/actors/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {CharacterFormNewCharacterMakerActorsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as ActorCharacterFaceIndexParams} from '../../../../../actions/actor_character_faces/index';
import {Params as CharacterFormUpdateParams} from '../../../../../actions/character_forms/update';

import ActorCharacter from '../../../../../../domain/entities/ActorCharacter';
import ActorCharacterFace from '../../../../../../domain/entities/ActorCharacterFace';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

export interface Params {
  characterMakerActorGroupId: number;
  skinColorPatternId: number;
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CharacterFormNewCharacterMakerActorsRouteProp;
}

export interface DispatchProps {
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  updateCharacterForm: (params: CharacterFormUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, route, indexActorCharacterFaces, updateCharacterForm} =
    props;
  const {characterMakerActorGroupId, skinColorPatternId} = route.params;
  const onSelectActorCharacter = React.useCallback(
    (actorCharacter: ActorCharacter) => {
      const actorCharacterId = actorCharacter.id;
      indexActorCharacterFaces({actorCharacterId}).then(result => {
        const actorCharacterFace = result.records[0];
        const actorCharacterFaceId = actorCharacterFace?.id;
        const actorCharacterFaceName = actorCharacterFace?.faceName;
        updateCharacterForm({
          actorCharacterId,
          actorCharacterFaceId,
          actorCharacterFaceName,
        });
        navigation.getParent()?.goBack();
      });
    },
    [],
  );
  return (
    <BaseIndex
      navigation={navigation}
      characterMakerActorGroupId={characterMakerActorGroupId}
      skinColorPatternId={skinColorPatternId}
      onSelectActorCharacter={onSelectActorCharacter}
    />
  );
};

export default React.memo(Index);
