import SoundMapper, {SoundData} from '../../../entities/mappers/SoundMapper';

import BackgroundMusicHideSceneCommand from '../../../../domain/value_objects/scene_commands/BackgroundMusicHideSceneCommand';

export interface BackgroundMusicHideSceneCommandData {
  type: string;
  sound: SoundData;
}

export default class BackgroundMusicHideSceneCommandMapper {
  private soundMapper: SoundMapper;

  constructor() {
    this.soundMapper = new SoundMapper();
  }

  public map(
    obj: BackgroundMusicHideSceneCommandData,
  ): BackgroundMusicHideSceneCommand {
    const sound = this.soundMapper.map(obj.sound);
    return new BackgroundMusicHideSceneCommand(sound);
  }
}
