import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';

import commercialVideoKeyvisuallUri from '../../../../../assets/svgs/commercial-video-keyvisual.svg';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep1: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Text style={styles.message}>
          TapNovelMakerでつくった作品の{'\n'}
          CM動画を作成することができます。
        </Text>
        <View style={styles.keyVisual}>
          <SvgUriAdapter
            uri={commercialVideoKeyvisuallUri}
            width={295}
            height={151}
          />
        </View>
        <PrimaryButton onPress={onPress}>
          説明を読んでCM動画を作成する
        </PrimaryButton>
      </ScrollView>
    </View>
  );
};

export default React.memo(ServiceExplanationStep1);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
  } as ViewStyle,
  scrollView: {
    paddingVertical: 32,
    marginBottom: 32,
    alignItems: 'center',
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  keyVisual: {
    marginVertical: 24,
  } as ViewStyle,
});
