import * as React from 'react';
import {Platform, StyleSheet, ViewStyle} from 'react-native';

import StoryListItemContent from './StoryListItemContent';
import NoStories from './NoStories';

import shouldUpdateStoryList from '../../shared/enhanced/shouldUpdateStoryList';
import DefaultList from '../../shared/lists/DefaultList';

import Story from '../../../../domain/entities/Story';

interface Props {
  stories: Story[];
  type: string;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  onSelectStory: (story: Story) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  onOpenActionSheet?: (story: Story) => void;
}

export default class StoryList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return shouldUpdateStoryList(this.props, nextProps);
  }

  public render(): React.ReactNode {
    const {
      stories,
      type,
      ListHeaderComponent,
      ListFooterComponent,
      onSelectStory,
      onEndReached,
    } = this.props;
    if (stories.length === 0) {
      return <NoStories type={type} />;
    }
    return (
      <DefaultList
        style={styles.container}
        data={stories}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={ListFooterComponent}
        onEndReachedThreshold={0.8}
        renderCustom={this.renderCustom}
        onSelectItem={onSelectStory}
        onEndReached={onEndReached}
      />
    );
  }

  private renderCustom = (item: Story) => {
    const {onOpenActionSheet} = this.props;
    return (
      <StoryListItemContent
        story={item}
        onOpenActionSheet={onOpenActionSheet}
      />
    );
  };
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    paddingBottom: 90,
    marginBottom: Platform.select({web: 0, default: 90}),
  } as ViewStyle,
});
