import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics/index';

import ContactCategory from '../../../../domain/entities/writer/ContactCategory';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetContactCategoriesRepository from '../../../../data/repositories/writer/NetContactCategoriesRepository';

const types = {
  failure: Types.IndexWriterContactCategoriesFailure,
  request: Types.IndexWriterContactCategoriesRequest,
  success: Types.IndexWriterContactCategoriesSuccess,
};

export interface Params extends BaseParams {}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<ContactCategory>> {
  return indexGenerics(new NetContactCategoriesRepository(), types, params);
}
