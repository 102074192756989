import * as React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ScaleModal from '../../shared/modals/ScaleModal';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../shared/svg/SvgUriAdapter';
import convertImageSource from '../../../helpers/convertImageSource';

import extensionActivationBgUri from '../../../../assets/svgs/extension-activation-bg.svg';
import extensionActivationTitleUri from '../../../../assets/images/extensions/extension-activation-title.png';

interface Props {
  visible: boolean;
  title: string;
  description: React.ReactNode | string;
  source?: ImageSourcePropType;
  imageStyle?: ImageStyle;
  onCloseModal: () => void;
}

const ExtensionActivationModal: React.FunctionComponent<Props> = props => {
  const {visible, title, description, source, imageStyle, onCloseModal} = props;
  return (
    <ScaleModal
      style={{width: 311}}
      visible={visible}
      onCloseModal={onCloseModal}>
      <View style={styles.modal}>
        <SvgUriAdapter
          uri={extensionActivationBgUri}
          width={311}
          height={110}
        />
        <View
          style={{
            position: 'absolute',
            top: 31,
            left: 0,
            right: 0,
            alignItems: 'center',
          }}>
          <Image
            style={{
              width: 275,
              height: 29,
            }}
            source={convertImageSource(extensionActivationTitleUri)}
          />
          <Text style={styles.title}>{title}</Text>
        </View>
        <Text style={styles.description}>{description}</Text>
        <View style={styles.image}>
          {source ? (
            <Image style={imageStyle} source={source} resizeMode={'contain'} />
          ) : null}
        </View>
        <View style={styles.button}>
          <PrimaryButton buttonSize={BUTTON_SIZE} onPress={onCloseModal}>
            OK
          </PrimaryButton>
        </View>
      </View>
    </ScaleModal>
  );
};

export default React.memo(ExtensionActivationModal);

const BUTTON_SIZE = {width: 160, height: 46};

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  modal: {
    marginBottom: 24,
    marginHorizontal: 16,
  } as ViewStyle,
  title: {
    color: '#ff8f13',
    fontSize: 15,
    fontWeight: 'bold',
    marginTop: 19,
    alignSelf: 'center',
  } as TextStyle,
  description: {
    color: '#222222',
    fontSize: 12,
    lineHeight: 20,
    marginVertical: 8,
    marginHorizontal: 16,
  } as TextStyle,
  image: {
    alignItems: 'center',
    marginVertical: 8,
  } as ViewStyle,
});
