import * as React from 'react';
import {InteractionManager, ScrollView, View} from 'react-native';

import EffectCategorySelect from './partials/EffectCategorySelect';
import SearchInput from './partials/SearchInput';
import SearchButtonWithTotalCount from './partials/SearchButtonWithTotalCount';

import PopularTagsBox from '../../../../shared/PopularTagsBox';
import Layout from '../../../../shared/Layout';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormEffectShowSceneCommandNewBaseEffectSearchFormRouteProp,
  SceneFormEffectShowSceneCommandEditBaseEffectSearchFormRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as EffectCategoryIndexParams} from '../../../../../actions/effect_categories/index';
import {Params as BaseEffectIndexParams} from '../../../../../actions/base_effects/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import EffectCategory from '../../../../../../domain/entities/EffectCategory';
import PopularTag from '../../../../../../domain/entities/PopularTag';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import NetPopularTagsRepository from '../../../../../../data/repositories/writer/NetPopularTagsRepository';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  selectedLargeEffectCategoryId?: number | null;
  selectedMiddleEffectCategoryId?: number | null;
  selectedSmallEffectCategoryId?: number | null;
  favorite?: boolean;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormEffectShowSceneCommandNewBaseEffectSearchFormRouteProp
    | SceneFormEffectShowSceneCommandEditBaseEffectSearchFormRouteProp;
  sceneForm: SceneForm | null;
  largeEffectCategoriesParams: EffectCategoryIndexParams;
  largeEffectCategories: EffectCategory[] | null;
  middleEffectCategoriesParams: EffectCategoryIndexParams;
  middleEffectCategories: EffectCategory[] | null;
  smallEffectCategoriesParams: EffectCategoryIndexParams;
  smallEffectCategories: EffectCategory[] | null;
}

export interface DispatchProps {
  indexEffectCategories: (
    params: EffectCategoryIndexParams,
  ) => Promise<PaginatedResult<EffectCategory>>;
  indexBaseEffects: (
    params: BaseEffectIndexParams,
  ) => Promise<PaginatedResult<BaseEffect>>;
}

interface Props extends StateProps, DispatchProps {
  onSubmit: (params: {query?: string; effectCategoryId?: number}) => void;
}

interface State {
  popularTags?: Array<PopularTag> | null;
  totalCount: number;
  query: string;
}

export default class Index extends React.Component<Props, State> {
  private timerId: any | null = null;

  constructor(props: Props) {
    super(props);
    const {query} = props.route.params;
    this.state = {
      totalCount: 0,
      query: query || '',
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      largeEffectCategories,
      largeEffectCategoriesParams,
      indexEffectCategories,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    InteractionManager.runAfterInteractions(() => {
      new NetPopularTagsRepository()
        .findBy({taggableType: 'Effect'})
        .then(result => {
          this.setState({popularTags: result.records});
        });
      if (!largeEffectCategories) {
        indexEffectCategories(largeEffectCategoriesParams);
      }
      this.fetchTotalCount();
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {
      route,
      indexEffectCategories,
      middleEffectCategoriesParams,
      smallEffectCategoriesParams,
    } = this.props;
    const {
      selectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId,
    } = route.params;
    const {
      selectedLargeEffectCategoryId: prevSelectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId: prevSelectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId: prevSelectedSmallEffectCategoryId,
    } = prevProps.route.params;
    let updated = false;
    if (selectedLargeEffectCategoryId !== prevSelectedLargeEffectCategoryId) {
      indexEffectCategories(middleEffectCategoriesParams);
      updated = true;
    }
    if (selectedMiddleEffectCategoryId !== prevSelectedMiddleEffectCategoryId) {
      indexEffectCategories(smallEffectCategoriesParams);
      updated = true;
    }
    if (selectedSmallEffectCategoryId !== prevSelectedSmallEffectCategoryId) {
      updated = true;
    }
    if (updated) {
      this.fetchTotalCount();
    }
  }

  public render(): React.ReactNode {
    const {
      navigation,
      route,
      largeEffectCategories,
      middleEffectCategories,
      smallEffectCategories,
    } = this.props;
    const {query, totalCount, popularTags} = this.state;
    const {
      selectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId,
      hideBack,
    } = route.params;
    return (
      <Layout
        title={'エフェクト検索条件'}
        back={hideBack ? false : true}
        close={true}
        navigation={navigation}
        scrollable={false}>
        <ScrollView>
          {largeEffectCategories && popularTags && (
            <>
              <SearchInput query={query} onChangeText={this.handleChangeText} />
              <PopularTagsBox
                popularTags={popularTags}
                onSelectPopularTag={this.handleSelectPopularTag}
              />
              <View style={{marginHorizontal: 16, marginTop: 16}}>
                <LabelWithOption title={'カテゴリ'} />
              </View>
              <EffectCategorySelect
                value={selectedLargeEffectCategoryId || 0}
                effectCategories={largeEffectCategories}
                onValueChange={this.handleValueChangeForLargeEffectCategoryId}
              />
              <EffectCategorySelect
                value={selectedMiddleEffectCategoryId || 0}
                effectCategories={middleEffectCategories}
                onValueChange={this.handleValueChangeForMiddleEffectCategoryId}
              />
              <EffectCategorySelect
                value={selectedSmallEffectCategoryId || 0}
                effectCategories={smallEffectCategories}
                onValueChange={this.handleValueChangeForSmallEffectCategoryId}
              />
            </>
          )}
        </ScrollView>
        <SearchButtonWithTotalCount
          totalCount={totalCount}
          onPress={this.handleSubmit}
        />
      </Layout>
    );
  }

  private handleChangeText = (query: string) => {
    this.setStateAndFetchTotalCount({query});
  };

  private handleValueChangeForLargeEffectCategoryId = (
    selectedLargeEffectCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({
      selectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId: null,
      selectedSmallEffectCategoryId: null,
    });
  };

  private handleValueChangeForMiddleEffectCategoryId = (
    selectedMiddleEffectCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({
      selectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId: null,
    });
  };

  private handleValueChangeForSmallEffectCategoryId = (
    selectedSmallEffectCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({selectedSmallEffectCategoryId});
  };

  private setStateAndFetchTotalCount<K extends keyof State>(
    state: Pick<State, K>,
  ): void {
    this.setState(state, () => {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.fetchTotalCount();
      }, 250);
    });
  }

  private fetchTotalCount = () => {
    const {indexBaseEffects} = this.props;
    indexBaseEffects({...this.buildSearchParams(), perPage: 1}).then(
      searchResult => {
        this.setState({totalCount: searchResult.total});
      },
    );
  };

  private buildSearchParams = () => {
    const {route} = this.props;
    const {query} = this.state;
    const {
      selectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId,
      favorite,
    } = route.params;
    return {
      query,
      favorite,
      exceptBackground: true,
      allEffectCategoryId:
        this.covertEffectCategoryId(selectedSmallEffectCategoryId) ||
        this.covertEffectCategoryId(selectedMiddleEffectCategoryId) ||
        this.covertEffectCategoryId(selectedLargeEffectCategoryId) ||
        undefined,
    };
  };

  private handleSubmit = () => {
    const {route, onSubmit} = this.props;
    const {
      selectedLargeEffectCategoryId,
      selectedMiddleEffectCategoryId,
      selectedSmallEffectCategoryId,
    } = route.params;
    const {query} = this.state;
    onSubmit({
      query,
      effectCategoryId:
        this.covertEffectCategoryId(selectedSmallEffectCategoryId) ||
        this.covertEffectCategoryId(selectedMiddleEffectCategoryId) ||
        this.covertEffectCategoryId(selectedLargeEffectCategoryId) ||
        undefined,
    });
  };

  private covertEffectCategoryId = (
    effectCategoryId: number | null | undefined,
  ) => {
    if (effectCategoryId && Number(effectCategoryId) != 0) {
      return Number(effectCategoryId);
    }
    return undefined;
  };

  private handleSelectPopularTag = (popularTag: PopularTag) => {
    const {onSubmit} = this.props;
    onSubmit({query: popularTag.tagName});
  };
}
