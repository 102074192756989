import * as React from 'react';
import {CommonActions} from '@react-navigation/native';

import BaseIndex, {
  FormParams,
} from '../../../../../shared/generics/character_form/character_names/Index';
import AlertModal from '../../../../../shared/modals/AlertModal';

import NavigationProp from '../../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNamesRouteProp} from '../../../../../../navigators/RouteProps';

import {Params as CharacterIndexParams} from '../../../../../../actions/character_patterns/index';
import {Params as CharacterCreateParams} from '../../../../../../actions/characters/create';

import {formatErrorMessages} from '../../../../../../helpers/errorMessages';
import redirectSceneForm from '../../../../../../helpers/redirectSceneForm';

import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';
import Character from '../../../../../../../domain/entities/Character';
import CharacterPattern from '../../../../../../../domain/entities/CharacterPattern';
import PaginatedResult from '../../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  actorCharacterId: number;
  inverted?: boolean;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterFormCharacterNamesRouteProp;
  sceneForm: SceneForm | null;
  actorCharacter: ActorCharacter | null;
}

export interface DispatchProps {
  createCharacter: (params: CharacterCreateParams) => Promise<Character>;
  indexCharacterPatterns: (
    params: CharacterIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  showActorCharacter: (id: number) => Promise<ActorCharacter>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {loading, alertMessage} = this.state;
    return (
      <>
        <BaseIndex
          {...this.props}
          loading={loading}
          onSubmit={this.handleSubmit}
        />
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </>
    );
  }

  private handleSubmit = (formParams: FormParams) => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {navigation, route, createCharacter, indexCharacterPatterns} =
      this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      actorCharacterId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
      inverted,
    } = route.params;
    const {name} = formParams;
    this.setState({loading: true}, () => {
      createCharacter({actorCharacterId, name, storyId, inverted})
        .then(character => {
          const characterId = character.id;
          const characterPatternId = character.defaultCharacterPatternId;
          indexCharacterPatterns({storyId}).then(() => {
            this.setState({loading: false}, () => {
              navigation.dispatch(
                CommonActions.reset({
                  routes: [
                    {
                      name: 'SceneFormCharacterShowSceneCommandNewCharacterPatterns',
                      params: {
                        storyId,
                        episodeId,
                        sceneId,
                        parentSceneCommandId,
                        position,
                      },
                    },
                    {
                      name: 'SceneFormCharacterShowSceneCommandNewActorCharacterFaces',
                      params: {
                        episodeId,
                        sceneId,
                        characterId,
                        characterPatternId,
                        sceneCommandIndex,
                        subSceneCommandIndex,
                        parentSceneCommandId,
                        position,
                      },
                    },
                  ],
                }),
              );
            });
          });
        })
        .catch(error => {
          this.setState(
            {loading: false, alertMessage: formatErrorMessages({}, error)},
            () => {
              this.disabledSubmit = false;
            },
          );
        });
    });
  };
}
