import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import {colors} from '../../../styles/variables';

import ListItemWideSeparator from '../ListItemWideSeparator';

interface Props {
  disableNextScene?: boolean;
  disablePrevScene?: boolean;
  onPressNextScene?: () => void;
  onPressPrevScene?: () => void;
  onPressFinish: () => void;
}

const MenuCommands: React.FunctionComponent<Props> = props => {
  const {
    disableNextScene,
    disablePrevScene,
    onPressNextScene,
    onPressPrevScene,
    onPressFinish,
  } = props;
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={disableNextScene ? undefined : onPressNextScene}>
        <View style={styles.command}>
          <Text
            style={[
              styles.commandText,
              disableNextScene ? styles.commandTextDisabled : null,
            ]}>
            次のシーンへ
          </Text>
        </View>
      </TouchableOpacity>
      <ListItemWideSeparator />
      <TouchableOpacity
        style={{flex: 1}}
        onPress={disablePrevScene ? undefined : onPressPrevScene}>
        <View style={styles.command}>
          <Text
            style={[
              styles.commandText,
              disablePrevScene ? styles.commandTextDisabled : null,
            ]}>
            前のシーンへ
          </Text>
        </View>
      </TouchableOpacity>
      <ListItemWideSeparator />
      <TouchableOpacity style={{flex: 1}} onPress={onPressFinish}>
        <View style={styles.command}>
          <Text style={styles.commandText}>ストーリーを閉じる</Text>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(MenuCommands);

const styles = StyleSheet.create({
  command: {
    flex: 1,
    justifyContent: 'center',
    marginHorizontal: 16,
  } as ViewStyle,
  commandText: {
    color: colors.textColor,
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  commandTextDisabled: {
    color: colors.lightGray,
  } as TextStyle,
  commandWrapper: {
    flex: 1,
  } as ViewStyle,
  container: {
    height: 159,
    width: '100%',
  } as ViewStyle,
});
