import * as React from 'react';
import {InteractionManager} from 'react-native';

import EffectPositionList from './partials/EffectPositionList';

import Layout from '../../../../shared/Layout';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormEffectShowSceneCommandNewEffectPositionsRouteProp,
  SceneFormEffectShowSceneCommandEditEffectPositionsRouteProp,
} from '../../../../../navigators/RouteProps';

import {backgroundImageUrl} from '../../../../../helpers/images';
import scrollableLayout from '../../../../../helpers/scrollableLayout';

import Background from '../../../../../../domain/entities/Background';
import Effect from '../../../../../../domain/entities/Effect';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import Position from '../../../../../../domain/value_objects/Position';

import fetchBlob from '../../../../../../data/data_stores/net/FetchBlob';

export interface Props {
  navigation: NavigationProp;
  route:
    | SceneFormEffectShowSceneCommandNewEffectPositionsRouteProp
    | SceneFormEffectShowSceneCommandEditEffectPositionsRouteProp;
  effect: Effect | null;
  sceneForm: SceneForm | null;
  showEffect: (id: number) => Promise<Effect>;
  onSubmit: (position: Position) => void;
}

interface State {}

const aspectRatio = 1.25;

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedEffect: null,
      selectedFilter:
        props.sceneForm && props.sceneForm.options
          ? props.sceneForm.options.filter
          : undefined,
      uri: null,
    };
  }

  public componentDidMount() {
    const {route, effect, showEffect} = this.props;
    const {effectId} = route.params;
    if (!effect) {
      showEffect(effectId);
    }
    if (this.props.sceneForm && this.props.sceneForm.background) {
      const {background} = this.props.sceneForm;
      InteractionManager.runAfterInteractions(() => {
        this.fetchBlob(background).then(uri => {
          this.setState({uri});
        });
      });
    }
  }

  public render(): React.ReactNode {
    const {navigation, effect, onSubmit} = this.props;
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={'位置指定'}
            scrollable={scrollableLayout(context)}
            navigation={navigation}
            back={true}
            close={true}>
            <EffectPositionList
              width={context.content.width}
              onSelectPosition={onSubmit}
            />
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private async fetchBlob(background: Background): Promise<string> {
    const url = backgroundImageUrl(background, 'large');
    return fetchBlob(url);
  }
}
