import React from 'react';

import RootStack from '../../../../RootStack';
import {DefaultTransition} from '../../../../BaseNavigator';

import SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects from '../../../../../containers/scene_form/background_show_scene_command/new/background_base_effects/Index'; // tslint:disable-next-line:max-line-length
import SceneFormBackgroundShowSceneCommandNewBackgroundEffects from '../../../../../containers/scene_form/background_show_scene_command/new/background_effects/Index'; // tslint:disable-next-line:max-line-length

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects">
      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects"
        component={SceneFormBackgroundShowSceneCommandNewBackgroundBaseEffects}
        options={{title: '背景エフェクト'}}
      />
      <RootStack.Screen
        name="SceneFormBackgroundShowSceneCommandNewBackgroundEffects"
        component={SceneFormBackgroundShowSceneCommandNewBackgroundEffects}
        options={{title: '背景エフェクト'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
