import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import OauthAccountMapper, {
  OauthAccountData,
} from '../../entities/mappers/OauthAccountMapper';

import OauthAccount from '../../../domain/entities/OauthAccount';
import OauthAccountsRepository from '../../../domain/repositories/writer/OauthAccountsRepository';

export default class NetOauthAccountsRepository
  extends NetResourcesRepository<OauthAccount, OauthAccountData>
  implements OauthAccountsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/oauth_accounts',
        new OauthAccountMapper(),
      ),
    );
  }
}
