import EntityMapper from './EntityMapper';

import ActorCharacterMapper, {ActorCharacterData} from './ActorCharacterMapper';

import GenderMapper, {
  GenderData,
} from '../../value_objects/mappers/GenderMapper';

import Actor from '../../../domain/entities/Actor';

export interface ActorData {
  id: number;
  user_id: number | null;
  name: string;
  actor_category_id: number;
  original_image_url: string;
  actor_character: ActorCharacterData;
  gender: GenderData;
  contest_ids: Array<number>;
  price: number | null;
  amount: number | null;
  maker_pro_only?: boolean;
  options: {allowed_inversion?: boolean};
}

export default class ActorMapper implements EntityMapper<ActorData, Actor> {
  private actorCharacterMapper = new ActorCharacterMapper();
  private genderMapper = new GenderMapper();

  public map(obj: ActorData): Actor {
    const actorCharacter = this.actorCharacterMapper.map(obj.actor_character);
    const gender = this.genderMapper.map(obj.gender);
    return new Actor(
      obj.id,
      obj.user_id,
      obj.name,
      obj.actor_category_id,
      obj.original_image_url,
      actorCharacter,
      gender,
      obj.contest_ids || [],
      obj.price,
      obj.amount,
      !!obj.maker_pro_only,
      {allowedInversion: obj.options?.allowed_inversion},
    );
  }
}
