import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import Icon from '../../../../shared/icons/Icon';
import Genre from '../../../../../../domain/entities/Genre';

interface Props {
  genre: Genre;
}

const GenreListItem: React.FC<Props> = props => {
  const {genre} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(`/projects/search?genre_id=${genre.id}`);
  }, []);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <View style={styles.icon}>
        <Icon name={`genre-${genre.code}`} size={35} color={'#ff8f13'} />
      </View>
      <Text style={styles.text}>{genre.name}</Text>
    </TouchableOpacity>
  );
};

export default React.memo(GenreListItem);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  } as ViewStyle,
  icon: {
    width: 48,
    height: 48,
    margin: 4,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#999',
    fontSize: 9,
    fontWeight: 'bold',
  } as TextStyle,
});
