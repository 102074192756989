import * as React from 'react';

import InfoForm from './partials/InfoForm';

import Layout from '../shared/Layout';
import AlertModal from '../shared/modals/AlertModal';

import NavigationProp from '../../navigators/NavigationProp';
import {ShowSceneRouteProp} from '../../navigators/RouteProps';

import {Params as SceneUpdateParams} from '../../actions/scenes/update';

import {formatErrorMessages} from '../../helpers/errorMessages';

import Scene from '../../../domain/entities/Scene';

export interface Params {
  sceneId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ShowSceneRouteProp;
  scene: Scene | null;
}

export interface DispatchProps {
  showScene: (id: number) => Promise<Scene>;
  updateScene: (id: number, params: SceneUpdateParams) => Promise<Scene>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class Show extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public componentDidMount() {
    const {route, showScene} = this.props;
    const {sceneId} = route.params;
    showScene(sceneId);
  }

  public render(): React.ReactNode {
    const {navigation, scene} = this.props;
    const {loading, alertMessage} = this.state;
    return (
      <Layout
        title={`シーン${scene ? scene.numberOfEpisode : ''}の情報`}
        navigation={navigation}
        scrollable={false}
        close={true}
        loading={loading}>
        {scene && (
          <InfoForm title={scene.title || ''} onSubmit={this.handleSubmit} />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleSubmit = (params: {title: string}) => {
    const {navigation, scene, updateScene} = this.props;
    if (!scene) {
      return;
    }
    this.setState({loading: true}, () => {
      updateScene(scene.id, params)
        .then(() => {
          this.setState({loading: false});
          navigation.goBack();
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };
}
