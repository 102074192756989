import * as React from 'react';
import {ImageStyle, StyleSheet} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';

import {fullScreenIllustrationImageUrl} from '../../../../../../helpers/images';

import FullScreenIllustration from '../../../../../../../domain/entities/FullScreenIllustration';

interface Props {
  fullScreenIllustration: FullScreenIllustration;
  children?: React.ReactNode;
}

const FullScreenIllustrationView: React.FunctionComponent<Props> = props => {
  const {fullScreenIllustration} = props;
  return (
    <FastImageAdapter
      resizeMode={'contain'}
      style={styles.image}
      source={{
        uri: fullScreenIllustrationImageUrl(fullScreenIllustration, 'large'),
        headers: {Accept: 'image/webp,image/apng,*/*'},
      }}
    />
  );
};

export default React.memo(FullScreenIllustrationView);

const width = 200;
const height = 283;
const aspectRatio = width / height;

const styles = StyleSheet.create({
  image: {
    aspectRatio,
    height,
    width,
  } as ImageStyle,
});
