import * as React from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputSelectionChangeEventData,
  View,
  ViewStyle,
} from 'react-native';

import BaseSceneCommandModal from '../BaseSceneCommandModal';

import DescriptiveTextViewWithEditor, {
  TabValue,
} from './DescriptiveTextViewWithEditor';

import DeleteButton from '../buttons/DeleteButton';

import {ModalParams} from '../../../Modal';

import CompositeSequenceSceneCommandFormFactory from '../../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import {addSymbolToTextAndCalcSelection} from '../../../../../../helpers/textInputHelper';

import TextFrame from '../../../../../../../domain/entities/TextFrame';
import Voice from '../../../../../../../domain/entities/Voice';
import Sound from '../../../../../../../domain/entities/Sound';
import SceneCommandForm from '../../../../../../../domain/forms/scene_commands/SceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import TextNormalizer from '../../../../../../../domain/helpers/TextNormalizer';

interface Props {
  storyId: number;
  sceneCommandForm: DescriptiveTextShowSceneCommandForm;
  textFrames: TextFrame[];
  enableVoice?: boolean;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestCloseModal: () => void;
  onRemoveDescriptiveTextShowCommand: (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
  ) => void;
  onChangeDescriptiveTextShowSceneCommandForm: (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
  ) => void;
  onRequestIndexTextFrames: () => void;
  onChangeCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
  onForwardToAddSoundEffects: (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
    parentSceneCommandId?: number,
    callback?: (sound: Sound) => void,
  ) => void;
  onRequestUpdateModal: (modalParams: ModalParams) => void;
}

interface State {
  currentTab: TabValue;
  selectedText: string;
  selectedTextFrame: TextFrame;
  selectedVoice: Voice | null;
  selectedSound: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
  selection?: {
    start: number;
    end: number;
  };
}

export default class DescriptiveTextViewWithEditorModal extends React.PureComponent<
  Props,
  State
> {
  private currentSelection:
    | {
        start: number;
        end: number;
      }
    | undefined = undefined;

  private textInputRef: React.RefObject<TextInput>;

  constructor(props: Props) {
    super(props);
    const {sceneCommandForm} = props;
    const textLength = sceneCommandForm.text.length;
    this.state = {
      currentTab: 'text',
      selectedText: sceneCommandForm.text,
      selectedTextFrame: sceneCommandForm.textFrame,
      selectedVoice: sceneCommandForm.voice,
      selectedSound: sceneCommandForm.sound,
      selectedSoundStartTime: sceneCommandForm.startTime,
      selectedSoundEndTime: sceneCommandForm.endTime,
      selection: {start: textLength, end: textLength},
    };
    this.textInputRef = React.createRef<TextInput>();
  }

  public render(): React.ReactNode {
    const {
      storyId,
      sceneCommandForm,
      textFrames,
      enableVoice,
      onRequestCloseModal,
    } = this.props;
    const {
      currentTab,
      selectedText,
      selectedTextFrame,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      selection,
    } = this.state;
    return (
      <BaseSceneCommandModal
        title={'テキストの編集'}
        onRequestCloseModal={onRequestCloseModal}
        footer={
          <View style={styles.footer}>
            <DeleteButton onPress={this.destroySceneCommandForm} />
          </View>
        }>
        <DescriptiveTextViewWithEditor
          storyId={storyId}
          sceneCommandForm={sceneCommandForm}
          textFrames={textFrames}
          enableVoice={enableVoice}
          textInputRef={this.textInputRef}
          currentTab={currentTab}
          selectedText={selectedText}
          selectedTextFrame={selectedTextFrame}
          selectedVoice={selectedVoice}
          selectedSound={selectedSound}
          selectedSoundStartTime={selectedSoundStartTime}
          selectedSoundEndTime={selectedSoundEndTime}
          selection={selection}
          onSubmit={this.handleSubmit}
          onChangeTab={this.handleChangeTab}
          onSelectText={this.handleSelectText}
          onPressSymbol={this.handlePressSymbol}
          onSelectTextFrame={this.handleSelectTextFrame}
          onSelectionChange={this.handleSelectionChange}
          onUpdateVoice={this.handleUpdateVoice}
          addSound={this.addSound}
          deleteSound={this.deleteSound}
          onChagenSoundRegion={this.handleChangeSoundRegion}
        />
      </BaseSceneCommandModal>
    );
  }

  private destroySceneCommandForm = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onRequestCloseModal,
      onRemoveDescriptiveTextShowCommand,
      onChangeCommand,
      onRemoveCommand,
    } = this.props;
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.remove(
          parentSceneCommandForm,
          sceneCommandForm,
        );
      if (newParentSceneCommandForm) {
        onChangeCommand(newParentSceneCommandForm);
      } else {
        onRemoveCommand(parentSceneCommandForm);
      }
    } else {
      onRemoveDescriptiveTextShowCommand(sceneCommandForm);
    }
    onRequestCloseModal();
  };

  private handleSubmit = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      onChangeDescriptiveTextShowSceneCommandForm,
      onRequestCloseModal,
      onChangeCommand,
    } = this.props;
    const {
      selectedTextFrame,
      selectedText,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
    } = this.state;
    const nextText = TextNormalizer.normalize(selectedText, 'descriptive_text');
    const newSceneCommandForm = new DescriptiveTextShowSceneCommandForm(
      selectedTextFrame,
      nextText,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime || null,
      selectedSoundEndTime || null,
      sceneCommandForm.sceneCommandId,
    );
    if (parentSceneCommandForm) {
      const newParentSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          newSceneCommandForm,
        );
      onChangeCommand(newParentSceneCommandForm);
    } else {
      onChangeDescriptiveTextShowSceneCommandForm(newSceneCommandForm);
    }
    onRequestCloseModal();
  };

  private handleChangeTab = (currentTab: TabValue) => {
    this.setState({currentTab});
  };

  private handleSelectText = (selectedText: string) => {
    this.setState({selectedText});
  };

  private handlePressSymbol = (symbol: string) => {
    const {selectedText} = this.state;
    const {text, selection} = addSymbolToTextAndCalcSelection(
      selectedText,
      symbol,
      this.currentSelection,
    );
    this.setState({selectedText: text, selection});
    this.textInputRef.current?.focus();
  };

  private handleUpdateVoice = (voice: Voice | null) => {
    this.setState({selectedVoice: voice});
  };

  private handleSelectionChange = (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => {
    if (this.state.selection) {
      this.setState({selection: undefined});
    }
    this.currentSelection = e.nativeEvent.selection;
  };

  private handleSelectTextFrame = (selectedTextFrame: TextFrame) => {
    this.setState({
      selectedTextFrame,
    });
  };

  private addSound = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,

      onForwardToAddSoundEffects,
    } = this.props;
    onForwardToAddSoundEffects(
      sceneCommandForm,
      parentSceneCommandForm?.sceneCommandId,
      (sound: Sound) => {
        this.setState({selectedSound: sound});
      },
    );
  };

  private deleteSound = () => {
    this.setState({
      selectedSound: null,
      selectedSoundStartTime: null,
      selectedSoundEndTime: null,
    });
  };

  private handleChangeSoundRegion = (startTime?: number, endTime?: number) => {
    this.setState({
      selectedSoundStartTime: startTime || null,
      selectedSoundEndTime: endTime || null,
    });
  };
}

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  } as ViewStyle,
  footer: {
    marginVertical: 5,
    padding: 10,
  } as ViewStyle,
});
