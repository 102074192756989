import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import Headline from '../../../../../shared/Headline';
import EntityImageGridListWithModal from '../../../../../shared/EntityImageGridListWithModal';

import {effectUsageHistoryImageUrl} from '../../../../../../helpers/images';

import EffectUsageHistory from '../../../../../../../domain/entities/EffectUsageHistory';

const IMAGE_SIZE = 'middle';

interface Props {
  effectUsageHistories: EffectUsageHistory[];
  onSelectEffectUsageHistory: (effectUsageHistory: EffectUsageHistory) => void;
  onForwardToFavoriteList?: () => void;
  modalRenderFavoriteButton?: (item: EffectUsageHistory) => React.ReactNode;
}

export default class EffectUsageHistoryList extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      effectUsageHistories,
      onSelectEffectUsageHistory,
      onForwardToFavoriteList,
      modalRenderFavoriteButton,
    } = this.props;
    return (
      <>
        <Headline
          title={'使用したエフェクト'}
          onPressFavoriteList={onForwardToFavoriteList}
        />
        <EntityImageGridListWithModal
          style={styles.container}
          entities={effectUsageHistories}
          aspectRatio={ASPECT_RATIO}
          imageMaskStyle={styles.imageMaskStyle}
          containerStyle={styles.containerStyle}
          imageAspectRatio={1}
          frame={false}
          ListFooterComponent={ListFooterComponent}
          onSelectEntity={onSelectEffectUsageHistory}
          imageUrlExtractor={this.imageUrlExtractor}
          labelExtractor={this.labelExtractor}
          modalRenderFavoriteButton={modalRenderFavoriteButton}
        />
      </>
    );
  }

  private imageUrlExtractor = (
    effectUsageHistory: EffectUsageHistory,
    width: number,
  ) => {
    return effectUsageHistoryImageUrl(effectUsageHistory, IMAGE_SIZE);
  };

  private labelExtractor = (effectUsageHistory: EffectUsageHistory) => {
    return effectUsageHistory.baseEffect.name;
  };
}

const ASPECT_RATIO = 1.25;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    flex: 1,
    paddingBottom: Platform.select({web: 75, default: undefined}),
  } as ViewStyle,
  imageMaskStyle: {
    backgroundColor: '#666',
  } as ViewStyle,
  containerStyle: {
    marginBottom: 8,
  } as ViewStyle,
  spacer: {
    height: 75,
    width: '100%',
  } as ViewStyle,
});

const ListFooterComponent = Platform.select({
  web: undefined,
  default: <View style={styles.spacer} />,
});
