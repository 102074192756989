import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ManuscriptClapButton from './ManuscriptClapButton';

import ProjectManuscript from '../../../../../domain/entities/ProjectManuscript';

interface Props {
  manuscript: ProjectManuscript;
  disabledLike?: boolean;
}

const DetailIdea: React.FC<Props> = props => {
  const {manuscript, disabledLike} = props;
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <Text style={styles.title}>エピソードタイトル</Text>
        <Text style={styles.content}>{manuscript.title}</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.title}>企画のあらまし</Text>
        <Text style={styles.content}>{manuscript.outlinePlan}</Text>
      </View>
      {manuscript.highlightOfStory ? (
        <View style={styles.section}>
          <Text style={styles.title}>作者の思う見どころ</Text>
          <Text style={styles.content}>{manuscript.highlightOfStory}</Text>
        </View>
      ) : null}
      <ManuscriptClapButton
        manuscript={manuscript}
        disabledLike={disabledLike}
      />
    </View>
  );
};

export default React.memo(DetailIdea);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingHorizontal: 16,
  } as ViewStyle,
  section: {
    marginVertical: 16,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
  content: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
});
