import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import MemberJoinRequestList from './MemberJoinRequestList';
import MemberJoinRequestListHeader from './MemberJoinRequestListHeader';
import EpisodeReleaseRequestList from './EpisodeReleaseRequestList';
import EpisodeReleaseRequestListHeader from './EpisodeReleaseRequestListHeader';

import ScrollableTabViewAdapter from '../../../shared/scrollable_tab_view/ScrollableTabViewAdapter';
import NoticeIcon from '../../../shared/NoticeIcon';

import ProjectMemberJoinRequest, {
  Status as ProjectMemberJoinRequestStatus,
} from '../../../../../domain/entities/ProjectMemberJoinRequest';
import ProjectEpisodeReleaseRequest, {
  Status as ProjectEpisodeReleaseRequestStatus,
} from '../../../../../domain/entities/ProjectEpisodeReleaseRequest';

interface Props {
  memberJoinRequests: ProjectMemberJoinRequest[];
  episodeReleaseRequests: ProjectEpisodeReleaseRequest[];
  memberJoinRequestStatus: ProjectMemberJoinRequestStatus;
  episodeReleaseRequestStatus: ProjectEpisodeReleaseRequestStatus;
  onForceUpdateMemberJoinRequests: () => void;
  onMemberJoinRequestStatusChange: (
    status: ProjectMemberJoinRequestStatus,
  ) => void;
  onEpisodeReleaseRequestStatusChange: (
    status: ProjectEpisodeReleaseRequestStatus,
  ) => void;
}

const RequestTabs: React.FC<Props> = props => {
  const {
    memberJoinRequests,
    episodeReleaseRequests,
    memberJoinRequestStatus,
    episodeReleaseRequestStatus,
    onForceUpdateMemberJoinRequests,
    onMemberJoinRequestStatusChange,
    onEpisodeReleaseRequestStatusChange,
  } = props;
  return (
    <>
      <ScrollableTabViewAdapter
        tabs={[
          {
            label: '参加申請',
            element: (
              <MemberJoinRequestList
                ListHeaderComponent={
                  <MemberJoinRequestListHeader
                    status={memberJoinRequestStatus}
                    onStatusChange={onMemberJoinRequestStatusChange}
                  />
                }
                memberJoinRequests={memberJoinRequests}
                onForceUpdate={onForceUpdateMemberJoinRequests}
              />
            ),
          },
          {
            label: 'エピソード公開申請',
            element: (
              <EpisodeReleaseRequestList
                ListHeaderComponent={
                  <EpisodeReleaseRequestListHeader
                    status={episodeReleaseRequestStatus}
                    onStatusChange={onEpisodeReleaseRequestStatusChange}
                  />
                }
                episodeReleaseRequests={episodeReleaseRequests}
              />
            ),
          },
        ]}
        locked={false}
      />
      <View style={styles.notices}>
        <View style={styles.notice}>
          {/**
          <NoticeIcon />
          */}
        </View>
        <View style={styles.notice}>
          {/**
          <NoticeIcon />
          */}
        </View>
      </View>
    </>
  );
};

export default React.memo(RequestTabs);

const styles = StyleSheet.create({
  notices: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 0,
    flexDirection: 'row',
  } as ViewStyle,
  notice: {
    flex: 1,
    alignItems: 'flex-end',
    margin: 8,
  } as ViewStyle,
});
