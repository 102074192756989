export default class AccountAggregation {
  constructor(
    public id: number,
    public from: Date | null,
    public to: Date | null,
    public penName: string,
    public publishedStoriesCount: number,
    public fannedConsumerUserCount: number,
    public episodePageViewCount: number,
    public reactionsCount: number,
    public commentsCount: number,
  ) {}
}
