import EntityMapper from './EntityMapper';

import BaseEffect from '../../../domain/entities/BaseEffect';

import EffectMapper, {EffectData} from './EffectMapper';

export interface BaseEffectData {
  id: number;
  name: string;
  original_image_url: string;
  effects_count: number;
  effect: EffectData;
}

export default class BaseEffectMapper
  implements EntityMapper<BaseEffectData, BaseEffect>
{
  private effectMapper = new EffectMapper();

  public map(obj: BaseEffectData): BaseEffect {
    return new BaseEffect(
      obj.id,
      obj.name,
      obj.original_image_url,
      obj.effects_count,
      this.effectMapper.map(obj.effect),
    );
  }
}
