import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../components/writer/user_upgrade_achievements/Show';

import AppState from '../../../reducers/AppState';

import {UserUpgradeAchievementRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: UserUpgradeAchievementRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  props: Props,
) => {
  return {};
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
