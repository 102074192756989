import {Dispatch} from 'redux';

import Types from '../../../../Types';

import OauthAccount from '../../../../../../domain/entities/OauthAccount';

import NetRegistrationRepository from '../../../../../../data/repositories/writer/social_accounts/facebook_accounts/NetRegistrationRepository';

export interface Params {
  accessToken: string;
  userId: string;
  screenName: string;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<OauthAccount> {
  return async (dispatch: Dispatch<any>): Promise<OauthAccount> => {
    try {
      const payload = await new NetRegistrationRepository().create({
        facebookAccount: params,
      });
      dispatch({
        payload,
        type: Types.CreateWriterOauthAccountSuccess,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateWriterOauthAccountFailure});
      throw err;
    }
  };
}
