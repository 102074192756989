import * as React from 'react';
import {
  GestureResponderEvent,
  TextStyle,
  StyleProp,
  ViewStyle,
} from 'react-native';

import BaseButton, {FontSizeClass} from './BaseButton';

interface Props {
  style?: StyleProp<ViewStyle>;
  buttonSize?: {width: number; height: number};
  fontSizeClass?: FontSizeClass;
  fontStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  unwrappedText?: boolean;
  children?: React.ReactNode;
  onPress: (e: GestureResponderEvent) => void;
  onPressIn?: (e: GestureResponderEvent) => void;
}

const DefaultOutlineButton: React.FunctionComponent<Props> = props => {
  const {
    style,
    buttonSize,
    fontSizeClass,
    fontStyle,
    disabled,
    unwrappedText,
    children,
    onPress,
    onPressIn,
  } = props;
  return (
    <BaseButton
      style={style}
      tintColor={'white'}
      buttonSize={buttonSize || DEFAULT_BUTTON_SIZE}
      fontSizeClass={fontSizeClass}
      fontStyle={fontStyle}
      disabled={disabled}
      unwrappedText={unwrappedText}
      onPress={onPress}
      onPressIn={onPressIn}>
      {children}
    </BaseButton>
  );
};

export default React.memo(DefaultOutlineButton);

const DEFAULT_BUTTON_SIZE = {
  width: 311,
  height: 46,
};
