import SceneCommand from './SceneCommand';

import Sound from '../../entities/Sound';

import BackgroundMusicHideSceneCommandForm from '../../forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class BackgroundMusicHideSceneCommand implements SceneCommand {
  constructor(public sound: Sound) {}

  public getValue() {
    return {
      sound_id: this.sound.id,
    };
  }

  public toForm(): SceneCommandForm {
    return new BackgroundMusicHideSceneCommandForm(this.sound);
  }
}
