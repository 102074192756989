import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import ActorCharacterFacePreview from '../components/ActorCharacterFacePreview';
import Form from './components/Form';
import FormModal from './components/FormModal';
import FormModalPointOnly from './components/FormModalPointOnly';

import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';
import {ShopActorCheckoutRouteProp} from '../../../../navigators/RouteProps';

import {
  sendDefaultCustomEvent,
  setUserId,
  getCurrentUserId,
} from '../../../../helpers/analytics';

import Actor from '../../../../../domain/entities/shop/Actor';
import PaymentMethod from '../../../../../domain/entities/writer/my_stripe/PaymentMethod';
import PaymentIntent from '../../../../../domain/entities/writer/my_stripe/PaymentIntent';
import UserResourcePurchase from '../../../../../domain/entities/writer/UserResourcePurchase';
import CurrentUserStatus from '../../../../../domain/entities/writer/CurrentUserStatus';
import UserPoint from '../../../../../domain/entities/writer/UserPoint';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

import NetUserActorPurchasesRepository from '../../../../../data/repositories/writer/NetUserActorPurchasesRepository';

export interface Params {
  actorId: number;
  disableNavigation?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ShopActorCheckoutRouteProp;
  currentUserStatus: CurrentUserStatus | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route, currentUserStatus} = props;
  const {actorId, disableNavigation} = route.params;
  const [actor, setActor] = React.useState<Actor | null>(null);
  const [userPoint, setUserPoint] = React.useState<UserPoint | null>(null);
  const [userResourcePurchase, setUserResourcePurchase] =
    React.useState<UserResourcePurchase | null>(null);
  const [consumptionPoint, setConsumptionPoint] = React.useState(0);
  React.useEffect(() => {
    TapNovelRestApi.get(`/api/writer/shop/actors/${actorId}`).then(res => {
      setActor(camelizeKeys(res.body) as Actor);
    });
  }, [actorId]);
  useFocusEffect(
    React.useCallback(() => {
      setUserResourcePurchase(null);
      new NetUserActorPurchasesRepository()
        .find(actorId)
        .then(userResourcePurchase => {
          setUserResourcePurchase(userResourcePurchase);
        });
    }, [actorId]),
  );
  React.useEffect(() => {
    TapNovelRestApi.get('/api/writer/user_point').then(response => {
      setUserPoint(camelizeKeys(response.body) as UserPoint);
    });
  }, []);
  const enabledPartnerProgram = currentUserStatus
    ? currentUserStatus.extensionsCodes.includes('partner_program') &&
      currentUserStatus.serviceAgreementCodes.includes('partner_program')
    : false;
  const onConfirm = React.useCallback(() => {
    setVisibleFormModal(true);
  }, []);
  const amount = actor ? calcAmount(actor, consumptionPoint) : 0;
  const onChangePoint = React.useCallback(
    (text: string) => {
      if (!userPoint) {
        return;
      }
      const nextConsumptionPoint = text ? Number.parseInt(text) : 0;
      const nextAmount = actor ? calcAmount(actor, nextConsumptionPoint) : 0;
      setPaymentIntent(null);
      if (nextConsumptionPoint <= userPoint.availablePoint) {
        if (nextAmount === 0 || nextAmount >= 50) {
          setConsumptionPoint(nextConsumptionPoint);
        }
      }
    },
    [actor, userPoint],
  );
  const [loading, setLoading] = React.useState(false);
  const [visibleFormModal, setVisibleFormModal] = React.useState(false);
  const [visibleFormModalPointOnly, setVisibleFormModalPointOnly] =
    React.useState(false);
  const [paymentMethod, setPaymentMethod] =
    React.useState<PaymentMethod | null>(null);
  const [paymentIntent, setPaymentIntent] =
    React.useState<PaymentIntent | null>(null);
  const onCloseFormModal = React.useCallback(() => {
    setVisibleFormModal(false);
  }, []);
  const onCloseFormModalPointOnly = React.useCallback(() => {
    setVisibleFormModalPointOnly(false);
  }, []);
  const onOpenFormCompleteModal = React.useCallback(() => {
    navigation.replace('ShopActorCheckoutComplete', {
      actorId,
      disableNavigation,
    });
  }, [actorId]);
  const success = React.useCallback(() => {
    onCloseFormModal();
    const intervalId = setInterval(() => {
      new NetUserActorPurchasesRepository()
        .find(actorId)
        .then(userResourcePurchase => {
          setUserResourcePurchase(userResourcePurchase);
          clearInterval(intervalId);
          setLoading(false);
          onOpenFormCompleteModal();
          const currentUserId = getCurrentUserId();
          setUserId(userResourcePurchase.resourceOwnerUserId);
          sendDefaultCustomEvent({
            resourceType: 'creator/actor_sale',
            resourceId: userResourcePurchase.id,
            actionName: 'create',
            amount: actor?.amount ? Number(actor.amount) : undefined,
            price: actor?.price ? Number(actor.price) : undefined,
            resourceOwnerUserId: userResourcePurchase.resourceOwnerUserId,
          });
          setUserId(currentUserId);
          sendDefaultCustomEvent({
            resourceType: 'writer/actor_purchase',
            resourceId: userResourcePurchase.id,
            actionName: 'create',
            amount: actor?.amount ? Number(actor.amount) : undefined,
            price: actor?.price ? Number(actor.price) : undefined,
            resourceOwnerUserId: userResourcePurchase.resourceOwnerUserId,
          });
        });
    }, 1000);
  }, [actor]);
  const fail = React.useCallback((e: any) => {
    if (e.body.errorCode === 'pay_by_all_point') {
      setVisibleFormModalPointOnly(true);
    }
    setLoading(false);
    setVisibleFormModal(false);
  }, []);
  const failPointOnly = React.useCallback((e: any) => {
    setLoading(false);
    setVisibleFormModalPointOnly(false);
  }, []);
  return (
    <Layout
      title={'購入確認'}
      navigation={navigation}
      back={true}
      containerStyle={{backgroundColor: '#fafafa'}}
      loading={loading}>
      {actor && (
        <>
          <ActorCharacterFacePreview actor={actor} selectedImage={actor} />
          <Form
            actor={actor}
            consumptionPoint={consumptionPoint}
            purchased={!!userResourcePurchase}
            userPoint={userPoint}
            enabledPartnerProgram={enabledPartnerProgram}
            amount={amount}
            onConfirm={onConfirm}
            onChangePoint={onChangePoint}
          />
        </>
      )}
      {visibleFormModal && actor?.resourceProduct && (
        <FormModal
          resourceProduct={actor.resourceProduct}
          paymentMethod={paymentMethod}
          paymentIntent={paymentIntent}
          consumptionPoint={consumptionPoint}
          setPaymentMethod={setPaymentMethod}
          setPaymentIntent={setPaymentIntent}
          onCloseModal={onCloseFormModal}
          setLoading={setLoading}
          success={success}
          fail={fail}
        />
      )}
      {visibleFormModalPointOnly && actor?.resourceProduct && (
        <FormModalPointOnly
          resourceProduct={actor.resourceProduct}
          consumptionPoint={consumptionPoint}
          onCloseModal={onCloseFormModalPointOnly}
          setLoading={setLoading}
          success={success}
          fail={failPointOnly}
        />
      )}
    </Layout>
  );
};

export default React.memo(Show);

const calcAmount = (actor: Actor, consumptionPoint: number) => {
  if (!actor.price) {
    return 0;
  }
  if (!consumptionPoint) {
    return actor.amount || 0;
  }
  const val = Number.parseInt(`${actor.price}`) - consumptionPoint;
  return val + Math.round((val * actor.jctTaxRatePercentage) / 100);
};
