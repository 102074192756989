import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import MetaCharacterPatternMapper, {
  MetaCharacterPatternData,
} from '../../entities/mappers/MetaCharacterPatternMapper';

import MetaCharacterPattern from '../../../domain/entities/MetaCharacterPattern';
import MetaCharacterPatternsRepository from '../../../domain/repositories/writer/MetaCharacterPatternsRepository';

export default class NetMetaCharacterPatternsRepository
  extends NetResourcesRepository<MetaCharacterPattern, MetaCharacterPatternData>
  implements MetaCharacterPatternsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/meta_character_patterns',
        new MetaCharacterPatternMapper(),
      ),
    );
  }
}
