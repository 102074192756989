import * as React from 'react';

import HelpList from './partials/HelpList';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';
import {HelpRouteProp} from '../../navigators/RouteProps';

import {Params as HelpIndexParams} from '../../actions/writer/helps';

import Help from '../../../domain/entities/writer/Help';
import HelpCategory from '../../../domain/entities/writer/HelpCategory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export interface Params {
  writerHelpCategoryId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: HelpRouteProp;
  helpCategory: HelpCategory | null;
  helps: Help[] | null;
  helpsParams: HelpIndexParams;
}

export interface DispatchProps {
  showHelpCategory: (id: number) => Promise<HelpCategory>;
  indexHelps: (params: HelpIndexParams) => Promise<PaginatedResult<Help>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  helpIdToCollapsed: {[key: string]: boolean};
}

export default class Show extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      helpIdToCollapsed: {},
    };
  }

  public componentDidMount() {
    const {
      route,
      helpCategory,
      helps,
      showHelpCategory,
      indexHelps,
      helpsParams,
    } = this.props;
    const {writerHelpCategoryId} = route.params;
    if (!helpCategory) {
      showHelpCategory(writerHelpCategoryId);
    }
    if (!helps) {
      indexHelps(helpsParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, helpCategory, helps} = this.props;
    const {helpIdToCollapsed} = this.state;
    return (
      <Layout
        title={helpCategory ? helpCategory.name : ''}
        navigation={navigation}
        scrollable={false}
        back={true}>
        {helps && (
          <HelpList
            helps={helps}
            helpIdToCollapsed={helpIdToCollapsed}
            onPressHelp={this.handlePressHelp}
          />
        )}
      </Layout>
    );
  }

  private handlePressHelp = (help: Help) => {
    const {helpIdToCollapsed} = this.state;
    const collapsed =
      helpIdToCollapsed[help.id] === undefined
        ? true
        : helpIdToCollapsed[help.id];
    this.setState({
      helpIdToCollapsed: {
        ...helpIdToCollapsed,
        [help.id]: !collapsed,
      },
    });
  };
}
