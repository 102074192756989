import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import BaseBackground from '../../../domain/entities/BaseBackground';

import NetBaseBackgroundsRepository from '../../../data/repositories/writer/NetBaseBackgroundsRepository';

const types = {
  failure: Types.ShowBaseBackgroundFailure,
  request: Types.ShowBaseBackgroundRequest,
  success: Types.ShowBaseBackgroundSuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<BaseBackground> {
  return showGeneric(new NetBaseBackgroundsRepository(), types, id);
}
