import * as React from 'react';
import {
  GestureResponderEvent,
  Image,
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

import {squareImageUrl} from '../../../../../../helpers/images';

import {colors} from '../../../../../../styles/variables';

import {Options} from '../../../../../../../domain/helpers/ImageUrlHelper';

const resizeMode = 'contain';
const headers = {
  Accept: 'image/webp,image/apng,*/*',
};

export interface Entity {
  id: number;
  getImageUrl(options: Options): string;
}

interface Props<E extends Entity> {
  entity: E;
  checked: boolean;
  onSelectEntity: (entity: E) => void;
}

export default class ImageListItem<
  E extends Entity,
> extends React.PureComponent<Props<E>> {
  private pageXOnPressIn: number | null = null;

  public render(): React.ReactNode {
    const {entity, checked} = this.props;
    return (
      <Pressable
        key={entity.id}
        style={checked ? styles.containerActive : styles.container}
        onPress={this.handlePress}
        onPressIn={this.handlePressIn}>
        {entity.id === 0 ? (
          <Text style={styles.label}>なし</Text>
        ) : (
          <Image
            resizeMode={resizeMode}
            style={styles.image}
            source={{
              uri: squareImageUrl(entity, IMAGE_SIZE),
              headers,
            }}
          />
        )}
      </Pressable>
    );
  }

  private handlePress = (e: GestureResponderEvent) => {
    const {entity, onSelectEntity} = this.props;
    const pageXOnPressIn = this.pageXOnPressIn;
    this.pageXOnPressIn = null;
    if (pageXOnPressIn && Math.abs(e.nativeEvent.pageX - pageXOnPressIn) >= 3) {
      return;
    }
    onSelectEntity(entity);
  };

  private handlePressIn = (e: GestureResponderEvent) => {
    this.pageXOnPressIn = e.nativeEvent.pageX;
  };
}

const IMAGE_SIZE = 30;

const container = {
  alignItems: 'center',
  backgroundColor: 'white',
  borderColor: '#efefef',
  borderRadius: 4,
  borderWidth: 1,
  justifyContent: 'center',
  marginHorizontal: 3,
  minWidth: 80,
  height: 46,
  paddingHorizontal: 16,
} as ViewStyle;

const styles = StyleSheet.create({
  container,
  containerActive: {
    ...container,
    borderColor: '#ff8f13',
  } as ViewStyle,
  label: {
    color: colors.gray,
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  } as ImageStyle,
});
