import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Edit, {DispatchProps, StateProps} from '../../components/episodes/Edit';

import AppState from '../../reducers/AppState';

import showEpisode from '../../actions/episodes/show';
import showStory from '../../actions/stories/show';
import updateEpisode, {
  Params as EpisodeUpdateParams,
} from '../../actions/episodes/update';
import showCurrentUser from '../../actions/writer/current_user/show';
import showCurrentUserStatus from '../../actions/writer/current_user_status/show';

import selectEntity from '../../helpers/selectEntity';

import {EditEpisodeRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EditEpisodeRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const {episodeId} = route.params || {episodeId: undefined};
  const episode = selectEntity(entities.episodes, episodeId);
  return {
    navigation,
    currentUser,
    currentUserStatus,
    episode,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showEpisode: (id: number) => dispatch(showEpisode(id)),
    showStory: (id: number) => dispatch(showStory(id)),
    updateEpisode: (id: number, params: EpisodeUpdateParams) =>
      dispatch(updateEpisode(id, params)),
    showCurrentUser: () => dispatch(showCurrentUser()),
    showCurrentUserStatus: () => dispatch(showCurrentUserStatus()),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
