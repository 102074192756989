import * as React from 'react';
import {camelizeKeys} from 'humps';
import {useFocusEffect} from '@react-navigation/native';

import CloseWindow from './components/CloseWindow';

import Layout from '../../shared/Layout';
import HeaderRightButton from '../../shared/buttons/HeaderRightButton';

import ActorCharacterFaceGridList from './components/ActorCharacterFaceGridList';
import ActorCharacterFacePreview from './components/ActorCharacterFacePreview';

import NavigationProp from '../../../navigators/NavigationProp';
import {ShopActorRouteProp} from '../../../navigators/RouteProps';

import Actor from '../../../../domain/entities/shop/Actor';
import ActorCharacterFace from '../../../../domain/entities/shop/ActorCharacterFace';
import UserResourcePurchase from '../../../../domain/entities/writer/UserResourcePurchase';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';
import NetUserActorPurchasesRepository from '../../../../data/repositories/writer/NetUserActorPurchasesRepository';

export interface Params {
  id: number;
  disableNavigation?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ShopActorRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {id, disableNavigation} = route.params;
  const [actor, setActor] = React.useState<Actor | null>(null);
  const [userResourcePurchase, setUserResourcePurchase] =
    React.useState<UserResourcePurchase | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      setUserResourcePurchase(null);
      new NetUserActorPurchasesRepository()
        .find(id)
        .then(userResourcePurchase => {
          setUserResourcePurchase(userResourcePurchase);
        });
    }, [id]),
  );
  const [actorCharacterFaces, setActorCharacterFaces] = React.useState<
    ActorCharacterFace[] | null
  >(null);
  const [selectedActorCharacterFace, setSelectedActorCharacterFace] =
    React.useState<ActorCharacterFace | null>(
      actorCharacterFaces ? actorCharacterFaces[0] || null : null,
    );
  React.useEffect(() => {
    TapNovelRestApi.get(`/api/writer/shop/actors/${id}`).then(res => {
      setActor(camelizeKeys(res.body) as Actor);
    });
  }, [id]);
  React.useEffect(() => {
    if (actor) {
      TapNovelRestApi.get(
        `/api/writer/shop/actor_character_faces?actor_id=${actor.id}`,
      ).then(res => {
        setActorCharacterFaces(camelizeKeys(res.body) as ActorCharacterFace[]);
      });
    }
  }, [actor]);
  React.useEffect(() => {
    setSelectedActorCharacterFace(
      actorCharacterFaces ? actorCharacterFaces[0] || null : null,
    );
  }, [actorCharacterFaces]);
  const onSelectActorCharacterFace = React.useCallback(
    (actorCharacterFace: ActorCharacterFace) => {
      setSelectedActorCharacterFace(actorCharacterFace);
    },
    [],
  );
  const onPress = React.useCallback(() => {
    navigation.navigate('ShopActorCheckout', {actorId: id, disableNavigation});
  }, [id]);
  const rightButton = React.useMemo(() => {
    return {
      tintColor: 'white',
      title: (
        <HeaderRightButton
          buttonSize={{width: 80, height: 32}}
          disabled={!actor?.price || !!userResourcePurchase}
          onPress={onPress}>
          {userResourcePurchase ? '購入済み' : '購入する'}
        </HeaderRightButton>
      ),
    };
  }, [actor, userResourcePurchase]);
  return (
    <Layout
      title={'表情差分'}
      navigation={navigation}
      back={disableNavigation ? false : true}
      rightButton={rightButton}
      footer={disableNavigation ? <CloseWindow /> : null}>
      {actor &&
        actor.creatorUser &&
        actorCharacterFaces &&
        selectedActorCharacterFace && (
          <>
            <ActorCharacterFacePreview
              actor={actor}
              selectedImage={selectedActorCharacterFace}
              withSeparator={true}
            />
            <ActorCharacterFaceGridList
              actorCharacterFaces={actorCharacterFaces}
              selectedActorCharacterFace={selectedActorCharacterFace}
              onSelectActorCharacterFace={onSelectActorCharacterFace}
            />
          </>
        )}
    </Layout>
  );
};

export default React.memo(Show);
