import * as React from 'react';
import {StyleSheet, TouchableOpacity} from 'react-native';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {unstable_createElement} from 'react-native-web';

import {filetypes} from './filetypes';

interface Props {
  disabled?: boolean;
  type: 'image' | 'audio' | 'any';
  onChangeFile?: (file: File) => void;
  onFailFile?: (message: string) => void;
  children?: React.ReactNode;
}

const DocumentPickerAdapter: React.FunctionComponent<Props> = props => {
  const {disabled, type, onChangeFile, onFailFile, children} = props;
  const filetypeSetting = filetypes[type];
  const handleChange = React.useCallback(
    (event: React.SyntheticEvent<HTMLLabelElement>) => {
      if (!event.target) {
        return;
      }
      const file = (event.target as any).files[0];
      if (!file) {
        return;
      }
      if (!filetypeSetting.pattern.test(file.type)) {
        onFailFile && onFailFile(filetypeSetting.errorMessage);
        return;
      }
      if (file.size > Math.pow(1024, 2) * filetypeSetting.maxFileMBSize) {
        onFailFile &&
          onFailFile(
            `ファイルは${filetypeSetting.maxFileMBSize}MB以下でアップロードしてください`,
          );
        return;
      }
      const reader = new FileReader();

      reader.onload = (event: any) => {
        if (!event.target || !event.target.result) {
          return;
        }
        if (typeof event.target.result !== 'string') {
          return;
        }
        const uri = event.target.result;
        (file as any).uri = uri;
        onChangeFile && onChangeFile(file as any);
      };

      reader.onerror = err => {
        onFailFile && onFailFile('エラー');
      };

      reader.onabort = err => {
        onFailFile && onFailFile('エラー');
      };

      reader.readAsDataURL(file);
    },
    [],
  );
  return (
    <TouchableOpacity activeOpacity={0.6}>
      {unstable_createElement('label', {
        onChange: handleChange,
        style: styles.label,
        children: (
          <>
            {unstable_createElement('input', {
              style: styles.input,
              type: 'file',
              accept: filetypeSetting.acceptWeb,
              disabled,
            })}
            {children}
          </>
        ),
      })}
    </TouchableOpacity>
  );
};

export default React.memo(DocumentPickerAdapter);

const styles = StyleSheet.create({
  label: {
    display: 'flex',
    cursor: 'pointer',
  } as any,
  input: {
    border: 0,
    width: 0,
    height: 0,
    visibility: 'hidden' as any,
    display: 'none',
  } as any,
});
