import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import PrimaryButton from '../../../../../shared/buttons/PrimaryButton';

interface Props {
  style?: StyleProp<ViewStyle>;
  onPress: () => void;
}

const NewBackgroundUsageHistoryButton: React.FunctionComponent<
  Props
> = props => {
  const {style, onPress} = props;
  return (
    <PrimaryButton style={style} onPress={onPress}>
      背景を選ぶ
    </PrimaryButton>
  );
};

export default React.memo(NewBackgroundUsageHistoryButton);
