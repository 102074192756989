import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import EffectPosition from './EffectPosition';

import Position from '../../../../../../../domain/value_objects/Position';

interface Props {
  width: number;
  onSelectPosition: (position: Position) => void;
}

export default class EffectPositionList extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {width, onSelectPosition} = this.props;
    const imageWidth = Math.ceil(
      (width -
        (CONTAINER_MARGIN_HORIZONTAL +
          CONTENT_MARGIN_HORIZONTAL * NUM_COLUMNS) *
          2) /
        NUM_COLUMNS,
    );
    return (
      <View style={styles.container}>
        <EffectPosition
          position={Position.Left}
          width={imageWidth}
          onSelectPosition={onSelectPosition}
        />
        <EffectPosition
          position={Position.Center}
          width={imageWidth}
          onSelectPosition={onSelectPosition}
        />
        <EffectPosition
          position={Position.Right}
          width={imageWidth}
          onSelectPosition={onSelectPosition}
        />
      </View>
    );
  }
}

const CONTAINER_MARGIN_HORIZONTAL = 10;
const CONTENT_MARGIN_HORIZONTAL = 6;
const NUM_COLUMNS = 3;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginHorizontal: CONTAINER_MARGIN_HORIZONTAL,
  } as ViewStyle,
});
