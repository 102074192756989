import * as React from 'react';
import {FlatList, ListRenderItemInfo, Platform, Text} from 'react-native';

import AnswerListItem from './AnswerListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Answer from '../../../../../domain/entities/writer/faq/Answer';
import AnswerLike from '../../../../../domain/entities/writer/faq/AnswerLike';

interface Props {
  answers: Array<Answer> | null;
  answerLikes: Array<AnswerLike>;
  questionWriterUserId: number;
  selfWriterUserId?: number;
}

const QuestionList: React.FC<Props> = props => {
  const {answers, answerLikes, questionWriterUserId, selfWriterUserId} = props;
  const keyExtractor = React.useCallback(
    (answers: Answer) => `${answers.id}`,
    [],
  );
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Answer>) => (
      <AnswerListItem
        answer={info.item}
        answerLike={
          answerLikes.find(v => v.writerFaqAnswerId === info.item.id) || null
        }
        questionWriterUserId={questionWriterUserId}
        selfWriterUserId={selfWriterUserId}
      />
    ),
    [],
  );
  if (!answers || answers?.length === 0) {
    return null;
  }
  return (
    <FlatList
      data={answers}
      scrollEnabled={Platform.select({web: true, default: false})}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(QuestionList);

const ListHeaderComponent: React.FC = () => {
  return (
    <Text
      style={{
        color: '#383838',
        fontSize: 16,
        fontWeight: 'bold',
        marginHorizontal: 16,
        marginTop: 24,
        marginBottom: 8,
      }}>
      みんなの回答
    </Text>
  );
};
