import {Platform, ScaledSize} from 'react-native';

export default function getContentDimension(size: ScaledSize) {
  if (Platform.OS !== 'web') {
    return {width: size.width, height: size.height};
  } else {
    return size.width > 480
      ? {width: 433, height: Math.min(962, size.height - 140)}
      : {width: size.width, height: size.height};
  }
}
