import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/speech_text_show_scene_command/new/speech_texts/Index';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import indexSpeechBalloons, {
  Params as SpeechBalloonIndexParams,
} from '../../../../../actions/speech_balloons/index';
import indexOrientedSpeechBalloons, {
  Params as OrientedSpeechBalloonIndexParams,
} from '../../../../../actions/oriented_speech_balloons/index';
import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../../../../actions/scene_command_forms/create';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';
import showSpeechBalloon from '../../../../../actions/speech_balloons/show';

import selectEntities from '../../../../../helpers/selectEntities';
import selectEntity from '../../../../../helpers/selectEntity';

import {SceneFormSpeechTextShowSceneCommandNewSpeechTextsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormSpeechTextShowSceneCommandNewSpeechTextsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const {parentSceneCommandId} = route.params;
  const sceneForm = forms.sceneForm;
  const sceneCommandForms = forms.sceneCommandForms;
  const speechBalloonsParams = {};
  const speechBalloons = selectEntities(
    entities.speechBalloons,
    queries.speechBalloons,
    speechBalloonsParams,
  );
  const orientedSpeechBalloonsParams = {};
  const orientedSpeechBalloons = selectEntities(
    entities.orientedSpeechBalloons,
    queries.orientedSpeechBalloons,
    orientedSpeechBalloonsParams,
  );
  const orientedSpeechBalloonEntities = entities.orientedSpeechBalloons;
  const orientedSpeechBalloonQueries = queries.orientedSpeechBalloons;
  const parentSceneCommandForm = getSceneCommandForm(
    sceneCommandForms,
    parentSceneCommandId,
  );
  return {
    navigation,
    currentUserStatus,
    sceneForm,
    sceneCommandForms,
    speechBalloons,
    speechBalloonsParams,
    orientedSpeechBalloons,
    orientedSpeechBalloonsParams,
    orientedSpeechBalloonEntities,
    orientedSpeechBalloonQueries,
    parentSceneCommandForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexSpeechBalloons: (params: SpeechBalloonIndexParams) =>
      dispatch(indexSpeechBalloons(params)),
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
    indexOrientedSpeechBalloons: (params: OrientedSpeechBalloonIndexParams) =>
      dispatch(indexOrientedSpeechBalloons(params)),
    showSpeechBalloon: (id: number) => dispatch(showSpeechBalloon(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
