import EntityMapper from '../EntityMapper';

import NotificationSetting from '../../../../domain/entities/writer/NotificationSetting';

import NotificationSettingCategoryMapper, {
  NotificationSettingCategoryData,
} from './NotificationSettingCategoryMapper';

export interface NotificationSettingData {
  id: number;
  name: string;
  writer_notification_setting_category: NotificationSettingCategoryData;
}

export default class NotificationSettingMapper
  implements EntityMapper<NotificationSettingData, NotificationSetting>
{
  private notificationSettingCategoryMapper =
    new NotificationSettingCategoryMapper();

  public map(obj: NotificationSettingData): NotificationSetting {
    return new NotificationSetting(
      obj.id,
      obj.name,
      this.notificationSettingCategoryMapper.map(
        obj.writer_notification_setting_category,
      ),
    );
  }
}
