import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/effect_show_scene_command/edit/base_effect_search_form/Index';

import AppState from '../../../../../reducers/AppState';

import indexEffectCategories, {
  Params as EffectCategoryIndexParams,
} from '../../../../../actions/effect_categories/index';
import indexBaseEffects, {
  Params as BaseEffectIndexParams,
} from '../../../../../actions/base_effects/index';

import selectEntities from '../../../../../helpers/selectEntities';
import {SceneFormEffectShowSceneCommandEditBaseEffectSearchFormRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandEditBaseEffectSearchFormRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {selectedLargeEffectCategoryId, selectedMiddleEffectCategoryId} =
    route.params;
  const sceneForm = forms.sceneForm;
  const largeEffectCategoriesParams: EffectCategoryIndexParams = {
    parentId: null,
    exceptBackground: true,
  };
  const largeEffectCategories = selectEntities(
    entities.effectCategories,
    queries.effectCategories,
    largeEffectCategoriesParams,
  );
  const middleEffectCategoriesParams: EffectCategoryIndexParams = {
    parentId: selectedLargeEffectCategoryId || 0,
    exceptBackground: true,
  };
  const middleEffectCategories = selectEntities(
    entities.effectCategories,
    queries.effectCategories,
    middleEffectCategoriesParams,
  );
  const smallEffectCategoriesParams: EffectCategoryIndexParams = {
    parentId: selectedMiddleEffectCategoryId || 0,
    exceptBackground: true,
  };
  const smallEffectCategories = selectEntities(
    entities.effectCategories,
    queries.effectCategories,
    smallEffectCategoriesParams,
  );
  return {
    sceneForm,
    largeEffectCategories,
    largeEffectCategoriesParams,
    middleEffectCategories,
    middleEffectCategoriesParams,
    smallEffectCategories,
    smallEffectCategoriesParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexEffectCategories: (params: EffectCategoryIndexParams) =>
      dispatch(indexEffectCategories(params)),
    indexBaseEffects: (params: BaseEffectIndexParams) =>
      dispatch(indexBaseEffects(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
