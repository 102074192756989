import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../components/analysis/episodes/Show';

import AppState from '../../../reducers/AppState';

import showSummaryEpisodeAggregation, {
  Option as SummaryEpisodeAggregationOption,
} from '../../../actions/summary/episode_aggregations/show';
import indexSummaryEpisodeBrowsingRetentionRates, {
  Params as SummaryEpisodeBrowsingRetentionRateIndexParams,
} from '../../../actions/summary/episode_browsing_retention_rates/index';

import {AnalysisEpisodeRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: AnalysisEpisodeRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showSummaryEpisodeAggregation: (
      id: number,
      options: SummaryEpisodeAggregationOption = {},
    ) => dispatch(showSummaryEpisodeAggregation(id, options)),
    indexSummaryEpisodeBrowsingRetentionRates: (
      params: SummaryEpisodeBrowsingRetentionRateIndexParams,
    ) => dispatch(indexSummaryEpisodeBrowsingRetentionRates(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
