import * as React from 'react';

import BaseIndex from '../../common/illustrations/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormIllustrationShowSceneCommandEditIllustrationsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as IllustrationUsageHistoryUpdateParams} from '../../../../../actions/illustration_usage_histories/update';
import {Params as IllustrationIndexParams} from '../../../../../actions/illustrations/index';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import {QueryState} from '../../../../../reducers/queries/Response';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import Illustration from '../../../../../../domain/entities/Illustration';
import IllustrationCategory from '../../../../../../domain/entities/IllustrationCategory';
import IllustrationUsageHistory from '../../../../../../domain/entities/IllustrationUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  illustrationCategoryId?: number;
  selectedLargeIllustrationCategoryId?: number | null;
  selectedMiddleIllustrationCategoryId?: number | null;
  selectedSmallIllustrationCategoryId?: number | null;
  sceneCommandId: number;
  parentSceneCommandId?: number;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormIllustrationShowSceneCommandEditIllustrationsRouteProp;
  sceneForm: SceneForm | null;
  illustrationsParams: IllustrationIndexParams;
  illustrations: Illustration[] | null;
  illustrationCategory: IllustrationCategory | null;
  parentSceneCommandForm: SceneCommandForm | null;
  illustrationQueries: QueryState;
  totalCount: number | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexIllustrations: (
    params: IllustrationIndexParams,
  ) => Promise<PaginatedResult<Illustration>>;
  showIllustrationCategory: (id: number) => Promise<IllustrationCategory>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateIllustrationUsageHistory: (
    id: number,
    params: IllustrationUsageHistoryUpdateParams,
  ) => Promise<IllustrationUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedIllustration: Illustration | null;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    const {route} = this.props;
    const {sort} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        sort={sort}
        onSelectIllustration={this.handleSelectIllustration}
        onPressSearchConditionChangeButton={
          this.handlePressSearchConditionChangeButton
        }
      />
    );
  }

  private handlePressSearchConditionChangeButton = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      query,
      selectedLargeIllustrationCategoryId,
      selectedMiddleIllustrationCategoryId,
      selectedSmallIllustrationCategoryId,
      favorite,
      sceneCommandId,
      parentSceneCommandId,
    } = route.params;
    navigation.navigate(
      'SceneFormIllustrationShowSceneCommandEditIllustrationSearchFormNavigations',
      {
        screen:
          'SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm',
        params: {
          storyId,
          episodeId,
          sceneId,
          query,
          selectedLargeIllustrationCategoryId,
          selectedMiddleIllustrationCategoryId,
          selectedSmallIllustrationCategoryId,
          favorite,
          sceneCommandId,
          parentSceneCommandId,
          hideBack: true,
        },
      } as any,
    );
  };

  private handleSelectIllustration = (illustration: Illustration): void => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      updateSceneCommandForm,
      updateIllustrationUsageHistory,
    } = this.props;
    const {storyId, sceneCommandId} = route.params;
    const sceneCommandForm = new IllustrationShowSceneCommandForm(
      illustration,
      null,
      null,
      null,
      sceneCommandId,
    );
    if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          sceneCommandForm,
        ),
      });
    } else {
      updateSceneCommandForm({
        sceneCommandForm,
      });
    }
    updateIllustrationUsageHistory(illustration.id, {storyId}).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };
}
