import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import FaqHome from '../../../containers/faq/home/Index';
import FaqQuestions from '../../../containers/faq/questions/Index';
import FaqQuestion from '../../../containers/faq/questions/Show';
import NewFaqQuestion from '../../../containers/faq/questions/New';
import NewFaqAnswer from '../../../containers/faq/answers/New';
import NewFaqReply from '../../../containers/faq/replies/New';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="FaqHome">
      <RootStack.Screen
        name="FaqHome"
        component={FaqHome}
        options={{
          title: 'お問い合わせ',
        }}
      />
      <RootStack.Screen
        name="FaqQuestions"
        component={FaqQuestions}
        options={{
          title: 'お問い合わせ',
        }}
      />
      <RootStack.Screen
        name="FaqQuestion"
        component={FaqQuestion}
        options={{
          title: 'お問い合わせ詳細',
        }}
      />
      <RootStack.Screen
        name="NewFaqQuestion"
        component={NewFaqQuestion}
        options={{
          title: 'お問い合わせフォーム',
        }}
      />
      <RootStack.Screen
        name="NewFaqAnswer"
        component={NewFaqAnswer}
        options={{
          title: '回答フォーム',
        }}
      />
      <RootStack.Screen
        name="NewFaqReply"
        component={NewFaqReply}
        options={{
          title: '返信フォーム',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;

const PublishedStack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="FaqHome">
      <RootStack.Screen
        name="FaqHome"
        component={FaqHome}
        options={{
          title: 'お問い合わせ',
        }}
      />
      <RootStack.Screen
        name="FaqQuestions"
        component={FaqQuestions}
        options={{
          title: 'お問い合わせ',
        }}
      />
      <RootStack.Screen
        name="FaqQuestion"
        component={FaqQuestion}
        options={{
          title: 'お問い合わせ詳細',
        }}
      />
    </RootStack.Navigator>
  );
};

export {PublishedStack};
