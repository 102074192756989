import SceneCommand from './SceneCommand';

import Position from '../Position';

import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class MarkHideSceneCommand implements SceneCommand {
  constructor(public position: Position) {}

  public getValue() {
    return {
      position: this.position.toString(),
    };
  }

  public toForm(): SceneCommandForm {
    throw Error('MarkHideSceneCommand#toForm() error');
  }
}
