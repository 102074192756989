import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import SoundUsageHistory from '../../../domain/entities/SoundUsageHistory';

export default function reducer(
  state: {[key: number]: SoundUsageHistory} = {},
  action: AnyAction,
): {[key: number]: SoundUsageHistory} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexSoundUsageHistoriesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (
            obj: {[key: number]: SoundUsageHistory},
            record: SoundUsageHistory,
          ) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.UpdateSoundUsageHistorySuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
