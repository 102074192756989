import * as React from 'react';
import {Pressable, StyleSheet, View, ViewStyle} from 'react-native';

type Position = 'left' | 'right';

interface Size {
  height: number;
  width: number;
}

interface Props {
  containerSize: Size;
  position?: Position;
  onPress?: () => void;
  children?: React.ReactNode;
}

const BaseArrow: React.FunctionComponent<Props> = props => {
  const {containerSize, position, children, onPress} = props;
  const containerStyle = position === 'left' ? {left: 0} : {right: 0};
  const circleStyle =
    position === 'left' ? {paddingRight: 2} : {paddingLeft: 2};
  const bottom = (containerSize.height - TOUCHABLE_SIZE) / 2;
  return (
    <Pressable onPress={onPress}>
      <View style={[styles.container, containerStyle, {bottom}]}>
        <View style={[styles.circle, circleStyle]}>{children}</View>
      </View>
    </Pressable>
  );
};

export default React.memo(BaseArrow);

const CYCLE_SIZE = 28;
const TOUCHABLE_SIZE = CYCLE_SIZE + 20;

const styles = StyleSheet.create({
  circle: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .5)',
    borderRadius: 100,
    height: CYCLE_SIZE,
    justifyContent: 'center',
    width: CYCLE_SIZE,
  } as ViewStyle,
  container: {
    alignItems: 'center',
    height: TOUCHABLE_SIZE,
    justifyContent: 'center',
    position: 'absolute',
    width: TOUCHABLE_SIZE,
  } as ViewStyle,
});
