import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';

import {Params as StoryCreateParams} from '../../../actions/stories/create';
import {Params as StoryFormUpdateParams} from '../../../actions/story_forms/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import File from '../../../../domain/entities/File';
import Story from '../../../../domain/entities/Story';
import StoryForm from '../../../../domain/forms/StoryForm';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  storyForm: StoryForm | null;
}

export interface DispatchProps {
  createStory: (params: StoryCreateParams) => Promise<Story>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class New extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public render(): React.ReactNode {
    const {navigation, storyForm} = this.props;
    const {loading, alertMessage} = this.state;
    return (
      <Layout
        title={'中表紙'}
        navigation={navigation}
        back={true}
        close={true}
        loading={loading}
        hideHeaderBottomBorder={true}>
        {storyForm && (
          <Form
            storyForm={storyForm}
            onChangeImage={this.handleChangeImage}
            onChangeCoverIllustrator={this.handleChangeCoverIllustrator}
            onSubmit={this.handleSubmit}
            onRequestReset={this.handleRequestReset}
            onForwardToImageSelection={() => {}}
            onForwardToCharacterImageSelection={() => {}}
            onForwardToTextInput={() => {}}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleChangeImage = (image: File) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({image, uploadedSelfImage: true});
  };

  private handleChangeCoverIllustrator = (coverIllustrator: string) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({coverIllustrator});
  };

  private handleSubmit = () => {
    const {navigation, storyForm, createStory} = this.props;
    if (!storyForm) {
      return;
    }
    storyForm.published = false;
    this.setState({loading: true}, () => {
      createStory(storyForm.toParams())
        .then(() => {
          this.setState({loading: false}, () => {
            (navigation.getParent() || navigation).goBack();
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private handleRequestReset = () => {
    const {updateStoryForm} = this.props;
    updateStoryForm({
      image: null,
      hasImage: false,
      uploadedSelfImage: false,
    });
  };
}
