import * as React from 'react';
import {Text, View} from 'react-native';

import Form from '../../common/name_label_colors/partials/Form';

import Layout from '../../../shared/Layout';
import AlertModal from '../../../shared/modals/AlertModal';
import ActionModal from '../../../shared/modals/ActionModal';
import AlertIcon from '../../../shared/icons/AlertIcon';

import NavigationProp from '../../../../navigators/NavigationProp';
import {NewCharacterRouteProp} from '../../../../navigators/RouteProps';

import {formatErrorMessages} from '../../../../helpers/errorMessages';

import {Params as CharacterFormUpdateParams} from '../../../../actions/character_forms/update';
import {Params as CharacterCreateParams} from '../../../../actions/characters/create';
import {Params as ApplicationModalUpdateParams} from '../../../../actions/application_modal/update';

import Character from '../../../../../domain/entities/Character';
import Actor from '../../../../../domain/entities/Actor';
import SpeechBalloon from '../../../../../domain/entities/SpeechBalloon';
import CharacterForm from '../../../../../domain/forms/CharacterForm';
import NameLabelColor from '../../../../../domain/value_objects/NameLabelColor';

export interface Params {
  projectId?: number;
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewCharacterRouteProp;
  characterForm: CharacterForm | null;
  speechBalloonId: number;
  speechBalloon: SpeechBalloon | null;
}

export interface DispatchProps {
  updateCharacterForm: (params: CharacterFormUpdateParams) => void;
  createCharacter: (params: CharacterCreateParams) => Promise<Character>;
  showActor: (id: number) => Promise<Actor>;
  showSpeechBalloon: (id: number) => Promise<SpeechBalloon>;
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  actor: Actor | null;
  loading: boolean;
  alertMessage?: string;
  projectModal?: boolean;
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      actor: null,
      loading: false,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      characterForm,
      speechBalloonId,
      speechBalloon,
      showSpeechBalloon,
    } = this.props;
    if (!speechBalloon) {
      showSpeechBalloon(speechBalloonId);
    }
    if (!characterForm) {
      (navigation.getParent() || navigation).goBack();
    }
    navigation.addListener('focus', () => {
      const {characterForm, showActor} = this.props;
      const {actor} = this.state;
      if (characterForm?.actorId) {
        if (!actor || actor.id !== characterForm.actorId) {
          showActor(characterForm.actorId).then(actor => {
            this.setState({actor});
          });
        }
      }
    });
  }

  public render(): React.ReactNode {
    const {navigation, route, speechBalloon, characterForm} = this.props;
    const {actor, loading, alertMessage, projectModal} = this.state;
    const {projectId} = route.params;
    return (
      <Layout
        title={'キャラ作成'}
        navigation={navigation}
        back={true}
        loading={loading}>
        {characterForm && speechBalloon && (
          <Form
            characterForm={characterForm}
            speechBalloon={speechBalloon}
            gender={actor?.gender}
            onSelectNameLabelColor={this.handleSelectNameLabelColor}
            onSubmit={
              projectId ? this.handleOpenProjectModal : this.handleSubmit
            }
          />
        )}
        <ActionModal
          visible={!!projectModal}
          description={
            <View style={{alignItems: 'center'}}>
              <AlertIcon size={32} color={'#ff8f13'} />
              <Text
                style={{textAlign: 'center', marginTop: 20, marginBottom: 4}}>
                作成したキャラクターはプロジェクトメンバー全員が利用できます。
              </Text>
              <Text style={{textAlign: 'center', marginTop: 4}}>
                キャラクターを購入した場合は、プロジェクト内のみでの利用となりますのでご注意ください。
              </Text>
            </View>
          }
          buttonText={'追加'}
          onAccept={this.handleSubmit}
          onRequestClose={this.handleCloseProjectModal}
        />
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleSelectNameLabelColor = (nameLabelColor: NameLabelColor) => {
    const {updateCharacterForm} = this.props;
    updateCharacterForm({nameLabelColor});
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };

  private handleOpenProjectModal = () => {
    this.setState({projectModal: true});
  };

  private handleCloseProjectModal = () => {
    this.setState({projectModal: false});
  };

  private handleSubmit = () => {
    const {
      navigation,
      route,
      createCharacter,
      updateApplicationModal,
      characterForm,
    } = this.props;
    const {storyId, projectId} = route.params;
    if (!characterForm) {
      return;
    }
    const {
      actorCharacterId,
      name,
      description,
      actorCharacterFaceId,
      nameLabelColor,
      voiceActorName,
    } = characterForm;
    if (!actorCharacterId || !name || !actorCharacterFaceId) {
      return;
    }
    this.setState({loading: true, projectModal: false}, () => {
      createCharacter({
        actorCharacterId,
        name,
        description: description || '',
        actorCharacterFaceId,
        storyId,
        nameLabelColor,
        voiceActorName,
      })
        .then(character => {
          this.setState({loading: false}, () => {
            if (projectId) {
              navigation.navigate('ProjectCharacters', {projectId});
            } else {
              navigation.goBack();
              navigation.goBack();
            }
            setTimeout(() => {
              updateApplicationModal({
                options: {
                  message: 'キャラクターを追加しました！',
                },
              });
            }, 200);
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };
}
