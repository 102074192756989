import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import WriterUserAvator from '../../questions/components/WriterUserAvator';
import ReplyListItem from '../../replies/components/ReplyListItem';
import LikeIcon from '../../../shared/icons/reaction/LikeIcon';
import ReactionIcon from '../../../shared/icons/ReactionIcon';

import Answer from '../../../../../domain/entities/writer/faq/Answer';
import AnswerLike from '../../../../../domain/entities/writer/faq/AnswerLike';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

interface Props {
  answer: Answer;
  answerLike: AnswerLike | null;
  questionWriterUserId: number;
  selfWriterUserId?: number;
}

const AnswerListItem: React.FC<Props> = props => {
  const {answer, questionWriterUserId, selfWriterUserId} = props;
  const linkTo = useLinkTo();
  const [answerLike, setAnswerLike] = React.useState<AnswerLike | null>(
    props.answerLike,
  );
  const [answerLikesCount, setAnswerLikesCount] = React.useState(
    answer.answerLikesCount,
  );
  const onPress = React.useCallback(() => {
    if (answerLike) {
      TapNovelRestApi.delete(`/api/writer/faq/answer_likes/${answerLike.id}`);
      setAnswerLikesCount(answerLikesCount - 1);
      setAnswerLike(null);
    } else {
      TapNovelRestApi.post(`/api/writer/faq/answer_likes/`, {
        answerLike: {writerFaqAnswerId: answer.id},
      }).then(response => {
        setAnswerLike(camelizeKeys(response.body) as AnswerLike);
        setAnswerLikesCount(answerLikesCount + 1);
      });
    }
  }, [answerLike, answerLikesCount]);
  const activeLikeButton =
    !!answerLike && selfWriterUserId !== answer.writerUser.id;
  const onPressReply = React.useCallback(() => {
    linkTo(`/faq/answers/${answer.id}/replies/new`);
  }, []);
  return (
    <View style={styles.container}>
      <WriterUserAvator
        writerUser={answer.writerUser}
        time={answer.createdAt}
      />
      <Text style={styles.content}>{answer.content}</Text>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
        <Pressable
          style={styles.like}
          disabled={selfWriterUserId === answer.writerUser.id}
          onPress={onPress}>
          {activeLikeButton ? (
            <LikeIcon size={11} />
          ) : (
            <ReactionIcon size={11} color={'#999999'} />
          )}
          <Text
            style={[
              styles.likeText,
              activeLikeButton ? styles.likeTextActive : null,
            ]}>
            {answerLikesCount}
          </Text>
        </Pressable>
        {questionWriterUserId === selfWriterUserId && !answer.writerFaqReply ? (
          <Pressable onPress={onPressReply}>
            <Text
              style={{
                color: '#999',
                fontSize: 11,
                fontWeight: 'bold',
                marginLeft: 16,
              }}>
              返信する
            </Text>
          </Pressable>
        ) : null}
      </View>
      {answer.writerFaqReply ? (
        <ReplyListItem style={styles.reply} reply={answer.writerFaqReply} />
      ) : null}
    </View>
  );
};

export default React.memo(AnswerListItem);

const styles = StyleSheet.create({
  container: {
    padding: 16,
  } as ViewStyle,
  content: {
    color: '#222',
    fontSize: 14,
    marginTop: 16,
  } as TextStyle,
  reply: {
    marginLeft: 60,
  } as ViewStyle,
  like: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  } as ViewStyle,
  likeText: {
    color: '#999999',
    fontSize: 12,
    fontWeight: '500',
    marginHorizontal: 2,
  } as TextStyle,
  likeTextActive: {
    color: '#f23406',
  } as TextStyle,
});
