import * as React from 'react';

import AddSceneCommandLink from './AddSceneCommandLink';

import SpeechTextIcon from '../../../../shared/icons/SpeechTextIcon';

import {colors} from '../../../../../styles/variables';

interface Props {
  onPress: () => void;
}

const AddSpeechTextShowSceneCommandLink: React.FunctionComponent<
  Props
> = props => {
  const {onPress} = props;
  return (
    <AddSceneCommandLink title={'セリフ'} onPress={onPress}>
      <SpeechTextIcon color={colors.textColor} />
    </AddSceneCommandLink>
  );
};

export default React.memo(AddSpeechTextShowSceneCommandLink);
