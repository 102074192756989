import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import TextCounter from './forms/TextCounter';

import {MAX_LENGTH} from '../../helpers/textInputHelper';

interface Props {
  style?: ViewStyle;
  length: number;
}

const ElasticBoxInputTextCounter: React.FunctionComponent<Props> = props => {
  const {style, length} = props;
  const ary = [];
  if (length > MAX_LENGTH) {
    ary.push('文字数がオーバーしています');
  }
  const message = ary.join(' / ');
  return (
    <View style={[style, styles.container]}>
      <Text style={styles.message}>{message}</Text>
      <View style={styles.textCounter}>
        <TextCounter length={length} maxLength={MAX_LENGTH} />
      </View>
    </View>
  );
};

export default React.memo(ElasticBoxInputTextCounter);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginHorizontal: 16,
    marginVertical: 8,
    alignItems: 'center',
    justifyContent: 'flex-end',
  } as ViewStyle,
  message: {
    color: '#f23406',
    fontSize: 11,
    marginHorizontal: 8,
  } as TextStyle,
  textCounter: {} as ViewStyle,
});
