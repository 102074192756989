import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import ServiceExplanation from './partials/service_explanation/ServiceExplanation';
import ProjectList from './partials/ProjectList';
import NoProjects from './partials/NoProjects';
import SearchProjectsButton from './partials/SearchProjectsButton';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';

import Project from '../../../domain/entities/Project';

import CurrentUser from '../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';

import TapNovelTypedRestApi from '../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  showCurrentUser: () => Promise<CurrentUser>;
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, showCurrentUser, showCurrentUserStatus} = props;
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | null>(
    null,
  );
  const [currentUserStatus, setCurrentUserStatus] =
    React.useState<CurrentUserStatus | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState('プロジェクト');
  const [projects, setProjects] = React.useState<Project[] | null>(null);
  const onForceUpdate = React.useCallback(() => {
    TapNovelTypedRestApi.get<Project[]>('/api/writer/user/projects')
      .then(result => {
        setProjects(result.body);
      })
      .catch(error => {
        if (error.status === 403) {
          navigation.navigate('Home', {});
        }
      });
  }, []);
  useFocusEffect(onForceUpdate);
  React.useEffect(() => {
    showCurrentUser().then(currentUser => {
      setCurrentUser(currentUser);
    });
    showCurrentUserStatus().then(currentUserStatus => {
      setCurrentUserStatus(currentUserStatus);
    });
  }, []);
  const onAgreeServicePolicy = React.useCallback(() => {
    setLoading(true);
    TapNovelTypedRestApi.post('/api/writer/user_service_agreements', {
      writer_user_service_agreement: {
        writer_service_agreement_code: 'project',
      },
    })
      .then(response => {
        setTimeout(() => {
          showCurrentUserStatus().then(setCurrentUserStatus);
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  const agreed =
    currentUser?.grade && currentUserStatus
      ? currentUserStatus.serviceAgreementCodes.includes('project')
      : false;
  React.useEffect(() => {
    if (agreed) {
      setTitle('プロジェクト一覧');
    }
  }, [agreed]);
  return (
    <Layout
      title={title}
      navigation={navigation}
      back={true}
      loading={loading}
      scrollable={
        currentUser && currentUserStatus
          ? !agreed
            ? false
            : projects && projects.length > 0
            ? true
            : false
          : false
      }
      containerStyle={{paddingBottom: 100}}
      footer={
        agreed && projects && projects.length > 0 ? (
          <SearchProjectsButton />
        ) : null
      }>
      {currentUser && currentUserStatus ? (
        agreed ? (
          projects ? (
            projects.length > 0 ? (
              <ProjectList
                projects={projects}
                wrriterUserId={currentUser.writerUserId}
                onForceUpdate={onForceUpdate}
              />
            ) : (
              <NoProjects />
            )
          ) : null
        ) : (
          <ServiceExplanation
            setTitle={setTitle}
            onAgreeServicePolicy={onAgreeServicePolicy}
          />
        )
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
