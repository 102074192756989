import * as React from 'react';

import StorySummary from './partials/StorySummary';
import StoryAggregationView from './partials/StoryAggregationView';

import EpisodeList from '../episodes/partials/EpisodeList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {AnalysisStoryRouteProp} from '../../../navigators/RouteProps';

import StoryAggregation from '../../../../domain/value_objects/summary/StoryAggregation';
import EpisodeAggregation from '../../../../domain/value_objects/summary/EpisodeAggregation';
import EpisodePageViewAggregation from '../../../../domain/value_objects/summary/EpisodePageViewAggregation';

import {Option as SummaryStoryAggregationShowOption} from '../../../actions/summary/story_aggregations/show';
import {Params as SummaryEpisodeAggregationIndexParams} from '../../../actions/summary/episode_aggregations/index';
import {Params as SummaryEpisodePageViewAggregationIndexParams} from '../../../actions/summary/episode_page_view_aggregations/index';

import {
  TermRange,
  convertTermRangeToQueryParams,
} from '../../../view_models/TermRange';

export interface Params {
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: AnalysisStoryRouteProp;
}

export interface DispatchProps {
  showSummaryStoryAggregation: (
    id: number,
    option: SummaryStoryAggregationShowOption,
  ) => Promise<StoryAggregation>;
  indexSummaryEpisodeAggregations: (
    params: SummaryEpisodeAggregationIndexParams,
  ) => Promise<Array<EpisodeAggregation>>;
  indexSummaryEpisodePageViewAggregations: (
    params: SummaryEpisodePageViewAggregationIndexParams,
  ) => Promise<Array<any>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  storyAggregation: StoryAggregation | null;
  episodeAggregations: Array<EpisodeAggregation> | null;
  episodePageViewAggregations: Array<EpisodePageViewAggregation> | null;
  termRange: TermRange;
}

export default class Show extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      storyAggregation: null,
      episodeAggregations: null,
      episodePageViewAggregations: null,
      termRange: 'all',
    };
  }

  public componentDidMount() {
    this.fetchAggregations();
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {
      storyAggregation,
      episodeAggregations,
      episodePageViewAggregations,
      termRange,
    } = this.state;
    return (
      <Layout
        title={storyAggregation?.title || '分析'}
        scrollable={false}
        navigation={navigation}
        back={true}>
        {storyAggregation &&
          episodeAggregations &&
          episodePageViewAggregations && (
            <EpisodeList
              episodeAggregations={episodeAggregations}
              ListHeaderComponent={
                <>
                  <StorySummary storyAggregation={storyAggregation} />
                  <StoryAggregationView
                    storyAggregation={storyAggregation}
                    termRange={termRange}
                    episodePageViewAggregations={episodePageViewAggregations}
                    onTermRangeChange={this.handleTermRangeChange}
                  />
                </>
              }
              onSelectEpisode={this.handleSelectEpisode}
            />
          )}
      </Layout>
    );
  }

  private handleSelectEpisode = (episodeAggregation: EpisodeAggregation) => {
    const {navigation} = this.props;
    navigation.navigate('AnalysisEpisode', {
      episodeId: episodeAggregation.id,
    });
  };

  private fetchAggregations = () => {
    const {
      route,
      showSummaryStoryAggregation,
      indexSummaryEpisodeAggregations,
      indexSummaryEpisodePageViewAggregations,
    } = this.props;
    const {storyId} = route.params;
    const {termRange} = this.state;
    showSummaryStoryAggregation(
      storyId,
      convertTermRangeToQueryParams(termRange),
    ).then(storyAggregation => this.setState({storyAggregation}));
    indexSummaryEpisodeAggregations({
      ...convertTermRangeToQueryParams(termRange),
      storyId,
    }).then(episodeAggregations => this.setState({episodeAggregations}));
    indexSummaryEpisodePageViewAggregations({
      ...convertTermRangeToQueryParams(termRange),
      storyId,
    }).then(episodePageViewAggregations =>
      this.setState({episodePageViewAggregations}),
    );
  };

  private handleTermRangeChange = (termRange: TermRange) => {
    this.setState({termRange}, () => {
      this.fetchAggregations();
    });
  };
}
