import * as React from 'react';
import {
  Image,
  ImageStyle,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import CheckButton from '../../../shared/forms/CheckButton';

import convertImageSource from '../../../../helpers/convertImageSource';

import generationMethodlUri from '../../../../../assets/images/commercial_video/generation-method.png';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep2: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Text style={styles.message}>
          CM動画は、各ストーリーのエピソード1の{'\n'}
          冒頭30Tap程度を書き出したものとなります。
        </Text>
        <Image
          style={styles.image}
          source={convertImageSource(generationMethodlUri)}
        />
        <CheckButton
          style={styles.checkButton}
          checked={checked}
          onPress={onPressCheck}>
          理解した
        </CheckButton>
        <PrimaryButton disabled={!checked} onPress={onPress}>
          次へ
        </PrimaryButton>
      </ScrollView>
    </View>
  );
};

export default React.memo(ServiceExplanationStep2);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
  } as ViewStyle,
  scrollView: {
    paddingVertical: 32,
    marginBottom: 32,
    alignItems: 'center',
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
  image: {
    width: 279,
    height: 160,
    marginTop: 24,
    marginBottom: 16,
  } as ImageStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginBottom: 16,
  } as ViewStyle,
});
