import * as React from 'react';

import BaseIndex from '../../common/effect_usage_histories/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormEffectShowSceneCommandEditEffectUsageHistoriesRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as EffectUsageHistoryIndexParams} from '../../../../../actions/effect_usage_histories/index';
import {Params as EffectUsageHistoryUpdateParams} from '../../../../../actions/effect_usage_histories/update';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import EffectUsageHistory from '../../../../../../domain/entities/EffectUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  back?: boolean;
  sceneCommandId: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandEditEffectUsageHistoriesRouteProp;
  sceneForm: SceneForm | null;
  effectUsageHistoriesParams: EffectUsageHistoryIndexParams;
  effectUsageHistories: EffectUsageHistory[] | null;
  parentSceneCommandForm: SceneCommandForm | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexEffectUsageHistories: (
    params: EffectUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<EffectUsageHistory>>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateEffectUsageHistory: (
    id: number,
    params: EffectUsageHistoryUpdateParams,
  ) => Promise<EffectUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        onForwardToNewEffectUsageHistory={
          this.handleForwardToNewEffectUsageHistory
        }
        onSelectEffectUsageHistory={this.handleSelectEffectUsageHistory}
        onForwardToFavoriteList={this.handleForwardToFavoriteList}
      />
    );
  }

  private handleSelectEffectUsageHistory = (
    effectUsageHistory: EffectUsageHistory,
  ) => {
    const {navigation, route} = this.props;
    const baseEffectId = effectUsageHistory.baseEffect.id;
    const {storyId, episodeId, sceneId, sceneCommandId, parentSceneCommandId} =
      route.params;
    routers.linkToSceneFormEffectShowSceneCommandEditEffects(navigation, {
      baseEffectId,
      storyId,
      episodeId,
      sceneId,
      sceneCommandId,
      parentSceneCommandId,
    });
  };

  private handleForwardToNewEffectUsageHistory = () => {
    const {navigation, route} = this.props;
    const {storyId, episodeId, sceneId, sceneCommandId, parentSceneCommandId} =
      route.params;
    routers.linkToSceneFormEffectShowSceneCommandEditBaseEffectSearchForm(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandId,
        parentSceneCommandId,
      },
    );
  };

  private handleForwardToFavoriteList = () => {
    const {navigation, route} = this.props;
    const {storyId, episodeId, sceneId, sceneCommandId, parentSceneCommandId} =
      route.params;
    routers.linkToSceneFormEffectShowSceneCommandEditBaseEffects(navigation, {
      storyId,
      episodeId,
      sceneId,
      sceneCommandId,
      parentSceneCommandId,
      favorite: true,
    });
  };
}
