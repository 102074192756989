import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import BackgroundCategory from '../../../domain/entities/BackgroundCategory';

import NetBackgroundCategoriesRepository from '../../../data/repositories/NetBackgroundCategoriesRepository';

const types = {
  failure: Types.ShowBackgroundCategoryFailure,
  request: Types.ShowBackgroundCategoryRequest,
  success: Types.ShowBackgroundCategorySuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<BackgroundCategory> {
  return showGeneric(new NetBackgroundCategoriesRepository(), types, id);
}
