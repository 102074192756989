export default class UserRegistrationPasswordEditForm {
  constructor(
    public currentPassword: string,
    public password: string,
    public passwordConfirmation: string,
  ) {}

  public validate(): boolean {
    return (
      this.currentPassword.length > 0 &&
      this.password.length > 0 &&
      this.passwordConfirmation.length > 0 &&
      this.password === this.passwordConfirmation
    );
  }

  public getParams() {
    return {
      currentPassword: this.currentPassword,
      password: this.password,
      passwordConfirmation: this.passwordConfirmation,
    };
  }
}
