import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Episode from '../../../domain/entities/Episode';

import NetEpisodesRepository from '../../../data/repositories/writer/NetEpisodesRepository';

import EpisodeFormCreateEvent from '../../../domain/services/analytics_particular_events/EpisodeFormCreateEvent';

export interface DefaultParams {
  storyId?: number;
  title?: string | null;
  catchPhrase?: string | null;
  summary?: string | null;
  introduction?: string | null;
  imageUrl?: string | null;
  genreIds?: number[] | null;
}

interface CopyParams {
  copyFrom: number;
}

export type Params = DefaultParams | CopyParams;

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Episode> {
  return async (dispatch: Dispatch<any>): Promise<Episode> => {
    try {
      const payload = await new NetEpisodesRepository().create(
        Object.keys(params).includes('storyId')
          ? {
              episode: params,
            }
          : params,
      );
      dispatch({payload, type: Types.CreateEpisodeSuccess});
      sendDefaultCustomEvent({
        resourceType: 'episode',
        resourceId: payload.id,
        resourceName: payload.title,
        actionName: 'create',
      });
      if (Object.keys(params).includes('copyFrom')) {
        sendDefaultCustomEvent({
          resourceType: 'copied_episode',
          resourceId: payload.id,
          resourceName: payload.title,
          actionName: 'create',
        });
      }
      new EpisodeFormCreateEvent().create().then(sameEventsCount => {
        if (sameEventsCount === 1) {
          dispatch({
            payload: 'first_episode_form_created',
            type: Types.UpdateCoachmarkModal,
          });
        }
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateEpisodeFailure});
      throw err;
    }
  };
}
