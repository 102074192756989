import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import {colors} from '../../../../styles/variables';

interface Props {
  children?: React.ReactNode;
}

const ListItemNormalSeparator: React.FunctionComponent<Props> = props => {
  return <View style={styles.separator} />;
};

export default React.memo(ListItemNormalSeparator);

const styles = StyleSheet.create({
  separator: {
    backgroundColor: colors.powderGray,
    height: StyleSheet.hairlineWidth,
  } as ViewStyle,
});
