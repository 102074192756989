import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import CharacterForm from '../../../domain/forms/CharacterForm';

export default function reducer(
  state: CharacterForm | null = null,
  action: AnyAction,
): CharacterForm | null {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return null;
    case Types.CreateCharacterForm:
      return buildCharacterForm(createCharacterForm(), action);
    case Types.UpdateCharacterForm:
      return buildCharacterForm(createCharacterForm(state), action);
    default:
      return state;
  }
}

function createCharacterForm(oldForm: CharacterForm | null = null) {
  const newForm = new CharacterForm();
  if (oldForm) {
    newForm.id = oldForm.id;
    newForm.name = oldForm.name;
    newForm.description = oldForm.description;
    newForm.actorId = oldForm.actorId;
    newForm.actorCharacterId = oldForm.actorCharacterId;
    newForm.defaultCharacterPatternId = oldForm.defaultCharacterPatternId;
    newForm.actorCharacterFaceId = oldForm.actorCharacterFaceId;
    newForm.actorCharacterFaceName = oldForm.actorCharacterFaceName;
    newForm.published = oldForm.published;
    newForm.nameLabelColor = oldForm.nameLabelColor;
    newForm.voiceActorName = oldForm.voiceActorName;
  }
  return newForm;
}

function buildCharacterForm(characterForm: CharacterForm, action: AnyAction) {
  const {
    id,
    name,
    description,
    actorId,
    actorCharacterId,
    defaultCharacterPatternId,
    actorCharacterFaceId,
    actorCharacterFaceName,
    published,
    nameLabelColor,
    voiceActorName,
  } = action.payload;
  if (id !== undefined) {
    characterForm.id = id;
  }
  if (name !== undefined) {
    characterForm.name = name;
  }
  if (description !== undefined) {
    characterForm.description = description;
  }
  if (actorId !== undefined) {
    characterForm.actorId = actorId;
  }
  if (actorCharacterId !== undefined) {
    characterForm.actorCharacterId = actorCharacterId;
  }
  if (defaultCharacterPatternId !== undefined) {
    characterForm.defaultCharacterPatternId = defaultCharacterPatternId;
  }
  if (actorCharacterFaceId !== undefined) {
    characterForm.actorCharacterFaceId = actorCharacterFaceId;
  }
  if (actorCharacterFaceName !== undefined) {
    characterForm.actorCharacterFaceName = actorCharacterFaceName;
  }
  if (published !== undefined) {
    characterForm.published = published;
  }
  if (nameLabelColor !== undefined) {
    characterForm.nameLabelColor = nameLabelColor;
  }
  if (voiceActorName !== undefined) {
    characterForm.voiceActorName = voiceActorName;
  }
  return characterForm;
}
