import * as React from 'react';
import {Pressable, StyleSheet, ViewStyle} from 'react-native';

import MusicNoteIcon from '../../../shared/icons/MusicNoteIcon';

interface Props {
  onPress: () => void;
}

const BackgroundMusicChangeButton: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <Pressable style={styles.container} onPress={onPress}>
      <MusicNoteIcon size={11} color={'#222'} />
    </Pressable>
  );
};

export default React.memo(BackgroundMusicChangeButton);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 8,
    right: 16,
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: '#d5d5d5',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
