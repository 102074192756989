import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

export default function reducer(
  state: {[key: number]: CurrentUserStatus} = {},
  action: AnyAction,
): {[key: number]: CurrentUserStatus} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.ShowWriterCurrentUserStatusSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
