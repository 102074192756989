import React from 'react';

import RootStack from '../../../../RootStack';

import SceneFormSoundEffectShowSceneCommandNewSoundSearchForm from '../../../../../containers/scene_form/sound_effect_show_scene_command/new/sound_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormSoundEffectShowSceneCommandNewSoundSearchForm">
      <RootStack.Screen
        name="SceneFormSoundEffectShowSceneCommandNewSoundSearchForm"
        component={SceneFormSoundEffectShowSceneCommandNewSoundSearchForm}
        options={{title: '効果音検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
