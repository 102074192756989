import * as React from 'react';
import {Platform, StyleProp, Text, View, ViewStyle} from 'react-native';
import numeral from 'numeral';

import MakerProModal from './partials/MakerProModal';
import PurchaseModal from './partials/PurchaseModal';

import Layout from '../../../Layout';
import SearchResult from '../../../scene_form/SearchResult';
import ActionModal from '../../../modals/ActionModal';
import ResourceFavoriteButton from '../../../ResourceFavoriteButton';

import NavigationProp from '../../../../../navigators/NavigationProp';

import {Params as ActorIndexParams} from '../../../../../actions/actors/index';
import {Params as ActorCategoryIndexParams} from '../../../../../actions/actor_categories/index';

import buildActorSearchWord from '../../../../../view_models/buildActorSearchWord';

import {getNextPage} from '../../../../../helpers/selectEntities';
import {actorImageUrl} from '../../../../../helpers/images';
import {QueryState} from '../../../../../reducers/queries/Response';

import CurrentUser from '../../../../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../../../../domain/entities/writer/CurrentUserStatus';
import Actor from '../../../../../../domain/entities/Actor';
import ActorCategory from '../../../../../../domain/entities/ActorCategory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../domain/value_objects/Position';
import UserResourcePurchase from '../../../../../../domain/entities/writer/UserResourcePurchase';
import ActorSearchSetting from '../../../../../../domain/value_objects/ActorSearchSetting';

import TapNovelRestApi from '../../../../../../data/data_stores/net/TapNovelRestApi';
import NetUserActorPurchasesRepository from '../../../../../../data/repositories/writer/NetUserActorPurchasesRepository';
import StorageActorSearchSettingRepository from '../../../../../../data/repositories/StorageActorSearchSettingRepository';

const actorSearchSettingRepository = new StorageActorSearchSettingRepository();

export interface Params {
  storyId: number;
  sceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
  formValues: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
  };
  sort?: 'popularity' | 'new_arrival';
  withPaid?: boolean;
  page?: number;
  back?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
  actors: Actor[] | null;
  actorsParams: ActorIndexParams;
  actorCategories: ActorCategory[] | null;
  actorCategoriesParams: ActorCategoryIndexParams;
  actorQueries: QueryState;
  totalCount: number | null;
  sort?: 'popularity' | 'new_arrival';
  withPaid?: boolean;
  shopMode?: boolean;
}

export interface DispatchProps {
  indexActors: (params: ActorIndexParams) => Promise<PaginatedResult<Actor>>;
  indexActorCategories: (
    params: ActorCategoryIndexParams,
  ) => Promise<PaginatedResult<ActorCategory>>;
}

interface Props extends StateProps, DispatchProps {
  title?: string;
  formValues: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    purchase?: boolean;
    paid?: boolean;
    allowedInversion?: Array<'true' | 'false'>;
  };
  enabledFavorite?: boolean;
  onPressForSearch: () => void;
  onPressForActor: (actor: Actor) => void;
}

interface State {
  userResourcePurchases: UserResourcePurchase[] | null;
  selectedActor: Actor | null;
  selectedPaidActor: Actor | null;
  selectedMakerProOnlyActor: Actor | null;
}

export default class Index extends React.Component<Props, State> {
  private actorSearchSetting: ActorSearchSetting | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      userResourcePurchases: null,
      selectedActor: null,
      selectedPaidActor: null,
      selectedMakerProOnlyActor: null,
    };
    actorSearchSettingRepository
      .find()
      .then(actorSearchSetting => {
        this.actorSearchSetting = actorSearchSetting;
      })
      .catch(() => {});
  }

  public componentDidMount() {
    const {navigation} = this.props;
    navigation.addListener('focus', this.fetchEntities);

    navigation.addListener('focus', this.fetchUserActorPurchases);
    if (Platform.OS === 'web') {
      window.addEventListener('focus', this.fetchUserActorPurchases);
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      this.props.formValues !== prevProps.formValues ||
      this.props.sort !== prevProps.sort ||
      this.props.withPaid !== prevProps.withPaid
    ) {
      this.fetchEntities();
    }
  }

  public componentWillUnmount() {
    const {navigation} = this.props;
    navigation.removeListener('focus', this.fetchEntities);

    navigation.removeListener('focus', this.fetchUserActorPurchases);
    if (Platform.OS === 'web') {
      window.removeEventListener('focus', this.fetchUserActorPurchases);
    }
  }

  public render(): React.ReactNode {
    const {
      navigation,
      sort,
      actors,
      totalCount,
      formValues,
      title,
      withPaid,
      shopMode,
      onPressForSearch,
    } = this.props;
    const {selectedActor, userResourcePurchases} = this.state;
    const showPaidLabel = this.showPaidLabel();
    return (
      <Layout
        title={
          title ||
          `${
            formValues.favorite
              ? 'お気に入り'
              : formValues.purchase
              ? '購入済み'
              : ''
          }キャラクター`
        }
        back={true}
        close={true}
        navigation={navigation}
        scrollable={false}>
        <SearchResult
          entities={actors}
          totalCount={totalCount}
          sort={sort}
          favorite={formValues.favorite}
          purchase={formValues.purchase}
          paid={formValues.paid}
          title={this.getTitle()}
          containerStyle={showPaidLabel ? {height: 220} : undefined}
          aspectRatio={aspectRatio}
          resourceName={'キャラクター'}
          extraData={userResourcePurchases}
          withPaid={withPaid}
          onPressSearchConditionChangeButton={onPressForSearch}
          onSelectEntity={this.handlePressForActor}
          onPressSort={this.handlePressSort}
          onEndReached={this.handleEndReached}
          imageUrlExtractor={this.imageUrlExtractor}
          renderCustomLabel={this.renderCustomLabel}
          disabledItem={shopMode ? this.disabledItem : undefined}
          modalRenderFavoriteButton={this.modalRenderFavoriteButton}
          onPaidValueChange={
            showPaidLabel && !shopMode ? this.handlePaidValueChange : undefined
          }
        />
        <ActionModal
          visible={!!selectedActor}
          description={actionModalDescription}
          onAccept={this.handleAccept}
          onRequestClose={this.handleRequestClose}
        />
        {this.state.selectedPaidActor && (
          <PurchaseModal
            actorId={this.state.selectedPaidActor.id}
            onRequestClose={this.handleRequestClose}
          />
        )}
        {this.state.selectedMakerProOnlyActor && (
          <MakerProModal onRequestClose={this.handleRequestClose} />
        )}
      </Layout>
    );
  }

  private fetchEntities = () => {
    const {
      actors,
      actorsParams,
      actorCategories,
      actorCategoriesParams,
      indexActors,
      indexActorCategories,
    } = this.props;
    if (
      !actors ||
      actorsParams.favorite ||
      actorsParams.purchase ||
      actorsParams.paid
    ) {
      indexActors(actorsParams);
    }
    if (!actorCategories) {
      indexActorCategories(actorCategoriesParams);
    }
  };

  private fetchUserActorPurchases = () => {
    new NetUserActorPurchasesRepository()
      .findAll()
      .then(userResourcePurchases => {
        this.setState({userResourcePurchases: userResourcePurchases.records});
      });
  };

  private getTitle = () => {
    const {formValues, actorCategories} = this.props;
    if (!actorCategories) {
      return '';
    }
    return buildActorSearchWord(actorCategories, formValues);
  };

  private handleEndReached = (): void => {
    const {actorQueries, actorsParams, indexActors} = this.props;
    const nextPage = getNextPage(actorQueries, actorsParams);
    if (nextPage) {
      indexActors({...actorsParams, page: nextPage});
    }
  };

  private imageUrlExtractor = (actor: Actor, width: number) => {
    return actorImageUrl(actor, 'middle');
  };

  private handlePressSort = (sort: 'popularity' | 'new_arrival') => {
    const {navigation} = this.props;
    navigation.setParams({sort});
  };

  private renderCustomLabel = (actor: Actor, width: number) => {
    const {currentUser, shopMode} = this.props;
    const {userResourcePurchases} = this.state;
    const showPaidLabel = this.showPaidLabel();
    return (
      <>
        {actor.makerProOnly ? (
          <MakerProLabel style={showPaidLabel ? {bottom: 15} : {}} />
        ) : actor.contestIds.length === 0 ? null : (
          <RestrictedContestLabel style={showPaidLabel ? {bottom: 15} : {}} />
        )}
        {showPaidLabel ? (
          <View
            style={{
              height: 30,
              justifyContent: 'center',
            }}>
            {userResourcePurchases
              ?.map(v => v.resourceId)
              .includes(actor.id) ? (
              <Text style={{color: '#222', fontSize: 14, fontWeight: 'bold'}}>
                購入済み
              </Text>
            ) : (
              <Text style={{color: '#222', fontSize: 14, fontWeight: 'bold'}}>
                {currentUser && currentUser.id === actor.userId
                  ? `自作${shopMode ? 'キャラ' : '無料'}`
                  : actor.amount
                  ? `￥${numeral(actor.amount).format('0')}`
                  : `${actor.makerProOnly ? '会員限定' : ''}無料`}
              </Text>
            )}
          </View>
        ) : null}
      </>
    );
  };

  private handlePressForActor = async (selectedActor: Actor) => {
    const {onPressForActor, shopMode} = this.props;
    const {userResourcePurchases} = this.state;
    const actorId = selectedActor.id;
    if (
      !shopMode &&
      ((selectedActor.price &&
        !userResourcePurchases?.map(v => v.resourceId).includes(actorId)) ||
        selectedActor.makerProOnly)
    ) {
      try {
        await TapNovelRestApi.get(`/api/writer/available_actors/${actorId}`);
      } catch (e) {
        if (selectedActor.makerProOnly) {
          this.setState({selectedMakerProOnlyActor: selectedActor});
        } else {
          this.setState({selectedPaidActor: selectedActor});
        }
        return;
      }
    }
    if (selectedActor.contestIds.length > 0 && !selectedActor.makerProOnly) {
      return this.setState({selectedActor});
    }
    onPressForActor(selectedActor);
  };

  private handleAccept = () => {
    const {onPressForActor} = this.props;
    const {selectedActor} = this.state;
    if (selectedActor) {
      this.setState({selectedActor: null}, () => {
        onPressForActor(selectedActor);
      });
    }
  };

  private handleRequestClose = () => {
    this.setState({
      selectedActor: null,
      selectedPaidActor: null,
      selectedMakerProOnlyActor: null,
    });
  };

  private modalRenderFavoriteButton = (actor: Actor) => {
    const {enabledFavorite} = this.props;
    if (!enabledFavorite) {
      return null;
    }
    return (
      <ResourceFavoriteButton
        style={{top: 55, right: 10}}
        resourceType={'Actor'}
        resourceId={actor.id}
      />
    );
  };

  private handlePaidValueChange = (value: boolean) => {
    const {navigation} = this.props;
    navigation.setParams({withPaid: value});
    if (this.actorSearchSetting) {
      actorSearchSettingRepository.update({
        ...this.actorSearchSetting,
        withPaid: value,
      });
    } else {
      actorSearchSettingRepository.update({
        withPaid: value,
      });
    }
  };

  private showPaidLabel = () => {
    const {formValues, shopMode} = this.props;
    const {favorite, purchase} = formValues;
    if (shopMode) {
      return true;
    }
    return !favorite && !purchase;
  };

  private disabledItem = (actor: Actor) => {
    const {currentUser} = this.props;
    const {userResourcePurchases} = this.state;
    if (currentUser && currentUser.id === actor.userId) {
      return true;
    }
    if (!userResourcePurchases) {
      return false;
    }
    return userResourcePurchases?.map(v => v.resourceId).includes(actor.id);
  };
}

const aspectRatio = 0.7;

interface RestrictedContestLabelProps {
  style?: StyleProp<ViewStyle>;
}

const RestrictedContestLabel: React.FC<RestrictedContestLabelProps> = props => (
  <ActorLabel {...props}>コンテスト限定</ActorLabel>
);

interface MakerProLabelProps {
  style?: StyleProp<ViewStyle>;
}

const MakerProLabel: React.FC<MakerProLabelProps> = props => (
  <ActorLabel style={[props.style, {backgroundColor: '#ff8f13'}]}>
    MakerPro限定
  </ActorLabel>
);

interface ActorLabelProps extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
}

const ActorLabel: React.FC<ActorLabelProps> = props => (
  <View
    style={[
      {
        backgroundColor: '#f5c71c',
        width: '100%',
        height: 18,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: 0,
        opacity: 0.9,
      },
      props.style,
    ]}>
    <Text
      style={{
        color: '#fff',
        fontSize: 11,
        textAlign: 'center',
      }}>
      {props.children}
    </Text>
  </View>
);

const actionModalDescription = (
  <View>
    <Text
      style={{
        color: '#222',
        fontSize: 14,
        textAlign: 'center',
        marginBottom: 4,
      }}>
      このキャラクターはコンテスト限定{'\n'}
      キャラクターとなっております。
    </Text>
    <Text
      style={{
        color: '#222',
        fontSize: 14,
        textAlign: 'center',
        marginTop: 4,
      }}>
      対象となるコンテストへの応募作品のみに{'\n'}
      ご利用いただけます。
    </Text>
  </View>
);
