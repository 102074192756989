import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import DefaultOutlineButton from '../../../../../shared/buttons/DefaultOutlineButton';
import EnterIcon from '../../../../../shared/icons/EnterIcon';
import WaitingIcon from '../../../../../shared/icons/WaitingIcon';

interface Props {
  waiting: boolean;
  onPress: () => void;
}

const DeleteButton: React.FunctionComponent<Props> = props => {
  const {waiting, onPress} = props;
  return (
    <DefaultOutlineButton
      style={styles.container}
      buttonSize={BUTTON_SIZE}
      unwrappedText={true}
      onPress={onPress}>
      <View style={styles.icon}>
        {waiting ? <WaitingIcon size={12} /> : <EnterIcon size={12} />}
      </View>
      <Text style={styles.text}>{waiting ? '待機' : '登場'}</Text>
    </DefaultOutlineButton>
  );
};

export default React.memo(DeleteButton);

const BUTTON_SIZE = {
  width: 92,
  height: 32,
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 4,
  } as ViewStyle,
  icon: {
    marginRight: 3,
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
