import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/writer/user_video_share_service_accounts/Index';

import AppState from '../../../reducers/AppState';

import indexWriterOauthAccounts, {
  Params as WriterOauthAccountIndexParams,
} from '../../../actions/writer/oauth_accounts/index';

import selectEntities from '../../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries} = state;
  const writerOauthAccounsParams: WriterOauthAccountIndexParams = {};
  const oauthAccounts = selectEntities(
    entities.writer.oauthAccounts,
    queries.writer.oauthAccounts,
    writerOauthAccounsParams,
  );
  return {
    navigation,
    oauthAccounts,
    writerOauthAccounsParams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  props: Props,
) => {
  return {
    indexWriterOauthAccounts: (params: WriterOauthAccountIndexParams) =>
      dispatch(indexWriterOauthAccounts(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
