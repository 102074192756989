import SceneCommand from './SceneCommand';

import CompositeSequenceSceneCommandForm from '../../forms/scene_commands/CompositeSequenceSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class CompositeSequenceSceneCommand implements SceneCommand {
  constructor(public commands: SceneCommand[]) {}

  public getValue() {
    return {};
  }

  public toForm(): SceneCommandForm {
    return new CompositeSequenceSceneCommandForm(
      this.commands.map(command => command.toForm()),
    );
  }
}
