import SceneCommand from './SceneCommand';

import FullScreenIllustration from '../../entities/FullScreenIllustration';
import Sound from '../../entities/Sound';

import FullScreenIllustrationShowSceneCommandForm from '../../forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class FullScreenIllustrationShowSceneCommand
  implements SceneCommand
{
  constructor(
    public fullScreenIllustration: FullScreenIllustration,
    public sound?: Sound | null,
    public startTime?: number | null,
    public endTime?: number | null,
  ) {}

  public getValue() {
    return {
      full_screen_illustration_id: this.fullScreenIllustration.id,
      sound_id: this.sound ? this.sound.id : null,
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }

  public toForm(): SceneCommandForm {
    return new FullScreenIllustrationShowSceneCommandForm(
      this.fullScreenIllustration,
      this.sound || null,
      this.startTime || null,
      this.endTime || null,
    );
  }
}
