import * as React from 'react';
import {Image, ImageStyle, StyleSheet, View, ViewStyle} from 'react-native';

import NewIllustrationUsageHistoryButton from './NewIllustrationUsageHistoryButton';

import convertImageSource from '../../../../../../helpers/convertImageSource';

import illustrationKeyVisualUri from '../../../../../../../assets/images/illustration_key_visual.png';

interface Props {
  onForwardToNewIllustrationUsageHistory: () => void;
}

const NoIllustrationUsageHistories: React.FunctionComponent<Props> = props => {
  const {onForwardToNewIllustrationUsageHistory} = props;
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        resizeMode={'contain'}
        source={convertImageSource(illustrationKeyVisualUri)}
      />
      <View style={styles.button}>
        <NewIllustrationUsageHistoryButton
          onPress={onForwardToNewIllustrationUsageHistory}
        />
      </View>
    </View>
  );
};

export default NoIllustrationUsageHistories;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  image: {
    height: 100,
    width: 130,
    margin: 16,
  } as ImageStyle,
  button: {
    margin: 16,
  },
});
