import * as React from 'react';
import {StyleSheet, Text, TextStyle, ViewStyle} from 'react-native';

import Checkbox from './Checkbox';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import CurrentUserStatus from '../../../../../domain/entities/writer/CurrentUserStatus';

interface Props {
  currentUserStatus: CurrentUserStatus;
  onPressNext: () => void;
}

const FormStep1: React.FunctionComponent<Props> = props => {
  const {currentUserStatus, onPressNext} = props;
  const [disabledNextButton, setDisabledNextButton] = React.useState(true);
  const onToggleAgreed = React.useCallback((agreed: boolean) => {
    setDisabledNextButton(!agreed);
  }, []);
  return (
    <>
      {currentUserStatus.enabledConsumerUser ||
      currentUserStatus.enabledCreatorUser ? (
        <>
          <Text style={styles.defaultMessage}>
            {currentUserStatus.enabledConsumerUser
              ? 'お客様は読書サービスのTapNovel'
              : ''}
            {currentUserStatus.enabledConsumerUser &&
            currentUserStatus.enabledCreatorUser
              ? 'と\n'
              : ''}
            {currentUserStatus.enabledCreatorUser
              ? '素材サービスのTapNovelCreator'
              : ''}
            も{'\n'}
            ご利用いただいております。
          </Text>
          <Text style={styles.defaultMessage}>
            一度退会すると投稿した作品及びアカウント情報は{'\n'}
            すべて消去されます。
          </Text>
        </>
      ) : (
        <Text style={styles.defaultMessage}>
          一度退会すると投稿した作品は{'\n'}すべて消去されます。
        </Text>
      )}
      {currentUserStatus.entryContestsCount > 0 ? (
        <Text style={styles.contestMessage}>
          応募規約に記載の通りコンテストへ{'\n'}応募した作品は消去されません。
        </Text>
      ) : null}

      {currentUserStatus.enabledCreatorUser ? (
        <Text style={styles.warningMessage}>
          尚、購入されたイラストは購入者が{'\n'}
          引き続き利用することができます。
        </Text>
      ) : null}
      <Checkbox onToggleAgreed={onToggleAgreed} />
      <PrimaryButton disabled={disabledNextButton} onPress={onPressNext}>
        次へ
      </PrimaryButton>
    </>
  );
};

export default React.memo(FormStep1);

const styles = StyleSheet.create({
  defaultMessage: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
  contestMessage: {
    color: '#f23406',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
  warningMessage: {
    color: '#f23406',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
});
