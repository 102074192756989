import * as React from 'react';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import BackgroundMusicView from './visualizations/BackgroundMusicView';

import {ModalParams} from '../../Modal';

import SceneFrame from '../../../../../view_models/SceneFrame';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';

interface Props {
  sceneCommandForm: BackgroundMusicShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  sceneFrame: SceneFrame;
  onRequestOpenModal: (modalParams: ModalParams) => void;
}

export default class BackgroundMusicShowSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, ['sceneCommandForm']);
  }

  public render(): React.ReactNode {
    const {sceneCommandForm} = this.props;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <BackgroundMusicView sound={sceneCommandForm.sound} start={true} />
        }
        onRequestOpenModal={this.handleRequestOpen}
      />
    );
  }

  private handleRequestOpen = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      sceneFrame,
      onRequestOpenModal,
    } = this.props;
    onRequestOpenModal({
      type: 'BackgroundMusicShowSceneCommandModal',
      sceneCommandForm,
      parentSceneCommandForm,
      sceneFrame,
    });
  };
}
