import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import EffectMapper, {EffectData} from '../../entities/mappers/EffectMapper';

import Effect from '../../../domain/entities/Effect';
import EffectsRepository from '../../../domain/repositories/writer/EffectsRepository';

export default class NetEffectsRepository
  extends NetResourcesRepository<Effect, EffectData>
  implements EffectsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/effects',
        new EffectMapper(),
      ),
    );
  }
}
