import Entity from './Entity';

export default class EpisodeCommentReply implements Entity {
  constructor(
    public id: number,
    public episodeCommentId: number,
    public comment: string,
    public likesCount: number,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
