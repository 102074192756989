import EntityMapper from '../../../EntityMapper';

import Price from '../../../../../../domain/entities/writer/my_stripe/maker_pro/Price';

export interface PriceData {
  id: string;
  billing_scheme: string;
  currency: string;
  recurring: string;
  tax_behavior: string;
  type: string;
  unit_amount: number;
  unit_amount_decimal: string;
  amount_including_tax: number;
}

export default class PriceMapper implements EntityMapper<PriceData, Price> {
  public map(obj: PriceData): Price {
    return new Price(
      obj.id,
      obj.billing_scheme,
      obj.currency,
      obj.recurring,
      obj.tax_behavior,
      obj.type,
      obj.unit_amount,
      obj.unit_amount_decimal,
      obj.amount_including_tax,
    );
  }
}
