import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

interface Props {
  onPress: () => void;
}

const FormStep0: React.FC<Props> = props => {
  const {onPress} = props;
  return (
    <>
      <View style={styles.container}>
        <Text style={styles.text}>
          タップライターが共同執筆するプロジェクトを{'\n'}
          作成できます。
        </Text>
        <Text style={styles.text}>
          プロジェクトを立ち上げた人がリーダー、{'\n'}
          参加した人はメンバーになります。
        </Text>
        <Text style={styles.text}>
          リーダーの立ち上げた企画内容に沿って、{'\n'}
          メンバーそれぞれがエピソードを執筆することが{'\n'}
          できます。
        </Text>
        <Text style={styles.text}>
          リーダーは、プロジェクトに参加したメンバーが{'\n'}
          執筆したエピソードの監修を行っていただきます。
        </Text>
        <Text style={styles.text}>
          似たようなプロジェクトが既にないか確認の上で{'\n'}
          作成ください。
        </Text>
        <Text style={styles.text}>
          ※条件違いの同プロジェクトは作成できません。
        </Text>
      </View>
      <View style={styles.button}>
        <PrimaryButton onPress={onPress}>プロジェクトを作成する</PrimaryButton>
      </View>
    </>
  );
};

export default React.memo(FormStep0);

const styles = StyleSheet.create({
  container: {
    marginVertical: 28,
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
    marginVertical: 4,
  } as TextStyle,
  button: {
    marginTop: 4,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
