import * as React from 'react';
import {LayoutChangeEvent, StyleSheet, View, ViewStyle} from 'react-native';

import ManuscriptHeader from './ManuscriptHeader';
import ManuscriptList from './ManuscriptList';
import TopicList from './TopicList';
import NoActiveIteration from './NoActiveIteration';

import ScrollableTabViewAdapter, {
  ChangedTabInfo,
} from '../../shared/scrollable_tab_view/ScrollableTabViewAdapter';
import NoticeIcon from '../../shared/NoticeIcon';

import ProjectManuscript from '../../../../domain/entities/ProjectManuscript';
import ProjectTopic from '../../../../domain/entities/ProjectTopic';
import ProjectIteration from '../../../../domain/entities/ProjectIteration';

interface Props {
  projectId: number;
  currentTabPage: number;
  iteration: ProjectIteration | null;
  manuscripts: ProjectManuscript[];
  topics: ProjectTopic[];
  isLeader?: boolean;
  setCurrentTabPage: React.Dispatch<React.SetStateAction<number>>;
  onForceUpdate: () => void;
}

const ProjectTabs: React.FC<Props> = props => {
  const {
    projectId,
    currentTabPage,
    iteration,
    manuscripts,
    topics,
    isLeader,
    setCurrentTabPage,
    onForceUpdate,
  } = props;
  const heights = React.useRef<number[]>([]);
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const onChangeTab = React.useCallback((changedTabInfo: ChangedTabInfo) => {
    setCurrentTabPage(changedTabInfo.i);
  }, []);
  const onLayoutEpisodes = React.useCallback((e: LayoutChangeEvent) => {
    if (e.nativeEvent.layout.height === 0) {
      return;
    }
    heights.current[0] = e.nativeEvent.layout.height;
    forceUpdate();
  }, []);
  const onLayoutTopics = React.useCallback((e: LayoutChangeEvent) => {
    if (e.nativeEvent.layout.height === 0) {
      return;
    }
    heights.current[1] = e.nativeEvent.layout.height;
    forceUpdate();
  }, []);
  return (
    <View
      style={{
        height: heights.current[currentTabPage] + 100,
        borderTopColor: '#efefef',
        borderTopWidth: 1,
        marginTop: 24,
      }}>
      <ScrollableTabViewAdapter
        tabs={[
          {
            label: 'エピソード',
            element: (
              <View onLayout={onLayoutEpisodes}>
                {iteration ? (
                  <ManuscriptList
                    manuscripts={manuscripts}
                    ListHeaderComponent={
                      <ManuscriptHeader
                        projectId={projectId}
                        iteration={iteration}
                        isLeader={isLeader}
                        onForceUpdate={onForceUpdate}
                      />
                    }
                  />
                ) : (
                  <NoActiveIteration
                    projectId={projectId}
                    onForceUpdate={onForceUpdate}
                    isLeader={isLeader}
                  />
                )}
              </View>
            ),
          },
          {
            label: 'トピック',
            element: (
              <View onLayout={onLayoutTopics}>
                <TopicList
                  topics={topics}
                  isLeader={isLeader}
                  onForceUpdate={onForceUpdate}
                />
              </View>
            ),
          },
        ]}
        locked={false}
        onChangeTab={onChangeTab}
      />
      <View style={styles.notices}>
        <View style={styles.notice}>
          {/**
          <NoticeIcon />
          */}
        </View>
        <View style={styles.notice}>
          {/**
          <NoticeIcon />
          */}
        </View>
      </View>
    </View>
  );
};

export default React.memo(ProjectTabs);

const styles = StyleSheet.create({
  notices: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    flexDirection: 'row',
    height: 0,
  } as ViewStyle,
  notice: {
    flex: 1,
    alignItems: 'flex-end',
    margin: 8,
  } as ViewStyle,
});
