import React from 'react';
import {
  InteractionManager,
  View,
  Modal,
  StyleSheet,
  Dimensions,
  TouchableWithoutFeedback,
} from 'react-native';

import {Props, CoachmarkPosition} from './Coachmark';

import CoachmarkView from './CoachmarkView';

import DimensionContext from '../dimension/DimensionContext';

interface State {
  visible: boolean;
  childStyle: {
    top: number;
    left: number;
    width: number;
    height: number;
  };
  position?: CoachmarkPosition;
}

export default class CoachmarkOverlay extends React.PureComponent<
  Props,
  State
> {
  static defaultProps: Props = {
    coachmarkViewChildren: null,
    autoShow: false,
    isAnchorReady: true,
  };

  private view = React.createRef<View>();
  private interval?: ReturnType<typeof setInterval>;

  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (!nextProps.focus && prevState.visible) {
      return {
        visible: false,
      };
    }
    return null;
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      childStyle: {
        top: 0,
        left: 0,
        width: 0,
        height: 0,
      },
    };
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    if (this.props.autoShow && !prevProps.autoShow) {
      this.show();
    } else if (!this.props.autoShow && prevProps.autoShow) {
      this.hide();
    } else if (this.props.autoShow && !this.props.focus && prevProps.focus) {
      this.props.onHide?.();
    }
  }

  public render(): React.ReactNode {
    return (
      <>
        <View ref={this.view} onLayout={this.measureLayout}>
          {React.Children.only(this.props.children)}
        </View>
        <Modal
          animationType={'fade'}
          transparent={true}
          visible={this.props.focus && this.state.visible}>
          <View style={styles.backdrop} />
          <TouchableWithoutFeedback
            disabled={this.props.dialog}
            onPress={this.hide}>
            <View style={StyleSheet.absoluteFill} />
          </TouchableWithoutFeedback>
          {this.state.position === 'bottom' ? (
            <>
              {this.renderCoachmark()}
              {this.renderChildren()}
            </>
          ) : (
            <>
              {this.renderChildren()}
              {this.renderCoachmark()}
            </>
          )}
        </Modal>
      </>
    );
  }

  public show = () => {
    return new Promise<Promise<void>>(resolve => {
      InteractionManager.runAfterInteractions(() => {
        this.interval = setInterval(() => {
          this.isInViewPort().then(isInViewPort => {
            if (isInViewPort) {
              this.stopWatching();
              resolve(this.handleShow());
            }
          });
        }, 200);
      });
    });
  };

  public hide = () => {
    return this.handleHide();
  };

  private isInViewPort = () => {
    return new Promise(resolve => {
      if (!this.props.isAnchorReady || !this.view || !this.view.current) {
        return resolve(false);
      }
      this.view.current.measure((x, y, width, height, pageX, pageY) => {
        const windowHeight = Dimensions.get('window').height;
        const windowWidth = Dimensions.get('window').width;
        const rectBottom = pageY + height;
        const rectTop = pageY;
        const rectLeft = x;
        const rectRight = x + width;
        const isInViewPort =
          rectBottom <= windowHeight &&
          rectTop >= 0 &&
          rectLeft >= 0 &&
          rectRight <= windowWidth;
        if (isInViewPort) {
          this.setState({
            childStyle: {
              top: pageY,
              left: pageX,
              width,
              height,
            },
            position:
              pageY > Dimensions.get('window').height - (pageY + height)
                ? CoachmarkPosition.BOTTOM
                : CoachmarkPosition.TOP,
          });
        }
        resolve(isInViewPort);
      });
    });
  };

  private handleShow = () => {
    this.props.onShow?.();
    this.setState({
      visible: true,
    });
    return new Promise<void>(resolve => {
      this.interval = setInterval(() => {
        if (!this.state.visible) {
          this.stopWatching();
          resolve();
        }
      }, 16);
    });
  };

  private handleHide = () => {
    this.setState({visible: false}, () => {
      this.props.onHide?.();
    });
  };

  private stopWatching = () => {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = undefined;
  };

  private measureLayout = () => {
    if (this.props.autoShow) {
      this.show();
    }
  };

  private renderChildren = () => {
    return (
      <View
        style={[styles.child, this.state.childStyle, this.props.childStyle]}>
        {this.props.children}
      </View>
    );
  };

  private renderCoachmark = () => {
    const {coachmarkViewStyle, arrowStyle, arrowSize, coachmarkViewChildren} =
      this.props;
    return (
      <DimensionContext.Consumer>
        {context => {
          return (
            <TouchableWithoutFeedback
              disabled={this.props.dialog}
              onPress={this.hide}>
              <View
                style={{
                  position: 'absolute',
                  overflow: 'hidden',
                  left: (context.window.width - context.content.width) / 2,
                  right: (context.window.width - context.content.width) / 2,
                  ...(this.state.position === CoachmarkPosition.TOP
                    ? {
                        top:
                          this.state.childStyle.top +
                          this.state.childStyle.height,
                      }
                    : {
                        bottom:
                          Dimensions.get('window').height -
                          this.state.childStyle.top,
                      }),
                }}>
                <CoachmarkView
                  style={coachmarkViewStyle}
                  arrowStyle={arrowStyle}
                  arrowSize={arrowSize}
                  position={this.state.position}>
                  {coachmarkViewChildren}
                </CoachmarkView>
              </View>
            </TouchableWithoutFeedback>
          );
        }}
      </DimensionContext.Consumer>
    );
  };
}

const styles = StyleSheet.create({
  backdrop: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'rgba(27,27,27,0.7)',
  },
  child: {
    position: 'absolute',
  },
});
