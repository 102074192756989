import dateformat from 'dateformat';

import AccountAggregationMapper, {
  AccountAggregationData,
} from '../../../value_objects/mappers/summary/AccountAggregationMapper';

import TapNovelRestApi from '../../../data_stores/net/TapNovelRestApi';

import AccountAggregationsRepository from '../../../../domain/repositories/writer/summary/AccountAggregationsRepository';
import AccountAggregation from '../../../../domain/value_objects/summary/AccountAggregation';

export default class NetAccountAggregationsRepository
  implements AccountAggregationsRepository
{
  private mapper: AccountAggregationMapper;
  constructor() {
    this.mapper = new AccountAggregationMapper();
  }

  public async find(
    options: {from?: Date | undefined; to?: Date | undefined} = {},
  ): Promise<AccountAggregation> {
    const path = `/api/writer/summary/account_aggregation${this.generateQuery(
      options,
    )}`;
    const obj = await TapNovelRestApi.get<AccountAggregationData>(path);
    return this.mapper.map(obj.body);
  }

  private generateQuery(options: {
    from?: Date | undefined;
    to?: Date | undefined;
  }) {
    const ary = [];
    if (options.from) {
      ary.push(`from=${dateformat(options.from, 'yyyy.mm.dd')}`);
    }
    if (options.to) {
      ary.push(`to=${dateformat(options.to, 'yyyy.mm.dd')}`);
    }
    if (ary.length > 0) {
      return `?${ary.join('&')}`;
    } else {
      return '';
    }
  }
}
