import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import InvoiceMapper, {
  InvoiceData,
} from '../../../entities/mappers/writer/my_stripe/InvoiceMapper';

import Invoice from '../../../../domain/entities/writer/my_stripe/Invoice';
import InvoicesRepository from '../../../../domain/repositories/writer/my_stripe/InvoicesRepository';

export default class NetInvoicesRepository
  extends NetResourcesRepository<Invoice, InvoiceData>
  implements InvoicesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/my_stripe/invoices',
        new InvoiceMapper(),
      ),
    );
  }
}
