import * as React from 'react';

import Form from './partials/Form';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';

import {EditEpisodeCommentReplyRouteProp} from '../../navigators/RouteProps';

import {Params as EpisodeCommentReplyUpdateParams} from '../../actions/episode_comment_replies/update';

import EpisodeCommentReply from '../../../domain/entities/EpisodeCommentReply';
import EpisodeComment from '../../../domain/entities/EpisodeComment';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditEpisodeCommentReplyRouteProp;
}

export interface DispatchProps {
  showEpisodeCommentReply: (id: number) => Promise<EpisodeCommentReply>;
  updateEpisodeCommentReply: (
    id: number,
    params: EpisodeCommentReplyUpdateParams,
  ) => Promise<EpisodeCommentReply>;
  showEpisodeComment: (id: number) => Promise<EpisodeComment>;
}

interface Props extends StateProps, DispatchProps {}

const Edit: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    route,
    showEpisodeCommentReply,
    updateEpisodeCommentReply,
    showEpisodeComment,
  } = props;
  const {id} = route.params;
  const [episodeComment, setEpisodeComment] =
    React.useState<EpisodeComment | null>(null);
  const [episodeCommentReply, setEpisodeCommentReply] =
    React.useState<EpisodeCommentReply | null>(null);
  React.useEffect(() => {
    showEpisodeCommentReply(id).then(setEpisodeCommentReply);
  }, []);
  React.useEffect(() => {
    if (episodeCommentReply) {
      showEpisodeComment(episodeCommentReply.episodeCommentId).then(
        setEpisodeComment,
      );
    }
  }, [episodeCommentReply]);
  const handleSubmit = React.useCallback((comment: string) => {
    updateEpisodeCommentReply(id, {comment}).then(episodeCommentReply => {
      showEpisodeComment(episodeCommentReply.episodeCommentId);
    });
    navigation.pop();
  }, []);
  return (
    <Layout
      title={'返信'}
      navigation={navigation}
      close={true}
      hideHeaderBottomBorder={true}>
      {episodeComment && episodeCommentReply && (
        <Form
          initialComment={episodeCommentReply.comment}
          episodeComment={episodeComment}
          onSubmit={handleSubmit}
        />
      )}
    </Layout>
  );
};

export default React.memo(Edit);
