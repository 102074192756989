import * as React from 'react';
import {Animated, Platform, StyleSheet, ViewStyle} from 'react-native';
import SortableList, {RowProps} from 'react-native-sortable-list';
import {shallowEqual} from 'recompose';

import NoCharacters from './NoCharacters';
import CharacterInfoWitMenu from './CharacterInfoWitMenu';

import shouldUpdateCharacterList from '../../shared/enhanced/shouldUpdateCharacterList';

import Character from '../../../../domain/entities/Character';

interface Props {
  characters: Character[];
  sortingEnabled?: boolean;
  onSelectCharacter: (character: Character) => void;
  onChangeOrder: (character: Character, rowOrderPosition: number) => void;
  onStartSort?: () => void;
  onEndSort?: () => void;
  onOpenActionSheet?: (character: Character) => void;
}

interface State {
  characters: Character[];
  order: number[];
}

export default class CharacterList extends React.Component<Props, State> {
  private mounted = false;

  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    const nextOrder = CharacterList.getOrder(nextProps.characters);
    if (shouldUpdateCharacterList(nextProps, prevState)) {
      return {order: nextOrder, characters: nextProps.characters};
    }
    return null;
  }

  private static getOrder = (characters: Character[]) => {
    return characters.map(character => character.id);
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      characters: props.characters,
      order: this.getOrder(props.characters),
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {characters, sortingEnabled} = this.props;
    const {order} = this.state;
    if (characters.length === 0) {
      return <NoCharacters />;
    }
    return (
      <SortableList
        contentContainerStyle={styles.contentContainerStyle}
        scrollEnabled={Platform.select({web: true, default: false})}
        data={this.getData()}
        order={order}
        sortingEnabled={sortingEnabled === undefined ? true : sortingEnabled}
        renderRow={this.renderCustom}
        onPressRow={this.handlePressRow}
        onActivateRow={this.handleActivateRow}
        onChangeOrder={this.handleChangeOrder}
        onReleaseRow={this.handleReleaseRow}
      />
    );
  }

  private getData = (): {[key: number]: Character} => {
    const {characters} = this.props;
    const data: {[key: number]: Character} = {};
    characters.forEach(character => {
      data[character.id] = character;
    });
    return data;
  };

  private renderCustom = (props: RowProps<Character, number>) => {
    const {data, active} = props;
    const {onOpenActionSheet} = this.props;
    return (
      <Animated.View
        key={data.id}
        style={[styles.row, active ? styles.rowActive : null]}>
        <CharacterInfoWitMenu
          character={data}
          onOpenActionSheet={onOpenActionSheet}
        />
      </Animated.View>
    );
  };

  private handlePressRow = (key: number) => {
    const {sortingEnabled, onSelectCharacter} = this.props;
    if (sortingEnabled === false) {
      return;
    }
    const character = this.fetchCharacterFromKey(key);
    if (character) {
      onSelectCharacter(character);
    }
  };

  private handleActivateRow = (key: number) => {
    const {onStartSort} = this.props;
    onStartSort && onStartSort();
  };

  private handleChangeOrder = (nextOrder: number[]) => {
    this.setStateIfMounted({order: nextOrder});
  };

  private handleReleaseRow = (key: number) => {
    const {characters, onChangeOrder, onEndSort} = this.props;
    const {order} = this.state;
    const character = this.fetchCharacterFromKey(key);
    onEndSort && onEndSort();
    if (
      !order ||
      !character ||
      shallowEqual(order, this.getOrder(characters))
    ) {
      return;
    }
    const rowOrderPosition = order.map(o => Number(o)).indexOf(Number(key));
    onChangeOrder(character, rowOrderPosition);
  };

  private fetchCharacterFromKey = (key: number): Character | null => {
    const {characters} = this.props;
    const id = Number(key);
    const character = characters.find(row => row.id === id) || null;
    return character;
  };

  private getOrder = (characters: Character[]) => {
    return CharacterList.getOrder(characters);
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ): void {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }
}

const styles = StyleSheet.create({
  contentContainerStyle: {
    display: Platform.select({
      web: 'block',
      default: undefined,
    }) as any,
  },
  row: {
    flex: 1,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      } as any,
      default: {},
    }),
  } as ViewStyle,
  rowActive: {
    transform: [{scale: 1.03}],
    borderTopColor: '#efefef',
    borderTopWidth: 1,
  } as ViewStyle,
  menu: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 8,
  } as ViewStyle,
});
