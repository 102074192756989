import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

const ArrowUp: React.FC = () => {
  return <View style={styles.container} />;
};

const propsAreEqual = () => true;

export default React.memo(ArrowUp, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '225deg'}],
    marginTop: 6,
  } as ViewStyle,
});
