import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import CheckButton from '../../../shared/forms/CheckButton';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';

import fullVideoUsageRangelUri from '../../../../../assets/svgs/full-video-usage-range.svg';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep3: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        作成した動画は、作品をTapNovelへ投稿した作者本人であれば、ご自由に動画共有サービスへ投稿（配信・収益化を含む）いただくことができます。
      </Text>
      <View style={styles.usageRange}>
        <SvgUriAdapter uri={fullVideoUsageRangelUri} width={303} height={217} />
      </View>
      <CheckButton
        style={styles.checkButton}
        checked={checked}
        onPress={onPressCheck}>
        理解した
      </CheckButton>
      <PrimaryButton disabled={!checked} onPress={onPress}>
        次へ
      </PrimaryButton>
    </View>
  );
};

export default React.memo(ServiceExplanationStep3);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginHorizontal: 24,
  } as TextStyle,
  usageRange: {
    marginTop: 24,
    marginBottom: 16,
  } as ViewStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginBottom: 16,
  } as ViewStyle,
});
