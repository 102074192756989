import AccessToken from '../data_stores/storage/AccessToken';

import AccessTokenRepository from '../../domain/repositories/AccessTokenRepository';

export default class StorageAccessTokenRepository
  implements AccessTokenRepository
{
  public find(): Promise<string | null> {
    return AccessToken.get();
  }

  public update(value: string): Promise<void> {
    return AccessToken.set(value);
  }

  public destroy(): Promise<void> {
    return AccessToken.remove();
  }
}
