import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import Headline from '../../../../../shared/Headline';
import EntityImageGridListWithModal from '../../../../../shared/EntityImageGridListWithModal';
import ResourceFavoriteButton from '../../../../../shared/ResourceFavoriteButton';

import {backgroundUsageHistoryImageUrl} from '../../../../../../helpers/images';

import BackgroundUsageHistory from '../../../../../../../domain/entities/BackgroundUsageHistory';

const IMAGE_SIZE = 'middle';

interface Props {
  backgroundUsageHistories: BackgroundUsageHistory[];
  enabledFavorite?: boolean;
  onSelectBackgroundUsageHistory: (
    backgroundUsageHistory: BackgroundUsageHistory,
  ) => void;
  onForwardToFavoriteList?: () => void;
}

export default class BackgroundUsageHistoryList extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      backgroundUsageHistories,
      onSelectBackgroundUsageHistory,
      onForwardToFavoriteList,
    } = this.props;
    return (
      <>
        <Headline
          title={'使用した背景'}
          onPressFavoriteList={onForwardToFavoriteList}
        />
        <EntityImageGridListWithModal
          entities={backgroundUsageHistories}
          style={styles.container}
          aspectRatio={aspectRatio}
          resizeMode={'cover'}
          ListFooterComponent={ListFooterComponent}
          onSelectEntity={onSelectBackgroundUsageHistory}
          imageUrlExtractor={this.imageUrlExtractor}
          modalRenderFavoriteButton={this.modalRenderFavoriteButton}
        />
      </>
    );
  }

  private imageUrlExtractor = (
    backgroundUsageHistory: BackgroundUsageHistory,
  ) => {
    return backgroundUsageHistoryImageUrl(backgroundUsageHistory, IMAGE_SIZE);
  };

  private modalRenderFavoriteButton = (
    backgroundUsageHistory: BackgroundUsageHistory,
  ) => {
    const {enabledFavorite} = this.props;
    if (!enabledFavorite) {
      return null;
    }
    return (
      <ResourceFavoriteButton
        style={{top: 55, right: 10}}
        resourceType={'BaseBackground'}
        resourceId={backgroundUsageHistory.baseBackground.id}
      />
    );
  };
}

const aspectRatio = 1.25;

const styles = StyleSheet.create({
  container: {
    paddingBottom: Platform.select({web: 75, default: undefined}),
  } as ViewStyle,
  spacer: {
    height: 75,
    width: '100%',
  } as ViewStyle,
});

const ListFooterComponent = Platform.select({
  web: undefined,
  default: <View style={styles.spacer} />,
});
