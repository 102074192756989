import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/character_form/edit/character_publication/Index';

import AppState from '../../../../reducers/AppState';

import showCharacter from '../../../../actions/characters/show';
import updateCharacterPublication, {
  Params as CharacterPublicationUpdateParams,
} from '../../../../actions/character_publications/update';

import selectEntity from '../../../../helpers/selectEntity';

import {CharacterFormEditCharacterPublicationRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CharacterFormEditCharacterPublicationRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities} = state;
  const {characterId} = route.params;
  const character = selectEntity(entities.characters, characterId);
  return {
    character,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCharacter: (id: number) => dispatch(showCharacter(id)),
    updateCharacterPublication: (
      id: number,
      params: CharacterPublicationUpdateParams,
    ) => dispatch(updateCharacterPublication(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
