import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import SoundUsageHistory from '../../../domain/entities/SoundUsageHistory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexSoundUsageHistoriesSuccess: {
      const result = action.payload as PaginatedResult<SoundUsageHistory>;
      return mergeResponse(state, result);
    }
    case Types.UpdateSoundUsageHistorySuccess: {
      const soundUsageHistory = action.payload as SoundUsageHistory;
      const newState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        const filteredIds = response.pageToIds[1].filter(
          id => id !== soundUsageHistory.id,
        );
        if (stateKey.includes(`"storyId":${soundUsageHistory.storyId}`)) {
          if (
            stateKey.includes(`"soundType":"${soundUsageHistory.soundType}"`)
          ) {
            response.pageToIds[1] = [soundUsageHistory.id, ...filteredIds];
          }
        }
        newState[stateKey] = response;
      });
      return newState;
    }
    default:
      return state;
  }
}
