import {Dispatch} from 'redux';

import Types from '../../../Types';

import {sendDefaultCustomEvent, setUser} from '../../../../helpers/analytics';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

import SessionRepository from '../../../../../data/repositories/writer/users/NetSessionRepository';

import AccessTokenRepository from '../../../../../data/repositories/StorageAccessTokenRepository';

export interface Params {
  email?: string | null;
  password?: string | null;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<CurrentUser> {
  return async (dispatch: Dispatch<any>): Promise<CurrentUser> => {
    try {
      const payload = await new SessionRepository().create({
        apiWriterUser: params,
      });
      const accessToken = payload.accessToken;
      if (!accessToken) {
        throw new Error('Authentication Error!');
      }
      new AccessTokenRepository().update(accessToken);
      dispatch({payload, type: Types.CreateWriterCurrentUserSuccess});
      setUser(payload);
      sendDefaultCustomEvent({
        resourceType: 'writer/user_session',
        resourceId: payload.id,
        resourceName: payload.penName,
        actionName: 'create',
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateWriterCurrentUserFailure});
      throw err;
    }
  };
}
