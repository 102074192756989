import * as React from 'react';
import {FlatList, ListRenderItemInfo, Text, View} from 'react-native';

import MemberJoinRequestListItem from './MemberJoinRequestListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectMemberJoinRequest from '../../../../../domain/entities/ProjectMemberJoinRequest';

interface Props {
  memberJoinRequests: ProjectMemberJoinRequest[];
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
  onForceUpdate: () => void;
}

const MemberJoinRequestList: React.FC<Props> = props => {
  const {memberJoinRequests, ListHeaderComponent, onForceUpdate} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ProjectMemberJoinRequest>) => (
      <MemberJoinRequestListItem
        memberJoinRequest={info.item}
        onForceUpdate={onForceUpdate}
      />
    ),
    [],
  );
  if (memberJoinRequests.length === 0) {
    return (
      <>
        {ListHeaderComponent}
        <NoMemberJoinRequestList />
      </>
    );
  }
  return (
    <FlatList
      data={memberJoinRequests}
      renderItem={renderItem}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(MemberJoinRequestList);

const NoMemberJoinRequestList: React.FC = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Text style={{color: '#222', fontSize: 14}}>
        メンバーの参加申請はありません
      </Text>
    </View>
  );
};
