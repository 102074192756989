import Entity from '../../../../domain/entities/Entity';

interface Props {
  entity: Entity | null;
}

export default (current: Props, next: Props) => {
  if (current.entity === next.entity) {
    return false;
  }
  if (!current.entity || !next.entity) {
    return true;
  }
  if (current.entity.id !== next.entity.id) {
    return true;
  }
  return false;
};
