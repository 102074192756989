import * as React from 'react';

import BaseIndex from '../../../shared/generics/character_patterns/Index';
import shouldUpdateCharacterPatternList from '../../../shared/enhanced/shouldUpdateCharacterPatternList';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CharacterFormNewRouteProp} from '../../../../navigators/RouteProps';

import * as routers from '../../../../routers';

import {Params as CharacterPatternIndexParams} from '../../../../actions/character_patterns/index';

import {equalForKeys} from '../../../../helpers/equalForKeys';
import redirectSceneForm from '../../../../helpers/redirectSceneForm';

import CharacterPattern from '../../../../../domain/entities/CharacterPattern';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';
import Position from '../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../domain/forms/SceneForm';
import CurrentUserStatus from '../../../../../domain/entities/writer/CurrentUserStatus';
import ActorSearchSetting from '../../../../../domain/value_objects/ActorSearchSetting';

import StorageActorSearchSettingRepository from '../../../../../data/repositories/StorageActorSearchSettingRepository';

const actorSearchSettingRepository = new StorageActorSearchSettingRepository();

export interface Params {
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CharacterFormNewRouteProp;
  currentUserStatus?: CurrentUserStatus;
  sceneForm: SceneForm | null;
  characterPatternsParams: CharacterPatternIndexParams;
  characterPatterns: CharacterPattern[] | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  private actorSearchSetting: ActorSearchSetting | null = null;

  constructor(props: Props) {
    super(props);
    actorSearchSettingRepository
      .find()
      .then(actorSearchSetting => {
        this.actorSearchSetting = actorSearchSetting;
      })
      .catch(() => {});
  }

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!equalForKeys(this.props, nextProps, ['characterPatternsParams'])) {
      return true;
    }
    if (shouldUpdateCharacterPatternList(this.props, nextProps)) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {enabledFavorite} = this.props;
    return (
      <BaseIndex
        {...this.props}
        onSelectCharacterPattern={this.handleSelectCharacterPattern}
        onForwardToNewCharacterPattern={this.handleForwardToNewCharacterPattern}
        onForwardToNewCharacter={this.handleForwardToNewCharacter}
        labelExtractor={this.labelExtractor}
        onForwardToFavoriteList={this.handleForwardToFavoriteList}
        onPressShoppingList={this.handleForwardToShoppingList}
        characterPatterns={[]}
        enabledFavorite={enabledFavorite}
      />
    );
  }

  private handleSelectCharacterPattern = (
    characterPattern: CharacterPattern,
  ) => {};

  private handleForwardToNewCharacterPattern = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;

    const withPaid =
      this.actorSearchSetting &&
      (this.actorSearchSetting.withPaid === true ||
        this.actorSearchSetting.withPaid === false)
        ? this.actorSearchSetting.withPaid
        : false;

    routers.linkToCharacterFormNewActorSearchForm(navigation, {
      storyId,
      withPaid,
    });
  };

  private handleForwardToNewCharacter = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToCharacterFormNewCharacterMaker(navigation, {
      storyId,
    });
  };

  private labelExtractor = (characterPattern: CharacterPattern) => {
    return characterPattern.character.name;
  };

  private handleForwardToFavoriteList = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToCharacterFormNewActors(navigation, {
      storyId,
      formValues: {
        query: '',
        ageMin: 0,
        ageMax: 6,
        rootCategoryIdToActorCategoryIds: {},
        favorite: true,
      },
    });
  };

  private handleForwardToShoppingList = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToCharacterFormNewActors(navigation, {
      storyId,
      formValues: {
        query: '',
        ageMin: 0,
        ageMax: 6,
        rootCategoryIdToActorCategoryIds: {},
        purchase: true,
      },
    });
  };
}
