import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import AnnouncementCategoryMapper, {
  AnnouncementCategoryData,
} from '../../entities/mappers/writer/AnnouncementCategoryMapper';

import AnnouncementCategory from '../../../domain/entities/writer/AnnouncementCategory';
import AnnouncementCategoriesRepository from '../../../domain/repositories/writer/AnnouncementCategoriesRepository';

export default class NetAnnouncementCategoriesRepository
  extends NetResourcesRepository<AnnouncementCategory, AnnouncementCategoryData>
  implements AnnouncementCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/announcement_categories',
        new AnnouncementCategoryMapper(),
      ),
    );
  }
}
