import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import EditEpisode from '../../../../containers/episodes/Edit';
import EpisodeFormEditEpisodePublication from '../../../../containers/episode_form/edit/episode_publication/Index';
import EpisodeFormEditEpisodePublicationCompletion from '../../../../containers/episode_form/edit/episode_publication_completion/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="EditEpisode">
      <RootStack.Screen
        name="EditEpisode"
        component={EditEpisode}
        options={{title: 'エピソード情報'}}
      />
      <RootStack.Screen
        name="EpisodeFormEditEpisodePublication"
        component={EpisodeFormEditEpisodePublication}
        options={{title: 'エピソードの公開設定'}}
      />
      <RootStack.Screen
        name="EpisodeFormEditEpisodePublicationCompletion"
        component={EpisodeFormEditEpisodePublicationCompletion}
        options={{title: 'エピソードの公開設定'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
