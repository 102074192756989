import * as React from 'react';

import CharacterHideSceneCommandModal from './modal/scene_command_modals/CharacterHideSceneCommandModal';
import CharacterShowOrUpdateSceneCommandModal from './modal/scene_command_modals/CharacterShowOrUpdateSceneCommandModal';
import CurrentSceneFrameModal from './modal/scene_command_modals/CurrentSceneFrameModal';
import DescriptiveTextShowSceneCommandModal from './modal/scene_command_modals/DescriptiveTextShowSceneCommandModal';
import FullScreenIllustrationShowSceneCommandModal from './modal/scene_command_modals/FullScreenIllustrationShowSceneCommandModal';
import IllustrationShowSceneCommandModal from './modal/scene_command_modals/IllustrationShowSceneCommandModal';
import EffectShowSceneCommandModal from './modal/scene_command_modals/EffectShowSceneCommandModal';
import SoundEffectShowSceneCommandModal from './modal/scene_command_modals/SoundEffectShowSceneCommandModal';
import BackgroundMusicShowSceneCommandModal from './modal/scene_command_modals/BackgroundMusicShowSceneCommandModal';
import BackgroundMusicHideSceneCommandModal from './modal/scene_command_modals/BackgroundMusicHideSceneCommandModal';
import CurrentBackgroundMusicShowSceneCommandModal from './modal/scene_command_modals/CurrentBackgroundMusicShowSceneCommandModal';
import SpeechTextShowSceneCommandModal from './modal/scene_command_modals/SpeechTextShowSceneCommandModal';

import SceneFrame, {
  CharacterSceneCommandForm,
} from '../../../view_models/SceneFrame';
import CharacterPatternMapping from '../../../view_models/CharacterPatternMapping';

import {Params as ActorCharacterFaceIndexParams} from '../../../actions/actor_character_faces/index';
import {Params as CharacterPatternCreateParams} from '../../../actions/character_patterns/create';
import {Params as ActorCostumeIndexParams} from '../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../actions/actor_hair_styles/index';
import {Params as ActorAccessorySetIndexParams} from '../../../actions/actor_accessory_sets/index';

import {QueryState} from '../../../reducers/queries/Response';

import ActorCharacterFace from '../../../../domain/entities/ActorCharacterFace';
import Mark from '../../../../domain/entities/Mark';
import SpeechBalloon from '../../../../domain/entities/SpeechBalloon';
import OrientedSpeechBalloon from '../../../../domain/entities/OrientedSpeechBalloon';
import TextFrame from '../../../../domain/entities/TextFrame';
import CharacterPattern from '../../../../domain/entities/CharacterPattern';
import ActorCostume from '../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../domain/entities/ActorHairStyle';
import ActorAccessorySet from '../../../../domain/entities/ActorAccessorySet';
import Sound from '../../../../domain/entities/Sound';
import PaginatedResult from '../../../../domain/results/PaginatedResult';
import CharacterHideSceneCommandForm from '../../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import SceneCommandForm from '../../../../domain/forms/scene_commands/SceneCommandForm';
import Position from '../../../../domain/value_objects/Position';

export type ModalParams =
  | {
      type: 'CharacterHideSceneCommandModal';
      sceneCommandForm: CharacterSceneCommandForm;
      sceneCommandIndex: number;
      parentSceneCommandForm?: SceneCommandForm | null;
    }
  | {
      type: 'CharacterShowOrUpdateSceneCommandModal';
      sceneCommandForm: CharacterSceneCommandForm;
      sceneCommandIndex: number;
      waitable: boolean;
      parentSceneCommandForm?: SceneCommandForm | null;
    }
  | {
      type: 'CurrentSceneFrameModal';
      sceneCommandForm: CharacterSceneCommandForm;
      sceneCommandIndex: number;
      parentSceneCommandForm?: SceneCommandForm | null;
    }
  | {
      type: 'DescriptiveTextShowSceneCommandModal';
      sceneCommandForm: DescriptiveTextShowSceneCommandForm;
      parentSceneCommandForm?: SceneCommandForm | null;
    }
  | {
      type: 'FullScreenIllustrationShowSceneCommandModal';
      sceneCommandForm: FullScreenIllustrationShowSceneCommandForm;
      parentSceneCommandForm?: SceneCommandForm | null;
    }
  | {
      type: 'IllustrationShowSceneCommandModal';
      sceneCommandForm: IllustrationShowSceneCommandForm;
      parentSceneCommandForm?: SceneCommandForm | null;
    }
  | {
      type: 'SpeechTextShowSceneCommandModal';
      sceneCommandForm: SpeechTextShowSceneCommandForm;
      parentSceneCommandForm?: SceneCommandForm | null;
      sceneFrame: SceneFrame;
    }
  | {
      type: 'EffectShowSceneCommandModal';
      sceneCommandForm: EffectShowSceneCommandForm;
      parentSceneCommandForm?: SceneCommandForm | null;
      sceneFrame: SceneFrame;
    }
  | {
      type: 'SoundEffectShowSceneCommandModal';
      sceneCommandForm: SoundEffectShowSceneCommandForm;
      parentSceneCommandForm?: SceneCommandForm | null;
      sceneFrame: SceneFrame;
    }
  | {
      type: 'BackgroundMusicShowSceneCommandModal';
      sceneCommandForm: BackgroundMusicShowSceneCommandForm;
      parentSceneCommandForm?: SceneCommandForm | null;
      sceneFrame: SceneFrame;
    }
  | {
      type: 'BackgroundMusicHideSceneCommandModal';
      sceneCommandForm: BackgroundMusicHideSceneCommandForm;
      parentSceneCommandForm?: SceneCommandForm | null;
      sceneFrame: SceneFrame;
    }
  | {
      type: 'CurrentBackgroundMusicShowSceneCommandModal';
      sceneCommandForm: BackgroundMusicShowSceneCommandForm;
      sceneCommandIndex: number;
      parentSceneCommandForm?: SceneCommandForm | null;
    };

interface Props {
  storyId: number;
  modalParams: ModalParams;
  actorCharacterFaceEntities: {[key: number]: ActorCharacterFace};
  actorCharacterFaceQueries: QueryState;
  marks: Mark[] | null;
  speechBalloons: SpeechBalloon[] | null;
  orientedSpeechBalloons: OrientedSpeechBalloon[] | null;
  textFrames: TextFrame[] | null;
  enableVoice?: boolean;
  enableSoundEffect?: boolean;
  enableTrimming?: boolean;

  onRequestCloseModal: () => void;

  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
  onChangeCharacterShowOrUpdateCommand: (
    sceneCommandForm: CharacterSceneCommandForm,
    characterPatternMapping?: CharacterPatternMapping,
  ) => void;
  onInsertCharacterUpdateCommandAt: (
    sceneCommandForm: CharacterSceneCommandForm,
    sceneCommandIndex: number,
    characterPatternMapping?: CharacterPatternMapping,
  ) => void;
  onRemoveCharacterShowOrUpdateCommand: (
    sceneCommandForm: CharacterSceneCommandForm,
  ) => void;
  onInsertCharacterHideCommandAt: (
    sceneCommandForm: CharacterSceneCommandForm,
    sceneCommandIndex: number,
  ) => void;
  onRemoveCharacterHideCommand: (
    sceneCommandForm: CharacterHideSceneCommandForm,
  ) => void;

  onChangeSpeechTextShowSceneCommandForm: (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
  ) => void;
  onRemoveSpeechTextShowCommand: (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
  ) => void;

  onChangeDescriptiveTextShowSceneCommandForm: (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
  ) => void;
  onRemoveDescriptiveTextShowCommand: (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
  ) => void;

  onForwardToChangeIllustrations: (
    sceneCommandForm: IllustrationShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => void;
  onRemoveIllustrationShowCommand: (
    sceneCommandForm: IllustrationShowSceneCommandForm,
  ) => void;
  onForwardToAddSoundEffects: (
    sceneCommandForm:
      | IllustrationShowSceneCommandForm
      | FullScreenIllustrationShowSceneCommandForm
      | EffectShowSceneCommandForm
      | SpeechTextShowSceneCommandForm
      | DescriptiveTextShowSceneCommandForm,
    parentSceneCommandId?: number,
    callback?: (sound: Sound) => void,
  ) => void;
  onChangeIllustrationShowSceneCommandForm: (
    sceneCommandForm: IllustrationShowSceneCommandForm,
  ) => void;

  onForwardToChangeFullScreenIllustrations: (
    sceneCommandForm: FullScreenIllustrationShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => void;
  onRemoveFullScreenIllustrationShowCommand: (
    sceneCommandForm: FullScreenIllustrationShowSceneCommandForm,
  ) => void;
  onChangeFullScreenIllustrationShowSceneCommandForm: (
    sceneCommandForm: FullScreenIllustrationShowSceneCommandForm,
  ) => void;

  onForwardToChangeEffects: (
    sceneCommandForm: EffectShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => void;
  onRemoveEffectShowCommand: (
    sceneCommandForm: EffectShowSceneCommandForm,
  ) => void;
  onChangeEffectShowSceneCommandForm: (
    sceneCommandForm: EffectShowSceneCommandForm,
  ) => void;

  onForwardToChangeSoundEffects: (
    sceneCommandForm: SoundEffectShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => void;
  onRemoveSoundEffectShowCommand: (
    sceneCommandForm: SoundEffectShowSceneCommandForm,
  ) => void;

  onForwardToBackgroundMusic: (params: {
    sceneCommandIndex: number;
    parentSceneCommandId?: number;
  }) => void;
  onForwardToChangeBackgroundMusic: (
    sceneCommandForm: BackgroundMusicShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => void;
  onRemoveBackgroundMusicShowCommand: (
    sceneCommandForm: BackgroundMusicShowSceneCommandForm,
  ) => void;
  onInsertBackgroundMusicHideCommandAt: (
    sceneCommandForm: BackgroundMusicShowSceneCommandForm,
    sceneCommandIndex: number,
  ) => void;

  onChangeCommand: (
    sceneCommandForm: SceneCommandForm,
    characterPatternMapping?: CharacterPatternMapping,
  ) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;

  onRequestIndexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  onRequestIndexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  onRequestIndexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  onRequestIndexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  onRequestIndexMarks: () => Promise<PaginatedResult<Mark>>;
  onRequestIndexSpeechBalloons: () => void;
  onRequestIndexOrientedSpeechBalloons: () => void;
  onRequestIndexTextFrames: () => void;
  onRequestCreateCharacterPattern: (
    params: CharacterPatternCreateParams,
  ) => Promise<CharacterPattern>;
  onRequestUpdateModal: (modalParams: ModalParams) => void;
}

export default class Modal extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      storyId,
      modalParams,
      marks,
      speechBalloons,
      orientedSpeechBalloons,
      textFrames,
      actorCharacterFaceEntities,
      actorCharacterFaceQueries,
      enableVoice,
      enableSoundEffect,
      enableTrimming,
      onRequestCloseModal,
      onForwardToCharacters,
      onChangeCharacterShowOrUpdateCommand,
      onInsertCharacterUpdateCommandAt,
      onRemoveCharacterShowOrUpdateCommand,
      onInsertCharacterHideCommandAt,
      onRemoveCharacterHideCommand,
      onChangeSpeechTextShowSceneCommandForm,
      onRemoveSpeechTextShowCommand,
      onChangeDescriptiveTextShowSceneCommandForm,
      onRemoveDescriptiveTextShowCommand,
      onForwardToChangeIllustrations,
      onRemoveIllustrationShowCommand,
      onChangeIllustrationShowSceneCommandForm,
      onForwardToAddSoundEffects,
      onForwardToChangeFullScreenIllustrations,
      onRemoveFullScreenIllustrationShowCommand,
      onChangeFullScreenIllustrationShowSceneCommandForm,
      onForwardToChangeEffects,
      onRemoveEffectShowCommand,
      onChangeEffectShowSceneCommandForm,
      onForwardToChangeSoundEffects,
      onRemoveSoundEffectShowCommand,
      onForwardToBackgroundMusic,
      onForwardToChangeBackgroundMusic,
      onRemoveBackgroundMusicShowCommand,
      onInsertBackgroundMusicHideCommandAt,
      onRequestIndexActorCharacterFaces,
      onRequestIndexMarks,
      onRequestIndexSpeechBalloons,
      onRequestIndexOrientedSpeechBalloons,
      onRequestIndexTextFrames,
      onChangeCommand,
      onRemoveCommand,
      onRequestCreateCharacterPattern,
      onRequestIndexActorCostumes,
      onRequestIndexActorHairStyles,
      onRequestIndexActorAccessorySets,
      onRequestUpdateModal,
    } = this.props;
    switch (modalParams.type) {
      case 'CharacterHideSceneCommandModal':
        return (
          <CharacterHideSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            onRequestCloseModal={onRequestCloseModal}
            onRemoveCharacterHideCommand={onRemoveCharacterHideCommand}
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
          />
        );
      case 'CharacterShowOrUpdateSceneCommandModal':
        return (
          <CharacterShowOrUpdateSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            sceneCommandIndex={modalParams.sceneCommandIndex}
            waitable={modalParams.waitable}
            actorCharacterFaceEntities={actorCharacterFaceEntities}
            actorCharacterFaceQueries={actorCharacterFaceQueries}
            marks={marks}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            onRequestCloseModal={onRequestCloseModal}
            onForwardToCharacters={onForwardToCharacters}
            onChangeCharacterShowOrUpdateCommand={
              onChangeCharacterShowOrUpdateCommand
            }
            onInsertCharacterHideCommandAt={onInsertCharacterHideCommandAt}
            onRemoveCharacterShowOrUpdateCommand={
              onRemoveCharacterShowOrUpdateCommand
            }
            onRequestIndexActorCharacterFaces={
              onRequestIndexActorCharacterFaces
            }
            onRequestIndexMarks={onRequestIndexMarks}
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
            onRequestCreateCharacterPattern={onRequestCreateCharacterPattern}
            onRequestIndexActorCostumes={onRequestIndexActorCostumes}
            onRequestIndexActorHairStyles={onRequestIndexActorHairStyles}
            onRequestIndexActorAccessorySets={onRequestIndexActorAccessorySets}
          />
        );
      case 'CurrentSceneFrameModal':
        return (
          <CurrentSceneFrameModal
            sceneCommandForm={modalParams.sceneCommandForm}
            sceneCommandIndex={modalParams.sceneCommandIndex}
            actorCharacterFaceEntities={actorCharacterFaceEntities}
            actorCharacterFaceQueries={actorCharacterFaceQueries}
            marks={marks}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            onRequestCloseModal={onRequestCloseModal}
            onForwardToCharacters={onForwardToCharacters}
            onInsertCharacterUpdateCommandAt={onInsertCharacterUpdateCommandAt}
            onInsertCharacterHideCommandAt={onInsertCharacterHideCommandAt}
            onRequestIndexActorCharacterFaces={
              onRequestIndexActorCharacterFaces
            }
            onRequestCreateCharacterPattern={onRequestCreateCharacterPattern}
            onRequestIndexActorCostumes={onRequestIndexActorCostumes}
            onRequestIndexActorHairStyles={onRequestIndexActorHairStyles}
            onRequestIndexMarks={onRequestIndexMarks}
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
            onRequestIndexActorAccessorySets={onRequestIndexActorAccessorySets}
          />
        );
      case 'DescriptiveTextShowSceneCommandModal':
        return (
          <DescriptiveTextShowSceneCommandModal
            storyId={storyId}
            sceneCommandForm={modalParams.sceneCommandForm}
            textFrames={textFrames}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            enableVoice={enableVoice}
            onRequestCloseModal={onRequestCloseModal}
            onRemoveDescriptiveTextShowCommand={
              onRemoveDescriptiveTextShowCommand
            }
            onChangeDescriptiveTextShowSceneCommandForm={
              onChangeDescriptiveTextShowSceneCommandForm
            }
            onRequestIndexTextFrames={onRequestIndexTextFrames}
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
            onForwardToAddSoundEffects={onForwardToAddSoundEffects}
            onRequestUpdateModal={onRequestUpdateModal}
          />
        );
      case 'FullScreenIllustrationShowSceneCommandModal':
        return (
          <FullScreenIllustrationShowSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            onRequestCloseModal={onRequestCloseModal}
            onForwardToChangeFullScreenIllustrations={
              onForwardToChangeFullScreenIllustrations
            }
            onRemoveFullScreenIllustrationShowCommand={
              onRemoveFullScreenIllustrationShowCommand
            }
            onChangeFullScreenIllustrationShowSceneCommandForm={
              onChangeFullScreenIllustrationShowSceneCommandForm
            }
            onForwardToAddSoundEffects={onForwardToAddSoundEffects}
            onRequestUpdateModal={onRequestUpdateModal}
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
          />
        );
      case 'IllustrationShowSceneCommandModal':
        return (
          <IllustrationShowSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            enableSoundEffect={enableSoundEffect}
            onRequestCloseModal={onRequestCloseModal}
            onForwardToChangeIllustrations={onForwardToChangeIllustrations}
            onRemoveIllustrationShowCommand={onRemoveIllustrationShowCommand}
            onChangeIllustrationShowSceneCommandForm={
              onChangeIllustrationShowSceneCommandForm
            }
            onForwardToAddSoundEffects={onForwardToAddSoundEffects}
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
            onRequestUpdateModal={onRequestUpdateModal}
          />
        );
      case 'SpeechTextShowSceneCommandModal':
        return (
          <SpeechTextShowSceneCommandModal
            storyId={storyId}
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            speechBalloons={speechBalloons}
            sceneFrame={modalParams.sceneFrame}
            orientedSpeechBalloons={orientedSpeechBalloons}
            enableVoice={enableVoice}
            onRequestCloseModal={onRequestCloseModal}
            onRemoveSpeechTextShowCommand={onRemoveSpeechTextShowCommand}
            onChangeSpeechTextShowSceneCommandForm={
              onChangeSpeechTextShowSceneCommandForm
            }
            onRequestIndexSpeechBalloons={onRequestIndexSpeechBalloons}
            onRequestIndexOrientedSpeechBalloons={
              onRequestIndexOrientedSpeechBalloons
            }
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
            onForwardToAddSoundEffects={onForwardToAddSoundEffects}
            onRequestUpdateModal={onRequestUpdateModal}
          />
        );
      case 'EffectShowSceneCommandModal':
        return (
          <EffectShowSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            enableSoundEffect={enableSoundEffect}
            sceneFrame={modalParams.sceneFrame}
            onRequestCloseModal={onRequestCloseModal}
            onForwardToChangeEffects={onForwardToChangeEffects}
            onRemoveEffectShowCommand={onRemoveEffectShowCommand}
            onChangeEffectShowSceneCommandForm={
              onChangeEffectShowSceneCommandForm
            }
            onForwardToAddSoundEffects={onForwardToAddSoundEffects}
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
            onRequestUpdateModal={onRequestUpdateModal}
          />
        );
      case 'SoundEffectShowSceneCommandModal':
        return (
          <SoundEffectShowSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            enableTrimming={enableTrimming}
            onRequestCloseModal={onRequestCloseModal}
            onForwardToChangeSoundEffects={onForwardToChangeSoundEffects}
            onRemoveSoundEffectShowCommand={onRemoveSoundEffectShowCommand}
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
          />
        );
      case 'BackgroundMusicShowSceneCommandModal':
        return (
          <BackgroundMusicShowSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            onRequestCloseModal={onRequestCloseModal}
            onForwardToChangeBackgroundMusic={onForwardToChangeBackgroundMusic}
            onRemoveBackgroundMusicShowCommand={
              onRemoveBackgroundMusicShowCommand
            }
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
          />
        );
      case 'BackgroundMusicHideSceneCommandModal':
        return (
          <BackgroundMusicHideSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            onRequestCloseModal={onRequestCloseModal}
            onRemoveBackgroundMusicShowCommand={
              onRemoveBackgroundMusicShowCommand
            }
            onChangeCommand={onChangeCommand}
            onRemoveCommand={onRemoveCommand}
          />
        );
      case 'CurrentBackgroundMusicShowSceneCommandModal':
        return (
          <CurrentBackgroundMusicShowSceneCommandModal
            sceneCommandForm={modalParams.sceneCommandForm}
            parentSceneCommandForm={modalParams.parentSceneCommandForm}
            sceneCommandIndex={modalParams.sceneCommandIndex}
            onRequestCloseModal={onRequestCloseModal}
            onForwardToBackgroundMusic={onForwardToBackgroundMusic}
            onRemoveBackgroundMusicShowCommand={
              onRemoveBackgroundMusicShowCommand
            }
            onInsertBackgroundMusicHideCommandAt={
              onInsertBackgroundMusicHideCommandAt
            }
            onRemoveCommand={onRemoveCommand}
          />
        );
    }
    return null;
  }
}
