import * as React from 'react';

import NoEntities from '../../shared/NoEntities';

interface Props {
  type: string;
}

const NoStories: React.FunctionComponent<Props> = props => {
  const {type} = props;
  return <NoEntities>{type}のストーリーがありません</NoEntities>;
};

export default React.memo(NoStories);
