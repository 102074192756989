import SceneCommand from './SceneCommand';

import Effect from '../../entities/Effect';
import Sound from '../../entities/Sound';

import Position from '../Position';

import EffectShowSceneCommandForm from '../../forms/scene_commands/EffectShowSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class EffectShowSceneCommand implements SceneCommand {
  constructor(
    public effect: Effect,
    public position: Position,
    public startIndex?: number | null,
    public endIndex?: number | null,
    public sound?: Sound | null,
    public startTime?: number | null,
    public endTime?: number | null,
  ) {}

  public getValue() {
    return {
      effect_id: this.effect.id,
      position: this.position.toString(),
      start_index: this.startIndex,
      end_index: this.endIndex,
      sound_id: this.sound?.id,
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }

  public toForm(): SceneCommandForm {
    return new EffectShowSceneCommandForm(
      this.effect,
      this.position,
      this.startIndex,
      this.endIndex,
      this.sound,
      this.startTime || null,
      this.endTime || null,
    );
  }
}
