import React from 'react';

import RootStack from '../../../../RootStack';

import SceneFormEffectShowSceneCommandNewBaseEffectSearchForm from '../../../../../containers/scene_form/effect_show_scene_command/new/base_effect_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormEffectShowSceneCommandNewBaseEffectSearchForm">
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandNewBaseEffectSearchForm"
        component={SceneFormEffectShowSceneCommandNewBaseEffectSearchForm}
        options={{title: 'エフェクト検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
