import * as React from 'react';
import {View, ViewStyle} from 'react-native';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import FullScreenIllustrationView from './visualizations/FullScreenIllustrationView';

import {ModalParams} from '../../Modal';

import SoundIcon from '../../../../shared/icons/SoundIcon';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';

const soundIconStyle = {
  position: 'absolute',
  top: 10,
  width: 24,
  height: 24,
  borderRadius: 12,
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  justifyContent: 'center',
  alignItems: 'center',
} as ViewStyle;

interface Props {
  sceneCommandForm: FullScreenIllustrationShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestOpenModal: (modalParams: ModalParams) => void;
}

export default class FullScreenIllustrationShowSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, ['sceneCommandForm']);
  }

  public render(): React.ReactNode {
    const {sceneCommandForm} = this.props;
    return (
      <DimensionContext.Consumer>
        {context => (
          <SceneCommandBoxWithModal
            sceneCommandBox={
              <>
                <FullScreenIllustrationView
                  fullScreenIllustration={
                    sceneCommandForm.fullScreenIllustration
                  }
                />
                {sceneCommandForm.sound ? (
                  <View
                    style={[
                      soundIconStyle,
                      {right: context.content.width / 2 - 90},
                    ]}>
                    <SoundIcon size={13} />
                  </View>
                ) : null}
              </>
            }
            onRequestOpenModal={this.handleRequestOpen}
          />
        )}
      </DimensionContext.Consumer>
    );
  }

  private handleRequestOpen = () => {
    const {sceneCommandForm, parentSceneCommandForm, onRequestOpenModal} =
      this.props;
    onRequestOpenModal({
      type: 'FullScreenIllustrationShowSceneCommandModal',
      sceneCommandForm,
      parentSceneCommandForm,
    });
  };
}
