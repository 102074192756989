import * as React from 'react';

import Form from './partials/Form';

import Layout from '../shared/Layout';
import AlertModal from '../shared/modals/AlertModal';

import NavigationProp from '../../navigators/NavigationProp';
import {EditEpisodeRouteProp} from '../../navigators/RouteProps';

import * as routers from '../../routers';

import {Params as EpisodeUpdate} from '../../actions/episodes/update';

import {formatErrorMessages} from '../../helpers/errorMessages';

import Episode from '../../../domain/entities/Episode';
import Story from '../../../domain/entities/Story';
import CurrentUser from '../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';
import EpisodeForm from '../../../domain/forms/EpisodeForm';

const title = 'エピソード情報';

export interface Params {
  episodeId: number;
  toBePublished?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditEpisodeRouteProp;
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
  episode: Episode | null;
}

export interface DispatchProps {
  showEpisode: (id: number) => Promise<Episode>;
  showStory: (id: number) => Promise<Story>;
  updateEpisode: (id: number, params: EpisodeUpdate) => Promise<Episode>;
  showCurrentUser: () => Promise<CurrentUser>;
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
  story: Story | null;
}

export default class Edit extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      story: null,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      showEpisode,
      showStory,
      showCurrentUser,
      showCurrentUserStatus,
    } = this.props;
    const {episodeId} = route.params || {episodeId: undefined};
    showCurrentUser();
    showCurrentUserStatus();
    showEpisode(episodeId)
      .then(episode => {
        showStory(episode.storyId).then(story => {
          this.setState({story});
        });
      })
      .catch(e => {
        if (e.status === 401 || e.status === 404) {
          navigation.goBack();
        }
      });
  }

  public render(): React.ReactNode {
    const {navigation, episode, currentUser, currentUserStatus} = this.props;
    const {loading, alertMessage, story} = this.state;
    const episodeForm = new EpisodeForm();
    if (episode) {
      episodeForm.bind(episode);
    }
    const enabledPartnerProgram = currentUserStatus
      ? currentUserStatus.extensionsCodes.includes('partner_program') &&
        currentUserStatus.serviceAgreementCodes.includes('partner_program')
      : false;
    return (
      <Layout
        title={title}
        scrollable={true}
        navigation={navigation}
        close={true}
        loading={loading}>
        {episode && story && (
          <Form
            episodeForm={episodeForm}
            enableOption={!currentUser?.isNotGradeBlack()}
            disableOption={story.allowedVideoSharing && episode.free}
            allowedVideoSharing={story.allowedVideoSharing}
            enabledPartnerProgram={enabledPartnerProgram}
            onSubmit={this.handleSubmit}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleSubmit = (
    episodeform: EpisodeForm,
    afterSubmit: () => void,
  ) => {
    const {navigation, route, episode, updateEpisode, showStory} = this.props;
    const {toBePublished} = route.params;
    if (!episode) {
      return;
    }
    this.setState({loading: true}, () => {
      updateEpisode(episode.id, episodeform.toParams())
        .then(() => {
          this.setState({loading: false}, () => {
            navigation.goBack();
            if (toBePublished) {
              routers.linkToEpisodeFormEditEpisodePublication(navigation, {
                episodeId: episode.id,
              });
            }
            showStory(episode.storyId);
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        })
        .then(() => {
          afterSubmit();
        });
    });
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };
}
