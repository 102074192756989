import EntityMapper from './EntityMapper';

import BackgroundMapper, {BackgroundData} from './BackgroundMapper';
import BaseBackgroundMapper, {BaseBackgroundData} from './BaseBackgroundMapper';

import BackgroundUsageHistory from '../../../domain/entities/BackgroundUsageHistory';

export interface BackgroundUsageHistoryData {
  id: number;
  story_id: number;
  background: BackgroundData;
  base_background: BaseBackgroundData;
  last_used_at: string;
  created_at: string;
}

export default class BackgroundUsageHistoryMapper
  implements EntityMapper<BackgroundUsageHistoryData, BackgroundUsageHistory>
{
  private backgroundMapper = new BackgroundMapper();
  private baseBackgroundMapper = new BaseBackgroundMapper();

  public map(obj: BackgroundUsageHistoryData): BackgroundUsageHistory {
    return new BackgroundUsageHistory(
      obj.id,
      obj.story_id,
      this.backgroundMapper.map(obj.background),
      this.baseBackgroundMapper.map(obj.base_background),
      new Date(obj.last_used_at),
      new Date(obj.created_at),
    );
  }
}
