import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import DetailEpisode from './partials/DetailEpisode';
import DetailIdea from './partials/DetailIdea';
import DetailPlot from './partials/DetailPlot';
import CommentListHeader from './partials/CommentListHeader';
import CommentList from './partials/CommentList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectManuscriptRouteProp} from '../../../navigators/RouteProps';

import ProjectManuscript from '../../../../domain/entities/ProjectManuscript';
import ProjectManuscriptComment from '../../../../domain/entities/ProjectManuscriptComment';
import ProjectManuscriptCommentLike from '../../../../domain/entities/ProjectManuscriptCommentLike';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectManuscriptRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {id, projectId} = route.params;
  const [manuscript, setManuscript] = React.useState<ProjectManuscript | null>(
    null,
  );
  const [comments, setComments] = React.useState<
    ProjectManuscriptComment[] | null
  >(null);
  const [commentLikes, setCommentLikes] = React.useState<
    ProjectManuscriptCommentLike[] | null
  >(null);
  const fetchEntities = React.useCallback(() => {
    TapNovelTypedRestApi.get<ProjectManuscript>(
      `/api/writer/project_manuscripts/${id}`,
    ).then(result => {
      setManuscript(result.body);
    });
    TapNovelTypedRestApi.get<ProjectManuscriptComment[]>(
      '/api/writer/project_manuscript_comments',
      {projectManuscriptId: id},
    ).then(result => {
      setComments(result.body);
    });
    TapNovelTypedRestApi.get<ProjectManuscriptCommentLike[]>(
      '/api/writer/project_manuscript_comment_likes',
      {projectManuscriptId: id},
    ).then(result => {
      setCommentLikes(result.body);
    });
  }, []);
  useFocusEffect(fetchEntities);
  const title =
    manuscript?.typeCode === 'idea'
      ? 'エピソードアイデア'
      : manuscript?.typeCode === 'plot'
      ? 'エピソードプロット'
      : manuscript?.typeCode === 'episode'
      ? 'エピソード'
      : '';
  return (
    <Layout
      title={title}
      navigation={navigation}
      back={true}
      enabledUserSelect={true}>
      {manuscript && comments && commentLikes && (
        <CommentList
          comments={comments}
          commentLikes={commentLikes}
          ListHeaderComponent={
            <CommentListHeader
              manuscript={manuscript}
              commentsCount={comments.length}>
              {manuscript.typeCode === 'idea' ? (
                <DetailIdea manuscript={manuscript} />
              ) : manuscript.typeCode === 'plot' ? (
                <DetailPlot manuscript={manuscript} />
              ) : manuscript.typeCode === 'episode' ? (
                <DetailEpisode manuscript={manuscript} />
              ) : null}
            </CommentListHeader>
          }
        />
      )}
    </Layout>
  );
};

export default React.memo(Show);
