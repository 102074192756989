import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import NetCharactersRepository from '../../../data/repositories/writer/NetCharactersRepository';

export default function action(
  id: number,
): (dispatch: Dispatch<any>) => Promise<number> {
  return async (dispatch: Dispatch<any>): Promise<number> => {
    try {
      await new NetCharactersRepository().destroy(id);
      const payload = id;
      dispatch({payload, type: Types.DestroyCharacterRequest});
      sendDefaultCustomEvent({
        resourceType: 'character',
        resourceId: id,
        actionName: 'destroy',
      });
      return id;
    } catch (err) {
      dispatch({type: Types.DestroyCharacterFailure});
      throw err;
    }
  };
}
