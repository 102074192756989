import * as React from 'react';
import {Platform, ViewStyle} from 'react-native';
import {WebViewMessageEvent} from 'react-native-webview';

import Tabs from '../stories/partials/Tabs';

import MenuIcon from '../shared/icons/MenuIcon';
import NotificationIconWithBadgeCount from '../shared/NotificationIconWithBadgeCount';
import Layout from '../shared/Layout';
import DeleteAccountCompletionModal from '../shared/modals/DeleteAccountCompletionModal';
import TapNovelWebView from '../shared/TapNovelWebView';
import AlertModal from '../shared/modals/AlertModal';

import WelcomeTapNovel from '../stories/partials/WelcomeTapNovel';
import StoryDeleteConfirmModal from '../stories/partials/StoryDeleteConfirmModal';

import shouldUpdateStoryList from '../shared/enhanced/shouldUpdateStoryList';

import NavigationProp from '../../navigators/NavigationProp';
import {HomeRouteProp} from '../../navigators/RouteProps';
import dispatchAction from '../../navigators/dispatchAction';

import * as routers from '../../routers';

import {Params as CurrentUserShowParams} from '../../actions/writer/current_user/show';
import {Params as StoryIndexParams} from '../../actions/stories/index';
import {Params as FacebookAccountSessionCreateParams} from '../../actions/writer/social_accounts/facebook_accounts/session/create';
import {Params as LineAccountSessionCreateParams} from '../../actions/writer/social_accounts/line_accounts/session/create';
import {Params as TwitterAccountSessionCreateParams} from '../../actions/writer/social_accounts/twitter_accounts/session/create';

import {formatErrorMessages} from '../../helpers/errorMessages';
import {equalForKeys} from '../../helpers/equalForKeys';

import Story from '../../../domain/entities/Story';
import CurrentUser from '../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import {getUserAgent} from '../../../data/data_stores/net/UserAgent';

const WEBVIEW_STYLE: ViewStyle = {flex: 1};
const WEBVIEW_ORIGINWHITELIST = ['*'];
const WEBVIEW_PATH = '/writer';

export interface Params {
  page?: number;
  showDeleteAccountCompletion?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: HomeRouteProp;
  accessToken: {value: string | null} | null;
  currentUser: CurrentUser | null;
  serializedStories: Story[] | null;
  serializedStoriesParams: StoryIndexParams;
  completedStories: Story[] | null;
  completedStoriesParams: StoryIndexParams;
  currentUserStatus: CurrentUserStatus | null;
}

export interface DispatchProps {
  showCurrentUser: (params?: CurrentUserShowParams) => Promise<CurrentUser>;
  indexStories: (params: StoryIndexParams) => Promise<PaginatedResult<Story>>;
  destroyStory: (id: number) => Promise<number>;
  createFacebookAccountSession: (
    params: FacebookAccountSessionCreateParams,
  ) => Promise<CurrentUser>;
  createLineAccountSession: (
    params: LineAccountSessionCreateParams,
  ) => Promise<CurrentUser>;
  createTwitterAccountSession: (
    params: TwitterAccountSessionCreateParams,
  ) => Promise<CurrentUser>;
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  nextPageForSerialized?: number | null;
  nextPageForCompleted?: number | null;
  userAgent?: string | null;
  deleteStory: Story | null;
  alertMessage?: string;
}

export default class Index extends React.Component<Props, State> {
  private disabledNewStoryButton = false;
  private redirectWriterUserRegistrationNavigationOnce = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      nextPageForSerialized: null,
      nextPageForCompleted: null,
      userAgent: null,
      deleteStory: null,
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    if (this.disabledNewStoryButton) {
      return false;
    }
    if (
      !equalForKeys(this.props, nextProps, [
        'storiesParams',
        'currentUser',
        'accessToken',
        'route',
        'currentUserStatus',
      ])
    ) {
      return true;
    }
    if (
      shouldUpdateStoryList(
        {stories: this.props.serializedStories},
        {stories: nextProps.serializedStories},
      )
    ) {
      return true;
    }
    if (
      shouldUpdateStoryList(
        {stories: this.props.completedStories},
        {stories: nextProps.completedStories},
      )
    ) {
      return true;
    }
    if (!equalForKeys(this.state, nextState)) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {navigation, currentUser, showCurrentUser, showCurrentUserStatus} =
      this.props;
    getUserAgent()
      .then(userAgent => {
        this.setState({userAgent});
      })
      .catch(() => {
        this.setState({userAgent: ''});
      });
    if (currentUser) {
      this.fetchStories();
      this.redirectAfterGetCurrentUser(currentUser);
      showCurrentUserStatus();
      navigation.setOptions({title: 'ホーム'});
    }
    setTimeout(() => {
      navigation.addListener('focus', () => {
        const {currentUser} = this.props;
        if (currentUser?.enabled) {
          showCurrentUserStatus().catch(() => {});
          this.fetchStories();
        } else {
          showCurrentUser()
            .then(currentUser => {
              if (!currentUser.confirmed) {
                if (!this.redirectWriterUserRegistrationNavigationOnce) {
                  this.redirectWriterUserRegistrationNavigationOnce = true;
                  routers.linkToWriterUserRegistrationNavigation(
                    navigation,
                    {},
                    'WriterTemporaryUserRegistrationCompletion',
                  );
                }
              } else if (!currentUser.acceptedTerms) {
                routers.linkToWriterUserRegistrationNavigation(
                  navigation,
                  {},
                  'NewWriterTemporaryUsersTermsAcceptance',
                );
              } else if (!currentUser.enabled) {
                routers.linkToWriterUserRegistrationNavigation(
                  navigation,
                  {},
                  'EditWriterUser',
                );
              }
              showCurrentUserStatus();
            })
            .catch(() => {
              //
            });
        }
      });
    }, 100);
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.currentUser && prevProps.currentUser) {
      if (this.props.currentUser.id !== prevProps.currentUser.id) {
        this.fetchStories();
      }
    } else if (!equalForKeys(this.props, prevProps, ['accessToken'])) {
      this.fetchStories();
    }
  }

  public render(): React.ReactNode {
    const {
      route,
      accessToken,
      currentUser,
      serializedStories,
      completedStories,
    } = this.props;
    const {loading, userAgent, alertMessage, deleteStory} = this.state;
    const {showDeleteAccountCompletion} = route.params || {};
    if (!accessToken) {
      return null;
    }
    if (accessToken.value && !serializedStories && !completedStories) {
      return null;
    }
    if (!serializedStories !== !completedStories) {
      return null;
    }
    const showHeader = !!currentUser?.isActivated();
    return (
      <Layout
        title={showHeader ? 'ストーリー一覧' : undefined}
        hideHeaderBottomBorder={!currentUser}
        rightButton={this.rightButton()}
        nolayout={!showHeader}
        loading={loading}
        scrollable={false}
        innerRightButton={
          currentUser?.isActivated() ? this.innerRightButton() : null
        }>
        {serializedStories &&
        completedStories &&
        (serializedStories.length > 0 || completedStories.length > 0) ? (
          <Tabs
            serializedStories={serializedStories}
            completedStories={completedStories}
            onSelectStory={this.handleSelectStory}
            onDeleteStory={this.handleDeleteStory}
            onEndReachedForSerialized={this.handleFetchMoreStoriesForSerialized}
            onEndReachedForCompleted={this.handleFetchMoreStoriesForCompleted}
            onForwardToNewStory={this.handleForwardToNewStory}
          />
        ) : currentUser?.isActivated() ? (
          <WelcomeTapNovel onForwardToNewStory={this.handleForwardToNewStory} />
        ) : currentUser?.confirmed ? null : userAgent ? (
          <TapNovelWebView
            style={WEBVIEW_STYLE}
            originWhitelist={WEBVIEW_ORIGINWHITELIST}
            path={WEBVIEW_PATH}
            userAgent={userAgent}
            onMessage={this.handleMessage}
          />
        ) : null}
        <DeleteAccountCompletionModal
          visible={!!showDeleteAccountCompletion}
          onRequestClose={this.handleRequestCloseDeleteAccountCompletionModal}
        />
        {deleteStory && (
          <StoryDeleteConfirmModal
            story={deleteStory}
            onRequestCloseModal={this.handleRequestCloseModal}
            onRequestDeleteStory={this.handleRequestDeleteStory}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseAlertModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private rightButton = () => {
    const {currentUser} = this.props;
    return currentUser?.isActivated()
      ? {
          children: <MenuIcon />,
          handler: this.handleOpenModal,
          tintColor: 'black',
        }
      : undefined;
  };

  private innerRightButton = () => {
    const {currentUserStatus} = this.props;
    const badgeCount = currentUserStatus?.getUnconfirmedNotificationsCount();
    return {
      title: (
        <NotificationIconWithBadgeCount
          badgeCount={badgeCount}
          disabled={currentUserStatus?.notificationsCount === 0}
        />
      ),
      handler: this.navivateNotifications,
      tintColor: 'white',
      disabled: currentUserStatus?.notificationsCount === 0,
    };
  };

  private navivateNotifications = () => {
    const {navigation} = this.props;
    navigation.navigate('Notifications', {});
  };

  private handleSelectStory = (story: Story) => {
    const {navigation} = this.props;
    const storyId = story.id;
    routers.linkToStory(navigation, {storyId});
  };

  private handleDeleteStory = (story: Story) => {
    this.setState({deleteStory: story});
  };

  private handleRequestCloseModal = () => {
    this.setState({deleteStory: null});
  };

  private handleRequestDeleteStory = (story: Story) => {
    const {indexStories, destroyStory, serializedStoriesParams} = this.props;
    this.setState({loading: true, deleteStory: null}, () => {
      destroyStory(story.id)
        .then(() => {
          indexStories(serializedStoriesParams).then(() => {
            this.setState({loading: false});
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private handleCloseAlertModal = () => {
    this.setState({alertMessage: undefined});
  };

  private handleForwardToNewStory = () => {
    const {navigation} = this.props;
    routers.linkToNewStoryNavigation(navigation, {});
  };

  private handleRequestCloseDeleteAccountCompletionModal = () => {
    this.props.navigation.setParams({
      showDeleteAccountCompletion: undefined,
    });
  };

  private fetchStories() {
    this.fetchSerializedStories();
    this.fetchCompletedStories();
  }

  private fetchSerializedStories() {
    const {indexStories, serializedStoriesParams} = this.props;
    indexStories(serializedStoriesParams)
      .then(result => {
        this.setState({
          nextPageForSerialized: result.nextPage,
        });
      })
      .catch(() => {});
  }

  private fetchCompletedStories() {
    const {indexStories, completedStoriesParams} = this.props;
    indexStories(completedStoriesParams)
      .then(result => {
        this.setState({
          nextPageForCompleted: result.nextPage,
        });
      })
      .catch(() => {});
  }

  private handleFetchMoreStoriesForSerialized = () => {
    const {serializedStoriesParams} = this.props;
    if (!this.state.nextPageForSerialized) {
      return;
    }
    this.props.navigation.setParams({page: this.state.nextPageForSerialized});
    const {indexStories} = this.props;
    indexStories({
      ...serializedStoriesParams,
      page: this.state.nextPageForSerialized
        ? this.state.nextPageForSerialized
        : 1,
    }).then(result => {
      this.setState({
        nextPageForSerialized: result.nextPage,
      });
    });
  };

  private handleFetchMoreStoriesForCompleted = () => {
    const {completedStoriesParams} = this.props;
    if (!this.state.nextPageForCompleted) {
      return;
    }
    this.props.navigation.setParams({page: this.state.nextPageForCompleted});
    const {indexStories} = this.props;
    indexStories({
      ...completedStoriesParams,
      page: this.state.nextPageForCompleted
        ? this.state.nextPageForCompleted
        : 1,
    }).then(result => {
      this.setState({
        nextPageForCompleted: result.nextPage,
      });
    });
  };

  private handleOpenModal = () => {
    const {navigation} = this.props;
    routers.linkToMenu(navigation, {});
  };

  private redirectAfterGetCurrentUser = (currentUser: CurrentUser) => {
    const {navigation} = this.props;
    if (!currentUser.confirmed) {
      if (!this.redirectWriterUserRegistrationNavigationOnce) {
        this.redirectWriterUserRegistrationNavigationOnce = true;
        routers.linkToWriterUserRegistrationNavigation(
          navigation,
          {},
          'WriterTemporaryUserRegistrationCompletion',
        );
      }
    } else if (!currentUser.acceptedTerms) {
      routers.linkToWriterUserRegistrationNavigation(
        navigation,
        {},
        'NewWriterTemporaryUsersTermsAcceptance',
      );
    } else if (!currentUser.enabled) {
      routers.linkToWriterUserRegistrationNavigation(
        navigation,
        {},
        'EditWriterUser',
      );
    }
  };

  private handleMessage = (event: WebViewMessageEvent) => {
    if (
      Platform.OS !== 'web' &&
      event.nativeEvent.data.startsWith('dispatchAction')
    ) {
      const path = event.nativeEvent.data.split(',')[1];
      dispatchAction(path);
    }
  };
}
