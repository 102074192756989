import * as React from 'react';

import Icon from './Icon';

import {colors} from '../../../styles/variables';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const CheckedIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 12;
  return (
    <Icon name={'checked'} size={size} color={props.color || colors.blue} />
  );
};

export default React.memo(CheckedIcon);
