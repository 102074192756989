import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import Scene from '../../../domain/entities/Scene';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexScenesSuccess: {
      const result = action.payload as PaginatedResult<Scene>;
      return mergeResponse(state, result);
    }
    case Types.CreateSceneSuccess: {
      const scene = action.payload as Scene;
      const newState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        if (stateKey.includes(`"episodeId":${scene.episodeId}`)) {
          response.pageToIds[1] = [...response.pageToIds[1], scene.id];
        }
        newState[stateKey] = response;
      });
      return newState;
    }
    case Types.DestroySceneSuccess: {
      const id = action.payload as number;
      const destroyState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        Object.keys(response.pageToIds).forEach((pageKey: string) => {
          const nPageKey = Number(pageKey);
          response.pageToIds[nPageKey] = response.pageToIds[nPageKey].filter(
            sceneId => {
              return sceneId !== id;
            },
          );
        });
        destroyState[stateKey] = response;
      });
      return destroyState;
    }
    default:
      return state;
  }
}
