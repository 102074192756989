import * as React from 'react';

import {
  ImageStyle,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import Input from '../../../../shared/forms/Input';
import TextArea from '../../../../shared/forms/TextArea';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';
import TooltipModal from '../../../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../../../shared/KeyboardSpacer';
import AlertModal from '../../../../shared/modals/AlertModal';
import ActionModal from '../../../../shared/modals/ActionModal';

import DocumentPickerAdapter from '../../../../shared/document_picker/DocumentPickerAdapter';

import PlusCircleSolidIcon from '../../../../shared/icons/PlusCircleSolidIcon';

import {colors, formStyles} from '../../../../../styles/variables';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

export type FormValuesPlot = {
  title: string;
  plot: string;
  notes: string;
  document?: File;
  submitted: boolean;
};

type FormErrorMessage = {[P in keyof FormValuesPlot]?: string | string[]};

const TypeToTooltipInfo = {
  plot: {
    title: 'プロット',
    description:
      '読み手に物語の全体像や面白味が分かるよう、物語の展開を時系列に沿って簡潔にご記載ください。\n世界観やテーマ、登場人物の設定等もわかりやすくご記載ください。\n登場人物がどんな感情を抱き、どんな行動をするのかを意識して書くことを推奨しております。\n必ず結末まで書くようにしましょう。',
  },
} as {
  [key in keyof FormValuesPlot]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

interface Props {
  values?: Partial<FormValuesPlot>;
  errorMessages?: FormErrorMessage;
  onSubmit: (values: FormValuesPlot) => void;
}

const FormStep2Plot: React.FC<Props> = props => {
  const {values, errorMessages, onSubmit} = props;
  const [title, setTitle] = React.useState(values?.title || '');
  const [plot, setPlot] = React.useState(values?.plot || '');
  const [notes, setNotes] = React.useState(values?.notes || '');
  const [document, setDocument] = React.useState<File | undefined>(undefined);
  const [visiblePostCheck, setVisiblePostCheck] = React.useState(false);
  const [tooltipModalType, setTooltipModalType] = React.useState<
    keyof FormValuesPlot | null
  >(null);
  const onPressQuestionPlot = React.useCallback(() => {
    setTooltipModalType('plot');
  }, []);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalTooltip = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const handleChangeFile = React.useCallback((file: File) => {
    setDocument(file);
  }, []);
  const resetImage = React.useCallback(() => {
    setDocument(undefined);
  }, []);
  const onPress = () => {
    onSubmit({title, plot, notes, document, submitted: true});
  };
  const openModal = React.useCallback(() => {
    setVisiblePostCheck(true);
  }, []);
  const onAccept = () => {
    setVisiblePostCheck(false);
    onPress();
  };
  const onRequestClose = React.useCallback(() => {
    setVisiblePostCheck(false);
  }, []);
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{paddingBottom: 130}}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'エピソードタイトル(最大40文字)'}
            maxLength={40}
            length={title.length}
            requiredOrOptional={'required'}
            entered={title.length > 0 && title.length <= 40}
            errorMessages={errorMessages?.title}
          />
          <Input
            style={[
              {marginBottom: 3, marginTop: 8},
              title.length === 0
                ? requiredInputStyle
                : title.length <= 40
                ? undefined
                : styles.inputError,
            ]}
            value={title}
            placeholder={'エピソードタイトルを入力'}
            onChangeText={setTitle}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'プロット（最大2000文字）'}
            length={plot.length}
            maxLength={2000}
            requiredOrOptional={'required'}
            entered={plot.length > 0}
            errorMessages={errorMessages?.plot}
            onPressQuestion={onPressQuestionPlot}
          />
          <TextArea
            style={[
              styles.textArea,
              plot.length <= 2000 ? undefined : styles.inputError,
              plot ? undefined : requiredInputStyle,
            ]}
            containerStyle={plot ? undefined : requiredInputStyle}
            value={plot}
            placeholder={'プロットを入力'}
            onChangeText={setPlot}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'備考（最大400文字）'}
            length={notes.length}
            maxLength={400}
            requiredOrOptional={'optional'}
            entered={notes.length > 0}
            errorMessages={errorMessages?.notes}
          />
          <TextArea
            style={[
              styles.textArea,
              notes.length <= 400 ? undefined : styles.inputError,
            ]}
            value={notes}
            placeholder={'キャラクターや世界観等、プロットの補足を入力'}
            onChangeText={setNotes}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'ファイルを添付（Wordファイル推奨）'}
            requiredOrOptional={'optional'}
            errorMessages={errorMessages?.document}
          />
          {document ? (
            <View
              style={[
                styles.pickerWrapper,
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  paddingHorizontal: 16,
                },
              ]}>
              <Text
                ellipsizeMode={'head'}
                numberOfLines={1}
                style={{
                  width: 250,
                  color: '#666',
                  fontSize: 13,
                  fontWeight: 'bold',
                  marginRight: 16,
                }}>
                {document?.name}
              </Text>
              <TouchableOpacity onPress={resetImage}>
                <View style={[styles.button, {width: 75, height: 24}]}>
                  <Text style={[styles.buttonText, {fontSize: 12}]}>
                    リセット
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          ) : (
            <View style={styles.pickerWrapper}>
              <DocumentPickerAdapter
                type={'any'}
                onChangeFile={handleChangeFile}
                onFailFile={error => {
                  console.log(error);
                }}>
                <View style={[styles.button, {width: 240, height: 46}]}>
                  <PlusCircleSolidIcon size={16} color={'#383838'} />
                  <Text style={[styles.buttonText, {fontSize: 14}]}>
                    ファイル添付
                  </Text>
                </View>
              </DocumentPickerAdapter>
            </View>
          )}
        </View>
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            imageStyle={modalInfo.imageStyle}
            source={modalInfo.source}
            onCloseModal={onCloseModalTooltip}></TooltipModal>
        ) : null}
        <KeyboardSpacer />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <View style={styles.submit}>
          <PrimaryButton disabled={false} onPress={openModal}>
            プロットを提出する
          </PrimaryButton>
        </View>
      </View>
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
      <ActionModal
        visible={visiblePostCheck}
        title={'提出後の修正・取り下げはできません。'}
        description={'提出しますか？'}
        descriptionStyle={{textAlign: 'center'}}
        onAccept={onAccept}
        onRequestClose={onRequestClose}
      />
    </View>
  );
};

export default React.memo(FormStep2Plot);

const styles = StyleSheet.create({
  submit: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
  pickerWrapper: {
    height: 78,
    backgroundColor: '#fafafa',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 16,
  } as ViewStyle,
  button: {
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#383838',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#fff',
  } as ViewStyle,
  buttonText: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: 3,
  } as TextStyle,
});
