import * as React from 'react';

import ElasticBoxText from './ElasticBoxText';

import {textFramePartImageUrl} from '../../helpers/images';

import TextFrame from '../../../domain/entities/TextFrame';

import FramePartPosition from '../../../domain/value_objects/FramePartPosition';

interface Props {
  text: string;
  textFrame: TextFrame;
  width: number;
  height?: number;
  numberOfLines?: number;
  hasVoice?: boolean;
  hasSound?: boolean;
}

const ElasticSpeechBalloon: React.FunctionComponent<Props> = props => {
  const {text, textFrame, width, height, numberOfLines, hasVoice, hasSound} =
    props;
  const top = textFrame.getPartImageUrlOf(FramePartPosition.Top);
  const middle = textFrame.getPartImageUrlOf(FramePartPosition.Middle);
  const bottom = textFrame.getPartImageUrlOf(FramePartPosition.Bottom);
  return (
    <ElasticBoxText
      key={`speech_ballon_${textFrame.id}_${text}_${width}`}
      text={text}
      top={textFramePartImageUrl(top, 'large')}
      middle={textFramePartImageUrl(middle, 'large')}
      bottom={textFramePartImageUrl(bottom, 'large')}
      width={width}
      height={height}
      textStyle={textFrame.textStyle}
      voiceIconStyle={textFrame.voiceIconStyle}
      numberOfLines={numberOfLines}
      useStyledFrame={textFrame.caption}
      hasVoice={hasVoice}
      hasSound={hasSound}
    />
  );
};

export default React.memo(ElasticSpeechBalloon);
