import * as React from 'react';

import ActorCharacterBuilder from '../../../character_maker/ActorCharacterBuilder';

import NavigationProp from '../../../../../navigators/NavigationProp';

import ActorCharacter from '../../../../../../domain/entities/ActorCharacter';

export interface Params {
  storyId: number;
  sceneCommandIndex?: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {
  characterMakerActorGroupId: number;
  skinColorPatternId: number;
  onSelectActorCharacter: (
    actorCharacer: ActorCharacter,
    inverted?: boolean,
  ) => void;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  return <ActorCharacterBuilder {...props} />;
};

export default React.memo(Index);
