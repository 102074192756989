export interface Options {
  width: number;
  height: number;
  canvas?: boolean;
}

const ORIGINAL_STYLE = '/original/';
const HALF_STYLE = '/half/';
const ONE_THIRD_STYLE = '/one_third/';

const CANVAS_PREFIX = '';
const NO_CANVAS_PREFIX = 'nc-';

export default class ImageUrlHelper {
  public static getImageUrl(url: string, options: Options): string {
    const canvas = options.canvas === undefined ? false : options.canvas;
    const style = `/${canvas ? CANVAS_PREFIX : NO_CANVAS_PREFIX}${
      options.width
    }x${options.height}/`;
    return `${url.replace(ORIGINAL_STYLE, style)}`;
  }

  public static getHalfImageUrl(url: string): string {
    return url.replace(ORIGINAL_STYLE, HALF_STYLE);
  }

  public static getOneThirdImageUrl(url: string): string {
    return url.replace(ORIGINAL_STYLE, ONE_THIRD_STYLE);
  }
}
