import * as React from 'react';
import {
  GestureResponderEvent,
  ImageResizeMode,
  ImageStyle,
  StyleProp,
  StyleSheet,
  ViewStyle,
  Platform,
} from 'react-native';

import CommonListView from './CommonListView';
import ImageGridListItem, {ITEM_MARGIN_HORIZONTAL} from './ImageGridListItem';

import DirtyPressIgnoreView from '../DirtyPressIgnoreView';

import DimensionContext from '../../shared/dimension/DimensionContext';

interface Props<T> {
  data: T[];
  zoom?: number;
  aspectRatio?: number;
  resizeMode?: ImageResizeMode;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  imageMaskStyle?: StyleProp<ViewStyle>;
  frame?: boolean;
  showsVerticalScrollIndicator?: boolean;
  onEndReachedThreshold?: number | null;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  extraData?: any;
  onSelectItem: (item: T) => void;
  onLongPressItem?: (item: T) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  imageUrlExtractor: (item: T, width: number) => string;
  labelExtractor?: (item: T) => string | null;
  renderNoImage?: (item: T, width: number) => React.ReactNode;
  renderCustom?: (item: T, width: number) => React.ReactNode;
  renderCustomLabel?: (item: T, width: number) => React.ReactNode;
  imageFrameStyleExtractor?: (item: T) => StyleProp<ViewStyle>;
  imageStyleExtractor?: (item: T) => StyleProp<ImageStyle>;
  disabledItem?: (item: T) => boolean;
}

interface BaseProps<T> extends Props<T> {
  itemWidth: number;
}

interface State {
  longPressed: boolean;
}

class BaseImageGridList<T> extends DirtyPressIgnoreView<
  T,
  BaseProps<T>,
  State
> {
  constructor(props: BaseProps<T>) {
    super(props);
    this.state = {
      longPressed: false,
    };
  }

  public render(): React.ReactNode {
    const {
      style,
      data,
      showsVerticalScrollIndicator,
      ListHeaderComponent,
      ListFooterComponent,
      ListEmptyComponent,
      extraData,
      onEndReached,
      onEndReachedThreshold,
    } = this.props;
    const {longPressed} = this.state;
    return (
      <CommonListView
        style={[styles.container, style]}
        contentContainerStyle={styles.contentContainer}
        scrollEnabled={Platform.select({web: true, default: !longPressed})}
        data={data}
        numColumns={NUM_COLUMNS}
        blockAnimation={true}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={ListFooterComponent}
        ListEmptyComponent={ListEmptyComponent}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onEndReached={onEndReached}
        onEndReachedThreshold={onEndReachedThreshold}
        renderRow={this.renderRow}
        extraData={extraData}
      />
    );
  }

  private handleLongPressItem = (item: T) => {
    const {onLongPressItem} = this.props;
    if (!onLongPressItem) {
      return;
    }
    this.setState({longPressed: true});
    onLongPressItem(item);
  };

  private renderRow = (rowData: T): React.ReactElement<any> => {
    const {
      zoom,
      aspectRatio,
      resizeMode,
      containerStyle,
      imageMaskStyle,
      frame,
      itemWidth,
      extraData,
      imageUrlExtractor,
      labelExtractor,
      renderNoImage,
      renderCustom,
      renderCustomLabel,
      imageFrameStyleExtractor,
      imageStyleExtractor,
      disabledItem,
    } = this.props;
    return (
      <ImageGridListItem
        item={rowData}
        width={itemWidth}
        zoom={zoom}
        aspectRatio={aspectRatio}
        resizeMode={resizeMode}
        containerStyle={containerStyle}
        imageMaskStyle={imageMaskStyle}
        extraData={extraData}
        imageFrameStyleExtractor={imageFrameStyleExtractor}
        imageStyleExtractor={imageStyleExtractor}
        disabledItem={disabledItem}
        frame={frame}
        onSelectItem={this.handleSelectItem}
        onLongPressItem={this.handleLongPressItem}
        imageUrlExtractor={imageUrlExtractor}
        labelExtractor={labelExtractor}
        renderNoImage={renderNoImage}
        renderCustom={renderCustom}
        renderCustomLabel={renderCustomLabel}
      />
    );
  };
}

export default class ImageGridList<T> extends React.Component<Props<T>> {
  public render(): React.ReactNode {
    return (
      <DimensionContext.Consumer>
        {context => {
          const itemWidth =
            (context.content.width -
              (CONTENT_CONTAINER_MARGIN_HORIZONTAL +
                ITEM_MARGIN_HORIZONTAL * NUM_COLUMNS) *
                2) /
            NUM_COLUMNS;
          return <BaseImageGridList {...this.props} itemWidth={itemWidth} />;
        }}
      </DimensionContext.Consumer>
    );
  }
}

const NUM_COLUMNS = 3;

const CONTENT_CONTAINER_MARGIN_HORIZONTAL = 14;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  contentContainer: {
    flexGrow: 1,
    marginHorizontal: CONTENT_CONTAINER_MARGIN_HORIZONTAL,
    marginVertical: 4,
  } as ViewStyle,
});
