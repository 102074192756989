import * as React from 'react';
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  LayoutRectangle,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import BaseModal from './BaseModal';

interface Props extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
  animationType?: 'none' | 'slide' | 'fade';
  visible: boolean;
  disableCloseByOutsideTouch?: boolean;
  onLayout?: (event: LayoutChangeEvent) => void;
  onCloseModal?: (event: GestureResponderEvent) => void;
}

interface State {
  layout: LayoutRectangle | null;
}

export default class ModalContainer extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      layout: null,
    };
  }

  public render(): React.ReactNode {
    const {
      style,
      animationType,
      disableCloseByOutsideTouch,
      visible,
      children,
    } = this.props;
    return (
      <BaseModal
        animationType={animationType}
        onRequestClose={this.handleRequestClose}
        transparent={true}
        visible={visible}>
        <Pressable
          style={styles.overlay}
          disabled={disableCloseByOutsideTouch}
          onPress={this.handleTouchStart}>
          <View onLayout={this.handleLayout} style={[styles.container, style]}>
            {children}
          </View>
        </Pressable>
      </BaseModal>
    );
  }

  private handleLayout = (event: LayoutChangeEvent) => {
    const {onLayout} = this.props;
    if (onLayout) {
      onLayout(event);
    }
    this.setState({layout: event.nativeEvent.layout});
  };

  private handleTouchStart = (event: GestureResponderEvent) => {
    const {onCloseModal} = this.props;
    const {layout} = this.state;
    if (!layout || !onCloseModal) {
      return;
    }
    if (!event.nativeEvent) {
      return;
    }
    const {pageX, pageY} = event.nativeEvent;
    const {height, width, x, y} = layout;
    if (
      !(x <= pageX && pageX <= x + width && y <= pageY && pageY <= y + height)
    ) {
      event.stopPropagation();
      event.preventDefault();
      onCloseModal(event);
    }
  };

  private handleRequestClose = () => {
    /** TODO */
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    width: '83%',
    ...Platform.select({
      web: {pointerEvents: 'all'},
    }),
    default: {},
  } as ViewStyle,
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, .5)',
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
});
