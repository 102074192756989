import * as React from 'react';
import {Platform, StatusBar} from 'react-native';

import FooterCloseButton from '../shared/viewer/FooterCloseButton';
import GoogleAds from '../shared/viewer/GoogleAds';
import MenuCommands from '../shared/viewer/MenuCommands';

import {Params as EpisodeViewerErrorLogCreateParams} from '../../actions/episode_viewer_error_logs/create';

import NavigationProp from '../../navigators/NavigationProp';
import {EpisodeRouteProp} from '../../navigators/RouteProps';

import StorageViewerSettingsRepository from '../../../data/repositories/StorageViewerSettingsRepository';

import EpisodeViewerErrorLog from '../../../domain/entities/EpisodeViewerErrorLog';

import {WriterEpisodeViewer} from '../../../vendor/react-native-tapnovel-viewer';

const orientation = 'vertical';

export interface Params {
  episodeId: number | string;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EpisodeRouteProp;
}

export interface DispatchProps {
  createEpisodeViewerErrorLog: (
    params: EpisodeViewerErrorLogCreateParams,
  ) => Promise<EpisodeViewerErrorLog>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  visibleProgressBar: boolean;
  textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect';
  enabledSound: boolean;
  autoPlaySpeed: 0 | 1 | 1.5 | 2;
}

export default class Show extends React.PureComponent<Props, State> {
  private viewerSettingsRepository: StorageViewerSettingsRepository =
    new StorageViewerSettingsRepository();

  constructor(props: Props) {
    super(props);
    this.state = {
      visibleProgressBar: true,
      textSpeed: 'no_effect',
      enabledSound: true,
      autoPlaySpeed: 0,
    };
  }

  public async componentDidMount(): Promise<void> {
    StatusBar.setHidden(true);
    const viewerSetting = await this.viewerSettingsRepository.find();
    const visibleProgressBar = viewerSetting.visibleProgressBar;
    const textSpeed = viewerSetting.textSpeed;
    const enabledSound =
      viewerSetting.enabledSound === undefined
        ? true
        : viewerSetting.enabledSound;
    const autoPlaySpeed =
      viewerSetting.autoPlaySpeed === undefined
        ? 1
        : viewerSetting.autoPlaySpeed;
    this.setState({
      visibleProgressBar,
      textSpeed,
      enabledSound,
      autoPlaySpeed,
    });
  }

  public componentWillUnmount(): void {
    StatusBar.setHidden(false);
  }

  public render(): React.ReactNode {
    const {route} = this.props;
    const {visibleProgressBar, textSpeed, enabledSound, autoPlaySpeed} =
      this.state;
    const episodeId = Number(route.params.episodeId);
    return (
      <WriterEpisodeViewer.default
        episodeId={episodeId}
        visibleProgressBar={visibleProgressBar}
        textSpeed={textSpeed}
        autoPlaySpeed={autoPlaySpeed}
        enabledSound={enabledSound}
        orientation={orientation}
        renderMenuCommands={this.renderMenuCommands}
        renderDownloadProgressModalTop={this.renderDownloadProgressModalTop}
        renderFooter={this.renderFooter}
        onLoadFail={this.handleLoadFail}
        onRequestClose={this.handleRequestClose}
        onVisibleProgressBarChange={this.handleVisibleProgressBarChange}
        onTextSpeedChange={this.handleTextSpeedChange}
        onAutoPlaySpeedChange={this.handleAutoPlaySpeedChange}
        onEnabledSoundChange={this.handleEnabledSoundChange}
      />
    );
  }

  private renderMenuCommands = (options?: {
    command?: {id: number; sceneId: number} | null;
    disableNextScene?: boolean;
    disablePrevScene?: boolean;
    onPressNextScene?: () => void;
    onPressPrevScene?: () => void;
  }): React.ReactNode => {
    return (
      <MenuCommands
        onPressFinish={() => {
          this.handleRequestClose();
        }}
        disableNextScene={options ? options.disableNextScene : undefined}
        disablePrevScene={options ? options.disablePrevScene : undefined}
        onPressNextScene={options ? options.onPressNextScene : undefined}
        onPressPrevScene={options ? options.onPressPrevScene : undefined}
      />
    );
  };

  private renderDownloadProgressModalTop = (width: number): React.ReactNode => {
    return null;
  };

  private renderFooter = () => {
    return <FooterCloseButton onPressFinish={this.handleRequestClose} />;
  };

  private handleLoadFail = (error: any) => {
    const {route, createEpisodeViewerErrorLog} = this.props;
    const episodeId = Number(route.params.episodeId);
    createEpisodeViewerErrorLog({
      episodeId: episodeId,
      errorUrl: `tapnovel-maker://episodes/${episodeId}/viewer`,
      errorInfo: {
        name: error.name,
        stack: error.stack,
        message: error.message,
      },
      platform: Platform.select({
        ios: 'ios',
        android: 'android',
        default: 'web',
      }),
    });
  };

  private handleRequestClose = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };

  private handleVisibleProgressBarChange = (visibleProgressBar: boolean) => {
    this.setState({visibleProgressBar});
    this.viewerSettingsRepository.update({visibleProgressBar});
  };

  private handleTextSpeedChange = (
    textSpeed: 'slow' | 'normal' | 'fast' | 'no_effect',
  ) => {
    this.setState({textSpeed});
    this.viewerSettingsRepository.update({textSpeed});
  };

  private handleAutoPlaySpeedChange = (autoPlaySpeed: 0 | 1 | 1.5 | 2) => {
    this.setState({autoPlaySpeed: autoPlaySpeed});
    this.viewerSettingsRepository.update({autoPlaySpeed: autoPlaySpeed});
  };

  private handleEnabledSoundChange = (enabledSound: boolean) => {
    this.setState({enabledSound});
    this.viewerSettingsRepository.update({enabledSound});
  };
}
