import * as React from 'react';
import {
  ImageStyle,
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ImageIcon from '../../../../../shared/icons/ImageIcon';
import ImageFilterIcon from '../../../../../shared/icons/ImageFilterIcon';
import ImageEffectIcon from '../../../../../shared/icons/ImageEffectIcon';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';
import BackgroundEffectImageSequence from '../../../../../shared/BackgroundEffectImageSequence';

import {backgroundImageUrl} from '../../../../../../helpers/images';

import {baseStyleWidth} from '../../../../../../styles/variables';

import Background from '../../../../../../../domain/entities/Background';
import SceneForm from '../../../../../../../domain/forms/SceneForm';

import {GLImageFilter} from '../../../../../../../vendor/react-native-tapnovel-viewer';

const ICON_COLOR = '#383838';

interface Props {
  sceneForm: SceneForm;
  background: Background | null;
  onForwardToBackgrounds: () => void;
  onForwardToBackgroundFilters: () => void;
  onForwardToBackgroundEffects: () => void;
}

const BackgroundView: React.FunctionComponent<Props> = props => {
  const {
    sceneForm,
    background,
    onForwardToBackgrounds,
    onForwardToBackgroundFilters,
    onForwardToBackgroundEffects,
  } = props;
  return (
    <DimensionContext.Consumer>
      {context => (
        <View
          style={[
            styles.container,
            {
              height: context.content.width / aspectRatio,
              width: context.content.width,
              overflow: 'hidden',
            },
          ]}>
          {background ? (
            <>
              <GLImageFilter
                size={{
                  height: context.content.width / aspectRatio,
                  width: context.content.width,
                }}
                uri={backgroundImageUrl(background, 'large')}
                filter={
                  (sceneForm.options && sceneForm.options.filter) || undefined
                }
              />
              <BackgroundEffectImageSequence
                id={sceneForm.positionedEffect?.id}
                size={{
                  height: context.content.width / aspectRatio,
                  width: context.content.width,
                }}
                urls={sceneForm.positionedEffect?.getMaxSizeFrameUrls() || []}
              />
              <TouchableOpacity
                activeOpacity={0.9}
                style={styles.fill}
                onPress={onForwardToBackgrounds}
              />
              <Pressable
                style={styles.buttonFiltrer}
                onPress={onForwardToBackgroundFilters}>
                <ImageFilterIcon color={ICON_COLOR} />
              </Pressable>
              <Pressable
                style={styles.buttonEffect}
                onPress={onForwardToBackgroundEffects}>
                <ImageEffectIcon color={ICON_COLOR} />
              </Pressable>
            </>
          ) : (
            <View
              style={[
                styles.noImage,
                {
                  height: context.content.width / aspectRatio,
                  width: context.content.width,
                },
              ]}>
              <Text style={styles.noImageText}>背景イラスト</Text>
              <ImageIcon size={35} color={ICON_COLOR} />
            </View>
          )}
        </View>
      )}
    </DimensionContext.Consumer>
  );
};

export default React.memo(BackgroundView);

const aspectRatio = baseStyleWidth / 245;

const button = {
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderRadius: 18,
  borderColor: '#383838',
  borderWidth: 1,
  height: 36,
  justifyContent: 'center',
  position: 'absolute',
  top: 8,
  width: 36,
  flexDirection: 'row',
} as ViewStyle;

const styles = StyleSheet.create({
  fill: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  } as ViewStyle,

  buttonFiltrer: {
    ...button,
    right: 48,
  } as ViewStyle,
  buttonEffect: {
    ...button,
    right: 8,
  } as ViewStyle,
  buttonText: {
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  container: {
    alignItems: 'center',
    backgroundColor: '#efefef',
    marginBottom: 5,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
  image: {
    aspectRatio,
  } as ImageStyle,
  noImage: {
    alignItems: 'center',
    aspectRatio,
    justifyContent: 'center',
  } as ViewStyle,
  noImageText: {
    color: '#383838',
    fontSize: 17,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
});
