import * as React from 'react';
import {StyleSheet, Text, TextStyle, ViewStyle} from 'react-native';

import DefaultOutlineButton from '../../../../../shared/buttons/DefaultOutlineButton';

interface Props {
  title: string;
  onPress: () => void;
}

const CharacterChangeButton: React.FunctionComponent<Props> = props => {
  const {title, onPress} = props;
  return (
    <DefaultOutlineButton
      style={styles.container}
      buttonSize={BUTTON_SIZE}
      unwrappedText={true}
      onPress={onPress}>
      <Text style={styles.text}>{title}</Text>
    </DefaultOutlineButton>
  );
};

export default React.memo(CharacterChangeButton);

const BUTTON_SIZE = {
  width: 93,
  height: 28,
};

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 4,
  } as ViewStyle,
  text: {
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
