import {Platform, StyleSheet, TextStyle} from 'react-native';

export const colors = {
  backgroundGray: '#EFEFEF',
  blue: '#1E8ECA',
  brightGray: '#CBCBCB',
  brown: '#341E05',
  disableColor: '#d5d5d5',
  disableTextColor: '#999999',
  facebook: '#3B5999',
  hatebu: '#008FDE',
  gray: '#666',
  lightBlack: '#383838',
  lightGray: '#999',
  line: '#00C300',
  orange: '#FF8F13',
  paleGray: '#D6D6D6',
  powderGray: '#E5E5E5',
  red: '#D02D06',
  textColor: '#222',
  twitter: '#1B95E0',
  whiteGray: '#FAFAFA',
};

export const size = {
  minTapHeight: 44,
  safeAreaBottom: Platform.select({
    ios: 34,
    default: 0,
  }),
  safeAreaTop: Platform.select({
    ios: 44,
    default: 0,
  }),
  listItemSeparator: Platform.select({
    android: 0.6,
    ios: StyleSheet.hairlineWidth,
    default: 0,
  }),
};

export const baseStyleWidth = 375;

export const formStyles = StyleSheet.create({
  formContainer: {
    marginBottom: 20,
  },
  formFieldArrow: {
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    minHeight: size.minTapHeight + 2,
  },
  formFieldIcon: {
    paddingHorizontal: 15,
  },
  formFieldText: {
    color: colors.textColor,
    flex: 1,
    fontSize: 15,
  },
  formFieldWrapper: {
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderColor: colors.powderGray,
  },
  formFieldTextAreaWrapper: {
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: colors.powderGray,
    marginVertical: 16,
  },
  formGroup: {
    marginTop: 32,
    marginHorizontal: 16,
  },
  formInput: {
    color: colors.textColor,
    fontSize: 15,
    height: size.minTapHeight,
  },
  formInputBorder: {
    borderColor: colors.paleGray,
    borderTopWidth: 1,
  },
  formLabel: {
    color: colors.gray,
    fontSize: 13,
    paddingBottom: 5,
  },
  formLabelUnit: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  formSelect: {
    alignItems: 'center',
    flexDirection: 'row',
    height: size.minTapHeight,
    paddingHorizontal: 10,
  },
  formSelectText: {
    color: colors.textColor,
    fontSize: 15,
  },
  formTextArea: {
    color: colors.textColor,
    fontSize: 15,
    height: 130,
    textAlignVertical: 'top',
  },
  formUnit: {
    backgroundColor: 'white',
    borderColor: colors.paleGray,
    borderRadius: 3,
    borderWidth: 1,
  },
  formWrapper: {
    marginHorizontal: 10,
    marginTop: 20,
  },
  input: {
    backgroundColor: 'white',
    color: 'black',
    fontSize: 15,
    height: size.minTapHeight,
    marginVertical: 3,
  },
  inputWrapper: {
    backgroundColor: 'white',
    height: size.minTapHeight,
    paddingHorizontal: 15,
  },
  inputBorder: {
    borderColor: colors.powderGray,
    borderTopWidth: 1,
  },
  label: {
    color: colors.gray,
    fontSize: 13,
    paddingBottom: 5,
    paddingHorizontal: 15,
  },
  note: {
    alignSelf: 'center',
    color: '#383838',
    fontSize: 12,
    marginVertical: 8,
  },
  select: {
    alignItems: 'center',
    flexDirection: 'row',
    height: size.minTapHeight,
    paddingHorizontal: 15,
  },
  textArea: {
    backgroundColor: 'white',
    fontSize: 15,
    height: 174,
    margin: 8,
    textAlignVertical: 'top',
  },
});

export const modalStyles = StyleSheet.create({
  text: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
  } as TextStyle,
});
