import SoundMapper, {SoundData} from '../../../entities/mappers/SoundMapper';

import BackgroundMusicShowSceneCommand from '../../../../domain/value_objects/scene_commands/BackgroundMusicShowSceneCommand';

export interface BackgroundMusicShowSceneCommandData {
  type: string;
  sound: SoundData;
}

export default class BackgroundMusicShowSceneCommandMapper {
  private soundMapper: SoundMapper;

  constructor() {
    this.soundMapper = new SoundMapper();
  }

  public map(
    obj: BackgroundMusicShowSceneCommandData,
  ): BackgroundMusicShowSceneCommand {
    const sound = this.soundMapper.map(obj.sound);
    return new BackgroundMusicShowSceneCommand(sound);
  }
}
