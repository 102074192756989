import * as React from 'react';
import {ScrollView, StyleSheet, View, ViewStyle} from 'react-native';

import Tab from './Tab';

interface Item<Value> {
  value: Value;
  label: string;
  disabled?: boolean;
}

interface Props<Value> {
  items: Array<Item<Value>>;
  currentValue: Value;
  onChangeTab: (value: Value) => void;
}

export default class Tabs<Value> extends React.PureComponent<Props<Value>> {
  public render(): React.ReactNode {
    const {items, currentValue, onChangeTab} = this.props;
    return (
      <View style={styles.container}>
        <ScrollView
          bounces={false}
          horizontal={true}
          showsHorizontalScrollIndicator={false}>
          {items
            .filter(item => !item.disabled)
            .map(item => (
              <Tab<Value>
                key={`${item.value}`}
                value={item.value}
                label={item.label}
                isActive={currentValue === item.value}
                onChangeTab={onChangeTab}
              />
            ))}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: 39,
    width: '100%',
    backgroundColor: 'white',
    borderColor: '#efefef',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 16,
  } as ViewStyle,
});
