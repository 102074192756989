import EntityMapper from '../EntityMapper';

import ContactCategoryMapper, {
  ContactCategoryData,
} from './ContactCategoryMapper';

import Contact from '../../../../domain/entities/writer/Contact';

export interface ContactData {
  id: number;
  writer_contact_category: ContactCategoryData;
  email: string;
  content: string;
  device_name: string;
  platform_name: string;
  platform_version: string;
  application_version: string;
  created_at: string;
  updated_at: string;
}

export default class ContactMapper
  implements EntityMapper<ContactData, Contact>
{
  private contactCategoryMapper = new ContactCategoryMapper();
  public map(obj: ContactData): Contact {
    return new Contact(
      obj.id,
      this.contactCategoryMapper.map(obj.writer_contact_category),
      obj.email,
      obj.content,
      obj.device_name,
      obj.platform_name,
      obj.platform_version,
      obj.application_version,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
