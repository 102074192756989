import * as React from 'react';
import {View} from 'react-native';
import RNRangeSlider from 'rn-range-slider';

interface Props {
  min: number;
  max: number;
  minRange?: number;
  step: number;
  low?: number;
  high?: number;
  onSliderTouchEnd?: (low: number, high: number) => void;
}

const RangeSlider: React.FC<Props> = props => {
  const {min, max, minRange, step, low, high, onSliderTouchEnd} = props;
  return (
    <RNRangeSlider
      style={{
        width: '95%',
        height: 40,
        justifyContent: 'center',
      }}
      min={min}
      max={max}
      step={step}
      minRange={minRange}
      initialLowValue={low}
      initialHighValue={high}
      renderThumb={renderThumb}
      renderRail={renderRail}
      renderRailSelected={renderRailSelected}
      renderLabel={renderLabel}
      renderNotch={renderNotch}
      onSliderTouchEnd={onSliderTouchEnd}
    />
  );
};

export default React.memo(RangeSlider);

const renderThumb = () => (
  <View
    style={{
      width: 10,
      height: 36,
      borderRadius: 24,
      borderWidth: 1,
      borderColor: '#383838',
      backgroundColor: '#fff',
    }}
  />
);

const renderRail = () => (
  <View
    style={{
      width: '100%',
      height: 16,
      borderWidth: 1,
      borderColor: '#d5d5d5',
      backgroundColor: '#fff',
    }}
  />
);
const renderRailSelected = () => (
  <View
    style={{
      width: '100%',
      height: 16,
      backgroundColor: '#999',
    }}
  />
);

const renderLabel = (value: number) => <View />;
const renderNotch = () => <View />;
