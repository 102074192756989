import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import New, {DispatchProps, StateProps} from '../../components/stories/New';

import AppState from '../../reducers/AppState';

import showCurrentUser from '../../actions/writer/current_user/show';
import indexGenres, {
  Params as GenreIndexParams,
} from '../../actions/genres/index';
import createStoryForm, {
  Params as StoryFormCreateParams,
} from '../../actions/story_forms/create';
import updateStoryForm, {
  Params as StoryFormUpdateParams,
} from '../../actions/story_forms/update';
import createStory, {
  Params as StoryCreateParams,
} from '../../actions/stories/create';

import selectEntity from '../../helpers/selectEntity';
import selectEntities from '../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const genresParams = {};
  const storyForm = forms.storyForm;
  const genres = selectEntities(entities.genres, queries.genres, genresParams);
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  return {
    currentUser,
    genres,
    genresParams,
    navigation,
    storyForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUser: () => dispatch(showCurrentUser()),
    indexGenres: (params: GenreIndexParams) => dispatch(indexGenres(params)),
    createStoryForm: (params: StoryFormCreateParams) =>
      dispatch(createStoryForm(params)),
    updateStoryForm: (params: StoryFormUpdateParams) =>
      dispatch(updateStoryForm(params)),
    createStory: (params: StoryCreateParams) => dispatch(createStory(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
