import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import BellIcon from '../../../shared/icons/BellIcon';
import BellSilentIcon from '../../../shared/icons/BellSilentIcon';

import {WriterUserNotificationSetting} from '../../../../../domain/entities/writer/UserNotificationSettingsBatch';

interface Props {
  writerUserNotificationSetting: WriterUserNotificationSetting;
  onValueChangeWriterUserNotificationSetting: (
    writerUserNotificationSetting: WriterUserNotificationSetting,
    enabled: boolean,
  ) => void;
}

export default class NotificationSettingListItem extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {writerUserNotificationSetting} = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.inner}>
          <Text style={styles.text}>
            {writerUserNotificationSetting.writerNotificationSetting.name}
          </Text>
          <Pressable
            onPress={() => {
              this.handleValueChange(
                !(writerUserNotificationSetting.enabled || false),
              );
            }}>
            <View>
              {writerUserNotificationSetting.enabled ? (
                <BellIcon size={24} color={'#ff8f13'} />
              ) : (
                <BellSilentIcon size={21} color={'#999999'} />
              )}
            </View>
          </Pressable>
        </View>
      </View>
    );
  }

  private handleValueChange = (enabled: boolean) => {
    const {
      writerUserNotificationSetting,
      onValueChangeWriterUserNotificationSetting,
    } = this.props;
    onValueChangeWriterUserNotificationSetting(
      writerUserNotificationSetting,
      enabled,
    );
  };
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    height: 50,
    justifyContent: 'center',
  } as ViewStyle,
  inner: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginHorizontal: 16,
  } as ViewStyle,
  text: {
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
    marginLeft: 5,
    marginRight: 10,
    width: '76%',
  } as TextStyle,
});
