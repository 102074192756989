import {shallowEqual} from 'recompose';
import SceneCommandForm from '../../../../domain/forms/scene_commands/SceneCommandForm';

interface Props {
  sceneCommandForms: SceneCommandForm[] | null;
}

export default (current: Props, next: Props) => {
  if (current.sceneCommandForms === next.sceneCommandForms) {
    return false;
  }
  if (!current.sceneCommandForms || !next.sceneCommandForms) {
    return true;
  }
  if (current.sceneCommandForms.length !== next.sceneCommandForms.length) {
    return true;
  }
  return !current.sceneCommandForms.every((currentSceneCommandForm, i) => {
    if (!next.sceneCommandForms) {
      return false;
    }
    const nextSceneCommandForm = next.sceneCommandForms[i];
    if (
      currentSceneCommandForm.sceneCommandId !==
      nextSceneCommandForm.sceneCommandId
    ) {
      return false;
    }
    if (!shallowEqual(currentSceneCommandForm, nextSceneCommandForm)) {
      return false;
    }
    return true;
  });
};
