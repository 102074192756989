import EntityMapper from './EntityMapper';

import SoundCategory from '../../../domain/entities/SoundCategory';

export interface SoundCategoryData {
  id: number;
  parent_id: number;
  name: string;
  leaf: boolean;
  sound_count: number;
}

export default class SoundCategoryMapper
  implements EntityMapper<SoundCategoryData, SoundCategory>
{
  public map(obj: SoundCategoryData): SoundCategory {
    return new SoundCategory(
      obj.id,
      obj.parent_id,
      obj.name,
      obj.leaf,
      obj.sound_count,
    );
  }
}
