import * as React from 'react';

import EpisodeList from '../episodes/partials/EpisodeList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ExportStoryRouteProp} from '../../../navigators/RouteProps';

import Episode from '../../../../domain/entities/Episode';

import NetEpisodesRepository from '../../../../data/repositories/writer/NetEpisodesRepository';

export interface Params {
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ExportStoryRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {storyId} = route.params;
  const [episodes, setEpisodes] = React.useState<Array<Episode> | null>(null);
  React.useEffect(() => {
    new NetEpisodesRepository().findBy({storyId}).then(result => {
      setEpisodes(result.records);
    });
  }, []);
  const onSelectEpisode = React.useCallback((episode: Episode) => {
    navigation.navigate('ExportEpisode', {episodeId: episode.id});
  }, []);
  return (
    <Layout
      title={'エクスポートするエピソードを選択'}
      navigation={navigation}
      back={true}>
      <EpisodeList episodes={episodes} onSelectEpisode={onSelectEpisode} />
    </Layout>
  );
};

export default React.memo(Show);
