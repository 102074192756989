import * as React from 'react';

import Form, {FormValues} from './partials/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {EditProjectTopicRouteProp} from '../../../navigators/RouteProps';

import ProjectTopic from '../../../../domain/entities/ProjectTopic';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditProjectTopicRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {projectId, id} = route.params;
  const [values, setValues] = React.useState<FormValues | null>(null);
  const onSubmit = React.useCallback((values: FormValues) => {
    TapNovelTypedRestApi.patch<ProjectTopic>(
      `/api/writer/project_topics/${id}`,
      {
        projectTopic: values,
        projectId,
      },
    ).then(result => {
      navigation.goBack();
    });
  }, []);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<ProjectTopic>(
      `/api/writer/project_topics/${id}`,
    ).then(result => {
      setValues(result.body);
    });
  }, []);
  return (
    <Layout title={'トピック編集'} navigation={navigation} back={true}>
      {values && <Form values={values} onSubmit={onSubmit} />}
    </Layout>
  );
};

export default React.memo(New);
