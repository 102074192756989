import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import ServiceExplanation from './partials/ServiceExplanation';
import AccountAggregationView from './partials/AccountAggregationView';

import StoryAggregationList from '../stories/partials/StoryAggregationList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {
  TermRange,
  convertTermRangeToQueryParams,
} from '../../../view_models/TermRange';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

import AccountAggregation from '../../../../domain/value_objects/partner_program/AccountAggregation';
import StoryAggregation from '../../../../domain/value_objects/partner_program/StoryAggregation';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<CurrentUser>;
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, showCurrentUser, showCurrentUserStatus} = props;
  const [currentUser, setCurrentUser] = React.useState<CurrentUser | null>(
    null,
  );
  const [currentUserStatus, setCurrentUserStatus] =
    React.useState<CurrentUserStatus | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [title, setTitle] = React.useState('パートナープログラム');
  const [termRange, setTermRange] = React.useState<TermRange>('all');
  const [accountAggregation, setAccountAggregation] =
    React.useState<AccountAggregation | null>(null);
  const [storyAggregations, setStoryAggregations] =
    React.useState<Array<StoryAggregation> | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      const params = convertTermRangeToQueryParams(termRange);
      TapNovelRestApi.get(
        '/api/writer/partner_program/account_aggregation',
        params,
      ).then(response => {
        setAccountAggregation(
          camelizeKeys(response.body as any) as AccountAggregation,
        );
      });
      TapNovelRestApi.get(
        '/api/writer/partner_program/story_aggregations',
        params,
      ).then(response => {
        setStoryAggregations(
          camelizeKeys(response.body as any) as Array<StoryAggregation>,
        );
      });
    }, [termRange]),
  );
  React.useEffect(() => {
    showCurrentUser().then(currentUser => {
      setCurrentUser(currentUser);
    });
    showCurrentUserStatus().then(currentUserStatus => {
      setCurrentUserStatus(currentUserStatus);
    });
  }, []);
  const onAgreeServicePolicy = React.useCallback(() => {
    setLoading(true);
    TapNovelRestApi.post('/api/writer/user_service_agreements', {
      writer_user_service_agreement: {
        writer_service_agreement_code: 'partner_program',
      },
    })
      .then(response => {
        setTimeout(() => {
          showCurrentUserStatus().then(setCurrentUserStatus);
          setLoading(false);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);
  return (
    <Layout title={title} navigation={navigation} back={true} loading={loading}>
      {currentUser?.grade && currentUserStatus ? (
        currentUserStatus.serviceAgreementCodes.includes('partner_program') ? (
          storyAggregations ? (
            <StoryAggregationList
              storyAggregations={storyAggregations}
              ListHeaderComponent={
                <>
                  {accountAggregation ? (
                    <AccountAggregationView
                      grade={currentUser.grade}
                      accountAggregation={accountAggregation}
                      termRange={termRange}
                      onTermRangeChange={setTermRange}
                    />
                  ) : null}
                </>
              }
            />
          ) : null
        ) : (
          <ServiceExplanation
            onAgreeServicePolicy={onAgreeServicePolicy}
            setTitle={setTitle}
          />
        )
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
