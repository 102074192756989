import * as React from 'react';
import {ScrollView, StyleSheet, View, ViewStyle} from 'react-native';

import Tab from './Tab';

export type Value =
  | 'face'
  | 'mark'
  | 'costume'
  | 'hair_style'
  | 'actor_accessory';

interface Props {
  activeTab: Value;
  hide?: boolean;
  disabledCostume?: boolean;
  disabledHairStyle?: boolean;
  disabledActorAccessory?: boolean;
  onChangeTab: (value: Value) => void;
}

const Tabs: React.FunctionComponent<Props> = props => {
  const {
    activeTab,
    hide,
    disabledCostume,
    disabledHairStyle,
    disabledActorAccessory,
    onChangeTab,
  } = props;
  if (hide) {
    return <View style={styles.hide} />;
  }
  return (
    <View style={styles.tabs}>
      <ScrollView
        bounces={false}
        horizontal={true}
        showsHorizontalScrollIndicator={false}>
        <Tab
          value={'face'}
          label={'表情'}
          isActive={activeTab === 'face'}
          onChangeTab={onChangeTab}
        />
        <Tab
          value={'mark'}
          label={'アイコン'}
          isActive={activeTab === 'mark'}
          onChangeTab={onChangeTab}
        />
        {!disabledCostume && (
          <Tab
            value={'costume'}
            label={'衣装'}
            isActive={activeTab === 'costume'}
            onChangeTab={onChangeTab}
          />
        )}
        {!disabledHairStyle && (
          <Tab
            value={'hair_style'}
            label={'髪色'}
            isActive={activeTab === 'hair_style'}
            onChangeTab={onChangeTab}
          />
        )}
        {!disabledActorAccessory && (
          <Tab
            value={'actor_accessory'}
            label={'アクセサリー'}
            isActive={activeTab === 'actor_accessory'}
            onChangeTab={onChangeTab}
          />
        )}
      </ScrollView>
    </View>
  );
};

export default React.memo(Tabs);

const styles = StyleSheet.create({
  tabs: {
    height: 41,
    flexDirection: 'row',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    backgroundColor: 'white',
  } as ViewStyle,
  hide: {
    borderTopColor: '#efefef',
    borderTopWidth: 1,
  } as ViewStyle,
});
