import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import StoryInfo from './StoryInfo';

import FastImageAdapter from '../../shared/fast_image/FastImageAdapter';
import MoreIcon from '../../shared/icons/ionicons/MoreIcon';

import {storyCoverImageUrl} from '../../../helpers/images';

import {colors} from '../../../styles/variables';

import StoryCoverImageDecorator from '../../../view_models/StoryCoverImageDecorator';

import Story from '../../../../domain/entities/Story';

interface Props {
  story: Story;
  children?: React.ReactNode;
  onOpenActionSheet?: (story: Story) => void;
}

const StoryListItemContent: React.FunctionComponent<Props> = props => {
  const {story, onOpenActionSheet} = props;
  const source = {
    uri: storyCoverImageUrl(new StoryCoverImageDecorator(story), 'small'),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  const handleOpenActionSheet = React.useCallback(() => {
    if (onOpenActionSheet) {
      onOpenActionSheet(story);
    }
  }, [story]);
  return (
    <View style={styles.container}>
      <View style={styles.body}>
        {story.hasCoverImage ? (
          <FastImageAdapter style={styles.image} source={source} />
        ) : (
          <View style={styles.image}>
            <Text style={styles.noImageText}>表紙なし</Text>
          </View>
        )}
        <View style={styles.storyInfo}>
          <StoryInfo story={story} />
        </View>
        {onOpenActionSheet && (
          <TouchableOpacity onPress={handleOpenActionSheet}>
            <View
              style={{
                position: 'absolute',
                top: -8,
                right: -5,
                padding: 8,
              }}>
              <MoreIcon />
            </View>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default React.memo(StoryListItemContent);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  body: {
    flex: 1,
    flexDirection: 'row',
    margin: 10,
  } as ViewStyle,
  container: {
    backgroundColor: 'white',
  } as ViewStyle,
  header: {
    margin: 10,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  storyInfo: {
    flex: 1,
    marginLeft: 10,
  } as ViewStyle,
  title: {
    color: colors.textColor,
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
});
