import React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {useIsFocused} from '@react-navigation/native';
import CoachmarkOverlay from './CoachmarkOverlay';

export enum CoachmarkPosition {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface Props extends React.PropsWithChildren {
  childStyle?: StyleProp<ViewStyle>;
  coachmarkViewChildren: React.ReactNode;
  coachmarkViewStyle?: StyleProp<ViewStyle>;
  arrowStyle?: StyleProp<ViewStyle>;
  arrowSize?: number;
  autoShow?: boolean;
  focus?: boolean;
  dialog?: boolean;
  onHide?: () => void;
  onShow?: () => void;
  isAnchorReady?: boolean;
}

const Coachmark: React.FunctionComponent<Props> = props => {
  return <CoachmarkOverlay {...props} focus={useIsFocused()} />;
};

export default Coachmark;
