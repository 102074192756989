import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import Select from './forms/Select';

import loadGoogleFont from '../../helpers/loadGoogleFont';

interface Props {
  value?: string;
  onValueChange: (itemValue: string | number, itemPosition: number) => void;
}

export default class FontsSelect extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {value} = this.props;
    return (
      <View style={styles.container}>
        <Select
          items={items}
          value={value}
          onValueChange={this.handleValueChange}
        />
      </View>
    );
  }

  private handleValueChange = async (
    itemValue: string | number,
    itemPosition: number,
  ) => {
    const {onValueChange} = this.props;
    await loadGoogleFont(`${itemValue}`);
    onValueChange(itemValue, itemPosition);
  };
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
    marginBottom: 24,
  } as ViewStyle,
});

const items = [
  {label: 'Noto Sans JP', value: 'Noto Sans JP'},
  {label: 'Noto Serif JP', value: 'Noto Serif JP'},
  {label: 'さわらびゴシック', value: 'Sawarabi Gothic'},
  {label: 'さわらび明朝', value: 'Sawarabi Mincho'},
  {label: 'しっぽり明朝', value: 'Shippori Mincho'},
  {label: 'ドットゴシック16', value: 'DotGothic16'},
  {label: 'ロックンロール One', value: 'RocknRoll One'},
  {label: 'ステッキ', value: 'Stick'},
  {label: 'レゲエ One', value: 'Reggae One'},
  {label: 'エムプラス1p', value: 'M PLUS 1p'},
  {label: 'ポッタ One', value: 'Potta One'},
  {label: 'はちまるポップ', value: 'Hachi Maru Pop'},
  {label: 'たぬき油性マジック', value: 'Yusei Magic'},
  {label: '小杉丸ゴシック', value: 'Kosugi Maru'},
  {label: '小杉ゴシック', value: 'Kosugi'},
];
