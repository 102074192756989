import * as React from 'react';

import BaseIndex from '../../common/speech_texts/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormSpeechTextShowSceneCommandNewSpeechTextsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as SpeechBalloonIndexParams} from '../../../../../actions/speech_balloons/index';
import {Params as OrientedSpeechBalloonIndexParams} from '../../../../../actions/oriented_speech_balloons/index';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import {QueryState} from '../../../../../reducers/queries/Response';

import selectEntities from '../../../../../helpers/selectEntities';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import CurrentUserStatus from '../../../../../../domain/entities/writer/CurrentUserStatus';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import OrientedSpeechBalloon from '../../../../../../domain/entities/OrientedSpeechBalloon';
import SpeechBalloon from '../../../../../../domain/entities/SpeechBalloon';
import Voice from '../../../../../../domain/entities/Voice';
import Sound from '../../../../../../domain/entities/Sound';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  speechBalloonId: number;
  positions: Position[];
  text?: string;
  characterName?: string;
  overrideCharacterName?: boolean;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormSpeechTextShowSceneCommandNewSpeechTextsRouteProp;
  currentUserStatus: CurrentUserStatus | null;
  sceneForm: SceneForm | null;
  sceneCommandForms: SceneCommandForm[] | null;
  speechBalloonsParams: SpeechBalloonIndexParams;
  speechBalloons: SpeechBalloon[] | null;
  orientedSpeechBalloonsParams: OrientedSpeechBalloonIndexParams;
  orientedSpeechBalloons: OrientedSpeechBalloon[] | null;
  orientedSpeechBalloonEntities: {[key: number]: OrientedSpeechBalloon};
  orientedSpeechBalloonQueries: QueryState;
  parentSceneCommandForm: SceneCommandForm | null;
}

export interface DispatchProps {
  showSpeechBalloon: (id: number) => Promise<SpeechBalloon>;
  indexSpeechBalloons: (
    params: SpeechBalloonIndexParams,
  ) => Promise<PaginatedResult<SpeechBalloon>>;
  indexOrientedSpeechBalloons: (
    params: OrientedSpeechBalloonIndexParams,
  ) => Promise<PaginatedResult<OrientedSpeechBalloon>>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    const {route, currentUserStatus} = this.props;
    const {
      speechBalloonId,
      text,
      characterName,
      overrideCharacterName,
      positions,
      sceneCommandIndex,
    } = route.params;
    return (
      <BaseIndex
        {...this.props}
        positions={positions}
        speechBalloonId={speechBalloonId}
        text={text || ''}
        characterName={characterName}
        overrideCharacterName={overrideCharacterName}
        sceneCommandIndex={sceneCommandIndex}
        enableVoice={currentUserStatus?.extensionsCodes.includes('voice')}
        onSelectText={this.handleSelectText}
      />
    );
  }

  private handleSelectText = (
    text: string,
    speechBalloonId: number,
    positions: Position[],
    characterName?: string,
    voice?: Voice | null,
    sound?: Sound | null,
    startTime?: number | null,
    endTime?: number | null,
  ) => {
    const {
      route,
      navigation,
      parentSceneCommandForm,
      orientedSpeechBalloonEntities,
      orientedSpeechBalloonQueries,
      indexOrientedSpeechBalloons,
      createSceneCommandForm,
      updateSceneCommandForm,
    } = this.props;
    const {
      sceneCommandIndex: index,
      subSceneCommandIndex,
      overrideCharacterName,
    } = route.params;

    const callback = (orientedSpeechBalloons: OrientedSpeechBalloon[]) => {
      const foundOrientedSpeechBalloon = orientedSpeechBalloons.find(
        orientedSpeechBalloon => {
          return orientedSpeechBalloon.matchesPositions(positions);
        },
      );
      if (!foundOrientedSpeechBalloon) {
        return;
      }
      if (this.disabledSubmit) {
        return;
      }
      this.disabledSubmit = true;
      const sceneCommandForm = new SpeechTextShowSceneCommandForm(
        foundOrientedSpeechBalloon,
        text,
        overrideCharacterName || null,
        characterName || null,
        voice || null,
        sound || null,
        startTime || null,
        endTime || null,
      );
      if (parentSceneCommandForm) {
        const compositeSceneCommandForm =
          CompositeSequenceSceneCommandFormFactory.add(
            parentSceneCommandForm,
            sceneCommandForm,
            subSceneCommandIndex !== undefined
              ? {insertIndex: subSceneCommandIndex}
              : undefined,
          );
        updateSceneCommandForm({sceneCommandForm: compositeSceneCommandForm});
      } else {
        createSceneCommandForm({
          index,
          sceneCommandForm,
        });
      }
      (navigation.getParent() || navigation).goBack();
    };
    const orientedSpeechBalloonParams = {speechBalloonId};
    const orientedSpeechBalloons = selectEntities(
      orientedSpeechBalloonEntities,
      orientedSpeechBalloonQueries,
      orientedSpeechBalloonParams,
    );
    if (orientedSpeechBalloons) {
      callback(orientedSpeechBalloons);
    } else {
      indexOrientedSpeechBalloons({
        speechBalloonId,
      }).then(result => {
        callback(result.records);
      });
    }
  };
}
