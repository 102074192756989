import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ProjectEpisode from '../../../../../../domain/entities/ProjectEpisode';

interface Props {
  episode: ProjectEpisode;
}

const ManuscriptListHeader: React.FC<Props> = props => {
  const {episode} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{episode.title}</Text>
    </View>
  );
};

export default React.memo(ManuscriptListHeader);

const styles = StyleSheet.create({
  container: {
    padding: 16,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
});
