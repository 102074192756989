import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import SoundView from './SoundView';

import CloseIcon from '../icons/CloseIcon';

import Sound from '../../../../domain/entities/Sound';

interface Props {
  style?: StyleProp<ViewStyle>;
  sound: Sound;
  muted?: boolean;
  playing?: boolean;
  deleteSound?: () => void;
  onPlay?: () => void;
}

const AttachmentSoundAddView: React.FC<Props> = props => {
  const {style, sound, muted, playing, deleteSound, onPlay} = props;
  return (
    <View style={[styles.container, style]}>
      <View style={styles.playButton}>
        <SoundView
          sound={sound}
          iconSize={20}
          labelStyle={styles.soundViewLabel}
          muted={muted}
          playing={playing}
          onPlay={onPlay}
        />
      </View>
      <TouchableOpacity style={styles.deleteButton} onPress={deleteSound}>
        <CloseIcon size={11} color={'#383838'} />
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(AttachmentSoundAddView);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#efefef',
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  playButton: {
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  soundViewLabel: {
    fontSize: 14,
    marginLeft: 5,
  } as ViewStyle,
  deleteButton: {
    position: 'absolute',
    right: 8,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
