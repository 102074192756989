import React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {camelizeKeys} from 'humps';
import numeral from 'numeral';

import CheckButton from '../../../shared/forms/CheckButton';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import TransferRequest from '../../../../../domain/entities/TransferRequest';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

export type Inputs = {
  amount: number;
};

interface Props {
  defaultValues?: Inputs;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {onSubmit} = props;
  const [checked1, setChecked1] = React.useState(false);
  const [checked2, setChecked2] = React.useState(false);
  const [amount, setAmount] = React.useState<number>(2000);
  const [transferRequest, setTransferRequest] =
    React.useState<TransferRequest | null>(null);
  const onChangeAmount = React.useCallback((text: string) => {
    setAmount(text ? Number.parseInt(text) : 0);
  }, []);
  const toggleChecked1 = React.useCallback(
    () => setChecked1(!checked1),
    [checked1],
  );
  const toggleChecked2 = React.useCallback(
    () => setChecked2(!checked2),
    [checked2],
  );
  const onPressPolicyTerms = React.useCallback(() => {}, []);
  const onPress = React.useCallback(() => {
    onSubmit({amount});
  }, [amount]);

  React.useEffect(() => {
    TapNovelRestApi.get('/api/writer/transfer_request', {
      amount,
    }).then(response => {
      setTransferRequest(camelizeKeys(response.body) as TransferRequest);
    });
  }, [amount]);
  const requested = false;
  if (!transferRequest) {
    return null;
  }
  return (
    <View style={styles.container}>
      <View style={styles.amountRow}>
        <Text style={styles.rowLabel}>申請額</Text>
        <View style={styles.amountRowValue}>
          <Text style={styles.amountRowValueUnit}>￥</Text>
          <TextInput
            style={styles.amountRowValueInput}
            value={`${amount}`}
            onChangeText={onChangeAmount}
          />
        </View>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowLabel}>ポイント残高</Text>
        <Text style={styles.rowValue}>
          <Text style={styles.rowValueUnit}>￥</Text>
          {numeral(transferRequest.remainingPoint).format('0,0')} pt
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowLabel}>振込手数料</Text>
        <Text style={styles.rowValue}>
          <Text style={styles.rowValueUnit}>−￥</Text>
          {numeral(transferRequest.bankTransferFee).format('0,0')}
        </Text>
      </View>
      <Text style={[styles.note, {marginBottom: 16}]}>
        ※
        <TouchableOpacity onPress={onPressPolicyTerms}>
          <Text style={styles.noteLink}>規約</Text>
        </TouchableOpacity>
        で定めている通り、振込手数料はお客様負担となります。
      </Text>

      <Text style={styles.title}>受け取れる金額</Text>
      <View style={styles.box}>
        <Text style={styles.value}>
          <Text style={styles.valueUnit}>￥</Text>
          {numeral(transferRequest.amountWithoutFee).format('0,0')}
        </Text>
      </View>
      <View style={styles.submit}>
        <CheckButton
          style={styles.submitCheck}
          checked={checked1}
          onPress={toggleChecked1}>
          口座名義は本人確認と同一です
        </CheckButton>
        <CheckButton
          style={styles.submitCheck}
          checked={checked2}
          onPress={toggleChecked2}>
          振込日は振込申請月の翌月10日前後となります
        </CheckButton>
        <PrimaryButton
          disabled={
            transferRequest.requested ||
            !checked1 ||
            !checked2 ||
            !transferRequest.requestable
          }
          style={styles.submitButton}
          onPress={onPress}>
          {transferRequest.requested ? '申請中' : '振込申請する'}
        </PrimaryButton>
      </View>
    </View>
  );
};

export default React.memo(Form) as typeof Form;

const styles = StyleSheet.create({
  container: {
    padding: 16,
  } as ViewStyle,
  amountRow: {
    marginBottom: 16,
  } as ViewStyle,
  amountRowValue: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 8,
    marginTop: 8,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  amountRowValueUnit: {
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
  amountRowValueInput: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
  } as TextStyle,
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 8,
    alignItems: 'center',
  } as ViewStyle,
  rowLabel: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  rowValue: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  rowValueUnit: {
    fontSize: 14,
  } as TextStyle,
  note: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  noteLink: {
    color: '#ff8f13',
    borderBottomColor: '#ff8f13',
    borderBottomWidth: 0.6,
  } as TextStyle,
  title: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  box: {
    height: 65,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    marginTop: 10,
    marginBottom: 32,
  } as ViewStyle,
  value: {
    color: '#383838',
    fontSize: 25,
    fontWeight: 'bold',
  } as TextStyle,
  valueUnit: {
    fontSize: 20,
  } as TextStyle,
  submit: {
    alignItems: 'center',
  } as ViewStyle,
  submitCheck: {
    width: 290,
  } as ViewStyle,
  submitButton: {
    marginTop: 16,
  } as ViewStyle,
});
