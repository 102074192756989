import * as React from 'react';

import DescriptiveTextViewWithEditorModal from './visualizations/DescriptiveTextViewWithEditorModal';

import {ModalParams} from '../../Modal';

import TextFrame from '../../../../../../domain/entities/TextFrame';
import Sound from '../../../../../../domain/entities/Sound';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';

interface Props {
  storyId: number;
  sceneCommandForm: DescriptiveTextShowSceneCommandForm;
  textFrames: TextFrame[] | null;
  enableVoice?: boolean;
  parentSceneCommandForm?: SceneCommandForm | null;
  onRequestCloseModal: () => void;
  onRemoveDescriptiveTextShowCommand: (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
  ) => void;
  onChangeDescriptiveTextShowSceneCommandForm: (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
  ) => void;
  onRequestIndexTextFrames: () => void;
  onChangeCommand: (sceneCommandForm: SceneCommandForm) => void;
  onRemoveCommand: (sceneCommandForm: SceneCommandForm) => void;
  onForwardToAddSoundEffects: (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
    parentSceneCommandId?: number,
    callback?: (sound: Sound) => void,
  ) => void;
  onRequestUpdateModal: (modalParams: ModalParams) => void;
}

export default class DescriptiveTextShowSceneCommandModal extends React.PureComponent<Props> {
  public componentDidMount() {
    this.requestEntities();
  }

  public render(): React.ReactNode {
    const {textFrames} = this.props;
    if (!textFrames) {
      return null;
    }
    return (
      <DescriptiveTextViewWithEditorModal
        {...this.props}
        textFrames={textFrames}
      />
    );
  }

  private requestEntities = () => {
    const {textFrames, onRequestIndexTextFrames} = this.props;
    if (!textFrames) {
      onRequestIndexTextFrames();
    }
  };
}
