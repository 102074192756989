import * as React from 'react';
import {
  ImageStyle,
  Text,
  TextStyle,
  TouchableOpacity,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import CommentDetail from './CommentDetail';
import ArrowUp from '../../../shared/ArrowUp';
import ArrowDown from '../../../shared/ArrowDown';

import ProjectTopicComment from '../../../../../domain/entities/ProjectTopicComment';

interface Props {
  comment: ProjectTopicComment;
}

const FoldableCommentDetail: React.FC<Props> = props => {
  const {comment} = props;
  const [folded, setFolded] = React.useState(true);
  const toggleFolded = React.useCallback(() => {
    setFolded(!folded);
  }, [folded]);
  return (
    <View style={styles.container}>
      {!folded && (
        <View style={{flexDirection: 'row', marginBottom: 16}}>
          <CommentDetail comment={comment} />
        </View>
      )}
      <TouchableOpacity style={styles.button} onPress={toggleFolded}>
        {folded ? (
          <>
            <ArrowUp />
            <Text style={styles.buttonText}>返信元コメントを表示</Text>
          </>
        ) : (
          <>
            <ArrowDown />
            <Text style={styles.buttonText}>返信元コメントを非表示</Text>
          </>
        )}
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(FoldableCommentDetail);

const styles = StyleSheet.create({
  container: {
    margin: 16,
  } as ViewStyle,
  button: {
    flexDirection: 'row',
  } as ViewStyle,
  buttonText: {
    color: '#999',
    fontSize: 10,
    fontWeight: 'bold',
    marginLeft: 5,
  } as TextStyle,
});
