import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {}

const NoOffers: React.FC<Props> = props => {
  return (
    <View style={styles.container}>
      <Text style={styles.message}>現在オファーはありません</Text>
      <Text style={styles.note}>
        ※オファーはタップライターごとに異なります。{'\n'}
        ライターランクやTapNovelでの作品の執筆状況等、サービス活動歴によってオファー内容が決まります。
      </Text>
    </View>
  );
};

export default React.memo(NoOffers);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginHorizontal: 24,
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 32,
  } as TextStyle,
  note: {
    color: '#666',
    fontSize: 11,
  } as TextStyle,
});
