import * as React from 'react';
import {
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import CharacterImageFormField from './CharacterImageFormField';

import KeyboardSpacer from '../../shared/KeyboardSpacer';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import EditableLaterMessage from '../../shared/EditableLaterMessage';
import Input from '../../shared/forms/Input';
import LabelWithOption from '../../shared/forms/LabelWithOption';
import Select from '../../shared/forms/Select';
import TextArea from '../../shared/forms/TextArea';
import TooltipModal from '../../shared/modals/TooltipModal';

import convertImageSource from '../../../helpers/convertImageSource';

import {colors, formStyles} from '../../../styles/variables';

import CharacterForm from '../../../../domain/forms/CharacterForm';
import ActorCharacterFace from '../../../../domain/entities/ActorCharacterFace';

import characterFaceUri from '../../../../assets/images/tips/character_face.png';
import characterDescriptionUri from '../../../../assets/images/tips/character_description.png';
import characterVoiceActorNameUri from '../../../../assets/images/tips/character_voice_actor_name.png';

type TooltipModalType =
  | 'actor_character_face'
  | 'description'
  | 'voice_actor_name';

const TypeToTooltipInfo = {
  actor_character_face: {
    title: '表情',
    description:
      'キャラクター一覧画面に表示される表情です。\nそのキャラクターらしい表情を選びましょう。',
    source: convertImageSource(characterFaceUri),
    imageStyle: {width: 279, height: 160},
  },
  description: {
    title: 'キャラクタープロフィール',
    description: 'キャラクター一覧画面に表示されるプロフィールとなります。',
    source: convertImageSource(characterDescriptionUri),
    imageStyle: {width: 279, height: 160},
  },
  voice_actor_name: {
    title: '声優名',
    description:
      'キャラクター情報に、キャラクターボイスを担当した声優名を表示することができます。',
    source: convertImageSource(characterVoiceActorNameUri),
    imageStyle: {width: 279, height: 160},
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

interface Props {
  characterForm: CharacterForm;
  actorCharacterFaces: ActorCharacterFace[] | null;
  enabledVoiceActorName?: boolean;
  onForwardCharacterPatterns: () => void;
  onChangeName: (name: string) => void;
  onChangeVoiceActorName: (name: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeActorCharacterFaceId: (actorCharacterFaceId: number) => void;
  onSubmit: () => void;
}

interface State {
  tooltipModalType: TooltipModalType | null;
  disabled: boolean;
  description: string;
}

export default class Form extends React.Component<Props, State> {
  private timerId: any | null = null;

  constructor(props: Props) {
    super(props);
    const {description} = props.characterForm;
    this.state = {
      tooltipModalType: null,
      disabled: false,
      description: description || '',
    };
  }

  public render(): React.ReactNode {
    const {
      characterForm,
      actorCharacterFaces,
      enabledVoiceActorName,
      onForwardCharacterPatterns,
      onChangeName,
      onChangeVoiceActorName,
    } = this.props;
    const {tooltipModalType, disabled, description} = this.state;
    const {actorCharacterFaceId} = characterForm;
    const actorCharacterFace =
      actorCharacterFaces && actorCharacterFaceId
        ? actorCharacterFaces.find(o => o.id === actorCharacterFaceId)
        : null;
    const modalInfo = tooltipModalType
      ? TypeToTooltipInfo[tooltipModalType]
      : null;
    return (
      <View style={styles.container}>
        <View style={styles.characterImage}>
          <CharacterImageFormField
            actorCharacterFace={actorCharacterFace}
            onForwardCharacterPatterns={onForwardCharacterPatterns}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'キャラクター名'}
            length={characterForm.name?.length || 0}
            maxLength={MAX_NAME_SIZE}
          />
          <Input
            placeholder={'キャラクター名を入力'}
            style={this.validName() ? null : styles.errorText}
            value={characterForm.name || ''}
            onChangeText={onChangeName}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'表情'}
            onPressQuestion={this.setTooltipModalTypeActorCharacterFace}
          />
          <Select
            style={styles.select}
            items={
              actorCharacterFaces
                ? actorCharacterFaces.map(actorCharacterFace => {
                    return {
                      label: actorCharacterFace.faceName,
                      value: actorCharacterFace.id,
                    };
                  })
                : []
            }
            value={actorCharacterFaceId}
            disabled={!((actorCharacterFaces?.length || 0) > 0)}
            rightIcon={<View style={styles.arrowIcon} />}
            confirmText={'決定'}
            cancelText={'キャンセル'}
            onValueChange={this.handleChangeActorCharacterFaceId}
          />
        </View>
        <View style={formStyles.formGroup}>
          <View style={styles.descriptionLabel}>
            <LabelWithOption
              title={`プロフィール（最大${MAX_DESCRIPTION_SIZE}文字）`}
              length={this.state.description.length}
              maxLength={MAX_DESCRIPTION_SIZE}
              onPressQuestion={this.setTooltipModalTypeDescription}
            />
          </View>
          <TextArea
            style={[
              styles.descriptionTextArea,
              this.validDescription() ? null : styles.errorText,
            ]}
            value={description}
            onChangeText={this.updateChangeDescription}
            onBlur={this.handleChangeDescription}
          />
        </View>
        {enabledVoiceActorName ? (
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'声優名'}
              length={characterForm.voiceActorName?.length || 0}
              maxLength={MAX_NAME_SIZE}
              onPressQuestion={this.setTooltipModalTypeVoiceActorName}
            />
            <Input
              placeholder={'声優名を入力'}
              style={this.validVoiceActorName() ? null : styles.errorText}
              value={characterForm.voiceActorName || ''}
              onChangeText={onChangeVoiceActorName}
            />
          </View>
        ) : null}
        <View style={styles.button}>
          <EditableLaterMessage />
          <PrimaryButton
            disabled={disabled || !this.validForm()}
            onPress={this.handleSubmit}>
            次へ
          </PrimaryButton>
        </View>
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            source={modalInfo.source}
            imageStyle={modalInfo.imageStyle}
            onCloseModal={this.clearTooltipModalType}></TooltipModal>
        ) : null}
        <KeyboardSpacer />
      </View>
    );
  }

  private setTooltipModalTypeActorCharacterFace = () => {
    this.setState({tooltipModalType: 'actor_character_face'});
  };

  private setTooltipModalTypeDescription = () => {
    this.setState({tooltipModalType: 'description'});
  };

  private setTooltipModalTypeVoiceActorName = () => {
    this.setState({tooltipModalType: 'voice_actor_name'});
  };

  private clearTooltipModalType = () => {
    this.setState({tooltipModalType: null});
  };

  private handleChangeActorCharacterFaceId = (value: string | number) => {
    const {onChangeActorCharacterFaceId} = this.props;
    onChangeActorCharacterFaceId(Number(value));
  };

  private updateChangeDescription = (description: string) => {
    const {onChangeDescription} = this.props;
    this.setState({description});
    if (this.timerId) {
      clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(() => {
      onChangeDescription(description);
      this.timerId = null;
    }, 300);
  };

  private handleChangeDescription = () => {
    const {onChangeDescription} = this.props;
    const {description} = this.state;
    onChangeDescription(description);
  };

  private validForm = () => {
    const {characterForm} = this.props;
    if (!characterForm.actorCharacterId) {
      return false;
    }
    if (!characterForm.name) {
      return false;
    }
    if (!this.validName()) {
      return false;
    }
    if (!this.validVoiceActorName()) {
      return false;
    }
    if (!this.validDescription()) {
      return false;
    }
    return true;
  };

  private validName = () => {
    const {characterForm} = this.props;
    if ((characterForm?.name?.length || 0) > MAX_NAME_SIZE) {
      return false;
    }
    return true;
  };

  private validDescription = () => {
    const {description} = this.state;
    if (description.length > MAX_DESCRIPTION_SIZE) {
      return false;
    }
    return true;
  };

  private validVoiceActorName = () => {
    const {characterForm} = this.props;
    if ((characterForm?.voiceActorName?.length || 0) > MAX_NAME_SIZE) {
      return false;
    }
    return true;
  };

  private handleSubmit = () => {
    const {onSubmit} = this.props;
    onSubmit();
  };
}

const MAX_NAME_SIZE = 20;

const MAX_DESCRIPTION_SIZE = 200;

const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  container: {
    flex: 1,
    marginBottom: 30,
  } as ViewStyle,
  characterImage: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  formGroup: {
    marginTop: 20,
  } as ViewStyle,
  input: {
    backgroundColor: 'white',
    fontSize: 14,
    height: 44,
    paddingHorizontal: 15,
  } as ViewStyle,
  inputWrapper: {
    backgroundColor: 'white',
    borderBottomWidth: 1,
    borderColor: colors.powderGray,
    borderTopWidth: 1,
  } as ViewStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  } as ViewStyle,
  select: {
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  descriptionLabel: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  descriptionCounter: {
    color: '#999999',
    fontSize: 11,
  } as TextStyle,
  descriptionTextArea: {
    height: 174,
  } as ViewStyle,
  errorText: {
    color: '#f23406',
  } as TextStyle,
});
