import EntityMapper from '../EntityMapper';

import UserProfile from '../../../../domain/entities/consumer/UserProfile';

export interface UserProfileData {
  id: number;
  description: string;
  has_image: boolean;
  original_image_url: string;
}

export default class UserProfileMapper
  implements EntityMapper<UserProfileData, UserProfile>
{
  public map(obj: UserProfileData): UserProfile {
    return new UserProfile(
      obj.id,
      obj.description,
      obj.has_image,
      obj.original_image_url,
    );
  }
}
