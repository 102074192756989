import * as React from 'react';

import Content from './partials/Content';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {WriterUserPasswordCompletionRouteProp} from '../../../navigators/RouteProps';

export interface Params {
  email: string;
}

export interface StateProps {
  navigation: NavigationProp;
  route: WriterUserPasswordCompletionRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

interface State {}

export default class Index extends React.PureComponent<Props, State> {
  public render(): React.ReactNode {
    const {navigation, route} = this.props;
    const {email} = route.params;
    return (
      <Layout
        title={'パスワード再設定メール送信'}
        scrollable={false}
        navigation={navigation}
        back={true}>
        <Content
          email={email}
          refreshing={false}
          onRefresh={this.handleRefresh}
        />
      </Layout>
    );
  }

  private handleRefresh = () => {};
}
