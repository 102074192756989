import React from 'react';

import RootStack from '../RootStack';
import {DefaultTransition} from '../BaseNavigator';

import CoverImageFormBackgroundUsageHistories from '../../containers/cover_image_form/background_usage_histories/Index';
import CoverImageFormBackgrounds from '../../containers/cover_image_form/backgrounds/Index';
import CoverImageFormBaseBackgroundSearchForm from '../../containers/cover_image_form/base_background_search_form/Index';
import CoverImageFormBaseBackgrounds from '../../containers/cover_image_form/base_backgrounds/Index';
import CoverImageFormImageCrop from '../../containers/cover_image_form/image_crop/Index';
import CoverImageFormText from '../../containers/cover_image_form/text/Index';
import CoverImageFormTextPreview from '../../containers/cover_image_form/text_preview/Index';
import CoverImageFormCharacterPatterns from '../../containers/cover_image_form/character_patterns/Index';
import CoverImageFormCharacterFormActorSearchForm from '../../containers/cover_image_form/character_form/actor_search_form/Index';
import CoverImageFormCharacterFormActors from '../../containers/cover_image_form/character_form/actors/Index';
import CoverImageFormCharacterFormDefaultCharacterPatterns from '../../containers/cover_image_form/character_form/default_character_patterns/Index';
import CoverImageFormCharacterFormCharacterNames from '../../containers/cover_image_form/character_form/character_names/Index';
import CoverImageFormActorCharacterFaces from '../../containers/cover_image_form/actor_character_faces/Index';
import CoverImageFormCharacterMakerHome from '../../containers/cover_image_form/character_maker/home/Index';
import CoverImageFormCharacterMakerActors from '../../containers/cover_image_form/character_maker/actors/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="CoverImageFormBackgroundUsageHistories">
      <RootStack.Screen
        name="CoverImageFormBackgroundUsageHistories"
        component={CoverImageFormBackgroundUsageHistories}
        options={{title: '背景'}}
      />
      <RootStack.Screen
        name="CoverImageFormBackgrounds"
        component={CoverImageFormBackgrounds}
        options={{title: '背景'}}
      />
      <RootStack.Screen
        name="CoverImageFormBaseBackgroundSearchForm"
        component={CoverImageFormBaseBackgroundSearchForm}
        options={{title: '背景検索条件'}}
      />
      <RootStack.Screen
        name="CoverImageFormBaseBackgrounds"
        component={CoverImageFormBaseBackgrounds}
        options={{title: '背景'}}
      />
      <RootStack.Screen
        name="CoverImageFormImageCrop"
        component={CoverImageFormImageCrop}
        options={{title: '背景'}}
      />
      <RootStack.Screen
        name="CoverImageFormText"
        component={CoverImageFormText}
        options={{title: 'テキスト入力'}}
      />
      <RootStack.Screen
        name="CoverImageFormTextPreview"
        component={CoverImageFormTextPreview}
        options={{title: 'テキスト配置'}}
      />
      <RootStack.Screen
        name="CoverImageFormCharacterPatterns"
        component={CoverImageFormCharacterPatterns}
        options={{title: 'キャラクター'}}
      />
      <RootStack.Screen
        name="CoverImageFormCharacterFormActorSearchForm"
        component={CoverImageFormCharacterFormActorSearchForm}
        options={{title: 'キャラクター検索条件'}}
      />
      <RootStack.Screen
        name="CoverImageFormCharacterFormActors"
        component={CoverImageFormCharacterFormActors}
        options={{title: 'キャラクター'}}
      />
      <RootStack.Screen
        name="CoverImageFormCharacterFormDefaultCharacterPatterns"
        component={CoverImageFormCharacterFormDefaultCharacterPatterns}
        options={{title: '衣装'}}
      />
      <RootStack.Screen
        name="CoverImageFormCharacterFormCharacterNames"
        component={CoverImageFormCharacterFormCharacterNames}
        options={{title: 'キャラクター名'}}
      />
      <RootStack.Screen
        name="CoverImageFormActorCharacterFaces"
        component={CoverImageFormActorCharacterFaces}
        options={{title: '表情選択'}}
      />
      <RootStack.Screen
        name="CoverImageFormCharacterMakerHome"
        component={CoverImageFormCharacterMakerHome}
        options={{title: '素体'}}
      />
      <RootStack.Screen
        name="CoverImageFormCharacterMakerActors"
        component={CoverImageFormCharacterMakerActors}
        options={{title: 'キャラクター'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
