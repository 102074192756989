import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import UserNotificationSettingsBatch from '../../../../domain/entities/writer/UserNotificationSettingsBatch';

export default function reducer(
  state: {[key: number]: UserNotificationSettingsBatch} = {},
  action: AnyAction,
): {[key: number]: UserNotificationSettingsBatch} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.ShowWriterUserNotificationSettingsBatchSuccess:
    case Types.UpdateWriterUserNotificationSettingsBatchSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
