import * as React from 'react';

import IterationList from './partials/IterationList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectIterationsRouteProp} from '../../../navigators/RouteProps';

import ProjectIteration from '../../../../domain/entities/ProjectIteration';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectIterationsRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {projectId} = route.params;
  const [iterations, setIterations] = React.useState<ProjectIteration[] | null>(
    null,
  );
  React.useEffect(() => {
    TapNovelTypedRestApi.get<ProjectIteration[]>(
      '/api/writer/project_iterations',
      {projectId, completed: true},
    ).then(result => {
      setIterations(result.body);
    });
  }, []);
  return (
    <Layout
      title={'過去のターム一覧'}
      navigation={navigation}
      back={true}
      scrollable={false}>
      {iterations && <IterationList iterations={iterations} />}
    </Layout>
  );
};

export default React.memo(Index);
