import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import StoryList from './partials/StoryList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {WritersGateStoriesRouteProp} from '../../../navigators/RouteProps';

import Story from '../../../../domain/entities/writers_gate/Story';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  offerId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: WritersGateStoriesRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {offerId} = route.params;
  const [stories, setStories] = React.useState<Array<Story> | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelTypedRestApi.get<Array<Story>>(
        '/api/writer/writers_gate/stories',
      ).then(response => {
        setStories(response.body);
      });
    }, []),
  );
  const onSelectStory = React.useCallback((story: Story) => {
    navigation.navigate('WritersGateStory', {storyId: story.id, offerId});
  }, []);
  return (
    <Layout title={'ストーリーを選択'} navigation={navigation} back={true}>
      <StoryList stories={stories} onSelectStory={onSelectStory} />
    </Layout>
  );
};

export default React.memo(Index);
