import * as React from 'react';

import BaseArrow from './BaseArrow';

import ArrowForwardIcon from '../icons/ArrowForwardIcon';

interface Size {
  height: number;
  width: number;
}

interface Props {
  containerSize: Size;
  onPress?: () => void;
  children?: React.ReactNode;
}

const ArrowRight: React.FunctionComponent<Props> = props => {
  const {containerSize, onPress} = props;
  return (
    <BaseArrow
      containerSize={containerSize}
      position={'right'}
      onPress={onPress}>
      <ArrowForwardIcon size={14} color={'white'} />
    </BaseArrow>
  );
};

export default React.memo(ArrowRight);
