import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';

import exportKeyVisuallUri from '../../../../../assets/svgs/export-key-visual.svg';

interface Props {
  onAgreeServicePolicy: () => void;
}

const ServiceExplanation: React.FunctionComponent<Props> = props => {
  const {onAgreeServicePolicy} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        TapNovelMakerでつくった作品の{'\n'}
        テキストデータをエクスポートできます。
      </Text>
      <View style={styles.keyVisual}>
        <SvgUriAdapter uri={exportKeyVisuallUri} width={295} height={160} />
      </View>
      <PrimaryButton onPress={onAgreeServicePolicy}>
        テキストデータをエクスポートする
      </PrimaryButton>
    </View>
  );
};

export default React.memo(ServiceExplanation);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  keyVisual: {
    marginVertical: 24,
  } as ViewStyle,
});
