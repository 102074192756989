import * as React from 'react';

import WriterSummary from './partials/WriterSummary';
import WriterAggregationView from './partials/WriterAggregationView';
import StoryList from './partials/StoryList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {Option as SummaryAccountAggregationShowOption} from '../../../actions/summary/account_aggregations/show';
import {Params as SummaryStoryAggregationIndexParams} from '../../../actions/summary/story_aggregations/index';

import AccountAggregation from '../../../../domain/value_objects/summary/AccountAggregation';
import StoryAggregation from '../../../../domain/value_objects/summary/StoryAggregation';

import {
  TermRange,
  convertTermRangeToQueryParams,
} from '../../../view_models/TermRange';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {
  showSummaryAccountAggregation: (
    option: SummaryAccountAggregationShowOption,
  ) => Promise<AccountAggregation>;
  indexSummaryStoryAggregations: (
    params: SummaryStoryAggregationIndexParams,
  ) => Promise<Array<StoryAggregation>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  accountAggregation: AccountAggregation | null;
  storyAggregations: Array<StoryAggregation> | null;
  termRange: TermRange;
}
export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accountAggregation: null,
      storyAggregations: null,
      termRange: 'all',
    };
  }

  public componentDidMount() {
    this.fetchAggregations();
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {accountAggregation, storyAggregations, termRange} = this.state;
    return (
      <Layout
        title={'分析'}
        scrollable={false}
        navigation={navigation}
        back={true}>
        {accountAggregation && storyAggregations && (
          <StoryList
            storyAggregations={storyAggregations}
            ListHeaderComponent={
              <>
                <WriterSummary accountAggregation={accountAggregation} />
                <WriterAggregationView
                  accountAggregation={accountAggregation}
                  termRange={termRange}
                  onTermRangeChange={this.handleTermRangeChange}
                />
              </>
            }
            onSelectStory={this.handleSelectStory}
          />
        )}
      </Layout>
    );
  }

  private handleSelectStory = (storyAggregation: StoryAggregation) => {
    const {navigation} = this.props;
    navigation.navigate('AnalysisStory', {storyId: storyAggregation.id});
  };

  private fetchAggregations = () => {
    const {showSummaryAccountAggregation, indexSummaryStoryAggregations} =
      this.props;
    const {termRange} = this.state;
    showSummaryAccountAggregation(
      convertTermRangeToQueryParams(termRange),
    ).then(accountAggregation => this.setState({accountAggregation}));
    indexSummaryStoryAggregations(
      convertTermRangeToQueryParams(termRange),
    ).then(storyAggregations => this.setState({storyAggregations}));
  };

  private handleTermRangeChange = (termRange: TermRange) => {
    this.setState({termRange}, () => {
      this.fetchAggregations();
    });
  };
}
