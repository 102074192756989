import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import Headline from '../../../../../shared/Headline';
import SoundList from '../../../../../shared/scene_form/SoundList';

import SoundUsageHistory from '../../../../../../../domain/entities/SoundUsageHistory';

interface Props {
  soundUsageHistories: SoundUsageHistory[];
  onSelectSoundUsageHistory: (soundUsageHistory: SoundUsageHistory) => void;
  onForwardToFavoriteList?: () => void;
}

export default class SoundUsageHistoryList extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      soundUsageHistories,
      onSelectSoundUsageHistory,
      onForwardToFavoriteList,
    } = this.props;
    return (
      <>
        <Headline
          title={'使用したBGM'}
          onPressFavoriteList={onForwardToFavoriteList}
        />
        <SoundList
          style={styles.container}
          entities={soundUsageHistories}
          ListFooterComponent={ListFooterComponent}
          onSelectEntity={onSelectSoundUsageHistory}
        />
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: Platform.select({web: 84, default: undefined}),
  } as ViewStyle,
  spacer: {
    height: 84,
    width: '100%',
  } as ViewStyle,
});

const ListFooterComponent = <View style={styles.spacer} />;
