import * as React from 'react';
import {StyleSheet, Text, View, ViewStyle} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';

import PrimaryButton from '../buttons/PrimaryButton';
import DefaultModal from '../modals/DefaultModal';

import {modalStyles} from '../../../styles/variables';

interface Props {
  visible: boolean;
  onRequestClose: () => void;
}

const DeleteAccountCompletionModal: React.FunctionComponent<Props> = props => {
  const {visible, onRequestClose} = props;
  return (
    <DefaultModal
      visible={visible}
      onCloseModal={onRequestClose}
      footer={
        <PrimaryButton style={styles.button} onPress={onRequestClose}>
          OK
        </PrimaryButton>
      }>
      <View style={styles.body}>
        <Text style={modalStyles.text}>退会しました</Text>
      </View>
    </DefaultModal>
  );
};

export default onlyUpdateForKeys(['visible'])(DeleteAccountCompletionModal);

const styles = StyleSheet.create({
  body: {
    marginTop: 40,
    marginBottom: 16,
  } as ViewStyle,
  button: {
    marginTop: 16,
    marginBottom: 32,
    width: 145,
    height: 46,
  } as ViewStyle,
});
