import * as React from 'react';

import BaseIndex from '../../common/sound_usage_histories/Index';

import shouldUpdateEntityList from '../../../../shared/enhanced/shouldUpdateEntityList';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormSoundEffectShowSceneCommandNewSoundUsageHistoriesRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as SoundUsageHistoryIndexParams} from '../../../../../actions/sound_usage_histories/index';
import {Params as SoundUsageHistoryUpdateParams} from '../../../../../actions/sound_usage_histories/update';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import {equalForKeys} from '../../../../../helpers/equalForKeys';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import Sound from '../../../../../../domain/entities/Sound';
import SoundUsageHistory from '../../../../../../domain/entities/SoundUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  back?: boolean;
  page?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  callback?: (sound: Sound) => void;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormSoundEffectShowSceneCommandNewSoundUsageHistoriesRouteProp;
  sceneForm: SceneForm | null;
  soundUsageHistories: SoundUsageHistory[] | null;
  soundUsageHistoriesParams: SoundUsageHistoryIndexParams;
  parentSceneCommandForm: SceneCommandForm | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexSoundUsageHistories: (
    params: SoundUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<SoundUsageHistory>>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateSoundUsageHistory: (
    id: number,
    params: SoundUsageHistoryUpdateParams,
  ) => Promise<SoundUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  private disabledSubmit = false;

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (!equalForKeys(this.props, nextProps, ['soundUsageHistoriesParams'])) {
      return true;
    }
    if (
      shouldUpdateEntityList(
        {
          entities: this.props.soundUsageHistories,
        },
        {entities: nextProps.soundUsageHistories},
      )
    ) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      soundUsageHistories,
      soundUsageHistoriesParams,
      indexSoundUsageHistories,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!soundUsageHistories) {
      indexSoundUsageHistories(soundUsageHistoriesParams);
    }
  }

  public render(): React.ReactNode {
    return (
      <BaseIndex
        {...this.props}
        onForwardToNewSoundUsageHistory={
          this.handleForwardToNewSoundUsageHistory
        }
        onSelectSoundUsageHistory={this.handleSelectSoundUsageHistory}
        onForwardToFavoriteList={this.handleForwardToFavoriteList}
      />
    );
  }

  private handleSelectSoundUsageHistory = (
    soundUsageHistory: SoundUsageHistory,
  ) => {
    const {
      route,
      navigation,
      parentSceneCommandForm,
      createSceneCommandForm,
      updateSceneCommandForm,
      updateSoundUsageHistory,
    } = this.props;
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      storyId,
      sceneCommandIndex: index,
      subSceneCommandIndex,
      callback,
    } = route.params;
    const {sound} = soundUsageHistory;
    const sceneCommandForm = new SoundEffectShowSceneCommandForm(
      sound,
      null,
      null,
    );
    if (callback) {
      callback(sound);
    } else if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.add(
          parentSceneCommandForm,
          sceneCommandForm,
          subSceneCommandIndex !== undefined
            ? {insertIndex: subSceneCommandIndex}
            : undefined,
        ),
      });
    } else {
      createSceneCommandForm({
        index,
        sceneCommandForm,
      });
    }
    updateSoundUsageHistory(sound.id, {
      storyId,
      soundType: 'sound_effect',
    }).then(() => {
      (navigation.getParent() || navigation).goBack();
    });
  };

  private handleForwardToNewSoundUsageHistory = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      callback,
    } = route.params;
    routers.linkToSceneFormSoundEffectShowSceneCommandNewSoundSearchForm(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        callback,
      },
    );
  };

  private handleForwardToFavoriteList = () => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
    } = route.params;
    routers.linkToSceneFormSoundEffectShowSceneCommandNewSounds(navigation, {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      favorite: true,
    });
  };
}
