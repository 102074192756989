import {Dispatch} from 'redux';

import AppState from '../../../reducers/AppState';

import EpisodeBrowsingRetentionRate from '../../../../domain/value_objects/summary/EpisodeBrowsingRetentionRate';

import NetEpisodeBrowsingRetentionRatesRepository from '../../../../data/repositories/writer/summary/NetEpisodeBrowsingRetentionRatesRepository';

export interface Params {
  episodeId: number;
  from?: Date | undefined;
  to?: Date | undefined;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<Array<EpisodeBrowsingRetentionRate>> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<Array<EpisodeBrowsingRetentionRate>> => {
    const repository = new NetEpisodeBrowsingRetentionRatesRepository();
    const payload = await repository.findBy(params);
    return payload;
  };
}
