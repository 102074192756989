import * as React from 'react';
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import Row from './Row';

interface Props {
  rows: Array<{0: string; 1: string; 2: string}>;
  label0Style?: StyleProp<TextStyle>;
  label1Style?: StyleProp<TextStyle>;
  label2Style?: StyleProp<TextStyle>;
}

const Body: React.FunctionComponent<Props> = props => {
  const {rows, ...rest} = props;
  return (
    <View style={styles.container}>
      {rows.map(row => {
        return <Row labels={row} {...rest} />;
      })}
    </View>
  );
};

export default React.memo(Body);

const styles = StyleSheet.create({
  container: {
    borderColor: '#d5d5d5',
    borderWidth: 0,
    borderTopWidth: 1,
    borderLeftWidth: 1,
  } as ViewStyle,
});
