import * as React from 'react';
import {
  ImageStyle,
  Linking,
  Platform,
  Text,
  TextStyle,
  TouchableOpacity,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {useActionSheet} from '@expo/react-native-action-sheet';
import Config from 'react-native-config';

import MemberImage from './MemberImage';

import ActionModal from '../../../shared/modals/ActionModal';
import HeartIcon from '../../../shared/icons/project/HeartIcon';
import MoreIcon from '../../../shared/icons/ionicons/MoreIcon';

import ProjectMember from '../../../../../domain/entities/ProjectMember';
import ProjectUserStatus from '../../../../../domain/entities/ProjectUserStatus';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  member: ProjectMember;
  leaderSection?: boolean;
  onForceUpdate?: () => void;
}

const MemberListItem: React.FC<Props> = props => {
  const {member, leaderSection, onForceUpdate} = props;
  const {showActionSheetWithOptions} = useActionSheet();
  const [visibleModal, setVisibleModal] = React.useState(false);
  const onCheckAndOpenActionSheet = React.useCallback(() => {
    if (leaderSection) {
      return onOpenActionSheet(false);
    }
    TapNovelTypedRestApi.get<ProjectUserStatus>(
      '/api/writer/project_user_status',
      {projectId: member.projectId},
    ).then(result => {
      onOpenActionSheet(result.body.role === 'leader');
    });
  }, []);
  const onOpenActionSheet = React.useCallback((isLeader: boolean) => {
    const options = [
      'プロフィールを表示',
      ...(isLeader ? ['プロジェクトから追放'] : []),
      'キャンセル',
    ];
    const showWriterProfile = 0;
    const dismissProjectIndex = isLeader ? 1 : 99;
    const cancelButtonIndex = isLeader ? 2 : 1;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        options,
        destructiveButtonIndex: dismissProjectIndex,
      },
      async (buttonIndex?: number) => {
        switch (buttonIndex) {
          case dismissProjectIndex:
            setVisibleModal(true);
            break;
          case showWriterProfile:
            Linking.openURL(generateUrl(`/writers/${member.writerUser.id}`));
            break;
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, []);
  const onRequestClose = React.useCallback(() => {
    setVisibleModal(false);
  }, []);
  const onAccept = React.useCallback(() => {
    TapNovelTypedRestApi.delete(
      `/api/writer/leader/project_members/${member.id}`,
    ).then(() => {
      setVisibleModal(false);
      onForceUpdate && onForceUpdate();
    });
  }, []);
  return (
    <>
      <View style={styles.container}>
        <View style={styles.imageWrapper}>
          <MemberImage
            member={member}
            imageSize={IMAGE_SIZE}
            withRankColor={true}
          />
        </View>
        <Text style={styles.penName}>{member.writerUser.penName}</Text>
        {member.writerUser.fansCount ? (
          <View style={styles.fan}>
            <HeartIcon />
            <Text style={styles.fanText}>ファン</Text>
            <Text style={styles.fanCount}>{member.writerUser.fansCount}</Text>
          </View>
        ) : null}
        <TouchableOpacity
          style={styles.headerRight}
          onPress={onCheckAndOpenActionSheet}>
          <View style={styles.more}>
            <MoreIcon />
          </View>
        </TouchableOpacity>
      </View>
      <ActionModal
        visible={visibleModal}
        title={'プロジェクトから追放'}
        description={'このユーザーをプロジェクトから\n追放しますか？'}
        titleStyle={{color: '#222'}}
        descriptionStyle={{textAlign: 'center', fontSize: 14}}
        withCloseIcon={true}
        buttonText={'追放'}
        onRequestClose={onRequestClose}
        onAccept={onAccept}
      />
    </>
  );
};

export default React.memo(MemberListItem);

const IMAGE_SIZE = 60;

const styles = StyleSheet.create({
  container: {
    height: 81,
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 16,
  } as ViewStyle,
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: IMAGE_SIZE / 2,
    backgroundColor: '#ffffff',
    height: IMAGE_SIZE,
    overflow: 'hidden',
    width: IMAGE_SIZE,
  } as ViewStyle,
  image: {
    width: IMAGE_SIZE,
    height: IMAGE_SIZE,
  } as ImageStyle,
  fan: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  penName: {
    flex: 1,
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    paddingHorizontal: 10,
  } as TextStyle,
  fanText: {
    color: '#222',
    fontSize: 12,
    marginHorizontal: 4,
  } as TextStyle,
  fanCount: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
  headerRight: {
    alignItems: 'center',
    height: 44,
    justifyContent: 'center',
    position: 'absolute',
    right: -10,
    top: -10,
    width: 44,
    zIndex: 100,
  } as ViewStyle,
  more: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
});

const generateUrl = (path: string) => {
  if (Platform.OS === 'web') {
    return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  } else {
    return Config.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
  }
};
