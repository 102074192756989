import AutoSaveSceneMapper, {AutoSaveSceneData} from './AutoSaveSceneMapper';

import AutoSaveUpdatingScene from '../../../domain/entities/AutoSaveUpdatingScene';

export interface AutoSaveUpdatingSceneData extends AutoSaveSceneData {}

export default class AutoSaveUpdatingSceneMapper extends AutoSaveSceneMapper<
  AutoSaveUpdatingSceneData,
  AutoSaveUpdatingScene
> {
  public map(obj: AutoSaveUpdatingSceneData): AutoSaveUpdatingScene {
    return new AutoSaveUpdatingScene(
      obj.id,
      this.mapSceneForm(obj),
      this.mapSceneCommandForms(obj),
      new Date(obj.updatedAt),
    );
  }
}
