import {Dispatch} from 'redux';
import {v4} from 'uuid';

import Types from '../Types';

import File from '../../../domain/entities/File';
import FullScreenIllustration from '../../../domain/entities/FullScreenIllustration';

import NetFullScreenIllustrationsRepository from '../../../data/repositories/writer/NetFullScreenIllustrationsRepository';

export interface Params {
  storyId: number;
  image: File;
  uploadedSelfMaterial?: boolean;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<FullScreenIllustration> {
  return async (dispatch: Dispatch<any>): Promise<FullScreenIllustration> => {
    try {
      const code = v4();
      const payload = await new NetFullScreenIllustrationsRepository().create(
        {
          fullScreenIllustration: {
            ...params,
            code,
            name: baseName(params.image?.name) || code,
          },
        },
        {multipart: true},
      );
      dispatch({payload, type: Types.CreateFullScreenIllustrationSuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateFullScreenIllustrationFailure});
      throw err;
    }
  };
}

function baseName(str: string) {
  if (!str) {
    return null;
  }
  let base = new String(str).substring(str.lastIndexOf('/') + 1);
  if (base.lastIndexOf('.') != -1)
    base = base.substring(0, base.lastIndexOf('.'));
  return base;
}
