import Entity from './Entity';

import FramePartPosition from '../value_objects/FramePartPosition';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class TextFramePart implements Entity {
  constructor(
    public id: number,
    public textFrameId: number,
    public position: FramePartPosition,
    public originalImageUrl: string,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }
}
