import * as React from 'react';
import {
  FlatList,
  ListRenderItemInfo,
  StyleSheet,
  ViewStyle,
} from 'react-native';

import CharacterMakerActorGroupListItem from './CharacterMakerActorGroupListItem';

import CharacterMakerActorGroup from '../../../../../../../domain/entities/CharacterMakerActorGroup';

interface Props {
  contentWidth: number;
  characterMakerActorGroups: CharacterMakerActorGroup[] | null;
  onSelectCharacterMakerActorGroup: (
    characterMakerActorGroup: CharacterMakerActorGroup,
    skinColorPatternId: number,
  ) => void;
}

const ActorCharacterFaceList: React.FunctionComponent<Props> = props => {
  const {
    contentWidth,
    characterMakerActorGroups,
    onSelectCharacterMakerActorGroup,
  } = props;
  const keyExtractor = React.useCallback(
    (item: CharacterMakerActorGroup) => `${item.id}`,
    [],
  );
  const width =
    (contentWidth - (MARGIN_HORIZONTAL + CONTENT_MARGIN * 2) * 2) / 2;
  const height = (width / 168) * 60;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<CharacterMakerActorGroup>) => (
      <CharacterMakerActorGroupListItem
        style={[styles.content, {width, height}]}
        characterMakerActorGroup={info.item}
        onSelectCharacterMakerActorGroup={onSelectCharacterMakerActorGroup}
      />
    ),
    [contentWidth],
  );
  return (
    <FlatList
      data={characterMakerActorGroups}
      style={styles.container}
      contentContainerStyle={styles.contentContainerStyle}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      numColumns={NUM_COLUMNS}
    />
  );
};

export default ActorCharacterFaceList;

const MARGIN_HORIZONTAL = 12;

const CONTENT_MARGIN = 4;

const NUM_COLUMNS = 2;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: MARGIN_HORIZONTAL,
    backgroundColor: '#fafafa',
  } as ViewStyle,
  contentContainerStyle: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  content: {
    margin: CONTENT_MARGIN,
  } as ViewStyle,
});
