import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import Select from '../../../forms/Select';

interface Props {
  ageMin: number;
  ageMax: number;
  onAgeMinChange: (ageMin: string | number) => void;
  onAgeMaxChange: (ageMax: string | number) => void;
}

const AgeRange: React.FunctionComponent<Props> = props => {
  const {ageMin, ageMax, onAgeMinChange, onAgeMaxChange} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>年齢</Text>
      <View style={styles.selectWrapper}>
        <Select
          style={styles.select}
          items={AGE_MIN_SELECT_OPTIONS}
          value={ageMin}
          confirmText={'決定'}
          cancelText={'キャンセル'}
          rightIcon={<View style={styles.arrowIcon} />}
          onValueChange={onAgeMinChange}
        />
        <Text style={styles.rangeSymbol}>〜</Text>
        <Select
          style={styles.select}
          items={AGE_MAX_SELECT_OPTIONS}
          value={ageMax}
          confirmText={'決定'}
          cancelText={'キャンセル'}
          rightIcon={<View style={styles.arrowIcon} />}
          onValueChange={onAgeMaxChange}
        />
      </View>
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(AgeRange, propsAreEqual);

const AGE_MIN_SELECT_OPTIONS = [
  {label: '下限なし', value: 0},
  {label: '10代', value: 1},
  {label: '20代', value: 2},
  {label: '30代', value: 3},
  {label: '40代', value: 4},
  {label: '50代', value: 5},
  {label: '60代以上', value: 6},
];

const AGE_MAX_SELECT_OPTIONS = [
  {label: '10代未満', value: 0},
  {label: '10代', value: 1},
  {label: '20代', value: 2},
  {label: '30代', value: 3},
  {label: '40代', value: 4},
  {label: '50代', value: 5},
  {label: '上限なし', value: 6},
];

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 8,
  } as ViewStyle,
  title: {
    marginHorizontal: 16,
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  selectWrapper: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 16,
    height: 30,
  } as ViewStyle,
  rangeSymbol: {
    marginHorizontal: 16,
    alignSelf: 'center',
    color: '#383838',
    fontSize: 14,
  } as TextStyle,
  select: {
    flex: 1,
    justifyContent: 'center',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
  },
});
