import * as React from 'react';
import {ActivityIndicator, StyleSheet, View, ViewStyle} from 'react-native';
import {useFocusEffect} from '@react-navigation/native';

import EpisodeList from '../episodes/partials/EpisodeList';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {WritersGateStoryRouteProp} from '../../../navigators/RouteProps';

import {Params as ApplicationModalUpdateParams} from '../../../actions/application_modal/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import Episode from '../../../../domain/entities/writers_gate/Episode';
import Story from '../../../../domain/entities/writers_gate/Story';
import OfferManuscript from '../../../../domain/entities/writers_gate/OfferManuscript';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  storyId: number;
  offerId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: WritersGateStoryRouteProp;
}

export interface DispatchProps {
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route, updateApplicationModal} = props;
  const {storyId, offerId} = route.params;
  const [episodes, setEpisodes] = React.useState<Array<Episode> | null>(null);
  const [story, setStory] = React.useState<Story | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const submitting = React.useRef(false);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelTypedRestApi.get<Array<Episode>>(
        '/api/writer/writers_gate/episodes',
        {
          storyId,
        },
      ).then(response => {
        setEpisodes(response.body);
      });
      TapNovelTypedRestApi.get<Story>(
        `/api/writer/writers_gate/stories/${storyId}`,
        {
          storyId,
        },
      ).then(response => {
        setStory(response.body);
      });
    }, []),
  );
  const onSelectEpisode = React.useCallback(
    (episode: Episode) => {
      if (submitting.current) {
        return;
      }
      if (!story) {
        return;
      }
      setLoading(true);
      submitting.current = true;
      TapNovelTypedRestApi.post<OfferManuscript>(
        '/api/writer/writers_gate/offer_manuscripts',
        {
          offerManuscript: {
            writersGateOfferId: offerId,
            title: `${story.title} / ${episode.title}`,
            copyEpisodeId: episode.id,
          },
        },
        {multipart: true},
      )
        .then(manuscript => {
          setTimeout(() => {
            setLoading(false);
          }, 300);
          if (manuscript.body.episodeId) {
            navigation.goBack();
            navigation.push('Episode', {
              episodeId: manuscript.body.episodeId,
            });
          } else {
            const message =
              manuscript.body.writersGateOffer.typeCode === 'idea'
                ? 'オファーにアイデアを提出しました。'
                : 'オファーにプロットを提出しました。';
            updateApplicationModal({options: {message}});
            submitting.current = false;
            navigation.goBack();
          }
        })
        .catch(e => {
          setLoading(false);
          setAlertMessage(formatErrorMessages({}, e));
          submitting.current = false;
        });
    },
    [story],
  );
  return (
    <Layout title={'エピソードを選択'} navigation={navigation} back={true}>
      {story && (
        <EpisodeList episodes={episodes} onSelectEpisode={onSelectEpisode} />
      )}
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalAlert}>
          {alertMessage}
        </AlertModal>
      )}
      {loading && (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color="#999999" />
        </View>
      )}
    </Layout>
  );
};

export default React.memo(Show);

const styles = StyleSheet.create({
  loading: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
});
