import Entity from './Entity';

import EpisodeComment from './EpisodeComment';

export default class EpisodeCommentBlockingUser implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public userId: number,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}

  public blocked(episodeComment: EpisodeComment) {
    if (episodeComment.user.id !== this.userId) {
      return false;
    }
    if (episodeComment.episodeTip) {
      return episodeComment.episodeTip.storyId === this.storyId;
    } else if (episodeComment.episodeReaction) {
      return episodeComment.episodeReaction.storyId === this.storyId;
    }
    return false;
  }
}
