import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import QuestionDetail from './components/QuestionDetail';
import ResolveButton from './components/ResolveButton';

import AnswerList from '../answers/components/AnswerList';
import NewAnswerButton from '../answers/components/NewAnswerButton';
import NoAnswers from '../answers/components/NoAnswers';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {FaqQuestionRouteProp} from '../../../navigators/RouteProps';

import Question from '../../../../domain/entities/writer/faq/Question';
import Answer from '../../../../domain/entities/writer/faq/Answer';
import AnswerLike from '../../../../domain/entities/writer/faq/AnswerLike';
import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: FaqQuestionRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route, currentUser} = props;
  const {id} = route.params;
  const [question, setQuestion] = React.useState<Question | null>(null);
  const [answers, setAnswers] = React.useState<Array<Answer> | null>(null);
  const [answerLikes, setAnswerLikes] =
    React.useState<Array<AnswerLike> | null>(null);
  React.useEffect(() => {
    setTimeout(() => {
      TapNovelRestApi.patch(`/api/writer/faq/questions/${id}/page_view`);
    }, 100);
  }, []);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelRestApi.get(`/api/writer/faq/questions/${id}`)
        .then(response => {
          setQuestion(camelizeKeys(response.body) as Question);
        })
        .catch(() => {});
      TapNovelRestApi.get(`/api/writer/faq/answers/`, {writerFaqQuestionId: id})
        .then(response => {
          setAnswers(camelizeKeys(response.body) as Array<Answer>);
        })
        .catch(() => {});
      TapNovelRestApi.get(`/api/writer/faq/answer_likes/`, {
        writerFaqQuestionId: id,
      })
        .then(response => {
          setAnswerLikes(camelizeKeys(response.body) as Array<AnswerLike>);
        })
        .catch(() => {
          setAnswerLikes([]);
        });
    }, [id]),
  );
  const onRequestResolve = React.useCallback(() => {
    if (!question) {
      return;
    }
    TapNovelRestApi.patch(`/api/writer/faq/questions/${question.id}`, {
      question: {resolved: true},
    }).then(response => {
      setQuestion(camelizeKeys(response.body) as Question);
    });
  }, [question]);
  const selfWriterUserId = currentUser?.writerUserId;
  return (
    <Layout
      title={'お問い合わせ詳細'}
      navigation={navigation}
      scrollable={true}
      back={true}>
      {question ? <QuestionDetail question={question} /> : null}
      {question && answers && answerLikes ? (
        answers.length > 0 ? (
          <>
            <AnswerList
              answers={answers}
              answerLikes={answerLikes}
              questionWriterUserId={question.writerUser.id}
              selfWriterUserId={selfWriterUserId}
            />
            <NewAnswerButton
              question={question}
              answered={
                !!answers.find(a => a.writerUser.id === selfWriterUserId)
              }
              selfWriterUserId={selfWriterUserId}
            />
          </>
        ) : (
          <NoAnswers question={question} selfWriterUserId={selfWriterUserId} />
        )
      ) : null}
      {question && answers && answers.length > 0 ? (
        <ResolveButton
          question={question}
          selfWriterUserId={selfWriterUserId}
          onRequestResolve={onRequestResolve}
        />
      ) : null}
    </Layout>
  );
};

export default React.memo(Show);
