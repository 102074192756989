import * as React from 'react';
import {
  NativeSyntheticEvent,
  TextInput,
  TextInputSelectionChangeEventData,
} from 'react-native';

import ElasticBoxInput from './ElasticBoxInput';

import {textFramePartImageUrl} from '../../helpers/images';

import TextFrame from '../../../domain/entities/TextFrame';

import FramePartPosition from '../../../domain/value_objects/FramePartPosition';

interface Props {
  textInputRef?: React.RefObject<TextInput>;
  text: string;
  textFrame: TextFrame;
  width: number;
  height: number;
  selection?: {start: number; end?: number};
  hasVoice?: boolean;
  onChangeText: (text: string) => void;
  onSelectionChange?: (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => void;
}

const ElasticTextFrameInput: React.FunctionComponent<Props> = props => {
  const {
    textInputRef,
    text,
    textFrame,
    width,
    height,
    selection,
    hasVoice,
    onChangeText,
    onSelectionChange,
  } = props;
  const top = textFrame.getPartImageUrlOf(FramePartPosition.Top);
  const middle = textFrame.getPartImageUrlOf(FramePartPosition.Middle);
  const bottom = textFrame.getPartImageUrlOf(FramePartPosition.Bottom);
  return (
    <ElasticBoxInput
      textInputRef={textInputRef}
      text={text}
      top={textFramePartImageUrl(top, 'large')}
      middle={textFramePartImageUrl(middle, 'large')}
      bottom={textFramePartImageUrl(bottom, 'large')}
      width={width}
      height={height}
      textStyle={textFrame.textStyle}
      voiceIconStyle={textFrame.voiceIconStyle}
      selection={selection}
      useStyledFrame={textFrame.caption}
      hasVoice={hasVoice}
      onChangeText={onChangeText}
      onSelectionChange={onSelectionChange}
    />
  );
};

export default React.memo(ElasticTextFrameInput);
