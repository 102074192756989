import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import CheckButton from '../../../shared/forms/CheckButton';
import AlertIcon from '../../../shared/icons/AlertIcon';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep4: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <View style={styles.container}>
      <View style={styles.alert}>
        <AlertIcon size={32} color={'#ff8f13'} />
        <Text style={styles.alertText}>注意</Text>
      </View>
      <Text style={styles.message}>
        パートナープログラムで獲得した報酬額を{'\n'}
        公表することは規約違反となります。
      </Text>
      <Text style={styles.warning}>
        違反が発覚した場合は、{'\n'}
        アカウントの削除対象となります
      </Text>
      <CheckButton
        style={styles.checkButton}
        checked={checked}
        onPress={onPressCheck}>
        注意事項を理解した
      </CheckButton>
      <PrimaryButton disabled={!checked} onPress={onPress}>
        パートナーグラムを始める
      </PrimaryButton>
    </View>
  );
};

export default React.memo(ServiceExplanationStep4);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  alert: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
  } as ViewStyle,
  alertText: {
    color: '#ff8f13',
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 4,
  } as TextStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginHorizontal: 24,
    marginBottom: 16,
    textAlign: 'center',
  } as TextStyle,
  warning: {
    color: '#f23406',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: 32,
  } as TextStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginBottom: 16,
  } as ViewStyle,
});
