import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import EpisodeComment from '../../../domain/entities/EpisodeComment';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetEpisodeCommentsRepository from '../../../data/repositories/writer/NetEpisodeCommentsRepository';

const types = {
  failure: Types.IndexEpisodeCommentsFailure,
  request: Types.IndexEpisodeCommentsRequest,
  success: Types.IndexEpisodeCommentsSuccess,
};

export interface Params extends BaseParams {
  episodeId: number;
  reactionIds?: number[];
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<EpisodeComment>> {
  return indexGenerics(new NetEpisodeCommentsRepository(), types, params);
}
