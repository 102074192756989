import * as React from 'react';

import WriterUser from './partials/WriterUser';
import MenuList from './partials/MenuList';

import Layout from '../shared/Layout';
import DimensionContexte from '../shared/dimension/DimensionContext';

import NavigationProp from '../../navigators/NavigationProp';

import MenuItem from '../../view_models/MenuItem';

import scrollableLayout from '../../helpers/scrollableLayout';

import WriterCurrentUser from '../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';

import {RootStackParamList} from '../../navigators/RootStackParamList';

type RouteName = keyof RootStackParamList;

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  currentUser: WriterCurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<WriterCurrentUser>;
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {showCurrentUser, showCurrentUserStatus} = this.props;
    showCurrentUser();
    showCurrentUserStatus();
  }

  public render(): React.ReactNode {
    const {navigation, currentUser} = this.props;

    return (
      <DimensionContexte.Consumer>
        {context => (
          <Layout
            title={'マイメニュー'}
            scrollable={scrollableLayout(context)}
            navigation={navigation}
            close={true}>
            {currentUser && (
              <>
                <WriterUser
                  currentUser={currentUser}
                  onPress={this.handlePress}
                />
                <MenuList
                  scrollEnabled={!scrollableLayout(context)}
                  disabledRouteNames={this.getDisabledRouteNames()}
                  isGradeBlack={currentUser?.isGradeBlack()}
                  isPro={currentUser.enabledPaidSubscriber}
                  onSelectMenuItem={this.handleSelectMenuItem}
                />
              </>
            )}
          </Layout>
        )}
      </DimensionContexte.Consumer>
    );
  }

  private handlePress = () => {
    const {navigation} = this.props;
    navigation.navigate('EditWriterUser', {});
  };

  private handleSelectMenuItem = (menuItem: MenuItem) => {
    const {navigation} = this.props;
    if (menuItem.routeName === 'NewStoryNavigation') {
      navigation.goBack();
    }
    navigation.navigate(menuItem.routeName as any, {});
  };

  private handlePressCommunityButton = () => {
    const {navigation} = this.props;
    navigation.navigate('CommunityNavigation', {});
  };

  private getDisabledRouteNames = (): Array<RouteName> => {
    const {currentUserStatus} = this.props;
    const val: Array<RouteName> = [];
    if (!currentUserStatus) {
      return val;
    }
    if (!currentUserStatus.extensionsCodes.includes('analysis')) {
      val.push('AnalysisStories');
    }
    if (currentUserStatus.responseStoryAggregationsCount === 0) {
      val.push('ResponseStories');
    }
    if (!currentUserStatus.extensionsCodes.includes('commercial_video')) {
      val.push('CommercialVideoExportJobExecutions');
    }
    if (!currentUserStatus.extensionsCodes.includes('partner_program')) {
      val.push('PartnerProgramNavigation');
    }
    return val;
  };
}
