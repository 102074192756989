import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/illustration_show_scene_command/edit/illustrations/Index';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import showIllustrationCategory from '../../../../../actions/illustration_categories/show';
import updateIllustrationUsageHistory, {
  Params as IllustrationUsageHistoryUpdateParams,
} from '../../../../../actions/illustration_usage_histories/update';
import indexIllustrations, {
  Params as IllustrationIndexParams,
} from '../../../../../actions/illustrations/index';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import selectEntities, {
  getTotalCount,
} from '../../../../../helpers/selectEntities';
import selectEntity from '../../../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../../../helpers/ResourceAuthorizattion';

import {SceneFormIllustrationShowSceneCommandEditIllustrationsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormIllustrationShowSceneCommandEditIllustrationsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {
    query,
    illustrationCategoryId,
    parentSceneCommandId,
    sort,
    favorite,
    storyId,
  } = route.params;
  const sceneForm = forms.sceneForm;
  const illustrationsParams: IllustrationIndexParams = {
    query,
    allIllustrationCategoryId: illustrationCategoryId,
    sort: sort || 'popularity',
    favorite,
    storyId,
  };
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  const illustrationCategory = illustrationCategoryId
    ? selectEntity(entities.illustrationCategories, illustrationCategoryId)
    : null;
  const illustrations = selectEntities(
    entities.illustrations,
    queries.illustrations,
    illustrationsParams,
  );
  const totalCount = getTotalCount(queries.illustrations, illustrationsParams);
  const illustrationQueries = queries.illustrations;
  return {
    sceneForm,
    illustrationCategory,
    illustrations,
    illustrationsParams,
    navigation,
    parentSceneCommandForm,
    illustrationQueries,
    totalCount,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexIllustrations: (params: IllustrationIndexParams) =>
      dispatch(indexIllustrations(params)),
    showIllustrationCategory: (id: number) =>
      dispatch(showIllustrationCategory(id)),
    updateIllustrationUsageHistory: (
      id: number,
      params: IllustrationUsageHistoryUpdateParams,
    ) => dispatch(updateIllustrationUsageHistory(id, params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
