import * as React from 'react';
import {InteractionManager, Text} from 'react-native';

import BackgroundFilterList from './partials/BackgroundFilterList';
import SelectedBackgroundFilterImage from './partials/SelectedBackgroundFilterImage';

import HeaderRightButton from '../../../../shared/buttons/HeaderRightButton';
import Layout from '../../../../shared/Layout';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundShowSceneCommandNewBackgroundFiltersRouteProp} from '../../../../../navigators/RouteProps';

import {Params as SceneFormUpdateParams} from '../../../../../actions/scene_forms/update';

import {backgroundImageUrl} from '../../../../../helpers/images';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';
import scrollableLayout from '../../../../../helpers/scrollableLayout';

import Background from '../../../../../../domain/entities/Background';
import SceneForm, {Filter} from '../../../../../../domain/forms/SceneForm';

import fetchBlob from '../../../../../../data/data_stores/net/FetchBlob';

export interface Params {
  episodeId?: number;
  sceneId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBackgroundFiltersRouteProp;
  sceneForm: SceneForm | null;
}

export interface DispatchProps {
  updateSceneForm: (params: SceneFormUpdateParams) => Promise<SceneForm>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  uri: string | null;
  selectedFilter?: Filter;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedFilter:
        props.sceneForm && props.sceneForm.options
          ? props.sceneForm.options.filter
          : undefined,
      uri: null,
    };
  }

  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (this.props.sceneForm && this.props.sceneForm.background) {
      const {background} = this.props.sceneForm;
      InteractionManager.runAfterInteractions(() => {
        this.fetchBlob(background).then(uri => {
          this.setState({uri});
        });
      });
    }
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;

    const {uri, selectedFilter} = this.state;
    const filter = selectedFilter || 'normal';
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={'背景フィルター'}
            scrollable={scrollableLayout(context)}
            navigation={navigation}
            leftButton={{
              tintColor: '#383838',
              title: <Text>キャンセル</Text>,
              handler: () => {
                navigation.goBack();
              },
            }}
            rightButton={{
              tintColor: '#383838',
              title: (
                <HeaderRightButton onPress={this.handleSubmit}>
                  決定
                </HeaderRightButton>
              ),
            }}
            close={true}>
            {uri && (
              <>
                <SelectedBackgroundFilterImage uri={uri} filter={filter} />
                <BackgroundFilterList
                  uri={uri}
                  selectedFilter={filter}
                  onSelectFilter={this.handleSelectFilter}
                />
              </>
            )}
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private handleSelectFilter = (filter: Filter) => {
    this.setState({selectedFilter: filter});
  };

  private handleSubmit = () => {
    const {navigation, sceneForm, updateSceneForm} = this.props;
    const {selectedFilter} = this.state;
    if (!sceneForm) {
      return;
    }
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      id,
      background,
      storyId,
      episodeId,
      positionedEffect,
      effectOptions,
    } = sceneForm;
    const options = {filter: selectedFilter};
    const params = {
      id,
      background,
      storyId,
      episodeId,
      options,
      positionedEffect,
      effectOptions,
    };
    updateSceneForm(params);
    (navigation.getParent() || navigation).goBack();
  };

  private async fetchBlob(background: Background): Promise<string> {
    const url = backgroundImageUrl(background, 'large');
    return fetchBlob(url);
  }
}
