import StoryAggregation from '../../../../domain/value_objects/summary/StoryAggregation';

export interface StoryAggregationData {
  id: number;
  from?: string | null;
  to?: string | null;
  title: string;
  has_cover_image: boolean;
  original_cover_image_url: string;
  story_page_view_count: number;
  story_page_view_user_count: number;
  episode_page_view_count: number;
  episode_page_view_unique_consumer_user_count: number;
  content_fulfillment_rate: number;
  published_episodes_count: number;
  favorited_consumer_users_count: number;
  episode_page_view_rate: number;
  genre_average_episode_page_view_rate: number;
}

export default class StoryAggregationMapper {
  public map(obj: StoryAggregationData): StoryAggregation {
    return new StoryAggregation(
      obj.id,
      obj.from ? new Date(obj.from) : null,
      obj.to ? new Date(obj.to) : null,
      obj.title,
      obj.has_cover_image,
      obj.original_cover_image_url,
      obj.story_page_view_count,
      obj.story_page_view_user_count,
      obj.episode_page_view_count,
      obj.episode_page_view_unique_consumer_user_count,
      obj.content_fulfillment_rate,
      obj.published_episodes_count,
      obj.favorited_consumer_users_count,
      obj.episode_page_view_rate,
      obj.genre_average_episode_page_view_rate,
    );
  }
}
