import {Dispatch} from 'redux';

import Types from '../../Types';

import AppState from '../../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../../generics/index';

import Episode from '../../../../domain/entities/response/Episode';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

import NetEpisodesRepository from '../../../../data/repositories/writer/response/NetEpisodesRepository';

const types = {
  failure: Types.IndexResponseEpisodesFailure,
  request: Types.IndexResponseEpisodesRequest,
  success: Types.IndexResponseEpisodesSuccess,
};

export interface Params extends BaseParams {
  storyId: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<Episode>> {
  return indexGenerics(new NetEpisodesRepository(), types, params);
}
