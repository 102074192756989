import TapNovelRestApi from '../../../data/data_stores/net/TapNovelRestApi';

export default class GoogleOauth {
  public authorize = async (callback: () => void) => {
    TapNovelRestApi.get(
      '/api/writer/social_accounts/google_accounts/oauth_account',
    )
      .then(callback)
      .catch(() => {
        const listener = async (event: MessageEvent) => {
          if (event.data && event.data.type !== 'google_oauth') {
            return;
          }
          TapNovelRestApi.post(
            '/api/writer/social_accounts/google_accounts/oauth_account',
            {
              code: event.data.params.code,
              redirect_uri: this.getRedirectUri(),
            },
          ).then(callback);
          window.removeEventListener('message', listener);
        };
        window.addEventListener('message', listener, false);
        window.open(
          `${
            process.env.REACT_APP_TAPNOVEL_API_ENDPOINT
          }/api/writer/social_accounts/google_accounts/oauth_account/new?redirect_uri=${this.getRedirectUri()}`,
          undefined,
          `width=500,height=700,toolbar=no,menubar=no,left=${
            window.innerWidth / 2 - 250
          },top=${window.innerHeight / 2 - 300}`,
        );
      });
  };

  private getRedirectUri = () => {
    return process.env.REACT_APP_GOOGLE_WRITER_AUTH_REDIRECT_URI as string;
  };
}
