import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../components/characters/Show';

import AppState from '../../reducers/AppState';

import showCharacter from '../../actions/characters/show';

import selectEntity from '../../helpers/selectEntity';

import {CharacterRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CharacterRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, writerCurrentUserId} = state;
  const {characterId} = route.params;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const character = selectEntity(entities.characters, Number(characterId));
  return {
    navigation,
    character,
    currentUser,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCharacter: (id: number) => dispatch(showCharacter(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
