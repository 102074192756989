import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import CharacterPatternMapper, {
  CharacterPatternData,
} from '../../entities/mappers/CharacterPatternMapper';

import CharacterPattern from '../../../domain/entities/CharacterPattern';
import CharacterPatternUsageHistoriesRepository from '../../../domain/repositories/writer/CharacterPatternUsageHistoriesRepository';

export default class NetCharacterPatternUsageHistoriesRepository
  extends NetResourcesRepository<CharacterPattern, CharacterPatternData>
  implements CharacterPatternUsageHistoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_patterns',
        new CharacterPatternMapper(),
        'usage_history',
      ),
    );
  }
}
