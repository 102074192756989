import * as React from 'react';
import {StyleSheet, Text, TextStyle, ViewStyle} from 'react-native';

import Checkbox from './Checkbox';

import LinkButton from '../../../shared/buttons/LinkButton';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import CurrentUserStatus from '../../../../../domain/entities/writer/CurrentUserStatus';

interface Props {
  currentUserStatus: CurrentUserStatus;
  onPressSubmit: () => void;
  onPressCancel: () => void;
}

const FormStep3: React.FunctionComponent<Props> = props => {
  const {currentUserStatus, onPressSubmit, onPressCancel} = props;
  return (
    <>
      {currentUserStatus.enabledConsumerUser ? (
        <>
          <Text style={styles.defaultMessage}>
            TapNovelMaker（執筆サービス）と{'\n'}
            TapNovel（読書サービス）の{'\n'}
            両方を退会します。
          </Text>
          <Text style={styles.defaultMessage}>
            一度退会すると投稿した作品及びアカウント情報を{'\n'}
            復元することはできません。。
          </Text>
        </>
      ) : (
        <>
          <Text style={styles.defaultMessage}>TapNovelMakerを退会します。</Text>
          <Text style={styles.defaultMessage}>
            一度退会すると投稿した作品は{'\n'}
            復元することはできません。
          </Text>
        </>
      )}
      <Text style={styles.defaultMessage}>本当によろしいですか？</Text>
      <PrimaryButton onPress={onPressSubmit}>退会する</PrimaryButton>
      <LinkButton style={{marginTop: 16}} onPress={onPressCancel}>
        キャンセル
      </LinkButton>
    </>
  );
};

export default React.memo(FormStep3);

const styles = StyleSheet.create({
  defaultMessage: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 16,
  } as TextStyle,
});
