import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import CheckBoxList from '../../../forms/CheckBoxList';

import ActorCategory from '../../../../../../domain/entities/ActorCategory';

interface Props {
  rootActorCategory: ActorCategory;
  actorCategories: Array<ActorCategory>;
  selectedActorCategoryIds?: Array<number>;
  hideTitle?: boolean;
  onChangeValues: (parentId: number, ids: Array<number>) => void;
}

const CategoryCheckBoxList: React.FunctionComponent<Props> = props => {
  const {
    rootActorCategory,
    actorCategories,
    selectedActorCategoryIds,
    hideTitle,
    onChangeValues,
  } = props;
  const items = React.useMemo(() => {
    return actorCategories.map(actorCategory => {
      return {
        label: actorCategory.name,
        value: actorCategory.id,
      };
    });
  }, [rootActorCategory]);
  const handleChangeValues = React.useCallback((values: number[]) => {
    onChangeValues(rootActorCategory.id, values);
  }, []);
  return (
    <View style={styles.container}>
      {!hideTitle && (
        <Text style={styles.title}>{rootActorCategory.getShortName()}</Text>
      )}
      <CheckBoxList
        items={items}
        values={selectedActorCategoryIds}
        onChangeValues={handleChangeValues}
      />
    </View>
  );
};

export default React.memo(CategoryCheckBoxList);

const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
  } as ViewStyle,
  title: {
    marginHorizontal: 16,
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
});
