import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import ResourcesRepository from '../../../domain/repositories/ResourcesRepository';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export interface ActionTypes {
  request: Types;
  success: Types;
  failure: Types;
}

export interface Params {
  page?: number;
  perPage?: number;
}

export default function action<T, P extends Params>(
  repository: ResourcesRepository<T>,
  types: ActionTypes,
  params: P,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<T>> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<PaginatedResult<T>> => {
    try {
      const payload = await repository.findBy(params);
      dispatch({payload, type: types.success});
      return payload;
    } catch (err) {
      dispatch({type: types.failure});
      throw err;
    }
  };
}
