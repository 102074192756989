import Entity from '../Entity';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class ActorEye implements Entity {
  constructor(
    public id: number,
    public actorId: number,
    public facePatternId: number,
    public facePatternName: string,
    public eyeColorPatternId: number,
    public eyeColorPatternName: string,
    public originalImageUrl: string,
  ) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getHalfImageUrl(): string {
    return ImageUrlHelper.getHalfImageUrl(this.originalImageUrl);
  }

  public getOneThirdImageUrl(): string {
    return ImageUrlHelper.getOneThirdImageUrl(this.originalImageUrl);
  }

  public getLabel(): string {
    return this.eyeColorPatternName;
  }
}
