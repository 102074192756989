import * as React from 'react';
import {
  Image,
  ImageStyle,
  Linking,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import DimensionContext from '../../../shared/dimension/DimensionContext';
import PlayIcon from '../../../shared/icons/PlayIcon';
import AlertModal from '../../../shared/modals/AlertModal';
import TooltipModal from '../../../shared/modals/TooltipModal';
import VideoPlayer from '../../../shared/VideoPlayer';
import UserStatusContext from '../../../shared/user_status/UserStatusContext';

import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';
import convertImageSource from '../../../../helpers/convertImageSource';

import {colors} from '../../../../styles/variables';
import {storyCoverImageUrl} from '../../../../helpers/images';
import {formatErrorMessages} from '../../../../helpers/errorMessages';
import {enabledCommercialVideoDownload} from '../../../../helpers/features';

import EpisodeVideoExportJobExecution from '../../../../../domain/entities/writer/EpisodeVideoExportJobExecution';

import TikTokOauth from '../../../../../domain/services/social_logins/TikTokOauth';
import GoogleOauth from '../../../../../domain/services/social_logins/GoogleOauth';

import tiktokIconUri from '../../../../../assets/images/icons/tiktok.png';
import youtubeIconUri from '../../../../../assets/images/icons/youtube.png';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

interface Props {
  episodeVideoExportJobExecution: EpisodeVideoExportJobExecution;
  notification?: boolean;
}

const EpisodeVideoExportJobExecutionList: React.FunctionComponent<
  Props
> = props => {
  const {episodeVideoExportJobExecution, notification} = props;
  const [successMessage, setSuccessMessage] = React.useState<{
    title: string;
    description?: string;
    button?: {
      uri: string;
      text: string;
    };
  } | null>(null);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const closeSuccessModal = React.useCallback(() => {
    setSuccessMessage(null);
  }, []);
  const closeErrorModal = React.useCallback(() => {
    setErrorMessage(null);
  }, []);
  const source = React.useMemo(() => {
    return {
      uri: storyCoverImageUrl(
        new StoryCoverImageDecorator(
          episodeVideoExportJobExecution.episode.story,
        ),
        'large',
      ),
      headers: {Accept: 'image/webp,image/apng,*/*'},
    };
  }, []);
  const [playing, setPlaying] = React.useState(false);
  const onPress = React.useCallback(() => {
    setPlaying(!playing);
  }, [playing]);
  const onEnded = React.useCallback(() => {
    setPlaying(false);
  }, []);
  React.useEffect(() => {
    new TikTokOauth().getOauthAccount();
  }, []);
  const onPressTiktok = React.useCallback(() => {
    new TikTokOauth().authorize(() => {
      if (episodeVideoExportJobExecution.episodeVideo) {
        TapNovelRestApi.post(
          '/api/writer/social_accounts/tiktok_accounts/videos',
          {episode_video_id: episodeVideoExportJobExecution.episodeVideo.id},
        )
          .then(() => {
            setSuccessMessage({
              title: 'TikTokへアップロードしました。',
              description:
                'TikTok内の「メッセージ」＞「システム通知」より作成したCM動画を投稿できます。\n※タイムラグが発生する場合がありますので、通知が来ていない場合は時間を置いて確認ください。',
              button: {
                uri: 'https://www.tiktok.com/',
                text: 'TikTokへ移動する',
              },
            });
          })
          .catch(e => {
            setErrorMessage(formatErrorMessages({}, e));
          });
      }
    });
  }, []);
  const onPressYoutube = React.useCallback(() => {
    /*
    new GoogleOauth().authorize(() => {
      if (episodeVideoExportJobExecution.episodeVideo) {
        Linking.openURL(
          episodeVideoExportJobExecution.episodeVideo.videoDownloadUrl
        );
        TapNovelRestApi.post(
          "/api/writer/social_accounts/google_accounts/youtube/videos",
          { episode_video_id: episodeVideoExportJobExecution.episodeVideo.id }
        )
          .then(() => {
            setSuccessMessage({title: 'Googleへアップロードしました。'});
          })
          .catch((e) => {
            setErrorMessage(formatErrorMessages({}, e));
          });
      }
    });
    */
    if (episodeVideoExportJobExecution.episodeVideo) {
      Linking.openURL(
        episodeVideoExportJobExecution.episodeVideo.videoDownloadUrl,
      );
    }
  }, []);
  const onPressInstagram = React.useCallback(() => {}, []);
  return (
    <DimensionContext.Consumer>
      {context => {
        const width = context.content.width * 0.62;
        const size = {width, height: width / ASPECT_RATIO};
        return (
          <View style={styles.container}>
            {notification ? (
              <Text style={styles.title}>CM動画が完成しました！</Text>
            ) : null}
            <Pressable style={styles.imageWrapper} onPress={onPress}>
              {episodeVideoExportJobExecution.episodeVideo && playing ? (
                <VideoPlayer
                  style={size}
                  src={
                    episodeVideoExportJobExecution.episodeVideo.videoInlineUrl
                  }
                  onEnded={onEnded}
                />
              ) : (
                <>
                  {episodeVideoExportJobExecution.episode.story
                    .hasCoverImage ? (
                    <FastImageAdapter
                      style={[styles.image, size]}
                      source={source}
                    />
                  ) : (
                    <View style={[styles.image, size]}>
                      <Text style={styles.noImageText}>表紙なし</Text>
                    </View>
                  )}
                  <View style={styles.playIcon}>
                    <PlayIcon size={50} color={'white'} />
                  </View>
                </>
              )}
            </Pressable>
            <Text style={styles.message}>このCM動画をシェアしよう！</Text>
            <View style={styles.buttons}>
              {/**
              <View style={styles.buttonWrapper}>
                <Text style={styles.buttonMessage}>
                  フォロワーが少ない方はTikTokがオススメ
                </Text>
                <Pressable style={styles.buttonTikTok} onPress={onPressTiktok}>
                  <View style={styles.buttonInner}>
                    <Image
                      source={convertImageSource(tiktokIconUri)}
                      style={styles.icon}
                    />
                    <Text style={styles.buttonText}>TikTokにシェア</Text>
                  </View>
                </Pressable>
              </View>
               */}
              <View style={styles.buttonWrapper}>
                <Text style={styles.buttonMessage}>
                  ご自身でお好きなSNSにアップロートください
                </Text>
                <Pressable
                  style={styles.buttonYouTube}
                  onPress={onPressYoutube}>
                  <Text style={styles.buttonText}>
                    このCM動画をダウンロードする
                  </Text>
                </Pressable>
              </View>
            </View>
            {successMessage && (
              <TooltipModal
                visible={true}
                title={successMessage.title}
                description={successMessage.description}
                buttonText={successMessage.button?.text}
                onCloseModal={() => {
                  if (successMessage.button) {
                    Linking.openURL(successMessage.button.uri);
                  }
                  closeSuccessModal();
                }}
              />
            )}
            {errorMessage && (
              <AlertModal visible={true} onCloseModal={closeErrorModal}>
                {errorMessage}
              </AlertModal>
            )}
          </View>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(EpisodeVideoExportJobExecutionList);

const ASPECT_RATIO = 0.56;

const button = {
  borderRadius: 24,
  width: 311,
  height: 46,
  justifyContent: 'center',
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 8,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 16,
    fontWeight: 'bold',
    marginTop: 24,
    marginBottom: 8,
  } as TextStyle,
  imageWrapper: {
    marginTop: 24,
    alignItems: 'center',
    marginBottom: 32,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
  } as ImageStyle,
  playIcon: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  message: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  buttons: {
    marginTop: 8,
  } as ViewStyle,
  buttonWrapper: {
    marginVertical: 8,
  } as ViewStyle,
  buttonMessage: {
    textAlign: 'center',
    color: '#222',
    fontSize: 11,
    marginBottom: 8,
  } as TextStyle,
  buttonTikTok: {
    ...button,
    backgroundColor: '#000',
  } as ViewStyle,
  buttonYouTube: {
    ...button,
    alignItems: 'center',
    backgroundColor: '#ff8f13',
  } as ViewStyle,
  buttonInner: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  buttonText: {
    color: '#fff',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  icon: {
    marginRight: 8,
    width: 30,
    height: 30,
  } as ImageStyle,
});
