import * as React from 'react';
import {StyleSheet, TouchableOpacity, View, ViewStyle} from 'react-native';
import Collapsible from 'react-native-collapsible';

import AnnouncementCategoryList from './AnnouncementCategoryList';

import shouldUpdateEntity from '../../shared/enhanced/shouldUpdateEntity';
import shouldUpdateEntityList from '../../shared/enhanced/shouldUpdateEntityList';

import AnnouncementCategory from '../../../../domain/entities/writer/AnnouncementCategory';

interface Props {
  announcementCategories: AnnouncementCategory[];
  selectedAnnouncementCategory: AnnouncementCategory;
  onSelectAnnouncementCategory: (
    announcementCategory: AnnouncementCategory,
  ) => void;
  onCloseModal: () => void;
  children?: React.ReactNode;
}

const AnnouncementCategorySelectModal: React.FunctionComponent<
  Props
> = props => {
  const {
    announcementCategories,
    selectedAnnouncementCategory,
    onSelectAnnouncementCategory,
    onCloseModal,
  } = props;
  const [collapsed, setCollapsed] = React.useState(true);
  // eslint-disable-next-line
  React.useEffect(() => {
    setCollapsed(false);
    return () => {
      setCollapsed(true);
    };
  });
  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={styles.overlay}
        disabled={false}
        onPress={onCloseModal}>
        <Collapsible collapsed={collapsed}>
          <AnnouncementCategoryList
            announcementCategories={announcementCategories}
            selectedAnnouncementCategory={selectedAnnouncementCategory}
            onSelectAnnouncementCategory={onSelectAnnouncementCategory}
          />
        </Collapsible>
      </TouchableOpacity>
    </View>
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return (
    !shouldUpdateEntity(
      {entity: prevProps.selectedAnnouncementCategory},
      {entity: nextProps.selectedAnnouncementCategory},
    ) &&
    !shouldUpdateEntityList(
      {entities: prevProps.announcementCategories},
      {entities: nextProps.announcementCategories},
    )
  );
};

export default React.memo(AnnouncementCategorySelectModal, areEqual);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'rgba(0, 0, 0, .6)',
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
    top: 56,
  } as ViewStyle,
  overlay: {
    flex: 1,
  } as ViewStyle,
});
