import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import NewCharacterPatternButton from './NewCharacterPatternButton';

interface Props {
  onPress: () => void;
}

const BottomNewCharacterPatternButton: React.FunctionComponent<
  Props
> = props => {
  const {onPress} = props;
  return (
    <View style={styles.container}>
      <NewCharacterPatternButton onPress={onPress} />
    </View>
  );
};

export default React.memo(BottomNewCharacterPatternButton);

const styles = StyleSheet.create({
  container: {
    marginBottom: 8,
  } as ViewStyle,
});
