import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';

import ListItemNormalSeparator from '../../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectEpisode from '../../../../../../domain/entities/ProjectEpisode';
import ProjectIteration from '../../../../../../domain/entities/ProjectIteration';

interface Props {
  episode: ProjectEpisode;
  iteration: ProjectIteration | null;
  hasDraftManuscripts?: boolean;
}

const ManuscriptListFooter: React.FC<Props> = props => {
  const {episode, iteration, hasDraftManuscripts} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo(
      `/projects/${episode.projectId}/user/manuscripts/new?projectEpisodeId=${episode.id}`,
    );
  }, []);
  const onPressNop = React.useCallback(() => {}, []);
  return (
    <>
      <ListItemNormalSeparator />
      <View style={styles.container}>
        {iteration &&
        (episode.latestProjectManuscript.status === 'draft' ||
          episode.latestProjectManuscript.projectIterationId !==
            iteration.id) ? (
          <>
            <PrimaryButton onPress={onPress}>
              このエピソードを提出する
            </PrimaryButton>
            {hasDraftManuscripts ? (
              <Text
                style={{marginVertical: 16, fontSize: 12, fontWeight: 'bold'}}>
                執筆中のエピソードを修正する場合は上記ボタンを{'\n'}
                押してからフェーズでエピソードを選択ください
              </Text>
            ) : null}
          </>
        ) : (
          <PrimaryButton disabled={true} onPress={onPressNop}>
            次のタームまでお待ちください
          </PrimaryButton>
        )}
      </View>
    </>
  );
};

export default React.memo(ManuscriptListFooter);

const styles = StyleSheet.create({
  container: {
    marginTop: 60,
    marginBottom: 16,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
