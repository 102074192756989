import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts from '../../../../containers/scene_form/descriptive_text_show_scene_command/new/descriptive_texts/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts">
      <RootStack.Screen
        name="SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts"
        component={SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTexts}
        options={{title: 'テキスト内容'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
