import EntityMapper from './EntityMapper';

import GenreMapper, {GenreData} from './GenreMapper';
import KeywordMapper, {KeywordData} from './KeywordMapper';

import Story, {Rating, Format} from '../../../domain/entities/Story';

export interface StoryData {
  id: number;
  writer_id: number;
  title: string;
  url: string;
  catch_phrase: string;
  summary: string;
  introduction: string;
  cover_illustrator: string | null;
  published: boolean;
  violated: boolean;
  regulatory_violation_status: 'requesting' | 'applying' | 'rejected' | null;
  serialized: boolean;
  allowed_video_sharing: boolean;
  rating: Rating | null;
  format: Format | null;
  has_image: boolean;
  original_image_url: string;
  has_cover_image: boolean;
  original_cover_image_url: string;
  ogp_image_default_url: string;
  ogp_image_story_publication_url: string;
  ogp_image_episode_publication_url: string;
  ogp_image_episode_reserved_publication_url: string;
  entry_contest_id: number | null;
  updated_at: string;
  created_at: string;
  genres?: GenreData[];
  keywords?: KeywordData[];
  published_episodes_count?: number;
  estimated_total_tap_count?: number | null;
  last_episode_published_title?: string | null;
}

export default class StoryMapper implements EntityMapper<StoryData, Story> {
  private genreMapper: GenreMapper;
  private keywordMapper: KeywordMapper;

  constructor() {
    this.genreMapper = new GenreMapper();
    this.keywordMapper = new KeywordMapper();
  }

  public map(obj: StoryData): Story {
    const genreObjs = obj.genres ? obj.genres : [];
    const genres = genreObjs.map(genreObj => {
      return this.genreMapper.map(genreObj);
    });
    const keywordObjs = obj.keywords ? obj.keywords : [];
    const keywords = keywordObjs.map(keywordObj => {
      return this.keywordMapper.map(keywordObj);
    });
    return new Story(
      obj.id,
      obj.writer_id,
      obj.title,
      obj.url,
      obj.catch_phrase,
      obj.summary,
      obj.introduction,
      obj.cover_illustrator,
      obj.published,
      obj.violated,
      obj.regulatory_violation_status,
      obj.serialized,
      obj.allowed_video_sharing,
      obj.rating,
      obj.format,
      obj.has_image,
      obj.original_image_url,
      obj.has_cover_image,
      obj.original_cover_image_url,
      obj.ogp_image_default_url,
      obj.ogp_image_story_publication_url,
      obj.ogp_image_episode_publication_url,
      obj.ogp_image_episode_reserved_publication_url,
      obj.entry_contest_id,
      new Date(obj.updated_at),
      new Date(obj.created_at),
      genres,
      keywords,
      obj.estimated_total_tap_count,
      obj.last_episode_published_title,
    );
  }
}
