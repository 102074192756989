import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import StorageAccessTokenRepository from '../../../data/repositories/StorageAccessTokenRepository';

const types = {
  failure: Types.ShowAccessTokenFailure,
  request: Types.ShowAccessTokenRequest,
  success: Types.ShowAccessTokenSuccess,
};

export default function action(): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<string | null> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<string | null> => {
    try {
      const payload = await new StorageAccessTokenRepository().find();
      dispatch({payload, type: types.success});
      return payload;
    } catch (err) {
      dispatch({type: types.failure});
      throw err;
    }
  };
}
