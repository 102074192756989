import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import TextFrame from '../../../domain/entities/TextFrame';

import NetTextFramesRepository from '../../../data/repositories/NetTextFramesRepository';

const types = {
  failure: Types.ShowTextFrameFailure,
  request: Types.ShowTextFrameRequest,
  success: Types.ShowTextFrameSuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<TextFrame> {
  return showGeneric(new NetTextFramesRepository(), types, id);
}
