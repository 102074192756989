import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/story_form/keywords/Index';

import AppState from '../../../reducers/AppState';

import updateStoryForm, {
  Params as StoryFormUpdateParams,
} from '../../../actions/story_forms/update';
import indexGenres, {
  Params as GenreIndexParams,
} from '../../../actions/genres/index';
import showForbiddenWordValidationResult from '../../../actions/forbidden_word_validation_results/show';

import selectEntity from '../../../helpers/selectEntity';
import selectEntities from '../../../helpers/selectEntities';

import {StoryFormKeywordsRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: StoryFormKeywordsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries} = state;
  const {storyId} = route.params ? route.params : {storyId: undefined};
  const genresParams = {};
  const genres = selectEntities(entities.genres, queries.genres, genresParams);
  const story = storyId ? selectEntity(entities.stories, storyId) : null;
  return {
    navigation,
    genres,
    genresParams,
    story,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    updateStoryForm: (params: StoryFormUpdateParams) =>
      dispatch(updateStoryForm(params)),
    indexGenres: (params: GenreIndexParams) => dispatch(indexGenres(params)),
    showForbiddenWordValidationResult: (id: string) =>
      dispatch(showForbiddenWordValidationResult(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
