import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import NewWriterUserPassword from '../../../../containers/writer/user_passwords/New';
import EditWriterUserPassword from '../../../../containers/writer/user_passwords/Edit';
import WriterUserPasswordCompletion from '../../../../containers/writer/user_password_completion/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="NewWriterUserPassword">
      <RootStack.Screen
        name="NewWriterUserPassword"
        component={NewWriterUserPassword}
        options={{title: 'パスワード再設定'}}
      />

      <RootStack.Screen
        name="EditWriterUserPassword"
        component={EditWriterUserPassword}
        options={{title: 'パスワード再設定'}}
      />

      <RootStack.Screen
        name="WriterUserPasswordCompletion"
        component={WriterUserPasswordCompletion}
        options={{title: 'パスワード再設定メール送信'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
