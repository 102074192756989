import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import ActorEyeMapper, {
  ActorEyeData,
} from '../../../entities/mappers/character_maker/ActorEyeMapper';

import PaginatedResult from '../../../../domain/results/PaginatedResult';
import ActorEye from '../../../../domain/entities/character_maker/ActorEye';
import ActorEyesRepository from '../../../../domain/repositories/writer/character_maker/ActorEyesRepository';

export default class NetActorEyesRepository
  extends NetResourcesRepository<ActorEye, ActorEyeData>
  implements ActorEyesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_maker/actor_eyes',
        new ActorEyeMapper(),
      ),
    );
  }

  public async findBy(params: {
    actorId: number;
    facePatternId?: number;
    eyeColorPatternId?: number;
  }): Promise<PaginatedResult<ActorEye>> {
    return super.findBy(params);
  }
}
