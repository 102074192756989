import React from 'react';

import RootStack from '../RootStack';

import Character from '../../containers/characters/Show';
import EditCharacter from '../../containers/characters/Edit';
import NewCharacter from '../../containers/characters/New';
import Episode from '../../containers/episodes/Show';

import PoliciesPrivacy from '../../containers/policies/privacy/Index';
import PoliciesTerms from '../../containers/policies/terms/Index';
import PoliciesSettlementLaw from '../../containers/policies/settlement_law/Index';
import PoliciesSpecifiedCommercialTransactionLaw from '../../containers/policies/specified_commercial_transaction_law/Index';
import EditScene from '../../containers/scenes/Edit';
import NewScene from '../../containers/scenes/New';
import Story from '../../containers/stories/Show';

import Menu from '../../containers/menu/Index';
import Announcements from '../../containers/announcements/Index';
import Announcement from '../../containers/announcements/Show';
import Extensions from '../../containers/extensions/Index';

import AnalysisStories from '../../containers/analysis/stories/Index';
import AnalysisStory from '../../containers/analysis/stories/Show';
import AnalysisEpisode from '../../containers/analysis/episodes/Show';

import ResponseStories from '../../containers/response/stories/Index';
import ResponseStory from '../../containers/response/stories/Show';
import ResponseEpisode from '../../containers/response/episodes/Show';

import CommercialVideoExportJobExecutions from '../../containers/commercial_video/export_job_executions/Index';
import CommercialVideoExportJobExecution from '../../containers/commercial_video/export_job_executions/Show';

import CommercialVideoStories from '../../containers/commercial_video/stories/Index';
import CommercialVideoStory from '../../containers/commercial_video/stories/Show';

import ShopNavigation from '../shop/navigations/Index';
import ShopActorSearchFormNavigation from '../shop/navigations/actor_search_form/Index';
import ProjectsNavigation from '../projects/navigations/Index';
import WritersGateNavigation from '../writers_gate/navigations/Index';

const MemberOnlyScreens = (
  <>
    <RootStack.Screen
      name="Story"
      component={Story}
      options={{title: 'エピソード一覧'}}
    />

    <RootStack.Screen
      name="Episode"
      component={Episode}
      options={{title: 'シーン一覧'}}
    />

    <RootStack.Screen
      name="Character"
      component={Character}
      options={{title: 'キャラクター情報', gestureEnabled: false}}
    />
    <RootStack.Screen
      name="NewCharacter"
      component={NewCharacter}
      options={{title: 'キャラ作成', gestureEnabled: false}}
    />

    <RootStack.Screen
      name="EditCharacter"
      component={EditCharacter}
      options={{title: 'キャラ編集', gestureEnabled: false}}
    />

    <RootStack.Screen
      name="NewScene"
      component={NewScene}
      options={{title: 'シーンの作成', gestureEnabled: false}}
    />

    <RootStack.Screen
      name="EditScene"
      component={EditScene}
      options={{title: 'シーンの編集', gestureEnabled: false}}
    />

    <RootStack.Screen
      name="PoliciesPrivacy"
      component={PoliciesPrivacy}
      options={{title: 'プライバシーポリシー'}}
    />

    <RootStack.Screen
      name="PoliciesTerms"
      component={PoliciesTerms}
      options={{title: '利用規約'}}
    />

    <RootStack.Screen
      name="PoliciesSettlementLaw"
      component={PoliciesSettlementLaw}
      options={{title: '資金決済法に基づく表示'}}
    />

    <RootStack.Screen
      name="PoliciesSpecifiedCommercialTransactionLaw"
      component={PoliciesSpecifiedCommercialTransactionLaw}
      options={{title: '特定商取引法に基づく表示'}}
    />

    <RootStack.Screen
      name="Menu"
      component={Menu}
      options={{title: 'メニュー', animationEnabled: false}}
    />

    <RootStack.Screen
      name="Announcements"
      component={Announcements}
      options={{title: 'お知らせ'}}
    />

    <RootStack.Screen
      name="Announcement"
      component={Announcement}
      options={{title: 'お知らせ'}}
    />

    <RootStack.Screen
      name="Extensions"
      component={Extensions}
      options={{title: '機能拡張'}}
    />
    <RootStack.Screen
      name="AnalysisStories"
      component={AnalysisStories}
      options={{title: '分析'}}
    />
    <RootStack.Screen
      name="AnalysisStory"
      component={AnalysisStory}
      options={{title: '分析'}}
    />
    <RootStack.Screen
      name="AnalysisEpisode"
      component={AnalysisEpisode}
      options={{title: '分析'}}
    />
    <RootStack.Screen
      name="ResponseStories"
      component={ResponseStories}
      options={{title: 'レスポンス'}}
    />
    <RootStack.Screen
      name="ResponseStory"
      component={ResponseStory}
      options={{title: 'レスポンス'}}
    />
    <RootStack.Screen
      name="ResponseEpisode"
      component={ResponseEpisode}
      options={{title: 'レスポンス'}}
    />
    <RootStack.Screen
      name="CommercialVideoExportJobExecutions"
      component={CommercialVideoExportJobExecutions}
      options={{title: 'CM動画一覧'}}
    />
    <RootStack.Screen
      name="CommercialVideoExportJobExecution"
      component={CommercialVideoExportJobExecution}
      options={{title: 'CM動画'}}
    />
    <RootStack.Screen
      name="CommercialVideoStories"
      component={CommercialVideoStories}
      options={{title: 'CM動画を作成するストーリーを選択'}}
    />
    <RootStack.Screen
      name="CommercialVideoStory"
      component={CommercialVideoStory}
      options={{title: 'CM動画の作成'}}
    />
    <RootStack.Screen
      name="ShopNavigation"
      component={ShopNavigation}
      options={{title: 'ショップ'}}
    />
    <RootStack.Screen
      name="ShopActorSearchFormNavigation"
      component={ShopActorSearchFormNavigation}
      options={{title: 'ショップ'}}
    />
    <RootStack.Screen
      name="ProjectsNavigation"
      component={ProjectsNavigation}
      options={{title: 'プロジェクト'}}
    />
    <RootStack.Screen
      name="WritersGateNavigation"
      component={WritersGateNavigation}
      options={{title: 'ライターズゲート'}}
    />
  </>
);

export default MemberOnlyScreens;
