import * as React from 'react';
import {ActivityIndicator, StyleSheet, View, ViewStyle} from 'react-native';
import Form, {FormValues} from './partials/form/Form';

import AlertModal from '../../../shared/modals/AlertModal';

import NavigationProp from '../../../../navigators/NavigationProp';
import {NewProjectUserManuscriptRouteProp} from '../../../../navigators/RouteProps';

import {Params as ApplicationModalUpdateParams} from '../../../../actions/application_modal/update';

import {formatErrorMessages} from '../../../../helpers/errorMessages';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';
import ProjectManuscript from '../../../../../domain/entities/ProjectManuscript';

export interface Params {
  projectId: number;
  projectEpisodeId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewProjectUserManuscriptRouteProp;
}

export interface DispatchProps {
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, route, updateApplicationModal} = props;
  const {projectId, projectEpisodeId} = route.params;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const submitting = React.useRef(false);
  const onSubmit = React.useCallback((formValues: FormValues) => {
    if (submitting.current) {
      return;
    }
    setLoading(true);
    submitting.current = true;
    TapNovelTypedRestApi.post<ProjectManuscript>(
      '/api/writer/user/project_manuscripts',
      {
        projectManuscript: formValues,
        projectId,
        projectEpisodeId,
      },
      {multipart: true},
    )
      .then(manuscript => {
        setTimeout(() => {
          setLoading(false);
        }, 300);
        if (manuscript.body.episodeId) {
          navigation.goBack();
          if (manuscript.body.manuscriptNumber > 1) {
            navigation.push('Episode', {
              episodeId: manuscript.body.episodeId,
            });
          } else {
            navigation.push('NewScene', {
              episodeId: manuscript.body.episodeId,
            });
          }
        } else {
          navigation.navigate('Project', {id: projectId});
          const message =
            manuscript.body.typeCode === 'idea'
              ? 'プロジェクトにアイデアを提出しました。'
              : 'プロジェクトにプロットを提出しました。';
          updateApplicationModal({options: {message}});
          submitting.current = false;
        }
      })
      .catch(e => {
        setLoading(false);
        setAlertMessage(formatErrorMessages({}, e));
        submitting.current = false;
      });
  }, []);
  return (
    <>
      <Form
        navigation={navigation}
        projectEpisodeId={projectEpisodeId}
        onSubmit={onSubmit}
      />
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalAlert}>
          {alertMessage}
        </AlertModal>
      )}
      {loading && (
        <View style={styles.loading}>
          <ActivityIndicator size="large" color="#999999" />
        </View>
      )}
    </>
  );
};

export default React.memo(New);

const styles = StyleSheet.create({
  loading: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
});
