import * as React from 'react';
import {FlatList, StyleSheet, ViewStyle} from 'react-native';

import EntityListItem from './EntityListItem';

interface Entity {
  id: number;
  getOneThirdImageUrl: () => string;
  getLabel: () => string;
}

interface Props<T extends Entity> {
  entities: T[] | null;
  width: number;
  visible: boolean;
  selectedEntityId: number | null;
  clipped: boolean;
  inverted?: boolean;
  zoom?: number;
  top?: number;
  centeringByTop?: boolean;
  foregroundImageUris?: string[];
  backgroundImageUris?: string[];
  scrollEnabled?: boolean;
  onSelectEntity: (entity: T) => void;
  extractLabel?: (entity: T) => string;
  extractTopForegroundImageUris?: (entity: T) => string[];
}

type EntityListComponent = <T extends Entity>(
  props: Props<T>,
) => React.ReactElement<Props<T>>;

const EntityList: EntityListComponent = props => {
  const {
    entities,
    width,
    visible,
    selectedEntityId,
    clipped,
    inverted,
    zoom,
    top,
    centeringByTop,
    foregroundImageUris,
    backgroundImageUris,
    scrollEnabled,
    onSelectEntity,
    extractLabel,
    extractTopForegroundImageUris,
  } = props;
  return (
    <FlatList
      style={[styles.container, visible ? null : {display: 'none'}]}
      data={entities}
      scrollEnabled={scrollEnabled}
      renderItem={info => (
        <EntityListItem
          entity={info.item}
          width={
            (width - (MARGIN_HORIZONTAL + CONTENT_MARGIN * NUM_COLUMNS) * 2) /
            NUM_COLUMNS
          }
          selected={selectedEntityId === info.item.id}
          clipped={clipped}
          inverted={inverted}
          zoom={zoom}
          top={top}
          centeringByTop={centeringByTop}
          foregroundImageUris={foregroundImageUris}
          backgroundImageUris={backgroundImageUris}
          onSelectEntity={onSelectEntity}
          extractLabel={extractLabel}
          extractTopForegroundImageUris={extractTopForegroundImageUris}
        />
      )}
      numColumns={3}
      extraData={selectedEntityId}
    />
  );
};

export default React.memo(EntityList) as typeof EntityList;

const MARGIN_HORIZONTAL = 14;
const CONTENT_MARGIN = 2;

const NUM_COLUMNS = 3;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginHorizontal: MARGIN_HORIZONTAL,
  } as ViewStyle,
});
