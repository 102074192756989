import EntityMapper from '../EntityMapper';

import UserNotificationSettingsBatch from '../../../../domain/entities/writer/UserNotificationSettingsBatch';

import NotificationSettingMapper, {
  NotificationSettingData,
} from './NotificationSettingMapper';

interface UserNotificationSettingData {
  id: number | null;
  enabled: boolean | null;
  created_at: Date | null;
  updated_at: Date | null;
  writer_notification_setting: NotificationSettingData;
}

export interface UserNotificationSettingsBatchData {
  id: number;
  writer_user_notification_settings: UserNotificationSettingData[];
}

export default class UserNotificationSettingsBatchMapper
  implements
    EntityMapper<
      UserNotificationSettingsBatchData,
      UserNotificationSettingsBatch
    >
{
  private notificationSettingMapper = new NotificationSettingMapper();

  public map(
    obj: UserNotificationSettingsBatchData,
  ): UserNotificationSettingsBatch {
    const writerUserNotificationSettings =
      obj.writer_user_notification_settings.map(
        writerUserNotificationSettingData => {
          return {
            createdAt: writerUserNotificationSettingData.created_at
              ? new Date(writerUserNotificationSettingData.created_at)
              : null,
            enabled: writerUserNotificationSettingData.enabled,
            id: writerUserNotificationSettingData.id,
            writerNotificationSetting: this.notificationSettingMapper.map(
              writerUserNotificationSettingData.writer_notification_setting,
            ),
            updatedAt: writerUserNotificationSettingData.updated_at
              ? new Date(writerUserNotificationSettingData.updated_at)
              : null,
          };
        },
      );
    return new UserNotificationSettingsBatch(
      obj.id,
      writerUserNotificationSettings,
    );
  }
}
