import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/character_form/new/name_label_colors/Index';

import AppState from '../../../../reducers/AppState';

import createCharacter, {
  Params as CharacterCreateParams,
} from '../../../../actions/characters/create';
import showActor from '../../../../actions/actors/show';
import updateCharacterForm, {
  Params as CharacterFormUpdateParams,
} from '../../../../actions/character_forms/update';
import showSpeechBalloon from '../../../../actions/speech_balloons/show';
import updateApplicationModal, {
  Params as ApplicationModalUpdateParams,
} from '../../../../actions/application_modal/update';

import selectEntity from '../../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, forms} = state;
  const characterForm =
    forms.characterForm && forms.characterForm.id === null
      ? forms.characterForm
      : null;
  const speechBalloonId = 1;
  const speechBalloon = selectEntity(entities.speechBalloons, speechBalloonId);
  return {
    characterForm,
    navigation,
    speechBalloonId,
    speechBalloon,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createCharacter: (params: CharacterCreateParams) =>
      dispatch(createCharacter(params)),
    showActor: (id: number) => dispatch(showActor(id)),
    updateCharacterForm: (params: CharacterFormUpdateParams) =>
      dispatch(updateCharacterForm(params)),
    showSpeechBalloon: (id: number) => dispatch(showSpeechBalloon(id)),
    updateApplicationModal: (params: ApplicationModalUpdateParams) =>
      dispatch(updateApplicationModal(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
