import * as React from 'react';
import {Image, ImageStyle, StyleSheet, View, ViewStyle} from 'react-native';

import NewCharacterPatternButton from './NewCharacterPatternButton';
import NewCharacterButton from './NewCharacterButton';

import convertImageSource from '../../../../../helpers/convertImageSource';

import characterKeyVisualUri from '../../../../../../assets/images/character_key_visual.png';

interface Props {
  onForwardToNewCharacterPattern: () => void;
  onForwardToNewCharacter: () => void;
}

const NoCharacterPatterns: React.FunctionComponent<Props> = props => {
  const {onForwardToNewCharacterPattern, onForwardToNewCharacter} = props;
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        resizeMode={'contain'}
        source={convertImageSource(characterKeyVisualUri)}
      />
      <View style={styles.buttonWrapper}>
        <NewCharacterPatternButton
          style={styles.buttonNewCharacterPattern}
          onPress={onForwardToNewCharacterPattern}
        />
        <NewCharacterButton
          style={styles.buttonNewCharacter}
          onPress={onForwardToNewCharacter}
        />
      </View>
    </View>
  );
};

export default NoCharacterPatterns;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  image: {
    height: 80,
    width: 150,
    margin: 16,
  } as ImageStyle,
  buttonWrapper: {
    margin: 16,
  } as ViewStyle,
  buttonNewCharacterPattern: {
    marginBottom: 8,
    width: 240,
  } as ViewStyle,
  buttonNewCharacter: {
    marginTop: 8,
    width: 240,
  } as ViewStyle,
});
