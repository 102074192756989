import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import StoryInfo from './partials/StoryInfo';
import EpisodeList from '../episodes/partials/EpisodeList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ResponseStoryRouteProp} from '../../../navigators/RouteProps';

import {Params as ResponseStoryUpdateParams} from '../../../actions/response/stories/update';
import {Params as ResponseEpisodeIndexParams} from '../../../actions/response/episodes/index';

import Story from '../../../../domain/entities/response/Story';
import Episode from '../../../../domain/entities/response/Episode';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ResponseStoryRouteProp;
  story: Story | null;
  episodes: Episode[] | null;
  episodesParams: ResponseEpisodeIndexParams;
}

export interface DispatchProps {
  showResponseStory: (id: number) => Promise<Story>;
  updateResponseStory: (
    id: number,
    params: ResponseStoryUpdateParams,
  ) => Promise<Story>;
  indexResponseEpisodes: (
    params: ResponseEpisodeIndexParams,
  ) => Promise<PaginatedResult<Episode>>;
}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    route,
    story,
    episodes,
    episodesParams,
    showResponseStory,
    updateResponseStory,
    indexResponseEpisodes,
  } = props;
  const {storyId} = route.params;
  useFocusEffect(
    React.useCallback(() => {
      showResponseStory(storyId)
        .then(story => {
          if (!story.confirmed) {
            updateResponseStory(storyId, {confirmed: true});
          }
        })
        .catch(e => {
          if (e.status === 401 || e.status === 404) {
            navigation.goBack();
          }
        });
      indexResponseEpisodes(episodesParams);
    }, [storyId]),
  );
  const onSelectEpisode = React.useCallback(
    (episode: Episode) =>
      navigation.navigate('ResponseEpisode', {episodeId: episode.id}),
    [],
  );
  return (
    <Layout
      title={story ? story.title : 'レスポンス'}
      navigation={navigation}
      scrollable={false}
      back={true}>
      {story && episodes && (
        <EpisodeList
          episodes={episodes}
          ListHeaderComponent={<StoryInfo story={story} />}
          onSelectEpisode={onSelectEpisode}
        />
      )}
    </Layout>
  );
};

export default React.memo(Show);
