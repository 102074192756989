import * as React from 'react';

import BaseIndex from '../../../../shared/generics/background_show_scene_command/base_background_search_form/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as BackgroundCategoryIndexParams} from '../../../../../actions/background_categories/index';
import {Params as BaseBackgroundIndexParams} from '../../../../../actions/base_backgrounds/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import BaseBackground from '../../../../../../domain/entities/BaseBackground';
import BackgroundCategory from '../../../../../../domain/entities/BackgroundCategory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  selectedLargeBackgroundCategoryId?: number | null;
  selectedMiddleBackgroundCategoryId?: number | null;
  selectedSmallBackgroundCategoryId?: number | null;
  favorite?: boolean;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormRouteProp;
  sceneForm: SceneForm | null;
  largeBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  largeBackgroundCategories: BackgroundCategory[] | null;
  middleBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  middleBackgroundCategories: BackgroundCategory[] | null;
  smallBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  smallBackgroundCategories: BackgroundCategory[] | null;
}

export interface DispatchProps {
  indexBackgroundCategories: (
    params: BackgroundCategoryIndexParams,
  ) => Promise<PaginatedResult<BackgroundCategory>>;
  indexBaseBackgrounds: (
    params: BaseBackgroundIndexParams,
  ) => Promise<PaginatedResult<BaseBackground>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }

  private handleSubmit = (params: {
    query?: string;
    backgroundCategoryId?: number;
    selectedLargeBackgroundCategoryId?: number | null;
    selectedMiddleBackgroundCategoryId?: number | null;
    selectedSmallBackgroundCategoryId?: number | null;
  }) => {
    const {navigation, route} = this.props;
    const {storyId, episodeId, sceneId, favorite} = route.params;
    const {
      query,
      backgroundCategoryId,
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId,
    } = params;
    routers.linkToSceneFormBackgroundShowSceneCommandNewBaseBackgrounds(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        query,
        backgroundCategoryId,
        selectedSmallBackgroundCategoryId,
        selectedMiddleBackgroundCategoryId,
        selectedLargeBackgroundCategoryId,
        favorite,
      },
    );
  };
}
