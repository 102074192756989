import React from 'react';
import {
  LayoutChangeEvent,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {camelizeKeys} from 'humps';

import Autocomplete from './Autocomplete';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

import MultiSwitch from '../../../shared/forms/MultiSwitch';

import Bank from '../../../../../domain/entities/Bank';
import BankBranch from '../../../../../domain/entities/BankBranch';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

type ItemValue = number | string | boolean | null;

interface Item<IValue extends ItemValue = ItemValue> {
  label: string;
  value: IValue;
  optionLabel?: string;
}

export type AccountType =
  | 'savings_account'
  | 'checking_account'
  | 'certificate_of_deposit';

export type Inputs = {
  bankName: string;
  bankBranchName: string;
  accountType: AccountType;
  accountNumber: string;
  accountHolderName: string;
};

export type Errors = {
  bankName?: string[];
  bankBranchName?: string[];
  accountType?: string[];
  accountNumber?: string[];
  accountHolderName?: string[];
};

interface Props {
  defaultValues?: Inputs;
  errors?: Errors;
  onSubmit: (data: Inputs) => void;
}

const Form: React.FC<Props> = props => {
  const {defaultValues, errors, onSubmit} = props;
  const [bankName, setBankName] = React.useState(defaultValues?.bankName || '');
  const [bankBranchName, setBankBranchName] = React.useState(
    defaultValues?.bankBranchName || '',
  );
  const [accountType, setAccountType] = React.useState<AccountType>(
    defaultValues?.accountType || 'savings_account',
  );
  const [accountNumber, setAccountNumber] = React.useState(
    defaultValues?.accountNumber || '',
  );
  const [accountHolderName, setAccountHolderName] = React.useState(
    defaultValues?.accountHolderName || '',
  );
  const onSelectAccountType = React.useCallback((item: {value: string}) => {
    if (
      item.value === 'savings_account' ||
      item.value === 'checking_account' ||
      item.value === 'certificate_of_deposit'
    ) {
      setAccountType(item.value);
    }
  }, []);

  const [banks, setBanks] = React.useState<Bank[] | null>(null);
  const [bankBranches, setBankBranches] = React.useState<BankBranch[] | null>(
    null,
  );
  const [displayBanksAutocomplete, setDisplayBanksAutocomplete] =
    React.useState(false);
  const [banksAutocompleteStyle, setBanksAutocompleteStyle] =
    React.useState<ViewStyle | null>(null);
  const [displayBankBranchesAutocomplete, setDisplayBankBrancesAutocomplete] =
    React.useState(false);
  const [bankBranchesAutocompleteStyle, setBankBranchesAutocompleteStyle] =
    React.useState<ViewStyle | null>(null);
  const onLayoutBankName = React.useCallback((e: LayoutChangeEvent) => {
    const {x, y, width, height} = e.nativeEvent.layout;
    setBanksAutocompleteStyle({top: y + height - 1, left: x, width});
  }, []);
  const onFocusBankName = React.useCallback(() => {
    setDisplayBanksAutocomplete(true);
  }, []);
  const onBlurBankName = React.useCallback(() => {
    setTimeout(() => {
      setDisplayBanksAutocomplete(false);
    }, 120);
  }, []);
  const onLayoutBankBranchName = React.useCallback((e: LayoutChangeEvent) => {
    const {x, y, width, height} = e.nativeEvent.layout;
    setBankBranchesAutocompleteStyle({top: y + height - 1, left: x, width});
  }, []);
  const onFocusBankBranchName = React.useCallback(
    () => setDisplayBankBrancesAutocomplete(true),
    [],
  );
  const onBlurBankBranchName = React.useCallback(
    () => setTimeout(() => setDisplayBankBrancesAutocomplete(false), 120),
    [],
  );
  const bank = banks?.find(item => item.name === bankName);
  const bankBranch = bankBranches?.find(item => item.name === bankBranchName);
  const disabled =
    !bank ||
    !bankBranch ||
    !accountNumber.match(/\d{7}/) ||
    accountHolderName.length === 0;
  React.useEffect(() => {
    TapNovelRestApi.get('/api/writer/banks', {term: bankName}).then(
      response => {
        setBanks(camelizeKeys(response.body as any) as Array<Bank>);
      },
    );
  }, [bankName]);
  React.useEffect(() => {
    TapNovelRestApi.get('/api/writer/bank_branches', {
      bankId: bank?.id,
      term: bankBranchName,
      limit: 1000,
    }).then(response => {
      setBankBranches(camelizeKeys(response.body as any) as Array<BankBranch>);
    });
  }, [bank, bankBranchName]);
  const onPress = React.useCallback(() => {
    onSubmit({
      bankName,
      bankBranchName,
      accountType,
      accountNumber,
      accountHolderName,
    });
  }, [bankName, bankBranchName, accountType, accountNumber, accountHolderName]);
  return (
    <View style={styles.container}>
      <View style={styles.field} onLayout={onLayoutBankName}>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>金融機関</Text>
          {errors?.bankName ? (
            <Text style={styles.error}>{errors.bankName}</Text>
          ) : null}
        </View>
        <TextInput
          style={styles.input}
          placeholder={'金融機関を入力'}
          placeholderTextColor={'#999'}
          value={bankName}
          onChangeText={setBankName}
          onFocus={onFocusBankName}
          onBlur={onBlurBankName}
        />
      </View>
      <View style={styles.field} onLayout={onLayoutBankBranchName}>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>支店名</Text>
          {errors?.bankBranchName ? (
            <Text style={styles.error}>{errors.bankBranchName}</Text>
          ) : null}
        </View>
        <TextInput
          style={styles.input}
          placeholder={'支店名を入力'}
          placeholderTextColor={'#999'}
          value={bankBranchName}
          editable={!!bank}
          onChangeText={setBankBranchName}
          onFocus={onFocusBankBranchName}
          onBlur={onBlurBankBranchName}
        />
      </View>
      <View style={styles.field}>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>口座種別</Text>
          {errors?.accountType ? (
            <Text style={styles.error}>{errors.accountType}</Text>
          ) : null}
        </View>
        <MultiSwitch
          items={ACCOUNT_TYPE_ITEMS}
          value={accountType}
          onSelectItem={onSelectAccountType}
        />
      </View>
      <View style={styles.field}>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>口座番号</Text>
          {errors?.accountNumber ? (
            <Text style={styles.error}>{errors.accountNumber}</Text>
          ) : null}
        </View>
        <TextInput
          style={styles.input}
          placeholder={'口座番号を入力'}
          placeholderTextColor={'#999'}
          value={accountNumber}
          onChangeText={setAccountNumber}
        />
      </View>
      <View style={styles.field}>
        <View style={styles.labelWrapper}>
          <Text style={styles.label}>口座名義</Text>
          {errors?.accountHolderName ? (
            <Text style={styles.error}>{errors.accountHolderName}</Text>
          ) : null}
        </View>
        <TextInput
          style={styles.input}
          placeholder={'口座名義を入力'}
          placeholderTextColor={'#999'}
          value={accountHolderName}
          onChangeText={setAccountHolderName}
        />
      </View>
      <View style={styles.submit}>
        <PrimaryButton disabled={disabled} onPress={onPress}>
          登録
        </PrimaryButton>
      </View>
      {displayBanksAutocomplete && banks ? (
        <Autocomplete
          style={banksAutocompleteStyle}
          data={banks.map(bank => ({label: bank.name, value: bank.name}))}
          onValueChange={value => {
            setBankName(value);
          }}
        />
      ) : null}
      {displayBankBranchesAutocomplete && bankBranches && bank ? (
        <Autocomplete
          style={bankBranchesAutocompleteStyle}
          data={bankBranches.map(bankBranch => ({
            label: bankBranch.name,
            value: bankBranch.name,
          }))}
          onValueChange={value => {
            setBankBranchName(value);
          }}
        />
      ) : null}
    </View>
  );
};

export default React.memo(Form) as typeof Form;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
    paddingTop: 26,
    paddingBottom: 67,
    paddingHorizontal: 16,
  } as ViewStyle,
  field: {
    height: 48,
    //    backgroundColor: 'red',
    marginVertical: 16,
    justifyContent: 'space-between',
  } as ViewStyle,
  labelWrapper: {
    flexDirection: 'row',
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  input: {
    height: 30,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    fontSize: 14,
  } as TextStyle,
  error: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: 10,
    paddingHorizontal: 8,
  } as TextStyle,
  submit: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
});

const ACCOUNT_TYPE_ITEMS: Array<Item<AccountType>> = [
  {
    label: '普通預金',
    value: 'savings_account',
  },
  {
    label: '当座預金',
    value: 'checking_account',
  },
  {
    label: '貯蓄預金',
    value: 'certificate_of_deposit',
  },
];
