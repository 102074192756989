import TapNovelRestApi from '../../data_stores/net/TapNovelRestApi';

import RawMapper, {RawData} from '../../value_objects/mappers/RawMapper';

import RawSceneScriptsRepository from '../../../domain/repositories/writer/RawSceneScriptsRepository';

export default class NetRawSceneScriptsRepository
  implements RawSceneScriptsRepository
{
  private endPoint = '/api/writer/scene_scripts';

  private mapper: RawMapper;

  constructor() {
    this.mapper = new RawMapper();
  }

  public create(params: {[key: string]: any}): Promise<any> {
    const path = this.endPoint;
    return TapNovelRestApi.post<RawData>(path, params).then(obj => {
      return this.mapper.map(obj.body);
    });
  }
}
