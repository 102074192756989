import * as React from 'react';
import {
  ActivityIndicator,
  Image,
  ImageStyle,
  InteractionManager,
  StyleProp,
  View,
} from 'react-native';

import SelectableLabeledImageGridList from '../../../../../shared/SelectableLabeledImageGridList';

import {Filter} from '../../../../../../../domain/forms/SceneForm';

import {GLImageFilter} from '../../../../../../../vendor/react-native-tapnovel-viewer';
import GLCapturedSequentialImages from '../../../../../../../vendor/react-native-tapnovel-viewer/presentation/components/shared/GLCapturedSequentialImages';

const FILTERS: Array<Filter> = [
  'normal',
  'monochrome',
  'sepia',
  'mosaic',
  'dream',
  'red',
  'blue',
  'green',
];

const FILTER_NAME = {
  normal: 'ノーマル',
  monochrome: 'モノクロ',
  sepia: 'セピア',
  mosaic: 'モザイク',
  dream: 'モヤモヤ',
  red: '赤',
  blue: '青',
  green: '緑',
};

interface Item {
  name: string;
  value: Filter;
  active: boolean;
}

interface Props {
  uri: string;
  selectedFilter: Filter | null;
  onSelectFilter: (filter: Filter) => void;
}

interface State {
  readly: boolean;
  keyToBase64: {[key: string]: string} | null;
}

export default class BackgroundFilterList extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      readly: false,
      keyToBase64: null,
    };
  }

  public componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        this.setState({readly: true});
      }, 100);
    });
  }

  public render(): React.ReactNode {
    const {selectedFilter, onSelectFilter} = this.props;
    const {readly, keyToBase64} = this.state;
    const width = 300;
    const height = width / aspectRatio;
    return (
      <>
        <SelectableLabeledImageGridList
          key={`${readly}`}
          entities={FILTERS}
          aspectRatio={aspectRatio}
          selectedEntity={selectedFilter}
          onSelectEntity={onSelectFilter}
          renderLabel={this.renderLabel}
          keyExtractor={this.keyExtractor}
          isActive={this.isActive}
          renderImage={this.renderImage}
          extraData={keyToBase64}
        />
        {readly && (
          <GLCapturedSequentialImages
            size={{height, width}}
            items={this.filterItems()}
            onLoad={keyToBase64 => {
              this.setState({keyToBase64});
            }}
          />
        )}
      </>
    );
  }

  private filterItems = () => {
    const {uri} = this.props;
    return FILTERS.map(filter => {
      return {
        uri,
        key: filter,
        filter,
      };
    });
  };

  private renderLabel = (filter: Filter) => {
    return FILTER_NAME[filter];
  };

  private keyExtractor = (filter: Filter) => {
    return filter;
  };

  private isActive = (filter: Filter, selectedFilter: Filter | null) => {
    return filter === selectedFilter;
  };

  private renderImage = (
    filter: Filter,
    width: number,
    imageStyle: StyleProp<ImageStyle>,
  ) => {
    const {uri} = this.props;
    const {keyToBase64} = this.state;
    const height = width / aspectRatio;
    return (
      <View style={imageStyle}>
        {keyToBase64 ? (
          <Image
            style={{width, height}}
            source={{uri: keyToBase64 ? keyToBase64[filter] || uri : uri}}
            resizeMode={'contain'}
          />
        ) : (
          <ActivityIndicator color={'#999999'} />
        )}
      </View>
    );
  };
}

const aspectRatio = 1.25;
