import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/character_show_scene_command/new/character_positions/Index';

import AppState from '../../../../../reducers/AppState';

import showActorCharacterFace from '../../../../../actions/actor_character_faces/show';
import updateCharacterPatternUsageHistory from '../../../../../actions/character_pattern_usage_histories/update';
import showCharacterPattern from '../../../../../actions/character_patterns/show';
import showMark from '../../../../../actions/marks/show';
import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../../../../actions/scene_command_forms/create';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import selectEntity from '../../../../../helpers/selectEntity';
import {SceneFormCharacterShowSceneCommandNewCharacterPositionsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterPositionsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, forms} = state;
  const {
    characterPatternId,
    actorCharacterFaceId,
    markId,
    parentSceneCommandId,
  } = route.params;
  const sceneForm = forms.sceneForm;
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  const actorCharacterFace = selectEntity(
    entities.actorCharacterFaces,
    actorCharacterFaceId,
  );
  const characterPattern = selectEntity(
    entities.characterPatterns,
    characterPatternId,
  );
  const mark = markId ? selectEntity(entities.marks, markId) : null;
  const sceneCommandForms = forms.sceneCommandForms;
  return {
    sceneForm,
    actorCharacterFace,
    characterPattern,
    mark,
    navigation,
    parentSceneCommandForm,
    sceneCommandForms,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
    showActorCharacterFace: (id: number) =>
      dispatch(showActorCharacterFace(id)),
    showCharacterPattern: (id: number) => dispatch(showCharacterPattern(id)),
    showMark: (id: number) => dispatch(showMark(id)),
    updateCharacterPatternUsageHistory: (id: number) =>
      dispatch(updateCharacterPatternUsageHistory(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
