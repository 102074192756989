import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Edit, {
  DispatchProps,
  StateProps,
} from '../../../components/story_form/detail_info/Edit';

import AppState from '../../../reducers/AppState';

import showStory from '../../../actions/stories/show';
import updateStory, {
  Params as StoryUpdateParams,
} from '../../../actions/stories/update';
import updateStoryForm, {
  Params as StoryFormUpdateParams,
} from '../../../actions/story_forms/update';

import selectEntity from '../../../helpers/selectEntity';

import {EditStoryFormDetailInfoRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EditStoryFormDetailInfoRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, forms} = state;
  const {storyId} = route.params ? route.params : {storyId: undefined};
  const storyForm = forms.storyForm;
  const story = storyId ? selectEntity(entities.stories, storyId) : null;
  return {
    navigation,
    story,
    storyForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showStory: (id: number) => dispatch(showStory(id)),
    updateStory: (id: number, params: StoryUpdateParams) =>
      dispatch(updateStory(id, params)),
    updateStoryForm: (params: StoryFormUpdateParams) =>
      dispatch(updateStoryForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
