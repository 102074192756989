import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import ManuscriptList from './partials/ManuscriptList';
import ManuscriptListHeader from './partials/ManuscriptListHeader';
import ManuscriptListFooter from './partials/ManuscriptListFooter';

import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';
import {ProjectUserEpisodeRouteProp} from '../../../../navigators/RouteProps';

import ProjectEpisode from '../../../../../domain/entities/ProjectEpisode';
import ProjectManuscript from '../../../../../domain/entities/ProjectManuscript';
import ProjectIteration from '../../../../../domain/entities/ProjectIteration';
import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectUserEpisodeRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {projectId, id} = route.params;
  const [episode, setEpisode] = React.useState<ProjectEpisode | null>(null);
  const [iteration, setIteration] = React.useState<
    ProjectIteration | null | undefined
  >(undefined);
  const [manuscripts, setManuscripts] = React.useState<
    ProjectManuscript[] | null
  >(null);
  const [hasDraftManuscripts, setHasDraftManuscripts] =
    React.useState<boolean>(false);
  const fetchEntities = React.useCallback(() => {
    TapNovelTypedRestApi.get<ProjectEpisode>(
      `/api/writer/user/project_episodes/${id}`,
    ).then(result => {
      setEpisode(result.body);
    });
    TapNovelTypedRestApi.get<ProjectManuscript[]>(
      '/api/writer/user/project_manuscripts',
      {
        projectEpisodeId: id,
        status: 'draft',
      },
    ).then(result => {
      setHasDraftManuscripts(result.body.length > 0);
    });
    TapNovelTypedRestApi.get<ProjectManuscript[]>(
      '/api/writer/user/project_manuscripts',
      {
        projectEpisodeId: id,
      },
    ).then(result => {
      setManuscripts(result.body);
    });
    TapNovelTypedRestApi.get<ProjectIteration>(
      '/api/writer/current_project_iteration',
      {projectId},
    )
      .then(result => {
        setIteration(result.body);
      })
      .catch(() => {
        setIteration(null);
      });
  }, []);
  useFocusEffect(fetchEntities);
  return (
    <Layout
      title={'エピソード履歴'}
      navigation={navigation}
      back={true}
      scrollable={false}>
      {episode && manuscripts && iteration !== undefined && (
        <ManuscriptList
          manuscripts={manuscripts}
          ListHeaderComponent={<ManuscriptListHeader episode={episode} />}
          ListFooterComponent={
            <ManuscriptListFooter
              episode={episode}
              iteration={iteration}
              hasDraftManuscripts={hasDraftManuscripts}
            />
          }
        />
      )}
    </Layout>
  );
};

export default React.memo(Index);
