import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ManualCategoryMapper, {
  ManualCategoryData,
} from '../../entities/mappers/writer/ManualCategoryMapper';

import ManualCategory from '../../../domain/entities/writer/ManualCategory';
import ManualCategoriesRepository from '../../../domain/repositories/writer/ManualCategoriesRepository';

export default class NetManualCategoriesRepository
  extends NetResourcesRepository<ManualCategory, ManualCategoryData>
  implements ManualCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/manual_categories',
        new ManualCategoryMapper(),
      ),
    );
  }
}
