import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import StoryListItem from './StoryListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Story from '../../../../../domain/entities/Story';

interface Props {
  stories: Array<Story> | null;
  onSelectStory: (story: Story) => void;
}

const StoryList: React.FunctionComponent<Props> = props => {
  const {stories, onSelectStory} = props;
  const keyExtractor = React.useCallback((story: Story) => `${story.id}`, []);
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Story>) => (
      <StoryListItem story={info.item} onSelectStory={onSelectStory} />
    ),
    [],
  );
  return (
    <FlatList
      data={stories}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListFooterComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(StoryList);
