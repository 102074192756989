import EntityMapper from './EntityMapper';

import SoundMapper, {SoundData} from './SoundMapper';

import SoundUsageHistory from '../../../domain/entities/SoundUsageHistory';

export interface SoundUsageHistoryData {
  id: number;
  story_id: number;
  sound_type: 'background_music' | 'sound_effect';
  sound: SoundData;
  last_used_at: string;
  created_at: string;
}

export default class SoundUsageHistoryMapper
  implements EntityMapper<SoundUsageHistoryData, SoundUsageHistory>
{
  private soundMapper = new SoundMapper();

  public map(obj: SoundUsageHistoryData): SoundUsageHistory {
    return new SoundUsageHistory(
      obj.id,
      obj.story_id,
      obj.sound_type,
      this.soundMapper.map(obj.sound),
      new Date(obj.last_used_at),
      new Date(obj.created_at),
    );
  }
}
