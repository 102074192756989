import * as React from 'react';
import {Text, View} from 'react-native';

import DefaultModal from '../../../shared/modals/DefaultModal';

interface Props {
  onCloseModal: () => void;
}

const FormCompleteModal: React.FunctionComponent<Props> = props => {
  const {onCloseModal} = props;
  return (
    <DefaultModal
      visible={true}
      disableCloseByOutsideTouch={true}
      onCloseModal={onCloseModal}>
      <Text
        style={{
          color: '#c99f0e',
          fontSize: 16,
          fontWeight: 'bold',
          textAlign: 'center',
        }}>
        入会ありがとうございました！
      </Text>
      <View
        style={{justifyContent: 'center', alignItems: 'center', margin: 24}}>
        <Text style={{marginBottom: 16, textAlign: 'center'}}>
          MakerProをお楽しみください！
        </Text>
      </View>
    </DefaultModal>
  );
};

export default React.memo(FormCompleteModal);
