import * as React from 'react';

import TapNovelWebView from '../../shared/TapNovelWebView';

import NavigationProp from '../../../navigators/NavigationProp';

import {getUserAgent} from '../../../../data/data_stores/net/UserAgent';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const [userAgent, setUserAgent] = React.useState<string | null>(null);
  React.useEffect(() => {
    getUserAgent().then(ua => {
      setUserAgent(ua);
    });
  }, []);
  return userAgent ? (
    <TapNovelWebView
      style={{flex: 1}}
      originWhitelist={['*']}
      path={'/writer/monitor/applications/new'}
      userAgent={userAgent}
    />
  ) : null;
};

export default React.memo(Index);
