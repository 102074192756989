import * as React from 'react';
import {FlatList, ListRenderItemInfo, View, Text} from 'react-native';

import EpisodeReleaseRequestListItem from './EpisodeReleaseRequestListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import ProjectEpisodeReleaseRequest from '../../../../../domain/entities/ProjectEpisodeReleaseRequest';

interface Props {
  episodeReleaseRequests: ProjectEpisodeReleaseRequest[];
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const EpisodeReleaseRequestList: React.FC<Props> = props => {
  const {episodeReleaseRequests, ListHeaderComponent} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ProjectEpisodeReleaseRequest>) => (
      <EpisodeReleaseRequestListItem episodeReleaseRequest={info.item} />
    ),
    [],
  );
  if (episodeReleaseRequests.length === 0) {
    return (
      <>
        {ListHeaderComponent}
        <NoEpisodeReleaseRequestList />
      </>
    );
  }
  return (
    <FlatList
      data={episodeReleaseRequests}
      renderItem={renderItem}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(EpisodeReleaseRequestList);

const NoEpisodeReleaseRequestList: React.FC = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Text style={{color: '#222', fontSize: 14}}>
        エピソード公開申請はありません
      </Text>
    </View>
  );
};
