import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/cover_image_form/background_usage_histories/Index';

import AppState from '../../../reducers/AppState';

import showStory from '../../../actions/stories/show';
import indexBackgroundUsageHistories, {
  Params as BackgroundUsageHistoryIndexParams,
} from '../../../actions/background_usage_histories/index';
import indexBaseBackgrounds, {
  Params as BaseBackgroundIndexParams,
} from '../../../actions/base_backgrounds/index';

import selectEntities from '../../../helpers/selectEntities';
import selectEntity from '../../../helpers/selectEntity';
import {enabledResourceFavorite} from '../../../helpers/ResourceAuthorizattion';
import {CoverImageFormBackgroundUsageHistoriesRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CoverImageFormBackgroundUsageHistoriesRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {storyId} = route.params;
  const story = selectEntity(entities.stories, storyId);
  const {coverImageForm} = forms;
  const backgroundUsageHistoryParams: BackgroundUsageHistoryIndexParams = {
    storyId,
  };
  const backgroundUsageHistories = selectEntities(
    entities.backgroundUsageHistories,
    queries.backgroundUsageHistories,
    backgroundUsageHistoryParams,
  );
  const mainGenreId = story && story.getMainGenreId();
  const mainGenreBaseBackgroundsParams: BaseBackgroundIndexParams = {
    wallpaperGenreId: mainGenreId ? [mainGenreId] : null,
    onlyWallpaper: true,
    sort: 'popularity',
    perPage: 1000,
  };
  const mainGenreBaseBackgrounds = selectEntities(
    entities.baseBackgrounds,
    queries.baseBackgrounds,
    mainGenreBaseBackgroundsParams,
  );
  const subGenreBaseBackgroundsParams: BaseBackgroundIndexParams = {
    wallpaperGenreId: story ? story.getSubGenreIds() : null,
    onlyWallpaper: true,
    sort: 'popularity',
    perPage: 1000,
  };
  const subGenreBaseBackgrounds = selectEntities(
    entities.baseBackgrounds,
    queries.baseBackgrounds,
    subGenreBaseBackgroundsParams,
  );
  return {
    navigation,
    coverImageForm,
    story,
    backgroundUsageHistories,
    backgroundUsageHistoryParams,
    mainGenreBaseBackgrounds,
    mainGenreBaseBackgroundsParams,
    subGenreBaseBackgrounds,
    subGenreBaseBackgroundsParams,
    enabledFavorite: enabledResourceFavorite(state),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showStory: (id: number) => dispatch(showStory(id)),
    indexBackgroundUsageHistories: (
      params: BackgroundUsageHistoryIndexParams,
    ) => dispatch(indexBackgroundUsageHistories(params)),
    indexBaseBackgrounds: (params: BaseBackgroundIndexParams) =>
      dispatch(indexBaseBackgrounds(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
