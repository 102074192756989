import * as React from 'react';
import {
  ImageStyle,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import Input from '../../../../shared/forms/Input';
import TextArea from '../../../../shared/forms/TextArea';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';
import TooltipModal from '../../../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../../../shared/KeyboardSpacer';
import AlertModal from '../../../../shared/modals/AlertModal';
import ActionModal from '../../../../shared/modals/ActionModal';

import {colors, formStyles} from '../../../../../styles/variables';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

export type FormValuesIdea = {
  title: string;
  outlinePlan: string;
  highlightOfStory: string;
  submitted: boolean;
};

type FormErrorMessage = {[P in keyof FormValuesIdea]?: string | string[]};

const TypeToTooltipInfo = {
  outlinePlan: {
    title: '企画のあらまし',
    description:
      'あらすじやラフストーリー、イントロダクションなど、エピソードの全体像をわかりやすくご記載ください。\n形式は自由ですが、誰（どんな問題や欲求を抱えた人）が、どんな出来事（障害）に遭遇して、どんなことに悩み（葛藤）、どんな行動を起こして、どうなる話なのかを簡潔に書くことを推奨しております。',
  },
  highlightOfStory: {
    title: '作者の思う見どころ',
    description:
      '誤読を防ぐために、企画の狙いや作者がこの作品の\nどんなところを面白いと思っているのかを簡潔にご記載ください。',
  },
} as {
  [key in keyof FormValuesIdea]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

interface Props {
  values?: Partial<FormValuesIdea>;
  errorMessages?: FormErrorMessage;
  onSubmit: (values: FormValuesIdea) => void;
}

const FormStep2Idea: React.FC<Props> = props => {
  const {values, errorMessages, onSubmit} = props;
  const [title, setTitle] = React.useState(values?.title || '');
  const [outlinePlan, setOutlinePlan] = React.useState(
    values?.outlinePlan || '',
  );
  const [highlightOfStory, setHighlightOfStory] = React.useState(
    values?.highlightOfStory || '',
  );
  const [visiblePostCheck, setVisiblePostCheck] = React.useState(false);
  const onPressQuestionOutlinePlan = React.useCallback(() => {
    setTooltipModalType('outlinePlan');
  }, []);
  const onPressQuestionHighlightOfStory = React.useCallback(() => {
    setTooltipModalType('highlightOfStory');
  }, []);
  const [tooltipModalType, setTooltipModalType] = React.useState<
    keyof FormValuesIdea | null
  >(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalTooltip = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onPress = () => {
    onSubmit({title, outlinePlan, highlightOfStory, submitted: true});
  };
  const openModal = React.useCallback(() => {
    setVisiblePostCheck(true);
  }, []);
  const onAccept = () => {
    setVisiblePostCheck(false);
    onPress();
  };
  const onRequestClose = React.useCallback(() => {
    setVisiblePostCheck(false);
  }, []);
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  const disabled =
    title.length <= 0 ||
    title.length > 40 ||
    outlinePlan.length <= 0 ||
    outlinePlan.length > 400 ||
    highlightOfStory.length > 200;
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={{paddingBottom: 130}}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'エピソードタイトル(最大40文字)'}
            maxLength={40}
            length={title.length}
            requiredOrOptional={'required'}
            entered={title.length > 0 && title.length <= 40}
            errorMessages={errorMessages?.title}
          />
          <Input
            style={[
              {marginBottom: 3, marginTop: 8},
              title.length === 0
                ? requiredInputStyle
                : title.length <= 40
                ? undefined
                : styles.inputError,
            ]}
            value={title}
            placeholder={'エピソードタイトルを入力'}
            onChangeText={setTitle}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'企画のあらまし（最大400文字）'}
            length={outlinePlan.length}
            maxLength={400}
            requiredOrOptional={'required'}
            entered={outlinePlan.length > 0}
            errorMessages={errorMessages?.outlinePlan}
            onPressQuestion={onPressQuestionOutlinePlan}
          />
          <TextArea
            style={[
              styles.textArea,
              outlinePlan.length <= 400 ? undefined : styles.inputError,
              outlinePlan ? undefined : requiredInputStyle,
            ]}
            containerStyle={outlinePlan ? undefined : requiredInputStyle}
            value={outlinePlan}
            placeholder={'企画のあらましを入力'}
            onChangeText={setOutlinePlan}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'作者の思う見どころ（最大200文字）'}
            length={highlightOfStory.length}
            maxLength={200}
            requiredOrOptional={'optional'}
            entered={highlightOfStory.length > 0}
            errorMessages={errorMessages?.highlightOfStory}
            onPressQuestion={onPressQuestionHighlightOfStory}
          />
          <TextArea
            style={[
              styles.textArea,
              highlightOfStory.length <= 200 ? undefined : styles.inputError,
            ]}
            value={highlightOfStory}
            placeholder={'作者の思う見どころ'}
            onChangeText={setHighlightOfStory}
          />
        </View>
        {modalInfo ? (
          <TooltipModal
            visible={true}
            title={modalInfo.title}
            description={modalInfo.description}
            imageStyle={modalInfo.imageStyle}
            source={modalInfo.source}
            onCloseModal={onCloseModalTooltip}></TooltipModal>
        ) : null}
        <KeyboardSpacer />
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
        }}>
        <View style={styles.button}>
          <PrimaryButton disabled={disabled} onPress={openModal}>
            アイデアを提出する
          </PrimaryButton>
        </View>
      </View>
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalTooltip}>
          {alertMessage}
        </AlertModal>
      )}
      <ActionModal
        visible={visiblePostCheck}
        title={'提出後の修正・取り下げはできません。'}
        description={'提出しますか？'}
        descriptionStyle={{textAlign: 'center'}}
        onAccept={onAccept}
        onRequestClose={onRequestClose}
      />
    </View>
  );
};

export default React.memo(FormStep2Idea);

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
});
