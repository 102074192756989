import * as React from 'react';
import {ImageStyle, StyleSheet, View, ViewStyle} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';

import {actorCharacterImageUrl} from '../../../../../../helpers/images';

import ActorCharacter from '../../../../../../../domain/entities/ActorCharacter';

interface Props {
  actorCharacter: ActorCharacter | null;
  inverted?: boolean;
  children?: React.ReactNode;
}

const Preview: React.FunctionComponent<Props> = props => {
  const {actorCharacter, inverted} = props;
  return (
    <View style={styles.container}>
      <View style={[styles.image, inverted ? styles.inverted : null]}>
        {actorCharacter && (
          <FastImageAdapter
            style={styles.image}
            resizeMode={'contain'}
            source={{
              uri: actorCharacterImageUrl(actorCharacter, 'large'),
              headers: {Accept: 'image/webp,image/apng,*/*'},
            }}
          />
        )}
      </View>
    </View>
  );
};

export default React.memo(Preview);

const characterWidth = 240;
const characterHeight = 240;
const characterAspectRatio = characterWidth / characterHeight;

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    marginTop: 20,
  } as ViewStyle,
  image: {
    aspectRatio: characterAspectRatio,
    height: characterHeight,
    width: characterWidth,
  } as ImageStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ViewStyle,
});
