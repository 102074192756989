import Entity from '../Entity';

export default class UserEpisodeComment implements Entity {
  constructor(
    public id: number,
    public episodeId: number,
    public comment: string,
    public commentForSupporter: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
