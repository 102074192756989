import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import DragScrollView from './drag_scroll_view';

import NoneIcon from './icons/NoneIcon';

import FontColor from '../../../domain/value_objects/FontColor';

import {convertFontColor} from '../../../vendor/react-native-tapnovel-viewer/presentation/styles/variables';

interface Props {
  style?: ViewStyle;
  fontColor: FontColor;
  title?: string;
  withNone?: boolean;
  onSelectFontColor: (fontColor: FontColor) => void;
}

export default class FontColorButtons extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {style, withNone, title, onSelectFontColor} = this.props;
    return (
      <View style={[styles.container, style]}>
        {title && <Text style={styles.label}>{title}</Text>}
        <DragScrollView
          horizontal={true}
          bounces={false}
          showsHorizontalScrollIndicator={false}>
          <View style={styles.fontColors}>
            {withNone && (
              <Pressable
                style={[{marginHorizontal: 5}]}
                onPress={() => onSelectFontColor(FontColor.None)}>
                <NoneIcon
                  size={24}
                  color={
                    this.props.fontColor === FontColor.None ? '#ff8f13' : '#ccc'
                  }
                />
              </Pressable>
            )}
            {[
              FontColor.White,
              FontColor.Black,
              FontColor.Red,
              FontColor.Pink,
              FontColor.Orange,
              FontColor.Yellow,
              FontColor.Green,
              FontColor.LightBlue,
              FontColor.Blue,
              FontColor.Purple,
              FontColor.Gray,
            ].map(fontColor => (
              <Pressable
                key={fontColor}
                onPress={() => onSelectFontColor(fontColor)}>
                <View
                  key={fontColor}
                  style={[
                    styles.fontColor,
                    {backgroundColor: convertFontColor(fontColor)},
                    fontColor === this.props.fontColor
                      ? styles.fontColorActive
                      : fontColor === FontColor.White
                      ? {
                          borderColor: '#dddddd',
                          borderWidth: 1,
                        }
                      : null,
                  ]}
                />
              </Pressable>
            ))}
          </View>
        </DragScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
  } as ViewStyle,
  fontColors: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 25,
    marginHorizontal: 16,
  } as ViewStyle,
  fontColor: {
    borderRadius: 50,
    height: 24,
    overflow: 'hidden',
    width: 24,
    marginHorizontal: 5,
  } as TextStyle,
  fontColorActive: {
    borderColor: '#ff8f13',
    borderWidth: 2,
  } as TextStyle,
  noneActive: {
    borderColor: '#ff8f13',
    borderWidth: 2,
    borderRadius: 50,
    height: 24,
    width: 24,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
    marginHorizontal: 16,
    marginBottom: 10,
  } as TextStyle,
});
