import * as React from 'react';

import Form from './partials/Form';

import Layout from '../shared/Layout';
import AlertModal from '../shared/modals/AlertModal';

import NavigationProp from '../../navigators/NavigationProp';

import * as routers from '../../routers';

import {Params as StoryCreateParams} from '../../actions/stories/create';
import {Params as GenreIndexParams} from '../../actions/genres/index';
import {Params as StoryFormCreateParams} from '../../actions/story_forms/create';
import {Params as StoryFormUpdateParams} from '../../actions/story_forms/update';

import {formatErrorMessages} from '../../helpers/errorMessages';

import CurrentUser from '../../../domain/entities/writer/CurrentUser';
import Genre from '../../../domain/entities/Genre';
import Story, {Rating, Format} from '../../../domain/entities/Story';
import StoryForm from '../../../domain/forms/StoryForm';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  currentUser: CurrentUser | null;
  genresParams: GenreIndexParams;
  storyForm: StoryForm | null;
  genres: Genre[] | null;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<CurrentUser>;
  indexGenres: (params: GenreIndexParams) => Promise<PaginatedResult<Genre>>;
  createStoryForm: (params: StoryFormCreateParams) => Promise<any>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
  createStory: (params: StoryCreateParams) => Promise<Story>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  initializedStoryForm: boolean;
  loading: boolean;
  alertMessage?: string;
}

export default class New extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      initializedStoryForm: false,
      loading: false,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      currentUser,
      genresParams,
      genres,
      showCurrentUser,
      createStoryForm,
      indexGenres,
    } = this.props;
    const params = {
      published: false,
      serialized: true,
      allowedVideoSharing: false,
    };
    navigation.addListener('focus', () => {
      if (currentUser) {
        if (!currentUser.isActivated()) {
          navigation.getParent()?.goBack();
        }
      } else {
        showCurrentUser()
          .then(currentUser => {
            if (!currentUser.isActivated()) {
              navigation.getParent()?.goBack();
            }
          })
          .catch(() => {
            navigation.getParent()?.goBack();
          });
      }
    });
    createStoryForm(params).then(() => {
      this.setState({initializedStoryForm: true});
    });
    if (!genres) {
      indexGenres(genresParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, storyForm, genres} = this.props;
    const {initializedStoryForm, loading, alertMessage} = this.state;
    return (
      <Layout
        title={'ストーリー情報'}
        navigation={navigation}
        close={true}
        loading={loading}
        scrollable={false}
        hideHeaderBottomBorder={true}>
        {storyForm && genres && initializedStoryForm && (
          <Form
            key={`${storyForm.id || 'none'}`}
            storyForm={storyForm}
            genres={genres}
            submitButtonText={'保存'}
            episodesCount={0}
            onForwardToMainGenre={this.handleForwardToMainGenre}
            onForwardToSubGenres={this.handleForwardToSubGenres}
            onToggleSerialized={this.handleToggleSerialized}
            onToggleAllowedVideoSharing={this.handleToggleAllowedVideoSharing}
            onForwardToKeywords={this.handleForwardToKeywords}
            onChangeFormat={this.handleChangeFormat}
            onChangeRating={this.handleChangeRating}
            onSubmit={this.handleSubmit}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleForwardToMainGenre = (
    mainGenreId: number | null,
    subGenreIds: number[] | null,
  ) => {
    const {navigation} = this.props;
    routers.linkToStoryFormMainGenre(navigation, {
      mainGenreId,
      subGenreIds,
    });
  };

  private handleForwardToSubGenres = (
    mainGenreId: number | null,
    subGenreIds: number[] | null,
  ) => {
    const {navigation} = this.props;
    routers.linkToStoryFormSubGenres(navigation, {
      mainGenreId,
      subGenreIds,
    });
  };

  private handleToggleSerialized = (serialized: boolean) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({serialized});
  };

  private handleToggleAllowedVideoSharing = (allowedVideoSharing: boolean) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({allowedVideoSharing});
  };

  private handleForwardToKeywords = (keywordNames: string[] | null) => {
    const {navigation} = this.props;
    routers.linkToStoryFormKeywords(navigation, {
      keywordNames,
      enableContests: true,
    });
  };

  private handleChangeRating = (rating: Rating) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({rating});
  };

  private handleChangeFormat = (format: Format) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({format});
  };

  private handleSubmit = (params: {
    title: string;
    introduction: string;
    catchPhrase: string;
  }) => {
    const {navigation, storyForm, createStory, updateStoryForm} = this.props;
    if (!storyForm) {
      return;
    }
    updateStoryForm(params);
    this.setState({loading: true}, () => {
      createStory({...storyForm.toParams(), ...params})
        .then(story => {
          this.setState({loading: false}, () => {
            (navigation.getParent() || navigation).goBack();
            navigation.navigate('Story', {storyId: story.id});
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };
}
