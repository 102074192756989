import Entity from '../Entity';

export default class CurrentUserStatus implements Entity {
  constructor(
    public id: number,
    public fannedConsumerUsersCount: number,
    public publishedStoriesCount: number,
    public notificationsCount: number,
    public unconfirmedNotificationsCount: number,
    public responseStoryAggregationsCount: number,
    public entryContestsCount: number,
    public transfersCount: number,
    public enabledConsumerUser: boolean,
    public enabledCreatorUser: boolean,
    public extensionsCodes: Array<string>,
    public serviceAgreementCodes: Array<string>,
    public lastNotifiedAt: Date | null,
    public lastNotificationConfirmedAt: Date | null,
    public firstPublishedAt: Date | null,
  ) {}

  public getUnconfirmedNotificationsCount() {
    if (this.unconfirmedNotifications()) {
      return this.unconfirmedNotificationsCount;
    }
    return null;
  }

  public unconfirmedNotifications() {
    if (!this.lastNotifiedAt) {
      return false;
    }
    if (!this.lastNotificationConfirmedAt) {
      return true;
    }
    return this.lastNotifiedAt > this.lastNotificationConfirmedAt;
  }
}
