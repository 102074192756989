import EntityMapper from './EntityMapper';

import ActorCharacterMapper, {ActorCharacterData} from './ActorCharacterMapper';

import ActorHairStyle from '../../../domain/entities/ActorHairStyle';

export interface ActorHairStyleData {
  id: number;
  color_pattern_name: string;
  original_image_url: string;
  actor_character: ActorCharacterData;
}

export default class ActorHairStyleMapper
  implements EntityMapper<ActorHairStyleData, ActorHairStyle>
{
  public map(obj: ActorHairStyleData): ActorHairStyle {
    const actorCharacter = new ActorCharacterMapper().map(obj.actor_character);
    return new ActorHairStyle(
      obj.id,
      obj.color_pattern_name,
      obj.original_image_url,
      actorCharacter,
    );
  }
}
