import * as React from 'react';
import {Pressable, StyleProp, Text, View, ViewStyle} from 'react-native';

import ArrowForwardIcon from '../icons/ArrowForwardIcon';

import {formStyles} from '../../../styles/variables';

interface Props {
  style?: StyleProp<ViewStyle>;
  name: string;
  placeholder?: string;
  numberOfLines?: number;
  onPress: () => void;
}

const RadioButton: React.FunctionComponent<Props> = props => {
  const {style, name, placeholder, numberOfLines, onPress} = props;
  return (
    <Pressable onPress={onPress}>
      <View style={formStyles.formFieldWrapper}>
        <View style={[formStyles.formFieldArrow, style]}>
          <Text
            numberOfLines={numberOfLines || 1}
            style={[
              formStyles.formFieldText,
              name ? null : {color: '#999999'},
            ]}>
            {name || placeholder}
          </Text>
          <View style={formStyles.formFieldIcon}>
            <ArrowForwardIcon />
          </View>
        </View>
      </View>
    </Pressable>
  );
};

export default React.memo(RadioButton);
