import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {
  favorite?: boolean;
  purchase?: boolean;
  resourceName: string;
}

const SearchEmpty: React.FunctionComponent<Props> = props => {
  const {favorite, purchase, resourceName} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.text}>
        {favorite ? 'お気に入り登録している' : purchase ? '購入している' : ''}
        {resourceName}はありません
      </Text>
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(SearchEmpty, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
});
