export function addSymbolToTextAndCalcSelection(
  text: string,
  symbol: string,
  currentSelection:
    | {
        start: number;
        end: number;
      }
    | undefined,
) {
  if (currentSelection) {
    const head = text.slice(0, currentSelection.start);
    const tail = text.slice(currentSelection.start);
    const symbolLength = ['（）', '『』', '””'].includes(symbol)
      ? 1
      : symbol.length;
    currentSelection = {
      start: currentSelection.start + symbolLength,
      end: currentSelection.end + symbolLength,
    };
    return {
      text: `${head}${symbol}${tail}`,
      selection: currentSelection,
    };
  } else {
    return {
      text: `${text}${symbol}`,
      selection: currentSelection,
    };
  }
}

export const MAX_LENGTH = 60;

export function validText(text: string) {
  const textLength = text.length;
  return textLength > 0 && textLength <= MAX_LENGTH;
}
