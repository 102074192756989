import EntityMapper from '../EntityMapper';

import UserResourceFavorite from '../../../../domain/entities/writer/UserResourceFavorite';

export interface UserResourceFavoriteData {
  id: number;
  resource_type: string;
  resource_id: number;
  created_at: string;
}

export default class UserResourceFavoriteMapper
  implements EntityMapper<UserResourceFavoriteData, UserResourceFavorite>
{
  public map(obj: UserResourceFavoriteData): UserResourceFavorite {
    return new UserResourceFavorite(
      obj.id,
      obj.resource_type,
      obj.resource_id,
      new Date(obj.created_at),
    );
  }
}
