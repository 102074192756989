import * as React from 'react';
import {
  InteractionManager,
  NativeSyntheticEvent,
  Platform,
  StyleProp,
  StyleSheet,
  TextInput,
  TextInputSelectionChangeEventData,
  TextStyle,
  ViewStyle,
} from 'react-native';

import {isSafari} from '../../../data/data_stores/net/UserAgent';

import ElasticBox from './ElasticBox';

import NameLabelColor from '../../view_models/NameLabelColor';

import {getViewerTextBox} from '../../../vendor/react-native-tapnovel-viewer/presentation/styles/variables';

interface Props {
  textInputRef?: React.RefObject<TextInput>;
  text: string;
  name?: string;
  nameLabelColor?: NameLabelColor;
  hasVoice?: boolean;
  top: string;
  middle: string;
  bottom: string;
  width: number;
  height: number;
  textStyle: StyleProp<TextStyle>;
  middleStyle?: StyleProp<ViewStyle>;
  voiceIconStyle?: ViewStyle;
  selection?: {start: number; end?: number};
  useStyledFrame?: boolean;
  onChangeText: (text: string) => void;
  onChangeName?: (name: string) => void;
  onSelectionChange?: (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => void;
}

interface State {
  loaded: boolean;
}

export default class ElasticBoxInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loaded: !isSafari && Platform.OS !== 'android',
    };
  }

  public componentDidMount() {
    const {loaded} = this.state;
    if (loaded) {
      return;
    }
    InteractionManager.runAfterInteractions(() => {
      setTimeout(() => {
        this.setState({loaded: true});
      }, 100);
    });
  }

  public render(): React.ReactNode {
    const {
      textInputRef,
      text,
      name,
      nameLabelColor,
      hasVoice,
      top,
      middle,
      bottom,
      width,
      height,
      textStyle,
      useStyledFrame,
      selection,
      middleStyle,
      voiceIconStyle,
      onChangeText,
      onChangeName,
    } = this.props;
    const {loaded} = this.state;
    return (
      <ElasticBox
        style={styles.container}
        text={text}
        top={top}
        middle={middle}
        bottom={bottom}
        width={width}
        height={height}
        name={name}
        nameLabelColor={nameLabelColor}
        hasVoice={hasVoice}
        useStyledFrame={useStyledFrame}
        middleStyle={middleStyle}
        voiceIconStyle={voiceIconStyle}
        onChangeName={onChangeName}>
        <TextInput
          ref={textInputRef}
          multiline={true}
          autoFocus={loaded}
          selection={loaded ? selection : undefined}
          style={[
            getViewerTextBox(width),
            styles.text,
            textStyle,
            {width, height},
          ]}
          value={text}
          onChangeText={onChangeText}
          onSelectionChange={this.handleSelectionChange}
        />
      </ElasticBox>
    );
  }

  private handleSelectionChange = (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => {
    const {onSelectionChange} = this.props;
    if (onSelectionChange) {
      onSelectionChange(e);
    }
  };
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  text: {
    backgroundColor: 'transparent',
    position: 'absolute',
    textAlignVertical: 'top',
  } as TextStyle,
});
