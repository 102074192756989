import * as React from 'react';
import {
  ImageResizeMode,
  ImageStyle,
  GestureResponderEvent,
  StyleProp,
  ViewStyle,
} from 'react-native';

import ImageGridList from './ImageGridList';

import {imageUrl} from '../../../helpers/images';

import {Options} from '../../../../domain/helpers/ImageUrlHelper';

interface Entity {
  id: number;
  getImageUrl(options: Options): string;
}

interface Props<T extends Entity> {
  entities: T[];
  aspectRatio?: number;
  imageAspectRatio?: number;
  zoom?: number;
  style?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  imageMaskStyle?: StyleProp<ViewStyle>;
  resizeMode?: ImageResizeMode;
  onEndReachedThreshold?: number | null;
  showsVerticalScrollIndicator?: boolean;
  frame?: boolean;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  extraData?: any;
  onLoadEntities?: () => void;
  onSelectEntity: (entity: T) => void;
  onLongPressEntity: (entity: T) => void;
  onTouchEnd: (event: GestureResponderEvent) => void;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
  imageUrlExtractor?: (item: T, width: number) => string;
  labelExtractor?: (item: T) => string | null;
  imageFrameStyleExtractor?: (item: T) => StyleProp<ViewStyle>;
  imageStyleExtractor?: (item: T) => StyleProp<ImageStyle>;
  renderCustomLabel?: (item: T, width: number) => React.ReactNode;
  disabledItem?: (item: T) => boolean;
}

export default class EntityImageGridList<
  T extends Entity,
> extends React.Component<Props<T>> {
  public componentDidMount() {
    const {onLoadEntities} = this.props;
    if (onLoadEntities) {
      onLoadEntities();
    }
  }

  public render(): React.ReactNode {
    const {
      entities,
      aspectRatio,
      imageAspectRatio,
      zoom,
      style,
      containerStyle,
      imageMaskStyle,
      resizeMode,
      onEndReachedThreshold,
      showsVerticalScrollIndicator,
      frame,
      ListHeaderComponent,
      ListFooterComponent,
      ListEmptyComponent,
      extraData,
      onSelectEntity,
      onLongPressEntity,
      onEndReached,
      imageUrlExtractor,
      labelExtractor,
      imageFrameStyleExtractor,
      imageStyleExtractor,
      renderCustomLabel,
      disabledItem,
    } = this.props;
    return (
      <ImageGridList
        data={entities}
        resizeMode={resizeMode || 'contain'}
        aspectRatio={aspectRatio}
        zoom={zoom}
        style={style}
        containerStyle={containerStyle}
        imageMaskStyle={imageMaskStyle}
        showsVerticalScrollIndicator={showsVerticalScrollIndicator}
        frame={frame}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={ListFooterComponent}
        ListEmptyComponent={ListEmptyComponent}
        extraData={extraData}
        onSelectItem={onSelectEntity}
        onLongPressItem={onLongPressEntity}
        onEndReached={onEndReached}
        onEndReachedThreshold={onEndReachedThreshold}
        imageUrlExtractor={
          imageUrlExtractor
            ? imageUrlExtractor
            : (entity, width) =>
                imageUrl(entity, width, imageAspectRatio || aspectRatio || 1)
        }
        labelExtractor={labelExtractor}
        imageFrameStyleExtractor={imageFrameStyleExtractor}
        imageStyleExtractor={imageStyleExtractor}
        renderCustomLabel={renderCustomLabel}
        disabledItem={disabledItem}
      />
    );
  }
}
