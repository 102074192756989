import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PlayIcon from '../../../shared/icons/PlayIcon';
import BlackButton from '../../../shared/buttons/BlackButton';

interface Props {
  onPress: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
}

const SceneSubmitButton: React.FunctionComponent<Props> = props => {
  const {disabled, onPress} = props;
  return (
    <BlackButton
      buttonSize={buttonSize}
      unwrappedText={true}
      disabled={disabled}
      onPress={onPress}>
      <View style={styles.icon}>
        <PlayIcon color={'white'} size={25} />
      </View>
      <Text style={styles.text}>{'プレビュー'}</Text>
    </BlackButton>
  );
};

export default React.memo(SceneSubmitButton);

const buttonSize = {
  width: 168,
  height: 46,
};

const styles = StyleSheet.create({
  icon: {
    marginHorizontal: 6,
  } as ViewStyle,
  text: {
    color: 'white',
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
});
