import AutoSaveSceneMapper, {AutoSaveSceneData} from './AutoSaveSceneMapper';

import AutoSaveCreatingScene from '../../../domain/entities/AutoSaveCreatingScene';

export interface AutoSaveCreatingSceneData extends AutoSaveSceneData {}

export default class AutoSaveCreatingSceneMapper extends AutoSaveSceneMapper<
  AutoSaveCreatingSceneData,
  AutoSaveCreatingScene
> {
  public map(obj: AutoSaveCreatingSceneData): AutoSaveCreatingScene {
    return new AutoSaveCreatingScene(
      obj.id,
      this.mapSceneForm(obj),
      this.mapSceneCommandForms(obj),
      new Date(obj.updatedAt),
    );
  }
}
