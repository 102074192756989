import EntityMapper from '../EntityMapper';

import Announcement from '../../../../domain/entities/writer/Announcement';

import AnnouncementCategoryMapper, {
  AnnouncementCategoryData,
} from './AnnouncementCategoryMapper';

export interface AnnouncementData {
  id: number;
  title: string;
  content: string;
  published_at: string;
  writer_announcement_category: AnnouncementCategoryData;
  url: string;
}

export default class AnnouncementMapper
  implements EntityMapper<AnnouncementData, Announcement>
{
  private announcementCategoryMapper = new AnnouncementCategoryMapper();

  public map(obj: AnnouncementData): Announcement {
    return new Announcement(
      obj.id,
      obj.title,
      obj.content,
      new Date(obj.published_at),
      this.announcementCategoryMapper.map(obj.writer_announcement_category),
    );
  }
}
