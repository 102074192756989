import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/effect_show_scene_command/new/effect_positions/Index';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import showEffect from '../../../../../actions/effects/show';
import updateEffectUsageHistory, {
  Params as EffectUsageHistoryUpdateParams,
} from '../../../../../actions/effect_usage_histories/update';
import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../../../../actions/scene_command_forms/create';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';

import selectEntity from '../../../../../helpers/selectEntity';

import {SceneFormEffectShowSceneCommandNewEffectPositionsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandNewEffectPositionsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, forms} = state;
  const {effectId, parentSceneCommandId} = route.params;
  const sceneForm = forms.sceneForm;
  const effect = selectEntity(entities.effects, effectId);
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  return {
    navigation,
    sceneForm,
    effect,
    parentSceneCommandForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showEffect: (id: number) => dispatch(showEffect(id)),
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
    updateEffectUsageHistory: (
      id: number,
      params: EffectUsageHistoryUpdateParams,
    ) => dispatch(updateEffectUsageHistory(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
