import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import ActorCharacterFace from '../../entities/ActorCharacterFace';
import CharacterPattern from '../../entities/CharacterPattern';
import Mark from '../../entities/Mark';

import Position, {PositionValueMapper} from '../../value_objects/Position';

const CLASS_NAME: SceneCommandFormClass = 'CharacterShowSceneCommandForm';

export default class CharacterShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(
    public characterPattern: CharacterPattern,
    public actorCharacterFace: ActorCharacterFace,
    public mark: Mark | null,
    public position: Position,
    public waiting?: boolean,
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      actor_character_face_id: this.actorCharacterFace.id,
      character_pattern_id: this.characterPattern.id,
      mark_id: this.mark ? this.mark.id : null,
      position: PositionValueMapper.map(this.position),
      type: 'SceneCommands::CharacterShowSceneCommand',
      waiting: this.waiting,
    };
  }

  public copy(sceneCommandId?: number): CharacterShowSceneCommandForm {
    return new CharacterShowSceneCommandForm(
      this.characterPattern,
      this.actorCharacterFace,
      this.mark,
      this.position,
      this.waiting,
      sceneCommandId,
    );
  }
}
