import React from 'react';
import {
  Linking,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import dateformat from 'dateformat';
import numeral from 'numeral';

import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';

import Transfer from '../../../../../domain/entities/Transfer';

interface Props {
  transfer: Transfer;
  token: string;
}

const TransferListItem: React.FC<Props> = props => {
  const {transfer, token} = props;
  const onPress = React.useCallback(() => {
    Linking.openURL(`${transfer.paymentReceiptUrl}?token=${token}`);
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.rowLabel}>振込日</Text>
        <Text style={styles.rowValue}>
          {dateformat(transfer.createdAt, 'yyyy年mm月dd日')}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowLabel}>請求書番号</Text>
        <Text style={styles.rowValue}>{transfer.id}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.rowLabel}>振込金額</Text>
        <Text style={styles.rowValue}>
          ￥{numeral(transfer.amount).format('0,0')}
        </Text>
      </View>
      <DefaultOutlineButton style={styles.button} onPress={onPress}>
        閲覧する
      </DefaultOutlineButton>
    </View>
  );
};

export default React.memo(TransferListItem);

const styles = StyleSheet.create({
  container: {
    padding: 16,
  } as ViewStyle,
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  rowLabel: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  rowValue: {
    color: '#383838',
    fontSize: 12,
  } as TextStyle,
  button: {
    width: 180,
    height: 34,
    alignSelf: 'center',
  } as ViewStyle,
});
