import * as React from 'react';

import Icon from '../Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const HeartIcon: React.FC<Props> = props => {
  const size = props.size || 15;
  const color = props.color || '#f24006';
  return <Icon name={'project-heart'} size={size} color={color} />;
};

export default React.memo(HeartIcon);
