import * as React from 'react';
import {
  GestureResponderEvent,
  Platform,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

interface Props {
  sceneCommandBox: React.ReactNode;
  onRequestOpenModal: () => void;
}

export default class SceneCommandBoxWithModal extends React.Component<Props> {
  private pressInLocation: {
    pageX: number;
    pageY: number;
  } | null = null;

  private timerId: any | number | null = null;

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, ['sceneCommandBox']);
  }

  public render(): React.ReactNode {
    const {sceneCommandBox} = this.props;
    const webProps = Platform.select({
      web: {
        onMouseDown: this.handleTouchStart,
        onMouseUp: this.handleTouchEnd,
      },
      default: {},
    });
    return (
      <View
        style={styles.container}
        onTouchStart={this.handleTouchStart}
        onTouchEnd={this.handleTouchEnd}
        {...webProps}>
        {sceneCommandBox}
      </View>
    );
  }

  private handleTouchStart = (event: GestureResponderEvent) => {
    const {pageX, pageY} = event.nativeEvent;
    if (pageX !== undefined && pageY !== undefined) {
      this.pressInLocation = {pageX, pageY};
      this.timerId = setTimeout(this.handleLongPress, 400);
    }
  };

  private handleTouchEnd = (event: GestureResponderEvent) => {
    const {pageX: aX, pageY: aY} = event.nativeEvent;
    if (this.pressInLocation) {
      const {pageX: bX, pageY: bY} = this.pressInLocation;
      if (aX === undefined || aY === undefined) {
        if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
        }
      }
      if (
        this.getDistanceBetweenPoints(aX, aY, bX, bY) >
        Platform.select({web: 3, default: 10})
      ) {
        return;
      }
    }
    if (this.timerId) {
      clearTimeout(this.timerId);
      this.handleOpenModal();
      this.timerId = null;
    }
  };

  private handleLongPress = () => {
    this.timerId = null;
  };

  private handleOpenModal = () => {
    const {onRequestOpenModal} = this.props;
    onRequestOpenModal();
  };

  private getDistanceBetweenPoints(
    aX: number,
    aY: number,
    bX: number,
    bY: number,
  ): number {
    const deltaX = aX - bX;
    const deltaY = aY - bY;
    return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
});
