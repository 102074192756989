export const setupForm = (params: {
  onlySetup: boolean;
  clientSecret: string;
  paymentMethodId?: string;
  success: (result: any) => void;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {onlySetup, clientSecret, paymentMethodId, success, setLoading} =
    params;
  const stripe = (window as any).Stripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  );
  const elements = stripe.elements();
  const cardNumberElement = elements.create('cardNumber', {
    showIcon: true,
    placeholder: 'カード番号',
  });

  if (!paymentMethodId) {
    cardNumberElement.mount('#payment-method-card-number-element');
    const cardExpiryElement = elements.create('cardExpiry');
    cardExpiryElement.mount('#payment-method-card-expiry-element');
    const cardCvcElement = elements.create('cardCvc');
    cardCvcElement.mount('#payment-method-card-cvc-element');
  }

  const form = document.querySelector<HTMLFormElement>('#payment-method-form');
  if (form) {
    form.addEventListener('submit', async e => {
      e.preventDefault();
      const submit = form.querySelector<HTMLButtonElement>(
        'button[type="submit"]',
      );
      if (!submit) {
        return;
      }
      setLoading && setLoading(true);
      disableSubmit(submit);
      try {
        const data = {
          payment_method: paymentMethodId || {
            card: cardNumberElement,
          },
        };
        const promise = onlySetup
          ? stripe.confirmCardSetup(clientSecret, data)
          : stripe.confirmCardPayment(clientSecret, data);
        promise
          .then((result: any) => {
            callbackAfterConfirmCard(result, form, submit, success, setLoading);
          })
          .catch((error: any) => {
            console.log(error);
            setLoading && setLoading(false);
            enableSubmit(submit);
          });
      } catch (error: any) {
        setLoading && setLoading(false);
        alert('エラーが発生しました。');
        enableSubmit(submit);
      }
    });
  }
};

const callbackAfterConfirmCard = (
  result: any,
  form: HTMLFormElement,
  submit: HTMLButtonElement,
  success: (result: any) => void,
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const cardNumberErrorMessage = form.querySelector<HTMLParagraphElement>(
    '#payment-method-card-number-error-message',
  );
  const cardExpiryErrorMessage = form.querySelector<HTMLParagraphElement>(
    '#payment-method-card-expiry-error-message',
  );
  const cardCvcErrorMessage = form.querySelector<HTMLParagraphElement>(
    '#payment-method-card-cvc-error-message',
  );

  if (result.error) {
    if (
      !cardNumberErrorMessage ||
      !cardExpiryErrorMessage ||
      !cardCvcErrorMessage
    ) {
      return;
    }
    const errorCode = result.error.code;
    cardNumberErrorMessage.innerText = '';
    cardExpiryErrorMessage.innerText = '';
    cardCvcErrorMessage.innerText = '';
    if (result.error.type == 'validation_error') {
      switch (errorCode) {
        case 'incomplete_number':
        case 'invalid_number':
          cardNumberErrorMessage.innerText = result.error.message;
          break;
        case 'incomplete_expiry':
        case 'invalid_expiry_year_past':
          cardExpiryErrorMessage.innerText = result.error.message;
          break;
        case 'incomplete_cvc':
          cardCvcErrorMessage.innerText = result.error.message;
          break;
      }
    } else {
      alert(`${result.error.message}`);
    }
    setLoading && setLoading(false);
    enableSubmit(submit);
  } else {
    success(result);
  }
};

const disableSubmit = (submit: HTMLButtonElement) => {
  submit.setAttribute('disabled', 'true');
  submit.innerText = '送信中...';
  submit.style.backgroundColor = 'rgb(213, 213, 213)';
};

const enableSubmit = (submit: HTMLButtonElement) => {
  submit.removeAttribute('disabled');
  submit.innerText = '登録する';
  submit.style.backgroundColor = '#ff8f13';
};
