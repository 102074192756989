import * as React from 'react';
import {Image, ImageStyle, StyleSheet, View, ViewStyle} from 'react-native';

import NewBackgroundUsageHistoryButton from './NewBackgroundUsageHistoryButton';

import convertImageSource from '../../../../helpers/convertImageSource';

import backgroundKeyVisualUri from '../../../../../assets/images/background_key_visual.png';

interface Props {
  onForwardToNewBackgroundUsageHistory: () => void;
}

const NoBackgroundUsageHistories: React.FunctionComponent<Props> = props => {
  const {onForwardToNewBackgroundUsageHistory} = props;
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        resizeMode={'contain'}
        source={convertImageSource(backgroundKeyVisualUri)}
      />
      <View style={styles.button}>
        <NewBackgroundUsageHistoryButton
          style={{width: 240}}
          onPress={onForwardToNewBackgroundUsageHistory}
        />
      </View>
    </View>
  );
};

export default NoBackgroundUsageHistories;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  image: {
    width: 130,
    height: 100,
    margin: 16,
  } as ImageStyle,
  button: {
    margin: 16,
  },
});
