import * as React from 'react';
import {ScrollView, View} from 'react-native';

import StoryDetail from './partials/StoryDetail';
import StoryDetailTabs from './partials/StoryDetailTabs';

import NewEpisodeButton from '../episodes/partials/NewEpisodeButton';
import EpisodeDeleteConfirmModal from '../episodes/partials/EpisodeDeleteConfirmModal';
import NewCharacterButton from '../characters/partials/NewCharacterButton';
import CharacterDeleteAlertModal from '../characters/partials/CharacterDeleteAlertModal';
import CharacterDeleteConfirmModal from '../characters/partials/CharacterDeleteConfirmModal';

import Layout from '../shared/Layout';
import {ChangedTabInfo} from '../shared/scrollable_tab_view/ScrollableTabViewAdapter';
import shouldUpdateCharacterList from '../shared/enhanced/shouldUpdateCharacterList';
import shouldUpdateEpisodeList from '../shared/enhanced/shouldUpdateEpisodeList';
import shouldUpdateStory from '../shared/enhanced/shouldUpdateStory';
import AlertModal from '../shared/modals/AlertModal';

import {equalForKeys} from '../../helpers/equalForKeys';

import NavigationProp from '../../navigators/NavigationProp';
import {StoryRouteProp} from '../../navigators/RouteProps';

import * as routers from '../../routers';

import {Params as EpisodeIndexParams} from '../../actions/episodes/index';
import {Params as CharacterIndexParams} from '../../actions/characters/index';
import {Params as CharacterUpdateParams} from '../../actions/characters/update';
import {Params as AutoGenerationEpisodeCreateParams} from '../../actions/auto_generation_episodes/create';
import {Params as EpisodeCreateParams} from '../../actions/episodes/create';
import {Params as EpisodeUpdateParams} from '../../actions/episodes/update';
import {formatErrorMessages} from '../../helpers/errorMessages';

import Character from '../../../domain/entities/Character';
import Episode from '../../../domain/entities/Episode';
import Story from '../../../domain/entities/Story';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import TapNovelRestApi from '../../../data/data_stores/net/TapNovelRestApi';

const scrollViewStyle = {backgroundColor: '#fafafa'};

export interface Params {
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: StoryRouteProp;
  story: Story | null;
  episodes: Episode[] | null;
  episodesParams: EpisodeIndexParams;
  characters: Character[] | null;
  charactersParams: CharacterIndexParams;
}

export interface DispatchProps {
  showStory: (id: number) => Promise<Story>;
  indexEpisodes: (
    params: EpisodeIndexParams,
  ) => Promise<PaginatedResult<Episode>>;
  destroyEpisode: (id: number) => Promise<number>;
  indexCharacters: (
    params: CharacterIndexParams,
  ) => Promise<PaginatedResult<Character>>;
  updateCharacter: (
    id: number,
    params: CharacterUpdateParams,
  ) => Promise<Character>;
  destroyCharacter: (id: number) => Promise<number>;
  createAutoGenerationEpisode: (
    params: AutoGenerationEpisodeCreateParams,
  ) => Promise<Episode>;
  createEpisode: (params: EpisodeCreateParams) => Promise<Episode>;
  updateEpisode: (id: number, params: EpisodeUpdateParams) => Promise<Episode>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  scrollEnabled: boolean;
  loading: boolean;
  currentTabPage: number;
  disabledNewEpisodeButton: boolean;
  deleteEpisode: Episode | null;
  deleteCharacter: Character | null;
  visibleCharacterDeleteAlertModal: boolean;
  alertMessage?: string;
  idToCopyLabel: {[key: number]: string};
}

export default class Show extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      scrollEnabled: true,
      loading: false,
      currentTabPage: 0,
      disabledNewEpisodeButton: false,
      deleteEpisode: null,
      deleteCharacter: null,
      visibleCharacterDeleteAlertModal: false,
      idToCopyLabel: {},
    };
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    if (
      !equalForKeys(this.props, nextProps, [
        'episodesParams',
        'charactersParams',
      ])
    ) {
      return true;
    }
    if (shouldUpdateStory(this.props, nextProps)) {
      return true;
    }
    if (shouldUpdateEpisodeList(this.props, nextProps)) {
      return true;
    }
    if (shouldUpdateCharacterList(this.props, nextProps)) {
      return true;
    }
    if (!equalForKeys(this.state, nextState)) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      episodesParams,
      charactersParams,
      story,
      episodes,
      characters,
      showStory,
      indexEpisodes,
      indexCharacters,
    } = this.props;
    const {storyId} = route.params;
    if (!story) {
      showStory(storyId).catch(e => {
        navigation.goBack();
      });
    }
    if (!episodes) {
      indexEpisodes(episodesParams);
    }
    if (!characters) {
      indexCharacters(charactersParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, story, episodes, characters} = this.props;
    const {
      loading,
      currentTabPage,
      scrollEnabled,
      deleteEpisode,
      deleteCharacter,
      alertMessage,
      visibleCharacterDeleteAlertModal,
      idToCopyLabel,
    } = this.state;
    return (
      <Layout
        title={'エピソード一覧'}
        navigation={navigation}
        back={true}
        loading={loading}
        scrollable={false}>
        {story && episodes && characters && (
          <ScrollView style={scrollViewStyle} scrollEnabled={scrollEnabled}>
            <StoryDetail
              story={story}
              episodes={episodes}
              onForwardToEditStory={this.handleForwardToEditStory}
              onForwardToEditStoryImage={this.handleForwardToEditStoryImage}
            />
            <StoryDetailTabs
              episodes={episodes}
              characters={characters}
              currentTabPage={currentTabPage}
              scrollEnabled={scrollEnabled}
              disableNewEpisode={false}
              idToCopyLabel={idToCopyLabel}
              onSelectEpisode={this.handleSelectEpisode}
              onDeleteEpisode={this.handleDeleteEpisode}
              onCopyEpisode={this.handleCopyEpisode}
              onForwardToNewEpisode={this.handleForwardToNewEpisode}
              onForwardToNewCharacter={this.handleForwardToNewCharacter}
              onSelectCharacter={this.handleSelectCharacter}
              onDeleteCharacter={this.handleDeleteCharacter}
              onChangeTab={this.handleChangeTab}
              onChangeOrderEpisode={this.handleChangeOrderEpisode}
              onChangeOrderCharacter={this.handleChangeOrderCharacter}
              onStartSort={this.handleStartSort}
              onEndSort={this.handleEndSort}
            />
          </ScrollView>
        )}
        <View style={{position: 'absolute', bottom: 0, left: 0, right: 0}}>
          {currentTabPage === 0 ? (
            story &&
            episodes &&
            ((story.format === 'format_one_shot' && episodes.length >= 1) ||
              (story.format === 'format_short' &&
                episodes.length >= 9)) ? null : (
              <NewEpisodeButton
                label={
                  episodes && episodes.length === 0
                    ? '新しいエピソードをつくる'
                    : null
                }
                onForwardToNewEpisode={this.handleForwardToNewEpisode}
              />
            )
          ) : (
            <NewCharacterButton
              label={
                characters && characters.length === 0
                  ? '新しいキャラクターをつくる'
                  : null
              }
              onForwardToNewCharacter={this.handleForwardToNewCharacter}
            />
          )}
        </View>
        {deleteEpisode && (
          <EpisodeDeleteConfirmModal
            episode={deleteEpisode}
            onRequestCloseModal={this.handleRequestCloseModal}
            onRequestDeleteEpisode={this.handleRequestDeleteEpisode}
          />
        )}
        {deleteCharacter && (
          <CharacterDeleteConfirmModal
            character={deleteCharacter}
            onRequestCloseModal={this.handleRequestCloseModal}
            onRequestDeleteCharacter={this.handleRequestDeleteCharacter}
          />
        )}
        {visibleCharacterDeleteAlertModal && (
          <CharacterDeleteAlertModal
            onRequestCloseModal={this.handleRequestCloseModal}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleForwardToEditStory = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToEditStoryNavigation(navigation, {storyId});
  };

  private handleForwardToEditStoryImage = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToEditStoryNavigation(
      navigation,
      {storyId, skipTitleValidation: true},
      'EditStoryFormCoverImage',
    );
  };

  private handleSelectEpisode = (episode: Episode) => {
    const {navigation} = this.props;
    const episodeId = episode.id;
    routers.linkToEpisode(navigation, {episodeId});
  };

  private handleDeleteEpisode = (episode: Episode) => {
    this.setState({deleteEpisode: episode});
  };

  private handleCopyEpisode = (episode: Episode) => {
    const {createEpisode} = this.props;
    this.setState({loading: true});
    createEpisode({copyFrom: episode.id})
      .then(newEpisode => {
        this.setState({
          loading: false,
          idToCopyLabel: {
            ...this.state.idToCopyLabel,
            [newEpisode.id]:
              episode.title.length >= 10
                ? 'コピーしました'
                : `${episode.title}からコピーしました`,
          },
        });
      })
      .catch((error: any) => {
        this.setState({
          loading: false,
          alertMessage: formatErrorMessages({}, error),
        });
      });
  };

  private handleForwardToNewEpisode = () => {
    const {navigation, route, createAutoGenerationEpisode} = this.props;
    const {storyId} = route.params;
    if (this.state.disabledNewEpisodeButton) {
      return;
    }
    this.setState({disabledNewEpisodeButton: true});
    createAutoGenerationEpisode({storyId})
      .then(episode => {
        const episodeId = episode.id;
        const episodePurgeForBack = true;
        routers.linkToNewScene(navigation, {episodeId, episodePurgeForBack});
      })
      .then(() => {
        this.setState({disabledNewEpisodeButton: false});
      });
  };

  private handleSelectCharacter = (character: Character) => {
    const {navigation} = this.props;
    const characterId = character.id;
    routers.linkToCharacter(navigation, {characterId});
  };

  private handleDeleteCharacter = (character: Character) => {
    const characterId = character.id;
    TapNovelRestApi.get(
      `/api/writer/scene_character_patterns?character_id=${characterId}`,
    ).then((res: any) => {
      if (res.body.length > 0) {
        this.setState({visibleCharacterDeleteAlertModal: true});
      } else {
        this.setState({deleteCharacter: character});
      }
    });
  };

  private handleForwardToNewCharacter = () => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToNewCharacter(navigation, {storyId});
  };

  private handleChangeTab = (changedTabInfo: ChangedTabInfo) => {
    this.setState({currentTabPage: changedTabInfo.i});
  };

  private handleStartSort = () => {
    this.setState({scrollEnabled: false});
  };

  private handleEndSort = () => {
    this.setState({scrollEnabled: true});
  };

  private handleChangeOrderEpisode = (
    episode: Episode,
    rowOrderPosition: number,
    options?: {error?: () => void},
  ) => {
    const {indexEpisodes, episodesParams, updateEpisode} = this.props;
    this.setState({loading: true}, () => {
      updateEpisode(episode.id, {rowOrderPosition, ignoreUpdate: true})
        .then(() => {
          indexEpisodes(episodesParams).then(
            () => {
              this.setState({loading: false});
            },
            () => {
              this.setState({loading: false});
            },
          );
        })
        .catch(error => {
          if (options?.error) {
            options.error();
          }
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private handleChangeOrderCharacter = (
    character: Character,
    rowOrderPosition: number,
  ) => {
    const {indexCharacters, charactersParams, updateCharacter} = this.props;
    this.setState({loading: true}, () => {
      updateCharacter(character.id, {rowOrderPosition, ignoreUpdate: true})
        .then(() => {
          indexCharacters(charactersParams).then(
            () => {
              this.setState({loading: false});
            },
            () => {
              this.setState({loading: false});
            },
          );
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private handleRequestDeleteEpisode = (episode: Episode) => {
    const {showStory, indexEpisodes, episodesParams, destroyEpisode} =
      this.props;
    this.setState(
      {
        loading: true,
        deleteEpisode: null,
        visibleCharacterDeleteAlertModal: false,
      },
      () => {
        destroyEpisode(episode.id)
          .then(() => {
            showStory(episode.storyId);
            indexEpisodes(episodesParams).then(
              () => {
                this.setState({loading: false});
              },
              () => {
                this.setState({loading: false});
              },
            );
          })
          .catch(error => {
            this.setState({
              loading: false,
              alertMessage: formatErrorMessages({}, error),
            });
          });
      },
    );
  };

  private handleRequestDeleteCharacter = (character: Character) => {
    const {indexCharacters, charactersParams, destroyCharacter} = this.props;
    this.setState(
      {
        loading: true,
        deleteCharacter: null,
        visibleCharacterDeleteAlertModal: false,
      },
      () => {
        destroyCharacter(character.id)
          .then(() => {
            indexCharacters(charactersParams).then(
              () => {
                this.setState({loading: false});
              },
              () => {
                this.setState({loading: false});
              },
            );
          })
          .catch(() => {
            this.setState({loading: false});
          });
      },
    );
  };

  private handleRequestCloseModal = () => {
    this.setState({
      deleteEpisode: null,
      deleteCharacter: null,
      visibleCharacterDeleteAlertModal: false,
    });
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };
}
