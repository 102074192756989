import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import SceneForm from '../../../domain/forms/SceneForm';

export default function reducer(
  state: SceneForm | null = null,
  action: AnyAction,
): SceneForm | null {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return null;
    case Types.CreateSceneForm:
      return action.payload;
    case Types.UpdateSceneForm:
      return action.payload;
    case Types.DestroySceneForm:
      return null;
    default:
      return state;
  }
}
