import * as React from 'react';

import FormNew, {FormValues} from './partials/FormNew';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewProjectEpisodeReleaseRequestRouteProp} from '../../../navigators/RouteProps';

import {Params as ApplicationModalUpdateParams} from '../../../actions/application_modal/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import NetEpisodesRepository from '../../../../data/repositories/writer/NetEpisodesRepository';

import Project from '../../../../domain/entities/Project';
import ProjectManuscript from '../../../../domain/entities/ProjectManuscript';
import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  episodeId: number;
  projectManuscriptId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewProjectEpisodeReleaseRequestRouteProp;
}

export interface DispatchProps {
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, route, updateApplicationModal} = props;
  const {projectId, episodeId, projectManuscriptId} = route.params;
  const [values, setValues] = React.useState<FormValues | null>(null);
  const [project, setProject] = React.useState<Project | null>(null);
  const [projectManuscript, setProjectManuscript] =
    React.useState<ProjectManuscript | null>(null);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<Project>(`/api/writer/projects/${projectId}`).then(
      result => {
        setProject(result.body);
      },
    );
    new NetEpisodesRepository().find(episodeId).then(episode => {
      setValues({
        title: episode.title,
        writerUserEpisodeCommentId: episode.writerUserEpisodeComment?.id,
        writerUserEpisodeCommentComment:
          episode.writerUserEpisodeComment?.comment || '',
      });
    });
    TapNovelTypedRestApi.get<ProjectManuscript>(
      `/api/writer/project_manuscripts/${projectManuscriptId}`,
    ).then(result => {
      setProjectManuscript(result.body);
    });
  }, []);
  const onPress = React.useCallback(
    (values: FormValues) => {
      if (!projectManuscript) {
        return;
      }
      new NetEpisodesRepository()
        .update(episodeId, {
          episode: {
            title: values.title,
            writer_user_episode_comment_attributes: {
              id: values.writerUserEpisodeCommentId,
              comment: values.writerUserEpisodeCommentComment,
              commentForSupporter: '',
            },
          },
        })
        .then(() => {
          TapNovelTypedRestApi.post(
            `/api/writer/project_episode_release_requests`,
            {
              projectEpisodeId: projectManuscript.projectEpisodeId,
              project_episode_release_request: {
                episodeId,
              },
            },
          )
            .then(() => {
              navigation.goBack();
              setTimeout(() => {
                if ((navigation.getParent() as any).navigate) {
                  (navigation.getParent() as any).navigate('Project', {
                    id: projectManuscript.projectId,
                  });
                }
              }, 300);
              updateApplicationModal({
                options: {message: 'プロジェクトにエピソードを提出しました。'},
              });
            })
            .catch(e => {
              setAlertMessage(formatErrorMessages({}, e));
            });
        });
    },
    [projectManuscript],
  );
  return (
    <Layout title={'エピソード情報'} navigation={navigation} back={true}>
      {values && project ? (
        <FormNew values={values} project={project} onSubmit={onPress} />
      ) : null}
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseModalAlert}>
          {alertMessage}
        </AlertModal>
      )}
    </Layout>
  );
};

export default React.memo(New);
