import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
}

const VideoIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 20;
  const color = props.color || 'white';
  return <Icon name={'video'} size={size} color={color} />;
};

export default React.memo(VideoIcon);
