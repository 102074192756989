import * as React from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputSubmitEditingEventData,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import SearchIcon from '../../../../shared/icons/SearchIcon';

interface Props {}

const SearchInput: React.FC<Props> = props => {
  const linkTo = useLinkTo();
  const onSubmitEditing = React.useCallback(
    (e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => {
      const query = e.nativeEvent.text.trim();
      if (query.length > 0) {
        linkTo(`/projects/search?query=${query}`);
      }
    },
    [],
  );
  return (
    <View style={styles.container}>
      <TextInput
        style={styles.textInput}
        clearButtonMode={'while-editing'}
        placeholder={'キーワードでプロジェクトを検索'}
        placeholderTextColor={'#999'}
        onSubmitEditing={onSubmitEditing}
      />
      <View style={[styles.iconWrapper, {left: 24}]}>
        <View style={styles.icon}>
          <SearchIcon size={15} />
        </View>
      </View>
    </View>
  );
};

export default React.memo(SearchInput);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 64,
    backgroundColor: '#fafafa',
  } as ViewStyle,
  textInput: {
    flex: 1,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    margin: 16,
    height: 32,
    padding: 8,
    paddingLeft: 32,
  } as TextStyle,
  iconWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
  } as ViewStyle,
  icon: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
