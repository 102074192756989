import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import Logo from '../../../../shared/images/Logo';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import {baseStyleWidth} from '../../../../../styles/variables';

interface Props {
  onPressPoliciesPrivacy: () => void;
  onPressPoliciesTerms: () => void;
  onSubmit: () => void;
}
const rate = 1;

const Form: React.FunctionComponent<Props> = props => {
  const {onPressPoliciesPrivacy, onPressPoliciesTerms, onSubmit} = props;
  return (
    <DimensionContext.Consumer>
      {context => {
        const rate = context.content.width / baseStyleWidth;
        return (
          <View style={{flex: 1}}>
            <View style={styles.policyBox}>
              <Logo width={278 * rate} height={43 * rate} />
              <Text style={styles.availableTapNovelAccount}>
                TapNovel（読書サービス）のアカウントで{'\n'}
                TapNovelMaker（投稿サービス）を利用できます。
              </Text>
              <Text style={styles.policyText}>
                TapNovelMakerの
                <Pressable onPress={onPressPoliciesTerms}>
                  <Text style={styles.policyLink}>利用規約</Text>
                </Pressable>
                と
                <Pressable onPress={onPressPoliciesPrivacy}>
                  <Text style={styles.policyLink}>プライバシーポリシー</Text>
                </Pressable>
                {'\n'}
                に同意の上ご利用ください
              </Text>
            </View>
            <View style={styles.signupButton}>
              <PrimaryButton onPress={onSubmit}>利用開始する</PrimaryButton>
            </View>
          </View>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(Form);

const styles = StyleSheet.create({
  policyBox: {
    alignItems: 'center',
    marginTop: 40,
  } as ViewStyle,
  availableTapNovelAccount: {
    marginVertical: 26,
    color: '#222',
    textAlign: 'center',
    fontSize: 13,
  } as TextStyle,
  policyText: {
    fontSize: 11,
    color: '#222',
    textAlign: 'center',
  } as TextStyle,
  policyLink: {
    color: '#ff8f13',
    textDecorationLine: 'underline',
  } as TextStyle,
  signupButton: {
    alignItems: 'center',
    marginVertical: 16,
  } as ViewStyle,
});
