import {Dispatch} from 'redux';

import Types from '../Types';

import EpisodeCommentBlockingUser from '../../../domain/entities/EpisodeCommentBlockingUser';

import NetEpisodeCommentBlockingUsersRepository from '../../../data/repositories/writer/NetEpisodeCommentBlockingUsersRepository';

export interface Params {
  storyId: number;
  consumerUserId: number;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<EpisodeCommentBlockingUser> {
  return async (
    dispatch: Dispatch<any>,
  ): Promise<EpisodeCommentBlockingUser> => {
    try {
      const payload =
        await new NetEpisodeCommentBlockingUsersRepository().create({
          episodeCommentBlockingUser: params,
        });
      dispatch({
        payload,
        type: Types.CreateEpisodeCommentBlockingUserSuccess,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateEpisodeCommentBlockingUserFailure});
      throw err;
    }
  };
}
