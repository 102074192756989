import * as React from 'react';
import {View, ViewStyle} from 'react-native';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import EffectView from './visualizations/EffectView';

import {ModalParams} from '../../Modal';

import SoundIcon from '../../../../shared/icons/SoundIcon';

import SceneFrame from '../../../../../view_models/SceneFrame';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';

const soundIconStyle = {
  position: 'absolute',
  right: 100,
  top: 16,
  width: 30,
  height: 30,
  borderRadius: 15,
  backgroundColor: 'rgba(255, 255, 255, 0.6)',
  justifyContent: 'center',
  alignItems: 'center',
} as ViewStyle;

interface Props {
  sceneCommandForm: EffectShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  sceneFrame: SceneFrame;
  onRequestOpenModal: (modalParams: ModalParams) => void;
}

export default class EffectShowSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, ['sceneCommandForm']);
  }

  public render(): React.ReactNode {
    const {sceneCommandForm} = this.props;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <>
            <EffectView effect={sceneCommandForm.effect} />
            {sceneCommandForm.sound ? (
              <View style={soundIconStyle}>
                <SoundIcon />
              </View>
            ) : null}
          </>
        }
        onRequestOpenModal={this.handleRequestOpen}
      />
    );
  }

  private handleRequestOpen = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      sceneFrame,
      onRequestOpenModal,
    } = this.props;
    onRequestOpenModal({
      type: 'EffectShowSceneCommandModal',
      sceneCommandForm,
      parentSceneCommandForm,
      sceneFrame,
    });
  };
}
