import React from 'react';

import RootStack from '../../../../../RootStack';

import SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm from '../../../../../../containers/scene_form/character_show_scene_command/new/character_form/actor_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm">
      <RootStack.Screen
        name="SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm"
        component={
          SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchForm
        }
        options={{title: 'キャラクター検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
