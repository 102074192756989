import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import BackgroundWeatherMapper, {
  BackgroundWeatherData,
} from '../entities/mappers/BackgroundWeatherMapper';

import BackgroundWeather from '../../domain/entities/BackgroundWeather';
import BackgroundWeathersRepository from '../../domain/repositories/BackgroundWeathersRepository';

export default class NetBackgroundWeathersRepository
  extends NetResourcesRepository<BackgroundWeather, BackgroundWeatherData>
  implements BackgroundWeathersRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/background_weathers',
        new BackgroundWeatherMapper(),
      ),
    );
  }
}
