import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import GenreMapper, {GenreData} from '../entities/mappers/GenreMapper';

import Genre from '../../domain/entities/Genre';
import GenresRepository from '../../domain/repositories/GenresRepository';

export default class NetGenresRepository
  extends NetResourcesRepository<Genre, GenreData>
  implements GenresRepository
{
  constructor() {
    super(new NetResourcesRepositoryAdapter('/api/genres', new GenreMapper()));
  }
}
