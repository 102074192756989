import * as React from 'react';

import BaseIndex from '../../../../shared/generics/character_show_or_update_scene_command/actor_character_faces/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewActorCharacterFacesRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as ActorCharacterFaceIndexParams} from '../../../../../actions/actor_character_faces/index';
import {Params as ActorCostumeIndexParams} from '../../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../../actions/actor_accessory_sets/index';
import {Params as MarkIndexParams} from '../../../../../actions/marks/index';
import {Params as CharacterPatternIndexParams} from '../../../../../actions/character_patterns/index';
import {Params as CharacterPatternCreateParams} from '../../../../../actions/character_patterns/create';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import {equalForKeys} from '../../../../../helpers/equalForKeys';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';
import CharacterPatternMapping from '../../../../../view_models/CharacterPatternMapping';

import ActorCharacterFace from '../../../../../../domain/entities/ActorCharacterFace';
import Mark from '../../../../../../domain/entities/Mark';
import Character from '../../../../../../domain/entities/Character';
import CharacterPattern from '../../../../../../domain/entities/CharacterPattern';
import ActorCostume from '../../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../../domain/entities/ActorHairStyle';
import ActorAccessorySet from '../../../../../../domain/entities/ActorAccessorySet';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../domain/value_objects/Position';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import CharacterShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  episodeId?: number;
  sceneId?: number;
  characterId: number;
  characterPatternId: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewActorCharacterFacesRouteProp;
  sceneForm: SceneForm | null;
  marksParams: MarkIndexParams;
  character: Character | null;
  characterPattern: CharacterPattern | null;
  marks: Mark[] | null;
  parentSceneCommandForm: SceneCommandForm | null;
  sceneCommandForms: SceneCommandForm[] | null;
}

export interface DispatchProps {
  showCharacter: (id: number) => Promise<Character>;
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  showCharacterPattern: (id: number) => Promise<CharacterPattern>;
  createCharacterPattern: (
    params: CharacterPatternCreateParams,
  ) => Promise<CharacterPattern>;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  indexMarks: (params: MarkIndexParams) => Promise<PaginatedResult<Mark>>;
  updateCharacterPatternUsageHistory: (id: number) => Promise<CharacterPattern>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  private disabledSubmit = false;

  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, [
      'actorCharacterFacesParams',
      'character',
      'characterPattern',
      'actorCharacterFaces',
      'marks',
    ]);
  }

  public componentDidMount() {
    const {navigation, route, sceneForm, showCharacterPattern} = this.props;
    const {characterPatternId} = route.params;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    showCharacterPattern(characterPatternId);
  }

  public render(): React.ReactNode {
    const {characterPattern} = this.props;
    const {characterId, characterPatternId} = this.props.route.params;
    if (!characterPattern) {
      return null;
    }
    return (
      <BaseIndex
        {...this.props}
        characterPattern={characterPattern}
        back={true}
        characterId={characterId}
        characterPatternId={characterPatternId}
        onSelectActorCharacterFace={this.handleSelectActorCharacterFace}
      />
    );
  }

  private handleSelectActorCharacterFace = async (
    characterPattern: CharacterPattern,
    actorCharacterFace: ActorCharacterFace,
    mark?: Mark | null,
  ) => {
    const {
      navigation,
      route,
      parentSceneCommandForm,
      sceneCommandForms,
      createSceneCommandForm,
      updateSceneCommandForm,
      updateCharacterPatternUsageHistory,
    } = this.props;
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
    } = route.params;
    const characterPatternId = characterPattern.id;
    const actorCharacterFaceId = actorCharacterFace.id;
    const markId = mark ? mark.id : null;
    if (position === undefined) {
      routers.linkToSceneFormCharacterShowSceneCommandNewCharacterPositions(
        navigation,
        {
          episodeId,
          sceneId,
          actorCharacterFaceId,
          characterPatternId,
          markId,
          sceneCommandIndex,
          subSceneCommandIndex,
          parentSceneCommandId,
        },
      );
      return;
    }
    const existsSceneCommandFormAtIndex =
      sceneCommandForms &&
      sceneCommandIndex !== undefined &&
      !!sceneCommandForms[sceneCommandIndex];
    const sceneCommandForm = new CharacterShowSceneCommandForm(
      characterPattern,
      actorCharacterFace,
      mark || null,
      position,
      sceneCommandIndex === 0
        ? true
        : sceneCommandIndex !== 1 ||
          parentSceneCommandForm ||
          existsSceneCommandFormAtIndex
        ? undefined
        : true,
    );
    if (parentSceneCommandForm) {
      let characterPatternMapping: CharacterPatternMapping | undefined =
        undefined;
      const oldCharacterPattern = this.getOldCharacterPattern(
        parentSceneCommandForm,
        position,
      );
      if (
        oldCharacterPattern &&
        oldCharacterPattern.id !== characterPattern.id
      ) {
        characterPatternMapping = new CharacterPatternMapping();
        await characterPatternMapping.register(
          oldCharacterPattern,
          sceneCommandForm.characterPattern,
          sceneCommandForm.position,
        );
      }
      const compositeSceneCommandForm =
        CompositeSequenceSceneCommandFormFactory.add(
          parentSceneCommandForm,
          sceneCommandForm,
          subSceneCommandIndex !== undefined
            ? {insertIndex: subSceneCommandIndex}
            : undefined,
        );
      updateSceneCommandForm({
        sceneCommandForm: compositeSceneCommandForm,
        characterPatternMapping,
      });
    } else {
      const index = sceneCommandIndex;
      createSceneCommandForm({sceneCommandForm, index});
    }
    updateCharacterPatternUsageHistory(characterPatternId);
    (navigation.getParent() || navigation).goBack();
  };

  private getOldCharacterPattern = (
    sceneCommandForm: SceneCommandForm,
    position: Position,
  ): CharacterPattern | null => {
    if (
      (sceneCommandForm instanceof CharacterShowSceneCommandForm ||
        sceneCommandForm instanceof CharacterUpdateSceneCommandForm) &&
      sceneCommandForm.position === position
    ) {
      return sceneCommandForm.characterPattern;
    } else if (sceneCommandForm instanceof CompositeParallelSceneCommandForm) {
      const value = sceneCommandForm.commandForms
        .map(commandForm => {
          return this.getOldCharacterPattern(commandForm, position);
        })
        .filter(this.notNull);
      return value.length > 0 ? value[0] : null;
    } else if (sceneCommandForm instanceof CompositeSequenceSceneCommandForm) {
      const value = sceneCommandForm.commandForms
        .map(commandForm => {
          return this.getOldCharacterPattern(commandForm, position);
        })
        .filter(this.notNull);
      return value.length > 0 ? value[0] : null;
    }
    return null;
  };

  private notNull<T>(item: T | null): item is T {
    return item !== null;
  }
}
