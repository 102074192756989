import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import DefaultOutlineButton from '../../../shared/buttons/DefaultOutlineButton';
import Input from '../../../shared/forms/Input';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import SocialLoginButtons from '../../../shared/SocialLoginButtons';

import {colors, formStyles} from '../../../../styles/variables';

import UserSessionForm from '../../../../../domain/forms/writer/UserSessionForm';
import {
  ErrorData,
  SuccessData,
} from '../../../../../domain/services/social_logins/SocialLogin';

interface Props {
  form: UserSessionForm;
  errorMessages: {
    email: Array<string> | undefined;
    password: Array<string> | undefined;
    error: string | undefined;
  } | null;
  onChangeEmail: (email: string) => void;
  onChangePassword: (password: string) => void;
  onSubmit: () => void;
  onForwardToSignup: () => void;
  onForwardToNewPassword: () => void;
  onSuccessFacebookLogin: (data: SuccessData) => void;
  onErrorFacebookLogin: (data: ErrorData) => void;
  onSuccessTwitterLogin: (data: SuccessData) => void;
  onErrorTwitterLogin: (data: ErrorData) => void;
  onSuccessLineLogin: (data: SuccessData) => void;
  onErrorLineLogin: (data: ErrorData) => void;
  children?: React.ReactNode;
}

const Form: React.FunctionComponent<Props> = props => {
  const {
    form,
    errorMessages,
    onChangeEmail,
    onChangePassword,
    onSubmit,
    onForwardToSignup,
    onForwardToNewPassword,
    onSuccessFacebookLogin,
    onErrorFacebookLogin,
    onSuccessTwitterLogin,
    onErrorTwitterLogin,
    onSuccessLineLogin,
    onErrorLineLogin,
  } = props;
  return (
    <>
      <View style={{backgroundColor: 'white', paddingBottom: 67}}>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'メールアドレス'}
            errorMessages={errorMessages?.email || errorMessages?.error}
          />
          <Input
            placeholder={'メールアドレスを入力'}
            style={
              errorMessages?.email || errorMessages?.error
                ? styles.fieldWithErrors
                : undefined
            }
            textContentType={'emailAddress'}
            value={form.email}
            onChangeText={onChangeEmail}
            keyboardType="email-address"
            autoCapitalize="none"
            onSubmitEditing={() => handleSubmitEditing(form, onSubmit)}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'パスワード'}
            errorMessages={errorMessages?.password || errorMessages?.error}
          />
          <Input
            placeholder={'パスワードを入力'}
            style={
              errorMessages?.password || errorMessages?.error
                ? styles.fieldWithErrors
                : undefined
            }
            textContentType={'password'}
            value={form.password}
            onChangeText={onChangePassword}
            secureTextEntry={true}
            onSubmitEditing={() => handleSubmitEditing(form, onSubmit)}
          />
        </View>
        <View style={styles.loginButton}>
          <DefaultOutlineButton onPress={onSubmit} disabled={!validate(form)}>
            ログイン
          </DefaultOutlineButton>
        </View>
        <Pressable style={styles.newPassword} onPress={onForwardToNewPassword}>
          <Text style={styles.newPasswordText}>
            パスワードを忘れた方はこちら
          </Text>
        </Pressable>
        <Text style={{color: '#222222', fontSize: 11, textAlign: 'center'}}>
          お持ちのSNSアカウントではじめる
        </Text>
        <SocialLoginButtons
          onSuccessFacebookLogin={onSuccessFacebookLogin}
          onErrorFacebookLogin={onErrorFacebookLogin}
          onSuccessLineLogin={onSuccessLineLogin}
          onErrorLineLogin={onErrorLineLogin}
          onSuccessTwitterLogin={onSuccessTwitterLogin}
          onErrorTwitterLogin={onErrorTwitterLogin}
        />
      </View>
      <View style={styles.signup}>
        <Text style={styles.signupTitle}>会員登録がまだお済みでない方</Text>
        <Text style={styles.signupMessage}>
          会員登録で、自分でつくったストーリーを{'\n'}
          TapNovelに公開できます！
        </Text>
        <View style={styles.signupButton}>
          <PrimaryButton onPress={onForwardToSignup}>
            会員登録(無料)はこちら
          </PrimaryButton>
        </View>
      </View>
    </>
  );
};

const handleSubmitEditing = (form: UserSessionForm, onSubmit: () => void) => {
  if (!validate(form)) {
    return;
  }
  onSubmit();
};

const validate = (form: UserSessionForm) => {
  if (!form.email.match(/.+@.+\.[A-Za-z0-9]{1,}/)) {
    return false;
  }
  if (form.password.length === 0) {
    return false;
  }
  return true;
};

export default React.memo(Form);

const styles = StyleSheet.create({
  loginButton: {
    alignItems: 'center',
    marginVertical: 32,
  } as ViewStyle,
  signup: {
    alignItems: 'center',
    marginBottom: 32,
  } as ViewStyle,
  signupButton: {} as ViewStyle,
  signupMessage: {
    color: '#222222',
    fontSize: 13,
    lineHeight: 18,
    textAlign: 'center',
    marginVertical: 16,
  } as TextStyle,
  signupTitle: {
    marginTop: 32,
    color: colors.textColor,
    fontSize: 13,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
  fieldWithErrors: {
    backgroundColor: 'rgba(242, 52, 6, 0.3)',
    color: '#999999',
  } as ViewStyle,
  newPassword: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
  } as ViewStyle,
  newPasswordText: {
    borderBottomWidth: 1,
    color: '#222222',
    fontSize: 11,
  } as TextStyle,
});
