import * as React from 'react';
import {StyleProp, TextStyle, ViewStyle} from 'react-native';

import CommonListView from './CommonListView';
import DefaultListItem from './DefaultListItem';

import ListItemNormalSeparator from './separators/ListItemNormalSeparator';
import ListItemRightSeparator from './separators/ListItemRightSeparator';

import DirtyPressIgnoreView from '../DirtyPressIgnoreView';

type Separator = 'normal' | 'right';

interface Props<T> {
  style?: StyleProp<ViewStyle>;
  contentContainerStyle?: ViewStyle;
  data: T[];
  horizontal?: boolean;
  separator?: Separator;
  buttonType?: boolean;
  scrollEnabled?: boolean;
  onEndReachedThreshold?: number | null;
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListFooterComponent?: React.ComponentType<any> | React.ReactElement | null;
  ListEmptyComponent?: React.ComponentType<any> | React.ReactElement | null;
  extraData?: any;
  disableItem?: boolean;
  onSelectItem: (item: T) => void;
  renderCustom?: (item: T) => React.ReactNode;
  renderTitle?: (item: T) => React.ReactNode;
  renderIcon?: (item: T) => React.ReactNode;
  titleStyleExtractor?: (item: T) => StyleProp<TextStyle>;
  onEndReached?: ((info: {distanceFromEnd: number}) => void) | null;
}

interface State {}

export default class DefaultList<T> extends DirtyPressIgnoreView<
  T,
  Props<T>,
  State
> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {};
  }

  public render(): React.ReactNode {
    const {
      style,
      contentContainerStyle,
      horizontal,
      data,
      separator,
      scrollEnabled,
      ListHeaderComponent,
      ListFooterComponent,
      ListEmptyComponent,
      extraData,
      onEndReached,
      onEndReachedThreshold,
    } = this.props;
    const containerStyle =
      separator === 'right' ? {backgroundColor: 'white'} : undefined;
    return (
      <CommonListView
        style={[containerStyle, style]}
        contentContainerStyle={contentContainerStyle}
        horizontal={horizontal}
        data={data}
        scrollEnabled={scrollEnabled}
        renderRow={this.renderRow}
        ItemSeparatorComponent={
          separator === 'right'
            ? ListItemRightSeparator
            : ListItemNormalSeparator
        }
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={ListFooterComponent}
        ListEmptyComponent={ListEmptyComponent}
        extraData={extraData}
        onTouchStart={this.handleTouchStart}
        onTouchMove={this.handleTouchMove}
        onEndReached={onEndReached}
        onEndReachedThreshold={onEndReachedThreshold}
      />
    );
  }

  private renderRow = (rowData: T): React.ReactElement<any> => {
    const {
      buttonType,
      extraData,
      disableItem,
      renderCustom,
      renderTitle,
      renderIcon,
      titleStyleExtractor,
    } = this.props;
    return (
      <DefaultListItem
        item={rowData}
        onSelectItem={this.handleSelectItem}
        buttonType={buttonType}
        extraData={extraData}
        disable={disableItem}
        renderCustom={renderCustom}
        renderTitle={renderTitle}
        renderIcon={renderIcon}
        titleStyleExtractor={titleStyleExtractor}
      />
    );
  };
}
