import * as React from 'react';

import AddSceneCommandLink from './AddSceneCommandLink';

import IllustrationIcon from '../../../../shared/icons/IllustrationIcon';

import {colors} from '../../../../../styles/variables';

interface Props {
  onPress: () => void;
}

const AddIllustrationShowSceneCommandLink: React.FunctionComponent<
  Props
> = props => {
  const {onPress} = props;
  return (
    <AddSceneCommandLink title={'アイテム'} onPress={onPress}>
      <IllustrationIcon color={colors.textColor} />
    </AddSceneCommandLink>
  );
};

export default React.memo(AddIllustrationShowSceneCommandLink);
