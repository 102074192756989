import EntityMapper from './EntityMapper';

import FramePartPositionMapper, {
  FramePartPositionData,
} from '../../value_objects/mappers/FramePartPositionMapper';

import OrientedSpeechBalloonPart from '../../../domain/entities/OrientedSpeechBalloonPart';

export interface OrientedSpeechBalloonPartData {
  id: number;
  oriented_speech_balloon_id: number;
  frame_part_position: FramePartPositionData;
  original_image_url: string;
}

export default class OrientedSpeechBalloonPartMapper
  implements
    EntityMapper<OrientedSpeechBalloonPartData, OrientedSpeechBalloonPart>
{
  private framePartPositionMapper: FramePartPositionMapper;

  constructor() {
    this.framePartPositionMapper = new FramePartPositionMapper();
  }

  public map(obj: OrientedSpeechBalloonPartData): OrientedSpeechBalloonPart {
    const framePartPosition = this.framePartPositionMapper.map(
      obj.frame_part_position,
    );
    return new OrientedSpeechBalloonPart(
      obj.id,
      obj.oriented_speech_balloon_id,
      framePartPosition,
      obj.original_image_url,
    );
  }
}
