import * as React from 'react';
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import dateformat from 'dateformat';

import CheckedIcon from '../../../../shared/icons/CheckedIcon';
import CloseIcon from '../../../../shared/icons/CloseIcon';

import DefaultOutlineButton from '../../../../shared/buttons/DefaultOutlineButton';

import Invoice from '../../../../../../domain/entities/writer/my_stripe/Invoice';

import RawApi from '../../../../../../data/data_stores/net/RawApi';

interface Props {
  invoice: Invoice;
}

const InvoiceListItem: React.FunctionComponent<Props> = props => {
  const {invoice} = props;
  const [loading, setLoading] = React.useState(false);
  const onPress = React.useCallback(() => {
    setLoading(true);
    RawApi.get(invoice.apiWriterReceiptUrl)
      .then(async response => {
        const disposition = response.headers.get('Content-Disposition');
        if (disposition && disposition.indexOf('attachment') !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            const fileName = decodeURIComponent(
              matches[1].replace(/['"]/g, ''),
            );
            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(await response.blob());
            a.download = fileName;
            a.click();
          }
        }
        setLoading(false);
      })
      .catch(() => {
        () => {
          setLoading(false);
        };
      });
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        {invoice.status === 'paid' ? (
          <CheckedIcon color={'rgb(26,127,55)'} />
        ) : invoice.status === 'void' || invoice.status === 'uncollectible' ? (
          <CloseIcon size={12} color={'#222'} />
        ) : null}
      </View>
      <View style={styles.rows}>
        <View style={styles.row}>
          <Text style={styles.rowLabel}>発行日</Text>
          <Text style={styles.rowText}>
            {invoice.issuedAt
              ? dateformat(invoice.issuedAt, 'yyyy年mm月dd日')
              : null}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.rowLabel}>請求書番号</Text>
          <Text style={styles.rowText}>{invoice.dataNumber}</Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.rowLabel}>金額</Text>
          <Text style={styles.rowText}>{invoice.dataTotal}円</Text>
        </View>
      </View>
      <View style={styles.right}>
        <DefaultOutlineButton
          style={styles.button}
          fontStyle={styles.buttonText}
          disabled={loading}
          onPress={onPress}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              paddingRight: 11,
            }}>
            {
              <ActivityIndicator
                size={10}
                style={{marginRight: 1}}
                color={loading ? '#666' : 'transparent'}
              />
            }
            ダウンロード
          </View>
        </DefaultOutlineButton>
      </View>
    </View>
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(InvoiceListItem, areEqual);

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: '#e5e5e5',
    marginVertical: 8,
    marginHorizontal: 16,
    padding: 16,
    flexDirection: 'row',
  } as ViewStyle,
  left: {
    width: 15,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  rows: {
    marginHorizontal: 16,
  } as ViewStyle,
  row: {
    flexDirection: 'row',
  } as ViewStyle,
  rowLabel: {
    color: '##666',
    fontSize: 14,
    fontWeight: 'bold',
    width: 80,
  } as TextStyle,
  rowText: {
    fontSize: 14,
  } as TextStyle,
  right: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  } as ViewStyle,
  button: {
    width: 95,
    height: 32,
  } as ViewStyle,
  buttonText: {
    fontSize: 11,
  } as TextStyle,
});
