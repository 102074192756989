import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import CharacterViewForCharacterMaker from './CharacterViewForCharacterMaker';

import Tabs from '../tabs/Tabs';
import ConfirmButton from '../buttons/ConfirmButton';
import LabelList from '../lists/LabelList';
import ImageList from '../lists/ImageList';

import DimensionContext from '../../../../../shared/dimension/DimensionContext';

import {Params as ActorCharacterFaceIndexParams} from '../../../../../../actions/actor_character_faces/index';

import {QueryState} from '../../../../../../reducers/queries/Response';

import {CharacterSceneCommandForm} from '../../../../../../view_models/SceneFrame';

import ActorCharacterFace from '../../../../../../../domain/entities/ActorCharacterFace';
import ActorFace from '../../../../../../../domain/entities/character_maker/ActorFace';
import ActorFacePart from '../../../../../../../domain/entities/character_maker/ActorFacePart';
import ActorCostume from '../../../../../../../domain/entities/character_maker/ActorCostume';
import ActorHairColor from '../../../../../../../domain/entities/character_maker/ActorHairColor';
import ActorEye from '../../../../../../../domain/entities/character_maker/ActorEye';
import Mark from '../../../../../../../domain/entities/Mark';
import PaginatedResult from '../../../../../../../domain/results/PaginatedResult';

import NetActorCostumesRepository from '../../../../../../../data/repositories/writer/character_maker/NetActorCostumesRepository';
import NetActorEyesRepository from '../../../../../../../data/repositories/writer/character_maker/NetActorEyesRepository';
import NetActorFacePartsRepository from '../../../../../../../data/repositories/writer/character_maker/NetActorFacePartsRepository';
import NetActorFacesRepository from '../../../../../../../data/repositories/writer/character_maker/NetActorFacesRepository';
import NetActorHairColorsRepository from '../../../../../../../data/repositories/writer/character_maker/NetActorHairColorsRepository';
import NetMetaCharacterPatternsRepository from '../../../../../../../data/repositories/writer/NetMetaCharacterPatternsRepository';
import NetActorCharactersRepository from '../../../../../../../data/repositories/writer/NetActorCharactersRepository';

const actorCostumesRepository = new NetActorCostumesRepository();
const actorEyesRepository = new NetActorEyesRepository();
const actorFacePartsRepository = new NetActorFacePartsRepository();
const actorFacesRepository = new NetActorFacesRepository();
const actorHairColorsRepository = new NetActorHairColorsRepository();
const metaCharacterPatternsRepository =
  new NetMetaCharacterPatternsRepository();
const actorCharactersRepository = new NetActorCharactersRepository();

type TabValue = 'face' | 'mark' | 'costume' | 'hair_color';

interface Props {
  sceneCommandForm: CharacterSceneCommandForm;
  actorCharacterFaceEntities: {[key: number]: ActorCharacterFace};
  actorCharacterFaceQueries: QueryState;
  marks: Mark[] | null;
  inverted?: boolean;
  onRequestIndexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  onRequestIndexMarks: () => Promise<PaginatedResult<Mark>>;
  onSubmit: (
    selectedActorCharacterFace: ActorCharacterFace,
    selectedMark: Mark | null,
  ) => void;
  onRequestLoading?: (loading: boolean) => void;
}

interface State {
  currentTab: TabValue;
  marks: Mark[] | null;
  actorFaceParts: ActorFacePart[] | null;
  actorFaces: ActorFace[] | null;
  actorCostumes: ActorCostume[] | null;
  actorHairColors: ActorHairColor[] | null;
  actorEyes: ActorEye[] | null;
  selectedActorFacePart: ActorFacePart | null;
  selectedActorCostume: ActorCostume | null;
  selectedActorHairColor: ActorHairColor | null;
  selectedActorEye: ActorEye | null;
  selectedMark: Mark;
}

export default class CharacterViewWithEditor extends React.PureComponent<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    const {sceneCommandForm} = props;
    this.state = {
      currentTab: 'face',
      marks: null,
      actorFaceParts: null,
      actorFaces: null,
      actorCostumes: null,
      actorHairColors: null,
      actorEyes: null,
      selectedActorFacePart: null,
      selectedActorCostume: null,
      selectedActorHairColor: null,
      selectedActorEye: null,
      selectedMark: sceneCommandForm.mark || noMark,
    };
  }

  public componentDidMount() {
    this.requestEntities();
  }

  public render(): React.ReactNode {
    const {marks, inverted} = this.props;
    const {
      currentTab,
      actorFaceParts,
      actorCostumes,
      actorHairColors,
      selectedActorFacePart,
      selectedActorCostume,
      selectedActorHairColor,
      selectedActorEye,
      selectedMark,
    } = this.state;
    if (
      !actorFaceParts ||
      !marks ||
      !actorCostumes ||
      !actorHairColors ||
      !selectedActorFacePart ||
      !selectedMark ||
      !selectedActorCostume ||
      !selectedActorHairColor ||
      !selectedActorEye
    ) {
      return null;
    }
    return (
      <DimensionContext.Consumer>
        {context => (
          <View style={styles.body}>
            <CharacterViewForCharacterMaker
              actorFacePart={selectedActorFacePart}
              actorHairStyle={selectedActorHairColor}
              actorCostume={selectedActorCostume}
              actorEye={selectedActorEye}
              mark={selectedMark}
              inverted={inverted}
            />
            <View style={styles.bodyFooter}>
              <Tabs<TabValue>
                items={[
                  {value: 'face', label: '表情'},
                  {value: 'mark', label: 'アイコン'},
                  {
                    value: 'costume',
                    label: '衣装',
                    disabled: (actorCostumes || []).length <= 1,
                  },
                  {
                    value: 'hair_color',
                    label: '髪色',
                    disabled:
                      (actorHairColors || []).length <= 1 ||
                      selectedActorHairColor.noHairColor,
                  },
                ]}
                currentValue={currentTab}
                onChangeTab={this.handleChangeTab}
              />
              <LabelList
                visible={currentTab === 'face'}
                entities={actorFaceParts}
                selectedEntityId={selectedActorFacePart?.id}
                onSelectEntity={this.handleSelectActorFacePart}
                extractLabel={this.extractActorFacePartLabel}
              />
              <ImageList
                visible={currentTab === 'mark'}
                entities={[noMark, ...marks]}
                selectedEntityId={selectedMark.id}
                onSelectEntity={this.handleSelectMark}
              />
              <LabelList
                visible={currentTab === 'costume'}
                entities={actorCostumes}
                selectedEntityId={selectedActorCostume.id}
                onSelectEntity={this.handleSelectActorCostume}
              />
              <LabelList
                visible={currentTab === 'hair_color'}
                entities={actorHairColors}
                selectedEntityId={selectedActorHairColor.id}
                onSelectEntity={this.handleSelectActorHairColor}
              />
              <View
                style={{
                  alignItems: 'center',
                  marginVertical: context.content.width > 320 ? 15 : 10,
                }}>
                <ConfirmButton onPress={this.handlePress} />
              </View>
            </View>
          </View>
        )}
      </DimensionContext.Consumer>
    );
  }

  private requestEntities = async () => {
    const {sceneCommandForm, marks, onRequestIndexMarks} = this.props;
    const metaCharacterPattern = await metaCharacterPatternsRepository.find(
      sceneCommandForm.characterPattern.id,
    );
    const {faceName} = sceneCommandForm.actorCharacterFace;
    const {
      id,
      actorId,
      hairStyleName,
      costumeName,
      colorPatternId,
      eyeColorPatternId,
    } = metaCharacterPattern;
    const actorFacePartsResult = await actorFacePartsRepository.findBy({
      actorId,
      colorPatternId,
      sort: 'face_pattern_id',
    });
    const selectedActorFacePart =
      actorFacePartsResult.records.find(
        actorFacePart => actorFacePart.facePatternName === faceName,
      ) || null;
    const actorFacesResult = await actorFacesRepository.findBy({
      actorId,
      facePatternId: selectedActorFacePart?.facePatternId,
    });
    const actorCostumesResult = await actorCostumesRepository.findBy({
      actorId,
    });
    const actorHairColorsResult = await actorHairColorsRepository.findBy({
      actorId,
      name: hairStyleName,
      sort: 'hair_color',
    });
    const actorEyesResult = await actorEyesRepository.findBy({
      actorId,
      eyeColorPatternId,
    });
    const selectedActorCostume =
      actorCostumesResult.records.find(
        actorCostume => actorCostume.name === costumeName,
      ) || null;
    const selectedActorHairColor =
      actorHairColorsResult.records.find(
        actorHairColor => actorHairColor.colorPatternId === colorPatternId,
      ) || null;
    const selectedActorEye =
      actorEyesResult.records.find(
        actorEye =>
          actorEye.facePatternId === selectedActorFacePart?.facePatternId,
      ) || null;
    this.setState({
      actorFaceParts: actorFacePartsResult.records,
      actorFaces: actorFacesResult.records,
      actorCostumes: actorCostumesResult.records,
      actorHairColors: actorHairColorsResult.records,
      actorEyes: actorEyesResult.records,
      selectedActorFacePart,
      selectedActorCostume,
      selectedActorHairColor,
      selectedActorEye,
    });
    if (!marks) {
      onRequestIndexMarks().then(result => {
        this.setState({marks: result.records});
      });
    }
  };

  private handleChangeTab = (currentTab: TabValue) => {
    if (this.state.currentTab === currentTab) {
      return;
    }
    this.setState({currentTab});
    switch (currentTab) {
      case 'face':
        return;
      case 'costume':
        return;
      case 'hair_color':
        return;
    }
  };

  private handleSelectActorFacePart = (
    selectedActorFacePart: ActorFacePart,
  ) => {
    const {actorEyes} = this.state;
    const selectedActorEye =
      actorEyes?.find(
        actorEye =>
          actorEye.facePatternId === selectedActorFacePart.facePatternId,
      ) || null;
    this.setState({selectedActorFacePart, selectedActorEye});
  };

  private handleSelectMark = (selectedMark: Mark) => {
    this.setState({selectedMark});
  };

  private handleSelectActorCostume = (selectedActorCostume: ActorCostume) => {
    this.setState({selectedActorCostume});
  };

  private handleSelectActorHairColor = async (
    selectedActorHairColor: ActorHairColor,
  ) => {
    const {selectedActorFacePart: currentSelectedActorFacePart} = this.state;
    const actorFacePartsResult = await actorFacePartsRepository.findBy({
      actorId: selectedActorHairColor.actorId,
      colorPatternId: selectedActorHairColor.colorPatternId,
      sort: 'face_pattern_id',
    });
    const selectedActorFacePart =
      actorFacePartsResult.records.find(
        actorFacePart =>
          actorFacePart.facePatternId ===
          currentSelectedActorFacePart?.facePatternId,
      ) || null;
    this.setState({
      selectedActorHairColor,
      selectedActorFacePart,
      actorFaceParts: actorFacePartsResult.records,
    });
  };

  private extractActorFacePartLabel = (actorFacePart: ActorFacePart) => {
    return actorFacePart.facePatternName;
  };

  private handlePress = () => {
    const {onRequestIndexActorCharacterFaces, onSubmit, onRequestLoading} =
      this.props;
    const {
      selectedActorFacePart,
      selectedActorCostume,
      selectedActorHairColor,
      selectedActorEye,
      selectedMark,
    } = this.state;
    if (
      !selectedActorFacePart ||
      !selectedActorCostume ||
      !selectedActorHairColor ||
      !selectedActorEye
    ) {
      return;
    }
    const {actorId, facePatternName} = selectedActorFacePart;
    const actorCostumeId = selectedActorCostume.id;
    const actorHairStyleId = selectedActorHairColor.id;
    const eyeColorPatternId = selectedActorEye.eyeColorPatternId;
    onRequestLoading && onRequestLoading(true);
    actorCharactersRepository
      .create({
        actorCharacter: {
          actorId,
          actorCostumeId,
          actorHairStyleId,
          eyeColorPatternId,
        },
      })
      .then(actorCharacter => {
        onRequestIndexActorCharacterFaces({
          actorCharacterId: actorCharacter.id,
        }).then(actorCharacterFacesResult => {
          const foundActorCharacterFace =
            actorCharacterFacesResult.records.find(
              actorCharacterFace =>
                actorCharacterFace.faceName === facePatternName,
            );
          if (!foundActorCharacterFace) {
            return;
          }
          onRequestLoading && onRequestLoading(false);
          onSubmit(
            foundActorCharacterFace,
            selectedMark?.id === 0 ? null : selectedMark,
          );
        });
      })
      .catch(() => {
        onRequestLoading && onRequestLoading(false);
      });
  };
}

const noMark = new Mark(0, '');

const styles = StyleSheet.create({
  body: {
    alignItems: 'center',
    width: '100%',
    backgroundColor: '#fafafa',
  } as ViewStyle,
  bodyFooter: {
    backgroundColor: 'white',
    width: '100%',
  } as ViewStyle,
});
