import CurrentUser from '../../entities/writer/CurrentUser';

import File from '../../entities/File';

import ImageUrlHelper, {Options} from '../../helpers/ImageUrlHelper';

export default class UserForm {
  public id: number;
  public email: string;
  public penName: string;
  public description: string;
  public image: File | null | undefined;
  public originalImageUrl: string | null = null;
  public birthday: Date | null = null;
  public homePageUrl = '';
  public twitterAccountUrl = '';
  public youtubeChannelUrl = '';

  constructor() {
    this.id = 0;
    this.email = '';
    this.penName = '';
    this.description = '';
  }

  public bind(currentUser: CurrentUser) {
    this.id = currentUser.id;
    this.email = currentUser.email;
    this.penName = currentUser.penName;
    if (currentUser.userProfile) {
      this.description = currentUser.userProfile.description;
      if (currentUser.userProfile.hasImage) {
        this.originalImageUrl = currentUser.userProfile.originalImageUrl;
      }
      this.birthday = currentUser.userProfile.birthday;
      this.homePageUrl = currentUser.userProfile.homePageUrl || '';
      this.twitterAccountUrl = currentUser.userProfile.twitterAccountUrl || '';
      this.youtubeChannelUrl = currentUser.userProfile.youtubeChannelUrl || '';
    }
  }

  public copyForm(that: UserForm) {
    this.id = that.id;
    this.email = that.email;
    this.penName = that.penName;
    this.description = that.description;
    this.image = that.image;
    this.originalImageUrl = that.originalImageUrl;
    this.birthday = that.birthday;
    this.homePageUrl = that.homePageUrl;
    this.twitterAccountUrl = that.twitterAccountUrl;
    this.youtubeChannelUrl = that.youtubeChannelUrl;
  }

  public toParams() {
    const penName = this.penName;
    const email = this.email;
    const description = this.description;
    const birthday = this.getFormattedBirthday();
    const homePageUrl = this.homePageUrl;
    const twitterAccountUrl = this.twitterAccountUrl;
    const youtubeChannelUrl = this.youtubeChannelUrl;
    const image = this.image;
    const userProfileAttributes =
      image === undefined
        ? {
            description,
            birthday,
            homePageUrl,
            twitterAccountUrl,
            youtubeChannelUrl,
          }
        : {
            description,
            image,
            birthday,
            homePageUrl,
            twitterAccountUrl,
            youtubeChannelUrl,
          };
    return {
      email,
      penName,
      userProfileAttributes,
    };
  }

  public getImageUrl(options: Options): string {
    if (!this.originalImageUrl) {
      return '';
    }
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }

  public getBirthdayYear = () => {
    if (this.birthday) {
      return this.birthday.getFullYear();
    } else {
      return null;
    }
  };

  public getBirthdayMonth = () => {
    if (this.birthday) {
      return this.birthday.getMonth() + 1;
    } else {
      return null;
    }
  };

  public getBirthdayDate = () => {
    if (this.birthday) {
      return this.birthday.getDate();
    } else {
      return null;
    }
  };

  private getFormattedBirthday = () => {
    if (this.birthday) {
      return `${this.getBirthdayYear()}-${this.getBirthdayMonth()}-${this.getBirthdayDate()}`;
    } else {
      return null;
    }
  };
}
