import Entity from '../Entity';

export default class Episode implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public title: string,
    public tipsCount: number,
    public reactionsCount: number,
    public commentsCount: number,
    public confirmed: boolean,
    public confirmedTips: boolean,
    public confirmedReactions: boolean,
    public confirmedComments: boolean,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
