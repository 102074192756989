import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import Story from '../../../domain/entities/Story';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexStoriesSuccess: {
      const result = action.payload as PaginatedResult<Story>;
      return mergeResponse(state, result);
    }
    case Types.CreateStorySuccess: {
      const story = action.payload as Story;
      const createState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        response.pageToIds[1] = [story.id, ...response.pageToIds[1]];
        createState[stateKey] = response;
      });
      return createState;
    }
    case Types.DestroyStorySuccess: {
      const id = action.payload as number;
      const destroyState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        Object.keys(response.pageToIds).forEach((pageKey: string) => {
          const nPageKey = Number(pageKey);
          if (response.pageToIds[nPageKey]) {
            response.pageToIds[nPageKey] = response.pageToIds[nPageKey].filter(
              storyId => {
                return storyId !== id;
              },
            );
          }
        });
        destroyState[stateKey] = response;
      });
      return destroyState;
    }
    default:
      return state;
  }
}
