import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  style?: ViewStyle;
  totalCount: number | null;
  sort?: 'popularity' | 'new_arrival';
  favorite?: boolean;
  purchase?: boolean;
  paid?: boolean;
  onPress: (sort: 'popularity' | 'new_arrival') => void;
}

const TotalCountWithSort: React.FunctionComponent<Props> = props => {
  const {style, totalCount, favorite, purchase, paid, onPress} = props;
  const sort = props.sort || 'popularity';
  const handlePressPopularity = React.useCallback(() => {
    onPress('popularity');
  }, []);
  const handlePressNewArrival = React.useCallback(() => {
    onPress('new_arrival');
  }, []);
  return (
    <View style={[styles.container, style]}>
      <Text style={styles.totalCount}>
        {totalCount === null ? '' : `全${totalCount}件`}
      </Text>
      <View style={styles.sortButtons}>
        <Pressable
          disabled={sort === 'popularity'}
          onPress={handlePressPopularity}>
          <Text
            style={[
              styles.sortButton,
              sort === 'popularity' ? styles.sortButtonActive : null,
            ]}>
            {favorite || purchase
              ? '利用回数順'
              : paid
              ? 'おすすめ順'
              : '人気順'}
          </Text>
        </Pressable>
        <Text style={styles.bar}>|</Text>
        <Pressable
          disabled={sort === 'new_arrival'}
          onPress={handlePressNewArrival}>
          <Text
            style={[
              styles.sortButton,
              sort === 'new_arrival' ? styles.sortButtonActive : null,
            ]}>
            {favorite ? '登録順' : purchase ? '購入順' : '新着順'}
          </Text>
        </Pressable>
      </View>
    </View>
  );
};

export default React.memo(TotalCountWithSort);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginVertical: 12,
  } as ViewStyle,
  totalCount: {
    color: '#222222',
    fontSize: 13,
  } as TextStyle,
  sortButtons: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  sortButton: {
    color: '#222222',
    fontSize: 13,
  } as TextStyle,
  sortButtonActive: {
    color: '#ff8f13',
    fontWeight: 'bold',
  } as TextStyle,
  bar: {
    color: '#e5e5e5',
    fontSize: 15,
    marginHorizontal: 5,
  } as TextStyle,
});
