import * as React from 'react';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import SpeechTextView from './visualizations/SpeechTextView';

import {ModalParams} from '../../Modal';

import SceneFrame from '../../../../../view_models/SceneFrame';
import NameLabelColor from '../../../../../view_models/NameLabelColor';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import Position from '../../../../../../domain/value_objects/Position';
import Gender from '../../../../../../domain/value_objects/Gender';

interface Props {
  sceneCommandForm: SpeechTextShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  sceneFrame: SceneFrame;
  onRequestOpenModal: (modalParams: ModalParams) => void;
}

export default class SpeechTextShowSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, [
      'sceneCommandForm',
      'sceneFrame',
    ]);
  }

  public render(): React.ReactNode {
    const {sceneCommandForm} = this.props;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <SpeechTextView
            orientedSpeechBalloon={sceneCommandForm.orientedSpeechBalloon}
            text={sceneCommandForm.text}
            name={this.getCharacterName()}
            nameLabelColor={this.getCharacterNameLabelColor()}
            hasVoice={!!sceneCommandForm.voice}
            hasSound={!!sceneCommandForm.sound}
          />
        }
        onRequestOpenModal={this.handleRequestOpen}
      />
    );
  }

  private handleRequestOpen = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      sceneFrame,
      onRequestOpenModal,
    } = this.props;
    onRequestOpenModal({
      type: 'SpeechTextShowSceneCommandModal',
      sceneCommandForm,
      parentSceneCommandForm,
      sceneFrame,
    });
  };

  private getCharacterName = (): string | undefined => {
    const {sceneCommandForm} = this.props;
    if (sceneCommandForm.overrideCharacterName) {
      if (sceneCommandForm.characterName) {
        return sceneCommandForm.characterName;
      } else {
        return undefined;
      }
    }
    const characters = this.getCharacters();
    if (sceneCommandForm.getPositions().length > 1) {
      return undefined;
    }
    if (characters.length > 1) {
      return undefined;
    }
    return characters.map(character => character.name)[0];
  };

  private getCharacterNameLabelColor = (): NameLabelColor | undefined => {
    const {sceneCommandForm} = this.props;
    if (sceneCommandForm.overrideCharacterName) {
      return NameLabelColor.Black;
    }
    if (sceneCommandForm.getPositions().length > 1) {
      return undefined;
    }
    const characters = this.getCharacters();
    if (characters) {
      if (characters.length === 1) {
        const character = characters[0];
        if (character.nameLabelColor) {
          return character.nameLabelColor;
        }
        switch (character.option.gender) {
          case Gender.Male:
            return NameLabelColor.Blue;
          case Gender.Female:
            return NameLabelColor.Pink;
          case Gender.Other:
            return NameLabelColor.Green;
          default:
          // noop
        }
      } else {
        return NameLabelColor.Green;
      }
    } else {
      return undefined;
    }
  };

  private getCharacters = () => {
    const {sceneFrame, sceneCommandForm} = this.props;
    const left = sceneFrame.leftCharacter;
    const center = sceneFrame.centerCharacter;
    const right = sceneFrame.rightCharacter;
    const ret = [];
    if (
      left &&
      sceneFrame.isActive(Position.Left) &&
      sceneCommandForm.getPositions().includes(Position.Left)
    ) {
      ret.push(left.characterPattern.character);
    }
    if (
      center &&
      sceneFrame.isActive(Position.Center) &&
      sceneCommandForm.getPositions().includes(Position.Center)
    ) {
      ret.push(center.characterPattern.character);
    }
    if (
      right &&
      sceneFrame.isActive(Position.Right) &&
      sceneCommandForm.getPositions().includes(Position.Right)
    ) {
      ret.push(right.characterPattern.character);
    }
    return ret;
  };
}
