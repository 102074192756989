import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import BaseEffectMapper, {
  BaseEffectData,
} from '../../entities/mappers/BaseEffectMapper';

import BaseEffect from '../../../domain/entities/BaseEffect';
import BaseEffectsRepository from '../../../domain/repositories/writer/BaseEffectsRepository';

export default class NetBaseEffectsRepository
  extends NetResourcesRepository<BaseEffect, BaseEffectData>
  implements BaseEffectsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/base_effects',
        new BaseEffectMapper(),
      ),
    );
  }
}
