import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Edit, {
  DispatchProps,
  StateProps,
} from '../../../components/story_form/cover_image/Edit';

import AppState from '../../../reducers/AppState';

import showStory from '../../../actions/stories/show';
import updateStory, {
  Params as StoryUpdateParams,
} from '../../../actions/stories/update';
import createStoryForm, {
  Params as StoryFormCreateParams,
} from '../../../actions/story_forms/create';
import updateStoryForm, {
  Params as StoryFormUpdateParams,
} from '../../../actions/story_forms/update';
import createCoverImageForm, {
  Params as CoverImageFormCreateParams,
} from '../../../actions/cover_image_forms/create';
import updateCoverImageForm, {
  Params as CoverImageFormUpdateParams,
} from '../../../actions/cover_image_forms/update';
import showCurrentUser from '../../../actions/writer/current_user/show';

import selectEntity from '../../../helpers/selectEntity';

import {EditStoryFormCoverImageRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EditStoryFormCoverImageRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, forms, writerCurrentUserId} = state;
  const {storyId} = route.params ? route.params : {storyId: undefined};
  const storyForm = storyId
    ? forms.storyForm && forms.storyForm.id === Number(storyId)
      ? forms.storyForm
      : null
    : forms.storyForm;
  const coverImageForm =
    forms.coverImageForm?.type === 'coverImage' ? forms.coverImageForm : null;
  const story = storyId ? selectEntity(entities.stories, storyId) : null;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  return {
    navigation,
    story,
    storyForm,
    coverImageForm,
    currentUser,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showStory: (id: number) => dispatch(showStory(id)),
    updateStory: (id: number, params: StoryUpdateParams) =>
      dispatch(updateStory(id, params)),
    createStoryForm: (params: StoryFormCreateParams) =>
      dispatch(createStoryForm(params)),
    updateStoryForm: (params: StoryFormUpdateParams) =>
      dispatch(updateStoryForm(params)),
    createCoverImageForm: (params: CoverImageFormCreateParams) =>
      dispatch(createCoverImageForm(params)),
    updateCoverImageForm: (params: CoverImageFormUpdateParams) =>
      dispatch(updateCoverImageForm(params)),
    showCurrentUser: () => dispatch(showCurrentUser()),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
