import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import {useActionSheet} from '@expo/react-native-action-sheet';
import dateformat from 'dateformat';

import TooltipModal from '../../shared/modals/TooltipModal';
import ActionModal from '../../shared/modals/ActionModal';
import QuestionIcon from '../../shared/icons/QuestionIcon';
import MoreIcon from '../../shared/icons/ionicons/MoreIcon';

import ProjectIteration from '../../../../domain/entities/ProjectIteration';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  projectId: number;
  iteration: ProjectIteration;
  isLeader?: boolean;
  onForceUpdate: () => void;
}

const ManuscriptHeader: React.FC<Props> = props => {
  const {projectId, iteration, isLeader, onForceUpdate} = props;
  const linkTo = useLinkTo();
  const {showActionSheetWithOptions} = useActionSheet();
  const [visibleTooltip, setVisibleTooltip] = React.useState(false);
  const [visibleAction, setVisibleAction] = React.useState(false);
  const onPressQuestion = React.useCallback(() => {
    setVisibleTooltip(true);
  }, []);
  const onCloseModalTooltip = React.useCallback(() => {
    setVisibleTooltip(false);
  }, []);
  const onCloseModalAction = React.useCallback(() => {
    setVisibleAction(false);
  }, []);
  const onAccept = React.useCallback(() => {
    TapNovelTypedRestApi.post(`/api/writer/leader/project_iterations`, {
      projectId,
      force: true,
    })
      .then(onForceUpdate)
      .then(onCloseModalAction);
  }, []);
  const onOpenActionSheet = React.useCallback(() => {
    const options = [
      '過去のターム',
      ...(isLeader ? ['次のタームを開始する', '提出を打ち切る'] : []),
      'キャンセル',
    ];
    const showOldTermsIndex = 0;
    const startNextTermIndex = isLeader ? 1 : 101;
    const stopCurrentTermIndex = isLeader ? 2 : 102;
    const cancelButtonIndex = isLeader ? 3 : 1;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        options,
        destructiveButtonIndex: stopCurrentTermIndex,
      },
      async (buttonIndex?: number) => {
        switch (buttonIndex) {
          case showOldTermsIndex:
            linkTo(`/projects/${projectId}/iterations`);
            break;
          case startNextTermIndex:
            setVisibleAction(true);
            break;
          case stopCurrentTermIndex: {
            TapNovelTypedRestApi.patch(
              `/api/writer/leader/project_iterations/${iteration.id}`,
              {projectIteration: {completed: true}},
            ).then(onForceUpdate);
            break;
          }
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, [isLeader]);
  return (
    <>
      <View style={styles.container}>
        <View style={styles.left}>
          <Text style={styles.leftTitle}>
            第{iteration.number}ターム（
            {dateformat(iteration.createdAt, 'yyyy.mm.dd')} ～）
          </Text>
          <TouchableOpacity onPress={onPressQuestion}>
            <QuestionIcon />
          </TouchableOpacity>
        </View>
        <TouchableOpacity style={styles.right} onPress={onOpenActionSheet}>
          <View style={styles.more}>
            <MoreIcon />
          </View>
        </TouchableOpacity>
      </View>
      <TooltipModal
        visible={visibleTooltip}
        title={'ターム'}
        description={
          'プロジェクトは期間を区切りながら進みます。\n同一のタームでは、同一エピソードの提出は1回までとなります。\n別のエピソードは提出できます。\nタームの開始及び終了はリーダーが行います。'
        }
        onCloseModal={onCloseModalTooltip}
      />
      <ActionModal
        visible={visibleAction}
        title={'次のタームを開始する'}
        description={
          <>
            <Text
              style={{
                paddingBottom: 8,
                display: 'flex',
                justifyContent: 'center',
              }}>
              現在のタームを終了して次のタームを{'\n'}
              開始します。{'\n'}
            </Text>
            <Text>
              今回のタームに提出されている提出物への{'\n'}
              コメントはできなくなりますが{'\n'}
              よろしいですか？
            </Text>
          </>
        }
        descriptionStyle={{textAlign: 'center'}}
        buttonText={'終了'}
        onRequestClose={onCloseModalAction}
        onAccept={onAccept}
      />
    </>
  );
};

export default React.memo(ManuscriptHeader);

const styles = StyleSheet.create({
  container: {
    paddingVertical: 20,
    paddingHorizontal: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  } as ViewStyle,
  left: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  leftTitle: {
    color: '#ff8f13',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  right: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  more: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: 40,
    marginBottom: 56,
  } as TextStyle,
});
