import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import New, {DispatchProps, StateProps} from '../../components/contacts/New';

import AppState from '../../reducers/AppState';

import createWriterContact, {
  Params as WriterContactCreateParams,
} from '../../actions/writer/contacts/create';
import indexWriterContactCategories, {
  Params as WriterContactCategoryIndexParams,
} from '../../actions/writer/contact_categories/index';
import indexWriterHelps, {
  Params as WriterHelpIndexParams,
} from '../../actions/writer/helps/index';

import selectEntities from '../../helpers/selectEntities';
import selectEntity from '../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries, writerCurrentUserId} = state;
  const writerCurrentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const contactCategoriesParams: WriterContactCategoryIndexParams = {};
  const contactCategories = selectEntities(
    entities.writer.contactCategories,
    queries.writer.contactCategories,
    contactCategoriesParams,
  );
  const helpsParams: WriterHelpIndexParams = {primary: true};
  const helps = selectEntities(
    entities.writer.helps,
    queries.writer.helps,
    helpsParams,
  );
  return {
    writerCurrentUser,
    contactCategories,
    contactCategoriesParams,
    helps,
    helpsParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createWriterContact: (params: WriterContactCreateParams) =>
      dispatch(createWriterContact(params)),
    indexWriterContactCategories: (params: WriterContactCategoryIndexParams) =>
      dispatch(indexWriterContactCategories(params)),
    indexWriterHelps: (params: WriterHelpIndexParams) =>
      dispatch(indexWriterHelps(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
