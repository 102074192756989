import * as React from 'react';

import NavigationProp from '../../../navigators/NavigationProp';

import Form from './partials/Form';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import {
  isFulfilledImages,
  isFulfilledEpisode,
} from '../../../view_models/navigateStoryPublication';

import * as routers from '../../../routers';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import {Params as StoryUpdateParams} from '../../../actions/stories/update';
import {Params as StoryFormUpdateParams} from '../../../actions/story_forms/update';

import Story, {Rating, Format} from '../../../../domain/entities/Story';

import StoryForm from '../../../../domain/forms/StoryForm';

import {EditStoryFormDetailInfoRouteProp} from '../../../navigators/RouteProps';

export interface Params {
  storyId: number;
  episodeIdToBePublished?: number;
  forwardToStoryPublication?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditStoryFormDetailInfoRouteProp;
  story: Story | null;
  storyForm: StoryForm | null;
}

export interface DispatchProps {
  showStory: (id: number) => Promise<Story>;
  updateStory: (id: number, params: StoryUpdateParams) => Promise<Story>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class Edit extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public componentDidMount() {
    const {navigation, route, story, storyForm, showStory} = this.props;
    const {storyId} = route.params;
    if (!storyForm) {
      navigation.replace('EditStory', {storyId});
    }
    if (!story) {
      showStory(storyId);
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, story, storyForm} = this.props;
    const {loading, alertMessage} = this.state;
    const {episodeIdToBePublished} = route.params;
    return (
      <Layout
        title={'ストーリー情報'}
        navigation={navigation}
        back={true}
        close={true}
        hideHeaderBottomBorder={true}
        loading={loading}>
        {storyForm && (
          <Form
            storyForm={storyForm}
            submitButtonText={!episodeIdToBePublished ? '決定' : undefined}
            onChangeCatchPhrase={this.handleChangeCatchPhrase}
            onToggleSerialized={this.handleToggleSerialized}
            onForwardToKeywords={this.handleForwardToKeywords}
            onChangeFormat={this.handleChangeFormat}
            onChangeRating={this.handleChangeRating}
            onSubmit={this.handleSubmit}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleChangeCatchPhrase = (catchPhrase: string) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({catchPhrase});
  };

  private handleToggleSerialized = (serialized: boolean) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({serialized});
  };

  private handleForwardToKeywords = (keywordNames: string[] | null) => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    routers.linkToStoryFormKeywords(navigation, {storyId, keywordNames});
  };

  private handleChangeRating = (rating: Rating) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({rating});
  };

  private handleChangeFormat = (format: Format) => {
    const {updateStoryForm} = this.props;
    updateStoryForm({format});
  };

  private handleSubmit = () => {
    const {navigation, route, story, storyForm, updateStory} = this.props;
    const {episodeIdToBePublished} = route.params;
    const storyId = storyForm?.id;
    if (!story || !storyForm || !storyId) {
      return;
    }
    if (episodeIdToBePublished) {
      if (!isFulfilledImages(story)) {
        routers.linkToEditStoryFormCoverImage(navigation, {
          storyId,
          episodeIdToBePublished,
          back: true,
        });
        return;
      }
    }
    const params = storyForm.toParams();
    this.setState({loading: true}, () => {
      updateStory(storyId, params)
        .then(() => {
          this.setState({loading: false}, async () => {
            (navigation.getParent() || navigation).goBack();
            if (episodeIdToBePublished) {
              if (await isFulfilledEpisode(episodeIdToBePublished)) {
                routers.linkToEpisodeFormEditEpisodePublication(navigation, {
                  episodeId: episodeIdToBePublished,
                });
              } else {
                routers.linkToEditEpisode(navigation, {
                  episodeId: episodeIdToBePublished,
                  toBePublished: true,
                });
              }
            }
          });
        })
        .catch(error => {
          this.setState({
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };
}
