import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ProLink from '../../../shared/ProLink';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import CheckButton from '../../../shared/forms/CheckButton';

import Table from '../../../pro/home/partials/Table';

import Price from '../../../../../domain/entities/writer/my_stripe/maker_pro/Price';

import NetPriceRepository from '../../../../../data/repositories/writer/my_stripe/maker_pro/NetPriceRepository';

const BODY_ROWS: Array<{0: string; 1: string; 2: string}> = [
  ['動画の書き出し\n時間', '冒頭30Tap程度', 'エピソード全て'],
  ['書き出し可能な\nエピソード', '第1話のみ', '各エピソード'],
  ['作成できる\n動画の種類', 'タテ型のみ', 'タテ型・ヨコ型'],
  ['サウンド', '無', '有'],
  ['速度の指定', '不可', '可'],
];

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep4: React.FunctionComponent<Props> = props => {
  const {onPress} = props;

  const [price, setPrice] = React.useState<Price | null>(null);
  React.useEffect(() => {
    new NetPriceRepository().find().then(setPrice);
  }, []);

  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.scrollView}>
        <Text style={styles.message}>
          もっと動画の作成にこだわりたい方へ。{'\n'}
          MakerProなら、以下の機能をご利用できます。
        </Text>
        <Table
          bodyRows={BODY_ROWS}
          withoutNote={true}
          label0Style={{textAlign: 'left', paddingHorizontal: 8}}
          label1Style={{fontWeight: 'normal'}}
          label2Style={{fontWeight: 'normal'}}
        />
        <View style={styles.pro}>
          <Text style={styles.proText}>
            フル動画を作成したい場合は、{'\n'}
            <Text style={styles.proTextBold}>
              MakerPro {price ? price.amountIncludingTax : '-'} 円/月 (税込)
            </Text>
            をご検討ください。
          </Text>
          <ProLink />
        </View>
        <CheckButton
          style={styles.checkButton}
          checked={checked}
          onPress={onPressCheck}>
          理解した
        </CheckButton>
        <PrimaryButton disabled={!checked} onPress={onPress}>
          CM動画を作成する
        </PrimaryButton>
      </ScrollView>
    </View>
  );
};

export default React.memo(ServiceExplanationStep4);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
  } as ViewStyle,
  scrollView: {
    paddingVertical: 32,
    marginBottom: 32,
    alignItems: 'center',
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginHorizontal: 24,
  } as TextStyle,
  usageRange: {
    marginTop: 24,
    marginBottom: 16,
  } as ViewStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginBottom: 16,
  } as ViewStyle,
  pro: {
    marginTop: 8,
    marginBottom: 28,
    alignItems: 'center',
  } as ViewStyle,
  proText: {
    color: '#383838',
    fontSize: 12,
    textAlign: 'center',
  },
  proTextBold: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  },
  link: {
    flexDirection: 'row',
    marginVertical: 4,
    alignItems: 'center',
  } as ViewStyle,
  linkText: {
    color: '#ff8f13',
    fontSize: 12,
    fontWeight: 'bold',
    borderColor: '#ff8f13',
    borderBottomWidth: 1,
    marginRight: 4,
  } as TextStyle,
});
