import * as React from 'react';

import InvoiceList from './partials/InvoiceList';

import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';

import Invoice from '../../../../../domain/entities/writer/my_stripe/Invoice';

import NetInvoicesRepository from '../../../../../data/repositories/writer/my_stripe/NetInvoicesRepository';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation} = props;
  const [invoices, setInvoices] = React.useState<Array<Invoice> | null>(null);
  React.useEffect(() => {
    new NetInvoicesRepository().findAll().then(response => {
      setInvoices(response.records);
    });
  }, []);
  return (
    <Layout
      title={'お支払い履歴'}
      navigation={navigation}
      back={true}
      scrollable={false}>
      {invoices && <InvoiceList invoices={invoices} />}
    </Layout>
  );
};

export default React.memo(Index);
