import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/analysis/stories/Index';

import AppState from '../../../reducers/AppState';

import showSummaryAccountAggregation, {
  Option as SummaryAccountAggregationShowOption,
} from '../../../actions/summary/account_aggregations/show';
import indexSummaryStoryAggregations, {
  Params as SummaryStoryAggregationIndexParams,
} from '../../../actions/summary/story_aggregations/index';

import {AnalysisStoriesRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: AnalysisStoriesRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showSummaryAccountAggregation: (
      option: SummaryAccountAggregationShowOption,
    ) => dispatch(showSummaryAccountAggregation(option)),
    indexSummaryStoryAggregations: (
      params: SummaryStoryAggregationIndexParams,
    ) => dispatch(indexSummaryStoryAggregations(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
