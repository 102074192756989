import * as React from 'react';

import Form from './partials/Form';
import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {Params as WriterOauthAccountIndexParams} from '../../../actions/writer/oauth_accounts/index';

import OauthAccount from '../../../../domain/entities/OauthAccount';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  oauthAccounts: OauthAccount[] | null;
  writerOauthAccounsParams: WriterOauthAccountIndexParams;
}

export interface DispatchProps {
  indexWriterOauthAccounts: (
    params: WriterOauthAccountIndexParams,
  ) => Promise<PaginatedResult<OauthAccount>>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    oauthAccounts,
    writerOauthAccounsParams,
    indexWriterOauthAccounts,
  } = props;
  React.useEffect(() => {
    indexWriterOauthAccounts(writerOauthAccounsParams);
  }, []);
  const onRequestUpdate = React.useCallback(() => {
    indexWriterOauthAccounts(writerOauthAccounsParams);
  }, []);
  return (
    <Layout title={'動画サービス連携'} navigation={navigation} back={true}>
      {oauthAccounts && (
        <Form oauthAccounts={oauthAccounts} onRequestUpdate={onRequestUpdate} />
      )}
    </Layout>
  );
};

export default React.memo(Index);
