import NetResourcesRepository from '../../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../../NetResourcesRepositoryAdapter';

import ActorCostumeMapper, {
  ActorCostumeData,
} from '../../../entities/mappers/character_maker/ActorCostumeMapper';

import PaginatedResult from '../../../../domain/results/PaginatedResult';
import ActorCostume from '../../../../domain/entities/character_maker/ActorCostume';
import ActorCostumesRepository from '../../../../domain/repositories/writer/character_maker/ActorCostumesRepository';

export default class NetActorCostumesRepository
  extends NetResourcesRepository<ActorCostume, ActorCostumeData>
  implements ActorCostumesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/character_maker/actor_costumes',
        new ActorCostumeMapper(),
      ),
    );
  }

  public async findBy(params: {
    actorId: number;
  }): Promise<PaginatedResult<ActorCostume>> {
    return super.findBy(params);
  }
}
