import * as React from 'react';
import {camelizeKeys} from 'humps';

import Detail from './partials/Detail';

import Layout from '../../shared/Layout';
import TooltipModal from '../../shared/modals/TooltipModal';
import AlertModal from '../../shared/modals/AlertModal';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import NavigationProp from '../../../navigators/NavigationProp';
import {CommercialVideoStoryRouteProp} from '../../../navigators/RouteProps';

import Story from '../../../../domain/entities/writer/vertical_short_video/Story';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

import NetEpisodeVideoExportJobExecutionsRepository from '../../../../data/repositories/writer/NetEpisodeVideoExportJobExecutionsRepository';

export interface Params {
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CommercialVideoStoryRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FunctionComponent<Props> = props => {
  const {navigation, route} = props;
  const {storyId} = route.params;
  const [story, setStory] = React.useState<Story | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  React.useEffect(() => {
    TapNovelRestApi.get(
      `/api/writer/vertical_short_video/stories/${storyId}`,
    ).then(response => {
      setStory(camelizeKeys(response.body as Record<string, unknown>) as Story);
    });
  }, []);
  const onEpisodeVideoExport = React.useCallback(() => {
    if (!story) {
      return;
    }
    setLoading(true);
    new NetEpisodeVideoExportJobExecutionsRepository()
      .create({
        episodeVideoExportJobExecution: {
          episodeId: story.firstEpisodeId,
          orientation: 'vertical',
          commercial: true,
          autoPlaySpeed: 1.5,
        },
      })
      .then(() => {
        TapNovelRestApi.get(
          `/api/writer/vertical_short_video/stories/${storyId}`,
        ).then(response => {
          setStory(
            camelizeKeys(response.body as Record<string, unknown>) as Story,
          );
          setLoading(false);
          setVisibleModal(true);
        });
      })
      .catch(error => {
        setLoading(false);
        setAlertMessage(formatErrorMessages({}, error));
      });
  }, [story]);
  const onCloseModal = React.useCallback(() => {
    setVisibleModal(false);
  }, []);
  const onCloseAlertModal = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  return (
    <Layout
      title={'CM動画の作成'}
      navigation={navigation}
      back={true}
      loading={loading}>
      {story ? (
        <Detail story={story} onEpisodeVideoExport={onEpisodeVideoExport} />
      ) : null}
      <TooltipModal
        visible={visibleModal}
        title={'CM動画作成依頼を受け付けました'}
        description={
          'CM動画の作成には数分ほどかかります。\nいましばらくお待ちください。'
        }
        buttonText={'閉じる'}
        onCloseModal={onCloseModal}
      />
      {alertMessage && (
        <AlertModal visible={true} onCloseModal={onCloseAlertModal}>
          {alertMessage}
        </AlertModal>
      )}
    </Layout>
  );
};

export default React.memo(Show);
