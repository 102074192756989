import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories from '../../../../containers/scene_form/illustration_show_scene_command/new/illustration_usage_histories/Index';
import SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm from '../../../../containers/scene_form/illustration_show_scene_command/new/illustration_search_form/Index';
import SceneFormIllustrationShowSceneCommandNewIllustrations from '../../../../containers/scene_form/illustration_show_scene_command/new/illustrations/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories">
      <RootStack.Screen
        name="SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories"
        component={
          SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistories
        }
        options={{title: 'アイテム'}}
      />
      <RootStack.Screen
        name="SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm"
        component={
          SceneFormIllustrationShowSceneCommandNewIllustrationSearchForm
        }
        options={{title: 'アイテム検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormIllustrationShowSceneCommandNewIllustrations"
        component={SceneFormIllustrationShowSceneCommandNewIllustrations}
        options={{title: 'アイテム'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
