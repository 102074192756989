import * as React from 'react';

import BaseIndex from '../../../../../shared/generics/character_maker/home/Index';

import NavigationProp from '../../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterMakerHomeRouteProp} from '../../../../../../navigators/RouteProps';

import {Params as CharacterMakerActorGroupIndexParams} from '../../../../../../actions/character_maker_actor_groups/index';

import redirectSceneForm from '../../../../../../helpers/redirectSceneForm';

import CharacterMakerActorGroup from '../../../../../../../domain/entities/CharacterMakerActorGroup';
import PaginatedResult from '../../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../../domain/forms/SceneForm';
import CurrentUser from '../../../../../../../domain/entities/writer/CurrentUser';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterMakerHomeRouteProp;
  currentUser: CurrentUser | null;
  sceneForm: SceneForm | null;
  characterMakerActorGroups: CharacterMakerActorGroup[] | null;
  characterMakerActorGroupsParams: CharacterMakerActorGroupIndexParams;
}

export interface DispatchProps {
  indexCharacterMakerActorGroups: (
    params: CharacterMakerActorGroupIndexParams,
  ) => Promise<PaginatedResult<CharacterMakerActorGroup>>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, route, sceneForm, currentUser} = props;
  const {params} = route;
  React.useEffect(() => {
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }, []);
  const onSelectCharacterMakerActorGroup = React.useCallback(
    (
      characterMakerActorGroup: CharacterMakerActorGroup,
      skinColorPatternId: number,
    ) => {
      navigation.navigate(
        'SceneFormCharacterShowSceneCommandNewCharacterMakerActors',
        {
          ...params,
          characterMakerActorGroupId: characterMakerActorGroup.id,
          skinColorPatternId,
        },
      );
    },
    [],
  );
  return (
    <BaseIndex
      {...props}
      enableSkinColorPattern={currentUser?.isBlackOrSubscriber()}
      onSelectCharacterMakerActorGroup={onSelectCharacterMakerActorGroup}
    />
  );
};

export default React.memo(Index);
