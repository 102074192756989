import * as React from 'react';
import {EventArg} from '@react-navigation/native';

import Form from './partials/Form';
import Modal, {ModalParams} from './partials/Modal';
import UnupdateConfirmModal from './partials/UnupdateConfirmModal';
import RestoreModal from './partials/RestoreModal';

import Layout from '../shared/Layout';
import AlertModal from '../shared/modals/AlertModal';
import shouldUpdateEntityList from '../shared/enhanced/shouldUpdateEntityList';

import {equalForKeys} from '../../helpers/equalForKeys';

import NavigationProp from '../../navigators/NavigationProp';
import {EditSceneRouteProp} from '../../navigators/RouteProps';

import * as routers from '../../routers';

import {formatErrorMessages} from '../../helpers/errorMessages';

import CharacterPatternMapping from '../../view_models/CharacterPatternMapping';
import CoachmarkState from '../../view_models/CoachmarkState';
import {visibleRestoreModal} from '../../view_models/controlFlags';

import {Params as ActorCharacterFaceIndexParams} from '../../actions/actor_character_faces/index';
import {Params as MarkIndexParams} from '../../actions/marks/index';
import {Params as SpeechBalloonIndexParams} from '../../actions/speech_balloons/index';
import {Params as OrientedSpeechBalloonIndexParams} from '../../actions/oriented_speech_balloons/index';
import {Params as SceneCommandFormListsCreateParams} from '../../actions/scene_command_form_lists/create';
import {Params as SceneCommandFormListsUpdateParams} from '../../actions/scene_command_form_lists/update';
import {Params as SceneCommandFormCreateParams} from '../../actions/scene_command_forms/create';
import {Params as SceneCommandFormDestroyParams} from '../../actions/scene_command_forms/destroy';
import {Params as SceneCommandFormUpdateParams} from '../../actions/scene_command_forms/update';
import {Params as SceneFormUpdateParams} from '../../actions/scene_forms/update';
import {Params as SceneScriptCreateParams} from '../../actions/scene_scripts/create';
import {Params as SceneUpdateParams} from '../../actions/scenes/update';
import {Params as FullScreenIllustrationIndexParams} from '../../actions/full_screen_illustrations/index';
import {Params as TextFrameIndexParams} from '../../actions/text_frames/index';
import {Params as CharacterPatternIndexParams} from '../../actions/character_patterns/index';
import {Params as CharacterPatternCreateParams} from '../../actions/character_patterns/create';
import {Params as ActorCostumeIndexParams} from '../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../actions/actor_hair_styles/index';
import {Params as ActorAccessorySetIndexParams} from '../../actions/actor_accessory_sets/index';
import {Params as VoiceCreateParams} from '../../actions/voices/create';
import {Params as VoiceUpdateParams} from '../../actions/voices/update';
import {Params as CoachmarkModalUpdateParams} from '../../actions/coachmark_modal/update';
import {Params as ApplicationActionModalUpdateParams} from '../../actions/application_action_modal/update';

import {QueryState} from '../../reducers/queries/Response';

import {CharacterSceneCommandForm} from '../../view_models/SceneFrame';

import CurrentUser from '../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';
import ActorCharacterFace from '../../../domain/entities/ActorCharacterFace';
import Mark from '../../../domain/entities/Mark';
import SpeechBalloon from '../../../domain/entities/SpeechBalloon';
import OrientedSpeechBalloon from '../../../domain/entities/OrientedSpeechBalloon';
import TextFrame from '../../../domain/entities/TextFrame';
import FullScreenIllustration from '../../../domain/entities/FullScreenIllustration';
import Episode from '../../../domain/entities/Episode';
import Scene from '../../../domain/entities/Scene';
import CharacterPattern from '../../../domain/entities/CharacterPattern';
import ActorCostume from '../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../domain/entities/ActorHairStyle';
import ActorAccessorySet from '../../../domain/entities/ActorAccessorySet';
import Voice from '../../../domain/entities/Voice';
import Sound from '../../../domain/entities/Sound';
import SceneWithCommandsWrapper from '../../../domain/entities/SceneWithCommandsWrapper';
import PaginatedResult from '../../../domain/results/PaginatedResult';
import SceneCommandForm from '../../../domain/forms/scene_commands/SceneCommandForm';
import SceneForm from '../../../domain/forms/SceneForm';
import SceneCommandFormsBuilder from '../../../domain/services/scene_commands/SceneCommandFormsBuilder';
import SceneCommandFormsPositionSwitcher from '../../../domain/services/scene_commands/SceneCommandFormsPositionSwitcher';
import SceneCommandParamsBuilder from '../../../domain/services/scene_commands/SceneCommandParamsBuilder';
import CharacterHideSceneCommandForm from '../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import Position from '../../../domain/value_objects/Position';

import StorageAutoSaveUpdatingScenesRepository from '../../../data/repositories/StorageAutoSaveUpdatingScenesRepository';

const autoSaveUpdatingScenesRepository =
  new StorageAutoSaveUpdatingScenesRepository();

export interface Params {
  sceneId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditSceneRouteProp;
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
  actorCharacterFaceEntities: {[key: number]: ActorCharacterFace};
  actorCharacterFaceQueries: QueryState;
  marksParams: MarkIndexParams;
  marks: Mark[] | null;
  sceneWithCommandsWrapper: SceneWithCommandsWrapper | null;
  sceneForm: SceneForm | null;
  sceneCommandForms: SceneCommandForm[] | null;
  speechBalloons: SpeechBalloon[] | null;
  speechBalloonsParams: SpeechBalloonIndexParams;
  orientedSpeechBalloons: OrientedSpeechBalloon[] | null;
  orientedSpeechBalloonsParams: OrientedSpeechBalloonIndexParams;
  textFrames: TextFrame[] | null;
  textFramesParams: TextFrameIndexParams;
  fullScreenIllustrations: FullScreenIllustration[] | null;
  fullScreenIllustrationsParams: FullScreenIllustrationIndexParams;
}

export interface DispatchProps {
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  indexMarks: (params: MarkIndexParams) => Promise<PaginatedResult<Mark>>;
  showSceneWithCommandsWrapper: (
    id: number,
  ) => Promise<SceneWithCommandsWrapper>;
  showEpisode: (id: number) => Promise<Episode>;
  updateScene: (id: number, params: SceneUpdateParams) => Promise<Scene>;
  updateSceneForm: (params: SceneFormUpdateParams) => Promise<SceneForm>;
  destroySceneForm: () => Promise<null>;
  createSceneCommandFormLists: (
    params: SceneCommandFormListsCreateParams,
  ) => Promise<SceneCommandForm[]>;
  updateSceneCommandFormLists: (
    params: SceneCommandFormListsUpdateParams,
  ) => Promise<SceneCommandForm[]>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  destroySceneCommandForm: (
    params: SceneCommandFormDestroyParams,
  ) => Promise<any>;
  createSceneScript: (params: SceneScriptCreateParams) => Promise<any>;
  indexFullScreenIllustrations: (
    params: FullScreenIllustrationIndexParams,
  ) => Promise<PaginatedResult<FullScreenIllustration>>;
  indexSpeechBalloons: (
    params: SpeechBalloonIndexParams,
  ) => Promise<PaginatedResult<SpeechBalloon>>;
  indexOrientedSpeechBalloons: (
    params: OrientedSpeechBalloonIndexParams,
  ) => Promise<PaginatedResult<OrientedSpeechBalloon>>;
  indexTextFrames: (
    params: TextFrameIndexParams,
  ) => Promise<PaginatedResult<TextFrame>>;
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  createCharacterPattern: (
    params: CharacterPatternCreateParams,
  ) => Promise<CharacterPattern>;
  updateCharacterPatternUsageHistory: (id: number) => Promise<CharacterPattern>;
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  createVoice: (params: VoiceCreateParams) => Promise<Voice>;
  updateVoice: (id: number, params: VoiceUpdateParams) => Promise<Voice>;
  updateCoachmarkModal: (params: CoachmarkModalUpdateParams) => void;
  updateApplicationActionModal: (
    params: ApplicationActionModalUpdateParams,
  ) => void;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  disabledSubmitButton: boolean;
  scene: Scene | null;
  sceneForm: SceneForm | null;
  sceneCommandForms: SceneCommandForm[] | null;
  modalParams: ModalParams | null;
  alertMessage?: string;
  visibleRestoredModal?: boolean;
  beforeBackCallback: (() => void) | null;
}

export default class Edit extends React.Component<Props, State> {
  private formRef = React.createRef<Form>();

  private mounted = false;

  private editing = true;

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      disabledSubmitButton: true,
      scene: null,
      sceneCommandForms: null,
      sceneForm: null,
      modalParams: null,
      beforeBackCallback: null,
    };
  }

  public static getDerivedStateFromProps(
    nextProps: Readonly<Props>,
    prevState: State,
  ): Partial<State> | null {
    if (
      !prevState.disabledSubmitButton &&
      !nextProps.sceneForm?.background &&
      (nextProps.sceneCommandForms?.length || 0) === 0
    ) {
      return {disabledSubmitButton: true};
    } else if (
      prevState.disabledSubmitButton &&
      (nextProps.sceneForm?.background ||
        (nextProps.sceneCommandForms?.length || 0) > 0)
    ) {
      return {disabledSubmitButton: false};
    }
    return null;
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
  ): boolean {
    if (
      !equalForKeys(this.props, nextProps, [
        'sceneForm',
        'sceneCommandForms',
        'currentUserStatus',
      ])
    ) {
      return true;
    }
    if (!equalForKeys(this.state, nextState)) {
      return true;
    }
    if (nextState.modalParams) {
      if (
        !equalForKeys(this.props, nextProps, [
          'actorCharacterFaceEntities',
          'actorCharacterFaceQueries',
          'marksParams',
          'speechBalloonsParams',
          'orientedSpeechBalloonsParams',
          'textFramesParams',
        ])
      ) {
        return true;
      }
      if (
        shouldUpdateEntityList(
          {
            entities: this.props.marks,
          },
          {
            entities: nextProps.marks,
          },
        )
      ) {
        return true;
      }
      if (
        shouldUpdateEntityList(
          {
            entities: this.props.speechBalloons,
          },
          {
            entities: nextProps.speechBalloons,
          },
        )
      ) {
        return true;
      }
      if (
        shouldUpdateEntityList(
          {
            entities: this.props.orientedSpeechBalloons,
          },
          {
            entities: nextProps.orientedSpeechBalloons,
          },
        )
      ) {
        return true;
      }
      if (
        shouldUpdateEntityList(
          {
            entities: this.props.textFrames,
          },
          {
            entities: nextProps.textFrames,
          },
        )
      ) {
        return true;
      }
    }
    return false;
  }

  public componentDidMount() {
    this.mounted = true;
    const {route, showCurrentUserStatus} = this.props;
    this.addListenerBeforeRemove();
    showCurrentUserStatus();
    this.buildSceneFormFromClient();
  }

  public componentDidUpdate(
    prevProps: Props,
    prevState: State,
    prevContext: any,
  ) {
    const {route, sceneForm, sceneCommandForms} = this.props;
    const {sceneId} = route.params;
    if (
      sceneForm &&
      sceneCommandForms &&
      this.state.sceneForm &&
      this.state.sceneCommandForms
    ) {
      if (this.changedForm()) {
        autoSaveUpdatingScenesRepository.update(sceneId, {
          sceneForm,
          sceneCommandForms,
          updatedAt: new Date(),
        });
      } else {
        autoSaveUpdatingScenesRepository.destroy(sceneId);
      }
    }
    if (!this.props.sceneCommandForms || !prevProps.sceneCommandForms) {
      return;
    }
    const currentSceneCommandIds = this.props.sceneCommandForms.map(s => {
      return s.sceneCommandId;
    });
    const prevSceneCommandIds = prevProps.sceneCommandForms.map(s => {
      return s.sceneCommandId;
    });
    const ret = prevSceneCommandIds.every((prevSceneCommandId, index) => {
      return prevSceneCommandId === currentSceneCommandIds[index];
    });
    if (!ret) {
      return;
    }
    const currentLastSceneCommandForm =
      this.props.sceneCommandForms[this.props.sceneCommandForms.length - 1];
    const prevLastSceneCommandForm =
      prevProps.sceneCommandForms[prevProps.sceneCommandForms.length - 1];
    if (
      currentLastSceneCommandForm instanceof
        CompositeSequenceSceneCommandForm &&
      (!(
        prevLastSceneCommandForm instanceof CompositeSequenceSceneCommandForm
      ) ||
        (prevLastSceneCommandForm instanceof
          CompositeSequenceSceneCommandForm &&
          prevLastSceneCommandForm.commandForms.length <
            currentLastSceneCommandForm.commandForms.length))
    ) {
      //
    } else if (currentSceneCommandIds.length <= prevSceneCommandIds.length) {
      return;
    }
    if (this.formRef.current) {
      this.formRef.current.scrollToEnd();
    }
  }

  public componentWillUnmount(): void {
    this.mounted = false;
    const {navigation, route, updateApplicationActionModal} = this.props;
    if (this.editing && this.changedForm()) {
      updateApplicationActionModal({
        action: {
          message:
            '編集中に画面を遷移したためシーンが\n保存されていません。\n編集画面へ戻りますか？',
          callback: () => {
            visibleRestoreModal.value = false;
            navigation.navigate('EditScene', route.params);
          },
        },
      });
    }
  }

  public render(): React.ReactNode {
    const {
      navigation,
      currentUser,
      currentUserStatus,
      sceneForm,
      sceneCommandForms,
      actorCharacterFaceEntities,
      actorCharacterFaceQueries,
      marks,
      speechBalloons,
      orientedSpeechBalloons,
      textFrames,
    } = this.props;
    const {
      loading,
      scene,
      modalParams,
      disabledSubmitButton,
      alertMessage,
      visibleRestoredModal,
    } = this.state;
    return (
      <Layout
        title={`シーン${scene ? scene.numberOfEpisode : ''}の編集`}
        navigation={navigation}
        scrollable={false}
        back={true}
        loading={loading}
        alwaysEnableBackButton={true}>
        {this.state.sceneForm &&
          this.state.sceneCommandForms &&
          sceneForm &&
          sceneCommandForms && (
            <Form
              ref={this.formRef}
              sceneForm={sceneForm}
              sceneCommandForms={sceneCommandForms}
              newScene={false}
              enableFullScreenIllustration={currentUserStatus?.extensionsCodes.includes(
                'full_screen_illustration',
              )}
              enableSoundEffect={currentUserStatus?.extensionsCodes.includes(
                'sound_effect',
              )}
              enableBackgroundMusic={currentUserStatus?.extensionsCodes.includes(
                'background_music',
              )}
              disabledSubmitButton={disabledSubmitButton}
              onForwardToBackgrounds={this.handleForwardToBackgrounds}
              onForwardToBackgroundFilters={
                this.handleForwardToBackgroundFilters
              }
              onForwardToBackgroundEffects={
                this.handleForwardToBackgroundEffects
              }
              onForwardToCharacters={this.handleForwardToCharacters}
              onForwardToSpeechBalloons={this.handleForwardToSpeechBalloons}
              onForwardToTextFrames={this.handleForwardToTextFrames}
              onForwardToIllustrations={this.handleForwardToIllustrations}
              onForwardToFullScreenIllustrations={
                this.handleForwardToFullScreenIllustrations
              }
              onForwardToEffects={this.handleForwardToEffects}
              onForwardToSoundEffects={this.handleForwardToSoundEffects}
              onForwardToBackgroundMusic={this.handleForwardToBackgroundMusic}
              onChangeOrder={this.handleChangeOrder}
              onRequestOpenModal={this.handleRequestOpenModal}
              onRequestUpdateCoachmarkModal={
                this.handleRequestUpdateCoachmarkModal
              }
              onPreview={this.handlePreview}
              onSubmit={this.handleSubmit}
            />
          )}
        <UnupdateConfirmModal
          visible={this.state.beforeBackCallback != null}
          onLeave={this.handleLeave}
          onCancel={this.handleCancel}
        />
        {modalParams && sceneForm?.storyId && (
          <Modal
            storyId={sceneForm.storyId}
            modalParams={modalParams}
            actorCharacterFaceEntities={actorCharacterFaceEntities}
            actorCharacterFaceQueries={actorCharacterFaceQueries}
            marks={marks}
            speechBalloons={speechBalloons}
            orientedSpeechBalloons={orientedSpeechBalloons}
            textFrames={textFrames}
            enableVoice={currentUserStatus?.extensionsCodes.includes('voice')}
            enableSoundEffect={currentUserStatus?.extensionsCodes.includes(
              'sound_effect',
            )}
            enableTrimming={currentUser?.enabledPaidSubscriber}
            onRequestCloseModal={this.handleRequestCloseModal}
            onForwardToCharacters={this.handleForwardToCharacters}
            onChangeCharacterShowOrUpdateCommand={
              this.handleChangeCharacterShowOrUpdateCommand
            }
            onInsertCharacterUpdateCommandAt={
              this.handleInsertCharacterUpdateCommandAt
            }
            onRemoveCharacterShowOrUpdateCommand={
              this.handleRemoveCharacterShowOrUpdateCommand
            }
            onInsertCharacterHideCommandAt={
              this.handleInsertCharacterHideCommandAt
            }
            onRemoveCharacterHideCommand={this.handleRemoveCharacterHideCommand}
            onChangeSpeechTextShowSceneCommandForm={
              this.handleChangeSpeechTextShowSceneCommandForm
            }
            onRemoveSpeechTextShowCommand={
              this.handleRemoveSpeechTextShowCommand
            }
            onChangeDescriptiveTextShowSceneCommandForm={
              this.handleChangeDescriptiveTextShowSceneCommandForm
            }
            onRemoveDescriptiveTextShowCommand={
              this.handleRemoveDescriptiveTextShowCommand
            }
            onForwardToChangeIllustrations={
              this.handleForwardToChangeIllustrations
            }
            onRemoveIllustrationShowCommand={
              this.handleRemoveIllustrationShowCommand
            }
            onChangeIllustrationShowSceneCommandForm={
              this.handleChangeIllustrationShowSceneCommandForm
            }
            onForwardToAddSoundEffects={this.handleForwardToAddSoundEffects}
            onForwardToChangeFullScreenIllustrations={
              this.handleForwardToChangeFullScreenIllustrations
            }
            onRemoveFullScreenIllustrationShowCommand={
              this.handleRemoveFullScreenIllustrationShowCommand
            }
            onChangeFullScreenIllustrationShowSceneCommandForm={
              this.handleChangeFullScreenIllustrationShowSceneCommandForm
            }
            onForwardToChangeEffects={this.handleForwardToChangeEffects}
            onChangeEffectShowSceneCommandForm={
              this.handleChangeEffectShowSceneCommandForm
            }
            onRemoveEffectShowCommand={this.handleRemoveEffectShowCommand}
            onForwardToChangeSoundEffects={
              this.handleForwardToChangeSoundEffects
            }
            onRemoveSoundEffectShowCommand={
              this.handleRemoveSoundEffectShowCommand
            }
            onForwardToBackgroundMusic={this.handleForwardToBackgroundMusic}
            onForwardToChangeBackgroundMusic={
              this.handleForwardToChangeBackgroundMusic
            }
            onRemoveBackgroundMusicShowCommand={
              this.handleRemoveBackgroundMusicShowCommand
            }
            onInsertBackgroundMusicHideCommandAt={
              this.handleInsertBackgroundMusicHideCommandAt
            }
            onChangeCommand={this.handleChangeCommand}
            onRemoveCommand={this.handleRemoveCommand}
            onRequestIndexActorCharacterFaces={
              this.handleRequestIndexActorCharacterFaces
            }
            onRequestIndexMarks={this.handleRequestIndexMarks}
            onRequestIndexSpeechBalloons={this.handleRequestIndexSpeechBalloons}
            onRequestIndexOrientedSpeechBalloons={
              this.handleRequestIndexOrientedSpeechBalloons
            }
            onRequestIndexTextFrames={this.handleRequestIndexTextFrames}
            onRequestCreateCharacterPattern={
              this.handleRequestCreateCharacterPattern
            }
            onRequestIndexActorCostumes={this.handleRequestIndexActorCostumes}
            onRequestIndexActorHairStyles={
              this.handleRequestIndexActorHairStyles
            }
            onRequestIndexActorAccessorySets={
              this.handleRequestIndexActorAccessorySets
            }
            onRequestUpdateModal={this.handleRequestUpdateModal}
          />
        )}
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={this.handleCloseModal}>
          {alertMessage}
        </AlertModal>
        <RestoreModal
          visible={!!visibleRestoredModal}
          mode={'updating'}
          onRequestClose={this.handleCloseRestoredModal}
        />
      </Layout>
    );
  }

  private buildSceneFormFromServer = (
    sceneWithCommandsWrapper: SceneWithCommandsWrapper,
  ) => {
    const {updateSceneForm, createSceneCommandFormLists} = this.props;
    const scene = sceneWithCommandsWrapper.scene;
    if (!this.mounted) {
      return;
    }
    updateSceneForm(scene).then(sceneForm => {
      this.setStateIfMounted({sceneForm, scene});
    });
    const sceneCommands = sceneWithCommandsWrapper.sceneCommands;
    createSceneCommandFormLists({
      sceneCommandForms: new SceneCommandFormsBuilder().build(sceneCommands),
    }).then(sceneCommandForms => {
      this.setStateIfMounted({sceneCommandForms});
    });
  };

  private buildSceneFormFromClient = () => {
    const {
      navigation,
      route,
      showSceneWithCommandsWrapper,
      updateSceneForm,
      createSceneCommandFormLists,
    } = this.props;
    const {sceneId} = route.params;
    showSceneWithCommandsWrapper(sceneId)
      .then(async sceneWithCommandsWrapper => {
        try {
          const autoSaveUpdatingScene =
            await autoSaveUpdatingScenesRepository.find(sceneId);
          const {scene} = sceneWithCommandsWrapper;
          if (
            scene.updatedAt.getTime() <
            autoSaveUpdatingScene.updatedAt.getTime()
          ) {
            if (!this.mounted) {
              return;
            }
            updateSceneForm(autoSaveUpdatingScene.sceneForm).then(() => {
              const sceneForm = new SceneForm(scene.storyId, scene.episodeId);
              sceneForm.bind(scene);
              this.setStateIfMounted({sceneForm, scene});
            });
            createSceneCommandFormLists({
              sceneCommandForms: autoSaveUpdatingScene.sceneCommandForms,
            }).then(sceneCommandForms => {
              this.setStateIfMounted({
                sceneCommandForms: new SceneCommandFormsBuilder().build(
                  sceneWithCommandsWrapper.sceneCommands,
                ),
                visibleRestoredModal: visibleRestoreModal.value && true,
              });
              visibleRestoreModal.value = true;
            });
          } else {
            autoSaveUpdatingScenesRepository.destroy(sceneId);
            this.buildSceneFormFromServer(sceneWithCommandsWrapper);
          }
        } catch (e) {
          this.buildSceneFormFromServer(sceneWithCommandsWrapper);
        }
      })
      .catch(e => {
        if (e.status === 401 || e.status === 404) {
          navigation.goBack();
        }
      });
  };

  private addListenerBeforeRemove = () => {
    const {navigation} = this.props;
    navigation.addListener('beforeRemove', this.beforeRemoveCallback);
  };

  private removeListenerBeforeRemove = () => {
    const {navigation} = this.props;
    navigation.removeListener('beforeRemove', this.beforeRemoveCallback);
  };

  private beforeRemoveCallback = (
    e: EventArg<
      'beforeRemove',
      true,
      {
        action: Readonly<{
          type: string;
          payload?: any;
          source?: string | undefined;
          target?: string | undefined;
        }>;
      }
    >,
  ) => {
    if (e.data.action.type === 'RESET') {
      e.preventDefault();
      return;
    }
    const {navigation} = this.props;
    if (!navigation.isFocused()) {
      return;
    }
    const callback = () => navigation.dispatch(e.data.action);
    const {sceneForm, sceneCommandForms} = this.props;
    if (!sceneForm || !sceneCommandForms) {
      return;
    }
    if (this.changedForm()) {
      e.preventDefault();
      this.setState({beforeBackCallback: callback});
    } else {
      this.clearOldScene();
    }
  };

  private changedForm = () => {
    const {sceneForm, sceneCommandForms} = this.props;
    const {
      sceneForm: stateSceneForm,
      sceneCommandForms: stateSceneCommandForms,
    } = this.state;
    if (
      !sceneForm ||
      !sceneCommandForms ||
      !stateSceneForm ||
      !stateSceneCommandForms
    ) {
      return false;
    }
    return (
      sceneCommandForms.length !== stateSceneCommandForms.length ||
      JSON.stringify(sceneForm.toParams()) !==
        JSON.stringify(stateSceneForm.toParams()) ||
      JSON.stringify(
        sceneCommandForms.map(sceneCommandForm => sceneCommandForm.toParams()),
      ) !==
        JSON.stringify(
          stateSceneCommandForms.map(sceneCommandForm =>
            sceneCommandForm.toParams(),
          ),
        )
    );
  };

  private handleForwardToBackgrounds = () => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    routers.linkToSceneFormBackgroundShowSceneCommandNewNavigations(
      navigation,
      {storyId, sceneId},
    );
  };

  private handleForwardToBackgroundFilters = () => {
    const {navigation, route} = this.props;
    const {sceneId} = route.params;
    routers.linkToSceneFormBackgroundShowSceneCommandNewBackgroundFiltersNavigations(
      navigation,
      {sceneId},
    );
  };

  private handleForwardToBackgroundEffects = () => {
    const {navigation, route} = this.props;
    const {sceneId} = route.params;
    routers.linkToSceneFormBackgroundShowSceneCommandNewBackgroundEffectsNavigations(
      navigation,
      {sceneId},
    );
  };

  private handleForwardToCharacters = (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    // tslint:disable-next-line:max-line-length
    routers.linkToSceneFormCharacterShowSceneCommandNewNavigations(navigation, {
      storyId,
      sceneId,
      ...params,
    });
  };

  private handleChangeCharacterShowOrUpdateCommand = (
    sceneCommandForm: CharacterSceneCommandForm,
    characterPatternMapping?: CharacterPatternMapping,
  ) => {
    const {updateSceneCommandForm} = this.props;
    updateSceneCommandForm({sceneCommandForm, characterPatternMapping});
  };

  private handleInsertCharacterUpdateCommandAt = (
    sceneCommandForm: CharacterSceneCommandForm,
    sceneCommandIndex: number,
    characterPatternMapping?: CharacterPatternMapping,
  ) => {
    const {createSceneCommandForm} = this.props;
    createSceneCommandForm({
      index: sceneCommandIndex,
      sceneCommandForm,
      characterPatternMapping,
    });
  };

  private handleRemoveCharacterShowOrUpdateCommand = (
    sceneCommandForm: CharacterSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleInsertCharacterHideCommandAt = (
    sceneCommandForm: CharacterSceneCommandForm,
    sceneCommandIndex: number,
  ) => {
    const {createSceneCommandForm} = this.props;
    createSceneCommandForm({
      index: sceneCommandIndex,
      sceneCommandForm: new CharacterHideSceneCommandForm(
        sceneCommandForm.characterPattern,
        sceneCommandForm.actorCharacterFace,
        sceneCommandForm.mark,
        sceneCommandForm.position,
      ),
    });
  };

  private handleRemoveCharacterHideCommand = (
    sceneCommandForm: CharacterHideSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleForwardToSpeechBalloons = (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
    positions?: Position[];
  }) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    const storyId = sceneForm?.storyId;
    if (!storyId) {
      return;
    }
    const {positions} = params;
    routers.linkToSceneFormSpeechTextShowSceneCommandNewNavigations(
      navigation,
      {
        storyId,
        sceneId,
        lazyload: !!positions,
        ...params,
      },
    );
    if (positions) {
      setTimeout(() => {
        routers.linkToSceneFormSpeechTextShowSceneCommandNewSpeechTexts(
          navigation,
          {
            storyId,
            sceneId,
            ...params,
            positions,
            speechBalloonId: 1,
          },
        );
      }, 10);
    }
  };

  private handleChangeSpeechTextShowSceneCommandForm = (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
  ) => {
    const {updateSceneCommandForm} = this.props;
    updateSceneCommandForm({sceneCommandForm});
  };

  private handleRemoveSpeechTextShowCommand = (
    sceneCommandForm: SpeechTextShowSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleForwardToTextFrames = (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    const storyId = sceneForm?.storyId;
    if (!storyId) {
      return;
    }
    routers.linkToSceneFormDescriptiveTextShowSceneCommandNewNavigations(
      navigation,
      {
        storyId,
        sceneId,
        ...params,
      },
    );
  };

  private handleChangeDescriptiveTextShowSceneCommandForm = (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
  ) => {
    const {updateSceneCommandForm} = this.props;
    updateSceneCommandForm({sceneCommandForm});
  };

  private handleRemoveDescriptiveTextShowCommand = (
    sceneCommandForm: DescriptiveTextShowSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleForwardToIllustrations = (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    routers.linkToSceneFormIllustrationShowSceneCommandNewNavigations(
      navigation,
      {
        storyId,
        sceneId,
        ...params,
      },
    );
  };

  private handleForwardToChangeIllustrations = (
    sceneCommandForm: IllustrationShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    const sceneCommandId = sceneCommandForm.sceneCommandId;
    routers.linkToSceneFormIllustrationShowSceneCommandEditNavigations(
      navigation,
      {
        storyId,
        sceneId,
        sceneCommandId,
        parentSceneCommandId,
      },
    );
  };

  private handleRemoveIllustrationShowCommand = (
    sceneCommandForm: IllustrationShowSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleChangeIllustrationShowSceneCommandForm = (
    sceneCommandForm: IllustrationShowSceneCommandForm,
  ) => {
    const {updateSceneCommandForm} = this.props;
    updateSceneCommandForm({sceneCommandForm});
  };

  private handleForwardToAddSoundEffects = (
    sceneCommandForm:
      | FullScreenIllustrationShowSceneCommandForm
      | IllustrationShowSceneCommandForm
      | EffectShowSceneCommandForm
      | SpeechTextShowSceneCommandForm
      | DescriptiveTextShowSceneCommandForm,
    parentSceneCommandId?: number,
    callback?: (sound: Sound) => void,
  ) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    routers.linkToSceneFormSoundEffectShowSceneCommandEditNavigations(
      navigation,
      {
        storyId,
        sceneId,
        sceneCommandId: sceneCommandForm.sceneCommandId,
        parentSceneCommandId,
        callback,
      },
    );
  };

  private handleForwardToFullScreenIllustrations = (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    routers.linkToSceneFormFullScreenIllustrationShowSceneCommandNewNavigations(
      navigation,
      {
        storyId,
        sceneId,
        ...params,
      },
    );
  };

  private handleForwardToEffects = (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    routers.linkToSceneFormEffectShowSceneCommandNewNavigations(navigation, {
      storyId,
      sceneId,
      ...params,
    });
  };

  private handleForwardToSoundEffects = (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    routers.linkToSceneFormSoundEffectShowSceneCommandNewNavigations(
      navigation,
      {
        storyId,
        sceneId,
        ...params,
      },
    );
  };

  private handleForwardToBackgroundMusic = (params: {
    sceneCommandIndex?: number;
    subSceneCommandIndex?: number;
    parentSceneCommandId?: number;
  }) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    routers.linkToSceneFormBackgroundMusicShowSceneCommandNewNavigations(
      navigation,
      {
        storyId,
        sceneId,
        ...params,
      },
    );
  };

  private handleForwardToChangeFullScreenIllustrations = (
    sceneCommandForm: FullScreenIllustrationShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    const sceneCommandId = sceneCommandForm.sceneCommandId;
    routers.linkToSceneFormFullScreenIllustrationShowSceneCommandEditNavigations(
      navigation,
      {
        storyId,
        sceneId,
        sceneCommandId,
        parentSceneCommandId,
      },
    );
  };

  private handleRemoveFullScreenIllustrationShowCommand = (
    sceneCommandForm: FullScreenIllustrationShowSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleChangeFullScreenIllustrationShowSceneCommandForm = (
    sceneCommandForm: FullScreenIllustrationShowSceneCommandForm,
  ) => {
    const {updateSceneCommandForm} = this.props;
    updateSceneCommandForm({sceneCommandForm});
  };

  private handleForwardToChangeEffects = (
    sceneCommandForm: EffectShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    const sceneCommandId = sceneCommandForm.sceneCommandId;
    routers.linkToSceneFormEffectShowSceneCommandEditNavigations(navigation, {
      storyId,
      sceneId,
      sceneCommandId,
      parentSceneCommandId,
    });
  };

  private handleChangeEffectShowSceneCommandForm = (
    sceneCommandForm: EffectShowSceneCommandForm,
  ) => {
    const {updateSceneCommandForm} = this.props;
    updateSceneCommandForm({sceneCommandForm});
  };

  private handleRemoveEffectShowCommand = (
    sceneCommandForm: EffectShowSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleForwardToChangeSoundEffects = (
    sceneCommandForm: SoundEffectShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    const sceneCommandId = sceneCommandForm.sceneCommandId;
    routers.linkToSceneFormSoundEffectShowSceneCommandEditNavigations(
      navigation,
      {
        storyId,
        sceneId,
        sceneCommandId,
        parentSceneCommandId,
      },
    );
  };

  private handleRemoveSoundEffectShowCommand = (
    sceneCommandForm: SoundEffectShowSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleForwardToChangeBackgroundMusic = (
    sceneCommandForm: BackgroundMusicShowSceneCommandForm,
    parentSceneCommandId?: number,
  ) => {
    const {navigation, route, sceneForm} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm) {
      return;
    }
    const storyId = sceneForm.storyId;
    const sceneCommandId = sceneCommandForm.sceneCommandId;
    routers.linkToSceneFormBackgroundMusicShowSceneCommandEditNavigations(
      navigation,
      {
        storyId,
        sceneId,
        sceneCommandId,
        parentSceneCommandId,
      },
    );
  };

  private handleRemoveBackgroundMusicShowCommand = (
    sceneCommandForm: BackgroundMusicShowSceneCommandForm,
  ) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleInsertBackgroundMusicHideCommandAt = (
    sceneCommandForm: BackgroundMusicShowSceneCommandForm,
    sceneCommandIndex: number,
  ) => {
    const {createSceneCommandForm} = this.props;
    createSceneCommandForm({
      index: sceneCommandIndex,
      sceneCommandForm: new BackgroundMusicHideSceneCommandForm(
        sceneCommandForm.sound,
      ),
    });
  };

  private handleChangeCommand = (
    sceneCommandForm: SceneCommandForm,
    characterPatternMapping?: CharacterPatternMapping,
  ) => {
    const {updateSceneCommandForm} = this.props;
    updateSceneCommandForm({sceneCommandForm, characterPatternMapping});
  };

  private handleRemoveCommand = (sceneCommandForm: SceneCommandForm) => {
    const {destroySceneCommandForm} = this.props;
    destroySceneCommandForm({sceneCommandForm});
  };

  private handleChangeOrder = (positionMap: Map<Position, Position>) => {
    const {
      sceneCommandForms: currentSceneCommandForms,
      updateSceneCommandFormLists,
      orientedSpeechBalloons,
      indexOrientedSpeechBalloons,
      orientedSpeechBalloonsParams,
    } = this.props;
    if (!currentSceneCommandForms) {
      return;
    }
    const callback = (records: OrientedSpeechBalloon[]) => {
      const sceneCommandForms = new SceneCommandFormsPositionSwitcher(
        records,
      ).switchPosition(currentSceneCommandForms, positionMap);
      updateSceneCommandFormLists({sceneCommandForms});
    };
    if (orientedSpeechBalloons) {
      callback(orientedSpeechBalloons);
    } else {
      indexOrientedSpeechBalloons(orientedSpeechBalloonsParams).then(result => {
        callback(result.records);
      });
    }
  };

  private handleRequestOpenModal = (modalParams: ModalParams) => {
    this.setState({modalParams});
  };

  private handleRequestCloseModal = () => {
    this.setState({modalParams: null});
  };

  private handleRequestUpdateCoachmarkModal = (
    coachmarkState: CoachmarkState | null,
  ) => {
    const {updateCoachmarkModal} = this.props;
    updateCoachmarkModal({value: coachmarkState});
  };

  private handlePreview = () => {
    const {navigation, route, sceneForm, sceneCommandForms} = this.props;
    const {sceneId} = route.params;
    if (!sceneForm || !sceneCommandForms) {
      return;
    }
    const episodeId = sceneForm.episodeId;
    const params = this.createSceneParams(sceneForm, sceneCommandForms);
    routers.linkToPreviewSceneViewer(navigation, {
      params,
      episodeId,
      sceneId,
      withNextScenes: true,
    });
  };

  private handleSubmit = () => {
    const {
      route,
      navigation,
      showEpisode,
      updateScene,
      sceneForm,
      sceneCommandForms,
    } = this.props;
    const {sceneId} = route.params;
    if (!sceneForm || !sceneCommandForms) {
      return;
    }
    this.setState({disabledSubmitButton: true, loading: true}, () => {
      updateScene(sceneId, this.createSceneParams(sceneForm, sceneCommandForms))
        .then(scene => {
          showEpisode(scene.episodeId);
          this.removeListenerBeforeRemove();
          this.clearOldScene(() => {
            this.setState({loading: false}, () => {
              navigation.goBack();
            });
          });
        })
        .catch(error => {
          this.setState({
            disabledSubmitButton: false,
            loading: false,
            alertMessage: formatErrorMessages({}, error),
          });
        });
    });
  };

  private createSceneParams(
    sceneForm: SceneForm,
    sceneCommandForms: SceneCommandForm[],
  ): SceneScriptCreateParams {
    const episodeId = sceneForm.episodeId;
    const backgroundId = sceneForm.background ? sceneForm.background.id : null;
    const options = sceneForm.options;
    const positionedEffectId = sceneForm.positionedEffect?.id || null;
    const effectOptions = sceneForm.effectOptions;
    const commandsAttributes = new SceneCommandParamsBuilder().build(
      sceneCommandForms,
    );
    return {
      id: sceneForm.id,
      backgroundId,
      commandsAttributes,
      episodeId,
      options,
      positionedEffectId,
      effectOptions,
      scriptVersion: 2,
    };
  }

  private clearOldScene(callback?: () => void) {
    const {route, destroySceneForm} = this.props;
    const {sceneId} = route.params;
    this.editing = false;
    autoSaveUpdatingScenesRepository.destroy(sceneId);
    destroySceneForm().then(() => {
      callback && callback();
    });
  }

  private handleLeave = () => {
    const callback = this.state.beforeBackCallback;
    if (!callback) {
      return;
    }
    this.clearOldScene();
    this.setState({beforeBackCallback: null}, callback);
  };

  private handleCancel = () => {
    this.setState({beforeBackCallback: null});
  };

  private handleRequestIndexActorCharacterFaces = (
    params: ActorCharacterFaceIndexParams,
  ) => {
    const {indexActorCharacterFaces} = this.props;
    return indexActorCharacterFaces(params);
  };

  private handleRequestIndexMarks = () => {
    const {indexMarks, marksParams} = this.props;
    return indexMarks(marksParams);
  };

  private handleRequestIndexSpeechBalloons = () => {
    const {indexSpeechBalloons, speechBalloonsParams, speechBalloons} =
      this.props;
    if (!speechBalloons) {
      indexSpeechBalloons(speechBalloonsParams);
    }
  };

  private handleRequestIndexOrientedSpeechBalloons = () => {
    const {
      indexOrientedSpeechBalloons,
      orientedSpeechBalloonsParams,
      orientedSpeechBalloons,
    } = this.props;
    if (!orientedSpeechBalloons) {
      indexOrientedSpeechBalloons(orientedSpeechBalloonsParams);
    }
  };

  private handleRequestIndexTextFrames = () => {
    const {indexTextFrames, textFramesParams, textFrames} = this.props;
    if (!textFrames) {
      indexTextFrames(textFramesParams);
    }
  };

  private handleRequestCreateCharacterPattern = async (
    params: CharacterPatternCreateParams,
  ): Promise<CharacterPattern> => {
    const {
      indexCharacterPatterns,
      createCharacterPattern,
      updateCharacterPatternUsageHistory,
    } = this.props;
    let characterPattern = (await indexCharacterPatterns(params)).records[0];
    if (!characterPattern) {
      characterPattern = await createCharacterPattern(params);
    }
    updateCharacterPatternUsageHistory(characterPattern.id);
    return characterPattern;
  };

  private handleRequestIndexActorCostumes = (
    params: ActorCostumeIndexParams,
  ) => {
    const {indexActorCostumes} = this.props;
    return indexActorCostumes(params);
  };

  private handleRequestIndexActorHairStyles = (
    params: ActorHairStyleIndexParams,
  ) => {
    const {indexActorHairStyles} = this.props;
    return indexActorHairStyles(params);
  };

  private handleRequestIndexActorAccessorySets = (
    params: ActorAccessorySetIndexParams,
  ) => {
    const {indexActorAccessorySets} = this.props;
    return indexActorAccessorySets(params);
  };

  private handleRequestUpdateModal = (modalParams: ModalParams) => {
    this.setState({modalParams});
  };

  private handleCloseModal = () => {
    this.setState({alertMessage: undefined});
  };

  private handleCloseRestoredModal = () => {
    this.setState({visibleRestoredModal: false});
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ): void {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }
}
