import EntityMapper from './EntityMapper';

import EpisodeCommentBlockingUser from '../../../domain/entities/EpisodeCommentBlockingUser';

export interface EpisodeCommentBlockingUserData {
  id: number;
  story_id: number;
  consumer_user_id: number;
  created_at: string;
  updated_at: string;
}

export default class EpisodeCommentBlockingUserMapper
  implements
    EntityMapper<EpisodeCommentBlockingUserData, EpisodeCommentBlockingUser>
{
  public map(obj: EpisodeCommentBlockingUserData): EpisodeCommentBlockingUser {
    return new EpisodeCommentBlockingUser(
      obj.id,
      obj.story_id,
      obj.consumer_user_id,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
