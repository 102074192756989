import * as React from 'react';
import {
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import Form from './Form';
import KeywordTags from './KeywordTags';
import ContestKeywordTags from './ContestKeywordTags';

import KeyboardSpacer from '../../../shared/KeyboardSpacer';
import Tag from '../../../shared/Tag';

import {colors} from '../../../../styles/variables';

import Contest from '../../../../../domain/entities/Contest';

interface Props {
  names: string[];
  contests?: Contest[] | null;
  onRemove: (name: string) => void;
  onCreateKeyword: (text: string, callback?: () => void) => void;
  children?: React.ReactNode;
}

const FormWithKeywordTags: React.FunctionComponent<Props> = props => {
  const {names, contests, onRemove, onCreateKeyword} = props;
  const onPress = React.useCallback((contest: Contest) => {
    onCreateKeyword(contest.keywordName);
  }, []);
  return (
    <View style={styles.container}>
      <ScrollView bounces={false}>
        <KeywordTags names={names} onRemove={onRemove} />
      </ScrollView>
      {contests && contests.length > 0 && (
        <ContestKeywordTags contests={contests} onPress={onPress} />
      )}
      <View style={styles.form}>
        <Form onCreateKeyword={onCreateKeyword} />
      </View>
      <KeyboardSpacer />
    </View>
  );
};

export default React.memo(FormWithKeywordTags);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  } as ViewStyle,
  form: {
    backgroundColor: 'white',
    borderTopColor: colors.powderGray,
    borderTopWidth: 1,
    height: 60,
    ...Platform.select({
      web: {position: 'absolute', bottom: 0, left: 0, right: 0},
      default: {},
    }),
  } as ViewStyle,
});
