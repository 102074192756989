import * as React from 'react';
import {InteractionManager, ScrollView, View} from 'react-native';

import IllustrationCategorySelect from './partials/IllustrationCategorySelect';
import SearchInput from './partials/SearchInput';
import SearchButtonWithTotalCount from './partials/SearchButtonWithTotalCount';

import PopularTagsBox from '../../../../shared/PopularTagsBox';
import Layout from '../../../../shared/Layout';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormRouteProp,
  SceneFormIllustrationShowSceneCommandEditIllustrationSearchFormRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as IllustrationCategoryIndexParams} from '../../../../../actions/illustration_categories/index';
import {Params as IllustrationIndexParams} from '../../../../../actions/illustrations/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import Illustration from '../../../../../../domain/entities/Illustration';
import IllustrationCategory from '../../../../../../domain/entities/IllustrationCategory';
import PopularTag from '../../../../../../domain/entities/PopularTag';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import NetPopularTagsRepository from '../../../../../../data/repositories/writer/NetPopularTagsRepository';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
  selectedLargeIllustrationCategoryId?: number | null;
  selectedMiddleIllustrationCategoryId?: number | null;
  selectedSmallIllustrationCategoryId?: number | null;
  favorite?: boolean;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormIllustrationShowSceneCommandNewIllustrationSearchFormRouteProp
    | SceneFormIllustrationShowSceneCommandEditIllustrationSearchFormRouteProp;
  sceneForm: SceneForm | null;
  largeIllustrationCategoriesParams: IllustrationCategoryIndexParams;
  largeIllustrationCategories: IllustrationCategory[] | null;
  middleIllustrationCategoriesParams: IllustrationCategoryIndexParams;
  middleIllustrationCategories: IllustrationCategory[] | null;
  smallIllustrationCategoriesParams: IllustrationCategoryIndexParams;
  smallIllustrationCategories: IllustrationCategory[] | null;
}

export interface DispatchProps {
  indexIllustrationCategories: (
    params: IllustrationCategoryIndexParams,
  ) => Promise<PaginatedResult<IllustrationCategory>>;
  indexIllustrations: (
    params: IllustrationIndexParams,
  ) => Promise<PaginatedResult<Illustration>>;
}

interface Props extends StateProps, DispatchProps {
  onSubmit: (params: {query?: string; illustrationCategoryId?: number}) => void;
}

interface State {
  popularTags?: Array<PopularTag> | null;
  totalCount: number;
  query: string;
}

export default class Index extends React.Component<Props, State> {
  private timerId: any | null = null;

  constructor(props: Props) {
    super(props);
    const {query} = props.route.params;
    this.state = {
      totalCount: 0,
      query: query || '',
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      largeIllustrationCategories,
      largeIllustrationCategoriesParams,
      indexIllustrationCategories,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    InteractionManager.runAfterInteractions(() => {
      new NetPopularTagsRepository()
        .findBy({taggableType: 'Illustration'})
        .then(result => {
          this.setState({popularTags: result.records});
        });
      if (!largeIllustrationCategories) {
        indexIllustrationCategories(largeIllustrationCategoriesParams);
      }
      this.fetchTotalCount();
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {
      route,
      indexIllustrationCategories,
      middleIllustrationCategoriesParams,
      smallIllustrationCategoriesParams,
    } = this.props;
    const {
      selectedLargeIllustrationCategoryId,
      selectedMiddleIllustrationCategoryId,
      selectedSmallIllustrationCategoryId,
    } = route.params;
    const {
      selectedLargeIllustrationCategoryId:
        prevSelectedLargeIllustrationCategoryId,
      selectedMiddleIllustrationCategoryId:
        prevSelectedMiddleIllustrationCategoryId,
      selectedSmallIllustrationCategoryId:
        prevSelectedSmallIllustrationCategoryId,
    } = prevProps.route.params;
    let updated = false;
    if (
      selectedLargeIllustrationCategoryId !==
      prevSelectedLargeIllustrationCategoryId
    ) {
      indexIllustrationCategories(middleIllustrationCategoriesParams);
      updated = true;
    }
    if (
      selectedMiddleIllustrationCategoryId !==
      prevSelectedMiddleIllustrationCategoryId
    ) {
      indexIllustrationCategories(smallIllustrationCategoriesParams);
      updated = true;
    }
    if (
      selectedSmallIllustrationCategoryId !==
      prevSelectedSmallIllustrationCategoryId
    ) {
      updated = true;
    }
    if (updated) {
      this.fetchTotalCount();
    }
  }

  public render(): React.ReactNode {
    const {
      navigation,
      route,
      largeIllustrationCategories,
      middleIllustrationCategories,
      smallIllustrationCategories,
    } = this.props;
    const {query, totalCount, popularTags} = this.state;
    const {
      selectedLargeIllustrationCategoryId,
      selectedMiddleIllustrationCategoryId,
      selectedSmallIllustrationCategoryId,
      hideBack,
    } = route.params;
    return (
      <Layout
        title={'アイテム検索条件'}
        back={hideBack ? false : true}
        close={true}
        navigation={navigation}
        scrollable={false}>
        <ScrollView>
          {largeIllustrationCategories && popularTags && (
            <>
              <SearchInput query={query} onChangeText={this.handleChangeText} />
              <PopularTagsBox
                popularTags={popularTags}
                onSelectPopularTag={this.handleSelectPopularTag}
              />
              <View style={{marginHorizontal: 16, marginTop: 16}}>
                <LabelWithOption title={'カテゴリ'} />
              </View>
              <IllustrationCategorySelect
                value={selectedLargeIllustrationCategoryId || 0}
                illustrationCategories={largeIllustrationCategories}
                onValueChange={
                  this.handleValueChangeForLargeIllustrationCategoryId
                }
              />
              <IllustrationCategorySelect
                value={selectedMiddleIllustrationCategoryId || 0}
                illustrationCategories={middleIllustrationCategories}
                onValueChange={
                  this.handleValueChangeForMiddleIllustrationCategoryId
                }
              />
              <IllustrationCategorySelect
                value={selectedSmallIllustrationCategoryId || 0}
                illustrationCategories={smallIllustrationCategories}
                onValueChange={
                  this.handleValueChangeForSmallIllustrationCategoryId
                }
              />
            </>
          )}
        </ScrollView>
        <SearchButtonWithTotalCount
          totalCount={totalCount}
          onPress={this.handleSubmit}
        />
      </Layout>
    );
  }

  private handleChangeText = (query: string) => {
    this.setStateAndFetchTotalCount({query});
  };

  private handleValueChangeForLargeIllustrationCategoryId = (
    selectedLargeIllustrationCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({
      selectedLargeIllustrationCategoryId,
      selectedMiddleIllustrationCategoryId: null,
      selectedSmallIllustrationCategoryId: null,
    });
  };

  private handleValueChangeForMiddleIllustrationCategoryId = (
    selectedMiddleIllustrationCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({
      selectedMiddleIllustrationCategoryId,
      selectedSmallIllustrationCategoryId: null,
    });
  };

  private handleValueChangeForSmallIllustrationCategoryId = (
    selectedSmallIllustrationCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({selectedSmallIllustrationCategoryId});
  };

  private setStateAndFetchTotalCount<K extends keyof State>(
    state: Pick<State, K>,
  ): void {
    this.setState(state, () => {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.fetchTotalCount();
      }, 250);
    });
  }

  private fetchTotalCount = () => {
    const {indexIllustrations} = this.props;
    indexIllustrations({...this.buildSearchParams(), perPage: 1}).then(
      searchResult => {
        this.setState({totalCount: searchResult.total});
      },
    );
  };

  private buildSearchParams = () => {
    const {route} = this.props;
    const {query} = this.state;
    const {
      selectedLargeIllustrationCategoryId,
      selectedMiddleIllustrationCategoryId,
      selectedSmallIllustrationCategoryId,
      favorite,
      storyId,
    } = route.params;
    return {
      query,
      favorite,
      storyId,
      allIllustrationCategoryId:
        this.covertIllustrationCategoryId(
          selectedSmallIllustrationCategoryId,
        ) ||
        this.covertIllustrationCategoryId(
          selectedMiddleIllustrationCategoryId,
        ) ||
        this.covertIllustrationCategoryId(
          selectedLargeIllustrationCategoryId,
        ) ||
        undefined,
    };
  };

  private handleSubmit = () => {
    const {route, onSubmit} = this.props;
    const {
      selectedLargeIllustrationCategoryId,
      selectedMiddleIllustrationCategoryId,
      selectedSmallIllustrationCategoryId,
    } = route.params;
    const {query} = this.state;
    onSubmit({
      query,
      illustrationCategoryId:
        this.covertIllustrationCategoryId(
          selectedSmallIllustrationCategoryId,
        ) ||
        this.covertIllustrationCategoryId(
          selectedMiddleIllustrationCategoryId,
        ) ||
        this.covertIllustrationCategoryId(
          selectedLargeIllustrationCategoryId,
        ) ||
        undefined,
    });
  };

  private covertIllustrationCategoryId = (
    illustrationCategoryId: number | null | undefined,
  ) => {
    if (illustrationCategoryId && Number(illustrationCategoryId) != 0) {
      return Number(illustrationCategoryId);
    }
    return undefined;
  };

  private handleSelectPopularTag = (popularTag: PopularTag) => {
    const {onSubmit} = this.props;
    onSubmit({query: popularTag.tagName});
  };
}
