import Story from '../../../../domain/entities/Story';

interface Props {
  stories: Story[] | null;
}

export default (current: Props, next: Props) => {
  if (current.stories === next.stories) {
    return false;
  }
  if (!current.stories || !next.stories) {
    return true;
  }
  if (current.stories.length !== next.stories.length) {
    return true;
  }
  return !current.stories.every((currentStory, i) => {
    if (!next.stories) {
      return false;
    }
    const nextStory = next.stories[i];
    if (!nextStory) {
      return false;
    }
    if (currentStory.id !== nextStory.id) {
      return false;
    }
    if (currentStory.updatedAt.getTime() !== nextStory.updatedAt.getTime()) {
      return false;
    }
    return true;
  });
};
