import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import SceneWithCommandsWrapper from '../../../domain/entities/SceneWithCommandsWrapper';

export default function reducer(
  state: {[key: number]: SceneWithCommandsWrapper} = {},
  action: AnyAction,
): {[key: number]: SceneWithCommandsWrapper} {
  switch (action.type) {
    case Types.ShowSceneWithCommandsWrapperSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case Types.DestroySceneForm:
      Object.keys(state).forEach((key: any) => {
        delete state[key];
      });
      return {};
    default:
      return state;
  }
}
