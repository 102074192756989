import * as React from 'react';

import BaseIndex from '../../../../../shared/generics/actor_search_form/Index';

import NavigationProp from '../../../../../../navigators/NavigationProp';
import {SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchFormRouteProp} from '../../../../../../navigators/RouteProps';

import * as routers from '../../../../../../routers';

import {Params as ActorCategoryIndexParams} from '../../../../../../actions/actor_categories/index';
import {Params as ActorIndexParams} from '../../../../../../actions/actors/index';

import redirectSceneForm from '../../../../../../helpers/redirectSceneForm';

import Actor from '../../../../../../../domain/entities/Actor';
import ActorCategory from '../../../../../../../domain/entities/ActorCategory';
import PaginatedResult from '../../../../../../../domain/results/PaginatedResult';
import Position from '../../../../../../../domain/value_objects/Position';
import SceneForm from '../../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  subSceneCommandIndex?: number;
  parentSceneCommandId?: number;
  position?: Position;
  formValues?: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    purchase?: boolean;
  };
  withPaid?: boolean;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormCharacterShowSceneCommandNewCharacterFormActorSearchFormRouteProp;
  sceneForm: SceneForm | null;
  actorCategoriesParams: ActorCategoryIndexParams;
  actorCategories: ActorCategory[] | null;
}

export interface DispatchProps {
  indexActorCategories: (
    params: ActorCategoryIndexParams,
  ) => Promise<PaginatedResult<ActorCategory>>;
  indexActors: (params: ActorIndexParams) => Promise<PaginatedResult<Actor>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {navigation, route, sceneForm} = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {formValues, hideBack} = this.props.route.params;
    return (
      <BaseIndex
        {...this.props}
        formValues={formValues}
        withPaid={this.props.route.params.withPaid}
        hideBack={hideBack}
        onPress={this.handlePress}
      />
    );
  }

  private handlePress = (params: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    purchase?: boolean;
  }) => {
    const {navigation, route} = this.props;
    const {
      storyId,
      episodeId,
      sceneId,
      sceneCommandIndex,
      subSceneCommandIndex,
      parentSceneCommandId,
      position,
      withPaid,
    } = route.params;
    const {
      query,
      ageMin,
      ageMax,
      rootCategoryIdToActorCategoryIds,
      favorite,
      purchase,
    } = params;
    routers.linkToSceneFormCharacterShowSceneCommandNewCharacterFormActors(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        sceneCommandIndex,
        subSceneCommandIndex,
        parentSceneCommandId,
        position,
        withPaid,
        formValues: {
          query,
          ageMin,
          ageMax,
          rootCategoryIdToActorCategoryIds,
          favorite,
          purchase,
        },
      },
    );
  };
}
