import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../components/writer/user_accounts/Show';

import indexWriterOauthAccounts, {
  Params as WriterOauthAccountIndexParams,
} from '../../../actions/writer/oauth_accounts/index';
import createWriterFacebookAccount, {
  Params as WriterFacebookAccountCreateParams,
} from '../../../actions/writer/social_accounts/facebook_accounts/registration/create';
import destroyWriterFacebookAccount from '../../../actions/writer/social_accounts/facebook_accounts/registration/destroy';
import createWriterTwitterAccount, {
  Params as WriterTwitterAccountCreateParams,
} from '../../../actions/writer/social_accounts/twitter_accounts/registration/create';
import destroyWriterTwitterAccount from '../../../actions/writer/social_accounts/twitter_accounts/registration/destroy';
import createWriterLineAccount, {
  Params as WriterLineAccountCreateParams,
} from '../../../actions/writer/social_accounts/line_accounts/registration/create';
import destroyWriterLineAccount from '../../../actions/writer/social_accounts/line_accounts/registration/destroy';

import AppState from '../../../reducers/AppState';

import selectEntities from '../../../helpers/selectEntities';
import selectEntity from '../../../helpers/selectEntity';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries, writerCurrentUserId} = state;
  const writerOauthAccounsParams: WriterOauthAccountIndexParams = {};
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const oauthAccounts = selectEntities(
    entities.writer.oauthAccounts,
    queries.writer.oauthAccounts,
    writerOauthAccounsParams,
  );
  return {
    currentUser,
    writerOauthAccounsParams,
    navigation,
    oauthAccounts,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createWriterFacebookAccount: (params: WriterFacebookAccountCreateParams) =>
      dispatch(createWriterFacebookAccount(params)),
    destroyWriterFacebookAccount: (id: number) =>
      dispatch(destroyWriterFacebookAccount(id)),
    createWriterTwitterAccount: (params: WriterTwitterAccountCreateParams) =>
      dispatch(createWriterTwitterAccount(params)),
    destroyWriterTwitterAccount: (id: number) =>
      dispatch(destroyWriterTwitterAccount(id)),
    createWriterLineAccount: (params: WriterLineAccountCreateParams) =>
      dispatch(createWriterLineAccount(params)),
    destroyWriterLineAccount: (id: number) =>
      dispatch(destroyWriterLineAccount(id)),
    indexWriterOauthAccounts: (params: WriterOauthAccountIndexParams) =>
      dispatch(indexWriterOauthAccounts(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
