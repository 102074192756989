import * as React from 'react';

import BaseIndex from '../../../shared/generics/actor_search_form/Index';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CoverImageFormCharacterFormActorSearchFormNavigationRouteProp} from '../../../../navigators/RouteProps';

import {Params as ActorCategoryIndexParams} from '../../../../actions/actor_categories/index';
import {Params as ActorIndexParams} from '../../../../actions/actors/index';

import redirectStoryCoverImageForm from '../../../../helpers/redirectStoryCoverImageForm';

import CoverImageForm from '../../../../../domain/forms/CoverImageForm';
import Actor from '../../../../../domain/entities/Actor';
import ActorCategory from '../../../../../domain/entities/ActorCategory';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  formValues?: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
  };
  withPaid?: boolean;
  hideBack?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormCharacterFormActorSearchFormNavigationRouteProp;
  coverImageForm: CoverImageForm | null;
  actorCategoriesParams: ActorCategoryIndexParams;
  actorCategories: ActorCategory[] | null;
}

export interface DispatchProps {
  indexActorCategories: (
    params: ActorCategoryIndexParams,
  ) => Promise<PaginatedResult<ActorCategory>>;
  indexActors: (params: ActorIndexParams) => Promise<PaginatedResult<Actor>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {route} = this.props;
    const {formValues, withPaid, hideBack} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        formValues={formValues}
        withPaid={withPaid}
        hideBack={hideBack}
        onPress={this.handlePress}
      />
    );
  }

  private handlePress = (params: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
  }) => {
    const {navigation, route} = this.props;
    const {storyId, aspectRatio, imageKey, formValues, withPaid} = route.params;
    const {query, ageMin, ageMax, rootCategoryIdToActorCategoryIds} = params;
    navigation.navigate('CoverImageFormCharacterFormActors', {
      storyId,
      aspectRatio,
      imageKey,
      formValues: {
        query,
        ageMin,
        ageMax,
        rootCategoryIdToActorCategoryIds,
        favorite: formValues?.favorite,
      },
      withPaid,
    });
  };
}
