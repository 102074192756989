import * as React from 'react';

import Layout from '../../shared/Layout';

import EditForm from './partial/EditForm';

import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {EditWriterUserPasswordRouteProp} from '../../../navigators/RouteProps';

import {Params as UserPasswordUpdateParams} from '../../../actions/writer/users/password/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

export interface Params {
  reset_password_token: string;
}

export interface StateProps {
  navigation: NavigationProp;
  route: EditWriterUserPasswordRouteProp;
}

export interface DispatchProps {
  updateUserPassword: (
    params: UserPasswordUpdateParams,
  ) => Promise<Record<string, unknown>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class Edit extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {loading: false};
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {loading, alertMessage} = this.state;

    return (
      <Layout
        title={'パスワード再設定'}
        navigation={navigation}
        close={true}
        loading={loading}
        hideHeaderBottomBorder={true}
        scrollViewStyle={{backgroundColor: '#fafafa'}}>
        <EditForm onSubmit={this.handleSubmit} />
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleSubmit = (password: string, passwordConfirmation: string) => {
    const {navigation, route, updateUserPassword} = this.props;
    const {reset_password_token} = route.params;
    this.setState({loading: true});
    updateUserPassword({
      password,
      passwordConfirmation,
      resetPasswordToken: reset_password_token,
    })
      .then(() => {
        navigation.goBack();
        navigation.navigate('NewWriterUserSession', {});
      })
      .catch(error => {
        this.setState({
          loading: false,
          alertMessage: formatErrorMessages({}, error),
        });
      });
  };
}
