import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import WritersGateHome from '../../../containers/writers_gate/home/Index';
import WritersGateOffer from '../../../containers/writers_gate/offers/Show';
import WritersGateManuscripts from '../../../containers/writers_gate/manuscripts/Index';
import WritersGateManuscript from '../../../containers/writers_gate/manuscripts/Show';
import NewWritersGateManuscript from '../../../containers/writers_gate/manuscripts/New';
import WritersGateStories from '../../../containers/writers_gate/stories/Index';
import WritersGateStory from '../../../containers/writers_gate/stories/Show';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="WritersGateHome">
      <RootStack.Screen
        name="WritersGateHome"
        component={WritersGateHome}
        options={{
          title: 'オファー一覧',
        }}
      />
      <RootStack.Screen
        name="WritersGateOffer"
        component={WritersGateOffer}
        options={{
          title: 'オファー詳細',
        }}
      />
      <RootStack.Screen
        name="WritersGateManuscripts"
        component={WritersGateManuscripts}
        options={{
          title: '提出管理',
        }}
      />
      <RootStack.Screen
        name="WritersGateManuscript"
        component={WritersGateManuscript}
        options={{
          title: '提出',
        }}
      />
      <RootStack.Screen
        name="NewWritersGateManuscript"
        component={NewWritersGateManuscript}
        options={{
          title: '提出',
        }}
      />
      <RootStack.Screen
        name="WritersGateStories"
        component={WritersGateStories}
        options={{
          title: 'ストーリー選択',
        }}
      />
      <RootStack.Screen
        name="WritersGateStory"
        component={WritersGateStory}
        options={{
          title: 'エピソード選択',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
