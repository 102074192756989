import Entity from './Entity';

export default class PopularTag implements Entity {
  constructor(
    public id: number,
    public tagName: string,
    public taggableType: string,
    public priority: string,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
