import * as React from 'react';
import {StyleSheet, TouchableOpacity, View, ViewStyle} from 'react-native';

import SoundIcon from '../icons/SoundIcon';

interface Props {
  addSound: () => void;
}

const AttachmentSoundDeleteView: React.FC<Props> = props => {
  const {addSound} = props;
  return (
    <View style={styles.container}>
      <View style={styles.button}>
        <TouchableOpacity style={styles.icon} onPress={addSound}>
          <SoundIcon size={12} />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default React.memo(AttachmentSoundDeleteView);

const styles = StyleSheet.create({
  container: {
    height: 50,
    width: '100%',
  } as ViewStyle,
  button: {
    position: 'absolute',
    right: 8,
    bottom: 8,
  } as ViewStyle,
  icon: {
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderRadius: 18,
    borderColor: '#383838',
    width: 28,
    height: 28,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
