import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import PositionedEffect from '../../../domain/entities/PositionedEffect';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetPositionedEffectsRepository from '../../../data/repositories/writer/NetPositionedEffectsRepository';

const types = {
  failure: Types.IndexPositionedEffectsFailure,
  request: Types.IndexPositionedEffectsRequest,
  success: Types.IndexPositionedEffectsSuccess,
};

export interface Params extends BaseParams {
  effectId: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<PositionedEffect>> {
  return indexGenerics(new NetPositionedEffectsRepository(), types, params);
}
