import * as React from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import TwitterIcon from '../icons/TwitterIcon';
import FacebookRoundIcon from '../icons/FacebookRoundIcon';
import LineSquareIcon from '../icons/LineSquareIcon';

import {
  twitterShareUrl,
  facebookShareUrl,
  lineShareUrl,
} from '../../../helpers/shareUrls';

import {colors} from '../../../styles/variables';

interface Props {
  message: string;
  url: string;
  title: string;
}

const ShareButtonsWithCopy: React.FunctionComponent<Props> = props => {
  const {message, url, title} = props;
  const copyUrl = React.useCallback(() => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(url);
    } else {
      const el = document.createElement('textarea');
      el.value = url;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  }, [url]);
  return (
    <View style={styles.container}>
      <a
        href={twitterShareUrl(message, url, title)}
        className="social-share-button"
        target={'_blank'}>
        <TwitterIcon size={46} color={'#1d9bf0'} />
      </a>
      <a
        href={facebookShareUrl(url)}
        className="social-share-button"
        target={'_blank'}>
        <FacebookRoundIcon size={46} color={'#1877f2'} />
      </a>
      <a
        href={lineShareUrl(message, url)}
        className="social-share-button"
        target={'_blank'}>
        <LineSquareIcon size={46} color={'#06c755'} />
      </a>
      <TouchableOpacity
        style={{
          backgroundColor: '#e5e5e5',
          borderRadius: 23,
          height: 46,
          width: 46,
          justifyContent: 'center',
        }}
        onPress={copyUrl}>
        <Text
          style={{
            fontSize: 10,
            color: '#222',
            textAlign: 'center',
          }}>
          URLを{'\n'}コピー
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(ShareButtonsWithCopy);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
    height: 46,
    width: 275,
    flexDirection: 'row',
  } as ViewStyle,
});
