import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import {useLinkTo} from '@react-navigation/native';
import PrimaryButton from '../../shared/buttons/PrimaryButton';

interface Props {}

const NoProejcts: React.FC<Props> = props => {
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    linkTo('/projects/search/home');
  }, []);
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        まだ参加しているプロジェクトはありません
      </Text>
      <PrimaryButton onPress={onPress}>プロジェクトを探す</PrimaryButton>
    </View>
  );
};

export default React.memo(NoProejcts);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  message: {
    color: '#222',
    fontSize: 14,
    marginBottom: 32,
  } as TextStyle,
});
