import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import PopularTagMapper, {
  PopularTagData,
} from '../../entities/mappers/PopularTagMapper';

import PopularTag from '../../../domain/entities/PopularTag';
import PopularTagsRepository from '../../../domain/repositories/writer/PopularTagsRepository';

export default class NetPopularTagsRepository
  extends NetResourcesRepository<PopularTag, PopularTagData>
  implements PopularTagsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/popular_tags',
        new PopularTagMapper(),
      ),
    );
  }
}
