import EntityMapper from '../../EntityMapper';

import PaymentIntent from '../../../../../domain/entities/writer/my_stripe/PaymentIntent';

export interface PaymentIntentData {
  id: string;
  client_secret: string;
  status: string;
  amount: number | null;
  currency: string | null;
}

export default class PaymentIntentMapper
  implements EntityMapper<PaymentIntentData, PaymentIntent>
{
  public map(obj: PaymentIntentData): PaymentIntent {
    return new PaymentIntent(
      obj.id,
      obj.client_secret,
      obj.status,
      obj.amount,
      obj.currency,
    );
  }
}
