import * as React from 'react';
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from 'react-native';
import WaveSurfer from 'wavesurfer.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';

import SoundView from './SoundView';
import AttachmentSoundAddView from './AttachmentSoundAddView';

import ConfirmButton from '../../scenes/partials/modal/scene_command_modals/buttons/ConfirmButton';

import AssetDecoder from '../AssetDecoder';
import SoundPlayable from '../SoundPlayable';
import SoundUrlHelper from '../../../helpers/SoundUrlHelper';

import Sound from '../../../../domain/entities/Sound';

interface Props {
  style?: StyleProp<ViewStyle>;
  sound: Sound;
  startTime?: number | null;
  endTime?: number | null;
  autofix?: boolean;
  playButtonStyle?: 'default' | 'large';
  onChagenRegion?: (start?: number, end?: number) => void;
  onRequestCloseModal?: () => void;
  deleteSound?: () => void;
}

const TrimmedSoundEffectView: React.FC<Props> = props => {
  const {
    style,
    sound,
    startTime,
    endTime,
    autofix,
    onChagenRegion,
    onRequestCloseModal,
    deleteSound,
  } = props;
  const playButtonStyle = props.playButtonStyle || 'default';
  const [playing, setPlaying] = React.useState(false);
  const currentStartTime = React.useRef<number | undefined>(
    startTime || undefined,
  );
  const currentEndTime = React.useRef<number | undefined>(endTime || undefined);
  const waveformRef = React.useRef<any | null>(null);
  const waveSurfer = React.useRef<WaveSurfer | null>(null);
  const wsRegions = React.useRef<any | null>(null);
  React.useEffect(() => {
    decodeAudio(sound, currentStartTime, currentEndTime);
    waveSurfer.current = WaveSurfer.create({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      container: waveformRef.current,
      height: 36,
      waveColor: '#34a4d0',
      normalize: true,
      cursorColor: 'transparent',
      cursorWidth: 0,
    });
    waveSurfer.current.load(sound.audioUrl);
    wsRegions.current = waveSurfer.current.registerPlugin(
      RegionsPlugin.create(),
    );
    waveSurfer.current.on('decode', end => {
      setTimeout(() => {
        wsRegions.current.addRegion({
          start: startTime || 0,
          end: endTime || end,
          drag: true,
          resize: true,
        });
      }, 50);
    });
    waveSurfer.current.on('finish', () => {
      setPlaying(false);
    });
    wsRegions.current.on('region-created', (region: any) => {
      const div = region.element as HTMLDivElement;
      div.querySelectorAll('div').forEach(c => {
        c.style.border = '1px solid black';
        c.style.height = '95%';
        c.style.backgroundColor = 'white';
        c.style.borderRadius = '5px';
      });
    });
    wsRegions.current.on('region-out', (region: any) => {
      waveSurfer.current?.pause();
      setTimeout(() => {
        setPlaying(false);
      }, 100);
    });
    wsRegions.current.on('region-updated', (region: any) => {
      const start = region.start < 0.01 ? 0 : region.start;
      const end =
        region.totalDuration - region.end < 0.01
          ? region.totalDuration
          : region.end;
      if (start == 0 && end == region.totalDuration) {
        currentStartTime.current = undefined;
        currentEndTime.current = undefined;
      } else {
        currentStartTime.current = start;
        currentEndTime.current = end;
      }
      decodeAudio(sound, currentStartTime, currentEndTime);
      if (autofix) {
        onChagenRegion?.(currentStartTime.current, currentEndTime.current);
      }
    });
    return () => {
      waveSurfer.current?.destroy();
    };
  }, []);
  const handlePlay = React.useCallback(() => {
    if (playing) {
      waveSurfer.current?.pause();
    } else {
      waveSurfer.current?.setVolume(0);
      wsRegions.current?.regions[0].play();
    }
    setPlaying(!playing);
  }, [playing]);
  const handleFinishPlay = React.useCallback(() => {
    setPlaying(false);
  }, []);
  const onPress = React.useCallback(() => {
    onChagenRegion?.(currentStartTime.current, currentEndTime.current);
    onRequestCloseModal?.();
  }, []);
  const uri = SoundUrlHelper.getAudioUrl(
    sound.audioUrl,
    sound.trimmedAudioUrl,
    {
      satrtTime: currentStartTime.current,
      endTime: currentEndTime.current,
    },
  );
  return (
    <View style={[style || styles.container]}>
      {playButtonStyle === 'large' ? (
        <SoundView
          sound={sound}
          iconSize={iconSize}
          labelStyle={styles.label}
          muted={true}
          playing={playing}
          onPlay={handlePlay}
        />
      ) : (
        <AttachmentSoundAddView
          sound={sound}
          style={{width: '100%'}}
          muted={true}
          playing={playing}
          deleteSound={deleteSound}
          onPlay={handlePlay}
        />
      )}
      {playing ? (
        <SoundPlayable uri={uri} onFinishPlay={handleFinishPlay} />
      ) : null}
      <View
        ref={waveformRef}
        style={[
          styles.waveform,
          {
            paddingHorizontal: playButtonStyle === 'large' ? 16 : undefined,
            marginTop: playButtonStyle === 'large' ? 48 : undefined,
          },
        ]}
      />
      {autofix ? null : (
        <View style={{alignItems: 'center', marginBottom: 16}}>
          <ConfirmButton onPress={onPress} />
        </View>
      )}
    </View>
  );
};

export default React.memo(TrimmedSoundEffectView);

const iconSize = 32;

const styles = StyleSheet.create({
  container: {
    height: 240,
    width: '100%',
  } as ViewStyle,
  button: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  label: {
    fontSize: 20,
    marginLeft: 8,
    color: '#222',
    fontWeight: 'bold',
  } as TextStyle,
  waveform: {
    flex: 1,
    marginTop: 8,
  } as ViewStyle,
});

const decodeAudio = (
  sound: Sound,
  currentStartTime: React.MutableRefObject<number | undefined>,
  currentEndTime: React.MutableRefObject<number | undefined>,
) => {
  AssetDecoder.decodeAudio(
    SoundUrlHelper.getAudioUrl(sound.audioUrl, sound.trimmedAudioUrl, {
      satrtTime: currentStartTime.current,
      endTime: currentEndTime.current,
    }),
  );
};
