import * as React from 'react';
import {FlatList, StyleProp, StyleSheet, ViewStyle} from 'react-native';

import TextFrameGridListItem from './TextFrameGridListItem';

import shouldUpdateEntityList from '../../../../../shared/enhanced/shouldUpdateEntityList';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';

import TextFrame from '../../../../../../../domain/entities/TextFrame';

interface Props {
  style?: StyleProp<ViewStyle>;
  textFrames: TextFrame[];
  selectedTextFrameId: number;
  onSelectTextFrame: (textFrame: TextFrame) => void;
}

export default class TextFrameGridList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return (
      shouldUpdateEntityList(
        {entities: this.props.textFrames},
        {entities: nextProps.textFrames},
      ) ||
      this.props.selectedTextFrameId !== nextProps.selectedTextFrameId ||
      this.props.style !== nextProps.style
    );
  }

  public render(): React.ReactNode {
    const {style, textFrames, selectedTextFrameId, onSelectTextFrame} =
      this.props;
    return (
      <DimensionContext.Consumer>
        {context => {
          const width =
            (context.content.width - CONTAINER_MARGIN_HORIZONTAL * 2) /
            NUM_COLUMNS;
          return (
            <FlatList
              style={[styles.container, style]}
              data={textFrames}
              keyExtractor={this.keyExtractor}
              numColumns={NUM_COLUMNS}
              renderItem={info => (
                <TextFrameGridListItem
                  textFrame={info.item}
                  selectedTextFrameId={selectedTextFrameId}
                  width={width}
                  onSelectTextFrame={onSelectTextFrame}
                />
              )}
              extraData={selectedTextFrameId}
            />
          );
        }}
      </DimensionContext.Consumer>
    );
  }

  private keyExtractor = (item: TextFrame) => `${item.id}`;
}

const CONTAINER_MARGIN_HORIZONTAL = 16;

const NUM_COLUMNS = 2;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: CONTAINER_MARGIN_HORIZONTAL,
  } as ViewStyle,
  imageWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'transparent',
    borderWidth: 2,
    borderRadius: 4,
  } as ViewStyle,
  imageWrapperActive: {
    borderColor: '#ff8f13',
  } as ViewStyle,
});
