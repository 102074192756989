import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import EditStory from '../../../../containers/stories/Edit';

import EditStoryFormCoverImage from '../../../../containers/story_form/cover_image/Edit';
import EditStoryFormDetailInfo from '../../../../containers/story_form/detail_info/Edit';
import EditStoryFormImage from '../../../../containers/story_form/image/Edit';
import StoryFormKeywords from '../../../../containers/story_form/keywords/Index';
import StoryFormMainGenre from '../../../../containers/story_form/main_genre/Index';
import StoryFormSubGenres from '../../../../containers/story_form/sub_genres/Index';
import StoryFormStoryPublication from '../../../../containers/story_form/story_publication/Index';
import StoryFormStoryPublicationCompletion from '../../../../containers/story_form/story_publication_completion/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="EditStory">
      <RootStack.Screen
        name="EditStory"
        component={EditStory}
        options={{title: 'ストーリー情報'}}
      />

      <RootStack.Screen
        name="EditStoryFormCoverImage"
        component={EditStoryFormCoverImage}
        options={{title: '表紙'}}
      />

      <RootStack.Screen
        name="EditStoryFormDetailInfo"
        component={EditStoryFormDetailInfo}
        options={{title: 'ストーリー情報'}}
      />

      <RootStack.Screen
        name="EditStoryFormImage"
        component={EditStoryFormImage}
        options={{title: '中表紙'}}
      />

      <RootStack.Screen
        name="StoryFormKeywords"
        component={StoryFormKeywords}
        options={{title: 'キーワード'}}
      />

      <RootStack.Screen
        name="StoryFormMainGenre"
        component={StoryFormMainGenre}
        options={{title: 'メインジャンル'}}
      />

      <RootStack.Screen
        name="StoryFormSubGenres"
        component={StoryFormSubGenres}
        options={{title: 'サブジャンル'}}
      />

      <RootStack.Screen
        name="StoryFormStoryPublication"
        component={StoryFormStoryPublication}
        options={{title: 'ストーリーの公開設定'}}
      />
      <RootStack.Screen
        name="StoryFormStoryPublicationCompletion"
        component={StoryFormStoryPublicationCompletion}
        options={{title: 'ストーリーの公開設定'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
