import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import IllustrationCategory from '../../../domain/entities/IllustrationCategory';

import NetIllustrationCategoriesRepository from '../../../data/repositories/NetIllustrationCategoriesRepository';

const types = {
  failure: Types.ShowIllustrationCategoryFailure,
  request: Types.ShowIllustrationCategoryRequest,
  success: Types.ShowIllustrationCategorySuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<IllustrationCategory> {
  return showGeneric(new NetIllustrationCategoriesRepository(), types, id);
}
