import * as React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import CheckedIcon from '../icons/CheckedIcon';

import {colors} from '../../../styles/variables';

const color = '#ff8f13';

interface Props {
  checked: boolean;
  style?: StyleProp<ViewStyle>;
  buttonType?: boolean;
  size?: number;
  disabled?: boolean;
  children?: React.ReactNode;
}

const CheckBox: React.FunctionComponent<Props> = props => {
  const {checked, style, buttonType, size, disabled} = props;
  const containerStyle = buttonType
    ? [{borderColor: disabled ? colors.backgroundGray : colors.paleGray}]
    : undefined;
  return (
    <View style={[styles.container, containerStyle, style]}>
      {checked && <CheckedIcon size={size} color={color} />}
    </View>
  );
};

export default React.memo(CheckBox);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: 20,
    justifyContent: 'center',
    width: 20,
  } as ViewStyle,
});
