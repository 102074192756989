import * as React from 'react';

import UserStatusContext from './UserStatusContext';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

interface Props extends React.PropsWithChildren {
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
}

export default class UserStatusProvider extends React.Component<Props> {
  public render(): React.ReactNode {
    const {currentUser, currentUserStatus, children} = this.props;

    return (
      <UserStatusContext.Provider value={{currentUser, currentUserStatus}}>
        {children}
      </UserStatusContext.Provider>
    );
  }
}
