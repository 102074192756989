import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import QuestionIcon from '../../shared/icons/QuestionIcon';

import Project from '../../../../domain/entities/Project';

import TooltipModal from '../../shared/modals/TooltipModal';

type TooltipModalType =
  | 'format'
  | 'secondaryUse'
  | 'allowedChangeOnSecondaryUse'
  | 'requiredChangePermissionOnSecondaryUse';

interface Props {
  project: Project;
}

const ProjectInfo: React.FC<Props> = props => {
  const {project} = props;
  const {story} = project;
  const [modalInfoType, setModalInfoType] =
    React.useState<TooltipModalType | null>(null);
  const onPressQuestionFormat = React.useCallback(() => {
    setModalInfoType('format');
  }, []);
  const onPressQuestionSecondaryUse = React.useCallback(() => {
    setModalInfoType('secondaryUse');
  }, []);
  const onPressQuestionAllowedChangeOnSecondaryUse = React.useCallback(() => {
    setModalInfoType('allowedChangeOnSecondaryUse');
  }, []);
  const onPressQuestionRequiredChangePermissionOnSecondaryUse =
    React.useCallback(() => {
      setModalInfoType('requiredChangePermissionOnSecondaryUse');
    }, []);
  const onCloseModal = React.useCallback(() => {
    setModalInfoType(null);
  }, []);
  const modalInfo = modalInfoType ? TypeToTooltipInfo[modalInfoType] : null;
  return (
    <View style={styles.container}>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>企画内容</Text>
        </View>
        <Text style={styles.content}>{project.planning}</Text>
      </View>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>形式</Text>
          <TouchableOpacity onPress={onPressQuestionFormat}>
            <QuestionIcon />
          </TouchableOpacity>
        </View>
        <Text style={styles.content}>
          {story.format === 'format_series'
            ? 'シリーズ'
            : story.format === 'format_omnibus'
            ? 'オムニバス'
            : ''}
        </Text>
      </View>
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>エピソードの著作権</Text>
        </View>
        <Text style={styles.content}>
          {project.episodeCopyright === 'author_owned'
            ? 'エピソードを投稿したメンバーが保有'
            : project.episodeCopyright === 'leader_buy_out'
            ? 'リーダーが買い取り'
            : ''}
        </Text>
      </View>
      {project.episodeCopyright === 'leader_buy_out' &&
        project.episodePurchasePrice !== null && (
          <View style={styles.section}>
            <View style={styles.header}>
              <Text style={styles.title}>買い取り報酬額</Text>
            </View>
            <Text style={styles.content}>￥{project.episodePurchasePrice}</Text>
          </View>
        )}
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>エピソードの二次利用</Text>
          <TouchableOpacity onPress={onPressQuestionSecondaryUse}>
            <QuestionIcon />
          </TouchableOpacity>
        </View>
        <Text style={styles.content}>
          {project.secondaryUse === 'only_uncommercial_personal_use'
            ? '非営利目的での個人利用OK'
            : project.secondaryUse === 'denied_secondary_use'
            ? '二次利用NG'
            : ''}
        </Text>
      </View>
      {project.allowedChangeOnSecondaryUse !== null && (
        <View style={styles.section}>
          <View style={styles.header}>
            <Text style={styles.title}>エピソードの二次利用時の改変</Text>
            <TouchableOpacity
              onPress={onPressQuestionAllowedChangeOnSecondaryUse}>
              <QuestionIcon />
            </TouchableOpacity>
          </View>
          <Text style={styles.content}>
            {project.allowedChangeOnSecondaryUse ? '改変OK' : '改変NG'}
          </Text>
        </View>
      )}
      {project.requiredChangePermissionOnSecondaryUse !== null && (
        <View style={styles.section}>
          <View style={styles.header}>
            <Text style={styles.title}>エピソードの二次利用時の改変の許可</Text>
            <TouchableOpacity
              onPress={onPressQuestionRequiredChangePermissionOnSecondaryUse}>
              <QuestionIcon />
            </TouchableOpacity>
          </View>
          <Text style={styles.content}>
            {project.requiredChangePermissionOnSecondaryUse
              ? '許可必要（事前確認必須）'
              : '許可不要（事後差し止め）'}
          </Text>
        </View>
      )}
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>求めるタップライター</Text>
        </View>
        <Text style={styles.content}>{project.idealCandidateProfile}</Text>
      </View>
      {project.admissionCapacityRequired &&
        project.admissionCapacity !== null && (
          <View style={styles.section}>
            <View style={styles.header}>
              <Text style={styles.title}>募集定員</Text>
            </View>
            <Text style={styles.content}>{project.admissionCapacity}人</Text>
          </View>
        )}
      <View style={styles.section}>
        <View style={styles.header}>
          <Text style={styles.title}>参加方法</Text>
        </View>
        <Text style={styles.content}>
          {project.participationMethod === 'free_participation'
            ? '自由参加'
            : project.participationMethod === 'approval_system'
            ? '承認制'
            : ''}
        </Text>
      </View>
      {modalInfo ? (
        <TooltipModal
          visible={true}
          title={modalInfo.title}
          description={modalInfo.description}
          onCloseModal={onCloseModal}></TooltipModal>
      ) : null}
    </View>
  );
};

export default React.memo(ProjectInfo);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 16,
  } as ViewStyle,
  section: {
    marginBottom: 32,
  } as ViewStyle,
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginRight: 4,
  } as TextStyle,
  content: {
    color: '#222',
    fontSize: 14,
    marginTop: 16,
  } as TextStyle,
  modalText: {
    marginVertical: 4,
  } as TextStyle,
});

const TypeToTooltipInfo = {
  format: {
    title: '形式',
    description:
      '特定の主人公キャラクターがいるものをシリーズ、\n主人公が毎回変わるものをオムニバスとします。',
  },
  secondaryUse: {
    title: '二次利用',
    description: (
      <View>
        <Text>
          メンバーが執筆したエピソードの第三者による二次利用について定めることができます。
        </Text>
        <Text style={{marginVertical: 8}}>
          非営利目的での利用OKを選択した場合は、非営利の場合に限り、設定する条件に沿って第三者の個人がTapNovel以外で利用することができます。
        </Text>
        <Text>
          法人による利用、営利目的での利用はトラブル防止のため、当社が窓口を行います。
        </Text>
      </View>
    ),
  },
  allowedChangeOnSecondaryUse: {
    title: '二次利用時の改変',
    description: (
      <View>
        <Text style={styles.modalText}>
          メンバーが執筆したエピソードの第三者による二次利時の改変について定めることができます。
        </Text>
        <Text style={styles.modalText}>
          改変OKを選択した場合は、設定する条件に沿って第三者の個人がTapNovel以外で利用する際に改変を認めることができます。
        </Text>
      </View>
    ),
  },
  requiredChangePermissionOnSecondaryUse: {
    title: '二次利用時の改変の許可',
    description: (
      <View>
        <Text style={styles.modalText}>
          メンバーが執筆したエピソードの第三者による二次利時の改変の許可について定めることができます。
        </Text>
        <Text style={styles.modalText}>
          許可不要を選択した場合は、第三者の個人がTapNovel以外の利用において改変する際に、エピソードを執筆したメンバーに許可を取る必要はございません。
          {'\n'}
          常識的な範疇を超える改変については、エピソードを執筆したメンバーによって差し止めが可能なものとします。
        </Text>
        <Text style={styles.modalText}>
          許可必要を選択した場合は、第三者の個人がTapNovel以外の利用において改変する際に、エピソードを執筆したメンバーに許可を取る必要がございます。
          {'\n'}
          メンバーが執筆したエピソードの第三者による二次利時の改変の許可について定めることができます。
        </Text>
        <Text style={styles.modalText}>
          拡散を希望する場合は許可不要にすることを推奨します。
        </Text>
      </View>
    ),
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: React.ReactNode | string;
  };
};
