import EntityMapper from './EntityMapper';

import EpisodeCommentReply from '../../../domain/entities/EpisodeCommentReply';

export interface EpisodeCommentReplyData {
  id: number;
  consumer_user_episode_comment_id: number;
  comment: string;
  user_episode_comment_reply_likes_count: number;
  created_at: Date;
  updated_at: Date;
}

export default class EpisodeCommentReplyMapper
  implements EntityMapper<EpisodeCommentReplyData, EpisodeCommentReply>
{
  public map(obj: EpisodeCommentReplyData): EpisodeCommentReply {
    return new EpisodeCommentReply(
      obj.id,
      obj.consumer_user_episode_comment_id,
      obj.comment,
      obj.user_episode_comment_reply_likes_count,
      new Date(obj.created_at),
      new Date(obj.updated_at),
    );
  }
}
