import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import Story from '../../../domain/entities/Story';

import NetStoriesRepository from '../../../data/repositories/writer/NetStoriesRepository';

const types = {
  failure: Types.ShowStoryFailure,
  request: Types.ShowStoryRequest,
  success: Types.ShowStorySuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Story> {
  return showGeneric(new NetStoriesRepository(), types, id);
}
