import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import Detail from './partials/Detail';

import {FormValues as FormEditValues} from './partials/FormEdit';

import Layout from '../../shared/Layout';
import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectEpisodeReleaseRequestRouteProp} from '../../../navigators/RouteProps';

import {Params as EpisodePublicationUpdateParams} from '../../../actions/episode_publications/update';
import {Params as ApplicationModalUpdateParams} from '../../../actions/application_modal/update';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import ProjectEpisodeReleaseRequest from '../../../../domain/entities/ProjectEpisodeReleaseRequest';

import Episode from '../../../../domain/entities/Episode';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectEpisodeReleaseRequestRouteProp;
}

export interface DispatchProps {
  updateEpisodePublication: (
    id: number,
    params: EpisodePublicationUpdateParams,
  ) => Promise<Episode>;
  updateApplicationModal: (params: ApplicationModalUpdateParams) => void;
}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route, updateEpisodePublication, updateApplicationModal} =
    props;
  const {projectId, id} = route.params;
  const [episodeReleaseRequest, setEpisodeReleaseRequest] =
    React.useState<ProjectEpisodeReleaseRequest | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const fetchEpisodeReleaseRequest = React.useCallback(() => {
    TapNovelTypedRestApi.get<ProjectEpisodeReleaseRequest>(
      `/api/writer/leader/project_episode_release_requests/${id}`,
    ).then(result => {
      setEpisodeReleaseRequest(result.body);
    });
  }, []);
  const fetchEntities = React.useCallback(() => {
    fetchEpisodeReleaseRequest();
  }, []);
  useFocusEffect(fetchEntities);
  const onForceUpdate = fetchEntities;
  const onSubmitForEpisodeRelease = React.useCallback(
    (values: FormEditValues) => {
      if (!episodeReleaseRequest) {
        return;
      }
      setLoading(true);
      const {option, publicationState, scheduledDeliveredAt} = values;
      updateEpisodePublication(episodeReleaseRequest.episode.id, {
        free: option === 'free',
        published: publicationState !== 'private',
        scheduledDeliveredAt,
      })
        .then(() => {
          const message =
            publicationState === 'public'
              ? 'エピソードを公開しました'
              : publicationState === 'reserved'
              ? 'エピソードを予約公開設定しました'
              : 'エピソードを非公開にしました';
          setLoading(false);
          if (publicationState !== 'private') {
            navigation.goBack();
          }
          updateApplicationModal({options: {message}});
        })
        .catch(error => {
          setLoading(false);
          setAlertMessage(formatErrorMessages({}, error));
        });
    },
    [episodeReleaseRequest],
  );
  const onCloseModal = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  return (
    <Layout
      title={'エピソード公開申請'}
      navigation={navigation}
      back={true}
      loading={loading}>
      {episodeReleaseRequest && (
        <Detail
          episodeReleaseRequest={episodeReleaseRequest}
          onForceUpdate={onForceUpdate}
          onSubmitForEpisodeRelease={onSubmitForEpisodeRelease}
          setLoading={setLoading}
        />
      )}
      <AlertModal visible={!!alertMessage} onCloseModal={onCloseModal}>
        {alertMessage}
      </AlertModal>
    </Layout>
  );
};

export default React.memo(Show);
