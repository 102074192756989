import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import StarIcon from './icons/StarIcon';
import StarSolidIcon from './icons/StarSolidIcon';

import UserResourceFavorite from '../../../domain/entities/writer/UserResourceFavorite';

import NetUserResourceFavoritesRepository from '../../../data/repositories/writer/NetUserResourceFavoritesRepository';

const userResourceFavoritesRepository =
  new NetUserResourceFavoritesRepository();

interface Props {
  style?: StyleProp<ViewStyle>;
  resourceType: string;
  resourceId: number;
  iconSize?: number;
  defaultValue?: UserResourceFavorite | null;
  initialized?: boolean;
}

const ResourceFavoriteButton: React.FunctionComponent<Props> = props => {
  const {style, resourceType, resourceId, defaultValue, initialized} = props;
  const [userResourceFavorite, setUserResourceFavorite] =
    React.useState<UserResourceFavorite | null>(defaultValue || null);
  React.useEffect(() => {
    if (initialized) {
      return;
    }
    userResourceFavoritesRepository
      .findBy({
        resourceType,
        resourceId,
      })
      .then(result => {
        setUserResourceFavorite(result.records[0]);
      });
  }, []);
  const onPress = React.useCallback(() => {
    if (userResourceFavorite) {
      userResourceFavoritesRepository
        .destroy(userResourceFavorite.id)
        .then(() => {
          setUserResourceFavorite(null);
        });
    } else {
      userResourceFavoritesRepository
        .create({
          writerUserResourceFavorite: {
            resourceType,
            resourceId,
          },
        })
        .then(userResourceFavorite => {
          setUserResourceFavorite(userResourceFavorite);
        });
    }
  }, [userResourceFavorite]);
  const favorited = !!userResourceFavorite;
  const iconSize = props.iconSize || 20;
  return (
    <TouchableOpacity
      style={[favorited ? styles.favorited : styles.container, style]}
      onPress={onPress}>
      {favorited ? (
        <StarSolidIcon size={iconSize} />
      ) : (
        <StarIcon size={iconSize} />
      )}
    </TouchableOpacity>
  );
};

export default React.memo(ResourceFavoriteButton);

const container: ViewStyle = {
  width: 38,
  height: 38,
  backgroundColor: '#fff',
  borderRadius: 25,
  borderWidth: 1,
  borderColor: '#f5c71c',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 10,
  right: 10,
};

const styles = StyleSheet.create({
  container,
  favorited: {
    ...container,
    backgroundColor: '#efefef',
    borderColor: '#efefef',
  } as ViewStyle,
});
