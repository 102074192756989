import * as React from 'react';
import {
  Image,
  ImageSourcePropType,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

export interface Props {
  source: ImageSourcePropType;
  label: string;
  connected: boolean;
  onPress: () => void;
}

export default class Setting extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {source, label, connected, onPress} = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.imageAndLabel}>
          <Image source={source} style={styles.image} />
          <Text style={connected ? styles.labelConnected : styles.label}>
            {label}
          </Text>
        </View>
        <PrimaryButton
          style={connected ? styles.buttonConnected : styles.button}
          fontStyle={connected ? styles.buttonTextStyle : undefined}
          onPress={onPress}>
          {connected ? '連携解除' : '連携する'}
        </PrimaryButton>
      </View>
    );
  }
}

const label = {
  color: '#383838',
  fontSize: 14,
  marginHorizontal: 16,
} as TextStyle;

const button = {
  width: 100,
  height: 32,
} as ViewStyle;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 16,
  } as ViewStyle,
  imageAndLabel: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  label,
  labelConnected: {
    ...label,
    color: '#999',
  } as TextStyle,
  image: {
    width: 40,
    height: 40,
  } as ImageStyle,
  button,
  buttonConnected: {
    ...button,
    backgroundColor: '#d5d5d5',
    borderColor: '#d5d5d5',
  } as ViewStyle,
  buttonTextStyle: {
    color: '#999',
  } as TextStyle,
});
