import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Index, {DispatchProps, StateProps} from '../../components/helps/Index';

import AppState from '../../reducers/AppState';

import indexHelpCategories, {
  Params as HelpCategoryIndexParams,
} from '../../actions/writer/help_categories';

import selectEntities from '../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries} = state;
  const helpCategoriesParams = {} as HelpCategoryIndexParams;
  const helpCategories = selectEntities(
    entities.writer.helpCategories,
    queries.writer.helpCategories,
    helpCategoriesParams,
  );
  return {
    helpCategories,
    helpCategoriesParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
  props: Props,
) => {
  return {
    indexHelpCategories: (params: HelpCategoryIndexParams) =>
      dispatch(indexHelpCategories(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
