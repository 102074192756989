import * as React from 'react';

import AddSceneCommandLink from './AddSceneCommandLink';

import ImageEffectIcon from '../../../../shared/icons/ImageEffectIcon';

import {colors} from '../../../../../styles/variables';

interface Props {
  onPress: () => void;
}

const AddEffectShowSceneCommandLink: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <AddSceneCommandLink title={'エフェクト'} onPress={onPress}>
      <ImageEffectIcon color={colors.textColor} />
    </AddSceneCommandLink>
  );
};

export default React.memo(AddEffectShowSceneCommandLink);
