import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';

import BottomPrimaryButton from '../../shared/buttons/BottomPrimaryButton';

import StoryFormCreateEvent from '../../../../domain/services/analytics_particular_events/StoryFormCreateEvent';

interface Props {
  style?: StyleProp<ViewStyle>;
  bottomButtonStyle?: StyleProp<ViewStyle>;
  onForwardToNewStory: () => void;
}

const NewStoryButton: React.FunctionComponent<Props> = props => {
  const {style, bottomButtonStyle, onForwardToNewStory} = props;
  const onPress = React.useCallback(() => {
    onForwardToNewStory();
    new StoryFormCreateEvent().create();
  }, []);
  return (
    <BottomPrimaryButton
      style={style}
      bottomButtonStyle={bottomButtonStyle}
      onPress={onPress}>
      新しいストーリーをつくる
    </BottomPrimaryButton>
  );
};

export default React.memo(NewStoryButton);
