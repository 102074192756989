import * as React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

interface Props {
  beforeText?: string;
}

const EditableLaterMessage: React.FunctionComponent<Props> = props => {
  const {beforeText} = props;
  return <Text style={styles.container}>{beforeText}あとで編集可能です</Text>;
};

const propsAreEqual = () => true;

export default React.memo(EditableLaterMessage, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    textAlign: 'center',
    color: '#383838',
    fontSize: 11,
    marginBottom: 8,
  } as TextStyle,
});
