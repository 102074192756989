import EntityMapper from './EntityMapper';

import BackgroundCategory from '../../../domain/entities/BackgroundCategory';

export interface BackgroundCategoryData {
  id: number;
  parent_id: number;
  name: string;
  leaf: boolean;
  base_background_count: number;
}

export default class BackgroundCategoryMapper
  implements EntityMapper<BackgroundCategoryData, BackgroundCategory>
{
  public map(obj: BackgroundCategoryData): BackgroundCategory {
    return new BackgroundCategory(
      obj.id,
      obj.parent_id,
      obj.name,
      obj.leaf,
      obj.base_background_count,
    );
  }
}
