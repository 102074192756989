import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import Headline from '../../../../../shared/Headline';
import EntityImageGridListWithModal from '../../../../../shared/EntityImageGridListWithModal';

import {illustrationUsageHistoryImageUrl} from '../../../../../../helpers/images';

import IllustrationUsageHistory from '../../../../../../../domain/entities/IllustrationUsageHistory';

const IMAGE_SIZE = 'middle';

interface Props {
  illustrationUsageHistories: IllustrationUsageHistory[];
  onSelectIllustrationUsageHistory: (
    illustrationUsageHistory: IllustrationUsageHistory,
  ) => void;
  onForwardToFavoriteList?: () => void;
  modalRenderFavoriteButton?: (
    item: IllustrationUsageHistory,
  ) => React.ReactNode;
}

export default class IllustrationUsageHistoryList extends React.Component<Props> {
  public render(): React.ReactNode {
    const {
      illustrationUsageHistories,
      onSelectIllustrationUsageHistory,
      onForwardToFavoriteList,
      modalRenderFavoriteButton,
    } = this.props;
    return (
      <>
        <Headline
          title={'使用したアイテム'}
          onPressFavoriteList={onForwardToFavoriteList}
        />
        <EntityImageGridListWithModal
          style={styles.container}
          entities={illustrationUsageHistories}
          aspectRatio={ASPECT_RATIO}
          imageAspectRatio={1}
          ListFooterComponent={ListFooterComponent}
          onSelectEntity={onSelectIllustrationUsageHistory}
          imageUrlExtractor={this.imageUrlExtractor}
          modalRenderFavoriteButton={modalRenderFavoriteButton}
        />
      </>
    );
  }
  private imageUrlExtractor = (
    illustrationUsageHistory: IllustrationUsageHistory,
    width: number,
  ) => {
    return illustrationUsageHistoryImageUrl(
      illustrationUsageHistory,
      IMAGE_SIZE,
    );
  };
}

const ASPECT_RATIO = 1.25;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    flex: 1,
    paddingBottom: Platform.select({web: 75, default: undefined}),
  } as ViewStyle,
  spacer: {
    height: 75,
    width: '100%',
  } as ViewStyle,
});

const ListFooterComponent = Platform.select({
  web: undefined,
  default: <View style={styles.spacer} />,
});
