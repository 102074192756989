import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../components/announcements/Index';

import AppState from '../../reducers/AppState';

import indexAnnouncements, {
  Params as AnnouncementIndexParams,
} from '../../actions/writer/announcements';
import indexAnnouncementCategories, {
  Params as AnnouncementCategoryIndexParams,
} from '../../actions/writer/announcement_categories';

import selectEntities from '../../helpers/selectEntities';

import {AnnouncementsRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: AnnouncementsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries} = state;
  const {writerAnnouncementCategoryId, page} = route.params || {
    writerAnnouncementCategoryId: undefined,
    page: undefined,
  };
  const announcementsParams: AnnouncementIndexParams = {
    writerAnnouncementCategoryId,
    page,
  };
  const announcementCategoriesParams: AnnouncementCategoryIndexParams = {};
  const announcements = selectEntities(
    entities.writer.announcements,
    queries.writer.announcements,
    announcementsParams,
  );
  const announcementCategories = selectEntities(
    entities.writer.announcementCategories,
    queries.writer.announcementCategories,
    announcementCategoriesParams,
  );
  return {
    announcementCategories,
    announcementCategoriesParams,
    announcements,
    announcementsParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexAnnouncements: (params: AnnouncementIndexParams) =>
      dispatch(indexAnnouncements(params)),
    indexAnnouncementCategories: (params: AnnouncementCategoryIndexParams) =>
      dispatch(indexAnnouncementCategories(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
