import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import Detail from './partials/Detail';
import CommentList from './partials/CommentList';
import CommentListHeader from './partials/CommentListHeader';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {ProjectTopicRouteProp} from '../../../navigators/RouteProps';

import ProjectTopic from '../../../../domain/entities/ProjectTopic';
import ProjectTopicComment from '../../../../domain/entities/ProjectTopicComment';
import ProjectTopicCommentLike from '../../../../domain/entities/ProjectTopicCommentLike';
import ProjectTopicCommentReplyLike from '../../../../domain/entities/ProjectTopicCommentReplyLike';
import WriterCurrentUser from '../../../../domain/entities/writer/CurrentUser';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectTopicRouteProp;
  currentUser: WriterCurrentUser | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route, currentUser} = props;
  const {id} = route.params;
  const [topic, setTopic] = React.useState<ProjectTopic | null>(null);
  const [comments, setComments] = React.useState<ProjectTopicComment[] | null>(
    null,
  );
  const [commentLikes, setCommentLikes] = React.useState<
    ProjectTopicCommentLike[] | null
  >(null);
  const [commentReplyLikes, setCommentReplyLikes] = React.useState<
    ProjectTopicCommentReplyLike[] | null
  >(null);
  const [topicOwner, setTopicOwner] = React.useState<boolean | undefined>(
    undefined,
  );
  const fetchEntities = React.useCallback(() => {
    TapNovelTypedRestApi.get<ProjectTopic>(
      `/api/writer/project_topics/${id}`,
    ).then(result => {
      setTopic(result.body);
    });
    TapNovelTypedRestApi.get<ProjectTopicComment[]>(
      `/api/writer/project_topic_comments`,
      {projectTopicId: id},
    ).then(result => {
      setComments(result.body);
    });
    TapNovelTypedRestApi.get<ProjectTopicCommentLike[]>(
      '/api/writer/project_topic_comment_likes',
      {projectTopicId: id},
    ).then(result => {
      setCommentLikes(result.body);
    });
    TapNovelTypedRestApi.get<ProjectTopicCommentReplyLike[]>(
      '/api/writer/project_topic_comment_reply_likes',
      {projectTopicId: id},
    ).then(result => {
      setCommentReplyLikes(result.body);
    });
  }, []);
  React.useEffect(() => {
    if (topic && currentUser) {
      setTopicOwner(topic.writerUser.id === currentUser.writerUserId);
    }
  }, [topic, currentUser]);
  useFocusEffect(fetchEntities);
  return (
    <Layout
      title={'トピック詳細'}
      navigation={navigation}
      back={true}
      enabledUserSelect={true}>
      {topic &&
        comments &&
        commentLikes &&
        commentReplyLikes &&
        topicOwner !== undefined && (
          <CommentList
            topic={topic}
            comments={comments}
            commentLikes={commentLikes}
            commentReplyLikes={commentReplyLikes}
            topicOwner={topicOwner}
            ListHeaderComponent={
              <CommentListHeader topic={topic}>
                <Detail topic={topic} topicOwner={topicOwner} />
              </CommentListHeader>
            }
          />
        )}
    </Layout>
  );
};

export default React.memo(Show);
