import {Dispatch} from 'redux';

import Types from '../Types';

import {sendDefaultCustomEvent} from '../../helpers/analytics';

import Scene, {Options} from '../../../domain/entities/Scene';

import NetScenesRepository from '../../../data/repositories/writer/NetScenesRepository';

import EpisodeCreateEvent from '../../../domain/services/analytics_particular_events/EpisodeCreateEvent';

interface DefaultParams {
  episodeId: number;
  backgroundId: number | null;
  options?: Options | null;
  commandsAttributes: {[key: string]: any};
}

interface CopyParams {
  copyFrom: number;
}

export type Params = DefaultParams | CopyParams;

export default function action(
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Scene> {
  return async (dispatch: Dispatch<any>): Promise<Scene> => {
    try {
      const payload = await new NetScenesRepository().create(
        Object.keys(params).includes('episodeId')
          ? {
              scene: params,
            }
          : params,
      );
      dispatch({payload, type: Types.CreateSceneSuccess});
      sendDefaultCustomEvent({
        resourceType: 'scene',
        resourceId: payload.id,
        resourceName: `シーン${payload.numberOfEpisode}`,
        actionName: 'create',
      });
      if (Object.keys(params).includes('copyFrom')) {
        sendDefaultCustomEvent({
          resourceType: 'copied_scene',
          resourceId: payload.id,
          resourceName: `シーン${payload.numberOfEpisode}`,
          actionName: 'create',
        });
      }
      new EpisodeCreateEvent()
        .create(payload.episodeId)
        .then(sameEventsCount => {
          if (sameEventsCount === 1) {
            dispatch({
              payload: 'first_episode_created',
              type: Types.UpdateCoachmarkModal,
            });
          } else {
            dispatch({
              payload: {
                message: `シーンを${
                  Object.keys(params).includes('copyFrom') ? 'コピー' : '登録'
                }しました！`,
              },
              type: Types.UpdateApplicationModal,
            });
          }
        });
      return payload;
    } catch (err) {
      dispatch({type: Types.CreateSceneFailure});
      throw err;
    }
  };
}
