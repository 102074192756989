import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import CharacterImage from './partials/CharacterImage';
import EditButton from './partials/EditButton';

import Layout from '../shared/Layout';
import buildPublicationSettingButton from '../shared/buttons/buildPublicationSettingButton';
import PenIcon from '../shared/icons/PenIcon';
import PublicStateLabel from '../shared/PublicStateLabel';

import NavigationProp from '../../navigators/NavigationProp';
import {CharacterRouteProp} from '../../navigators/RouteProps';

import * as routers from '../../routers';

import Character from '../../../domain/entities/Character';
import CurrentUser from '../../../domain/entities/writer/CurrentUser';
import ProjectUserStatus from '../../../domain/entities/ProjectUserStatus';

import TapNovelTypedRestApi from '../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId?: number;
  characterId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CharacterRouteProp;
  character: Character | null;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  showCharacter: (id: number) => Promise<Character>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  enabledRightButton: boolean;
}

export default class Edit extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      enabledRightButton: false,
    };
  }

  public componentDidMount() {
    const {navigation, route, showCharacter} = this.props;
    const {characterId, projectId} = route.params;
    showCharacter(characterId).catch(e => {
      if (e.status === 401) {
        navigation.goBack();
      }
    });
    if (projectId) {
      TapNovelTypedRestApi.get<ProjectUserStatus>(
        '/api/writer/project_user_status',
        {projectId},
      ).then(result => {
        this.setState({enabledRightButton: result.body.role === 'leader'});
      });
    } else {
      this.setState({enabledRightButton: true});
    }
  }

  public render(): React.ReactNode {
    const {navigation, character, currentUser} = this.props;
    const {enabledRightButton} = this.state;
    const disabled =
      !currentUser?.writerUserId ||
      !character?.writerUserId ||
      currentUser.writerUserId !== character.writerUserId;
    return (
      <Layout
        title={'キャラクター情報'}
        navigation={navigation}
        back={true}
        rightButton={
          enabledRightButton
            ? buildPublicationSettingButton({
                handler: this.handleForwardCharacterPublication,
                disabled: (character?.description?.length || 0) === 0,
              })
            : null
        }>
        {character && (
          <View style={styles.container}>
            <View style={styles.imageWrapper}>
              <CharacterImage
                imageSize={80}
                actorCharacterFace={character.actorCharacterFace}
                inverted={character.inverted}
              />
            </View>
            <View style={styles.info}>
              <Text style={styles.name}>{character.name}</Text>
              <PublicStateLabel published={character.published} />
              {character.voiceActorName ? (
                <Text style={styles.voiceActorName}>
                  CV: {character.voiceActorName}
                </Text>
              ) : null}
              <View style={styles.descriptionWrapper}>
                <Text style={styles.description}>
                  {character.description || ''}
                </Text>
              </View>
              <View style={styles.editButtonWrapper}>
                <EditButton
                  disabled={disabled}
                  onPress={this.handleowardToEditEpisode}
                />
              </View>
            </View>
          </View>
        )}
      </Layout>
    );
  }

  private handleForwardCharacterPublication = () => {
    const {navigation, character} = this.props;
    if (!character) {
      return;
    }
    routers.linkToCharacterFormEditCharacterPublication(navigation, {
      characterId: character.id,
    });
  };

  private handleowardToEditEpisode = () => {
    const {navigation, route, character} = this.props;
    const {projectId} = route.params;
    if (!character) {
      return;
    }
    if (projectId) {
      navigation.navigate('EditProjectCharacter', {
        characterId: character.id,
        projectId,
      });
    } else {
      routers.linkToEditCharacter(navigation, {
        characterId: character.id,
      });
    }
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 16,
    flexDirection: 'row',
  } as ViewStyle,
  imageWrapper: {
    width: 80 + 16,
  } as ViewStyle,
  info: {
    flex: 1,
  } as ViewStyle,
  name: {
    fontSize: 14,
    fontWeight: 'bold',
    paddingVertical: 8,
    color: '#383838',
  } as TextStyle,
  voiceActorName: {
    fontSize: 12,
    color: '#383838',
    marginVertical: 10,
    marginBottom: 0,
  } as TextStyle,
  descriptionWrapper: {
    marginTop: 10,
    marginBottom: 32,
  } as ViewStyle,
  description: {
    fontSize: 14,
    color: '#383838',
  } as TextStyle,
  editButtonWrapper: {
    alignItems: 'flex-end',
  } as ViewStyle,
  editButton: {
    width: 100,
    height: 28,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#383838',
    borderWidth: 1,
    borderRadius: 16,
    marginHorizontal: 5,
  } as ViewStyle,
  editButtonLabel: {
    marginLeft: 4,
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
