import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import NewWriterUserSession from '../../../../containers/writer/user_sessions/New';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="NewWriterUserSession">
      <RootStack.Screen
        name="NewWriterUserSession"
        component={NewWriterUserSession}
        options={{title: 'ログイン'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
