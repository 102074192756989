import {
  enabledCommercialVideo,
  enabledMakerPro,
  enabledPartnerProgram,
} from '../helpers/features';

enum Setting {
  SETTINGS_ACCOUNT = 'アカウント設定',
  SETTINGS_BANK_ACCOUNT = '受取口座設定',
  SETTINGS_TRANSFER_HISTORY = '振込履歴',
  SETTINGS_USER_IDENTIFICATION = '本人確認',
  VIDEO_SHARE_SERVICE_ACCOUNTS = '動画サービス連携',
  PROFILE = 'プロフィール',
  SETTINGS_NOTIFICATIONS = '通知設定',
  ANNOUNCEMENT = 'お知らせ',
  ANALYTICS = '投稿したストーリーの分析',
  SETTINGS_BILLING_PAYMENT_METHODS = 'お支払い方法',
  ACCOUNT_BILLING_HISTORY = 'お支払い履歴',
  HELPS = 'ヘルプ',
  POLICIES_PRIVACY = 'プライバシーポリシー',
  POLICIES_TERMS = '利用規約',
  PARTNER_PROGRAM_POLICIES_TERMS = 'パートナープログラム約款',
  POLICIES_SPECIFIED_COMMERCIAL_TRANSACTION_LAW = '特定商取引法に基づく表示',
  POLICIES_SETTLEMENT_LAW = '資金決済法に基づく表示',
  CONTACTS = 'お問い合わせ',
  DELETE_ACCOUNT = '退会',
  LOGOUT = 'ログアウト',
}

export default Setting;

export function getSetting() {
  return [
    Setting.SETTINGS_NOTIFICATIONS,
    Setting.SETTINGS_ACCOUNT,
    ...(enabledCommercialVideo ? [Setting.VIDEO_SHARE_SERVICE_ACCOUNTS] : []),
    ...(enabledMakerPro
      ? [
          Setting.SETTINGS_BILLING_PAYMENT_METHODS,
          Setting.ACCOUNT_BILLING_HISTORY,
        ]
      : []),
    ...(enabledPartnerProgram
      ? [
          Setting.SETTINGS_BANK_ACCOUNT,
          Setting.SETTINGS_TRANSFER_HISTORY,
          Setting.SETTINGS_USER_IDENTIFICATION,
        ]
      : []),
    // Setting.PROFILE,
    // Setting.ANNOUNCEMENT,
    // Setting.ANALYTICS,
    // Setting.HELPS,
    Setting.POLICIES_PRIVACY,
    Setting.POLICIES_SPECIFIED_COMMERCIAL_TRANSACTION_LAW,
    // Setting.POLICIES_SETTLEMENT_LAW,
    Setting.POLICIES_TERMS,
    Setting.PARTNER_PROGRAM_POLICIES_TERMS,
    // Setting.CONTACTS,
    Setting.DELETE_ACCOUNT,
    Setting.LOGOUT,
  ];
}
