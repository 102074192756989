import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

interface Props {}

const UserExtensionMissionListHeader: React.FunctionComponent<
  Props
> = props => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>ミッションをクリアして機能を拡張しよう！</Text>
    </View>
  );
};

export default React.memo(UserExtensionMissionListHeader);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 15,
  } as ViewStyle,
  text: {
    color: '#383838',
    fontSize: 13,
  } as TextStyle,
});
