import {Dispatch} from 'redux';

import AppState from '../../../reducers/AppState';

import StoryAggregation from '../../../../domain/value_objects/summary/StoryAggregation';

import NetStoryAggregationsRepository from '../../../../data/repositories/writer/summary/NetStoryAggregationsRepository';

export interface Params {
  from?: Date | undefined;
  to?: Date | undefined;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<Array<StoryAggregation>> {
  return async (
    dispatch: Dispatch<any>,
    getState: () => AppState,
  ): Promise<Array<StoryAggregation>> => {
    const repository = new NetStoryAggregationsRepository();
    const payload = await repository.findBy(params);
    return payload;
  };
}
