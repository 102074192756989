import {Platform} from 'react-native';
import Config from 'react-native-config';

const enabled = (key: string) =>
  Platform.OS === 'web' ? process.env[key] === 'true' : Config[key] === 'true';

export const enabledMakerPro = enabled('REACT_APP_FEATURES_MAKER_PRO');

export const enabledCommercialVideo = enabled(
  'REACT_APP_FEATURES_COMMERCIAL_VIDEO',
);

export const enabledCommercialVideoDownload = enabled(
  'REACT_APP_FEATURES_COMMERCIAL_VIDEO_DOWNLOAD',
);

export const enabledFullVideo = enabled('REACT_APP_FEATURES_FULL_VIDEO');

export const enabledExport = enabled('REACT_APP_FEATURES_EXPORT');

export const enabledShop = enabled('REACT_APP_FEATURES_SHOP');

export const enabledPartnerProgram = enabled(
  'REACT_APP_FEATURES_PARTNER_PROGRAM',
);

export const enabledPointPurchase = enabled(
  'REACT_APP_FEATURES_POINT_PURCHASE',
);

export const enabledProject = enabled('REACT_APP_FEATURES_PROJECT');

export const enabledProjectManuscriptEpisode = enabled(
  'REACT_APP_FEATURES_PROJECT_MANUSCRIPT_EPISODE',
);

export const enabledWritersGate = enabled('REACT_APP_FEATURES_WRITERS_GATE');

export const enabledTrimmedSound = enabled('REACT_APP_FEATURES_TRIMMED_SOUND');

export const enabledTrimmedEffect = enabled(
  'REACT_APP_FEATURES_TRIMMED_EFFECT',
);
