import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/descriptive_text_show_scene_command/new/descriptive_texts/Index';

import AppState from '../../../../../reducers/AppState';

import getSceneCommandForm from '../../../../../helpers/getSceneCommandForm';

import indexTextFrames, {
  Params as TextFrameIndexParams,
} from '../../../../../actions/text_frames/index';
import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../../../../actions/scene_command_forms/create';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../../../../actions/scene_command_forms/update';
import showTextFrame from '../../../../../actions/text_frames/show';

import selectEntities from '../../../../../helpers/selectEntities';
import selectEntity from '../../../../../helpers/selectEntity';

import {SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTextsRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormDescriptiveTextShowSceneCommandNewDescriptiveTextsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const {parentSceneCommandId} = route.params;
  const sceneForm = forms.sceneForm;
  const textFramesParams = {};
  const textFrames = selectEntities(
    entities.textFrames,
    queries.textFrames,
    textFramesParams,
  );
  const parentSceneCommandForm = getSceneCommandForm(
    state.forms.sceneCommandForms,
    parentSceneCommandId,
  );
  return {
    navigation,
    currentUserStatus,
    sceneForm,
    textFrames,
    textFramesParams,
    parentSceneCommandForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexTextFrames: (params: TextFrameIndexParams) =>
      dispatch(indexTextFrames(params)),
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
    showTextFrame: (id: number) => dispatch(showTextFrame(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
