import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import ArrowForwardIcon from '../../../icons/ArrowForwardIcon';

interface Props {
  values: string[];
  placeholder: string;
  onPress: () => void;
}

const SelectButton: React.FunctionComponent<Props> = props => {
  const {values, placeholder, onPress} = props;
  return (
    <Pressable onPress={onPress}>
      <View style={styles.container}>
        {values.length > 0 ? (
          <Text style={styles.values} numberOfLines={1}>
            {values.join(',')}
          </Text>
        ) : (
          <Text style={styles.placeholder}>{placeholder}</Text>
        )}
        <ArrowForwardIcon />
      </View>
    </Pressable>
  );
};

export default React.memo(SelectButton);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#efefef',
    paddingRight: 8,
  } as ViewStyle,
  values: {
    fontSize: 14,
    color: '#383838',
    marginVertical: 8,
    marginRight: 8,
  } as TextStyle,
  placeholder: {
    fontSize: 14,
    color: '#999',
    marginVertical: 8,
  } as TextStyle,
});
