import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/background_show_scene_command/new/background_base_effects/Index';

import AppState from '../../../../../reducers/AppState';

import indexBaseEffects, {
  Params as BaseEffectIndexParams,
} from '../../../../../actions/base_effects/index';
import indexEffects, {
  Params as EffectIndexParams,
} from '../../../../../actions/effects/index';
import updateSceneForm, {
  Params as SceneFormUpdateParams,
} from '../../../../../actions/scene_forms/update';

import selectEntities from '../../../../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries, forms} = state;
  const sceneForm = forms.sceneForm;
  const baseEffectsParams: BaseEffectIndexParams = {
    allEffectCategoryId: 26,
    sort: 'category_order',
  };
  const baseEffects = selectEntities(
    entities.baseEffects,
    queries.baseEffects,
    baseEffectsParams,
  );
  return {
    navigation,
    sceneForm,
    baseEffects,
    baseEffectsParams,
    baseEffectQueries: queries.baseEffects,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexBaseEffects: (params: BaseEffectIndexParams) =>
      dispatch(indexBaseEffects(params)),
    indexEffects: (params: EffectIndexParams) => dispatch(indexEffects(params)),
    updateSceneForm: (params: SceneFormUpdateParams) =>
      dispatch(updateSceneForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
