import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';
import numeral from 'numeral';

import EpisodeInfo from './EpisodeInfo';

import PlayIcon from '../../shared/icons/PlayIcon';
import PenIcon from '../../shared/icons/PenIcon';

import {colors} from '../../../styles/variables';

import Episode from '../../../../domain/entities/Episode';

interface Props {
  episode: Episode;
  disabledPreview: boolean;
  onFowardToEditEpisode: () => void;
  onForwardToEpisodePreview: () => void;
}

const EpisodeDetail: React.FunctionComponent<Props> = (props: Props) => {
  const {
    episode,
    disabledPreview,
    onFowardToEditEpisode,
    onForwardToEpisodePreview,
  } = props;
  return (
    <View style={styles.container}>
      <EpisodeInfo
        episode={episode}
        titleStyle={styles.episodeInfoTitle}
        freeLabelStyle={styles.episodeInfoFreeLabelStyle}
      />
      <View style={styles.bottom}>
        <View>
          <View style={styles.bottomTextRow}>
            <Text style={styles.bottomTextLabel}>合計文字数</Text>
            <Text style={styles.bottomText}>：</Text>
            <Text style={styles.bottomText}>
              {numeral(episode.getTotalTextLength() || 0).format('0,0')}字
            </Text>
          </View>
          <View style={styles.bottomTextRow}>
            <Text style={styles.bottomTextLabel}>合計Tap数</Text>
            <Text style={styles.bottomText}>：</Text>
            <Text style={styles.bottomText}>
              {numeral(episode.tapCountWithoutClear || 0).format('0,0')}tap
            </Text>
          </View>
          {/* episode.getSpeechTextLengthRate() < MIN_SPEECH_TEXT_LENGTH_RATE ? (
            <ImageBackground
              source={convertImageSource(SpeechBalloonRedUri)}
              style={{
                width: 196,
                height: 24,
                position: "absolute",
                bottom: -28,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  color: "#f23406",
                  fontSize: 10,
                }}
              >
                <AlertIcon color={"#f23406"} />
                セリフ割合が{numeral(MIN_SPEECH_TEXT_LENGTH_RATE).format("0%")}
                を下回っております
              </Text>
            </ImageBackground>
              ) : null */}
        </View>
        <View style={styles.buttons}>
          <TouchableOpacity
            activeOpacity={0.6}
            onPress={disabledPreview ? undefined : onForwardToEpisodePreview}>
            <View
              style={[
                styles.previewButton,
                disabledPreview && styles.previewButtonDisable,
              ]}>
              <PlayIcon size={13} color={'white'} />
              <Text style={styles.previewButtonLabel}>プレビュー</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity activeOpacity={0.6} onPress={onFowardToEditEpisode}>
            <View style={styles.editButton}>
              <PenIcon size={13} color={'#383838'} />
              <Text style={styles.editButtonLabel}>編集</Text>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default onlyUpdateForKeys(['episode', 'disabledPreview'])(EpisodeDetail);

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderBottomColor: colors.powderGray,
    borderBottomWidth: 1,
  } as ViewStyle,
  episodeInfoTitle: {
    fontSize: 17,
  } as TextStyle,
  episodeInfoFreeLabelStyle: {
    justifyContent: 'flex-start',
  } as ViewStyle,
  bottom: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 16,
    marginBottom: 16,
  } as ViewStyle,
  bottomTextRow: {
    flexDirection: 'row',
  } as ViewStyle,
  bottomTextLabel: {
    fontSize: 11,
    color: '#222222',
    width: 55,
  } as ViewStyle,
  bottomText: {
    fontSize: 11,
    color: '#222222',
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
  } as ViewStyle,
  previewButton: {
    width: 100,
    height: 28,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#383838',
    borderRadius: 16,
    marginHorizontal: 5,
  } as ViewStyle,
  previewButtonDisable: {
    backgroundColor: '#999999',
  } as ViewStyle,
  previewButtonLabel: {
    marginLeft: 4,
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  editButton: {
    width: 100,
    height: 28,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#383838',
    borderWidth: 1,
    borderRadius: 16,
    marginHorizontal: 5,
  } as ViewStyle,
  editButtonLabel: {
    marginLeft: 4,
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
