import * as React from 'react';
import {camelizeKeys} from 'humps';

import CompleteMessage from './components/CompleteMessage';
import CloseButton from './components/CloseButton';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import TransferRequest from '../../../../domain/entities/TransferRequest';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Complete: React.FC<Props> = props => {
  const {navigation} = props;
  const [transferRequest, setTransferRequest] =
    React.useState<TransferRequest | null>(null);
  const onPress = React.useCallback(() => {
    navigation.pop();
  }, []);
  React.useEffect(() => {
    TapNovelRestApi.get('/api/writer/transfer_request').then(response => {
      setTransferRequest(camelizeKeys(response.body) as TransferRequest);
    });
  }, []);
  return (
    <Layout
      title={'振込申請'}
      scrollable={false}
      close={true}
      containerStyle={{backgroundColor: '#fafafa'}}>
      {transferRequest && <CompleteMessage transferRequest={transferRequest} />}
      <CloseButton onPress={onPress} />
    </Layout>
  );
};

export default React.memo(Complete);
