import * as React from 'react';

import Tabs from './partials/Tabs';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {WritersGateManuscriptsRouteProp} from '../../../navigators/RouteProps';

import OfferManuscript from '../../../../domain/entities/writers_gate/OfferManuscript';
import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: WritersGateManuscriptsRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {
  children?: React.ReactNode;
}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const [unlockedOfferManuscripts, setUnlockedOfferManuscripts] =
    React.useState<OfferManuscript[] | null>(null);
  const [lockedOfferManuscripts, setLockedOfferManuscripts] = React.useState<
    OfferManuscript[] | null
  >(null);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<OfferManuscript[]>(
      `/api/writer/writers_gate/offer_manuscripts`,
      {submitted: true, locked: false},
    )
      .then(result => {
        setUnlockedOfferManuscripts(result.body);
      })
      .catch(error => {
        if (error.status === 403) {
          navigation.navigate('Home', {});
        }
      });
    TapNovelTypedRestApi.get<OfferManuscript[]>(
      `/api/writer/writers_gate/offer_manuscripts`,
      {submitted: true, locked: true},
    )
      .then(result => {
        setLockedOfferManuscripts(result.body);
      })
      .catch(error => {
        if (error.status === 403) {
          navigation.navigate('Home', {});
        }
      });
  }, []);
  return (
    <Layout
      title={'提出管理'}
      scrollable={false}
      navigation={navigation}
      back={true}>
      {unlockedOfferManuscripts && lockedOfferManuscripts ? (
        <Tabs
          unlockedOfferManuscripts={unlockedOfferManuscripts}
          lockedOfferManuscripts={lockedOfferManuscripts}
        />
      ) : null}
    </Layout>
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(Index, areEqual);
