import * as React from 'react';
import {
  GestureResponderEvent,
  Image,
  ImageResizeMode,
  ImageStyle,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import {equalForKeys} from '../../../helpers/equalForKeys';

interface Props<T> {
  item: T;
  width: number;
  zoom?: number;
  aspectRatio?: number;
  resizeMode?: ImageResizeMode;
  containerStyle?: StyleProp<ViewStyle>;
  imageMaskStyle?: StyleProp<ViewStyle>;
  frame?: boolean;
  extraData?: boolean;
  onSelectItem: (item: T) => void;
  onLongPressItem?: (item: T) => void;
  imageUrlExtractor: (item: T, width: number) => string;
  labelExtractor?: (item: T) => string | null;
  renderNoImage?: (item: T, width: number) => React.ReactNode;
  renderCustom?: (item: T, width: number) => React.ReactNode;
  renderCustomLabel?: (item: T, width: number) => React.ReactNode;
  imageFrameStyleExtractor?: (item: T) => StyleProp<ViewStyle>;
  imageStyleExtractor?: (item: T) => StyleProp<ImageStyle>;
  disabledItem?: (item: T) => boolean;
}

interface State {
  width: number;
}

export default class ImageGridListItem<T> extends React.Component<
  Props<T>,
  State
> {
  public static getDerivedStateFromProps<T>(
    nextProps: Readonly<Props<T>>,
    prevState: State,
  ): Partial<State> | null {
    const {width} = nextProps;
    return {width};
  }

  constructor(props: Props<T>) {
    super(props);
    const {width} = this.props;
    this.state = {width};
  }

  public shouldComponentUpdate(
    nextProps: Readonly<Props<T>>,
    nextState: Readonly<State>,
  ): boolean {
    if (
      !equalForKeys(this.props, nextProps, [
        'item',
        'width',
        'zoom',
        'extraData',
      ])
    ) {
      return true;
    }
    if (!equalForKeys(this.state, nextState)) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {
      item,
      resizeMode,
      containerStyle,
      imageMaskStyle,
      frame,
      onSelectItem,
      onLongPressItem,
      imageUrlExtractor,
      labelExtractor,
      renderNoImage,
      renderCustom,
      renderCustomLabel,
      imageFrameStyleExtractor,
      imageStyleExtractor,
      disabledItem,
    } = this.props;
    const {width} = this.state;
    const uri = imageUrlExtractor(item, width);
    const zoom = this.props.zoom || 1;
    const aspectRatio = this.props.aspectRatio || 1.24;
    const containerSizeStyle = {
      height: width / aspectRatio + (labelExtractor ? 11 : 0),
      width: width,
    };
    const imageStyle = {
      aspectRatio,
      height: (width * zoom) / aspectRatio,
      width: width * zoom,
    } as ImageStyle;
    const imageFrameStyle = imageFrameStyleExtractor
      ? imageFrameStyleExtractor(item)
      : undefined;
    const customImageStyle = imageStyleExtractor
      ? imageStyleExtractor(item)
      : undefined;
    const label = labelExtractor && labelExtractor(item);
    const disabled = disabledItem ? disabledItem(item) : false;
    return (
      <TouchableOpacity
        style={[
          styles.container,
          containerSizeStyle,
          containerStyle,
          disabled ? {opacity: 0.5} : null,
        ]}
        activeOpacity={0.9}
        disabled={disabled}
        onPress={() => onSelectItem(item)}
        onLongPress={onLongPressItem ? () => onLongPressItem(item) : undefined}>
        <View
          style={[
            styles.imageWrapper,
            containerSizeStyle,
            frame === false ? undefined : styles.imageFrame,
            imageFrameStyle,
          ]}>
          <View
            style={[
              styles.imageMask,
              imageMaskStyle,
              imageStyle,
              customImageStyle,
            ]}>
            {uri !== '' ? (
              <Image
                resizeMode={resizeMode}
                style={imageStyle}
                source={{
                  uri,
                  headers: {Accept: 'image/webp,image/apng,*/*'},
                }}
              />
            ) : renderNoImage ? (
              renderNoImage(item, width)
            ) : null}
          </View>
          {label && <Text style={styles.label}>{label}</Text>}
          {renderCustomLabel && renderCustomLabel(item, width)}
          {renderCustom && renderCustom(item, width)}
        </View>
      </TouchableOpacity>
    );
  }
}

export const ITEM_MARGIN_HORIZONTAL = 2;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: ITEM_MARGIN_HORIZONTAL,
    marginVertical: 4,
  } as ViewStyle,
  imageFrame: {
    backgroundColor: 'white',
  } as ViewStyle,
  imageMask: {
    alignItems: 'center',
    overflow: 'hidden',
  } as ViewStyle,
  imageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  label: {
    color: '#666666',
    fontSize: 12,
    lineHeight: 12,
    fontWeight: 'bold',
    marginTop: 4,
    textAlign: 'center',
  } as TextStyle,
});
