export default class StoryAggregation {
  public originalImageUrl = '';

  constructor(
    public id: number,
    public from: Date | null,
    public to: Date | null,
    public title: string,
    public hasCoverImage: boolean,
    public originalCoverImageUrl: string,
    public storyPageViewCount: number,
    public storyPageViewUserCount: number,
    public episodePageViewCount: number,
    public episodePageViewUniqueConsumerUserCount: number,
    public contentFulfillmentRate: number,
    public publishedEpisodesCount: number,
    public favoritedConsumerUsersCount: number,
    public episodePageViewRate: number,
    public genreAverageEpisodePageViewRate: number,
  ) {}
}
