import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {WebView} from 'react-native-webview';
import {camelizeKeys} from 'humps';

import {FormValues, CONCACT_CATEGORY_IDS} from './FormData';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import Select from '../../../shared/forms/Select';
import TextArea from '../../../shared/forms/TextArea';
import LabelWithOption from '../../../shared/forms/LabelWithOption';

import {formStyles} from '../../../../styles/variables';

import Category from '../../../../../domain/entities/writer/faq/Category';

import TapNovelRestApi from '../../../../../data/data_stores/net/TapNovelRestApi';

interface Props {
  hidden?: boolean;
  onPressConfirm: (formValues: FormValues) => void;
}

const Form: React.FC<Props> = props => {
  const {hidden, onPressConfirm} = props;
  const [noteHtmlHeight, setNoteHtmlHeight] = React.useState(0);
  const [categories, setCategories] = React.useState<Array<Category> | null>(
    null,
  );
  const [selectedCategory, setSelectedCategory] =
    React.useState<Category | null>(null);
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');
  React.useEffect(() => {
    TapNovelRestApi.get('/api/writer/faq/categories')
      .then(response => {
        setCategories(camelizeKeys(response.body) as Array<Category>);
      })
      .catch(() => {});
  }, []);
  const categoryItems = React.useMemo(() => {
    if (!categories) {
      return null;
    }
    return [
      {value: '', label: '選択してください'},
      ...categories.map(category => ({
        value: category.id,
        label: category.name,
      })),
    ];
  }, [categories]);
  const onLoad = React.useCallback((event: any) => {
    const target = event.target as any;
    if (target && target.contentDocument) {
      const noteHtmlHeight =
        target.contentDocument.querySelector('#main').clientHeight;
      setNoteHtmlHeight(noteHtmlHeight);
    }
  }, []);
  const onValueChangeCategoryId = React.useCallback(
    (value: string | number) => {
      setSelectedCategory(
        categories?.find(c => c.id === Number(value)) || null,
      );
    },
    [categories],
  );
  if (!categoryItems) {
    return null;
  }
  const valid =
    selectedCategory &&
    (CONCACT_CATEGORY_IDS.includes(selectedCategory.id)
      ? true
      : title.length > 0 && title.length <= MAX_TITLE_LENGTH) &&
    content.length > 0 &&
    content.length <= MAX_CONTENT_LENGTH;
  const onPressSubmit = () => {
    if (selectedCategory) {
      onPressConfirm({title, content, selectedCategory});
    }
  };
  return (
    <View style={[styles.container, hidden ? {display: 'none'} : null]}>
      <View style={styles.formGroup}>
        <LabelWithOption title={'お問い合わせカテゴリ'} />
        <Select
          value={selectedCategory?.id || ''}
          items={categoryItems}
          onValueChange={onValueChangeCategoryId}
          confirmText={'決定'}
          cancelText={'キャンセル'}
          rightIcon={<View style={styles.arrowIcon} />}
        />
      </View>
      {selectedCategory && (
        <>
          {selectedCategory.note && (
            <View style={{marginHorizontal: 16}}>
              <WebView
                source={{
                  html: generateNoteHtml(selectedCategory.note),
                }}
                style={{
                  maxHeight: noteHtmlHeight,
                  marginBottom: 16,
                }}
                onLoad={onLoad}
                scrollEnabled={false}
              />
            </View>
          )}
          {CONCACT_CATEGORY_IDS.includes(selectedCategory.id) ? null : (
            <View style={styles.formGroup}>
              <LabelWithOption
                title={`お問い合わせタイトル（${MAX_TITLE_LENGTH}文字まで）`}
                length={title.length}
                maxLength={MAX_TITLE_LENGTH}
              />
              <View
                style={{borderBottomColor: '#efefef', borderBottomWidth: 1}}>
                <TextInput
                  style={formStyles.formInput}
                  keyboardType={'email-address'}
                  autoCapitalize={'none'}
                  placeholder={'お問い合わせタイトルを入力'}
                  placeholderTextColor={'#999'}
                  value={title}
                  onChangeText={setTitle}
                  returnKeyType={'done'}
                />
              </View>
            </View>
          )}
          <View style={[styles.formGroup, styles.formGroupTextArea]}>
            <LabelWithOption
              title={`お問い合わせ内容（${MAX_CONTENT_LENGTH}文字まで）`}
              length={content.length}
              maxLength={MAX_CONTENT_LENGTH}
            />
            <TextArea
              style={[formStyles.formTextArea, styles.formTextArea]}
              value={content}
              placeholder={'お問い合わせ内容を入力'}
              onChangeText={setContent}
            />
          </View>
          {CONCACT_CATEGORY_IDS.includes(selectedCategory.id) ? null : (
            <Text
              style={{
                color: '#f23406',
                fontSize: 13,
                textAlign: 'center',
                marginVertical: 8,
              }}>
              TapNovelMakerでは、お問い合わせ内容を公開し、{'\n'}
              他の利用者様にも回答いただける形式をとっております。
            </Text>
          )}
          <View style={styles.submitButton}>
            <PrimaryButton disabled={!valid} onPress={onPressSubmit}>
              内容を確認
            </PrimaryButton>
          </View>
        </>
      )}
    </View>
  );
};

const MAX_TITLE_LENGTH = 80;
const MAX_CONTENT_LENGTH = 400;

export default React.memo(Form);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'white',
  } as ViewStyle,
  headlineText: {
    color: '#383838',
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
  formGroup: {
    margin: 16,
  } as ViewStyle,
  formGroupHelp: {
    marginTop: 41,
  } as ViewStyle,
  formGroupTextArea: {
    marginBottom: 0,
  } as ViewStyle,
  submitButton: {
    alignItems: 'center',
    marginVertical: 16,
  } as ViewStyle,
  helpText: {
    color: '#222222',
    fontSize: 15,
    lineHeight: 20,
  } as TextStyle,
  helpLink: {
    borderBottomWidth: 1,
    borderBottomColor: '#383838',
  } as ViewStyle,
  helpLinkText: {
    color: '#222222',
    fontWeight: 'bold',
  } as TextStyle,
  helpMessage: {
    color: '#383838',
    fontSize: 15,
    lineHeight: 17,
  } as TextStyle,
  contactMessageWrapper: {
    marginTop: 16,
  } as ViewStyle,
  contactMessage: {
    color: '#222222',
    fontSize: 15,
    lineHeight: 20,
  } as TextStyle,
  formTextArea: {
    height: 174,
  } as TextStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
    right: 3,
  } as ViewStyle,
});

const generateNoteHtml = (note: string) => {
  return `
  <html>
    <head>
      <style>
        html, body {
          margin: 0;
          padding: 0;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
            "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overscroll-behavior-y: contain;
        }

        a, p {
          color: #222;
          margin: 0;
          padding: 0;
          border: 0;
        }
        a {
          font-weight: bold;

        }
        p {
          font-size: 14px;
          font-weight: 400;
        }
      </style>
    </head>
    <body>
      <div id="main">
        <p>
          ${note}
        </p>
      </div>
    </body>
  </html>
`;
};
