import * as React from 'react';
import {
  ImageStyle,
  ImageSourcePropType,
  ScrollView,
  StyleSheet,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import Input from '../../../shared/forms/Input';
import MultiSwitch from '../../../shared/forms/MultiSwitch';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import TooltipModal from '../../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../../shared/KeyboardSpacer';
import AlertModal from '../../../shared/modals/AlertModal';
import UserStatusContext from '../../../shared/user_status/UserStatusContext';

import {formatErrorMessages} from '../../../../helpers/errorMessages';
import {sendDefaultCustomEvent} from '../../../../helpers/analytics';

import {colors, formStyles} from '../../../../styles/variables';

import NetEpisodeVideoExportJobExecutionsRepository from '../../../../../data/repositories/writer/NetEpisodeVideoExportJobExecutionsRepository';

const requiredInputStyle = {backgroundColor: '#fff1e9'};

type TooltipModalType = 'title' | 'auto_play_speed' | 'image' | 'opening_movie';

const TypeToTooltipInfo = {
  title: {
    title: '動画タイトル',
    description: '動画ファイル名をつけることができます。',
  },
  auto_play_speed: {
    title: '速度',
    description: '自動Tap機能を利用した際の速度に対応しております。',
  },
  image: {
    title: 'サムネイル',
    description: '作成したサムネイルはダウンロードして利用することができます。',
  },
  opening_movie: {
    title: 'モーションロゴ',
    description:
      '動画が始まる前にTapNovelの音声入りアニメーションを表示することができます。',
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
    source?: ImageSourcePropType;
    imageStyle: ImageStyle;
  };
};

export type Fields = 'introduction' | 'catchPhrase';

interface Props {
  episodeId: number;
  orientation?: 'vertical' | 'horizontal';
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmitComplete: () => void;
  onForwardImage: (params: {
    title: string;
    orientation: 'vertical' | 'horizontal';
    autoPlaySpeed: '1.0' | '1.5' | '2.0';
    openingMovie: 'blank' | 'present';
    endingMovie: 'blank' | 'present';
  }) => void;
}

interface State {
  title: string;
  orientation: 'vertical' | 'horizontal';
  autoPlaySpeed: '1.0' | '1.5' | '2.0';
  image: 'blank' | 'present';
  openingMovie: 'blank' | 'present';
  endingMovie: 'blank' | 'present';
  tooltipModalType: TooltipModalType | null;
  visibleCompleteModal: boolean;
  alertMessage?: string;
}

export default class Form extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      title: '',
      orientation: props.orientation || 'vertical',
      autoPlaySpeed: '1.0',
      image: 'blank',
      openingMovie: 'blank',
      endingMovie: 'blank',
      tooltipModalType: null,
      visibleCompleteModal: false,
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {onSubmitComplete} = this.props;
    const {
      title,
      orientation,
      autoPlaySpeed,
      image,
      openingMovie,
      endingMovie,
      tooltipModalType,
      alertMessage,
      visibleCompleteModal,
    } = this.state;
    const modalInfo = tooltipModalType
      ? TypeToTooltipInfo[tooltipModalType]
      : null;
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{paddingBottom: 130}}>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'動画タイトル(最大80文字)'}
              length={title.length}
              maxLength={80}
              requiredOrOptional={'required'}
              entered={title.length > 0 && title.length <= 80}
              onPressQuestion={this.handlePressQuestionTitle}
            />
            <Input
              style={[
                {marginBottom: 3, marginTop: 8},
                title.length === 0
                  ? requiredInputStyle
                  : this.validTitle()
                  ? undefined
                  : styles.inputError,
              ]}
              value={title}
              placeholder={'動画タイトルを入力'}
              onChangeText={this.handleChangeTitle}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption title={'向き'} />
            <MultiSwitch
              items={ORIENTATION_ITEMS}
              value={orientation}
              onSelectItem={this.handleChangeOrientation}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'速度'}
              onPressQuestion={this.handlePressQuestionAutoPlaySpeed}
            />
            <MultiSwitch
              items={AUTO_PLAY_SPEED_ITEMS}
              value={autoPlaySpeed}
              onSelectItem={this.handleChangeAutoPlaySpeed}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'サムネイル'}
              onPressQuestion={this.handlePressQuestionImage}
            />
            <MultiSwitch
              items={IMAGE_ITEMS}
              value={image}
              onSelectItem={this.handleChangeImage}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'モーションロゴ'}
              onPressQuestion={this.handlePressQuestionOpeningMovie}
            />
            <MultiSwitch
              items={OPENING_MOVIE_ITEMS}
              value={openingMovie}
              onSelectItem={this.handleChangeOpeningMovie}
            />
          </View>
          <UserStatusContext.Consumer>
            {userStatusContext =>
              userStatusContext.currentUser?.isGradeBlack() ? (
                <View style={formStyles.formGroup}>
                  <LabelWithOption title={'PR動画'} />
                  <MultiSwitch
                    items={ENDING_MOVIE_ITEMS}
                    value={endingMovie}
                    onSelectItem={this.handleChangeEndingMovie}
                  />
                </View>
              ) : null
            }
          </UserStatusContext.Consumer>

          {modalInfo ? (
            <TooltipModal
              visible={true}
              title={modalInfo.title}
              description={modalInfo.description}
              imageStyle={modalInfo.imageStyle}
              source={modalInfo.source}
              onCloseModal={this.handleCloseModal}></TooltipModal>
          ) : null}
          <KeyboardSpacer />
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }}>
          <View style={styles.button}>
            <PrimaryButton
              disabled={this.disabledButton()}
              onPress={this.handleSubmit}>
              {image === 'blank' ? '動画を作成する' : '次へ'}
            </PrimaryButton>
          </View>
        </View>
        {alertMessage && (
          <AlertModal
            visible={true}
            onCloseModal={() => {
              this.setState({alertMessage: undefined});
            }}>
            {alertMessage}
          </AlertModal>
        )}
        <TooltipModal
          visible={visibleCompleteModal}
          title={'動画化依頼を受け付けました'}
          description={
            '作品の動画化には数時間ほどかかります。\nいましばらくお待ち下さい。'
          }
          buttonText={'閉じる'}
          onCloseModal={this.handleCloseCompleteModal}
        />
      </View>
    );
  }

  private handleChangeTitle = (title: string) => {
    if (this.mounted) {
      this.setState({title});
    }
  };

  private validTitle = () => {
    const {title} = this.state;
    return title.length <= 80;
  };

  private handleChangeOrientation = (item: {label: string; value: string}) => {
    const value = item.value;
    if (value === 'vertical' || value === 'horizontal') {
      this.setState({orientation: value});
    }
  };

  private handleChangeAutoPlaySpeed = (item: {
    label: string;
    value: string;
  }) => {
    const value = item.value;
    if (value === '1.0' || value === '1.5' || value === '2.0') {
      this.setState({autoPlaySpeed: value});
    }
  };

  private handleChangeImage = (item: {label: string; value: string}) => {
    const value = item.value;
    if (value === 'blank' || value === 'present') {
      this.setState({image: value});
    }
  };

  private handleChangeOpeningMovie = (item: {label: string; value: string}) => {
    const value = item.value;
    if (value === 'blank' || value === 'present') {
      this.setState({openingMovie: value});
    }
  };

  private handleChangeEndingMovie = (item: {label: string; value: string}) => {
    const value = item.value;
    if (value === 'blank' || value === 'present') {
      this.setState({endingMovie: value});
    }
  };

  private handleSubmit = () => {
    const {episodeId, setLoading, onForwardImage} = this.props;
    const {
      title,
      orientation,
      autoPlaySpeed,
      image,
      openingMovie,
      endingMovie,
    } = this.state;
    if (image === 'present') {
      onForwardImage({
        title,
        orientation,
        autoPlaySpeed,
        openingMovie,
        endingMovie,
      });
      return;
    }
    setLoading(true);
    new NetEpisodeVideoExportJobExecutionsRepository()
      .create({
        episodeVideoExportJobExecution: {
          episodeId,
          title,
          orientation,
          commercial: false,
          autoPlaySpeed,
          openingMovie: openingMovie === 'present',
          endingMovie: endingMovie === 'present',
        },
      })
      .then(response => {
        sendDefaultCustomEvent({
          resourceType: `${orientation}_full_video`,
          resourceId: response.id,
          resourceName: title,
          actionName: 'create',
        });
        setLoading(false);
        this.setState({visibleCompleteModal: true});
      })
      .catch(error => {
        this.setState({alertMessage: formatErrorMessages({}, error)});
        setLoading(false);
      });
  };

  private disabledButton = (): boolean => {
    const {title} = this.state;
    if (!this.validTitle() || !title) {
      return true;
    }
    return false;
  };

  private handlePressQuestionTitle = () => {
    this.setState({tooltipModalType: 'title'});
  };

  private handlePressQuestionAutoPlaySpeed = () => {
    this.setState({tooltipModalType: 'auto_play_speed'});
  };

  private handlePressQuestionImage = () => {
    this.setState({tooltipModalType: 'image'});
  };

  private handlePressQuestionOpeningMovie = () => {
    this.setState({tooltipModalType: 'opening_movie'});
  };

  private handleCloseModal = () => {
    this.setState({tooltipModalType: null});
  };

  private handleCloseCompleteModal = () => {
    const {onSubmitComplete} = this.props;
    this.setState({visibleCompleteModal: false}, () => {
      onSubmitComplete();
    });
  };
}

const ORIENTATION_ITEMS = [
  {label: 'タテ型', value: 'vertical'},
  {label: 'ヨコ型', value: 'horizontal'},
];

const AUTO_PLAY_SPEED_ITEMS = [
  {label: '1.0倍速', value: '1.0'},
  {label: '1.5倍速', value: '1.5'},
  {label: '2.0倍速', value: '2.0'},
];

const IMAGE_ITEMS = [
  {label: '無し', value: 'blank'},
  {label: '有り', value: 'present'},
];

const OPENING_MOVIE_ITEMS = [
  {label: '無し', value: 'blank'},
  {label: '有り', value: 'present'},
];

const ENDING_MOVIE_ITEMS = [
  {label: '無し', value: 'blank'},
  {label: '有り', value: 'present'},
];

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
});
