import SceneCommandMapper, {SceneCommandData} from './SceneCommandMapper';

import CompositeSequenceSceneCommand from '../../../../domain/value_objects/scene_commands/CompositeSequenceSceneCommand';

export interface CompositeSequenceSceneCommandData {
  type: string;
  commands: SceneCommandData[];
}

export default class CompositeSequenceSceneCommandMapper {
  public map(
    obj: CompositeSequenceSceneCommandData,
  ): CompositeSequenceSceneCommand {
    const sceneCommandMapper = new SceneCommandMapper();
    const commands = obj.commands.map(command =>
      sceneCommandMapper.map(command),
    );
    return new CompositeSequenceSceneCommand(commands);
  }
}
