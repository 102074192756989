import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import Preview from './Preview';

import DimensionContext from '../dimension/DimensionContext';

import loadGoogleFont, {
  executeAfterLoadedFont,
} from '../../../helpers/loadGoogleFont';

import ImageTextInfo from '../../../../domain/value_objects/ImageTextInfo';

import File from '../../../../domain/entities/File';

interface Props {
  style?: ViewStyle;
  visible?: boolean;
  backgroundImageUri: string | null;
  characterImageUri?: string | null;
  aspectRatio: number;
  imageTextInfoList: ImageTextInfo[];
  onDrawCanvas?: (file: File | null) => void;
}

export default class PreviewBox extends React.Component<Props> {
  private ref = React.createRef<Preview>();

  public async componentDidMount() {
    if (Platform.OS !== 'web') {
      return;
    }
    executeAfterLoadedFont(() => {
      this.forceUpdate();
    });
    Promise.all(
      Array.prototype.map.call(
        this.props.imageTextInfoList,
        async imageTextInfo => {
          if (imageTextInfo.fontFamily) {
            return loadGoogleFont(imageTextInfo.fontFamily, imageTextInfo.text);
          } else {
            return new Promise(resolve => resolve(null));
          }
        },
      ),
    ).then(() => {
      setTimeout(() => {
        this.forceUpdate();
      }, 1000);
    });
  }

  public render(): React.ReactNode {
    const {
      style,
      visible,
      backgroundImageUri,
      characterImageUri,
      aspectRatio,
      imageTextInfoList,
      onDrawCanvas,
    } = this.props;
    return (
      <View
        style={[
          styles.container,
          style,
          visible
            ? null
            : {
                display: Platform.select({
                  default: 'none',
                  android: undefined,
                }),
                width: 0,
                height: 0,
                flex: 0,
              },
        ]}>
        <DimensionContext.Consumer>
          {context => {
            const width = (context.content.width / 375) * 293;
            return (
              <Preview
                ref={this.ref}
                visible={visible}
                backgroundImageUri={backgroundImageUri}
                characterImageUri={characterImageUri}
                imageTextInfoList={imageTextInfoList}
                width={width}
                height={width / aspectRatio}
                onDrawCanvas={onDrawCanvas}
              />
            );
          }}
        </DimensionContext.Consumer>
      </View>
    );
  }

  public getImageFile = async (): Promise<File | null> => {
    if (!this.ref.current) {
      return null;
    }
    return await this.ref.current.getImageFile();
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
  },
});
