import * as React from 'react';
import {View} from 'react-native';
import {useIsFocused} from '@react-navigation/core';
import DefaultModal from '../../../../shared/modals/DefaultModal';

interface Props {
  title: string;
  footer?: React.ReactNode;
  loading?: boolean;
  onRequestCloseModal: () => void;
  children?: React.ReactNode;
}

const BaseSceneCommandModal: React.FunctionComponent<Props> = props => {
  const {title, footer, children, loading, onRequestCloseModal} = props;
  const isFocused = useIsFocused();
  return (
    <DefaultModal
      title={title}
      visible={isFocused}
      animationType={'none'}
      footer={footer}
      disableCloseByOutsideTouch={true}
      loading={loading}
      onCloseModal={onRequestCloseModal}>
      {children}
      {footer && <View style={{backgroundColor: '#efefef', height: 1}} />}
    </DefaultModal>
  );
};

export default React.memo(BaseSceneCommandModal);
