import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {onlyUpdateForKeys} from 'recompose';

import LinkButton from '../../../../shared/buttons/LinkButton';
import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import DefaultModal from '../../../../shared/modals/DefaultModal';

import {modalStyles} from '../../../../../styles/variables';

interface Props {
  visible: boolean;
  onBlock: () => void;
  onCancel: () => void;
  children?: React.ReactNode;
}

const BlockUserConfirmModal: React.FunctionComponent<Props> = props => {
  const {visible, onBlock, onCancel} = props;
  return (
    <DefaultModal
      style={{width: 311}}
      visible={visible}
      disableCloseByOutsideTouch={true}
      animationType={'none'}
      footer={
        <View style={styles.buttons}>
          <LinkButton style={styles.button} onPress={onCancel}>
            キャンセル
          </LinkButton>
          <PrimaryButton style={styles.button} onPress={onBlock}>
            ブロック
          </PrimaryButton>
        </View>
      }>
      <View style={styles.body}>
        <Text style={[modalStyles.text, styles.message, {fontWeight: 'bold'}]}>
          投稿者をブロックする
        </Text>
        <Text
          style={[modalStyles.text, styles.message, {fontWeight: 'normal'}]}>
          このコメントは非表示となり、この投稿者は今後コメントできなくなります。
        </Text>
        <Text
          style={[modalStyles.text, styles.message, {fontWeight: 'normal'}]}>
          投稿者をブロックしますか？
        </Text>
      </View>
    </DefaultModal>
  );
};

export default onlyUpdateForKeys(['visible'])(BlockUserConfirmModal);

const styles = StyleSheet.create({
  body: {
    marginHorizontal: 10,
    marginTop: 32,
    marginBottom: 16,
  } as ViewStyle,
  button: {
    paddingHorizontal: 5,
    width: 145,
    height: 46,
  } as ViewStyle,
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 32,
  } as ViewStyle,
  message: {
    paddingVertical: 5,
    lineHeight: 25,
    color: '#222',
  } as TextStyle,
});
