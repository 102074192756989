import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {useDispatch} from 'react-redux';
import BottomPrimaryButton from '../../shared/buttons/BottomPrimaryButton';
import Coachmark from '../../shared/coachmark/Coachmark';
import CoachmarkContext from '../../shared/coachmark/CoachmarkContext';
import Step1 from '../../shared/publication_steps/Step1';

import Types from '../../../actions/Types';

interface Props {
  label?: string | null;
  onForwardToNewEpisode: () => void;
}

const NewEpisodeButton: React.FunctionComponent<Props> = props => {
  const {label, onForwardToNewEpisode} = props;
  const dispatch = useDispatch();
  const onRequestClose = React.useCallback(() => {
    dispatch({
      payload: null,
      type: Types.UpdateCoachmarkModal,
    });
  }, []);
  return (
    <View style={styles.container}>
      <CoachmarkContext.Consumer>
        {context => {
          const autoShow = context === 'first_story_created';
          return (
            <Coachmark
              autoShow={autoShow}
              dialog={true}
              coachmarkViewChildren={<Step1 onRequestClose={onRequestClose} />}
              arrowStyle={styles.arrow}>
              <BottomPrimaryButton
                bottomButtonStyle={
                  autoShow
                    ? styles.bottomButtonActive
                    : styles.bottomButtonInactive
                }
                onPress={onForwardToNewEpisode}>
                {label || 'エピソードを追加'}
              </BottomPrimaryButton>
            </Coachmark>
          );
        }}
      </CoachmarkContext.Consumer>
    </View>
  );
};

export default React.memo(NewEpisodeButton);

const baseBottomButtonStyle = {
  paddingTop: 8,
  paddingBottom: 8,
  borderRadius: 2,
  width: undefined,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'rgba(255,255,255,0.9)',
    paddingVertical: 8,
  } as ViewStyle,
  arrow: {
    transform: [{rotate: '10deg'}],
  } as ViewStyle,
  bottomButtonActive: {
    ...baseBottomButtonStyle,
    backgroundColor: 'rgba(255,255,255,0.9)',
  } as ViewStyle,
  bottomButtonInactive: {
    ...baseBottomButtonStyle,
    backgroundColor: 'rgba(255,255,255,0)',
  } as ViewStyle,
});
