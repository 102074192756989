import * as React from 'react';

import PrimaryButton from './PrimaryButton';

interface Props {
  disabled?: boolean;
  buttonSize?: {width: number; height: number};
  children?: React.ReactNode;
  onPress: () => void;
}

const HeaderRightButton: React.FunctionComponent<Props> = props => {
  const {disabled, buttonSize, children, onPress} = props;
  return (
    <PrimaryButton
      buttonSize={buttonSize || BUTTON_SIZE}
      disabled={disabled}
      onPress={onPress}>
      {children}
    </PrimaryButton>
  );
};

export default React.memo(HeaderRightButton);

const BUTTON_SIZE = {
  width: 64,
  height: 32,
};
