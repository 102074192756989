import * as React from 'react';
import {
  View,
  Image,
  ImageStyle,
  PanResponder,
  PanResponderGestureState,
  PanResponderInstance,
  Platform,
  PixelRatio,
  StyleSheet,
  ViewStyle,
} from 'react-native';

import GLSurface from './GLSurface';
import GLImage from './GLImage';

import convertBase64ToFile from './convertBase64ToFile';

import File from '../../../../domain/entities/File';

import {Filter} from '../../../../vendor/react-native-tapnovel-viewer/domain/value_objects/command_options/BackgroundCommandOptions';
import GLFilter from '../../../../vendor/react-native-tapnovel-viewer/presentation/components/shared/GLFilter';

const movementFromZoom = (
  gestureState: PanResponderGestureState,
  dimensions: {width: number; height: number},
  offsets: {x: number; y: number},
  zoom: number,
) => {
  const pxVsMovX = dimensions.width === 0 ? 0 : 1 / dimensions.width;
  const moveX = gestureState.dx * pxVsMovX * zoom;
  const newPosX = parseFloat(`${offsets.x}`) + parseFloat(`${moveX}`);

  const pxVsMovY = dimensions.height === 0 ? 0 : 1 / dimensions.height;
  const moveY = gestureState.dy * pxVsMovY * zoom;
  const newPosY = parseFloat(`${offsets.y}`) + parseFloat(`${moveY}`);
  return {
    x: newPosX,
    y: newPosY,
  };
};

type Props = typeof ImageCrop.defaultProps & {
  uri: string;
  zoom: number;
  imageHeight: number;
  imageWidth: number;
  filter?: Filter;
};

interface State {
  zoom: number;
  backgroundImageCenterX: number;
  backgroundImageCenterY: number;
  canvasCenterX: number;
  canvasCenterY: number;
  currentCapture: string;
}

export default class ImageCrop extends React.PureComponent<Props, State> {
  public static defaultProps = {
    cropWidth: 300,
    cropHeight: 300,
    zoomFactor: 0,
    minZoom: 0,
    maxZoom: 100,
    quality: 1,
    pixelRatio: PixelRatio.get(),
    type: 'jpg',
    format: 'base64',
    filePath: '',
  };

  private panResponder: PanResponderInstance;
  private offsetX = 0;
  private offsetY = 0;
  private zoomLastDistance: number | string | null = null;
  private zoomCurrentDistance: number | string | null = null;

  private containerRef = React.createRef<HTMLDivElement>();
  private ref = React.createRef<typeof GLSurface>();
  private backgroundImageContainerRef = React.createRef<View>();

  constructor(props: Props) {
    super(props);
    this.state = {
      zoom: 1,
      backgroundImageCenterX: 0.5,
      backgroundImageCenterY: 0.5,
      canvasCenterX: 0.5,
      canvasCenterY: 0.5,
      currentCapture: '',
    };
    this.panResponder = PanResponder.create({
      onStartShouldSetPanResponder: (evt, gestureState) => true,
      onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
      onMoveShouldSetPanResponder: (evt, gestureState) => true,
      onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
      onPanResponderTerminationRequest: (evt, gestureState) => false,
      onShouldBlockNativeResponder: (evt, gestureState) => true,

      onPanResponderGrant: (evt, gestureState) => {
        this.offsetX = this.state.canvasCenterX;
        this.offsetY = this.state.canvasCenterY;
        this.zoomLastDistance = 0;
        this.zoomCurrentDistance = 0;
      },

      onPanResponderMove: (evt, gestureState) => {
        const {imageWidth, imageHeight} = this.props;
        const imageAspectRatio = imageWidth / imageHeight;
        const cropWidth =
          imageAspectRatio < 0.6
            ? this.props.cropWidth * (imageAspectRatio + 0.2)
            : this.props.cropWidth;
        const cropHeight =
          imageAspectRatio < 0.6
            ? this.props.cropHeight * (imageAspectRatio + 0.2)
            : this.props.cropHeight;
        const verticallyMode =
          imageWidth / imageHeight > cropWidth / cropHeight;
        const canvasHeight = verticallyMode
          ? cropWidth / (imageWidth / imageHeight)
          : cropHeight;
        const canvasWidth = verticallyMode
          ? canvasHeight * (cropWidth / cropHeight)
          : cropWidth;
        const backgroundImageWidth = cropWidth;
        const backgroundImageHeight = cropWidth / (imageWidth / imageHeight);
        const offsetWidth = verticallyMode ? cropWidth : backgroundImageWidth;
        const offsetHeight = verticallyMode
          ? canvasHeight
          : backgroundImageHeight;

        if (evt.nativeEvent.changedTouches.length <= 1) {
          const trackX =
            (gestureState.dx / this.props.cropWidth) * this.state.zoom;
          const trackY =
            (gestureState.dy / this.props.cropHeight) * this.state.zoom;
          let newPosX = Number(this.offsetX) - Number(trackX);
          let newPosY = Number(this.offsetY) - Number(trackY);
          if (newPosX > 1) newPosX = Number(1);
          if (newPosY > 1) newPosY = Number(1);
          if (newPosX < 0) newPosX = Number(0);
          if (newPosY < 0) newPosY = Number(0);

          const movement = movementFromZoom(
            gestureState,
            {
              width: offsetWidth - canvasWidth,
              height: offsetHeight - canvasHeight,
            },
            {x: this.offsetX, y: this.offsetY},
            this.state.zoom,
          );
          this.setState({
            canvasCenterX: Math.min(
              Math.max(movement.x, (1 - this.state.zoom) / 2),
              (this.state.zoom + 1) / 2,
            ),
            canvasCenterY: Math.min(
              Math.max(movement.y, (1 - this.state.zoom) / 2),
              (this.state.zoom + 1) / 2,
            ),
          });
        } else {
          if (this.zoomLastDistance == 0) {
            const a =
              evt.nativeEvent.changedTouches[0].locationX -
              evt.nativeEvent.changedTouches[1].locationX;
            const b =
              evt.nativeEvent.changedTouches[0].locationY -
              evt.nativeEvent.changedTouches[1].locationY;
            const c = Math.sqrt(a * a + b * b);
            this.zoomLastDistance = c.toFixed(1);
          } else {
            const a =
              evt.nativeEvent.changedTouches[0].locationX -
              evt.nativeEvent.changedTouches[1].locationX;
            const b =
              evt.nativeEvent.changedTouches[0].locationY -
              evt.nativeEvent.changedTouches[1].locationY;
            const c = Math.sqrt(a * a + b * b);
            this.zoomCurrentDistance = c.toFixed(1);
            const zoomCurrentDistance = this.zoomCurrentDistance;
            const nativeEvent = evt.nativeEvent;
            this.backgroundImageContainerRef.current?.measure(
              (_x, _y, _w, _h, pageX, pageY) => {
                const distance =
                  (parseFloat(zoomCurrentDistance) -
                    parseFloat(`${this.zoomLastDistance}`)) /
                  400;
                const zoom = this.state.zoom - distance;
                const x =
                  (nativeEvent.changedTouches[0].pageX -
                    pageX +
                    (nativeEvent.changedTouches[1].pageX - pageX)) /
                  2 /
                  backgroundImageWidth;
                const y =
                  (nativeEvent.changedTouches[0].pageY -
                    pageY +
                    (nativeEvent.changedTouches[1].pageY - pageY)) /
                  2 /
                  backgroundImageHeight;
                this.updateZoom(zoom, {x, y});
                this.zoomLastDistance = zoomCurrentDistance;
              },
            );
          }
        }
      },
    });
  }

  public componentDidMount() {
    this.setupEvent();
  }

  public render(): React.ReactNode {
    return this.wrapContainer(
      <View {...this.panResponder.panHandlers}>{this.renderContainer()}</View>,
    );
  }

  public crop(): Promise<File> {
    const {quality, type, format} = this.props;
    return this.ref.current
      .captureFrame({quality, type, format})
      .then((base64: string) => {
        return convertBase64ToFile(base64, type);
      });
  }

  private wrapContainer = (children: React.ReactNode): React.ReactNode => {
    if (Platform.OS !== 'web') {
      return children;
    }
    return (
      <div ref={this.containerRef} style={{flex: 1, cursor: 'move'}}>
        {children}
      </div>
    );
  };

  private renderContainer = () => {
    const {uri, pixelRatio, filter, imageWidth, imageHeight} = this.props;
    const {
      zoom,
      backgroundImageCenterX,
      backgroundImageCenterY,
      canvasCenterX,
      canvasCenterY,
    } = this.state;
    if (imageWidth === 0 || imageHeight === 0) {
      return null;
    }
    const imageAspectRatio = imageWidth / imageHeight;
    const cropWidth =
      imageAspectRatio < 0.6
        ? this.props.cropWidth * (imageAspectRatio + 0.2)
        : this.props.cropWidth;
    const cropHeight =
      imageAspectRatio < 0.6
        ? this.props.cropHeight * (imageAspectRatio + 0.2)
        : this.props.cropHeight;
    const verticallyMode = imageWidth / imageHeight > cropWidth / cropHeight;
    const canvasHeight = verticallyMode
      ? cropWidth / (imageWidth / imageHeight)
      : cropHeight;
    const canvasWidth = verticallyMode
      ? canvasHeight * (cropWidth / cropHeight)
      : cropWidth;
    const backgroundImageWidth = cropWidth;
    const backgroundImageHeight = cropWidth / (imageWidth / imageHeight);
    const offsetWidth = verticallyMode ? cropWidth : backgroundImageWidth;
    const offsetHeight = verticallyMode ? canvasHeight : backgroundImageHeight;
    return (
      <>
        <View
          ref={this.backgroundImageContainerRef}
          style={styles.backgroundImageContainer}>
          <Image
            source={{uri}}
            style={[
              styles.backgroundImage,
              {
                width: backgroundImageWidth,
                height: backgroundImageHeight,
                transform: [{scale: 1 / zoom}],
                left:
                  (0.5 - backgroundImageCenterX) *
                  (backgroundImageWidth / zoom - backgroundImageWidth),
                top:
                  (0.5 - backgroundImageCenterY) *
                  (backgroundImageHeight / zoom - backgroundImageHeight),
              },
            ]}
            resizeMode={'cover'}
          />
        </View>
        <View
          style={[
            styles.surfaceContainer,
            {
              left:
                ((offsetWidth - canvasWidth) * (canvasCenterX - 0.5)) / zoom +
                (offsetWidth - canvasWidth) / 2,
              top:
                ((offsetHeight - canvasHeight) * (canvasCenterY - 0.5)) / zoom +
                (offsetHeight - canvasHeight) / 2,
              width: canvasWidth,
              height: canvasHeight,
            },
          ]}>
          <GLFilter
            key={JSON.stringify({width: canvasWidth, height: canvasHeight})}
            surfaceRef={this.ref}
            size={{width: canvasWidth, height: canvasHeight}}
            filter={filter}
            useForceGL={true}
            pixelRatio={pixelRatio}>
            <GLImage
              source={{uri}}
              imageSize={{
                height: imageHeight,
                width: imageWidth,
              }}
              width={cropWidth}
              height={cropHeight}
              resizeMode={'cover'}
              zoom={zoom}
              center={[
                (backgroundImageCenterX *
                  backgroundImageWidth *
                  (1 / zoom - 1) +
                  ((offsetWidth - canvasWidth) * (canvasCenterX - 0.5)) / zoom +
                  (offsetWidth - canvasWidth) / 2 +
                  canvasWidth / 2) /
                  (backgroundImageWidth / zoom),
                (backgroundImageCenterY *
                  backgroundImageHeight *
                  (1 / zoom - 1) +
                  ((offsetHeight - canvasHeight) * (canvasCenterY - 0.5)) /
                    zoom +
                  (offsetHeight - canvasHeight) / 2 +
                  canvasHeight / 2) /
                  (backgroundImageHeight / zoom),
              ]}
            />
          </GLFilter>
        </View>
      </>
    );
  };

  private setupEvent = () => {
    if (this.containerRef.current) {
      this.containerRef.current.addEventListener('wheel', this.handleWheel, {
        passive: false,
        capture: true,
      });
      const preventZoomSafari = (e: Event) => e.preventDefault();
      this.containerRef.current.addEventListener(
        'gesturestart',
        preventZoomSafari,
      );
      this.containerRef.current.addEventListener(
        'gesturechange',
        preventZoomSafari,
      );
    }
  };

  private handleWheel = (e: WheelEvent) => {
    e.preventDefault();
    if (!this.containerRef.current) {
      return;
    }
    const zoom = this.state.zoom + e.deltaY / 100;
    const r = this.containerRef.current.getBoundingClientRect();
    const x = (e.clientX - r.left) / r.width;
    const y = (e.clientY - r.top) / r.height;
    this.updateZoom(zoom, {x, y});
  };

  private updateZoom = (zoom: number, point: {x: number; y: number}) => {
    const {
      backgroundImageCenterX,
      backgroundImageCenterY,
      canvasCenterX,
      canvasCenterY,
      zoom: prevZoom,
    } = this.state;
    if (zoom < 0.01) {
      zoom = 0.01;
    }
    if (zoom > 1) {
      zoom = 1;
    }
    if (zoom === prevZoom) {
      return;
    }
    const {x, y} = point;
    const dx = ((x - backgroundImageCenterX) * zoom) / 10;
    const dy = ((y - backgroundImageCenterY) * zoom) / 10;
    this.setState({
      zoom,
      backgroundImageCenterX: Math.min(
        Math.max(backgroundImageCenterX + dx, 0),
        1,
      ),
      backgroundImageCenterY: Math.min(
        Math.max(backgroundImageCenterY + dy, 0),
        1,
      ),
      canvasCenterX: Math.min(
        Math.max(canvasCenterX, (1 - zoom) / 2),
        (zoom + 1) / 2,
      ),
      canvasCenterY: Math.min(
        Math.max(canvasCenterY, (1 - zoom) / 2),
        (zoom + 1) / 2,
      ),
    });
  };
}

const styles = StyleSheet.create({
  backgroundImageContainer: {
    overflow: 'hidden',
    backgroundColor: '#cccccc',
  } as ViewStyle,
  backgroundImage: {
    ...Platform.select({
      web: {opacity: 0.9, filter: 'brightness(75%)'},
      default: {
        opacity: 0.5,
        backgroundColor: 'black',
      },
    }),
  } as ImageStyle,
  surfaceContainer: {
    position: 'absolute',
    top: 0,
    borderWidth: 2,
    borderStyle: 'dotted',
    borderColor: 'white',
    overflow: 'hidden',
  } as ViewStyle,
});
