import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';
import {camelizeKeys} from 'humps';

import Header from './components/Header';
import SearchFormSection from './components/SearchFormSection';
import QuestionListSection from './components/QuestionListSection';
import UnresolveCase from '../questions/components/UnresolveCase';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {FaqHomeRouteProp} from '../../../navigators/RouteProps';

import Question from '../../../../domain/entities/writer/faq/Question';
import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

import TapNovelRestApi from '../../../../data/data_stores/net/TapNovelRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: FaqHomeRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation, currentUser} = props;
  const [resolvedQuestions, setResolvedQuestions] =
    React.useState<Array<Question> | null>(null);
  const [unresolvedQuestions, setUnresolvedQuestions] =
    React.useState<Array<Question> | null>(null);
  useFocusEffect(
    React.useCallback(() => {
      TapNovelRestApi.get('/api/writer/faq/questions', {
        resolved: true,
        perPage: 10,
        sort: 'page_view_desc',
      })
        .then(response => {
          setResolvedQuestions(camelizeKeys(response.body) as Array<Question>);
        })
        .catch(() => {});
      TapNovelRestApi.get('/api/writer/faq/questions', {
        resolved: false,
        perPage: 10,
      })
        .then(response => {
          setUnresolvedQuestions(
            camelizeKeys(response.body) as Array<Question>,
          );
        })
        .catch(() => {});
    }, []),
  );
  const onPressMore = React.useCallback(() => {
    navigation.navigate('FaqQuestions', {});
  }, []);
  return (
    <Layout
      title={'お問い合わせ'}
      navigation={navigation}
      scrollable={true}
      back={true}>
      {resolvedQuestions && unresolvedQuestions ? (
        <>
          <Header />
          <SearchFormSection />
          <QuestionListSection
            title={'よく見られているお問い合わせ'}
            questions={resolvedQuestions}
            onPressMore={onPressMore}
          />
          <QuestionListSection
            title={'回答待ちのお問い合わせ'}
            questions={unresolvedQuestions}
          />
          <UnresolveCase signedIn={!!currentUser} />
        </>
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
