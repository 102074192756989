import Entity from '../Entity';
import NotificationSetting from './NotificationSetting';

export interface WriterUserNotificationSetting {
  id: number | null;
  enabled: boolean | null;
  createdAt: Date | null;
  updatedAt: Date | null;
  writerNotificationSetting: NotificationSetting;
}

export default class UserNotificationSettingsBatch implements Entity {
  constructor(
    public id: number,
    public writerUserNotificationSettings: WriterUserNotificationSetting[],
  ) {}

  public groupedWriterUserNotificationSettings(): WriterUserNotificationSetting[][] {
    const keyToObjs = this.writerUserNotificationSettings.reduce(
      (accumulator, currentValue) => {
        const key =
          currentValue.writerNotificationSetting
            .writerNotificationSettingCategory.id;
        if (!accumulator[`${key}`]) {
          accumulator[`${key}`] = [];
        }
        accumulator[`${key}`].push(currentValue);
        return accumulator;
      },
      {} as {[key: string]: WriterUserNotificationSetting[]},
    );
    return Object.keys(keyToObjs).map(key => {
      return keyToObjs[key];
    });
  }
}
