import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

interface Props {
  style?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
  label: string;
}

const Cell: React.FunctionComponent<Props> = props => {
  const {style, label, labelStyle} = props;
  return (
    <View style={[styles.container, style]}>
      <Text style={[styles.label, labelStyle]}>{label}</Text>
    </View>
  );
};

export default React.memo(Cell);

const styles = StyleSheet.create({
  container: {
    width: 109,
    height: 45,
    justifyContent: 'center',
    borderColor: '#d5d5d5',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderRightWidth: 1,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 20,
    textAlign: 'center',
  } as TextStyle,
});
