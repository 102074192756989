import * as React from 'react';
import {useFocusEffect} from '@react-navigation/native';

import StoryList from './partials/StoryList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';

import {Params as ResponseStoryIndexParams} from '../../../actions/response/stories/index';

import Story from '../../../../domain/entities/response/Story';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  stories: Story[] | null;
  storiesParams: ResponseStoryIndexParams;
}

export interface DispatchProps {
  indexResponseStories: (
    params: ResponseStoryIndexParams,
  ) => Promise<PaginatedResult<Story>>;
}

interface Props extends StateProps, DispatchProps {}

const Index: React.FunctionComponent<Props> = props => {
  const {navigation, stories, storiesParams, indexResponseStories} = props;
  useFocusEffect(
    React.useCallback(() => {
      indexResponseStories(storiesParams);
    }, []),
  );
  const onSelectStory = React.useCallback((story: Story) => {
    navigation.navigate('ResponseStory', {storyId: story.id});
  }, []);
  return (
    <Layout
      title={'レスポンス'}
      navigation={navigation}
      scrollable={false}
      back={true}>
      {stories && <StoryList stories={stories} onSelectStory={onSelectStory} />}
    </Layout>
  );
};

export default React.memo(Index);
