import * as React from 'react';
import {StyleSheet, TextInput, TextStyle, View, ViewStyle} from 'react-native';

import SearchIcon from '../../../../icons/SearchIcon';

interface Props {
  query: string;
  onChangeText: (query: string) => void;
}

const SearchInput: React.FunctionComponent<Props> = props => {
  const {query, onChangeText} = props;
  return (
    <View style={styles.container}>
      <TextInput
        style={styles.textInput}
        value={query}
        clearButtonMode={'while-editing'}
        placeholder={'キーワードで検索'}
        onChangeText={onChangeText}
      />
      <View style={styles.iconWrapper}>
        <View style={styles.icon}>
          <SearchIcon size={15} />
        </View>
      </View>
    </View>
  );
};

export default React.memo(SearchInput);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: 64,
    backgroundColor: '#fafafa',
  } as ViewStyle,
  textInput: {
    flex: 1,
    backgroundColor: '#ffffff',
    borderRadius: 4,
    margin: 16,
    height: 32,
    padding: 8,
    paddingLeft: 32,
  } as TextStyle,
  iconWrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 24,
  } as ViewStyle,
  icon: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
