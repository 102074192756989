import CharacterShowSceneCommandForm from '../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import CharacterHideSceneCommandForm from '../../domain/forms/scene_commands/CharacterHideSceneCommandForm';

import BackgroundMusicShowSceneCommandForm from '../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';

import Position from '../../domain/value_objects/Position';

export type CharacterSceneCommandForm =
  | CharacterShowSceneCommandForm
  | CharacterUpdateSceneCommandForm
  | CharacterHideSceneCommandForm;

export type BackgroundMusicSceneCommandForm =
  | BackgroundMusicShowSceneCommandForm
  | BackgroundMusicHideSceneCommandForm;

export default class SceneFrame {
  public static copy(sceneFrame: SceneFrame): SceneFrame {
    const newSceneFrame = new SceneFrame();
    newSceneFrame.leftCharacter = sceneFrame.leftCharacter;
    newSceneFrame.centerCharacter = sceneFrame.centerCharacter;
    newSceneFrame.rightCharacter = sceneFrame.rightCharacter;
    newSceneFrame.backgroundMusic = sceneFrame.backgroundMusic;
    return newSceneFrame;
  }

  public leftCharacter: CharacterSceneCommandForm | null = null;
  public centerCharacter: CharacterSceneCommandForm | null = null;
  public rightCharacter: CharacterSceneCommandForm | null = null;

  public backgroundMusic: BackgroundMusicSceneCommandForm | null = null;

  public startBGM = false;

  public update(
    position: Position,
    sceneCommandForm: CharacterSceneCommandForm | null,
  ) {
    switch (position) {
      case Position.Left:
        this.leftCharacter = sceneCommandForm;
        break;
      case Position.Center:
        this.centerCharacter = sceneCommandForm;
        break;
      case Position.Right:
        this.rightCharacter = sceneCommandForm;
        break;
      default:
        break;
    }
  }

  public get(position: Position): CharacterSceneCommandForm | null {
    switch (position) {
      case Position.Left:
        return this.leftCharacter;
      case Position.Center:
        return this.centerCharacter;
      case Position.Right:
        return this.rightCharacter;
      default:
        return null;
    }
  }

  public isActive(position: Position): boolean {
    const character = this.get(position);
    if (!character) {
      return false;
    }
    return (
      character instanceof CharacterShowSceneCommandForm ||
      character instanceof CharacterUpdateSceneCommandForm
    );
  }

  public crowded(): boolean {
    return (
      this.isActive(Position.Left) &&
      this.isActive(Position.Center) &&
      this.isActive(Position.Right)
    );
  }

  public setBackgroundMusic(
    backgroundMusic: BackgroundMusicSceneCommandForm | null,
  ) {
    if (!this.playingBGM()) {
      this.startBGM = true;
    }
    this.backgroundMusic = backgroundMusic;
  }

  public playingBGM(): boolean {
    return !!(
      this.backgroundMusic &&
      this.backgroundMusic instanceof BackgroundMusicShowSceneCommandForm
    );
  }
}
