export default function SearchResultWrapper(props: {
  children?: React.ReactNode;
}) {
  return (
    <div
      className={'scrollable'}
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '100%',
        flexDirection: 'column',
      }}>
      {props.children}
    </div>
  );
}
