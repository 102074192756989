import * as React from 'react';
import {StyleSheet, TouchableOpacity, View, ViewStyle} from 'react-native';

import BaseEffectImageSequence from '../../../../../shared/BaseEffectImageSequence';
import RangeSlider from '../../../../../shared/RangeSlider';
import UserStatusContext from '../../../../../shared/user_status/UserStatusContext';

import Effect from '../../../../../../../domain/entities/Effect';

import {enabledTrimmedEffect} from '../../../../../../helpers/features';

import PreviewIcon from '../../../../../shared/icons/PreviewIcon';
import PauseIcon from '../../../../../shared/icons/PauseIcon';

interface Props {
  effect: Effect;
  startIndex?: number;
  endIndex?: number;
  children?: React.ReactNode;
  onSliderTouchEnd?: (low: number, high: number) => void;
}

const EffectView: React.FunctionComponent<Props> = props => {
  const {effect, startIndex, endIndex} = props;
  const [key, setKey] = React.useState<Date | null>(null);
  const [ready, setReady] = React.useState(false);
  const [playing, setPlaying] = React.useState(true);
  const [pause, setPause] = React.useState(false);
  const allUrls = effect.centerPositionedEffect.frameUrls;
  const min = 0;
  const max = allUrls.length;
  const [urls, setUrls] = React.useState(
    extractUrls(
      effect.centerPositionedEffect.frameUrls,
      startIndex === undefined || startIndex === null ? min : startIndex,
      endIndex === undefined || endIndex === null ? max : endIndex,
    ),
  );
  const onPress = React.useCallback(() => {
    if (playing) {
      setPause(!pause);
    } else {
      setPlaying(true);
      setPause(false);
    }
  }, [playing, pause]);
  const onReady = React.useCallback(() => {
    setReady(true);
  }, []);
  const onFinish = React.useCallback(() => {
    setPause(false);
    setPlaying(false);
  }, []);
  const onSliderTouchEnd = React.useCallback((low: number, high: number) => {
    setUrls(extractUrls(effect.centerPositionedEffect.frameUrls, low, high));
    props.onSliderTouchEnd?.(low, high);
  }, []);
  React.useEffect(() => {
    if (playing) {
      setKey(new Date());
    }
  }, [playing]);
  if (!key) {
    return null;
  }
  return (
    <>
      <View style={styles.container}>
        <TouchableOpacity
          style={{flex: 1}}
          onPress={ready ? onPress : undefined}>
          <BaseEffectImageSequence
            key={`${key}`}
            id={effect.id}
            urls={urls}
            size={size}
            loop={false}
            pause={pause}
            onReady={onReady}
            onFinish={onFinish}
          />
          <View
            style={{
              width: '100%',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            {!ready ? null : playing ? (
              pause ? (
                <PauseIcon color={'white'} size={78} />
              ) : null
            ) : (
              <PreviewIcon color={'white'} size={78} />
            )}
          </View>
        </TouchableOpacity>
      </View>
      <UserStatusContext.Consumer>
        {userStatusContext =>
          enabledTrimmedEffect &&
          (userStatusContext.currentUser?.enabledPaidSubscriber ||
            userStatusContext.currentUser?.isGradeBlack()) ? (
            <RangeSlider
              min={min}
              max={max}
              step={1}
              low={
                startIndex === undefined || startIndex === null
                  ? min
                  : startIndex
              }
              high={
                endIndex === undefined || endIndex === null ? max : endIndex
              }
              minRange={1}
              onSliderTouchEnd={onSliderTouchEnd}
            />
          ) : null
        }
      </UserStatusContext.Consumer>
    </>
  );
};

export default React.memo(EffectView);

const width = 320;
const height = 260;
const size = {width, height};

const aspectRatio = width / height;

const styles = StyleSheet.create({
  container: {
    aspectRatio,
    backgroundColor: 'rgba(200, 200, 200, 0.9)',
    height,
    width,
  } as ViewStyle,
});

const extractUrls = (urls: string[], low: number, high: number) => {
  return urls.slice(low, high);
};
