import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';
import {useActionSheet} from '@expo/react-native-action-sheet';

import CommentListItem from './CommentListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import EpisodeComment from '../../../../../domain/entities/EpisodeComment';
import EpisodeCommentReply from '../../../../../domain/entities/EpisodeCommentReply';
import User from '../../../../../domain/entities/consumer/User';
import WriterCurrentUser from '../../../../../domain/entities/writer/CurrentUser';
import EpisodeCommentBlockingUser from '../../../../../domain/entities/EpisodeCommentBlockingUser';

interface Props {
  currentUser: WriterCurrentUser;
  episodeComments: EpisodeComment[] | null;
  episodeCommentBlockingUsers: EpisodeCommentBlockingUser[];
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  onRequestCreateEpisodeCommentBlockingUser: (user: User) => void;
  onRequestDestroyEpisodeCommentBlockingUser: (
    episodeCommentBlockingUser: EpisodeCommentBlockingUser,
  ) => void;
  onRequestDestroyEpisodeComment: (episodeComment: EpisodeComment) => void;
  onRequestCreateEpisodeCommentStoryPage: (
    episodeComment: EpisodeComment,
  ) => void;
  onRequestDestroyEpisodeCommentStoryPage: (
    episodeComment: EpisodeComment,
  ) => void;
  onOpenNewEpisodeCommentReply: (episodeComment: EpisodeComment) => void;
  onOpenEditEpisodeCommentReply: (
    episodeCommentReply: EpisodeCommentReply,
  ) => void;
  onRequestDestroyEpisodeCommentReply: (
    episodeCommentReply: EpisodeCommentReply,
  ) => void;
}

const CommentList: React.FunctionComponent<Props> = props => {
  const {
    currentUser,
    episodeComments,
    episodeCommentBlockingUsers,
    ListHeaderComponent,
    onRequestCreateEpisodeCommentBlockingUser,
    onRequestDestroyEpisodeCommentBlockingUser,
    onRequestDestroyEpisodeComment,
    onRequestCreateEpisodeCommentStoryPage,
    onRequestDestroyEpisodeCommentStoryPage,
    onOpenNewEpisodeCommentReply,
    onOpenEditEpisodeCommentReply,
    onRequestDestroyEpisodeCommentReply,
  } = props;
  const {showActionSheetWithOptions} = useActionSheet();
  const keyExtractor = React.useCallback(
    (episode: EpisodeComment, index: number): string => `${index}`,
    [],
  );
  const onRequestActionSheetForComment = React.useCallback(
    (episodeComment: EpisodeComment) => {
      const foundEpisodeCommentBlockingUser = episodeCommentBlockingUsers.find(
        episodeCommentBlockingUser =>
          episodeCommentBlockingUser.blocked(episodeComment),
      );
      const baseOptions = [
        episodeComment.displayOnStoryPage
          ? 'このコメントを作品ページから非表示にする'
          : 'このコメントを作品ページに表示する',
        foundEpisodeCommentBlockingUser
          ? 'この投稿者のブロックを解除する'
          : 'この投稿者をブロックする',
        '削除',
        'キャンセル',
      ];
      const options = baseOptions;
      const offset = -1;
      const visibleEpisodeCommentIndex = 1 + offset;
      const blockUserIndex = 2 + offset;
      const destructiveButtonIndex = 3 + offset;
      const cancelButtonIndex = 4 + offset;
      showActionSheetWithOptions(
        {
          cancelButtonIndex,
          destructiveButtonIndex,
          options,
        },
        (buttonIndex?: number) => {
          switch (buttonIndex) {
            case visibleEpisodeCommentIndex:
              if (episodeComment.displayOnStoryPage) {
                onRequestDestroyEpisodeCommentStoryPage(episodeComment);
              } else {
                onRequestCreateEpisodeCommentStoryPage(episodeComment);
              }
              break;
            case blockUserIndex:
              if (foundEpisodeCommentBlockingUser) {
                onRequestDestroyEpisodeCommentBlockingUser(
                  foundEpisodeCommentBlockingUser,
                );
              } else {
                onRequestCreateEpisodeCommentBlockingUser(episodeComment.user);
              }
              break;
            case destructiveButtonIndex:
              onRequestDestroyEpisodeComment(episodeComment);
              break;
            case cancelButtonIndex:
              break;
          }
        },
      );
    },
    [episodeCommentBlockingUsers],
  );
  const onRequestActionSheetForCommentReply = React.useCallback(
    (episodeCommentReply: EpisodeCommentReply) => {
      const options = ['削除', 'キャンセル'];
      const destructiveButtonIndex = 0;
      const cancelButtonIndex = 1;
      showActionSheetWithOptions(
        {
          cancelButtonIndex,
          destructiveButtonIndex,
          options,
        },
        (buttonIndex?: number) => {
          switch (buttonIndex) {
            case destructiveButtonIndex:
              onRequestDestroyEpisodeCommentReply(episodeCommentReply);
              break;
            case cancelButtonIndex:
              break;
          }
        },
      );
    },
    [],
  );
  const renderItem = React.useCallback(
    (
      info: ListRenderItemInfo<EpisodeComment>,
    ): React.ReactElement<any> | null => {
      const blocked = !!episodeCommentBlockingUsers.find(
        episodeCommentBlockingUser =>
          episodeCommentBlockingUser.blocked(info.item),
      );
      return (
        <CommentListItem
          currentUser={currentUser}
          episodeComment={info.item}
          blocked={blocked}
          onRequestActionSheetForComment={onRequestActionSheetForComment}
          onRequestActionSheetForCommentReply={
            onRequestActionSheetForCommentReply
          }
          onOpenEditEpisodeCommentReply={onOpenEditEpisodeCommentReply}
          onOpenNewEpisodeCommentReply={onOpenNewEpisodeCommentReply}
        />
      );
    },
    [episodeCommentBlockingUsers],
  );
  return (
    <FlatList
      data={episodeComments}
      keyExtractor={keyExtractor}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
      onEndReachedThreshold={0.1}
    />
  );
};

export default React.memo(CommentList);
