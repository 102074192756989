import TextFrameMapper, {
  TextFrameData,
} from '../../../entities/mappers/TextFrameMapper';
import VoiceMapper, {VoiceData} from '../../../entities/mappers/VoiceMapper';
import SoundMapper, {SoundData} from '../../../entities/mappers/SoundMapper';

import DescriptiveTextShowSceneCommand from '../../../../domain/value_objects/scene_commands/DescriptiveTextShowSceneCommand';

export interface DescriptiveTextShowSceneCommandData {
  type: string;
  text_frame: TextFrameData;
  text: string;
  voice?: VoiceData | null;
  sound?: SoundData | null;
  start_time?: number;
  end_time?: number;
}

export default class DescriptiveTextShowSceneCommandMapper {
  private textFrameMapper: TextFrameMapper;
  private voiceMapper: VoiceMapper;
  private soundMapper: SoundMapper;

  constructor() {
    this.textFrameMapper = new TextFrameMapper();
    this.voiceMapper = new VoiceMapper();
    this.soundMapper = new SoundMapper();
  }

  public map(
    obj: DescriptiveTextShowSceneCommandData,
  ): DescriptiveTextShowSceneCommand {
    const textFrame = this.textFrameMapper.map(obj.text_frame);
    const voice = obj.voice ? this.voiceMapper.map(obj.voice) : null;
    const sound = obj.sound ? this.soundMapper.map(obj.sound) : null;
    return new DescriptiveTextShowSceneCommand(
      textFrame,
      obj.text,
      voice,
      sound,
      obj.start_time,
      obj.end_time,
    );
  }
}
