import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import ContestErrorMessagesBox from '../../../shared/ContestErrorMessagesBox';
import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import MultiSwitch from '../../../shared/forms/MultiSwitch';

import {formStyles} from '../../../../styles/variables';

interface Props {
  published: boolean;
  entryContestInfo?: {
    contest_id: number;
    errors: string[];
  };
  disabled?: boolean;
  onSelectItem: (item: {label: string; value: string}) => void;
  onPress: () => void;
}

const Form: React.FunctionComponent<Props> = props => {
  const {
    published,
    entryContestInfo,
    disabled: defaultDisable,
    onSelectItem,
    onPress,
  } = props;
  const disabled =
    defaultDisable ||
    (published && !!(entryContestInfo && entryContestInfo.errors.length > 0));
  return (
    <>
      <View style={formStyles.formGroup}>
        <LabelWithOption title={'公開設定'} />
        <MultiSwitch
          items={PUBLISHED_ITEMS}
          value={`${published}`}
          onSelectItem={onSelectItem}
        />
      </View>
      {published && entryContestInfo && (
        <ContestErrorMessagesBox
          note={true}
          entryContestInfo={entryContestInfo}
        />
      )}
      <View style={styles.button}>
        <PrimaryButton disabled={disabled} onPress={onPress}>
          決定
        </PrimaryButton>
      </View>
    </>
  );
};

export default React.memo(Form);

const PUBLISHED_ITEMS = [
  {label: '公開', value: 'true'},
  {label: '非公開', value: 'false'},
];

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    marginVertical: 16,
  } as ViewStyle,
});
