import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ActorAccessorySetMapper, {
  ActorAccessorySetData,
} from '../../entities/mappers/ActorAccessorySetMapper';

import ActorAccessorySet from '../../../domain/entities/ActorAccessorySet';
import ActorAccessorySetsRepository from '../../../domain/repositories/writer/ActorAccessorySetsRepository';

export default class NetActorAccessorySetsRepository
  extends NetResourcesRepository<ActorAccessorySet, ActorAccessorySetData>
  implements ActorAccessorySetsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/actor_accessory_sets',
        new ActorAccessorySetMapper(),
      ),
    );
  }
}
