import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import OrientedSpeechBalloon from '../../../domain/entities/OrientedSpeechBalloon';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetOrientedSpeechBalloonsRepository from '../../../data/repositories/NetOrientedSpeechBalloonsRepository';

const types = {
  failure: Types.IndexOrientedSpeechBalloonsFailure,
  request: Types.IndexOrientedSpeechBalloonsRequest,
  success: Types.IndexOrientedSpeechBalloonsSuccess,
};

export interface Params extends BaseParams {
  speechBalloonId?: number;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<OrientedSpeechBalloon>> {
  return indexGenerics(
    new NetOrientedSpeechBalloonsRepository(),
    types,
    params,
  );
}
