import React from 'react';
import {
  Animated,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
  Text,
  TextStyle,
} from 'react-native';

import {CoachmarkPosition} from './Coachmark';

import CoachmarkArrowIcon from '../icons/CoachmarkArrowIcon';

interface Props {
  children: React.ReactNode;
  position?: CoachmarkPosition;
  style?: StyleProp<ViewStyle>;
  arrowStyle?: StyleProp<ViewStyle>;
  arrowSize?: number;
}

const CoachmarkView: React.FunctionComponent<Props> = props => {
  const {position, children, style, arrowStyle, arrowSize} = props;
  return (
    <View style={[styles.container, style]}>
      {position === CoachmarkPosition.TOP ? (
        <>
          <Animated.View style={[styles.arrowStyleUp, arrowStyle]}>
            <CoachmarkArrowIcon size={arrowSize || defaultArrowSize} />
          </Animated.View>
          {typeof children === 'string' ? (
            <Text style={styles.message}>{children}</Text>
          ) : (
            children
          )}
        </>
      ) : (
        <>
          {typeof children === 'string' ? (
            <Text style={styles.message}>{children}</Text>
          ) : (
            children
          )}
          <Animated.View style={[styles.arrowStyleDown, arrowStyle]}>
            <CoachmarkArrowIcon size={arrowSize || defaultArrowSize} />
          </Animated.View>
        </>
      )}
    </View>
  );
};

const defaultArrowSize = 100;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  arrowStyleUp: {
    transform: [{rotate: '180deg'}],
    right: 20,
  } as ViewStyle,
  arrowStyleDown: {} as ViewStyle,
  message: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    marginVertical: 5,
    textShadowColor: 'rgba(0, 0, 0, 0.5)',
    textShadowRadius: 5,
  } as TextStyle,
});

export default React.memo(CoachmarkView);
