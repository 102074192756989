import {combineReducers} from 'redux';

import applicationModal from './applicationModal';
import applicationActionModal from './applicationActionModal';
import coachmarkModal from './coachmarkModal';

export default combineReducers({
  applicationModal,
  applicationActionModal,
  coachmarkModal,
});
