import * as React from 'react';
import {FlatList, View, TouchableOpacity} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import MemberListItem from './MemberListItem';

import DimensionContext from '../../shared/dimension/DimensionContext';

import ArrowForwardIcon from '../../shared/icons/ArrowForwardIcon';

import ProjectMember from '../../../../domain/entities/ProjectMember';

interface Props {
  projectId: number;
  members: ProjectMember[];
}

const MemberList: React.FC<Props> = props => {
  const {projectId, members} = props;
  return (
    <DimensionContext.Consumer>
      {context => {
        const imageSize =
          Math.round((context.content.width - CONTAINER_MARGIN * 2) / 7) -
          ITEM_MARGIN;
        return (
          <FlatList
            data={members}
            renderItem={info => (
              <MemberListItem member={info.item} imageSize={imageSize} />
            )}
            ItemSeparatorComponent={ItemSeparatorComponent}
            ListFooterComponent={() => (
              <ListFooterComponent
                projectId={projectId}
                imageSize={imageSize}
              />
            )}
            horizontal={true}
          />
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(MemberList);

const CONTAINER_MARGIN = 16;
const ITEM_MARGIN = 8;

const ItemSeparatorComponent = React.memo(() => (
  <View style={{width: ITEM_MARGIN}} />
));

const ListFooterComponent = React.memo(
  (props: {projectId: number; imageSize: number}) => {
    const {projectId, imageSize} = props;
    const linkTo = useLinkTo();
    const onPress = React.useCallback(() => {
      linkTo(`/projects/${projectId}/members`);
    }, []);
    return (
      <TouchableOpacity
        style={{
          width: imageSize,
          height: imageSize,
          borderRadius: imageSize / 2,
          borderWidth: 1,
          borderColor: '#666',
          marginLeft: ITEM_MARGIN,
          justifyContent: 'center',
          alignItems: 'center',
        }}
        onPress={onPress}>
        <ArrowForwardIcon color={'#666'} size={13} />
      </TouchableOpacity>
    );
  },
);
