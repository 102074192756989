import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../../../components/shop/actors/checkout/Show';

import AppState from '../../../../reducers/AppState';

import selectEntity from '../../../../helpers/selectEntity';

import {ShopActorCheckoutRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: ShopActorCheckoutRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;

  const {entities, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  return {
    navigation,
    route,
    currentUserStatus,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {};
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
