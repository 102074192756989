import * as React from 'react';
import {
  Text,
  TextStyle,
  TouchableOpacity,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import ThumbsUpActiveIcon from '../../../shared/icons/project/ThumbsUpActiveIcon';
import ThumbsUpIcon from '../../../shared/icons/project/ThumbsUpIcon';

import ProjectTopicComment from '../../../../../domain/entities/ProjectTopicComment';
import ProjectTopicCommentLike from '../../../../../domain/entities/ProjectTopicCommentLike';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  comment: ProjectTopicComment;
  commentLike: ProjectTopicCommentLike | null;
}

const CommentLikeButton: React.FC<Props> = props => {
  const {comment} = props;
  const [commentLike, setCommentLike] = React.useState<
    ProjectTopicCommentLike | null | undefined
  >(props.commentLike);
  const [likesCount, setLikesCount] = React.useState(comment.likesCount);
  const onPress = React.useCallback(() => {
    if (commentLike) {
      TapNovelTypedRestApi.delete(
        `/api/writer/project_topic_comment_likes/${commentLike.id}`,
      ).then(result => {
        setCommentLike(null);
        setLikesCount(likesCount - 1);
      });
    } else {
      TapNovelTypedRestApi.post<ProjectTopicCommentLike>(
        '/api/writer/project_topic_comment_likes',
        {
          projectTopicCommentId: comment.id,
        },
      ).then(result => {
        setCommentLike(result.body);
        setLikesCount(likesCount + 1);
      });
    }
  }, [commentLike, likesCount]);
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPress}>
        {commentLike ? (
          <ThumbsUpActiveIcon size={13} />
        ) : (
          <ThumbsUpIcon size={13} />
        )}
      </TouchableOpacity>
      <Text style={commentLike ? styles.likesActiveCount : styles.likesCount}>
        {likesCount}
      </Text>
    </View>
  );
};

export default React.memo(CommentLikeButton);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  } as ViewStyle,
  likesCount: {
    color: '#999',
    fontSize: 10,
    marginLeft: 2,
  } as TextStyle,
  likesActiveCount: {
    color: '#f23406',
    fontSize: 10,
    marginLeft: 2,
  } as TextStyle,
});
