import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import ResourceFavoriteListLink from './ResourceFavoriteListLink';
import ResourceShoppingListLink from './ResourceShoppingListLink';

import {colors} from '../../styles/variables';

interface Props {
  title: string;
  onPressShoppingList?: () => void;
  onPressFavoriteList?: () => void;
}

const Headline: React.FunctionComponent<Props> = props => {
  const {title, onPressShoppingList, onPressFavoriteList} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      <View style={styles.buttons}>
        {onPressShoppingList && (
          <ResourceShoppingListLink
            style={styles.button}
            onPress={onPressShoppingList}
          />
        )}
        {onPressFavoriteList && (
          <ResourceFavoriteListLink
            style={styles.button}
            onPress={onPressFavoriteList}
          />
        )}
      </View>
    </View>
  );
};

export default React.memo(Headline);

const styles = StyleSheet.create({
  container: {
    height: 45,
    width: '100%',
    backgroundColor: colors.whiteGray,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
  } as ViewStyle,
  title: {
    color: colors.textColor,
    fontSize: 11,
    fontWeight: 'bold',
  } as TextStyle,
  buttons: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    width: 100,
  } as ViewStyle,
  button: {
    marginLeft: 10,
  } as ViewStyle,
});
