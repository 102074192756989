import * as React from 'react';

import Icon from '../Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const SadIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 35;
  const color = props.color || '#ff8f13';
  return <Icon name={'reaction-sad'} size={size} color={color} />;
};

export default React.memo(SadIcon);
