import * as React from 'react';
import {Platform, StyleProp, ViewStyle} from 'react-native';

import AttachmentSoundAddView from './AttachmentSoundAddView';
import AttachmentSoundAddViewTrimmed from './AttachmentSoundAddViewTrimmed';
import AttachmentSoundDeleteView from './AttachmentSoundDeleteView';
import UserStatusContext from '../user_status/UserStatusContext';

import Sound from '../../../../domain/entities/Sound';

import {enabledTrimmedSound} from '../../../helpers/features';

interface Props {
  style?: StyleProp<ViewStyle>;
  sound?: Sound | null;
  startTime?: number | null;
  endTime?: number | null;
  addSound: () => void;
  deleteSound: () => void;
  onChagenRegion?: (start?: number, end?: number) => void;
}

const AttachmentSoundView: React.FC<Props> = props => {
  const {
    style,
    sound,
    startTime,
    endTime,
    addSound,
    deleteSound,
    onChagenRegion,
  } = props;
  return (
    <UserStatusContext.Consumer>
      {userStatusContext =>
        sound ? (
          enabledTrimmedSound &&
          (userStatusContext.currentUser?.enabledPaidSubscriber ||
            userStatusContext.currentUser?.isGradeBlack()) ? (
            <AttachmentSoundAddViewTrimmed
              sound={sound}
              startTime={startTime}
              endTime={endTime}
              style={[
                {height: 88},
                style,
                Platform.select({
                  default: {},
                  web: {touchAction: 'none'} as any,
                }),
              ]}
              deleteSound={deleteSound}
              onChagenRegion={onChagenRegion}
            />
          ) : (
            <AttachmentSoundAddView
              style={[{height: 32}, style]}
              sound={sound}
              deleteSound={deleteSound}
            />
          )
        ) : (
          <AttachmentSoundDeleteView addSound={addSound} />
        )
      }
    </UserStatusContext.Consumer>
  );
};

export default React.memo(AttachmentSoundView);
