import * as React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import {colors} from '../../../styles/variables';

interface Props {
  style?: StyleProp<ViewStyle>;
  children?: React.ReactNode;
}

const BottomButtonContainer: React.FunctionComponent<Props> = props => {
  const {style, children} = props;
  return <View style={[styles.container, style]}>{children}</View>;
};

export default React.memo(BottomButtonContainer);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.whiteGray,
    borderTopColor: '#efefef',
    paddingHorizontal: 2,
    paddingTop: 32,
    paddingBottom: 16,
    width: '100%',
    alignItems: 'center',
  } as ViewStyle,
});
