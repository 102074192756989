export default class ContactForm {
  constructor(
    public writerContactCategoryId: number | null,
    public email: string,
    public content: string,
  ) {}

  public validate() {
    return (
      this.writerContactCategoryId !== null &&
      this.email.length > 0 &&
      this.content.length > 0
    );
  }

  public getParams() {
    if (!this.validate() || this.writerContactCategoryId === null) {
      return null;
    }
    return {
      writerContactCategoryId: this.writerContactCategoryId,
      email: this.email,
      content: this.content,
    };
  }
}
