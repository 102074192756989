import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewWriterUserCancellationRouteProp} from '../../../navigators/RouteProps';

import CurrentUserStatus from '../../../../domain/entities/writer/CurrentUserStatus';

import {linkToHome} from '../../../routers';

import {Params as WriterCurrentUserRegistrationDestroyParams} from '../../../actions/writer/users/registration/destroy';
export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: NewWriterUserCancellationRouteProp;
}

export interface DispatchProps {
  showCurrentUserStatus: () => Promise<CurrentUserStatus>;
  destroyWriterCurrentUserRegistration: (
    params: WriterCurrentUserRegistrationDestroyParams,
  ) => Promise<void>;
}

interface Props extends StateProps, DispatchProps {}

const New: React.FunctionComponent<Props> = props => {
  const {
    navigation,
    showCurrentUserStatus,
    destroyWriterCurrentUserRegistration,
  } = props;
  const [currentUserStatus, setCurrentUserStatus] =
    React.useState<CurrentUserStatus | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [reason, setReason] = React.useState('');
  React.useEffect(() => {
    showCurrentUserStatus().then(value => {
      setCurrentUserStatus(value);
    });
  }, []);
  const onRequestDeleteAccount = React.useCallback(() => {
    destroyWriterCurrentUserRegistration({reason}).then(() => {
      linkToHome(navigation);
    });
  }, [reason]);
  const onPressCancel = React.useCallback(() => {
    navigation.goBack();
  }, []);
  const onToggleLoading = React.useCallback((value: boolean) => {
    setLoading(value);
  }, []);
  return (
    <Layout
      title={'退会'}
      back={true}
      navigation={navigation}
      loading={loading}>
      {currentUserStatus ? (
        <Form
          currentUserStatus={currentUserStatus}
          onRequestDeleteAccount={onRequestDeleteAccount}
          onPressCancel={onPressCancel}
          onToggleLoading={onToggleLoading}
          setReason={setReason}
        />
      ) : null}
    </Layout>
  );
};

export default React.memo(New);
