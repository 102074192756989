import CharacterShowSceneCommandForm from '../../forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../forms/scene_commands/CharacterUpdateSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

import CharacterHideSceneCommand from '../../value_objects/scene_commands/CharacterHideSceneCommand';
import CharacterShowSceneCommand from '../../value_objects/scene_commands/CharacterShowSceneCommand';
import CharacterUpdateSceneCommand from '../../value_objects/scene_commands/CharacterUpdateSceneCommand';
import DescriptiveTextShowSceneCommand from '../../value_objects/scene_commands/DescriptiveTextShowSceneCommand';
import FullScreenIllustrationShowSceneCommand from '../../value_objects/scene_commands/FullScreenIllustrationShowSceneCommand';
import IllustrationShowSceneCommand from '../../value_objects/scene_commands/IllustrationShowSceneCommand';
import MarkHideSceneCommand from '../../value_objects/scene_commands/MarkHideSceneCommand';
import MarkShowSceneCommand from '../../value_objects/scene_commands/MarkShowSceneCommand';
import SceneCommand from '../../value_objects/scene_commands/SceneCommand';
import SpeechTextShowSceneCommand from '../../value_objects/scene_commands/SpeechTextShowSceneCommand';

type CharacterSceneCommand =
  | CharacterShowSceneCommand
  | CharacterUpdateSceneCommand
  | null;

export default class SceneCommandFormsBuilder {
  public build(commands: SceneCommand[]): SceneCommandForm[] {
    const forms: SceneCommandForm[] = [];
    const characters: CharacterSceneCommand[] = [];
    const marks: Array<MarkShowSceneCommand | null> = [];
    commands.forEach((command, i) => {
      const nextCommand = commands[i + 1];
      if (command instanceof CharacterHideSceneCommand) {
        characters[command.position] = null;
        marks[command.position] = null;
        forms.push(command.toForm());
      } else if (command instanceof CharacterShowSceneCommand) {
        marks[command.position] = null;
        characters[command.position] = command;
        forms.push(command.toForm());
      } else if (command instanceof CharacterUpdateSceneCommand) {
        const mark = marks[command.position];
        characters[command.position] = command;
        forms.push(command.toForm(mark ? mark.mark : undefined));
      } else if (command instanceof DescriptiveTextShowSceneCommand) {
        forms.push(command.toForm());
      } else if (command instanceof FullScreenIllustrationShowSceneCommand) {
        forms.push(command.toForm());
      } else if (command instanceof IllustrationShowSceneCommand) {
        forms.push(command.toForm());
      } else if (command instanceof MarkHideSceneCommand) {
        marks[command.position] = null;
        const samePositioncharacter = characters[command.position];
        if (
          samePositioncharacter &&
          !(
            nextCommand instanceof CharacterUpdateSceneCommand &&
            command.position === nextCommand.position
          )
        ) {
          forms.push(
            new CharacterUpdateSceneCommandForm(
              samePositioncharacter.characterPattern,
              samePositioncharacter.actorCharacterFace,
              null,
              samePositioncharacter.position,
            ),
          );
        }
      } else if (command instanceof MarkShowSceneCommand) {
        marks[command.position] = command;
        const lastCommandForm = forms[forms.length - 1];
        const samePositionCharacter = characters[command.position];
        if (
          (lastCommandForm instanceof CharacterShowSceneCommandForm ||
            lastCommandForm instanceof CharacterUpdateSceneCommandForm) &&
          lastCommandForm.position === command.position
        ) {
          lastCommandForm.mark = command.mark;
        } else if (samePositionCharacter) {
          forms.push(
            new CharacterUpdateSceneCommandForm(
              samePositionCharacter.characterPattern,
              samePositionCharacter.actorCharacterFace,
              command.mark,
              samePositionCharacter.position,
            ),
          );
        }
      } else if (command instanceof SpeechTextShowSceneCommand) {
        forms.push(command.toForm());
      } else {
        forms.push(command.toForm());
      }
    });
    return forms;
  }
}
