import * as React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ProLabel from '../../shared/ProLabel';
import SvgUriAdapter from '../../shared/svg/SvgUriAdapter';

import {writerUserImageUrl} from '../../../helpers/images';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import uri from '../../../../assets/svgs/noimage-writer.svg';

interface Props {
  currentUser: CurrentUser;
  onPress: () => void;
}

const WriterUser: React.FunctionComponent<Props> = (props: Props) => {
  const {currentUser, onPress} = props;
  const source =
    currentUser.userProfile && currentUser.userProfile.hasImage
      ? {uri: writerUserImageUrl(currentUser.userProfile, 'middle')}
      : null;
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        <TouchableOpacity activeOpacity={0.6} onPress={onPress}>
          <View style={styles.imageWrapper}>
            {source ? (
              <Image style={styles.image} source={source} />
            ) : (
              <SvgUriAdapter uri={uri} width={60} height={60} />
            )}
          </View>
        </TouchableOpacity>
      </View>
      <View style={styles.center}>
        <Text style={styles.penName}>{currentUser.penName}</Text>
        {currentUser.paidSubscriberId && currentUser.enabledPaidSubscriber ? (
          <View style={styles.pro}>
            <ProLabel />
            <Text style={styles.proNumber}>
              会員No：
              {String(currentUser.paidSubscriberId).padStart(7, '0')}
            </Text>
          </View>
        ) : null}
      </View>
      <View style={styles.right}>
        <TouchableOpacity activeOpacity={0.6} onPress={onPress}>
          <Text style={styles.editLink}>プロフィール修正</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default WriterUser;

const styles = StyleSheet.create({
  container: {
    height: 92,
    width: '100%',
    paddingHorizontal: 16,
    flexDirection: 'row',
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  left: {
    justifyContent: 'center',
  } as ViewStyle,
  center: {
    flex: 1,
    marginTop: 16,
    marginLeft: 12,
  } as ViewStyle,
  right: {
    marginLeft: 12,
    marginBottom: 10,
    justifyContent: 'flex-end',
  } as ViewStyle,
  imageWrapper: {
    width: 60,
    height: 60,
    borderRadius: 30,
    backgroundColor: '#efefef',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  } as ViewStyle,
  image: {
    width: 60,
    height: 60,
  } as ImageStyle,
  penName: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
  } as TextStyle,
  editLink: {
    color: '#999999',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  pro: {
    marginTop: 8,
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  proNumber: {
    color: '#222',
    fontSize: 12,
    marginHorizontal: 4,
  } as TextStyle,
});
