import NetResourcesRepository from './NetResourcesRepository';
import NetResourcesRepositoryAdapter from './NetResourcesRepositoryAdapter';

import IllustrationCategoryMapper, {
  IllustrationCategoryData,
} from '../entities/mappers/IllustrationCategoryMapper';

import IllustrationCategory from '../../domain/entities/IllustrationCategory';
import IllustrationCategoriesRepository from '../../domain/repositories/IllustrationCategoriesRepository';

export default class NetIllustrationCategoriesRepository
  extends NetResourcesRepository<IllustrationCategory, IllustrationCategoryData>
  implements IllustrationCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/illustration_categories',
        new IllustrationCategoryMapper(),
      ),
    );
  }
}
