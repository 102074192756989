import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import Sound from '../../entities/Sound';

const CLASS_NAME: SceneCommandFormClass = 'BackgroundMusicShowSceneCommandForm';

export default class BackgroundMusicShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(public sound: Sound, sceneCommandId?: number) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      sound_id: this.sound.id,
      type: 'SceneCommands::BackgroundMusicShowSceneCommand',
    };
  }

  public copy(sceneCommandId?: number): BackgroundMusicShowSceneCommandForm {
    return new BackgroundMusicShowSceneCommandForm(this.sound, sceneCommandId);
  }
}
