import * as React from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import BottomButtonContainer from './BottomButtonContainer';
import PrimaryButton from './PrimaryButton';

interface Props {
  style?: StyleProp<ViewStyle>;
  bottomButtonStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
  children?: React.ReactNode;
  onPress: () => void;
}

const BottomDefaultButton: React.FunctionComponent<Props> = props => {
  const {style, bottomButtonStyle, disabled, children, onPress} = props;
  return (
    <BottomButtonContainer style={bottomButtonStyle}>
      <PrimaryButton style={style} onPress={onPress} disabled={disabled}>
        {children}
      </PrimaryButton>
    </BottomButtonContainer>
  );
};

export default React.memo(BottomDefaultButton);
