import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ActorMapper, {ActorData} from '../../entities/mappers/ActorMapper';

import Actor from '../../../domain/entities/Actor';
import ActorsRepository from '../../../domain/repositories/writer/ActorsRepository';

export default class NetActorsRepository
  extends NetResourcesRepository<Actor, ActorData>
  implements ActorsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/actors',
        new ActorMapper(),
      ),
    );
  }
}
