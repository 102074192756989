import * as React from 'react';
import {StyleSheet, Text, TextStyle, ViewStyle} from 'react-native';

import shouldUpdateEntityList from '../../../shared/enhanced/shouldUpdateEntityList';
import CheckBox from '../../../shared/forms/CheckBox';
import DefaultList from '../../../shared/lists/DefaultList';

import {colors} from '../../../../styles/variables';

import Genre from '../../../../../domain/entities/Genre';

interface GenreWithChecked {
  genre: Genre;
  checked: boolean;
}

interface Props {
  genres: Genre[];
  mainGenreId: number | null;
  selectedGenreIds: number[];
  onToggleGenre: (genre: Genre, checked: boolean) => void;
}

export default class CharacterList extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (this.props.mainGenreId !== nextProps.mainGenreId) {
      return true;
    }
    if (this.props.selectedGenreIds !== nextProps.selectedGenreIds) {
      return true;
    }
    if (
      shouldUpdateEntityList(
        {entities: this.props.genres},
        {entities: nextProps.genres},
      )
    ) {
      return true;
    }
    return false;
  }

  public render(): React.ReactNode {
    const {genres, selectedGenreIds} = this.props;
    const data = genres.map(genre => {
      const checked = selectedGenreIds.some(id => {
        return id === genre.id;
      });
      return {
        checked,
        genre,
      };
    });
    return (
      <DefaultList
        data={data}
        separator={'right'}
        renderTitle={this.renderTitle}
        onSelectItem={this.handleSelectItem}
        renderIcon={this.renderIcon}
      />
    );
  }

  private renderIcon = (item: GenreWithChecked) => {
    return (
      <CheckBox
        checked={item.checked}
        buttonType={true}
        style={styles.checkBox}
        size={12}
        disabled={!this.enableSelectItem(item)}
      />
    );
  };

  private handleSelectItem = (item: GenreWithChecked) => {
    const {onToggleGenre} = this.props;
    if (!this.enableSelectItem(item)) {
      return;
    }
    onToggleGenre(item.genre, !item.checked);
  };

  private renderTitle = (item: GenreWithChecked): React.ReactNode => {
    const style = !this.enableSelectItem(item)
      ? styles.disabled
      : item.checked
      ? styles.checked
      : {};
    return <Text style={style}>{item.genre.name}</Text>;
  };

  private enableSelectItem = (item: GenreWithChecked): boolean => {
    const {mainGenreId, selectedGenreIds} = this.props;
    if (mainGenreId === item.genre.id) {
      return false;
    }
    if (
      selectedGenreIds.length >= 2 &&
      !selectedGenreIds.includes(item.genre.id)
    ) {
      return false;
    }
    return true;
  };
}

const styles = StyleSheet.create({
  checkBox: {
    marginRight: 5,
  } as ViewStyle,
  checked: {
    color: '#ff8f13',
    fontWeight: 'bold',
  } as TextStyle,
  disabled: {
    color: colors.powderGray,
  } as TextStyle,
});
