import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import NewStory from '../../../../containers/stories/New';
import NewStoryFormCoverImage from '../../../../containers/story_form/cover_image/New';
import NewStoryFormDetailInfo from '../../../../containers/story_form/detail_info/New';
import NewStoryFormImage from '../../../../containers/story_form/image/New';
import StoryFormKeywords from '../../../../containers/story_form/keywords/Index';
import StoryFormMainGenre from '../../../../containers/story_form/main_genre/Index';
import StoryFormSubGenres from '../../../../containers/story_form/sub_genres/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="NewStory">
      <RootStack.Screen
        name="NewStory"
        component={NewStory}
        options={{title: 'ストーリー情報'}}
      />

      <RootStack.Screen
        name="NewStoryFormCoverImage"
        component={NewStoryFormCoverImage}
        options={{title: '表紙'}}
      />

      <RootStack.Screen
        name="NewStoryFormDetailInfo"
        component={NewStoryFormDetailInfo}
        options={{title: 'ストーリー情報'}}
      />

      <RootStack.Screen
        name="NewStoryFormImage"
        component={NewStoryFormImage}
        options={{title: '中表紙'}}
      />

      <RootStack.Screen
        name="StoryFormKeywords"
        component={StoryFormKeywords}
        options={{title: 'キーワード'}}
      />

      <RootStack.Screen
        name="StoryFormMainGenre"
        component={StoryFormMainGenre}
        options={{title: 'メインジャンル'}}
      />

      <RootStack.Screen
        name="StoryFormSubGenres"
        component={StoryFormSubGenres}
        options={{title: 'サブジャンル'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
