import EntityMapper from './EntityMapper';

import SceneForm from '../../../domain/forms/SceneForm';
import SceneCommandForm, {
  SceneCommandFormClass,
} from '../../../domain/forms/scene_commands/SceneCommandForm';

import BackgroundMusicHideSceneCommandForm from '../../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundShowSceneCommandForm from '../../../domain/forms/scene_commands/BackgroundShowSceneCommandForm';
import CharacterHideSceneCommandForm from '../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import CharacterShowSceneCommandForm from '../../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';

import Background from '../../../domain/entities/Background';
import Effect from '../../../domain/entities/Effect';
import PositionedEffect from '../../../domain/entities/PositionedEffect';
import Sound from '../../../domain/entities/Sound';
import Voice from '../../../domain/entities/Voice';
import ActorCharacterFace from '../../../domain/entities/ActorCharacterFace';
import Character from '../../../domain/entities/Character';
import CharacterPattern from '../../../domain/entities/CharacterPattern';
import ActorCharacter from '../../../domain/entities/ActorCharacter';
import Mark from '../../../domain/entities/Mark';
import TextFrame from '../../../domain/entities/TextFrame';
import TextFramePart from '../../../domain/entities/TextFramePart';
import FullScreenIllustration from '../../../domain/entities/FullScreenIllustration';
import Illustration from '../../../domain/entities/Illustration';
import OrientedSpeechBalloon from '../../../domain/entities/OrientedSpeechBalloon';
import OrientedSpeechBalloonPart from '../../../domain/entities/OrientedSpeechBalloonPart';

export interface AutoSaveSceneData {
  id: number;
  sceneForm: SceneForm;
  sceneCommandForms: SceneCommandForm[];
  updatedAt: string;
}

export default abstract class AutoSaveSceneMapper<
  D extends AutoSaveSceneData,
  E,
> implements EntityMapper<D, E>
{
  public abstract map(s: D, h: Headers | null): E;

  protected mapSceneForm(s: D): SceneForm {
    const plain = s.sceneForm;
    const sceneForm = new SceneForm(plain.storyId, plain.episodeId);
    sceneForm.id = plain.id;
    sceneForm.background = plain.background
      ? this.buildBackground(plain.background)
      : null;
    sceneForm.positionedEffect = plain.positionedEffect
      ? this.buildPositionedEffect(plain.positionedEffect)
      : null;
    sceneForm.options = plain.options;
    sceneForm.effectOptions = plain.effectOptions;
    return sceneForm;
  }

  protected mapSceneCommandForms(s: D): SceneCommandForm[] {
    return s.sceneCommandForms.map(plain => {
      return this.mapSceneCommandForm(plain);
    });
  }

  public mapSceneCommandForm(plainObj: {
    className: SceneCommandFormClass;
    sceneCommandId: number;
    [key: string]: any;
  }): SceneCommandForm {
    switch (plainObj.className) {
      case 'BackgroundMusicHideSceneCommandForm':
        return new BackgroundMusicHideSceneCommandForm(
          this.buildSound(plainObj.sound),
          plainObj.sceneCommandId,
        );
      case 'BackgroundMusicShowSceneCommandForm':
        return new BackgroundMusicShowSceneCommandForm(
          this.buildSound(plainObj.sound),
          plainObj.sceneCommandId,
        );
      case 'BackgroundShowSceneCommandForm':
        return new BackgroundShowSceneCommandForm(
          this.buildBackground(plainObj.background),
          plainObj.sceneCommandId,
        );
      case 'CharacterHideSceneCommandForm':
        return new CharacterHideSceneCommandForm(
          this.buildCharacterPattern(plainObj.characterPattern),
          this.buildActorCharacterFace(plainObj.actorCharacterFace),
          plainObj.mark ? this.buildMark(plainObj.mark) : null,
          plainObj.position,
          plainObj.sceneCommandId,
        );
      case 'CharacterShowSceneCommandForm':
        return new CharacterShowSceneCommandForm(
          this.buildCharacterPattern(plainObj.characterPattern),
          this.buildActorCharacterFace(plainObj.actorCharacterFace),
          plainObj.mark ? this.buildMark(plainObj.mark) : null,
          plainObj.position,
          plainObj.waiting,
          plainObj.sceneCommandId,
        );
      case 'CharacterUpdateSceneCommandForm':
        return new CharacterUpdateSceneCommandForm(
          this.buildCharacterPattern(plainObj.characterPattern),
          this.buildActorCharacterFace(plainObj.actorCharacterFace),
          plainObj.mark ? this.buildMark(plainObj.mark) : null,
          plainObj.position,
          plainObj.sceneCommandId,
        );
      case 'CompositeParallelSceneCommandForm':
        return new CompositeParallelSceneCommandForm(
          plainObj.commandForms.map((commandForm: any) =>
            this.mapSceneCommandForm(commandForm),
          ),
          plainObj.sceneCommandId,
        );
      case 'CompositeSequenceSceneCommandForm':
        return new CompositeSequenceSceneCommandForm(
          plainObj.commandForms.map((commandForm: any) =>
            this.mapSceneCommandForm(commandForm),
          ),
          plainObj.sceneCommandId,
        );
      case 'DescriptiveTextShowSceneCommandForm':
        return new DescriptiveTextShowSceneCommandForm(
          this.buildTextFrame(plainObj.textFrame),
          plainObj.text,
          plainObj.voice ? this.buildVoice(plainObj.voice) : null,
          plainObj.sound ? this.buildSound(plainObj.sound) : null,
          plainObj.startTime ? plainObj.startTime : null,
          plainObj.endTime ? plainObj.endTime : null,
          plainObj.sceneCommandId,
        );
      case 'EffectShowSceneCommandForm':
        return new EffectShowSceneCommandForm(
          this.buildEffect(plainObj.effect),
          plainObj.position,
          plainObj.startIndex,
          plainObj.endIndex,
          plainObj.sound ? this.buildSound(plainObj.sound) : null,
          plainObj.startTime ? plainObj.startTime : null,
          plainObj.endTime ? plainObj.endTime : null,
          plainObj.sceneCommandId,
        );
      case 'FullScreenIllustrationShowSceneCommandForm':
        return new FullScreenIllustrationShowSceneCommandForm(
          this.buildFullScreenIllustration(plainObj.fullScreenIllustration),
          plainObj.sound ? this.buildSound(plainObj.sound) : null,
          plainObj.startTime ? plainObj.startTime : null,
          plainObj.endTime ? plainObj.endTime : null,
          plainObj.sceneCommandId,
        );
      case 'IllustrationShowSceneCommandForm':
        return new IllustrationShowSceneCommandForm(
          this.buildIllustration(plainObj.illustration),
          plainObj.sound ? this.buildSound(plainObj.sound) : null,
          plainObj.startTime ? plainObj.startTime : null,
          plainObj.endTime ? plainObj.endTime : null,
          plainObj.sceneCommandId,
        );
      case 'SoundEffectShowSceneCommandForm':
        return new SoundEffectShowSceneCommandForm(
          this.buildSound(plainObj.sound),
          plainObj.startTime,
          plainObj.endTime,
          plainObj.sceneCommandId,
        );
      case 'SpeechTextShowSceneCommandForm':
        return new SpeechTextShowSceneCommandForm(
          this.buildOrientedSpeechBalloon(plainObj.orientedSpeechBalloon),
          plainObj.text,
          plainObj.overrideCharacterName,
          plainObj.characterName,
          plainObj.voice ? this.buildVoice(plainObj.voice) : null,
          plainObj.sound ? this.buildSound(plainObj.sound) : null,
          plainObj.startTime ? plainObj.startTime : null,
          plainObj.endTime ? plainObj.endTime : null,
          plainObj.sceneCommandId,
        );
    }
  }

  private buildSound = (plainObj: Sound) => {
    return new Sound(
      plainObj.id,
      plainObj.name,
      plainObj.audioUrl,
      plainObj.trimmedAudioUrl,
    );
  };

  private buildVoice = (plainObj: Voice) => {
    return new Voice(
      plainObj.id,
      plainObj.storyId,
      plainObj.name,
      plainObj.audioUrl,
      plainObj.audioFileName,
      plainObj.audioContentType,
      plainObj.audioFileSize,
      new Date(plainObj.audioUpdatedAt),
      new Date(plainObj.createdAt),
      new Date(plainObj.updatedAt),
    );
  };

  private buildBackground = (plainObj: Background) => {
    return new Background(
      plainObj.id,
      plainObj.backgroundId,
      plainObj.backgroundWeatherId,
      plainObj.backgroundTimeZoneId,
      plainObj.option,
      plainObj.originalImageUrl,
    );
  };

  private buildEffect = (plainObj: Effect) => {
    return new Effect(
      plainObj.id,
      plainObj.baseEffectId,
      plainObj.name,
      plainObj.originalImageUrl,
      this.buildPositionedEffect(plainObj.centerPositionedEffect),
    );
  };

  private buildPositionedEffect = (plainObj: PositionedEffect) => {
    return new PositionedEffect(
      plainObj.id,
      plainObj.baseEffectId,
      plainObj.effectId,
      plainObj.position,
      plainObj.originalImageUrl,
      plainObj.frameUrls,
    );
  };

  private buildCharacterPattern = (plainObj: CharacterPattern) => {
    return new CharacterPattern(
      plainObj.id,
      this.buildCharacter(plainObj.character),
      this.buildActorCharacter(plainObj.actorCharacter),
      plainObj.actorCharacterId,
      plainObj.originalImageUrl,
    );
  };

  private buildCharacter = (plainObj: Character) => {
    return new Character(
      plainObj.id,
      plainObj.storyId,
      plainObj.actorId,
      plainObj.actorCharacterId,
      plainObj.characterMakerOnly,
      plainObj.name,
      plainObj.writerUserId,
      plainObj.description,
      plainObj.nameLabelColor,
      plainObj.voiceActorName,
      plainObj.inverted,
      plainObj.published,
      plainObj.originalImageUrl,
      plainObj.defaultCharacterPatternId,
      plainObj.characterPatternsCount,
      new Date(plainObj.createdAt),
      new Date(plainObj.updatedAt),
      this.buildActorCharacterFace(plainObj.actorCharacterFace),
      plainObj.option,
    );
  };

  private buildActorCharacter = (plainObj: ActorCharacter) => {
    return new ActorCharacter(
      plainObj.id,
      plainObj.actorId,
      plainObj.actorHairStyleId,
      plainObj.actorCostumeId,
      plainObj.actorAccessorySetId,
      plainObj.originalImageUrl,
      plainObj.faceCenterPosition,
    );
  };

  private buildActorCharacterFace = (plainObj: ActorCharacterFace) => {
    return new ActorCharacterFace(
      plainObj.id,
      plainObj.actorCharacterId,
      plainObj.faceName,
      plainObj.originalImageUrl,
      plainObj.faceCenterPosition,
    );
  };

  private buildMark = (plainObj: Mark) => {
    return new Mark(plainObj.id, plainObj.originalImageUrl);
  };

  private buildTextFrame = (plainObj: TextFrame) => {
    return new TextFrame(
      plainObj.id,
      plainObj.originalImageUrl,
      plainObj.caption,
      plainObj.textFrameParts.map(textFramePart =>
        this.buildTextFramePart(textFramePart),
      ),
      plainObj.containerStyle,
      plainObj.textStyle,
      plainObj.voiceIconStyle,
    );
  };

  private buildTextFramePart = (plainObj: TextFramePart) => {
    return new TextFramePart(
      plainObj.id,
      plainObj.textFrameId,
      plainObj.position,
      plainObj.originalImageUrl,
    );
  };

  private buildFullScreenIllustration = (plainObj: FullScreenIllustration) => {
    return new FullScreenIllustration(
      plainObj.id,
      plainObj.storyId,
      plainObj.name,
      plainObj.originalImageUrl,
    );
  };

  private buildIllustration = (plainObj: Illustration) => {
    return new Illustration(
      plainObj.id,
      plainObj.name,
      plainObj.originalImageUrl,
    );
  };

  private buildOrientedSpeechBalloon = (plainObj: OrientedSpeechBalloon) => {
    return new OrientedSpeechBalloon(
      plainObj.id,
      plainObj.speechBalloonId,
      plainObj.speechBalloonDirection,
      plainObj.originalImageUrl,
      plainObj.orientedSpeechBalloonParts.map(orientedSpeechBalloonPart =>
        this.buildOrientedSpeechBalloonPart(orientedSpeechBalloonPart),
      ),
      plainObj.containerStyle,
      plainObj.textStyle,
      plainObj.voiceIconStyle,
    );
  };

  private buildOrientedSpeechBalloonPart = (
    plainObj: OrientedSpeechBalloonPart,
  ) => {
    return new OrientedSpeechBalloonPart(
      plainObj.id,
      plainObj.orientedSpeechBalloonId,
      plainObj.framePartPosition,
      plainObj.originalImageUrl,
    );
  };
}
