import {AnyAction} from 'redux';

import {QueryState} from './Response';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    default:
      return state;
  }
}
