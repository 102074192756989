import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/sound_effect_show_scene_command/edit/sound_search_form/Index';

import AppState from '../../../../../reducers/AppState';

import indexSoundCategories, {
  Params as SoundCategoryIndexParams,
} from '../../../../../actions/sound_categories/index';
import indexSounds, {
  Params as SoundIndexParams,
} from '../../../../../actions/sounds/index';

import selectEntities from '../../../../../helpers/selectEntities';
import {SceneFormSoundEffectShowSceneCommandEditSoundSearchFormRouteProp} from '../../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: SceneFormSoundEffectShowSceneCommandEditSoundSearchFormRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms} = state;
  const {selectedLargeSoundCategoryId, selectedMiddleSoundCategoryId} =
    route.params;
  const sceneForm = forms.sceneForm;
  const largeSoundCategoriesParams: SoundCategoryIndexParams = {
    parentId: null,
    onlySoundEffect: true,
  };
  const largeSoundCategories = selectEntities(
    entities.soundCategories,
    queries.soundCategories,
    largeSoundCategoriesParams,
  );
  const middleSoundCategoriesParams: SoundCategoryIndexParams = {
    parentId: selectedLargeSoundCategoryId || 0,
    onlySoundEffect: true,
  };
  const middleSoundCategories = selectEntities(
    entities.soundCategories,
    queries.soundCategories,
    middleSoundCategoriesParams,
  );
  const smallSoundCategoriesParams: SoundCategoryIndexParams = {
    parentId: selectedMiddleSoundCategoryId || 0,
    onlySoundEffect: true,
  };
  const smallSoundCategories = selectEntities(
    entities.soundCategories,
    queries.soundCategories,
    smallSoundCategoriesParams,
  );
  return {
    sceneForm,
    largeSoundCategories,
    largeSoundCategoriesParams,
    middleSoundCategories,
    middleSoundCategoriesParams,
    smallSoundCategories,
    smallSoundCategoriesParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexSoundCategories: (params: SoundCategoryIndexParams) =>
      dispatch(indexSoundCategories(params)),
    indexSounds: (params: SoundIndexParams) => dispatch(indexSounds(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
