import * as React from 'react';
import {StyleProp, StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import Select from '../../../shared/forms/Select';

import {TermRange} from '../../../../view_models/TermRange';

const items: Array<{label: string; value: TermRange}> = [
  {label: '今日', value: 'today'},
  {label: '昨日', value: 'yesterday'},
  {label: '過去7日間', value: 'last_7days'},
  {label: '過去30日間', value: 'last_30days'},
  {label: '全期間', value: 'all'},
];

interface Props {
  style?: StyleProp<ViewStyle>;
  selectPicker?: StyleProp<TextStyle>;
  termRange: TermRange;
  onTermRangeChange: (termRange: TermRange) => void;
}

const TermSelect: React.FunctionComponent<Props> = props => {
  const {style, selectPicker, termRange, onTermRangeChange} = props;
  const onValueChange = React.useCallback(
    (itemValue: string | number, itemPosition: number) => {
      onTermRangeChange(itemValue as TermRange);
    },
    [],
  );
  return (
    <View style={[styles.container, style]}>
      <Select
        style={styles.select}
        pickerStyle={[styles.selectPicker, selectPicker]}
        items={items}
        value={termRange}
        onValueChange={onValueChange}
        rightIcon={<View style={styles.arrowIcon} />}
      />
    </View>
  );
};

export default React.memo(TermSelect);

const styles = StyleSheet.create({
  container: {
    margin: 16,
    marginTop: 0,
  } as ViewStyle,
  select: {
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
    height: 50,
  } as ViewStyle,
  selectPicker: {
    marginTop: 16,
    backgroundColor: '#fafafa',
  } as TextStyle,
  arrowIcon: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: '45deg'}],
    top: 5,
    right: 5,
  } as ViewStyle,
});
