import OrientedSpeechBalloon from '../../entities/OrientedSpeechBalloon';

import CharacterHideSceneCommandForm from '../../forms/scene_commands/CharacterHideSceneCommandForm';
import CharacterShowSceneCommandForm from '../../forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../forms/scene_commands/CharacterUpdateSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../forms/scene_commands/SpeechTextShowSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../forms/scene_commands/CompositeParallelSceneCommandForm';
import CompositeSequenceSceneCommandForm from '../../forms/scene_commands/CompositeSequenceSceneCommandForm';
import Position from '../../value_objects/Position';

export default class SceneCommandFormsPositionSwitcher {
  constructor(private orientedSpeechBalloons: OrientedSpeechBalloon[]) {}

  public switchPosition(
    sceneCommandForms: SceneCommandForm[],
    positionMap: Map<Position, Position>,
  ): SceneCommandForm[] {
    return sceneCommandForms.map(sceneCommandForm => {
      if (sceneCommandForm instanceof CharacterHideSceneCommandForm) {
        const newPosition = positionMap.get(sceneCommandForm.position);
        if (newPosition !== undefined) {
          return new CharacterHideSceneCommandForm(
            sceneCommandForm.characterPattern,
            sceneCommandForm.actorCharacterFace,
            sceneCommandForm.mark,
            newPosition,
            sceneCommandForm.sceneCommandId,
          );
        }
        return sceneCommandForm;
      } else if (sceneCommandForm instanceof CharacterShowSceneCommandForm) {
        const newPosition = positionMap.get(sceneCommandForm.position);
        if (newPosition !== undefined) {
          return new CharacterShowSceneCommandForm(
            sceneCommandForm.characterPattern,
            sceneCommandForm.actorCharacterFace,
            sceneCommandForm.mark,
            newPosition,
            sceneCommandForm.waiting,
            sceneCommandForm.sceneCommandId,
          );
        }
        return sceneCommandForm;
      } else if (sceneCommandForm instanceof CharacterUpdateSceneCommandForm) {
        const newPosition = positionMap.get(sceneCommandForm.position);
        if (newPosition !== undefined) {
          return new CharacterUpdateSceneCommandForm(
            sceneCommandForm.characterPattern,
            sceneCommandForm.actorCharacterFace,
            sceneCommandForm.mark,
            newPosition,
            sceneCommandForm.sceneCommandId,
          );
        }
        return sceneCommandForm;
      } else if (sceneCommandForm instanceof SpeechTextShowSceneCommandForm) {
        const found = sceneCommandForm
          .getPositions()
          .find(pos => positionMap.get(pos) !== undefined);
        if (found !== undefined) {
          const newPositions = sceneCommandForm.getPositions().map(pos => {
            const newPos = positionMap.get(pos);
            if (newPos !== undefined) {
              return newPos;
            } else {
              return pos;
            }
          });
          const newOrientedSpeechBalloon = this.selectOrientedSpeechBalloon(
            sceneCommandForm.orientedSpeechBalloon.speechBalloonId,
            newPositions,
          );
          if (newOrientedSpeechBalloon) {
            return new SpeechTextShowSceneCommandForm(
              newOrientedSpeechBalloon,
              sceneCommandForm.text,
              sceneCommandForm.overrideCharacterName,
              sceneCommandForm.characterName,
              sceneCommandForm.voice,
              sceneCommandForm.sound,
              sceneCommandForm.startTime,
              sceneCommandForm.endTime,
              sceneCommandForm.sceneCommandId,
            );
          }
        }
        return sceneCommandForm;
      } else if (
        sceneCommandForm instanceof CompositeParallelSceneCommandForm
      ) {
        return new CompositeParallelSceneCommandForm(
          this.switchPosition(
            sceneCommandForm.commandForms,
            positionMap,
          ) as any,
          sceneCommandForm.sceneCommandId,
        );
      } else if (
        sceneCommandForm instanceof CompositeSequenceSceneCommandForm
      ) {
        return new CompositeSequenceSceneCommandForm(
          this.switchPosition(sceneCommandForm.commandForms, positionMap),
          sceneCommandForm.sceneCommandId,
        );
      } else {
        return sceneCommandForm;
      }
    });
  }

  private selectOrientedSpeechBalloon(
    speechBalloonId: number,
    positions: Position[],
  ): OrientedSpeechBalloon | null {
    return (
      this.orientedSpeechBalloons.find(orientedSpeechBalloon => {
        return (
          orientedSpeechBalloon.speechBalloonId === speechBalloonId &&
          orientedSpeechBalloon.matchesPositions(positions)
        );
      }) || null
    );
  }
}
