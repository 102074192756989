import {AnyAction} from 'redux';

import {mergeResponse, QueryState} from './Response';

import Types from '../../actions/Types';

import FullScreenIllustrationUsageHistory from '../../../domain/entities/FullScreenIllustrationUsageHistory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexFullScreenIllustrationUsageHistoriesSuccess: {
      const result =
        action.payload as PaginatedResult<FullScreenIllustrationUsageHistory>;
      return mergeResponse(state, result);
    }
    case Types.UpdateFullScreenIllustrationUsageHistorySuccess: {
      const fullScreenIllustrationUsageHistory =
        action.payload as FullScreenIllustrationUsageHistory;
      const newState = {} as QueryState;
      Object.keys(state).forEach(stateKey => {
        const response = {...state[stateKey]};
        const filteredIds = response.pageToIds[1].filter(
          id => id !== fullScreenIllustrationUsageHistory.id,
        );
        if (
          stateKey.includes(
            `"storyId":${fullScreenIllustrationUsageHistory.storyId}`,
          )
        ) {
          response.pageToIds[1] = [
            fullScreenIllustrationUsageHistory.id,
            ...filteredIds,
          ];
        }
        newState[stateKey] = response;
      });
      return newState;
    }
    default:
      return state;
  }
}
