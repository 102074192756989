import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Show, {
  DispatchProps,
  StateProps,
} from '../../components/preview_scene_viewers/Show';

import AppState from '../../reducers/AppState';

import createEpisodeViewerErrorLog, {
  Params as EpisodeViewerErrorLogCreateParams,
} from '../../actions/episode_viewer_error_logs/create';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    createEpisodeViewerErrorLog: (params: EpisodeViewerErrorLogCreateParams) =>
      dispatch(createEpisodeViewerErrorLog(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
