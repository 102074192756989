import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import Episode from '../../../domain/entities/Episode';

import NetEpisodesRepository from '../../../data/repositories/writer/NetEpisodesRepository';

const types = {
  failure: Types.ShowEpisodeFailure,
  request: Types.ShowEpisodeRequest,
  success: Types.ShowEpisodeSuccess,
};

export default function action(
  id: number,
  forceUpdate?: boolean,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Episode> {
  return showGeneric(new NetEpisodesRepository(), types, id);
}
