// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line
import Icon from "react-native-vector-icons/dist/Ionicons";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import iconFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';

const loadIonicons = () => {
  const iconFontStyles = `@font-face {
    src: url(${iconFont});
    font-family: Ionicons;
  }`;
  const style = document.createElement('style');
  style.type = 'text/css';
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (style.styleSheet) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }

  // Inject stylesheet
  document.head.appendChild(style);
};

export default loadIonicons;
