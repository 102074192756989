import * as React from 'react';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import CharactersStage from './visualizations/CharactersStage';
import CharacterView from './visualizations/CharacterView';
import CharacterGhostView from './visualizations/CharacterGhostView';

import {ModalParams} from '../../Modal';

import SceneFrame, {
  CharacterSceneCommandForm,
} from '../../../../../view_models/SceneFrame';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import CharacterHideSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import Position from '../../../../../../domain/value_objects/Position';

interface Props {
  sceneCommandForm: CharacterHideSceneCommandForm;
  sceneFrame: SceneFrame;
  sceneCommandIndex: number;
  parentSceneCommandForm?: SceneCommandForm | null;
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
  onRequestOpenModal: (modalParams: ModalParams) => void;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
}

export default class CharacterHideSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, [
      'actorCharacterFace',
      'sceneCommandForm',
      'sceneFrame',
    ]);
  }

  public render(): React.ReactNode {
    const {sceneCommandForm, sceneFrame, onChangeOrder} = this.props;
    const {position} = sceneCommandForm;
    return (
      <CharactersStage
        leftCharacter={
          position === Position.Left
            ? this.renderCharacter(sceneCommandForm)
            : sceneFrame.leftCharacter && sceneFrame.isActive(Position.Left)
            ? this.renderCharacterForSceneFrame(sceneFrame.leftCharacter)
            : this.renderGhostCharacter(Position.Left)
        }
        centerCharacter={
          position === Position.Center
            ? this.renderCharacter(sceneCommandForm)
            : sceneFrame.centerCharacter && sceneFrame.isActive(Position.Center)
            ? this.renderCharacterForSceneFrame(sceneFrame.centerCharacter)
            : this.renderGhostCharacter(Position.Center)
        }
        rightCharacter={
          position === Position.Right
            ? this.renderCharacter(sceneCommandForm)
            : sceneFrame.rightCharacter && sceneFrame.isActive(Position.Right)
            ? this.renderCharacterForSceneFrame(sceneFrame.rightCharacter)
            : this.renderGhostCharacter(Position.Right)
        }
        characterNameDisplay={false}
        onChangeOrder={onChangeOrder}
      />
    );
  }

  private renderCharacter = (
    sceneCommandForm: CharacterHideSceneCommandForm,
  ): React.ReactNode => {
    const {actorCharacterFace, mark, position} = sceneCommandForm;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <CharacterView
            actorCharacterFace={actorCharacterFace}
            mark={mark}
            characterHide={true}
            label={'退場'}
          />
        }
        onRequestOpenModal={() => {
          this.handleRequestOpenModal(position);
        }}
      />
    );
  };

  private renderCharacterForSceneFrame = (
    sceneCommandForm: CharacterSceneCommandForm,
  ): React.ReactNode => {
    const {position} = sceneCommandForm;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <CharacterView
            actorCharacterFace={sceneCommandForm.actorCharacterFace}
            mark={sceneCommandForm.mark}
            transparent={true}
          />
        }
        onRequestOpenModal={() => {
          this.handleRequestOpenModal(position);
        }}
      />
    );
  };

  private renderGhostCharacter = (position: Position): React.ReactNode => {
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={<CharacterGhostView position={position} />}
        onRequestOpenModal={() => {
          this.handleRequestOpenModal(position);
        }}
      />
    );
  };

  private handleRequestOpenModal = (position: Position) => {
    const {
      sceneCommandForm,
      sceneCommandIndex,
      sceneFrame,
      parentSceneCommandForm,
      onRequestOpenModal,
      onForwardToCharacters,
    } = this.props;
    if (sceneCommandForm.position === position) {
      onRequestOpenModal({
        type: 'CharacterHideSceneCommandModal',
        sceneCommandForm,
        sceneCommandIndex,
      });
    } else {
      switch (position) {
        case Position.Left:
          if (sceneFrame.leftCharacter && sceneFrame.isActive(Position.Left)) {
            onRequestOpenModal({
              type: 'CurrentSceneFrameModal',
              sceneCommandForm: sceneFrame.leftCharacter,
              sceneCommandIndex,
              parentSceneCommandForm,
            });
          } else {
            onForwardToCharacters({
              sceneCommandIndex,
              parentSceneCommandId: sceneCommandForm.sceneCommandId,
              position: Position.Left,
            });
          }
          break;
        case Position.Center:
          if (
            sceneFrame.centerCharacter &&
            sceneFrame.isActive(Position.Center)
          ) {
            onRequestOpenModal({
              type: 'CurrentSceneFrameModal',
              sceneCommandForm: sceneFrame.centerCharacter,
              sceneCommandIndex,
              parentSceneCommandForm,
            });
          } else {
            onForwardToCharacters({
              sceneCommandIndex,
              parentSceneCommandId: sceneCommandForm.sceneCommandId,
              position: Position.Center,
            });
          }
          break;
        case Position.Right:
          if (
            sceneFrame.rightCharacter &&
            sceneFrame.isActive(Position.Right)
          ) {
            onRequestOpenModal({
              type: 'CurrentSceneFrameModal',
              sceneCommandForm: sceneFrame.rightCharacter,
              sceneCommandIndex,
              parentSceneCommandForm,
            });
          } else {
            onForwardToCharacters({
              sceneCommandIndex,
              parentSceneCommandId: sceneCommandForm.sceneCommandId,
              position: Position.Right,
            });
          }
          break;
      }
    }
  };
}
