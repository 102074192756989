import NetResourceRepository from '../NetResourceRepository';
import NetResourceRepositoryAdapter from '../NetResourceRepositoryAdapter';

import CurrentUserStatusMapper, {
  CurrentUserStatusData,
} from '../../entities/mappers/writer/CurrentUserStatusMapper';

import CurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';
import CurrentUserStatusRepository from '../../../domain/repositories/writer/CurrentUserStatusRepository';

export default class NetCurrentUserStatusRepository
  extends NetResourceRepository<CurrentUserStatus, CurrentUserStatusData>
  implements CurrentUserStatusRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/writer/current_user_status',
        new CurrentUserStatusMapper(),
      ),
    );
  }
}
