import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/response/stories/Index';

import AppState from '../../../reducers/AppState';

import indexResponseStories, {
  Params as ResponseStoryIndexParams,
} from '../../../actions/response/stories/index';

import selectEntities from '../../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries} = state;
  const storiesParams: ResponseStoryIndexParams = {};
  const stories = selectEntities(
    entities.response.stories,
    queries.response.stories,
    storiesParams,
  );
  return {
    navigation,
    stories,
    storiesParams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexResponseStories: (params: ResponseStoryIndexParams) =>
      dispatch(indexResponseStories(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
