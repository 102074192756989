import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import SoundUsageHistory from '../../../domain/entities/SoundUsageHistory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetSoundUsageHistoriesRepository from '../../../data/repositories/writer/NetSoundUsageHistoriesRepository';

const types = {
  failure: Types.IndexSoundUsageHistoriesFailure,
  request: Types.IndexSoundUsageHistoriesRequest,
  success: Types.IndexSoundUsageHistoriesSuccess,
};

export interface Params extends BaseParams {
  storyId: number;
  soundType: 'background_music' | 'sound_effect';
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<SoundUsageHistory>> {
  return indexGenerics(new NetSoundUsageHistoriesRepository(), types, params);
}
