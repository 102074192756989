import * as React from 'react';
import {ImageStyle, Platform, StyleSheet, View, ViewStyle} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';

import {effectImageUrl} from '../../../../../../helpers/images';

import Effect from '../../../../../../../domain/entities/Effect';

const RESIZE_MODE = 'contain';
const IMAGE_SIZE = 'large';
const headers = {Accept: 'image/webp,image/apng,*/*'};

interface Props {
  effect: Effect;
  children?: React.ReactNode;
}

const IllustrationView: React.FunctionComponent<Props> = props => {
  const {effect} = props;
  return (
    <View style={styles.container}>
      <FastImageAdapter
        resizeMode={RESIZE_MODE}
        style={styles.image}
        source={{
          uri: effectImageUrl(effect, IMAGE_SIZE),
          headers,
        }}
      />
    </View>
  );
};

export default React.memo(IllustrationView);

const width = 246;
const height = 200;
const aspectRatio = width / height;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: '100%',
    marginVertical: 5,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
  image: {
    aspectRatio,
    backgroundColor: 'rgba(200, 200, 200, 0.9)',
    width,
    height,
  } as ImageStyle,
});
