import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Edit, {DispatchProps, StateProps} from '../../components/scenes/Edit';

import AppState from '../../reducers/AppState';

import showCurrentUserStatus from '../../actions/writer/current_user_status/show';
import indexActorCharacterFaces, {
  Params as ActorCharacterFaceIndexParams,
} from '../../actions/actor_character_faces/index';
import indexMarks, {Params as MarkIndexParams} from '../../actions/marks/index';
import indexSpeechBalloons, {
  Params as SpeechBalloonIndexParams,
} from '../../actions/speech_balloons/index';
import indexOrientedSpeechBalloons, {
  Params as OrientedSpeechBalloonIndexParams,
} from '../../actions/oriented_speech_balloons/index';
import indexTextFrames, {
  Params as TextFramesIndexParams,
} from '../../actions/text_frames/index';
import createSceneCommandFormLists, {
  Params as SceneCommandFormListsCreateParams,
} from '../../actions/scene_command_form_lists/create';
import updateSceneCommandFormLists, {
  Params as SceneCommandFormListsUpdateParams,
} from '../../actions/scene_command_form_lists/update';
import createSceneCommandForm, {
  Params as SceneCommandFormCreateParams,
} from '../../actions/scene_command_forms/create';
import destroySceneCommandForm, {
  Params as SceneCommandFormDestroyParams,
} from '../../actions/scene_command_forms/destroy';
import updateSceneCommandForm, {
  Params as SceneCommandFormUpdateParams,
} from '../../actions/scene_command_forms/update';
import destroySceneForm from '../../actions/scene_forms/destroy';
import updateSceneForm, {
  Params as SceneFormUpdateParams,
} from '../../actions/scene_forms/update';
import createSceneScript, {
  Params as SceneScriptCreateParams,
} from '../../actions/scene_scripts/create';
import showSceneWithCommandsWrapper from '../../actions/scene_with_commands_wrappers/show';
import showEpisode from '../../actions/episodes/show';
import updateScene, {
  Params as SceneUpdateParams,
} from '../../actions/scenes/update';
import indexFullScreenIllustrations, {
  Params as FullScreenIllustrationIndexParams,
} from '../../actions/full_screen_illustrations/index';
import indexCharacterPatterns, {
  Params as CharacterPatternIndexParams,
} from '../../actions/character_patterns/index';
import createCharacterPattern, {
  Params as CharacterPatternCreateParams,
} from '../../actions/character_patterns/create';
import updateCharacterPatternUsageHistory from '../../actions/character_pattern_usage_histories/update';
import indexActorCostumes, {
  Params as ActorCostumeIndexParams,
} from '../../actions/actor_costumes/index';
import indexActorHairStyles, {
  Params as ActorHairStyleIndexParams,
} from '../../actions/actor_hair_styles/index';
import indexActorAccessorySets, {
  Params as ActorAccessorySetIndexParams,
} from '../../actions/actor_accessory_sets/index';
import createVoice, {
  Params as VoiceCreateParams,
} from '../../actions/voices/create';
import updateVoice, {
  Params as VoiceUpdateParams,
} from '../../actions/voices/update';
import updateCoachmarkModal, {
  Params as CoachmarkModalUpdateParams,
} from '../../actions/coachmark_modal/update';
import updateApplicationActionModal, {
  Params as ApplicationActionModalUpdateParams,
} from '../../actions/application_action_modal/update';

import selectEntities from '../../helpers/selectEntities';
import selectEntity from '../../helpers/selectEntity';
import {EditSceneRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: EditSceneRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;
  const currentUserStatus = currentUser
    ? selectEntity(
        entities.writer.currentUserStatuses,
        currentUser.writerUserId,
      )
    : null;
  const sceneForm = forms.sceneForm;
  const sceneCommandForms = forms.sceneCommandForms;
  const {sceneId} = route.params;
  const sceneWithCommandsWrapper = selectEntity(
    entities.sceneWithCommandsWrappers,
    sceneId,
  );
  const speechBalloonsParams: SpeechBalloonIndexParams = {};
  const speechBalloons = selectEntities(
    entities.speechBalloons,
    queries.speechBalloons,
    speechBalloonsParams,
  );
  const orientedSpeechBalloonsParams: OrientedSpeechBalloonIndexParams = {};
  const orientedSpeechBalloons = selectEntities(
    entities.orientedSpeechBalloons,
    queries.orientedSpeechBalloons,
    orientedSpeechBalloonsParams,
  );
  const textFramesParams: TextFramesIndexParams = {};
  const textFrames = selectEntities(
    entities.textFrames,
    queries.textFrames,
    speechBalloonsParams,
  );
  const fullScreenIllustrationsParams: FullScreenIllustrationIndexParams = {
    sceneId,
  };
  const fullScreenIllustrations = selectEntities(
    entities.fullScreenIllustrations,
    queries.fullScreenIllustrations,
    fullScreenIllustrationsParams,
  );
  const marksParams = {};
  const marks = selectEntities(entities.marks, queries.marks, marksParams);
  const actorCharacterFaceEntities = entities.actorCharacterFaces;
  const actorCharacterFaceQueries = queries.actorCharacterFaces;
  return {
    currentUser,
    currentUserStatus,
    actorCharacterFaceEntities,
    actorCharacterFaceQueries,
    fullScreenIllustrations,
    fullScreenIllustrationsParams,
    marks,
    marksParams,
    navigation,
    speechBalloons,
    speechBalloonsParams,
    orientedSpeechBalloons,
    orientedSpeechBalloonsParams,
    textFrames,
    textFramesParams,
    sceneCommandForms,
    sceneForm,
    sceneWithCommandsWrapper,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showCurrentUserStatus: () => dispatch(showCurrentUserStatus()),
    indexActorCharacterFaces: (params: ActorCharacterFaceIndexParams) =>
      dispatch(indexActorCharacterFaces(params)),
    indexMarks: (params: MarkIndexParams) => dispatch(indexMarks(params)),
    createSceneCommandForm: (params: SceneCommandFormCreateParams) =>
      dispatch(createSceneCommandForm(params)),
    createSceneCommandFormLists: (params: SceneCommandFormListsCreateParams) =>
      dispatch(createSceneCommandFormLists(params)),
    createSceneScript: (params: SceneScriptCreateParams) =>
      dispatch(createSceneScript(params)),
    destroySceneCommandForm: (params: SceneCommandFormDestroyParams) =>
      dispatch(destroySceneCommandForm(params)),
    destroySceneForm: () => dispatch(destroySceneForm()),
    indexFullScreenIllustrations: (params: FullScreenIllustrationIndexParams) =>
      dispatch(indexFullScreenIllustrations(params)),
    indexSpeechBalloons: (params: SpeechBalloonIndexParams) =>
      dispatch(indexSpeechBalloons(params)),
    indexOrientedSpeechBalloons: (params: OrientedSpeechBalloonIndexParams) =>
      dispatch(indexOrientedSpeechBalloons(params)),
    indexTextFrames: (params: TextFramesIndexParams) =>
      dispatch(indexTextFrames(params)),
    showSceneWithCommandsWrapper: (id: number) =>
      dispatch(showSceneWithCommandsWrapper(id)),
    showEpisode: (id: number) => dispatch(showEpisode(id)),
    updateScene: (id: number, params: SceneUpdateParams) =>
      dispatch(updateScene(id, params)),
    updateSceneCommandForm: (params: SceneCommandFormUpdateParams) =>
      dispatch(updateSceneCommandForm(params)),
    updateSceneCommandFormLists: (params: SceneCommandFormListsUpdateParams) =>
      dispatch(updateSceneCommandFormLists(params)),
    updateSceneForm: (params: SceneFormUpdateParams) =>
      dispatch(updateSceneForm(params)),
    indexCharacterPatterns: (params: CharacterPatternIndexParams) =>
      dispatch(indexCharacterPatterns(params)),
    createCharacterPattern: (params: CharacterPatternCreateParams) =>
      dispatch(createCharacterPattern(params)),
    updateCharacterPatternUsageHistory: (id: number) =>
      dispatch(updateCharacterPatternUsageHistory(id)),
    indexActorCostumes: (params: ActorCostumeIndexParams) =>
      dispatch(indexActorCostumes(params)),
    indexActorHairStyles: (params: ActorHairStyleIndexParams) =>
      dispatch(indexActorHairStyles(params)),
    indexActorAccessorySets: (params: ActorAccessorySetIndexParams) =>
      dispatch(indexActorAccessorySets(params)),
    createVoice: (params: VoiceCreateParams) => dispatch(createVoice(params)),
    updateVoice: (id: number, params: VoiceUpdateParams) =>
      dispatch(updateVoice(id, params)),
    updateCoachmarkModal: (params: CoachmarkModalUpdateParams) =>
      dispatch(updateCoachmarkModal(params)),
    updateApplicationActionModal: (
      params: ApplicationActionModalUpdateParams,
    ) => dispatch(updateApplicationActionModal(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Edit);
