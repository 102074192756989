import ActorCategory from '../../domain/entities/ActorCategory';

const AGE_VALUE_TO_ACTOR_CATEGORY_ID = {
  0: 6,
  1: 7,
  2: 8,
  3: 9,
  4: 10,
  5: 11,
  6: 12,
} as {[key: number]: number};

export default (formValues: {
  query: string;
  ageMin: number;
  ageMax: number;
  rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
  favorite?: boolean;
  purchase?: boolean;
  paid?: boolean;
  allowedInversion?: Array<'true' | 'false'>;
  withPaid?: boolean;
}) => {
  const {
    query,
    ageMin,
    ageMax,
    rootCategoryIdToActorCategoryIds,
    favorite,
    purchase,
    paid,
    allowedInversion,
    withPaid,
  } = formValues;
  const params: {[key: string]: any} = {};
  if (favorite) {
    params['favorite'] = favorite;
  }
  if (purchase) {
    params['purchase'] = purchase;
  }
  if (query) {
    params['query'] = query;
  }
  if (paid) {
    params['paid'] = paid;
  }
  if (withPaid) {
    params['with_paid'] = withPaid;
  }
  const ageActorCategoryIds = getAgeActorCategoryIds(ageMin, ageMax);
  if (ageActorCategoryIds.length > 0) {
    const parentId = ActorCategory.HUMAN_AGE_ID;
    ageActorCategoryIds.forEach((id, index) => {
      params[`filters[${parentId}][actor_category_id][${index}]`] = id;
    });
  }
  if (typeof rootCategoryIdToActorCategoryIds === 'object') {
    Object.keys(rootCategoryIdToActorCategoryIds).forEach(parentId => {
      const ids = rootCategoryIdToActorCategoryIds[parentId];
      ids.forEach((id, index) => {
        params[`filters[${parentId}][actor_category_id][${index}]`] = id;
      });
    });
  }
  if (allowedInversion) {
    if (
      allowedInversion.includes('true') &&
      allowedInversion.includes('false')
    ) {
      //
    } else if (allowedInversion.includes('true')) {
      params['allowed_inversion'] = true;
    } else if (allowedInversion.includes('false')) {
      params['allowed_inversion'] = false;
    }
  }
  return params;
};

const getAgeActorCategoryIds = (ageMin: number, ageMax: number) => {
  const ids: Array<number> = [];
  if (ageMin !== 0 || ageMax !== 6) {
    for (let i = ageMin; i <= ageMax; i += 1) {
      ids.push(AGE_VALUE_TO_ACTOR_CATEGORY_ID[i]);
    }
    if (ageMin > ageMax) {
      ids.push(0);
    }
  }
  return ids;
};
