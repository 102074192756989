import React from 'react';

import RootStack from '../RootStack';

import Home from '../../containers/home/Index';

import Helps from '../../containers/helps/Index';
import Help from '../../containers/helps/Show';
import Manuals from '../../containers/manuals/Index';
import Manual from '../../containers/manuals/Show';

const PublicScreens = (
  <>
    <RootStack.Screen
      name="Home"
      component={Home}
      options={{title: 'TapNovelMaker（タップノベルメーカー）'}}
    />

    <RootStack.Screen
      name="Helps"
      component={Helps}
      options={{title: 'ヘルプ'}}
    />

    <RootStack.Screen
      name="Help"
      component={Help}
      options={{title: 'ヘルプ'}}
    />
    <RootStack.Screen
      name="Manuals"
      component={Manuals}
      options={{title: 'マニュアル'}}
    />

    <RootStack.Screen
      name="Manual"
      component={Manual}
      options={{title: 'マニュアル'}}
    />
  </>
);

export default PublicScreens;
