import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import EffectUsageHistory from '../../../domain/entities/EffectUsageHistory';

export default function reducer(
  state: {[key: number]: EffectUsageHistory} = {},
  action: AnyAction,
): {[key: number]: EffectUsageHistory} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexEffectUsageHistoriesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (
            obj: {[key: number]: EffectUsageHistory},
            record: EffectUsageHistory,
          ) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.UpdateEffectUsageHistorySuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
