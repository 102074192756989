import * as React from 'react';
import {StyleSheet, ViewStyle, Platform} from 'react-native';
import SortableList, {RowProps} from 'react-native-sortable-list';
import {shallowEqual} from 'recompose';

import CharacterStagePosition from './CharacterStagePosition';

import Position from '../../../../../../../domain/value_objects/Position';

type DataKey = string | number;

interface Props {
  leftCharacter?: React.ReactNode;
  centerCharacter?: React.ReactNode;
  rightCharacter?: React.ReactNode;
  characterNameDisplay: boolean;
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
}

interface State {
  nextOrder: DataKey[] | null;
  sorting: boolean;
}

export default class CharactersStage extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      nextOrder: null,
      sorting: false,
    };
  }

  public componentDidMount() {
    this.mounted = true;
  }

  public componentWillUnmount() {
    this.mounted = false;
  }

  public render(): React.ReactNode {
    const {
      leftCharacter,
      centerCharacter,
      rightCharacter,
      characterNameDisplay,
    } = this.props;
    if (!leftCharacter && !centerCharacter && !rightCharacter) {
      return null;
    }
    return (
      <SortableList
        style={[
          {
            height: characterNameDisplay ? 170 : 150,
            flexDirection: 'row',
          },
          this.state.sorting ? styles.containerActive : null,
        ]}
        scrollEnabled={Platform.select({web: true, default: false})}
        horizontal={true}
        data={[leftCharacter, centerCharacter, rightCharacter]}
        renderRow={this.renderRow}
        onChangeOrder={this.handleChangeOrder}
        onActivateRow={this.handleActivateRow}
        onReleaseRow={this.handleReleaseRow}
        rowActivationTime={400}
      />
    );
  }

  private generatePositionMap(
    nextOrder: DataKey[] | null,
  ): Map<Position, Position> | null {
    const map = new Map();
    if (nextOrder === null || shallowEqual(nextOrder, ['0', '1', '2'])) {
      return null;
    } else if (shallowEqual(nextOrder, ['0', '2', '1'])) {
      map.set(Position.Center, Position.Right);
      map.set(Position.Right, Position.Center);
    } else if (shallowEqual(nextOrder, ['1', '0', '2'])) {
      map.set(Position.Left, Position.Center);
      map.set(Position.Center, Position.Left);
    } else if (shallowEqual(nextOrder, ['1', '2', '0'])) {
      map.set(Position.Left, Position.Right);
      map.set(Position.Center, Position.Left);
      map.set(Position.Right, Position.Center);
    } else if (shallowEqual(nextOrder, ['2', '0', '1'])) {
      map.set(Position.Left, Position.Center);
      map.set(Position.Center, Position.Right);
      map.set(Position.Right, Position.Left);
    } else if (shallowEqual(nextOrder, ['2', '1', '0'])) {
      map.set(Position.Left, Position.Right);
      map.set(Position.Right, Position.Left);
    }
    return map;
  }

  private renderRow = (
    props: RowProps<React.ReactNode, number>,
  ): JSX.Element => {
    const {active, data} = props;
    return (
      <CharacterStagePosition active={active}>{data}</CharacterStagePosition>
    );
  };

  private handleChangeOrder = (nextOrder: DataKey[]) => {
    this.setStateIfMounted({nextOrder});
  };

  private handleActivateRow = (key: DataKey) => {
    this.setStateIfMounted({nextOrder: null, sorting: true});
  };

  private handleReleaseRow = (key: DataKey) => {
    const {onChangeOrder} = this.props;
    const positionMap = this.generatePositionMap(this.state.nextOrder);
    this.setStateIfMounted({nextOrder: null, sorting: false}, () => {
      if (positionMap) {
        onChangeOrder(positionMap);
      }
    });
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ): void {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }
}

const styles = StyleSheet.create({
  containerActive: {
    backgroundColor: '#eed',
  } as ViewStyle,
});
