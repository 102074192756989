import * as React from 'react';

import WriterUserImage from './WriterUserImage';

import ProjectMember from '../../../../../domain/entities/ProjectMember';

interface Props {
  member: ProjectMember;
  imageSize?: number;
  withRankColor?: boolean;
}

const MemberImage: React.FC<Props> = props => {
  const {member, imageSize, withRankColor} = props;
  return (
    <WriterUserImage
      user={member.writerUser}
      imageSize={imageSize}
      withRankColor={withRankColor}
    />
  );
};

export default React.memo(MemberImage);
