import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../components/character_form/edit/character_patterns/Index';

import AppState from '../../../../reducers/AppState';

import indexCharacterPatterns, {
  Params as CharacterPatternIndexParams,
} from '../../../../actions/character_patterns/index';
import indexActorCharacterFaces, {
  Params as ActorCharacterFaceIndexParams,
} from '../../../../actions/actor_character_faces/index';
import updateCharacterForm, {
  Params as CharacterFormUpdateParams,
} from '../../../../actions/character_forms/update';

import selectEntities from '../../../../helpers/selectEntities';

import {CharacterFormEditCharacterPatternsRouteProp} from '../../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: CharacterFormEditCharacterPatternsRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries} = state;
  const {characterId} = route.params;
  const characterPatternsParams: CharacterPatternIndexParams = {
    characterId,
  };
  const characterPatterns = selectEntities(
    entities.characterPatterns,
    queries.characterPatterns,
    characterPatternsParams,
  );
  return {
    characterPatterns,
    characterPatternsParams,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexCharacterPatterns: (params: CharacterPatternIndexParams) =>
      dispatch(indexCharacterPatterns(params)),
    indexActorCharacterFaces: (params: ActorCharacterFaceIndexParams) =>
      dispatch(indexActorCharacterFaces(params)),
    updateCharacterForm: (params: CharacterFormUpdateParams) =>
      dispatch(updateCharacterForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
