import * as React from 'react';

import ActorCharacterFaces from './partials/ActorCharacterFaces';

import ActorCharacterFaceBuilder from '../../../character_maker/ActorCharacterFaceBuilder';

import NavigationProp from '../../../../../navigators/NavigationProp';

import {Params as CharacterPatternIndexParams} from '../../../../../actions/character_patterns/index';
import {Params as CharacterPatternCreateParams} from '../../../../../actions/character_patterns/create';
import {Params as ActorCharacterFaceIndexParams} from '../../../../../actions/actor_character_faces/index';
import {Params as ActorCostumeIndexParams} from '../../../../../actions/actor_costumes/index';
import {Params as ActorHairStyleIndexParams} from '../../../../../actions/actor_hair_styles/index';
import {Params as ActorAccessorySetIndexParams} from '../../../../../actions/actor_accessory_sets/index';
import {Params as MarkIndexParams} from '../../../../../actions/marks/index';

import ActorCharacterFace from '../../../../../../domain/entities/ActorCharacterFace';
import ActorCostume from '../../../../../../domain/entities/ActorCostume';
import ActorHairStyle from '../../../../../../domain/entities/ActorHairStyle';
import ActorAccessorySet from '../../../../../../domain/entities/ActorAccessorySet';
import Character from '../../../../../../domain/entities/Character';
import CharacterPattern from '../../../../../../domain/entities/CharacterPattern';
import MetaCharacterPattern from '../../../../../../domain/entities/MetaCharacterPattern';

import Mark from '../../../../../../domain/entities/Mark';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

import NetMetaCharacterPatternsRepository from '../../../../../../data/repositories/writer/NetMetaCharacterPatternsRepository';

const metaCharacterPatternsRepository =
  new NetMetaCharacterPatternsRepository();
interface Props {
  navigation: NavigationProp;
  back?: boolean;
  loading?: boolean;
  characterId: number;
  characterPatternId: number;
  marksParams: MarkIndexParams;
  marks: Mark[] | null;
  character: Character | null;
  characterPattern: CharacterPattern;
  hideTab?: boolean;
  onSelectActorCharacterFace: (
    characterPattern: CharacterPattern,
    actorCharacterFace: ActorCharacterFace,
    mark?: Mark | null,
  ) => void;
  showCharacter: (id: number) => Promise<Character>;
  indexCharacterPatterns: (
    params: CharacterPatternIndexParams,
  ) => Promise<PaginatedResult<CharacterPattern>>;
  createCharacterPattern: (
    params: CharacterPatternCreateParams,
  ) => Promise<CharacterPattern>;
  updateCharacterPatternUsageHistory: (id: number) => Promise<CharacterPattern>;
  indexActorCharacterFaces: (
    params: ActorCharacterFaceIndexParams,
  ) => Promise<PaginatedResult<ActorCharacterFace>>;
  indexActorCostumes: (
    params: ActorCostumeIndexParams,
  ) => Promise<PaginatedResult<ActorCostume>>;
  indexActorHairStyles: (
    params: ActorHairStyleIndexParams,
  ) => Promise<PaginatedResult<ActorHairStyle>>;
  indexActorAccessorySets: (
    params: ActorAccessorySetIndexParams,
  ) => Promise<PaginatedResult<ActorAccessorySet>>;
  indexMarks: (params: MarkIndexParams) => Promise<PaginatedResult<Mark>>;
}

const Index: React.FunctionComponent<Props> = props => {
  const {characterPatternId, marks, marksParams, indexMarks} = props;
  const [metaCharacterPattern, setMetaCharacterPattern] =
    React.useState<MetaCharacterPattern | null>(null);
  React.useEffect(() => {
    metaCharacterPatternsRepository
      .find(characterPatternId)
      .then(metaCharacterPattern => {
        setMetaCharacterPattern(metaCharacterPattern);
      });
    if (!marks) {
      indexMarks(marksParams);
    }
  }, []);
  if (!metaCharacterPattern) {
    return null;
  }
  return metaCharacterPattern.characterMakerOnly ? (
    <ActorCharacterFaceBuilder
      {...props}
      metaCharacterPattern={metaCharacterPattern}
    />
  ) : (
    <ActorCharacterFaces {...props} />
  );
};

export default React.memo(Index);
