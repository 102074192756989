import Entity from './Entity';

import SceneForm from '../forms/SceneForm';
import SceneCommandForm from '../forms/scene_commands/SceneCommandForm';

export default class AutoSaveCreatingScene implements Entity {
  constructor(
    public id: number,
    public sceneForm: SceneForm,
    public sceneCommandForms: SceneCommandForm[],
    public updatedAt: Date,
  ) {}
}
