import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import CheckCircleIcon from '../../../../../shared/icons/CheckCircleIcon';

import {colors} from '../../../../../../styles/variables';

import Position from '../../../../../../../domain/value_objects/Position';

const checkedColor = '#ff8f13';
const uncheckedColor = '#efefef';

interface Props {
  value: Position;
  label: string;
  checked: boolean;
  onSelectPosition: (position: Position, toggleMultiple?: boolean) => void;
}

export default class PositionList extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {value, label, checked, onSelectPosition} = this.props;
    return (
      <Pressable
        key={value}
        style={checked ? styles.containerActive : styles.container}
        onPress={() => onSelectPosition(value)}
        onLongPress={() => onSelectPosition(value, true)}>
        <View style={styles.icon}>
          <CheckCircleIcon
            size={13}
            color={checked ? checkedColor : uncheckedColor}
          />
        </View>
        <Text style={[styles.label]}>{label}</Text>
      </Pressable>
    );
  }
}

const container = {
  height: 98,
  width: 90,
  flex: 1,
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: 1,
  borderColor: '#efefef',
  borderRadius: 4,
  marginHorizontal: 4,
} as ViewStyle;

const styles = StyleSheet.create({
  container,
  containerActive: {
    ...container,
    borderColor: '#ff8f13',
  } as ViewStyle,
  icon: {
    position: 'absolute',
    top: 4,
    left: 4,
  } as ViewStyle,
  label: {
    color: colors.gray,
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
});
