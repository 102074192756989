import * as React from 'react';
import {FlatList, ListRenderItemInfo, Text, View} from 'react-native';

import OfferManuscriptListItem from './OfferManuscriptListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import OfferManuscript from '../../../../../domain/entities/writers_gate/OfferManuscript';

interface Props {
  offerManuscripts: OfferManuscript[];
  title: string;
  ListFooterComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const OfferManuscriptList: React.FC<Props> = props => {
  const {offerManuscripts, title, ListFooterComponent} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<OfferManuscript>) => (
      <OfferManuscriptListItem offerManuscript={info.item} />
    ),
    [],
  );
  if (offerManuscripts.length == 0) {
    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <Text>{title}オファーはありません。</Text>
      </View>
    );
  }
  return (
    <FlatList
      data={offerManuscripts}
      style={{backgroundColor: '#fafafa'}}
      renderItem={renderItem}
      ListHeaderComponent={ListItemNormalSeparator}
      ListFooterComponent={
        <>
          <ListItemNormalSeparator />
          {ListFooterComponent}
        </>
      }
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(OfferManuscriptList);
