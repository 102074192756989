import * as React from 'react';
import {
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import WriterUserAvator from '../../questions/components/WriterUserAvator';

import Reply from '../../../../../domain/entities/writer/faq/Reply';

interface Props {
  style?: StyleProp<ViewStyle>;
  reply: Reply;
}

const ReplyListItem: React.FC<Props> = props => {
  const {style, reply} = props;
  return (
    <View style={[styles.container, style]}>
      <WriterUserAvator
        writerUser={reply.writerUser}
        time={reply.createdAt}
        size={40}
      />
      <Text style={styles.content}>{reply.content}</Text>
    </View>
  );
};

export default React.memo(ReplyListItem);

const styles = StyleSheet.create({
  container: {
    padding: 16,
  } as ViewStyle,
  content: {
    color: '#222',
    fontSize: 14,
    marginTop: 16,
  } as TextStyle,
});
