import * as React from 'react';
import {
  ActivityIndicator,
  NativeScrollEvent,
  NativeSyntheticEvent,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import {SafeAreaInsetsContext} from 'react-native-safe-area-context';
import {ActionSheetProvider} from '@expo/react-native-action-sheet';

import NavigationProp from '../../navigators/NavigationProp';

import NavigationBar from './NavigationBar';
import {Props as NavigationBarButtonProps} from './NavigationBarButton';

import ArrowBackIcon from './icons/ArrowBackIcon';
import CloseIcon from './icons/CloseIcon';
import UserSelectContent from './UserSelectContent';

type Navigation = NavigationProp;

type ButtonProps = NavigationBarButtonProps | null;

interface Props extends React.PropsWithChildren {
  title?: string | null;
  element?: React.ReactElement<any> | React.ReactText | null;
  leftButton?: ButtonProps;
  rightButton?: ButtonProps;
  innerRightButton?: ButtonProps;
  navigation?: Navigation;
  back?: boolean;
  nolayout?: boolean;
  close?: boolean;
  scrollable?: boolean;
  bounces?: boolean;
  containerStyle?: ViewStyle;
  hideHeaderBottomBorder?: boolean;
  scrollViewStyle?: ViewStyle;
  loading?: boolean;
  footer?: React.ReactNode;
  safeAreaTopStyle?: StyleProp<ViewStyle>;
  alwaysEnableBackButton?: boolean;
  enabledUserSelect?: boolean;
  onPressBack?: () => void;
  onPressClose?: () => void;
  onScroll?: (event?: NativeSyntheticEvent<NativeScrollEvent>) => void;
  onScrollEndDrag?: (event: NativeSyntheticEvent<NativeScrollEvent>) => void;
}

export default class Index extends React.PureComponent<Props> {
  private scrollView: ScrollView | null = null;

  private disabledSubmit = false;

  public render(): React.ReactNode {
    const {
      title,
      element,
      scrollable,
      bounces,
      children,
      containerStyle,
      hideHeaderBottomBorder,
      scrollViewStyle,
      loading,
      footer,
      safeAreaTopStyle,
      nolayout,
      innerRightButton,
      enabledUserSelect,
      onScroll,
      onScrollEndDrag,
    } = this.props;
    if (nolayout) {
      return <React.Fragment>{children}</React.Fragment>;
    }
    return (
      <SafeAreaInsetsContext.Consumer>
        {insets => (
          <ActionSheetProvider>
            <View style={[styles.container, containerStyle]}>
              <View
                style={[
                  {paddingTop: insets?.top},
                  styles.safeAreaTop,
                  safeAreaTopStyle,
                ]}
              />

              <NavigationBar
                title={title}
                element={element}
                leftButton={this.renderLeftBarButton()}
                rightButton={this.renderRightBarButton()}
                innerRightButton={innerRightButton}
                hideBottomBorder={hideHeaderBottomBorder}
              />

              <UserSelectContent enabled={enabledUserSelect}>
                {scrollable !== false ? (
                  <ScrollView
                    ref={scrollView => {
                      this.scrollView = scrollView as any;
                    }}
                    style={scrollViewStyle}
                    bounces={bounces === undefined ? false : bounces}
                    scrollEventThrottle={16}
                    onScroll={onScroll}
                    automaticallyAdjustContentInsets={false}
                    onScrollEndDrag={onScrollEndDrag}>
                    {children}
                  </ScrollView>
                ) : (
                  <View style={styles.inner}>{children}</View>
                )}
              </UserSelectContent>

              <View
                style={[
                  {
                    paddingBottom: insets?.bottom,
                    backgroundColor: 'transparent',
                  },
                ]}
              />

              {loading && (
                <View style={styles.loading}>
                  <ActivityIndicator size="large" color="#999999" />
                </View>
              )}
              {footer}
            </View>
          </ActionSheetProvider>
        )}
      </SafeAreaInsetsContext.Consumer>
    );
  }

  public scrollToEnd() {
    if (!this.scrollView) {
      return;
    }
    this.scrollView.scrollToEnd();
  }

  private renderLeftBarButton(): ButtonProps | undefined {
    const {leftButton, navigation, back, alwaysEnableBackButton, onPressBack} =
      this.props;
    if (leftButton) {
      return leftButton;
    }
    if (back && navigation) {
      return {
        handler:
          onPressBack ||
          (() => {
            if (this.disabledSubmit) {
              return;
            }
            if (!alwaysEnableBackButton) {
              this.disabledSubmit = true;
            }
            navigation.goBack();
          }),
        tintColor: 'white',
        title: <ArrowBackIcon />,
      };
    }
    return undefined;
  }

  private renderRightBarButton(): ButtonProps | undefined {
    const {rightButton, navigation, close, onPressClose} = this.props;
    if (rightButton) {
      return rightButton;
    }
    if (close && navigation) {
      return {
        handler:
          onPressClose ||
          (() => {
            if (this.disabledSubmit) {
              return;
            }
            this.disabledSubmit = true;
            (navigation.getParent() || navigation).goBack();
          }),
        tintColor: 'white',
        title: <CloseIcon />,
      };
    }
    return undefined;
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flex: 1,
  } as ViewStyle,
  inner: {
    flex: 1,
    backgroundColor: 'white',
  } as ViewStyle,
  loading: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, .6)',
    bottom: 0,
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  } as ViewStyle,
  safeAreaTop: {
    backgroundColor: 'white',
  } as ViewStyle,
});
