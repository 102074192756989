import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import QuestionIcon from '../../../shared/icons/QuestionIcon';
import InkstickIcon from '../../../shared/icons/InkstickIcon';
import TooltipModal from '../../../shared/modals/TooltipModal';

import Grade from '../../../../../domain/entities/writer/Grade';

type TooltipModalType = 'writer_rank';

const TypeToTooltipInfo = {
  writer_rank: {
    title: 'ライターランク',
    description:
      'ファンの獲得数に応じて上がっていきます。\n閲覧や読者から受け取ったギフトによる報酬のポイント付与率に影響いたします。',
  },
};

interface Props {
  grade: Grade;
}

const WriterRankSection: React.FC<Props> = props => {
  const {grade} = props;
  const [tooltipModalType, setTooltipModalType] =
    React.useState<TooltipModalType | null>(null);
  const onRequestCloseModal = React.useCallback(() => {
    setTooltipModalType(null);
  }, []);
  const onRequestOpenModal = React.useCallback(() => {
    setTooltipModalType('writer_rank');
  }, []);
  const modalInfo = tooltipModalType
    ? TypeToTooltipInfo[tooltipModalType]
    : null;
  return (
    <View style={styles.container}>
      <Text style={styles.label}>ライターランク</Text>
      <View style={styles.question}>
        <TouchableOpacity onPress={onRequestOpenModal}>
          <QuestionIcon />
        </TouchableOpacity>
      </View>
      <InkstickIcon size={16} color={grade.color} />
      <Text style={[styles.rank, {color: grade.color}]}>
        {grade.code.toUpperCase()}
      </Text>
      {modalInfo ? (
        <TooltipModal
          visible={true}
          title={modalInfo.title}
          description={modalInfo.description}
          onCloseModal={onRequestCloseModal}
        />
      ) : null}
    </View>
  );
};

const propsAreEqual = () => true;

export default React.memo(WriterRankSection, propsAreEqual);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#efefef',
    height: 43,
    margin: 16,
  } as ViewStyle,
  label: {
    color: '#383838',
    fontSize: 10,
    marginRight: 4,
  } as TextStyle,
  question: {
    marginRight: 9,
  } as ViewStyle,
  rank: {
    fontSize: 15,
    fontWeight: 'bold',
    marginLeft: 4,
  } as TextStyle,
});
