import * as React from 'react';

import BackgroundUsageHistoryList from './partials/BackgroundUsageHistoryList';
import BottomNewBackgroundUsageHistoryButton from './partials/BottomNewBackgroundUsageHistoryButton';
import NoBackgroundUsageHistories from './partials/NoBackgroundUsageHistories';

import Layout from '../../../../shared/Layout';
import shouldUpdateEntityList from '../../../../shared/enhanced/shouldUpdateEntityList';
import ResourceFavoriteListLink from '../../../../shared/ResourceFavoriteListLink';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistoriesRouteProp} from '../../../../../navigators/RouteProps';

import * as routers from '../../../../../routers';

import {Params as BackgroundUsageHistoryIndexParams} from '../../../../../actions/background_usage_histories/index';

import {equalForKeys} from '../../../../../helpers/equalForKeys';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import BackgroundUsageHistory from '../../../../../../domain/entities/BackgroundUsageHistory';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  back?: boolean;
  page?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBackgroundUsageHistoriesRouteProp;
  sceneForm: SceneForm | null;
  backgroundUsageHistories: BackgroundUsageHistory[] | null;
  backgroundUsageHistoryParams: BackgroundUsageHistoryIndexParams;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexBackgroundUsageHistories: (
    params: BackgroundUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<BackgroundUsageHistory>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (
      !equalForKeys(this.props, nextProps, ['backgroundUsageHistoryParams'])
    ) {
      return true;
    }
    if (
      shouldUpdateEntityList(
        {
          entities: this.props.backgroundUsageHistories,
        },
        {entities: nextProps.backgroundUsageHistories},
      )
    ) {
      return true;
    }
    return false;
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      backgroundUsageHistories,
      backgroundUsageHistoryParams,
      indexBackgroundUsageHistories,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!backgroundUsageHistories) {
      indexBackgroundUsageHistories(backgroundUsageHistoryParams);
    }
  }

  public render(): React.ReactNode {
    const {route, navigation, backgroundUsageHistories, enabledFavorite} =
      this.props;

    const {back} = route.params;
    return (
      <Layout
        title={'背景'}
        scrollable={false}
        back={back}
        navigation={navigation}
        close={true}>
        {backgroundUsageHistories ? (
          backgroundUsageHistories.length > 0 ? (
            <>
              <BackgroundUsageHistoryList
                backgroundUsageHistories={backgroundUsageHistories.filter(
                  (x, i, self) => {
                    return (
                      self
                        .map(o => o.baseBackground.id)
                        .indexOf(x.baseBackground.id) === i
                    );
                  },
                )}
                onSelectBackgroundUsageHistory={
                  this.handleSelectBackgroundUsageHistory
                }
                onForwardToFavoriteList={
                  enabledFavorite ? this.handleForwardToFavoriteList : undefined
                }
                enabledFavorite={enabledFavorite}
              />
              <BottomNewBackgroundUsageHistoryButton
                onPress={this.handleForwardToNewBackgroundUsageHistory}
              />
            </>
          ) : (
            <>
              <NoBackgroundUsageHistories
                onForwardToNewBackgroundUsageHistory={
                  this.handleForwardToNewBackgroundUsageHistory
                }
              />
              {enabledFavorite && (
                <ResourceFavoriteListLink
                  style={{position: 'absolute', top: 16, right: 16}}
                  onPress={this.handleForwardToFavoriteList}
                />
              )}
            </>
          )
        ) : null}
      </Layout>
    );
  }

  private handleSelectBackgroundUsageHistory = (
    backgroundUsageHistory: BackgroundUsageHistory,
  ) => {
    const {navigation, route} = this.props;
    const baseBackgroundId = backgroundUsageHistory.baseBackground.id;
    const {storyId, episodeId, sceneId} = route.params;
    routers.linkToSceneFormBackgroundShowSceneCommandNewBackgrounds(
      navigation,
      {
        baseBackgroundId,
        storyId,
        episodeId,
        sceneId,
      },
    );
  };

  private handleForwardToNewBackgroundUsageHistory = () => {
    const {navigation, route} = this.props;
    const {storyId, episodeId, sceneId} = route.params;
    routers.linkToSceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchForm(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
      },
    );
  };

  private handleForwardToFavoriteList = () => {
    const {navigation, route} = this.props;
    const {storyId, episodeId, sceneId} = route.params;
    routers.linkToSceneFormBackgroundShowSceneCommandNewBaseBackgrounds(
      navigation,
      {
        storyId,
        episodeId,
        sceneId,
        favorite: true,
      },
    );
  };
}
