import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import NewCharacterButton from './NewCharacterButton';

interface Props {
  onPress: () => void;
}

const BottomNewCharacterButton: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  return (
    <View style={styles.container}>
      <NewCharacterButton onPress={onPress} />
    </View>
  );
};

export default React.memo(BottomNewCharacterButton);

const styles = StyleSheet.create({
  container: {
    marginTop: 8,
  } as ViewStyle,
});
