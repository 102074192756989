import * as React from 'react';

import BaseIndex from '../../common/effects/Index';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormEffectShowSceneCommandEditEffectsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as EffectUsageHistoryUpdateParams} from '../../../../../actions/effect_usage_histories/update';
import {Params as EffectIndexParams} from '../../../../../actions/effects/index';
import {Params as PositionedEffectIndexParams} from '../../../../../actions/positioned_effects/index';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import * as routers from '../../../../../routers';

import Effect from '../../../../../../domain/entities/Effect';
import EffectUsageHistory from '../../../../../../domain/entities/EffectUsageHistory';
import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import PositionedEffect from '../../../../../../domain/entities/PositionedEffect';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

import Position from '../../../../../../domain/value_objects/Position';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  baseEffectId: number;
  sceneCommandId: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormEffectShowSceneCommandEditEffectsRouteProp;
  sceneForm: SceneForm | null;
  effectsParams: EffectIndexParams;
  baseEffect: BaseEffect | null;
  effects: Effect[] | null;
  parentSceneCommandForm: SceneCommandForm | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexEffects: (params: EffectIndexParams) => Promise<PaginatedResult<Effect>>;
  showBaseEffect: (id: number) => Promise<BaseEffect>;
  indexPositionedEffects: (
    params: PositionedEffectIndexParams,
  ) => Promise<PaginatedResult<PositionedEffect>>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateEffectUsageHistory: (
    id: number,
    params: EffectUsageHistoryUpdateParams,
  ) => Promise<EffectUsageHistory>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedEffect: Effect | null;
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    return <BaseIndex {...this.props} onSubmit={this.handleSubmit} />;
  }
  private handleSubmit = (effect: Effect): void => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {
      route,
      navigation,
      parentSceneCommandForm,
      updateSceneCommandForm,
      updateEffectUsageHistory,
      indexPositionedEffects,
    } = this.props;
    const {storyId, episodeId, sceneId, sceneCommandId, parentSceneCommandId} =
      route.params;
    indexPositionedEffects({effectId: effect.id}).then(result => {
      if (result.records.length < 5) {
        const sceneCommandForm = new EffectShowSceneCommandForm(
          effect,
          Position.Center,
          null,
          null,
          null,
          null,
          null,
          sceneCommandId,
        );
        if (parentSceneCommandForm) {
          updateSceneCommandForm({
            sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
              parentSceneCommandForm,
              sceneCommandForm,
            ),
          });
        } else {
          updateSceneCommandForm({
            sceneCommandForm,
          });
        }
        updateEffectUsageHistory(effect.id, {storyId}).then(() => {
          (navigation.getParent() || navigation).goBack();
        });
      } else {
        routers.linkToSceneFormEffectShowSceneCommandEditEffectPositions(
          navigation,
          {
            effectId: effect.id,
            storyId,
            episodeId,
            sceneId,
            sceneCommandId,
            parentSceneCommandId,
          },
        );
      }
    });
  };
}
