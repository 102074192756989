import * as React from 'react';
import {
  Image,
  ImageStyle,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import NewStoryButton from './NewStoryButton';
import DimensionContext from '../../shared/dimension/DimensionContext';

import convertImageSource from '../../../helpers/convertImageSource';

import storyKeyVisualUri from '../../../../assets/images/story_key_visual.png';

interface Props {
  onForwardToNewStory: () => void;
}

const WelcomeTapNovel: React.FunctionComponent<Props> = props => {
  const {onForwardToNewStory} = props;
  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        source={convertImageSource(storyKeyVisualUri)}
      />
      <DimensionContext.Consumer>
        {context => {
          const fontSize = Math.min((context.content.width / 300) * 11, 15);
          return (
            <Text style={[styles.message, {fontSize}]}>
              TapNovelMakerへようこそ！{'\n'}
              さっそく新しいストーリーをつくってみましょう！
            </Text>
          );
        }}
      </DimensionContext.Consumer>
      <View style={styles.buttonContainer}>
        <NewStoryButton
          style={styles.button}
          onForwardToNewStory={onForwardToNewStory}
        />
      </View>
    </View>
  );
};

export default React.memo(WelcomeTapNovel);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  } as ViewStyle,
  image: {
    width: 158,
    height: 80,
  } as ImageStyle,
  message: {
    color: '#666666',
    fontSize: 15,
    lineHeight: Platform.select({default: 20, web: 25}),
    marginVertical: 12,
    textAlign: 'center',
  } as TextStyle,
  buttonContainer: {
    marginTop: 12,
  } as ViewStyle,
  button: {
    width: 240,
    height: 46,
  } as ViewStyle,
});
