import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import numeral from 'numeral';
import dateformat from 'dateformat';

import FastImageAdapter from '../../shared/fast_image/FastImageAdapter';
import TapIcon from '../../shared/icons/project/TapIcon';
import FavoriteIcon from '../../shared/icons/project/FavoriteIcon';
import ThumbsUpActiveIcon from '../../shared/icons/project/ThumbsUpActiveIcon';
import DefaultOutlineButton from '../../shared/buttons/DefaultOutlineButton';

import {storyCoverImageUrl} from '../../../helpers/images';
import StoryCoverImageDecorator from '../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../styles/variables';

import Project from '../../../../domain/entities/Project';
import ProjectStoryAggregation from '../../../../domain/entities/ProjectStoryAggregation';

interface Props {
  project: Project;
  storyAggregation: ProjectStoryAggregation;
  isLeader: boolean;
  isMember: boolean;
}

const StoryInfo: React.FC<Props> = props => {
  const {project, storyAggregation, isLeader, isMember} = props;
  const {story} = project;
  const linkTo = useLinkTo();
  const source = {
    uri: storyCoverImageUrl(
      new StoryCoverImageDecorator(project.story),
      'small',
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  const onPressStoryImage = React.useCallback(() => {
    linkTo(
      `/projects/${project.id}/story/cover_image/edit?storyId=${story.id}`,
    );
  }, []);
  const onPressSummary = React.useCallback(() => {
    linkTo(`/projects/${project.id}/summary`);
  }, []);
  const onPressCharacterList = React.useCallback(() => {
    linkTo(`/projects/${project.id}/characters`);
  }, []);
  const onPressEpisodeList = React.useCallback(() => {
    linkTo(`/projects/${project.id}/episodes`);
  }, []);
  return (
    <View style={styles.container}>
      <View style={styles.top}>
        <TouchableOpacity disabled={!isLeader} onPress={onPressStoryImage}>
          {story.hasCoverImage ? (
            <FastImageAdapter style={styles.image} source={source} />
          ) : (
            <View style={styles.image}>
              <Text style={styles.noImageText}>表紙なし</Text>
            </View>
          )}
        </TouchableOpacity>
        <View style={styles.right}>
          <Text style={styles.title}>{story.title}</Text>
          <Text style={styles.leader}>
            リーダー：{project.story.writerUser.penName}
          </Text>
          <View style={styles.genres}>
            {project.story.genres[0] && (
              <Text style={[styles.genre, styles.genreMain]}>
                {project.story.genres[0].name}
              </Text>
            )}
            {project.story.genres.slice(1).map(genre => (
              <Text style={styles.genre}>{genre.name}</Text>
            ))}
          </View>
          <View style={styles.info}>
            <View style={styles.infoRow}>
              <TapIcon color={'#ff8f13'} size={10} />
              <Text style={styles.infoRowText}>
                {numeral(storyAggregation.tapsCount).format('0,0')}
              </Text>
              <Text style={styles.infoRowUnit}>Tap</Text>
            </View>
            <View style={styles.infoRow}>
              <FavoriteIcon color={'#ff8f13'} size={10} />
              <Text style={styles.infoRowText}>
                {numeral(storyAggregation.favoritesCount).format('0,0')}
              </Text>
            </View>
            <View style={styles.infoRow}>
              <ThumbsUpActiveIcon color={'#ff8f13'} size={10} />
              <Text style={styles.infoRowText}>
                {numeral(storyAggregation.reactionsCount).format('0,0')}
              </Text>
            </View>
          </View>
        </View>
      </View>
      {isMember ? (
        <>
          <DefaultOutlineButton
            style={{position: 'absolute', right: 0, bottom: 40}}
            buttonSize={{width: 95, height: 28}}
            onPress={onPressSummary}>
            概要を見る
          </DefaultOutlineButton>
          <DefaultOutlineButton
            style={{position: 'absolute', right: 120, bottom: 0}}
            buttonSize={{width: 95, height: 28}}
            onPress={onPressCharacterList}>
            キャラクター
          </DefaultOutlineButton>
          <DefaultOutlineButton
            style={{position: 'absolute', right: 0, bottom: 0}}
            buttonSize={{width: 95, height: 28}}
            onPress={onPressEpisodeList}>
            エピソード
          </DefaultOutlineButton>
        </>
      ) : null}
      <Text style={styles.description}>{story.introduction}</Text>
      <Text style={styles.updatedAt}>
        最終更新：
        {dateformat(story.updatedAt, 'yyyy.mm.dd HH:MM')}
      </Text>
    </View>
  );
};

export default React.memo(StoryInfo);

const ASPECT_RATIO = 0.7;
const width = 140;

const styles = StyleSheet.create({
  container: {
    margin: 16,
  } as ViewStyle,
  top: {
    flexDirection: 'row',
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    borderWidth: 0.5,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  right: {
    marginLeft: 8,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 17,
    fontWeight: 'bold',
  } as TextStyle,
  leader: {
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 16,
  } as TextStyle,
  genres: {
    flexDirection: 'row',
  } as ViewStyle,
  genre: {
    color: '#666',
    fontSize: 10,
    marginTop: 16,
    marginRight: 8,
  } as TextStyle,
  genreMain: {
    color: '#222',
  } as TextStyle,
  info: {
    flexDirection: 'row',
    marginTop: 16,
  } as ViewStyle,
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 12,
  } as ViewStyle,
  infoRowText: {
    color: '#222',
    fontSize: 10,
    marginLeft: 4,
  } as TextStyle,
  infoRowUnit: {
    color: '#222',
    fontSize: 10,
    marginLeft: 1,
  } as TextStyle,
  description: {
    color: '#383838',
    fontSize: 10,
    marginTop: 16,
  } as TextStyle,
  updatedAt: {
    color: '#666',
    fontSize: 10,
    textAlign: 'right',
    marginTop: 16,
  } as TextStyle,
  subTitle: {
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
});
