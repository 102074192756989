import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import WriterUserAvator from './WriterUserAvator';

import Question from '../../../../../domain/entities/writer/faq/Question';

interface Props {
  question: Question;
}

const QuestionDetail: React.FC<Props> = props => {
  const {question} = props;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{question.title}</Text>
      <WriterUserAvator
        writerUser={question.writerUser}
        time={question.createdAt}
      />
      <Text style={styles.category}>{question.writerFaqCategory.name}</Text>
      <Text style={styles.content}>{question.content}</Text>
    </View>
  );
};

export default React.memo(QuestionDetail);

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    padding: 16,
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 15,
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
  category: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 24,
  } as TextStyle,
  content: {
    color: '#222',
    fontSize: 14,
    marginTop: 16,
  } as TextStyle,
});
