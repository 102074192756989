import * as React from 'react';
import {CommonActions} from '@react-navigation/native';
import BaseIndex from '../../../shared/generics/character_form/actors/Index';

import NavigationProp from '../../../../navigators/NavigationProp';
import {CharacterFormNewActorsRouteProp} from '../../../../navigators/RouteProps';

import * as routers from '../../../../routers';

import {Params as ActorIndexParams} from '../../../../actions/actors/index';
import {Params as ActorCategoryIndexParams} from '../../../../actions/actor_categories/index';

import {QueryState} from '../../../../reducers/queries/Response';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';
import CurrentUserStatus from '../../../../../domain/entities/writer/CurrentUserStatus';
import Actor from '../../../../../domain/entities/Actor';
import ActorCategory from '../../../../../domain/entities/ActorCategory';
import PaginatedResult from '../../../../../domain/results/PaginatedResult';

export interface Params {
  storyId: number;
  formValues: {
    query: string;
    ageMin: number;
    ageMax: number;
    rootCategoryIdToActorCategoryIds: {[key: string]: Array<number>};
    favorite?: boolean;
    purchase?: boolean;
  };
  sort?: 'popularity' | 'new_arrival';
  withPaid?: boolean;
  page?: number;
  back?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CharacterFormNewActorsRouteProp;
  currentUser: CurrentUser | null;
  currentUserStatus: CurrentUserStatus | null;
  actors: Actor[] | null;
  actorsParams: ActorIndexParams;
  actorCategories: ActorCategory[] | null;
  actorCategoriesParams: ActorCategoryIndexParams;
  actorQueries: QueryState;
  totalCount: number | null;
}

export interface DispatchProps {
  indexActors: (params: ActorIndexParams) => Promise<PaginatedResult<Actor>>;
  indexActorCategories: (
    params: ActorCategoryIndexParams,
  ) => Promise<PaginatedResult<ActorCategory>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {navigation, route} = this.props;
    const {storyId, formValues} = route.params;
    if (formValues && typeof formValues !== 'object') {
      setTimeout(() => {
        navigation.getParent()?.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [
              {name: 'Home'},
              {
                params: {storyId},
                name: 'NewCharacter',
              },
            ],
          }),
        );
      }, 300);
    }
  }

  public render(): React.ReactNode {
    const {route, totalCount} = this.props;
    const {formValues, withPaid, sort} = route.params || {};
    return (
      <BaseIndex
        {...this.props}
        formValues={formValues}
        totalCount={totalCount}
        withPaid={withPaid}
        sort={sort}
        onPressForActor={this.handlePressForActor}
        onPressForSearch={this.handlePressForSearch}
      />
    );
  }

  private handlePressForSearch = () => {
    const {navigation, route} = this.props;
    const {storyId, formValues, withPaid} = route.params;
    navigation.navigate('CharacterFormNewActorSearchFormNavigations', {
      screen: 'CharacterFormNewActorSearchForm',
      params: {
        storyId,
        formValues,
        withPaid,
        hideBack: true,
      },
    } as any);
  };

  private handlePressForActor = (actor: Actor) => {
    const {navigation, route} = this.props;
    const {storyId} = route.params;
    const actorId = actor.id;
    routers.linkToCharacterFormNewDefaultCharacterPatterns(navigation, {
      storyId,
      actorId,
    });
  };
}
