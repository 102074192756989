import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormEffectShowSceneCommandNewEffectUsageHistories from '../../../../containers/scene_form/effect_show_scene_command/new/effect_usage_histories/Index';
import SceneFormEffectShowSceneCommandNewBaseEffectSearchForm from '../../../../containers/scene_form/effect_show_scene_command/new/base_effect_search_form/Index';
import SceneFormEffectShowSceneCommandNewBaseEffects from '../../../../containers/scene_form/effect_show_scene_command/new/base_effects/Index';
import SceneFormEffectShowSceneCommandNewEffects from '../../../../containers/scene_form/effect_show_scene_command/new/effects/Index';
import SceneFormEffectShowSceneCommandNewEffectPositions from '../../../../containers/scene_form/effect_show_scene_command/new/effect_positions/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormEffectShowSceneCommandNewEffectUsageHistories">
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandNewEffectUsageHistories"
        component={SceneFormEffectShowSceneCommandNewEffectUsageHistories}
        options={{title: 'エフェクト'}}
      />
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandNewBaseEffectSearchForm"
        component={SceneFormEffectShowSceneCommandNewBaseEffectSearchForm}
        options={{title: 'エフェクト検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandNewBaseEffects"
        component={SceneFormEffectShowSceneCommandNewBaseEffects}
        options={{title: 'エフェクト'}}
      />
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandNewEffects"
        component={SceneFormEffectShowSceneCommandNewEffects}
        options={{title: 'エフェクト'}}
      />
      <RootStack.Screen
        name="SceneFormEffectShowSceneCommandNewEffectPositions"
        component={SceneFormEffectShowSceneCommandNewEffectPositions}
        options={{title: 'エフェクト'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
