import SceneCommand from './SceneCommand';

import OrientedSpeechBalloon from '../../entities/OrientedSpeechBalloon';
import Voice from '../../entities/Voice';
import Sound from '../../entities/Sound';

import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../forms/scene_commands/SpeechTextShowSceneCommandForm';

export default class SpeechTextShowSceneCommand implements SceneCommand {
  constructor(
    public orientedSpeechBalloon: OrientedSpeechBalloon,
    public text: string,
    public overrideCharacterName: boolean | null,
    public characterName: string | null,
    public voice?: Voice | null,
    public sound?: Sound | null,
    public startTime?: number | null,
    public endTime?: number | null,
  ) {}

  public getValue() {
    return {
      oriented_speech_balloon_id: this.orientedSpeechBalloon.id,
      text: this.text,
      voice_id: this.voice ? this.voice.id : null,
      sound_id: this.sound ? this.sound.id : null,
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }

  public toForm(): SceneCommandForm {
    return new SpeechTextShowSceneCommandForm(
      this.orientedSpeechBalloon,
      this.text,
      this.overrideCharacterName,
      this.characterName,
      this.voice || null,
      this.sound || null,
      this.startTime || null,
      this.endTime || null,
    );
  }
}
