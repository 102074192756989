enum Types {
  ShowAccessTokenFailure = 'ShowAccessTokenFailure',
  ShowAccessTokenRequest = 'ShowAccessTokenRequest',
  ShowAccessTokenSuccess = 'ShowAccessTokenSuccess',

  ResetWriterCurrentUser = 'ResetWriterCurrentUser',

  ShowWriterCurrentUserFailure = 'ShowWriterCurrentUserFailure',
  ShowWriterCurrentUserRequest = 'ShowWriterCurrentUserRequest',
  ShowWriterCurrentUserSuccess = 'ShowWriterCurrentUserSuccess',

  CreateWriterCurrentUserFailure = 'CreateWriterCurrentUserFailure',
  CreateWriterCurrentUserRequest = 'CreateWriterCurrentUserRequest',
  CreateWriterCurrentUserSuccess = 'CreateWriterCurrentUserSuccess',

  UpdateWriterCurrentUserFailure = 'UpdateWriterCurrentUserFailure',
  UpdateWriterCurrentUserRequest = 'UpdateWriterCurrentUserRequest',
  UpdateWriterCurrentUserSuccess = 'UpdateWriterCurrentUserSuccess',

  ShowWriterCurrentUserStatusFailure = 'ShowWriterCurrentUserStatusFailure',
  ShowWriterCurrentUserStatusRequest = 'ShowWriterCurrentUserStatusRequest',
  ShowWriterCurrentUserStatusSuccess = 'ShowWriterCurrentUserStatusSuccess',

  IndexGenresFailure = 'IndexGenresFailure',
  IndexGenresRequest = 'IndexGenresRequest',
  IndexGenresSuccess = 'IndexGenresSuccess',

  IndexStoriesFailure = 'IndexStoriesFailure',
  IndexStoriesRequest = 'IndexStoriesRequest',
  IndexStoriesSuccess = 'IndexStoriesSuccess',

  ShowStoryFailure = 'ShowStoryFailure',
  ShowStoryRequest = 'ShowStoryRequest',
  ShowStorySuccess = 'ShowStorySuccess',

  CreateStoryFailure = 'CreateStoryFailure',
  CreateStoryRequest = 'CreateStoryRequest',
  CreateStorySuccess = 'CreateStorySuccess',

  UpdateStoryFailure = 'UpdateStoryFailure',
  UpdateStoryRequest = 'UpdateStoryRequest',
  UpdateStorySuccess = 'UpdateStorySuccess',

  DestroyStoryFailure = 'DestroyStoryFailure',
  DestroyStoryRequest = 'DestroyStoryRequest',
  DestroyStorySuccess = 'DestroyStorySuccess',

  CreateStoryForm = 'CreateStoryForm',
  UpdateStoryForm = 'UpdateStoryForm',

  CreateCoverImageForm = 'CreateCoverImageForm',
  UpdateCoverImageForm = 'UpdateCoverImageForm',

  IndexScenesFailure = 'IndexScenesFailure',
  IndexScenesRequest = 'IndexScenesRequest',
  IndexScenesSuccess = 'IndexScenesSuccess',

  ShowSceneFailure = 'ShowSceneFailure',
  ShowSceneRequest = 'ShowSceneRequest',
  ShowSceneSuccess = 'ShowSceneSuccess',

  CreateSceneFailure = 'CreateSceneFailure',
  CreateSceneRequest = 'CreateSceneRequest',
  CreateSceneSuccess = 'CreateSceneSuccess',

  UpdateSceneFailure = 'UpdateSceneFailure',
  UpdateSceneRequest = 'UpdateSceneRequest',
  UpdateSceneSuccess = 'UpdateSceneSuccess',

  DestroySceneFailure = 'DestroySceneFailure',
  DestroySceneRequest = 'DestroySceneRequest',
  DestroySceneSuccess = 'DestroySceneSuccess',

  ShowSceneWithCommandsWrapperFailure = 'ShowSceneWithCommandsWrapperFailure',
  ShowSceneWithCommandsWrapperRequest = 'ShowSceneWithCommandsWrapperRequest',
  ShowSceneWithCommandsWrapperSuccess = 'ShowSceneWithCommandsWrapperSuccess',

  CreateSceneForm = 'CreateSceneForm',
  UpdateSceneForm = 'UpdateSceneForm',
  DestroySceneForm = 'DestroySceneForm',

  CreateSceneCommandFormList = 'CreateSceneCommandFormList',
  UpdateSceneCommandFormList = 'UpdateSceneCommandFormList',

  CreateSceneCommandForm = 'CreateSceneCommandForm',
  UpdateSceneCommandForm = 'UpdateSceneCommandForm',
  DestroySceneCommandForm = 'DestroySceneCommandForm',

  UpdateApplicationModal = 'UpdateApplicationModal',
  UpdateApplicationActionModal = 'UpdateApplicationActionModal',

  CreateEpisodePublicationModal = 'CreateEpisodePublicationModal',
  DestroyEpisodePublicationModal = 'DestroyEpisodePublicationModal',

  CreateSceneRegistrationModal = 'CreateSceneRegistrationModal',
  DestroySceneRegistrationModal = 'DestroySceneRegistrationModal',

  UpdateCoachmarkModal = 'UpdateCoachmarkModal',

  IndexEpisodesFailure = 'IndexEpisodesFailure',
  IndexEpisodesRequest = 'IndexEpisodesRequest',
  IndexEpisodesSuccess = 'IndexEpisodesSuccess',

  ShowEpisodeFailure = 'ShowEpisodeFailure',
  ShowEpisodeRequest = 'ShowEpisodeRequest',
  ShowEpisodeSuccess = 'ShowEpisodeSuccess',

  CreateEpisodeFailure = 'CreateEpisodeFailure',
  CreateEpisodeRequest = 'CreateEpisodeRequest',
  CreateEpisodeSuccess = 'CreateEpisodeSuccess',

  UpdateEpisodeFailure = 'UpdateEpisodeFailure',
  UpdateEpisodeRequest = 'UpdateEpisodeRequest',
  UpdateEpisodeSuccess = 'UpdateEpisodeSuccess',

  DestroyEpisodeFailure = 'DestroyEpisodeFailure',
  DestroyEpisodeRequest = 'DestroyEpisodeRequest',
  DestroyEpisodeSuccess = 'DestroyEpisodeSuccess',

  IndexCharactersFailure = 'IndexCharactersFailure',
  IndexCharactersRequest = 'IndexCharactersRequest',
  IndexCharactersSuccess = 'IndexCharactersSuccess',

  ShowCharacterFailure = 'ShowCharacterFailure',
  ShowCharacterRequest = 'ShowCharacterRequest',
  ShowCharacterSuccess = 'ShowCharacterSuccess',

  CreateCharacterFailure = 'CreateCharacterFailure',
  CreateCharacterRequest = 'CreateCharacterRequest',
  CreateCharacterSuccess = 'CreateCharacterSuccess',

  UpdateCharacterFailure = 'UpdateCharacterFailure',
  UpdateCharacterRequest = 'UpdateCharacterRequest',
  UpdateCharacterSuccess = 'UpdateCharacterSuccess',

  DestroyCharacterFailure = 'DestroyCharacterFailure',
  DestroyCharacterRequest = 'DestroyCharacterRequest',
  DestroyCharacterSuccess = 'DestroyCharacterSuccess',

  CreateCharacterForm = 'CreateCharacterForm',
  UpdateCharacterForm = 'UpdateCharacterForm',

  IndexCharacterPatternsFailure = 'IndexCharacterPatternsFailure',
  IndexCharacterPatternsRequest = 'IndexCharacterPatternsRequest',
  IndexCharacterPatternsSuccess = 'IndexCharacterPatternsSuccess',

  ShowCharacterPatternFailure = 'ShowCharacterPatternFailure',
  ShowCharacterPatternRequest = 'ShowCharacterPatternRequest',
  ShowCharacterPatternSuccess = 'ShowCharacterPatternSuccess',

  CreateCharacterPatternFailure = 'CreateCharacterPatternFailure',
  CreateCharacterPatternRequest = 'CreateCharacterPatternRequest',
  CreateCharacterPatternSuccess = 'CreateCharacterPatternSuccess',

  UpdateCharacterPatternUsageHistoryFailure = 'UpdateCharacterPatternUsageHistoryFailure',
  UpdateCharacterPatternUsageHistoryRequest = 'UpdateCharacterPatternUsageHistoryRequest',
  UpdateCharacterPatternUsageHistorySuccess = 'UpdateCharacterPatternUsageHistorySuccess',

  IndexBackgroundUsageHistoriesFailure = 'IndexBackgroundUsageHistoriesFailure',
  IndexBackgroundUsageHistoriesRequest = 'IndexBackgroundUsageHistoriesRequest',
  IndexBackgroundUsageHistoriesSuccess = 'IndexBackgroundUsageHistoriesSuccess',

  UpdateBackgroundUsageHistoryFailure = 'UpdateBackgroundUsageHistoryFailure',
  UpdateBackgroundUsageHistoryRequest = 'UpdateBackgroundUsageHistoryRequest',
  UpdateBackgroundUsageHistorySuccess = 'UpdateBackgroundUsageHistorySuccess',

  IndexFullScreenIllustrationUsageHistoriesFailure = 'IndexFullScreenIllustrationUsageHistoriesFailure',
  IndexFullScreenIllustrationUsageHistoriesRequest = 'IndexFullScreenIllustrationUsageHistoriesRequest',
  IndexFullScreenIllustrationUsageHistoriesSuccess = 'IndexFullScreenIllustrationUsageHistoriesSuccess',

  UpdateFullScreenIllustrationUsageHistoryFailure = 'UpdateFullScreenIllustrationUsageHistoryFailure',
  UpdateFullScreenIllustrationUsageHistoryRequest = 'UpdateFullScreenIllustrationUsageHistoryRequest',
  UpdateFullScreenIllustrationUsageHistorySuccess = 'UpdateFullScreenIllustrationUsageHistorySuccess',

  IndexIllustrationUsageHistoriesFailure = 'IndexIllustrationUsageHistoriesFailure',
  IndexIllustrationUsageHistoriesRequest = 'IndexIllustrationUsageHistoriesRequest',
  IndexIllustrationUsageHistoriesSuccess = 'IndexIllustrationUsageHistoriesSuccess',

  UpdateIllustrationUsageHistoryFailure = 'UpdateIllustrationUsageHistoryFailure',
  UpdateIllustrationUsageHistoryRequest = 'UpdateIllustrationUsageHistoryRequest',
  UpdateIllustrationUsageHistorySuccess = 'UpdateIllustrationUsageHistorySuccess',

  IndexWallpaperUsageHistoriesFailure = 'IndexWallpaperUsageHistoriesFailure',
  IndexWallpaperUsageHistoriesRequest = 'IndexWallpaperUsageHistoriesRequest',
  IndexWallpaperUsageHistoriesSuccess = 'IndexWallpaperUsageHistoriesSuccess',

  UpdateWallpaperUsageHistoryFailure = 'UpdateWallpaperUsageHistoryFailure',
  UpdateWallpaperUsageHistoryRequest = 'UpdateWallpaperUsageHistoryRequest',
  UpdateWallpaperUsageHistorySuccess = 'UpdateWallpaperUsageHistorySuccess',

  IndexEffectUsageHistoriesFailure = 'IndexEffectUsageHistoriesFailure',
  IndexEffectUsageHistoriesRequest = 'IndexEffectUsageHistoriesRequest',
  IndexEffectUsageHistoriesSuccess = 'IndexEffectUsageHistoriesSuccess',

  UpdateEffectUsageHistoryFailure = 'UpdateEffectUsageHistoryFailure',
  UpdateEffectUsageHistoryRequest = 'UpdateEffectUsageHistoryRequest',
  UpdateEffectUsageHistorySuccess = 'UpdateEffectUsageHistorySuccess',

  IndexSoundUsageHistoriesFailure = 'IndexSoundUsageHistoriesFailure',
  IndexSoundUsageHistoriesRequest = 'IndexSoundUsageHistoriesRequest',
  IndexSoundUsageHistoriesSuccess = 'IndexSoundUsageHistoriesSuccess',

  UpdateSoundUsageHistoryFailure = 'UpdateSoundUsageHistoryFailure',
  UpdateSoundUsageHistoryRequest = 'UpdateSoundUsageHistoryRequest',
  UpdateSoundUsageHistorySuccess = 'UpdateSoundUsageHistorySuccess',

  IndexActorsFailure = 'IndexActorsFailure',
  IndexActorsRequest = 'IndexActorsRequest',
  IndexActorsSuccess = 'IndexActorsSuccess',

  ShowActorFailure = 'ShowActorFailure',
  ShowActorRequest = 'ShowActorRequest',
  ShowActorSuccess = 'ShowActorSuccess',

  IndexFilteredActorsFailure = 'IndexFilteredActorsFailure',
  IndexFilteredActorsRequest = 'IndexFilteredActorsRequest',
  IndexFilteredActorsSuccess = 'IndexFilteredActorsSuccess',

  IndexActorCategoriesFailure = 'IndexActorCategoriesFailure',
  IndexActorCategoriesRequest = 'IndexActorCategoriesRequest',
  IndexActorCategoriesSuccess = 'IndexActorCategoriesSuccess',

  ShowActorCategoryFailure = 'ShowActorCategoryFailure',
  ShowActorCategoryRequest = 'ShowActorCategoryRequest',
  ShowActorCategorySuccess = 'ShowActorCategorySuccess',

  ShowActorCharacterFailure = 'ShowActorCharacterFailure',
  ShowActorCharacterRequest = 'ShowActorCharacterRequest',
  ShowActorCharacterSuccess = 'ShowActorCharacterSuccess',

  IndexActorCharacterFacesFailure = 'IndexActorCharacterFacesFailure',
  IndexActorCharacterFacesRequest = 'IndexActorCharacterFacesRequest',
  IndexActorCharacterFacesSuccess = 'IndexActorCharacterFacesSuccess',

  ShowActorCharacterFaceFailure = 'ShowActorCharacterFaceFailure',
  ShowActorCharacterFaceRequest = 'ShowActorCharacterFaceRequest',
  ShowActorCharacterFaceSuccess = 'ShowActorCharacterFaceSuccess',

  IndexActorCostumesFailure = 'IndexActorCostumesFailure',
  IndexActorCostumesRequest = 'IndexActorCostumesRequest',
  IndexActorCostumesSuccess = 'IndexActorCostumesSuccess',

  IndexActorHairStylesFailure = 'IndexActorHairStylesFailure',
  IndexActorHairStylesRequest = 'IndexActorHairStylesRequest',
  IndexActorHairStylesSuccess = 'IndexActorHairStylesSuccess',

  IndexActorAccessoriesFailure = 'IndexActorAccessoriesFailure',
  IndexActorAccessoriesRequest = 'IndexActorAccessoriesRequest',
  IndexActorAccessoriesSuccess = 'IndexActorAccessoriesSuccess',

  IndexActorAccessorySetsFailure = 'IndexActorAccessorySetsFailure',
  IndexActorAccessorySetsRequest = 'IndexActorAccessorySetsRequest',
  IndexActorAccessorySetsSuccess = 'IndexActorAccessorySetsSuccess',

  IndexCharacterMakerActorGroupsFailure = 'IndexCharacterMakerActorGroupsFailure',
  IndexCharacterMakerActorGroupsRequest = 'IndexCharacterMakerActorGroupsRequest',
  IndexCharacterMakerActorGroupsSuccess = 'IndexCharacterMakerActorGroupsSuccess',

  ShowCharacterMakerActorGroupFailure = 'ShowCharacterMakerActorGroupFailure',
  ShowCharacterMakerActorGroupRequest = 'ShowCharacterMakerActorGroupRequest',
  ShowCharacterMakerActorGroupSuccess = 'ShowCharacterMakerActorGroupSuccess',

  IndexBackgroundCategoriesFailure = 'IndexBackgroundCategoriesFailure',
  IndexBackgroundCategoriesRequest = 'IndexBackgroundCategoriesRequest',
  IndexBackgroundCategoriesSuccess = 'IndexBackgroundCategoriesSuccess',

  ShowBackgroundCategoryFailure = 'ShowBackgroundCategoryFailure',
  ShowBackgroundCategoryRequest = 'ShowBackgroundCategoryRequest',
  ShowBackgroundCategorySuccess = 'ShowBackgroundCategorySuccess',

  IndexFilteredBackgroundCategoriesFailure = 'IndexFilteredBackgroundCategoriesFailure',
  IndexFilteredBackgroundCategoriesRequest = 'IndexFilteredBackgroundCategoriesRequest',
  IndexFilteredBackgroundCategoriesSuccess = 'IndexFilteredBackgroundCategoriesSuccess',

  IndexBaseBackgroundsFailure = 'IndexBaseBackgroundsFailure',
  IndexBaseBackgroundsRequest = 'IndexBaseBackgroundsRequest',
  IndexBaseBackgroundsSuccess = 'IndexBaseBackgroundsSuccess',

  ShowBaseBackgroundFailure = 'ShowBaseBackgroundFailure',
  ShowBaseBackgroundRequest = 'ShowBaseBackgroundRequest',
  ShowBaseBackgroundSuccess = 'ShowBaseBackgroundSuccess',

  IndexFilteredBaseBackgroundsFailure = 'IndexFilteredBaseBackgroundsFailure',
  IndexFilteredBaseBackgroundsRequest = 'IndexFilteredBaseBackgroundsRequest',
  IndexFilteredBaseBackgroundsSuccess = 'IndexFilteredBaseBackgroundsSuccess',

  IndexBackgroundsFailure = 'IndexBackgroundsFailure',
  IndexBackgroundsRequest = 'IndexBackgroundsRequest',
  IndexBackgroundsSuccess = 'IndexBackgroundsSuccess',

  ShowBackgroundFailure = 'ShowBackgroundFailure',
  ShowBackgroundRequest = 'ShowBackgroundRequest',
  ShowBackgroundSuccess = 'ShowBackgroundSuccess',

  IndexBackgroundWeathersFailure = 'IndexBackgroundWeathersFailure',
  IndexBackgroundWeathersRequest = 'IndexBackgroundWeathersRequest',
  IndexBackgroundWeathersSuccess = 'IndexBackgroundWeathersSuccess',

  IndexBackgroundTimeZonesFailure = 'IndexBackgroundTimeZonesFailure',
  IndexBackgroundTimeZonesRequest = 'IndexBackgroundTimeZonesRequest',
  IndexBackgroundTimeZonesSuccess = 'IndexBackgroundTimeZonesSuccess',

  IndexIllustrationCategoriesFailure = 'IndexIllustrationCategoriesFailure',
  IndexIllustrationCategoriesRequest = 'IndexIllustrationCategoriesRequest',
  IndexIllustrationCategoriesSuccess = 'IndexIllustrationCategoriesSuccess',

  ShowIllustrationCategoryFailure = 'ShowIllustrationCategoryFailure',
  ShowIllustrationCategoryRequest = 'ShowIllustrationCategoryRequest',
  ShowIllustrationCategorySuccess = 'ShowIllustrationCategorySuccess',

  IndexIllustrationsFailure = 'IndexIllustrationsFailure',
  IndexIllustrationsRequest = 'IndexIllustrationsRequest',
  IndexIllustrationsSuccess = 'IndexIllustrationsSuccess',

  ShowIllustrationFailure = 'ShowIllustrationFailure',
  ShowIllustrationRequest = 'ShowIllustrationRequest',
  ShowIllustrationSuccess = 'ShowIllustrationSuccess',

  IndexFullScreenIllustrationsFailure = 'IndexFullScreenIllustrationsFailure',
  IndexFullScreenIllustrationsRequest = 'IndexFullScreenIllustrationsRequest',
  IndexFullScreenIllustrationsSuccess = 'IndexFullScreenIllustrationsSuccess',

  ShowFullScreenIllustrationFailure = 'ShowFullScreenIllustrationFailure',
  ShowFullScreenIllustrationRequest = 'ShowFullScreenIllustrationRequest',
  ShowFullScreenIllustrationSuccess = 'ShowFullScreenIllustrationSuccess',

  CreateFullScreenIllustrationFailure = 'CreateFullScreenIllustrationFailure',
  CreateFullScreenIllustrationRequest = 'CreateFullScreenIllustrationRequest',
  CreateFullScreenIllustrationSuccess = 'CreateFullScreenIllustrationSuccess',

  IndexFilteredIllustrationsFailure = 'IndexFilteredIllustrationsFailure',
  IndexFilteredIllustrationsRequest = 'IndexFilteredIllustrationsRequest',
  IndexFilteredIllustrationsSuccess = 'IndexFilteredIllustrationsSuccess',

  IndexEffectCategoriesFailure = 'IndexEffectCategoriesFailure',
  IndexEffectCategoriesRequest = 'IndexEffectCategoriesRequest',
  IndexEffectCategoriesSuccess = 'IndexEffectCategoriesSuccess',

  ShowEffectCategoryFailure = 'ShowEffectCategoryFailure',
  ShowEffectCategoryRequest = 'ShowEffectCategoryRequest',
  ShowEffectCategorySuccess = 'ShowEffectCategorySuccess',

  IndexBaseEffectsFailure = 'IndexBaseEffectsFailure',
  IndexBaseEffectsRequest = 'IndexBaseEffectsRequest',
  IndexBaseEffectsSuccess = 'IndexBaseEffectsSuccess',

  ShowBaseEffectFailure = 'ShowBaseEffectFailure',
  ShowBaseEffectRequest = 'ShowBaseEffectRequest',
  ShowBaseEffectSuccess = 'ShowBaseEffectSuccess',

  IndexEffectsFailure = 'IndexEffectsFailure',
  IndexEffectsRequest = 'IndexEffectsRequest',
  IndexEffectsSuccess = 'IndexEffectsSuccess',

  ShowEffectFailure = 'ShowEffectFailure',
  ShowEffectRequest = 'ShowEffectRequest',
  ShowEffectSuccess = 'ShowEffectSuccess',

  IndexPositionedEffectsFailure = 'IndexPositionedEffectsFailure',
  IndexPositionedEffectsRequest = 'IndexPositionedEffectsRequest',
  IndexPositionedEffectsSuccess = 'IndexPositionedEffectsSuccess',

  ShowPositionedEffectFailure = 'ShowPositionedEffectFailure',
  ShowPositionedEffectRequest = 'ShowPositionedEffectRequest',
  ShowPositionedEffectSuccess = 'ShowPositionedEffectSuccess',

  IndexSoundCategoriesFailure = 'IndexSoundCategoriesFailure',
  IndexSoundCategoriesRequest = 'IndexSoundCategoriesRequest',
  IndexSoundCategoriesSuccess = 'IndexSoundCategoriesSuccess',

  ShowSoundCategoryFailure = 'ShowSoundCategoryFailure',
  ShowSoundCategoryRequest = 'ShowSoundCategoryRequest',
  ShowSoundCategorySuccess = 'ShowSoundCategorySuccess',

  IndexSoundsFailure = 'IndexSoundsFailure',
  IndexSoundsRequest = 'IndexSoundsRequest',
  IndexSoundsSuccess = 'IndexSoundsSuccess',

  ShowSoundFailure = 'ShowSoundFailure',
  ShowSoundRequest = 'ShowSoundRequest',
  ShowSoundSuccess = 'ShowSoundSuccess',

  IndexWallpapersFailure = 'IndexWallpapersFailure',
  IndexWallpapersRequest = 'IndexWallpapersRequest',
  IndexWallpapersSuccess = 'IndexWallpapersSuccess',

  ShowWallpaperFailure = 'ShowWallpaperFailure',
  ShowWallpaperRequest = 'ShowWallpaperRequest',
  ShowWallpaperSuccess = 'ShowWallpaperSuccess',

  IndexMarksFailure = 'IndexMarksFailure',
  IndexMarksRequest = 'IndexMarksRequest',
  IndexMarksSuccess = 'IndexMarksSuccess',

  ShowMarkFailure = 'ShowMarkFailure',
  ShowMarkRequest = 'ShowMarkRequest',
  ShowMarkSuccess = 'ShowMarkSuccess',

  IndexOrientedSpeechBalloonsFailure = 'IndexOrientedSpeechBalloonsFailure',
  IndexOrientedSpeechBalloonsRequest = 'IndexOrientedSpeechBalloonsRequest',
  IndexOrientedSpeechBalloonsSuccess = 'IndexOrientedSpeechBalloonsSuccess',

  ShowOrientedSpeechBalloonFailure = 'ShowOrientedSpeechBalloonFailure',
  ShowOrientedSpeechBalloonRequest = 'ShowOrientedSpeechBalloonRequest',
  ShowOrientedSpeechBalloonSuccess = 'ShowOrientedSpeechBalloonSuccess',

  IndexSpeechBalloonsFailure = 'IndexSpeechBalloonsFailure',
  IndexSpeechBalloonsRequest = 'IndexSpeechBalloonsRequest',
  IndexSpeechBalloonsSuccess = 'IndexSpeechBalloonsSuccess',

  ShowSpeechBalloonFailure = 'ShowSpeechBalloonFailure',
  ShowSpeechBalloonRequest = 'ShowSpeechBalloonRequest',
  ShowSpeechBalloonSuccess = 'ShowSpeechBalloonSuccess',

  IndexTextFramesFailure = 'IndexTextFramesFailure',
  IndexTextFramesRequest = 'IndexTextFramesRequest',
  IndexTextFramesSuccess = 'IndexTextFramesSuccess',

  ShowTextFrameFailure = 'ShowTextFrameFailure',
  ShowTextFrameRequest = 'ShowTextFrameRequest',
  ShowTextFrameSuccess = 'ShowTextFrameSuccess',

  IndexVoicesFailure = 'IndexVoicesFailure',
  IndexVoicesRequest = 'IndexVoicesRequest',
  IndexVoicesSuccess = 'IndexVoicesSuccess',

  ShowVoiceFailure = 'ShowVoiceFailure',
  ShowVoiceRequest = 'ShowVoiceRequest',
  ShowVoiceSuccess = 'ShowVoiceSuccess',

  CreateVoiceFailure = 'CreateVoiceFailure',
  CreateVoiceRequest = 'CreateVoiceRequest',
  CreateVoiceSuccess = 'CreateVoiceSuccess',

  UpdateVoiceFailure = 'UpdateVoiceFailure',
  UpdateVoiceRequest = 'UpdateVoiceRequest',
  UpdateVoiceSuccess = 'UpdateVoiceSuccess',

  DestroyVoiceFailure = 'DestroyVoiceFailure',
  DestroyVoiceRequest = 'DestroyVoiceRequest',
  DestroyVoiceSuccess = 'DestroyVoiceSuccess',

  ShowWriterUserEpisodeCommentFailure = 'ShowWriterUserEpisodeCommentFailure',
  ShowWriterUserEpisodeCommentRequest = 'ShowWriterUserEpisodeCommentRequest',
  ShowWriterUserEpisodeCommentSuccess = 'ShowWriterUserEpisodeCommentSuccess',

  CreateWriterUserEpisodeCommentFailure = 'CreateWriterUserEpisodeCommentFailure',
  CreateWriterUserEpisodeCommentRequest = 'CreateWriterUserEpisodeCommentRequest',
  CreateWriterUserEpisodeCommentSuccess = 'CreateWriterUserEpisodeCommentSuccess',

  UpdateWriterUserEpisodeCommentFailure = 'UpdateWriterUserEpisodeCommentFailure',
  UpdateWriterUserEpisodeCommentRequest = 'UpdateWriterUserEpisodeCommentRequest',
  UpdateWriterUserEpisodeCommentSuccess = 'UpdateWriterUserEpisodeCommentSuccess',

  IndexEpisodeCommentsFailure = 'IndexEpisodeCommentsFailure',
  IndexEpisodeCommentsRequest = 'IndexEpisodeCommentsRequest',
  IndexEpisodeCommentsSuccess = 'IndexEpisodeCommentsSuccess',

  ShowEpisodeCommentFailure = 'ShowEpisodeCommentFailure',
  ShowEpisodeCommentRequest = 'ShowEpisodeCommentRequest',
  ShowEpisodeCommentSuccess = 'ShowEpisodeCommentSuccess',

  DestroyEpisodeCommentFailure = 'DestroyEpisodeCommentFailure',
  DestroyEpisodeCommentRequest = 'DestroyEpisodeCommentRequest',
  DestroyEpisodeCommentSuccess = 'DestroyEpisodeCommentSuccess',

  ShowEpisodeCommentReplyFailure = 'ShowEpisodeCommentReplyFailure',
  ShowEpisodeCommentReplyRequest = 'ShowEpisodeCommentReplyRequest',
  ShowEpisodeCommentReplySuccess = 'ShowEpisodeCommentReplySuccess',

  CreateEpisodeCommentReplyFailure = 'CreateEpisodeCommentReplyFailure',
  CreateEpisodeCommentReplyRequest = 'CreateEpisodeCommentReplyRequest',
  CreateEpisodeCommentReplySuccess = 'CreateEpisodeCommentReplySuccess',

  UpdateEpisodeCommentReplyFailure = 'UpdateEpisodeCommentReplyFailure',
  UpdateEpisodeCommentReplyRequest = 'UpdateEpisodeCommentReplyRequest',
  UpdateEpisodeCommentReplySuccess = 'UpdateEpisodeCommentReplySuccess',

  DestroyEpisodeCommentReplyFailure = 'DestroyEpisodeCommentReplyFailure',
  DestroyEpisodeCommentReplyRequest = 'DestroyEpisodeCommentReplyRequest',
  DestroyEpisodeCommentReplySuccess = 'DestroyEpisodeCommentReplySuccess',

  IndexEpisodeCommentBlockingUsersFailure = 'IndexEpisodeCommentBlockingUsersFailure',
  IndexEpisodeCommentBlockingUsersRequest = 'IndexEpisodeCommentBlockingUsersRequest',
  IndexEpisodeCommentBlockingUsersSuccess = 'IndexEpisodeCommentBlockingUsersSuccess',

  CreateEpisodeCommentBlockingUserFailure = 'CreateEpisodeCommentBlockingUserFailure',
  CreateEpisodeCommentBlockingUserRequest = 'CreateEpisodeCommentBlockingUserRequest',
  CreateEpisodeCommentBlockingUserSuccess = 'CreateEpisodeCommentBlockingUserSuccess',

  DestroyEpisodeCommentBlockingUserFailure = 'DestroyEpisodeCommentBlockingUserFailure',
  DestroyEpisodeCommentBlockingUserRequest = 'DestroyEpisodeCommentBlockingUserRequest',
  DestroyEpisodeCommentBlockingUserSuccess = 'DestroyEpisodeCommentBlockingUserSuccess',

  IndexEpisodeReactionsFailure = 'IndexEpisodeReactionsFailure',
  IndexEpisodeReactionsRequest = 'IndexEpisodeReactionsRequest',
  IndexEpisodeReactionsSuccess = 'IndexEpisodeReactionsSuccess',

  ShowEpisodeReactionFailure = 'ShowEpisodeReactionFailure',
  ShowEpisodeReactionRequest = 'ShowEpisodeReactionRequest',
  ShowEpisodeReactionSuccess = 'ShowEpisodeReactionSuccess',

  IndexWriterUserNotificationsFailure = 'IndexWriterUserNotificationsFailure',
  IndexWriterUserNotificationsRequest = 'IndexWriterUserNotificationsRequest',
  IndexWriterUserNotificationsSuccess = 'IndexWriterUserNotificationsSuccess',

  UpdateWriterUserNotificationFailure = 'UpdateWriterUserNotificationFailure',
  UpdateWriterUserNotificationRequest = 'UpdateWriterUserNotificationRequest',
  UpdateWriterUserNotificationSuccess = 'UpdateWriterUserNotificationSuccess',

  ShowWriterUserNotificationSettingsBatchFailure = 'ShowWriterUserNotificationSettingsBatchFailure',
  ShowWriterUserNotificationSettingsBatchRequest = 'ShowWriterUserNotificationSettingsBatchRequest',
  ShowWriterUserNotificationSettingsBatchSuccess = 'ShowWriterUserNotificationSettingsBatchSuccess',

  UpdateWriterUserNotificationSettingsBatchFailure = 'UpdateWriterUserNotificationSettingsBatchFailure',
  UpdateWriterUserNotificationSettingsBatchRequest = 'UpdateWriterUserNotificationSettingsBatchRequest',
  UpdateWriterUserNotificationSettingsBatchSuccess = 'UpdateWriterUserNotificationSettingsBatchSuccess',

  IndexWriterAnnouncementCategoriesFailure = 'IndexWriterAnnouncementCategoriesFailure',
  IndexWriterAnnouncementCategoriesRequest = 'IndexWriterAnnouncementCategoriesRequest',
  IndexWriterAnnouncementCategoriesSuccess = 'IndexWriterAnnouncementCategoriesSuccess',

  ShowWriterAnnouncementCategoryFailure = 'ShowWriterAnnouncementCategoryFailure',
  ShowWriterAnnouncementCategoryRequest = 'ShowWriterAnnouncementCategoryRequest',
  ShowWriterAnnouncementCategorySuccess = 'ShowWriterAnnouncementCategorySuccess',

  IndexWriterAnnouncementsFailure = 'IndexWriterAnnouncementsFailure',
  IndexWriterAnnouncementsRequest = 'IndexWriterAnnouncementsRequest',
  IndexWriterAnnouncementsSuccess = 'IndexWriterAnnouncementsSuccess',

  ShowWriterAnnouncementFailure = 'ShowWriterAnnouncementFailure',
  ShowWriterAnnouncementRequest = 'ShowWriterAnnouncementRequest',
  ShowWriterAnnouncementSuccess = 'ShowWriterAnnouncementSuccess',

  IndexWriterHelpCategoriesFailure = 'IndexWriterHelpCategoriesFailure',
  IndexWriterHelpCategoriesRequest = 'IndexWriterHelpCategoriesRequest',
  IndexWriterHelpCategoriesSuccess = 'IndexWriterHelpCategoriesSuccess',

  ShowWriterHelpCategoryFailure = 'ShowWriterHelpCategoryFailure',
  ShowWriterHelpCategoryRequest = 'ShowWriterHelpCategoryRequest',
  ShowWriterHelpCategorySuccess = 'ShowWriterHelpCategorySuccess',

  IndexWriterHelpsFailure = 'IndexWriterHelpsFailure',
  IndexWriterHelpsRequest = 'IndexWriterHelpsRequest',
  IndexWriterHelpsSuccess = 'IndexWriterHelpsSuccess',

  ShowWriterHelpFailure = 'ShowWriterHelpFailure',
  ShowWriterHelpRequest = 'ShowWriterHelpRequest',
  ShowWriterHelpSuccess = 'ShowWriterHelpSuccess',

  IndexWriterManualCategoriesFailure = 'IndexWriterManualCategoriesFailure',
  IndexWriterManualCategoriesRequest = 'IndexWriterManualCategoriesRequest',
  IndexWriterManualCategoriesSuccess = 'IndexWriterManualCategoriesSuccess',

  ShowWriterManualCategoryFailure = 'ShowWriterManualCategoryFailure',
  ShowWriterManualCategoryRequest = 'ShowWriterManualCategoryRequest',
  ShowWriterManualCategorySuccess = 'ShowWriterManualCategorySuccess',

  IndexWriterManualsFailure = 'IndexWriterManualsFailure',
  IndexWriterManualsRequest = 'IndexWriterManualsRequest',
  IndexWriterManualsSuccess = 'IndexWriterManualsSuccess',

  ShowWriterManualFailure = 'ShowWriterManualFailure',
  ShowWriterManualRequest = 'ShowWriterManualRequest',
  ShowWriterManualSuccess = 'ShowWriterManualSuccess',

  IndexWriterContactCategoriesFailure = 'IndexWriterContactCategoriesFailure',
  IndexWriterContactCategoriesRequest = 'IndexWriterContactCategoriesRequest',
  IndexWriterContactCategoriesSuccess = 'IndexWriterContactCategoriesSuccess',

  CreateWriterContactFailure = 'CreateWriterContactFailure',
  CreateWriterContactRequest = 'CreateWriterContactRequest',
  CreateWriterContactSuccess = 'CreateWriterContactSuccess',

  IndexWriterOauthAccountsFailure = 'IndexWriterOauthAccountsFailure',
  IndexWriterOauthAccountsRequest = 'IndexWriterOauthAccountsRequest',
  IndexWriterOauthAccountsSuccess = 'IndexWriterOauthAccountsSuccess',

  CreateWriterOauthAccountFailure = 'CreateWriterOauthAccountFailure',
  CreateWriterOauthAccountRequest = 'CreateWriterOauthAccountRequest',
  CreateWriterOauthAccountSuccess = 'CreateWriterOauthAccountSuccess',

  DestroyWriterOauthAccountFailure = 'DestroyWriterOauthAccountFailure',
  DestroyWriterOauthAccountRequest = 'DestroyWriterOauthAccountRequest',
  DestroyWriterOauthAccountSuccess = 'DestroyWriterOauthAccountSuccess',

  IndexResponseStoriesFailure = 'IndexResponseStoriesFailure',
  IndexResponseStoriesRequest = 'IndexResponseStoriesRequest',
  IndexResponseStoriesSuccess = 'IndexResponseStoriesSuccess',

  ShowResponseStoryFailure = 'ShowResponseStoryFailure',
  ShowResponseStoryRequest = 'ShowResponseStoryRequest',
  ShowResponseStorySuccess = 'ShowResponseStorySuccess',

  UpdateResponseStoryFailure = 'UpdateResponseStoryFailure',
  UpdateResponseStoryRequest = 'UpdateResponseStoryRequest',
  UpdateResponseStorySuccess = 'UpdateResponseStorySuccess',

  IndexResponseEpisodesFailure = 'IndexResponseEpisodesFailure',
  IndexResponseEpisodesRequest = 'IndexResponseEpisodesRequest',
  IndexResponseEpisodesSuccess = 'IndexResponseEpisodesSuccess',

  ShowResponseEpisodeFailure = 'ShowResponseEpisodeFailure',
  ShowResponseEpisodeRequest = 'ShowResponseEpisodeRequest',
  ShowResponseEpisodeSuccess = 'ShowResponseEpisodeSuccess',

  UpdateResponseEpisodeFailure = 'UpdateResponseEpisodeFailure',
  UpdateResponseEpisodeRequest = 'UpdateResponseEpisodeRequest',
  UpdateResponseEpisodeSuccess = 'UpdateResponseEpisodeSuccess',
}

export default Types;
