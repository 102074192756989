import * as React from 'react';
import {
  GestureResponderEvent,
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';

import ScaleModal from './ScaleModal';

interface Props {
  style?: StyleProp<ViewStyle>;
  visible: boolean;
  children?: React.ReactNode;
  onCloseModal?: (event: GestureResponderEvent) => void;
  onLayout?: (event: LayoutChangeEvent) => void;
}

const LargeModal: React.FunctionComponent<Props> = props => {
  const {style, visible, children, onCloseModal, onLayout} = props;
  return (
    <ScaleModal
      style={[styles.container, style]}
      visible={visible}
      visibleHeader={true}
      onCloseModal={onCloseModal}
      onLayout={onLayout}>
      {children}
    </ScaleModal>
  );
};

export default React.memo(LargeModal);

const styles = StyleSheet.create({
  container: {
    width: '85%',
  } as ViewStyle,
});
