import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useActionSheet} from '@expo/react-native-action-sheet';
import dateformat from 'dateformat';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import TapIcon from '../../../shared/icons/project/TapIcon';
import ThumbsUpActiveIcon from '../../../shared/icons/project/ThumbsUpActiveIcon';
import MoreIcon from '../../../shared/icons/ionicons/MoreIcon';

import ImageUrlHelper from '../../../../../domain/helpers/ImageUrlHelper';
import Episode from '../../../../../domain/entities/Episode';
import ProjectUserStatus from '../../../../../domain/entities/ProjectUserStatus';

interface Props {
  episode: Episode;
  best: boolean;
  userStatus: ProjectUserStatus;
  onUpdateEpisode: (episode: Episode) => void;
  onCreateStoryBestEpisode: (episode: Episode) => void;
  onDestroyStoryBestEpisode: (episode: Episode) => void;
}

const EpisodeListItem: React.FC<Props> = props => {
  const {
    episode,
    best,
    userStatus,
    onUpdateEpisode,
    onCreateStoryBestEpisode,
    onDestroyStoryBestEpisode,
  } = props;
  const {showActionSheetWithOptions} = useActionSheet();
  const onOpenActionSheet = React.useCallback(() => {
    const options = [
      best ? 'イチ押しエピソードを解除' : 'イチ押しエピソードに選ぶ',
      '非公開にする',
      'キャンセル',
    ];
    const updateRecommendEpisodeIndex = 0;
    const updateEpisodeStatusIndex = 1;
    const cancelButtonIndex = 2;
    showActionSheetWithOptions(
      {
        cancelButtonIndex,
        options,
        destructiveButtonIndex: updateEpisodeStatusIndex,
      },
      async (buttonIndex?: number) => {
        switch (buttonIndex) {
          case updateEpisodeStatusIndex:
            onUpdateEpisode(episode);
            break;
          case updateRecommendEpisodeIndex:
            if (best) {
              onDestroyStoryBestEpisode(episode);
            } else {
              onCreateStoryBestEpisode(episode);
            }
            break;
          case cancelButtonIndex:
            break;
        }
      },
    );
  }, [best]);
  const source = {
    uri: ImageUrlHelper.getImageUrl(episode.originalImageUrl, {
      width: 992,
      height: 622,
    }),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  return (
    <View style={styles.container}>
      <View style={styles.left}>
        {episode.hasImage ? (
          <FastImageAdapter style={styles.image} source={source} />
        ) : (
          <Text style={styles.noImageText}>サムネイル</Text>
        )}
        <View style={styles.readTime}>
          <Text style={styles.readTimeText}>
            読了{episode.approximateReadingTimeText}
          </Text>
        </View>
      </View>
      <View style={styles.right}>
        <Text style={styles.title}>{episode.title}</Text>
        <Text style={styles.penName}>{episode.writerUser?.penName}</Text>
        <View style={styles.info}>
          <View style={styles.infoRow}>
            <TapIcon color={'#ff8f13'} size={10} />
            <Text style={styles.infoRowText}>
              {episode.estimatedTotalTapCount}
            </Text>
            <Text style={styles.infoRowUnit}>Tap</Text>
          </View>
          <View style={styles.infoRow}>
            <ThumbsUpActiveIcon color={'#ff8f13'} size={10} />
            <Text style={styles.infoRowText}>{episode.reactionsCount}</Text>
          </View>
        </View>
        <Text style={styles.updatedAt}>
          {dateformat(
            episode.scheduledDeliveredAt || episode.updatedAt,
            'yyyy.mm.dd',
          )}
        </Text>
      </View>
      {userStatus.role === 'leader' ? (
        <TouchableOpacity
          style={styles.headerRight}
          onPress={onOpenActionSheet}>
          <View style={styles.more}>
            <MoreIcon />
          </View>
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default React.memo(EpisodeListItem);

const styles = StyleSheet.create({
  container: {
    padding: 10,
    flexDirection: 'row',
  } as ViewStyle,
  left: {
    width: 160,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#d8d8d8',
  } as ViewStyle,
  right: {
    marginLeft: 10,
  } as ViewStyle,
  image: {
    width: 160,
    height: 100,
  } as ImageStyle,
  noImageText: {
    color: '#999',
    fontSize: 20,
    fontWeight: 'bold',
  } as TextStyle,
  readTime: {
    position: 'absolute',
    bottom: 8,
    right: 8,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 3,
    minWidth: 48,
    height: 16,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 2,
  } as ViewStyle,
  readTimeText: {
    color: '#fff',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
  title: {
    marginVertical: 8,
    color: '#222',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  penName: {
    color: '#222',
    fontSize: 10,
  } as TextStyle,
  info: {
    flexDirection: 'row',
    marginVertical: 8,
  } as ViewStyle,
  infoRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  } as ViewStyle,
  infoRowText: {
    color: '#999',
    fontSize: 10,
    marginLeft: 4,
  } as TextStyle,
  infoRowUnit: {
    color: '#999',
    fontSize: 10,
    marginLeft: 1,
  } as TextStyle,
  updatedAt: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  headerRight: {
    alignItems: 'center',
    height: 44,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 44,
    zIndex: 100,
  } as ViewStyle,
  more: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
});
