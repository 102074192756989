import * as React from 'react';

import GenreList from './partials/GenreList';

import BottomButtonContainer from '../../shared/buttons/BottomButtonContainer';
import PrimaryButton from '../../shared/buttons/PrimaryButton';
import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {StoryFormSubGenresRouteProp} from '../../../navigators/RouteProps';

import {Params as GenreIndexParams} from '../../../actions/genres/index';
import {Params as StoryFormUpdateParams} from '../../../actions/story_forms/update';

import Genre from '../../../../domain/entities/Genre';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export interface Params {
  storyId?: number;
  mainGenreId: number | null;
  subGenreIds: number[] | null;
  callback?: (subGenreIds: number[]) => void;
}

export interface StateProps {
  navigation: NavigationProp;
  route: StoryFormSubGenresRouteProp;
  genresParams: GenreIndexParams;
  genres: Genre[] | null;
}

export interface DispatchProps {
  indexGenres: (params: GenreIndexParams) => Promise<PaginatedResult<Genre>>;
  updateStoryForm: (params: StoryFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedGenreIds: number[];
}

export default class Index extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedGenreIds: props.route.params.subGenreIds || [],
    };
  }

  public componentDidMount() {
    const {genresParams, indexGenres, genres} = this.props;
    if (!genres) {
      indexGenres(genresParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, genres} = this.props;
    const {mainGenreId} = route.params;
    const {selectedGenreIds} = this.state;
    return (
      <Layout
        title={'サブジャンル（2つまで）'}
        scrollable={false}
        navigation={navigation}
        back={true}>
        <>
          {genres && (
            <GenreList
              genres={genres}
              mainGenreId={mainGenreId}
              selectedGenreIds={selectedGenreIds}
              onToggleGenre={this.handleToggleGenre}
            />
          )}

          {selectedGenreIds.length > 0 && (
            <BottomButtonContainer>
              <PrimaryButton onPress={this.handleSubmit}>決定</PrimaryButton>
            </BottomButtonContainer>
          )}
        </>
      </Layout>
    );
  }

  private handleToggleGenre = (genre: Genre, checked: boolean) => {
    const {route, updateStoryForm} = this.props;
    const {callback} = route.params;
    const selectedGenreIds = checked
      ? [...this.state.selectedGenreIds, genre.id]
      : this.state.selectedGenreIds.filter(id => {
          return id !== genre.id;
        });
    this.setState({selectedGenreIds});
    if (callback) {
      callback(selectedGenreIds);
    } else {
      updateStoryForm({subGenreIds: selectedGenreIds});
    }
  };

  private handleSubmit = () => {
    const {navigation} = this.props;
    navigation.goBack();
  };
}
