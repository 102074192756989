import EffectMapper, {EffectData} from '../../../entities/mappers/EffectMapper';

import SoundMapper, {SoundData} from '../../../entities/mappers/SoundMapper';

import EffectShowSceneCommand from '../../../../domain/value_objects/scene_commands/EffectShowSceneCommand';

import PositionMapper, {PositionData} from '../PositionMapper';

export interface EffectShowSceneCommandData {
  type: string;
  effect: EffectData;
  position: PositionData;
  start_index?: number;
  end_index?: number;
  sound?: SoundData | null;
  start_time?: number;
  end_time?: number;
}

export default class EffectShowSceneCommandMapper {
  private effectMapper: EffectMapper;
  private positionMapper: PositionMapper;
  private soundMapper: SoundMapper;

  constructor() {
    this.effectMapper = new EffectMapper();
    this.positionMapper = new PositionMapper();
    this.soundMapper = new SoundMapper();
  }

  public map(obj: EffectShowSceneCommandData): EffectShowSceneCommand {
    const effect = this.effectMapper.map(obj.effect);
    const position = this.positionMapper.map(obj.position);
    const sound = obj.sound ? this.soundMapper.map(obj.sound) : null;
    return new EffectShowSceneCommand(
      effect,
      position,
      obj.start_index,
      obj.end_index,
      sound,
      obj.start_time,
      obj.end_time,
    );
  }
}
