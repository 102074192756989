import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const LineSquareIcon: React.FunctionComponent<Props> = props => {
  return (
    <Icon
      name={'line-square'}
      size={props.size || 22}
      color={props.color || 'white'}
    />
  );
};

export default React.memo(LineSquareIcon);
