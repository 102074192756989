import * as React from 'react';
import {ActivityIndicator, Text, View} from 'react-native';
import DefaultModal from '../../../../shared/modals/DefaultModal';
import Form from '../../../../shared/stripe/Form';

import PaymentMethod from '../../../../../../domain/entities/writer/my_stripe/PaymentMethod';
import PaymentIntent from '../../../../../../domain/entities/writer/my_stripe/PaymentIntent';
import ResourceProduct from '../../../../../../domain/entities/shop/ResourceProduct';

import NetResourceProductPaymentIntentsRepository from '../../../../../../data/repositories/writer/my_stripe/NetResourceProductPaymentIntentsRepository';

interface Props {
  resourceProduct: ResourceProduct;
  paymentMethod: PaymentMethod | null;
  paymentIntent: PaymentIntent | null;
  consumptionPoint?: number | null;
  setPaymentMethod: React.Dispatch<React.SetStateAction<PaymentMethod | null>>;
  setPaymentIntent: React.Dispatch<React.SetStateAction<PaymentIntent | null>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  success: () => void;
  fail: (e: any) => void;
  onCloseModal: () => void;
}

const FormModal: React.FunctionComponent<Props> = props => {
  const [visible, setVisible] = React.useState(false);
  const {
    resourceProduct,
    paymentMethod,
    paymentIntent,
    consumptionPoint,
    setPaymentMethod,
    setPaymentIntent,
    success,
    fail,
    onCloseModal,
    setLoading,
  } = props;
  const onReady = React.useCallback(() => {
    setVisible(true);
  }, []);
  const createPaymentIntent = React.useCallback(() => {
    return new NetResourceProductPaymentIntentsRepository().create({
      resourceProductId: resourceProduct.id,
      consumptionPoint,
    });
  }, []);
  return (
    <>
      <DefaultModal
        visible={true}
        style={{display: visible ? 'flex' : 'none'}}
        disableCloseByOutsideTouch={true}
        onCloseModal={onCloseModal}>
        <Text
          style={{
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: 16,
          }}>
          キャラクターの購入
        </Text>
        <Form
          onlySetup={false}
          paymentMethod={paymentMethod}
          paymentIntent={paymentIntent}
          createPaymentIntent={createPaymentIntent}
          onReady={onReady}
          setPaymentMethod={setPaymentMethod}
          setPaymentIntent={setPaymentIntent}
          setLoading={setLoading}
          success={success}
          fail={fail}
        />
      </DefaultModal>
      {visible ? null : (
        <View
          style={{
            alignItems: 'center',
            bottom: 0,
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
          }}>
          <ActivityIndicator size="large" color="#ff8f13" />
        </View>
      )}
    </>
  );
};

export default React.memo(FormModal);
