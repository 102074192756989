import * as React from 'react';
import {InteractionManager} from 'react-native';

import BackgroundEffectList from './partials/BackgroundEffectList';
import SelectedBackgroundEffectImage from './partials/SelectedBackgroundEffectImage';

import HeaderRightButton from '../../../../shared/buttons/HeaderRightButton';
import Layout from '../../../../shared/Layout';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormBackgroundShowSceneCommandNewBackgroundEffectsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as EffectIndexParams} from '../../../../../actions/effects/index';
import {Params as SceneFormUpdateParams} from '../../../../../actions/scene_forms/update';

import {backgroundImageUrl} from '../../../../../helpers/images';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';
import scrollableLayout from '../../../../../helpers/scrollableLayout';

import Background from '../../../../../../domain/entities/Background';
import BaseEffect from '../../../../../../domain/entities/BaseEffect';
import Effect from '../../../../../../domain/entities/Effect';
import SceneForm, {
  Filter,
  EffectOptions,
} from '../../../../../../domain/forms/SceneForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

import fetchBlob from '../../../../../../data/data_stores/net/FetchBlob';

export interface Params {
  baseEffectId: number;
  effectId?: number;
  effectLayerOrder: 'background' | 'foreground';
  episodeId?: number;
  sceneId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormBackgroundShowSceneCommandNewBackgroundEffectsRouteProp;
  sceneForm: SceneForm | null;
  baseEffect: BaseEffect | null;
  effectsParams: EffectIndexParams;
  effects: Array<Effect> | null;
}

export interface DispatchProps {
  showBaseEffect: (id: number) => Promise<BaseEffect>;
  indexEffects: (params: EffectIndexParams) => Promise<PaginatedResult<Effect>>;
  updateSceneForm: (params: SceneFormUpdateParams) => Promise<SceneForm>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  uri: string | null;
  selectedEffect: Effect | null;
  selectedFilter?: Filter;
  selectedEffectLayerOrder: 'background' | 'foreground';
}

export default class Index extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  constructor(props: Props) {
    super(props);
    this.state = {
      selectedEffect: null,
      selectedFilter:
        props.sceneForm && props.sceneForm.options
          ? props.sceneForm.options.filter
          : undefined,
      selectedEffectLayerOrder: props.route.params.effectLayerOrder,
      uri: null,
    };
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      baseEffect,
      effects,
      effectsParams,
      showBaseEffect,
      indexEffects,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    const {baseEffectId, effectId} = route.params;
    if (this.props.sceneForm && this.props.sceneForm.background) {
      const {background} = this.props.sceneForm;
      InteractionManager.runAfterInteractions(() => {
        this.fetchBlob(background).then(uri => {
          this.setState({uri});
        });
      });
    }
    if (!baseEffect) {
      showBaseEffect(baseEffectId);
    }
    const callback = (effects: Array<Effect>) => {
      if (!effectId) {
        return;
      }
      const selectedEffect = effects.find(record => record.id === effectId);
      if (selectedEffect) {
        this.setState({selectedEffect});
      }
    };
    if (!effects) {
      indexEffects(effectsParams).then(result => {
        callback(result.records);
      });
    } else {
      callback(effects);
    }
  }

  public render(): React.ReactNode {
    const {navigation, baseEffect, effects} = this.props;

    const {uri, selectedFilter, selectedEffect, selectedEffectLayerOrder} =
      this.state;
    const filter = selectedFilter || 'normal';
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={baseEffect?.name || ''}
            scrollable={scrollableLayout(context)}
            navigation={navigation}
            back={true}
            rightButton={{
              tintColor: '#383838',
              title: (
                <HeaderRightButton
                  disabled={!selectedEffect}
                  onPress={this.handleSubmit}>
                  決定
                </HeaderRightButton>
              ),
            }}
            close={true}>
            {uri && effects && (
              <>
                <SelectedBackgroundEffectImage
                  uri={uri}
                  filter={filter}
                  selectedBaseEffect={selectedEffect}
                  defaultLayerOrder={selectedEffectLayerOrder}
                  onChangeLayerOrder={this.handleChangeLayerOrder}
                />
                <BackgroundEffectList
                  uri={uri}
                  effects={effects}
                  selectedEffect={selectedEffect}
                  onSelectEffect={this.handleSelectEffect}
                />
              </>
            )}
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private handleChangeLayerOrder = (
    selectedEffectLayerOrder: 'background' | 'foreground',
  ) => {
    this.setState({selectedEffectLayerOrder});
  };

  private handleSelectEffect = (selectedEffect: Effect) => {
    this.setState({selectedEffect});
  };

  private handleSubmit = async () => {
    const {navigation, sceneForm, updateSceneForm} = this.props;
    const {selectedFilter, selectedEffect, selectedEffectLayerOrder} =
      this.state;
    if (!sceneForm) {
      return;
    }
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {id, background, storyId, episodeId} = sceneForm;
    const options = {filter: selectedFilter};
    const positionedEffect = selectedEffect?.centerPositionedEffect;
    const effectOptions = positionedEffect
      ? ({
          layerOrder: selectedEffectLayerOrder || 'background',
        } as EffectOptions)
      : null;
    updateSceneForm({
      id,
      background,
      storyId,
      episodeId,
      options,
      positionedEffect,
      effectOptions,
    });
    (navigation.getParent() || navigation).goBack();
  };

  private async fetchBlob(background: Background): Promise<string> {
    const url = backgroundImageUrl(background, 'large');
    return fetchBlob(url);
  }
}
