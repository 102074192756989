import * as React from 'react';

import AnnouncementCategoryFilter from './partials/AnnouncementCategoryFilter';
import AnnouncementCategorySelectModal from './partials/AnnouncementCategorySelectModal';
import AnnouncementList from './partials/AnnouncementList';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';
import {AnnouncementsRouteProp} from '../../navigators/RouteProps';

import {linkToAnnouncement} from '../../routers';

import {Params as AnnouncementCategoryIndexParams} from '../../actions/writer/announcement_categories';
import {Params as AnnouncementIndexParams} from '../../actions/writer/announcements';

import Announcement from '../../../domain/entities/writer/Announcement';
import AnnouncementCategory from '../../../domain/entities/writer/AnnouncementCategory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export interface Params {
  writerAnnouncementCategoryId?: number;
  activatedFilter?: boolean | string;
  page?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: AnnouncementsRouteProp;
  announcements: Announcement[] | null;
  announcementCategories: AnnouncementCategory[] | null;
  announcementsParams: AnnouncementIndexParams;
  announcementCategoriesParams: AnnouncementCategoryIndexParams;
}

export interface DispatchProps {
  indexAnnouncements: (
    params: AnnouncementIndexParams,
  ) => Promise<PaginatedResult<Announcement>>;
  indexAnnouncementCategories: (
    params: AnnouncementCategoryIndexParams,
  ) => Promise<PaginatedResult<AnnouncementCategory>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  selectedAnnouncementCategory: AnnouncementCategory | null;
  nextPages: {[key: string]: number | undefined};
  refreshing: boolean;
}

export default class Index extends React.PureComponent<Props, State> {
  private nullAnnouncementCategory = new AnnouncementCategory(0, 'すべて');

  constructor(props: Props) {
    super(props);
    this.state = {
      nextPages: {},
      refreshing: false,
      selectedAnnouncementCategory: null,
    };
  }

  public componentDidMount() {
    const {
      announcements,
      announcementCategories,
      indexAnnouncementCategories,
      announcementCategoriesParams,
    } = this.props;
    if (!announcements) {
      this.fetchAnnouncements();
    }
    if (!announcementCategories) {
      indexAnnouncementCategories(announcementCategoriesParams);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {announcements, route} = this.props;
    const {writerAnnouncementCategoryId, page} = route.params || {
      writerAnnouncementCategoryId: undefined,
      page: undefined,
    };
    const {
      writerAnnouncementCategoryId: prevPropWriterAnnouncementCategoryId,
      page: prevPropPage,
    } = prevProps.route.params || {
      writerAnnouncementCategoryId: undefined,
      page: undefined,
    };
    const currentPage = page || 1;
    const prevPage = prevPropPage || 1;
    const currentAnnouncementCategoryId = writerAnnouncementCategoryId || 0;
    const prevAnnouncementCategoryId =
      prevPropWriterAnnouncementCategoryId || 0;
    if (
      !announcements ||
      currentPage !== prevPage ||
      currentAnnouncementCategoryId !== prevAnnouncementCategoryId
    ) {
      this.fetchAnnouncements();
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, announcements, announcementCategories} =
      this.props;
    const {refreshing} = this.state;
    const params = route.params || {};
    let {activatedFilter} = params;
    activatedFilter =
      typeof activatedFilter === 'string'
        ? activatedFilter !== 'false'
        : activatedFilter;
    const selectedAnnouncementCategory =
      this.state.selectedAnnouncementCategory ||
      (announcementCategories && this.nullAnnouncementCategory);
    return (
      <Layout
        title={'お知らせ'}
        scrollable={false}
        navigation={navigation}
        back={true}>
        {selectedAnnouncementCategory && (
          <AnnouncementCategoryFilter
            selectedAnnouncementCategory={selectedAnnouncementCategory}
            activated={activatedFilter || false}
            onPress={this.toggleOpenModal}
          />
        )}
        {announcements && (
          <AnnouncementList
            announcements={announcements}
            onSelectAnnouncement={this.handleSelectAnnouncement}
            onEndReached={this.handleEndReached}
            refreshing={refreshing}
            onRefresh={this.handleRefresh}
          />
        )}
        {activatedFilter &&
          announcementCategories &&
          selectedAnnouncementCategory && (
            <AnnouncementCategorySelectModal
              announcementCategories={[this.nullAnnouncementCategory].concat(
                announcementCategories,
              )}
              selectedAnnouncementCategory={selectedAnnouncementCategory}
              onSelectAnnouncementCategory={
                this.handleSelectAnnouncementCategory
              }
              onCloseModal={this.handleCloseModal}
            />
          )}
      </Layout>
    );
  }

  private handleEndReached = (info: {distanceFromEnd: number}) => {
    const {route} = this.props;
    const {writerAnnouncementCategoryId} = route.params || {
      writerAnnouncementCategoryId: undefined,
      page: undefined,
    };
    const page = this.state.nextPages[writerAnnouncementCategoryId || 0];
    if (page) {
      this.props.navigation.setParams({page});
    }
  };

  private handleSelectAnnouncement = (announcement: Announcement) => {
    const {navigation} = this.props;
    linkToAnnouncement(navigation, {id: announcement.id});
  };

  private handleSelectAnnouncementCategory = (
    announcementCategory: AnnouncementCategory,
  ) => {
    const {navigation} = this.props;
    const writerAnnouncementCategoryId =
      announcementCategory.id > 0 ? announcementCategory.id : undefined;
    navigation.setParams({
      activatedFilter: false,
      writerAnnouncementCategoryId,
      page: undefined,
    });
    this.setState({
      selectedAnnouncementCategory: announcementCategory,
    });
  };

  private toggleOpenModal = () => {
    const {navigation, route} = this.props;
    const params = route.params || {};
    navigation.setParams({
      activatedFilter: !params.activatedFilter,
    });
  };

  private handleCloseModal = () => {
    const {navigation} = this.props;
    navigation.setParams({activatedFilter: false});
  };

  private fetchAnnouncements() {
    const {route} = this.props;
    const {writerAnnouncementCategoryId: defaultWriterAnnouncementCategoryId} =
      route.params || {
        writerAnnouncementCategoryId: undefined,
        page: undefined,
      };
    const writerAnnouncementCategoryId =
      defaultWriterAnnouncementCategoryId || 0;
    const {indexAnnouncements, announcementsParams} = this.props;
    indexAnnouncements(announcementsParams).then(result => {
      this.setState({
        nextPages: {
          ...this.state.nextPages,
          [writerAnnouncementCategoryId]: result.nextPage,
        },
      });
    });
  }

  private handleRefresh = () => {
    this.fetchAnnouncements();
  };
}
