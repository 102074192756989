import * as React from 'react';
import {Image, ImageStyle, StyleProp} from 'react-native';

import SelectableLabeledImageGridList from './SelectableLabeledImageGridList';

import {imageUrl} from '../../helpers/images';

import {Options} from '../../../domain/helpers/ImageUrlHelper';

export interface Entity {
  id: number;
  getImageUrl(options: Options): string;
}

export interface Props<T extends Entity> {
  entities: T[];
  aspectRatio: number;
  selectedEntity: T | null;
  onSelectEntity: (entity: T) => void;
  renderLabel: (entity: T) => string;
  renderImage?: (entity: T) => React.ReactNode;
  imageUrlExtractor?: (item: T, width: number) => string;
}

export default class SelectableLabeledEntityImageGridList<
  T extends Entity,
> extends React.PureComponent<Props<T>> {
  public render(): React.ReactNode {
    return (
      <SelectableLabeledImageGridList
        {...this.props}
        keyExtractor={this.keyExtractor}
        isActive={this.isActive}
        renderImage={this.renderImage}
      />
    );
  }

  private keyExtractor = (entity: T) => {
    return `${entity.id}`;
  };

  private isActive = (entity: T, selectedEntity: T | null): boolean => {
    return entity.id === selectedEntity?.id;
  };

  private renderImage = (
    entity: T,
    width: number,
    imageStyle: StyleProp<ImageStyle>,
  ) => {
    const {aspectRatio, imageUrlExtractor} = this.props;
    return (
      <Image
        style={imageStyle}
        source={{
          uri: imageUrlExtractor
            ? imageUrlExtractor(entity, width)
            : imageUrl(entity, width, aspectRatio),
        }}
      />
    );
  };
}
