import * as React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

import {Props as NavigationBarButtonProps} from '../NavigationBarButton';

interface Props {
  disabled?: boolean;
  text?: string;
  handler?: () => void;
  renderButtonWrapper?: (
    button: React.ReactElement<any>,
  ) => React.ReactElement<any>;
}

export default (props: Props): NavigationBarButtonProps => {
  const {disabled, text, handler, renderButtonWrapper} = props;
  return {
    title: (
      <Text style={[styles.label, disabled ? styles.labelDisable : null]}>
        {text || '公開設定'}
      </Text>
    ),
    tintColor: 'white',
    disabled,
    handler,
    renderButtonWrapper,
  };
};

const styles = StyleSheet.create({
  label: {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#ff8f13',
  } as TextStyle,
  labelDisable: {
    color: '#999999',
  } as TextStyle,
});
