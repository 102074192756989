import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ScaleModal from './ScaleModal';

import PrimaryButton from '../buttons/PrimaryButton';
import LinkButton from '../buttons/LinkButton';
import DimensionContext from '../dimension/DimensionContext';
import CloseIcon from '../icons/CloseIcon';

interface Props {
  visible: boolean;
  title?: string;
  description: React.ReactNode | string;
  buttonText?: string;
  withCloseIcon?: boolean;
  titleStyle?: TextStyle;
  descriptionStyle?: TextStyle;
  onAccept: () => void;
  onRequestClose: () => void;
}

const ActionModal: React.FunctionComponent<Props> = props => {
  const {
    visible,
    title,
    description,
    buttonText,
    withCloseIcon,
    titleStyle,
    descriptionStyle,
    onAccept,
    onRequestClose,
  } = props;
  return (
    <DimensionContext.Consumer>
      {context => {
        return (
          <ScaleModal
            style={{width: context.content.width * 0.82}}
            visible={visible}
            onCloseModal={onRequestClose}>
            <View style={styles.modal}>
              {title && <Text style={[styles.title, titleStyle]}>{title}</Text>}
              <Text style={[styles.description, descriptionStyle]}>
                {description}
              </Text>
              <View style={styles.buttons}>
                <LinkButton
                  buttonSize={BUTTON_SIZE}
                  style={styles.button}
                  onPress={onRequestClose}>
                  キャンセル
                </LinkButton>
                <PrimaryButton
                  buttonSize={BUTTON_SIZE}
                  style={styles.button}
                  onPress={onAccept}>
                  {buttonText || 'OK'}
                </PrimaryButton>
              </View>
            </View>
            {withCloseIcon ? (
              <TouchableOpacity
                style={styles.closeIcon}
                onPress={onRequestClose}>
                <CloseIcon color={'#999999'} />
              </TouchableOpacity>
            ) : null}
          </ScaleModal>
        );
      }}
    </DimensionContext.Consumer>
  );
};

export default React.memo(ActionModal);

const BUTTON_SIZE = {width: 120, height: 46};

const styles = StyleSheet.create({
  modal: {
    marginVertical: 24,
    marginHorizontal: 16,
  } as ViewStyle,
  title: {
    color: '#ff8f13',
    fontSize: 15,
    fontWeight: 'bold',
    marginVertical: 8,
    alignSelf: 'center',
  } as TextStyle,
  description: {
    color: '#222222',
    fontSize: 12,
    lineHeight: 18,
    marginVertical: 8,
  } as TextStyle,
  buttons: {
    flexDirection: 'row',
    marginBottom: 16,
  } as ViewStyle,
  button: {
    alignItems: 'center',
    marginTop: 16,
  } as ViewStyle,
  closeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 44,
    height: 44,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
