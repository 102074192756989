import Entity from './Entity';

import Background from './Background';
import PositionedEffect from './PositionedEffect';

export type Filter =
  | 'normal'
  | 'sepia'
  | 'monochrome'
  | 'mosaic'
  | 'dream'
  | 'red'
  | 'blue'
  | 'green';

export interface Options {
  filter?: Filter;
}

export interface EffectOptions {
  layerOrder: 'background' | 'foreground';
}

export default class Scene implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public episodeId: number,
    public title: string | null,
    public numberOfEpisode: number,
    public background: Background | null,
    public positionedEffect: PositionedEffect | null,
    public options: Options | null,
    public effectOptions: EffectOptions | null,
    public updatedAt: Date,
    public createdAt: Date,
    public charactersCount: number,
    public illustrationsCount: number,
    public speechTextsCount: number,
    public descriptiveTextsCount: number,
    public tapCount: number,
    public totalSpeechTextLength: number,
    public totalDescriptiveTextLength: number,
  ) {}
}
