import {Dispatch} from 'redux';

import Types from '../Types';

import EpisodeCommentReply from '../../../domain/entities/EpisodeCommentReply';

import NetEpisodeCommentRepliesRepository from '../../../data/repositories/writer/NetEpisodeCommentRepliesRepository';

export interface Params {
  comment: string;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<EpisodeCommentReply> {
  return async (dispatch: Dispatch<any>): Promise<EpisodeCommentReply> => {
    try {
      const payload = await new NetEpisodeCommentRepliesRepository().update(
        id,
        {
          episodeCommentReply: params,
        },
      );
      dispatch({payload, type: Types.UpdateEpisodeCommentReplySuccess});
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateEpisodeCommentReplyFailure});
      throw err;
    }
  };
}
