import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import StoryListItem from './StoryListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import StoryAggregation from '../../../../../domain/value_objects/summary/StoryAggregation';

interface Props {
  storyAggregations: StoryAggregation[];
  ListHeaderComponent?: React.ComponentType<any> | React.ReactElement | null;
  onSelectStory: (storyAggregation: StoryAggregation) => void;
}

export default class AnnouncementList extends React.Component<Props> {
  public render(): React.ReactNode {
    const {storyAggregations, ListHeaderComponent} = this.props;
    return (
      <FlatList
        data={storyAggregations}
        keyExtractor={this.keyExtractor}
        renderItem={this.renderItem}
        ItemSeparatorComponent={ListItemNormalSeparator}
        ListHeaderComponent={ListHeaderComponent}
        ListFooterComponent={ListItemNormalSeparator}
        onEndReachedThreshold={0.1}
      />
    );
  }

  private keyExtractor = (
    storyAggregation: StoryAggregation,
    index: number,
  ): string => {
    return `${storyAggregation.id}`;
  };

  private renderItem = (
    info: ListRenderItemInfo<StoryAggregation>,
  ): React.ReactElement<any> | null => {
    const {item: storyAggregation} = info;
    const {onSelectStory} = this.props;
    return (
      <StoryListItem
        storyAggregation={storyAggregation}
        onSelectStory={onSelectStory}
      />
    );
  };
}
