import * as React from 'react';

import ShareContent from '../../shared/share/ShareContent';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {StoryFormStoryPublicationCompletionRouteProp} from '../../../navigators/RouteProps';

import Story from '../../../../domain/entities/Story';

export interface Params {
  storyId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: StoryFormStoryPublicationCompletionRouteProp;
  story: Story | null;
}

export interface DispatchProps {
  showStory: (id: number) => Promise<Story>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {showStory, route, story} = this.props;
    const {storyId} = route.params;
    showStory(storyId);
  }

  public render(): React.ReactNode {
    const {navigation, story} = this.props;
    return (
      <Layout
        title={`ストーリーの公開設定`}
        close={true}
        navigation={navigation}
        scrollable={false}>
        {story && (
          <ShareContent
            message={`${story.title}を公開しました。`}
            url={`${story.url}?ogp_type=story_publication`}
            imageUrl={story.ogpImageStoryPublicationUrl}
            title={story.title}
          />
        )}
      </Layout>
    );
  }
}
