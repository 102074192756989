import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import Scene from '../../../domain/entities/Scene';

import NetScenesRepository from '../../../data/repositories/writer/NetScenesRepository';

const types = {
  failure: Types.ShowSceneFailure,
  request: Types.ShowSceneRequest,
  success: Types.ShowSceneSuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Scene> {
  return showGeneric(new NetScenesRepository(), types, id);
}
