import * as React from 'react';

import SettingList from './partials/SettingList';
import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';

import * as routers from '../../routers';

import Setting from '../../view_models/Setting';

import WriterCurrentUser from '../../../domain/entities/writer/CurrentUser';
import WriterCurrentUserStatus from '../../../domain/entities/writer/CurrentUserStatus';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  currentUser: WriterCurrentUser | null;
  currentUserStatus: WriterCurrentUserStatus | null;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<WriterCurrentUser>;
  showCurrentUserStatus: () => Promise<WriterCurrentUserStatus>;
  destroyWriterCurrentUserSession: () => Promise<void>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {
      currentUser,
      currentUserStatus,
      showCurrentUser,
      showCurrentUserStatus,
    } = this.props;
    if (!currentUser) {
      showCurrentUser();
    }
    if (!currentUserStatus) {
      showCurrentUserStatus();
    }
  }

  public render(): React.ReactNode {
    const {navigation, currentUser, currentUserStatus} = this.props;
    return (
      <Layout
        title={'メニュー'}
        scrollable={false}
        navigation={navigation}
        close={true}>
        {currentUser && currentUserStatus && (
          <SettingList
            currentUser={currentUser}
            currentUserStatus={currentUserStatus}
            onSelectSetting={this.handleSelectSetting}
          />
        )}
      </Layout>
    );
  }

  private handleSelectSetting = (setting: Setting) => {
    const {navigation} = this.props;
    switch (setting) {
      case Setting.POLICIES_PRIVACY:
        routers.linkToPoliciesPrivacy(navigation);
        break;
      case Setting.POLICIES_TERMS:
        routers.linkToPoliciesTerms(navigation);
        break;
      case Setting.PARTNER_PROGRAM_POLICIES_TERMS:
        navigation.navigate('PartnerProgramPoliciesTerms', {});
        break;
      case Setting.POLICIES_SPECIFIED_COMMERCIAL_TRANSACTION_LAW:
        routers.linkToPoliciesSpecifiedCommercialTransactionLaw(navigation);
        break;
      case Setting.POLICIES_SETTLEMENT_LAW:
        routers.linkToPoliciesSettlementLaw(navigation);
        break;
      case Setting.SETTINGS_ACCOUNT:
        routers.linkToWriterUserAccount(navigation);
        break;
      case Setting.VIDEO_SHARE_SERVICE_ACCOUNTS:
        navigation.navigate('WriterUserVideoShareServiceAccounts', {});
        break;
      case Setting.SETTINGS_BILLING_PAYMENT_METHODS:
        navigation.navigate('SettingsBillingPaymentMethods', {});
        break;
      case Setting.ACCOUNT_BILLING_HISTORY:
        navigation.navigate('AccountNavigation', {
          screen: 'AccountBillingHistory',
          params: {},
        });
        break;
      case Setting.SETTINGS_BANK_ACCOUNT:
        navigation.navigate('SettingsBankAccount', {});
        break;
      case Setting.SETTINGS_TRANSFER_HISTORY:
        navigation.navigate('SettingsTransferHistory', {});
        break;
      case Setting.SETTINGS_USER_IDENTIFICATION:
        navigation.navigate('SettingsUserIdentification', {});
        break;
      case Setting.SETTINGS_NOTIFICATIONS:
        routers.linkToSettingsNotifications(navigation);
        break;
      case Setting.PROFILE:
        routers.linkToEditWriterUser(navigation, {});
        break;
      case Setting.ANNOUNCEMENT:
        routers.linkToAnnouncements(navigation);
        break;
      case Setting.HELPS:
        routers.linkToHelps(navigation);
        break;
      case Setting.CONTACTS:
        routers.linkToNewContact(navigation);
        break;
      case Setting.DELETE_ACCOUNT:
        routers.linkToNewWriterUserCancellation(navigation);
        break;
      case Setting.LOGOUT: {
        const {destroyWriterCurrentUserSession} = this.props;
        destroyWriterCurrentUserSession();
        navigation.navigate('Home', {});
        break;
      }
    }
  };
}
