import * as React from 'react';

import TapNovelWebView from '../../../shared/TapNovelWebView';
import Layout from '../../../shared/Layout';

import NavigationProp from '../../../../navigators/NavigationProp';
import {WritersGatePoliciesTermsRouteProp} from '../../../../navigators/RouteProps';

import {getUserAgent} from '../../../../../data/data_stores/net/UserAgent';

export interface Params {
  modal?: any;
}

export interface StateProps {
  navigation: NavigationProp;
  route: WritersGatePoliciesTermsRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {
  children?: React.ReactNode;
}

const Index: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {modal} = route.params || {modal: undefined};
  const back = !modal;
  const close = !!modal;
  const [userAgent, setUserAgent] = React.useState<string | null>(null);
  React.useEffect(() => {
    getUserAgent().then(ua => {
      setUserAgent(ua);
    });
  });
  return (
    <Layout
      title={'ライターズゲート約款'}
      scrollable={false}
      navigation={navigation}
      back={back}
      close={close}>
      {userAgent && (
        <TapNovelWebView
          style={{flex: 1}}
          originWhitelist={['*']}
          path={'/writer/writers_gate/policies/terms?without_layout=on'}
          userAgent={userAgent}
        />
      )}
    </Layout>
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(Index, areEqual);
