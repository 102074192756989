import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import PositionListItem from './PositionListItem';

import Position from '../../../../../../../domain/value_objects/Position';

interface Props {
  selectedPositions: Position[];
  visible: boolean;
  onSelectPosition: (position: Position, toggleMultiple?: boolean) => void;
}

const PositionList: React.FunctionComponent<Props> = props => {
  const {selectedPositions, visible, onSelectPosition} = props;
  return (
    <View style={[styles.container, visible ? null : {display: 'none'}]}>
      <View style={[styles.positions]}>
        {[
          {label: '左', value: Position.Left},
          {label: '中央', value: Position.Center},
          {label: '右', value: Position.Right},
        ].map(item => (
          <PositionListItem
            key={`${item.value}`}
            value={item.value}
            label={item.label}
            checked={selectedPositions.includes(item.value)}
            onSelectPosition={onSelectPosition}
          />
        ))}
      </View>
      <Text style={styles.message}>長押しで複数選択できます</Text>
    </View>
  );
};

export default React.memo(PositionList);

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    justifyContent: 'center',
  } as ViewStyle,
  positions: {
    flexDirection: 'row',
    overflow: 'hidden',
    paddingHorizontal: 7,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  message: {
    marginTop: 10,
    color: '#383838',
    fontSize: 10,
    textAlign: 'center',
  } as TextStyle,
});
