import * as React from 'react';
import {
  ImageStyle,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import NoFlickerImage from '../no_flicker_image/NoFlickerImage';

import ShuffleIcon from '../icons/ShuffleIcon';

import InversionButton from '../InversionButton';

import {markImageUrl} from '../../../helpers/images';

import ActorFacePart from '../../../../domain/entities/character_maker/ActorFacePart';
import ActorEye from '../../../../domain/entities/character_maker/ActorEye';
import ActorHairStyle from '../../../../domain/entities/character_maker/ActorHairStyle';
import ActorCostume from '../../../../domain/entities/character_maker/ActorCostume';
import Mark from '../../../../domain/entities/Mark';

interface Props {
  actorFacePart: ActorFacePart | null | undefined;
  actorEye: ActorEye | null | undefined;
  actorHairStyle: ActorHairStyle | null | undefined;
  actorCostume: ActorCostume | null | undefined;
  mark?: Mark | null;
  width: number;
  inverted?: boolean;
  onPressShuffle?: () => void;
  onPressInversion?: () => void;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {
    actorFacePart,
    actorEye,
    actorHairStyle,
    actorCostume,
    mark,
    width,
    inverted,
    onPressShuffle,
    onPressInversion,
  } = props;
  const characterSize = width * ASPECT_RATIO - MARGIN;
  return (
    <View
      style={[styles.container, {width: width, height: width * ASPECT_RATIO}]}>
      {mark && mark.id !== 0 ? (
        <View style={styles.markWrapper}>
          <NoFlickerImage
            style={styles.mark}
            resizeMode={'contain'}
            source={{uri: markImageUrl(mark, 'large')}}
          />
        </View>
      ) : null}

      {actorFacePart && actorEye && actorHairStyle && actorCostume && (
        <View style={[styles.character, inverted ? styles.inverted : null]}>
          <NoFlickerImage
            style={[
              styles.image,
              {width: characterSize, height: characterSize},
            ]}
            resizeMode={'contain'}
            source={{
              uri: actorFacePart.getActorOneThirdImageUrl(),
            }}
          />
          <NoFlickerImage
            style={[
              styles.image,
              {width: characterSize, height: characterSize},
            ]}
            resizeMode={'contain'}
            source={{
              uri: actorFacePart.getOneThirdImageUrl(),
            }}
          />
          <NoFlickerImage
            style={[
              styles.image,
              {width: characterSize, height: characterSize},
            ]}
            resizeMode={'contain'}
            source={{
              uri: actorEye.getOneThirdImageUrl(),
            }}
          />
          <NoFlickerImage
            style={[
              styles.image,
              {width: characterSize, height: characterSize},
            ]}
            resizeMode={'contain'}
            source={{
              uri: actorHairStyle.getOneThirdImageUrl(),
            }}
          />
          <NoFlickerImage
            style={[
              styles.image,
              {width: characterSize, height: characterSize},
            ]}
            resizeMode={'contain'}
            source={{
              uri: actorCostume.getOneThirdImageUrl(),
            }}
          />
        </View>
      )}
      {onPressShuffle ? (
        <TouchableOpacity style={styles.shuffle} onPress={onPressShuffle}>
          <ShuffleIcon />
        </TouchableOpacity>
      ) : null}
      {onPressInversion ? <InversionButton onPress={onPressInversion} /> : null}
    </View>
  );
};

export default React.memo(CharacterView);

const ASPECT_RATIO = 256 / 375;

const MARGIN = 16;

const MARK_SIZE = 30;

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    padding: 8,
  } as ViewStyle,
  character: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  inverted: {
    transform: [{scaleX: -1}],
  } as ViewStyle,
  image: {
    position: 'absolute',
  } as ImageStyle,
  markWrapper: {
    position: 'absolute',
    left: 0,
    right: 100,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  } as ViewStyle,
  mark: {
    width: MARK_SIZE,
    height: MARK_SIZE,
  } as ImageStyle,
  shuffle: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 38,
    height: 38,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 19,
    borderWidth: 1,
    borderColor: '#383838',
  } as ViewStyle,
});
