import React from 'react';

import TransferList from './components/TransferList';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {SettingsBankAccountRouteProp} from '../../../navigators/RouteProps';

import Transfer from '../../../../domain/entities/Transfer';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: SettingsBankAccountRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Index: React.FC<Props> = props => {
  const {navigation} = props;
  const [transfers, setTransfers] = React.useState<Transfer[] | null>(null);
  const [token, setToken] = React.useState<string | null>(null);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<Transfer[]>('/api/writer/transfers')
      .then(response => {
        setTransfers(response.body);
      })
      .catch(() => {});
    TapNovelTypedRestApi.post<{token: string}>('/api/writer/session_token')
      .then(response => {
        setToken(response.body.token);
      })
      .catch(() => {});
  }, []);
  return (
    <Layout title={'振込履歴'} back={true} navigation={navigation}>
      {transfers && token ? (
        <TransferList transfers={transfers} token={token} />
      ) : null}
    </Layout>
  );
};

export default React.memo(Index);
