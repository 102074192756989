import EntityMapper from './EntityMapper';

import EffectCategory from '../../../domain/entities/EffectCategory';

export interface EffectCategoryData {
  id: number;
  parent_id: number;
  name: string;
  leaf: boolean;
  base_effect_count: number;
}

export default class EffectCategoryMapper
  implements EntityMapper<EffectCategoryData, EffectCategory>
{
  public map(obj: EffectCategoryData): EffectCategory {
    return new EffectCategory(
      obj.id,
      obj.parent_id,
      obj.name,
      obj.leaf,
      obj.base_effect_count,
    );
  }
}
