import * as React from 'react';
import {
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import {storyCoverImageUrl} from '../../../../helpers/images';

import {colors} from '../../../../styles/variables';

import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';

import StoryAggregation from '../../../../../domain/value_objects/summary/StoryAggregation';

interface Props {
  storyAggregation: StoryAggregation;
  onSelectStory: (storyAggregation: StoryAggregation) => void;
}

const StoryListItemContent: React.FunctionComponent<Props> = props => {
  const {storyAggregation, onSelectStory} = props;
  const handleSelectStory = React.useCallback(() => {
    onSelectStory(storyAggregation);
  }, [storyAggregation.id]);
  const source = {
    uri: storyCoverImageUrl(
      new StoryCoverImageDecorator(storyAggregation),
      'small',
    ),
    headers: {Accept: 'image/webp,image/apng,*/*'},
  };
  return (
    <Pressable style={styles.container} onPress={handleSelectStory}>
      {storyAggregation.hasCoverImage ? (
        <FastImageAdapter style={styles.image} source={source} />
      ) : (
        <View style={styles.image}>
          <Text style={styles.noImageText}>表紙なし</Text>
        </View>
      )}
      <View style={styles.storyInfo}>
        <View style={styles.top}>
          <Text style={styles.title}>{storyAggregation.title}</Text>
        </View>
        <>
          <View style={styles.bottomItem}>
            <Text style={styles.bottomItemKey}>作品ページ参照数(人)</Text>
            <Text style={styles.bottomItemValue}>
              {numeral(storyAggregation.storyPageViewCount).format('0,0')}(
              {numeral(storyAggregation.storyPageViewUserCount).format('0,0')})
            </Text>
          </View>
          <View style={styles.bottomItem}>
            <Text style={styles.bottomItemKey}>閲覧数(人)</Text>
            <Text style={styles.bottomItemValue}>
              {numeral(storyAggregation.episodePageViewCount).format('0,0')}(
              {numeral(
                storyAggregation.episodePageViewUniqueConsumerUserCount,
              ).format('0,0')}
              )
            </Text>
          </View>
          <View style={styles.bottomItem}>
            <Text style={styles.bottomItemKey}>閲覧率</Text>
            <Text style={styles.bottomItemValue}>
              {numeral(storyAggregation.episodePageViewRate * 100).format(
                '0,0.0',
              )}
              %
            </Text>
          </View>
        </>
      </View>
      <View style={styles.icon}>
        <ArrowForwardIcon />
      </View>
    </Pressable>
  );
};

export default React.memo(StoryListItemContent);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flexDirection: 'row',
    marginVertical: 10,
    marginHorizontal: 16,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  storyInfo: {
    flex: 1,
    marginLeft: 10,
    justifyContent: 'center',
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  top: {
    marginBottom: 11,
  } as ViewStyle,
  bottomItem: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  bottomItemKey: {
    color: '#383838',
    fontSize: 10,
    lineHeight: 11,
    width: 100,
  } as TextStyle,
  bottomItemValue: {
    marginHorizontal: 8,
    color: '#ff8f13',
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 20,
    textAlign: 'right',
    width: 125,
  } as TextStyle,
  icon: {
    justifyContent: 'center',
  } as ViewStyle,
});
