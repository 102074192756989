import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import PreviewBox from '../../../shared/cover_image_form/PreviewBox';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import FontColorButtons from '../../../shared/FontColorButtons';
import FontSizeButtons from '../../../shared/FontSizeButtons';
import FontSelect from '../../../shared/FontSelect';

import loadGoogleFont from '../../../../helpers/loadGoogleFont';

import CoverImageForm from '../../../../../domain/forms/CoverImageForm';
import FontColor from '../../../../../domain/value_objects/FontColor';
import FontSize from '../../../../../domain/value_objects/FontSize';
import ImageTextInfo from '../../../../../domain/value_objects/ImageTextInfo';

import File from '../../../../../domain/entities/File';

interface Props {
  coverImageForm: CoverImageForm;
  aspectRatio: number;
  newImageTextInfo: ImageTextInfo;
  imageKey: 'coverImage' | 'image';
  onSubmit: (file: File, imageTextInfoList: ImageTextInfo[]) => void;
}

interface State {
  newImageTextInfo: ImageTextInfo;
  fetchedBackgroundImageUri: boolean;
  backgroundImageUri: string | null;
}

export default class Form extends React.PureComponent<Props, State> {
  private ref = React.createRef<PreviewBox>();

  constructor(props: Props) {
    super(props);
    this.state = {
      newImageTextInfo: props.newImageTextInfo,
      fetchedBackgroundImageUri: false,
      backgroundImageUri: null,
    };

    const imageTextInfoList = this.generateImageTextInfoList(
      props.coverImageForm,
      props.newImageTextInfo,
    );
    Promise.all(
      Array.prototype.map.call(imageTextInfoList, async imageTextInfo => {
        if (imageTextInfo.fontFamily) {
          await loadGoogleFont(imageTextInfo.fontFamily, imageTextInfo.text);
        }
      }),
    );
  }

  public async componentDidMount() {
    const {coverImageForm} = this.props;
    if (coverImageForm) {
      const backgroundImageUri = await coverImageForm.getBackgroundImageUri();
      this.setState({backgroundImageUri, fetchedBackgroundImageUri: true});
    }
  }

  public async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {coverImageForm} = this.props;
    if (coverImageForm && prevProps.coverImageForm) {
      const backgroundImageUri = await coverImageForm.getBackgroundImageUri();
      this.setState({backgroundImageUri, fetchedBackgroundImageUri: true});
    }
  }

  public render(): React.ReactNode {
    const {coverImageForm, aspectRatio} = this.props;
    const {newImageTextInfo, fetchedBackgroundImageUri, backgroundImageUri} =
      this.state;
    if (!fetchedBackgroundImageUri) {
      return null;
    }
    return (
      <View style={styles.container}>
        <View style={styles.preview}>
          <PreviewBox
            key={this.generateKey(newImageTextInfo)}
            ref={this.ref}
            visible={true}
            backgroundImageUri={backgroundImageUri}
            characterImageUri={coverImageForm.getCharacterImageUri()}
            aspectRatio={aspectRatio}
            imageTextInfoList={this.generateImageTextInfoList(
              coverImageForm,
              newImageTextInfo,
            )}
          />
        </View>

        <FontSizeButtons
          fontSize={newImageTextInfo.fontSize}
          onSelectFontSize={this.handleSelectFontSize}
        />
        <FontColorButtons
          style={{marginTop: 20}}
          title={'文字の色'}
          fontColor={newImageTextInfo.fontColor}
          onSelectFontColor={this.handlePressFontColor}
        />
        <FontColorButtons
          style={{marginBottom: 20}}
          title={'文字枠の色'}
          fontColor={newImageTextInfo.fontFrameColor}
          withNone={true}
          onSelectFontColor={this.handlePressFontFrameColor}
        />
        <FontSelect
          value={newImageTextInfo.fontFamily}
          onValueChange={this.handleValueChange}
        />
        <View style={{alignItems: 'center'}}>
          <PrimaryButton onPress={this.handlePress}>
            ここに配置する
          </PrimaryButton>
        </View>
      </View>
    );
  }

  private generateImageTextInfoList = (
    coverImageForm: CoverImageForm,
    newImageTextInfo: ImageTextInfo,
  ) => {
    const baseImageTextInfoList = coverImageForm.getImageTextInfoList();
    return baseImageTextInfoList
      .filter(
        baseImageTextInfo =>
          baseImageTextInfo.textPosition !== newImageTextInfo.textPosition,
      )
      .concat([newImageTextInfo]);
  };

  private handlePress = async () => {
    const {coverImageForm, imageKey, onSubmit} = this.props;
    const {newImageTextInfo} = this.state;
    if (!this.ref.current) {
      return;
    }
    const file = await this.ref.current.getImageFile();
    if (file) {
      const imageTextInfoList = this.generateImageTextInfoList(
        coverImageForm,
        newImageTextInfo,
      );
      onSubmit(file, imageTextInfoList);
    }
  };

  private generateKey = (imageTextInfo: ImageTextInfo) => {
    return `${imageTextInfo.fontSize}_${imageTextInfo.fontColor}_${imageTextInfo.fontFamily}_${imageTextInfo.fontFrameColor}`;
  };

  private handlePressFontColor = (fontColor: FontColor) => {
    const {newImageTextInfo} = this.state;
    this.setState({newImageTextInfo: {...newImageTextInfo, fontColor}});
  };

  private handlePressFontFrameColor = (fontFrameColor: FontColor) => {
    const {newImageTextInfo} = this.state;
    this.setState({
      newImageTextInfo: {...newImageTextInfo, fontFrameColor},
    });
  };

  private handleSelectFontSize = (fontSize: FontSize) => {
    const {newImageTextInfo} = this.state;
    this.setState({
      newImageTextInfo: {...newImageTextInfo, fontSize},
    });
  };

  private handleValueChange = async (fontFamily: string | number) => {
    const {newImageTextInfo} = this.state;
    await loadGoogleFont(`${fontFamily}`, newImageTextInfo.text);
    setTimeout(() => {
      this.setState({
        newImageTextInfo: {...newImageTextInfo, fontFamily: `${fontFamily}`},
      });
    }, 100);
  };
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 32,
  } as ViewStyle,
  preview: {
    flex: 1,
    alignItems: 'center',
  },
});
