import EntityMapper from '../EntityMapper';

import ContactCategory from '../../../../domain/entities/writer/ContactCategory';

export interface ContactCategoryData {
  id: number;
  name: string;
  note: string;
}

export default class ContactCategoryMapper
  implements EntityMapper<ContactCategoryData, ContactCategory>
{
  public map(obj: ContactCategoryData): ContactCategory {
    return new ContactCategory(obj.id, obj.name, obj.note);
  }
}
