import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Show, {DispatchProps, StateProps} from '../../components/scenes/Show';

import AppState from '../../reducers/AppState';

import showScene from '../../actions/scenes/show';
import updateScene, {
  Params as SceneUpdateParams,
} from '../../actions/scenes/update';

import selectEntity from '../../helpers/selectEntity';
import {ShowSceneRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: ShowSceneRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {sceneId} = route.params;
  const {entities} = state;
  const scene = selectEntity(entities.scenes, sceneId);
  return {
    navigation,
    scene,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showScene: (id: number) => dispatch(showScene(id)),
    updateScene: (id: number, params: SceneUpdateParams) =>
      dispatch(updateScene(id, params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
