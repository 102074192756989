import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import SpeechBalloon from '../../../domain/entities/SpeechBalloon';

import NetSpeechBalloonsRepository from '../../../data/repositories/NetSpeechBalloonsRepository';

const types = {
  failure: Types.ShowSpeechBalloonFailure,
  request: Types.ShowSpeechBalloonRequest,
  success: Types.ShowSpeechBalloonSuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<SpeechBalloon> {
  return showGeneric(new NetSpeechBalloonsRepository(), types, id);
}
