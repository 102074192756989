import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import {SortValue} from './types';

import {buildPathWithQuery} from '../../../../helpers/buildPathWithQuery';
interface Props {
  query?: string;
  sort: SortValue;
}

const SortButtons: React.FC<Props> = props => {
  const {query, sort} = props;
  const linkTo = useLinkTo();
  const onPressIdDesc = React.useCallback(() => {
    linkTo(buildPathWithQuery('/faq/questions', {query, sort: 'id_desc'}));
  }, [query, sort]);
  const onPressPageViewDesc = React.useCallback(() => {
    linkTo(
      buildPathWithQuery('/faq/questions', {query, sort: 'page_view_desc'}),
    );
  }, [query, sort]);
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPressIdDesc}>
        <Text style={sort === 'id_desc' ? styles.textActive : styles.text}>
          新着順
        </Text>
      </TouchableOpacity>
      <Text style={styles.separator}>|</Text>
      <TouchableOpacity onPress={onPressPageViewDesc}>
        <Text
          style={sort === 'page_view_desc' ? styles.textActive : styles.text}>
          閲覧順
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export default React.memo(SortButtons);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: 16,
    marginTop: 0,
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 12,
  } as TextStyle,
  textActive: {
    color: '#ff8f13',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  separator: {
    width: 6,
    height: 12,
    color: '#e5e5e5',
    fontSize: 14,
    marginHorizontal: 5,
    textAlign: 'center',
  } as TextStyle,
});
