import {Dispatch} from 'redux';

import Types from '../Types';

import Voice from '../../../domain/entities/Voice';
import File from '../../../domain/entities/File';

import NetVoicesRepository from '../../../data/repositories/writer/NetVoicesRepository';

export interface Params {
  audio: File | null;
}

export default function action(
  id: number,
  params: Params,
): (dispatch: Dispatch<any>) => Promise<Voice> {
  return async (dispatch: Dispatch<any>): Promise<Voice> => {
    try {
      const payload = await new NetVoicesRepository().update(id, {
        voice: params,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.UpdateVoiceFailure});
      throw err;
    }
  };
}
