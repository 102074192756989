import * as React from 'react';
import {
  Animated,
  ActivityIndicator,
  Image,
  ImageStyle,
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import MoreIcon from '../../shared/icons/ionicons/MoreIcon';
import shouldUpdateScene from '../../shared/enhanced/shouldUpdateScene';

import {backgroundImageUrl} from '../../../helpers/images';

import {colors, baseStyleWidth} from '../../../styles/variables';

import Scene from '../../../../domain/entities/Scene';

interface Props {
  scene: Scene;
  contentWidth: number;
  sourceUri?: string;
  active?: boolean;
  copyLabel?: string | null;
  onOpenActionSheet: (scene: Scene) => void;
}

export default class SceneListItemContent extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return (
      shouldUpdateScene(this.props, nextProps) ||
      this.props.active !== nextProps.active ||
      this.props.contentWidth !== nextProps.contentWidth ||
      this.props.copyLabel !== nextProps.copyLabel ||
      this.props.sourceUri !== nextProps.sourceUri
    );
  }

  public render(): React.ReactNode {
    const {scene, sourceUri, active, copyLabel, contentWidth} = this.props;
    const imageWidth = contentWidth * (323 / baseStyleWidth);
    const imageHeight = imageWidth * (111 / 323);
    const imageStyle = Platform.select({
      default: {
        height: imageHeight,
        width: imageWidth,
      },
    });
    return (
      <Animated.View
        style={[styles.container, {transform: active ? [{scale: 1.03}] : []}]}>
        <View style={styles.header}>
          <Text
            style={styles.sceneNumber}>{`シーン${scene.numberOfEpisode}`}</Text>
          {scene.title ? (
            <Text ellipsizeMode={'clip'} numberOfLines={1} style={styles.title}>
              {scene.title}
            </Text>
          ) : copyLabel ? (
            <View style={styles.copyLabel}>
              <Text style={styles.copyLabelText}>{copyLabel}</Text>
            </View>
          ) : null}
          <TouchableOpacity
            style={styles.headerRight}
            activeOpacity={1}
            onPress={this.handlePress}>
            <View style={styles.more}>
              <MoreIcon />
            </View>
          </TouchableOpacity>
        </View>
        <View style={styles.imageWrapper}>
          {scene.background ? (
            <View style={[styles.image, imageStyle]}>
              <Image
                style={{height: imageHeight, width: imageWidth}}
                source={{
                  uri:
                    sourceUri || backgroundImageUrl(scene.background, 'large'),
                }}
              />
              {scene.background &&
                scene.options?.filter &&
                scene.options?.filter !== 'normal' &&
                !sourceUri && (
                  <ActivityIndicator
                    color={'#999999'}
                    style={{
                      position: 'absolute',
                      justifyContent: 'center',
                      alignSelf: 'center',
                    }}
                  />
                )}
            </View>
          ) : (
            <View
              style={[styles.image, imageStyle, {backgroundColor: '#efefef'}]}>
              <Text style={styles.noImageText}>背景イラストなし</Text>
            </View>
          )}
        </View>
        <View style={{flexDirection: 'row', marginTop: 8}}>
          <Text style={{fontSize: 11, color: '#383838', marginRight: 8}}>
            セリフ：{numeral(scene.totalSpeechTextLength || 0).format('0,0')}字
          </Text>
          <Text style={{fontSize: 11, color: '#383838', marginRight: 8}}>
            テキスト：
            {numeral(scene.totalDescriptiveTextLength || 0).format('0,0')}字
          </Text>
          <Text style={{fontSize: 11, color: '#383838', marginRight: 8}}>
            Tap：{numeral(scene.tapCount || 0).format('0,0')}
            Tap
          </Text>
        </View>
      </Animated.View>
    );
  }

  private handlePress = () => {
    const {scene, onOpenActionSheet} = this.props;
    onOpenActionSheet(scene);
  };
}

const HEADER_HEIGHT = 44;
const MORE_HEIGHT = HEADER_HEIGHT + 10;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    borderColor: colors.powderGray,
    borderWidth: 1,
    flex: 1,
    marginTop: 8,
    marginHorizontal: 10,
    paddingHorizontal: 16,
    paddingBottom: 8,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
  data: {
    flex: 1,
    flexDirection: 'row',
  } as ViewStyle,
  dataItem: {
    color: colors.gray,
    fontSize: 9,
    paddingRight: 5,
  } as TextStyle,
  dataWrapper: {
    paddingVertical: 10,
  } as ViewStyle,
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    height: HEADER_HEIGHT,
    width: '100%',
    zIndex: 1,
  } as ViewStyle,
  headerRight: {
    alignItems: 'center',
    height: MORE_HEIGHT,
    justifyContent: 'center',
    position: 'absolute',
    right: -15,
    top: -5,
    width: MORE_HEIGHT,
    zIndex: 100,
  } as ViewStyle,
  image: {
    alignItems: 'center',
    justifyContent: 'center',
  } as ImageStyle,
  imageWrapper: {
    alignItems: 'center',
  } as ViewStyle,
  more: {
    flex: 1,
    justifyContent: 'center',
  } as ViewStyle,
  noImageText: {
    color: '#666666',
    fontSize: 14,
  } as TextStyle,
  sceneNumber: {
    color: colors.textColor,
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  title: {
    fontWeight: 'bold',
    marginLeft: 8,
    fontSize: 10,
    color: colors.gray,
    maxWidth: 200,
  } as TextStyle,
  copyLabel: {
    borderColor: colors.lightGray,
    borderWidth: 1,
    borderRadius: 100,
    marginLeft: 8,
  } as ViewStyle,
  copyLabelText: {
    paddingVertical: 2,
    paddingHorizontal: 8,
    fontSize: 9,
    fontWeight: 'bold',
    color: colors.gray,
  } as TextStyle,
});
