import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import Mark from '../../../domain/entities/Mark';

import NetMarksRepository from '../../../data/repositories/NetMarksRepository';

const types = {
  failure: Types.ShowMarkFailure,
  request: Types.ShowMarkRequest,
  success: Types.ShowMarkSuccess,
};

export default function action(
  id: number,
): (dispatch: Dispatch<any>, getState: () => AppState) => Promise<Mark> {
  return showGeneric(new NetMarksRepository(), types, id);
}
