import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories from '../../../../containers/scene_form/illustration_show_scene_command/edit/illustration_usage_histories/Index';
import SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm from '../../../../containers/scene_form/illustration_show_scene_command/edit/illustration_search_form/Index';
import SceneFormIllustrationShowSceneCommandEditIllustrations from '../../../../containers/scene_form/illustration_show_scene_command/edit/illustrations/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories">
      <RootStack.Screen
        name="SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories"
        component={
          SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistories
        }
        options={{title: 'アイテム'}}
      />
      <RootStack.Screen
        name="SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm"
        component={
          SceneFormIllustrationShowSceneCommandEditIllustrationSearchForm
        }
        options={{title: 'アイテム検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormIllustrationShowSceneCommandEditIllustrations"
        component={SceneFormIllustrationShowSceneCommandEditIllustrations}
        options={{title: 'アイテム'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
