import * as React from 'react';

import Layout from '../../shared/Layout';

import NewForm from './partial/NewForm';

import AlertModal from '../../shared/modals/AlertModal';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewWriterUserPasswordRouteProp} from '../../../navigators/RouteProps';

import {Params as UserPasswordCreateParams} from '../../../actions/writer/users/password/create';

import {formatErrorMessages} from '../../../helpers/errorMessages';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: NewWriterUserPasswordRouteProp;
}

export interface DispatchProps {
  createUserPassword: (
    params: UserPasswordCreateParams,
  ) => Promise<Record<string, unknown>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  alertMessage?: string;
}

export default class New extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {loading: false};
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {loading, alertMessage} = this.state;

    return (
      <Layout
        title={'パスワード再設定'}
        navigation={navigation}
        close={true}
        loading={loading}
        hideHeaderBottomBorder={true}
        scrollViewStyle={{backgroundColor: '#fafafa'}}>
        <NewForm onSubmit={this.handleSubmit} />
        <AlertModal
          visible={!!alertMessage}
          onCloseModal={() => {
            this.setState({alertMessage: undefined});
          }}>
          {alertMessage}
        </AlertModal>
      </Layout>
    );
  }

  private handleSubmit = (email: string) => {
    const {navigation, createUserPassword} = this.props;
    this.setState({loading: true});
    createUserPassword({email})
      .then(() => {
        navigation.navigate('WriterUserPasswordCompletion', {email});
        this.setState({
          loading: false,
          alertMessage: undefined,
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
          alertMessage: formatErrorMessages({}, error),
        });
      });
  };
}
