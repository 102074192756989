import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import dateformat from 'dateformat';

import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import ProjectIteration from '../../../../../domain/entities/ProjectIteration';

interface Props {
  iteration: ProjectIteration;
}

const IterationListItem: React.FC<Props> = props => {
  const linkTo = useLinkTo();
  const {iteration} = props;
  const onPress = React.useCallback(() => {
    linkTo(`/projects/${iteration.projectId}/iterations/${iteration.id}`);
  }, []);
  return (
    <TouchableOpacity style={styles.container} onPress={onPress}>
      <Text style={styles.text}>
        第{iteration.number}ターム（
        {dateformat(iteration.createdAt, 'yyyy.mm.dd')}～
        {iteration.completedAt
          ? dateformat(iteration.completedAt, 'yyyy.mm.dd')
          : ''}
        ）
      </Text>
      <ArrowForwardIcon />
    </TouchableOpacity>
  );
};

export default React.memo(IterationListItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 54,
    paddingHorizontal: 16,
    backgroundColor: '#fff',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 14,
  } as TextStyle,
});
