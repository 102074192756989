import Entity from './Entity';

import ImageUrlHelper, {Options} from '../helpers/ImageUrlHelper';

export default class Mark implements Entity {
  constructor(public id: number, public originalImageUrl: string) {}

  public getImageUrl(options: Options): string {
    return ImageUrlHelper.getImageUrl(this.originalImageUrl, options);
  }
}
