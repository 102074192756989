import NetResourceRepository from '../NetResourceRepository';
import NetResourceRepositoryAdapter from '../NetResourceRepositoryAdapter';

import UserNotificationSettingsBatchMapper, {
  UserNotificationSettingsBatchData,
} from '../../entities/mappers/writer/UserNotificationSettingsBatchMapper';

import UserNotificationSettingsBatch from '../../../domain/entities/writer/UserNotificationSettingsBatch';
import UserNotificationSettingsBatchRepository from '../../../domain/repositories/writer/UserNotificationSettingsBatchRepository';

export default class NetUserNotificationSettingsBatchRepository
  extends NetResourceRepository<
    UserNotificationSettingsBatch,
    UserNotificationSettingsBatchData
  >
  implements UserNotificationSettingsBatchRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/writer/user_notification_settings_batch',
        new UserNotificationSettingsBatchMapper(),
      ),
    );
  }
}
