import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories from '../../../../containers/scene_form/sound_effect_show_scene_command/edit/sound_usage_histories/Index';
import SceneFormSoundEffectShowSceneCommandEditSoundSearchForm from '../../../../containers/scene_form/sound_effect_show_scene_command/edit/sound_search_form/Index';
import SceneFormSoundEffectShowSceneCommandEditSounds from '../../../../containers/scene_form/sound_effect_show_scene_command/edit/sounds/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories">
      <RootStack.Screen
        name="SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories"
        component={SceneFormSoundEffectShowSceneCommandEditSoundUsageHistories}
        options={{title: '効果音'}}
      />
      <RootStack.Screen
        name="SceneFormSoundEffectShowSceneCommandEditSoundSearchForm"
        component={SceneFormSoundEffectShowSceneCommandEditSoundSearchForm}
        options={{title: '効果音検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormSoundEffectShowSceneCommandEditSounds"
        component={SceneFormSoundEffectShowSceneCommandEditSounds}
        options={{title: '効果音'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
