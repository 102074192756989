import * as React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ArrowDown from '../../shared/ArrowDown';
import shouldUpdateEntity from '../../shared/enhanced/shouldUpdateEntity';

import AnnouncementCategory from '../../../../domain/entities/writer/AnnouncementCategory';

interface Props {
  selectedAnnouncementCategory: AnnouncementCategory;
  activated: boolean;
  onPress: () => void;
  children?: React.ReactNode;
}

const AnnouncementCategoryFilter: React.FunctionComponent<Props> = props => {
  const {selectedAnnouncementCategory, onPress} = props;
  return (
    <TouchableOpacity activeOpacity={0.6} onPress={onPress}>
      <View style={styles.container}>
        <View style={styles.box}>
          <View style={styles.selectedCategory}>
            <Text style={styles.selectedCategoryText}>
              {selectedAnnouncementCategory.name}
            </Text>
            <ArrowDown />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return (
    prevProps.activated === nextProps.activated &&
    !shouldUpdateEntity(
      {
        entity: prevProps.selectedAnnouncementCategory,
      },
      {
        entity: nextProps.selectedAnnouncementCategory,
      },
    )
  );
};

export default React.memo(AnnouncementCategoryFilter, areEqual);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    height: 56,
    width: '100%',
    justifyContent: 'center',
    padding: 16,
  } as ViewStyle,
  box: {
    flex: 1,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  selectedCategory: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  } as ViewStyle,
  selectedCategoryText: {
    color: '#999999',
    fontSize: 15,
    lineHeight: Platform.select({web: 1, default: undefined}),
    marginRight: 5,
    textAlign: 'center',
  } as TextStyle,
});
