import Character from '../../../../domain/entities/Character';

interface Props {
  characters: Character[] | null;
}

export default (current: Props, next: Props) => {
  if (current.characters === next.characters) {
    return false;
  }
  if (!current.characters || !next.characters) {
    return true;
  }
  if (current.characters.length !== next.characters.length) {
    return true;
  }
  return !current.characters.every((currentCharacter, i) => {
    if (!next.characters) {
      return false;
    }
    const nextCharacter = next.characters[i];
    if (currentCharacter.id !== nextCharacter.id) {
      return false;
    }
    if (
      currentCharacter.updatedAt.getTime() !== nextCharacter.updatedAt.getTime()
    ) {
      return false;
    }
    return true;
  });
};
