import * as React from 'react';
import TooltipModal from '../../shared/modals/TooltipModal';

interface Props {
  visible: boolean;
  mode: 'creating' | 'updating';
  onRequestClose: () => void;
}

const RestoreModal: React.FunctionComponent<Props> = props => {
  const {visible, mode, onRequestClose} = props;
  return (
    <TooltipModal
      visible={visible}
      title={'データを復元いたしました'}
      description={`保存する前にシーン${
        mode === 'creating' ? '作成' : '編集'
      }画面が\n閉じられたため、データを復元いたしました。`}
      onCloseModal={onRequestClose}
    />
  );
};

export default React.memo(RestoreModal);
