import * as React from 'react';

import Icon from './Icon';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const StarIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 12;
  const color = props.color || '#f5c71c';
  return <Icon name={'star'} size={size} color={color} />;
};

export default React.memo(StarIcon);
