import {Dispatch} from 'redux';

import Types from '../Types';

import NetEpisodeCommentBlockingUsersRepository from '../../../data/repositories/writer/NetEpisodeCommentBlockingUsersRepository';

export default function action(
  id: number,
): (dispatch: Dispatch<any>) => Promise<number> {
  return async (dispatch: Dispatch<any>): Promise<number> => {
    try {
      await new NetEpisodeCommentBlockingUsersRepository().destroy(id);
      const payload = id;
      dispatch({
        payload,
        type: Types.DestroyEpisodeCommentBlockingUserSuccess,
      });
      return payload;
    } catch (err) {
      dispatch({type: Types.DestroyEpisodeCommentBlockingUserFailure});
      throw err;
    }
  };
}
