import React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import ScaleModal from '../../../../modals/ScaleModal';
import MakerProIcon from '../../../../icons/MakerProIcon';
import DimensionContext from '../../../../dimension/DimensionContext';

import LinkButton from '../../../../buttons/LinkButton';
import PrimaryButton from '../../../../buttons/PrimaryButton';

interface Props {
  onRequestClose: () => void;
}

const MakerProModal: React.FC<Props> = props => {
  const linkTo = useLinkTo();
  const {onRequestClose} = props;
  const onPress = React.useCallback(() => {
    onRequestClose();
    linkTo(`/pro`);
  }, []);
  const onPressCancel = React.useCallback(() => {
    onRequestClose();
  }, []);
  return (
    <DimensionContext.Consumer>
      {context => (
        <ScaleModal
          style={{width: context.content.width * 0.82}}
          visible={true}>
          <View style={styles.container}>
            <Text style={styles.title}>
              こちらのキャラクターは{'\n'}
              MakerPro会員のみ利用できます。
            </Text>
            <MakerProIcon size={36} color={'#ff8f13'} />
            <Text style={styles.message}>
              シーンを登録してMakerPro{'\n'}
              詳細画面へ移動しますか？
            </Text>
            <View style={styles.buttons}>
              <LinkButton
                style={{width: 100, height: 46, marginRight: 8}}
                onPress={onPressCancel}>
                キャンセル
              </LinkButton>
              <PrimaryButton style={{width: 160, height: 46}} onPress={onPress}>
                移動する
              </PrimaryButton>
            </View>
          </View>
        </ScaleModal>
      )}
    </DimensionContext.Consumer>
  );
};

export default React.memo(MakerProModal);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    marginVertical: 32,
  } as ViewStyle,
  title: {
    color: '#ff8f13',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 24,
    textAlign: 'center',
  } as TextStyle,
  message: {
    color: '#222',
    fontSize: 14,
    textAlign: 'center',
    marginTop: 24,
    marginBottom: 32,
  } as TextStyle,
  buttons: {
    flexDirection: 'row',
  } as ViewStyle,
});
