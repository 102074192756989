import EntityMapper from './EntityMapper';

import ActorCategory from '../../../domain/entities/ActorCategory';

export interface ActorCategoryData {
  id: number;
  parent_id: number;
  name: string;
  leaf: boolean;
  actor_count: number;
}

export default class ActorCategoryMapper
  implements EntityMapper<ActorCategoryData, ActorCategory>
{
  public map(obj: ActorCategoryData): ActorCategory {
    return new ActorCategory(
      obj.id,
      obj.parent_id,
      obj.name,
      obj.leaf,
      obj.actor_count,
    );
  }
}
