import Entity from '../Entity';

export default class Grade implements Entity {
  constructor(
    public id: number,
    public code: string,
    public name: string,
    public color: string,
  ) {}
}
