import Entity from './Entity';

import Sound from './Sound';

export default class SoundUsageHistory implements Entity {
  constructor(
    public id: number,
    public storyId: number,
    public soundType: 'background_music' | 'sound_effect',
    public sound: Sound,
    public lastUsedAt: Date,
    public createdAt: Date,
  ) {}

  public get name() {
    return this.sound.name;
  }

  public get audioUrl() {
    return this.sound.audioUrl;
  }
}
