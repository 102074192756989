import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import FullScreenIllustrationMapper, {
  FullScreenIllustrationData,
} from '../../entities/mappers/FullScreenIllustrationMapper';

import FullScreenIllustration from '../../../domain/entities/FullScreenIllustration';
import FullScreenIllustrationsRepository from '../../../domain/repositories/writer/FullScreenIllustrationsRepository';

export default class NetIllustrationsRepository
  extends NetResourcesRepository<
    FullScreenIllustration,
    FullScreenIllustrationData
  >
  implements FullScreenIllustrationsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/full_screen_illustrations',
        new FullScreenIllustrationMapper(),
      ),
    );
  }
}
