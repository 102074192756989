import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import dateformat from 'dateformat';

import WriterUserImage from '../members/partials/WriterUserImage';

import ArrowForwardIcon from '../../shared/icons/ArrowForwardIcon';
import ClapActiveIcon from '../../shared/icons/project/ClapActiveIcon';
import CommentIcon from '../../shared/icons/project/CommentIcon';

import ProjectManuscript from '../../../../domain/entities/ProjectManuscript';

interface Props {
  manuscript: ProjectManuscript;
}

const ManuscriptListItem: React.FC<Props> = props => {
  const {manuscript} = props;
  const navigation = useNavigation<any>();
  const onPress = React.useCallback(() => {
    navigation.navigate('ProjectManuscript', {
      projectId: manuscript.projectId,
      id: manuscript.id,
    });
  }, []);
  return (
    <View style={styles.container}>
      <WriterUserImage user={manuscript.writerUser} imageSize={50} />
      <View style={styles.right}>
        <Text style={styles.title}>
          <Text style={styles.penName}>
            {manuscript.writerUser.penName}さん
          </Text>
          が{manuscript.typeName}を提出しました。
        </Text>
        <Text style={styles.submittedAt}>
          {dateformat(
            manuscript.submittedAt || manuscript.createdAt,
            'yyyy.mm.dd HH:MM',
          )}
        </Text>
        <TouchableOpacity style={styles.submission} onPress={onPress}>
          <Text style={styles.submissionText}>
            {manuscript.title}
            {'\n'}
            {manuscript.typeName}（
            {manuscript.manuscriptNumber == 1
              ? '初'
              : manuscript.manuscriptNumber}
            稿）
          </Text>
          <ArrowForwardIcon />
        </TouchableOpacity>
        <View style={styles.info}>
          <View style={styles.infoRow}>
            <ClapActiveIcon color={'#ff8f13'} size={10} />
            <Text style={styles.infoRowText}>{manuscript.likesCount}</Text>
          </View>
          <View style={styles.infoRow}>
            <CommentIcon color={'#ff8f13'} size={10} />
            <Text style={styles.infoRowText}>{manuscript.commentsCount}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default React.memo(ManuscriptListItem);

const styles = StyleSheet.create({
  container: {
    marginVertical: 8,
    marginHorizontal: 16,
    flexDirection: 'row',
  } as ViewStyle,
  right: {
    marginTop: 4,
    marginBottom: 8,
    marginLeft: 10,
    flex: 1,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 12,
    marginBottom: 8,
  } as TextStyle,
  penName: {
    fontWeight: 'bold',
  } as TextStyle,
  submittedAt: {
    color: '#999',
    fontSize: 10,
  } as TextStyle,
  submission: {
    borderWidth: 1,
    borderColor: '#999',
    borderRadius: 3,
    marginVertical: 8,
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  } as ViewStyle,
  submissionText: {
    color: '#222',
    fontSize: 12,
    paddingRight: 16,
  } as TextStyle,
  info: {
    flexDirection: 'row',
  } as ViewStyle,
  infoRow: {
    flexDirection: 'row',
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  infoRowText: {
    color: '#999',
    fontSize: 10,
    marginLeft: 5,
  } as TextStyle,
});
