import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import SelectWithLabel from './SelectWithLabel';

interface Props {
  selectedTime: Date;
  onTimePress: (time: Date) => void;
}

export default class TimeSelect extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {selectedTime} = this.props;
    return (
      <View style={styles.container}>
        <SelectWithLabel
          items={HOUR_ITEMS}
          value={selectedTime.getHours()}
          label={'時'}
          onValueChange={this.handleHourValueChange}
        />
        <SelectWithLabel
          items={MINUTE_ITEMS}
          value={selectedTime.getMinutes()}
          label={'分'}
          onValueChange={this.handleMinuteValueChange}
        />
      </View>
    );
  }

  private handleHourValueChange = (value: string | number) => {
    const {selectedTime, onTimePress} = this.props;
    const date = selectedTime;
    date.setHours(Number(value));
    onTimePress(date);
  };

  private handleMinuteValueChange = (value: string | number) => {
    const {selectedTime, onTimePress} = this.props;
    const date = selectedTime;
    date.setMinutes(Number(value));
    onTimePress(date);
  };
}

function zeroPadding(n: number, length: number): string {
  return `0000000000${n}`.slice(-length);
}

const HOUR_ITEMS = [...Array(24).keys()].map(i => {
  return {value: i, label: zeroPadding(i, 2)};
});

const MINUTE_ITEMS = [...Array(60).keys()].map(i => {
  return {value: i, label: zeroPadding(i, 2)};
});

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
  } as ViewStyle,
});
