import * as React from 'react';
import {InteractionManager, ScrollView, View} from 'react-native';

import BackgroundCategorySelect from './partials/BackgroundCategorySelect';
import SearchInput from './partials/SearchInput';
import SearchButtonWithTotalCount from './partials/SearchButtonWithTotalCount';
import LabelWithOption from '../../../../shared/forms/LabelWithOption';

import PopularTagsBox from '../../../PopularTagsBox';
import Layout from '../../../../shared/Layout';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormRouteProp,
  CoverImageFormBaseBackgroundSearchFormRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as BackgroundCategoryIndexParams} from '../../../../../actions/background_categories/index';
import {Params as BaseBackgroundIndexParams} from '../../../../../actions/base_backgrounds/index';

import BaseBackground from '../../../../../../domain/entities/BaseBackground';
import BackgroundCategory from '../../../../../../domain/entities/BackgroundCategory';
import PopularTag from '../../../../../../domain/entities/PopularTag';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';

import NetPopularTagsRepository from '../../../../../../data/repositories/writer/NetPopularTagsRepository';

export interface Props {
  navigation: NavigationProp;
  route:
    | SceneFormBackgroundShowSceneCommandNewBaseBackgroundSearchFormRouteProp
    | CoverImageFormBaseBackgroundSearchFormRouteProp;
  largeBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  largeBackgroundCategories: BackgroundCategory[] | null;
  middleBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  middleBackgroundCategories: BackgroundCategory[] | null;
  smallBackgroundCategoriesParams: BackgroundCategoryIndexParams;
  smallBackgroundCategories: BackgroundCategory[] | null;
  indexBackgroundCategories: (
    params: BackgroundCategoryIndexParams,
  ) => Promise<PaginatedResult<BackgroundCategory>>;
  indexBaseBackgrounds: (
    params: BaseBackgroundIndexParams,
  ) => Promise<PaginatedResult<BaseBackground>>;
  onSubmit: (params: {
    query?: string;
    backgroundCategoryId?: number;
    selectedLargeBackgroundCategoryId?: number | null;
    selectedMiddleBackgroundCategoryId?: number | null;
    selectedSmallBackgroundCategoryId?: number | null;
  }) => void;
}

interface State {
  popularTags?: Array<PopularTag> | null;
  totalCount: number;
  query: string;
}

export default class Index extends React.Component<Props, State> {
  private timerId: any | null = null;

  constructor(props: Props) {
    super(props);
    const {query} = props.route.params;
    this.state = {
      totalCount: 0,
      query: query || '',
    };
  }

  public componentDidMount() {
    const {
      largeBackgroundCategories,
      largeBackgroundCategoriesParams,
      indexBackgroundCategories,
    } = this.props;
    InteractionManager.runAfterInteractions(() => {
      new NetPopularTagsRepository()
        .findBy({taggableType: 'BaseBackground'})
        .then(result => {
          this.setState({popularTags: result.records});
        });
      if (!largeBackgroundCategories) {
        indexBackgroundCategories(largeBackgroundCategoriesParams);
      }
      this.fetchTotalCount();
    });
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    const {
      route,
      indexBackgroundCategories,
      middleBackgroundCategoriesParams,
      smallBackgroundCategoriesParams,
    } = this.props;
    const {
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId,
    } = route.params;
    const {
      selectedLargeBackgroundCategoryId: prevSelectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId:
        prevSelectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId: prevSelectedSmallBackgroundCategoryId,
    } = prevProps.route.params;
    let updated = false;
    if (
      selectedLargeBackgroundCategoryId !==
      prevSelectedLargeBackgroundCategoryId
    ) {
      indexBackgroundCategories(middleBackgroundCategoriesParams);
      updated = true;
    }
    if (
      selectedMiddleBackgroundCategoryId !==
      prevSelectedMiddleBackgroundCategoryId
    ) {
      indexBackgroundCategories(smallBackgroundCategoriesParams);
      updated = true;
    }
    if (
      selectedSmallBackgroundCategoryId !==
      prevSelectedSmallBackgroundCategoryId
    ) {
      updated = true;
    }
    if (updated) {
      this.fetchTotalCount();
    }
  }

  public render(): React.ReactNode {
    const {
      navigation,
      route,
      largeBackgroundCategories,
      middleBackgroundCategories,
      smallBackgroundCategories,
    } = this.props;
    const {query, totalCount, popularTags} = this.state;
    const {
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId,
      hideBack,
    } = route.params;
    return (
      <Layout
        title={'背景検索条件'}
        back={hideBack ? false : true}
        close={true}
        navigation={navigation}
        scrollable={false}>
        <ScrollView>
          {largeBackgroundCategories && popularTags && (
            <>
              <SearchInput query={query} onChangeText={this.handleChangeText} />
              <PopularTagsBox
                popularTags={popularTags}
                onSelectPopularTag={this.handleSelectPopularTag}
              />
              <View style={{marginHorizontal: 16, marginTop: 16}}>
                <LabelWithOption title={'カテゴリ'} />
              </View>
              <BackgroundCategorySelect
                value={selectedLargeBackgroundCategoryId || 0}
                backgroundCategories={largeBackgroundCategories}
                onValueChange={
                  this.handleValueChangeForLargeBackgroundCategoryId
                }
              />
              <BackgroundCategorySelect
                value={selectedMiddleBackgroundCategoryId || 0}
                backgroundCategories={middleBackgroundCategories}
                onValueChange={
                  this.handleValueChangeForMiddleBackgroundCategoryId
                }
              />
              <BackgroundCategorySelect
                value={selectedSmallBackgroundCategoryId || 0}
                backgroundCategories={smallBackgroundCategories}
                onValueChange={
                  this.handleValueChangeForSmallBackgroundCategoryId
                }
              />
            </>
          )}
        </ScrollView>
        <SearchButtonWithTotalCount
          totalCount={totalCount}
          onPress={this.handleSubmit}
        />
      </Layout>
    );
  }

  private handleChangeText = (query: string) => {
    this.setStateAndFetchTotalCount({query});
  };

  private handleValueChangeForLargeBackgroundCategoryId = (
    selectedLargeBackgroundCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId: null,
      selectedSmallBackgroundCategoryId: null,
    });
  };

  private handleValueChangeForMiddleBackgroundCategoryId = (
    selectedMiddleBackgroundCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId: null,
    });
  };

  private handleValueChangeForSmallBackgroundCategoryId = (
    selectedSmallBackgroundCategoryId: number | string,
  ) => {
    const {navigation} = this.props;
    navigation.setParams({selectedSmallBackgroundCategoryId});
  };

  private setStateAndFetchTotalCount<K extends keyof State>(
    state: Pick<State, K>,
  ): void {
    this.setState(state, () => {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.fetchTotalCount();
      }, 250);
    });
  }

  private fetchTotalCount = () => {
    const {indexBaseBackgrounds} = this.props;
    indexBaseBackgrounds({...this.buildSearchParams(), perPage: 1}).then(
      searchResult => {
        this.setState({totalCount: searchResult.total});
      },
    );
  };

  private buildSearchParams = () => {
    const {route} = this.props;
    const {query} = this.state;
    const {
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId,
      favorite,
      storyId,
    } = route.params;
    return {
      query,
      favorite,
      storyId,
      allBackgroundCategoryId:
        this.covertBackgroundCategoryId(selectedSmallBackgroundCategoryId) ||
        this.covertBackgroundCategoryId(selectedMiddleBackgroundCategoryId) ||
        this.covertBackgroundCategoryId(selectedLargeBackgroundCategoryId) ||
        undefined,
    };
  };

  private handleSelectPopularTag = (popularTag: PopularTag) => {
    const {onSubmit} = this.props;
    onSubmit({query: popularTag.tagName});
  };

  private handleSubmit = () => {
    const {route, onSubmit} = this.props;
    const {
      selectedLargeBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedSmallBackgroundCategoryId,
    } = route.params;
    const {query} = this.state;
    onSubmit({
      query,
      backgroundCategoryId:
        this.covertBackgroundCategoryId(selectedSmallBackgroundCategoryId) ||
        this.covertBackgroundCategoryId(selectedMiddleBackgroundCategoryId) ||
        this.covertBackgroundCategoryId(selectedLargeBackgroundCategoryId) ||
        undefined,
      selectedSmallBackgroundCategoryId,
      selectedMiddleBackgroundCategoryId,
      selectedLargeBackgroundCategoryId,
    });
  };

  private covertBackgroundCategoryId = (
    backgroundCategoryId: number | null | undefined,
  ) => {
    if (backgroundCategoryId && Number(backgroundCategoryId) != 0) {
      return Number(backgroundCategoryId);
    }
    return undefined;
  };
}
