import * as React from 'react';

import ManualList from './partials/ManualList';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';
import {ManualRouteProp} from '../../navigators/RouteProps';

import {Params as ManualIndexParams} from '../../actions/writer/manuals';

import Manual from '../../../domain/entities/writer/Manual';
import ManualCategory from '../../../domain/entities/writer/ManualCategory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export interface Params {
  writerManualCategoryId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ManualRouteProp;
  manualCategory: ManualCategory | null;
  manuals: Manual[] | null;
  manualsParams: ManualIndexParams;
}

export interface DispatchProps {
  showManualCategory: (id: number) => Promise<ManualCategory>;
  indexManuals: (params: ManualIndexParams) => Promise<PaginatedResult<Manual>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  manualIdToCollapsed: {[key: string]: boolean};
}

export default class Show extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      manualIdToCollapsed: {},
    };
  }

  public componentDidMount() {
    const {
      route,
      manualCategory,
      manuals,
      showManualCategory,
      indexManuals,
      manualsParams,
    } = this.props;
    const {writerManualCategoryId} = route.params;
    if (!manualCategory) {
      showManualCategory(writerManualCategoryId);
    }
    if (!manuals) {
      indexManuals(manualsParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, manualCategory, manuals} = this.props;
    const {manualIdToCollapsed} = this.state;
    return (
      <Layout
        title={manualCategory ? manualCategory.name : ''}
        navigation={navigation}
        scrollable={false}
        back={true}>
        {manuals && (
          <ManualList
            manuals={manuals}
            manualIdToCollapsed={manualIdToCollapsed}
            onPressManual={this.handlePressManual}
          />
        )}
      </Layout>
    );
  }

  private handlePressManual = (manual: Manual) => {
    const {manualIdToCollapsed} = this.state;
    const collapsed =
      manualIdToCollapsed[manual.id] === undefined
        ? true
        : manualIdToCollapsed[manual.id];
    this.setState({
      manualIdToCollapsed: {
        ...manualIdToCollapsed,
        [manual.id]: !collapsed,
      },
    });
  };
}
