import * as React from 'react';
import {
  ImageStyle,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';
import numeral from 'numeral';
import FastImageAdapter from '../../../shared/fast_image/FastImageAdapter';
import ArrowForwardIcon from '../../../shared/icons/ArrowForwardIcon';

import StoryCoverImageDecorator from '../../../../view_models/StoryCoverImageDecorator';

import {colors} from '../../../../styles/variables';
import {storyCoverImageUrl} from '../../../../helpers/images';

import StoryAggregation from '../../../../../domain/value_objects/partner_program/StoryAggregation';

interface Props {
  storyAggregation: StoryAggregation;
}

const StoryAggregationListItem: React.FunctionComponent<Props> = props => {
  const {storyAggregation} = props;
  const linkTo = useLinkTo();
  const source = React.useMemo(() => {
    return {
      uri: storyCoverImageUrl(
        new StoryCoverImageDecorator(storyAggregation),
        'small',
      ),
      headers: {Accept: 'image/webp,image/apng,*/*'},
    };
  }, []);
  const onPress = React.useCallback(() => {
    linkTo(`/partner_program/stories/${storyAggregation.id}`);
  }, []);
  return (
    <Pressable style={styles.container} onPress={onPress}>
      {storyAggregation.hasCoverImage ? (
        <FastImageAdapter style={styles.image} source={source} />
      ) : (
        <View style={styles.image}>
          <Text style={styles.noImageText}>表紙なし</Text>
        </View>
      )}
      <View style={styles.storyInfo}>
        <Text style={styles.title}>{storyAggregation.title}</Text>
        <View style={[styles.row, {marginBottom: 6}]}>
          <Text style={styles.rowLabel}>有効閲覧数</Text>
          <Text style={styles.rowValue}>
            {numeral(storyAggregation.validViewsCount).format('0,0')}
          </Text>
        </View>
        <View style={styles.row}>
          <Text style={styles.rowLabel}>ギフト</Text>
          <Text style={styles.rowValue}>
            {numeral(storyAggregation.tipsAmount).format('0,0')}
          </Text>
        </View>
        <View style={styles.line} />
        <View style={styles.row}>
          <Text style={styles.rowLabel}>獲得ポイント</Text>
          <Text style={styles.rowValue}>
            {numeral(storyAggregation.totalPoint).format('0,0.0')} pt
          </Text>
        </View>
      </View>
      <View style={styles.arrow}>
        <ArrowForwardIcon />
      </View>
    </Pressable>
  );
};

export default React.memo(StoryAggregationListItem);

const ASPECT_RATIO = 0.7;
const width = 70;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginVertical: 10,
    marginHorizontal: 16,
    backgroundColor: 'white',
    alignItems: 'center',
  } as ViewStyle,
  image: {
    alignItems: 'center',
    alignSelf: 'stretch',
    aspectRatio: ASPECT_RATIO,
    backgroundColor: colors.powderGray,
    justifyContent: 'center',
    width,
    height: width / ASPECT_RATIO,
  } as ImageStyle,
  noImageText: {
    color: colors.lightGray,
    fontSize: 10,
  } as TextStyle,
  storyInfo: {
    flex: 1,
    marginHorizontal: 10,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  } as TextStyle,
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  } as ViewStyle,
  rowLabel: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  rowValue: {
    color: '#ff8f13',
    fontSize: 11,
  } as TextStyle,
  line: {
    borderWidth: 1,
    borderColor: '#f5f5f5',
    marginVertical: 8,
  } as ViewStyle,
  arrow: {
    marginLeft: 30,
  } as ViewStyle,
});
