import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import TextFrame from '../../entities/TextFrame';
import Voice from '../../entities/Voice';
import Sound from '../../entities/Sound';

const CLASS_NAME: SceneCommandFormClass = 'DescriptiveTextShowSceneCommandForm';

export default class DescriptiveTextShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  public text: string;
  constructor(
    public textFrame: TextFrame,
    text: string,
    public voice: Voice | null,
    public sound: Sound | null,
    public startTime: number | null,
    public endTime: number | null,
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
    this.text = text.trim();
  }

  public toParams(): {[key: string]: any} {
    return {
      text: this.text,
      text_frame_id: this.textFrame.id,
      voice_id: this.voice ? this.voice.id : null,
      sound_id: this.sound ? this.sound.id : null,
      start_time: this.startTime,
      end_time: this.endTime,
      type: 'SceneCommands::DescriptiveTextShowSceneCommand',
    };
  }

  public copy(sceneCommandId?: number): DescriptiveTextShowSceneCommandForm {
    return new DescriptiveTextShowSceneCommandForm(
      this.textFrame,
      this.text,
      this.voice,
      this.sound,
      this.startTime,
      this.endTime,
      sceneCommandId,
    );
  }
}
