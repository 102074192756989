import * as React from 'react';
import {Image, ImageStyle, StyleProp, View} from 'react-native';

import SelectableLabeledImageGridList from '../../../../../shared/SelectableLabeledImageGridList';

import Effect from '../../../../../../../domain/entities/Effect';

interface Props {
  uri?: string | null;
  effects: Array<Effect>;
  selectedEffect: Effect | null;
  onSelectEffect: (effect: Effect) => void;
}

export default class BackgroundEffectList extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {uri, effects, selectedEffect, onSelectEffect} = this.props;
    return (
      <SelectableLabeledImageGridList
        entities={effects}
        aspectRatio={aspectRatio}
        selectedEntity={selectedEffect}
        extraData={uri}
        onSelectEntity={onSelectEffect}
        renderLabel={this.renderLabel}
        keyExtractor={this.keyExtractor}
        isActive={this.isActive}
        renderImage={this.renderImage}
      />
    );
  }

  private renderLabel = (effect: Effect) => {
    return effect.name;
  };

  private keyExtractor = (effect: Effect) => {
    return `${effect.id}`;
  };

  private isActive = (effect: Effect, selectedEffect: Effect | null) => {
    return effect === selectedEffect;
  };

  private renderImage = (
    effect: Effect,
    width: number,
    imageStyle: StyleProp<ImageStyle>,
  ) => {
    const {uri} = this.props;
    const height = width / aspectRatio;
    return (
      <View style={imageStyle}>
        {uri ? (
          <Image
            style={{width, height}}
            source={{uri: uri}}
            resizeMode={'contain'}
          />
        ) : (
          <View style={{width, height, backgroundColor: 'black'}} />
        )}
        <Image
          style={{width, height, position: 'absolute'}}
          source={{uri: effect.originalImageUrl}}
          resizeMode={'contain'}
        />
      </View>
    );
  };
}

const aspectRatio = 1.25;
