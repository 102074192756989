import Entity from './Entity';

export default class ActorCategory implements Entity {
  public static ATTRIBUTE_ID = 1;

  public static HUMAN_ID = 2;
  public static ANIMAL_ID = 3;
  public static OTHER_ID = 4;

  public static HUMAN_AGE_ID = 5;
  public static HUMAN_GENDER_ID = 13;
  public static HUMAN_BODY_SHAPE_ID = 16;
  public static HUMAN_BODY_HEIGHT_ID = 20;
  public static HUMAN_HAIR_COLOR_ID = 24;
  public static HUMAN_FACIAL_FEATURE_ID = 70;

  public static ANIMAL_CLASSIFICATION_ID = 40;

  public static OTHER_CLASSIFICATION_ID = 49;
  public static OTHER_FORM_ID = 60;
  public static OTHER_SIZE_ID = 64;

  public static HUMAN_GENDER_MALE_ID = 14;
  public static HUMAN_GENDER_FEMALE_ID = 15;

  public static HUMAN_FACIAL_FEATURE_MALE_ID = 71;
  public static HUMAN_FACIAL_FEATURE_FEMALE_ID = 102;

  public static HUMAN_ROOT_CATEGORY_IDS = [
    ActorCategory.HUMAN_AGE_ID,
    ActorCategory.HUMAN_GENDER_ID,
    ActorCategory.HUMAN_BODY_SHAPE_ID,
    ActorCategory.HUMAN_BODY_HEIGHT_ID,
    ActorCategory.HUMAN_HAIR_COLOR_ID,
    ActorCategory.HUMAN_FACIAL_FEATURE_ID,
  ];

  public static ANIMAL_ROOT_CATEGORY_IDS = [
    ActorCategory.ANIMAL_CLASSIFICATION_ID,
  ];

  public static OTHER_ROOT_CATEGORY_IDS = [
    ActorCategory.OTHER_CLASSIFICATION_ID,
    ActorCategory.OTHER_FORM_ID,
    ActorCategory.OTHER_SIZE_ID,
  ];

  constructor(
    public id: number,
    public parentId: number | null,
    public name: string,
    public leaf: boolean,
    public actorCount: number,
  ) {}

  public getShortName = () => {
    return this.name.replace(/\(.+\)$/, '');
  };

  public getAttributeId = () => {
    if (this.isHuman()) {
      return ActorCategory.HUMAN_ID;
    } else if (this.isAnimal()) {
      return ActorCategory.ANIMAL_ID;
    } else if (this.isOther()) {
      return ActorCategory.OTHER_ID;
    }
  };

  public isAttribute = () => {
    return (
      this.id === ActorCategory.ATTRIBUTE_ID ||
      this.parentId === ActorCategory.ATTRIBUTE_ID
    );
  };

  public isHuman = () => {
    return this.rootCategoryIdsIncludesIdOrParentId(
      ActorCategory.HUMAN_ROOT_CATEGORY_IDS,
    );
  };

  public isAnimal = () => {
    return this.rootCategoryIdsIncludesIdOrParentId(
      ActorCategory.ANIMAL_ROOT_CATEGORY_IDS,
    );
  };

  public isOther = () => {
    return this.rootCategoryIdsIncludesIdOrParentId(
      ActorCategory.OTHER_ROOT_CATEGORY_IDS,
    );
  };

  public isHumanAge = () => {
    return (
      this.id === ActorCategory.HUMAN_AGE_ID ||
      this.parentId === ActorCategory.HUMAN_AGE_ID
    );
  };

  public isHumanGender = () => {
    return (
      this.id === ActorCategory.HUMAN_GENDER_ID ||
      this.parentId === ActorCategory.HUMAN_GENDER_ID
    );
  };

  public isHumanFacialFeature = () => {
    return (
      this.id === ActorCategory.HUMAN_FACIAL_FEATURE_ID ||
      this.parentId === ActorCategory.HUMAN_FACIAL_FEATURE_ID
    );
  };

  public isHumanHairColor = () => {
    return (
      this.id === ActorCategory.HUMAN_HAIR_COLOR_ID ||
      this.parentId === ActorCategory.HUMAN_HAIR_COLOR_ID
    );
  };

  private rootCategoryIdsIncludesIdOrParentId = (rootCategoryIds: number[]) => {
    return rootCategoryIds.some(rootId => {
      return rootId === this.id || rootId === this.parentId;
    });
  };
}
