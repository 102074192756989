import * as React from 'react';
import {Platform, StyleSheet, View, ViewStyle} from 'react-native';

import ElasticTextFrame from '../../../../../shared/ElasticTextFrame';
import DimensionContext from '../../../../../shared/dimension/DimensionContext';

import TextFrame from '../../../../../../../domain/entities/TextFrame';

interface Props {
  textFrame: TextFrame;
  text: string;
  hasVoice?: boolean;
  hasSound?: boolean;
  children?: React.ReactNode;
}

const DescriptiveTextView: React.FunctionComponent<Props> = props => {
  const {textFrame, text, hasVoice, hasSound} = props;
  return (
    <View style={styles.container}>
      <DimensionContext.Consumer>
        {context => (
          <ElasticTextFrame
            textFrame={textFrame}
            text={text}
            width={context.content.width}
            hasVoice={hasVoice}
            hasSound={hasSound}
          />
        )}
      </DimensionContext.Consumer>
    </View>
  );
};

export default React.memo(DescriptiveTextView);

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    height: '100%',
    marginVertical: 5,
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as ViewStyle,
});
