import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import Effect from '../../entities/Effect';
import Sound from '../../entities/Sound';

import Position, {PositionValueMapper} from '../../value_objects/Position';

const CLASS_NAME: SceneCommandFormClass = 'EffectShowSceneCommandForm';

export default class EffectShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(
    public effect: Effect,
    public position: Position,
    public startIndex?: number | null,
    public endIndex?: number | null,
    public sound?: Sound | null,
    public startTime?: number | null,
    public endTime?: number | null,
    sceneCommandId?: number,
  ) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      effect_id: this.effect.id,
      position: PositionValueMapper.map(this.position),
      start_index: this.startIndex,
      end_index: this.endIndex,
      sound_id: this.sound?.id,
      start_time: this.startTime,
      end_time: this.endTime,
      type: 'SceneCommands::EffectShowSceneCommand',
    };
  }

  public copy(sceneCommandId?: number): EffectShowSceneCommandForm {
    return new EffectShowSceneCommandForm(
      this.effect,
      this.position,
      this.startIndex,
      this.endIndex,
      this.sound,
      this.startTime,
      this.endTime,
      sceneCommandId,
    );
  }
}
