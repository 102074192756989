import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import PrimaryButton from '../../../../shared/buttons/PrimaryButton';
import CheckButton from '../../../../shared/forms/CheckButton';
import AlertIcon from '../../../../shared/icons/AlertIcon';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep4: React.FC<Props> = props => {
  const {onPress} = props;
  const linkTo = useLinkTo();
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  const onPressTerms = React.useCallback(() => {
    linkTo('/writers_gate/policies/terms?modal=true');
  }, [checked]);
  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.alert}>
          <AlertIcon size={32} color={'#ff8f13'} />
          <Text style={styles.alertText}>注意</Text>
        </View>
        <Text style={styles.message}>
          ライターズゲートのオファーは、一般公開前の情報です。
        </Text>
        <Text style={styles.message}>
          掲載されている企業名や作品名等、オファーに関する内容は口外禁止です。
        </Text>
        <Text style={styles.message}>
          万一、情報の漏洩が発覚した場合は𠮟るべき処分の{'\n'}
          対象となりますのでご注意ください。
        </Text>
        <CheckButton
          style={styles.checkButton}
          checked={checked}
          onPress={onPressCheck}>
          注意事項を理解した
        </CheckButton>
        <TouchableOpacity style={styles.agreement} onPress={onPressTerms}>
          <Text style={styles.agreementLink}>ライターズゲート約款</Text>
          <Text style={styles.agreementText}>を確認の上、ご同意ください</Text>
        </TouchableOpacity>
        <PrimaryButton disabled={!checked} onPress={onPress}>
          約款に同意してオファーを探す
        </PrimaryButton>
      </View>
    </ScrollView>
  );
};

export default React.memo(ServiceExplanationStep4);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 32,
  } as ViewStyle,
  alert: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 32,
  } as ViewStyle,
  alertText: {
    color: '#ff8f13',
    fontSize: 18,
    fontWeight: 'bold',
    marginLeft: 4,
  } as TextStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginHorizontal: 24,
    marginBottom: 8,
    textAlign: 'center',
  } as TextStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginTop: 24,
    marginBottom: 16,
  } as ViewStyle,
  agreement: {
    flexDirection: 'row',
    marginTop: 8,
    marginBottom: 16,
  } as ViewStyle,
  agreementLink: {
    color: '#ff8f13',
    fontSize: 10,
    textDecorationLine: 'underline',
    marginLeft: 8,
  } as TextStyle,
  agreementText: {
    color: '#222',
    fontSize: 10,
  } as TextStyle,
});
