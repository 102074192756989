import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import ImageGridListItem, {Entity} from './ImageGridListItem';

import DragScrollView from '../../../../../shared/drag_scroll_view';

interface Props<E extends Entity> {
  entities: E[];
  selectedEntity: E;
  numColumns?: number;
  visible: boolean;
  onSelectEntity: (entity: E) => void;
}

export default class ImageGridList<
  E extends Entity,
> extends React.PureComponent<Props<E>> {
  public render(): React.ReactNode {
    const {entities, selectedEntity, numColumns, visible, onSelectEntity} =
      this.props;
    return (
      <DragScrollView
        style={visible ? null : styles.hide}
        horizontal={true}
        bounces={false}
        showsHorizontalScrollIndicator={false}>
        <View style={styles.container}>
          {this.chunk(entities, numColumns || DEFAULT_NUM_COLUMNS).map(
            (chunkedEntities, i) => {
              return (
                <View key={i} style={styles.column}>
                  {chunkedEntities.map(entity => (
                    <ImageGridListItem
                      key={entity.id}
                      entity={entity}
                      checked={selectedEntity.id === entity.id}
                      onSelectEntity={onSelectEntity}
                    />
                  ))}
                </View>
              );
            },
          )}
        </View>
      </DragScrollView>
    );
  }

  private chunk(arr: E[], size: number) {
    return arr.reduce(
      (newarr, _, i) =>
        i % size ? newarr : [...newarr, arr.slice(i, i + size)],
      [] as E[][],
    );
  }
}

const DEFAULT_NUM_COLUMNS = 2;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    marginTop: 5,
    overflow: 'hidden',
    paddingHorizontal: 16,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  column: {
    height: '100%',
    width: 94,
    marginHorizontal: 2,
  } as ViewStyle,
  hide: {
    display: 'none',
  } as ViewStyle,
});
