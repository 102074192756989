import React from 'react';

import RootStack from '../../../../RootStack';

import SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm from '../../../../../containers/scene_form/background_music_show_scene_command/edit/sound_search_form/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
      }}
      initialRouteName="SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm">
      <RootStack.Screen
        name="SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm"
        component={SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm}
        options={{title: 'BGM検索条件'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
