import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import CharacterPatternMapping from '../../view_models/CharacterPatternMapping';

import SceneCommandForm from '../../../domain/forms/scene_commands/SceneCommandForm';

export interface Params {
  sceneCommandForm: SceneCommandForm;
  index: number | undefined;
  characterPatternMapping?: CharacterPatternMapping;
}

export default function action(params: Params) {
  return (dispatch: Dispatch<any>, getState: () => AppState) => {
    const payload = params;
    const {modals} = getState();
    const {coachmarkModal} = modals;
    if (coachmarkModal === 'first_episode_form_background_done') {
      dispatch({payload: null, type: Types.UpdateCoachmarkModal});
    }
    dispatch({
      payload,
      type: Types.CreateSceneCommandForm,
    });
    return new Promise(resolve => resolve(payload));
  };
}
