import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import Episode from '../../../../domain/entities/response/Episode';

export default function reducer(
  state: {[key: number]: Episode} = {},
  action: AnyAction,
): {[key: number]: Episode} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexResponseEpisodesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: Episode}, record: Episode) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.ShowResponseEpisodeSuccess:
    case Types.UpdateResponseEpisodeSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    default:
      return state;
  }
}
