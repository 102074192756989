import * as React from 'react';
import {Image, Text, View} from 'react-native';

import Layout from '../shared/Layout';

import ShareButtonsWithCopy from '../shared/share/ShareButtonsWithCopy';

import NavigationProp from '../../navigators/NavigationProp';
import {StoryGenreRankedAchievementRouteProp} from '../../navigators/RouteProps';

import convertImageSource from '../../helpers/convertImageSource';

import TapNovelRestApi from '../../../data/data_stores/net/TapNovelRestApi';

import GreatUri from '../../../assets/images/story_genre_ranked_achievements/great.png';

interface StoryGenreRankedAchievement {
  id: number;
  ogp_image_url: string;
  story_id: number;
  story_title: string;
  story_url: string;
  genre_id: number;
  genre_name: string;
  rank: number;
  updated_at: string;
  created_at: string;
}

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: StoryGenreRankedAchievementRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

interface State {
  storyTapCountAchievement: StoryGenreRankedAchievement | null;
}

export default class Show extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      storyTapCountAchievement: null,
    };
  }

  public componentDidMount() {
    const {navigation, route} = this.props;
    const {id} = route.params;
    TapNovelRestApi.get<StoryGenreRankedAchievement>(
      `/api/writer/story_genre_ranked_achievements/${id}`,
    )
      .then(response => {
        this.setState({storyTapCountAchievement: response.body});
      })
      .catch(e => {
        if (e.status === 404) {
          navigation.goBack();
        }
      });
  }

  public render(): React.ReactNode {
    const {navigation} = this.props;
    const {storyTapCountAchievement} = this.state;
    return (
      <Layout
        title={'通知'}
        navigation={navigation}
        scrollable={false}
        back={true}>
        {storyTapCountAchievement && (
          <View style={{flex: 1, alignItems: 'center', paddingVertical: 25}}>
            <Image
              source={convertImageSource(GreatUri)}
              style={{width: 343, height: 80}}
            />
            <Text
              style={{
                color: '#c99f0e',
                fontSize: 15,
                fontWeight: 'bold',
                textAlign: 'center',
                marginBottom: 25,
              }}>
              おめでとうございます！{'\n'}
              投稿作品が週間ランキングに入りました。
            </Text>
            <Image
              source={{uri: storyTapCountAchievement.ogp_image_url}}
              style={{
                width: 343,
                height: 180,
                marginBottom: 32,
              }}
            />
            <Text style={{color: '#222', fontSize: 15, marginBottom: 25}}>
              今後も素晴らしい作品をお待ちしております！
            </Text>
            <Text
              style={{
                color: '#222',
                fontSize: 15,
                fontWeight: 'bold',
                textAlign: 'center',
              }}>
              この画像をシェアして、{'\n'}
              もっとたくさんの人に読んでもらおう！
            </Text>
            <View style={{marginTop: 20}}>
              <ShareButtonsWithCopy
                title={storyTapCountAchievement.story_title}
                message={`『${storyTapCountAchievement.story_title}』が${storyTapCountAchievement.genre_name}ジャンルで週間ランキング${storyTapCountAchievement.rank}位になりました。`}
                url={`${storyTapCountAchievement.story_url}?ogp_type=genre_ranked_achievement&ogp_genre_id=${storyTapCountAchievement.genre_id}&ogp_rank=${storyTapCountAchievement.rank}`}
              />
            </View>
          </View>
        )}
      </Layout>
    );
  }
}
