import * as React from 'react';
import {ScrollView, StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import TapNovelRawApi from '../../../../../data/data_stores/net/TapNovelRawApi';
import {isWindows} from '../../../../../data/data_stores/net/UserAgent';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import MultiSwitch from '../../../shared/forms/MultiSwitch';
import Select from '../../../shared/forms/Select';
import LabelWithOption from '../../../shared/forms/LabelWithOption';
import TooltipModal from '../../../shared/modals/TooltipModal';
import KeyboardSpacer from '../../../shared/KeyboardSpacer';
import AlertModal from '../../../shared/modals/AlertModal';

import downloadFile from '../../../../helpers/downloadFile';

import {colors, formStyles} from '../../../../styles/variables';

type TooltipModalType = 'type';

const TypeToTooltipInfo = {
  type: {
    title: 'データの種類',
    description:
      'ローデータは演出も含むすべての情報が含まれます。\n台本はシーン名とキャラクターごとのセリフ、テキスト文章のみとなります。',
  },
} as {
  [key in TooltipModalType]: {
    title: string;
    description: string;
  };
};

export type Fields = 'introduction' | 'catchPhrase';

interface Props {
  episodeId: number;
}

interface State {
  type: 'raw_data' | 'script';
  format: 'text' | 'csv';
  encoding: 'utf8' | 'sjis';
  tooltipModalType: TooltipModalType | null;
  visibleCompleteModal: boolean;
  alertMessage?: string;
}

export default class Form extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      type: 'raw_data',
      format: 'text',
      encoding: isWindows ? 'sjis' : 'utf8',
      tooltipModalType: null,
      visibleCompleteModal: false,
    };
  }

  public render(): React.ReactNode {
    const {
      type,
      format,
      encoding,
      tooltipModalType,
      visibleCompleteModal,
      alertMessage,
    } = this.state;
    const modalInfo = tooltipModalType
      ? TypeToTooltipInfo[tooltipModalType]
      : null;
    return (
      <View style={styles.container}>
        <ScrollView contentContainerStyle={{paddingBottom: 130}}>
          <View style={formStyles.formGroup}>
            <LabelWithOption
              title={'データの種類'}
              onPressQuestion={this.handlePressQuestionType}
            />
            <MultiSwitch
              items={TYPE_ITEMS}
              value={type}
              onSelectItem={this.handleChangeType}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption title={'データの形式'} />
            <MultiSwitch
              disabled={type === 'script'}
              items={FORMAT_ITEMS}
              value={format}
              onSelectItem={this.handleChangeFormat}
            />
          </View>
          <View style={formStyles.formGroup}>
            <LabelWithOption title={'文字コード'} />
            <Select
              items={ENCODING_ITEMS}
              value={encoding}
              onValueChange={this.handleChangeEncoding}
            />
          </View>

          {modalInfo ? (
            <TooltipModal
              visible={true}
              title={modalInfo.title}
              description={modalInfo.description}
              onCloseModal={this.handleCloseModal}></TooltipModal>
          ) : null}
          <KeyboardSpacer />
        </ScrollView>
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }}>
          <View style={styles.button}>
            <PrimaryButton onPress={this.handleSubmit}>
              エクスポートする
            </PrimaryButton>
          </View>
        </View>
        {alertMessage && (
          <AlertModal
            visible={true}
            onCloseModal={() => {
              this.setState({alertMessage: undefined});
            }}>
            {alertMessage}
          </AlertModal>
        )}
        <TooltipModal
          visible={visibleCompleteModal}
          title={'エクスポートしました'}
          buttonText={'閉じる'}
          onCloseModal={this.handleCloseCompleteModal}
        />
      </View>
    );
  }

  private handleChangeType = (item: {label: string; value: string}) => {
    const value = item.value;
    if (value === 'raw_data') {
      this.setState({type: value});
    } else if (value === 'script') {
      this.setState({type: value, format: 'text'});
    }
  };

  private handleChangeFormat = (item: {label: string; value: string}) => {
    const value = item.value;
    if (value === 'text' || value === 'csv') {
      this.setState({format: value});
    }
  };

  private handleChangeEncoding = (value: number | string) => {
    if (value === 'utf8' || value === 'sjis') {
      this.setState({encoding: value});
    }
  };

  private handleSubmit = () => {
    const {episodeId} = this.props;
    const {type, format, encoding} = this.state;
    downloadFile(
      `/api/writer/episode_script_commands/${episodeId}.${format}`,
      {
        type,
        encoding,
      },
      () => {
        this.setState({visibleCompleteModal: true});
      },
    );
  };

  private handlePressQuestionType = () => {
    this.setState({tooltipModalType: 'type'});
  };

  private handleCloseModal = () => {
    this.setState({tooltipModalType: null});
  };

  private handleCloseCompleteModal = () => {
    this.setState({visibleCompleteModal: false});
  };
}

const TYPE_ITEMS = [
  {label: 'ローデータ', value: 'raw_data'},
  {label: '台本', value: 'script'},
];

const FORMAT_ITEMS = [
  {label: 'テキスト', value: 'text'},
  {label: 'CSV', value: 'csv'},
];

const ENCODING_ITEMS = [
  {label: 'UTF-8', value: 'utf8'},
  {label: 'Shift_JIS', value: 'sjis'},
];

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  container: {
    flex: 1,
  } as ViewStyle,
  thumbnailWrapper: {
    borderBottomWidth: 1,
    borderColor: colors.paleGray,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
  inputError: {
    color: '#f23406',
  },
});
