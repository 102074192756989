import ExtensionMission from './ExtensionMission';

export default class UserExtensionMission {
  constructor(
    public id: number,
    public completed: boolean,
    public enabledExtension: boolean,
    public writerExtensionMission: ExtensionMission,
  ) {}
}
