const fetchBlob = async (url: string): Promise<string> => {
  const res = await fetch(url, {
    headers: {Accept: 'image/webp,image/apng,*/*'},
  });
  const blob = await res.blob();
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new DOMException('Problem parsing input file.'));
    };

    fileReader.onload = () => {
      const data = fileReader.result;
      if (typeof data === 'string') {
        resolve(data);
      } else {
        reject(data);
      }
    };
    fileReader.readAsDataURL(blob);
  });
};

export default fetchBlob;
