import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../components/story_form/story_publication/Index';

import AppState from '../../../reducers/AppState';

import showStory from '../../../actions/stories/show';
import updateStoryPublication, {
  Params as StoryPublicationUpdateParams,
} from '../../../actions/story_publications/update';
import indexEpisodes, {
  Params as EpisodeIndexParams,
} from '../../../actions/episodes/index';
import indexCharacters, {
  Params as CharacterIndexParams,
} from '../../../actions/characters/index';

import selectEntity from '../../../helpers/selectEntity';

import {StoryFormStoryPublicationRouteProp} from '../../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: StoryFormStoryPublicationRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities} = state;
  const {storyId} = route.params;
  const story = selectEntity(entities.stories, storyId);
  return {
    story,
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showStory: (id: number) => dispatch(showStory(id)),
    updateStoryPublication: (
      id: number,
      params: StoryPublicationUpdateParams,
    ) => dispatch(updateStoryPublication(id, params)),
    indexEpisodes: (params: EpisodeIndexParams) =>
      dispatch(indexEpisodes(params)),
    indexCharacters: (params: CharacterIndexParams) =>
      dispatch(indexCharacters(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
