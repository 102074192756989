import React from 'react';

import RootStack from '../../RootStack';
import {DefaultTransition} from '../../BaseNavigator';

import ShopActors from '../../../containers/shop/actors/Index';
import ShopActor from '../../../containers/shop/actors/Show';
import ShopActorCheckout from '../../../containers/shop/actors/checkout/Show';
import ShopActorCheckoutComplete from '../../../containers/shop/actors/checkout/Complete';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="ShopActors">
      <RootStack.Screen
        name="ShopActors"
        component={ShopActors}
        options={{
          title: 'ショップ',
        }}
      />
      <RootStack.Screen
        name="ShopActor"
        component={ShopActor}
        options={{
          title: '表情差分',
        }}
      />
      <RootStack.Screen
        name="ShopActorCheckout"
        component={ShopActorCheckout}
        options={{
          title: '購入確認',
        }}
      />
      <RootStack.Screen
        name="ShopActorCheckoutComplete"
        component={ShopActorCheckoutComplete}
        options={{
          title: '購入完了',
        }}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
