import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import StaffIcon from '../../../shared/icons/StaffIcon';
import WriterIcon from '../../../shared/icons/WriterIcon';
import QuestionIcon from '../../../shared/icons/QuestionIcon';

import TooltipModal from '../../../shared/modals/TooltipModal';

interface Props {
  visible: boolean;
}

const IconsTips: React.FunctionComponent<Props> = props => {
  const {visible} = props;
  const [visibleModal, setVisibleModal] = React.useState(false);
  const onPress = React.useCallback(() => {
    setVisibleModal(!visibleModal);
  }, [visibleModal]);
  if (!visible) {
    return null;
  }
  return (
    <View style={styles.container}>
      <StaffIcon />
      <WriterIcon />

      <TouchableOpacity onPress={onPress}>
        <QuestionIcon />
      </TouchableOpacity>
      <TooltipModal
        visible={visibleModal}
        title={'アイコンについて'}
        description={
          <View>
            <View style={styles.item}>
              <View style={{marginTop: 2}}>
                <StaffIcon size={20} />
              </View>
              <Text style={styles.itemText}>
                TapNovel運営スタッフにつきます。
                運営としてではなく、一読者としての主観的なコメントとなります。
              </Text>
            </View>
            <View style={[styles.item, {marginBottom: -16}]}>
              <View style={{marginTop: 2}}>
                <WriterIcon size={20} />
              </View>
              <Text style={styles.itemText}>
                タップライターとして作品を投稿しているユーザにつきます。{'\n'}
                詳細をクリックしてタップライターのページを訪れてみてください。
              </Text>
            </View>
          </View>
        }
        onCloseModal={onPress}
      />
    </View>
  );
};

export default React.memo(IconsTips);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 45,
  } as ViewStyle,
  item: {
    flex: 1,
    flexDirection: 'row',
    alignContent: 'flex-start',
    marginVertical: 8,
  } as ViewStyle,
  itemText: {
    color: '#222',
    fontSize: 12,
    marginHorizontal: 8,
    lineHeight: 15,
  } as TextStyle,
});
