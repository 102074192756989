import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import Question from '../../../../../domain/entities/writer/faq/Question';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';

interface Props {
  question: Question;
  answered: boolean;
  selfWriterUserId?: number;
}

const NewAnswerButton: React.FC<Props> = props => {
  const {question, selfWriterUserId, answered} = props;
  const linkTo = useLinkTo();
  const onPress = React.useCallback(() => {
    if (selfWriterUserId) {
      linkTo(`/faq/questions/${question.id}/answers/new`);
    } else {
      linkTo(`/users/sign_in`);
    }
  }, [selfWriterUserId]);
  if (question.writerUser.id === selfWriterUserId) {
    return null;
  }
  return (
    <View style={styles.container}>
      {question.resolvedAt ? (
        <PrimaryButton style={styles.button} disabled={true} onPress={onPress}>
          解決済み
        </PrimaryButton>
      ) : (
        <>
          <Text style={styles.text}>
            補足が必要そうでしたら、回答していただけませんか？
          </Text>
          <PrimaryButton
            style={styles.button}
            disabled={question.writerUser.id === selfWriterUserId || answered}
            onPress={onPress}>
            {answered ? '回答済み' : '回答する'}
          </PrimaryButton>
        </>
      )}
    </View>
  );
};

export default React.memo(NewAnswerButton);

const styles = StyleSheet.create({
  container: {
    marginVertical: 40,
    marginHorizontal: 16,
    padding: 16,
    backgroundColor: '#fafafa',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#222',
    fontSize: 12,
    marginBottom: 16,
  } as TextStyle,
  button: {
    width: 240,
    height: 46,
  } as ViewStyle,
});
