import * as React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import CheckButton from '../../../shared/forms/CheckButton';

import convertImageSource from '../../../../helpers/convertImageSource';

import partnerProgramEpisodeTypelUri from '../../../../../assets/images/partner_program/partner-program-episode-type.png';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep2: React.FunctionComponent<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <View style={styles.container}>
      <Text style={styles.message}>
        ポイントを獲得できるのは、TapNovel会員限定のエピソードとなります。
      </Text>
      <View style={styles.imageWraper}>
        <Image
          source={convertImageSource(partnerProgramEpisodeTypelUri)}
          style={styles.image}
        />
      </View>
      <CheckButton
        style={styles.checkButton}
        checked={checked}
        onPress={onPressCheck}>
        理解した
      </CheckButton>
      <PrimaryButton disabled={!checked} onPress={onPress}>
        次へ
      </PrimaryButton>
    </View>
  );
};

export default React.memo(ServiceExplanationStep2);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 24,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginHorizontal: 24,
  } as TextStyle,
  imageWraper: {
    marginVertical: 24,
  } as ViewStyle,
  image: {
    width: 273,
    height: 160,
  } as ImageStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginTop: 8,
    marginBottom: 16,
  } as ViewStyle,
});
