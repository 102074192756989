import OrientedSpeechBalloonMapper, {
  OrientedSpeechBalloonData,
} from '../../../entities/mappers/OrientedSpeechBalloonMapper';
import VoiceMapper, {VoiceData} from '../../../entities/mappers/VoiceMapper';
import SoundMapper, {SoundData} from '../../../entities/mappers/SoundMapper';

import SpeechTextShowSceneCommand from '../../../../domain/value_objects/scene_commands/SpeechTextShowSceneCommand';

export interface SpeechTextShowSceneCommandData {
  type: string;
  oriented_speech_balloon: OrientedSpeechBalloonData;
  text: string;
  override_character_name: boolean | null;
  character_name: string | null;
  voice?: VoiceData | null;
  sound?: SoundData | null;
  start_time?: number;
  end_time?: number;
}

export default class SpeechTextShowSceneCommandMapper {
  private orientedSpeechBalloonMapper: OrientedSpeechBalloonMapper;
  private voiceMapper: VoiceMapper;
  private soundMapper: SoundMapper;

  constructor() {
    this.orientedSpeechBalloonMapper = new OrientedSpeechBalloonMapper();
    this.voiceMapper = new VoiceMapper();
    this.soundMapper = new SoundMapper();
  }

  public map(obj: SpeechTextShowSceneCommandData): SpeechTextShowSceneCommand {
    const orientedSpeechBalloon = this.orientedSpeechBalloonMapper.map(
      obj.oriented_speech_balloon,
    );
    const voice = obj.voice ? this.voiceMapper.map(obj.voice) : null;
    const sound = obj.sound ? this.soundMapper.map(obj.sound) : null;
    return new SpeechTextShowSceneCommand(
      orientedSpeechBalloon,
      obj.text,
      obj.override_character_name,
      obj.character_name,
      voice,
      sound,
      obj.start_time,
      obj.end_time,
    );
  }
}
