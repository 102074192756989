import * as React from 'react';
import {StyleSheet, TextStyle, View, ViewStyle} from 'react-native';

import SelectWrapper from './SelectWrapper';
import WriterNoImage from './WriterNoImage';

import LabelWithOption from '../../../shared/forms/LabelWithOption';
import AvatarPicker from '../../../shared/image_picker/AvatarPicker';
import Input from '../../../shared/forms/Input';
import TextArea from '../../../shared/forms/TextArea';

import {squareImageUrl} from '../../../../helpers/images';

import {formStyles} from '../../../../styles/variables';

import File from '../../../../../domain/entities/File';
import UserForm from '../../../../../domain/forms/writer/UserForm';

const range = (start: number, end: number) =>
  [...Array(end - start + 1)].map((_, i) => start + i);

const yearItems = [
  {label: '--', value: 0},
  ...range(1920, new Date().getFullYear())
    .map(i => {
      return {label: `${i}`, value: i};
    })
    .reverse(),
];

const monthItems = [
  {label: '--', value: 0},
  ...range(1, 12).map(i => {
    return {label: `${i}`, value: i};
  }),
];

const dayItems = [
  {label: '--', value: 0},
  ...range(1, 31).map(i => {
    return {label: `${i}`, value: i};
  }),
];

interface Props {
  form: UserForm;
  birthday?: string | null;
  onChangeImage: (file: File) => void;
  onChangePenName: (penName: string) => void;
  onChangeDescription: (description: string) => void;
  onChangeEmail: (email: string) => void;
  onValueChangeYear: (itemValue: number, itemPosition: number) => void;
  onValueChangeMonth: (itemValue: number, itemPosition: number) => void;
  onValueChangeDate: (itemValue: number, itemPosition: number) => void;
  onChangeHomePageUrl: (homePageUrl: string) => void;
  onChangeTwitterAccountUrl: (twitterAccountUrl: string) => void;
  onChangeYoutubeChannelUrl: (youtubeChannelUrl: string) => void;
}

interface State {
  disabledButton: boolean;
}

export default class Form extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      disabledButton: false,
    };
  }

  public render(): React.ReactNode {
    const {
      form,
      birthday,
      onChangeImage,
      onChangePenName,
      onChangeDescription,
      onValueChangeYear,
      onValueChangeMonth,
      onValueChangeDate,
      onChangeHomePageUrl,
      onChangeTwitterAccountUrl,
      onChangeYoutubeChannelUrl,
    } = this.props;
    let avatarSource = null;
    if (form.image) {
      avatarSource = {uri: form.image.uri};
    } else if (form.originalImageUrl) {
      avatarSource = {
        uri: squareImageUrl(form, 100),
        headers: {Accept: 'image/webp,image/apng,*/*'},
      };
    }
    return (
      <View style={styles.container}>
        <View style={[styles.formGroup, styles.formGroupThumbnail]}>
          <LabelWithOption title={'プロフィール画像'} />
          <AvatarPicker
            source={avatarSource}
            renderNoImage={() => <WriterNoImage />}
            imageIconStyle={{right: -13, bottom: 0}}
            onChangeImage={onChangeImage}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'ペンネーム'}
            maxLength={20}
            length={form.penName.length}
            requiredOrOptional={'required'}
            entered={form.penName.length > 0 && form.penName.length <= 20}
          />
          <Input
            style={[
              {marginBottom: 3, marginTop: 8},
              form.penName.length === 0
                ? {backgroundColor: '#fff1e9'}
                : undefined,
            ]}
            value={form.penName}
            placeholder={'ペンネームを入力'}
            onChangeText={onChangePenName}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'生年月日(非公開)'}
            requiredOrOptional={'optional'}
            entered={!!birthday}
          />
          <View style={{flexDirection: 'row'}}>
            <SelectWrapper
              items={yearItems}
              value={form.getBirthdayYear() || ''}
              label={'年'}
              onValueChange={onValueChangeYear}
            />
            <SelectWrapper
              items={monthItems}
              value={form.getBirthdayMonth() || ''}
              label={'月'}
              onValueChange={onValueChangeMonth}
            />
            <SelectWrapper
              items={dayItems}
              value={form.getBirthdayDate() || ''}
              label={'日'}
              onValueChange={onValueChangeDate}
            />
          </View>
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'プロフィール'}
            requiredOrOptional={'optional'}
            maxLength={150}
            length={form.description.length}
            entered={
              form.description.length > 0 && form.description.length <= 150
            }
          />
          <TextArea
            containerStyle={{marginBottom: 0}}
            style={[styles.textArea]}
            value={form.description}
            placeholder={'プロフィールを入力'}
            onChangeText={onChangeDescription}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'ホームページ'}
            requiredOrOptional={'optional'}
            entered={
              form.homePageUrl.length > 0 && form.homePageUrl.length <= 255
            }
          />
          <Input
            style={[{marginBottom: 3, marginTop: 8}]}
            value={form.homePageUrl}
            placeholder={'URLを入力'}
            onChangeText={onChangeHomePageUrl}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'Twitter'}
            requiredOrOptional={'optional'}
            entered={
              form.twitterAccountUrl.length > 0 &&
              form.twitterAccountUrl.length <= 255
            }
          />
          <Input
            style={[{marginBottom: 3, marginTop: 8}]}
            value={form.twitterAccountUrl}
            placeholder={'URLもしくはユーザー名を入力'}
            onChangeText={onChangeTwitterAccountUrl}
          />
        </View>
        <View style={formStyles.formGroup}>
          <LabelWithOption
            title={'YouTube'}
            requiredOrOptional={'optional'}
            entered={
              form.youtubeChannelUrl.length > 0 &&
              form.youtubeChannelUrl.length <= 255
            }
          />
          <Input
            style={[{marginBottom: 3, marginTop: 8}]}
            value={form.youtubeChannelUrl}
            placeholder={'チャンネルURLを入力'}
            onChangeText={onChangeYoutubeChannelUrl}
          />
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginBottom: 20,
  } as ViewStyle,
  formGroup: {
    paddingHorizontal: 16,
  } as ViewStyle,
  formGroupThumbnail: {
    paddingTop: 32,
    paddingBottom: 16,
  } as ViewStyle,
  textArea: {
    fontSize: 12.6,
    textAlignVertical: 'top',
  } as TextStyle,
});
