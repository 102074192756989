import * as React from 'react';
import {
  Animated,
  Easing,
  GestureResponderEvent,
  LayoutChangeEvent,
  StyleProp,
  StyleSheet,
  ViewStyle,
} from 'react-native';

import ModalContainer from './ModalContainer';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';

interface Props extends React.PropsWithChildren {
  style?: StyleProp<ViewStyle>;
  visible: boolean;
  visibleHeader?: boolean;
  footer?: React.ReactNode;
  onCloseModal?: (event: GestureResponderEvent) => void;
  onLayout?: (event: LayoutChangeEvent) => void;
}

interface State {
  opacityValue: Animated.Value;
  scaleValue: Animated.Value;
}

export default class ScaleModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      opacityValue: new Animated.Value(0),
      scaleValue: new Animated.Value(0),
    };
  }

  public componentDidMount() {
    this.animate();
  }

  public componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ) {
    if (this.props.visible && !prevProps.visible) {
      this.animate();
    } else if (!this.props.visible && prevProps.visible) {
      this.setState({
        opacityValue: new Animated.Value(0),
        scaleValue: new Animated.Value(0),
      });
    }
  }

  public render(): React.ReactNode {
    const {
      style,
      visible,
      visibleHeader,
      footer,
      onCloseModal,
      onLayout,
      children,
    } = this.props;
    const opacity = this.state.opacityValue.interpolate({
      inputRange: [0, 1],
      outputRange: [0.8, 1],
    });
    const scale = this.state.scaleValue.interpolate({
      inputRange: [0, 1.1],
      outputRange: [0.9, 1.1],
    });
    return (
      <ModalContainer
        style={style}
        animationType={'none'}
        visible={visible}
        onLayout={onLayout}
        onCloseModal={onCloseModal}>
        <Animated.View style={[styles.inner, {opacity, transform: [{scale}]}]}>
          {visibleHeader && <ModalHeader onCloseModal={onCloseModal} />}
          {children}
          <ModalFooter>{footer}</ModalFooter>
        </Animated.View>
      </ModalContainer>
    );
  }

  private animate = () => {
    const animationConfig = {
      duration: 120,
      easing: Easing.linear,
      toValue: 1,
      useNativeDriver: true,
    };
    Animated.sequence([
      Animated.parallel([
        Animated.timing(this.state.opacityValue, {...animationConfig}),
        Animated.timing(this.state.scaleValue, {
          ...animationConfig,
          toValue: 1.1,
        }),
      ]),
      Animated.timing(this.state.scaleValue, {...animationConfig}),
    ]).start();
  };
}

const styles = StyleSheet.create({
  inner: {
    alignItems: 'center',
    alignSelf: 'center',
    backgroundColor: 'white',
    borderRadius: 5,
    width: '100%',
  } as ViewStyle,
});
