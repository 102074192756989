import Entity from '../Entity';

export default class UserNotification implements Entity {
  constructor(
    public id: number,
    public writerUserId: number,
    public content: string,
    public fullpath: string,
    public optionsUpdatedAt: Date | null,
    public confirmedAt: Date | null,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}

  public confirmed() {
    return !!this.confirmedAt;
  }
}
