import * as React from 'react';
import {ImageStyle, StyleSheet, View, ViewStyle} from 'react-native';

import NewSoundUsageHistoryButton from './NewSoundUsageHistoryButton';

import SvgUriAdapter from '../../../../../shared/svg/SvgUriAdapter';

import backgroundMusicKeyVisualUri from '../../../../../../../assets/svgs/background-music-key-visual.svg';

interface Props {
  onForwardToNewSoundUsageHistory: () => void;
}

const NoSoundUsageHistories: React.FunctionComponent<Props> = props => {
  const {onForwardToNewSoundUsageHistory} = props;
  return (
    <View style={styles.container}>
      <View style={styles.image}>
        <SvgUriAdapter
          uri={backgroundMusicKeyVisualUri}
          width={167}
          height={87}
        />
      </View>
      <View style={styles.button}>
        <NewSoundUsageHistoryButton onPress={onForwardToNewSoundUsageHistory} />
      </View>
    </View>
  );
};

export default NoSoundUsageHistories;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  image: {
    height: 87,
    width: 167,
    margin: 32,
  } as ImageStyle,
  button: {
    margin: 16,
  },
});
