import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ContactCategoryMapper, {
  ContactCategoryData,
} from '../../entities/mappers/writer/ContactCategoryMapper';

import ContactCategory from '../../../domain/entities/writer/ContactCategory';
import ContactCategoriesRepository from '../../../domain/repositories/writer/ContactCategoriesRepository';

export default class NetContactsRepository
  extends NetResourcesRepository<ContactCategory, ContactCategoryData>
  implements ContactCategoriesRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/contact_categories',
        new ContactCategoryMapper(),
      ),
    );
  }
}
