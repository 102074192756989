import EntityMapper from './EntityMapper';

import EpisodeTip from '../../../domain/entities/EpisodeTip';

import TipMapper, {TipData} from './TipMapper';

export interface EpisodeTipData {
  id: number;
  writer_user_id: number;
  tip: TipData;
  story: {id: number};
  created_at: string;
}

export default class EpisodeTipMapper
  implements EntityMapper<EpisodeTipData, EpisodeTip>
{
  private tipMapper: TipMapper;

  constructor() {
    this.tipMapper = new TipMapper();
  }

  public map(obj: EpisodeTipData): EpisodeTip {
    return new EpisodeTip(
      obj.id,
      obj.writer_user_id,
      obj.story.id,
      this.tipMapper.map(obj.tip),
      new Date(obj.created_at),
    );
  }
}
