import SceneCommand from './SceneCommand';

import ActorCharacterFace from '../../entities/ActorCharacterFace';
import CharacterPattern from '../../entities/CharacterPattern';
import Mark from '../../entities/Mark';

import Position from '../Position';

import CharacterHideSceneCommandForm from '../../forms/scene_commands/CharacterHideSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class CharacterHideSceneCommand implements SceneCommand {
  constructor(
    public characterPattern: CharacterPattern,
    public actorCharacterFace: ActorCharacterFace,
    public position: Position,
    public mark: Mark | null,
  ) {}

  public getValue() {
    return {
      actor_character_face_id: this.actorCharacterFace.id,
      character_id: this.characterPattern.id,
      position: this.position.toString(),
      mark_id: this.mark ? this.mark.id : null,
    };
  }

  public toForm(mark?: Mark): SceneCommandForm {
    return new CharacterHideSceneCommandForm(
      this.characterPattern,
      this.actorCharacterFace,
      this.mark,
      this.position,
    );
  }
}
