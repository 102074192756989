import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import GridList from './GridList';

import StoryAggregation from '../../../../../domain/value_objects/summary/StoryAggregation';

interface Props {
  storyAggregation: StoryAggregation;
}

const StorySummary: React.FunctionComponent<Props> = props => {
  const {storyAggregation} = props;
  return (
    <View style={styles.container}>
      <GridList
        items={[
          {
            label: '公開中のエピソード',
            value: storyAggregation.publishedEpisodesCount,
          },
          {
            label: 'お気に入り登録数',
            value: storyAggregation.favoritedConsumerUsersCount,
          },
        ]}
      />
    </View>
  );
};

export default React.memo(StorySummary);

const styles = StyleSheet.create({
  container: {
    paddingTop: 16,
    backgroundColor: '#fafafa',
  } as ViewStyle,
});
