import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import showGeneric from '../generics/show';

import CharacterPattern from '../../../domain/entities/CharacterPattern';

import NetCharacterPatternsRepository from '../../../data/repositories/writer/NetCharacterPatternsRepository';

const types = {
  failure: Types.ShowCharacterPatternFailure,
  request: Types.ShowCharacterPatternRequest,
  success: Types.ShowCharacterPatternSuccess,
};

export default function action(
  id: number,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<CharacterPattern> {
  return showGeneric(new NetCharacterPatternsRepository(), types, id);
}
