import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ClapIcon from '../../../shared/icons/project/ClapIcon';
import ClapActiveIcon from '../../../shared/icons/project/ClapActiveIcon';

import ProjectManuscript from '../../../../../domain/entities/ProjectManuscript';
import ProjectManuscriptLike from '../../../../../domain/entities/ProjectManuscriptLike';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  manuscript: ProjectManuscript;
  disabledLike?: boolean;
}

const ManuscriptClapButton: React.FC<Props> = props => {
  const {manuscript, disabledLike} = props;
  const [manuscriptLike, setManuscriptLike] = React.useState<
    ProjectManuscriptLike | null | undefined
  >(undefined);
  const [likesCount, setLikesCount] = React.useState(manuscript.likesCount);
  React.useEffect(() => {
    if (disabledLike) {
      return;
    }
    TapNovelTypedRestApi.get<ProjectManuscriptLike[]>(
      '/api/writer/project_manuscript_likes',
      {
        projectManuscriptId: manuscript.id,
      },
    )
      .then(result => setManuscriptLike(result.body[0] || null))
      .catch(() => setManuscriptLike(null));
  }, []);
  const onPress = React.useCallback(() => {
    if (manuscriptLike === undefined) {
      return;
    }
    if (manuscriptLike) {
      TapNovelTypedRestApi.delete(
        `/api/writer/project_manuscript_likes/${manuscriptLike.id}`,
      ).then(result => {
        setManuscriptLike(null);
        setLikesCount(likesCount - 1);
      });
    } else {
      TapNovelTypedRestApi.post<ProjectManuscriptLike>(
        '/api/writer/project_manuscript_likes',
        {
          projectManuscriptId: manuscript.id,
        },
      ).then(result => {
        setManuscriptLike(result.body);
        setLikesCount(likesCount + 1);
      });
    }
  }, [manuscriptLike, likesCount]);
  return (
    <View style={styles.container}>
      <TouchableOpacity disabled={disabledLike} onPress={onPress}>
        {manuscriptLike ? <ClapActiveIcon /> : <ClapIcon />}
      </TouchableOpacity>
      <Text style={styles.text}>{likesCount}</Text>
    </View>
  );
};

export default React.memo(ManuscriptClapButton);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  } as ViewStyle,
  text: {
    color: '#999',
    fontSize: 14,
    marginLeft: 10,
  } as TextStyle,
});
