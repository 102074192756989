import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';

import LockIcon from '../../shared/icons/LockIcon';
import UnlockIcon from '../../shared/icons/UnlockIcon';
import PrimaryButton from '../../shared/buttons/PrimaryButton';

import UserExtensionMission from '../../../../domain/entities/writer/UserExtensionMission';

interface Props {
  userExtensionMission: UserExtensionMission;
  onRequestExtension: (userExtensionMission: UserExtensionMission) => void;
}

const UserExtensionMissionListItem: React.FunctionComponent<Props> = props => {
  const {userExtensionMission, onRequestExtension} = props;
  const onPress = React.useCallback(() => {
    onRequestExtension(userExtensionMission);
  }, []);
  const color = userExtensionMission.enabledExtension ? '#ff8f13' : '#666';
  return (
    <View
      style={[styles.container, {borderColor: color, backgroundColor: color}]}>
      <View style={[styles.left, {backgroundColor: color}]}>
        <View style={styles.leftLockIcon}>
          {userExtensionMission.enabledExtension ? (
            <UnlockIcon />
          ) : (
            <LockIcon />
          )}
        </View>
        <Text style={styles.leftText}>
          {userExtensionMission.writerExtensionMission.writerExtension
            .nameWithNewline ||
            userExtensionMission.writerExtensionMission.writerExtension.name}
        </Text>
      </View>
      <View style={styles.right}>
        {userExtensionMission.enabledExtension ? (
          <Text style={styles.rightTextEnabled}>拡張済み！</Text>
        ) : userExtensionMission.completed ? (
          <View style={styles.rightCompleted}>
            <Text style={styles.rightTextEnabled}>条件クリア！</Text>
            <PrimaryButton style={styles.rightButton} onPress={onPress}>
              拡張する
            </PrimaryButton>
          </View>
        ) : (
          <Text style={styles.rightTextDefault}>
            {userExtensionMission.writerExtensionMission.content}
          </Text>
        )}
      </View>
    </View>
  );
};

export default React.memo(UserExtensionMissionListItem);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 46,
    borderRadius: 3,
    borderWidth: 2,
    marginVertical: 8,
    flexDirection: 'row',
  } as ViewStyle,
  left: {
    width: 127,
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  leftLockIcon: {
    width: 42,
    alignItems: 'center',
  } as ViewStyle,
  leftText: {
    color: 'white',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  right: {
    backgroundColor: 'white',
    flex: 1,
    justifyContent: 'center',
    paddingHorizontal: 16,
  } as ViewStyle,
  rightCompleted: {
    flexDirection: 'row',
    alignItems: 'center',
  } as ViewStyle,
  rightButton: {
    width: 100,
    height: 32,
    marginHorizontal: 6,
  } as TextStyle,
  rightTextDefault: {
    color: '#383838',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
  rightTextEnabled: {
    color: '#ff8f13',
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
