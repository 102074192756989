const LOADED_FONTS: {[key: string]: boolean} = {};

const loadGoogleFont = async (value: string, text = '.') => {
  const key = `${value}_${text}`;
  if (!LOADED_FONTS[key]) {
    const textParams = text != '.' ? `&text=${encodeURI(text)}` : '';
    const url = `https://fonts.googleapis.com/css?family=${encodeURI(
      value,
    )}${textParams}`;
    addStylesheetURL(url);
    LOADED_FONTS[key] = true;
  }
  return loadedFont(value, text);
};

const loadedFont = async (value: string, text: string) => {
  const p = () => (document as any).fonts.ready;
  const fontFaceSet = await p();
  const span = document.createElement('span');
  span.style.cssText = `font-family: ${value}; color: "white"; font-size: 1px;`;
  span.textContent = text;
  document.body.append(span);
  let tryCount = 0;
  while (
    tryCount < 5 &&
    !text.split('').every(ch => fontFaceSet.check(`10px '${value}'`, ch))
  ) {
    tryCount += 1;
    await wait(100);
  }
  span.remove();
};

export const executeAfterLoadedFont = (callback: () => void) => {
  (document as any).fonts.onloadingdone = () => {
    callback();
  };
};

function addStylesheetURL(url: string) {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
}

function wait(timeout: number) {
  return new Promise((resolve, reject) => setTimeout(resolve, timeout));
}

export default loadGoogleFont;
