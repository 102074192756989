import * as React from 'react';

import HelpCategoryList from './partials/HelpCategoryList';

import Layout from '../shared/Layout';

import NavigationProp from '../../navigators/NavigationProp';

import {linkToHelp} from '../../routers';

import {Params as HelpCategoryIndexParams} from '../../actions/writer/help_categories';

import HelpCategory from '../../../domain/entities/writer/HelpCategory';
import PaginatedResult from '../../../domain/results/PaginatedResult';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  helpCategoriesParams: HelpCategoryIndexParams;
  helpCategories: HelpCategory[] | null;
}

export interface DispatchProps {
  indexHelpCategories: (
    params: HelpCategoryIndexParams,
  ) => Promise<PaginatedResult<HelpCategory>>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {helpCategories, indexHelpCategories, helpCategoriesParams} =
      this.props;
    if (!helpCategories) {
      indexHelpCategories(helpCategoriesParams);
    }
  }

  public render(): React.ReactNode {
    const {navigation, helpCategories} = this.props;
    return (
      <Layout
        title={'ヘルプ'}
        navigation={navigation}
        scrollable={false}
        back={true}>
        {helpCategories && (
          <HelpCategoryList
            helpCategories={helpCategories}
            onSelectHelpCategory={this.handleSelectHelpCategory}
          />
        )}
      </Layout>
    );
  }

  private handleSelectHelpCategory = (helpCategory: HelpCategory) => {
    const {navigation} = this.props;
    linkToHelp(navigation, {writerHelpCategoryId: helpCategory.id});
  };
}
