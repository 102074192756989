import SceneCommandForm, {SceneCommandFormClass} from './SceneCommandForm';

import Background from '../../entities/Background';

const CLASS_NAME: SceneCommandFormClass = 'BackgroundShowSceneCommandForm';

export default class BackgroundShowSceneCommandForm extends SceneCommandForm {
  public className = CLASS_NAME;

  constructor(public background: Background, sceneCommandId?: number) {
    super(sceneCommandId);
  }

  public toParams(): {[key: string]: any} {
    return {
      background_id: this.background.id,
      type: 'SceneCommands::BackgroundShowSceneCommand',
    };
  }

  public copy(sceneCommandId?: number): SceneCommandForm {
    return new BackgroundShowSceneCommandForm(this.background, sceneCommandId);
  }
}
