import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import ThumbsUpActiveIcon from '../../../shared/icons/project/ThumbsUpActiveIcon';
import ThumbsUpIcon from '../../../shared/icons/project/ThumbsUpIcon';

import ProjectTopic from '../../../../../domain/entities/ProjectTopic';
import ProjectTopicLike from '../../../../../domain/entities/ProjectTopicLike';

import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

interface Props {
  topic: ProjectTopic;
}

const TopicLikeButton: React.FC<Props> = props => {
  const {topic} = props;
  const [topicLike, setTopicLike] = React.useState<
    ProjectTopicLike | null | undefined
  >(undefined);
  const [likesCount, setLikesCount] = React.useState(topic.likesCount);

  React.useEffect(() => {
    TapNovelTypedRestApi.get<ProjectTopicLike[]>(
      '/api/writer/project_topic_likes',
      {
        projectTopicId: topic.id,
      },
    )
      .then(result => setTopicLike(result.body[0] || null))
      .catch(() => setTopicLike(null));
  }, []);
  const onPress = React.useCallback(() => {
    if (topicLike) {
      TapNovelTypedRestApi.delete(
        `/api/writer/project_topic_likes/${topicLike.id}`,
      ).then(result => {
        setTopicLike(null);
        setLikesCount(likesCount - 1);
      });
    } else {
      TapNovelTypedRestApi.post<ProjectTopicLike>(
        '/api/writer/project_topic_likes',
        {
          projectTopicId: topic.id,
        },
      ).then(result => {
        setTopicLike(result.body);
        setLikesCount(likesCount + 1);
      });
    }
  }, [topicLike, likesCount]);
  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={onPress}>
        {topicLike ? (
          <ThumbsUpActiveIcon size={20} />
        ) : (
          <ThumbsUpIcon size={20} />
        )}
      </TouchableOpacity>
      <Text style={topicLike ? styles.likesActiveCount : styles.likesCount}>
        {likesCount}
      </Text>
    </View>
  );
};

export default React.memo(TopicLikeButton);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  } as ViewStyle,
  likesCount: {
    color: '#999',
    fontSize: 14,
    marginLeft: 4,
  } as TextStyle,
  likesActiveCount: {
    color: '#f24006',
    fontSize: 14,
    marginLeft: 4,
  } as TextStyle,
});
