import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories from '../../../../containers/scene_form/background_music_show_scene_command/edit/sound_usage_histories/Index';
import SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm from '../../../../containers/scene_form/background_music_show_scene_command/edit/sound_search_form/Index';
import SceneFormBackgroundMusicShowSceneCommandEditSounds from '../../../../containers/scene_form/background_music_show_scene_command/edit/sounds/Index';

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories">
      <RootStack.Screen
        name="SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories"
        component={
          SceneFormBackgroundMusicShowSceneCommandEditSoundUsageHistories
        }
        options={{title: 'BGM'}}
      />
      <RootStack.Screen
        name="SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm"
        component={SceneFormBackgroundMusicShowSceneCommandEditSoundSearchForm}
        options={{title: 'BGM検索条件'}}
      />
      <RootStack.Screen
        name="SceneFormBackgroundMusicShowSceneCommandEditSounds"
        component={SceneFormBackgroundMusicShowSceneCommandEditSounds}
        options={{title: 'BGM'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
