import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';

import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';
import ImageIcon from '../../../shared/icons/ImageIcon';

import uri from '../../../../../assets/svgs/noimage-writer.svg';

interface Props {}

const WriterNoImage: React.FunctionComponent<Props> = props => {
  return (
    <View>
      <View style={styles.container}>
        <SvgUriAdapter uri={uri} width={100} height={100} />
      </View>
      <View style={[styles.imageIcon]}>
        <ImageIcon size={18} />
      </View>
    </View>
  );
};

export default React.memo(WriterNoImage);

const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
    width: 100,
    height: 100,
    borderRadius: 50,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  imageIcon: {
    position: 'absolute',
    backgroundColor: '#efefef',
    right: -13,
    bottom: 0,
    width: 36,
    height: 36,
    borderRadius: 18,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
});
