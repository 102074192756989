import EntityMapper from './EntityMapper';

import ActorCharacterFaceMapper, {
  ActorCharacterFaceData,
} from './ActorCharacterFaceMapper';
import GenderMapper, {
  GenderData,
} from '../../value_objects/mappers/GenderMapper';

import Character from '../../../domain/entities/Character';
import NameLabelColor from '../../../domain/value_objects/NameLabelColor';

export interface CharacterData {
  id: number;
  story_id: number;
  actor_id: number;
  actor_character_id: number;
  writer_user_id: number | null;
  character_maker_only: boolean;
  name: string;
  description: string;
  name_label_color: NameLabelColor | undefined;
  voice_actor_name: string | null | undefined;
  inverted: boolean;
  published: boolean;
  original_image_url: string;
  default_character_pattern_id: number;
  character_patterns_count: number;
  created_at: string;
  updated_at: string;
  actor_character_face: ActorCharacterFaceData;
  options: {
    gender: GenderData;
  };
}

export default class CharacterMapper
  implements EntityMapper<CharacterData, Character>
{
  private actorCharacterFace: ActorCharacterFaceMapper;
  private genderMapper: GenderMapper;

  constructor() {
    this.actorCharacterFace = new ActorCharacterFaceMapper();
    this.genderMapper = new GenderMapper();
  }

  public map(obj: CharacterData): Character {
    const actorCharacterFace = this.actorCharacterFace.map(
      obj.actor_character_face,
    );
    const gender = this.genderMapper.map(obj.options.gender);
    return new Character(
      obj.id,
      obj.story_id,
      obj.actor_id,
      obj.actor_character_id,
      obj.character_maker_only,
      obj.name,
      obj.writer_user_id,
      obj.description,
      obj.name_label_color,
      obj.voice_actor_name,
      obj.inverted,
      obj.published,
      obj.original_image_url,
      obj.default_character_pattern_id,
      obj.character_patterns_count,
      new Date(obj.created_at),
      new Date(obj.updated_at),
      actorCharacterFace,
      {
        gender,
      },
    );
  }
}
