import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

export interface Params {
  action: {
    message: string;
    callback: () => void;
  } | null;
}

export default function action(
  params: Params,
): (dispatch: Dispatch<any>, getState: () => AppState) => void {
  return (dispatch: Dispatch<any>, getState: () => AppState): void => {
    dispatch({
      payload: params.action,
      type: Types.UpdateApplicationActionModal,
    });
  };
}
