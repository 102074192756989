import * as React from 'react';
import {StyleSheet, Text, TextStyle, View, ViewStyle} from 'react-native';
import numeral from 'numeral';

import WriterRankSection from './WriterRankSection';
import TermSelect from '../../stories/partials/TermSelect';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import {TermRange} from '../../../../view_models/TermRange';

import Grade from '../../../../../domain/entities/writer/Grade';
import AccountAggregation from '../../../../../domain/value_objects/partner_program/AccountAggregation';

interface Props {
  grade: Grade;
  accountAggregation: AccountAggregation;
  termRange: TermRange;
  onTermRangeChange: (termRange: TermRange) => void;
}

const AccountAggregationView: React.FunctionComponent<Props> = props => {
  const {grade, accountAggregation, termRange, onTermRangeChange} = props;
  return (
    <>
      <View style={styles.container}>
        <WriterRankSection grade={grade} />
        <TermSelect
          termRange={termRange}
          onTermRangeChange={onTermRangeChange}
        />
        <View style={{paddingHorizontal: 12}}>
          <Text style={styles.title}>全体</Text>
          <View style={styles.rows}>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>閲覧ポイント</Text>
              <Text style={styles.rowValue}>
                {numeral(accountAggregation.validViewsPoint).format('0,0.0')} pt
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>ギフトポイント</Text>
              <Text style={styles.rowValue}>
                {numeral(accountAggregation.tipsPoint).format('0,0.0')} pt
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.rowLabel}>合計獲得ポイント</Text>
              <Text style={styles.rowValue}>
                {numeral(accountAggregation.totalPoint).format('0,0.0')} pt
              </Text>
            </View>
          </View>
          <Text style={styles.title}>ストーリー別</Text>
        </View>
      </View>
      <ListItemNormalSeparator />
    </>
  );
};

export default React.memo(AccountAggregationView);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
  },
  rows: {
    flex: 1,
    flexDirection: 'row',
    marginBottom: 16,
  } as ViewStyle,
  row: {
    flex: 1,
    margin: 4,
    padding: 10,
    backgroundColor: '#fff',
    borderRadius: 3,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  rowLabel: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  rowValue: {
    color: '#ff8f13',
    fontSize: 13,
    fontWeight: 'bold',
    marginTop: 10,
  } as TextStyle,
  title: {
    color: '#222',
    fontWeight: 'bold',
    fontSize: 12,
    marginHorizontal: 4,
    marginBottom: 6,
  } as TextStyle,
});
