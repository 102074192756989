import EntityMapper from './EntityMapper';

import Background from '../../../domain/entities/Background';

export interface BackgroundData {
  id: number;
  background_id: number;
  background_weather_id: number;
  background_time_zone_id: number;
  option: string;
  original_image_url: string;
}

export default class BackgroundMapper
  implements EntityMapper<BackgroundData, Background>
{
  public map(obj: BackgroundData): Background {
    return new Background(
      obj.id,
      obj.background_id,
      obj.background_weather_id,
      obj.background_time_zone_id,
      obj.option,
      obj.original_image_url,
    );
  }
}
