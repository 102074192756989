import Entity from '../Entity';

export default class UserResourceFavorite implements Entity {
  constructor(
    public id: number,
    public resourceType: string,
    public resourceId: number,
    public createdAt: Date,
  ) {}
}
