import * as React from 'react';
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  ViewStyle,
} from 'react-native';

import {colors} from '../../../../../../styles/variables';

export interface Entity {
  id: number;
  getLabel: () => string;
}

interface Props<E extends Entity> {
  entity: E;
  checked: boolean;
  onSelectEntity: (entity: E) => void;
  extractLabel?: (entity: E) => string;
}

export default class LabelListItem<
  E extends Entity,
> extends React.PureComponent<Props<E>> {
  private pageXOnPressIn: number | null = null;

  public render(): React.ReactNode {
    const {entity, checked, extractLabel} = this.props;
    return (
      <Pressable
        key={entity.id}
        style={checked ? styles.containerActive : styles.container}
        onPress={this.handlePress}
        onPressIn={this.handlePressIn}>
        <Text style={styles.label}>
          {(extractLabel ? extractLabel(entity) : entity.getLabel()) || 'なし'}
        </Text>
      </Pressable>
    );
  }

  private handlePress = (e: GestureResponderEvent) => {
    const {entity, onSelectEntity} = this.props;
    const pageXOnPressIn = this.pageXOnPressIn;
    this.pageXOnPressIn = null;
    if (pageXOnPressIn && Math.abs(e.nativeEvent.pageX - pageXOnPressIn) >= 3) {
      return;
    }
    onSelectEntity(entity);
  };

  private handlePressIn = (e: GestureResponderEvent) => {
    this.pageXOnPressIn = e.nativeEvent.pageX;
  };
}

const container = {
  alignItems: 'center',
  backgroundColor: 'white',
  borderColor: '#efefef',
  borderWidth: 1,
  borderRadius: 4,
  justifyContent: 'center',
  marginHorizontal: 3,
  minWidth: 80,
  height: 46,
  paddingHorizontal: 16,
} as ViewStyle;

const styles = StyleSheet.create({
  container,
  containerActive: {
    ...container,
    borderColor: '#ff8f13',
  } as ViewStyle,
  label: {
    color: colors.gray,
    fontSize: 13,
    fontWeight: 'bold',
  } as TextStyle,
});
