import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import SceneWithCommandsWrapperMapper, {
  SceneWithCommandsWrapperData,
} from '../../entities/mappers/SceneWithCommandsWrapperMapper';

import SceneWithCommandsWrapper from '../../../domain/entities/SceneWithCommandsWrapper';
import SceneWithCommandsWrappersRepository from '../../../domain/repositories/writer/SceneWithCommandsWrappersRepository';

import TapNovelRestApi from '../../data_stores/net/TapNovelRestApi';

export default class NetSceneWithCommandsWrappersRepository
  extends NetResourcesRepository<
    SceneWithCommandsWrapper,
    SceneWithCommandsWrapperData
  >
  implements SceneWithCommandsWrappersRepository
{
  private path = '/api/writer/scene_with_commands_wrappers';

  private mapper = new SceneWithCommandsWrapperMapper();

  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/scene_with_commands_wrappers',
        new SceneWithCommandsWrapperMapper(),
      ),
    );
  }

  public async find(id: number): Promise<SceneWithCommandsWrapper> {
    const path = `${this.path}/${id}?viewer_version=2`;
    const obj = await TapNovelRestApi.get<SceneWithCommandsWrapperData>(path);
    return this.mapper.map(obj.body);
  }
}
