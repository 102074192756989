import FramePartPosition from '../../../domain/value_objects/FramePartPosition';

export type FramePartPositionData = 'top' | 'middle' | 'bottom';

export default class FramePartPositionMapper {
  public map(obj: FramePartPositionData): FramePartPosition {
    switch (obj) {
      case 'top':
        return FramePartPosition.Top;
      case 'middle':
        return FramePartPosition.Middle;
      case 'bottom':
        return FramePartPosition.Bottom;
      default:
        throw Error('FramePartPositionData to FramePartPosition Error');
    }
  }
}
