import * as React from 'react';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import CharactersStage from './visualizations/CharactersStage';
import CharacterView from './visualizations/CharacterView';
import CharacterGhostView from './visualizations/CharacterGhostView';

import {ModalParams} from '../../Modal';

import SceneFrame, {
  CharacterSceneCommandForm,
} from '../../../../../view_models/SceneFrame';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import Position from '../../../../../../domain/value_objects/Position';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import CompositeParallelSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import CharacterShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import CharacterHideSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';

interface Props {
  sceneCommandForm: CompositeParallelSceneCommandForm;
  sceneFrame: SceneFrame;
  sceneCommandIndex: number;
  waitable: boolean;
  parentSceneCommandForm?: SceneCommandForm | null;
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
  onRequestOpenModal: (modalParams: ModalParams) => void;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
}

export default class CompositeParallelSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, [
      'sceneCommandForm',
      'sceneFrame',
    ]);
  }

  public render(): React.ReactNode {
    const {sceneFrame, onChangeOrder} = this.props;
    const leftCharacter = this.getCharacterSceneCommandFormByPosition(
      Position.Left,
    );
    const centerCharacter = this.getCharacterSceneCommandFormByPosition(
      Position.Center,
    );
    const rightCharacter = this.getCharacterSceneCommandFormByPosition(
      Position.Right,
    );
    return (
      <CharactersStage
        leftCharacter={
          leftCharacter
            ? this.renderCharacter(leftCharacter)
            : sceneFrame.leftCharacter && sceneFrame.isActive(Position.Left)
            ? this.renderCharacterForSceneFrame(sceneFrame.leftCharacter)
            : this.renderGhostCharacter(Position.Left)
        }
        centerCharacter={
          centerCharacter
            ? this.renderCharacter(centerCharacter)
            : sceneFrame.centerCharacter && sceneFrame.isActive(Position.Center)
            ? this.renderCharacterForSceneFrame(sceneFrame.centerCharacter)
            : this.renderGhostCharacter(Position.Center)
        }
        rightCharacter={
          rightCharacter
            ? this.renderCharacter(rightCharacter)
            : sceneFrame.rightCharacter && sceneFrame.isActive(Position.Right)
            ? this.renderCharacterForSceneFrame(sceneFrame.rightCharacter)
            : this.renderGhostCharacter(Position.Right)
        }
        onChangeOrder={onChangeOrder}
        characterNameDisplay={this.characterNameDisplay()}
      />
    );
  }

  private renderCharacter = (
    sceneCommandForm:
      | CharacterShowSceneCommandForm
      | CharacterUpdateSceneCommandForm
      | CharacterHideSceneCommandForm,
  ): React.ReactNode => {
    const {position} = sceneCommandForm;
    const characterWaiting =
      sceneCommandForm instanceof CharacterShowSceneCommandForm
        ? !!sceneCommandForm.waiting
        : undefined;
    const characterShow =
      sceneCommandForm instanceof CharacterShowSceneCommandForm
        ? !sceneCommandForm.waiting
        : undefined;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <CharacterView
            actorCharacterFace={sceneCommandForm.actorCharacterFace}
            mark={sceneCommandForm.mark}
            inverted={sceneCommandForm.characterPattern.character.inverted}
            characterShow={characterShow}
            characterHide={
              sceneCommandForm instanceof CharacterHideSceneCommandForm
                ? true
                : undefined
            }
            characterWaiting={characterWaiting}
            label={
              sceneCommandForm instanceof CharacterHideSceneCommandForm
                ? '退場'
                : characterShow
                ? '登場'
                : characterWaiting
                ? '待機'
                : undefined
            }
            characterName={
              sceneCommandForm instanceof CharacterShowSceneCommandForm
                ? sceneCommandForm.characterPattern.character.name
                : undefined
            }
          />
        }
        onRequestOpenModal={() => {
          this.handleRequestOpenModal(position);
        }}
      />
    );
  };

  private renderCharacterForSceneFrame = (
    sceneCommandForm: CharacterSceneCommandForm,
  ): React.ReactNode => {
    const {position} = sceneCommandForm;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <CharacterView
            actorCharacterFace={sceneCommandForm.actorCharacterFace}
            mark={sceneCommandForm.mark}
            inverted={sceneCommandForm.characterPattern.character.inverted}
            transparent={true}
          />
        }
        onRequestOpenModal={() => {
          this.handleRequestOpenModal(position);
        }}
      />
    );
  };

  private renderGhostCharacter = (position: Position): React.ReactNode => {
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={
          <CharacterGhostView
            style={this.characterNameDisplay() ? {bottom: 27} : {}}
            position={position}
          />
        }
        onRequestOpenModal={() => {
          this.handleRequestOpenModal(position);
        }}
      />
    );
  };

  private handleRequestOpenModal = (position: Position) => {
    const {
      sceneCommandIndex,
      sceneFrame,
      waitable,
      parentSceneCommandForm,
      onRequestOpenModal,
      onForwardToCharacters,
    } = this.props;
    const sceneCommandForm =
      this.getCharacterSceneCommandFormByPosition(position);
    if (sceneCommandForm && sceneCommandForm.position === position) {
      if (sceneCommandForm instanceof CharacterHideSceneCommandForm) {
        onRequestOpenModal({
          type: 'CharacterHideSceneCommandModal',
          sceneCommandForm,
          sceneCommandIndex,
          parentSceneCommandForm,
        });
      } else {
        onRequestOpenModal({
          type: 'CharacterShowOrUpdateSceneCommandModal',
          sceneCommandForm,
          sceneCommandIndex,
          waitable,
          parentSceneCommandForm,
        });
      }
    } else {
      switch (position) {
        case Position.Left:
          if (sceneFrame.leftCharacter && sceneFrame.isActive(Position.Left)) {
            onRequestOpenModal({
              type: 'CurrentSceneFrameModal',
              sceneCommandForm: sceneFrame.leftCharacter,
              sceneCommandIndex,
              parentSceneCommandForm,
            });
          } else {
            onForwardToCharacters({
              sceneCommandIndex,
              parentSceneCommandId: parentSceneCommandForm
                ? parentSceneCommandForm.sceneCommandId
                : undefined,
              position: Position.Left,
            });
          }
          break;
        case Position.Center:
          if (
            sceneFrame.centerCharacter &&
            sceneFrame.isActive(Position.Center)
          ) {
            onRequestOpenModal({
              type: 'CurrentSceneFrameModal',
              sceneCommandForm: sceneFrame.centerCharacter,
              sceneCommandIndex,
              parentSceneCommandForm,
            });
          } else {
            onForwardToCharacters({
              sceneCommandIndex,
              parentSceneCommandId: parentSceneCommandForm
                ? parentSceneCommandForm.sceneCommandId
                : undefined,
              position: Position.Center,
            });
          }
          break;
        case Position.Right:
          if (
            sceneFrame.rightCharacter &&
            sceneFrame.isActive(Position.Right)
          ) {
            onRequestOpenModal({
              type: 'CurrentSceneFrameModal',
              sceneCommandForm: sceneFrame.rightCharacter,
              sceneCommandIndex,
              parentSceneCommandForm,
            });
          } else {
            onForwardToCharacters({
              sceneCommandIndex,
              parentSceneCommandId: parentSceneCommandForm
                ? parentSceneCommandForm.sceneCommandId
                : undefined,
              position: Position.Right,
            });
          }
          break;
      }
    }
  };

  private getCharacterSceneCommandFormByPosition(
    position: Position,
  ):
    | CharacterShowSceneCommandForm
    | CharacterUpdateSceneCommandForm
    | CharacterHideSceneCommandForm
    | null {
    const {sceneCommandForm} = this.props;
    const foundCommandForm = sceneCommandForm.commandForms.find(
      commandForm =>
        (commandForm instanceof CharacterShowSceneCommandForm ||
          commandForm instanceof CharacterUpdateSceneCommandForm ||
          commandForm instanceof CharacterHideSceneCommandForm) &&
        commandForm.position === position,
    );
    if (
      foundCommandForm &&
      (foundCommandForm instanceof CharacterShowSceneCommandForm ||
        foundCommandForm instanceof CharacterUpdateSceneCommandForm ||
        foundCommandForm instanceof CharacterHideSceneCommandForm)
    ) {
      return foundCommandForm;
    }
    return null;
  }

  private characterNameDisplay = () => {
    const {sceneCommandForm} = this.props;
    return sceneCommandForm.commandForms.some(
      commandForm => commandForm instanceof CharacterShowSceneCommandForm,
    );
  };
}
