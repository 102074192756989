import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import ProjectListItem from './ProjectListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Project from '../../../../../domain/entities/Project';

interface Props {
  projects: Project[];
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
}

const ProjectList: React.FC<Props> = props => {
  const {projects, ListHeaderComponent} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Project>) => (
      <ProjectListItem project={info.item} />
    ),
    [],
  );
  return (
    <FlatList
      data={projects}
      renderItem={renderItem}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
      ItemSeparatorComponent={ListItemNormalSeparator}
    />
  );
};

export default React.memo(ProjectList);
