import * as React from 'react';
import {
  Pressable,
  RefreshControl,
  StyleSheet,
  ScrollView,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import CurrentUser from '../../../../../domain/entities/writer/CurrentUser';

interface Props {
  currentUser: CurrentUser | null;
  refreshing: boolean;
  onRefresh: () => void;
  onPressSignUpOtherEmail: () => void;
  onPressResendMail: () => void;
}

export default class Content extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {
      currentUser,
      refreshing,
      onRefresh,
      onPressSignUpOtherEmail,
      onPressResendMail,
    } = this.props;
    return (
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }>
        {currentUser && (
          <View style={styles.container}>
            <View style={[styles.section, {marginBottom: 24}]}>
              <Text style={styles.title}>メールを送信しました</Text>
              <Text style={styles.text}>
                メールをご確認いただき、認証を行ってください。
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={[styles.text, styles.textBold]}>
                認証メールが届かない方
              </Text>
              <Text style={styles.text}>
                入力したメールアドレスに誤りがないかご確認ください。
              </Text>
              <View
                style={{
                  backgroundColor: '#efefef',
                  width: '100%',
                  alignItems: 'center',
                  marginVertical: 16,
                }}>
                <Text
                  style={{
                    padding: 12,
                    color: '#383838',
                    fontSize: 14,
                  }}>
                  {currentUser.unconfirmedEmail || currentUser.email}
                </Text>
              </View>
            </View>
            <View style={styles.section}>
              <Text>
                メールアドレスが間違っていた場合は
                <Pressable onPress={onPressSignUpOtherEmail}>
                  <Text
                    style={{
                      fontWeight: 'bold',
                      textDecorationLine: 'underline',
                    }}>
                    再度登録
                  </Text>
                </Pressable>
                ください。
              </Text>
            </View>
            <View style={styles.section}>
              <Text>
                メールアドレスに誤りがなくても届かない場合は、迷惑フォルダに振り分けられている可能性がございますので、必ずご確認をお願いいたします。
              </Text>
            </View>
            <View style={styles.section}>
              <Text>
                ドメイン指定受信を利用している場合は【tapnovel.com】の設定をした上で認証メールを再送信ください。
              </Text>
            </View>
            <View
              style={[styles.section, {marginTop: 24, alignItems: 'center'}]}>
              <Pressable onPress={onPressResendMail}>
                <Text
                  style={{
                    color: '#383838',
                    fontSize: 16,
                    fontWeight: 'bold',
                    textDecorationLine: 'underline',
                  }}>
                  再送信はこちら
                </Text>
              </Pressable>
            </View>
          </View>
        )}
      </ScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: 40,
  } as ViewStyle,
  section: {
    justifyContent: 'center',
    marginVertical: 8,
    marginHorizontal: 24,
  } as ViewStyle,
  title: {
    color: '#383838',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 32,
    textAlign: 'center',
  } as TextStyle,
  text: {
    color: '#383838',
    fontSize: 14,
    lineHeight: 20,
  } as TextStyle,
  textBold: {
    fontWeight: 'bold',
    marginBottom: 16,
  } as TextStyle,
});
