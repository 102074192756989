import NetResourceRepository from '../../NetResourceRepository';
import NetResourceRepositoryAdapter from '../../NetResourceRepositoryAdapter';

import CurrentUserMapper, {
  CurrentUserData,
} from '../../../entities/mappers/writer/CurrentUserMapper';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import TermsAcceptanceRepository from '../../../../domain/repositories/writer/temporary_users/TermsAcceptanceRepository';

export default class NetTermsAcceptanceRepository
  extends NetResourceRepository<CurrentUser, CurrentUserData>
  implements TermsAcceptanceRepository
{
  constructor() {
    super(
      new NetResourceRepositoryAdapter(
        '/api/writer/temporary_users/terms_acceptance',
        new CurrentUserMapper(),
      ),
    );
  }
}
