import * as React from 'react';
import {FlatList, ListRenderItemInfo} from 'react-native';

import TopicListItem from './TopicListItem';
import NoTopics from './NoTopics';

import ListItemNormalSeparator from '../../shared/lists/separators/ListItemNormalSeparator';

import ProjectTopic from '../../../../domain/entities/ProjectTopic';

interface Props {
  topics: ProjectTopic[];
  isLeader?: boolean;
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
  onForceUpdate: () => void;
}

const TopicList: React.FC<Props> = props => {
  const {topics, isLeader, ListHeaderComponent, onForceUpdate} = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<ProjectTopic>) => (
      <TopicListItem
        topic={info.item}
        isLeader={isLeader}
        onForceUpdate={onForceUpdate}
      />
    ),
    [isLeader],
  );
  return (
    <FlatList
      data={topics}
      renderItem={renderItem}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={topics.length === 0 ? null : ListItemNormalSeparator}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListEmptyComponent={NoTopics}
      extraData={isLeader}
    />
  );
};

export default React.memo(TopicList);
