import React from 'react';
import {
  Image,
  ImageStyle,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import numeral from 'numeral';

import {
  getDoubleResizeImageUrl,
  HasOriginalImageUrl,
} from '../../../../helpers/images';

import Actor from '../../../../../domain/entities/shop/Actor';

interface Props {
  actor: Actor;
  selectedImage: HasOriginalImageUrl;
  withSeparator?: boolean;
}

const ActorCharacterFacePreview: React.FC<Props> = props => {
  const {actor, selectedImage, withSeparator} = props;
  const source = React.useMemo(
    () => ({
      uri: getDoubleResizeImageUrl(
        selectedImage,
        {
          width: IMAGE_WIDTH,
          height: IMAGE_HEIGHT,
        },
        {nocanvas: true},
      ),
    }),
    [selectedImage],
  );
  return (
    <View style={styles.container}>
      <View style={styles.header}>
        <View style={styles.headerLeft}>
          <Text style={styles.name}>{actor.name}</Text>
          <Text style={styles.costumeName}>{actor.defaultCostumeName}</Text>
          <Text style={styles.faceCount}>
            表情差分：{actor.faceCount}
            <Text style={{marginLeft: 8}}>
              反転機能：使用{actor.options.allowedInversion ? '' : '不'}可
            </Text>
          </Text>
        </View>
        <View style={styles.headerRight}>
          <Text style={styles.name}>
            イラスト：{actor.creatorUser.userName}
          </Text>
          <Text style={styles.price}>
            ￥{numeral(actor.amount).format('0')}
          </Text>
        </View>
      </View>
      <View style={withSeparator ? styles.bodyWithSeparator : styles.body}>
        <Image source={source} style={styles.image} />
      </View>
    </View>
  );
};

export default React.memo(ActorCharacterFacePreview);

const IMAGE_WIDTH = 169;
const IMAGE_HEIGHT = 240;

const body = {
  width: '100%',
  height: 257,
  backgroundColor: '#fafafa',
  justifyContent: 'center',
  alignItems: 'center',
} as ViewStyle;

const styles = StyleSheet.create({
  container: {} as ViewStyle,
  header: {
    width: '100%',
    height: 80,
    backgroundColor: '#efefef',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 16,
    paddingHorizontal: 16,
  } as ViewStyle,
  headerLeft: {} as ViewStyle,
  headerRight: {} as ViewStyle,
  name: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  price: {
    color: '#222222',
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'right',
    marginTop: 8,
  } as TextStyle,
  costumeName: {
    color: '#383838',
    fontSize: 10,
    marginTop: 4,
  } as TextStyle,
  faceCount: {
    color: '#383838',
    fontSize: 10,
  } as TextStyle,
  body,
  bodyWithSeparator: {
    ...body,
    borderBottomColor: '#efefef',
    borderBottomWidth: 1,
  } as ViewStyle,
  image: {
    width: IMAGE_WIDTH,
    height: IMAGE_HEIGHT,
  } as ImageStyle,
});
