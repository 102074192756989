import * as React from 'react';
import {View} from 'react-native';
import {DateObject} from 'react-native-calendars';

import DateCalendar from './DateCalendar';
import TimeSelect from './TimeSelect';

import LabelWithOption from '../../../../shared/forms/LabelWithOption';

import {formStyles} from '../../../../../styles/variables';

interface Props {
  selectedDate: DateObject | null;
  selectedTime: Date;
  visible: boolean;
  onDayPress: (date: DateObject) => void;
  onTimePress: (time: Date) => void;
}

export default class DateCalendarWithTimeSelect extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {selectedDate, selectedTime, visible, onDayPress, onTimePress} =
      this.props;
    return (
      <View style={[formStyles.formGroup, visible ? null : {display: 'none'}]}>
        <LabelWithOption title={'公開日時'} />
        <DateCalendar selectedDate={selectedDate} onDayPress={onDayPress} />
        <TimeSelect selectedTime={selectedTime} onTimePress={onTimePress} />
      </View>
    );
  }
}
