import * as React from 'react';
import {
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';
import {useLinkTo} from '@react-navigation/native';

import Question from '../../../../../domain/entities/writer/faq/Question';

interface Props {
  question: Question;
}

const QuestionListItem: React.FC<Props> = props => {
  const linkTo = useLinkTo();
  const {question} = props;
  const onPress = React.useCallback(() => {
    linkTo(`/faq/questions/${question.id}`);
  }, []);
  return (
    <Pressable style={styles.container} onPress={onPress}>
      {question.resolvedAt ? null : (
        <View style={styles.unsolvedLabel}>
          <Text style={styles.unsolvedLabelText}>回答待ち</Text>
        </View>
      )}
      <Text style={styles.title}>{question.title}</Text>
    </Pressable>
  );
};

export default React.memo(QuestionListItem);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 16,
    backgroundColor: 'white',
  } as ViewStyle,
  title: {
    color: '#222',
    fontSize: 13,
  } as TextStyle,
  unsolvedLabel: {
    height: 17,
    borderWidth: 1,
    borderColor: '#ff8f13',
    paddingHorizontal: 2,
    marginRight: 3,
  } as ViewStyle,
  unsolvedLabelText: {
    color: '#ff8f13',
    fontSize: 10,
    fontWeight: 'bold',
  } as TextStyle,
});
