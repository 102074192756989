import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';

import DocumentPickerAdapter from './document_picker/DocumentPickerAdapter';
import MicrophoneIcon from './icons/MicrophoneIcon';
import PlayIcon from './icons/PlayIcon';
import PlusCircleSolidIcon from './icons/PlusCircleSolidIcon';
import StopIcon from './icons/StopIcon';

import SoundPlayable from './SoundPlayable';
import SoundRecordable from './SoundRecordable';
import SoundRecorder from './SoundRecorder';

import Alert from '../shared/alert/Alert';
import AlertModal from '../shared/modals/AlertModal';

import {formatErrorMessages} from '../../helpers/errorMessages';

import Voice from '../../../domain/entities/Voice';

import NetVoicesRepository from '../../../data/repositories/writer/NetVoicesRepository';

const voicesRepository = new NetVoicesRepository();

interface Props {
  style?: ViewStyle;
  resetButtonWrapper?: ViewStyle;
  voice: Voice | null;
  storyId: number;
  visible: boolean;
  onUpdateVoice: (voice: Voice | null) => void;
}

const VoiceForm: React.FunctionComponent<Props> = props => {
  const {style, resetButtonWrapper, voice, storyId, visible, onUpdateVoice} =
    props;
  const [playing, setPlaying] = React.useState(false);
  const [requestedRecording, setRequestedRecording] = React.useState(false);
  const [recording, setRecording] = React.useState(false);
  const [sending, setSending] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const handlePlay = React.useCallback(() => {
    if (voice) {
      setPlaying(!playing);
    }
  }, [voice, playing]);
  const handleRecord = React.useCallback(async () => {
    if (!(await SoundRecorder.permitted())) {
      Alert.alertNative('マイクの使用を許可してください。');
    } else {
      setRecording(false);
      setRequestedRecording(!requestedRecording);
    }
  }, [requestedRecording]);
  const handleStart = React.useCallback(() => {
    if (!requestedRecording) {
      return;
    }
    setRecording(true);
  }, [requestedRecording]);
  const handleFinishPlay = React.useCallback(() => {
    setPlaying(false);
  }, []);
  const handleChangeFile = React.useCallback((audio: File) => {
    setSending(true);
    voicesRepository
      .create(
        {
          voice: {
            storyId,
            audio,
            name: audio.name,
            uploadedSelfMaterial: true,
          },
        },
        {multipart: true},
      )
      .then(voice => {
        onUpdateVoice(voice);
        setSending(false);
      })
      .catch(e => {
        setAlertMessage(formatErrorMessages({}, e));
        setSending(false);
      });
  }, []);
  const handleError = React.useCallback((error: any) => {
    setRecording(false);
    Alert.alertNative('お使いのブラウザでは録音できません。');
  }, []);
  const handleReset = React.useCallback(() => {
    onUpdateVoice(null);
  }, []);
  const handleRequestClose = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  return (
    <View
      style={[
        style,
        styles.container,
        voice ? styles.containerForVoice : null,
        visible ? null : {display: 'none'},
      ]}>
      {voice ? (
        <>
          <View style={[styles.resetButtonWrapper, resetButtonWrapper]}>
            <TouchableOpacity activeOpacity={0.9} onPress={handleReset}>
              <View style={styles.resetButton}>
                <Text style={styles.resetButtonLabel}>リセット</Text>
              </View>
            </TouchableOpacity>
          </View>
          <Text style={styles.audioFileName}>{voice.name}</Text>
          <View style={styles.buttons}>
            <TouchableOpacity activeOpacity={0.9} onPress={handlePlay}>
              <View style={styles.playButton}>
                {playing ? (
                  <StopIcon size={16} color={'white'} />
                ) : (
                  <PlayIcon size={16} color={'white'} />
                )}
                <Text style={styles.playButtonLabel}>
                  {playing ? '停止' : '再生'}する
                </Text>
                {playing ? (
                  <SoundPlayable
                    uri={voice.audioUrl}
                    onFinishPlay={handleFinishPlay}
                  />
                ) : null}
              </View>
            </TouchableOpacity>
          </View>
        </>
      ) : (
        <>
          {recording ? (
            <View style={styles.recordingMessage}>
              <View style={styles.recordingIcon} />
              <Text style={styles.recordingText}>録音中…</Text>
            </View>
          ) : (
            <Text style={styles.recordingText}>ボイスなし</Text>
          )}

          <View style={styles.buttons}>
            <TouchableOpacity
              disabled={sending}
              activeOpacity={0.9}
              onPress={handleRecord}>
              <View style={styles.recordButton}>
                {recording ? (
                  <>
                    <StopIcon size={16} color={'white'} />
                    <Text style={styles.recordButtonLabel}>停止する</Text>
                  </>
                ) : (
                  <>
                    <MicrophoneIcon size={16} color={'#ffffff'} />
                    <Text style={styles.recordButtonLabel}>録音する</Text>
                  </>
                )}
                {requestedRecording ? (
                  <SoundRecordable
                    onStart={handleStart}
                    onFinish={handleChangeFile}
                    onError={handleError}
                  />
                ) : null}
              </View>
            </TouchableOpacity>
            <DocumentPickerAdapter
              disabled={sending}
              type={'audio'}
              onChangeFile={handleChangeFile}
              onFailFile={error => {
                Alert.alertNative(error);
              }}>
              <View
                style={[
                  styles.uploadButton,
                  recording ? styles.uploadButtonDisable : null,
                ]}>
                <PlusCircleSolidIcon
                  size={16}
                  color={recording ? '#999999' : '#383838'}
                />
                <Text
                  style={[
                    styles.uploadButtonLabel,
                    recording ? styles.uploadButtonLabelDisable : null,
                  ]}>
                  アップロード
                </Text>
              </View>
            </DocumentPickerAdapter>
          </View>
        </>
      )}
      <AlertModal visible={!!alertMessage} onCloseModal={handleRequestClose}>
        {alertMessage}
      </AlertModal>
    </View>
  );
};

export default React.memo(VoiceForm);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#efefef',
    alignItems: 'center',
    paddingVertical: 16,
  } as ViewStyle,
  containerForVoice: {
    paddingTop: 8,
    paddingBottom: 24,
  } as ViewStyle,
  audioFileName: {
    color: '#999999',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: 10,
  } as TextStyle,
  addVoiceIcon: {
    marginVertical: 10,
  } as ViewStyle,
  audioUpload: {
    color: '#383838',
    fontSize: 15,
    fontWeight: 'bold',
  } as TextStyle,
  buttons: {
    flexDirection: 'row',
    marginTop: 16,
    marginBottom: 7,
  } as ViewStyle,
  resetButtonWrapper: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'flex-end',
  } as ViewStyle,
  resetButton: {
    width: 75,
    height: 24,
    marginRight: 8,
    borderRadius: 24,
    borderWidth: 1,
    borderColor: '#383838',
    backgroundColor: '#ffffff',
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  resetButtonLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    color: '#383838',
  } as TextStyle,
  playButton: {
    width: 200,
    height: 46,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#383838',
    borderRadius: 24,
    marginHorizontal: 5,
  } as ViewStyle,
  playButtonLabel: {
    marginLeft: 4,
    color: 'white',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  recordButton: {
    width: 135,
    height: 44,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#383838',
    backgroundColor: '#383838',
    borderWidth: 1,
    borderRadius: 24,
    marginHorizontal: 5,
  } as ViewStyle,
  recordButtonLabel: {
    marginLeft: 4,
    color: '#ffffff',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  uploadButton: {
    width: 135,
    height: 44,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: '#383838',
    backgroundColor: '#ffffff',
    borderWidth: 1,
    borderRadius: 24,
    marginHorizontal: 5,
  } as ViewStyle,
  uploadButtonDisable: {
    borderColor: '#d5d5d5',
    backgroundColor: '#d5d5d5',
  } as ViewStyle,
  uploadButtonLabel: {
    marginLeft: 4,
    color: '#383838',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  uploadButtonLabelDisable: {
    color: '#999999',
  } as TextStyle,
  recordingMessage: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  recordingText: {
    color: '#999999',
    fontSize: 14,
    fontWeight: 'bold',
  } as TextStyle,
  recordingIcon: {
    width: 14,
    height: 14,
    backgroundColor: '#f23406',
    borderRadius: 7,
    marginRight: 3,
  } as ViewStyle,
});
