import * as React from 'react';
import {TouchableOpacity, StyleSheet, ViewStyle} from 'react-native';

import CharacterInfo from './CharacterInfo';

import MoreIcon from '../../shared/icons/ionicons/MoreIcon';

import Character from '../../../../domain/entities/Character';

interface Props {
  character: Character;
  onOpenActionSheet?: (character: Character) => void;
}

const CharacterInfoWithMenu: React.FC<Props> = props => {
  const {character, onOpenActionSheet} = props;
  return (
    <>
      <CharacterInfo character={character} />
      {onOpenActionSheet && (
        <TouchableOpacity
          style={styles.menu}
          onPress={() => onOpenActionSheet(character)}>
          <MoreIcon />
        </TouchableOpacity>
      )}
    </>
  );
};

export default React.memo(CharacterInfoWithMenu);

const styles = StyleSheet.create({
  menu: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 8,
  } as ViewStyle,
});
