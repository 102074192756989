import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import UserNotificationMapper, {
  UserNotificationData,
} from '../../entities/mappers/writer/UserNotificationMapper';

import UserNotification from '../../../domain/entities/writer/UserNotification';
import UserNotificationsRepository from '../../../domain/repositories/writer/UserNotificationsRepository';

export default class NetUserNotificationsRepository
  extends NetResourcesRepository<UserNotification, UserNotificationData>
  implements UserNotificationsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/user_notifications',
        new UserNotificationMapper(),
      ),
    );
  }
}
