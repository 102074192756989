import * as React from 'react';

import SceneCommandBoxWithModal from './SceneCommandBoxWithModal';

import SoundEffectView from './visualizations/SoundEffectView';

import {ModalParams} from '../../Modal';

import SceneFrame from '../../../../../view_models/SceneFrame';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';

interface Props {
  sceneCommandForm: SoundEffectShowSceneCommandForm;
  parentSceneCommandForm?: SceneCommandForm | null;
  sceneFrame: SceneFrame;
  onRequestOpenModal: (modalParams: ModalParams) => void;
}

export default class SoundEffectShowSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, ['sceneCommandForm']);
  }

  public render(): React.ReactNode {
    const {sceneCommandForm} = this.props;
    return (
      <SceneCommandBoxWithModal
        sceneCommandBox={<SoundEffectView sound={sceneCommandForm.sound} />}
        onRequestOpenModal={this.handleRequestOpen}
      />
    );
  }

  private handleRequestOpen = () => {
    const {
      sceneCommandForm,
      parentSceneCommandForm,
      sceneFrame,
      onRequestOpenModal,
    } = this.props;
    onRequestOpenModal({
      type: 'SoundEffectShowSceneCommandModal',
      sceneCommandForm,
      parentSceneCommandForm,
      sceneFrame,
    });
  };
}
