import Entity from './Entity';

export default class MetaCharacterPattern implements Entity {
  constructor(
    public id: number,
    public actorId: number,
    public characterMakerOnly: boolean,
    public colorPatternId: number,
    public hairStyleName: string,
    public costumeName: string,
    public facePatternId: number,
    public eyeColorPatternId: number,
  ) {}
}
