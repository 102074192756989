import * as React from 'react';

import CompositeParallelSceneCommandBox from './CompositeParallelSceneCommandBox';
import CharacterHideSceneCommandBox from './CharacterHideSceneCommandBox';
import CharacterShowOrUpdateSceneCommandBox from './CharacterShowOrUpdateSceneCommandBox';
import DescriptiveTextShowSceneCommandBox from './DescriptiveTextShowSceneCommandBox';
import FullScreenIllustrationShowSceneCommandBox from './FullScreenIllustrationShowSceneCommandBox';
import IllustrationShowSceneCommandBox from './IllustrationShowSceneCommandBox';
import EffectShowSceneCommandBox from './EffectShowSceneCommandBox';
import SoundEffectShowSceneCommandBox from './SoundEffectShowSceneCommandBox';
import BackgroundMusicShowSceneCommandBox from './BackgroundMusicShowSceneCommandBox';
import BackgroundMusicHideSceneCommandBox from './BackgroundMusicHideSceneCommandBox';
import SpeechTextShowSceneCommandBox from './SpeechTextShowSceneCommandBox';
import CurrentSceneFrameBox from './CurrentSceneFrameBox';
import InsertSceneCommandLinksGroup, {
  Props as InsertSceneCommandLinksGroupProps,
} from '../InsertSceneCommandLinksGroup';

import {ModalParams} from '../../Modal';

import SceneFrame from '../../../../../view_models/SceneFrame';

import {equalForKeys} from '../../../../../helpers/equalForKeys';

import Position from '../../../../../../domain/value_objects/Position';
import CompositeSequenceSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeSequenceSceneCommandForm';
import CompositeParallelSceneCommandForm from '../../../../../../domain/forms/scene_commands/CompositeParallelSceneCommandForm';
import CharacterShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterShowSceneCommandForm';
import CharacterUpdateSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterUpdateSceneCommandForm';
import CharacterHideSceneCommandForm from '../../../../../../domain/forms/scene_commands/CharacterHideSceneCommandForm';
import DescriptiveTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/DescriptiveTextShowSceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import IllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/IllustrationShowSceneCommandForm';
import EffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/EffectShowSceneCommandForm';
import SoundEffectShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SoundEffectShowSceneCommandForm';
import BackgroundMusicShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/BackgroundMusicShowSceneCommandForm';
import BackgroundMusicHideSceneCommandForm from '../../../../../../domain/forms/scene_commands/BackgroundMusicHideSceneCommandForm';
import SpeechTextShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/SpeechTextShowSceneCommandForm';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';

interface Props {
  sceneCommandForm: CompositeSequenceSceneCommandForm;
  sceneFrame: SceneFrame;
  sceneCommandIndex: number;
  waitable: boolean;
  insertSceneCommandLinksGroupProps: InsertSceneCommandLinksGroupProps;
  onChangeOrder: (positionMap: Map<Position, Position>) => void;
  onRequestOpenModal: (modalParams: ModalParams) => void;
  onForwardToCharacters: (params: {
    sceneCommandIndex?: number;
    parentSceneCommandId?: number;
    position?: Position;
  }) => void;
}

export default class CompositeSequenceSceneCommandBox extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    return !equalForKeys(this.props, nextProps, [
      'sceneCommandForm',
      'sceneFrame',
      'waitable',
      'insertSceneCommandLinksGroupProps',
    ]);
  }

  public render(): React.ReactNode {
    const {
      sceneCommandForm,
      sceneFrame,
      sceneCommandIndex,
      waitable,
      insertSceneCommandLinksGroupProps,
      onChangeOrder,
      onRequestOpenModal,
      onForwardToCharacters,
    } = this.props;
    const activePositions =
      sceneCommandForm.commandForms[0] instanceof SpeechTextShowSceneCommandForm
        ? sceneCommandForm.commandForms[0].getPositions()
        : undefined;
    return (
      <>
        {this.visibleCurrentSceneFrameBox(sceneCommandForm) ? null : (
          <CurrentSceneFrameBox
            sceneFrame={sceneFrame}
            sceneCommandIndex={sceneCommandIndex}
            parentSceneCommandForm={sceneCommandForm}
            activePositions={activePositions}
            onChangeOrder={onChangeOrder}
            onRequestOpenModal={onRequestOpenModal}
            onForwardToCharacters={onForwardToCharacters}
          />
        )}
        {sceneCommandForm.commandForms.map((commandForm, i) => {
          return commandForm instanceof CompositeParallelSceneCommandForm ? (
            <>
              <CompositeParallelSceneCommandBox
                key={commandForm.sceneCommandId}
                sceneCommandForm={commandForm}
                sceneFrame={sceneFrame}
                sceneCommandIndex={sceneCommandIndex}
                parentSceneCommandForm={sceneCommandForm}
                waitable={waitable}
                onChangeOrder={onChangeOrder}
                onRequestOpenModal={onRequestOpenModal}
                onForwardToCharacters={onForwardToCharacters}
              />
              {sceneCommandForm.commandForms.length > 1 && i == 0 ? (
                <InsertSceneCommandLinksGroup
                  style={{marginTop: 16}}
                  {...insertSceneCommandLinksGroupProps}
                  insertIntoCompositeSequence={true}
                />
              ) : null}
            </>
          ) : commandForm instanceof CharacterShowSceneCommandForm ||
            commandForm instanceof CharacterUpdateSceneCommandForm ? (
            <CharacterShowOrUpdateSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              sceneFrame={sceneFrame}
              sceneCommandIndex={sceneCommandIndex}
              waitable={waitable}
              parentSceneCommandForm={sceneCommandForm}
              onChangeOrder={onChangeOrder}
              onRequestOpenModal={onRequestOpenModal}
              onForwardToCharacters={onForwardToCharacters}
            />
          ) : commandForm instanceof CharacterHideSceneCommandForm ? (
            <CharacterHideSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              sceneFrame={sceneFrame}
              sceneCommandIndex={sceneCommandIndex}
              parentSceneCommandForm={sceneCommandForm}
              onChangeOrder={onChangeOrder}
              onRequestOpenModal={onRequestOpenModal}
              onForwardToCharacters={onForwardToCharacters}
            />
          ) : commandForm instanceof DescriptiveTextShowSceneCommandForm ? (
            <DescriptiveTextShowSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              parentSceneCommandForm={sceneCommandForm}
              onRequestOpenModal={onRequestOpenModal}
            />
          ) : commandForm instanceof
            FullScreenIllustrationShowSceneCommandForm ? (
            <FullScreenIllustrationShowSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              parentSceneCommandForm={sceneCommandForm}
              onRequestOpenModal={onRequestOpenModal}
            />
          ) : commandForm instanceof IllustrationShowSceneCommandForm ? (
            <IllustrationShowSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              parentSceneCommandForm={sceneCommandForm}
              onRequestOpenModal={onRequestOpenModal}
            />
          ) : commandForm instanceof EffectShowSceneCommandForm ? (
            <EffectShowSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              parentSceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          ) : commandForm instanceof SoundEffectShowSceneCommandForm ? (
            <SoundEffectShowSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              parentSceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          ) : commandForm instanceof BackgroundMusicShowSceneCommandForm ? (
            <BackgroundMusicShowSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              parentSceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          ) : commandForm instanceof BackgroundMusicHideSceneCommandForm ? (
            <BackgroundMusicHideSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              parentSceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          ) : commandForm instanceof SpeechTextShowSceneCommandForm ? (
            <SpeechTextShowSceneCommandBox
              key={commandForm.sceneCommandId}
              sceneCommandForm={commandForm}
              parentSceneCommandForm={sceneCommandForm}
              sceneFrame={sceneFrame}
              onRequestOpenModal={onRequestOpenModal}
            />
          ) : null;
        })}
      </>
    );
  }

  private visibleCurrentSceneFrameBox = (
    sceneCommandForm: SceneCommandForm,
  ): boolean => {
    if (
      sceneCommandForm instanceof CompositeSequenceSceneCommandForm ||
      sceneCommandForm instanceof CompositeParallelSceneCommandForm
    ) {
      return sceneCommandForm.commandForms.some(commandForm => {
        return this.visibleCurrentSceneFrameBox(commandForm);
      });
    } else if (
      sceneCommandForm instanceof CharacterShowSceneCommandForm ||
      sceneCommandForm instanceof CharacterUpdateSceneCommandForm ||
      sceneCommandForm instanceof CharacterHideSceneCommandForm
    ) {
      return true;
    } else if (
      sceneCommandForm instanceof BackgroundMusicShowSceneCommandForm
    ) {
      return true;
    } else {
      return false;
    }
  };
}
