import * as React from 'react';

import BaseNew from '../../common/full_screen_illustrations/New';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustrationRouteProp} from '../../../../../navigators/RouteProps';

import {Params as FullScreenIllustrationIndexParams} from '../../../../../actions/full_screen_illustrations/index';
import {Params as FullScreenIllustrationCreateParams} from '../../../../../actions/full_screen_illustrations/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import * as routers from '../../../../../routers';

import CompositeSequenceSceneCommandFormFactory from '../../../../../view_models/CompositeSequenceSceneCommandFormFactory';

import FullScreenIllustration from '../../../../../../domain/entities/FullScreenIllustration';
import Sound from '../../../../../../domain/entities/Sound';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import FullScreenIllustrationShowSceneCommandForm from '../../../../../../domain/forms/scene_commands/FullScreenIllustrationShowSceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandId: number;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustrationRouteProp;
  sceneForm: SceneForm | null;
  parentSceneCommandForm: SceneCommandForm | null;
  fullScreenIllustrationsParams: FullScreenIllustrationIndexParams;
  fullScreenIllustrations: FullScreenIllustration[] | null;
}

export interface DispatchProps {
  indexFullScreenIllustrations: (
    params: FullScreenIllustrationIndexParams,
  ) => Promise<PaginatedResult<FullScreenIllustration>>;
  createFullScreenIllustration: (
    params: FullScreenIllustrationCreateParams,
  ) => Promise<FullScreenIllustration>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

interface State {}

export default class New extends React.PureComponent<Props, State> {
  private disabledSubmit = false;

  public render(): React.ReactNode {
    const {fullScreenIllustrations} = this.props;
    return (
      <BaseNew
        {...this.props}
        onSelectFullScreenIllustration={this.handleSelectFullScreenIllustration}
        onForwardFullScreenIllustrations={
          this.handleForwardFullScreenIllustrations
        }
      />
    );
  }

  private handleForwardFullScreenIllustrations = () => {
    const {navigation, route} = this.props;
    const params = route.params;
    routers.linkToSceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations(
      navigation,
      params,
    );
  };

  private handleSelectFullScreenIllustration = (
    fullScreenIllustration: FullScreenIllustration,
    sound: Sound | null,
  ): void => {
    if (this.disabledSubmit) {
      return;
    }
    this.disabledSubmit = true;
    const {navigation, route, parentSceneCommandForm, updateSceneCommandForm} =
      this.props;
    const {sceneCommandId} = route.params;
    const sceneCommandForm = new FullScreenIllustrationShowSceneCommandForm(
      fullScreenIllustration,
      sound,
      null,
      null,
      sceneCommandId,
    );
    if (parentSceneCommandForm) {
      updateSceneCommandForm({
        sceneCommandForm: CompositeSequenceSceneCommandFormFactory.update(
          parentSceneCommandForm,
          sceneCommandForm,
        ),
      });
    } else {
      updateSceneCommandForm({
        sceneCommandForm,
      });
    }
    (navigation.getParent() || navigation).goBack();
  };
}
