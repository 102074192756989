import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../navigators/NavigationProp';

import Show, {DispatchProps, StateProps} from '../../components/stories/Show';

import AppState from '../../reducers/AppState';

import showStory from '../../actions/stories/show';
import indexEpisodes, {
  Params as EpisodeIndexParams,
} from '../../actions/episodes/index';
import destroyEpisode from '../../actions/episodes/destroy';
import indexCharacters, {
  Params as CharacterIndexParams,
} from '../../actions/characters/index';
import createAutoGenerationEpisode, {
  Params as AutoGenerationEpisodeCreateParams,
} from '../../actions/auto_generation_episodes/create';
import createEpisode, {
  Params as EpisodeCreateParams,
} from '../../actions/episodes/create';
import updateEpisode, {
  Params as EpisodeUpdateParams,
} from '../../actions/episodes/update';
import updateCharacter, {
  Params as CharacterUpdateParams,
} from '../../actions/characters/update';
import destroyCharacter from '../../actions/characters/destroy';

import selectEntities from '../../helpers/selectEntities';
import selectEntity from '../../helpers/selectEntity';

import {StoryRouteProp} from '../../navigators/RouteProps';

interface Props {
  navigation: NavigationProp;
  route: StoryRouteProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation, route} = props;
  const {entities, queries} = state;
  const {storyId} = route.params;
  const charactersParams: CharacterIndexParams = {storyId};
  const episodesParams: EpisodeIndexParams = {storyId};
  const characters = selectEntities(
    entities.characters,
    queries.characters,
    charactersParams,
  );
  const episodes = selectEntities(
    entities.episodes,
    queries.episodes,
    episodesParams,
  );
  const story = selectEntity(entities.stories, storyId);
  return {
    characters,
    charactersParams,
    episodes,
    episodesParams,
    navigation,
    story,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    showStory: (id: number) => dispatch(showStory(id)),
    indexEpisodes: (params: EpisodeIndexParams) =>
      dispatch(indexEpisodes(params)),
    destroyEpisode: (id: number) => dispatch(destroyEpisode(id)),
    indexCharacters: (params: CharacterIndexParams) =>
      dispatch(indexCharacters(params)),
    createAutoGenerationEpisode: (params: AutoGenerationEpisodeCreateParams) =>
      dispatch(createAutoGenerationEpisode(params)),
    createEpisode: (params: EpisodeCreateParams) =>
      dispatch(createEpisode(params)),
    updateEpisode: (id: number, params: EpisodeUpdateParams) =>
      dispatch(updateEpisode(id, params)),
    updateCharacter: (id: number, params: CharacterUpdateParams) =>
      dispatch(updateCharacter(id, params)),
    destroyCharacter: (id: number) => dispatch(destroyCharacter(id)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Show);
