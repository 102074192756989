import * as React from 'react';
import {StyleProp, StyleSheet, View, ViewStyle} from 'react-native';

import VoiceMicrophoneIcon from './icons/VoiceMicrophoneIcon';
import SoundIcon from './icons/SoundIcon';

interface Props {
  style?: StyleProp<ViewStyle>;
  hasSound?: boolean;
  hasVoice?: boolean;
}

const ElasticBoxBadges: React.FunctionComponent<Props> = props => {
  const {style, hasSound, hasVoice} = props;
  if (!hasSound && !hasVoice) {
    return null;
  }
  return (
    <View style={[styles.container, style]}>
      {hasSound && (
        <View style={styles.badge}>
          <SoundIcon size={11} color={'#383838'} />
        </View>
      )}
      {hasVoice && (
        <View style={styles.badge}>
          <VoiceMicrophoneIcon size={12} color={'#383838'} />
        </View>
      )}
    </View>
  );
};

export default React.memo(ElasticBoxBadges);

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 2,
    right: 28,
    width: 45,
    height: 20,
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row',
  } as ViewStyle,
  badge: {
    width: 20,
    backgroundColor: 'rgba(255, 255, 255, 0.6)',
    height: 20,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
  } as ViewStyle,
});
