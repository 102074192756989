import {AnyAction} from 'redux';
import Types from '../../../actions/Types';

import {mergeResponse, QueryState} from '../Response';

import UserNotification from '../../../../domain/entities/writer/UserNotification';
import PaginatedResult from '../../../../domain/results/PaginatedResult';

export default function reducer(
  state: QueryState = {},
  action: AnyAction,
): QueryState {
  switch (action.type) {
    case Types.IndexWriterUserNotificationsSuccess: {
      const result = action.payload as PaginatedResult<UserNotification>;
      return mergeResponse(state, result);
    }
    default:
      return state;
  }
}
