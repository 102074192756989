import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import Illustration from '../../../domain/entities/Illustration';

export default function reducer(
  state: {[key: number]: Illustration} = {},
  action: AnyAction,
): {[key: number]: Illustration} {
  switch (action.type) {
    case Types.IndexIllustrationsSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: Illustration}, record: Illustration) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    default:
      return state;
  }
}
