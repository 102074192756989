import React from 'react';

import RootStack from '../../../RootStack';
import {DefaultTransition} from '../../../BaseNavigator';

import SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration from '../../../../containers/scene_form/full_screen_illustration_show_scene_command/edit/full_screen_illustrations/New'; // tslint:disable-next-line:max-line-length
import SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations from '../../../../containers/scene_form/full_screen_illustration_show_scene_command/edit/full_screen_illustrations/Index'; // tslint:disable-next-line:max-line-length

const Stack = () => {
  return (
    <RootStack.Navigator
      screenOptions={{
        animationEnabled: true,
        headerShown: false,
        ...DefaultTransition,
      }}
      initialRouteName="SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration">
      <RootStack.Screen
        name="SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration"
        component={
          SceneFormFullScreenIllustrationShowSceneCommandEditNewFullScreenIllustration
        }
        options={{title: 'スチル'}}
      />
      <RootStack.Screen
        name="SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations"
        component={
          SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrations
        }
        options={{title: 'スチル'}}
      />
    </RootStack.Navigator>
  );
};

export default Stack;
