import * as React from 'react';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import shouldUpdateEntity from '../../shared/enhanced/shouldUpdateEntity';

import Collapsible from 'react-native-collapsible';

import Manual from '../../../../domain/entities/writer/Manual';

interface Props {
  manual: Manual;
  collapsed: boolean;
  onPressManual: (manual: Manual) => void;
}

export default class ManualListItem extends React.Component<Props> {
  public shouldComponentUpdate(nextProps: Readonly<Props>): boolean {
    if (this.props.collapsed !== nextProps.collapsed) {
      return true;
    }
    return shouldUpdateEntity(
      {entity: this.props.manual},
      {entity: nextProps.manual},
    );
  }

  public render(): React.ReactNode {
    const {manual, collapsed} = this.props;
    return (
      <Pressable onPress={this.handlePress}>
        <View style={{flex: 1}}>
          <View style={styles.container}>
            <View style={styles.containerInner}>
              <View style={styles.title}>
                <Text style={styles.titleText}>{manual.title}</Text>
              </View>
              <View
                style={[styles.arrow, collapsed && styles.arrowCollapsed]}
              />
            </View>
          </View>
          <Collapsible collapsed={collapsed}>
            <View style={styles.content}>
              <Text style={styles.contentText}>{manual.content}</Text>
            </View>
          </Collapsible>
        </View>
      </Pressable>
    );
  }

  private handlePress = () => {
    const {manual, onPressManual} = this.props;
    onPressManual(manual);
  };
}

const COLLAPSED_HEIGHT = 50;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    height: COLLAPSED_HEIGHT,
    justifyContent: 'center',
  } as ViewStyle,
  containerInner: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 16,
  } as ViewStyle,
  content: {
    padding: 16,
  } as ViewStyle,
  contentText: {
    color: '#222222',
    fontSize: 15,
    lineHeight: 20,
  } as TextStyle,
  title: {
    flex: 1,
    marginRight: 5,
  } as ViewStyle,
  titleText: {
    color: '#222222',
    fontSize: 13,
    fontWeight: 'bold',
    ...Platform.select({
      web: {
        userSelect: 'none',
        cursor: 'pointer',
      },
      default: {},
    }),
  } as TextStyle,
  arrow: {
    width: 9,
    height: 9,
    borderColor: '#999999',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
    transform: [{rotate: `225deg`}],
  } as ViewStyle,
  arrowCollapsed: {
    transform: [{rotate: `45deg`}],
  } as ViewStyle,
});
