import {AnyAction} from 'redux';
import Types from '../../actions/Types';

import Scene from '../../../domain/entities/Scene';

export default function reducer(
  state: {[key: number]: Scene} = {},
  action: AnyAction,
): {[key: number]: Scene} {
  switch (action.type) {
    case Types.ResetWriterCurrentUser:
      return {};
    case Types.IndexScenesSuccess:
      return {
        ...state,
        ...action.payload.records.reduceRight(
          (obj: {[key: number]: Scene}, record: Scene) => {
            obj[record.id] = record;
            return obj;
          },
          {},
        ),
      };
    case Types.ShowSceneSuccess:
    case Types.CreateSceneSuccess:
    case Types.UpdateSceneSuccess:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };
    case Types.DestroySceneSuccess: {
      const deleteState = {...state};
      delete deleteState[action.payload];
      return deleteState;
    }
    default:
      return state;
  }
}
