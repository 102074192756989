import * as React from 'react';

import Form from './partials/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {CoverImageFormTextPreviewRouteProp} from '../../../navigators/RouteProps';

import {Params as CoverImageFormUpdateParams} from '../../../actions/cover_image_forms/update';

import redirectStoryCoverImageForm from '../../../helpers/redirectStoryCoverImageForm';

import File from '../../../../domain/entities/File';
import CoverImageForm from '../../../../domain/forms/CoverImageForm';
import FontColor from '../../../../domain/value_objects/FontColor';
import FontSize from '../../../../domain/value_objects/FontSize';
import WritingMode from '../../../../domain/value_objects/WritingMode';
import TextPosition from '../../../../domain/value_objects/TextPosition';
import ImageTextInfo from '../../../../domain/value_objects/ImageTextInfo';

export interface Params {
  storyId: number;
  aspectRatio: number;
  imageKey: 'coverImage' | 'image';
  text: string;
  fontColor: FontColor;
  fontSize: FontSize;
  writingMode: WritingMode;
  textPosition: TextPosition;
  fontFamily?: string;
}

export interface StateProps {
  navigation: NavigationProp;
  route: CoverImageFormTextPreviewRouteProp;
  coverImageForm: CoverImageForm | null;
}

export interface DispatchProps {
  updateCoverImageForm: (params: CoverImageFormUpdateParams) => Promise<any>;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {navigation, route, coverImageForm} = this.props;
    if (redirectStoryCoverImageForm(navigation, route, coverImageForm)) {
      return;
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, coverImageForm} = this.props;
    const {
      aspectRatio,
      imageKey,
      text,
      fontColor,
      fontSize,
      writingMode,
      textPosition,
      fontFamily,
    } = route.params;
    return (
      <Layout
        title={'テキスト入力'}
        navigation={navigation}
        back={true}
        close={true}>
        {coverImageForm && (
          <Form
            coverImageForm={coverImageForm}
            aspectRatio={aspectRatio}
            imageKey={imageKey}
            newImageTextInfo={{
              text,
              fontColor,
              fontFrameColor: FontColor.None,
              fontSize,
              writingMode,
              textPosition,
              fontFamily,
            }}
            onSubmit={this.handleSubmit}
          />
        )}
      </Layout>
    );
  }

  private handleSubmit = async (
    image: File,
    imageTextInfoList: ImageTextInfo[],
  ) => {
    const {navigation, coverImageForm, updateCoverImageForm} = this.props;
    if (!coverImageForm) {
      return;
    }
    const backgroundImageUri = await coverImageForm.getBackgroundImageUri();
    updateCoverImageForm({
      image,
      imageTextInfoList,
      ...(backgroundImageUri
        ? {}
        : {
            backgroundImageUri:
              'data:image/gif;base64,R0lGODlhAQABAGAAACH5BAEKAP8ALAAAAAABAAEAAAgEAP8FBAA7',
          }),
    });
    navigation.getParent()?.goBack();
  };
}
