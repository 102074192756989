import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../navigators/NavigationProp';

import New, {
  DispatchProps,
  StateProps,
} from '../../../components/writer/user_passwords/Edit';

import AppState from '../../../reducers/AppState';

import updateUserPassword, {
  Params as UserPasswordUpdateParams,
} from '../../../actions/writer/users/password/update';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  return {
    navigation,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    updateUserPassword: (params: UserPasswordUpdateParams) =>
      dispatch(updateUserPassword(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(New);
