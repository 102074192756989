import * as React from 'react';
import {FlatList, ListRenderItemInfo, Text, View} from 'react-native';

import EpisodeListItem from './EpisodeListItem';

import ListItemNormalSeparator from '../../../shared/lists/separators/ListItemNormalSeparator';

import Episode from '../../../../../domain/entities/Episode';
import ProjectUserStatus from '../../../../../domain/entities/ProjectUserStatus';

interface Props {
  episodes: Episode[];
  bestEpisodeId: number | null;
  userStatus: ProjectUserStatus;
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
  onUpdateEpisode: (episode: Episode) => void;
  onCreateStoryBestEpisode: (episode: Episode) => void;
  onDestroyStoryBestEpisode: (episode: Episode) => void;
}

const EpisodeList: React.FC<Props> = props => {
  const {
    episodes,
    bestEpisodeId,
    userStatus,
    ListHeaderComponent,
    onUpdateEpisode,
    onCreateStoryBestEpisode,
    onDestroyStoryBestEpisode,
  } = props;
  const renderItem = React.useCallback(
    (info: ListRenderItemInfo<Episode>) => (
      <EpisodeListItem
        episode={info.item}
        best={bestEpisodeId === info.item.id}
        userStatus={userStatus}
        onUpdateEpisode={onUpdateEpisode}
        onCreateStoryBestEpisode={onCreateStoryBestEpisode}
        onDestroyStoryBestEpisode={onDestroyStoryBestEpisode}
      />
    ),
    [bestEpisodeId],
  );
  if (episodes.length === 0) {
    return (
      <>
        {ListHeaderComponent}
        <NoEpisodeList />
      </>
    );
  }
  return (
    <FlatList
      data={episodes}
      renderItem={renderItem}
      ItemSeparatorComponent={ListItemNormalSeparator}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={ListItemNormalSeparator}
      ListEmptyComponent={<NoEpisodeList />}
      extraData={bestEpisodeId}
    />
  );
};

export default React.memo(EpisodeList);

const NoEpisodeList: React.FC = () => {
  return (
    <View
      style={{
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Text style={{color: '#222', fontSize: 14}}>エピソードはありません</Text>
    </View>
  );
};
