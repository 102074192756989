import {Platform} from 'react-native';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RestApi, {Options, Result} from './RestApi';

import {camelizeKeys, decamelizeKeys} from 'humps';

// tslint:disable-next-line:no-implicit-dependencies
import Config from 'react-native-config';

interface Params {
  [key: string]: any;
}

export type Result<T> = Result<T>;

export default class TapNovelRestApi extends RestApi {
  public static get<T>(path: string, params?: Params): Promise<Result<T>> {
    return super
      .get(this.getUrl(path), this.getParams(params))
      .catch(this.converErrors);
  }

  public static post<T>(
    path: string,
    params?: Params,
    options?: Options,
  ): Promise<Result<T>> {
    return super
      .post(this.getUrl(path), this.getParams(params), options)
      .catch(this.converErrors);
  }

  public static patch<T>(
    path: string,
    params?: Params,
    options?: Options,
  ): Promise<Result<T>> {
    return super
      .patch(this.getUrl(path), this.getParams(params), options)
      .catch(this.converErrors);
  }

  public static delete(
    path: string,
    params?: Params,
    options?: Options,
  ): Promise<void> {
    return super
      .delete(this.getUrl(path), this.getParams(params), options)
      .catch(this.converErrors);
  }

  public static getUrl(path: string) {
    if (Platform.OS === 'web') {
      return process.env.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
    } else {
      return Config.REACT_APP_TAPNOVEL_API_ENDPOINT + path;
    }
  }

  public static getParams(params?: Params): Params | undefined {
    if (!params) {
      return undefined;
    }
    return decamelizeKeys(params);
  }

  private static converErrors(errors: any): Promise<any> {
    if (errors.body) {
      if (errors.body.errors) {
        // eslint-disable-next-line
        throw {
          body: camelizeKeys(errors.body.errors),
          headers: errors.headers,
          status: errors.status,
        };
      } else {
        // eslint-disable-next-line
        throw {
          body: camelizeKeys(errors.body),
          headers: errors.headers,
          status: errors.status,
        };
      }
    } else {
      throw errors;
    }
  }
}
