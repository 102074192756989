import * as React from 'react';

import Form, {FormValues} from './partials/Form';
import FoldableCommentDetail from '../topic_comments/partials/FoldableCommentDetail';

import AlertModal from '../../shared/modals/AlertModal';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewProjectTopicCommentReplyRouteProp} from '../../../navigators/RouteProps';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import ProjectTopicComment from '../../../../domain/entities/ProjectTopicComment';

import TapNovelTypedRestApi from '../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  projectId: number;
  topicId: number;
  commentId: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewProjectTopicCommentReplyRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const New: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {commentId} = route.params;
  const [comment, setComment] = React.useState<ProjectTopicComment | null>(
    null,
  );
  const [alertMessage, setAlertMessage] = React.useState<string | null>(null);
  const onCloseModalAlert = React.useCallback(() => {
    setAlertMessage(null);
  }, []);
  const onPressClose = React.useCallback(() => navigation.goBack(), []);
  const onSubmit = React.useCallback((values: FormValues) => {
    TapNovelTypedRestApi.post('/api/writer/project_topic_comment_replies', {
      projectTopicCommentReply: {...values},
      projectTopicCommentId: commentId,
    })
      .then(() => navigation.goBack())
      .catch(e => {
        setAlertMessage(formatErrorMessages({}, e));
      });
  }, []);
  React.useEffect(() => {
    TapNovelTypedRestApi.get<ProjectTopicComment>(
      `/api/writer/project_topic_comments/${commentId}`,
      {},
    ).then(result => setComment(result.body));
  }, []);
  return (
    <Layout
      title={'返信'}
      navigation={navigation}
      close={true}
      onPressClose={onPressClose}>
      {comment && (
        <>
          <FoldableCommentDetail comment={comment} />
          <Form onSubmit={onSubmit} />
          {alertMessage && (
            <AlertModal visible={true} onCloseModal={onCloseModalAlert}>
              {alertMessage}
            </AlertModal>
          )}
        </>
      )}
    </Layout>
  );
};

export default React.memo(New);
