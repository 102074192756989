import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import ActorCharacterMapper, {
  ActorCharacterData,
} from '../../entities/mappers/ActorCharacterMapper';

import ActorCharacter from '../../../domain/entities/ActorCharacter';
import ActorCharactersRepository from '../../../domain/repositories/writer/ActorCharactersRepository';

export default class NetActorCharactersRepository
  extends NetResourcesRepository<ActorCharacter, ActorCharacterData>
  implements ActorCharactersRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/actor_characters',
        new ActorCharacterMapper(),
      ),
    );
  }

  public create(
    params: {
      actorCharacter: {
        actorId: number;
        actorHairStyleId: number;
        actorCostumeId: number;
        eyeColorPatternId: number;
      };
    },
    options?: {[key: string]: any},
  ): Promise<ActorCharacter> {
    return super.create(params, options);
  }
}
