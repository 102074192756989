import * as React from 'react';

import PrimaryButton from './PrimaryButton';

interface Props {
  disabled: boolean;
  onPress: () => void;
}

const EntitiesSearchButton: React.FunctionComponent<Props> = props => {
  const {disabled, onPress} = props;
  return (
    <PrimaryButton
      buttonSize={BUTTON_SIZE}
      disabled={disabled}
      onPress={onPress}>
      この条件で検索
    </PrimaryButton>
  );
};

export default React.memo(EntitiesSearchButton);

const BUTTON_SIZE = {
  width: 240,
  height: 32,
};
