import * as React from 'react';

import PasswordChangeModal from './partials/PasswordChangeModal';
import SentVerifyEmailModal from './partials/SentVerifyEmailModal';
import Settings from './partials/Settings';
import Alert from '../../shared/alert/Alert';

import Layout from '../../shared/Layout';

import {formatErrorMessages} from '../../../helpers/errorMessages';

import NavigationProp from '../../../navigators/NavigationProp';
import {WriterUserAccountRouteProp} from '../../../navigators/RouteProps';

import {
  linkToEditWriterUserRegistrationEmail,
  linkToEditWriterUserRegistrationPassword,
} from '../../../routers';

import {Params as WriterOauthAccountIndexParams} from '../../../actions/writer/oauth_accounts/index';
import {Params as WriterFacebookAccountCreateParams} from '../../../actions/writer/social_accounts/facebook_accounts/registration/create';
import {Params as WriterTwitterAccountCreateParams} from '../../../actions/writer/social_accounts/twitter_accounts/registration/create';
import {Params as WriterLineAccountCreateParams} from '../../../actions/writer/social_accounts/line_accounts/registration/create';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import OauthAccount from '../../../../domain/entities/OauthAccount';
import PaginatedResult from '../../../../domain/results/PaginatedResult';
import FacebookLogin from '../../../../domain/services/social_logins/FacebookLogin';
import TwitterLogin from '../../../../domain/services/social_logins/TwitterLogin';
import LineLogin from '../../../../domain/services/social_logins/LineLogin';

import {isSafari} from '../../../../data/data_stores/net/UserAgent';

export interface Params {
  showSentVerifyEmailModal?: boolean;
  showPasswordChangeModal?: boolean;
}

export interface StateProps {
  navigation: NavigationProp;
  route: WriterUserAccountRouteProp;
  oauthAccounts: OauthAccount[] | null;
  currentUser: CurrentUser | null;
  writerOauthAccounsParams: WriterOauthAccountIndexParams;
}

export interface DispatchProps {
  indexWriterOauthAccounts: (
    params: WriterOauthAccountIndexParams,
  ) => Promise<PaginatedResult<OauthAccount>>;
  createWriterFacebookAccount: (
    params: WriterFacebookAccountCreateParams,
  ) => Promise<OauthAccount>;
  destroyWriterFacebookAccount: (id: number) => Promise<void>;
  createWriterTwitterAccount: (
    params: WriterTwitterAccountCreateParams,
  ) => Promise<OauthAccount>;
  destroyWriterTwitterAccount: (id: number) => Promise<void>;
  createWriterLineAccount: (
    params: WriterLineAccountCreateParams,
  ) => Promise<OauthAccount>;
  destroyWriterLineAccount: (id: number) => Promise<void>;
}

interface Props extends StateProps, DispatchProps {}

export default class Show extends React.PureComponent<Props> {
  public componentDidMount() {
    const {indexWriterOauthAccounts, writerOauthAccounsParams} = this.props;
    indexWriterOauthAccounts(writerOauthAccounsParams);
    if (isSafari) {
      new TwitterLogin().getRequestToken();
    }
  }

  public render(): React.ReactNode {
    const {navigation, route, currentUser, oauthAccounts} = this.props;
    const {showSentVerifyEmailModal, showPasswordChangeModal} =
      route.params || {};
    return (
      <Layout title={'アカウント設定'} navigation={navigation} back={true}>
        {currentUser && (
          <Settings
            currentUser={currentUser}
            oauthAccounts={oauthAccounts}
            onPressEmailChange={this.handlePressEmailChange}
            onPressPasswordChange={this.handlePressPasswordChange}
            onPressFacebookLogin={this.handlePressFacebookLogin}
            onPressTwitterLogin={this.handlePressTwitterLogin}
            onPressLineLogin={this.handlePressLineLogin}
          />
        )}
        {showSentVerifyEmailModal && (
          <SentVerifyEmailModal
            visible={true}
            onRequestClose={this.handleRequestCloseSentVerifyEmailModal}
          />
        )}
        {showPasswordChangeModal && (
          <PasswordChangeModal
            visible={true}
            onRequestClose={this.handleRequestClosePasswordChangeModal}
          />
        )}
      </Layout>
    );
  }

  private handlePressEmailChange = () => {
    const {navigation} = this.props;
    linkToEditWriterUserRegistrationEmail(navigation);
  };

  private handlePressPasswordChange = () => {
    const {navigation} = this.props;
    linkToEditWriterUserRegistrationPassword(navigation);
  };

  private handlePressFacebookLogin = () => {
    const {
      oauthAccounts,
      createWriterFacebookAccount,
      destroyWriterFacebookAccount,
    } = this.props;
    if (!oauthAccounts) {
      return;
    }
    const facebookOauthAccount = oauthAccounts.find(
      oauthAccount => oauthAccount.oauthProviderCode === 'facebook',
    );
    if (facebookOauthAccount) {
      Alert.alert('確認', 'Facebookとの連携を解除しますか？', [
        {text: 'キャンセル', onPress: () => {}, style: 'cancel'},
        {
          text: '解除する',
          onPress: () => {
            destroyWriterFacebookAccount(facebookOauthAccount.id);
          },
        },
      ]);
      return;
    }
    new FacebookLogin().login({
      onError: () => {
        Alert.alert('連携できませんでした');
      },
      onSuccess: data => {
        if (!data.accessToken) {
          return;
        }
        createWriterFacebookAccount({
          accessToken: data.accessToken,
          screenName: data.screenName,
          userId: data.id,
        }).catch(error => {
          Alert.alert(formatErrorMessages({}, error));
        });
      },
    });
  };

  private handlePressTwitterLogin = () => {
    const {
      oauthAccounts,
      createWriterTwitterAccount,
      destroyWriterTwitterAccount,
    } = this.props;
    if (!oauthAccounts) {
      return;
    }
    const twitterOauthAccount = oauthAccounts.find(
      oauthAccount => oauthAccount.oauthProviderCode === 'twitter',
    );
    if (twitterOauthAccount) {
      Alert.alert('確認', 'Twitterとの連携を解除しますか？', [
        {text: 'キャンセル', onPress: () => {}, style: 'cancel'},
        {
          text: '解除する',
          onPress: () => {
            destroyWriterTwitterAccount(twitterOauthAccount.id);
          },
        },
      ]);
      return;
    }
    new TwitterLogin().login({
      onError: () => {
        Alert.alert('連携できませんでした');
      },
      onSuccess: data => {
        if (!data.accessToken || !data.accessTokenSecret) {
          return;
        }
        createWriterTwitterAccount({
          accessToken: data.accessToken,
          accessTokenSecret: data.accessTokenSecret,
          screenName: data.screenName,
          userId: data.id,
        }).catch(error => {
          Alert.alert(formatErrorMessages({}, error));
        });
      },
    });
  };

  private handlePressLineLogin = () => {
    const {oauthAccounts, createWriterLineAccount, destroyWriterLineAccount} =
      this.props;
    if (!oauthAccounts) {
      return;
    }
    const lineOauthAccount = oauthAccounts.find(
      oauthAccount => oauthAccount.oauthProviderCode === 'line',
    );
    if (lineOauthAccount) {
      Alert.alert('確認', 'Lineとの連携を解除しますか？', [
        {text: 'キャンセル', onPress: () => {}, style: 'cancel'},
        {
          text: '解除する',
          onPress: () => {
            destroyWriterLineAccount(lineOauthAccount.id);
          },
        },
      ]);
      return;
    }
    new LineLogin().login({
      onError: () => {
        Alert.alert('連携できませんでした');
      },
      onSuccess: data => {
        if (!data.accessToken) {
          return;
        }
        createWriterLineAccount({
          accessToken: data.accessToken,
          screenName: data.screenName,
          userId: data.id,
        }).catch(error => {
          Alert.alert(formatErrorMessages({}, error));
        });
      },
    });
  };

  private handleRequestCloseSentVerifyEmailModal = () => {
    const {navigation} = this.props;
    navigation.setParams({showSentVerifyEmailModal: false});
  };

  private handleRequestClosePasswordChangeModal = () => {
    const {navigation} = this.props;
    navigation.setParams({showPasswordChangeModal: false});
  };
}
