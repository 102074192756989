import React from 'react';
import {Platform} from 'react-native';
import {TransitionPresets} from '@react-navigation/stack';

import RootStack from './RootStack';
import CommonScreens from './screens/PublicScreens';
import MemberOnlyScreens from './screens/MemberOnlyScreens';
import ProOnlyScreens from './screens/ProOnlyScreens';

export const DefaultTransition = Platform.select({
  android: TransitionPresets.FadeFromBottomAndroid,
  web: TransitionPresets.FadeFromBottomAndroid,
  default: TransitionPresets.DefaultTransition,
});

const screenOptions = {
  animationEnabled: true,
  headerShown: false,
  cardStyle: {backgroundColor: 'transparent'},
  ...DefaultTransition,
};

export interface Props {
  signedIn: boolean;
  isPro: boolean;
}

const BaseNavigator = (props: Props) => {
  const {signedIn, isPro} = props;
  return (
    <RootStack.Navigator screenOptions={screenOptions} initialRouteName="Home">
      {CommonScreens}
      {signedIn && MemberOnlyScreens}
      {isPro && ProOnlyScreens}
    </RootStack.Navigator>
  );
};

export default React.memo(BaseNavigator);
