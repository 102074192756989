import * as React from 'react';

import DetailIdea from '../../manuscripts/partials/DetailIdea';
import DetailPlot from '../../manuscripts/partials/DetailPlot';
import DetailEpisode from '../../manuscripts/partials/DetailEpisode';

import Layout from '../../../shared/Layout';
import NavigationProp from '../../../../navigators/NavigationProp';
import {ProjectUserManuscriptRouteProp} from '../../../../navigators/RouteProps';

import ProjectManuscript from '../../../../../domain/entities/ProjectManuscript';
import TapNovelTypedRestApi from '../../../../../data/data_stores/net/TapNovelTypedRestApi';

export interface Params {
  id: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: ProjectUserManuscriptRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const Show: React.FC<Props> = props => {
  const {navigation, route} = props;
  const {id} = route.params;
  const [manuscript, setManuscript] = React.useState<ProjectManuscript | null>(
    null,
  );
  React.useEffect(() => {
    TapNovelTypedRestApi.get<ProjectManuscript>(
      `/api/writer/user/project_manuscripts/${id}`,
    ).then(result => {
      setManuscript(result.body);
    });
  }, []);
  return (
    <Layout
      title={`${manuscript?.typeNameLong}`}
      navigation={navigation}
      back={true}
      enabledUserSelect={true}>
      {manuscript &&
        (manuscript.typeCode === 'idea' ? (
          <DetailIdea manuscript={manuscript} disabledLike={true} />
        ) : manuscript.typeCode === 'plot' ? (
          <DetailPlot manuscript={manuscript} disabledLike={true} />
        ) : manuscript.typeCode === 'episode' ? (
          <DetailEpisode manuscript={manuscript} disabledLike={true} />
        ) : null)}
    </Layout>
  );
};

export default React.memo(Show);
