import NetResourcesRepository from '../NetResourcesRepository';
import NetResourcesRepositoryAdapter from '../NetResourcesRepositoryAdapter';

import SceneScriptMapper, {
  SceneScriptData,
} from '../../entities/mappers/SceneScriptMapper';

import SceneScript from '../../../domain/entities/SceneScript';
import SceneScriptsRepository from '../../../domain/repositories/writer/SceneScriptsRepository';

export default class NetSceneScriptsRepository
  extends NetResourcesRepository<SceneScript, SceneScriptData>
  implements SceneScriptsRepository
{
  constructor() {
    super(
      new NetResourcesRepositoryAdapter(
        '/api/writer/scene_scripts',
        new SceneScriptMapper(),
      ),
    );
  }
}
