import * as React from 'react';
import {
  ScrollView,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import PrimaryButton from '../../../shared/buttons/PrimaryButton';
import SvgUriAdapter from '../../../shared/svg/SvgUriAdapter';
import CheckButton from '../../../shared/forms/CheckButton';

import Tutorial3Uri from '../../../../../assets/svgs/projects/tutorial-3.svg';

interface Props {
  onPress: () => void;
}

const ServiceExplanationStep3: React.FC<Props> = props => {
  const {onPress} = props;
  const [checked, setChecked] = React.useState(false);
  const onPressCheck = React.useCallback(() => {
    setChecked(!checked);
  }, [checked]);
  return (
    <ScrollView>
      <View style={styles.container}>
        <Text style={styles.message}>
          プロジェクトのルールや{'\n'}
          提出した作品の権利についても、{'\n'}
          プロジェクトのリーダーが定めます。
        </Text>
        <Text style={styles.message}>
          それぞれのプロジェクトの要綱をよく読み、{'\n'}
          理解した上で参加するようにしてください。
        </Text>
        <View style={styles.image}>
          <SvgUriAdapter uri={Tutorial3Uri} width={220} height={210} />
        </View>
        <CheckButton
          style={styles.checkButton}
          checked={checked}
          onPress={onPressCheck}>
          理解した
        </CheckButton>
        <PrimaryButton disabled={!checked} onPress={onPress}>
          次へ
        </PrimaryButton>
      </View>
    </ScrollView>
  );
};

export default React.memo(ServiceExplanationStep3);

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    borderTopColor: '#efefef',
    borderTopWidth: 1,
    flex: 1,
    alignItems: 'center',
    paddingVertical: 24,
  } as ViewStyle,
  message: {
    color: '#383838',
    fontSize: 14,
    marginHorizontal: 24,
    marginBottom: 8,
  } as TextStyle,
  image: {
    marginTop: 8,
    marginBottom: 24,
  } as ViewStyle,
  checkButton: {
    width: 160,
    justifyContent: 'center',
    marginTop: 8,
    marginBottom: 16,
  } as ViewStyle,
});
