import * as React from 'react';

import EntityImageGridListWithModal from '../../../../shared/EntityImageGridListWithModal';
import Layout from '../../../../shared/Layout';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrationsRouteProp,
  SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrationsRouteProp,
} from '../../../../../navigators/RouteProps';

import {fullScreenIllustrationImageUrl} from '../../../../../helpers/images';
import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import {Params as FullScreenIllustrationIndexParams} from '../../../../../actions/full_screen_illustrations/index';

import FullScreenIllustration from '../../../../../../domain/entities/FullScreenIllustration';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormFullScreenIllustrationShowSceneCommandNewFullScreenIllustrationsRouteProp
    | SceneFormFullScreenIllustrationShowSceneCommandEditFullScreenIllustrationsRouteProp;
  sceneForm: SceneForm | null;
  fullScreenIllustrationsParams: FullScreenIllustrationIndexParams;
  fullScreenIllustrations: FullScreenIllustration[] | null;
}

export interface DispatchProps {
  indexFullScreenIllustrations: (
    params: FullScreenIllustrationIndexParams,
  ) => Promise<PaginatedResult<FullScreenIllustration>>;
  onSelectFullScreenIllustration: (
    fullScreenIllustration: FullScreenIllustration,
  ) => void;
}

interface Props extends StateProps, DispatchProps {}

export default class Index extends React.PureComponent<Props> {
  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      fullScreenIllustrationsParams,
      fullScreenIllustrations,
      indexFullScreenIllustrations,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!fullScreenIllustrations) {
      indexFullScreenIllustrations(fullScreenIllustrationsParams);
    }
  }

  public render(): React.ReactNode {
    const {
      navigation,
      fullScreenIllustrations,
      onSelectFullScreenIllustration,
    } = this.props;

    const title = 'スチル';
    return (
      <Layout title={title} navigation={navigation} close={true}>
        {fullScreenIllustrations && (
          <EntityImageGridListWithModal
            entities={fullScreenIllustrations}
            onSelectEntity={onSelectFullScreenIllustration}
            imageUrlExtractor={this.imageUrlExtractor}
          />
        )}
      </Layout>
    );
  }
  private imageUrlExtractor = (
    fullScreenIllustration: FullScreenIllustration,
    width: number,
  ) => {
    return fullScreenIllustrationImageUrl(fullScreenIllustration, 'middle');
  };
}
