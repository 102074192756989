import * as React from 'react';
import {
  GestureResponderEvent,
  Pressable,
  StyleSheet,
  Text,
  TextStyle,
  View,
  ViewStyle,
} from 'react-native';

import CloseIcon from '../icons/CloseIcon';

import {colors} from '../../../styles/variables';

interface Props {
  title?: string;
  onCloseModal?: (event: GestureResponderEvent) => void;
  children?: React.ReactNode;
}

const ModalHeader: React.FunctionComponent<Props> = props => {
  const {title, onCloseModal} = props;
  if (!title && !onCloseModal) {
    return null;
  }
  return (
    <View style={styles.container}>
      {title && <Text style={styles.containerTitle}>{title}</Text>}
      {onCloseModal && (
        <View style={styles.containerRight}>
          <Pressable
            onPress={event => {
              event.stopPropagation();
              event.preventDefault();
              onCloseModal && onCloseModal(event);
            }}>
            <View style={styles.close}>
              <CloseIcon size={14} color={colors.lightGray} />
            </View>
          </Pressable>
        </View>
      )}
    </View>
  );
};

export default React.memo(ModalHeader);

const styles = StyleSheet.create({
  close: {
    flex: 1,
    width: 44,
    justifyContent: 'center',
    alignItems: 'center',
  } as ViewStyle,
  container: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 42,
    justifyContent: 'center',
    width: '100%',
  } as ViewStyle,
  containerRight: {
    alignItems: 'center',
    height: 42,
    justifyContent: 'center',
    position: 'absolute',
    right: 0,
    width: 42,
  } as ViewStyle,
  containerTitle: {
    color: colors.textColor,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  } as TextStyle,
});
