import * as React from 'react';

import Icon from './Icon';

import {colors} from '../../../styles/variables';

interface Props {
  size?: number;
  color?: string;
  children?: React.ReactNode;
}

const PlusCircleSolidIcon: React.FunctionComponent<Props> = props => {
  const size = props.size || 9;
  const color = props.color || colors.gray;
  return <Icon name={'plus_circle_solid'} size={size} color={color} />;
};

export default React.memo(PlusCircleSolidIcon);
