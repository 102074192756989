import SceneCommand from './SceneCommand';

import Illustration from '../../entities/Illustration';
import Sound from '../../entities/Sound';

import IllustrationShowSceneCommandForm from '../../forms/scene_commands/IllustrationShowSceneCommandForm';
import SceneCommandForm from '../../forms/scene_commands/SceneCommandForm';

export default class IllustrationShowSceneCommand implements SceneCommand {
  constructor(
    public illustration: Illustration,
    public sound?: Sound | null,
    public startTime?: number | null,
    public endTime?: number | null,
  ) {}

  public getValue() {
    return {
      illustration_id: this.illustration.id,
      sound_id: this.sound?.id,
      start_time: this.startTime,
      end_time: this.endTime,
    };
  }

  public toForm(): SceneCommandForm {
    return new IllustrationShowSceneCommandForm(
      this.illustration,
      this.sound,
      this.startTime || null,
      this.endTime || null,
    );
  }
}
