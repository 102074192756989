import EntityMapper from './EntityMapper';

import ActorCharacterFace from '../../../domain/entities/ActorCharacterFace';

export interface ActorCharacterFaceData {
  id: number;
  actor_character_id: number;
  face_name: string;
  original_image_url: string;
  face_center_position?: {
    top: number;
    left: number;
  };
}

export default class ActorCharacterFaceMapper
  implements EntityMapper<ActorCharacterFaceData, ActorCharacterFace>
{
  public map(obj: ActorCharacterFaceData): ActorCharacterFace {
    return new ActorCharacterFace(
      obj.id,
      obj.actor_character_id,
      obj.face_name,
      obj.original_image_url,
      obj.face_center_position,
    );
  }
}
