export type Status = 'draft' | 'open' | 'paid' | 'void' | 'uncollectible';

export default class Invoice {
  constructor(
    public id: number,
    public myStripeSubscriptionId: number,
    public issuedAt: Date | null,
    public dataNumber: string,
    public dataTotal: number,
    public apiWriterReceiptUrl: string,
    public status: Status,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
