import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../components/scene_form/background_show_scene_command/new/background_filters/Index';

import AppState from '../../../../../reducers/AppState';

import updateSceneForm, {
  Params as SceneFormUpdateParams,
} from '../../../../../actions/scene_forms/update';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {forms} = state;
  const sceneForm = forms.sceneForm;
  return {
    navigation,
    sceneForm,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    updateSceneForm: (params: SceneFormUpdateParams) =>
      dispatch(updateSceneForm(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
