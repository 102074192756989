import * as React from 'react';
import {CommonActions} from '@react-navigation/native';

import Content from './partials/Content';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {WriterTemporaryUserRegistrationCompletionRouteProp} from '../../../navigators/RouteProps';

import * as routers from '../../../routers';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: WriterTemporaryUserRegistrationCompletionRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  showCurrentUser: () => Promise<CurrentUser>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  refreshing: boolean;
}

export default class Index extends React.PureComponent<Props, State> {
  private intervalTime = 1000;

  constructor(props: Props) {
    super(props);
    this.state = {refreshing: false};
  }

  public componentDidMount() {
    this.checkEmailConfirmed();
  }

  public render(): React.ReactNode {
    const {navigation, currentUser} = this.props;
    const {refreshing} = this.state;
    return (
      <Layout
        title={'仮会員登録完了'}
        scrollable={false}
        navigation={navigation}
        back={true}>
        <Content
          currentUser={currentUser}
          refreshing={refreshing}
          onRefresh={this.handleRefresh}
          onPressResendMail={this.handlePressResendMail}
          onPressSignUpOtherEmail={this.handlePressSignUpOtherEmail}
        />
      </Layout>
    );
  }

  private handleRefresh = () => {
    this.setState({refreshing: true});
    this.checkEmailConfirmed();
  };

  private checkEmailConfirmedForPeriodically = () => {
    if (this.intervalTime > 60000) {
      return;
    }
    setTimeout(() => {
      this.intervalTime = this.intervalTime * 1.5;
      this.checkEmailConfirmed();
      this.checkEmailConfirmedForPeriodically();
    }, this.intervalTime);
  };

  private checkEmailConfirmed = () => {
    const {navigation, showCurrentUser} = this.props;
    showCurrentUser()
      .then(currentUser => {
        setTimeout(() => {
          this.setState({refreshing: false}, () => {
            if (currentUser.confirmed) {
              setTimeout(() => {
                navigation.dispatch(
                  CommonActions.reset({
                    index: 0,
                    routes: [{params: {}, name: 'Home'}],
                  }),
                );
                routers.replaceToEditWriterUser(navigation, {});
              }, 300);
            }
          });
        }, 300);
      })
      .catch(() => {
        this.setState({refreshing: false});
      });
  };

  private handlePressResendMail = () => {
    const {navigation} = this.props;
    navigation.navigate('NewWriterUserConfirmation', {});
  };

  private handlePressSignUpOtherEmail = () => {
    const {navigation} = this.props;
    navigation.navigate('NewWriterUserRegistration', {});
  };
}
