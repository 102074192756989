import Background from '../entities/Background';
import PositionedEffect from '../entities/PositionedEffect';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Scene, {Filter, Options, EffectOptions} from '../entities/Scene';

export type Filter = Filter;
export type Options = Options;
export type EffectOptions = EffectOptions;

export default class SceneForm {
  public id: number | null = null;
  public background: Background | null = null;
  public positionedEffect: PositionedEffect | null = null;
  public options: Options | null = null;
  public effectOptions: EffectOptions | null = null;

  constructor(public storyId: number, public episodeId: number) {}

  public bind(scene: Scene) {
    this.id = scene.id;
    this.storyId = scene.storyId;
    this.episodeId = scene.episodeId;
    this.background = scene.background;
    this.positionedEffect = scene.positionedEffect;
    this.options = scene.options;
    this.effectOptions = scene.effectOptions;
  }

  public toParams(): {[key: string]: any} {
    return {
      background_id: this.background ? this.background.id : null,
      episode_id: this.episodeId,
      id: this.id,
      options: this.options,
      positioned_effect_id: this.positionedEffect?.id,
      effect_options: this.effectOptions,
      story_id: this.storyId,
    };
  }
}
