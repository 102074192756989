import EntityMapper from '../EntityMapper';

import Help from '../../../../domain/entities/writer/Help';

import HelpCategoryMapper, {HelpCategoryData} from './HelpCategoryMapper';

export interface HelpData {
  id: number;
  title: string;
  content: string;
  writer_help_category: HelpCategoryData;
}

export default class HelpMapper implements EntityMapper<HelpData, Help> {
  private helpCategoryMapper = new HelpCategoryMapper();

  public map(obj: HelpData): Help {
    return new Help(
      obj.id,
      obj.title,
      obj.content,
      this.helpCategoryMapper.map(obj.writer_help_category),
    );
  }
}
