import * as React from 'react';
import {
  StyleSheet,
  Text,
  TextStyle,
  Pressable,
  View,
  ViewStyle,
} from 'react-native';

import FontSize from '../../../domain/value_objects/FontSize';

interface Props {
  fontSize: FontSize;
  onSelectFontSize: (fontSize: FontSize) => void;
}

export default class FontSizeButtons extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {fontSize, onSelectFontSize} = this.props;
    return (
      <View style={styles.fontSizes}>
        <Text style={styles.label}>文字サイズ</Text>
        <Pressable
          key={'small'}
          onPress={() => {
            onSelectFontSize(FontSize.Small);
          }}>
          <View style={styles.fontSize}>
            <Text
              style={[
                styles.fontSizeSmall,
                fontSize === FontSize.Small ? styles.fontSizeActive : null,
              ]}>
              小
            </Text>
          </View>
        </Pressable>
        <Pressable
          key={'middle'}
          onPress={() => {
            onSelectFontSize(FontSize.Middle);
          }}>
          <View style={styles.fontSize}>
            <Text
              style={[
                styles.fontSizeMiddle,
                fontSize === FontSize.Middle ? styles.fontSizeActive : null,
              ]}>
              中
            </Text>
          </View>
        </Pressable>
        <Pressable
          key={'large'}
          onPress={() => {
            onSelectFontSize(FontSize.Large);
          }}>
          <View style={styles.fontSize}>
            <Text
              style={[
                styles.fontSizeLarge,
                fontSize === FontSize.Large ? styles.fontSizeActive : null,
              ]}>
              大
            </Text>
          </View>
        </Pressable>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  fontSizes: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 25,
    marginHorizontal: 16,
  } as ViewStyle,
  fontSize: {
    marginHorizontal: 15,
  } as TextStyle,
  fontSizeSmall: {
    color: '#383838',
    fontSize: 12,
    fontWeight: 'bold',
  } as TextStyle,
  fontSizeMiddle: {
    color: '#383838',
    fontSize: 19,
    fontWeight: 'bold',
  } as TextStyle,
  fontSizeLarge: {
    color: '#383838',
    fontSize: 25,
    fontWeight: 'bold',
  } as TextStyle,
  fontSizeActive: {
    color: '#1e8eca',
  } as TextStyle,
  label: {
    color: '#383838',
    fontSize: 11,
    fontWeight: 'bold',
    marginRight: 4,
  } as TextStyle,
});
