import EntityMapper from './EntityMapper';

import MetaCharacterPattern from '../../../domain/entities/MetaCharacterPattern';

export interface MetaCharacterPatternData {
  id: number;
  actor_id: number;
  character_maker_only: boolean;
  color_pattern_id: number;
  hair_style_name: string;
  costume_name: string;
  face_pattern_id: number;
  eye_color_pattern_id: number;
}

export default class MetaCharacterPatternMapper
  implements EntityMapper<MetaCharacterPatternData, MetaCharacterPattern>
{
  public map(obj: MetaCharacterPatternData): MetaCharacterPattern {
    return new MetaCharacterPattern(
      obj.id,
      obj.actor_id,
      obj.character_maker_only,
      obj.color_pattern_id,
      obj.hair_style_name,
      obj.costume_name,
      obj.face_pattern_id,
      obj.eye_color_pattern_id,
    );
  }
}
