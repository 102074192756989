import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';

import NavigationProp from '../../../../../../navigators/NavigationProp';

import Index, {
  DispatchProps,
  StateProps,
} from '../../../../../../components/scene_form/character_show_scene_command/new/character_maker/home/Index';

import AppState from '../../../../../../reducers/AppState';

import indexCharacterMakerActorGroups, {
  Params as CharacterMakerActorGroupIndexParams,
} from '../../../../../../actions/character_maker_actor_groups/index';

import selectEntity from '../../../../../../helpers/selectEntity';
import selectEntities from '../../../../../../helpers/selectEntities';

interface Props {
  navigation: NavigationProp;
}

const mapStateToProps = (state: AppState, props: Props): any => {
  const {navigation} = props;
  const {entities, queries, forms, writerCurrentUserId} = state;
  const currentUser = writerCurrentUserId
    ? selectEntity(entities.writer.currentUsers, writerCurrentUserId)
    : null;

  const characterMakerActorGroupsParams: CharacterMakerActorGroupIndexParams =
    {};
  const sceneForm = forms.sceneForm;
  const characterMakerActorGroups = selectEntities(
    entities.characterMakerActorGroups,
    queries.characterMakerActorGroups,
    characterMakerActorGroupsParams,
  );
  return {
    navigation,
    sceneForm,
    currentUser,
    characterMakerActorGroups,
    characterMakerActorGroupsParams,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<AppState, void, AnyAction>,
) => {
  return {
    indexCharacterMakerActorGroups: (
      params: CharacterMakerActorGroupIndexParams,
    ) => dispatch(indexCharacterMakerActorGroups(params)),
  };
};

export default connect<StateProps, DispatchProps, Props, AppState>(
  mapStateToProps,
  mapDispatchToProps,
)(Index);
