import * as React from 'react';

import TapNovelWebView from '../../../shared/TapNovelWebView';

import NavigationProp from '../../../../navigators/NavigationProp';
import {PoliciesTermsRouteProp} from '../../../../navigators/RouteProps';

import {getUserAgent} from '../../../../../data/data_stores/net/UserAgent';

export interface Params {}

export interface StateProps {
  navigation: NavigationProp;
  route: PoliciesTermsRouteProp;
}

export interface DispatchProps {}

interface Props extends StateProps, DispatchProps {
  children?: React.ReactNode;
}

const Index: React.FunctionComponent<Props> = props => {
  const [userAgent, setUserAgent] = React.useState<string | null>(null);
  React.useEffect(() => {
    getUserAgent().then(ua => {
      setUserAgent(ua);
    });
  });
  return userAgent ? (
    <TapNovelWebView
      style={{flex: 1}}
      originWhitelist={['*']}
      path={'/writer/policies/terms'}
      userAgent={userAgent}
    />
  ) : null;
};

const areEqual = (prevProps: Readonly<Props>, nextProps: Readonly<Props>) => {
  return true;
};

export default React.memo(Index, areEqual);
