import * as React from 'react';

import IllustrationUsageHistoryList from '../../common/illustration_usage_histories/partials/IllustrationUsageHistoryList';
import BottomNewIllustrationUsageHistoryButton from '../../common/illustration_usage_histories/partials/BottomNewIllustrationUsageHistoryButton';
import NoIllustrationUsageHistories from '../../common/illustration_usage_histories/partials/NoIllustrationUsageHistories';

import Layout from '../../../../shared/Layout';
import ResourceFavoriteListLink from '../../../../shared/ResourceFavoriteListLink';
import ResourceFavoriteButton from '../../../../shared/ResourceFavoriteButton';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {
  SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistoriesRouteProp,
  SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistoriesRouteProp,
} from '../../../../../navigators/RouteProps';

import {Params as IllustrationUsageHistoryIndexParams} from '../../../../../actions/illustration_usage_histories/index';
import {Params as IllustrationUsageHistoryUpdateParams} from '../../../../../actions/illustration_usage_histories/update';
import {Params as SceneCommandFormCreateParams} from '../../../../../actions/scene_command_forms/create';
import {Params as SceneCommandFormUpdateParams} from '../../../../../actions/scene_command_forms/update';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';

import IllustrationUsageHistory from '../../../../../../domain/entities/IllustrationUsageHistory';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
  sceneCommandIndex?: number;
  back?: boolean;
  parentSceneCommandId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route:
    | SceneFormIllustrationShowSceneCommandNewIllustrationUsageHistoriesRouteProp
    | SceneFormIllustrationShowSceneCommandEditIllustrationUsageHistoriesRouteProp;
  sceneForm: SceneForm | null;
  illustrationUsageHistoriesParams: IllustrationUsageHistoryIndexParams;
  illustrationUsageHistories: IllustrationUsageHistory[] | null;
  parentSceneCommandForm: SceneCommandForm | null;
  enabledFavorite?: boolean;
}

export interface DispatchProps {
  indexIllustrationUsageHistories: (
    params: IllustrationUsageHistoryIndexParams,
  ) => Promise<PaginatedResult<IllustrationUsageHistory>>;
  createSceneCommandForm: (
    params: SceneCommandFormCreateParams,
  ) => Promise<any>;
  updateSceneCommandForm: (
    params: SceneCommandFormUpdateParams,
  ) => Promise<any>;
  updateIllustrationUsageHistory: (
    id: number,
    params: IllustrationUsageHistoryUpdateParams,
  ) => Promise<IllustrationUsageHistory>;
}

interface Props extends StateProps, DispatchProps {
  onSelectIllustrationUsageHistory: (
    illustrationUsageHistory: IllustrationUsageHistory,
  ) => void;
  onForwardToNewIllustrationUsageHistory: () => void;
  onForwardToFavoriteList?: () => void;
}

export default class Index extends React.Component<Props> {
  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      illustrationUsageHistoriesParams,
      illustrationUsageHistories,
      indexIllustrationUsageHistories,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!illustrationUsageHistories) {
      indexIllustrationUsageHistories(illustrationUsageHistoriesParams);
    }
  }

  public render(): React.ReactNode {
    const {
      route,
      navigation,
      illustrationUsageHistories,
      enabledFavorite,
      onSelectIllustrationUsageHistory,
      onForwardToNewIllustrationUsageHistory,
      onForwardToFavoriteList,
    } = this.props;

    const {back} = route.params;
    return (
      <Layout
        title={'アイテム'}
        scrollable={false}
        back={back}
        navigation={navigation}
        close={true}>
        {illustrationUsageHistories ? (
          illustrationUsageHistories.length > 0 ? (
            <>
              <IllustrationUsageHistoryList
                illustrationUsageHistories={illustrationUsageHistories}
                onSelectIllustrationUsageHistory={
                  onSelectIllustrationUsageHistory
                }
                onForwardToFavoriteList={
                  enabledFavorite ? onForwardToFavoriteList : undefined
                }
                modalRenderFavoriteButton={this.modalRenderFavoriteButton}
              />

              <BottomNewIllustrationUsageHistoryButton
                onPress={onForwardToNewIllustrationUsageHistory}
              />
            </>
          ) : (
            <>
              <NoIllustrationUsageHistories
                onForwardToNewIllustrationUsageHistory={
                  onForwardToNewIllustrationUsageHistory
                }
              />
              {enabledFavorite && (
                <ResourceFavoriteListLink
                  style={{position: 'absolute', top: 16, right: 16}}
                  onPress={onForwardToFavoriteList}
                />
              )}
            </>
          )
        ) : null}
      </Layout>
    );
  }

  private modalRenderFavoriteButton = (
    illustrationUsageHistory: IllustrationUsageHistory,
  ) => {
    const {enabledFavorite} = this.props;
    if (!enabledFavorite) {
      return null;
    }
    return (
      <ResourceFavoriteButton
        style={{top: 55, right: 10}}
        resourceType={'Illustration'}
        resourceId={illustrationUsageHistory.illustration.id}
      />
    );
  };
}
