import * as React from 'react';
import {StyleSheet, View, ViewStyle} from 'react-native';
import {
  CalendarList,
  CalendarTheme,
  DateObject,
  DotMarking,
  LocaleConfig,
} from 'react-native-calendars';

import DimensionContext from '../../../../shared/dimension/DimensionContext';

import {colors} from '../../../../../styles/variables';

interface Props {
  selectedDate: DateObject | null;
  onDayPress: (date: DateObject) => void;
}

export default class DateCalendar extends React.PureComponent<Props> {
  public render(): React.ReactNode {
    const {selectedDate, onDayPress} = this.props;
    return (
      <DimensionContext.Consumer>
        {context => (
          <CalendarList
            key={`${selectedDate?.year}-${selectedDate?.month}`}
            current={selectedDate || new Date()}
            minDate={new Date()}
            calendarWidth={context.content.width - 32}
            markedDates={this.generateMarkedDates()}
            onDayPress={onDayPress}
            monthFormat={'yyyy年MM月'}
            pastScrollRange={0}
            horizontal={true}
            pagingEnabled={true}
            hideArrows={false}
            hideExtraDays={false}
            theme={CALENDAR_THEME}
            onPressArrowLeft={this.handlePressArrowLeft}
            onPressArrowRight={this.handlePressArrowRight}
            renderArrow={this.renderArrow}
          />
        )}
      </DimensionContext.Consumer>
    );
  }

  private generateMarkedDates(): {[date: string]: DotMarking} {
    const {selectedDate} = this.props;
    return selectedDate
      ? {
          [selectedDate.dateString]: {
            selected: true,
            selectedColor: '#ff8f13',
          },
        }
      : {};
  }

  private lessThanCurrentMonth(selectedMonth: string): boolean {
    const current = new Date();
    const currentBeginningOfMonth = new Date(
      current.getFullYear(),
      current.getMonth(),
    );
    const selected = new Date(selectedMonth);
    const selectedBeginningOfMonth = new Date(
      selected.getFullYear(),
      selected.getMonth(),
    );
    return currentBeginningOfMonth >= selectedBeginningOfMonth;
  }

  private handlePressArrowLeft = (
    substractMonth: () => void,
    selectedMonth = '',
  ) => {
    if (this.lessThanCurrentMonth(selectedMonth)) {
      return;
    }
    substractMonth();
  };

  private handlePressArrowRight = (
    addMonth: () => void,
    selectedMonth = '',
  ) => {
    addMonth();
  };

  private renderArrow = (direction: 'left' | 'right', selectedMonth = '') => {
    switch (direction) {
      case 'left':
        if (this.lessThanCurrentMonth(selectedMonth)) {
          return null;
        }
        return <View style={[styles.arrow, styles.arrowLeft]} />;
      case 'right':
        return <View style={[styles.arrow, styles.arrowRight]} />;
    }
  };
}

LocaleConfig.locales.ja = {
  today: '今日',
  dayNames: ['日', '月', '火', '水', '木', '金', '土'],
  dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
  monthNames: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
  monthNamesShort: [
    '1月',
    '2月',
    '3月',
    '4月',
    '5月',
    '6月',
    '7月',
    '8月',
    '9月',
    '10月',
    '11月',
    '12月',
  ],
};

LocaleConfig.defaultLocale = 'ja';

const CALENDAR_THEME = {
  dayTextColor: colors.textColor,
  monthTextColor: colors.gray,
  textDayFontSize: 16,
  textDayHeaderFontSize: 14,
  textDisabledColor: colors.disableTextColor,
  textMonthFontSize: 18,
  textMonthFontWeight: 'bold',
  textSectionTitleColor: colors.lightGray,
  todayTextColor: '#ff8f13',
} as CalendarTheme;

const styles = StyleSheet.create({
  arrow: {
    width: 9,
    height: 9,
    borderColor: '#383838',
    borderRightWidth: 1.5,
    borderBottomWidth: 1.5,
  } as ViewStyle,
  arrowLeft: {
    transform: [{rotate: '135deg'}],
  } as ViewStyle,
  arrowRight: {
    transform: [{rotate: '315deg'}],
  } as ViewStyle,
});
