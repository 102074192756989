import * as React from 'react';
import {StyleSheet, Text, TextStyle} from 'react-native';

interface Props {
  length?: number;
  maxLength: number;
  withUnit?: boolean;
}

const TextCounter: React.FunctionComponent<Props> = props => {
  const {length, maxLength, withUnit} = props;
  return (
    <Text style={styles.textCounter}>
      {length}/{maxLength}
      {withUnit ? '文字' : ''}
    </Text>
  );
};

export default React.memo(TextCounter);

const styles = StyleSheet.create({
  textCounter: {
    color: '#999999',
    fontSize: 11,
  } as TextStyle,
});
