import {AnyAction} from 'redux';
import Types from '../../actions/Types';

export default function reducer(
  state: {message: string; callback: () => void} | null = null,
  action: AnyAction,
): {message: string; callback: () => void} | null {
  switch (action.type) {
    case Types.UpdateApplicationActionModal:
      return action.payload;
    default:
      return state;
  }
}
