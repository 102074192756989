import * as React from 'react';
import {ImageStyle, StyleSheet, View} from 'react-native';

import FastImageAdapter from '../../../../../shared/fast_image/FastImageAdapter';

import {
  actorCharacterFaceImageUrl,
  markImageUrl,
} from '../../../../../../helpers/images';

import {CharacterSceneCommandForm} from '../../../../../../view_models/SceneFrame';

interface Props {
  characterSceneCommandForm: CharacterSceneCommandForm | null;
  width: number;
}

const CharacterView: React.FunctionComponent<Props> = props => {
  const {characterSceneCommandForm, width} = props;
  return (
    <View style={{width, height: width / 0.7}}>
      {characterSceneCommandForm && characterSceneCommandForm.mark && (
        <FastImageAdapter
          style={styles.mark}
          resizeMode={'contain'}
          source={{
            uri: markImageUrl(characterSceneCommandForm.mark, 'large'),
            headers: {Accept: 'image/webp,image/apng,*/*'},
          }}
        />
      )}
      {characterSceneCommandForm && (
        <FastImageAdapter
          resizeMode={'contain'}
          style={{width, height: width / 0.7}}
          source={{
            uri: actorCharacterFaceImageUrl(
              characterSceneCommandForm.actorCharacterFace,
              'middle',
            ),
            headers: {Accept: 'image/webp,image/apng,*/*'},
          }}
        />
      )}
    </View>
  );
};

export default React.memo(CharacterView);

const markWidth = 25;
const markHeight = 25;

const styles = StyleSheet.create({
  mark: {
    top: -10,
    left: 20,
    position: 'absolute',
    width: markWidth,
    height: markHeight,
  } as ImageStyle,
});
