import * as React from 'react';
import {
  Dimensions,
  EmitterSubscription,
  Keyboard,
  KeyboardEvent,
  LayoutAnimation,
  Platform,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';

import getContentDimension from '../../helpers/getContentDimension';

interface Props {
  topSpacing?: number;
  style?: ViewStyle;
}

interface State {
  isKeyboardOpened: boolean;
  keyboardSpace: number;
}

export default class KeyboardSpacer extends React.PureComponent<Props, State> {
  private _listeners: EmitterSubscription[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      isKeyboardOpened: false,
      keyboardSpace: 0,
    };
  }

  public componentDidMount() {
    if (Platform.OS !== 'ios') {
      return;
    }
    this._listeners = [
      Keyboard.addListener(
        Platform.select({
          android: 'keyboardDidShow',
          default: 'keyboardWillShow',
        }),
        this.updateKeyboardSpace,
      ),
      Keyboard.addListener(
        Platform.select({
          android: 'keyboardDidHide',
          default: 'keyboardWillHide',
        }),
        this.resetKeyboardSpace,
      ),
    ];
  }

  public componentWillUnmount() {
    if (!this._listeners) {
      return;
    }
    this._listeners.forEach(listener => listener.remove());
  }

  public render(): React.ReactNode {
    const {style} = this.props;
    const {keyboardSpace} = this.state;
    return <View style={[styles.container, {height: keyboardSpace}, style]} />;
  }

  private updateKeyboardSpace = (event: KeyboardEvent) => {
    const {topSpacing} = this.props;
    if (!event.endCoordinates) {
      return;
    }
    if (Platform.OS === 'ios') {
      LayoutAnimation.configureNext({duration: 200});
    } else {
      LayoutAnimation.configureNext(defaultAnimation);
    }
    const {height} = getContentDimension(Dimensions.get('window'));
    const screenHeight = height;
    const keyboardSpace =
      screenHeight - event.endCoordinates.screenY + (topSpacing || 0);
    this.setState({
      isKeyboardOpened: true,
      keyboardSpace,
    });
  };

  private resetKeyboardSpace = (event: KeyboardEvent) => {
    if (Platform.OS === 'ios') {
      LayoutAnimation.configureNext({duration: 200});
    } else {
      LayoutAnimation.configureNext(defaultAnimation);
    }
    this.setState({
      isKeyboardOpened: false,
      keyboardSpace: 0,
    });
  };
}

const styles = StyleSheet.create({
  container: {
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const defaultAnimation = {
  create: {
    duration: 300,
    property: LayoutAnimation.Properties.opacity,
    type: LayoutAnimation.Types.easeInEaseOut,
  },
  duration: 500,
  update: {
    springDamping: 200,
    type: LayoutAnimation.Types.spring,
  },
};
