import {Dispatch} from 'redux';

import Types from '../Types';

import AppState from '../../reducers/AppState';

import indexGenerics, {Params as BaseParams} from '../generics/index';

import FullScreenIllustration from '../../../domain/entities/FullScreenIllustration';
import PaginatedResult from '../../../domain/results/PaginatedResult';

import NetFullScreenIllustrationsRepository from '../../../data/repositories/writer/NetFullScreenIllustrationsRepository';

const types = {
  failure: Types.IndexFullScreenIllustrationsFailure,
  request: Types.IndexFullScreenIllustrationsRequest,
  success: Types.IndexFullScreenIllustrationsSuccess,
};

export interface Params extends BaseParams {
  storyId?: number;
  episodeId?: number;
  sceneId?: number;
  query?: string;
}

export default function action(
  params: Params,
): (
  dispatch: Dispatch<any>,
  getState: () => AppState,
) => Promise<PaginatedResult<FullScreenIllustration>> {
  return indexGenerics(
    new NetFullScreenIllustrationsRepository(),
    types,
    params,
  );
}
