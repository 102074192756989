import * as React from 'react';
import {CommonActions} from '@react-navigation/native';

import Form from './partials/Form';

import Layout from '../../shared/Layout';

import NavigationProp from '../../../navigators/NavigationProp';
import {NewWriterUserRegistrationRouteProp} from '../../../navigators/RouteProps';

import * as routers from '../../../routers';

import {Params as TemporaryUserRegistrationCreateParams} from '../../../actions/writer/temporary_users/registration/create';

import CurrentUser from '../../../../domain/entities/writer/CurrentUser';
import UserRegistrationForm from '../../../../domain/forms/writer/UserRegistrationForm';

export interface Params {
  hideClose?: boolean;
  penName?: string;
  email?: string;
  options?: {
    facebookAccount?: {
      accessToken: string;
      userId: string;
      screenName: string;
    };
    lineAccount?: {
      accessToken: string;
      userId: string;
      screenName: string;
    };
    twitterAccount?: {
      accessToken: string;
      accessTokenSecret: string;
      userId: string;
      screenName: string;
    };
  };
}

export interface StateProps {
  navigation: NavigationProp;
  route: NewWriterUserRegistrationRouteProp;
  currentUser: CurrentUser | null;
}

export interface DispatchProps {
  createTemporaryUserRegistration: (
    params: TemporaryUserRegistrationCreateParams,
  ) => Promise<CurrentUser>;
  showCurrentUser: () => Promise<CurrentUser>;
  updateCurrentUser: () => Promise<CurrentUser>;
  destroyWriterCurrentUserSession: () => Promise<void>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  loading: boolean;
  form: UserRegistrationForm;
  errorMessages: {
    email: Array<string> | undefined;
    password: Array<string> | undefined;
    passwordConfirmation: Array<string> | undefined;
  } | null;
}

export default class New extends React.PureComponent<Props, State> {
  private mounted = false;

  constructor(props: Props) {
    super(props);
    const {email, options} =
      props.route && props.route.params
        ? props.route.params
        : {email: '', options: {}};
    this.state = {
      form: new UserRegistrationForm(email || '', '', '', options),
      loading: false,
      errorMessages: null,
    };
  }

  public componentDidMount() {
    const {navigation} = this.props;
    this.mounted = true;
    navigation.addListener('focus', this.redirectIfUserStatus);
  }

  public componentWillUnmount() {
    const {navigation} = this.props;
    this.mounted = false;
    navigation.removeListener('focus', this.redirectIfUserStatus);
  }

  public render(): React.ReactNode {
    const {navigation, route} = this.props;
    const {hideClose} = route.params || {hideClose: undefined};
    const {loading, form, errorMessages} = this.state;

    return (
      <Layout
        title={'会員登録'}
        navigation={navigation}
        close={!hideClose}
        loading={loading}
        hideHeaderBottomBorder={true}>
        <Form
          form={form}
          errorMessages={errorMessages}
          onChangeEmail={this.handleChangeEmail}
          onChangePassword={this.handleChangePassword}
          onChangePasswordConfirmation={this.handleChangePasswordConfirmation}
          onPressPoliciesPrivacy={this.handlePressPoliciesPrivacy}
          onPressPoliciesTerms={this.handlePressPoliciesTerms}
          onSubmit={this.handleSubmit}
          onForwardToLogin={this.handleForwardToLogin}
        />
      </Layout>
    );
  }

  private handleChangeEmail = (email: string) => {
    const form = new UserRegistrationForm();
    form.email = email;
    form.password = this.state.form.password;
    form.passwordConfirmation = this.state.form.passwordConfirmation;
    form.options = this.state.form.options;
    this.setStateIfMounted({form});
  };

  private handleChangePassword = (password: string) => {
    const form = new UserRegistrationForm();
    form.email = this.state.form.email;
    form.password = password;
    form.passwordConfirmation = this.state.form.passwordConfirmation;
    form.options = this.state.form.options;
    this.setStateIfMounted({form});
  };

  private handleChangePasswordConfirmation = (passwordConfirmation: string) => {
    const form = new UserRegistrationForm();
    form.email = this.state.form.email;
    form.password = this.state.form.password;
    form.passwordConfirmation = passwordConfirmation;
    form.options = this.state.form.options;
    this.setStateIfMounted({form});
  };

  private handlePressPoliciesPrivacy = () => {
    const {navigation} = this.props;
    routers.linkToPoliciesPrivacyModal(navigation);
  };

  private handlePressPoliciesTerms = () => {
    const {navigation} = this.props;
    routers.linkToPoliciesTermsModal(navigation);
  };

  private handleSubmit = async () => {
    const {
      navigation,
      currentUser,
      createTemporaryUserRegistration,
      showCurrentUser,
      updateCurrentUser,
      destroyWriterCurrentUserSession,
    } = this.props;
    const {form} = this.state;
    try {
      const user = await showCurrentUser();
      if (user.id !== currentUser?.id) {
        updateCurrentUser();
        navigation.dispatch(
          CommonActions.reset({
            index: 0,
            routes: [{params: {}, name: 'Home'}],
          }),
        );
        return;
      }
    } catch (e) {
      // noop
    }
    if (currentUser && currentUser.enabled) {
      updateCurrentUser();
      navigation.dispatch(
        CommonActions.reset({
          index: 0,
          routes: [{params: {}, name: 'Home'}],
        }),
      );
      return;
    }
    await destroyWriterCurrentUserSession();
    this.setStateIfMounted({loading: true}, () => {
      createTemporaryUserRegistration(form.toParams())
        .then(() => {
          routers.linkToWriterTemporaryUserRegistrationCompletion(
            navigation,
            {},
          );
          this.setStateIfMounted({loading: false});
        })
        .catch(error => {
          console.log(error);
          this.setStateIfMounted({
            loading: false,
            errorMessages: error.body,
          });
        });
    });
  };

  private handleForwardToLogin = () => {
    const {navigation, route} = this.props;
    const params = route.params;
    routers.replaceToNewWriterUserSession(navigation, params);
  };

  private setStateIfMounted<K extends keyof State>(
    state:
      | ((
          prevState: Readonly<State>,
          props: Readonly<Props>,
        ) => Pick<State, K> | State | null)
      | (Pick<State, K> | State | null),
    callback?: () => void,
  ) {
    if (!this.mounted) {
      return;
    }
    this.setState(state as any, callback);
  }

  private redirectIfUserStatus = () => {
    const {navigation, currentUser} = this.props;
    if (currentUser) {
      if (!currentUser.confirmed) {
        // nop
      } else if (!currentUser.acceptedTerms) {
        routers.linkToWriterUserRegistrationNavigation(
          navigation,
          {},
          'NewWriterTemporaryUsersTermsAcceptance',
        );
      } else if (!currentUser.enabled) {
        routers.linkToWriterUserRegistrationNavigation(
          navigation,
          {},
          'EditWriterUser',
        );
      } else {
        navigation.goBack();
      }
    }
  };
}
