import EpisodeBrowsingRetentionRate from '../../../../domain/value_objects/summary/EpisodeBrowsingRetentionRate';

export interface EpisodeBrowsingRetentionRateData {
  retention_rate: number;
  command_index: number;
}

export default class EpisodeBrowsingRetentionRateMapper {
  public map(
    obj: EpisodeBrowsingRetentionRateData,
  ): EpisodeBrowsingRetentionRate {
    return new EpisodeBrowsingRetentionRate(
      obj.retention_rate,
      obj.command_index,
    );
  }
}
