export default class PaymentMethod {
  constructor(
    public id: number,
    public stripePaymentMethodId: string,
    public cardBrand: string,
    public cardLast4: string,
    public cardExpMonth: string,
    public cardExpYear: string,
    public defaultPaymentMethod: boolean,
    public createdAt: Date,
    public updatedAt: Date,
  ) {}
}
