import * as React from 'react';
import {
  Platform,
  NativeSyntheticEvent,
  TextInput,
  TextInputSelectionChangeEventData,
} from 'react-native';

import Form, {TabValue} from './partials/Form';

import Layout from '../../../../shared/Layout';
import HeaderRightButton from '../../../../shared/buttons/HeaderRightButton';
import CloseIcon from '../../../../shared/icons/CloseIcon';
import DimensionContext from '../../../../shared/dimension/DimensionContext';

import {validText} from '../../../../../helpers/textInputHelper';

import * as routers from '../../../../../routers';

import NavigationProp from '../../../../../navigators/NavigationProp';
import {SceneFormSpeechTextShowSceneCommandNewSpeechTextsRouteProp} from '../../../../../navigators/RouteProps';

import {Params as SpeechBalloonIndexParams} from '../../../../../actions/speech_balloons/index';
import {Params as OrientedSpeechBalloonIndexParams} from '../../../../../actions/oriented_speech_balloons/index';

import redirectSceneForm from '../../../../../helpers/redirectSceneForm';
import scrollableLayout from '../../../../../helpers/scrollableLayout';
import {addSymbolToTextAndCalcSelection} from '../../../../../helpers/textInputHelper';

import SpeechBalloon from '../../../../../../domain/entities/SpeechBalloon';
import OrientedSpeechBalloon from '../../../../../../domain/entities/OrientedSpeechBalloon';
import Voice from '../../../../../../domain/entities/Voice';
import Sound from '../../../../../../domain/entities/Sound';
import PaginatedResult from '../../../../../../domain/results/PaginatedResult';
import SceneForm from '../../../../../../domain/forms/SceneForm';
import SceneCommandForm from '../../../../../../domain/forms/scene_commands/SceneCommandForm';
import Position from '../../../../../../domain/value_objects/Position';

import TextNormalizer from '../../../../../../domain/helpers/TextNormalizer';

import {isAndroid} from '../../../../../../data/data_stores/net/UserAgent';

export interface Params {
  storyId: number;
  episodeId?: number;
  sceneId?: number;
}

export interface StateProps {
  navigation: NavigationProp;
  route: SceneFormSpeechTextShowSceneCommandNewSpeechTextsRouteProp;
  sceneForm: SceneForm | null;
  sceneCommandIndex?: number;
  sceneCommandForms: SceneCommandForm[] | null;
  positions: Position[];
  speechBalloonId: number;
  text: string;
  overrideCharacterName?: boolean;
  characterName?: string;
  speechBalloonsParams: SpeechBalloonIndexParams;
  speechBalloons: SpeechBalloon[] | null;
  orientedSpeechBalloonsParams: OrientedSpeechBalloonIndexParams;
  orientedSpeechBalloons: OrientedSpeechBalloon[] | null;
  enableVoice?: boolean;
}

export interface DispatchProps {
  onSelectText: (
    text: string,
    speechBalloonId: number,
    positions: Position[],
    name?: string,
    voice?: Voice | null,
    sound?: Sound | null,
    startTime?: number | null,
    endTime?: number | null,
  ) => void;
  indexSpeechBalloons: (
    params: SpeechBalloonIndexParams,
  ) => Promise<PaginatedResult<SpeechBalloon>>;
  indexOrientedSpeechBalloons: (
    params: OrientedSpeechBalloonIndexParams,
  ) => Promise<PaginatedResult<OrientedSpeechBalloon>>;
}

interface Props extends StateProps, DispatchProps {}

interface State {
  currentTab: TabValue;
  disabled: boolean;
  text: string;
  characterName?: string;
  selectedSpeechBalloonId: number;
  selectedPositions: Position[];
  selectedVoice: Voice | null;
  selectedSound: Sound | null;
  selectedSoundStartTime: number | null;
  selectedSoundEndTime: number | null;
  multiplePositions: boolean;
  selection?: {
    start: number;
    end: number;
  };
}

export default class Index extends React.PureComponent<Props, State> {
  private currentSelection:
    | {
        start: number;
        end: number;
      }
    | undefined = undefined;

  private textInputRef: React.RefObject<TextInput>;

  constructor(props: Props) {
    super(props);
    const {text} = props;
    const disabled = !validText(text);
    const textLength = text.length;
    this.state = {
      currentTab: 'text',
      disabled,
      text,
      characterName: props.overrideCharacterName
        ? '？？？'
        : props.characterName,
      selectedSpeechBalloonId: props.speechBalloonId,
      selectedPositions: props.positions,
      selectedVoice: null,
      selectedSound: null,
      selectedSoundStartTime: null,
      selectedSoundEndTime: null,
      multiplePositions: false,
      selection: {start: textLength, end: textLength},
    };
    this.textInputRef = React.createRef<TextInput>();
  }

  public componentDidMount() {
    const {
      navigation,
      route,
      sceneForm,
      speechBalloons,
      speechBalloonsParams,
      orientedSpeechBalloons,
      orientedSpeechBalloonsParams,
      indexSpeechBalloons,
      indexOrientedSpeechBalloons,
    } = this.props;
    if (redirectSceneForm(navigation, route, sceneForm)) {
      return;
    }
    if (!speechBalloons) {
      indexSpeechBalloons(speechBalloonsParams);
    }
    if (!orientedSpeechBalloons) {
      indexOrientedSpeechBalloons(orientedSpeechBalloonsParams);
    }
  }

  public render(): React.ReactNode {
    const {
      navigation,
      route,
      speechBalloons,
      orientedSpeechBalloons,
      overrideCharacterName,
      sceneCommandForms,
      sceneCommandIndex,
      enableVoice,
    } = this.props;
    const {
      currentTab,
      text,
      characterName,
      selectedSpeechBalloonId,
      selectedPositions,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
      selection,
    } = this.state;
    const {storyId} = route.params;
    const orientedSpeechBalloon = orientedSpeechBalloons?.find(
      o =>
        o.speechBalloonId === selectedSpeechBalloonId &&
        o.matchesPositions(selectedPositions),
    );
    return (
      <DimensionContext.Consumer>
        {context => (
          <Layout
            title={
              currentTab === 'text'
                ? 'セリフ内容'
                : currentTab === 'speech_balloon'
                ? '吹き出し'
                : 'セリフの向き'
            }
            scrollable={
              Platform.OS === 'web' && isAndroid
                ? false
                : scrollableLayout(context)
            }
            navigation={navigation}
            back={true}
            rightButton={this.buildRightButton()}>
            {orientedSpeechBalloon && speechBalloons && (
              <Form
                storyId={storyId}
                textInputRef={this.textInputRef}
                currentTab={currentTab}
                orientedSpeechBalloon={orientedSpeechBalloon}
                text={text}
                overrideCharacterName={overrideCharacterName}
                characterName={characterName}
                speechBalloons={speechBalloons}
                selectedSpeechBalloonId={selectedSpeechBalloonId}
                positions={selectedPositions}
                voice={selectedVoice}
                sound={selectedSound}
                selectedSoundStartTime={selectedSoundStartTime}
                selectedSoundEndTime={selectedSoundEndTime}
                enableVoice={enableVoice}
                sceneCommandForms={sceneCommandForms}
                sceneCommandIndex={sceneCommandIndex}
                selection={selection}
                onChangeTab={this.handleChangeTab}
                onChangeText={this.handleChangeText}
                onPressSymbol={this.handlePressSymbol}
                onChangeName={this.handleChangeName}
                onSelectSpeechBalloon={this.handleSelectSpeechBalloon}
                onSelectPosition={this.handleSelectPosition}
                onUpdateVoice={this.handleUpdateVoice}
                onSelectionChange={this.handleSelectionChange}
                addSound={this.addSound}
                deleteSound={this.deleteSound}
                onChagenRegion={this.handleChagenRegion}
              />
            )}
          </Layout>
        )}
      </DimensionContext.Consumer>
    );
  }

  private buildRightButton = () => {
    const {text} = this.state;
    if (text.length === 0) {
      return {
        handler: this.handleClose,
        tintColor: 'white',
        title: <CloseIcon />,
      };
    } else {
      return {
        tintColor: 'white',
        title: (
          <HeaderRightButton
            disabled={!validText(text)}
            onPress={this.handlePress}>
            確定
          </HeaderRightButton>
        ),
      };
    }
  };

  private handleClose = () => {
    const {navigation} = this.props;
    (navigation.getParent() || navigation).goBack();
  };

  private handleChangeTab = (currentTab: TabValue) => {
    this.setState({currentTab});
  };

  private handleChangeText = (text: string) => {
    const disabled = !validText(text);
    this.setState({disabled, text});
  };

  private handleChangeName = (characterName: string) => {
    this.setState({characterName});
  };

  private handleSelectSpeechBalloon = (speechBalloon: SpeechBalloon) => {
    this.setState({selectedSpeechBalloonId: speechBalloon.id});
  };

  private handleSelectPosition = (
    selectedPosition: Position,
    toggleMultiple?: boolean,
  ) => {
    const multiplePositions = toggleMultiple
      ? !this.state.multiplePositions
      : this.state.multiplePositions;

    const selectedPositions = multiplePositions
      ? this.state.selectedPositions.includes(selectedPosition)
        ? this.state.selectedPositions.length === 1
          ? this.state.selectedPositions
          : this.state.selectedPositions.filter(p => p !== selectedPosition)
        : [...this.state.selectedPositions, selectedPosition]
      : [selectedPosition];
    this.setState({
      selectedPositions,
      multiplePositions,
    });
  };

  private handlePress = () => {
    const {onSelectText} = this.props;
    const {
      disabled,
      text,
      characterName,
      selectedSpeechBalloonId,
      selectedPositions,
      selectedVoice,
      selectedSound,
      selectedSoundStartTime,
      selectedSoundEndTime,
    } = this.state;
    if (disabled) {
      return;
    }
    const nextText = TextNormalizer.normalize(text, 'speech_text');
    const nextDisabled = !validText(nextText);
    this.setState(
      {
        disabled: nextDisabled,
        text: nextText,
      },
      () => {
        if (nextDisabled) {
          return;
        }
        onSelectText(
          nextText,
          selectedSpeechBalloonId,
          selectedPositions,
          characterName,
          selectedVoice,
          selectedSound,
          selectedSoundStartTime,
          selectedSoundEndTime,
        );
      },
    );
  };

  private handlePressSymbol = (symbol: string) => {
    const {text: selectedText} = this.state;
    const {text, selection} = addSymbolToTextAndCalcSelection(
      selectedText,
      symbol,
      this.currentSelection,
    );
    const disabled = !validText(text);
    this.setState({disabled, text, selection});
    this.textInputRef.current?.focus();
  };

  private handleUpdateVoice = (voice: Voice | null) => {
    this.setState({selectedVoice: voice});
  };

  private handleSelectionChange = (
    e: NativeSyntheticEvent<TextInputSelectionChangeEventData>,
  ) => {
    if (this.state.selection) {
      this.setState({selection: undefined});
    }
    this.currentSelection = e.nativeEvent.selection;
  };

  private addSound = () => {
    const {navigation, route} = this.props;
    const params = route.params;
    routers.linkToSceneFormSoundEffectShowSceneCommandNewNavigations(
      navigation,
      {
        storyId: params.storyId,
        episodeId: params.episodeId,
        sceneId: params.sceneId,
        back: true,
        sceneCommandIndex: params.sceneCommandIndex,
        subSceneCommandIndex: params.subSceneCommandIndex,
        parentSceneCommandId: params.parentSceneCommandId,
        callback: selectedSound => {
          this.setState({selectedSound});
        },
      },
    );
  };

  private deleteSound = () => {
    this.setState({
      selectedSound: null,
      selectedSoundStartTime: null,
      selectedSoundEndTime: null,
    });
  };

  private handleChagenRegion = (start?: number, end?: number) => {
    this.setState({
      selectedSoundStartTime: start || null,
      selectedSoundEndTime: end || null,
    });
  };
}
